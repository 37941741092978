import { handleActions } from 'redux-actions'
import { getMyHealthDevicesSuccess, resetState } from '@tabeeb/modules/healthData/actions'

const defaultState = []

export default handleActions(
  {
    [getMyHealthDevicesSuccess](state, action) {
      return action.response.status === 200 ? action.response.data : defaultState
    },
    [resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
