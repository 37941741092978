import { createAction } from 'redux-actions'

export const setAudioInputDevice = createAction('SET_AUDIO_INPUT_DEVICE')
export const setAudioOutputDevice = createAction('SET_AUDIO_OUTPUT_DEVICE')
export const setVideoInputDevice = createAction('SET_VIDEO_INPUT_DEVICE')

export const loadCameraPreviews = createAction('LOAD_CAMERA_PREVIEWS')
export const unloadCameraPreviews = createAction('UNLOAD_CAMERA_PREVIEWS')

export const flipMobileCamera = createAction('FLIP_MOBILE_CAMERA')
export const setMobileCameraFacingMode = createAction('SET_MOBILE_CAMERA_FACING_MODE')

export const resetDevices = createAction('RESET_DEVICES')
