import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.reset](state, action) {
      return defaultState
    },
    [combineActions(
      rawActions.getLatestPoliciesWithAcceptanceStatusRequest,
      rawActions.getLatestPolicyRequest,
      rawActions.getPolicyRequest
    )](state, action) {
      return true
    },
    [combineActions(
      rawActions.getLatestPoliciesWithAcceptanceStatusSuccess,
      rawActions.getLatestPoliciesWithAcceptanceStatusFailed,
      rawActions.getLatestPolicySuccess,
      rawActions.getLatestPolicyFailed,
      rawActions.getPolicySuccess,
      rawActions.getPolicyFailed
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
