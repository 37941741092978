import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(rawActions.getPredictionSets.request)](state, action) {
      return true
    },
    [combineActions(
      rawActions.getPredictionSets.success,
      rawActions.getPredictionSets.failed,
      rawActions.getPredictionSets.cancel
    )](state, action) {
      return false
    },
    [rawActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
