import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as activityActions from '../actions'
import SearchFilter from '../components/SearchFilter'

class SearchFilterContainer extends Component {
  handleChangeSearchFilter = (e) => {
    const { searchFilter, activityActions } = this.props

    const newSearchFilter = e.target.value
    if (newSearchFilter !== searchFilter) {
      activityActions.onEnterSearchTextFilter(newSearchFilter)
    }
  }

  render() {
    const { searchFilter, activityType } = this.props

    const props = {
      searchFilter,
      handleChangeSearchFilter: this.handleChangeSearchFilter,
      isDisabled: ['Image', 'Shape', 'Audio', 'Drawing'].includes(activityType),
    }

    return <SearchFilter {...props} />
  }
}

SearchFilterContainer.propTypes = {
  searchFilter: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  activityActions: PropTypes.shape({
    onEnterSearchTextFilter: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ activities: { activitiesFilter } }) => {
  return {
    searchFilter: activitiesFilter.searchFilter,
    activityType: activitiesFilter.activityType,
  }
}

const mapDispatchToProps = (dispatch) => ({
  activityActions: bindActionCreators(activityActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilterContainer)
