import { put, takeLatest, select, all } from 'redux-saga/effects'

import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import * as aiActions from '../actions'
import * as annotationsActions from '../../annotations/actions'
import { AnnotationType } from '../../../Enums'
import { getSelectedAiObject, getAIObjects } from '../selectors'

function* onAddUniqueAiObjectSuccess(action) {
  const { data: uniqueAIObject } = action.response
  const { parentAnnotationId, annotationId, createAnnotation = true } = action.payload
  if (!createAnnotation) {
    return
  }

  const selectedAIObject = yield select(getSelectedAiObject)
  let aiObject = null

  if (uniqueAIObject.AIObjectId === selectedAIObject.Id) {
    aiObject = selectedAIObject
  }

  if (!aiObject) {
    const aiObjects = yield select(getAIObjects)
    aiObject = aiObjects.find((object) => object.Id === uniqueAIObject.AIObjectId)
  }

  const pageId = yield select(getSelectedGalleryItemId)
  const contentId = yield select(getContentId)

  const annotationText = `CV Class: ${aiObject.Name}, Object: ${uniqueAIObject.Name}`

  const annotation = {
    Id: annotationId || undefined,
    IsAIDetectCorrect: null,
    PageId: pageId,
    ParentAnnotationId: parentAnnotationId,
    Text: annotationText,
    Timestamp: -1,
    Type: AnnotationType.AI,
    UniqueAIObjectId: uniqueAIObject.Id,
    UserFeedback: null,
    AIObjectId: uniqueAIObject.AIObjectId,
    ContentId: contentId,
  }

  if (annotationId) {
    yield put(annotationsActions.updateAiAnnotationRequest(annotation))
  } else {
    yield put(annotationsActions.addAnnotationRequest(annotation))
  }
}

function* onAddUniqueAiObjectFailed(action) {
  yield put(onAddErrorNotification({ message: 'Failed to create an object.' }))
}

function* getUniqueAIObjects() {
  yield all([
    takeLatest(
      [aiActions.addUniqueAiObject.success, aiActions.shallowCloneUniqueAiObject.success],
      onAddUniqueAiObjectSuccess
    ),
    takeLatest(aiActions.addUniqueAiObject.failed, onAddUniqueAiObjectFailed),
  ])
}

export default getUniqueAIObjects
