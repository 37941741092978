import { memo } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'

import { Divider, LinearProgress, withStyles } from '@material-ui/core'

import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'

import AnswersListItem from '../AnswersListItem'
import { NoAnswersPlaceholder, FailedToLoadAnswersPlaceholder } from '../../Placeholders'

import Filters from '../../Filters'

import styles from './styles'

const AnswersList = ({
  classes,
  answers,
  loading,
  loaded,
  failed,
  filters,
  onLoadMore,
  onFiltersChange,
  filtersProps,
  answerInfoProps,
}) => {
  const isEmpty = answers.length === 0

  const isInitialLoading = loading && isEmpty
  const isAdditionalLoading = loading && !isEmpty

  return (
    <>
      <div className={classes.container}>
        <Filters value={filters} onChange={onFiltersChange} {...filtersProps} />
        <Divider />
        <InfiniteScroll useWindow={false} initialLoad={false} loadMore={onLoadMore} hasMore={!loaded && !loading}>
          <ul className={classes.list}>
            {answers.map((answer) => (
              <AnswersListItem key={answer.Id} answer={answer} answerInfoProps={answerInfoProps} />
            ))}
          </ul>
        </InfiniteScroll>
        {loaded && isEmpty && <NoAnswersPlaceholder />}
        {failed && <FailedToLoadAnswersPlaceholder />}
      </div>
      {isInitialLoading && <CircularProgressBar />}
      {isAdditionalLoading && <LinearProgress className={classes.progress} />}
    </>
  )
}

AnswersList.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    progress: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
  }).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  answerInfoProps: PropTypes.shape({
    pagePreview: PropTypes.bool,
    formName: PropTypes.bool,
    questionName: PropTypes.bool,
  }).isRequired,
  filtersProps: PropTypes.shape({
    userFilter: PropTypes.bool,
    formFilter: PropTypes.bool,
    statusFilter: PropTypes.bool,
  }).isRequired,
  filters: PropTypes.shape({
    userId: PropTypes.number,
    formId: PropTypes.number,
    statuses: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
}

export default memo(withStyles(styles)(AnswersList))
