import PropTypes from 'prop-types'
import { useEffect, useState, useCallback, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  TextField,
  Box,
} from '@mui/material'
import _ from 'lodash'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import LimitBar from '../LimitBar'

import { FEET_IN_METER } from '../../constants'
import { getContentBentleyGigapixels } from '../../actions'
import {
  getAreGigapixelsLoading,
  getBentleyImagesList,
  getUpcomingGigapixels,
  getUsedGigapixels,
  getContextCaptureGigapixelLimit,
} from '../../selectors'

import {
  TitleContainer,
  ContentContainer,
  ManualInputContainer,
  UnitsContainer,
  UnitsLabelContainer,
  ManualInputValuesContainer,
} from './styles'

const Settings = {
  BoundingBox: {
    AutoCut: 'Generate automatically',
    NoCut: 'Do not generate',
    ManualCut: 'Generate from the manual input',
  },
}

const UnitsOfMeasurement = {
  Feet: 'Feet',
  Meters: 'Meters',
}

const BentleySettingsDialog = ({ isOpen, isManualBoundingBoxInputEnabled, closeDialog, createBentleyModel }) => {
  const dispatch = useDispatch()

  const [selectedSettings, setSelectedSettings] = useState({
    boundingBoxMode: Settings.BoundingBox.AutoCut,
    unitOfMeasurement: UnitsOfMeasurement.Feet,
    width: 30,
    height: 100,
  })

  const areGigapixelsLoading = useSelector(getAreGigapixelsLoading)
  const imagesList = useSelector(getBentleyImagesList)
  const contentId = useSelector(getContentId)
  const usedGigapixels = useSelector(getUsedGigapixels)
  const upcomingGigapixels = useSelector(getUpcomingGigapixels)
  const limitGigapixels = useSelector(getContextCaptureGigapixelLimit)

  const limitExists = _.isNumber(limitGigapixels)
  const disabled = limitExists && limitGigapixels - usedGigapixels - upcomingGigapixels < 0

  const handleChange = useCallback(
    (event) => {
      setSelectedSettings({
        ...selectedSettings,
        [event.target.name]: event.target.value,
      })
    },
    [selectedSettings]
  )

  const handleOnlyNumericChange = useCallback(
    (event) => {
      const input = event.target.value
      const regex = /^([1-9][0-9]{0,4})$/i
      if (regex.test(input) || input === '') {
        setSelectedSettings({
          ...selectedSettings,
          [event.target.name]: input,
        })
      }
    },
    [selectedSettings]
  )

  const handleCreate = useCallback(() => {
    let settingsModel

    if (selectedSettings.boundingBoxMode === Settings.BoundingBox.ManualCut) {
      const shouldTransformIntoFeet = selectedSettings.unitOfMeasurement === UnitsOfMeasurement.Meters
      const widthInFeet = shouldTransformIntoFeet ? selectedSettings.width * FEET_IN_METER : selectedSettings.width
      const heightInFeet = shouldTransformIntoFeet ? selectedSettings.height * FEET_IN_METER : selectedSettings.height

      settingsModel = {
        boundingBox: {
          width: parseInt(widthInFeet, 10),
          height: parseInt(heightInFeet, 10),
        },
      }
    } else if (selectedSettings.boundingBoxMode === Settings.BoundingBox.AutoCut) {
      settingsModel = {
        boundingBox: {
          isAuto: true,
        },
      }
    } else {
      settingsModel = {
        boundingBox: null,
      }
    }

    createBentleyModel(settingsModel)
    closeDialog()
  }, [createBentleyModel, closeDialog, selectedSettings])

  useEffect(() => {
    if (isOpen && limitExists) {
      dispatch(getContentBentleyGigapixels.request({ contentId, pageIds: imagesList.map((page) => page.id) }))
    }
  }, [dispatch, isOpen, contentId, imagesList, limitExists])

  return (
    <Dialog open={isOpen}>
      <TitleContainer>Configure the 3D Model</TitleContainer>
      <DialogContent>
        <ContentContainer>
          {areGigapixelsLoading ? (
            <CircularProgressBar />
          ) : (
            limitExists && (
              <LimitBar
                labelComponent={<FormLabel>Session&apos;s Gigapixel Capacity</FormLabel>}
                used={usedGigapixels}
                upcoming={upcomingGigapixels}
                limit={limitGigapixels}
              />
            )
          )}
          <Box>
            <FormControl>
              <FormLabel>Bounding Box</FormLabel>
              <RadioGroup value={selectedSettings.boundingBoxMode} onChange={handleChange} name='boundingBoxMode'>
                {Object.values(Settings.BoundingBox).map((value) => {
                  const display = value === Settings.BoundingBox.ManualCut ? isManualBoundingBoxInputEnabled : true
                  return (
                    display && (
                      <FormControlLabel key={value} value={value} control={<Radio color='primary' />} label={value} />
                    )
                  )
                })}
              </RadioGroup>
            </FormControl>
          </Box>
          <ManualInputContainer>
            <Fade in={selectedSettings.boundingBoxMode === Settings.BoundingBox.ManualCut} unmountOnExit>
              <FormControl>
                <UnitsContainer
                  value={selectedSettings.unitOfMeasurement}
                  onChange={handleChange}
                  name='unitOfMeasurement'
                >
                  <UnitsLabelContainer>
                    <FormLabel>Choose unit:</FormLabel>
                  </UnitsLabelContainer>
                  <Box>
                    <FormControlLabel
                      value={UnitsOfMeasurement.Feet}
                      control={<Radio />}
                      label={UnitsOfMeasurement.Feet}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      value={UnitsOfMeasurement.Meters}
                      control={<Radio />}
                      label={UnitsOfMeasurement.Meters}
                    />
                  </Box>
                </UnitsContainer>
                <ManualInputValuesContainer>
                  <Box>
                    <TextField
                      value={selectedSettings.width}
                      label='Width'
                      variant='outlined'
                      onChange={handleOnlyNumericChange}
                      size='small'
                      name='width'
                      type='number'
                    />
                  </Box>
                  <Box>
                    <TextField
                      value={selectedSettings.height}
                      label='Height'
                      variant='outlined'
                      onChange={handleOnlyNumericChange}
                      size='small'
                      name='height'
                      type='number'
                    />
                  </Box>
                </ManualInputValuesContainer>
              </FormControl>
            </Fade>
          </ManualInputContainer>
        </ContentContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} color='primary' disabled={disabled}>
          Create
        </Button>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

BentleySettingsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isManualBoundingBoxInputEnabled: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  createBentleyModel: PropTypes.func.isRequired,
}

export default memo(BentleySettingsDialog)
