import { put, takeLatest, select, all } from 'redux-saga/effects'

import { rawContentActions, contentStateSelectors } from '@tabeeb/shared/content'
import { isImagePage } from '@tabeeb/services/pageService'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'

import * as galleryActions from '../../gallery/actions'
import { getPagesByFolderWithNesting, getFoldersByIds } from '../../gallery/selectors'

function* addFolderToBentleyModel({ payload: { folderId } }) {
  let pages = yield select((state) => getPagesByFolderWithNesting(state, { id: folderId }))

  pages = pages.filter((item) => isImagePage(item.contentType))

  if (pages.length < 1) {
    yield put(
      notificationActions.onAddWarningNotification({
        message: 'Folder is empty!',
      })
    )

    return
  }

  yield put(rawActions.addAllPagesToBentleyModel(pages))
}

function* onCreateBentleyModel(action) {
  const contentId = yield select(contentStateSelectors.getContentId)
  const imagesList = yield select((state) => state.bentleyModel.imagesList)

  if (imagesList.length < 1) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'Images list is empty!',
      })
    )
    return
  }

  const uniqueFolderIds = new Set()
  const pageIds = imagesList.map((page) => page.id)

  const folders = yield select((state) => getFoldersByIds(state, { ids: uniqueFolderIds }))

  const model = {
    contentId,
    pageIds,
    folderNames: folders.map((folder) => folder.Name),
    settings: action.payload,
  }

  yield put(rawActions.createBentleyModelRequest(model))
}

function* createBentleyModelFailed(action) {
  yield put(
    notificationActions.onAddErrorNotification({
      message: 'Failed to create a ContextCapture model',
    })
  )
}

function* createBentleyModelSuccess() {
  yield put(rawActions.closeBentleyModelTimeline())

  yield put(
    notificationActions.onAddInfoNotification({
      message: 'Model sent for creation',
    })
  )
}

function* getGigapixelsFailed(action) {
  yield put(
    notificationActions.onAddErrorNotification({
      message: 'Failed to calculate gigapixels used in the session',
    })
  )
  yield put(rawActions.resetState())
}

function* resetState() {
  yield put(rawActions.resetState())
}

function* bentleyModelSaga() {
  yield all([
    takeLatest(rawActions.createBentleyModel, onCreateBentleyModel),
    takeLatest(rawActions.createBentleyModelFailed, createBentleyModelFailed),
    takeLatest(rawActions.createBentleyModelSuccess, createBentleyModelSuccess),
    takeLatest(galleryActions.addFolderToBentleyModel, addFolderToBentleyModel),
    takeLatest(rawActions.getContentBentleyGigapixels.failed, getGigapixelsFailed),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default bentleyModelSaga
