import PropTypes from 'prop-types'

import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from '@material-ui/core'
import CircularProgressBar from '../../../presentation/components/CircularProgressBar/index'
import RadioButtons from '../../../forms/components/RadioButtons'

const ExportAsPdfOptionsDialog = ({
  isExportPdfOptionsDialogOpen,
  closeDialog,
  next,
  isShowProgress,
  setPdfExportOption,
  selectedOption,
  exportOptions,
}) => {
  const props = {
    control: {
      Label: 'Export options',
      Tooltip: 'Export options',
      Options: exportOptions,
    },
    answer: { selectedOptionsIds: [selectedOption] },
    handleChange: setPdfExportOption,
    disabled: false,
  }

  return (
    <>
      <Dialog modal='false' open={isExportPdfOptionsDialogOpen} onClose={closeDialog}>
        <DialogTitle>Export session media in the order of</DialogTitle>
        <DialogContent
          style={{
            width: '450px',
          }}
        >
          <RadioButtons {...props} />
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={next}>
            Continue
          </Button>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      {isExportPdfOptionsDialogOpen && isShowProgress && <CircularProgressBar />}
    </>
  )
}

ExportAsPdfOptionsDialog.propTypes = {
  isExportPdfOptionsDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  isShowProgress: PropTypes.bool.isRequired,
  setPdfExportOption: PropTypes.func.isRequired,
  exportOptions: PropTypes.array.isRequired,
  selectedOption: PropTypes.number.isRequired,
}

export default ExportAsPdfOptionsDialog
