import * as Yup from 'yup'

import { AIObjectPropertyType } from '@tabeeb/enums'
import { requiredField, noWhitespaces, maxLength, invalidUrl } from '../../shared/utils/validationErrorMessages'

export const generalMLModelSchema = Yup.object().shape({
  name: Yup.string().strict().trim(noWhitespaces).max(500, maxLength(500)).required(requiredField),
  description: Yup.string().strict().trim(noWhitespaces).max(1000, maxLength(1000)).required(requiredField),
})

export const urlValidationSchema = Yup.object().shape({
  url: Yup.string().strict().trim(noWhitespaces).required(requiredField).url(invalidUrl),
})

export const keyValidationSchema = Yup.object().shape({
  key: Yup.string().strict().trim(noWhitespaces).required(requiredField),
})

export const validDateTimeSchema = Yup.string()
  .nullable()
  .typeError('Must be a date.')
  .matches(
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/, // YYYY-MM-DDTHH:MM:SSZ
    'Default value must be a valid date.'
  )
  .test('is-valid-date', 'Must be a date greater than January 1, 0001.', (value) => {
    if (!value) {
      return true
    }
    const date = new Date(value)
    const minDate = new Date('0001-01-01')
    return date > minDate
  })

export const validFloatSchema = Yup.number()
  .nullable()
  .typeError('Must be a number.')
  .test('is-integer-or-float', 'Must be a float.', (value) => {
    if (value === null || value === undefined) {
      return true
    }
    const isInt = Number.isInteger(value)
    const isFloat = Number(value) === value && value % 1 !== 0
    return isInt || isFloat
  })
  .min(-Number.MAX_VALUE, 'Value is too small.')
  .max(Number.MAX_VALUE, 'Value is too large.')

export const validLatitudeLongitudeSchema = Yup.number()
  .nullable()
  .typeError('Must be a number.')
  .test(
    'is-float',
    'Must be a float.',
    (value) => value === null || value === undefined || (Number(value) === value && value % 1 !== 0)
  )
  .min(-Number.MAX_VALUE, 'Value is too small.')
  .max(Number.MAX_VALUE, 'Value is too large.')

export const validJsonSchema = Yup.string()
  .nullable()
  .typeError('Must be a JSON string.')
  .test('is-json', 'Must be a valid JSON string.', (value) => {
    if (!value) {
      return true
    }
    try {
      JSON.parse(value)
      return true
    } catch (e) {
      return false
    }
  })

export const validAIObjectPropertySchema = Yup.lazy((value, context) => {
  switch (context.parent.type) {
    case AIObjectPropertyType.DateTime:
      return validDateTimeSchema
    case AIObjectPropertyType.IntNumber:
      return Yup.number()
        .nullable()
        .typeError('Must be a number.')
        .integer('Must be an integer.')
        .min(-2147483648, 'Minimum value is -2147483648.')
        .max(2147483647, 'Maximum value is 2147483647.')
    case AIObjectPropertyType.DoubleNumber:
      return validFloatSchema
    case AIObjectPropertyType.Json:
      return validJsonSchema
    case AIObjectPropertyType.Url:
      return Yup.string().nullable().typeError('Must be a URL.').url('Must be a valid URL.')
    case AIObjectPropertyType.Latitude:
      return validLatitudeLongitudeSchema
    case AIObjectPropertyType.Longitude:
      return validLatitudeLongitudeSchema
    default:
      return Yup.mixed().nullable().notRequired()
  }
})
