import { ReminderRepeatMode, ReminderCustomRepeatMode, ReminderCustomRepeatEndOnType } from '@tabeeb/enums'
import { getUtcDate } from '@tabeeb/services/dateTimeService'
import { MINUTES_MIN_INTERVAL } from './constants'

export const ReminderError = {
  EmptyUsers: 'At least one user must be selected',
  InvalidStartDate: 'Start time must be greater than current time',
  InvalidRepeatInterval: 'Invalid repeat interval.',
  InvalidEndDate: 'End time must be greater than start time',
  InvalidOccurrencesValue: 'At least one occurrence expected',
  DayOfWeekNotSelected: 'At least one day of week must be selected',
}

export function getCustomRepeatSettingsErrors(currentReminder, settings) {
  const errors = []
  let repeatSettings = settings

  if (!currentReminder) return errors
  if (!repeatSettings) repeatSettings = currentReminder.CustomRepeatSettings
  if (!repeatSettings) return errors

  const { RepeatMode, StartDate } = currentReminder
  const { Mode, Interval, EndType, EndDate, EndOccurrences, SelectedDaysOfWeek } = repeatSettings

  if (RepeatMode === ReminderRepeatMode.Custom) {
    const isMinutesRepeatMode = Mode === ReminderCustomRepeatMode.Minute
    const isWeekRepeatMode = Mode === ReminderCustomRepeatMode.Week

    if ((isMinutesRepeatMode && Interval < MINUTES_MIN_INTERVAL) || Interval < 1 || Number.isNaN(Interval)) {
      errors.push(ReminderError.InvalidRepeatInterval)
    }

    if (isWeekRepeatMode && SelectedDaysOfWeek === 0) errors.push(ReminderError.DayOfWeekNotSelected)

    if (EndType === ReminderCustomRepeatEndOnType.Date && EndDate <= StartDate) {
      errors.push(ReminderError.InvalidEndDate)
    }

    if ((EndType === ReminderCustomRepeatEndOnType.Occurrences && EndOccurrences < 1) || Number.isNaN(EndOccurrences)) {
      errors.push(ReminderError.InvalidOccurrencesValue)
    }
  }

  return errors
}

export function getReminderErrors(currentReminder) {
  const mainSettingsErrors = []
  const now = getUtcDate()

  const { Users, RepeatMode, StartDate } = currentReminder

  if (Users.length === 0) mainSettingsErrors.push(ReminderError.EmptyUsers)

  if (RepeatMode === ReminderRepeatMode.NoRepeat && StartDate < now) {
    mainSettingsErrors.push(ReminderError.InvalidStartDate)
  }

  const customRepeatErrors = getCustomRepeatSettingsErrors(currentReminder)

  return [...mainSettingsErrors, ...customRepeatErrors]
}
