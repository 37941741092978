import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Badge, IconButton, makeStyles, Popover, useTheme } from '@material-ui/core'
import { Notifications as NotificationsIcon } from '@material-ui/icons'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { getNotificationsCountRequest } from '@tabeeb/modules/notificationsPage/actions'
import NotificationsTab, { notificationsPageSelectors } from '@tabeeb/modules/notificationsPage'

import useStyles from './styles'

const Notifications = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const buttonRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  const textColor = useSelector(appConfigStateSelectors.getMainTextColor)
  const notificationColor = useSelector(appConfigStateSelectors.getNotificationColor)
  const notificationsCount = useSelector(notificationsPageSelectors.getNotificationsCount)

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    dispatch(getNotificationsCountRequest())
  }, [dispatch])

  const badgeClasses = useMemo(() => {
    return makeStyles({
      badge: {
        color: theme.palette.getContrastText(notificationColor),
        backgroundColor: notificationColor,
      },
    })
  }, [notificationColor])

  return (
    <>
      <IconButton ref={buttonRef} style={{ color: textColor }} onClick={handleOpen}>
        <Badge
          overlap='rectangular'
          className={classes.badge}
          classes={badgeClasses()}
          badgeContent={notificationsCount > 0 ? notificationsCount : null}
          max={100}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        PaperProps={{ className: classes.tab }}
      >
        <NotificationsTab />
      </Popover>
    </>
  )
}

export default memo(Notifications)
