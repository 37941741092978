import { createContext } from 'react'

import { TopicType } from '@tabeeb/enums'

import { TranslationLanguage } from '../../constans'

export default createContext({
  topicId: 0,
  topicType: TopicType.Session,
  userId: null,
  language: TranslationLanguage.Original,
  setTranslationLanguage: () => {},
  setReceiverId: () => {},
})
