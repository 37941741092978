import { useState, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { Menu, MenuItem } from '@material-ui/core'
import {
  getContentForms,
  getFormButtonState,
  getIsExportFormToPdfOptionVisible,
  getIsFormExportInProgress,
} from '@tabeeb/modules/forms/selectors'
import { getExportButtonColor, getIsExportCustomFormCsvEnabled } from '@tabeeb/modules/appConfigState/selectors'
import {
  exportFormToCsv,
  exportFormToCustomCsv,
  exportFormToPdf,
  openExportFormsToCsvDialog,
} from '@tabeeb/modules/forms/actions'
import { FormButtons } from '../../services/enums'
import FormButton from '../FormButton'

const ExportFormButton = ({
  exportButtonColor,
  buttonState: { isVisible, isDisabled },
  contentForms,
  isExportToPdfOtionVisible,
  isExportCustomFormCsvButtonVisible,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()

  const _handleExportButtonClick = (e) => {
    setAnchorEl(e.currentTarget)
  }
  const handleExportButtonMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const _exportFormToCsv = () => {
    if (contentForms.length <= 1) dispatch(exportFormToCsv())
    else dispatch(openExportFormsToCsvDialog())

    handleExportButtonMenuClose()
  }

  const _exportFormToPdf = () => {
    dispatch(exportFormToPdf())
    handleExportButtonMenuClose()
  }

  const handleExportToCustomCsvButtonClick = useCallback(() => {
    dispatch(exportFormToCustomCsv())
    handleExportButtonMenuClose()
  }, [dispatch, handleExportButtonMenuClose])

  const componentProps = {
    onClick: _handleExportButtonClick,
    buttonText: 'Export',
    buttonColor: exportButtonColor,
    isDisabled,
  }

  return (
    isVisible && (
      <>
        <FormButton {...componentProps} />
        <Menu
          id='export-form-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleExportButtonMenuClose}
        >
          {isExportCustomFormCsvButtonVisible && (
            <MenuItem onClick={handleExportToCustomCsvButtonClick}>As Custom CSV</MenuItem>
          )}
          <MenuItem onClick={_exportFormToCsv}>As CSV file</MenuItem>
          {isExportToPdfOtionVisible && <MenuItem onClick={_exportFormToPdf}>To Gallery</MenuItem>}
        </Menu>
      </>
    )
  )
}

ExportFormButton.propTypes = {
  exportButtonColor: PropTypes.string,
  formExportInProgress: PropTypes.bool.isRequired,
  buttonState: PropTypes.shape({
    isVisible: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  }).isRequired,
  contentForms: PropTypes.array.isRequired,
  isExportToPdfOtionVisible: PropTypes.bool.isRequired,
  isExportCustomFormCsvButtonVisible: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  exportButtonColor: getExportButtonColor(state),
  buttonState: getFormButtonState(state, { button: FormButtons.ExportForm }),
  contentForms: getContentForms(state),
  formExportInProgress: getIsFormExportInProgress(state),
  isExportToPdfOtionVisible: getIsExportFormToPdfOptionVisible(state),
  isExportCustomFormCsvButtonVisible: getIsExportCustomFormCsvEnabled(state),
})

export default memo(connect(mapStateToProps)(ExportFormButton))
