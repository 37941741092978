import { all } from 'redux-saga/effects'
import contentsList from './contentsList'
import exportCsv from './exportCsv'
import generalFilter from './generalFilter'
import common from './common'
import dashboards from './dashboards'

function* tenantSessionsSaga() {
  yield all([contentsList(), exportCsv(), generalFilter(), common(), dashboards()])
}

export default tenantSessionsSaga
