import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

import { DoneAll } from '@material-ui/icons'
import { withStyles } from '@material-ui/core'

import { Approved, Rejected, Submitted } from '@tabeeb/modules/shared/icons'
import { AnswerStatusFilterOptions } from '@tabeeb/enums'

import Filter from '../Filter'
import SelectedStatuses from './SelectedStatuses'

import styles from './styles'

const StatusFilter = ({ classes, onChange, value }) => {
  const items = useMemo(
    () => [
      {
        subicon: <Submitted className={classes.icon} />,
        value: AnswerStatusFilterOptions.Submitted,
        title: AnswerStatusFilterOptions.Submitted,
        subtitle: 'All submitted answers',
      },
      {
        subicon: <Approved className={classes.icon} />,
        value: AnswerStatusFilterOptions.Approved,
        title: AnswerStatusFilterOptions.Approved,
        subtitle: 'All approved answers',
      },
      {
        subicon: <Rejected className={classes.icon} />,
        value: AnswerStatusFilterOptions.Rejected,
        title: AnswerStatusFilterOptions.Rejected,
        subtitle: 'All rejected answers',
      },
    ],
    [classes.icon]
  )

  return (
    <Filter
      multiple
      value={value}
      icon={DoneAll}
      label='Status'
      items={items}
      onChange={onChange}
      renderValue={SelectedStatuses}
    />
  )
}

StatusFilter.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default memo(withStyles(styles)(StatusFilter))
