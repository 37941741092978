import { takeLatest, put, all, select } from 'redux-saga/effects'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as galleryActions from '../actions'
import * as towerSideProfileActions from '../../towerSideProfile/actions'

function* galleryAddTowerSideProfile(action) {
  const isTowerSideProfileOpen = yield select((state) => state.towerSideProfile.isTimelineOpen)
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  if (!isTowerSideProfileOpen) yield put(towerSideProfileActions.openTowerSideProfileDialog())

  if (currentPanel !== TabPanel.TowerSideProfile)
    yield put(navbarActions.switchPanel(TabPanel.TowerSideProfile, TabPanelLocation.Bottom))
}

function* addTowerSideProfile() {
  yield all([takeLatest(galleryActions.galleryAddTowerSideProfile, galleryAddTowerSideProfile)])
}

export default addTowerSideProfile
