import { combineReducers } from 'redux'

import usersList from './usersList'
import roles from './roles'
import usersUpload from './usersUpload'
import tenants from './tenants'
import isUsersListLoading from './isUsersListLoading'

export default combineReducers({
  usersList,
  roles,
  tenants,
  usersUpload,
  isUsersListLoading,
})
