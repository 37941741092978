import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as formsActions from '../actions'

import AttachFormsDialog from '../components/AttachFormsDialog'

class AttachFormsDialogContainer extends Component {
  componentDidMount() {
    const {
      actions: { getAvailableFormsRequest, getAllFoldersInFormsRequest },
      isInsideFolder,
    } = this.props
    !isInsideFolder && getAllFoldersInFormsRequest()
    getAvailableFormsRequest()
  }

  componentWillUnmount() {
    const {
      actions: { clearCurrentFolder },
    } = this.props
    clearCurrentFolder()
  }

  render() {
    const { forms, onCloseDialog, isOpen, loadingForms, isAttachDetachProcessing } = this.props
    const props = {
      forms,
      isOpen,
      onCloseDialog,
      loadingForms,
      isAttachDetachProcessing,
    }
    return <AttachFormsDialog {...props} />
  }
}

AttachFormsDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  forms: PropTypes.array.isRequired,
  loadingForms: PropTypes.bool.isRequired,
  isAttachDetachProcessing: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    getAvailableFormsRequest: PropTypes.func.isRequired,
    getAllFoldersInFormsRequest: PropTypes.func.isRequired,
  }).isRequired,
  isInsideFolder: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  forms: state.forms.availableForms,
  loadingForms: state.forms.loadingAvailableForms,
  isAttachDetachProcessing: state.forms.isAttachDetachProcessing,
  isInsideFolder: !!state.forms.currentFolder.id,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(formsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AttachFormsDialogContainer)
