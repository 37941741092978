import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.addInputError](state, { payload }) {
      return [...state, payload]
    },
    [rawActions.removeInputError](state, { payload }) {
      return state.filter((error) => error.Id !== payload.Id)
    },
    [combineActions(rawActions.deleteTabOption, rawActions.deleteControlOption)](
      state,
      { payload: { control, option } }
    ) {
      const errorsToRemove = state.filter(
        (error) =>
          error.ControlType === control.FormItemType &&
          error.ControlId === control.TempId &&
          error.OptionId === option.Id
      )

      const newState = state.filter((error) => !errorsToRemove.some((err) => error.Id === err.Id))

      return newState
    },
    [combineActions(rawActions.deleteTabPanel, rawActions.deleteControl)](state, { payload }) {
      const control = payload

      const errorsToRemove = state.filter(
        (error) => error.ControlType === control.FormItemType && error.ControlId === control.TempId
      )

      const newState = state.filter((error) => !errorsToRemove.some((err) => error.Id === err.Id))

      return newState
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
