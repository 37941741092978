import { createSelector } from 'reselect'

import { ContentReviewStatus, ContentSharingType, SessionPermission, UserRole } from '@tabeeb/enums'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getContentDescription, getContentId, getContentTenantId } from '@tabeeb/shared/content/selectors'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'

export const getReviewsList = (state) => state.contentReviews.reviewsList
export const getFormsSelectedForReview = (state) => state.shareSessionPagesList.shareByFormDialogState.selectedForms
export const getUsersList = (state) => state.users.users
export const getTenantsListState = (state) => state.contentReviews.tenantsListState
export const getContentDescriptions = (state) => state.contentReviews.contentDescriptions
export const getContentReviewStatus = (state) => state.contentReviews.contentReviewStatus
export const getCurrentForm = (state) => state.forms.currentForm

export const getHasChangeAnswerStatusPermission = (state) =>
  hasSessionPermission(state, SessionPermission.RejectApprove)

export const getMergeReviewWithCurrentForm = createSelector(
  [getReviewsList, getContentId, getCurrentForm],
  (reviewsList, contentId, currentForm) => {
    return reviewsList.find(
      (r) =>
        r.Type === ContentSharingType.MergeForReview &&
        r.TargetContentId === contentId &&
        r.FormsIds.includes(currentForm?.FormId)
    )
  }
)

export const getIsCurrentUserReviewer = createSelector([getUsersList, getCurrentUserId], (usersList, currentUserId) => {
  const currentUser = usersList.find((user) => user.id === currentUserId)
  return currentUser?.role === UserRole.Reviewer
})

export const getCurrentUserReview = createSelector(
  [
    getReviewsList,
    getCurrentUserId,
    getHasChangeAnswerStatusPermission,
    getMergeReviewWithCurrentForm,
    getIsCurrentUserReviewer,
  ],
  (reviewsList, currentUserId, canChangeAnswerStatuses, currentFormReview, isReviewerRole) => {
    if (isReviewerRole) {
      const review = reviewsList.find(
        (r) =>
          r.Type === ContentSharingType.ForReview &&
          (r.ReviewerId === currentUserId || r.ReviewersIds.includes(currentUserId))
      )

      return review
    }

    if (currentFormReview && canChangeAnswerStatuses) {
      return currentFormReview
    }

    return null
  }
)

export const getReviewByUserId = createSelector([getReviewsList, (_, { userId }) => userId], (reviewsList, userId) => {
  return reviewsList.find((r) => r.ReviewerId === userId || r.ReviewersIds.includes(userId))
})

export const getSelectedFormsReview = createSelector(
  [getReviewsList, getFormsSelectedForReview],
  (reviewsList, selectedForms) => {
    if (!selectedForms) return null

    const reviewWithSelectedForms = reviewsList.find(
      (r) =>
        r.ReviewStatus === ContentReviewStatus.FeedbackSent &&
        r.ContentFormsIds.some((formId) => selectedForms.some((selectedForm) => selectedForm.ContentFormId === formId))
    )

    return reviewWithSelectedForms
  }
)

export const getSelectedFormsReviewer = createSelector([getSelectedFormsReview, getUsersList], (review, users) => {
  if (!review) return null

  const userId = review.ReviewerId
  const user = users.find((u) => u.id === userId)

  const reviewer = {
    Id: userId,
    Name: review.ReviewerName,
    AvatarUrl: user?.avatarUrl,
    IsPredefined: true,
  }

  return reviewer
})

export const getSelectedFormsReviewerTenant = createSelector([getSelectedFormsReview], (review) => {
  if (!review) return null

  const tenant = {
    Id: review.ReviewerTenantId,
    Name: review.ReviewerTenantName,
    IsPredefined: true,
  }

  return tenant
})

export const getSelectedFormsReviewerContent = createSelector([getSelectedFormsReview], (review) => {
  if (!review) return null

  const content = {
    Id: review.TargetContentId,
    Description: review.TargetContentName,
    IsPredefined: true,
  }

  return content
})

export const getReviewersUploadedPagesIds = createSelector([getReviewsList], (reviewsList) => {
  let reviewersPages = []

  reviewsList.forEach((review) => {
    if (review.ReviewStatus === ContentReviewStatus.Active && review.UploadedPagesIds.length > 0) {
      reviewersPages = [...reviewersPages, ...review.UploadedPagesIds]
    }
  })

  return reviewersPages
})

export const getTenantsListForReview = createSelector([getTenantsListState], (listState) => {
  return listState.list
})

export const getCanRenameContentDescriptionForTenant = createSelector(
  [getTenantsListForReview, getContentTenantId, (_, { tenantId }) => tenantId],
  (tenantList, contentTenantId, selectedTenantId) => {
    if (!selectedTenantId || contentTenantId === selectedTenantId) return false

    const selectedTenant = tenantList.find((t) => t.Id === selectedTenantId)
    if (!selectedTenant) return false

    const isVisible = selectedTenant.IsNamingConventionEnabled && selectedTenant.IsNamingConventionForReviewEnabled
    return isVisible
  }
)

export const getContentDescriptionForTenant = createSelector(
  [getContentDescriptions, getContentDescription, (_, { tenantId }) => tenantId],
  (contentDescriptionsInOtherTenants, descriptionInOriginalTenant, tenantId) => {
    if (!tenantId) return false

    const descriptionInOtherTenant = contentDescriptionsInOtherTenants.find((d) => d.TenantId === tenantId)

    if (!descriptionInOtherTenant) {
      return descriptionInOriginalTenant
    }

    return descriptionInOtherTenant.Description
  }
)
