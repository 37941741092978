import { useMemo } from 'react'
import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'
import { getPowerBIDevelopers } from '@tabeeb/modules/account/actions'

const usePowerBIDevelopers = (search) => {
  const requestParams = useMemo(() => ({ search }), [search])

  const { status: fetchStatus, response: users } = useApiRequest({
    request: getPowerBIDevelopers.request,
    payload: requestParams,
  })

  return { fetchStatus, users }
}

export default usePowerBIDevelopers
