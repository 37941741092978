import PropTypes from 'prop-types'

import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'

import './styles.less'

const UsersFilter = ({ selectedUserId, users, handleSelectUserFilter }) => {
  const selectedUser = users.find((user) => user.id === selectedUserId)

  return (
    <div className='activity-filters-item'>
      <FormControl className='activity-filters-item-control'>
        <InputLabel>User</InputLabel>
        <Select
          value={selectedUserId}
          onChange={handleSelectUserFilter}
          title={selectedUser ? selectedUser.displayName : null}
          MenuProps={{ id: 'activity-filters-menu' }}
        >
          <MenuItem value='all'>Everyone</MenuItem>
          {users &&
            users.map((user) => (
              <MenuItem value={user.id} key={user.id}>
                <span className='activity-filters-menu-text' title={user.displayName}>
                  {user.displayName}
                </span>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

UsersFilter.propTypes = {
  users: PropTypes.array.isRequired,
  selectedUserId: PropTypes.any.isRequired,
  handleSelectUserFilter: PropTypes.func.isRequired,
}

export default UsersFilter
