import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    divider: {
      width: '100%',
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1.25),
    },
    flexGrid: {
      display: 'flex',
    },
  }
})
