import { handleActions } from 'redux-actions'

import * as whiteboardActions from '../actions'

const defaultValue = false

export default handleActions(
  {
    [whiteboardActions.openMobileJoinCallDialog](state, action) {
      return true
    },
    [whiteboardActions.closeMobileJoinCallDialog](state, action) {
      return false
    },
    [whiteboardActions.resetWhiteboardState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
