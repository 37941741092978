import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'
import { TenantSessionsMode } from '../../../Enums'

const defaultState = TenantSessionsMode.Actual

export default handleActions(
  {
    [rawActions.selectTenantSessionsMode](state, action) {
      return action.payload
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
