import { memo, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { ClickAwayListener, Fade, List, ListItem, Paper, Popper } from '@material-ui/core'
import { LinearProgress } from '@mui/material'

import { callApiAsync } from '@tabeeb/shared/utils/requests'

import LoadingPlaceholder from './LoadingPlaceholder'
import SearchTextBox from './SearchTextBox'

import useStyles from './styles'

const REQUEST_DELAY = 500

const InlineSearch = ({
  minSearchLength,
  placeholderItemsCount,
  onSearch,
  onSelect,
  onClose,
  components: { info: Info, skeleton: Skeleton, placeholder: Placeholder },
  InputProps,
  isMultiSelect,
  infinite,
  pageSize,
}) => {
  const inputRef = useRef()
  const innerInputRef = useRef()
  const paperRef = useRef()
  const classes = useStyles()

  const [items, setItems] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [anyItemsLoadedLastTime, setAnyItemsLoadedLastTime] = useState(false)
  const pageNumber = useRef(1)

  const searchItems = useCallback(
    _.debounce((newSearchText) => {
      if ((newSearchText || '').length < minSearchLength) {
        return
      }

      const resetItems = !infinite || (infinite && pageNumber.current === 1)

      if (resetItems) {
        setItems([])
      }
      setLoading(true)
      setOpen(true)
      callApiAsync(onSearch({ searchText: newSearchText, pageNumber: pageNumber.current, pageSize }))
        .then(({ response }) => {
          const loadedItems = _.take(response.data, pageSize)

          setItems(resetItems ? loadedItems : (prevItems) => [...prevItems, ...loadedItems])
          setAnyItemsLoadedLastTime(loadedItems.length > 0)
          if (infinite) {
            pageNumber.current += 1
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false)
        })
    }, REQUEST_DELAY),
    [onSearch, pageNumber]
  )

  useEffect(() => {
    innerInputRef.current.focus()
  }, [])

  useEffect(() => {
    if (open) {
      searchItems(searchText)
    }
  }, [searchItems])

  const handleClose = useCallback(() => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleSelect = (item) => () => {
    if (!isMultiSelect) {
      handleClose()
      setSearchText('')
    }
    onSelect(item)
  }

  const handleReset = useCallback(() => {
    setItems([])
  }, [])

  const onFocus = () => {
    if (!open) {
      searchItems(searchText)
    }
  }

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = paperRef.current

    if (Math.floor(Math.abs(scrollHeight - clientHeight - scrollTop)) <= 1) {
      if (infinite && anyItemsLoadedLastTime && !loading) {
        searchItems(searchText)
      }
    }
  }

  const onSearchTextChanged = useCallback(
    (event) => {
      setSearchText(event.target.value)
      pageNumber.current = 1

      const isFocused = document.activeElement === innerInputRef.current
      if (isFocused) {
        searchItems(event.target.value)
      }
    },
    [searchItems]
  )

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <SearchTextBox
          ref={inputRef}
          inputRef={innerInputRef}
          value={searchText}
          onFocus={onFocus}
          onChange={onSearchTextChanged}
          InputProps={InputProps}
        />

        <Popper className={classes.popper} anchorEl={inputRef.current} open={open} placement='bottom' transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} unmountOnExit onExited={handleReset}>
              <Paper
                ref={paperRef}
                onScroll={handleScroll}
                className={classes.container}
                style={{ width: inputRef.current?.offsetWidth }}
              >
                <List disablePadding>
                  {items.map((item) => (
                    <ListItem key={item.Id} button onClick={handleSelect(item)}>
                      <Info item={item} />
                    </ListItem>
                  ))}
                </List>
                {loading && pageNumber.current === 1 && (
                  <LoadingPlaceholder items={placeholderItemsCount} component={Skeleton} />
                )}
                {loading && pageNumber.current > 1 && (
                  <LinearProgress sx={{ position: 'sticky', bottom: 0, left: 0, right: 0 }} />
                )}
                {!loading && items.length === 0 && <Placeholder />}
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

InlineSearch.defaultProps = {
  minSearchLength: 0,
  placeholderItemsCount: 4,
  isMultiSelect: false,
  infinite: false,
  pageSize: 50,
}

InlineSearch.propTypes = {
  components: PropTypes.shape({
    info: PropTypes.elementType.isRequired,
    skeleton: PropTypes.elementType.isRequired,
    placeholder: PropTypes.elementType.isRequired,
  }).isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  minSearchLength: PropTypes.number,
  placeholderItemsCount: PropTypes.number,
  InputProps: PropTypes.object,
  isMultiSelect: PropTypes.bool,
  infinite: PropTypes.bool,
  pageSize: PropTypes.number,
}

export default memo(InlineSearch)
