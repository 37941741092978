import { put, takeLatest, take, select, call, all, race } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import _ from 'lodash'

import routes from '@tabeeb/routes'

import * as formsActions from '@tabeeb/modules/forms/actions'
import * as formsPageActions from '@tabeeb/modules/formsPage/actions'
import * as notificationActions from '@tabeeb/modules/notification/actions'
import * as advancedFormSettingsActions from '@tabeeb/modules/formSettings/actions'

import { getSubdomain } from '@tabeeb/modules/appConfigState/selectors'
import * as accountSelectors from '@tabeeb/modules/account/selectors'
import * as formsPageSelectors from '@tabeeb/modules/formsPage/selectors'
import * as advancedFormSettingsSelectors from '@tabeeb/modules/formSettings/selectors'

import { getIsTextControl } from '@tabeeb/modules/createFormPage/helpers/controlTypesHelper'
import AzureFileUploader from '@tabeeb/services/azureFileUploader'
import { generateId } from '@tabeeb/modules/presentationToolbar/services/snapshotService'

import { ConditionTypes, FormItemType, ContentType, ConditionTriggerTypes, FetchStatus } from '@tabeeb/enums'

import formControlConditions from './formControlConditions'

import { createControl } from '../services/createControl'
import { createTab } from '../services/createTab'
import { createCounter } from '../services/createCounter'
import { createAction } from '../services/createAction'

import formControlTags from './formControlTags'

import * as rawActions from '../actions'
import { PageMode } from '../constants'
import { selectPageMode, selectAdvancedFormSettings } from '../selectors'

function* createFormItem(action) {
  const { controlsList, tabsList } = yield select((state) => state.createFormPage.createForm)
  const { mode, formToEdit, availableAIObjects, selectedAIObjectsIds } = yield select((state) => state.createFormPage)
  const { aiObjects } = yield select((state) => state.createFormPage)

  const control = action.payload

  switch (control) {
    case 'Tabs':
      const newTab = createTab(tabsList, mode, formToEdit.FormId, null)
      yield put(rawActions.addTabPanel(newTab))
      break
    case 'Counter':
      const newCounter = createCounter(control, controlsList, mode, formToEdit.FormId, null, null, null)
      if (aiObjects.length === 0) yield put(rawActions.getAiClassesRequest({ control: newCounter }))
      else {
        const objId = availableAIObjects[0].Id
        newCounter.AIObjectId = objId
        selectedAIObjectsIds.push(objId)
        yield put(rawActions.setSelectedAiObjectsIds(selectedAIObjectsIds))

        const newAvailableAIObjects = availableAIObjects.filter((obj) => !selectedAIObjectsIds.includes(obj.Id))
        yield put(rawActions.setAvailableAiObjects(newAvailableAIObjects))
        yield put(rawActions.addControl(newCounter))
      }
      break
    case 'Action':
      const newAction = createAction(control, controlsList, mode, formToEdit.FormId, null, null, null)
      yield put(rawActions.addControl(newAction))
      break
    default:
      const newControl = createControl(control, controlsList, mode, formToEdit.FormId, null, null)
      yield put(rawActions.addControl(newControl))
      break
  }
}

function* updateControlsOrder(action) {
  const controlsList = yield select((state) => state.createFormPage.createForm.controlsList)

  controlsList &&
    controlsList.map((control, index) => {
      control.Order = index
      return control
    })

  yield put(rawActions.updateControlsList(controlsList))
}

function* updateTabsOrder(action) {
  const tabsList = yield select((state) => state.createFormPage.createForm.tabsList)

  yield put(rawActions.updateTabsList(tabsList))
}

function* getFormIconUrl(action) {
  const file = action.payload
  const responseUrl = action.response.data.url

  const { tabsList, controlsList, formName, formViewMode, selectedNLPModelId, isSignatureEnabled } = yield select(
    (state) => state.createFormPage.createForm
  )

  const mode = yield select((state) => state.createFormPage.mode)
  const formToEdit = yield select((state) => state.createFormPage.formToEdit)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const cachedFolderId = yield select(formsPageSelectors.getCachedFolderId)
  const {
    fetchingStatus: permissionsFetchingStatus,
    data: {
      permissions: {
        byContentRoles: { sync: syncPermissions, override: permissionsByContentRoles, editableContentRoles },
      },
    },
  } = yield select(selectAdvancedFormSettings)

  const permissionPatch = _.values(_.pick(permissionsByContentRoles, editableContentRoles))

  const Controls = []
  const Counters = []
  const Actions = []

  controlsList.forEach((control) => {
    switch (control.FormItemType) {
      case FormItemType.Counter:
        if (control.CounterLimit === 0) control.CounterLimit = 1
        Counters.push(control)
        break
      case FormItemType.Control:
        if (control.Info) {
          if (!control.Info.Url) {
            control.Info.Url = null
            control.Info.Type = ContentType.Unknown
            control.Info.FileName = null
            control.Info.ThumbnailUrl = null
            control.Info.EncodedUrl = null
          }
        }
        Controls.push(control)
        break
      case FormItemType.Action:
        Actions.push(control)
        break
    }
  })

  const uploader = new AzureFileUploader(file, responseUrl, {})
  yield call(uploader.upload)
  const iconUrl = responseUrl.substring(0, responseUrl.lastIndexOf('?'))

  if (mode === 'create') {
    const createdForm = {
      Tabspanels: tabsList,
      Controls,
      Counters,
      Actions,
      Name: formName,
      IconUrl: iconUrl,
      IsPublished: false,
      UserId: currentUserId,
      FormViewMode: formViewMode,
      NLPModelId: selectedNLPModelId,
      FolderId: cachedFolderId,
      IsSignatureEnabled: isSignatureEnabled,
      GrantedPermissions: permissionsFetchingStatus === FetchStatus.Loaded && !syncPermissions ? permissionPatch : null,
    }
    yield put(rawActions.createFormRequest(createdForm))
  } else {
    formToEdit.Controls = Controls
    formToEdit.Counters = Counters
    formToEdit.Actions = Actions
    formToEdit.Tabspanels = tabsList
    formToEdit.Name = formName
    formToEdit.IconUrl = iconUrl
    formToEdit.FormViewMode = formViewMode
    formToEdit.NLPModelId = selectedNLPModelId
    formToEdit.IsSignatureEnabled = isSignatureEnabled

    yield put(rawActions.updateFormRequest(formToEdit))

    if (permissionsFetchingStatus === FetchStatus.Loaded) {
      if (syncPermissions) {
        yield put(
          advancedFormSettingsActions.enableFormPermissionsSyncByForm.request({
            formId: formToEdit.Id,
          })
        )
      } else {
        yield put(
          advancedFormSettingsActions.updateFormPermissionsByForm.request({
            permissions: permissionPatch,
            formId: formToEdit.Id,
          })
        )
      }
    }
  }
}

function* createForm(action) {
  yield put(rawActions.unsetFormIsLoading())
  yield returnToFolder()
}

function* createFormFailed(action) {
  yield put(
    notificationActions.onAddErrorNotification({
      message: action.response.data.Error.Details || action.response.data.Error.Message,
    })
  )
}

function* returnToFolder(action) {
  const cachedFolder = yield select(formsPageSelectors.getCachedFolder)
  cachedFolder?.FolderId ? yield put(formsPageActions.openFormFolder(cachedFolder)) : yield put(push(routes.forms))
}

function* updateForm(action) {
  const form = action.payload
  yield put(rawActions.updateFormControlsRequest(form))
}

function* updateFormControls(action) {
  yield put(rawActions.unsetFormIsLoading())
  yield returnToFolder()
}

function* setFormToEdit(action) {
  const form = action.response.data

  yield put(rawActions.setFormToEdit(form))
  yield put(rawActions.saveFormName(form.Name))
  yield put(rawActions.setFormViewMode(form.FormViewMode))
  yield put(rawActions.setFormSignatureState(form.IsSignatureEnabled))

  const aiObjects = yield select((state) => state.createFormPage.aiObjects)
  if (aiObjects.length === 0) yield put(rawActions.getAiClassesRequest({}))

  const formCounters = form.Counters.map((counter) => {
    counter.FormItemType = FormItemType.Counter
    return counter
  })

  const formControls = form.Controls.map((control) => {
    control.FormItemType = FormItemType.Control
    control.Conditions.forEach((condition) => {
      condition.TempId = condition.Id
    })
    return control
  })

  const formActions = form.Actions.map((control) => {
    control.FormItemType = FormItemType.Action
    return control
  })

  const controls = [...formControls, ...formCounters, ...formActions]
  const tabs = form.Tabspanels

  const selectedAIObjectsIds = []
  let isFoundAttachFormCondition = false

  const actions = []

  let updatedControls =
    controls &&
    controls.map((control) => {
      if (control.Options && control.Options.length > 0) {
        control.Options.map((option) => {
          if (!isFoundAttachFormCondition) {
            const attachFormCondition = option.Conditions.find(
              (condition) => condition.Type === ConditionTypes.AttachForm
            )
            if (attachFormCondition) {
              isFoundAttachFormCondition = true
              actions.push(put(formsActions.getAvailableFormsRequest({ control, option })))
            }
          }

          if (option.Conditions.length === 0) {
            option.Conditions = null
          }
        })
      } else if (control.FormItemType !== FormItemType.Action) {
        if (control.FormItemType === FormItemType.Counter) {
          selectedAIObjectsIds.push(control.AIObjectId)
        }

        if (!isFoundAttachFormCondition) {
          const attachFormCondition = control.Conditions.find(
            (condition) => condition.Type === ConditionTypes.AttachForm
          )

          if (attachFormCondition) {
            isFoundAttachFormCondition = true
            actions.push(put(formsActions.getAvailableFormsRequest({ control })))
          }
        }

        if (control.Conditions.length === 0) {
          control.Conditions = null
        }
      }

      control.TempId = control.Order
      return control
    })

  yield all(actions)

  tabs &&
    tabs.map((tab) => {
      tab.FormItemType = FormItemType.Tab
      tab.TempId = tab.Order
    })

  if (selectedAIObjectsIds.length > 0) {
    yield put(rawActions.setSelectedAiObjectsIds(selectedAIObjectsIds))
  }

  updatedControls = updatedControls.sort((a, b) => a.Order - b.Order)
  yield put(rawActions.updateControlsList(updatedControls))

  const updatedTabs = tabs.sort((a, b) => a.Order - b.Order)
  yield put(rawActions.updateTabsList(updatedTabs))
}

function* addInfoImage(action) {
  const uploadedFile = action.payload[0]
  const { controlId } = uploadedFile
  yield addInfoContent(uploadedFile, ContentType.Image, controlId)
}

function* addInfoVideo(action) {
  const uploadedFile = action.payload[0]
  const { controlId } = uploadedFile
  yield addInfoContent(uploadedFile, ContentType.UnencodedVideo, controlId)
}

function* addInfoContent(uploadedFile, contentType, controlId) {
  const controlsList = yield select((state) => state.createFormPage.createForm.controlsList)
  const currentControl = controlsList.find((c) => c.TempId === controlId)
  const url = uploadedFile.url.substring(0, uploadedFile.url.lastIndexOf('?'))
  const fileName = uploadedFile.file.name

  const infoContent = {
    Type: contentType,
    Url: url,
    FileName: fileName,
  }
  yield put(rawActions.removeLoadingFileControlId(controlId))
  yield put(rawActions.setInfoContent({ control: currentControl, infoContent }))
}

function* setLoadingFileControls(action) {
  const { controlId } = action.payload
  yield put(rawActions.addLoadingFileControlId(controlId))
}

function* unsetFormIsLoading() {
  yield put(rawActions.unsetFormIsLoading())
}

function* onGetAllAiObjectsSuccess(action) {
  const { data } = action.response
  const { control } = action.payload

  let selectedAIObjectsIds = yield select((state) => state.createFormPage.selectedAIObjectsIds)
  let availableAIObjects = []

  if (!selectedAIObjectsIds) {
    availableAIObjects = data
    selectedAIObjectsIds = []
  } else {
    availableAIObjects = data.filter((obj) => !selectedAIObjectsIds.includes(obj.Id))
  }

  if (control && availableAIObjects.length > 0) {
    const objId = availableAIObjects[0].Id

    control.AIObjectId = objId
    selectedAIObjectsIds.push(objId)
    availableAIObjects = availableAIObjects.filter((obj) => !selectedAIObjectsIds.includes(obj.Id))

    yield put(rawActions.addControl(control))
    yield put(rawActions.setAvailableAiObjects(availableAIObjects))
  } else if (availableAIObjects.length > 0) {
    yield put(rawActions.setAvailableAiObjects(availableAIObjects))
  }

  yield put(rawActions.setSelectedAiObjectsIds(selectedAIObjectsIds))
}

function* handleGetInitFormSettings({ payload: { formId } }) {
  const pageMode = yield select(selectPageMode)

  const { fetchingStatus: currentFetchingStatus } = yield select(selectAdvancedFormSettings)

  if (currentFetchingStatus === FetchStatus.Loaded) {
    return
  }

  if (pageMode === PageMode.create) {
    yield put(advancedFormSettingsActions.getFormPermissionsByNewForm.request())
  } else {
    yield put(advancedFormSettingsActions.getFormPermissionsByForm.request({ formId }))
  }

  yield race({
    success: take([
      advancedFormSettingsActions.getFormPermissionsByNewForm.success,
      advancedFormSettingsActions.getFormPermissionsByForm.success,
    ]),
    failed: take([
      advancedFormSettingsActions.getFormPermissionsByNewForm.failed,
      advancedFormSettingsActions.getFormPermissionsByForm.failed,
    ]),
  })

  const settings = yield select(advancedFormSettingsSelectors.selectAdvancedFormSettings)
  yield put(rawActions.setInitFormSettings(settings))
}

function* createFormPageSaga() {
  yield all([
    takeLatest(rawActions.createFormItem, createFormItem),
    takeLatest(rawActions.returnToFolder, returnToFolder),
    takeLatest(rawActions.getFormIconUrlSuccess, getFormIconUrl),
    takeLatest(rawActions.deleteControl, updateControlsOrder),
    takeLatest(rawActions.deleteTabPanel, updateTabsOrder),
    takeLatest(rawActions.createFormSuccess, createForm),
    takeLatest(rawActions.createFormFailed, createFormFailed),
    takeLatest(rawActions.updateFormSuccess, updateForm),
    takeLatest(rawActions.updateFormControlsSuccess, updateFormControls),
    takeLatest(rawActions.getFormToEditSuccess, setFormToEdit),
    takeLatest(rawActions.addInfoImageUploadFilesSuccess, addInfoImage),
    takeLatest(rawActions.addInfoVideoUploadFilesSuccess, addInfoVideo),
    takeLatest(rawActions.getAiClassesSuccess, onGetAllAiObjectsSuccess),
    takeLatest([rawActions.addInfoVideoUploadFiles, rawActions.addInfoImageUploadFiles], setLoadingFileControls),
    takeLatest(rawActions.getInitFormSettings, handleGetInitFormSettings),
    takeLatest(
      [
        rawActions.getFormIconUrlFailed,
        rawActions.createFormFailed,
        rawActions.updateFormFailed,
        rawActions.updateFormControlsFailed,
      ],
      unsetFormIsLoading
    ),
    formControlTags(),
    formControlConditions(),
  ])
}

export default createFormPageSaga
