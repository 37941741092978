import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Typography,
} from '@mui/material'

import { FolderUserRole } from '@tabeeb/enums'
import { UserInfo, UserInfoSkeleton, withModernTheme } from '@tabeeb/uikit'

import UsersMultiSelect from '@tabeeb/modules/invites/components/UsersMultiSelect'

import FolderUserRoleSelect from '@tabeeb/modules/../users/components/FolderUserRoleSelect'
import FolderUserRoleSelectField from '@tabeeb/modules/../users/components/FolderUserRoleSelectField'

import { useFolderUsers } from '@tabeeb/modules/sessionsPage/hooks'
import { getCanAddFolderUsers, getCanEditFolderUsers } from '@tabeeb/modules/sessionsPage/selectors/folderActions'

const schema = Yup.object().shape({
  Role: Yup.number().strict().oneOf(Object.values(FolderUserRole)).required(),
  Users: Yup.array()
    .strict()
    .of(
      Yup.object({
        Id: Yup.number().strict().required(),
      }).required()
    )
    .min(1)
    .required(),
})

const initial = {
  Role: FolderUserRole.User,
  Users: [],
}

const ManageFolderUsersDialog = ({ open, folder, onClose }) => {
  const owner = {
    Id: folder.UserId,
    AvatarUrl: folder.OwnerAvatarUrl,
    Email: folder.OwnerEmail,
    Name: folder.OwnerName,
  }

  const { loading, users, onChangeRole, onAdd, onRemove } = useFolderUsers({ folderId: folder?.Id })

  const canAddUsers = useSelector((state) => getCanAddFolderUsers(state, folder))
  const canEditUsers = useSelector((state) => getCanEditFolderUsers(state, folder))

  const onSubmit = async ({ Users, Role }, { resetForm }) => {
    await onAdd({ FolderId: folder.Id, Users, Role })
    resetForm()
  }

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <DialogTitle>Manage folder users</DialogTitle>
      <DialogContent>
        <Box display='flex' alignItems='center' mb={2}>
          <UserInfo item={owner} />
          <Typography variant='body1' fontWeight={500}>
            Owner
          </Typography>
        </Box>
        {canAddUsers && (
          <Formik
            initialValues={initial}
            validateOnMount
            validationSchema={schema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form autoComplete='off'>
                <Box display='flex' alignItems='center'>
                  <Field
                    name='Users'
                    strict
                    disabled={isSubmitting}
                    component={UsersMultiSelect}
                    endAdornment={<Field name='Role' disabled={isSubmitting} component={FolderUserRoleSelectField} />}
                  />

                  <Box ml={1.5}>
                    <Button
                      disabled={isSubmitting || !dirty || !isValid}
                      color='primary'
                      variant='contained'
                      type='submit'
                    >
                      Add users
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        )}
        <List disablePadding sx={{ mt: 1 }}>
          {loading && (
            <>
              <UserInfoSkeleton />
              <UserInfoSkeleton />
              <UserInfoSkeleton />
            </>
          )}
          {!loading &&
            users.map((user) =>
              user.Id === folder.UserId ? null : (
                <ListItem key={user.Id} disableGutters dense>
                  <UserInfo item={user} />

                  {canEditUsers && (
                    <>
                      <Box>
                        <FolderUserRoleSelect
                          role={user.Role}
                          userId={user.Id}
                          onChange={(e) => onChangeRole(user, e.target.value)}
                        />
                      </Box>
                      <Box ml={1}>
                        <Button size='small' color='primary' variant='contained' onClick={() => onRemove(user)}>
                          Remove
                        </Button>
                      </Box>
                    </>
                  )}
                </ListItem>
              )
            )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

ManageFolderUsersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  folder: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    UserId: PropTypes.number.isRequired,
    OwnerAvatarUrl: PropTypes.string,
    OwnerEmail: PropTypes.string.isRequired,
    OwnerName: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(withModernTheme(ManageFolderUsersDialog))
