import { Button, styled } from '@material-ui/core'
import { DefaultButton, DefaultTypography } from '@tabeeb/uikit'

export const CallControlsContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 10,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: theme.palette.background.primary,
}))

const ControlsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
}))

export const ControlsHeaderContainer = styled(ControlsContainer)(({ theme }) => ({
  height: '62px',
  background: 'rgba(0, 0, 0, 0.64)',
  padding: theme.spacing(),
  justifyContent: 'space-between',

  position: 'relative',
  transition: 'top 0.2s ease 0s',
  top: 0,
  zIndex: 100,

  '&.hiddenControls': {
    top: '-62px',
  },
}))

export const HeaderCallInfoContainer = styled(ControlsContainer)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.secondary.main,
  flexDirection: 'column',
  justifyContent: 'space-beetween',
  width: '60%',
}))

export const CallInfoTypography = styled(DefaultTypography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}))

export const RecordingContainer = styled('div')(({ theme }) => ({
  maxWidth: 60,
  maxHeight: 20,
}))

export const HeaderButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing() / 2,
  justifyContent: 'space-between',
  gap: '4px',
}))

export const HeaderButton = styled(DefaultButton)(({ theme }) => ({
  background: theme.palette.background.semitransparent,
  color: theme.palette.text.contrast,

  '&.active': {
    background: theme.palette.primary.main,
  },
}))

export const ExpandButton = styled(DefaultButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  color: theme.palette.text.dark,
  backgroundColor: theme.palette.secondary.light,

  '&.MuiButton-root:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}))

export const UserPin = styled(DefaultButton)(({ theme }) => ({
  background: theme.palette.background.semitransparent,
  color: theme.palette.secondary.light,
  pointerEvents: 'none',
}))

export const ControlsFooterContainer = styled(ControlsContainer)(({ theme }) => ({
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',

  position: 'relative',
  transition: 'bottom 0.2s ease 0s',
  bottom: 0,
  zIndex: 100,

  '&.hiddenControls': {
    bottom: '-95px',
  },
}))

export const UpperFooterContainer = styled(ControlsContainer)(({ theme }) => ({
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  flexGrow: 1,
  width: '100%',
  paddingBottom: theme.spacing(2),

  position: 'relative',
  transition: 'bottom 0.2s ease 0s',
  bottom: 0,
}))

export const FooterButtonsContainer = styled(ControlsContainer)(({ theme }) => ({
  height: '95px',
  background: 'rgba(0, 0, 0, 0.64)',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: '0 5%',

  position: 'relative',
  transition: 'bottom 0.2s ease 0s',
  bottom: 0,
}))

export const FooterButton = styled(Button)(({ theme }) => ({
  height: '48px',
  minWidth: '48px',
  width: '48px',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.contrast,

  '&.Mui-disabled': {
    color: theme.palette.text.mobile.disabled,
  },
}))

export const EndCallButton = styled(FooterButton)(({ theme }) => ({
  backgroundColor: '#ff5555',
  borderRadius: theme.shape.borderRadius * 2,
}))

export const UpperFooterLeftPanel = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(2),
}))
