import { handleActions, combineActions } from 'redux-actions'
import { FetchStatus } from '@tabeeb/enums'

import { resetContentState } from '@tabeeb/modules/shared/content/actions'

import { getAvailableReminderReceivers } from '../actions'

const defaultValue = {
  fetchingStatus: FetchStatus.Idle,
  data: [],
}

export default handleActions(
  {
    [getAvailableReminderReceivers.request](state) {
      return {
        ...state,
        fetchingStatus: FetchStatus.Loading,
      }
    },
    [getAvailableReminderReceivers.success](state, { response: { data: usersIds } }) {
      return {
        ...state,
        fetchingStatus: FetchStatus.Loaded,
        data: usersIds,
      }
    },
    [combineActions(getAvailableReminderReceivers.resetState, resetContentState)]() {
      return defaultValue
    },
  },
  defaultValue
)
