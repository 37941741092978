import { handleActions, combineActions } from 'redux-actions'

import { getFolderPermissionsSuccess } from '@tabeeb/modules/permissions/actions'

import * as rawActions from '../actions'

const defaultValue = []

const removeFolderById = (folders, folderId) => {
  const index = folders.findIndex((folder) => folder.Id === folderId)
  if (index === -1) {
    return folders
  }

  return [...folders.slice(0, index), ...folders.slice(index + 1)]
}

export default handleActions(
  {
    [combineActions(rawActions.getFolders.success, rawActions.getDeletedFolders.success)](state, action) {
      return [...action.response.data]
    },
    [rawActions.createFolder.success](state, action) {
      return [action.response.data, ...state]
    },
    [combineActions(
      rawActions.deleteFolderPermanently.request,
      rawActions.restoreFolder.request,
      rawActions.deleteFolder.request
    )](state, action) {
      const folderId = action.payload
      return removeFolderById(state, folderId)
    },
    [rawActions.leaveFolder.success](state, action) {
      const { FolderId, isCurrentUser } = action.payload
      if (!isCurrentUser) {
        return state
      }

      return removeFolderById(state, FolderId)
    },
    [rawActions.updateFolder.success](state, action) {
      const { Id, Name } = action.payload
      return state.map((folder) => (folder.Id === Id ? { ...folder, Name } : folder))
    },
    [rawActions.addUsersToFolder.success](state, action) {
      const { FolderId } = action.payload
      return state.map((folder) => (folder.Id === FolderId ? { ...folder, IsFolderShared: true } : folder))
    },
    [getFolderPermissionsSuccess](state, action) {
      const { folderId } = action.payload
      const { Permissions } = action.response.data

      return state.map((folder) =>
        folder.Id === folderId ? { ...folder, FolderUsersPermissions: Permissions } : folder
      )
    },
    [rawActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
