import { Route } from 'react-router'
import Routes from '@tabeeb/routes'
import PowerBIReportProvidersTable from '../components/PowerBIReportProvidersTable'
import PowerBIReportProvider from '../components/PowerBIReportProvider'

const PowerBIReportProvidersPage = () => {
  return (
    <>
      <Route
        exact
        key={Routes.powerBIReportProviders}
        path={Routes.powerBIReportProviders}
        render={() => {
          return <PowerBIReportProvidersTable />
        }}
      />
      <Route
        key={Routes.powerBIReportProvider}
        path={Routes.powerBIReportProvider}
        render={({
          match: {
            params: { id },
          },
        }) => {
          return <PowerBIReportProvider id={id} />
        }}
      />
    </>
  )
}

export default PowerBIReportProvidersPage
