import { _getAiClassesRequest } from './aiRequests'
import { UploadContentType } from '@tabeeb/enums'

const createFormPageRequests = {
  getFormIconUrlRequest: (file) => ({
    url: `api/blobs/upload-params`,
    method: 'get',
    params: { uploadContentType: UploadContentType.FormThumbnail, fileName: file.name },
  }),
  createFormRequest: (form) => ({
    url: `api/forms`,
    method: 'post',
    data: form,
  }),
  updateFormRequest: (form) => ({
    url: `api/forms`,
    method: 'put',
    data: form,
  }),
  updateFormControlsRequest: (form) => ({
    url: `api/forms/updateformcontrols`,
    method: 'put',
    data: form,
  }),
  updateFormTabsRequest: (form) => ({
    url: `api/forms/updateformtabs`,
    method: 'put',
    data: form,
  }),
  processFormControlsPredictionsRequest: (formId) => ({
    url: `api/formcontroltags/processFormControls/${formId}`,
    method: 'get',
  }),
  getFormToEditRequest: (formId) => ({
    url: `api/forms/${formId}`,
    method: 'get',
  }),
  getAiClassesRequest: _getAiClassesRequest,
}

export default createFormPageRequests
