const vqaPoliciesRequests = {
  getVqaPoliciesRequest: _getVqaPoliciesRequest,
  getVqaPoliciesForFormRequest: _getVqaPoliciesRequest,
  updateVqaPolicyRequest: (data) => ({
    url: `api/vqaPolicies`,
    method: 'put',
    data,
  }),
  addVqaPolicyRequest: (data) => ({
    url: `api/vqaPolicies`,
    method: 'post',
    data,
  }),
  deleteVqaPolicyRequest: (vqaPolicyId) => ({
    url: `api/vqaPolicies/${vqaPolicyId}`,
    method: 'delete',
  }),
  attachVqaPolicyToFormControlOptionRequest: (data) => ({
    url: `api/vqaPolicies/formControlOption`,
    method: 'post',
    data,
  }),
  detachVqaPolicyFromFormControlOptionRequest: ({ formControlOptionId }) => ({
    url: `api/vqaPolicies/formControlOption/${formControlOptionId}`,
    method: 'delete',
  }),
}

export default vqaPoliciesRequests

export function _getVqaPoliciesRequest() {
  return {
    url: `api/vqaPolicies`,
    method: 'get',
  }
}
