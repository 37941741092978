import { memo, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'

import { Button, Grid, Paper } from '@mui/material'
import { DeleteOutlineOutlined, SaveOutlined } from '@mui/icons-material'

import { UploadContentType } from '@tabeeb/enums'
import { DragAndDropFiles, PageHeader, PageLayout } from '@tabeeb/uikit'
import { uploadFile } from '@tabeeb/services/uploadService'
import { FormikRichTextEditor, FormikSelect, FormikTextField } from '@tabeeb/shared/forms'
import { maxLengthWithName, noWhitespaces, requiredFieldWithName } from '@tabeeb/shared/utils/validationErrorMessages'

import {
  ArticleContentType,
  ArticleReadScope,
  ArticleReadScopeDisplayName,
  ArticlePublicationStatus,
} from '../../../enums'

import ArticleSaveButton from '../../../components/ArticleSaveButton'
import PdfContent from '../../../components/ArticleContent/PdfContent'

const trainingMaterialSchema = Yup.object().shape({
  Name: Yup.string()
    .strict()
    .trim(noWhitespaces)
    .max(256, maxLengthWithName('Name', 256))
    .required(requiredFieldWithName('Name')),
  Content: Yup.object()
    .shape({
      Type: Yup.number().oneOf(Object.values(ArticleContentType)),
      Content: Yup.string().required(),
    })
    .required(),
  ReadScope: Yup.number().oneOf(Object.values(ArticleReadScope)).required(),
})

const TrainingMaterialEditorPage = ({ initial, title, onSubmit }) => {
  const [uploadInProgress, setUploadInProgress] = useState(false)

  const onUploadFile = useCallback(async ({ file, onProgress }) => {
    try {
      setUploadInProgress(true)
      const { url, sas } = await uploadFile({
        file,
        uploadContentType: UploadContentType.ArticleDocument,
        onProgress,
      })

      return `${url.split('?')[0]}${sas.sasRead}`
    } finally {
      setUploadInProgress(false)
    }
  }, [])

  const initialValues = useMemo(() => {
    return {
      Id: initial.Id,
      Name: initial.Name,
      Content: initial.Content,
      ReadScope: initial.ReadScope,
    }
  }, [initial])

  const onSubmitForm = useCallback(
    (data) => {
      onSubmit({
        Id: data.Id,
        Name: data.Name,
        Content: data.Content,
        ReadScope: data.ReadScope,
      })
    },
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      validationSchema={trainingMaterialSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, setFieldValue, isValid, isSubmitting }) => {
        const submitDisabled = !isValid || isSubmitting

        return (
          <Form style={{ display: 'flex', height: '100%', width: '100%' }} autoComplete='off'>
            <PageLayout variant='outlined' spacing='page' component={Paper} maxWidth='lg'>
              <PageHeader
                title={title}
                actions={
                  <>
                    {values.Content.Type === ArticleContentType.Pdf && values.Content.Content && (
                      <Button
                        disabled={isSubmitting}
                        variant='outlined'
                        startIcon={<DeleteOutlineOutlined color='error' />}
                        onClick={() => setFieldValue('Content.Content', null)}
                      >
                        Remove file
                      </Button>
                    )}
                    <Button
                      startIcon={<SaveOutlined color={submitDisabled ? 'disabled' : 'primary'} />}
                      type='submit'
                      disabled={submitDisabled}
                      color='primary'
                      variant='outlined'
                    >
                      Save
                    </Button>
                  </>
                }
              />
              <Grid container wrap='nowrap' direction='column' spacing={2} mt={-1} flexGrow={1} overflow='hidden'>
                <Grid item container direction='row' spacing={2}>
                  <Grid item xs={9}>
                    <Field
                      size='small'
                      color='primary'
                      name='Name'
                      label='Name'
                      component={FormikTextField}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      id='read-scope-select'
                      size='small'
                      color='primary'
                      name='ReadScope'
                      label='Read scope'
                      options={Object.values(ArticleReadScope).map((value) => ({
                        value,
                        name: ArticleReadScopeDisplayName[value],
                      }))}
                      component={FormikSelect}
                      disabled={!!values.Id || isSubmitting}
                    />
                  </Grid>
                </Grid>
                {values.Content.Type === ArticleContentType.Pdf && (
                  <Grid item xs={12} overflow='auto'>
                    {!values.Content.Content && (
                      <DragAndDropFiles
                        disabled={uploadInProgress || isSubmitting}
                        file={null}
                        placeholder='Drag and drop a PDF file here or click to select a file'
                        fileUploadButtonText='Select file'
                        acceptMimeFormats={{ 'application/pdf': ['.pdf'] }}
                        acceptedFormatDisplayStrings={['pdf']}
                        onDropAccepted={async (acceptedFiles) => {
                          const url = await onUploadFile({ file: acceptedFiles[0] })

                          setFieldValue('Content.Content', url)
                        }}
                      />
                    )}
                    {values.Content.Content && <PdfContent url={values.Content.Content} />}
                  </Grid>
                )}
                {values.Content.Type === ArticleContentType.Html && (
                  <Grid item xs={12}>
                    <Field
                      name='Content.Content'
                      component={FormikRichTextEditor}
                      disabled={uploadInProgress || isSubmitting}
                      uploadsHandler={onUploadFile}
                    />
                  </Grid>
                )}
              </Grid>
            </PageLayout>
          </Form>
        )
      }}
    </Formik>
  )
}

TrainingMaterialEditorPage.defaultProps = {
  initial: {
    Id: 0,
    Name: '',
    Content: {
      Type: ArticleContentType.Html,
      Content: '',
    },
    ReadScope: ArticleReadScope.Content,
    Status: ArticlePublicationStatus.Draft,
  },
}

TrainingMaterialEditorPage.propTypes = {
  initial: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string.isRequired,
    Content: PropTypes.shape({
      Type: PropTypes.oneOf(Object.values(ArticleContentType)).isRequired,
      Content: PropTypes.string,
    }).isRequired,
    ReadScope: PropTypes.oneOf(Object.values(ArticleReadScope)).isRequired,
    Status: PropTypes.oneOf(Object.values(ArticlePublicationStatus)).isRequired,
  }),
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(TrainingMaterialEditorPage)
