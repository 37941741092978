import { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Image as KonvaImage, Layer } from 'react-konva'

import { getSelectedGalleryItem } from '@tabeeb/modules/gallery/selectors'

import { setContentSize, resetLoadingInProgress, setLoadingInProgress } from '../../actions'
import { useImage } from '../../hooks'

const ImageViewer = () => {
  const dispatch = useDispatch()

  const contentUrl = useSelector((state) => getSelectedGalleryItem(state)?.contentUrl)
  const timestampReceived = useSelector((state) => getSelectedGalleryItem(state)?.timestampReceived)

  const url = useMemo(
    () => (contentUrl ? `${contentUrl + (contentUrl.includes('?') ? '&' : '?')}b=${timestampReceived}` : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentUrl]
  )

  const image = useImage({ src: url })

  useEffect(() => {
    if (image) {
      dispatch(setContentSize({ height: image.height, width: image.width }))
      dispatch(resetLoadingInProgress())
    } else {
      dispatch(setLoadingInProgress())
    }
  }, [dispatch, image])

  return (
    <Layer name='image-viewer'>
      {image && <KonvaImage fill='white' image={image} width={image.width} height={image.height} />}
    </Layer>
  )
}

export default memo(ImageViewer)
