import LinkedContents from '../LinkedContents'
import { Container } from './styles'

const ContentSharings = () => {
  return (
    <Container>
      <LinkedContents />
    </Container>
  )
}

export default ContentSharings
