import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { CertificateScopeTypeDisplayName, TenantPermission } from '@tabeeb/enums'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import AutocompleteCertificateType from '../AutocompleteCertificateType'
import { getCertificateScopeTypesAccordingToPermissions } from '../../helpers'

const CertificateTypeCustomFilterOperator = ({
  item,
  applyValue,
  getAllCertificateTypesResponse,
  loading,
  allCategories,
  disabledControls,
  setCertificateTypesSearch,
  personal = false,
  showCategoriesAndScopes = true,
}) => {
  const [filterValue, setFilterValue] = useState(item.value ?? { category: '', scope: '', typeIds: [] })

  const hasManageCertificatesTenantScopePermission = useSelector((state) =>
    hasTenantPermission(state, TenantPermission.CertificatesTenantScope)
  )
  const hasManageCertificatesGlobalScopePermission = useSelector((state) =>
    hasTenantPermission(state, TenantPermission.CertificatesGlobalScope)
  )

  const applyValues = useCallback(
    ({ category, scope, typeIds }) => {
      applyValue({
        ...item,
        value: {
          category,
          scope,
          typeIds: typeIds ?? [],
        },
      })
    },
    [applyValue, item]
  )

  const updateSelectValue = useCallback(
    ({ category, scope }) => {
      setFilterValue((prevState) => ({ category, scope, typeIds: prevState.typeIds }))

      applyValues({ category, scope, typeIds: filterValue.typeIds })
    },
    [applyValues, filterValue.typeIds]
  )

  const updateAutocompleteValue = useCallback(
    (event, newValues, reason) => {
      switch (reason) {
        case 'selectOption':
        case 'removeOption':
          setFilterValue((prevState) => ({ ...prevState, typeIds: newValues }))
          break
        case 'clear':
        default:
          setFilterValue((prevState) => ({ ...prevState, typeIds: [] }))
          break
      }

      applyValues({ category: filterValue.category, scope: filterValue.scope, typeIds: newValues })
    },
    [applyValues, filterValue.category, filterValue.scope]
  )

  const getCertificateScopesModel = useMemo(
    () => ({
      personal,
      hasManageCertificatesGlobalScopePermission,
      hasManageCertificatesTenantScopePermission,
    }),
    [hasManageCertificatesGlobalScopePermission, hasManageCertificatesTenantScopePermission, personal]
  )
  const certificateScopes = getCertificateScopeTypesAccordingToPermissions(getCertificateScopesModel)

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        minHeight: 48,
        width: 600,
      }}
    >
      {showCategoriesAndScopes && (
        <>
          <FormControl sx={{ flexGrow: 0.5, minWidth: 105 }} disabled={disabledControls?.Category}>
            <InputLabel variant='standard' id='category'>
              Category
            </InputLabel>
            <Select
              labelId='category'
              label='Category'
              value={filterValue.category}
              onChange={(event) => updateSelectValue({ category: event.target.value, scope: filterValue.scope })}
            >
              {Object.values(allCategories).map((value) => (
                <MenuItem key={value.Id} value={value.Id}>
                  {value.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ flexGrow: 0.5, minWidth: 105 }} disabled={disabledControls?.Scope}>
            <InputLabel variant='standard' id='scope'>
              Scope
            </InputLabel>
            <Select
              labelId='scope'
              label='Scope'
              value={filterValue.scope}
              onChange={(event) => updateSelectValue({ category: filterValue.category, scope: event.target.value })}
            >
              {certificateScopes.map((value) => (
                <MenuItem key={value} value={value}>
                  {CertificateScopeTypeDisplayName[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <AutocompleteCertificateType
        getAllTypesResponse={getAllCertificateTypesResponse}
        onChange={updateAutocompleteValue}
        scope={Number(filterValue.scope)}
        category={Number(filterValue.category)}
        value={filterValue.typeIds}
        getAllTypesLoading={loading}
        autocompleteProps={{ multiple: true }}
        inputProps={{ sx: { '& .MuiAutocomplete-input': { p: '4px 0px !important' } } }}
        onInputChange={setCertificateTypesSearch}
      />
    </Box>
  )
}

CertificateTypeCustomFilterOperator.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string.isRequired,
    value: PropTypes.shape({
      typeIds: PropTypes.arrayOf(
        PropTypes.shape({
          Id: PropTypes.number,
          CategoryId: PropTypes.number,
          ScopeId: PropTypes.number,
        })
      ),
    }),
  }).isRequired,
  applyValue: PropTypes.func.isRequired,
  getAllCertificateTypesResponse: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number,
      CategoryId: PropTypes.number,
      ScopeId: PropTypes.number,
    })
  ),
  loading: PropTypes.bool.isRequired,
  personal: PropTypes.bool,
  allCategories: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }).isRequired
  ),
  showCategoriesAndScopes: PropTypes.bool,
  disabledControls: PropTypes.shape({
    Category: PropTypes.bool,
    Scope: PropTypes.bool,
  }),
  setCertificateTypesSearch: PropTypes.func.isRequired,
}

export default CertificateTypeCustomFilterOperator
