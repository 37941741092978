import { handleActions } from 'redux-actions'
import { setFolderActions, resetState } from '../../actions'

const defaultValue = []

export default handleActions(
  {
    [setFolderActions](_, { payload: actions }) {
      return actions
    },
    [resetState]() {
      return defaultValue
    },
  },
  defaultValue
)
