import { handleActions } from 'redux-actions'
import { setNewReminder } from '../actions'

const defaultValue = null

export default handleActions(
  {
    [setNewReminder](state, { payload }) {
      return payload
    },
  },
  defaultValue
)
