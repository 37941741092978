import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Delete } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'

import { DialogTypes } from '../../enums'
import { usePageDialog } from '../../hooks'
import { getIsDeleteSessionActionEnabled } from '../../selectors/sessionActions'

const TrashBin = ({ href }) => {
  const { onOpen } = usePageDialog(DialogTypes.DeleteSession)

  const onDrop = useCallback(
    (e) => {
      if (e.preventDefault) {
        e.preventDefault()
      }

      if (e.stopPropagation) {
        e.stopPropagation()
      }

      const session = JSON.parse(e.dataTransfer.getData('text'))
      if (!session?.Id) {
        return
      }

      onOpen({ session })
    },
    [onOpen]
  )

  const isVisible = useSelector(getIsDeleteSessionActionEnabled)
  if (!isVisible) {
    return null
  }

  return (
    <Link to={href}>
      <Tooltip title='Open trash bin'>
        <Button startIcon={<Delete color='pagePrimaryAction' />} onDragOver={(e) => e.preventDefault()} onDrop={onDrop}>
          Trash bin
        </Button>
      </Tooltip>
    </Link>
  )
}

TrashBin.propTypes = {
  href: PropTypes.string.isRequired,
}

export default memo(TrashBin)
