import { combineActions, handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [actions.addImageToSplatModel](state, action) {
      return [...state, action.payload]
    },
    [actions.addAllPagesToSplatModel](state, action) {
      let pages = action.payload
      pages = pages.filter((item) => !state.some((page) => page.id === item.id))

      const newState = [...state]
      pages.forEach((page) => newState.push(page))

      return newState
    },
    [actions.updateImagesSplatModel](state, action) {
      return [...action.payload]
    },
    [actions.deleteImageFromSplatModel](state, action) {
      const index = action.payload
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [combineActions(actions.closeSplatModelTimeline, actions.resetState)]() {
      return defaultValue
    },
  },
  defaultValue
)
