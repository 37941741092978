import PropTypes from 'prop-types'

import { Arrow as CanvasArrow, Group } from 'react-konva'

import { AnnotationType } from '@tabeeb/enums'

import { getHighlightedColor } from '../../services/colorsService'
import { getViewBoxFromAnnotationCoordinates } from '../../helpers/viewBoxCalculator'

const ArrowLine = ({
  annotation,
  annotationRef,
  opacity,
  hovered,
  hoverProps,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
  children,
}) => {
  const position = ArrowLine.getXY(annotation)
  const anchor = ArrowLine.getAnchor(annotation)
  const points = ArrowLine.getPoints(annotation)

  const commonStyles = {
    lineCap: 'round',
    lineJoin: 'round',
    opacity,
  }

  const styles = {
    ...commonStyles,
    fill: annotation.Color,
    fillEnabled: true,
    stroke: annotation.Color,
    strokeWidth: annotation.Width,
    hitStrokeWidth: annotation.Width * 2,
    pointerAtBeginning: annotation.Type === AnnotationType.ArrowBoth,
  }

  const hoveredStyles = {
    ...commonStyles,
    fillEnabled: false,
    stroke: getHighlightedColor(annotation.Color),
    strokeWidth: 1,
    strokeHitEnabled: false,
    strokeScaleEnabled: false,
    pointerAtBeginning: annotation.Type === AnnotationType.ArrowBoth,
  }

  return (
    <Group {...position} {...dragAndDropProps}>
      <CanvasArrow
        id={annotation.Id}
        ref={annotationRef}
        points={points}
        {...styles}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
      />
      {(selected || hovered) && <CanvasArrow points={points} {...hoveredStyles} />}
      <Group {...anchor}>{children}</Group>
    </Group>
  )
}

ArrowLine.getXY = (annotation) => ({
  x: annotation.Coordinates[0].Start.X,
  y: annotation.Coordinates[0].Start.Y,
})

ArrowLine.getAnchor = (annotation) => ({
  x: annotation.Coordinates[0].End.X - annotation.Coordinates[0].Start.X,
  y: annotation.Coordinates[0].End.Y - annotation.Coordinates[0].Start.Y,
})

ArrowLine.getPoints = (annotation) => {
  const position = ArrowLine.getXY(annotation)
  const coords = annotation.Coordinates[0]
  return [
    coords.Start.X - position.x,
    coords.Start.Y - position.y,
    coords.End.X - position.x,
    coords.End.Y - position.y,
  ]
}

ArrowLine.getViewBox = getViewBoxFromAnnotationCoordinates

ArrowLine.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Color: PropTypes.string.isRequired,
    Coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        Start: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
        End: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
      })
    ),
    Type: PropTypes.number.isRequired,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  annotationRef: PropTypes.func,
  opacity: PropTypes.number,
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
  children: PropTypes.node,
}

export default ArrowLine
