import { memo, useState } from 'react'
import { useSelector } from 'react-redux'

import Button from '@material-ui/core/Button'
import ShareSessionOptionsMenu from '../ShareSessionOptionsMenu'
import { getIsShareSessionOptionsButtonVisible } from '../../../shareSessionPagesList/selectors'

import './styles.less'

const ShareSessionOptionsButton = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isVisible = useSelector(getIsShareSessionOptionsButtonVisible)

  const menuProps = { anchorEl, setAnchorEl }

  return isVisible ? (
    <>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} className='gallery-options-button' variant='contained'>
        add to +
      </Button>
      <ShareSessionOptionsMenu {...menuProps} />
    </>
  ) : null
}

export default memo(ShareSessionOptionsButton)
