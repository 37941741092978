import { memo } from 'react'
import PropTypes from 'prop-types'

import { Chip, Tooltip } from '@mui/material'
import { AssignmentLate, AssignmentTurnedIn } from '@mui/icons-material'

const ArticleReadStatusChip = ({ read }) => {
  return (
    <Tooltip title={read ? 'Read' : 'Unread'}>
      <Chip
        size='small'
        sx={{ cursor: 'help', padding: 0.25 }}
        icon={read ? <AssignmentTurnedIn /> : <AssignmentLate />}
        color={read ? 'success' : 'primary'}
        label={read ? 'Read' : 'Unread'}
      />
    </Tooltip>
  )
}

ArticleReadStatusChip.propTypes = {
  read: PropTypes.bool.isRequired,
}

export default memo(ArticleReadStatusChip)
