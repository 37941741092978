import { Box } from '@mui/material'

import Navigation from '../Navigation'
import ManageFolderUsersButton from './ManageFolderUsersButton'
import CreateFolderButton from './CreateFolderButton'
import CreateSessionButton from './CreateSessionButton'
import StorageSelector from '../StorageSelector'

const Header = () => {
  return (
    <Box display='flex' alignItems='flex-start' flexWrap='wrap' mt={2} mb={2}>
      <Box display='flex' alignItems='flex-start' justifyContent='flex-start' height='auto'>
        <Navigation />
      </Box>
      <Box display='flex' alignItems='flex-start' justifyContent='flex-end' height='auto' flexGrow={1}>
        <ManageFolderUsersButton />
        <CreateFolderButton />
        <CreateSessionButton />
        <StorageSelector />
      </Box>
    </Box>
  )
}

export default Header
