import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = false

const getIsSheetNameUnique = handleActions(
  {
    [actions.getIsSheetNameUnique.success](state, action) {
      return action.response.data.IsUnique
    },
    [actions.getIsSheetNameUnique.request]() {
      return false
    },
  },
  defaultState
)

export default getIsSheetNameUnique
