import { handleActions } from 'redux-actions'
import moment from 'moment'

import { updateDateFilter, resetFilters } from '../../actions'

const defaultState = {
  startDate: moment().subtract(2, 'days').startOf('day').format(),
  endDate: moment().endOf('day').format(),
}

export default handleActions(
  {
    [updateDateFilter](state, action) {
      return {
        ...action.payload,
      }
    },
    [resetFilters](state, action) {
      return defaultState
    },
  },
  defaultState
)
