import { memo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, DialogActions } from '@mui/material'
import { shareContent } from '@tabeeb/modules/contentSharings/actions'

const Actions = ({ stages, setCurrentStageIndex, currentStageIndex, onClose }) => {
  const dispatch = useDispatch()
  const currentStage = stages[currentStageIndex]
  if (!currentStage) {
    return null
  }

  const isStageOptional = currentStage.steps.every((step) => step.isOptional)
  const isStageCompleted = isStageOptional || currentStage.steps.every((step) => step.isOptional || step.isCompleted)
  const continueBtnText = isStageOptional ? 'Skip' : 'Continue'

  const _handleContinueClick = () => {
    if (!isStageCompleted) {
      return
    }

    const isLastStage = stages.length === 1 || currentStageIndex === stages.length - 1
    if (isLastStage) {
      dispatch(shareContent())
      onClose()
    } else {
      setCurrentStageIndex(currentStageIndex + 1)
    }
  }

  return (
    <DialogActions>
      <Button onClick={_handleContinueClick} color='primary' disabled={!isStageCompleted}>
        {continueBtnText}
      </Button>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  )
}

Actions.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          isOptional: PropTypes.bool.isRequired,
          isCompleted: PropTypes.bool.isRequired,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  setCurrentStageIndex: PropTypes.func.isRequired,
  currentStageIndex: PropTypes.number.isRequired,
}

export default memo(Actions)
