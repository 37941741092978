import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LocationOnOutlined } from '@material-ui/icons'

import { SessionPermission } from '@tabeeb/enums'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { trackingActions, trackingSelectors } from '@tabeeb/modules/tracking'

import ToolbarButton from '../ToolbarButton'

const TrackingButton = () => {
  const dispatch = useDispatch()

  const open = useSelector(trackingSelectors.getIsOpen)
  const hasPermission = useSelector((state) =>
    permissionsSelectors.hasSessionPermission(state, SessionPermission.MainScreenToolbarTracking)
  )
  const isFeatureEnabled = useSelector(appConfigStateSelectors.getIsTrackingMapEnabled)

  const display = isFeatureEnabled && hasPermission

  const onClick = () => {
    if (open) {
      dispatch(trackingActions.closeTracking())
    } else {
      dispatch(trackingActions.openTracking())
    }
  }

  return (
    <ToolbarButton
      display={display}
      Icon={LocationOnOutlined}
      selected={open}
      onClick={onClick}
      title={open ? 'Hide tracking' : 'Display tracking'}
    />
  )
}

export default memo(TrackingButton)
