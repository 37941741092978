const billingSettingsRequests = {
  getAdminBillingSettingsRequest: () => ({
    url: `tenant-service/api/config/admin/billingsettings`,
    method: 'get',
  }),
  getBillingSettingsRequest: () => ({
    url: `tenant-service/api/config/billingsettings`,
    method: 'get',
  }),
  updateBillingSettingsRequest: (data) => ({
    url: `tenant-service/api/config/admin/billingsettings`,
    method: 'put',
    data,
  }),
  getAdminSessionApprovalSettingsRequest: () => ({
    url: `tenant-service/api/config/admin/sessionApprovalSettings`,
    method: 'get',
  }),
  getSessionApprovalSettingsRequest: () => ({
    url: `api/config/sessionApprovalSettings`,
    method: 'get',
  }),
  updateSessionApprovalSettingsRequest: (data) => ({
    url: `tenant-service/api/config/admin/sessionApprovalSettings`,
    method: 'put',
    data,
  }),
}

export default billingSettingsRequests
