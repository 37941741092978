import { createAction } from 'redux-actions'

export const openMobileJoinCallDialog = createAction('OPEN_MOBILE_JOIN_CALL_DIALOG')
export const closeMobileJoinCallDialog = createAction('CLOSE_MOBILE_JOIN_CALL_DIALOG')
export const onMobileJoinCallDialogResult = createAction('ON_MOBILE_JOIN_CALL_DIALOG_RESULT')

export const openSwitchRecordingSourceDialog = createAction('OPEN_SWITCH_RECORDING_SOURCE_DIALOG')
export const closeSwitchRecordingSourceDialog = createAction('CLOSE_SWITCH_RECORDING_SOURCE_DIALOG')
export const openMuteCameraDialog = createAction('OPEN_MUTE_CAMERA_DIALOG')
export const closeMuteCameraDialog = createAction('CLOSE_MUTE_CAMERA_DIALOG')
export const openMakePresenterDialog = createAction('OPEN_MAKE_PRESENTER_DIALOG')
export const closeMakePresenterDialog = createAction('CLOSE_MAKE_PRESENTER_DIALOG')

export const openPickRecordingTypeDialog = createAction('OPEN_PICK_RECORDING_TYPE_DIALOG')
export const closePickRecordingTypeDialog = createAction('CLOSE_PICK_RECORDING_TYPE_DIALOG')
export const onSetRecordingType = createAction('ON_SET_RECORDING_TYPE')
export const resetPickRecordingTypeDialogResult = createAction('RESET_PICK_RECORDING_TYPE_DIALOG_RESULT')

export const openLeaveSessionPageDialog = createAction('OPEN_LEAVE_SESSION_PAGE_DIALOG')
export const closeLeaveSessionPageDialog = createAction('CLOSE_LEAVE_SESSION_PAGE_DIALOG')
export const onLeaveSessionPageDialogResult = createAction('ON_LEAVE_SESSION_PAGE_DIALOG_RESULT')

export const resetWhiteboardState = createAction('RESET_WHITEBOARD_STATE')

export const checkSessionInviteRequest = createAction('CHECK_SESSION_INVITE_REQUEST')
export const checkSessionInviteSuccess = createAction('CHECK_SESSION_INVITE_SUCCESS')
export const checkSessionInviteFailed = createAction('CHECK_SESSION_INVITE_FAILED')

export const toggleChatNotificationsMuteRequest = createAction('TOGGLE_CHAT_NOTIFICATIONS_MUTE_REQUEST')
export const toggleChatNotificationsMuteSuccess = createAction('TOGGLE_CHAT_NOTIFICATIONS_MUTE_SUCCESS')
export const toggleChatNotificationsMuteFailed = createAction('TOGGLE_CHAT_NOTIFICATIONS_MUTE_FAILED')
