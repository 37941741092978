import { memo } from 'react'
import PropTypes from 'prop-types'
import { Rectangle as GoogleMapRectangle } from '@react-google-maps/api'

import './styles.less'

const Rectangle = ({ start, end, color, ...rest }) => {
  return (
    <GoogleMapRectangle
      bounds={{
        north: start.Latitude,
        east: start.Longitude,
        south: end.Latitude,
        west: end.Longitude,
      }}
      options={{
        fillColor: color,
        strokeColor: color,
      }}
      {...rest}
    />
  )
}

Rectangle.propTypes = {
  color: PropTypes.string.isRequired,
  start: PropTypes.shape({
    Latitude: PropTypes.number.isRequired,
    Longitude: PropTypes.number.isRequired,
  }),
  end: PropTypes.shape({
    Latitude: PropTypes.number.isRequired,
    Longitude: PropTypes.number.isRequired,
  }),
}

export default memo(Rectangle)
