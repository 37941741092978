import { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'

import { Filter } from './styles'

const FilterButton = forwardRef(({ active, onClick, ...rest }, ref) => {
  return <Filter ref={ref} data-active={active} onClick={onClick} {...rest} />
})

FilterButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(FilterButton)
