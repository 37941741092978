import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setSelectedProject](state, action) {
      return action.payload
    },
  },
  defaultState
)
