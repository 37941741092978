import PropTypes from 'prop-types'
import { Grid, TextField, Typography } from '@mui/material'

const PowerBIReportProperties = ({ form }) => {
  return (
    <Grid container spacing={1} item xs={12} alignItems='center' component='form' autoComplete='off'>
      <Grid item xs={2}>
        <Typography fontWeight={500}>Report name</Typography>
      </Grid>
      <Grid item xs={10}>
        <TextField
          variant='outlined'
          size='small'
          name='name'
          placeholder='Report name'
          value={form.values.name}
          onChange={form.handleChange}
          inputProps={{ maxLength: 100 }}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

PowerBIReportProperties.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      name: PropTypes.string,
    }),
    handleChange: PropTypes.func,
  }).isRequired,
}

export default PowerBIReportProperties
