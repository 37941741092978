import { handleActions, combineActions } from 'redux-actions'
import * as actions from '../actions'

const defaultState = false

export default handleActions(
  {
    [actions.openAlertOwnerModal](state, action) {
      return true
    },
    [actions.closeAlertOwnerModal](state, action) {
      return false
    },
  },
  defaultState
)
