import { handleActions, combineActions } from 'redux-actions'

import * as activityActions from '../actions'
import * as galleryActions from '../../gallery/actions'
import * as defaultValues from '../common'

import { AnnotationType } from '../../../Enums'

const defaultState = {
  activities: [],
  loadingActivities: false,
  activitiesTake: defaultValues.ActivitiesTake,
  activitiesSkip: defaultValues.ActivitiesSkip,
  contentHeight: 0,
}

const activitiesList = handleActions(
  {
    [activityActions.cleanActivitiesList]() {
      return defaultState
    },
    [activityActions.setActivities](state, { payload }) {
      return _addActivities(state, payload)
    },
    [activityActions.addActivity](state, { payload }) {
      return _addActivity(state, payload)
    },
    [activityActions.addActivities](state, { payload }) {
      return _addActivities(state, payload)
    },
    [activityActions.updateActivity](state, { payload }) {
      return _addActivity(state, payload)
    },
    [activityActions.delActivity](state, { payload }) {
      return _delActivity(state, payload)
    },
    [combineActions(galleryActions.deleteGalleryItemSuccess, galleryActions.deleteGalleryItem)](state, { payload }) {
      return _delActivityByPageId(state, payload.pageId)
    },
    [activityActions.lockActivity](state, { payload }) {
      const { activityId, lock } = payload
      const activities = state.activities.map((activity) => {
        if (activity.Id === activityId) {
          return { ...activity, locked: lock }
        }
        return { ...activity }
      })

      return { ...state, activities }
    },
    [activityActions.getActivitiesByContentIdRequest](state, { payload }) {
      return _getActivitiesByContentIdRequest(state, payload)
    },
    [activityActions.getActivitiesByContentIdSuccess](state, { payload }) {
      return _getActivitiesByContentIdSuccess(state, payload)
    },
    [activityActions.setActivitiesContentHeight](state, { payload }) {
      return _setActivitiesContentHeight(state, payload)
    },
    [activityActions.loadMoreActivities](state, action) {
      return state
    },
    [activityActions.resetActivitiesSkipCount](state, action) {
      return _resetActivitiesSkipCount(state, action)
    },
    [activityActions.resetActivities](state, action) {
      return _resetActivities(state, action)
    },
    [galleryActions.updateAiAnnotationsText](state, action) {
      return _updateAiActivitiesText(state, action.payload)
    },
    [activityActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)

export default activitiesList

const _setActivitiesContentHeight = (state, contentHeight) => {
  return { ...state, contentHeight }
}

const _getActivitiesByContentIdRequest = (state, payload) => {
  const loadingActivities = false
  return { ...state, loadingActivities }
}

const _getActivitiesByContentIdSuccess = (state, payload) => {
  const loadingActivities = true
  const activitiesSkip = state.activitiesSkip + state.activitiesTake
  return { ...state, activitiesSkip, loadingActivities }
}

const _resetActivitiesSkipCount = (state, payload) => {
  const activitiesSkip = defaultValues.ActivitiesSkip
  return { ...state, activitiesSkip }
}

const _resetActivities = (state, payload) => {
  const activities = []
  return { ...state, activities }
}

function _delActivity(state, activityId) {
  const index = state.activities.findIndex((element) => element.id === activityId)

  let resultActivities = [...state.activities]
  if (index > -1) {
    resultActivities.splice(index, 1)
  }

  resultActivities = resultActivities.filter((item) => item.parentId !== activityId)

  return { ...state, activities: resultActivities }
}

function _addActivity(state, activity) {
  const index = state.activities.findIndex((element) => element.id === activity.id)
  let result
  if (index === -1) {
    result = [...state.activities, activity]
  } else {
    result = [...state.activities]
    result[index] = activity
  }

  return _sortActivity(state, result)
}

function _addActivities(state, activities) {
  const newActivitiesMap = new Map()
  activities.forEach((activity) => newActivitiesMap.set(activity.id, activity))

  const result = state.activities.map((oldActivity) => {
    if (!newActivitiesMap.has(oldActivity.id)) {
      return { ...oldActivity }
    }

    const result = { ...oldActivity, ...newActivitiesMap.get(oldActivity.id) }
    newActivitiesMap.delete(oldActivity.id)

    return result
  })

  newActivitiesMap.forEach((activity) => {
    result.push(activity)
  })

  return _sortActivity(state, result)
}

function _sortActivity(state, payload) {
  const activities = payload.sort((a, b) => b.timestampCreated - a.timestampCreated)
  return { ...state, activities }
}

function _delActivityByPageId(state, pageId) {
  const sourceActivities = [...state.activities]
  const activities = sourceActivities.filter((item) => item.pageId !== pageId)
  return { ...state, activities }
}

function _updateAiActivitiesText(state, { uniqueAiObjectId, propertiesText }) {
  const resultActivities = state.activities.map((activity) =>
    activity.type === AnnotationType.AI && activity.uniqueAIObjectId === uniqueAiObjectId
      ? { ...activity, text: _getUpdatedText(activity.text, propertiesText) }
      : activity
  )
  return { ...state, activities: resultActivities }
}

function _getUpdatedText(oldText, propertiesText) {
  const splitter = 'Properties</b>'
  const index = oldText.indexOf(splitter)
  let newText = oldText.substring(0, index + splitter.length)
  newText += propertiesText
  return newText
}
