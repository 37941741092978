import { combineReducers } from 'redux'

import reviewsList from './reviewsList'
import tenantsListState from './tenantsListState'
import contentDescriptions from './contentDescriptions'
import contentReviewStatus from './contentReviewStatus'

export default combineReducers({
  reviewsList,
  tenantsListState,
  contentDescriptions,
  contentReviewStatus,
})
