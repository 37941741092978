import { combineReducers } from 'redux'

import isVideoPlaying from './isVideoPlaying'
import isFullScreen from './isFullScreen'
import isAudioMuted from './isAudioMuted'
import formAnswersTimeline from './formAnswersTimeline'
import videoVolume from './videoVolume'
import seekToTimestamp from './seekToTimestamp'
import currentVideoTimestamp from './currentVideoTimestamp'
import durationVideoTimestamp from './durationVideoTimestamp'

export default combineReducers({
  isVideoPlaying,
  isFullScreen,
  isAudioMuted,
  formAnswersTimeline,
  videoVolume,
  seekToTimestamp,
  currentVideoTimestamp,
  durationVideoTimestamp,
})
