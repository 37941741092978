import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Folder } from '@mui/icons-material'
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'

import { removePagesFromSelection, updatePageSessionFolderRequest } from '../../actions'
import { getFoldersList, getSelectedFolderId, getSelection } from '../../selectors'

const GalleryItemsMenu = ({ open, position, onClose }) => {
  const dispatch = useDispatch()

  const selection = useSelector(getSelection)
  const selectedFolderId = useSelector(getSelectedFolderId)
  const foldersList = useSelector(getFoldersList)

  const moveSelectedPagesToFolder = useCallback(
    (folderId) => {
      dispatch(
        updatePageSessionFolderRequest({
          pageIds: selection,
          folderId,
        })
      )

      onClose()
    },
    [dispatch, onClose, selection]
  )

  const onMenuClose = useCallback(() => {
    dispatch(removePagesFromSelection(selection))

    onClose()
  }, [dispatch, onClose, selection])

  const items = useMemo(() => {
    return [
      {
        title: 'Main Gallery',
        folderId: null,
      },
      ...foldersList.map((folder) => ({
        title: folder.Name,
        folderId: folder.Id,
      })),
    ].filter((i) => i.folderId !== selectedFolderId)
  }, [foldersList, selectedFolderId])

  return (
    <Menu
      open={open}
      onClose={onMenuClose}
      anchorReference='anchorPosition'
      anchorPosition={{ top: position.y, left: position.x }}
      PaperProps={{ sx: { minWidth: 150, maxWidth: 200, maxHeight: 300 } }}
    >
      <Typography variant='subtitle2' p={1} paddingLeft={2} paddingRight={2}>
        Move to...
      </Typography>
      <Divider />

      {items.map((item) => (
        <MenuItem key={`folder_${item.folderId}`} onClick={() => moveSelectedPagesToFolder(item.folderId)}>
          <ListItemIcon>
            <Folder />
          </ListItemIcon>
          <ListItemText primary={item.title} />
        </MenuItem>
      ))}

      {items.length === 0 && (
        <MenuItem disabled>
          <ListItemText primary='No folders available' />
        </MenuItem>
      )}
    </Menu>
  )
}

GalleryItemsMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default GalleryItemsMenu
