import { memo } from 'react'
import PropTypes from 'prop-types'

import { Close } from '@material-ui/icons'
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@material-ui/core'

import VideoBridgeRegions from './VideoBridgeRegions'
import MuteChatNotifications from './MuteChatNotifications'

import useStyles from './styles'

const SessionSettingsDialog = ({ open, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
      <DialogTitle className={classes.header} disableTypography>
        <Typography className={classes.title} color='textSecondary' variant='h6' noWrap>
          Session settings
        </Typography>
        <Tooltip title='Close'>
          <IconButton onClick={onClose} aria-label='Close'>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <VideoBridgeRegions />
        <MuteChatNotifications />
      </DialogContent>
    </Dialog>
  )
}

SessionSettingsDialog.defaultProps = {
  open: false,
  onClose: () => {},
}

SessionSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(SessionSettingsDialog)
