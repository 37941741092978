import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.addPageIndex](state, action) {
      return [...state, action.payload]
    },
    [rawActions.removePageIndex](state, action) {
      const index = state.findIndex((item) => item === action.payload)
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [rawActions.clearDisabledPagesList](state, action) {
      return defaultState
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
