import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FoundUsers from '../components/Dialogs/LegacyShareSessionDialog/FoundUsers'
import { getFoundUsersForReview } from '../selectors/reviews'

class FoundUsersContainer extends Component {
  render() {
    const {
      switchAction,
      foundUsersList,
      isSwitchingInProgress,
      selectOwner,
      selectUser,
      limitedNumberOfDisplays,
      folderUsersList,
      openedAllSessionsPageDialogType,
      openedDialogType,
    } = this.props

    const props = {
      switchAction,
      foundUsersList,
      isSwitchingInProgress,
      selectOwner,
      selectUser,
      openedAllSessionsPageDialogType,
      openedDialogType,
      limitedNumberOfDisplays,
      folderUsersList,
    }
    return <FoundUsers {...props} />
  }
}

FoundUsersContainer.propTypes = {
  switchAction: PropTypes.func,
  foundUsersList: PropTypes.array.isRequired,
  isSwitchingInProgress: PropTypes.bool,
  selectOwner: PropTypes.func,
  selectUser: PropTypes.func,
  folderUsers: PropTypes.array,
  limitedNumberOfDisplays: PropTypes.number,
  openedDialogType: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  const {
    sessionsPage: { openedDialogType, foundUsersList },
    sessionsPage,
  } = state
  const { isReviewMode } = props
  const { folderUsersList } = sessionsPage
  return {
    foundUsersList: isReviewMode ? getFoundUsersForReview(state) : foundUsersList,
    folderUsersList,
    openedAllSessionsPageDialogType: openedDialogType,
  }
}

export default connect(mapStateToProps, null)(FoundUsersContainer)
