import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_SPATIAL_MODEL_STATE')

export const openPagesDialog = createAction('OPEN_PAGES_DIALOG')
export const closePagesDialog = createAction('CLOSE_PAGES_DIALOG')

export const addImageToModel = createAction('ADD_IMAGE_TO_MODEL')
export const addAllPagesToModel = createAction('ADD_ALL_PAGES_TO_MODEL')
export const addAllPagesToSpatialModel = createAction('ADD_ALL_PAGES_TO_SPATIAL_MODEL')
export const deleteImageFromModel = createAction('DELETE_IMAGE_FROM_MODEL')
export const updateImagesSpatialModel = createAction('UPDATE_IMAGES_SPATIAL_MODEL')
export const addImagesSourceFolder = createAction('ADD_IMAGES_SOURCE_FOLDER')

export const createSpatialModel = createAction('CREATE_SPATIAL_MODEL')

export const createSpatialModelRequest = createAction('CREATE_SPATIAL_MODEL_REQUEST')
export const createSpatialModelSuccess = createAction('CREATE_SPATIAL_MODEL_SUCCESS')
export const createSpatialModelFailed = createAction('CREATE_SPATIAL_MODEL_FAILED')

export const goToPage = createAction('GO_TO_PAGE')
