import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [combineActions(rawActions.createThreedeeModelRequest)](state, action) {
      return true
    },
    [combineActions(rawActions.createThreedeeModelFailed, rawActions.createThreedeeModelSuccess)](state, action) {
      return false
    },
    [rawActions.resetState](state, action) {
      return false
    },
  },
  false
)
