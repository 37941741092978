import { combineReducers } from 'redux'

import features from './features'
import filters from './filters'
import folderActions from './folderActions'
import sessionActions from './sessionActions'
import sortings from './sortings'

export default combineReducers({
  features,
  filters,
  folderActions,
  sessionActions,
  sortings,
})
