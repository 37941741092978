import PropTypes from 'prop-types'

import './styles.less'

const GalleryNavbar = ({ path = '', handleChangeFolder, isDragOn, allowDrop, onDrop, onDragEnter, onDragLeave }) => {
  return (
    <div className='gallery-navbar-container'>
      <div
        className='gallery-navbar-back-arrow'
        onClick={handleChangeFolder}
        onDragOver={allowDrop}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        style={{
          backgroundColor: isDragOn ? 'rgba(128, 128, 128, 0.4)' : '',
        }}
      >
        &lt;
      </div>
      <div className='gallery-navbar-folder-name'>{path.length > 13 ? `${path.substring(0, 13)}...` : path}</div>
    </div>
  )
}

GalleryNavbar.propTypes = {
  path: PropTypes.string.isRequired,
  handleChangeFolder: PropTypes.func.isRequired,
  isDragOn: PropTypes.bool.isRequired,
  allowDrop: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
}

export default GalleryNavbar
