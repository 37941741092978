import FileUploadsListContainer from '../../containers/FileUploadsListContainer'
import FileUploadsControlsContainer from '../../containers/FileUploadsControlsContainer'

import './styles.less'

const FileUploads = () => {
  return (
    <section className='file-uploads'>
      <FileUploadsListContainer />
      <FileUploadsControlsContainer />
    </section>
  )
}

export default FileUploads
