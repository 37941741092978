import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'
import PropTypes from 'prop-types'
import * as rawActions from '../actions/index'
import ExportAsPdfOptionsDialog from '../components/ExportAsPdfOptionsDialog/index'
import { ExportAsPdfOptions } from '../../../Enums'

class ExportAsPdfOptionsDialogContainer extends Component {
  _setPdfExportOption = (event, value) => {
    const { actions } = this.props
    actions.setPdfOptionsDialogState(parseInt(value, 10))
  }

  _next = () => {
    const {
      pdfOptionsDialogState,
      selectedFolderId,
      actions: { closePdfOptionsDialog, setEmailRecipientsDialogState, openExportGalleryOptionsDialog },
    } = this.props

    closePdfOptionsDialog()

    if (pdfOptionsDialogState === ExportAsPdfOptions.FormQuestions) {
      setEmailRecipientsDialogState('pdf')
    } else if (!selectedFolderId) {
      openExportGalleryOptionsDialog('pdf')
    } else {
      setEmailRecipientsDialogState('pdf')
    }
  }

  render() {
    const { formTitle, isExportPdfOptionsDialogOpen, isShowProgress, actions, pdfOptionsDialogState } = this.props

    const ExportAsPdfOptionsValues = {
      [ExportAsPdfOptions.PagesInGallery]: 'Pages In Gallery',
      [ExportAsPdfOptions.FormQuestions]: `${formTitle} questions`,
    }

    const exportOptions = Object.values(ExportAsPdfOptions).map((option) => ({
      Id: option,
      Value: ExportAsPdfOptionsValues[option],
    }))

    const props = {
      isExportPdfOptionsDialogOpen,
      closeDialog: actions.closePdfOptionsDialog,
      next: this._next,
      isShowProgress,
      setPdfExportOption: this._setPdfExportOption,
      selectedOption: pdfOptionsDialogState,
      exportOptions,
    }
    return <ExportAsPdfOptionsDialog {...props} />
  }
}

ExportAsPdfOptionsDialogContainer.propTypes = {
  isExportPdfOptionsDialogOpen: PropTypes.bool.isRequired,
  pdfOptionsDialogState: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  isShowProgress: PropTypes.bool.isRequired,
  selectedFolderId: PropTypes.number,
  formTitle: PropTypes.string.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

function mapStateToProps(state) {
  return {
    isExportPdfOptionsDialogOpen: state.gallery.isExportPdfOptionsDialogOpen,
    pdfOptionsDialogState: state.gallery.pdfOptionsDialogState,
    isShowProgress: state.gallery.assetsList.inProgress,
    selectedFolderId: state.gallery.galleryState.selectedFolderId,
    formTitle: getFormTitle(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportAsPdfOptionsDialogContainer)
