export default (theme) => {
  return {
    root: {
      flexGrow: 1,
      '& .MuiTabs-flexContainer': {
        width: 'fit-content',
        margin: 'auto',
      },
      '& .MuiTab-root': {
        minWidth: '130px !important',
      },
    },
  }
}
