import { useMemo } from 'react'
import { adminGetRolesRequest } from '@tabeeb/modules/admin/users/actions'
import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'

const useTenantRoles = () => {
  const { status, response } = useApiRequest({ request: adminGetRolesRequest })

  const roles = useMemo(() => {
    if (!response) {
      return []
    }
    return response
  }, [response])

  return { fetchStatus: status, roles }
}

export default useTenantRoles
