import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import GroupAddIcon from '@material-ui/icons/GroupAdd'
import { Checkbox, MenuItem, FormControl, ListItemText, Select } from '@material-ui/core'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'

import { FetchStatus } from '@tabeeb/enums'

import { selectUsersList } from '../../selectors'
import { ReminderError } from '../../services/reminderErrors'

import './styles.less'

const ReminderUsers = ({ currentReminder, setReminderSettings, errors }) => {
  const { fetchingStatus, users } = useSelector(selectUsersList)

  const [isChanged, setIsChanged] = useState(false)

  const selectedUsers = users.filter((u) => currentReminder.Users.includes(u.id))
  const error = errors.find((e) => e === ReminderError.EmptyUsers)
  const showError = error && isChanged

  const _handleChange = (e) => {
    const newUsersArray = e.target.value

    setReminderSettings({
      ...currentReminder,
      Users: newUsersArray.sort((a, b) => a - b),
    })

    setIsChanged(true)
  }

  return (
    <div className='reminder-users-container'>
      <div className='reminder-users'>
        <span className='reminder-users-icon'>
          <GroupAddIcon fontSize='inherit' />
        </span>
        <FormControl className='reminder-users-select'>
          <Select
            disabled={fetchingStatus === FetchStatus.Idle || fetchingStatus === FetchStatus.Loading}
            multiple
            displayEmpty
            value={selectedUsers.map((u) => u.id)}
            onChange={_handleChange}
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
              getContentAnchorEl: null,
              id: 'reminder-users-select-menu',
            }}
            renderValue={() => {
              if (selectedUsers.length === 0) {
                return <span className='reminder-users-select-placeholder'>Select user(s)</span>
              }

              return (
                <span className='reminder-users-selected' title={selectedUsers.map((u) => u.displayName).join(', ')}>
                  {selectedUsers.map((u) => u.displayName).join(', ')}
                </span>
              )
            }}
          >
            {users.map((user) => (
              <MenuItem className='reminder-user' key={user.id} value={user.id}>
                <UserAvatar user={user} />
                <ListItemText primary={user.displayName} />
                <Checkbox checked={selectedUsers.some((u) => u.id === user.id)} color='default' />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {showError && <span className='reminder-error'>{error}</span>}
    </div>
  )
}

ReminderUsers.propTypes = {
  currentReminder: PropTypes.object.isRequired,
  setReminderSettings: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
}

export default ReminderUsers
