import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.addPageToSelection](state, action) {
      const pageId = action.payload

      const newState = state.filter((id) => id !== pageId)
      newState.push(pageId)

      return newState
    },
    [rawActions.addPagesToSelection](state, action) {
      const pageIds = action.payload

      let newState = state.filter((id) => id !== !pageIds.some((pageId) => pageId === id))
      newState = [...newState, ...pageIds]

      return newState
    },
    [rawActions.removePageFromSelection](state, action) {
      const pageId = action.payload

      const newState = state.filter((id) => id !== pageId)

      return newState
    },
    [rawActions.removePagesFromSelection](state, action) {
      const pageIds = action.payload

      const newState = state.filter((id) => !pageIds.includes(id))

      return newState
    },
    [rawActions.clearSelection](state, action) {
      return [...defaultState]
    },
    [combineActions(rawActions.deleteGalleryItem)](state, action) {
      const { pageId } = action.payload

      const newState = state.filter((id) => id !== pageId)

      return newState
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
