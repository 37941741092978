import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getPredictionSet } from '../actions'

export default ({ id, onError }) => {
  const payload = useMemo(() => ({ predictionSetId: id }), [id])

  const { loading, response: predictionSet } = useApiRequest({ request: getPredictionSet.request, payload, onError })

  return { loading, predictionSet }
}
