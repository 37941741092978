import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = null

export default handleActions(
  {
    [actions.getAdminBillingSettingsSuccess](state, { response }) {
      return response.data
    },
    [actions.updateBillingSettingsSuccess](state, { payload }) {
      const { IsBillingEnabled, ExpirationInDays, SessionPrice } = payload
      return { ...state, IsBillingEnabled, ExpirationInDays, SessionPrice }
    },
  },
  defaultState
)
