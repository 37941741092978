import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.sendFormAnswerRequest](state, action) {
      return true
    },
    [rawActions.sendFormAnswerSuccess](state, action) {
      return defaultState
    },
    [rawActions.resetFormsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
