import pluralize from 'pluralize'
import _ from 'lodash'

import { DEFAULT_SESSIONS_TITLE } from '../../constants'

export class SessionTitleFormatter {
  title = DEFAULT_SESSIONS_TITLE

  setSessionTitle(title) {
    if (!title) {
      return
    }

    this.title = title
  }

  format(text) {
    const multipleSessionsTitle = this.title
    const singleSessionTitle = pluralize.singular(multipleSessionsTitle)

    return text
      .replace('Sessions', _.capitalize(multipleSessionsTitle))
      .replace('sessions', _.lowerFirst(multipleSessionsTitle))
      .replace('Session', _.capitalize(singleSessionTitle))
      .replace('session', _.lowerFirst(singleSessionTitle))
  }
}

export const sessionTitleFormatter = new SessionTitleFormatter()
