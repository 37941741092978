const styles = (theme) => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      color: theme.palette.common.white,
      fontSize: '20px',
      fontFamily: 'inherit',
    },
  }
}

export default styles
