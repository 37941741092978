import { put, takeLatest, all } from 'redux-saga/effects'

import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { regenerateFtpPassword, updateFtpServerSettings } from '../actions'

function* onUpdateFailed() {
  yield put(onAddErrorNotification({ message: 'There was an error updating the FTP settings' }))
}

function* onRegenerateFtpPasswordSuccess() {
  yield put(onAddSuccessNotification({ message: 'New FTP password has been sent' }))
}

function* onRegenerateFtpPasswordFailed() {
  yield put(onAddErrorNotification({ message: 'There was an error updating the FTP password' }))
}

function* updateFtpServerSettingsSagas() {
  yield all([
    takeLatest(updateFtpServerSettings.failed, onUpdateFailed),
    takeLatest(regenerateFtpPassword.success, onRegenerateFtpPasswordSuccess),
    takeLatest(regenerateFtpPassword.failed, onRegenerateFtpPasswordFailed),
  ])
}

export default updateFtpServerSettingsSagas
