import { combineActions, handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../../actions'

const emptyProvider = {
  Id: 0,
  Name: '',
  AzureTenantId: '',
  AzureClientId: '',
  AzureClientSecret: '',
}

const initialState = {
  open: false,
  fetchStatus: FetchStatus.Idle,
  isEditMode: false,
  provider: emptyProvider,
}

export default handleActions(
  {
    [rawActions.openPowerBIProviderModal]: (state, action) => {
      const provider = action.payload
      return { ...state, open: true, provider: provider || emptyProvider, isEditMode: !!provider }
    },
    [rawActions.closePowerBIProviderModal]: (_state, _action) => {
      return { ...initialState }
    },
    [combineActions(
      rawActions.createPowerBIReportProvider.request,
      rawActions.updatePowerBIReportProviderConfiguration.request
    )]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [combineActions(
      rawActions.createPowerBIReportProvider.success,
      rawActions.updatePowerBIReportProviderConfiguration.success
    )]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Loaded }
    },
    [combineActions(
      rawActions.createPowerBIReportProvider.failed,
      rawActions.updatePowerBIReportProviderConfiguration.failed
    )]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
  },
  initialState
)
