import { memo } from 'react'

import { InsertInvitationOutlined } from '@material-ui/icons'

import Placeholder from '../Placeholder'

const CalendarsListIsEmptyPlaceholder = () => {
  return <Placeholder icon={InsertInvitationOutlined} text='Calendars list is empty...' />
}

export default memo(CalendarsListIsEmptyPlaceholder)
