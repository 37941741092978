import { memo, useCallback } from 'react'
import classNames from 'classnames'

import { Tabs } from '@material-ui/core'

import { MainScreenContentType } from '@tabeeb/enums'

import MobileZoomButtons from '@tabeeb/modules/playerToolbar/components/MobileZoomButtons'
import VideoPlayerControls from '@tabeeb/modules/player/containers/ShowPlayerControlsContainer'

import AnnotationControls from '../AnnotationControls'

import AIFlagButton from '../AIFlagButton'
import CursorButton from '../CursorButton'
import DistanceMeasurementsButton from '../DistanceMeasurementsButton'
import ExportButton from '../ExportButton'
import PenButton from '../PenButton'
import TransformImageButton from '../TransformImageButton'
import FlagButton from '../FlagButton'
import TextButton from '../TextButton'
import Pagination from '../Pagination'
import UndoButton from '../UndoButton'
import RedoButton from '../RedoButton'
import ShapeButton from '../ShapeButton'
import ZoomButtons from '../ZoomButtons'
import CompassButton from '../CompassButton'
import PaletteButton from '../PaletteButton'
import DownloadButton from '../DownloadButton'
import GeosearchButton from '../GeosearchButton'
import TrackingButton from '../TrackingButton'
import HideAnnotationsButton from '../HideAnnotationsButton'
import RegenerateStructureModelButton from '../RegenerateStructureModelButton'
import Regenerate2dLineDrawingButton from '../Regenerate2dLineDrawingButton'
import SnippingToolButton from '../SnippingToolButton'
import ModelButton from '../ModelButton'
import ViewerBackgroundButton from '../ViewerBackgroundButton'

import VolumeButton from '../VolumeButton'
import PlayerTimer from '../PlayerTimer'
import PlaybackButton from '../PlaybackButton'
import FullScreenButton from '../FullScreenButton'

import StartRecordingButton from '../StartRecordingButton'
import PauseRecordingButton from '../PauseRecordingButton'
import StopRecordingButton from '../StopRecordingButton'
import RecordingTimer from '../RecordingTimer'

import RecordingControls from '../RecordingControls'

import SnapshotButton from '../SnapshotButton'
import FlashButton from '../FlashButton'
import MuteAudioButton from '../MuteAudioButton'
import MuteVideoButton from '../MuteVideoButton'
import ScreenSharingButton from '../ScreenSharingButton'

import PresenterControls from '../PresenterControls'

import ToolbarGate from '../ToolbarGate'
import WebZoomButtons from '../WebZoomButtons'

import useStyles from './styles'
import PointCloudControls from '../PointCloudControls'
import { FieldOfViewButtonContainer } from '../FieldOfViewButton'
import { PointCloudSettingsButtonContainer } from '../PointCloudSettingsButton'
import { PointCloudCameraNavigationButtonContainer } from '../PointCloudCameraNavigationButton'
import PointCloudMeasurementsButton from '../PointCloudMeasurementsButton'
import RecognizeTableButton from '../RecognizeTableButton'
import PointCloudDocumentationButton from '../PointCloudDocumentationButton'
import CallDuration from '../CallDuration'

const PlayerToolbar = () => {
  const classes = useStyles()

  const LeftSidePanel = useCallback(
    () => (
      <div className={classNames(classes.panel, classes.side, classes.left)}>
        <ToolbarGate contentType={MainScreenContentType.Gallery}>
          <Pagination />
          <AnnotationControls>
            <UndoButton />
            <RedoButton />
          </AnnotationControls>
        </ToolbarGate>
        <ToolbarGate contentType={MainScreenContentType.Call}>
          <RecordingControls>
            <StartRecordingButton />
            <PauseRecordingButton />
            <StopRecordingButton />
            <RecordingTimer />
          </RecordingControls>
        </ToolbarGate>
      </div>
    ),
    [classes.left, classes.panel, classes.side]
  )

  const CenterPanel = useCallback(
    () => (
      <div className={classNames(classes.panel, classes.center)}>
        <ToolbarGate contentType={MainScreenContentType.Tracking}>
          <TrackingButton />
        </ToolbarGate>
        <ToolbarGate contentType={MainScreenContentType.PointCloudGeoSearch}>
          <GeosearchButton />
        </ToolbarGate>
        <ToolbarGate contentType={MainScreenContentType.Gallery}>
          <RegenerateStructureModelButton />
          <Regenerate2dLineDrawingButton />
          <CompassButton />
          <DownloadButton />
          <HideAnnotationsButton />
          <AnnotationControls>
            <CursorButton />
            <PenButton />
            <ShapeButton />
            <TextButton />
            <PaletteButton />
            <DistanceMeasurementsButton />
          </AnnotationControls>
          <PointCloudControls>
            <ModelButton />
            <ViewerBackgroundButton />
            <FieldOfViewButtonContainer />
            <PointCloudSettingsButtonContainer />
            <PointCloudCameraNavigationButtonContainer />
            <PointCloudMeasurementsButton />
            <PaletteButton />
          </PointCloudControls>
          <RecognizeTableButton />

          <div className={classes.centerPlaceholder} />

          <GeosearchButton />
          <TrackingButton />
          <AnnotationControls>
            <AIFlagButton />
            <FlagButton />
          </AnnotationControls>
          <TransformImageButton />
          <ExportButton />
          <SnippingToolButton />
        </ToolbarGate>
        <ToolbarGate contentType={MainScreenContentType.Call}>
          <PresenterControls>
            <SnapshotButton />
            <FlashButton />
          </PresenterControls>
          <MuteVideoButton />
          <MuteAudioButton />
          <ScreenSharingButton />
        </ToolbarGate>
      </div>
    ),
    [classes.center, classes.centerPlaceholder, classes.panel]
  )

  const RightSidePanel = useCallback(
    () => (
      <div className={classNames(classes.panel, classes.side, classes.right)}>
        <ToolbarGate contentType={MainScreenContentType.Gallery}>
          <ZoomButtons />
          <VideoPlayerControls>
            <PlaybackButton />
            <PlayerTimer />
            <VolumeButton />
            <FullScreenButton />
          </VideoPlayerControls>
          <PointCloudControls>
            <PointCloudDocumentationButton />
          </PointCloudControls>
        </ToolbarGate>
        <ToolbarGate contentType={MainScreenContentType.Call}>
          <CallDuration />
          <MobileZoomButtons />
          <WebZoomButtons />
        </ToolbarGate>
      </div>
    ),
    [classes.panel, classes.right, classes.side]
  )

  return (
    <section className={classes.container}>
      <Tabs
        value={false}
        className={classes.toolbar}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
      >
        <LeftSidePanel />
        <CenterPanel />
        <RightSidePanel />
      </Tabs>
    </section>
  )
}

export default memo(PlayerToolbar)
