import { useState, useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { Button, IconButton, Tooltip } from '@material-ui/core'

import PolicyIcon from '@material-ui/icons/Policy'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AddIcon from '@material-ui/icons/Add'

import AppRoutes from '@tabeeb/routes'
import { useStyles } from './styles'
import CreateTenantDialog from '../CreateTenantDialog'

const HeaderControls = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleEditGlobalTermsOfUseButtonClick = useCallback(() => {
    history.push(AppRoutes.termsOfUse)
  }, [history])

  const handleEditPrivacyPolicyButtonClick = useCallback(() => {
    history.push(AppRoutes.privacyPolicy)
  }, [history])

  const [isCreateTenantDialogOpen, setIsCreateTenantDialogOpen] = useState(false)

  const handleCreateTenantButtonClick = useCallback(() => {
    setIsCreateTenantDialogOpen(true)
  }, [])

  const handleCreateTenantDialogClose = useCallback(() => {
    setIsCreateTenantDialogOpen(false)
  }, [])

  return (
    <div className={classes.root}>
      <div>
        <Tooltip title='Edit global terms of service'>
          <IconButton onClick={handleEditGlobalTermsOfUseButtonClick}>
            <AssignmentIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <Tooltip title='Edit privacy policy'>
          <IconButton onClick={handleEditPrivacyPolicyButtonClick}>
            <PolicyIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <Button
          startIcon={<AddIcon />}
          disableElevation
          variant='contained'
          color='primary'
          onClick={handleCreateTenantButtonClick}
        >
          Add
        </Button>
        <CreateTenantDialog isOpen={isCreateTenantDialogOpen} onClose={handleCreateTenantDialogClose} />
      </div>
    </div>
  )
}

export default HeaderControls
