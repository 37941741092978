import { put, takeLatest, all } from 'redux-saga/effects'

import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import { getConfigForUpdate } from '../actions'

function* onGetConfigForUpdateFailed() {
  yield put(onAddErrorNotification({ message: 'Failed to load the config' }))
}

function* getConfig() {
  yield all([takeLatest(getConfigForUpdate.failed, onGetConfigForUpdateFailed)])
}

export default getConfig
