import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_CLASSIFICATION_ANNOTATIONS_TIMELINE')

export const openAddClassificationAnnotationsDialog = createAction('OPEN_ADD_CLASSIFICATION_ANNOTATIONS_DIALOG')
export const closeAddClassificationAnnotationsDialog = createAction('CLOSE_ADD_CLASSIFICATION_ANNOTATIONS_DIALOG')

export const openClassificationAnnotationsTimeline = createAction('OPEN_CLASSIFICATION_ANNOTATIONS_TIMELINE')
export const closeClassificationAnnotationsTimeline = createAction('CLOSE_CLASSIFICATION_ANNOTATIONS_TIMELINE')

export const addClassificationAnnotations = createApiActions('ADD_CLASSIFICATION_ANNOTATIONS')

export const addPageToClassificationAnnotationsPages = createAction('ADD_PAGE_TO_CLASSIFICATION_ANNOTATIONS_PAGES')
export const addPagesToClassificationAnnotationsPages = createAction('ADD_PAGES_TO_CLASSIFICATION_ANNOTATIONS_PAGES')
export const deletePageFromClassificationAnnotationsPages = createAction(
  'DELETE_PAGE_FROM_CLASSIFICATION_ANNOTATIONS_PAGES'
)
export const updateClassificationAnnotationsPages = createAction('UPDATE_PAGES_CLASSIFICATION_ANNOTATIONS_PAGES')
