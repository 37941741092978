import { combineReducers } from 'redux'
import contentForms from './contentForms'
import currentForm from './currentForm'
import availableForms from './availableForms'
import selectedUserId from './selectedUserId'
import loadingFormAnswers from './loadingFormAnswers'
import loadingAvailableForms from './loadingAvailableForms'
import answerStatusStatistics from './answerStatusStatistics'
import answerInProgress from './answerInProgress'
import contentFormsLoadingState from './contentFormsLoadingState'
import displayAnswersMode from './displayAnswersMode'
import isAttachDetachProcessing from './isAttachDetachProcessing'
import formExportInProgress from './formExportInProgress'
import lastAttachedForm from './lastAttachedForm'
import isSwitchFormDialogOpen from './isSwitchFormDialogOpen'
import exportFormsToCsvOptionState from './exportFormsToCsvOptionState'
import isExportFormsToCsvDialogOpen from './isExportFormsToCsvDialogOpen'
import infoMessageCondition from './infoMessageCondition'
import loadingFormAnswersFromFileState from './loadingFormAnswersFromFileState'
import answerStatusFilter from './answerStatusFilter'
import recordSavingInProgress from './recordSavingInProgress'
import currentFolder from './currentFolder'
import foldersList from './foldersList'
import usersFilter from './usersFilter'

export default combineReducers({
  availableForms,
  contentForms,
  currentForm,
  selectedUserId,
  loadingFormAnswers,
  loadingAvailableForms,
  answerStatusStatistics,
  answerInProgress,
  contentFormsLoadingState,
  displayAnswersMode,
  isAttachDetachProcessing,
  formExportInProgress,
  lastAttachedForm,
  isSwitchFormDialogOpen,
  exportFormsToCsvOptionState,
  isExportFormsToCsvDialogOpen,
  infoMessageCondition,
  loadingFormAnswersFromFileState,
  answerStatusFilter,
  recordSavingInProgress,
  currentFolder,
  foldersList,
  usersFilter,
})
