import { memo } from 'react'
import PropTypes from 'prop-types'
import { SearchResultRowBtn } from '@tabeeb/modules/contentSharings/constants/enums'
import { StyledButton } from './styles'

const SearchResultRowButton = ({ button, handleButtonClick }) => {
  return button === SearchResultRowBtn.Remove ? (
    <StyledButton onClick={handleButtonClick} variant='contained' color='primary'>
      {button}
    </StyledButton>
  ) : (
    <StyledButton onClick={handleButtonClick} variant='outlined'>
      {button}
    </StyledButton>
  )
}

SearchResultRowButton.propTypes = {
  button: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
}

export default memo(SearchResultRowButton)
