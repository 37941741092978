import { put, takeLatest, all } from 'redux-saga/effects'

import { ErrorCode } from '@tabeeb/enums'
import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function getErrorMessage(Error) {
  switch (Error.Code) {
    case ErrorCode.ProjectNotDeleted:
    case ErrorCode.ProjectNotFound:
    case ErrorCode.NoDeleteProjectWithIterations:
      return Error.Message
    default:
      return 'Failed to delete a project.'
  }
}

function* onDeleteProjectFailed(action) {
  const message = getErrorMessage(action.response.data.Error)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* onDeleteProjectSuccess(action) {
  yield put(notificationActions.onAddInfoNotification({ message: 'Project deleted successfully.' }))
}

function* deleteProject() {
  yield all([
    takeLatest(actions.deleteProject.failed, onDeleteProjectFailed),
    takeLatest(actions.deleteProject.success, onDeleteProjectSuccess),
  ])
}

export default deleteProject
