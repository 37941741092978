import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VolumeUp, VolumeOff } from '@material-ui/icons'
import { Box, Fade, Popper, Slider } from '@material-ui/core'

import { playerActions, playerSelectors } from '@tabeeb/modules/player'
import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import ToolbarButton from '../ToolbarButton'

import useStyles from './styles'

const VolumeButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const volume = useSelector(playerSelectors.getVolume)

  const onClick = () => {
    dispatch(playerActions.onPlayerChangeVolume(volume ? 0 : 0.3))
  }

  const onChangeVolume = (_, newValue) => {
    dispatch(playerActions.onPlayerChangeVolume(newValue))
  }

  return (
    <Box onMouseEnter={onOpen} onMouseLeave={onClose}>
      <ToolbarButton
        ref={anchorRef}
        onClick={onClick}
        title={volume ? 'Mute' : 'Unmute'}
        Icon={volume ? VolumeUp : VolumeOff}
      />
      <Popper open={open} anchorEl={anchorRef.current} placement='top' transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box className={classes.slider}>
              <Slider
                min={0}
                max={1}
                step={0.1}
                defaultValue={0.5}
                value={volume}
                onChange={onChangeVolume}
                orientation='vertical'
              />
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  )
}

export default memo(VolumeButton)
