import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: '270px',
  },
  table: {
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
    },
    '& .MuiTableCell-root': {
      padding: '2px 5px 0px !important',
    },
  },
}))
