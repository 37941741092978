import { put, takeLatest, all, select, race, take } from 'redux-saga/effects'

import _ from 'lodash'

import { ControlType } from '@tabeeb/enums'

import { getShowMenuAI } from '@tabeeb/modules/appConfigState/selectors'

import { enableFormPermissionsSyncByForm, updateFormPermissionsByForm } from '@tabeeb/modules/formSettings/actions'
import { onAddSuccessNotification, onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import * as rawActions from '../actions'
import { getFormInfoControlsList } from '../selectors'
import addVQAModel from './addVQAModel'
import formControls from './formControls'

function* getFormInfoById(action) {
  const formId = action.payload

  yield put(rawActions.setFormInfoLoading(true))
  yield put(rawActions.getFormByIdRequest(formId))
  yield put(rawActions.getTagsListRequest())
}

function* getFormByIdSuccess(action) {
  const form = action.response.data
  yield put(rawActions.setOpenedForm(form))

  const actions =
    form?.Actions?.map((actionProps) => ({
      ControlType: ControlType.Action,
      ...actionProps,
    })) || []

  const isAIEnabled = yield select(getShowMenuAI)
  const counters =
    form.Counters?.map((counterProps) => ({
      ControlType: ControlType.Counter,
      disabled: !isAIEnabled,
      ...counterProps,
    })) || []

  const controls = form?.Controls || []

  const controlsList = [...controls, ...actions, ...counters].sort((a, b) => a.Order - b.Order)
  yield put(rawActions.updateFormInfoControlsList(controlsList))

  if (counters.length > 0 && isAIEnabled) yield put(rawActions.getAiClassesForFormInfo.request({}))
}

function* getTagsListSuccess(action) {
  const tags = action.response.data
  yield put(rawActions.setAvailableTags(tags))
}

function* addTagToControlSuccess(action) {
  const tag = action.response.data
  yield put(rawActions.addTagToFormControl(tag))
}

function* removeTagFromControlSuccess(action) {
  const { Id, FormControlId } = action.payload
  yield put(rawActions.removeTagFromFormControl({ controlId: FormControlId, formControlTagId: Id }))
}

function* onGetAiClassesSuccess(action) {
  const aiClasses = action.response.data

  const controlList = yield select(getFormInfoControlsList)
  const updatedControls = controlList.map((control) => {
    if (control.ControlType === ControlType.Counter)
      control.AiClassName = aiClasses.find((c) => c.Id === control.AIObjectId)?.Name

    return control
  })

  yield put(rawActions.updateFormInfoControlsList(updatedControls))
}

function* handleSaveFormSettings({ payload: { settings, formId } }) {
  if (settings.permissions.byContentRoles.sync) {
    yield put(enableFormPermissionsSyncByForm.request({ formId }))
  } else {
    const permissionPatch = _.values(
      _.pick(settings.permissions.byContentRoles.override, settings.permissions.byContentRoles.editableContentRoles)
    )

    yield put(updateFormPermissionsByForm.request({ permissions: permissionPatch, formId }))
  }

  const { success } = yield race({
    success: take([updateFormPermissionsByForm.success, enableFormPermissionsSyncByForm.success]),
    failed: take([updateFormPermissionsByForm.failed, enableFormPermissionsSyncByForm.failed]),
  })

  if (success) {
    yield put(onAddSuccessNotification({ message: 'Form settings have been updated successfully' }))
  } else {
    yield put(onAddErrorNotification({ message: 'Fail to update form settings' }))
  }
}

function* formStatisticsPageSaga() {
  yield all([
    takeLatest(rawActions.getFormInfoById, getFormInfoById),
    takeLatest(rawActions.getFormByIdSuccess, getFormByIdSuccess),
    takeLatest(rawActions.getAiClassesForFormInfo.success, onGetAiClassesSuccess),
    takeLatest(rawActions.getTagsListSuccess, getTagsListSuccess),
    takeLatest(
      [rawActions.addTagToControl.success, rawActions.createTagAndAddToControl.success],
      addTagToControlSuccess
    ),
    takeLatest(rawActions.removeTagFromControlSuccess, removeTagFromControlSuccess),
    takeLatest(rawActions.saveFormSettings, handleSaveFormSettings),
    addVQAModel(),
    formControls(),
  ])
}

export default formStatisticsPageSaga
