import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'
import { actualColumns } from '../services/enums'

const defaultState = actualColumns

export default handleActions(
  {
    [rawActions.setTableColumns](state, action) {
      return action.payload
    },
    [rawActions.getTenantContentsDashboardColumns.success](state, action) {
      const columnsResult = action.response.data
      const existingPropNames = actualColumns.map((c) => c.PropName)
      const primaryColumns = columnsResult.PrimaryColumns.map((c) => c.Name)
      const filteredPrimaryColumns = actualColumns.filter((actCol) => primaryColumns.includes(actCol.CellTitle))
      const existingCellTitles = filteredPrimaryColumns.map((c) => c.CellTitle)

      const mappedCustomColumns = columnsResult.CustomColumns.map((col) => {
        let uniquePropName = col.Name

        let tempId = 1
        while (existingPropNames.includes(uniquePropName)) {
          uniquePropName = `${col.Name}_${tempId}`
          tempId++
        }

        existingPropNames.push(uniquePropName)

        let uniqueCellTitle = col.Name

        tempId = 1
        while (existingCellTitles.includes(uniqueCellTitle)) {
          uniqueCellTitle = `${col.Name}_${tempId}`
          tempId++
        }

        existingCellTitles.push(uniqueCellTitle)

        if (uniqueCellTitle !== col.Name) {
          uniqueCellTitle = `${uniqueCellTitle} ${`(${col.Name})`}`
        }

        const mappedCol = {
          PropName: uniquePropName,
          CellTitle: uniqueCellTitle,
        }

        return mappedCol
      })

      return [...filteredPrimaryColumns, ...mappedCustomColumns]
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
