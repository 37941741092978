export default (theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 10px 6px',
      borderRight: '1px solid #eee',
      borderLeft: '1px solid #eee',
    },
  }
}
