import { memo } from 'react'
import PropTypes from 'prop-types'
import { Circle as GoogleMapCircle } from '@react-google-maps/api'

import './styles.less'

const Circle = ({ start, end, color, ...rest }) => {
  const center = new google.maps.LatLng((end.Latitude + start.Latitude) / 2, (end.Longitude + start.Longitude) / 2)
  const startPoint = new google.maps.LatLng(start.Latitude, start.Longitude)
  const endPoint = new google.maps.LatLng(end.Latitude, end.Longitude)

  const radius = google.maps.geometry.spherical.computeDistanceBetween(startPoint, endPoint) / 2

  return (
    <GoogleMapCircle
      center={center}
      radius={radius}
      options={{
        fillColor: color,
        strokeColor: color,
      }}
      {...rest}
    />
  )
}

Circle.propTypes = {
  color: PropTypes.string.isRequired,
  start: PropTypes.shape({
    Latitude: PropTypes.number.isRequired,
    Longitude: PropTypes.number.isRequired,
  }),
  end: PropTypes.shape({
    Latitude: PropTypes.number.isRequired,
    Longitude: PropTypes.number.isRequired,
  }),
}

export default memo(Circle)
