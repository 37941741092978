import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { contentViewerSelectors, contentViewerActions } from '@tabeeb/modules/contentViewer'

import * as playerActions from '@tabeeb/modules/player/actions'
import * as annotationActions from '../actions'
import * as annotationsSelectors from '../selectors'

import AnnotationContainer from './AnnotationContainer'
import LinkedPage from '../components/LinkedPage'

const SpatialViewPlaceholderURL = new URL('@tabeeb/assets/images/spatial-view-placeholder.png', import.meta.url)

class LinkedPageContainer extends AnnotationContainer {
  constructor(props) {
    super(props)

    this.hoverProps = {
      onMouseEnter: this._handleMouseEnter,
      onMouseLeave: this._handleMouseLeave,
    }

    this.selectionProps = {
      onClick: this._handleClick,
    }

    this.contextMenuProps = {
      onContextMenu: this._handleContextMenu,
    }

    this.dragAndDropProps = {
      draggable: true,
      onDragStart: this._handleDragStart,
      onDragEnd: this._handleDragEnd,
    }
  }

  _handleDragEnd = (e) => {
    const { target } = e
    const { annotation } = this.state
    const { contentScale } = this.props

    const newAnnotation = {
      ...annotation,
      Anchor: {
        X: target.x() * contentScale,
        Y: target.y() * contentScale,
      },
    }
    this.setState({ annotation: newAnnotation })
    this._handleEndUpdate(e)
  }

  _handleClick = (e) => {
    if (e.evt.which !== 1) {
      return
    }

    const { annotation } = this.state

    const {
      contentViewerActions: { setSpatialViewStartPageId, goToPage, setLoadingInProgress },
      playerActions: { seekVideoToTimestamp },
    } = this.props

    setSpatialViewStartPageId({ pageId: annotation.PageId })
    seekVideoToTimestamp(annotation.Timestamp * 1000)
    setLoadingInProgress()
    goToPage(annotation.LinkedPageId)
  }

  render() {
    const { contentScale, url, opacity } = this.props
    const { hovered, annotation } = this.state

    const props = {
      url,
      annotation,
      annotationRef: this.annotationRef,
      contentScale,
      opacity,
      hovered,
      hoverProps: this.hoverProps,
      selectionProps: this.selectionProps,
      contextMenuProps: this.contextMenuProps,
      dragAndDropProps: this.dragAndDropProps,
    }

    return <LinkedPage {...props} />
  }
}

LinkedPageContainer.propTypes = {
  contentScale: PropTypes.number.isRequired,
  currentAnnotation: PropTypes.object,
  url: PropTypes.string.isRequired,
}

const mapStateToProps = (state, { Id }) => {
  const currentAnnotation = annotationsSelectors.getAnnotationById(state, { Id })
  let url = null
  if (currentAnnotation.Timestamp !== -1) {
    const page = gallerySelectors.getGalleryItemById(state, { id: currentAnnotation.LinkedPageId })
    if (page) {
      url = page.thumbnailUrl
    }
  } else {
    url = SpatialViewPlaceholderURL
  }

  return {
    contentSize: contentViewerSelectors.getContentSize(state),
    contentScale: contentViewerSelectors.getContentScale(state),
    scale: contentViewerSelectors.getScale(state),
    opacity: annotationsSelectors.getAnnotationOpacity(state, { Id }),
    selected: false,
    currentAnnotation,
    url,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    annotationActions: bindActionCreators(annotationActions, dispatch),
    contentViewerActions: bindActionCreators(contentViewerActions, dispatch),
    playerActions: bindActionCreators(playerActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedPageContainer)
