import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(
      rawActions.getPagesStatisticsByAiObjectsForProjectTraining.request,
      rawActions.getPagesStatisticsByFormsForProjectTraining.request
    )](state, action) {
      return true
    },
    [combineActions(rawActions.closePagesAnalysisDialog, rawActions.trainProject.request)](state, action) {
      return false
    },
  },
  defaultState
)
