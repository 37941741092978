import { memo } from 'react'
import PropTypes from 'prop-types'

import { Field } from 'formik'

import { FormikTextField } from '@tabeeb/shared/forms'

const DurationField = ({ name, label, disabled }) => {
  return (
    <Field
      name={name}
      label={label}
      disabled={disabled}
      inputProps={{ step: 1, min: 1 }}
      component={FormikTextField}
      type='number'
      size='small'
    />
  )
}

DurationField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default memo(DurationField)
