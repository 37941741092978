import { memo } from 'react'
import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import { Draggable } from 'react-beautiful-dnd'

import './styles.less'

const TimelineItem = ({ item, index, handleDeleteItem }) => {
  const handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleDeleteItem(index)
  }

  return (
    <Draggable draggableId={`${index}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className='timeline-list-item'
        >
          <IconButton size='small' style={{ position: 'absolute', top: 2, right: 2 }} onClick={handleDelete}>
            <i className='material-icons' style={{ color: 'black', backgroundColor: 'white', borderRadius: '100%' }}>
              remove_circle
            </i>
          </IconButton>
          <img src={item.thumbnailUrl} alt='pic' />
        </div>
      )}
    </Draggable>
  )
}

TimelineItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
}

export default memo(TimelineItem)
