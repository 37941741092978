import { handleActions } from 'redux-actions'

import { getFtpServerSettings, updateFtpServerSettings } from '../actions'

interface FtpSettingsState {
  ftpHost: string
  ftpUser: string
  ftpPassword: string
}

const defaultState: FtpSettingsState = {
  ftpHost: '',
  ftpUser: '',
  ftpPassword: '',
}

export default handleActions<FtpSettingsState, any>(
  {
    [getFtpServerSettings.success.toString()]: (state, action) => {
      const typedAction = action as ReturnType<typeof getFtpServerSettings.success>
      const ftpSettings = typedAction.response.data

      return {
        ftpHost: ftpSettings.FtpHost,
        ftpUser: ftpSettings.FtpUserName,
        ftpPassword: ftpSettings.FtpPassword,
      }
    },
    [updateFtpServerSettings.success.toString()]: (state, action) => {
      const typedAction = action as ReturnType<typeof updateFtpServerSettings.success>
      const ftpSettings = typedAction.response.data

      return {
        ftpHost: ftpSettings.FtpHost,
        ftpUser: ftpSettings.FtpUserName,
        ftpPassword: ftpSettings.FtpPassword,
      }
    },
  },
  defaultState
)
