import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { HealthDataTypes, SystemOfMeasures } from '@tabeeb/enums'

import AddHealthDataDialog from '@tabeeb/modules/healthData/components/AddHealthDataDialog'
import HealthStatisticsChart from '@tabeeb/modules/healthData/components/HealthStatisticsChart'
import { getInchesForSave } from '@tabeeb/modules/healthData/helper/measurementConversion'
import { switchTimeLine } from '@tabeeb/modules/healthData/helper/chartsHelper'
import { getMeasureUnitIdByMeasureType } from '@tabeeb/modules/healthData/helper'
import { createConnection, terminateConnection } from '@tabeeb/modules/signalr/actions'
import { healthDeviceHubName } from '@tabeeb/modules/signalr/services/constants'

const HealthStatisticsCharts = ({
  showAddHealthDataOption,
  userId,
  userSystemOfMeasure,
  handleAddHealthData,
  chartsHealthData,
  setChartInterval,
}) => {
  const dispatch = useDispatch()
  const [chartHealthDataToSupplement, setChartHealthDataToSupplement] = useState(null)
  const [isOpenAddHealthDataDialog, setIsOpenAddHealthDataDialog] = useState(false)

  const handleToggleTimeLine = useCallback(
    (newInterval, dataType) => {
      if (newInterval !== null) {
        setChartInterval({ dataType, interval: newInterval })
        switchTimeLine(
          newInterval,
          `chart-${dataType}`,
          chartsHealthData.find((e) => e.dataType === dataType).convertedData
        )
      }
    },
    [chartsHealthData, setChartInterval]
  )

  const handleAddHealthDataDialogOpen = useCallback((chartHealthData) => {
    setChartHealthDataToSupplement(chartHealthData)
    setIsOpenAddHealthDataDialog(true)
  }, [])

  const handleAddHealthDataDialogClose = useCallback(() => setIsOpenAddHealthDataDialog(false), [])

  const handleAddDataSubmit = useCallback(
    (values) => {
      const { SystemOfMeasure, HealthDataTypeId } = values
      if (SystemOfMeasure === SystemOfMeasures.Imperial && HealthDataTypeId === HealthDataTypes.Height) {
        values.Height = getInchesForSave(values.HeightFt, values.HeightIn)
        delete values.HeightFt
        delete values.HeightIn
      }
      delete values.SystemOfMeasure
      delete values.HealthDataTypeId

      const value = Object.values(values).shift()

      const data = [
        {
          UserId: userId,
          DeviceId: null,
          MeasureId: HealthDataTypeId,
          MeasureUnitId: getMeasureUnitIdByMeasureType(HealthDataTypeId, SystemOfMeasure),
          Value: Number(value),
        },
      ]

      handleAddHealthData(data)
      setIsOpenAddHealthDataDialog(false)
    },
    [handleAddHealthData, userId]
  )

  useEffect(() => {
    dispatch(createConnection({ hubName: healthDeviceHubName }))

    return () => {
      dispatch(terminateConnection({ hubName: healthDeviceHubName }))
    }
  }, [dispatch])

  return (
    <>
      {chartsHealthData.map((chartHealthData) => (
        <HealthStatisticsChart
          key={chartHealthData.dataType}
          handleToggleTimeLine={handleToggleTimeLine}
          showAddHealthDataOption={showAddHealthDataOption}
          handleAddHealthDataDialogOpen={handleAddHealthDataDialogOpen}
          chartHealthData={chartHealthData}
          userSystemOfMeasure={userSystemOfMeasure}
        />
      ))}
      <AddHealthDataDialog
        isOpen={isOpenAddHealthDataDialog}
        chartHealthDataToSupplement={chartHealthDataToSupplement}
        onClose={handleAddHealthDataDialogClose}
        handleSubmit={handleAddDataSubmit}
        userSystemOfMeasure={userSystemOfMeasure}
      />
    </>
  )
}

HealthStatisticsCharts.propTypes = {
  showAddHealthDataOption: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  userSystemOfMeasure: PropTypes.number,
  handleAddHealthData: PropTypes.func,
  chartsHealthData: PropTypes.array.isRequired,
  setChartInterval: PropTypes.func.isRequired,
}

HealthStatisticsCharts.defaultProps = {
  showAddHealthDataOption: false,
}

export default HealthStatisticsCharts
