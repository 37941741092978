import { alpha } from '@material-ui/core/styles'

export default (theme) => {
  return {
    button: {
      minWidth: 0,
      padding: theme.spacing() / 2,
      margin: theme.spacing() / 2,
      marginLeft: 0,
      height: 26,
      boxShadow: theme.shadows[2],

      color: theme.palette.secondary.light,
      background: alpha(theme.palette.primary.light, 0.5),

      '&.Mui-disabled': {
        cursor: 'default',

        color: theme.palette.secondary.light,
        background: alpha(theme.palette.primary.light, 0.5),
      },

      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.7),
      },
    },
    muted: {
      color: theme.palette.grey[400],
      background: theme.palette.background.user.action,

      '&.Mui-disabled': {
        color: theme.palette.grey[400],
        background: theme.palette.background.user.action,
      },

      '&:hover': {
        backgroundColor: alpha(theme.palette.background.user.action, 0.7),
      },
    },
  }
}
