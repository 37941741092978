import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

import styles from './styles'

const Error = ({ classes }) => {
  return (
    <div className={classes.container}>
      <ErrorIcon className={classes.icon} />
      <Typography className={classes.text}>
        The media could not be loaded, either because the server or network failed or because the format is not
        supported
      </Typography>
    </div>
  )
}

Error.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(Error)
