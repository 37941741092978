import { useSelector } from 'react-redux'

import routes from '@tabeeb/routes'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { getIsTrainingMaterialsEnabled } from '@tabeeb/modules/appConfigState/selectors'

import NavbarTab from '../NavbarTab'

const TrainingMaterials = ({ ...rest }) => {
  const display = useSelector(getIsTrainingMaterialsEnabled)

  const props = {
    item: {
      label: trainingMaterialTitleFormatter.format('Training materials'),
      to: TrainingMaterials.to,
    },
    display,
    ...rest,
  }

  return <NavbarTab {...props} />
}

TrainingMaterials.to = routes.trainingMaterials

export default TrainingMaterials
