import { combineActions, handleActions } from 'redux-actions'

import { AnswerStatusFilterOptions } from '@tabeeb/enums'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'

import * as actions from '../actions'
import {
  defaultState,
  handleCloseAction,
  handleFailedAction,
  handleFiltersUpdateAction,
  handleOpenAction,
  handleRequestAction,
  handleStatusUpdate,
  handleSuccessAction,
} from './shared'

const questionAnswersDiscussionState = {
  ...defaultState,
  question: undefined,
  filters: {
    userId: -1,
    statuses: [
      AnswerStatusFilterOptions.Approved,
      AnswerStatusFilterOptions.Rejected,
      AnswerStatusFilterOptions.Submitted,
    ],
  },
}

const questionAnswersDiscussion = handleActions(
  {
    [actions.openQuestionFormAnswersDialog](state, action) {
      return handleOpenAction(state, action)
    },
    [actions.closeQuestionFormAnswersDialog](state, action) {
      return handleCloseAction(state, action)
    },
    [actions.getFormAnswersByQuestionRequest](state, action) {
      return handleRequestAction(state, action)
    },
    [actions.getFormAnswersByQuestionFailed](state, action) {
      return handleFailedAction(state, action)
    },
    [actions.getFormAnswersByQuestionSuccess](state, action) {
      return handleSuccessAction(state, action)
    },
    [actions.updateQuestionFormAnswersFilters](state, action) {
      return handleFiltersUpdateAction(state, action)
    },
    [combineActions(
      signalrEvents[signalrConstants.tabeebHubName].onAnswerStatusUpdated,
      signalrEvents[signalrConstants.tabeebHubName].onAnswerStatusUpdatedByReviewer
    )](state, action) {
      return handleStatusUpdate(state, action)
    },
    [actions.resetQuestionAnswersState](state, action) {
      return questionAnswersDiscussionState
    },
  },
  questionAnswersDiscussionState
)

export default questionAnswersDiscussion
