import { handleActions } from 'redux-actions'
import * as actions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [actions.addPageTo2dLineDrawing](state, action) {
      return [...state, action.payload]
    },
    [actions.updatePages2dLineDrawing](state, action) {
      return [...action.payload]
    },
    [actions.deletePageFrom2dLineDrawing](state, action) {
      const index = action.payload
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [actions.close2dLineDrawingDialog](state, action) {
      return defaultValue
    },
    [actions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
