import { memo } from 'react'
import PropTypes from 'prop-types'

import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'
import { FlipCameraAndroidOutlined } from '@mui/icons-material'

import { View } from '@tabeeb/modules/pointCloud/constants'

import ToolbarButton from '../ToolbarButton'
import ToolbarMenu from '../ToolbarMenu'

const PointCloudCameraNavigationButton = ({ onChange }) => {
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const views = [
    { view: View.Top, title: 'Top', icon: new URL('@tabeeb/assets/icons/cube/top-view.png', import.meta.url) },
    { view: View.Bottom, title: 'Bottom', icon: new URL('@tabeeb/assets/icons/cube/bottom-view.png', import.meta.url) },
    { view: View.Front, title: 'Front', icon: new URL('@tabeeb/assets/icons/cube/front-view.png', import.meta.url) },
    { view: View.Back, title: 'Back', icon: new URL('@tabeeb/assets/icons/cube/back-view.png', import.meta.url) },
    { view: View.Left, title: 'Left', icon: new URL('@tabeeb/assets/icons/cube/left-view.png', import.meta.url) },
    { view: View.Right, title: 'Right', icon: new URL('@tabeeb/assets/icons/cube/right-view.png', import.meta.url) },
  ]

  const onViewChange = (view) => {
    onChange(view)
    onClose()
  }

  return (
    <>
      <ToolbarButton ref={anchorRef} Icon={FlipCameraAndroidOutlined} onClick={onOpen} title='Quick navigation' />
      <ToolbarMenu anchorEl={anchorRef.current} open={open} onClose={onClose}>
        {views.map(({ view, title, icon }) => (
          <MenuItem key={view} onClick={() => onViewChange(view)} title={title}>
            <ListItemIcon>
              <img alt={title} src={icon} height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{title}</ListItemText>
          </MenuItem>
        ))}
      </ToolbarMenu>
    </>
  )
}

export const PointCloudCameraNavigationButtonContainer = () => (
  <div id='point-cloud-camera-navigation-button-container' />
)

PointCloudCameraNavigationButton.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default memo(PointCloudCameraNavigationButton)
