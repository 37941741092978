import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    previewContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 18,
    },
    dot: {
      borderRadius: '100%',
      border: '1px solid #c4c4c4',
      maxWidth: 18,
      maxHeight: 18,
    },
  }
})
