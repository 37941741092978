import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Assignment } from '@material-ui/icons'
import { getContentForms } from '@tabeeb/modules/forms/selectors'
import { getFormsTitle, getFormTitle } from '@tabeeb/modules/appConfigState/selectors'

import Filter from '../Filter'

const FormFilter = ({ onChange, value }) => {
  const forms = useSelector(getContentForms)
  const formsTitle = useSelector(getFormsTitle)
  const formTitle = useSelector(getFormTitle)

  const defaultItem = useMemo(
    () => ({
      value: -1,
      title: 'All',
      subtitle: `Display answers for all ${formsTitle}`,
    }),
    [formsTitle]
  )

  const items = useMemo(
    () => [
      defaultItem,
      ...forms.map((form) => ({
        value: form.FormId,
        title: form.Name,
      })),
    ],
    [defaultItem, forms]
  )

  return <Filter value={value} icon={Assignment} label={formTitle} items={items} onChange={onChange} />
}

FormFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}

export default memo(FormFilter)
