import { handleActions, combineActions } from 'redux-actions'

import {
  updateRecordingState,
  onRecordingStarting,
  onRecordingStarted,
  onRecordingStopping,
  onRecordingStopped,
  onRecordingPausing,
  onRecordingPaused,
  onSetErrorState,
  onRecordingContinuing,
  resetRecordingState,
} from '../actions'

import { RecordingStatus } from '../../../Enums'

const defaultValue = RecordingStatus.stopped

export default handleActions(
  {
    [combineActions(
      updateRecordingState,
      onRecordingStarting,
      onRecordingStarted,
      onRecordingContinuing,
      onRecordingStopping,
      onRecordingStopped,
      onRecordingPausing,
      onRecordingPaused,
      onSetErrorState
    )](state, { payload }) {
      return typeof payload.recordingStatus !== 'undefined' ? payload.recordingStatus : state
    },
    [resetRecordingState](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
