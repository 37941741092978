import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Dialog, DialogContent, DialogTitle, TextField, IconButton, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import { getFormsTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import AvailableTenantFormContainer from '../../containers/AvailableTenantFormContainer'
import CircularProgressBar from '../../../presentation/components/CircularProgressBar/index'
import { clearCurrentFolder, openFolder, setTenantFormsSearchText } from '../../actions'
import FoldersList from '../../../shared/folders/FoldersList'

import './styles.less'

const AttachTenantFormsDialog = ({
  forms,
  isOpen,
  closeDialog,
  loadingForms,
  onTextChanged,
  isAttachDetachProcessing,
}) => {
  const { foldersList, currentFolder } = useSelector((state) => state.tenantForms)
  const formsTitle = useSelector(getFormsTitleLowerCase)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isOpen) {
      return () => dispatch(clearCurrentFolder())
    }
  })

  const _handleExitFolder = () => {
    _handleOpenFolder({})
  }

  const _handleOpenFolder = useCallback(
    (folder) => {
      dispatch(setTenantFormsSearchText(''))
      dispatch(openFolder(folder))
    },
    [dispatch]
  )

  const foldersListProps = {
    isOpen: !currentFolder.id,
    list: foldersList,
    openFolder: _handleOpenFolder,
  }

  const dialogTitle = currentFolder.id ? (
    <>
      <IconButton
        className='available-forms-exit-button'
        aria-label='exit-folder'
        title='Click to exit the folder'
        onClick={_handleExitFolder}
      >
        <ArrowBack />
      </IconButton>
      <Typography title={currentFolder.name} variant='inherit' component='span'>
        {`Available ${formsTitle} / ${currentFolder.name}`}
      </Typography>
    </>
  ) : (
    `Available ${formsTitle}`
  )

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      className='available-forms-dialog'
      PaperProps={{ className: 'attach-tenant-forms-dialog-content' }}
    >
      <DialogTitle className='available-forms-title-container'>{dialogTitle}</DialogTitle>
      <DialogContent>
        {loadingForms ? (
          <CircularProgressBar />
        ) : (
          <div
            className={classNames('forms__availableToAttach', {
              'available-forms-dialog-disabled': isAttachDetachProcessing,
            })}
          >
            <TextField className='availavle-forms-search' placeholder='Search...' fullWidth onChange={onTextChanged} />
            <FoldersList className='available-forms-folders-container' {...foldersListProps} />
            {forms.map((form, index) => (
              <AvailableTenantFormContainer form={form} key={`${form.FormId}-${index}`} />
            ))}
            {forms.length <= 0 && <p className='no-available-forms-message'>No {formsTitle} found</p>}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

AttachTenantFormsDialog.propTypes = {
  forms: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loadingForms: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onTextChanged: PropTypes.func.isRequired,
  isAttachDetachProcessing: PropTypes.bool.isRequired,
}

export default AttachTenantFormsDialog
