import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as rawActions from '../actions'
import * as galleryActions from '../../gallery/actions'

import FileUpload from '../components/FileUpload'

class FileUploadContainer extends Component {
  _handleRetryUpload = () => {
    const {
      item,
      galleryActions: { addFilesToQueue },
    } = this.props

    addFilesToQueue({ files: [item.file], retry: true })
  }

  _handleCancelUpload = () => {
    const {
      item,
      actions: { cancelFileUploads },
    } = this.props

    cancelFileUploads([item])
  }

  render() {
    const { item } = this.props

    const props = {
      item,
      handleRetryUpload: this._handleRetryUpload,
      handleCancelUpload: this._handleCancelUpload,
    }

    return <FileUpload {...props} />
  }
}

FileUploadContainer.propTypes = {
  actions: PropTypes.shape({
    cancelFileUploads: PropTypes.func.isRequired,
  }).isRequired,
  galleryActions: PropTypes.shape({
    addFilesToQueue: PropTypes.func.isRequired,
  }).isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
  galleryActions: bindActionCreators(galleryActions, dispatch),
})

export default connect(null, mapDispatchToProps)(FileUploadContainer)
