import { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Button, Tooltip } from '@material-ui/core'

import { setSelectedTargetContentForMerging } from '../../../../actions'

import useStyles from '../TargetContentSearchResult/styles'

const SelectedTargetContent = ({ content }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <div className={classes.contentItem}>
      <div className={classes.info}>
        <Tooltip title={content.Description}>
          <span className={classes.description}>{content.Description}</span>
        </Tooltip>
      </div>
      {!content.IsPredefined && (
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={() => dispatch(setSelectedTargetContentForMerging(null))}
        >
          <span className={classes.buttonText}>Remove</span>
        </Button>
      )}
    </div>
  )
}

SelectedTargetContent.propTypes = {
  content: PropTypes.shape({
    Description: PropTypes.string.isRequired,
    IsPredefined: PropTypes.bool.isRequired,
  }).isRequired,
}

export default memo(SelectedTargetContent)
