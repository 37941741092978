import { handleActions } from 'redux-actions'

import _ from 'lodash'

import * as actions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [actions.addImagesSourceFolder](state, action) {
      const folderId = action.payload

      return _.uniq([...state, folderId])
    },
    [actions.closePagesDialog](state, action) {
      return defaultValue
    },
    [actions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
