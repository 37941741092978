import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Button, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material'

const HeaderButton = forwardRef(({ disabled, title, label, onClick, startIcon, endIcon }, ref) => {
  const theme = useTheme()
  const compact = useMediaQuery(theme.breakpoints.down('md'))

  if (compact) {
    return (
      <Tooltip title={title || label}>
        <IconButton ref={ref} onClick={onClick}>
          {startIcon}
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={title || label}>
      <Button ref={ref} color='inherit' disabled={disabled} startIcon={startIcon} endIcon={endIcon} onClick={onClick}>
        {label.toUpperCase()}
      </Button>
    </Tooltip>
  )
})

HeaderButton.defaultProps = {
  disabled: false,
  startIcon: null,
  endIcon: null,
}

HeaderButton.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
}

export default HeaderButton
