import * as Yup from 'yup'

import { UserRole } from '@tabeeb/enums'

import { validateEmail, validatePhone } from '@tabeeb/modules/../users/services/users'
import { noWhitespaces } from '@tabeeb/modules/shared/utils/validationErrorMessages'

export const VALIDATION_SCHEMAS = {
  Role: Yup.number().strict().oneOf([UserRole.User, UserRole.Viewer, UserRole.SessionGuest]).required(),
  Users: Yup.array()
    .strict()
    .of(
      Yup.object({
        Email: Yup.string()
          .strict()
          .test('email_or_phone', 'Email / Phone is invalid', (value) => {
            return validateEmail(value) || validatePhone(value)
          }),
      }).required()
    )
    .min(1)
    .required(),
  Expiration: Yup.boolean().strict().required(),
  Duration: Yup.number()
    .strict()
    .when('Expiration', {
      is: (value) => value === true,
      then: Yup.number().strict().positive().required(),
    }),
  Period: Yup.string()
    .strict()
    .when('Expiration', {
      is: (value) => value === true,
      then: Yup.string().strict().oneOf(['hour', 'day', 'month']).required(),
    }),
  FastRegistrationEnabled: Yup.boolean().strict().required(),
  OptionalPassword: Yup.string()
    .strict()
    .when('FastRegistrationEnabled', {
      is: (value) => value === true,
      then: Yup.string().strict().min(6).max(30).required().trim(noWhitespaces),
    }),
  Password: Yup.string().strict().min(6).max(30).required().trim(noWhitespaces),
}
