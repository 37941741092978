import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onBentleyAnalyticsReportAdd(action) {
  const modelsList = yield select((state) => state.bentleyAnalyticsReport.modelsList)

  if (modelsList.length !== 1) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'Models list is empty or contains more than one model!',
      })
    )
    return
  }

  const modelId = modelsList.map((model) => model.id)[0]

  const requestModel = {
    pageId: modelId,
  }

  yield put(actions.createBentleyAnalyticsReport.request(requestModel))
}

function* onBentleyAnalyticsReportAddSuccess() {
  yield put(actions.closeBentleyAnalyticsReportDialog())
  yield put(
    notificationActions.onAddInfoNotification({ message: 'Data sent for creating Analytics Report (Context Capture)' })
  )
}

function* onBentleyAnalyticsReportAddFailed() {
  yield put(
    notificationActions.onAddErrorNotification({ message: 'Failed to create Analytics Report (Context Capture)' })
  )
}

function* addBentleyAnalyticsReport() {
  yield all([
    takeLatest(actions.addBentleyAnalyticsReport, onBentleyAnalyticsReportAdd),
    takeLatest(actions.createBentleyAnalyticsReport.success, onBentleyAnalyticsReportAddSuccess),
    takeLatest(actions.createBentleyAnalyticsReport.failed, onBentleyAnalyticsReportAddFailed),
  ])
}

export default addBentleyAnalyticsReport
