import { memo, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Mic, Title, EmojiObjects } from '@material-ui/icons'
import { Button, Tabs, Tab, Box, withStyles } from '@material-ui/core'

import { AnnotationType } from '@tabeeb/enums'
import * as accountSelectors from '@tabeeb/modules/account/selectors'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'

import AudioAnnotationEditor from '../AudioAnnotationEditor'
import AIAnnotationEditor from '../AIAnnotationEditor'
import TextMessageEditor from '../TextMessageEditor'

import styles from './styles'

const HotspotEditor = ({ classes, availableAnnotationTypes, onCancel, onSubmit }) => {
  const [value, setValue] = useState()

  const user = useSelector(accountSelectors.getMe)

  const [selectedType, setSelectedType] = useState(availableAnnotationTypes[0])
  useEffect(() => {
    if (!availableAnnotationTypes.includes(selectedType)) {
      setSelectedType(availableAnnotationTypes[0])
      setValue()
    }
  }, [availableAnnotationTypes])

  const handleChangeAnnotationType = (e, value) => {
    if (selectedType !== value) {
      setSelectedType(value)
      setValue()
    }
  }

  const handleSetValue = (annotationType) => (value) => {
    if (annotationType === selectedType) {
      setValue(value)
    } else {
      setValue()
    }
  }

  const handleSubmit = useCallback(() => {
    onSubmit(value, selectedType)
  }, [value, selectedType])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [])

  return (
    <Box className={classes.container}>
      <UserAvatar className={classes.avatar} user={user} />
      <Box className={classes.content}>
        <Tabs
          className={classes.tabs}
          indicatorColor='primary'
          value={availableAnnotationTypes.includes(selectedType) ? selectedType : availableAnnotationTypes[0]}
          onChange={handleChangeAnnotationType}
        >
          {availableAnnotationTypes.includes(AnnotationType.Text) && (
            <Tab title='Text' value={AnnotationType.Text} icon={<Title />} />
          )}
          {availableAnnotationTypes.includes(AnnotationType.Audio) && (
            <Tab title='Audio' value={AnnotationType.Audio} icon={<Mic />} />
          )}
          {availableAnnotationTypes.includes(AnnotationType.AI) && (
            <Tab title='AI' value={AnnotationType.AI} icon={<EmojiObjects />} />
          )}
        </Tabs>
        {selectedType === AnnotationType.Text && <TextMessageEditor onChange={handleSetValue(AnnotationType.Text)} />}
        {selectedType === AnnotationType.Audio && (
          <AudioAnnotationEditor onChange={handleSetValue(AnnotationType.Audio)} />
        )}
        {selectedType === AnnotationType.AI && <AIAnnotationEditor onChange={handleSetValue(AnnotationType.AI)} />}

        <Box className={classes.actions}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant='outlined' color='primary' onClick={handleSubmit} disabled={!value}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

HotspotEditor.propTypes = {
  classes: PropTypes.shape({
    actions: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    tabs: PropTypes.string.isRequired,
  }).isRequired,
  availableAnnotationTypes: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(withStyles(styles)(HotspotEditor))
