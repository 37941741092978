import { handleActions, combineActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(
      actions.getPagesByIterationId.request,
      actions.getAiObjectsByIterationId.request,
      actions.getContentsByIterationId.request,
      actions.getFormsByIterationId.request,
      actions.getContentsForProject.request,
      actions.getPagesForProjectTraining.request
    )]() {
      return true
    },
    [combineActions(
      actions.getPagesByIterationId.success,
      actions.getPagesByIterationId.failed,
      actions.getAiObjectsByIterationId.success,
      actions.getAiObjectsByIterationId.failed,
      actions.getContentsByIterationId.success,
      actions.getContentsByIterationId.failed,
      actions.getFormsByIterationId.success,
      actions.getFormsByIterationId.failed,
      actions.getContentsForProject.success,
      actions.getContentsForProject.failed,
      actions.getPagesForProjectTraining.success,
      actions.getPagesForProjectTraining.failed
    )]() {
      return false
    },
  },
  defaultState
)
