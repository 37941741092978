import { memo } from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@mui/material'
import { CloudDoneOutlined, SaveAsOutlined } from '@mui/icons-material'

import { ArticlePublicationStatus, ArticlePublicationStatusDisplayName } from '../../enums'

const ArticlePublicationStatusToColorMap = {
  [ArticlePublicationStatus.Draft]: 'default',
  [ArticlePublicationStatus.Published]: 'primary',
}

const ArticlePublicationStatusToIconMap = {
  [ArticlePublicationStatus.Draft]: <SaveAsOutlined />,
  [ArticlePublicationStatus.Published]: <CloudDoneOutlined />,
}

const ArticlePublicationStatusChip = ({ status }) => {
  return (
    <Chip
      size='small'
      variant='outlined'
      sx={{ padding: 0.25 }}
      icon={ArticlePublicationStatusToIconMap[status]}
      color={ArticlePublicationStatusToColorMap[status]}
      label={ArticlePublicationStatusDisplayName[status]}
    />
  )
}

ArticlePublicationStatusChip.defaultProps = {
  status: ArticlePublicationStatus.Draft,
}

ArticlePublicationStatusChip.propTypes = {
  status: PropTypes.oneOf(Object.values(ArticlePublicationStatus)),
}

export default memo(ArticlePublicationStatusChip)
