import { Suspense, lazy, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import { RouteWrapper } from '@tabeeb/shared/routes/components'
import { LicenseInfo } from '@mui/x-license-pro'

import { store } from '@tabeeb/store'

import * as appConfig from '@tabeeb/modules/appConfigState/selectors'

import routes from '@tabeeb/routes'
import { PolicyType, TenantPermission } from '@tabeeb/enums'

import { LinearProgress } from '@material-ui/core'

import { isMobileOnly } from 'react-device-detect'

import { AnnouncementsDialog } from '@tabeeb/modules/articles'
import { useNotifier } from '@tabeeb/modules/notification'
import { PoliciesUpdatePopup } from '@tabeeb/modules/policies'
import AlertOwnerModalContainer from '@tabeeb/modules/alertOwner/AlertOwnerModalContainer'
import CallNotification from '@tabeeb/modules/callNotification'
import ReminderNotificationDialog from '@tabeeb/modules/reminders/components/ReminderNotificationDialog'
import TenantFormsDialog from '@tabeeb/modules/tenantForms/components/TenantFormsDialog'

import { CreateProfilePage, UserSettingsPage } from '@tabeeb/modules/account'
import ContentSharingDialog from '@tabeeb/modules/contentSharings/components/ContentSharingDialog'

import SignInRedirectCallback from '@tabeeb/modules/auth/components/SignInRedirectCallback'
import SignInSilentCallback from '@tabeeb/modules/auth/components/SignInSilentCallback'
import SignOutRedirectCallback from '@tabeeb/modules/auth/components/SignOutRedirectCallback'
import SwitchTenantContainer from '@tabeeb/modules/account/containers/SwitchTenantContainer'

import { isLegacyUrl, redirectToNewLinkFormat } from '@tabeeb/modules/shared/utils/helpers'
import {
  getIsAppAccountCreated,
  getIsIdentityAuthenticated,
  getIsUserAuthorized,
} from '@tabeeb/modules/account/selectors'

import { createConnection, terminateConnection } from '@tabeeb/modules/signalr/actions'
import { tabeebHubName } from '@tabeeb/modules/signalr/services/constants'
import { getShowCertificatesManagement } from '@tabeeb/modules/certificates/selectors'
import { getShowQualificationManagement } from '@tabeeb/modules/qualifications/selectors'
import { getShowBulkImport } from '@tabeeb/modules/bulkImports/selectors'

import Root from './Root'
import IdentityAuthenticate from './IdentityAuthenticate'
import TenantAuthenticate from './TenantAuthenticate'

const CustomIframePage = lazy(() => import('@tabeeb/shared/iframe/components/CustomIframePage'))

const ThreeDimensionalModelsPage = lazy(() => import('@tabeeb/modules/threeDimensionalModels'))
const JoinSessionViaInvitePage = lazy(() => import('@tabeeb/modules/account/pages/JoinSessionViaInvitePage'))

const AIPage = lazy(() => import('@tabeeb/modules/artificialIntelligence/pages'))
const AdminTenantsPages = lazy(() =>
  import('@tabeeb/modules/tenants').then(
    ({
      tenantsReducer,
      storagesReducer,
      tenantStoragesReducer,
      businessDomainsReducer,
      defaultCreateTenantConfigurationReducer,
      tenantStoragesSagas,
      tenantsSagas,
    }) => {
      store.injectReducers({
        tenants: tenantsReducer,
        storages: storagesReducer,
        tenantStorages: tenantStoragesReducer,
        businessDomains: businessDomainsReducer,
        defaultCreateTenantConfiguration: defaultCreateTenantConfigurationReducer,
      })

      store.injectSaga('tenantStorages', tenantStoragesSagas)
      store.injectSaga('tenants', tenantsSagas)

      return import('@tabeeb/modules/tenants/pages')
    }
  )
)
const AnalyticsPage = lazy(() => import('@tabeeb/modules/analytics'))
const ArticlesPages = lazy(() => import('@tabeeb/modules/articles/pages'))
const BillingSettingsPage = lazy(() => import('@tabeeb/modules/billingSettingsPage/pages'))
const CalendarPage = lazy(() => import('@tabeeb/modules/calendar/pages'))
const ContractorsPage = lazy(() => import('@tabeeb/modules/contractors/pages'))
const CreateFormPage = lazy(() => import('@tabeeb/modules/createFormPage/pages'))
const DevicesPage = lazy(() => import('@tabeeb/modules/admin/devices/DevicesContainer'))
const FormInfo = lazy(() => import('@tabeeb/modules/formInfoPage/pages'))
const FormsPage = lazy(() => import('@tabeeb/modules/formsPage/pages'))
const Geosearch = lazy(() =>
  import('@tabeeb/modules/geosearch').then((module) => {
    store.injectReducer('geosearch', module.reducer)
    store.injectSaga('geosearch', module.sagas)
    return import('@tabeeb/modules/geosearch/pages')
  })
)
const PolicyPage = lazy(() => import('@tabeeb/modules/policies/pages/Policy'))
const AppPrivacyPolicyPage = lazy(() => import('@tabeeb/modules/policies/pages/AppPrivacyPolicyPage'))
const AppTermsOfUsePage = lazy(() => import('@tabeeb/modules/policies/pages/AppTermsOfUsePage'))
const ReportsPages = lazy(() =>
  import('@tabeeb/modules/reports').then((module) => {
    store.injectReducer('reports', module.reportsReducer)
    store.injectSaga('reports', module.reportsSagas)
    return import('@tabeeb/modules/reports/pages')
  })
)

/// Sessions
const AllDeletedSessionsPage = lazy(() => import('@tabeeb/modules/sessionsPage/pages/AllDeletedSessionsPage'))
const AllSessionsPage = lazy(() => import('@tabeeb/modules/sessionsPage/pages/AllSessionsPage'))
const DeletedSessionsPage = lazy(() => import('@tabeeb/modules/sessionsPage/pages/MyDeletedSessionsPage'))
const MySessionsPage = lazy(() => import('@tabeeb/modules/sessionsPage/pages/MySessionsPage'))
const PublishedSessionsPage = lazy(() => import('@tabeeb/modules/sessionsPage/pages/PublishedSessionsPage'))
const FolderSessionsPage = lazy(() => import('@tabeeb/modules/sessionsPage/pages/FolderSessionsPage'))

const MobileContentsPage = lazy(() => import('@tabeeb/modules/mobileContentsPage/pages'))
const TenantConfigPage = lazy(() => import('@tabeeb/modules/tenantConfigPage/pages'))
const TrackingPage = lazy(() => import('@tabeeb/modules/tracking/pages'))
const UsersPage = lazy(() => import('@tabeeb/modules/admin/users/UsersContainer'))
const WhiteboardPage = lazy(() => import('@tabeeb/modules/whiteboard/pages'))
const LegacyAuthPage = lazy(() => import('@tabeeb/modules/legacy/auth'))
const CertificatesAndQualificationsManagement = lazy(() =>
  import('@tabeeb/modules/certificates/components/CertificatesAndQualificationsManagement')
)
const Qualification = lazy(() => import('@tabeeb/modules/qualifications/components/Qualification'))

const WorkflowsPage = lazy(() => import('@tabeeb/modules/workflows/pages'))
const BulkImportPage = lazy(() => import('@tabeeb/modules/bulkImports/pages/BulkImportPage'))

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE)

const App = () => {
  const location = useLocation()

  const isAiEnabled = useSelector(appConfig.getShowMenuAI)
  const isAnalyticsEnabled = useSelector(appConfig.getShowAnalyticsMenu)
  const isGeosearchEnabled = useSelector(appConfig.getIsGeosearchEnabled)
  const isTrackingMapEnabled = useSelector(appConfig.getIsTrackingMapEnabled)
  const isPointCloudModelsEnabled = useSelector(appConfig.getIsPointCloudModelsEnabled)
  const isSessionPublishingEnabled = useSelector(appConfig.getIsSessionPublishingEnabled)
  const isCalendarEnabled = useSelector(appConfig.getIsCalendarEnabled)
  const isCustomIframeEnabled = useSelector(appConfig.getIsCustomIframeEnabled)
  const certificatesManagementEnabled = useSelector(getShowCertificatesManagement)
  const qualificationsManagementEnabled = useSelector(getShowQualificationManagement)
  const isIncomingContractEnabled = useSelector(appConfig.getIsIncomingContractsEnabled)
  const isOutgoingContractsEnabled = useSelector(appConfig.getIsOutgoingContractsEnabled)
  const isBulkImportEnabled = useSelector(getShowBulkImport)

  useNotifier()

  const isAppAccountCreated = useSelector(getIsAppAccountCreated)
  const isIdentityAuthenticated = useSelector(getIsIdentityAuthenticated)
  const isAuthenticated = useSelector(getIsUserAuthorized)

  const dispatch = useDispatch()
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(createConnection({ hubName: tabeebHubName }))
    }

    return () => {
      dispatch(terminateConnection({ hubName: tabeebHubName }))
    }
  }, [dispatch, isAuthenticated])

  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        {/* RouteWrapper has React ErrorBoundary and Suspense components inside, so we must localize errors and lazy loading process
            inside single page view. To work it properly, we need to specify a key for each route */}
        <Route
          path={routes.root}
          exact
          render={() => {
            if (isLegacyUrl(location)) {
              redirectToNewLinkFormat()

              return null
            }

            return <Redirect to={routes.home} />
          }}
        />
        <Route>
          <Root>
            <Switch>
              <Route
                key={routes.signInRedirectCallback}
                path={routes.signInRedirectCallback}
                render={() => <SignInRedirectCallback />}
              />
              <Route
                key={routes.signInSilentCallback}
                path={routes.signInSilentCallback}
                render={() => <SignInSilentCallback />}
              />
              <Route
                key={routes.signOutRedirectCallback}
                path={routes.signOutRedirectCallback}
                render={() => <SignOutRedirectCallback />}
              />
              <Route
                key={routes.legacyRegister}
                path={routes.legacyRegister}
                render={() => (
                  <RouteWrapper routeEnabled={!isIdentityAuthenticated}>
                    <LegacyAuthPage />
                  </RouteWrapper>
                )}
              />
              <Route
                key={routes.legacyConfirmEmail}
                path={routes.legacyConfirmEmail}
                render={() => (
                  <RouteWrapper routeEnabled={!isIdentityAuthenticated}>
                    <LegacyAuthPage />
                  </RouteWrapper>
                )}
              />
              <Route
                key={routes.appPrivacyPolicy}
                path={routes.appPrivacyPolicy}
                render={() => (
                  <RouteWrapper>
                    <AppPrivacyPolicyPage />
                  </RouteWrapper>
                )}
              />
              <Route
                key={routes.appTermsOfUse}
                path={routes.appTermsOfUse}
                render={() => (
                  <RouteWrapper>
                    <AppTermsOfUsePage />
                  </RouteWrapper>
                )}
              />
              <Route
                key={routes.joinSessionViaInvite}
                path={routes.joinSessionViaInvite}
                render={() => (
                  <RouteWrapper>
                    <JoinSessionViaInvitePage />
                  </RouteWrapper>
                )}
              />
              <Route>
                <IdentityAuthenticate>
                  <Switch>
                    <Route
                      key={routes.switchTenant}
                      path={routes.switchTenant}
                      render={() => (
                        <RouteWrapper>
                          <SwitchTenantContainer />
                        </RouteWrapper>
                      )}
                    />
                    <Route>
                      <TenantAuthenticate>
                        <PoliciesUpdatePopup />
                        <AnnouncementsDialog />
                        <AlertOwnerModalContainer />
                        <CallNotification />
                        <TenantFormsDialog />
                        <ReminderNotificationDialog />
                        <ContentSharingDialog />
                        <Switch>
                          <Route
                            key={routes.createProfile}
                            path={routes.createProfile}
                            render={() => (
                              <RouteWrapper routeEnabled={!isAppAccountCreated}>
                                <CreateProfilePage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.allDeletedSessions}
                            path={routes.allDeletedSessions}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.AllSessionsAccess}>
                                <AllDeletedSessionsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.allSessions}
                            path={routes.allSessions}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.AllSessionsAccess}>
                                <AllSessionsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.deletedSessions}
                            path={routes.deletedSessions}
                            render={() => (
                              <RouteWrapper authorize>
                                <DeletedSessionsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.home}
                            path={routes.home}
                            render={() => (
                              <RouteWrapper authorize>
                                {isMobileOnly ? <MobileContentsPage /> : <MySessionsPage />}
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.sessionsFolder}
                            path={routes.sessionsFolder}
                            render={({
                              match: {
                                params: { folderId },
                              },
                            }) => {
                              const folder = parseInt(folderId, 10)

                              return (
                                <RouteWrapper authorize>
                                  {isMobileOnly ? (
                                    <MobileContentsPage folder={folder} />
                                  ) : (
                                    <FolderSessionsPage folder={folder} />
                                  )}
                                </RouteWrapper>
                              )
                            }}
                          />
                          <Route
                            key={routes.publishedSessions}
                            path={routes.publishedSessions}
                            render={() => (
                              <RouteWrapper authorize routeEnabled={isSessionPublishingEnabled}>
                                <PublishedSessionsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.analytics}
                            path={routes.analytics}
                            render={() => (
                              <RouteWrapper
                                authorize
                                routeEnabled={isAnalyticsEnabled}
                                permission={TenantPermission.Analytics}
                              >
                                <AnalyticsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.articles}
                            path={routes.articles}
                            render={() => (
                              <RouteWrapper authorize>
                                <ArticlesPages />
                              </RouteWrapper>
                            )}
                          />

                          <Route
                            key={routes.tenants}
                            path={routes.tenants}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.AllTenantsAccess}>
                                <AdminTenantsPages />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.users}
                            path={routes.users}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.AllUsersView}>
                                <UsersPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.reports}
                            path={routes.reports}
                            render={() => (
                              <RouteWrapper authorize>
                                <ReportsPages />
                              </RouteWrapper>
                            )}
                          />

                          <Route
                            key={routes.workflows}
                            path={routes.workflows}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.ManageWorkflows}>
                                <WorkflowsPage />
                              </RouteWrapper>
                            )}
                          />

                          <Route
                            key={routes.forms}
                            path={routes.forms}
                            render={() => (
                              <RouteWrapper authorize>
                                <FormsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.showForm}
                            path={routes.showForm}
                            render={() => (
                              <RouteWrapper authorize>
                                <FormInfo />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.createForm}
                            path={routes.createForm}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.CreateForms}>
                                <CreateFormPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.updateForm}
                            path={routes.updateForm}
                            render={() => (
                              <RouteWrapper authorize>
                                <CreateFormPage edit />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.geosearch}
                            path={routes.geosearch}
                            render={() => (
                              <RouteWrapper authorize routeEnabled={isGeosearchEnabled}>
                                <Geosearch />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.tracking}
                            path={routes.tracking}
                            render={() => (
                              <RouteWrapper authorize routeEnabled={isTrackingMapEnabled}>
                                <TrackingPage trackingPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.threeDimensionalModels}
                            path={routes.threeDimensionalModels}
                            render={() => (
                              <RouteWrapper
                                authorize
                                routeEnabled={isPointCloudModelsEnabled}
                                permission={TenantPermission.ThreeDimensionalModelsManagement}
                              >
                                <ThreeDimensionalModelsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.devices}
                            path={routes.devices}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.Devices}>
                                <DevicesPage />
                              </RouteWrapper>
                            )}
                          />

                          <Route
                            key={routes.termsOfUse}
                            path={routes.termsOfUse}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.EditPolicies}>
                                <PolicyPage policyType={PolicyType.TermsOfUse} />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.privacyPolicy}
                            path={routes.privacyPolicy}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.EditPolicies}>
                                <PolicyPage policyType={PolicyType.PrivacyPolicy} />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.tenantTerms}
                            path={routes.tenantTerms}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.EditPolicies}>
                                <PolicyPage policyType={PolicyType.TenantTerms} />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.ai}
                            path={routes.ai}
                            render={() => (
                              <RouteWrapper
                                authorize
                                routeEnabled={isAiEnabled}
                                permission={TenantPermission.AISettings}
                              >
                                <AIPage />
                              </RouteWrapper>
                            )}
                          />

                          <Route
                            key={routes.tenantConfig}
                            path={routes.tenantConfig}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.TenantSettings}>
                                <TenantConfigPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.userSettings}
                            path={routes.userSettings}
                            render={() => (
                              <RouteWrapper authorize>
                                <UserSettingsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.billingSettings}
                            path={routes.billingSettings}
                            render={() => (
                              <RouteWrapper authorize permission={TenantPermission.SessionApprovalSettings}>
                                <BillingSettingsPage />
                              </RouteWrapper>
                            )}
                          />

                          <Route
                            key={routes.session}
                            path={routes.session}
                            render={() => (
                              <RouteWrapper authorize>
                                <WhiteboardPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.calendarBase}
                            path={routes.calendarBase}
                            render={() => (
                              <RouteWrapper authorize routeEnabled={isCalendarEnabled}>
                                <CalendarPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.contractorsBase}
                            path={routes.contractorsBase}
                            render={() => (
                              <RouteWrapper
                                authorize
                                permission={TenantPermission.ContractsManagement}
                                routeEnabled={isIncomingContractEnabled || isOutgoingContractsEnabled}
                              >
                                <ContractorsPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.customIframe}
                            path={routes.customIframe}
                            render={() => (
                              <RouteWrapper authorize routeEnabled={isCustomIframeEnabled}>
                                <CustomIframePage />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.qualification}
                            path={routes.qualification}
                            exact
                            render={() => (
                              <RouteWrapper authorize routeEnabled={qualificationsManagementEnabled}>
                                <Qualification />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.certificatesBase}
                            path={[
                              routes.certificatesBase,
                              routes.certificateTypes,
                              routes.qualificationBase,
                              routes.qualificationUsers,
                            ]}
                            render={() => (
                              <RouteWrapper authorize routeEnabled={certificatesManagementEnabled}>
                                <CertificatesAndQualificationsManagement />
                              </RouteWrapper>
                            )}
                          />
                          <Route
                            key={routes.bulkImports}
                            path={routes.bulkImports}
                            render={() => (
                              <RouteWrapper authorize routeEnabled={isBulkImportEnabled}>
                                <BulkImportPage />
                              </RouteWrapper>
                            )}
                          />
                          <Route path='*' render={() => <Redirect to={routes.home} />} />
                        </Switch>
                      </TenantAuthenticate>
                    </Route>
                  </Switch>
                </IdentityAuthenticate>
              </Route>
            </Switch>
          </Root>
        </Route>
      </Switch>
    </Suspense>
  )
}

export default App
