import { Block, Done, Error, HourglassBottom, Loop, Pause } from '@mui/icons-material'
import { Chip, chipClasses } from '@mui/material'
import PropTypes from 'prop-types'

import { CertificateStatusType, CertificateStatusTypeDisplayName } from '@tabeeb/enums'

const CertificateStatusChip = ({ statusId, fontSize }) => {
  return (
    <Chip
      label={CertificateStatusTypeDisplayName[statusId]}
      size='small'
      sx={{
        height: 'auto',
        minHeight: '24px',
        fontSize,
        [`& .${chipClasses.label}`]: {
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
      color={
        statusId === CertificateStatusType.Approved
          ? 'success'
          : statusId === CertificateStatusType.Rejected || statusId === CertificateStatusType.Obsolete
          ? 'error'
          : statusId === CertificateStatusType.Expired
          ? 'warning'
          : statusId === CertificateStatusType.PendingReview || statusId === CertificateStatusType.Processed
          ? 'info'
          : 'default'
      }
      icon={
        statusId === CertificateStatusType.Approved || statusId === CertificateStatusType.Processed ? (
          <Done />
        ) : statusId === CertificateStatusType.Rejected ? (
          <Block />
        ) : statusId === CertificateStatusType.PendingReview ? (
          <Loop />
        ) : statusId === CertificateStatusType.Draft ? (
          <Pause />
        ) : statusId === CertificateStatusType.Obsolete ? (
          <Error />
        ) : (
          <HourglassBottom />
        )
      }
    />
  )
}

CertificateStatusChip.propTypes = {
  statusId: PropTypes.number,
  fontSize: PropTypes.string,
}

export default CertificateStatusChip
