import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { TableCell, TableSortLabel, Typography } from '@mui/material'

import { SortingOrder } from '@tabeeb/enums'

const TableHeaderSortableCell = ({ align, active, order, children, onSort }) => {
  const onSortClick = useCallback(() => {
    onSort(active && order === SortingOrder.Ascending ? SortingOrder.Descending : SortingOrder.Ascending)
  }, [active, onSort, order])

  return (
    <TableCell align={align} sortDirection={active ? order : false}>
      <TableSortLabel active={active} direction={active ? order : SortingOrder.Ascending} onClick={onSortClick}>
        <Typography variant='body2' fontWeight={500} noWrap>
          {children}
        </Typography>
      </TableSortLabel>
    </TableCell>
  )
}

TableHeaderSortableCell.defaultProps = {
  active: false,
  align: 'left',
}

TableHeaderSortableCell.propTypes = {
  active: PropTypes.bool,
  align: PropTypes.oneOf(['center', 'left', 'right']),

  order: PropTypes.oneOf(Object.values(SortingOrder)),
  children: PropTypes.node.isRequired,
  onSort: PropTypes.func.isRequired,
}

export default memo(TableHeaderSortableCell)
