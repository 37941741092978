import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = ''

export default handleActions(
  {
    [rawActions.setSearchText](state, { payload: { text } }) {
      return text
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
