import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setSelectedFormControlOption](state, action) {
      return action.payload
    },
    [combineActions(
      rawActions.detachVqaPolicyFromFormControlOptionSuccess,
      rawActions.attachVqaPolicyToFormControlOptionSuccess
    )](state, { response }) {
      if (state === defaultState) {
        return state
      }
      return { ...response.data }
    },
  },
  defaultState
)
