import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { TopicType } from '@tabeeb/enums'
import { gallerySelectors } from '@tabeeb/modules/gallery'

import { ResizableDialog } from '@tabeeb/uikit'
import Chat from '@tabeeb/modules/chat'

import { PageInfo } from '../../Info'

const PageDiscussionDialog = ({ isOpen, pageId, onClose }) => {
  const page = useSelector((state) => gallerySelectors.getGalleryItemById(state, { id: pageId }))
  if (!page) {
    return null
  }

  return (
    <ResizableDialog
      open={isOpen}
      onClose={onClose}
      header={<PageInfo page={page} />}
      content={<Chat topicId={pageId} topicType={TopicType.Page} />}
    />
  )
}

PageDiscussionDialog.propTypes = {
  pageId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(PageDiscussionDialog)
