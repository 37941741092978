import { selectGalleryItem } from '@tabeeb/modules/gallery/actions'
import { handleActions, combineActions } from 'redux-actions'

import * as playerToolbarActions from '../actions'
import * as rawActions from '../actions/drawing'

const defaultState = false

export default handleActions(
  {
    [rawActions.onEnableSnippingTool](state, action) {
      return true
    },
    [combineActions(
      rawActions.onDisableSnippingTool,
      rawActions.onEnableDrawing,
      rawActions.onEnableSelection,
      selectGalleryItem
    )](state, action) {
      return false
    },
    [playerToolbarActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
