import './styles.less'

const LoadingSpinner = () => {
  return (
    <div className='content-viewer-loading-spinner-container'>
      <div className='content-viewer-loading-spinner' />
    </div>
  )
}

export default LoadingSpinner
