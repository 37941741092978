import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'

import { FormAnswer } from '@tabeeb/modules/shared/icons'

import styles from './styles'

const NoAnswersPlaceholder = ({ classes }) => {
  return (
    <div className={classes.container}>
      <FormAnswer className={classes.icon} />
      <Typography className={classes.text}>No answers were given...</Typography>
    </div>
  )
}

NoAnswersPlaceholder.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(NoAnswersPlaceholder))
