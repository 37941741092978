import { Typography, withStyles } from '@material-ui/core'

import styles from './styles'

const NoMetadata = ({ classes }) => {
  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <Typography>Metadata is not available</Typography>
    </div>
  )
}

export default withStyles(styles)(NoMetadata)
