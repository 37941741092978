import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { GetApp } from '@material-ui/icons'

import { AssetType } from '@tabeeb/enums'
import { gallerySelectors } from '@tabeeb/modules/gallery'
import { isSupportDownload } from '@tabeeb/services/pageService'

import ToolbarButton from '../ToolbarButton'

const DownloadButton = () => {
  const display = useSelector((state) => isSupportDownload(gallerySelectors.getSelectedGalleryItemType(state)))

  const galleryItem = useSelector(gallerySelectors.getSelectedGalleryItem)

  const onClick = useCallback(() => {
    if (!galleryItem) {
      return
    }

    const documentAssetTypes = [
      AssetType.DocDocument,
      AssetType.ExcelDocument,
      AssetType.PptDocument,
      AssetType.PointCloud,
      AssetType.File,
      AssetType.AutodeskFile,
    ]
    const asset = galleryItem.assets.find((asset) => documentAssetTypes.includes(asset.Type))
    if (!asset) {
      return
    }

    window.open(asset.Url)
  }, [galleryItem])

  return <ToolbarButton Icon={GetApp} display={display} onClick={onClick} title='Download file' />
}

export default memo(DownloadButton)
