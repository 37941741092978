import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as formsActions from '../actions'
import { ConfirmationDialog } from '@tabeeb/uikit'

class InfoMessageDialogContainer extends Component {
  _onSubmit = () => {
    const {
      dialogMessage,
      totalMessagesCount,
      actions: { openInfoMessageDialog, closeInfoMessageDialog, checkLastAttachedForm },
    } = this.props

    closeInfoMessageDialog(dialogMessage)

    if (totalMessagesCount === 1) checkLastAttachedForm()
    else openInfoMessageDialog()
  }

  render() {
    const {
      dialogMessage,
      isDialogOpen,
      actions: { closeInfoMessageDialog },
    } = this.props

    const props = {
      onCloseDialog: closeInfoMessageDialog,
      onSubmit: this._onSubmit,
      isOpen: isDialogOpen,
      dialogMessage: dialogMessage && dialogMessage.text,
      submitButtonText: 'Ok',
      showCancelButton: false,
    }

    return <ConfirmationDialog {...props} />
  }
}

InfoMessageDialogContainer.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  dialogMessage: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }),
  actions: PropTypes.shape({
    closeInfoMessageDialog: PropTypes.func.isRequired,
    openInfoMessageDialog: PropTypes.func.isRequired,
    checkLastAttachedForm: PropTypes.func.isRequired,
  }).isRequired,
  totalMessagesCount: PropTypes.number.isRequired,
}

const mapStateToProps = ({ forms: { infoMessageCondition } }) => ({
  isDialogOpen: infoMessageCondition.isDialogOpen,
  dialogMessage: infoMessageCondition.messages[0],
  totalMessagesCount: infoMessageCondition.messages.length,
})

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoMessageDialogContainer)
