import PropTypes from 'prop-types'

import { Box, Typography, withStyles } from '@material-ui/core'
import { Notifications } from '@material-ui/icons'

import styles from './styles'

const NoNotifications = ({ classes }) => {
  return (
    <Box className={classes.container}>
      <Notifications className={classes.icon} />
      <Typography>You have no notifications waiting...</Typography>
    </Box>
  )
}

NoNotifications.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(NoNotifications)
