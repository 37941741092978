import { Children, Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

function normalizeRect(rect) {
  if (rect.width === undefined) {
    rect.width = rect.right - rect.left
  }

  if (rect.height === undefined) {
    rect.height = rect.bottom - rect.top
  }

  return rect
}

export default class VisibilitySensor extends Component {
  static defaultProps = {
    active: true,
    partialVisibility: true,
    minTopValue: 0,
    scrollCheck: true,
    scrollDelay: 250,
    scrollThrottle: -1,
    resizeCheck: true,
    resizeDelay: 250,
    resizeThrottle: -1,
    intervalCheck: false,
    intervalDelay: 100,
    delayedCall: false,
    offset: {},
    once: true,
    containment: null,
    children: <span />,
  }

  static propTypes = {
    onChange: PropTypes.func,
    active: PropTypes.bool,
    partialVisibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['top', 'right', 'bottom', 'left'])]),
    delayedCall: PropTypes.bool,
    offset: PropTypes.oneOfType([
      PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
        bottom: PropTypes.number,
        right: PropTypes.number,
      }),
    ]),
    scrollCheck: PropTypes.bool,
    scrollDelay: PropTypes.number,
    scrollThrottle: PropTypes.number,
    resizeCheck: PropTypes.bool,
    resizeDelay: PropTypes.number,
    resizeThrottle: PropTypes.number,
    intervalCheck: PropTypes.bool,
    intervalDelay: PropTypes.number,
    containment: typeof window !== 'undefined' ? PropTypes.instanceOf(window.Element) : PropTypes.any,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    minTopValue: PropTypes.number,
  }

  constructor(props) {
    super(props)

    this.state = {
      isVisible: null,
      visibilityRect: {},
    }
  }

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this)
    if (this.props.active) {
      this.startWatching()
    }
  }

  componentWillUnmount() {
    this.stopWatching()
  }

  componentDidUpdate(prevProps, prevState) {
    this.node = ReactDOM.findDOMNode(this)

    const { isVisible } = this.state

    if (isVisible && isVisible !== prevState.isVisible && this.props.once) {
      this.stopWatching()
      return
    }

    if (this.props.active && !prevProps.active) {
      this.setState({
        isVisible: null,
      })

      this.startWatching()
    } else if (!this.props.active) {
      this.stopWatching()
    }
  }

  addEventListener = (target, event, delay, throttle) => {
    if (!this.debounceCheck) {
      this.debounceCheck = {}
    }

    let timeout
    let func

    const later = () => {
      timeout = null
      this.check()
    }

    func = () => {
      clearTimeout(timeout)
      timeout = setTimeout(later, delay || 0)
    }

    const info = {
      target,
      fn: func,
      getLastTimeout: () => {
        return timeout
      },
    }

    target.addEventListener(event, info.fn)
    this.debounceCheck[event] = info
  }

  startWatching = () => {
    if (this.debounceCheck || this.interval) {
      return
    }

    if (this.props.intervalCheck) {
      this.interval = setInterval(this.check, this.props.intervalDelay)
    }

    if (this.props.scrollCheck) {
      const { scrollDelay, scrollThrottle } = this.props
      const container = this.props.containment || window
      this.addEventListener(container, 'scroll', scrollDelay, scrollThrottle)
    }

    if (this.props.resizeCheck) {
      const { resizeDelay, resizeThrottle } = this.props
      this.addEventListener(window, 'resize', resizeDelay, resizeThrottle)
    }

    !this.props.delayedCall && this.check()
  }

  stopWatching = () => {
    if (this.debounceCheck) {
      for (const debounceEvent in this.debounceCheck) {
        if (this.debounceCheck.hasOwnProperty(debounceEvent)) {
          const debounceInfo = this.debounceCheck[debounceEvent]

          clearTimeout(debounceInfo.getLastTimeout())
          debounceInfo.target.removeEventListener(debounceEvent, debounceInfo.fn)

          this.debounceCheck[debounceEvent] = null
        }
      }
    }
    this.debounceCheck = null

    if (this.interval) {
      this.interval = clearInterval(this.interval)
    }
  }

  roundRectDown(rect) {
    return {
      top: Math.floor(rect.top),
      left: Math.floor(rect.left),
      bottom: Math.floor(rect.bottom),
      right: Math.floor(rect.right),
    }
  }

  check = () => {
    const el = this.node
    let rect
    let containmentRect

    if (!el) {
      return this.state
    }

    rect = normalizeRect(this.roundRectDown(el.getBoundingClientRect()))

    if (this.props.containment) {
      const containmentDOMRect = this.props.containment.getBoundingClientRect()
      containmentRect = {
        top: containmentDOMRect.top,
        left: containmentDOMRect.left,
        bottom: containmentDOMRect.bottom,
        right: containmentDOMRect.right,
      }
    } else {
      containmentRect = {
        top: 0,
        left: 0,
        bottom: window.innerHeight || document.documentElement.clientHeight,
        right: window.innerWidth || document.documentElement.clientWidth,
      }
    }

    const offset = this.props.offset || {}
    const hasValidOffset = typeof offset === 'object'

    if (hasValidOffset) {
      containmentRect.top += offset.top || 0
      containmentRect.left += offset.left || 0
      containmentRect.bottom -= offset.bottom || 0
      containmentRect.right -= offset.right || 0
    }

    const hasSize = rect.height > 0 && rect.width > 0

    let isVisible =
      hasSize &&
      rect.top >= containmentRect.top &&
      rect.left >= containmentRect.left &&
      rect.bottom <= containmentRect.bottom &&
      rect.right <= containmentRect.right

    if (hasSize && this.props.partialVisibility) {
      const partialVisible =
        rect.top <= containmentRect.bottom &&
        rect.bottom >= containmentRect.top &&
        rect.left <= containmentRect.right &&
        rect.right >= containmentRect.left

      isVisible = this.props.minTopValue
        ? partialVisible && rect.top <= containmentRect.bottom - this.props.minTopValue
        : partialVisible
    }

    const { state } = this

    if (this.state.isVisible !== isVisible) {
      this.setState({ isVisible })
      if (this.props.onChange) this.props.onChange(isVisible)
    }

    return state
  }

  render() {
    if (this.props.children instanceof Function) {
      return this.props.children({
        isVisible: this.state.isVisible,
      })
    }
    return Children.only(this.props.children)
  }
}
