import { handleActions } from 'redux-actions'

import { onPlayerMute, onPlayerUnmute } from '../actions'

const defaultValue = false

export default handleActions(
  {
    [onPlayerMute](state, action) {
      return true
    },
    [onPlayerUnmute](state, action) {
      return false
    },
  },
  defaultValue
)
