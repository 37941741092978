import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useProgressiveLoadingResource } from '@tabeeb/modules/sncFilters/hooks'
import { FetchStatus } from '@tabeeb/enums'
import { getPowerBIReportTenants, setPowerBIReportTenantsRefetch } from '../actions'
import { getPowerBIReportTenantsState } from '../selectors'

const usePowerBIReportTenants = (reportId) => {
  const dispatch = useDispatch()
  const requestParams = useMemo(() => ({ reportId }), [reportId])
  const { requireRefetch, deleteStatus, items } = useSelector(getPowerBIReportTenantsState)

  const { status, loading, hasMore, onLoadMore, onReload } = useProgressiveLoadingResource({
    request: getPowerBIReportTenants.request,
    payload: requestParams,
  })

  useEffect(() => {
    if (requireRefetch) {
      onReload()
      dispatch(setPowerBIReportTenantsRefetch(false))
    }
  }, [dispatch, onReload, requireRefetch])

  return {
    fetchStatus: status,
    loading: loading || deleteStatus === FetchStatus.Loading,
    tenants: items || [],
    hasMore,
    onLoadMore,
    onReload,
  }
}

export default usePowerBIReportTenants
