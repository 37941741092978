import _ from 'lodash'

import { Earcut } from 'three/src/extras/Earcut'

/**
 * @param {import('three').Vector3[]} points
 * @returns {Number[]}
 */
export const triangulate = (points) => {
  const boundingBox = {
    min: {
      x: _.minBy(points, (point) => point.x).x,
      y: _.minBy(points, (point) => point.y).y,
      z: _.minBy(points, (point) => point.z).z,
    },
    max: {
      x: _.maxBy(points, (point) => point.x).x,
      y: _.maxBy(points, (point) => point.y).y,
      z: _.maxBy(points, (point) => point.z).z,
    },
  }

  const axes = _.orderBy(['x', 'y', 'z'], (axis) => boundingBox.max[axis] - boundingBox.min[axis])

  const flattened = []
  for (const point of points) {
    flattened.push(point[axes[0]])
    flattened.push(point[axes[1]])
  }

  return Earcut.triangulate(flattened, null, 2)
}
