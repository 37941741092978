import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.openExportFormsToCsvDialog](state, action) {
      return true
    },
    [rawActions.closeExportFormsToCsvDialog](state, action) {
      return false
    },
    [rawActions.resetFormsState](state, action) {
      return false
    },
  },
  false
)
