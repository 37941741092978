import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import ResizeDetector from 'react-resize-detector'
import { Document, Page, pdfjs } from 'react-pdf'

import ContentSkeleton from '../ContentSkeleton'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

const MAX_WIDTH = 1024

const PdfContent = ({ url }) => {
  const [width, setWidth] = useState(MAX_WIDTH)
  const [document, setDocument] = useState(null)

  return (
    <>
      <Box display='flex' alignItems='center' flexDirection='column' width={width}>
        <Document loading={<ContentSkeleton width={width} />} file={url} onLoadSuccess={setDocument}>
          {document &&
            Array.from(new Array(document.numPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                loading={<ContentSkeleton width={width} />}
                pageNumber={index + 1}
                width={Math.min(width, MAX_WIDTH)}
              />
            ))}
        </Document>
      </Box>
      <ResizeDetector handleWidth onResize={(newWidth, _) => setWidth(newWidth)} />
    </>
  )
}

PdfContent.propTypes = {
  url: PropTypes.string.isRequired,
}

export default PdfContent
