import { handleActions } from 'redux-actions'
import * as galleryActions from '../actions'

const defaultState = {
  displayPage: 'all',
  userId: 'all',
  activityType: 'all',
  searchFilter: '',
}

const activitiesFilter = handleActions(
  {
    [galleryActions.setPageFilter](state, action) {
      return { ...state, displayPage: action.payload }
    },
    [galleryActions.setUserFilter](state, action) {
      return { ...state, userId: action.payload }
    },
    [galleryActions.setActivityTypeFilter](state, action) {
      return { ...state, activityType: action.payload }
    },
    [galleryActions.onEnterSearchTextFilter](state, action) {
      return { ...state, searchFilter: action.payload }
    },
    [galleryActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)

export default activitiesFilter
