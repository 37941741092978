import ApexCharts from 'apexcharts'
import _ from 'lodash'

import { HealthStatisticsTimeLine, IOTDeviceUnitType, IOTDeviceUnitTypeChartName } from '@tabeeb/enums'

import {
  healthDataTypesWithoutMeasurementSystems,
  healthDataTypeToUnitType,
} from '@tabeeb/modules/healthData/constants'

export const switchTimeLine = (timeline, chartId, values) => {
  const currentDate = new Date().getTime()
  const scopeDate = new Date()

  switch (timeline) {
    case HealthStatisticsTimeLine.OneMonth:
      ApexCharts.exec(chartId, 'zoomX', scopeDate.setMonth(scopeDate.getMonth() - 1), currentDate)
      break
    case HealthStatisticsTimeLine.SixMonth:
      ApexCharts.exec(chartId, 'zoomX', scopeDate.setMonth(scopeDate.getMonth() - 6), currentDate)
      break
    case HealthStatisticsTimeLine.OneYear:
      ApexCharts.exec(chartId, 'zoomX', scopeDate.setFullYear(scopeDate.getFullYear() - 1), currentDate)
      break
    case HealthStatisticsTimeLine.YTD:
      ApexCharts.exec(chartId, 'zoomX', scopeDate.setMonth(0), currentDate)
      break
    case HealthStatisticsTimeLine.All:
      if (values.length > 0) {
        const firstDate = new Date(values[0].x)
        const lastDate = new Date(values[values.length - 1].x)
        ApexCharts.exec(chartId, 'zoomX', firstDate.getTime(), lastDate.setMonth(lastDate.getMonth() + 1))
      }
      break
    default:
  }
}

export const initialZoomForChart = (correspondingToMeasureTypeUserMeasures, measureType) => {
  if (correspondingToMeasureTypeUserMeasures.length > 0) {
    const firstDate = new Date(correspondingToMeasureTypeUserMeasures[0].CreatedOn)
    const lastDate = new Date(
      correspondingToMeasureTypeUserMeasures[correspondingToMeasureTypeUserMeasures.length - 1].CreatedOn
    )
    ApexCharts.exec(`chart-${measureType}`, 'zoomX', firstDate.getTime(), lastDate.setMonth(lastDate.getMonth() + 1))
  }
}

export const createCoordinatesFromMeasurementValues = (userMeasures) => {
  if (userMeasures.length === 0) {
    return {}
  }

  const coordinatesModel = {}
  const uniqueMeasureTypes = _.uniqBy(userMeasures, 'MeasureId').map((userMeasure) => userMeasure.MeasureId)

  uniqueMeasureTypes.forEach((measureType) => {
    coordinatesModel[measureType] = userMeasures
      .filter((el) => el.MeasureId === measureType)
      .map((item) => ({
        x: item.CreatedOn,
        y: item.Value,
      }))
  })

  return coordinatesModel
}

export const convertSeriesToChartFormat = (values) => (values ? [{ name: 'value', data: values }] : [])
export const generateApexChartOptions = (chartOptions, chartId) => {
  return {
    ...chartOptions,
    chart: { ...chartOptions.chart, id: chartId },
  }
}

export const getHealthDataUnitsForCharts = (dataType, systemOfMeasure) => {
  let unitType
  if (healthDataTypesWithoutMeasurementSystems.includes(dataType)) {
    unitType = healthDataTypeToUnitType.find(
      (healthDataTypeToUnitTypeElement) => healthDataTypeToUnitTypeElement.HealthDataType === dataType
    ).UnitType
  } else {
    unitType = healthDataTypeToUnitType.find(
      (healthDataTypeToUnitTypeElement) =>
        healthDataTypeToUnitTypeElement.HealthDataType === dataType &&
        healthDataTypeToUnitTypeElement.SystemOfMeasure === systemOfMeasure &&
        healthDataTypeToUnitTypeElement.IsDefaultMeasure
    ).UnitType
  }

  return IOTDeviceUnitTypeChartName[_.findKey(IOTDeviceUnitType, (iotDeviceUnitType) => iotDeviceUnitType === unitType)]
}
