import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {}

export default handleActions(
  {
    [rawActions.setFormToEdit](state, action) {
      return action.payload
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
