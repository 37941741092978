import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { GroupSharp } from '@material-ui/icons'
import { Avatar, Badge, IconButton } from '@material-ui/core'

import * as usersSelectors from '@tabeeb/modules/../users/selectors'
import UserAvatar from '@tabeeb/shared/userAvatar'

import { useChatContext } from '../../../contexts'

import * as chatSelectors from '../../../selectors'

import useStyles from './styles'

const ChatAvatar = ({ userId }) => {
  const classes = useStyles()

  const { topicId, topicType, userId: receiverId, setReceiverId } = useChatContext()

  const user = useSelector((state) => usersSelectors.getUserById(state, { Id: userId }))
  const unreadMessagesCount = useSelector((state) =>
    chatSelectors.getUnreadMessagesCountForChat(state, {
      topicId,
      topicType,
      userId,
    })
  )

  const selected = receiverId === userId
  const onSelectUser = () => {
    setReceiverId(userId)
  }

  const isPrivateChat = Boolean(userId)

  const avatar = isPrivateChat ? (
    <UserAvatar user={user} title={`Switch to private chat with ${user.displayName}`} />
  ) : (
    <Avatar title='Switch to public chat'>
      <GroupSharp />
    </Avatar>
  )

  return (
    <div className={classNames(classes.container, { [classes.selected]: selected })}>
      <IconButton size='small' onClick={onSelectUser}>
        <Badge
          overlap='rectangular'
          color='primary'
          className={classes.badge}
          badgeContent={selected ? 0 : unreadMessagesCount}
          title={`${unreadMessagesCount} unread messages`}
        >
          {avatar}
        </Badge>
      </IconButton>
    </div>
  )
}

ChatAvatar.defaultProps = {
  userId: null,
}

ChatAvatar.propTypes = {
  userId: PropTypes.number,
}

export default memo(ChatAvatar)
