import { handleActions } from 'redux-actions'
import * as actions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [actions.addImageToStructureModel](state, action) {
      return [...state, action.payload]
    },
    [actions.addAllPagesToStructureModel](state, action) {
      let pages = action.payload
      pages = pages.filter((item) => !state.some((page) => page.id === item.id))

      const newState = [...state]
      pages.forEach((page) => newState.push(page))

      return newState
    },
    [actions.updateImagesStructureModel](state, action) {
      return [...action.payload]
    },
    [actions.deleteImageFromStructureModel](state, action) {
      const index = action.payload
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [actions.closeStructureModelDialog](state, action) {
      return defaultValue
    },
    [actions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
