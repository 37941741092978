import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons'

import { getSelectedGalleryItemSupportsAnnotationsDisplay } from '@tabeeb/modules/gallery/selectors'

import { onHideAnnotations, onShowAnnotations } from '../../actions/drawing'
import { getHideAnnotations } from '../../selectors'

import ToolbarButton from '../ToolbarButton'

const HideAnnotationsButton = () => {
  const dispatch = useDispatch()

  const display = useSelector(getSelectedGalleryItemSupportsAnnotationsDisplay)
  const hidden = useSelector(getHideAnnotations)

  const onClick = useCallback(() => {
    if (hidden) {
      dispatch(onShowAnnotations())
    } else {
      dispatch(onHideAnnotations())
    }
  }, [dispatch, hidden])

  return (
    <ToolbarButton
      display={display}
      selected={hidden}
      Icon={hidden ? VisibilityOffOutlined : VisibilityOutlined}
      title={hidden ? 'Display annotations' : 'Hide annotations'}
      onClick={onClick}
    />
  )
}

export default memo(HideAnnotationsButton)
