import { useFormik } from 'formik'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { updatePowerBIReportProviderUsers } from '../../actions'

const usePowerBIProviderUsersForm = ({ providerId, initialUsers }) => {
  const dispatch = useDispatch()

  const onUsersSubmit = useCallback(
    (values, actions) => {
      const requestData = {
        userIds: values.userIds,
      }
      dispatch(updatePowerBIReportProviderUsers.request({ id: providerId, data: requestData }))
      actions.resetForm({ values })
    },
    [dispatch, providerId]
  )

  const initialValues = useMemo(() => ({ userIds: initialUsers }), [initialUsers])

  const usersForm = useFormik({
    initialValues,
    onSubmit: onUsersSubmit,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      userIds: Yup.array().of(Yup.string()).notRequired(),
    }),
  })

  const onRemoveUser = useCallback(
    (userIdToDelete) => {
      const filteredUserIds = usersForm.values.userIds.filter((userId) => userId !== userIdToDelete)
      usersForm.setValues((values) => ({ ...values, userIds: filteredUserIds }))
    },
    [usersForm]
  )

  const onUserSelectChange = useCallback(
    (_event, value, reason) => {
      if (reason === 'selectOption') {
        const userIds = value.map((u) => u.identityGuid || u)
        usersForm.setValues((values) => ({ ...values, userIds }))
      }
    },
    [usersForm]
  )

  return [usersForm, onRemoveUser, onUserSelectChange]
}

export default usePowerBIProviderUsersForm
