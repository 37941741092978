import { memo } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { Opacity } from '@material-ui/icons'

import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'
import { playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import Palette from './Palette'
import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'
import LineWidthSlider from './LineWidthSlider'
import TransparencySlider from './TransparencySlider'

import useStyles from './styles'

const PaletteButton = () => {
  const classes = useStyles()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const color = useSelector(playerToolbarSelectors.getSelectedColor)

  return (
    <>
      <ToolbarButton
        ref={anchorRef}
        selected={open}
        Icon={Opacity}
        htmlColor={color}
        onClick={onOpen}
        title={open ? 'Hide palette' : 'Show palette'}
        className={classNames({
          [classes.bright]: color.toLowerCase() === '#ffffff' || color.toLowerCase() === '#d9d9d9',
        })}
      />
      <ToolbarPopover anchorEl={anchorRef.current} open={open} spacing={2} onClose={onClose}>
        <Palette />
        <LineWidthSlider />
        <TransparencySlider />
      </ToolbarPopover>
    </>
  )
}

export default memo(PaletteButton)
