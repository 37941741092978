import { memo } from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@mui/material'
import { PeopleOutlineOutlined, PublicOutlined, SettingsOutlined } from '@mui/icons-material'

import { AnnouncementScope, AnnouncementScopeDisplayName } from '../../enums'

const AnnouncementScopeToColorMap = {
  [AnnouncementScope.System]: 'error',
  [AnnouncementScope.Tenant]: 'primary',
}

const AnnouncementScopeToIconMap = {
  [AnnouncementScope.System]: <PublicOutlined />,
  [AnnouncementScope.Tenant]: <PeopleOutlineOutlined />,
}

const AnnouncementScopeChip = ({ scope, onChange }) => {
  return (
    <Chip
      size='small'
      variant='outlined'
      sx={{ padding: 0.25 }}
      icon={AnnouncementScopeToIconMap[scope]}
      color={AnnouncementScopeToColorMap[scope]}
      label={AnnouncementScopeDisplayName[scope]}
      deleteIcon={onChange && <SettingsOutlined />}
      onDelete={onChange}
    />
  )
}

AnnouncementScopeChip.defaultProps = {
  scope: AnnouncementScope.Draft,
}

AnnouncementScopeChip.propTypes = {
  scope: PropTypes.oneOf(Object.values(AnnouncementScope)),
  onChange: PropTypes.func,
}

export default memo(AnnouncementScopeChip)
