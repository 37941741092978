import { combineReducers } from 'redux'

import isTimelineOpen from './isTimelineOpen'
import isProcessing from './isProcessing'
import imagesList from './imagesList'

export default combineReducers({
  isTimelineOpen,
  isProcessing,
  imagesList,
})
