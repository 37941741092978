import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getTenantContentReviews } from '@tabeeb/modules/contentReviews/actions'

const EMPTY_LIST = []

export default ({ id, onError }) => {
  const payload = useMemo(() => id, [id])

  const {
    loading,
    loaded,
    response: reviews,
  } = useApiRequest({
    defaultResponse: EMPTY_LIST,
    request: getTenantContentReviews.request,
    enabled: Boolean(id),
    payload,
    onError,
  })

  return { loaded, loading, reviews }
}
