import { memo } from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, Typography, Stepper, Step, StepButton } from '@mui/material'

const Title = ({ stages, setCurrentStageIndex, currentStageIndex }) => {
  if (stages?.length < 1) {
    return null
  }

  return (
    <DialogTitle>
      {stages.length > 1 ? (
        <Stepper nonLinear activeStep={currentStageIndex}>
          {stages.map((stage, index) => (
            <Step key={stage.label} completed={stage.isCompleted}>
              <StepButton color='inherit' onClick={() => setCurrentStageIndex(index)}>
                {stage.label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Typography>{stages[0].label}</Typography>
      )}
    </DialogTitle>
  )
}

Title.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          label: PropTypes.string.isRequired,
          isOptional: PropTypes.bool.isRequired,
          isCompleted: PropTypes.bool.isRequired,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
  currentStageIndex: PropTypes.number.isRequired,
  setCurrentStageIndex: PropTypes.func.isRequired,
}

export default memo(Title)
