const twoDLineDrawingRequests = {
  addTwoDLineDrawingRequest: (data) => ({
    url: `api/ai/2DLineDrawings/prepare`,
    method: 'post',
    data,
  }),
  regenerateTwoDLineDrawingRequest: (data) => ({
    url: `api/ai/2DLineDrawings/regenerate`,
    method: 'post',
    data,
  }),
}

export default twoDLineDrawingRequests
