import { handleActions } from 'redux-actions'

import { resetState } from '@tabeeb/modules/sessionsPage/actions'

import { setPointCloudGeoSearchOpen } from '../actions'

const defaultState = false

export default handleActions(
  {
    [setPointCloudGeoSearchOpen](state, { payload }) {
      return payload
    },
    [resetState]() {
      return defaultState
    },
  },
  defaultState
)
