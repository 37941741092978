import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(),
    },
    icon: {
      marginRight: theme.spacing(1.5),
    },
    label: {
      flexGrow: 1,

      ...theme.typography.body1,
    },
  }
})
