import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getVideoInputDevice } from '@tabeeb/modules/presentation/selectors'
import { getIsCurrentUserBeingRecorded } from '@tabeeb/shared/content/selectors'
import { isLocalRecordingActive } from '@tabeeb/modules/recording/services/localRecordingService'
import { openSwitchRecordingSourceDialog } from '@tabeeb/modules/whiteboard/actions'
import {
  loadCameraPreviews,
  setVideoInputDevice,
  unloadCameraPreviews,
} from '@tabeeb/modules/presentation/actions/devices'
import { getVideoInputDevices } from '@tabeeb/modules/presentation/services/conferenceService'

function useVideoSettings({ open }) {
  const dispatch = useDispatch()

  const [videoInputDevices, setVideoInputDevices] = useState([])
  const selectedVideoInputDevice = useSelector((state) => getVideoInputDevice(state))
  const showDevices = videoInputDevices.length && videoInputDevices[0].deviceId
  const isCurrentUserBeingRecorded = useSelector((state) => getIsCurrentUserBeingRecorded(state))

  const handleVideoInputDeviceClick = useCallback(
    (deviceId) => {
      if (isLocalRecordingActive() && isCurrentUserBeingRecorded) {
        dispatch(openSwitchRecordingSourceDialog(deviceId))
        return
      }
      if (deviceId !== selectedVideoInputDevice) {
        dispatch(setVideoInputDevice({ deviceId }))
      }
    },
    [dispatch, isCurrentUserBeingRecorded, selectedVideoInputDevice]
  )

  const updateDeviceList = useCallback(() => {
    getVideoInputDevices().then((devices) => {
      setVideoInputDevices(devices)

      if (selectedVideoInputDevice && !devices.find((device) => device.deviceId === selectedVideoInputDevice)) {
        dispatch(setVideoInputDevice({ deviceId: devices[0]?.deviceId }))
      }

      dispatch(unloadCameraPreviews())
      if (open) {
        dispatch(loadCameraPreviews())
      }
    })
  }, [dispatch, open, selectedVideoInputDevice])

  useEffect(() => {
    updateDeviceList()
    navigator.mediaDevices.addEventListener('devicechange', updateDeviceList)

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updateDeviceList)
    }
  }, [updateDeviceList, open])

  const returnValues = useMemo(
    () => ({ showDevices, selectedVideoInputDevice, handleVideoInputDeviceClick, videoInputDevices }),
    [handleVideoInputDeviceClick, selectedVideoInputDevice, showDevices, videoInputDevices]
  )

  return returnValues
}

export default useVideoSettings
