import { styled } from '@mui/material/styles'
import { ColumnFlexContainer } from '@tabeeb/uikit'

export const PageContainer = styled(ColumnFlexContainer)(({ theme }) => ({
  minHeight: '100%',
  height: 'max-content',
  width: '100%',
  color: theme.palette.text.main,
  backgroundColor: theme.palette.background.page,
}))

export const Container = styled(ColumnFlexContainer)(({ theme }) => ({
  paddingInline: theme.spacing(2),
  height: '100%',
  width: '100%',
  flexGrow: 1,
  maxWidth: theme.breakpoints.values.lg,
  margin: 'auto',
}))
