const galleryRequests = {
  getGalleryItemRequest: ({ pageId }) => ({
    url: `api/pages/${pageId}`,
    method: 'get',
  }),
  renameGalleryItemRequest: ({ pageId, name }) => ({
    url: `api/pages/rename`,
    method: 'put',
    data: {
      id: pageId,
      name,
    },
  }),
  deleteGalleryItemRequest: ({ pageId }) => ({
    url: `api/pages/${pageId}`,
    method: 'delete',
  }),
  uploadGalleryVideoByUrlRequest: ({ page }) => ({
    url: `api/pages`,
    method: 'post',
    data: page,
  }),
  addPointCloudModelRequest: (data) => ({
    url: `api/pages/pointCloud`,
    method: 'post',
    data,
  }),
  addPageRequest: (data) => ({
    url: `api/pages`,
    method: 'post',
    data,
  }),
  addPagesRequest: (data) => ({
    url: `api/pages/addpages`,
    method: 'post',
    data,
  }),
  addDrawingPageRequest: (data) => ({
    url: `api/pages`,
    method: 'post',
    data,
  }),
  addWebPageUsingUrlRequest: (data) => ({
    url: `api/pages`,
    method: 'post',
    data,
  }),
  addGalleryImageUploadRequest: (data) => ({
    url: `api/pages`,
    method: 'post',
    data,
  }),
  addGalleryPdfDocumentRequest: ({ contentId, folderId, insertionIndex, url, name = '', timeZoneOffset }) => {
    const request = {
      url: `api/contents/${contentId}/pdf?index=${insertionIndex}&pdfUrl=${url}&timeZoneOffset=${timeZoneOffset}&pdfName=${name}`,
      method: 'post',
    }

    if (folderId) {
      request.url = `${request.url}&folderId=${folderId}`
    }

    return request
  },
  addGalleryVideoUploadRequest: (data) => ({
    url: `api/pages`,
    method: 'post',
    data,
  }),
  getPagePosterLinkRequest: ({ pageId, withAnnotations }) => ({
    url: `api/pages/${pageId}/posterlink`,
    method: 'get',
    params: {
      withAnnotations,
    },
  }),
  exportAsPdfRequest: ({ contentId, folderId, galleryOption = 0, emails, exportOption }) => ({
    url: `api/contents/exportpdf`,
    method: 'post',
    data: {
      ContentId: contentId,
      FolderId: folderId,
      Emails: emails,
      Option: exportOption,
      GalleryOption: galleryOption,
    },
  }),
  exportImagesRequest: ({ contentId, folderId, galleryOption = 0, withAnnotations, emails }) => ({
    url: `api/contents/exportimages`,
    method: 'post',
    data: {
      ContentId: contentId,
      FolderId: folderId,
      IsWithAnnotations: withAnnotations,
      Emails: emails,
      GalleryOption: galleryOption,
    },
  }),
  exportImagesArchiveToFtpRequest: (data) => ({
    url: `api/contents/exportImagesArchiveToFtp`,
    method: 'post',
    data,
  }),
  updatePageNameRequest: (data) => ({
    url: `api/pages/updatepagename`,
    method: 'put',
    data,
  }),
  createSessionFolderRequest: (data) => ({
    url: `api/pageFolder`,
    method: 'post',
    data,
  }),
  deleteSessionFolderRequest: ({ folderId }) => ({
    url: `api/pageFolder/${folderId}`,
    method: 'delete',
  }),
  updatePageSessionFolderRequest: (data) => ({
    url: `api/pages/moveto`,
    method: 'put',
    data,
  }),
  updateSessionFolderPrivacyRequest: (data) => ({
    url: `api/pageFolder/setPrivacy`,
    method: 'put',
    data,
  }),
  getContentUsersSessionFolderPermissionsRequest: ({ folderId }) => ({
    url: `api/pageFolderPermissions/byFolder/${folderId}`,
    method: 'get',
  }),
  setPageFolderPermissionsRequest: ({ folderId, permissionModels }) => ({
    url: `api/pageFolderPermissions/${folderId}`,
    method: 'post',
    data: permissionModels,
  }),
  renameSessionFolderRequest: (data) => ({
    url: `api/pageFolder/rename`,
    method: 'put',
    data,
  }),
  addStructureModelRequest: (data) => ({
    url: `api/ai/structureModel`,
    method: 'post',
    data,
  }),
  getPagesByContentIdRequest: (contentId) => ({
    url: `api/pages/bycontent/${contentId}`,
    method: 'get',
  }),
  getAnswersTimelineForVideoPageRequest: ({ pageId }) => ({
    url: `api/pages/${pageId}/answersTimeline`,
    method: 'get',
  }),
  getDeletedPagesRequest: (sessionId) => ({
    url: `api/pages/${sessionId}/deleted`,
    method: 'get',
  }),
  getDeletedSessionFoldersRequest: (sessionId) => ({
    url: `api/pageFolder/${sessionId}/deleted`,
    method: 'get',
  }),
  restorePageRequest: (pageId) => ({
    url: `api/pages/${pageId}/restore`,
    method: 'put',
  }),
  restoreSelectedPagesRequest: (pageIds) => ({
    url: `api/pages/bulk/restore`,
    method: 'put',
    data: pageIds,
  }),
  restoreContentTrashBinFolderRequest: (folderId) => ({
    url: `api/pageFolder/${folderId}/restore`,
    method: 'put',
  }),
  restoreContentTrashBinRequest: (sessionId) => ({
    url: `api/contents/${sessionId}/trashbin/restore`,
    method: 'put',
  }),
  deletePagePermanentlyRequest: (pageId) => ({
    url: `api/pages/${pageId}/permanently`,
    method: 'delete',
  }),
  deleteSelectedPagesPermanentlyRequest: (pageIds) => ({
    url: `api/pages/bulk/delete/permanently`,
    method: 'put',
    data: pageIds,
  }),
  deleteContentTrashBinFolderRequest: (folderId) => ({
    url: `api/pageFolder/${folderId}/permanently`,
    method: 'delete',
  }),
  emptyContentTrashBinRequest: (sessionId) => ({
    url: `api/contents/${sessionId}/trashbin/empty`,
    method: 'delete',
  }),
  getSessionFoldersRequest: (contentId) => ({
    url: `api/pagefolder/bycontent/${contentId}`,
    method: 'get',
  }),
  sendUnarchiveModelRequest: (data) => ({
    url: `api/matterport/model/unarchive`,
    method: 'post',
    data,
  }),
  getAutodeskViewableRequest: (pageId) => ({
    url: `autodesk-service/api/translation/${pageId}`,
    method: 'get',
  }),
  addAutodeskPageRequest: (data) => ({
    url: `api/pages/autodesk`,
    method: 'post',
    data,
  }),
  getPageMeasurementSettingsRequest: (pageId) => ({
    url: `api/pages/${pageId}/measurementSettings`,
    method: 'get',
  }),
  updatePageMeasurementSettingsRequest: (data) => ({
    url: `api/pages/${data.PageId}/measurementSettings`,
    method: 'put',
    data,
  }),
  getSourcePagesAssetsRequest: (pageId) => ({
    url: `api/pages/${pageId}/source-pages-assets`,
    method: 'get',
  }),
}

export default galleryRequests
