import PropTypes from 'prop-types'
import classNames from 'classnames'

import UserAvatar from '../../../shared/userAvatar'
import ActivityInfoContainer from '../../containers/ActivityInfoContainer'

import './styles.less'

const ActivityItem = ({ activity, isSelectedActivity, handleSelectActivity }) => {
  return (
    <li className={classNames({ 'activity-item': true, selected: isSelectedActivity })} onClick={handleSelectActivity}>
      <div className='activityItem_userAvatar'>
        <UserAvatar user={activity.user} />
      </div>
      <ActivityInfoContainer activity={activity} />
    </li>
  )
}

ActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
  isSelectedActivity: PropTypes.bool.isRequired,
  handleSelectActivity: PropTypes.func.isRequired,
}

export default ActivityItem
