import { handleActions, combineActions } from 'redux-actions'

import { onPlayerChangeVolume, setCurrentVideoVolume, resetCurrentVideoTimestamp } from '../actions'

const defaultValue = 1

export default handleActions(
  {
    [combineActions(setCurrentVideoVolume, onPlayerChangeVolume)](state, { payload }) {
      return payload
    },
    [resetCurrentVideoTimestamp](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
