import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.deployIteration.request](state, { payload }) {
      return true
    },
    [combineActions(rawActions.deployIteration.failed, rawActions.deployIteration.success)](state, { payload }) {
      return false
    },
  },
  defaultState
)
