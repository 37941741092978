import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    button: {
      background: '#ffffff',
      padding: theme.spacing(0.75),
      minWidth: 0,
    },
    icon: {
      margin: '0px !important',
    },
  }
})
