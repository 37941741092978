import { handleActions } from 'redux-actions'
import { setLastAttachedForm, closeSwitchFormDialog, resetFormsState } from '../actions'

const defaultValue = {}

const lastAttachedForm = handleActions(
  {
    [setLastAttachedForm](state, action) {
      return action.payload
    },
    [closeSwitchFormDialog](state, action) {
      return defaultValue
    },
    [resetFormsState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)

export default lastAttachedForm
