import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@mui/material'
import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'
import { StyledTextField } from '../Modals/AddPowerBIReportModal/styles'
import { getPowerBISecurityModels } from '../../actions'

const SecurityModelsSelect = ({
  defaultValue,
  helperText,
  onChange,
  onBlur,
  inputName,
  multiple = false,
  variant = 'standard',
}) => {
  const [search, setSearch] = useState('')
  const { loading, response } = useApiRequest({ request: getPowerBISecurityModels.request })

  const onSearchChange = useCallback((_event, value) => {
    setSearch(value)
  }, [])

  const options = useMemo(() => {
    if (!response) {
      return []
    }
    return response.map((m) => ({ label: m.Name, id: m.Id }))
  }, [response])

  return (
    <Autocomplete
      loading={loading}
      options={options}
      name={inputName}
      size='small'
      fullWidth
      inputValue={search}
      onInputChange={onSearchChange}
      onChange={onChange}
      onBlur={onBlur}
      multiple={multiple}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          variant={variant}
          name={inputName}
          label={multiple ? 'Security models' : 'Security model'}
          fullWidth
          helperText={helperText}
        />
      )}
    />
  )
}

SecurityModelsSelect.propTypes = {
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  inputName: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  defaultValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
}

export default SecurityModelsSelect
