import { forwardRef, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button, Zoom } from '@material-ui/core'

import useStyles from './styles'

const ToolbarButton = forwardRef(({ className, color, htmlColor, display, selected, disabled, Icon, ...rest }, ref) => {
  const classes = useStyles()

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.dispatchEvent(new CustomEvent('resize'))

    return () => {
      // eslint-disable-next-line no-undef
      window.dispatchEvent(new CustomEvent('resize'))
    }
  }, [])

  if (!display) {
    return null
  }

  return (
    <Zoom in={display} unmountOnExit>
      <Button
        ref={ref}
        className={classNames(
          classes.icon,
          className,
          { [classes.active]: selected },
          { [classes.disabled]: disabled }
        )}
        disabled={disabled}
        {...rest}
      >
        <Icon color={htmlColor ? undefined : color} htmlColor={htmlColor} />
      </Button>
    </Zoom>
  )
})

ToolbarButton.defaultProps = {
  className: undefined,
  title: null,
  color: 'action',
  display: true,
  disabled: false,
  selected: false,
  onClick: () => {},
}

ToolbarButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  htmlColor: PropTypes.string,
  title: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.func.isRequired]).isRequired,
  display: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
}

export default memo(ToolbarButton)
