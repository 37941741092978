import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'stretch',

      '& > *': {
        margin: 0,
        borderRadius: 0,

        '&:first-child': {
          borderRight: 'none',
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        },

        '&:last-child': {
          borderLeft: 'none',
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
      },
    },
    zoomValue: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5),
      borderWidth: 1,
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderStyle: 'solid',
      userSelect: 'none',
    },
  }
})
