import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Divider, Grid, LinearProgress, Paper, Typography } from '@mui/material'
import { PageHeader, PageLayout } from '@tabeeb/uikit'
import { FetchStatus } from '@tabeeb/enums'
import { Delete, Save } from '@mui/icons-material'
import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import PowerBIProviderConfiguration from './PowerBIProviderConfiguration'
import { deletePowerBIReportProvider, getPowerBIReportProvider } from '../../actions'
import { PowerBIProviderModal } from '../Modals'
import { getPowerBIReportProviderState } from '../../selectors'
import usePowerBIProviderUsers from '../../hooks/usePowerBIProviderUsers'
import usePowerBIProviderForm from '../../hooks/forms/usePowerBIProviderForm'
import usePowerBIProviderUsersForm from '../../hooks/forms/usePowerBIProviderUsersForm'
import PowerBIProviderProperties from './PowerBIProviderProperties'
import PowerBIProviderUsers from './PowerBIProviderUsers'
import { DeleteReportProviderConfirmationDialog } from '../Dialogs'

const PowerBIReportProvider = ({ id }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPowerBIReportProvider.request(id))
  }, [dispatch, id])

  const { fetchStatus, provider, saveStatus, deleteStatus } = useSelector(getPowerBIReportProviderState)

  const [deleteReportProviderDialogOpen, onDeleteReportProviderDialogOpen, onDeleteReportProviderDialogClose] =
    useDialogState()

  const { fetchStatus: usersFetchStatus, users } = usePowerBIProviderUsers({
    providerId: provider.Id,
    enabled: !!provider.Id,
  })

  const providerForm = usePowerBIProviderForm(provider)

  const [usersForm, onRemoveUser, onUserSelectChange] = usePowerBIProviderUsersForm({
    providerId: provider.Id,
    initialUsers: users,
  })

  const onSave = useCallback(() => {
    if (providerForm.isValid && providerForm.dirty) {
      providerForm.submitForm()
    }
    if (usersForm.isValid && usersForm.dirty) {
      usersForm.submitForm()
    }
  }, [providerForm, usersForm])

  const onDelete = useCallback(() => {
    dispatch(deletePowerBIReportProvider.request(provider.Id))
    onDeleteReportProviderDialogClose()
  }, [dispatch, onDeleteReportProviderDialogClose, provider.Id])

  return (
    <PageLayout component={Paper} variant='outlined' spacing='page' maxWidth='lg'>
      <PageHeader
        title='Edit PowerBI reports provider'
        actions={
          <Grid container spacing={1} item xs={12}>
            <Grid item>
              <Button
                variant='outlined'
                color='error'
                onClick={onDeleteReportProviderDialogOpen}
                startIcon={
                  deleteStatus === FetchStatus.Loading ? <CircularProgress size={18} /> : <Delete color='error' />
                }
                disabled={fetchStatus !== FetchStatus.Loaded}
              >
                Delete
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                startIcon={
                  saveStatus === FetchStatus.Loading ? <CircularProgress size={18} /> : <Save color='primary' />
                }
                disabled={!providerForm.dirty && !usersForm.dirty}
                onClick={onSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        }
      />
      <Paper variant='outlined' sx={{ p: 3, height: '100%', overflow: 'auto' }}>
        {fetchStatus === FetchStatus.Loading && <LinearProgress />}
        {fetchStatus === FetchStatus.Failed && (
          <Typography sx={{ textAlign: 'center' }}>Failed to load provider info.</Typography>
        )}
        {fetchStatus === FetchStatus.Loaded && (
          <Grid container spacing={1} flexDirection='column'>
            <PowerBIProviderProperties form={providerForm} provider={provider} />
            <Divider sx={{ my: 1 }} />
            <PowerBIProviderConfiguration provider={provider} />
            <Divider sx={{ my: 1 }} />
            <Grid container item xs={12}>
              <Typography variant='body1' fontWeight={500} mb={1}>
                Users
              </Typography>
              {usersFetchStatus === FetchStatus.Loading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              {usersFetchStatus === FetchStatus.Failed && (
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: 'center' }}>Failed to load provider users.</Typography>
                </Grid>
              )}
              {usersFetchStatus === FetchStatus.Loaded && (
                <PowerBIProviderUsers
                  form={usersForm}
                  onRemoveUser={onRemoveUser}
                  onUserSelectChange={onUserSelectChange}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
      <PowerBIProviderModal />
      <DeleteReportProviderConfirmationDialog
        open={deleteReportProviderDialogOpen}
        onClose={onDeleteReportProviderDialogClose}
        onSubmit={onDelete}
      />
    </PageLayout>
  )
}

PowerBIReportProvider.propTypes = {
  id: PropTypes.string.isRequired,
}

export default PowerBIReportProvider
