import { styled } from '@mui/material/styles'
import { Input } from '@mui/material'
import { Search } from '@mui/icons-material'

export const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: '#fff',
  border: '1px solid #e5e5e5',
  borderRadius: theme.shape.borderRadius,
  flexGrow: 1,
  maxWidth: 320,
  paddingInline: theme.spacing(2),
  paddingBlock: theme.spacing(0.75),

  ...theme.typography.body1,
  fontWeight: 500,
}))

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  ...theme.typography.h6,
}))
