import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultValue = false

export default handleActions(
  {
    [actions.enableGridView](state, action) {
      return true
    },
    [actions.disableGridView](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
