import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import { TabPanel } from '@tabeeb/enums'
import Timeline from '@tabeeb/modules/timeline/components/Timeline'
import { getNavbarCurrentBottomPanel } from '@tabeeb/modules/customVideo/selectors'
import {
  closeSplatModelTimeline,
  createSplatModel,
  deleteImageFromSplatModel,
  updateImagesSplatModel,
} from '../../actions'
import { getIsTimelineOpen, getPagesList } from '../../selectors'
import { PAGES_REQUIRED_FOR_SPLAT_MODEL } from '../../services/constants'
import SplatSettingsDialog from '../SplatSettingsDialog'

const SplatModelTimeline = () => {
  const dispatch = useDispatch()
  const pages = useSelector(getPagesList)
  const isOpen = useSelector(getIsTimelineOpen)
  const currentPanel = useSelector(getNavbarCurrentBottomPanel)
  const [isDialogOpen, openDialog, closeDialog] = useDialogState()

  const handleClose = () => {
    dispatch(closeSplatModelTimeline())
  }

  const createModel = ({ FolderId, PageName }) => {
    if (pages && pages.length >= PAGES_REQUIRED_FOR_SPLAT_MODEL) {
      dispatch(createSplatModel.request({ PageIds: pages.map((p) => p.id), FolderId, PageName }))
    }
  }

  const handleDeleteItem = (pageIndex) => {
    dispatch(deleteImageFromSplatModel(pageIndex))
  }

  const handleUpdateList = (newList) => {
    dispatch(updateImagesSplatModel(newList))
  }

  const actions = [
    {
      onClick: handleClose,
      title: 'Cancel',
      class: 'close',
    },
    {
      onClick: openDialog,
      title: 'Done',
      class: 'submit',
      tooltip:
        pages.length < PAGES_REQUIRED_FOR_SPLAT_MODEL
          ? `A minimum of ${PAGES_REQUIRED_FOR_SPLAT_MODEL} pages is required to create a SPLAT model.`
          : null,
      disabled: pages.length < PAGES_REQUIRED_FOR_SPLAT_MODEL,
    },
  ]

  const props = {
    actions,
    timeLineList: pages,
    handleDeleteItem,
    handleUpdateList,
    isOpen,
    isSelected: currentPanel === TabPanel.SplatModel,
  }

  return (
    <>
      <Timeline {...props} />
      <SplatSettingsDialog
        createSplatModel={createModel}
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        pageIds={pages.map((p) => p.id)}
      />
    </>
  )
}

export default memo(SplatModelTimeline)
