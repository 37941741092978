import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const makeReducer = (actionAdd, actionDelete, actionSet) => {
  const defaultState = []

  return handleActions(
    {
      [actionAdd](state, { payload: newItem }) {
        if (state.some((item) => item.Id === newItem.Id)) {
          return state
        }

        return [...state, newItem]
      },
      [actionDelete](state, { payload: id }) {
        return state.filter((item) => item.Id !== id)
      },
      [actionSet](state, { payload }) {
        return [...payload]
      },
      [rawActions.setSelectedProject](state, action) {
        return defaultState
      },
    },
    defaultState
  )
}

export default {
  selectedContents: makeReducer(
    rawActions.addToSelectedContents,
    rawActions.deleteFromSelectedContents,
    rawActions.setSelectedContents
  ),
  selectedAIObjects: makeReducer(
    rawActions.addToSelectedAiObjects,
    rawActions.deleteFromSelectedAiObjects,
    rawActions.setSelectedAiObjects
  ),
  selectedForms: makeReducer(
    rawActions.addToSelectedForms,
    rawActions.deleteFromSelectedForms,
    rawActions.setSelectedForms
  ),
  selectedAnnotationOwners: makeReducer(
    rawActions.addToSelectedAnnotationOwners,
    rawActions.deleteFromSelectedAnnotationOwners,
    rawActions.setSelectedAnnotationOwners
  ),
  selectedAnswerers: makeReducer(
    rawActions.addToSelectedAnswerers,
    rawActions.deleteFromSelectedAnswerers,
    rawActions.setSelectedAnswerers
  ),
  selectedApprovers: makeReducer(
    rawActions.addToSelectedApprovers,
    rawActions.deleteFromSelectedApprovers,
    rawActions.setSelectedApprovers
  ),
  selectedReviewers: makeReducer(
    rawActions.addToSelectedReviewers,
    rawActions.deleteFromSelectedReviewers,
    rawActions.setSelectedReviewers
  ),
  selectedFormControls: makeReducer(
    rawActions.addToSelectedFormControls,
    rawActions.deleteFromSelectedFormControls,
    rawActions.setSelectedFormControls
  ),
}
