import { getAddButtonColor } from '@tabeeb/modules/appConfigState/selectors'
import { useState, memo } from 'react'
import { useSelector } from 'react-redux'
import { getFormButtonState } from '@tabeeb/modules/forms/selectors'
import AttachFormsDialogContainer from '../../containers/AttachFormsDialogContainer'
import { FormButtons } from '../../services/enums'
import FormButton from '../FormButton'

const AddFormButton = () => {
  const [isAttachFormDialogOpen, setIsAttachFormDialogOpen] = useState(false)
  const { isVisible } = useSelector((state) => getFormButtonState(state, { button: FormButtons.AddForm }))
  const color = useSelector(getAddButtonColor)

  if (!isVisible) return null

  const dialogProps = {
    onCloseDialog: () => setIsAttachFormDialogOpen(false),
    isOpen: isAttachFormDialogOpen,
  }

  const componentProps = {
    onClick: () => setIsAttachFormDialogOpen(true),
    buttonText: 'Add',
    buttonColor: color,
  }

  return (
    <>
      <FormButton {...componentProps} />
      {isAttachFormDialogOpen && <AttachFormsDialogContainer {...dialogProps} />}
    </>
  )
}

export default memo(AddFormButton)
