import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import SearchResultRowButton from './searchResultRowButton'
import { SearchResultItem, SearchResultItemInfo, SearchResultItemName, LockIcon } from './styles'

const ContentRow = ({ content, button, handleButtonClick }) => {
  const _handleClick = useCallback(() => {
    if (content.IsActive) {
      handleButtonClick(content)
    }
  }, [handleButtonClick, content])

  return (
    <SearchResultItem variant='outlined' disabled={!content.IsActive}>
      <SearchResultItemInfo>
        {!content.IsActive && <LockIcon />}
        <SearchResultItemName>{content.Description}</SearchResultItemName>
      </SearchResultItemInfo>
      {content.IsActive && <SearchResultRowButton button={button} handleButtonClick={_handleClick} />}
    </SearchResultItem>
  )
}

ContentRow.propTypes = {
  content: PropTypes.shape({
    Description: PropTypes.string.isRequired,
    IsActive: PropTypes.bool.isRequired,
  }).isRequired,
  button: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
}

export default memo(ContentRow)
