import { createApiActions } from '@tabeeb/shared/utils/actions'

export const getQualificationsWithPagination = createApiActions('GET_QUALIFICATIONS_WITH_PAGINATION')
export const getQualificationVersions = createApiActions('GET_QUALIFICATION_VERSIONS')
export const getQualificationVersion = createApiActions('GET_QUALIFICATION_VERSION')
export const createQualification = createApiActions('CREATE_QUALIFICATION')
export const createQualificationVersion = createApiActions('CREATE_QUALIFICATION_VERSION')
export const updateQualification = createApiActions('UPDATE_QUALIFICATION')
export const updateQualificationVersion = createApiActions('UPDATE_QUALIFICATION_VERSION')
export const deleteQualification = createApiActions('DELETE_QUALIFICATION')
export const restoreQualification = createApiActions('RESTORE_QUALIFICATION')
export const getUserQualifications = createApiActions('GET_USER_QUALIFICATIONS')
export const getActiveUserQualificationIds = createApiActions('GET_ACTIVE_USER_QUALIFICATION_IDS')
export const createUserQualification = createApiActions('CREATE_USER_QUALIFICATION')
export const revokeUserQualification = createApiActions('REVOKE_USER_QUALIFICATION')
export const restoreUserQualification = createApiActions('RESTORE_USER_QUALIFICATION')
export const searchQualificationVersions = createApiActions('SEARCH_QUALIFICATION_VERSIONS')
export const searchQualification = createApiActions('SEARCH_QUALIFICATION')
