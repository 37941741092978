import { createSelector } from 'reselect'
import { ContentSharingModelType, SessionPermission, TabPanel } from '@tabeeb/enums'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'
import {
  getIsContentReviewingSettingEnabled,
  getIsContentSharingSettingEnabled,
  getIsContentsLinkingSettingEnabled,
  getIsMergeForReviewEnabled,
} from '@tabeeb/modules/appConfigState/selectors'
import { getNavbarCurrentBottomPanel } from '@tabeeb/modules/customVideo/selectors'

export const getLinkedContentsList = (state) => state.contentSharings.linkedContents.list
export const getContentSharingDialogState = (state) => state.contentSharings.contentSharingDialogState
export const getContentSharingPages = (state) => state.contentSharings.contentSharingDialogState.pages
export const getContentSharingForms = (state) => state.contentSharings.contentSharingDialogState.contentForms
export const getContentSharingTenant = (state) => state.contentSharings.contentSharingDialogState.tenant
export const getUserSearchState = (state) => state.contentSharings.userSearch
export const getContentSearchState = (state) => state.contentSharings.contentSearch
export const getHasShareContentPermission = (state) => hasSessionPermission(state, SessionPermission.ExportSession)
export const getIsSelectedPagesForMergingOpen = (state) => state.contentSharings.selectedPagesForMerging.isOpen

export const getIsContentSharingEnabled = createSelector(
  [getIsContentSharingSettingEnabled, getHasShareContentPermission],
  (isSettingEnabled, hasPermission) => {
    return isSettingEnabled && hasPermission
  }
)

export const getIsContentReviewingEnabled = createSelector(
  [getIsContentReviewingSettingEnabled, getIsMergeForReviewEnabled, getHasShareContentPermission],
  (isReviewSettingEnabled, isMergeReviewSettingEnabled, hasPermission) => {
    return (isReviewSettingEnabled || isMergeReviewSettingEnabled) && hasPermission
  }
)

export const getIsContentLinkingEnabled = createSelector(
  [getIsContentsLinkingSettingEnabled, getHasShareContentPermission],
  (isSettingEnabled, hasPermission) => {
    return isSettingEnabled && hasPermission
  }
)

export const getIsSelectedPagesForMergingNavbarTabActive = createSelector(
  [getIsSelectedPagesForMergingOpen, getNavbarCurrentBottomPanel],
  (isOpen, currentBottomTab) => {
    return isOpen && currentBottomTab === TabPanel.LinkedContentsSelectedPages
  }
)

export const getContentReviewTypes = createSelector(
  [getIsContentReviewingSettingEnabled, getIsMergeForReviewEnabled],
  (isReviewSettingEnabled, isMergeReviewSettingEnabled) => {
    const types = []

    if (isReviewSettingEnabled) {
      types.push({ type: ContentSharingModelType.ForReview, description: 'Create review in new session' })
    }

    if (isMergeReviewSettingEnabled) {
      types.push({
        type: ContentSharingModelType.MergeForReview,
        description: 'Merge for review into an existing session',
      })
    }

    return types
  }
)

export const getContentReviewDefaultType = createSelector([getContentReviewTypes], (contentReviewTypes) => {
  if (contentReviewTypes.length === 0) {
    return null
  }

  return contentReviewTypes[0].type
})
