import PropTypes from 'prop-types'
import HealthDevice from '@tabeeb/modules/healthData/components/HealthDevice'

const HealthDevices = ({ healthDevices, openAddEditDeviceModal, onClearTabletSubmit, assignedLife365DeviceStatus }) => {
  return (
    healthDevices.length > 0 &&
    healthDevices.map((healthDevice) => (
      <HealthDevice
        key={healthDevice.Id}
        healthDevice={healthDevice}
        openAddEditDeviceModal={openAddEditDeviceModal}
        onClearTabletSubmit={onClearTabletSubmit}
        assignedLife365DeviceStatus={assignedLife365DeviceStatus}
      />
    ))
  )
}

HealthDevices.propTypes = {
  healthDevices: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      DeviceTypeId: PropTypes.number.isRequired,
      CategoryType: PropTypes.number.isRequired,
      VendorType: PropTypes.number.isRequired,
      ExternalId: PropTypes.string.isRequired,
      IsDisabled: PropTypes.bool.isRequired,
      UserId: PropTypes.string.isRequired,
      ProgramId: PropTypes.string,
      TenoviSensorCodeType: PropTypes.number,
    })
  ),
  openAddEditDeviceModal: PropTypes.func.isRequired,
  onClearTabletSubmit: PropTypes.func.isRequired,
  assignedLife365DeviceStatus: PropTypes.shape({
    TabletId: PropTypes.string,
    BatteryLife: PropTypes.number,
    OnCharger: PropTypes.bool,
    DeviceTime: PropTypes.instanceOf(Date),
  }),
}

export default HealthDevices
