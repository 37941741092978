import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    root: {},
    date: {
      width: 30,
      height: 30,
      background: 'white',
      borderRadius: 50,
      border: 'none',
      outline: 'none',
      color: theme.palette.text.primary,
      margin: theme.spacing(0.25),

      ...theme.typography.body2,
    },
    selected: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: theme.palette.primary.main,

      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    today: {
      color: theme.palette.primary.main,

      '&$selected': {
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
    },
    outside: {
      color: theme.palette.text.secondary,
    },
  }
})
