import { Lock } from '@mui/icons-material'
import { Button, DialogContent, Input, Paper, Select, styled, Typography } from '@mui/material'
import { ColumnFlexContainer, FlexContainer } from '@tabeeb/uikit'
import { buttonStyles } from '../styles'

const textStyles = {
  fontWeight: 'bold',
  color: '#616161',
}

const inputStyles = {
  ...textStyles,
  border: '1px solid #c4c4c4',
  borderRadius: 6,
  paddingLeft: 6,
  fontSize: 12,
}

export const Container = styled(DialogContent)(({ theme }) => ({
  width: 600,
}))

export const StepContainer = styled(ColumnFlexContainer)(({ theme, disabled }) => ({
  marginBottom: theme.spacing(),
  opacity: disabled ? 0.5 : 1,
}))

export const StepTitle = styled(FlexContainer)(({ theme }) => ({
  ...textStyles,
  justifyContent: 'space-between',
  fontSize: 14,
}))

export const StepContent = styled(FlexContainer)(({ theme }) => ({}))
export const SelectContainer = styled(ColumnFlexContainer)(({ theme }) => ({}))

export const SearchResultContainer = styled(ColumnFlexContainer)(({ theme }) => ({
  padding: `0 1px`,
  marginTop: theme.spacing(),
  maxHeight: 200,
  overflow: 'auto',
}))

export const SearchResultItem = styled(Paper)(({ theme, disabled }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(),
  padding: theme.spacing(0.75),
  opacity: disabled ? 0.6 : 1,
}))

export const SearchResultItemInfo = styled(FlexContainer)(({ theme }) => ({
  width: '87%',
  alignItems: 'center',
}))

export const SearchResultItemName = styled(Typography)(({ theme }) => ({
  ...textStyles,
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: 12,
}))

export const StyledInput = styled(Input)(({ theme }) => ({
  ...inputStyles,
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  ...inputStyles,
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  ...buttonStyles,
}))

export const LockIcon = styled(Lock)(({ theme }) => ({
  width: 14,
  position: 'relative',
  bottom: 1,
  marginRight: theme.spacing(0.5),
}))
