import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.getAiObjectsForVqaPolicySuccess](state, action) {
      return [...action.response.data]
    },
  },
  defaultState
)
