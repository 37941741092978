import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.setLoadingContent](state, action) {
      return action.payload
    },
    [rawActions.getTenantContentsDashboards.request](state, action) {
      return true
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
