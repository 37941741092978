import { put, takeLatest, select, all, take } from 'redux-saga/effects'

import { SessionNamingConventionType } from '@tabeeb/enums'
import { getHasShareContentPermission } from '@tabeeb/modules/contentSharings/selectors'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import {
  closeTenantFormsDialog,
  openTenantFormsDialog,
  submitTenantFormAnswers,
} from '@tabeeb/modules/tenantForms/actions'
import { getContentNameParts, getSubmittedAnswers } from '@tabeeb/modules/tenantForms/selectors'
import { getSessionPermissionsSuccess } from '@tabeeb/modules/permissions/actions'
import { getTenantsListForReview } from '../selectors'
import * as rawActions from '../actions'

function* onGetSessionPermissionsSuccess(action) {
  const hasShareContentPermission = yield select(getHasShareContentPermission)
  if (!hasShareContentPermission) return

  const contentId = yield select(getContentId)
  yield put(rawActions.getTenantsListForReviewRequest(contentId))
  yield put(rawActions.getContentDescriptionsRequest(contentId))
}

function* renameContentForTenant(action) {
  const tenantId = action.payload

  const tenantsListWithNamingConventionInfo = yield select(getTenantsListForReview)
  const contentId = yield select(getContentId)

  const reviewerTenant = tenantsListWithNamingConventionInfo.find((t) => t.Id === tenantId)

  let description = null
  let contentNameParts = null

  yield put(
    openTenantFormsDialog({
      sncType: SessionNamingConventionType.RenameSession,
      subdomain: reviewerTenant?.Subdomain,
    })
  )

  yield take([submitTenantFormAnswers])

  description = yield select(getSubmittedAnswers)
  contentNameParts = yield select(getContentNameParts)

  yield put(closeTenantFormsDialog())

  const data = {
    contentId,
    tenantId,
    description,
    contentNameParts,
  }

  yield put(rawActions.renameContentForTenantRequest(data))
}

function* contentDescription() {
  yield all([
    takeLatest(getSessionPermissionsSuccess, onGetSessionPermissionsSuccess),
    takeLatest(rawActions.renameContentForTenant, renameContentForTenant),
  ])
}

export default contentDescription
