import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(
      rawActions.getAllFormsSuccess,
      rawActions.getAllFormsFailed,
      rawActions.getFormsByFolderIdSuccess,
      rawActions.getFormsByFolderIdFailed
    )]() {
      return defaultState
    },
    [combineActions(rawActions.getAllFormsRequest, rawActions.getFormsByFolderIdRequest)]() {
      return true
    },
    [rawActions.resetState]() {
      return defaultState
    },
  },
  defaultState
)
