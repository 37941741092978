import _ from 'lodash'

export const getViewBoxFromAnnotationCoordinates = (annotation) => {
  return {
    topLeftX: _.reduce(
      annotation.Coordinates,
      (x, coordinate) => Math.min(x, coordinate.Start.X, coordinate.End.X),
      Number.MAX_VALUE
    ),
    topLeftY: _.reduce(
      annotation.Coordinates,
      (y, coordinate) => Math.min(y, coordinate.Start.Y, coordinate.End.Y),
      Number.MAX_VALUE
    ),
    bottomRightX: _.reduce(
      annotation.Coordinates,
      (x, coordinate) => Math.max(x, coordinate.Start.X, coordinate.End.X),
      Number.MIN_VALUE
    ),
    bottomRightY: _.reduce(
      annotation.Coordinates,
      (y, coordinate) => Math.max(y, coordinate.Start.Y, coordinate.End.Y),
      Number.MIN_VALUE
    ),
  }
}

export const getViewBoxFromAnnotationPoints = (annotation) => {
  return {
    topLeftX: _.minBy(annotation.Points, (point) => point.X).X,
    topLeftY: _.minBy(annotation.Points, (point) => point.Y).Y,
    bottomRightX: _.maxBy(annotation.Points, (point) => point.X).X,
    bottomRightY: _.maxBy(annotation.Points, (point) => point.Y).Y,
  }
}
