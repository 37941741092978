import { AIModelType, MLModelType } from '@tabeeb/enums'

export * from './validationSchemas'

export const boolToText = (flag) => (flag ? 'On' : 'Off')

export const isMLModelWithKey = (mlModelType) => {
  switch (mlModelType) {
    case MLModelType.AIModel:
      return true
    default:
      return false
  }
}

export const isAIModelWithKey = (aiModelType) => {
  switch (aiModelType) {
    case AIModelType.Custom:
    case AIModelType.CustomVision:
    case AIModelType.HuggingFace:
    case AIModelType.Roboflow:
      return true
    default:
      return false
  }
}

export const isAIModelKeyRequired = (aiModelType) => {
  switch (aiModelType) {
    case AIModelType.CustomVision:
    case AIModelType.HuggingFace:
    case AIModelType.Roboflow:
      return true
    case AIModelType.Custom:
    default:
      return false
  }
}

export const getAIModelKeyName = (aiModelType) => {
  switch (aiModelType) {
    case AIModelType.CustomVision:
      return 'Prediction Key'
    case AIModelType.Roboflow:
      return 'Private API Key'
    case AIModelType.HuggingFace:
    case AIModelType.Custom:
    default:
      return 'Token'
  }
}

export const isAIModelUrlDisplayed = (aiModelType) => {
  return aiModelType !== AIModelType.InternalTrained
}

export const isOnlyAIObjectsOfParentAllowed = (aiModelType) => {
  return aiModelType === AIModelType.InternalTrained
}
