const contentRequests = {
  renameContentRequest: (data) => ({
    url: `api/contents/updatecontentname`,
    method: 'post',
    data,
  }),
  getContentRequest: ({ contentId }) => ({
    url: `api/contents/getpartialcontent/${contentId}`,
    method: 'get',
  }),
  checkSessionInviteRequest: ({ contentId }) => ({
    url: `api/invites/checkcontent/${contentId}`,
    method: 'get',
  }),
  getVideoBridgeHostsRequest: ({ url }) => ({
    url: `api/config/getvideobridgehosts`,
    method: 'post',
    data: {
      Domain: url,
    },
  }),
  setPresenterRequest: ({ contentId, presenterId }) => ({
    url: `api/contents/setpresenter`,
    method: 'post',
    data: {
      ContentId: contentId,
      PresenterId: presenterId,
    },
  }),
  confirmPresenterStateRequest: ({ contentId }) => ({
    url: `api/contents/confirmpresenterstate/${contentId}`,
    method: 'get',
  }),
  leaveContentRequest: (data) => ({
    url: `api/contents/leavecontent`,
    method: 'post',
    data,
  }),

  toggleChatNotificationsMuteRequest: ({ contentId, value }) => ({
    url: `api/contents/${contentId}/toggleChatNotificationsMute`,
    method: 'put',
    params: {
      value,
    },
  }),
  getContentsForProjectRequest: (data) => ({
    url: `api/contents/tenant/project`,
    method: 'get',
    params: data,
  }),
}

export default contentRequests
