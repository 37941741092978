import { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { Fade, IconButton, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { MoreVertOutlined } from '@mui/icons-material'

import { InviteStatus, UserRole } from '@tabeeb/enums'
import UserAvatar from '@tabeeb/shared/userAvatar'

import UserRoleSelect from '@tabeeb/modules/../users/components/UserRoleSelect'

import ExpirationStatusChip from './ExpirationStatusChip'
import LinkAccessChip from './LinkAccessChip'
import StatusBadge from './StatusBadge'

const Invite = ({ invite, onUpdateInviteRole, onOpenMenu }) => {
  const recipient = invite.Recipient || { Name: invite.RecipientEmail, IsAccountDeleted: false }

  const status = invite.Accepted ? InviteStatus.Accepted : invite.Ignored ? InviteStatus.Declined : InviteStatus.Pending

  return (
    <Fade in>
      <ListItem disableGutters>
        <ListItemAvatar>
          <StatusBadge status={status}>
            <UserAvatar user={recipient} />
          </StatusBadge>
        </ListItemAvatar>
        <ListItemText primary={recipient.Name} secondary={`Invited ${moment(invite.CreatedOn).calendar()}`} />
        <ExpirationStatusChip invite={invite} />
        {invite.IdentityProtected && <LinkAccessChip />}
        {!invite.Accepted && (
          <UserRoleSelect
            role={invite.Role}
            onChange={({ target: { value: role } }) => onUpdateInviteRole({ Id: invite.Id, Role: role })}
          />
        )}
        <IconButton size='small' onClick={(e) => onOpenMenu({ anchor: e.currentTarget, item: invite })}>
          <MoreVertOutlined />
        </IconButton>
      </ListItem>
    </Fade>
  )
}

Invite.propTypes = {
  invite: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Accepted: PropTypes.bool.isRequired,
    Ignored: PropTypes.bool.isRequired,
    IdentityProtected: PropTypes.bool.isRequired,
    Recipient: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
    RecipientEmail: PropTypes.string,
    Role: PropTypes.oneOf(Object.values(UserRole)).isRequired,
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
    CreatedOn: PropTypes.string.isRequired,
  }).isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  onUpdateInviteRole: PropTypes.func.isRequired,
}

export default memo(Invite)
