import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CameraAltOutlined } from '@material-ui/icons'

import ToolbarButton from '@tabeeb/modules/playerToolbar/components/ToolbarButton'

import * as snapshotActions from '../../../presentationToolbar/actions/snapshot'

const SnapshotButton = () => {
  const dispatch = useDispatch()

  const snapshotInProgress = useSelector((state) => state.presentationToolbar.snapshotInProgress)

  const onClick = useCallback(() => {
    if (snapshotInProgress) {
      return
    }

    dispatch(snapshotActions.onSnapshotTaking())
  }, [dispatch, snapshotInProgress])

  return <ToolbarButton Icon={CameraAltOutlined} title='Take a snapshot' onClick={onClick} />
}

export default memo(SnapshotButton)
