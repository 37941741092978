export default (theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      maxHeight: 400,
      width: 518,
      position: 'absolute',
      zIndex: 1,
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
    content: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      overflowY: 'auto',
    },
    actions: {
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: '0px !important',
      display: 'flex',
      flexDirection: 'row-reverse',
      '& button': {
        marginLeft: theme.spacing(),
        '&:last-child': {
          marginLeft: 0,
        },
      },
    },
  }
}
