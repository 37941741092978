import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs } from '@mui/material'
import PowerBIReportUsers from '../PowerBIReportUsers'
import PowerBIReportTenants from '../PowerBIReportTenants'
import PowerBIReportDependentModules from '../PowerBIReportDepedentModules'

const PowerBIReportTabs = ({ report }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const onTabChange = useCallback((_event, value) => {
    setSelectedTab(value)
  }, [])

  return (
    <>
      <Tabs value={selectedTab} onChange={onTabChange}>
        <Tab label='Users' />
        <Tab label='Tenants' />
        <Tab label='Dependent reports' />
      </Tabs>
      {selectedTab === 0 && <PowerBIReportUsers report={report} />}
      {selectedTab === 1 && <PowerBIReportTenants report={report} />}
      {selectedTab === 2 && <PowerBIReportDependentModules report={report} />}
    </>
  )
}

PowerBIReportTabs.propTypes = {
  report: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    SecurityModel: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default PowerBIReportTabs
