import { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import InfiniteScroll from 'react-infinite-scroller'

import { RefreshOutlined } from '@mui/icons-material'
import { Popover, List, Divider, Box, Switch, Button, Typography } from '@mui/material'

import { BottomLinearProgress } from '@tabeeb/uikit'
import { useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'

import { clearSncSelection, disableSncFilters, enableSncFilters } from '../../actions'
import { useTenantSncQuestions } from '../../hooks'
import { getIsSncFiltersEnabled } from '../../selectors'

import SearchInput from '../SearchInput'
import QuestionsListItem from '../QuestionsListItem'
import NoSncQuestionsFoundPlaceholder from '../NoSncQuestionsFoundPlaceholder'

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const QuestionsMenu = ({ anchorEl, open, onClose, onTouch }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebouncedValue(searchText, 500)

  const enabled = useSelector(getIsSncFiltersEnabled)

  const { loading, loaded, questions, hasMore, onLoadMore } = useTenantSncQuestions({
    enabled: open,
    searchText: debouncedSearchText,
  })

  const onClear = () => {
    dispatch(clearSncSelection())
    onTouch()
  }

  const onToggle = () => {
    if (enabled) {
      dispatch(disableSncFilters())
    } else {
      dispatch(enableSncFilters())
    }

    onTouch()
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        sx: {
          width: 300,
        },
      }}
    >
      <Box m={1} mb={0}>
        <SearchInput searchQuery={searchText} setSearchQuery={setSearchText} />
      </Box>

      <Box display='flex' alignItems='center' mt={0.25} mb={0.25}>
        <Button
          size='small'
          startIcon={<RefreshOutlined color='error' />}
          sx={{ fontSize: (theme) => theme.typography.body2 }}
          onClick={onClear}
        >
          Reset selection
        </Button>

        <Divider flexItem variant='middle' orientation='vertical' />

        <Box display='flex' alignItems='center' onClick={onToggle} flexGrow={1} sx={{ cursor: 'pointer' }} pl={1}>
          <Switch size='small' checked={enabled} onClick={onToggle} />
          <Typography ml={1} variant='body2' noWrap>
            {enabled ? 'Enabled' : 'Disabled'}
          </Typography>
        </Box>
      </Box>

      <Divider />

      <List sx={{ position: 'relative', height: 512, maxHeight: 512, overflowY: 'auto', flexGrow: 1 }}>
        <InfiniteScroll useWindow={false} initialLoad loadMore={onLoadMore} hasMore={hasMore}>
          {questions.map((question) => {
            return <QuestionsListItem key={question.Id} disabled={loading} question={question} onTouch={onTouch} />
          })}
        </InfiniteScroll>

        {loaded && questions.length === 0 && <NoSncQuestionsFoundPlaceholder />}
      </List>

      {loading && <BottomLinearProgress />}
    </Popover>
  )
}

QuestionsMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onTouch: PropTypes.func.isRequired,
}

export default memo(QuestionsMenu)
