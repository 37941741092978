import { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { Checkbox, FormControl, InputLabel, MenuItem, Select, Typography, withStyles } from '@material-ui/core'

import styles from './styles'

const Filter = ({ value: savedValue, classes, multiple, icon: Icon, label, items, renderValue, onChange }) => {
  const [value, setValue] = useState(savedValue)
  const [isEditing, setIsEditing] = useState(false)
  const prevValueRef = useRef(value)

  useEffect(() => {
    if (!isEditing && !_.isEqual(prevValueRef.current, value)) {
      prevValueRef.current = value
      onChange(value)
    }
  }, [value, onChange, isEditing])

  useEffect(() => {
    setValue(savedValue)
  }, [savedValue])

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleSelectOpen = () => {
    setIsEditing(true)
  }

  const handleSelectClose = () => {
    setIsEditing(false)
  }

  return (
    <div className={classes.container}>
      <FormControl className={classes.control} variant='outlined'>
        <InputLabel className={classes.label}>
          <Icon fontSize='small' />
          {label}
        </InputLabel>
        <Select
          onOpen={handleSelectOpen}
          onClose={handleSelectClose}
          displayEmpty
          label={Filter.iconPlaceholder + label}
          value={value}
          onChange={handleChange}
          inputProps={{
            className: classes.select,
          }}
          MenuProps={{ ...Filter.menuProps, PaperProps: { className: classes.menu } }}
          multiple={multiple}
          renderValue={renderValue}
        >
          {items.map((item) => (
            <MenuItem key={item.value} className={classes.item} value={item.value}>
              {multiple && (
                <Checkbox className={classes.checkbox} disableRipple checked={value.indexOf(item.value) > -1} />
              )}
              {item.icon}
              <div className={classes.itemContent}>
                <Typography className={classes.title}>{item.title}</Typography>
                <Typography className={classes.subtitle}>
                  {item.subicon} {item.subtitle}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

Filter.iconPlaceholder = '**'

Filter.menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
}

Filter.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    control: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    select: PropTypes.string.isRequired,
    menu: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
    itemContent: PropTypes.string.isRequired,
    checkbox: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
    }).isRequired
  ).isRequired,
  renderValue: PropTypes.func,
}

export default memo(withStyles(styles)(Filter))
