import { combineReducers } from 'redux'

import foldersList from './foldersList'
import galleryList from './galleryList'
import galleryState from './galleryState'
import deleteItemId from './deleteItemId'
import controlPannel from './controlPannel'
import pdfSearch from './pdfSearch'
import isGalleryItemLoading from './isGalleryItemLoading'
import isExportImagesDialogOpen from './isExportImagesDialogOpen'
import assetsList from './assetsList'
import exportImagesWithAnnotations from './exportImagesWithAnnotations'
import emailRecipientsDialogState from './emailRecipientsDialogState'
import exportEmailRecipients from './exportEmailRecipients'
import pdfOptionsDialogState from './pdfOptionsDialogState'
import isExportPdfOptionsDialogOpen from './isExportPdfOptionsDialogOpen'
import isCustomVideoOptionsDialogOpen from './isCustomVideoOptionsDialogOpen'
import deleteSessionFolderId from './deleteSessionFolderId'
import customVideoOptionsDialogState from './customVideoOptionsDialogState'
import selection from './selection'
import exportGalleryOptionsDialogState from './exportGalleryOptionsDialogState'
import exportGalleryOption from './exportGalleryOption'
import isGalleryExpanded from './isGalleryExpanded'
import fileUploadsQueue from './fileUploadsQueue'
import editingFolder from './editFolder/editingFolder'
import contentUsersWithFolderPermissions from './editFolder/usersPermissions'
import isPermissionsRequestLoading from './editFolder/isPermissionsRequestLoading'
import isGalleryListLoading from './isGalleryListLoading'

export default combineReducers({
  foldersList,
  galleryList,
  galleryState,
  deleteItemId,
  controlPannel,
  pdfSearch,
  isGalleryItemLoading,
  isExportImagesDialogOpen,
  assetsList,
  exportImagesWithAnnotations,
  emailRecipientsDialogState,
  exportEmailRecipients,
  pdfOptionsDialogState,
  isExportPdfOptionsDialogOpen,
  isCustomVideoOptionsDialogOpen,
  deleteSessionFolderId,
  customVideoOptionsDialogState,
  selection,
  exportGalleryOptionsDialogState,
  exportGalleryOption,
  isGalleryExpanded,
  fileUploadsQueue,
  editingFolder,
  contentUsersWithFolderPermissions,
  isPermissionsRequestLoading,
  isGalleryListLoading,
})
