import { memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Popover, MenuItem, ListItemText } from '@material-ui/core'
import * as rawActions from '../../actions'
import * as customVideoActions from '../../../customVideo/actions'

import { customVideoMenuItems } from '../../services/galleryMenus'
import { AnswerStatuses, CustomVideoOptions } from '../../../../Enums'

const CustomVideoOptionsMenu = ({
  setAnchorEl,
  anchorEl,
  actions: { openCustomVideoOptionsDialog, setCustomVideoOptionsDialogState },
  customVideoActions: { getPagesByAnswerStatus },
}) => {
  const _renderMenuItem = (menuItemType, key) => {
    return (
      <MenuItem onClick={() => _handleMenuClick(menuItemType)} key={key}>
        <ListItemText primary={menuItemType} />
      </MenuItem>
    )
  }

  const _handleMenuClick = (menuItemType) => {
    setAnchorEl(null)
    switch (menuItemType) {
      case customVideoMenuItems.AllPages:
        setCustomVideoOptionsDialogState(CustomVideoOptions.PagesInGallery)
        openCustomVideoOptionsDialog()
        break
      case customVideoMenuItems.AllApproved:
        getPagesByAnswerStatus(AnswerStatuses.Approve)
        break
      case customVideoMenuItems.AllSubmitted:
        getPagesByAnswerStatus(AnswerStatuses.Pending)
        break
    }
  }

  return (
    <Popover
      className='gallery-custom-video-menu-popover'
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div className='gallery-custom-video-menu'>
        {Object.values(customVideoMenuItems).map((menuItem, index) => _renderMenuItem(menuItem, index))}
      </div>
    </Popover>
  )
}

CustomVideoOptionsMenu.propTypes = {
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    openCustomVideoOptionsDialog: PropTypes.func.isRequired,
    setCustomVideoOptionsDialogState: PropTypes.func.isRequired,
  }).isRequired,
  customVideoActions: PropTypes.shape({
    getPagesByAnswerStatus: PropTypes.func.isRequired,
  }).isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
    customVideoActions: bindActionCreators(customVideoActions, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(memo(CustomVideoOptionsMenu))
