export const LinkedContentStatus = {
  Pending: 0,
  Active: 1,
  MergingInProgress: 2,
  Rejected: 3,
}

export const LinkedContentStatusText = {
  [LinkedContentStatus.Pending]: 'Pending',
  [LinkedContentStatus.Active]: 'Active',
  [LinkedContentStatus.MergingInProgress]: 'Merging in progress',
  [LinkedContentStatus.Rejected]: 'Rejected',
}

export const LinkedContentControl = {
  Merge: 'MERGE',
  Unlink: 'UNLINK',
  Complete: 'COMPLETE',
}

export const ContentSharingDialogType = {
  ShareForCopying: 0,
  ShareForReview: 1,
  RequestToLinkContent: 2,
  SyncContentData: 3,
}

export const ContentSharingDialogStageId = {
  Destination: 1,
}

export const ContentSharingDialogStepId = {
  SelectContentForms: 1,
  SelectGalleryPages: 2,
  SelectTenantDestination: 3,
  SelectContentDestinationType: 4,
  SelectContentDestination: 5,
  SelectUser: 6,
  NotifyReviewers: 7,
}

export const SharedContentDataType = {
  AllData: 0,
  SelectedData: 1,
}

export const SearchResultRowBtn = {
  Select: 'Select',
  Remove: 'Remove',
}
