import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    label: {
      margin: 0,

      '& .MuiFormControlLabel-label': {
        flexGrow: 1,
      },
    },
    helper: {
      marginTop: -theme.spacing(0.5),
    },
  }
})
