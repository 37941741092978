import { handleActions } from 'redux-actions'

import { setSelectedVideoBridge } from '../actions/index'

const defaultValue = {
  name: '',
  url: '',
}

export default handleActions(
  {
    [setSelectedVideoBridge](state, action) {
      return action.payload
    },
  },
  defaultValue
)
