import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { FormButtons } from '@tabeeb/modules/forms/services/enums'
import { getFormButtonState } from '@tabeeb/modules/forms/selectors'
import { requestRework } from '@tabeeb/modules/contentReviews/actions'

const SendReviewFeedbackButton = () => {
  const { isDisabled } = useSelector((state) => getFormButtonState(state, { button: FormButtons.SendReviewFeedback }))

  const dispatch = useDispatch()

  return (
    <Button
      onClick={() => dispatch(requestRework())}
      variant='contained'
      color='primary'
      className='form-button'
      disabled={isDisabled}
    >
      send feedback
    </Button>
  )
}

export default memo(SendReviewFeedbackButton)
