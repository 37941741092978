import { memo } from 'react'
import PropTypes from 'prop-types'

import { Chip, Tooltip } from '@mui/material'
import { Warning, ErrorOutline } from '@mui/icons-material'
import { orange, red } from '@mui/material/colors'

import UserAvatar from '@tabeeb/shared/userAvatar'
import { validateEmail, validatePhone } from '@tabeeb/modules/../users/services/users'

const UsersChip = ({ user, index, getTagProps }) => {
  const isRegistered = Boolean(user.Id)
  const isValidEmailOrPhoneNumber = validateEmail(user.Email) || validatePhone(user.Email)

  return (
    <Chip
      color='primary'
      variant='outlined'
      size='small'
      avatar={
        isRegistered ? (
          <UserAvatar user={user} />
        ) : isValidEmailOrPhoneNumber ? (
          <Tooltip title='User not found'>
            <ErrorOutline style={{ color: orange[500], background: 'none' }} />
          </Tooltip>
        ) : (
          <Tooltip title='Not a valid email address or phone number'>
            <Warning style={{ color: red[500], background: 'none' }} />
          </Tooltip>
        )
      }
      label={user.Email}
      {...getTagProps({ index })}
    />
  )
}

UsersChip.propTypes = {
  index: PropTypes.number.isRequired,
  getTagProps: PropTypes.func.isRequired,
  user: PropTypes.shape({
    Id: PropTypes.number,
    Email: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(UsersChip)
