import { AnswerStatuses } from '@tabeeb/enums'

export function mapAnswersStatusesForReview(answerStatus) {
  const dict = {
    [AnswerStatuses.Approve]: AnswerStatuses.ApprovedByReviewer,
    [AnswerStatuses.Reject]: AnswerStatuses.RejectedByReviewer,
  }

  return dict[answerStatus]
}
