const formsPageRequests = {
  getAllFormsRequest: () => ({
    url: `api/forms`,
    method: 'get',
  }),
  getFormsByFolderIdRequest: (folderId) => ({
    url: `api/formFolders/formsInFolder/${folderId}`,
    method: 'get',
  }),
  deleteFormRequest: (formId) => ({
    url: `api/forms/${formId}`,
    method: 'delete',
  }),
  copyFormRequest: ({ formId }) => ({
    url: `api/forms/copy/${formId}`,
    method: 'post',
  }),
  editFormRequest: (form) => ({
    url: `api/forms`,
    method: 'put',
    data: form,
  }),
  publishFormRequest: ({ id, tenantsIds }) => ({
    url: `api/forms/publish`,
    method: 'post',
    data: { formId: id, tenantsIds },
  }),
  unpublishFormRequest: ({ id, tenantsIds }) => ({
    url: `api/forms/unpublish`,
    method: 'post',
    data: { formId: id, tenantsIds },
  }),
  publishFormFolderRequest: ({ id, tenantsIds }) => ({
    url: `api/formFolders/publish`,
    method: 'post',
    data: { folderId: id, tenantsIds },
  }),
  unpublishFormFolderRequest: ({ id, tenantsIds }) => ({
    url: `api/formFolders/unpublish`,
    method: 'post',
    data: { folderId: id, tenantsIds },
  }),
  getAllFormFoldersRequest: () => ({
    url: `api/formFolders`,
    method: 'get',
  }),
  createFormFolderRequest: (data) => ({
    url: 'api/formFolders',
    method: 'post',
    data,
  }),
  deleteFormFolderRequest: (folderId) => ({
    url: `api/formFolders/${folderId}`,
    method: 'delete',
  }),
  renameFormFolderRequest: (data) => ({
    url: `api/formFolders/rename`,
    method: 'put',
    data,
  }),
  switchFormFolderRequest: (data) => {
    const filteredData = {
      fromFolderId: data.fromFolderId,
      formId: data.formId,
      toFolderId: data.toFolderId,
    }

    return {
      url: `api/formFolders/move`,
      method: 'post',
      data: filteredData,
    }
  },
}

export default formsPageRequests
