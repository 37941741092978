import { all } from 'redux-saga/effects'

import createSelectionList from './createSelectionList'
import getSelectionList from './getSelectionList'
import updateSelectionList from './updateSelectionList'

function* artificialIntelligence() {
  yield all([createSelectionList(), getSelectionList(), updateSelectionList()])
}

export default artificialIntelligence
