import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.createCustomMetricsChart](state, { payload }) {
      return [payload, ...state]
    },
    [rawActions.deleteCustomMetricsChart](state, { payload }) {
      return state.filter((m) => m.id !== payload)
    },
  },
  defaultState
)
