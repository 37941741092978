import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import PropTypes from 'prop-types'

import { Paper, Skeleton } from '@mui/material'

import { PageHeader, PageLayout } from '@tabeeb/uikit'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import Routes from '@tabeeb/routes'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { useTrainingMaterial } from '@tabeeb/modules/articles/hooks'
import ArticleContent, { ArticleContentSkeleton } from '@tabeeb/modules/articles/components/ArticleContent'

const TrainingMaterialViewPage = ({ id }) => {
  const dispatch = useDispatch()

  const onError = useCallback(() => {
    dispatch(
      onAddErrorNotification({ message: trainingMaterialTitleFormatter.format('Failed to load training material') })
    )

    dispatch(replace(Routes.trainingMaterials))
  }, [dispatch])

  const { loading, trainingMaterial } = useTrainingMaterial({ id, onError })

  return (
    <PageLayout component={Paper} spacing='page' variant='outlined' maxWidth='lg'>
      <PageHeader title={trainingMaterial?.Name || <Skeleton width={350} />} />

      {loading ? <ArticleContentSkeleton /> : <ArticleContent content={trainingMaterial?.Content} />}
    </PageLayout>
  )
}

TrainingMaterialViewPage.propTypes = {
  id: PropTypes.number.isRequired,
}

export default memo(TrainingMaterialViewPage)
