import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'

import { useApiRequest, useDebouncedValue } from '@tabeeb/shared/utils/hooks'
import { searchQualification } from '../../actions'

const QualificationsAutocomplete = ({ multiple, AutocompleteProps, TextFieldInputProps }) => {
  const [searchTextQualification, setSearchTextQualification] = useState('')
  const debouncedSearchQualifications = useDebouncedValue(searchTextQualification, 500)
  const handleChangeSearchQualifications = useCallback((event, value) => {
    setSearchTextQualification(value)
  }, [])
  const searchQualificationParams = useMemo(
    () => ({ search: debouncedSearchQualifications }),
    [debouncedSearchQualifications]
  )

  const { response, loading } = useApiRequest({
    defaultResponse: [],
    request: searchQualification.request,
    payload: searchQualificationParams,
  })

  const { setFieldValue, touched, errors, setFieldTouched } = useFormikContext()

  return (
    <Autocomplete
      multiple={multiple}
      loading={loading}
      onInputChange={handleChangeSearchQualifications}
      onChange={(event, value) => {
        if (multiple) {
          setFieldValue(
            'Qualifications',
            Boolean(value) &&
              Array.isArray(value) &&
              value.map((_) => ({
                Id: _.Id,
                Name: _.Name,
              }))
          )
        } else {
          setFieldValue('Qualifications', [{ Id: value?.Id, Name: value?.Name }])
        }
      }}
      name='Qualifications'
      renderInput={(params) => (
        <TextField
          {...params}
          label='Qualifications'
          placeholder='Search qualification name...'
          error={Boolean(touched.Qualifications) && Boolean(errors.Qualifications)}
          helperText={Boolean(touched.Qualifications) && errors.Qualifications}
          {...TextFieldInputProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      options={response}
      getOptionLabel={(option) => option.Name}
      onBlur={() => setFieldTouched('Qualifications')}
      filterOptions={(options) => options}
      {...AutocompleteProps}
    />
  )
}

QualificationsAutocomplete.defaultProps = {
  multiple: false,
}

QualificationsAutocomplete.propTypes = {
  multiple: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  AutocompleteProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  TextFieldInputProps: PropTypes.object,
}

export default QualificationsAutocomplete
