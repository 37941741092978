export const SessionAction = {
  BulkCopy: 'BulkCopy',
  Copy: 'Copy',
  Delete: 'Delete',
  Move: 'Move',
  Leave: 'Leave',
  Publish: 'Publish',
  Restore: 'Restore',
  Share: 'Share',
}
