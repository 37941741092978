import { put, takeLatest, select, all } from 'redux-saga/effects'

import { PointCloudModelStatus } from '@tabeeb/enums'

import { rawContentActions, contentStateSelectors } from '@tabeeb/shared/content'
import { isImagePage } from '@tabeeb/services/pageService'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'

import * as galleryActions from '../../gallery/actions'
import { getPagesByFolderWithNesting, getFoldersByIds } from '../../gallery/selectors'

function* addFolderTo3dModel({ payload: { folderId } }) {
  let pages = yield select((state) => getPagesByFolderWithNesting(state, { id: folderId }))

  pages = pages.filter((item) => isImagePage(item.contentType))

  if (pages.length < 1) {
    yield put(
      notificationActions.onAddWarningNotification({
        message: 'Folder is empty!',
      })
    )

    return
  }

  yield put(rawActions.addAllPages3dToModel(pages))
}

function* create3dModel(action) {
  const contentId = yield select(contentStateSelectors.getContentId)
  const imagesList = yield select((state) => state.threedeeModel.imagesList)

  if (imagesList.length < 1) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'Images list is empty!',
      })
    )
    return
  }

  const uniqueFolderIds = new Set()
  const pageIds = imagesList.map((page) => page.id)

  const folders = yield select((state) => getFoldersByIds(state, { ids: uniqueFolderIds }))

  const model = {
    contentId,
    pageIds,
    folderNames: folders.map((folder) => folder.Name),
  }

  yield put(rawActions.createThreedeeModelRequest(model))
}

function* createThreedeeModelFailed(action) {
  yield put(
    notificationActions.onAddErrorNotification({
      message: action.response.data ? action.response.data : 'Failed to create 3D model',
    })
  )
}

function* createThreedeeModelSuccess() {
  yield put(rawActions.close3dModelDialog())

  yield put(
    rawContentActions.onUpdateContentState({
      pointCloudModelStatus: PointCloudModelStatus.ModelCreating,
    })
  )

  yield put(
    notificationActions.onAddInfoNotification({
      message: 'Model sent for creation',
    })
  )
}

function* resetState() {
  yield put(rawActions.resetState())
}

function* spatialModelSaga() {
  yield all([
    takeLatest(rawActions.create3dModel, create3dModel),
    takeLatest(rawActions.createThreedeeModelFailed, createThreedeeModelFailed),
    takeLatest(rawActions.createThreedeeModelSuccess, createThreedeeModelSuccess),
    takeLatest(galleryActions.addFolderTo3dModel, addFolderTo3dModel),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default spatialModelSaga
