import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as muteAudioActions from '../actions/muteAudio'
import { accountSelectors } from '../../account'

function* onRemoteAudioMuted({ payload }) {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const userId = payload[0].value
  if (currentUserId == userId) {
    yield put(muteAudioActions.muteAudio())
  }
}

function* onAudioMuted() {
  yield put(muteAudioActions.onMuteAudioProgressStateUpdate(false))
}

function* muteAudio() {
  yield put(muteAudioActions.onMuteAudioProgressStateUpdate(true))
}

function* muteAudioSaga() {
  yield all([
    takeLatest(muteAudioActions.onRemoteAudioMuted, onRemoteAudioMuted),
    takeLatest(muteAudioActions.onAudioMuted, onAudioMuted),
    takeLatest(muteAudioActions.muteAudio, muteAudio),
  ])
}

export default muteAudioSaga
