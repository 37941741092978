import { put, takeLatest, select, all } from 'redux-saga/effects'

import { convertServicePageToPluginPage } from '../../../services/dataConverter'

import { CustomVideoOptions, ContentType } from '../../../Enums'

import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import * as galleryActions from '../../gallery/actions'
import { getPagesByFolderWithNesting, getSelectedFolderId } from '../../gallery/selectors'
import { accountSelectors } from '../../account'
import { contentStateSelectors, rawContentActions } from '../../shared/content'

function* getVideo(action) {
  console.log(action)
}

function* exportCustomVideo(action) {
  const timelineList = yield select((state) => state.customVideo.timelineList)
  const contentId = yield select((state) => state.contentState.contentId)
  const { recipients } = action.payload

  const exportModels = timelineList.map((item, index) => {
    const pageItem = {}
    if (!item.id) {
      pageItem.IsTitle = true
    } else {
      pageItem.pageId = item.id
    }
    pageItem.pageOrder = index

    return pageItem
  })
  const data = {
    ContentId: contentId,
    Recipients: recipients.join(','),
    PageExportModels: exportModels,
  }

  yield put(rawActions.resetState())
  yield put(rawActions.getConcatenatedVideoRequest(data))
  yield put(
    notificationActions.onAddInfoNotification({
      message: 'An email will be sent to you shortly with the download links',
    })
  )
}

function* getPagesByAnswerStatusSuccess(action) {
  const response = action.response.data
  const contentId = yield select(contentStateSelectors.getContentId)
  const ownerId = yield select(contentStateSelectors.getOwnerId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const pageToPluginPageParams = {
    contentId,
    contentOwnerId: ownerId,
    currentUserId,
    isLoaded: true,
  }

  const pages = response.map((page) => convertServicePageToPluginPage({ ...pageToPluginPageParams, page }))
  yield put(rawActions.addMultipleTimelineItems(pages))
}

function* getPagesForCustomVideo() {
  const contentId = yield select(contentStateSelectors.getContentId)
  const { selectedFolderId } = yield select((state) => state.gallery.galleryState)

  const customVideoOptionsDialogState = yield select((state) => state.gallery.customVideoOptionsDialogState)
  switch (customVideoOptionsDialogState) {
    case CustomVideoOptions.PagesInGallery:
      let galleryList = yield select((state) => state.gallery.galleryList)
      galleryList = galleryList.filter(
        (item) =>
          item.folderId === selectedFolderId &&
          item.contentType !== ContentType.WebPage &&
          item.contentType !== ContentType.SpatialModel &&
          item.contentType !== ContentType.DocDocumentPage &&
          item.contentType !== ContentType.PptDocumentPage &&
          item.contentType !== ContentType.ExcelDocumentPage
      )

      yield put(rawActions.addMultipleTimelineItems(galleryList))
      break
    case CustomVideoOptions.FormQuestions:
      yield put(rawActions.getPagesPerQuestionsOrderRequest(contentId, selectedFolderId))
      break
  }
}

function* getPagesPerQuestionsOrderSuccess(action) {
  const response = action.response.data
  const contentId = yield select(contentStateSelectors.getContentId)
  const ownerId = yield select(contentStateSelectors.getOwnerId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const pageToPluginPageParams = {
    contentId,
    contentOwnerId: ownerId,
    currentUserId,
    isLoaded: true,
  }

  const pages = response.map((page) => convertServicePageToPluginPage({ ...pageToPluginPageParams, page }))
  yield put(rawActions.addMultipleTimelineItems(pages))
}

function* addFolderToCustomVideo({ payload: { folderId } }) {
  const pages = yield select((state) => getPagesByFolderWithNesting(state, { id: folderId }))

  if (pages.length < 1) {
    yield put(
      notificationActions.onAddWarningNotification({
        message: 'Folder is empty!',
      })
    )

    return
  }

  yield put(rawActions.addMultipleTimelineItems(pages))
}

function* getPagesByAnswerStatus(action) {
  const answerStatus = action.payload
  const contentId = yield select(contentStateSelectors.getContentId)
  const selectedFolderId = yield select((state) => getSelectedFolderId(state))

  const data = {
    ContentId: contentId,
    FolderId: selectedFolderId,
    AnswerStatus: answerStatus,
  }

  yield put(rawActions.getPagesByAnswerStatusRequest(data))
}

function* resetState() {
  yield put(rawActions.resetState())
}

function* customVideoSaga() {
  yield all([
    takeLatest(rawActions.getConcatenatedVideoSuccess, getVideo),
    takeLatest(rawActions.exportCustomVideo, exportCustomVideo),
    takeLatest(rawActions.getPagesByAnswerStatus, getPagesByAnswerStatus),
    takeLatest(rawActions.getPagesByAnswerStatusSuccess, getPagesByAnswerStatusSuccess),
    takeLatest(rawActions.getPagesForCustomVideo, getPagesForCustomVideo),
    takeLatest(rawActions.getPagesPerQuestionsOrderSuccess, getPagesPerQuestionsOrderSuccess),
    takeLatest(galleryActions.addFolderToCustomVideo, addFolderToCustomVideo),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default customVideoSaga
