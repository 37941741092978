import { takeLatest, put, all, select } from 'redux-saga/effects'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as galleryActions from '../actions'
import * as threeDModelActions from '../../3dmodel/actions'

function* galleryAdd3dModel(action) {
  const is3dModelOpen = yield select((state) => state.threedeeModel.isTimelineOpen)
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  if (!is3dModelOpen) yield put(threeDModelActions.open3dModelDialog())

  if (currentPanel !== TabPanel.ThreeDModel)
    yield put(navbarActions.switchPanel(TabPanel.ThreeDModel, TabPanelLocation.Bottom))
}

function* add3dModel() {
  yield all([takeLatest(galleryActions.galleryAdd3dModel, galleryAdd3dModel)])
}

export default add3dModel
