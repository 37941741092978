import { Typography, makeStyles } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const SelectedStatuses = (values) => {
  const classes = useStyles()

  return <Typography className={classes.statuses}>{values.join(', ') || 'All'}</Typography>
}

export default SelectedStatuses
