import { handleActions } from 'redux-actions'
import { setSortings, resetState } from '../../actions'
import { SortingColumn } from '../../enums'

const defaultValue = Object.values(SortingColumn)

export default handleActions(
  {
    [setSortings](_, { payload: sortings }) {
      return sortings
    },
    [resetState]() {
      return defaultValue
    },
  },
  defaultValue
)
