import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = {
  columns: [],
  data: [],
  rowAmount: 0,
}

const metadata = handleActions(
  {
    [actions.getContentMetadataSuccess](state, { response }) {
      const parsedResponse = JSON.parse(response.data.Metadata)
      const data = parsedResponse.data || []

      return { ...state, columns: parsedResponse.columns, data: [...state.data, ...data] }
    },
    [actions.getContentMetadataRowAmountSuccess](state, { response }) {
      return { ...state, rowAmount: response.data.RowAmount }
    },
    [actions.addRow.success](state, { payload, response }) {
      return {
        ...state,
        rowAmount: state.rowAmount + 1,
        data: [...state.data, { ...payload.item, rowId: response.data[0] }],
      }
    },
    [actions.deleteRow.success](state, { payload }) {
      return {
        ...state,
        rowAmount: state.rowAmount - 1,
        data: state.data.filter((item) => item.rowId !== payload.itemId),
      }
    },
    [actions.clearContentMetadataValues](state, action) {
      return { ...state, data: [] }
    },
    [actions.resetContentMetadata](state, action) {
      return defaultState
    },
    [actions.updateRow.success](state, action) {
      const { item } = action.payload

      return {
        ...state,
        data: state.data.map((dataItem) => {
          return dataItem.rowId === item.rowId ? item : dataItem
        }),
      }
    },
  },
  defaultState
)

export default metadata
