import { memo } from 'react'
import PropTypes from 'prop-types'

import { Fade, LinearProgress, Typography } from '@mui/material'

const LoadingProgressBar = ({ value, text }) => {
  if (value >= 100) {
    return null
  }

  return (
    <>
      <Fade in>
        <Typography
          sx={{
            zIndex: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            translate: '-50% -50%',
            transitionDelay: '5s !important',
            maxWidth: (theme) => theme.breakpoints.values.sm,
          }}
          variant='h6'
          color='HighlightText'
        >
          {text}
        </Typography>
      </Fade>
      <LinearProgress sx={{ left: 0, right: 0, bottom: 0, position: 'absolute' }} variant='determinate' value={value} />
    </>
  )
}

LoadingProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
}

export default memo(LoadingProgressBar)
