import { useSelector, useDispatch } from 'react-redux'
import { memo, useCallback } from 'react'

import { getPresenterEmail } from '@tabeeb/modules/../users/selectors'
import { getIsProcessing } from '../selectors'
import { closeRecipientsDialog, exportCustomVideo, setProcessing } from '../actions'

import { RecipientsDialog } from '../../shared/dialogs'

const RecipientsContainer = () => {
  const dispatch = useDispatch()

  const presenterEmail = useSelector(getPresenterEmail)
  const isProcessing = useSelector(getIsProcessing)

  const handleSubmitDialog = useCallback(
    (recipients) => {
      dispatch(setProcessing(true))
      dispatch(exportCustomVideo({ recipients }))
    },
    [dispatch]
  )

  const handleCloseDialog = useCallback(() => {
    dispatch(closeRecipientsDialog())
  }, [dispatch])

  const props = {
    initialRecipientsList: [presenterEmail],
    onClose: handleCloseDialog,
    onSubmit: handleSubmitDialog,
    title: 'Who should receive this custom video?',
    warningText: 'The video must be sent to at least one email address',
    isProcessing,
  }

  return <RecipientsDialog {...props} />
}

export default memo(RecipientsContainer)
