import { useState, useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import { NavigateNext, NavigateBefore } from '@material-ui/icons'
import PropTypes from 'prop-types'

import './styles.less'

const Navigation = ({ itemsAmount, onClick }) => {
  const showNavigation = itemsAmount > 1
  const maxIndex = itemsAmount - 1

  const [currentPage, setCurrentPage] = useState(1)

  const prevClick = () => {
    currentPage === 0 ? setCurrentPage(maxIndex) : setCurrentPage(currentPage - 1)
  }

  const nextClick = () => {
    currentPage === maxIndex ? setCurrentPage(0) : setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    if (!showNavigation) setCurrentPage(0)
    else setCurrentPage(maxIndex)
  }, [itemsAmount])

  useEffect(() => {
    onClick(currentPage)
  }, [currentPage])

  return showNavigation ? (
    <div className='navigation'>
      <IconButton className='navigation-button' onClick={prevClick}>
        <NavigateBefore />
      </IconButton>
      <IconButton className='navigation-button' onClick={nextClick}>
        <NavigateNext />
      </IconButton>
    </div>
  ) : null
}

Navigation.propTypes = {
  itemsAmount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Navigation
