import { handleActions, combineActions } from 'redux-actions'

import * as galleryActions from '@tabeeb/modules/gallery/actions'
import * as actions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(galleryActions.getPagesByContentIdRequest, actions.getPagesByIterationId.request)]() {
      return true
    },
    [combineActions(
      galleryActions.getPagesByContentIdSuccess,
      galleryActions.getPagesByContentIdFailed,
      actions.getPagesByIterationId.success,
      actions.getPagesByIterationId.failed
    )]() {
      return false
    },
  },
  defaultState
)
