import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Chip, withStyles } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import { makeGetAnswerStatusDropdownOptions, makeGetSelectedAnswerStatus } from '@tabeeb/modules/forms/selectors'
import { setFormAnswerStatus } from '@tabeeb/modules/forms/actions'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import AnswerStatusDropdown from '../AnswerStatusDropdown'

import styles from './styles'

const AnswerStatusBadge = ({ classes, answer }) => {
  const dispatch = useDispatch()

  const [anchorRef, open, handleOpen, handleClose] = usePopoverState()
  const contentId = useSelector(getContentId)

  const statusSelector = useMemo(makeGetSelectedAnswerStatus, [])
  const status = useSelector((state) => statusSelector(state, { answer }))

  const optionsSelector = useMemo(makeGetAnswerStatusDropdownOptions, [])
  const options = useSelector((state) => optionsSelector(state, { answer }))

  const handleStatusChange = (value) => () => {
    const statusModel = {
      FormAnswerStatus: value,
      ContentId: contentId,
      FormAnswerId: answer.Id,
    }

    dispatch(setFormAnswerStatus(statusModel))
    handleClose()
  }

  const StatusIcon = status.icon

  return (
    <>
      <Chip
        ref={anchorRef}
        style={{
          background: status.color,
        }}
        color='primary'
        className={classes.status}
        icon={<StatusIcon />}
        label={status.displayValue}
        deleteIcon={<ArrowDropDown titleAccess='Change status' />}
        onDelete={!status.isDisabled ? handleOpen : undefined}
      />
      {!status.isDisabled && (
        <AnswerStatusDropdown
          open={open}
          options={options}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          onSelect={handleStatusChange}
        />
      )}
    </>
  )
}

AnswerStatusBadge.propTypes = {
  classes: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  answer: PropTypes.shape({
    FormAnswerStatus: PropTypes.number.isRequired,
    ContentFormId: PropTypes.number.isRequired,
    FormControlId: PropTypes.number.isRequired,
    UserId: PropTypes.number.isRequired,
    Id: PropTypes.number.isRequired,
    FormTabAnswers: PropTypes.arrayOf(
      PropTypes.shape({
        FormTabId: PropTypes.number.isRequired,
      }).isRequired
    ),
  }).isRequired,
}

export default memo(withStyles(styles)(AnswerStatusBadge))
