import { handleActions } from 'redux-actions'
import * as actions from '../actions'
import { pageConverter } from '../services/pagesForSharingConverter'

const defaultValue = []

export default handleActions(
  {
    [actions.addPageForSharing](state, action) {
      let addedPage = action.payload
      const isAlreadyAdded = state.some((page) => page.id === addedPage.id)
      if (isAlreadyAdded) {
        return state
      }
      addedPage = pageConverter(addedPage)
      return [...state, addedPage]
    },
    [actions.deletePageForSharing](state, action) {
      const pageId = action.payload
      const index = state.findIndex((page) => page.id === pageId)
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [actions.addAllPagesForSharing](state, action) {
      let pages = action.payload
      pages = pages.filter((item) => !state.some((page) => page.id === item.id))

      const newState = [...state]
      pages.forEach((page) => newState.push(pageConverter(page)))

      return newState
    },
    [actions.closeShareSessionPagesDialog](state, action) {
      return defaultValue
    },
    [actions.addPagesByFormsForSharing](state, action) {
      let pages = action.payload
      const newState = state.filter((page) => page.contentFormId === null)
      pages = pages.filter((item) => !newState.some((page) => page.id === item.id))
      pages.forEach((page) => newState.push(page))

      return newState
    },
    [actions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
