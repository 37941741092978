import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      listStyle: 'none',
      flexGrow: 1,
      minWidth: 0,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flexGrow: 1,
      minWidth: 0,
    },
    avatar: {
      height: 32,
      width: 32,
    },
  }
})
