import { handleActions } from 'redux-actions'
import { MobileFormViewMode } from '../../../../Enums'

import * as rawActions from '../../actions'

const defaultState = MobileFormViewMode.Default

// Form view mode is only for mobile forms
export default handleActions(
  {
    [rawActions.updateFormViewMode](state, { payload }) {
      const formViewMode = state === MobileFormViewMode.Default ? MobileFormViewMode.List : MobileFormViewMode.Default
      return formViewMode
    },
    [rawActions.setFormViewMode](state, { payload }) {
      return payload
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
