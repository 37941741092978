import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

import { getStartDate, getEndDate, setStartTime, setEndTime } from '../services/dateTimeUtils'

const defaultState = {
  startDate: getStartDate(),
  endDate: getEndDate(),
}

export default handleActions(
  {
    [rawActions.setStartDate](state, action) {
      return { ...state, startDate: setStartTime(action.payload) }
    },
    [rawActions.setEndDate](state, action) {
      return { ...state, endDate: setEndTime(action.payload) }
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
