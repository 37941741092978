import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserForSharing } from '@tabeeb/modules/contentSharings/actions'
import { getUserSearchState } from '@tabeeb/modules/contentSharings/selectors'
import { SearchResultRowBtn } from '@tabeeb/modules/contentSharings/constants/enums'
import UserRow from './userRow'

const SearchUsersResult = () => {
  const dispatch = useDispatch()
  const { usersList } = useSelector(getUserSearchState)

  const _handleClick = useCallback(
    (user) => {
      dispatch(setUserForSharing(user))
    },
    [dispatch]
  )

  return usersList.map((user) => (
    <UserRow user={user} button={SearchResultRowBtn.Select} handleButtonClick={_handleClick} key={user.Id} />
  ))
}

export default memo(SearchUsersResult)
