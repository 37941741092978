import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      height: '100%',
      width: '100%',
      backdropFilter: 'blur(10px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      overflow: 'hidden',

      borderRadius: theme.shape.borderRadius,
    },
    background: {
      position: 'absolute',
      width: 'calc(100% + 40px)',
      height: 'calc(100% + 40px)',
      filter: 'blur(10px)',
      objectFit: 'cover',
    },
    image: {
      position: 'absolute',
      height: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      margin: 'auto',
      boxShadow: theme.shadows[5],
      left: '50%',
      transform: 'translate(-50%, 0px)',
    },
    avatar: {
      maxWidth: '100%',
      maxHeight: '100%',
      minWidth: 128,
      minHeight: 128,
      width: 150,
      height: 150,
      fontSize: 60,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: theme.shadows[4],
    },
  }
})
