import { Component } from 'react'
import PropTypes from 'prop-types'
import { withAITracking, AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import ai from '@tabeeb/services/telemetryService'

import { connect } from 'react-redux'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getSubdomain } from '@tabeeb/modules/appConfigState/selectors'

/**
 * How to collect custom telemetry
 *
 * Using react hooks (context already provided):
 * https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin#using-react-hooks
 *
 * See also in site above how to dispatch
 * Events: useTrackEvent
 * Metrics:  useTrackMetric
 *
 *-----------------------------
 * Class components:
 * import ai from '@tabeeb/services/telemetryService'
 * import {withAITracking} from '@microsoft/applicationinsights-react-js'
 *
 * class MyClassComponent extends Component {
 *  ...
 * }
 *
 * export default withAITracking(ai.reactPlugin, MyClassComponent)
 */

/*
 * Info about React plugin: https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
 * How to config: https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
 */

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
    }
  }

  componentDidMount() {
    const { history } = this.props
    const { initialized } = this.state
    const appInsightsConnectionString = process.env.APPLICATIONINSIGHTS_CONNECTION_STRING

    if (!initialized && Boolean(appInsightsConnectionString) && Boolean(history)) {
      ai.initialize(appInsightsConnectionString, history)
      this.setState({ initialized: true })
    }
  }

  componentDidUpdate(prevProps) {
    const { userId: prevUserId, subdomain: prevSubdomain } = prevProps
    const { userId: currentUserId, subdomain: currentSubdomain } = this.props

    if (prevUserId !== currentUserId || prevSubdomain !== currentSubdomain) {
      if (currentUserId) {
        ai.appInsights?.setAuthenticatedUserContext(currentUserId.toString(), currentSubdomain, true)
      } else {
        ai.appInsights?.clearAuthenticatedUserContext()
      }
    }
  }

  render() {
    const { children } = this.props
    return <AppInsightsContext.Provider value={ai.reactPlugin}>{children}</AppInsightsContext.Provider>
  }
}

TelemetryProvider.defaultProps = {
  userId: null,
  subdomain: null,
}

TelemetryProvider.propTypes = {
  history: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
  userId: PropTypes.number,
  subdomain: PropTypes.string,
}

const mapStateToProps = (state) => ({
  userId: getCurrentUserId(state),
  subdomain: getSubdomain(state),
})

export default connect(mapStateToProps)(
  withAITracking(ai.reactPlugin, TelemetryProvider, 'TelemetryProvider', 'tabeebApp')
)
