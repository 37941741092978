import { useEffect } from 'react'

import { Backdrop, CircularProgress } from '@mui/material'

import oidcUserManager from '@tabeeb/services/oidcUserManager'

const SignInSilentCallback = () => {
  useEffect(() => {
    oidcUserManager.signinSilentCallback()
  }, [])

  return (
    <Backdrop sx={{ zIndex: 9999, backgroundColor: 'inherit' }} open transitionDuration={0}>
      <CircularProgress color='info' size={40} thickness={6} />
    </Backdrop>
  )
}

export default SignInSilentCallback
