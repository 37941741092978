import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'

import AnnotationInfo from '../AnnotationInfo'

import inlineStyles from './inlineStyles'
import './styles.less'

const AnnotationPopover = ({ classes, anchorReference, handleClose, handleEdit, selectedCoordinatesInfo }) => (
  <Popover
    open
    anchorEl={anchorReference}
    anchorReference='anchorEl'
    classes={{ ...classes }}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <div className='annotation-popover-body'>
      <ul className='annotation-info-list'>
        {selectedCoordinatesInfo.map((annotationInfo, index) => (
          <AnnotationInfo key={index} handleEdit={handleEdit} annotationInfo={annotationInfo} />
        ))}
      </ul>
    </div>
  </Popover>
)

AnnotationPopover.propTypes = {
  anchorReference: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  selectedCoordinatesInfo: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(inlineStyles)(AnnotationPopover)
