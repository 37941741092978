import { createAction } from 'redux-actions'

import { AudioMixerEffect } from '@tabeeb/modules/shared/utils/streamEffects/AudioMixerEffect'
import { isLocalRecordingActive } from '@tabeeb/modules/recording/services/localRecordingService'
import { openSwitchRecordingSourceDialog } from '@tabeeb/modules/whiteboard/actions'
import { getContentId, getIsCurrentUserBeingRecorded } from '@tabeeb/shared/content/selectors'
import { signalrActions } from '@tabeeb/modules/signalr'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { muteVideo } from '@tabeeb/modules/presentationToolbar/actions/muteVideo'
import { AUDIO_DEVICE, DESKTOP_DEVICE, VIDEO_DEVICE } from '../constants'
import * as devicesActions from './devices'
import * as connectionService from '../services/connectionService'
import * as trackActions from './tracks'
import { getIfCameraMutedBeforeScreenSharing, getIsVideoMuted } from '../selectors'

export const toggleScreenSharing = createAction('TOGGLE_SCREEN_SHARING')
export const setScreenSharingError = createAction('SET_SCREEN_SHARING_ERROR')
export const setScreenSharingState = createAction('SET_SCREEN_SHARING_STATE')
export const setSystemAudioTrack = createAction('SET_SYSTEM_AUDIO_TRACK')
export const resetScreenSharingState = createAction('RESET_SCREEN_SHARING_STATE')
export const setIfCameraMutedBeforeScreenSharing = createAction('SET_IF_CAMERA_MUTED_BEFORE_SCREEN_SHARING')

export function stopScreenSharing(approvedByLocalRecording) {
  return (dispatch, getState) => {
    const isCurrentUserBeingRecorded = getIsCurrentUserBeingRecorded(getState())
    if (isLocalRecordingActive() && !approvedByLocalRecording && isCurrentUserBeingRecorded) {
      dispatch(openSwitchRecordingSourceDialog())
      return
    }
    const state = getState()
    const videoInputDeviceId = state.presentation.devices.videoInputId

    const { systemAudioTrack } = state.presentation.screenSharing
    if (systemAudioTrack) {
      const localTrack = state.presentation.tracks.localTracks.find((item) => item.getType() === AUDIO_DEVICE)

      if (localTrack) {
        localTrack.setEffect(undefined)
      }

      systemAudioTrack.dispose()
    }

    const wasCameraMutedBefore = getIfCameraMutedBeforeScreenSharing(state)
    dispatch(toggleScreenSharing())
    dispatch(
      devicesActions.setVideoInputDevice({
        deviceId: videoInputDeviceId,
        approvedByLocalRecording,
        muted: wasCameraMutedBefore,
      })
    )
    if (wasCameraMutedBefore) {
      dispatch(muteVideo())
    }

    const contentId = getContentId(state)
    const currentUserId = getCurrentUserId(state)
    dispatch(signalrActions.invokeHubAction({ method: 'OnScreenSharingStopped', args: [contentId, currentUserId] }))
  }
}

export function startScreenSharing(approvedByLocalRecording) {
  return (dispatch, getState) => {
    const state = getState()
    const isCurrentUserBeingRecorded = getIsCurrentUserBeingRecorded(state)
    if (isLocalRecordingActive() && !approvedByLocalRecording && isCurrentUserBeingRecorded) {
      dispatch(openSwitchRecordingSourceDialog())
      return
    }
    dispatch(setScreenSharingState(true))
    dispatch(toggleScreenSharing())

    const cameraMuted = getIsVideoMuted(state)
    dispatch(setIfCameraMutedBeforeScreenSharing(cameraMuted))

    const initTracks = {
      devices: [DESKTOP_DEVICE],
      resolution: window.config.resolution,
    }
    connectionService.createLocalTracks(initTracks).then(
      (tracks) => {
        const videoTrack = tracks.find((track) => track.type === VIDEO_DEVICE)
        const systemAudioTrack = tracks.find((track) => track.type === AUDIO_DEVICE)

        if (systemAudioTrack) {
          dispatch(setSystemAudioTrack(systemAudioTrack))

          const localTrack = state.presentation.tracks.localTracks.find((item) => item.getType() === AUDIO_DEVICE)

          if (localTrack) {
            const effect = new AudioMixerEffect(systemAudioTrack)
            localTrack.setEffect(effect)
          } else {
            dispatch(trackActions.replaceTrack({ track: systemAudioTrack }))
          }
        }

        videoTrack.track.onended = () => {
          dispatch(stopScreenSharing())
        }

        dispatch(trackActions.replaceTrack({ track: videoTrack, approvedByLocalRecording }))
        dispatch(setScreenSharingState(false))

        const contentId = getContentId(state)
        const currentUserId = getCurrentUserId(state)
        dispatch(signalrActions.invokeHubAction({ method: 'OnScreenSharingStarted', args: [contentId, currentUserId] }))
      },
      (error) => {
        dispatch(setScreenSharingError(error))
        dispatch(setScreenSharingState(false))
        dispatch(toggleScreenSharing())
      }
    )
  }
}
