import { green, red } from '@material-ui/core/colors'

export default (theme) => {
  const commonCheckbox = {
    width: 32,
    height: 32,
    marginLeft: 6,
  }

  return {
    default: {
      ...commonCheckbox,
    },
    green: {
      ...commonCheckbox,
      '&$checked': { color: green[600] },
    },
    red: {
      ...commonCheckbox,
      '&$checked': { color: red[600] },
    },
    checked: {},
  }
}
