export default (theme) => {
  const blurRadius = 10

  return {
    container: {
      width: '100%',
      height: '100%',
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    video: {
      position: 'absolute',
      maxWidth: 'fitContent',
      height: '100%',
      margin: 'auto',
      boxShadow: theme.shadows[5],
      zIndex: 0,
    },
    hidden: {
      display: 'none !important',
    },
    background: {
      position: 'absolute',
      width: `calc(100% + ${blurRadius * 4}px)`,
      height: `calc(100% + ${blurRadius * 4}px)`,
      top: -blurRadius * 2,
      left: -blurRadius * 2,
      filter: `blur(${blurRadius}px)`,
      objectFit: 'cover',
    },
    mirrored: {
      transform: 'rotateY(180deg)',
    },
  }
}
