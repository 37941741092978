import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'

import './styles.less'

const ShareSessionByFormTimelineItem = ({ item, handleDeleteItem, isForReview }) => {
  const handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleDeleteItem(item.id)
  }

  const canBeDeleted = isForReview ? item.contentFormId === null : true

  return (
    <div className='share-by-form-timeline-list-item'>
      {canBeDeleted && (
        <IconButton size='small' style={{ position: 'absolute', top: 2, right: 2 }} onClick={handleDelete}>
          <i className='material-icons' style={{ color: 'black', backgroundColor: 'white', borderRadius: '100%' }}>
            remove_circle
          </i>
        </IconButton>
      )}

      <img src={item.thumbnailUrl} alt='pic' />
    </div>
  )
}

ShareSessionByFormTimelineItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  isForReview: PropTypes.bool,
}

export default ShareSessionByFormTimelineItem
