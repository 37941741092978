import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getNlpModel } from '../actions'

export default ({ id, onError }) => {
  const payload = useMemo(() => ({ id }), [id])

  const { loading, response: nlpModel } = useApiRequest({ request: getNlpModel.request, payload, onError })

  return { loading, nlpModel }
}
