import { combineReducers } from 'redux'

import recordId from './recordId'
import serverName from './serverName'
import selectedUserId from './selectedUserId'
import errorMessage from './errorMessage'
import errorCode from './errorCode'
import session from './session'
import recordingStatus from './recordingStatus'
import startDate from './startDate'
import stopData from './stopData'
import isSwitchRecordedUserConfirmationDialogOpen from './isSwitchRecordedUserConfirmationDialogOpen'

import elapsedTime from './elapsedTime'

export default combineReducers({
  recordId,
  serverName,
  selectedUserId,
  errorMessage,
  errorCode,
  session,
  recordingStatus,
  startDate,
  stopData,
  elapsedTime,
  isSwitchRecordedUserConfirmationDialogOpen,
})
