import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_SHARE_SESSION_PAGES_STATE')

export const openShareSessionPagesDialog = createAction('OPEN_SHARE_SESSION_PAGES_DIALOG')
export const closeShareSessionPagesDialog = createAction('CLOSE_SHARE_SESSION_PAGES_DIALOG')

export const addPageForSharing = createAction('ADD_PAGE_FOR_SHARING')
export const addAllPagesForSharing = createAction('ADD_ALL_PAGES_FOR_SHARING')
export const deletePageForSharing = createAction('DELETE_PAGE_FOR_SHARING')

export const setIsShareContentByFormDialogOpen = createAction('SET_IS_SHARE_CONTENT_BY_FORM_DIALOG_OPEN')

export const setSelectedFormsForSharing = createAction('SET_SELECTED_FORMS_FOR_SHARING')
export const setFormsForSharing = createAction('SET_FORMS_FOR_SHARING')

export const addFormForSharing = createAction('ADD_FORM_FOR_SHARING')
export const removeFormForSharing = createAction('REMOVE_FORM_FOR_SHARING')

export const addAnswerStatusForSharing = createAction('ADD_ANSWER_STATUS_FOR_SHARING')
export const removeAnswerStatusForSharing = createAction('REMOVE_ANSWER_STATUS_FOR_SHARING')

export const addAllFormsForSharing = createAction('ADD_ALL_FORMS_FOR_SHARING')
export const removeAllFormsForSharing = createAction('REMOVE_ALL_FORMS_FOR_SHARING')

export const addStatusToAllFormsForSharing = createAction('ADD_STATUS_TO_ALL_FORMS_FOR_SHARING')
export const removeStatusFromAllFormsForSharing = createAction('REMOVE_STATUS_FROM_ALL_FORMS_FOR_SHARING')

export const getDataForSharingByForms = createAction('GET_DATA_FOR_SHARING_BY_FORMS')
export const addPagesByFormsForSharing = createAction('ADD_PAGES_BY_FORMS_FOR_SHARING')
