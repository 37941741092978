import { takeLatest, put, all, select } from 'redux-saga/effects'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as galleryActions from '../actions'
import * as bentleyModelActions from '../../bentleyModel/actions'

function* galleryAddBentleyModel(action) {
  const isBentleyModelOpen = yield select((state) => state.bentleyModel.isTimelineOpen)
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  if (!isBentleyModelOpen) yield put(bentleyModelActions.openBentleyModelTimeline())

  if (currentPanel !== TabPanel.BentleyModel)
    yield put(navbarActions.switchPanel(TabPanel.BentleyModel, TabPanelLocation.Bottom))
}

function* addBentleyModel() {
  yield all([takeLatest(galleryActions.galleryAddBentleyModel, galleryAddBentleyModel)])
}

export default addBentleyModel
