import { memo, useMemo } from 'react'

import { TableCell, TableRow, Tooltip, Typography } from '@material-ui/core'

import useStyles from './styles'

const Weekdays = () => {
  const classes = useStyles()

  const weekdays = useMemo(() => [
    {
      shorthand: 'Mo',
      name: 'Monday',
    },
    {
      shorthand: 'Tu',
      name: 'Tuesday',
    },
    {
      shorthand: 'We',
      name: 'Wednesday',
    },
    {
      shorthand: 'Th',
      name: 'Thursday',
    },
    {
      shorthand: 'Fr',
      name: 'Friday',
    },
    {
      shorthand: 'Sa',
      name: 'Saturday',
    },
    {
      shorthand: 'Su',
      name: 'Sunday',
    },
  ])

  return (
    <TableRow>
      {weekdays.map(({ shorthand, name }) => (
        <TableCell key={name} padding='none'>
          <Tooltip title={name}>
            <div className={classes.weekday}>
              <Typography color='textSecondary' variant='caption'>
                {shorthand}
              </Typography>
            </div>
          </Tooltip>
        </TableCell>
      ))}
    </TableRow>
  )
}

export default memo(Weekdays)
