import { TrackingEventType, TrackingAnnotationType } from '@tabeeb/enums'
import { getUserColor } from '@tabeeb/shared/utils/color'

import Marker from '../components/Marker'
import Circle from '../components/Circle'
import Rectangle from '../components/Rectangle'

export const convertTrackingToMapComponent = (tracking, props = {}) => {
  props.color = getUserColor(tracking.User)

  switch (tracking.AnnotationType) {
    case TrackingAnnotationType.Point:
      return (
        <Marker
          key={tracking.Id}
          latitude={tracking.Coordinates[0].Latitude}
          longitude={tracking.Coordinates[0].Longitude}
          {...props}
        />
      )
    case TrackingAnnotationType.Circle:
      return <Circle key={tracking.Id} start={tracking.Coordinates[0]} end={tracking.Coordinates[1]} {...props} />
    case TrackingAnnotationType.Rectangle:
      return <Rectangle key={tracking.Id} start={tracking.Coordinates[0]} end={tracking.Coordinates[1]} {...props} />
  }

  return null
}

export const convertMarkerToHotspotTracking = ({ marker, contentId }) => {
  return {
    EventType: TrackingEventType.Manual,
    AnnotationType: TrackingAnnotationType.Point,
    ContentId: contentId,
    Coordinates: [
      {
        Latitude: marker.position.lat(),
        Longitude: marker.position.lng(),
      },
    ],
  }
}

export const convertCircleToCircleTracking = ({ circle, contentId }) => {
  const radius = circle.getRadius()
  const center = circle.getCenter()

  const start = google.maps.geometry.spherical.computeOffset(center, radius, 135)
  const end = google.maps.geometry.spherical.computeOffset(center, radius, -45)

  return {
    EventType: TrackingEventType.Manual,
    AnnotationType: TrackingAnnotationType.Circle,
    ContentId: contentId,
    Coordinates: [
      {
        Latitude: start.lat(),
        Longitude: start.lng(),
      },
      {
        Latitude: end.lat(),
        Longitude: end.lng(),
      },
    ],
  }
}

export const convertRectangleToReactangleTracking = ({ rectangle, contentId }) => {
  const bounds = rectangle.getBounds()

  const northEast = bounds.getNorthEast()
  const southWest = bounds.getSouthWest()

  return {
    EventType: TrackingEventType.Manual,
    AnnotationType: TrackingAnnotationType.Rectangle,
    ContentId: contentId,
    Coordinates: [
      {
        Latitude: northEast.lat(),
        Longitude: northEast.lng(),
      },
      {
        Latitude: southWest.lat(),
        Longitude: southWest.lng(),
      },
    ],
  }
}
