import PropTypes from 'prop-types'
import { Button, Paper } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Add } from '@material-ui/icons'

import { HealthStatisticsTimeLine, HealthStatisticsTimeLineDisplayName } from '@tabeeb/enums'

import useStyles from './styles'

const HealthStatisticsChartControls = ({
  handleToggleTimeLine,
  showAddHealthDataOption,
  handleAddHealthDataDialogOpen,
  chartHealthData,
  toggleDisabled,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.toolbar}>
      <Paper elevation={0} className={classes.paper}>
        <ToggleButtonGroup
          exclusive
          value={chartHealthData.interval}
          onChange={(event, value) => handleToggleTimeLine(value, chartHealthData.dataType)}
          size='small'
          className={classes.toggleGroup}
        >
          {Object.keys(HealthStatisticsTimeLine).map((key, index) => (
            <ToggleButton
              key={index}
              id={HealthStatisticsTimeLine[key]}
              value={`${HealthStatisticsTimeLine[key]}`}
              disabled={toggleDisabled}
            >
              {HealthStatisticsTimeLineDisplayName[key]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Paper>
      {showAddHealthDataOption && (
        <div>
          <Button
            startIcon={<Add />}
            variant='outlined'
            color='primary'
            onClick={() =>
              handleAddHealthDataDialogOpen({ dataType: chartHealthData.dataType, name: chartHealthData.name })
            }
          >
            Add data
          </Button>
        </div>
      )}
    </div>
  )
}

HealthStatisticsChartControls.propTypes = {
  handleToggleTimeLine: PropTypes.func.isRequired,
  showAddHealthDataOption: PropTypes.bool.isRequired,
  handleAddHealthDataDialogOpen: PropTypes.func.isRequired,
  chartHealthData: PropTypes.shape({
    dataType: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
  }).isRequired,
  toggleDisabled: PropTypes.bool.isRequired,
}

export default HealthStatisticsChartControls
