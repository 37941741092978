import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getPagesByFolder } from '../selectors'

import GalleryList from '../components/GalleryList'

class GalleryListContainer extends Component {
  componentDidUpdate(prevProps) {
    const { selectedGalleryItemId, setSelectedItemRef } = this.props

    const prevGalleryList = prevProps.galleryList
    const { galleryList } = this.props

    if (galleryList !== prevGalleryList && prevGalleryList) {
      const notRenderedSelectedItem = document.getElementById(selectedGalleryItemId)

      if (notRenderedSelectedItem) setSelectedItemRef(notRenderedSelectedItem)
    }
  }

  render() {
    const { galleryList, listRef, setSelectedItemRef, isGalleryExpanded, isDragAvailable } = this.props

    const props = {
      galleryList,
      listRef,
      setSelectedItemRef,
      isGalleryExpanded,
      isDragAvailable,
    }

    return <GalleryList {...props} />
  }
}

GalleryListContainer.propTypes = {
  galleryList: PropTypes.array.isRequired,
  listRef: PropTypes.object,
  setSelectedItemRef: PropTypes.func.isRequired,
  selectedGalleryItemId: PropTypes.number.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isDragAvailable: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const galleryList = getPagesByFolder(state)

  return {
    selectedGalleryItemId: state.gallery.galleryState.selectedGalleryItemId,
    galleryList,
    isGalleryExpanded: state.gallery.isGalleryExpanded,
  }
}

export default connect(mapStateToProps, null)(GalleryListContainer)
