import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const loadPowerBIReports = createAction('LOAD_POWER_BI_REPORTS')
export const setPowerBIReportsFilters = createAction('SET_POWER_BI_REPORTS_FILTERS')

export const resetPowerBIReport = createAction('RESET_POWER_BI_REPORT')

export const openAddPowerBIReportModal = createAction('OPEN_ADD_POWER_BI_REPORT_MODAL')
export const closeAddPowerBIReportModal = createAction('CLOSE_ADD_POWER_BI_REPORT_MODAL')

export const openPowerBIReportTenantModal = createAction('OPEN_POWER_BI_REPORT_TENANT_MODAL')
export const closePowerBIReportTenantModal = createAction('CLOSE_POWER_BI_REPORT_TENANT_MODAL')

export const openPowerBIReportUserModal = createAction('OPEN_POWER_BI_REPORT_USER_MODAL')
export const closePowerBIReportUserModal = createAction('CLOSE_POWER_BI_REPORT_USER_MODAL')

export const openAddPowerBIReportDependantModuleModal = createAction('OPEN_ADD_POWER_BI_REPORT_DEPENDENT_MODULE_MODAL')
export const closeAddPowerBIReportDependantModuleModal = createAction(
  'CLOSE_ADD_POWER_BI_REPORT_DEPENDENT_MODULE_MODAL'
)

export const setPowerBIReportTenantsRefetch = createAction('SET_POWER_BI_REPORT_TENANTS_REFETCH')
export const setPowerBIReportUsersRefetch = createAction('SET_POWER_BI_REPORT_USERS_REFETCH')
export const setPowerBIReportDependentModulesRefetch = createAction('SET_POWER_BI_REPORT_DEPENDENT_MODULES_REFETCH')

export const getPowerBIReports = createApiActions('GET_POWER_BI_REPORTS')
export const getPowerBIReport = createApiActions('GET_POWER_BI_REPORT')
export const getPowerBIReportEmbedData = createApiActions('GET_POWER_BI_REPORT_EMBED_DATA')
export const deletePowerBIReport = createApiActions('DELETE_POWER_BI_REPORT')
export const getPowerBIReportProvidersForSearch = createApiActions('GET_POWER_BI_REPORT_PROVIDERS_FOR_SEARCH')

export const createPowerBIReport = createApiActions('CREATE_POWER_BI_REPORT')
export const updatePowerBIReport = createApiActions('UPDATE_POWER_BI_REPORT')
export const updatePowerBIReportName = createApiActions('UPDATE_POWER_BI_REPORT_NAME')
export const validatePowerBIReportConfiguration = createApiActions('VALIDATE_POWER_BI_REPORT_CONFIGURATION')
export const resetValidatePowerBIReportConfigurationConnection = createAction(
  'RESET_VALIDATE_POWER_BI_REPORT_CONFIGURATION'
)

export const getPowerBIReportTenants = createApiActions('GET_POWER_BI_REPORT_TENANTS')
export const deletePowerBIReportTenant = createApiActions('DELETE_POWER_BI_REPORT_TENANT')
export const createPowerBIReportTenant = createApiActions('CREATE_POWER_BI_REPORT_TENANT')
export const updatePowerBIReportTenant = createApiActions('UPDATE_POWER_BI_REPORT_TENANT')
export const getPowerBIReportTenantRoles = createApiActions('GET_POWER_BI_REPORT_TENANT_ROLES')

export const getPowerBIReportUsers = createApiActions('GET_POWER_BI_REPORT_USERS')
export const deletePowerBIReportUser = createApiActions('DELETE_POWER_BI_REPORT_USER')
export const createPowerBIReportUser = createApiActions('CREATE_POWER_BI_REPORT_USER')

export const getPowerBIReportDependentModules = createApiActions('GET_POWER_BI_REPORT_DEPENDENT_MODULES')
export const deletePowerBIReportDependentModule = createApiActions('DELETE_POWER_BI_REPORT_DEPENDENT_MODULE')
export const createPowerBIReportDependentModule = createApiActions('CREATE_POWER_BI_REPORT_DEPENDENT_MODULE')

export const getPowerBIEmbedToken = createApiActions('GET_POWER_BI_EMBED_TOKEN')

export const getPowerBISecurityModels = createApiActions('GET_POWER_BI_SECURITY_MODELS')
