import { handleActions } from 'redux-actions'

import { showCallControls, hideCallControls } from '../actions'

const defaultValue = true

export default handleActions(
  {
    [showCallControls]() {
      return defaultValue
    },
    [hideCallControls]() {
      return false
    },
  },
  defaultValue
)
