import { createSelector } from 'reselect'

import { getTotalContentsCount } from '.'

export const getPaginationPageNumber = (state) => state.sessionsPage.pagination.pageNumber
export const getPaginationPageSize = (state) => state.sessionsPage.pagination.pageSize

export const getPagesCount = createSelector(
  [getTotalContentsCount, getPaginationPageSize],
  (totalContentsCount, pageSize) => {
    return Math.ceil(totalContentsCount / pageSize) || 1
  }
)
