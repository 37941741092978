import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'
import { LinkedContentStatusText } from '@tabeeb/modules/contentSharings/constants/enums'
import { getLinkedContentsList } from '@tabeeb/modules/contentSharings/selectors'
import LinkedContentControls from '../LinkedContentControls'
import { Container, LinkedContent, LinkedContentName, LinkedContentText } from './styles'

const LinkedContentsList = () => {
  const list = useSelector(getLinkedContentsList)

  return (
    <div style={{ direction: 'rtl', overflow: 'auto' }}>
      <Container>
        {list.map((content) => (
          <LinkedContent variant='outlined' key={content.Id}>
            <LinkedContentControls content={content} />
            <Tooltip arrow placement='top' title={content.Description}>
              <LinkedContentName>{content.Description}</LinkedContentName>
            </Tooltip>
            <Tooltip arrow placement='top' title={content.OwnerName}>
              <LinkedContentText>{`Owner: ${content.OwnerName}`}</LinkedContentText>
            </Tooltip>
            <Tooltip arrow placement='top' title={content.TenantName}>
              <LinkedContentText>{`Tenant: ${content.TenantName}`}</LinkedContentText>
            </Tooltip>
            <LinkedContentText>{`Status: ${LinkedContentStatusText[content.Status]}`}</LinkedContentText>
          </LinkedContent>
        ))}
      </Container>
    </div>
  )
}

export default memo(LinkedContentsList)
