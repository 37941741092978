import { handleActions } from 'redux-actions'

import { seekVideoToTimestamp, resetSeekVideoToTimestamp } from '../actions'

const defaultValue = {
  value: -1.0,
}

export default handleActions(
  {
    [seekVideoToTimestamp](state, { payload }) {
      return {
        value: payload / 1000,
      }
    },
    [resetSeekVideoToTimestamp](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
