export default (theme) => {
  return {
    map: {
      width: '100%',
    },
    title: {
      '& .MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    content: {
      overflowY: 'visible',
    },
  }
}
