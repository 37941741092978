import { createSelector } from 'reselect'

import { TenantPermission } from '@tabeeb/enums'
import { getShowCertificates } from '@tabeeb/modules/appConfigState/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

export const getShowCertificatesManagement = createSelector(
  [
    getShowCertificates,
    (state) => hasTenantPermission(state, TenantPermission.CertificatesTenantScope),
    (state) => hasTenantPermission(state, TenantPermission.CertificatesGlobalScope),
  ],
  (enabled, hasManageCertificatesTenantScopePermission, hasManageCertificatesGlobalScopePermission) => {
    return enabled && (hasManageCertificatesTenantScopePermission || hasManageCertificatesGlobalScopePermission)
  }
)
