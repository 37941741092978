import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'absolute',
      marginRight: -1,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      zIndex: 1,
    },
  }
})
