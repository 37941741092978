import { memo } from 'react'
import PropTypes from 'prop-types'

import { Paper, Button, Typography } from '@material-ui/core'

import './styles.less'

const ErrorBar = ({ text, handleReload }) => {
  return (
    <Paper className='policy-error-bar-container'>
      <Typography variant='h6'>{text}</Typography>
      <Button variant='outlined' color='secondary' onClick={handleReload}>
        Retry
      </Button>
    </Paper>
  )
}

ErrorBar.defaultProps = {
  text: '',
  handleReload: () => {},
}

ErrorBar.propTypes = {
  text: PropTypes.string.isRequired,
  handleReload: PropTypes.func.isRequired,
}

export default memo(ErrorBar)
