import { Component } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core'

import { tabeebTheme } from '@tabeeb/uikit'

class Portal extends Component {
  componentDidMount() {
    this.node = this.props.node
    if (!this.node) {
      this.node = document.getElementById('react-root')
    }
    this.renderPortal()
  }

  componentDidUpdate() {
    this.renderPortal()
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.defaultNode)
    if (this.defaultNode) {
      this.node.removeChild(this.defaultNode)
    }

    this.defaultNode = null
  }

  renderPortal() {
    if (!this.defaultNode) {
      this.defaultNode = document.createElement('div')

      if (this.props.className) {
        this.defaultNode.className = this.props.className
      } else {
        this.defaultNode.style = 'position: absolute;width: 100%;height: 100%;overflow: hidden;top: 0px;left: 0px;'
      }

      this.node.appendChild(this.defaultNode)
    }

    const { children, store } = this.props
    if (store) {
      ReactDOM.render(
        <Provider store={store}>
          <MuiThemeProvider theme={tabeebTheme}>{children}</MuiThemeProvider>
        </Provider>,
        this.defaultNode
      )
    } else {
      ReactDOM.render(<MuiThemeProvider theme={tabeebTheme}>{children}</MuiThemeProvider>, this.defaultNode)
    }
  }

  render() {
    return null
  }
}

export default Portal
