import { all, takeLatest, put, take, select } from 'redux-saga/effects'

import { TenantPermission } from '@tabeeb/enums'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { getUsersContractorTenants } from '@tabeeb/modules/contractors/actions'

import {
  adminUpdateUser,
  adminSaveUserSuccess,
  adminSaveUserFailed,
  adminAssignRoleRequest,
  adminAssignRoleSuccess,
  adminAssignRoleFailed,
  adminCreateUserFailed,
  adminSetUserDisabledByUserIdSuccess,
  adminSetUserDisabledByUserIdFailed,
  adminSetUserEnabledByUserIdSuccess,
  adminSetUserEnabledByUserIdFailed,
  adminUpdateUserDisabledState,
  resetUserPasswordSuccess,
  adminSearchUsersSuccess,
} from '../actions'
import {
  onAddErrorNotification,
  onAddWarningNotification,
  onAddSuccessNotification,
} from '../../../notification/actions'

function* disableUserByIdSuccess({ payload: userId }) {
  yield put(adminUpdateUserDisabledState({ isDisabled: true, userId }))
}

function* enableUserByIdSuccess({ payload: userId }) {
  yield put(adminUpdateUserDisabledState({ isDisabled: false, userId }))
}

function* disableUserByIdFailed({ status, payload: userId }) {
  if (status === 304) {
    yield put(onAddWarningNotification({ message: 'User already disabled' }))
    yield put(adminUpdateUserDisabledState({ isDisabled: true, userId }))
  } else {
    yield put(onAddErrorNotification({ message: 'Failed to disable user' }))
  }
}

function* enableUserByIdFailed({ status, payload: userId }) {
  if (status === 304) {
    yield put(onAddWarningNotification({ message: 'User already enabled' }))
    yield put(adminUpdateUserDisabledState({ isDisabled: false, userId }))
  } else {
    yield put(onAddErrorNotification({ message: 'Failed to enable user' }))
  }
}

function* onSaveUserFailed(action) {
  yield put(onAddErrorNotification({ message: 'Failed to save user' }))
}

function* onSaveUserSuccess(action) {
  const user = action.response.data
  const role = action.payload.Role

  const data = {
    UserId: user.Id,
    TenantId: user.TenantId,
    Role: role,
  }

  yield put(adminAssignRoleRequest(data))

  const result = yield take([adminAssignRoleSuccess, adminAssignRoleFailed])
  if (result.type === adminAssignRoleSuccess().type) {
    yield put(adminUpdateUser({ ...user, Role: role }))
  } else if (result.type === adminAssignRoleFailed().type) {
    yield put(onAddErrorNotification({ message: 'Failed to update role' }))
  }
}

function* onResetUserPasswordSuccess(action) {
  yield put(onAddSuccessNotification({ message: 'Password reset requested' }))
}

function* onUsersLoaded(action) {
  const users = action.response.data
  if (users.length <= 0) {
    return
  }

  const hasPermission = yield select((state) => hasTenantPermission(state, TenantPermission.ContractsManagement))
  if (!hasPermission) {
    return
  }

  yield put(getUsersContractorTenants.request({ userIds: users.map((user) => user.Id) }))
}

function* adminUsersPageSaga(params) {
  yield all([
    takeLatest(adminSetUserDisabledByUserIdSuccess, disableUserByIdSuccess),
    takeLatest(adminSetUserEnabledByUserIdSuccess, enableUserByIdSuccess),
    takeLatest(adminSetUserEnabledByUserIdFailed, enableUserByIdFailed),
    takeLatest(adminSetUserDisabledByUserIdFailed, disableUserByIdFailed),
    takeLatest(adminSaveUserSuccess, onSaveUserSuccess),
    takeLatest([adminSaveUserFailed, adminCreateUserFailed], onSaveUserFailed),
    takeLatest([adminSearchUsersSuccess], onUsersLoaded),
    takeLatest([resetUserPasswordSuccess], onResetUserPasswordSuccess),
  ])
}

export default adminUsersPageSaga
