import { put, takeLatest, select, all } from 'redux-saga/effects'

import { contentStateSelectors, rawContentActions } from '@tabeeb/shared/content'
import { required2dLineDrawingFiles } from '@tabeeb/shared/utils/validationErrorMessages'
import { ContentType } from '@tabeeb/enums'
import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

import { getPagesList } from '../selectors'
import {
  MINIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING,
  MAXIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING,
  REQUIRED_3D_MODELS_NUMBER_FOR_2D_LINE_DRAWING,
} from '../constants'

function* onAdd2dLineDrawing(action) {
  const pagesList = yield select(getPagesList)

  if (
    pagesList.length < MINIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING ||
    pagesList.length > MAXIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING
  ) {
    yield put(notificationActions.onAddErrorNotification({ message: required2dLineDrawingFiles }))
    return
  }

  let pointCloudModelPageId = null

  let threeDModelsCount = 0

  pagesList.forEach((page) => {
    switch (page.contentType) {
      case ContentType.WebPage:
        threeDModelsCount++
        pointCloudModelPageId = page.id
        break
      default:
        break
    }
  })

  if (threeDModelsCount !== REQUIRED_3D_MODELS_NUMBER_FOR_2D_LINE_DRAWING) {
    yield put(notificationActions.onAddErrorNotification({ message: required2dLineDrawingFiles }))
    return
  }

  const contentId = yield select(contentStateSelectors.getContentId)

  const model = {
    contentId,
    pointCloudModelPageId,
  }

  yield put(actions.addTwoDLineDrawingRequest(model))
}

function* onAdd2dLineDrawingSuccess() {
  yield put(actions.close2dLineDrawingDialog())
  yield put(notificationActions.onAddInfoNotification({ message: 'Data sent for 2D Line Drawing creation' }))
}

function* onAdd2dLineDrawingFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to create 2D Line Drawing' }))
}

function* resetState() {
  yield put(actions.resetState())
}

function* add2dLineDrawing() {
  yield all([
    takeLatest(actions.add2dLineDrawing, onAdd2dLineDrawing),
    takeLatest(actions.addTwoDLineDrawingSuccess, onAdd2dLineDrawingSuccess),
    takeLatest(actions.addTwoDLineDrawingFailed, onAdd2dLineDrawingFailed),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default add2dLineDrawing
