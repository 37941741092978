import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../../actions'

const initialState = {
  open: false,
  fetchStatus: FetchStatus.Idle,
  reportId: 0,
}

export default handleActions(
  {
    [rawActions.openAddPowerBIReportDependantModuleModal]: (state, action) => {
      return { ...state, open: true, reportId: action.payload }
    },
    [rawActions.closeAddPowerBIReportDependantModuleModal]: () => {
      return initialState
    },
    [rawActions.createPowerBIReportDependentModule.request]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [rawActions.createPowerBIReportDependentModule.success]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loaded }
    },
    [rawActions.createPowerBIReportDependentModule.failed]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
  },
  initialState
)
