import { put, all, select, takeEvery } from 'redux-saga/effects'

import { CalendarSubscriptionDisplay } from '@tabeeb/enums'

import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import { calendarActions, calendarSelectors } from '@tabeeb/modules/calendar'

function* subscribeToUsersCalendarUpdates(action) {
  const { userId } = action.payload
  const { Enabled: enabled } = action.response.data

  if (enabled) {
    yield put(calendarActions.subscribeToCalendarUpdates({ calendarId: userId }))
  }
}

function* unsubscribeFromUsersCalendarUpdates(action) {
  const { userId } = action.payload

  yield put(calendarActions.unsubscribeFromCalendarUpdates({ calendarId: userId }))
}

function* resetCalendarUpdatesConnections() {
  const userIds = yield select(calendarSelectors.getEnabledUsersCalendarsIds)

  for (const userId of userIds) {
    yield put(calendarActions.unsubscribeFromCalendarUpdates({ calendarId: userId }))
  }
}

function* restoreCalendarUpdatesConnections() {
  const userIds = yield select(calendarSelectors.getEnabledUsersCalendarsIds)

  for (const userId of userIds) {
    yield put(calendarActions.subscribeToCalendarUpdates({ calendarId: userId }))
  }
}

function* updateCalendarSubscriptionDisplay(action) {
  const { userId, mode } = action.payload

  if (mode === CalendarSubscriptionDisplay.Hide) {
    yield put(calendarActions.unsubscribeFromCalendarUpdates({ calendarId: userId }))
  }

  if (mode === CalendarSubscriptionDisplay.Display) {
    yield put(calendarActions.subscribeToCalendarUpdates({ calendarId: userId }))
  }

  if (mode === CalendarSubscriptionDisplay.DisplayThisOnly) {
    const calendarsIds = yield select(calendarSelectors.getUsersCalendarsIds)
    for (const calendarId of calendarsIds) {
      if (calendarId === userId) {
        continue
      }

      yield put(calendarActions.unsubscribeFromCalendarUpdates({ calendarId: userId }))
    }

    yield put(calendarActions.subscribeToCalendarUpdates({ calendarId: userId }))
  }
}

function* calendarSignalRSagas() {
  yield all([
    takeEvery(calendarActions.updateCalendarSubscriptionDisplaySuccess, updateCalendarSubscriptionDisplay),
    takeEvery(calendarActions.subscribeToUsersCalendarSuccess, subscribeToUsersCalendarUpdates),
    takeEvery(calendarActions.unsubscribeFromUsersCalendarSuccess, unsubscribeFromUsersCalendarUpdates),
    takeEvery(
      [calendarActions.getCalendarsListSuccess, signalrEvents[signalrConstants.tabeebHubName].onReconnected],
      restoreCalendarUpdatesConnections
    ),
    takeEvery(calendarActions.resetCalendarUpdatesConnections, resetCalendarUpdatesConnections),
  ])
}

export default calendarSignalRSagas
