import { createAction } from 'redux-actions'

export const getBillingSettingsRequest = createAction('GET_BILLING_SETTINGS_REQUEST')
export const getBillingSettingsSuccess = createAction('GET_BILLING_SETTINGS_SUCCESS')
export const getBillingSettingsFailed = createAction('GET_BILLING_SETTINGS_FAILED')

export const getSessionApprovalSettingsRequest = createAction('GET_SESSION_APPROVAL_SETTINGS_REQUEST')
export const getSessionApprovalSettingsSuccess = createAction('GET_SESSION_APPROVAL_SETTINGS_SUCCESS')
export const getSessionApprovalSettingsFailed = createAction('GET_SESSION_APPROVAL_SETTINGS_FAILED')

export const getAdminBillingSettingsRequest = createAction('GET_ADMIN_BILLING_SETTINGS_REQUEST')
export const getAdminBillingSettingsSuccess = createAction('GET_ADMIN_BILLING_SETTINGS_SUCCESS')
export const getAdminBillingSettingsFailed = createAction('GET_ADMIN_BILLING_SETTINGS_FAILED')

export const getAdminSessionApprovalSettingsRequest = createAction('GET_ADMIN_SESSION_APPROVAL_SETTINGS_REQUEST')
export const getAdminSessionApprovalSettingsSuccess = createAction('GET_ADMIN_SESSION_APPROVAL_SETTINGS_SUCCESS')
export const getAdminSessionApprovalSettingsFailed = createAction('GET_ADMIN_SESSION_APPROVAL_SETTINGS_FAILED')

export const updateBillingSettings = createAction('UPDATE_BILLING_SETTINGS')

export const updateBillingSettingsRequest = createAction('UPDATE_BILLING_SETTINGS_REQUEST')
export const updateBillingSettingsSuccess = createAction('UPDATE_BILLING_SETTINGS_SUCCESS')
export const updateBillingSettingsFailed = createAction('UPDATE_BILLING_SETTINGS_FAILED')

export const updateSessionApprovalSettingsRequest = createAction('UPDATE_SESSION_APPROVAL_SETTINGS_REQUEST')
export const updateSessionApprovalSettingsSuccess = createAction('UPDATE_SESSION_APPROVAL_SETTINGS_SUCCESS')
export const updateSessionApprovalSettingsFailed = createAction('UPDATE_SESSION_APPROVAL_SETTINGS_FAILED')
