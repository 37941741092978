import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'
import { useSelector } from 'react-redux'
import FormControlButton from '@tabeeb/modules/forms/components/FormControlButton'
import { getIsEditAnswerIconVisible } from '@tabeeb/modules/forms/selectors'

const EditAnswerButton = ({ setIsEditingAnswer, control, isEditingAnswer }) => {
  const isVisible = useSelector((state) => getIsEditAnswerIconVisible(state, { control }))

  if (!isVisible || isEditingAnswer) return null

  const componentProps = {
    onClick: () => setIsEditingAnswer(true),
    Icon: EditIcon,
    title: 'Edit answers',
  }

  return <FormControlButton {...componentProps} />
}

EditAnswerButton.propTypes = {
  control: PropTypes.object.isRequired,
  setIsEditingAnswer: PropTypes.func.isRequired,
  isEditingAnswer: PropTypes.bool.isRequired,
}

export default EditAnswerButton
