import PropTypes from 'prop-types'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Collapse, IconButton, Tooltip, Typography } from '@mui/material'

import { useExpandable } from '@tabeeb/shared/utils/hooks'

const CollapsibleSection = ({ title, children }) => {
  const { expanded, onCollapse, onExpand } = useExpandable(true)

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='body2' fontWeight={500}>
          {title}
        </Typography>

        <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
          <IconButton size='small' onClick={expanded ? onCollapse : onExpand}>
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Tooltip>
      </Box>

      <Collapse in={expanded}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  )
}

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default CollapsibleSection
