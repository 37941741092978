import { memo } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { getCurrentForm } from '@tabeeb/modules/forms/selectors'
import FormTabPanel from '@tabeeb/modules/forms/components/FormTabPanel'

import styles from './styles'

const FormTabs = ({ classes }) => {
  const { Tabspanels } = useSelector(getCurrentForm)
  if (!Tabspanels || Tabspanels.length === 0) return null

  return (
    <div className={classes.container}>
      {Tabspanels.map((panel, index) => (
        <FormTabPanel panel={panel} index={index} key={panel.Order} />
      ))}
    </div>
  )
}

FormTabs.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(FormTabs))
