import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  form: {
    '&.MuiTableCell-root': {
      width: '50%',
      maxWidth: '50%',
      '& .MuiFormControlLabel-root': {
        '& .MuiFormControlLabel-label': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },
    },
  },
}))
