import { handleActions } from 'redux-actions'

import * as healthActions from '@tabeeb/modules/healthData/actions'

const defaultState = []

export default handleActions(
  {
    [healthActions.getTheLatestHealthDataHistorySuccess](state, action) {
      return action.response.data
    },
    [healthActions.addHealthDataRequest](state, action) {
      return state.map((currentMeasure) => {
        const addedMeasure = action.payload.find((added) => added.MeasureId === currentMeasure.MeasureId)

        return addedMeasure ?? currentMeasure
      })
    },
    [healthActions.updateHealthDataHistories](state, action) {
      const updatedHealthDataHistories = action.payload

      const result = state.map((stateHistory) => {
        const updatedHistory = updatedHealthDataHistories.find(
          (healthDataHistory) => healthDataHistory.HealthDataTypeId == stateHistory.HealthDataTypeId
        )

        if (updatedHistory) {
          stateHistory.Value = updatedHistory.Value
          stateHistory.SystemOfMeasureTypeId = updatedHistory.SystemOfMeasureTypeId
        }

        return stateHistory
      })
      return result
    },
    [healthActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
