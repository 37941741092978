export default (theme) => {
  return {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,0.6)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    footer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    timeline: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing() * 2,
      paddingRight: theme.spacing() * 2,
    },
    time: {
      color: '#fff',
      marginLeft: theme.spacing() * 2,
    },
    icon: {
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.h4.fontSize,
      },
      color: '#999',
      '&:hover': {
        color: theme.palette.common.white,
      },
    },

    volumeSlider: {
      width: 100,
    },
  }
}

export const timeline = (theme) => {
  return {
    root: {
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  }
}
