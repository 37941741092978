import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.openBentleyModelTimeline](state, action) {
      return true
    },
    [rawActions.closeBentleyModelTimeline](state, action) {
      return false
    },
    [rawActions.resetState](state, action) {
      return false
    },
  },
  false
)
