import { handleActions } from 'redux-actions'

import _ from 'lodash'

import * as rawActions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [rawActions.setSelectedSessions](state, action) {
      return action.payload
    },
    [rawActions.addSessionsToSelection](state, action) {
      const addedSessions = action.payload

      return _.uniqBy([...state, ...addedSessions], (c) => c.Id)
    },
    [rawActions.removeSessionsFromSelection](state, action) {
      const deletedSessions = action.payload

      return state.filter((c) => !deletedSessions.some((dc) => c.Id === dc.Id))
    },
    [rawActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
