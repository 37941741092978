import { Box } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { useDebouncedValue } from '@tabeeb/shared/utils/hooks'
import { searchUsersRequest } from '@tabeeb/modules/sessionsPage/actions'
import { getFoundUsersList } from '@tabeeb/modules/sessionsPage/selectors/reviews'

import UserSearch from '../UserSearch'

const UserCustomGridFilterOperator = ({ item, applyValue, disabled }) => {
  const dispatch = useDispatch()
  const [filterValue, setFilterValue] = useState(item.value ?? [])

  const foundUsersList = useSelector(getFoundUsersList)
  const [searchUserEmail, setSearchUserEmail] = useState('')
  const debouncedSearchUserEmail = useDebouncedValue(searchUserEmail, 500)

  const updateFilterValue = useCallback(
    (event, value) => {
      setFilterValue(value)
      applyValue({ ...item, value })
    },
    [applyValue, item]
  )

  useEffect(() => {
    dispatch(searchUsersRequest({ searchText: debouncedSearchUserEmail }))
  }, [debouncedSearchUserEmail, dispatch])

  const handleChangeUserEmailInput = useCallback((event, value) => {
    setSearchUserEmail(value)
  }, [])

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        minHeight: 48,
        minWidth: 300,
      }}
    >
      <UserSearch
        disabled={disabled}
        value={filterValue}
        options={foundUsersList}
        onChange={updateFilterValue}
        InputProps={{
          variant: 'standard',
          label: 'Enter name or email',
          placeholder: 'Enter name or email...',
        }}
        AutocompleteProps={{
          multiple: true,
          onInputChange: handleChangeUserEmailInput,
          sx: { maxWidth: 600, width: '100%', height: '100%' },
          ChipProps: { size: 'small', style: { 'margin-bottom': '2.5px' } },
          filterOptions: (options) => options,
        }}
      />
    </Box>
  )
}

UserCustomGridFilterOperator.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.number.isRequired,
        IdentityGuid: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  applyValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default UserCustomGridFilterOperator
