import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'
import * as presentationActions from '../../../presentation/actions/conference'

const defaultState = null

export default handleActions(
  {
    [rawActions.changeGalleryOpenedMenu](state, action) {
      return action.payload.openedMenu
    },
    [presentationActions.leaveRoom](state, action) {
      return defaultState
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
