import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, generatePath } from 'react-router-dom'

import { Box, useMediaQuery, useTheme } from '@mui/material'
import { Delete, Folder, FolderShared, KeyboardArrowDown } from '@mui/icons-material'

import Routes from '@tabeeb/routes'
import { TenantPermission } from '@tabeeb/enums'
import { usePopoverState } from '@tabeeb/shared/utils/hooks'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

import { getIsSessionPublishingEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { getFoldersList } from '@tabeeb/modules/sessionsPage/selectors'

import NavigationMenu from './NavigationMenu'
import NavigationItem from './NavigationItem'

import { NavigationLinkTypography } from './NavigationLink/styles'

const Navigation = () => {
  const theme = useTheme()
  const { pathname } = useLocation()

  const compact = useMediaQuery(theme.breakpoints.down('sm'))

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const folders = useSelector(getFoldersList)
  const showAllSessionsPageLink = useSelector((state) => hasTenantPermission(state, TenantPermission.AllSessionsAccess))
  const showPublishedSessionsPageLink = useSelector(getIsSessionPublishingEnabled)

  const isLinkSelected = (href) => {
    return pathname === href
  }

  const links = useMemo(() => {
    const pageLinks = []

    const mySessionsPageLink = {
      href: Routes.home,
      name: sessionTitleFormatter.format('My Sessions'),
      subitems: [
        ...(folders || []).map((folder) => ({
          icon: folder.IsFolderShared ? FolderShared : Folder,
          href: generatePath(Routes.sessionsFolder, { folderId: folder.Id }),
          name: folder.Name,
        })),
        {
          icon: Delete,
          href: Routes.deletedSessions,
          name: `Trash bin`,
        },
      ],
    }

    pageLinks.push(mySessionsPageLink)

    if (showAllSessionsPageLink) {
      pageLinks.push({
        href: Routes.allSessions,
        name: sessionTitleFormatter.format('All Sessions'),
        subitems: [
          {
            icon: Delete,
            href: Routes.allDeletedSessions,
            name: sessionTitleFormatter.format('Deleted Sessions'),
          },
        ],
      })
    }

    if (showPublishedSessionsPageLink) {
      pageLinks.push({
        href: Routes.publishedSessions,
        name: sessionTitleFormatter.format('Published Sessions'),
        subitems: [],
      })
    }

    return pageLinks
  }, [folders, showAllSessionsPageLink, showPublishedSessionsPageLink])

  if (compact) {
    const selectedLink = links.find(({ href }) => isLinkSelected(href)) || links[0]

    return (
      <Box display='flex' alignItems='center' onClick={onOpen} pt={1}>
        <NavigationLinkTypography ref={anchorRef} noWrap onClick={onOpen}>
          {selectedLink.name}
        </NavigationLinkTypography>
        <KeyboardArrowDown />
        <NavigationMenu anchorEl={anchorRef.current} open={open} onClose={onClose} items={links} />
      </Box>
    )
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' alignItems='flex-start' pt={1}>
        {links.map(({ name, href, subitems }) => (
          <Box key={href} display='flex' alignItems='center' mr={2}>
            <NavigationItem href={href} name={name} subitems={subitems} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Navigation
