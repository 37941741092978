import { put, takeLatest, select, take, all } from 'redux-saga/effects'

import * as notificationActions from '@tabeeb/modules/notification/actions'
import { SessionNamingConventionType } from '@tabeeb/enums'
import * as contentStateSelectors from '@tabeeb/modules/shared/content/selectors'
import * as tenantFormsSelectors from '@tabeeb/modules/tenantForms/selectors'
import * as connectionActions from '../../presentation/actions/connection'
import * as conferenceActions from '../../presentation/actions/conference'
import * as navbarActions from '../actions'
import * as tenantFormActions from '../../tenantForms/actions'
import * as contentActions from '../../shared/content/actions'
import { signalrActions } from '../../signalr'

import switchContent from './switchContent'

function* startCall() {
  console.log('Jitsi initialization started')
  yield put(navbarActions.setCallProcessingState(true))
}

function* setJitsiRoom() {
  console.log('Jitsi initialization finished')
  yield put(navbarActions.setCallProcessingState(false))
}

function* onConnectionFailed() {
  yield put(navbarActions.setCallProcessingState(false))
}

function* setVideoBridgeRegion(action) {
  const contentId = action.payload.ContentId
  const videoBridgeUrl = action.payload.VideobridgeUrl

  signalrActions.invokeHubAction({ method: 'SetVideoBridge', args: [contentId, videoBridgeUrl] })
}

function* renameSessionUsingConvention(action) {
  yield put(tenantFormActions.openTenantFormsDialog({ sncType: SessionNamingConventionType.RenameSession }))
  yield take([tenantFormActions.submitTenantFormAnswers])

  yield put(
    contentActions.renameContentRequest({
      contentId: yield select(contentStateSelectors.getContentId),
      description: yield select(tenantFormsSelectors.getSubmittedAnswers),
      contentNameParts: yield select(tenantFormsSelectors.getContentNameParts),
    })
  )

  yield put(tenantFormActions.closeTenantFormsDialog())
}

function* renameContentFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Session has not been renamed.' }))
}

function* navbarSaga() {
  yield all([
    takeLatest(connectionActions.startCall, startCall),
    takeLatest(conferenceActions.setJitsiRoom, setJitsiRoom),
    takeLatest(connectionActions.onConnectionFailed, onConnectionFailed),
    takeLatest(navbarActions.setVideoBridgeSuccess, setVideoBridgeRegion),
    takeLatest(navbarActions.renameSessionUsingConvention, renameSessionUsingConvention),
    takeLatest(contentActions.renameContentFailed, renameContentFailed),
    switchContent(),
  ])
}

export default navbarSaga
