import { memo } from 'react'
import LinkedContentsList from './LinkedContentsList'
import { Container, Header } from './styles'

const LinkedContents = () => {
  return (
    <Container>
      <Header>Linked contents</Header>
      <LinkedContentsList />
    </Container>
  )
}

export default memo(LinkedContents)
