import {
  AUDIO_DEVICE,
  AUDIO_INPUT_DEVICE_KEY,
  AUDIO_INPUT_KIND,
  AUDIO_OUTPUT_DEVICE_KEY,
  AUDIO_OUTPUT_KIND,
  DEFAULT_AUDIO_DEVICE_ID,
  DEFAULT_VIDEO_DEVICE_ID,
  VIDEO_DEVICE,
  VIDEO_INPUT_DEVICE_KEY,
  VIDEO_INPUT_KIND,
} from '@tabeeb/modules/presentation/constants'

export function checkForDevices() {
  return new Promise((resolve, reject) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      resolve([AUDIO_DEVICE, VIDEO_DEVICE])
    }

    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      const deviceKinds = getDeviceKinds(devices)
      const audioDevice = getAudioDevice(deviceKinds)
      const videoDevice = getVideoDevice(deviceKinds)

      resolve([audioDevice, videoDevice])
    })
  })
}

export function getDevices(kind) {
  return new Promise((resolve, reject) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      resolve([])
    }

    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      const specificDevices = devices.filter((device) => device.kind === kind)

      resolve(specificDevices)
    })
  })
}

export function getAudioInputDevices() {
  const defaultDevices = ['default', 'communications']
  return new Promise((resolve, reject) => {
    getDevices(AUDIO_INPUT_KIND).then((devices) => {
      resolve(devices.filter((device) => !defaultDevices.includes(device.deviceId)))
    })
  })
}

export function getAudioOutputDevices() {
  const defaultDevices = ['default', 'communications']
  return new Promise((resolve, reject) => {
    getDevices(AUDIO_OUTPUT_KIND).then((devices) => {
      resolve(devices.filter((device) => !defaultDevices.includes(device.deviceId)))
    })
  })
}

export function getVideoInputDevices(kind) {
  return new Promise((resolve, reject) => {
    getDevices(VIDEO_INPUT_KIND).then((devices) => {
      resolve(devices)
    })
  })
}

export function getDeviceById(deviceId) {
  return new Promise((resolve, reject) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      resolve(undefined)
    }

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const device = devices.find((device) => device.deviceId === deviceId)

      resolve(device)
    })
  })
}

function getAudioDevice(deviceKinds) {
  return deviceKinds.indexOf(AUDIO_INPUT_KIND) !== -1 ? AUDIO_DEVICE : ''
}

function getVideoDevice(deviceKinds) {
  return deviceKinds.indexOf(VIDEO_INPUT_KIND) !== -1 ? VIDEO_DEVICE : ''
}

function getDeviceKinds(devices) {
  return devices.map((device) => device.kind)
}

export const getAudioInputDeviceId = async (isDeviceSwitchingEnabled) => {
  const audioInputDevices = await getAudioInputDevices()
  const storedAudioInputDeviceId = localStorage.getItem(AUDIO_INPUT_DEVICE_KEY)
  const audioInputDeviceId =
    (storedAudioInputDeviceId && audioInputDevices.some((d) => d.deviceId === storedAudioInputDeviceId)
      ? storedAudioInputDeviceId
      : null) ||
    (isDeviceSwitchingEnabled && audioInputDevices[0] ? audioInputDevices[0].deviceId : DEFAULT_AUDIO_DEVICE_ID)

  return audioInputDeviceId
}

export const getAudioOutputDeviceId = async (isDeviceSwitchingEnabled) => {
  const audioOutputDevices = await getAudioOutputDevices()
  const storedAudioOutputDeviceId = localStorage.getItem(AUDIO_OUTPUT_DEVICE_KEY)
  const audioOutputDeviceId =
    (storedAudioOutputDeviceId && audioOutputDevices.some((d) => d.deviceId === storedAudioOutputDeviceId)
      ? storedAudioOutputDeviceId
      : null) ||
    (isDeviceSwitchingEnabled && audioOutputDevices[0] ? audioOutputDevices[0].deviceId : DEFAULT_AUDIO_DEVICE_ID)

  return audioOutputDeviceId
}

export const getVideoInputDeviceId = async () => {
  const videoDevices = await getVideoInputDevices()
  const storedVideoInputDeviceId = localStorage.getItem(VIDEO_INPUT_DEVICE_KEY)
  const videoInputDeviceId =
    (storedVideoInputDeviceId && videoDevices.some((d) => d.deviceId === storedVideoInputDeviceId)
      ? storedVideoInputDeviceId
      : null) || (videoDevices[0] ? videoDevices[0].deviceId : DEFAULT_VIDEO_DEVICE_ID)

  return videoInputDeviceId
}
