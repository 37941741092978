import { handleActions } from 'redux-actions'
import * as aiActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [aiActions.getPredictionSets.success](state, { response }) {
      return response.data
    },
    [aiActions.deletePredictionSet.success](state, { payload: predictionSetId }) {
      return state.filter((prSet) => prSet.Id !== predictionSetId)
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
