import { useSelector } from 'react-redux'

import { TenantPermission } from '@tabeeb/enums'
import routes from '@tabeeb/routes'

import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { getIsPointCloudModelsEnabled } from '@tabeeb/modules/appConfigState/selectors'

import NavbarTab from '../NavbarTab'

const ThreeDimensionalModels = ({ ...rest }) => {
  const display = useSelector(
    (state) =>
      hasTenantPermission(state, TenantPermission.ThreeDimensionalModelsManagement) &&
      getIsPointCloudModelsEnabled(state)
  )

  const props = {
    item: {
      label: '3D Models',
      to: ThreeDimensionalModels.to,
    },
    display,
    ...rest,
  }

  return <NavbarTab {...props} />
}

ThreeDimensionalModels.to = routes.threeDimensionalModels

export default ThreeDimensionalModels
