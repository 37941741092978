export function getSortedUsers(arr, searchText) {
  return arr.sort((a, b) => {
    const aval = likeThis(a, searchText)
    const bval = likeThis(b, searchText)
    return aval < bval
  })
}

function likeThis(obj, text) {
  let points = 0
  const name = obj.Name.split(' ')
  const tokens = text.split(' ')

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]
    for (let j = 0; j < name.length; j++) {
      const namepart = name[j]
      if (namepart !== undefined && namepart.indexOf(token) > -1) {
        points += 2
      }
    }
    if (obj.Email.indexOf(token) > -1) {
      points += 1
    }
  }
  return points
}
