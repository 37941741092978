import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      width: '100%',
      height: '100%',
      background: 'linear-gradient(#505050, #606060)',
      display: 'flex',
      position: 'relative',

      borderRadius: theme.shape.borderRadius,
    },
    recording: {
      border: '1px solid #ff0000',
    },
    selected: {
      border: '1px solid #ffffff',
    },
    border: {
      width: `100%`,
      height: `100%`,
      position: 'absolute',
      zIndex: 1,
      transition: 'box-shadow .3s ease',
      pointerEvents: 'none',
      borderRadius: theme.shape.borderRadius,
    },
  }
})
