import PropTypes from 'prop-types'

import { Tooltip } from '@mui/material'

const BaseStatusIcon = ({ title, icon: Icon, sx }) => {
  return (
    <Tooltip title={title}>
      <Icon sx={{ mr: 0.75, fontSize: '14px' }} {...sx} />
    </Tooltip>
  )
}

BaseStatusIcon.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
}

export default BaseStatusIcon
