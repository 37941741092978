export default (theme) => {
  return {
    container: {
      padding: theme.spacing(),
    },
    elevationInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    infoIcon: {
      color: '#F6BB2F',
    },
    elevationTooltip: {
      background: 'rgba(28, 28, 28, 0.6) !important',
      color: theme.palette.secondary.main,
      fontSize: `${theme.typography.fontSize}px !important`,
      maxWidth: '215px !important',
      padding: `${theme.spacing() * 4}px ${theme.spacing() * 2}px !important`,
      margin: `0px !important`,
    },
    elevationTooltipText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '250px',
    },
  }
}
