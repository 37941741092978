const predictionSetsRequests = {
  addPredictionSetRequest: (data) => ({
    url: `api/predictionSets`,
    method: 'post',
    data,
  }),
  updatePredictionSetRequest: (data) => ({
    url: `api/predictionSets`,
    method: 'put',
    data,
  }),
  deletePredictionSetRequest: (predictionSetId) => ({
    url: `api/predictionSets/${predictionSetId}`,
    method: 'delete',
  }),
  getPredictionSetsRequest: ({
    pageNumber = 1,
    pageSize = 25,
    order = 'asc',
    orderBy = 'CreatedOn',
    filters = { Name: '', Description: '', Type: null, AIObjects: [] },
  }) => ({
    url: `api/predictionSets`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      column: orderBy,
      order,
      Name: filters.Name,
      Description: filters.Description,
      Type: filters.Type,
      AIObjects: filters.AIObjects,
    },
  }),

  getPredictionSetRequest: ({ predictionSetId }) => ({
    url: `api/predictionSets/${predictionSetId}`,
    method: 'get',
  }),
}

export default predictionSetsRequests
