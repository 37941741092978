import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Image as CanvasImage } from 'react-konva'

const LinkedPage = ({
  url,
  annotation,
  annotationRef,
  opacity,
  hovered,
  contentScale,
  hoverProps,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
}) => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const imageObject = new Image()
    imageObject.onload = (e) => {
      setImage(imageObject)
    }

    imageObject.src = url

    return () => {
      imageObject.src = ''
    }
  }, [url])

  const scale = LinkedPage.getScale(annotation, image)
  const position = LinkedPage.getXY(annotation, contentScale)

  const styles = {
    opacity: (hovered ? 1 : 0.5) * opacity,
    scaleX: scale / contentScale,
    scaleY: scale / contentScale,
  }

  return (
    image && (
      <CanvasImage
        id={annotation.Id}
        image={image}
        ref={annotationRef}
        {...styles}
        {...position}
        {...hoverProps}
        {...selectionProps}
        {...dragAndDropProps}
        {...contextMenuProps}
      />
    )
  )
}

LinkedPage.getXY = (annotation, scale = 1) => ({
  x: annotation.Anchor.X / scale,
  y: annotation.Anchor.Y / scale,
})

LinkedPage.getScale = (annotation, image) => {
  if (!image) return 1

  let maxHeight = 128
  let maxWidth = 128
  if (annotation.Timestamp === -1) {
    maxHeight = 64
    maxWidth = 64
  }

  return Math.min(maxWidth / image.width, maxHeight / image.height)
}

LinkedPage.propTypes = {
  url: PropTypes.string.isRequired,
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    LinkedPageId: PropTypes.number.isRequired,
    Anchor: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
    }),
  }).isRequired,
  annotationRef: PropTypes.func,
  contentScale: PropTypes.number.isRequired,
  opacity: PropTypes.number,
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node,
}

export default LinkedPage
