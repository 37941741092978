import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.less'

const PageTab = ({ tabText, isSelected, handleClick }) => (
  <div className={classNames({ selectedPageFilter: isSelected })} onClick={handleClick}>
    {tabText}
  </div>
)

PageTab.propTypes = {
  tabText: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default PageTab
