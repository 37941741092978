import { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import PropTypes from 'prop-types'

import Routes from '@tabeeb/routes'
import { callApiAsync } from '@tabeeb/shared/utils/requests'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { updateTrainingMaterial } from '@tabeeb/modules/articles/actions'
import { useTrainingMaterial } from '@tabeeb/modules/articles/hooks'

import TrainingMaterialEditorPage from '../TrainingMaterialEditorPage'

const TrainingMaterialEditPage = ({ id }) => {
  const dispatch = useDispatch()

  const onError = useCallback(() => {
    dispatch(
      onAddErrorNotification({ message: trainingMaterialTitleFormatter.format('Failed to load training material') })
    )
    dispatch(replace(Routes.trainingMaterials))
  }, [dispatch])

  const onSubmit = useCallback(
    async (updatedTrainingMaterial) => {
      try {
        await callApiAsync(updateTrainingMaterial.request(updatedTrainingMaterial))
        dispatch(
          onAddSuccessNotification({
            message: trainingMaterialTitleFormatter.format('Training material successfully updated'),
          })
        )
        dispatch(replace(Routes.trainingMaterials))
      } catch {
        dispatch(
          onAddErrorNotification({
            message: trainingMaterialTitleFormatter.format('Failed to update training material'),
          })
        )
      }
    },
    [dispatch]
  )

  const { loading, trainingMaterial } = useTrainingMaterial({ id, onError })
  if (loading || !trainingMaterial) {
    return <CircularProgressBar />
  }

  return (
    <TrainingMaterialEditorPage
      title={trainingMaterialTitleFormatter.format('Edit training material')}
      initial={trainingMaterial}
      onSubmit={onSubmit}
    />
  )
}

TrainingMaterialEditPage.propTypes = {
  id: PropTypes.number.isRequired,
}

export default memo(TrainingMaterialEditPage)
