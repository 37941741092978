import { memo } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Tooltip } from '@mui/material'
import { RestoreFromTrash } from '@mui/icons-material'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

const RestoreFromTrashButton = ({ onClick }) => {
  return (
    <Tooltip title={sessionTitleFormatter.format('Restore selected sessions')}>
      <IconButton size='small' onClick={onClick}>
        <RestoreFromTrash />
      </IconButton>
    </Tooltip>
  )
}

RestoreFromTrashButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default memo(RestoreFromTrashButton)
