const bentleyRequests = {
  createBentleyModelRequest: (data) => ({
    url: `api/bentley/model`,
    method: 'post',
    data,
  }),
  createBentleyAnalyticsReportRequest: (data) => ({
    url: `api/bentley/analyticsReport`,
    method: 'post',
    data,
  }),
  regenerateBentleyAnalyticsReportRequest: (data) => ({
    url: `api/bentley/analyticsReport/regenerate`,
    method: 'post',
    data,
  }),
  getContentBentleyGigapixelsRequest: ({ contentId, pageIds }) => ({
    url: `api/bentley/content/${contentId}/gigapixels`,
    method: 'post',
    data: {
      pageIds,
    },
  }),
}

export default bentleyRequests
