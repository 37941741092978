import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.less'

const SearchBox = ({
  onTextChanged,
  showPdfSearchResults,
  hidePdfSearchResults,
  showPdfSearchResultsPage,
  searchMatchesCount,
  searchText,
}) => {
  return (
    <div className='pdfSearch'>
      <i className='tabeeb-icon-search' />
      <input
        value={searchText}
        type='text'
        placeholder='Search...'
        onChange={onTextChanged}
        className='pdfSearchInput'
      />
      <span
        className={classNames('pagesLink', { selectedLink: !showPdfSearchResultsPage })}
        onClick={hidePdfSearchResults}
      >
        Pages
      </span>
      <span
        className={classNames('resultsLink', { selectedLink: showPdfSearchResultsPage })}
        onClick={showPdfSearchResults}
      >
        Results
      </span>

      {searchMatchesCount > 0 && <span className='matchesCount'>{searchMatchesCount}</span>}
    </div>
  )
}

SearchBox.propTypes = {
  onTextChanged: PropTypes.func.isRequired,
  showPdfSearchResults: PropTypes.func.isRequired,
  hidePdfSearchResults: PropTypes.func.isRequired,
  showPdfSearchResultsPage: PropTypes.bool,
  searchMatchesCount: PropTypes.number,
  searchText: PropTypes.string,
}

export default SearchBox
