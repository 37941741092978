import { ButtonGroup, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { UniqueAIObjectCreationMode } from '@tabeeb/enums'
import * as annotationActions from '../../actions'
import { getUniqueAIObjectCreationMode } from '../../selectors'

const AIAnnotationEditorModeButton = () => {
  const dispatch = useDispatch()

  const uniqueAIObjectCreationMode = useSelector(getUniqueAIObjectCreationMode)

  const handleChangeUniqueAIObjectCreationMode = (mode) => {
    dispatch(annotationActions.setUniqueAiObjectCreationMode(mode))
  }

  return (
    <ButtonGroup fullWidth>
      <Button
        disableRipple
        variant={uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.CreateNew ? 'contained' : 'outlined'}
        onClick={() => handleChangeUniqueAIObjectCreationMode(UniqueAIObjectCreationMode.CreateNew)}
      >
        Create New
      </Button>
      <Button
        disableRipple
        variant={uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.SelectExisting ? 'contained' : 'outlined'}
        onClick={() => handleChangeUniqueAIObjectCreationMode(UniqueAIObjectCreationMode.SelectExisting)}
      >
        Select Existing
      </Button>
      <Button
        disableRipple
        variant={
          uniqueAIObjectCreationMode === UniqueAIObjectCreationMode.CreateNewFromExisting ? 'contained' : 'outlined'
        }
        onClick={() => handleChangeUniqueAIObjectCreationMode(UniqueAIObjectCreationMode.CreateNewFromExisting)}
      >
        New From Existing
      </Button>
    </ButtonGroup>
  )
}

export default AIAnnotationEditorModeButton
