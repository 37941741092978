import { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import * as contentViewerActions from '../actions'
import * as notificationActions from '../../notification/actions'
import * as annotationActions from '../../annotations/actions'
import { spatialModelSelectors } from '../../spatialModel'

import SpatialView from '../components/SpatialView'
import { getAssetsForSpatialModel } from '../selectors'
import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'
import LoadingProgressBar from '../components/LoadingProgressBar'
import Portal from '../components/Portal'

class SpatialViewContainer extends Component {
  constructor(props) {
    super(props)

    const { selectedGalleryItemId, modelAssets, spatialViewStartPageId } = this.props
    const imageLoadingStatus = {}
    modelAssets.forEach((asset) => (imageLoadingStatus[asset.Id] = false))

    const index = modelAssets.findIndex((asset) => asset.LinkedPageId === spatialViewStartPageId)

    this.state = {
      pageId: selectedGalleryItemId,
      imageLoadingStatus,
      current: index === -1 ? 0 : index,
    }
  }

  componentDidMount() {
    const {
      contentViewerActions: { setLoadingInProgress },
    } = this.props
    setLoadingInProgress()

    const { current } = this.state

    this.setCurrentFrame(current)
  }

  componentWillReceiveProps(props) {
    const { selectedGalleryItemId, modelAssets } = props

    if (selectedGalleryItemId !== this.state.pageId) {
      this.state.pageId = selectedGalleryItemId

      this.setCurrentFrame(0, props)

      const imageLoadingStatus = {}
      modelAssets.forEach((asset) => (imageLoadingStatus[asset.Id] = false))

      this.setState({
        pageId: selectedGalleryItemId,
        imageLoadingStatus,
      })

      const {
        contentViewerActions: { setLoadingInProgress },
      } = this.props
      setLoadingInProgress()
    }
  }

  componentDidUpdate() {
    const { imageLoadingStatus } = this.state
    const { isLoading } = this.props

    if (isLoading && _.every(imageLoadingStatus, (value) => value === true)) {
      const {
        contentViewerActions: { resetLoadingInProgress },
      } = this.props
      resetLoadingInProgress()
    }
  }

  componentWillUnmount() {
    const {
      contentViewerActions: { setSpatialViewStartPageId },
    } = this.props

    setSpatialViewStartPageId({ pageId: null })
  }

  getPointerPosition(eventTarget) {
    const stage = eventTarget.getStage()
    const stagePosition = stage.getPosition()
    const pointerPosition = stage.getPointerPosition()

    const { scaleX } = stage.attrs
    const { scaleY } = stage.attrs

    return {
      x: -stagePosition.x / scaleX + pointerPosition.x / scaleX,
      y: -stagePosition.y / scaleY + pointerPosition.y / scaleY,
    }
  }

  setCurrentFrame = (frame, props) => {
    const { modelAssets, onChange } = props || this.props
    const { length } = modelAssets

    let current = frame

    if (current < 0) {
      current += length
    }

    if (current > length - 1) {
      current -= length
    }

    if (current !== this.state.current) {
      this.setState({ current })
      onChange(current)
    }
  }

  _handleReplaceImage = (id) => {
    const { imageLoadingStatus } = this.state
    imageLoadingStatus[id] = false

    this.setState({
      imageLoadingStatus: { ...imageLoadingStatus },
    })
  }

  _handleLoadImage = (id) => {
    const { imageLoadingStatus } = this.state
    imageLoadingStatus[id] = true

    this.setState({
      imageLoadingStatus: { ...imageLoadingStatus },
    })
  }

  _handleGoToPage = (pageId) => () => {
    const {
      contentViewerActions: { goToPage },
    } = this.props

    goToPage(pageId)
  }

  getAnnotationsTextByCoordinates(eventTarget) {
    const { modelAssets, annotationActions } = this.props
    const points = this.getPointerPosition(eventTarget)
    const selectedAsset = modelAssets[this.state.current]
    annotationActions.getAnnotationTextByCoordinatesRequest({
      pageId: selectedAsset.LinkedPageId,
      x: points.x,
      y: points.y,
    })
  }

  _handleClick = ({ evt: event, target: eventTarget }) => {
    this.getAnnotationsTextByCoordinates(eventTarget)
  }

  _handleWheelMove = ({ evt: event }) => {
    const { deltaY } = event
    const { current } = this.state
    const delta = deltaY === 0 ? 0 : deltaY / Math.abs(deltaY)

    this.setCurrentFrame(current + delta)
  }

  render() {
    const { modelAssets, zoomed, contentSize } = this.props

    const props = {
      zoomed,
      contentSize,
      assets: modelAssets,
      current: this.state.current,
      handleClick: this._handleClick,
      handleGoToPage: this._handleGoToPage,
      handleReplaceImage: this._handleReplaceImage,
      handleLoadImage: this._handleLoadImage,
      handleWheelMove: this._handleWheelMove,
      contentRef: this.props.contentRef,
      stageRef: this.props.stageRef,
      centerCoordinatesUTM: this.props.centerCoordinatesUTM,
    }

    const total = modelAssets.length || 1
    const loaded = _.countBy(this.state.imageLoadingStatus, (status) => status === true).true || 0

    return (
      <>
        <SpatialView {...props} />
        {total > loaded && (
          <Portal node={document.getElementsByClassName('content-viewer-container')[0]}>
            <LoadingProgressBar value={(loaded / total) * 100} text='Please wait, we are loading 360 view for you' />
          </Portal>
        )}
      </>
    )
  }
}

SpatialViewContainer.propTypes = {
  spatialViewStartPageId: PropTypes.number,
  modelAssets: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  contentViewerActions: PropTypes.shape({
    goToPage: PropTypes.func.isRequired,
    setSpatialViewStartPageId: PropTypes.func.isRequired,
    setLoadingInProgress: PropTypes.func.isRequired,
    resetLoadingInProgress: PropTypes.func.isRequired,
  }),
  contentSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  selectedGalleryItemId: PropTypes.number,
  zoomed: PropTypes.bool.isRequired,
  annotationActions: PropTypes.shape({
    getAnnotationTextByCoordinatesRequest: PropTypes.func.isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
  contentRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

SpatialViewContainer.defaultProps = {
  cycle: true,
  scroll: true,
  vertical: false,
  tabIndex: 0,
  onChange: () => {},
}

function mapStateToProps(state) {
  const selectedGalleryItemId = getSelectedGalleryItemId(state)
  const modelAssets = getAssetsForSpatialModel(state)

  const { isLoading } = state.contentViewer

  const { spatialViewStartPageId } = state.contentViewer

  const centerCoordinatesUTM = spatialModelSelectors.getCenterCoordinatesUTM(state)

  return {
    spatialViewStartPageId,
    selectedGalleryItemId,
    modelAssets,
    isLoading,
    centerCoordinatesUTM,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    contentViewerActions: bindActionCreators(contentViewerActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    annotationActions: bindActionCreators(annotationActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpatialViewContainer)
