import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(rawActions.setIsFastExcludeModeOn)](state, { payload }) {
      return payload
    },
    [rawActions.setSelectedProject](state, action) {
      return defaultState
    },
  },
  defaultState
)
