import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  isVideoPage as videoPage,
  isImagePage,
  getImageRatio,
  getThumbnailUrl,
  isPointCloudPage,
} from '@tabeeb/services/pageService'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { getIsSelectedPagesForMergingNavbarTabActive } from '@tabeeb/modules/contentSharings/selectors'
import { addPageForMerging } from '@tabeeb/modules/contentSharings/actions'

import InlineEditContainer from '../../../shared/inlineEdit'

import { ContentType } from '../../../../Enums'
import { useGalleryItemsMenuContext } from '../../hooks'

import './styles.less'

const SpinnerUrl = new URL('@tabeeb/assets/images/spinner-loader.png', import.meta.url)

const GalleryItem = ({
  itemActions,
  selectionActions,
  dragAndDropActions,
  customVideoActions,
  spatialModelActions,
  threedeeModelActions,
  sessionSharingActions,
  structureModelActions,
  sessionReviewingActions,
  towerSideProfileActions,
  twoDLineDrawingActions,
  bentleyModelActions,
  bentleyAnalyticsReportActions,
  classificationAnnotationsActions,
  splatModelActions,

  isCustomVideoOpen,
  isSpatialModelOpen,
  is3dModelOpen,
  isSessionSharingPagesListOpen,
  isSessionReviewingPagesListOpen,
  isStructureModelOpen,
  isTowerSideProfileOpen,
  is2dLineDrawingOpen,
  isBentleyModelOpen,
  isBentleyAnalyticsReportOpen,
  isClassificationAnnotationsOpen,
  isSplatModelOpen,

  isAvailable,
  isSelectedInGallery,

  listRef,
  setSelectedItemRef,

  isExpanded,

  isViewer,
  canAddPointCloudPageToBentleyAnalyticsReport,

  ...item
}) => {
  const dispatch = useDispatch()
  const isSelectedPagesForMergingOpen = useSelector(getIsSelectedPagesForMergingNavbarTabActive)

  const _handleKeyUpName = (e) => {
    e.which === 13 && e.target.blur()
  }

  const _handleSelectGalleryItem = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.shiftKey) {
      if (item.isAddedToSelection) {
        selectionActions.removeItem()
      } else if (item.canDelete) {
        selectionActions.addItem()
      }
    } else {
      itemActions.select()
    }
  }

  const _handleAddButtonClick = (e) => {
    if (isCustomVideoOpen) {
      customVideoActions.addItem(e)
    } else if (isSpatialModelOpen) {
      spatialModelActions.addItem(e)
    } else if (is3dModelOpen && !item.isAddedTo3dModel) {
      threedeeModelActions.addItem(e)
    } else if (isSessionSharingPagesListOpen) {
      sessionSharingActions.addItem(e)
    } else if (isStructureModelOpen) {
      structureModelActions.addItem(e)
    } else if (isSessionReviewingPagesListOpen) {
      sessionReviewingActions.addItem(e)
    } else if (isTowerSideProfileOpen) {
      towerSideProfileActions.addItem(e)
    } else if (is2dLineDrawingOpen) {
      twoDLineDrawingActions.addItem(e)
    } else if (isBentleyModelOpen && !item.isAddedToBentleyModel) {
      bentleyModelActions.addItem(e)
    } else if (isSelectedPagesForMergingOpen) {
      e.preventDefault()
      e.stopPropagation()
      dispatch(addPageForMerging(item))
    } else if (isBentleyAnalyticsReportOpen) {
      bentleyAnalyticsReportActions.addItem(e)
    } else if (isClassificationAnnotationsOpen) {
      classificationAnnotationsActions.addItem(e)
    } else if (isSplatModelOpen && !item.isAddedToSplatModel) {
      splatModelActions.addItem(e)
    }
  }

  const _addDefaultSrc = (e) => {
    e.target.src = SpinnerUrl
  }

  const dateCreated = new Date(item.timestampCreated)
  const addButtonClasses = classNames('gallery-item-add-button', {
    selected:
      (is3dModelOpen && item.isAddedTo3dModel) ||
      (isBentleyModelOpen && item.isAddedToBentleyModel) ||
      (isBentleyAnalyticsReportOpen && item.isAddedToBentleyAnalyticsReport) ||
      (isCustomVideoOpen && item.isAddedToCustomVideo) ||
      (isSpatialModelOpen && item.isAddedToSpatialModel) ||
      (isSessionSharingPagesListOpen && item.isAddedToSessionSharingPagesList) ||
      (isSessionReviewingPagesListOpen && item.isAddedToSessionReviewingPagesList) ||
      (isStructureModelOpen && item.isAddedToStructureModel) ||
      (isTowerSideProfileOpen && item.isAddedToTowerSideProfile) ||
      (is2dLineDrawingOpen && item.isAddedTo2dLineDrawing) ||
      (isSelectedPagesForMergingOpen && item.isAddedToContentsMerging) ||
      (isClassificationAnnotationsOpen && item.isAddedToClassificationAnnotations) ||
      (isSplatModelOpen && item.isAddedToSplatModel),
  })

  const canShowAddButton =
    !isViewer &&
    ((isCustomVideoOpen &&
      item.contentType !== ContentType.WebPage &&
      item.contentType !== ContentType.SpatialModel &&
      item.contentType !== ContentType.DocDocumentPage &&
      item.contentType !== ContentType.PptDocumentPage &&
      item.contentType !== ContentType.ExcelDocumentPage &&
      item.contentType !== ContentType.File &&
      item.contentType !== ContentType.VideoByUrl) ||
      (isSpatialModelOpen && isImagePage(item.contentType)) ||
      (is3dModelOpen && isImagePage(item.contentType)) ||
      (isBentleyModelOpen && isImagePage(item.contentType)) ||
      (isSplatModelOpen && isImagePage(item.contentType)) ||
      (isBentleyAnalyticsReportOpen &&
        isPointCloudPage(item.contentType) &&
        canAddPointCloudPageToBentleyAnalyticsReport) ||
      isSessionSharingPagesListOpen ||
      isSessionReviewingPagesListOpen ||
      isSelectedPagesForMergingOpen ||
      (isStructureModelOpen && isImagePage(item.contentType)) ||
      (isTowerSideProfileOpen &&
        (item.contentType === ContentType.StructureModel ||
          item.contentType === ContentType.StructureModelPdf ||
          item.contentType === ContentType.WebPage)) ||
      (is2dLineDrawingOpen && item.contentType === ContentType.WebPage) ||
      (isClassificationAnnotationsOpen && isImagePage(item.contentType)))

  const canShowDeleteButton =
    item.canDelete &&
    !isCustomVideoOpen &&
    !isSpatialModelOpen &&
    !is3dModelOpen &&
    !isBentleyModelOpen &&
    !isBentleyAnalyticsReportOpen &&
    !isSessionSharingPagesListOpen &&
    !isStructureModelOpen &&
    !isSessionReviewingPagesListOpen &&
    !isTowerSideProfileOpen &&
    !is2dLineDrawingOpen &&
    !isSelectedPagesForMergingOpen &&
    !isClassificationAnnotationsOpen &&
    !isSplatModelOpen

  const isVideoPage = videoPage(item.contentType)

  const { onOpen } = useGalleryItemsMenuContext()

  const _handleContextMenuOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.shiftKey) {
      return
    }

    if (canShowDeleteButton) {
      selectionActions.addItem(item.id)

      onOpen(e)
    }
  }

  return (
    <li
      className={classNames('gallery-item', { selected: isSelectedInGallery }, { expanded: isExpanded })}
      onClick={_handleSelectGalleryItem}
      onContextMenu={_handleContextMenuOpen}
      ref={isSelectedInGallery ? setSelectedItemRef : null}
    >
      <figure
        className={classNames({ 'added-to-selection': item.isAddedToSelection }, { expanded: isExpanded })}
        draggable={canShowDeleteButton}
        {...dragAndDropActions}
      >
        <p className={classNames('figure-content', { 'not-available': !isAvailable }, { expanded: isExpanded })}>
          <span className='gallery-item-page'>
            <i>{item.index + 1}</i>
          </span>
          {canShowDeleteButton && (
            <span className='gallery-item-del-button' onClick={itemActions.delete}>
              <CancelIcon color='inherit' fontSize='inherit' />
            </span>
          )}
          {canShowAddButton && (
            <span className={addButtonClasses} onClick={_handleAddButtonClick}>
              <AddCircleIcon color='inherit' fontSize='inherit' />
            </span>
          )}
          {isVideoPage && <i className='play-button' />}
          <img
            className={classNames({ loaded: item.isThumbnailLoaded })}
            draggable='false'
            src={getThumbnailUrl(item)}
            alt={item.thumbnailCaption}
            onLoad={itemActions.loaded}
            onError={_addDefaultSrc}
          />
          <span className='image-placeholder' style={{ paddingTop: `${100 * getImageRatio(item)}%` }} />
        </p>
        <figcaption className={classNames({ expanded: isExpanded })}>
          <InlineEditContainer
            classNameSpan={classNames('gallery-item-span', { expanded: isExpanded })}
            disabled={!item.canRename}
            value={item.thumbnailCaption}
            title={item.thumbnailCaption}
            handleKeyDown={_handleKeyUpName}
            handleBlurInput={itemActions.rename}
            validateBlur={itemActions.validate}
          />
          {isExpanded ? (
            <time dateTime={dateCreated.toISOString()}>
              by {item.ownerUserName} - Created: {dateCreated.toDateString()}
            </time>
          ) : (
            <time dateTime={dateCreated.toISOString()}>{dateCreated.toDateString()}</time>
          )}
        </figcaption>
      </figure>
    </li>
  )
}

GalleryItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    thumbnailCaption: PropTypes.string.isRequired,
    timestampCreated: PropTypes.number.isRequired,
    contentType: PropTypes.number.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    isThumbnailLoaded: PropTypes.bool.isRequired,

    isAddedToSelection: PropTypes.bool.isRequired,
    isAddedTo3dModel: PropTypes.bool.isRequired,
    isAddedToSpatialModel: PropTypes.bool.isRequired,
    isAddedToCustomVideo: PropTypes.bool.isRequired,
    isAddedToSessionSharingPages: PropTypes.bool.isRequired,
    isAddedToStructureModel: PropTypes.bool.isRequired,
    isAddedToBentleyModel: PropTypes.bool.isRequired,
    isAddedToBentleyAnalyticsReport: PropTypes.bool.isRequired,
    isAddedToClassificationAnnotations: PropTypes.bool.isRequired,

    canRename: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
  }),
  itemActions: PropTypes.shape({
    loaded: PropTypes.func.isRequired,
    rename: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    validate: PropTypes.func,
  }).isRequired,
  isCustomVideoOpen: PropTypes.bool.isRequired,
  customVideoActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  isSpatialModelOpen: PropTypes.bool.isRequired,
  spatialModelActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  is3dModelOpen: PropTypes.bool.isRequired,
  threedeeModelActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  isBentleyModelOpen: PropTypes.bool.isRequired,
  bentleyModelActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  isBentleyAnalyticsReportOpen: PropTypes.bool.isRequired,
  bentleyAnalyticsReportActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  dragAndDropActions: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
  selectionActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
  }),
  isSessionSharingPagesListOpen: PropTypes.bool.isRequired,
  sessionSharingActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  isStructureModelOpen: PropTypes.bool.isRequired,
  structureModelActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  isSessionReviewingPagesListOpen: PropTypes.bool.isRequired,
  sessionReviewingActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  isTowerSideProfileOpen: PropTypes.bool.isRequired,
  towerSideProfileActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  is2dLineDrawingOpen: PropTypes.bool.isRequired,
  twoDLineDrawingActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  isClassificationAnnotationsOpen: PropTypes.bool.isRequired,
  classificationAnnotationsActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  splatModelActions: PropTypes.shape({
    addItem: PropTypes.func.isRequired,
  }),
  isSelectedInGallery: PropTypes.bool.isRequired,
  isAvailable: PropTypes.bool,
  listRef: PropTypes.object,
  setSelectedItemRef: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isViewer: PropTypes.bool.isRequired,
  isSplatModelOpen: PropTypes.bool.isRequired,
}

export default memo(GalleryItem)
