import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getContentTrainingMaterialUsers } from '../actions'

const EMPTY_LIST = []

export default ({ enabled, id, onError }) => {
  const payload = useMemo(() => ({ id }), [id])

  const {
    loading,
    response: users,
    onMutated: onReload,
  } = useApiRequest({
    request: getContentTrainingMaterialUsers.request,
    defaultResponse: EMPTY_LIST,
    enabled,
    payload,
    onError,
  })

  return { loading, users, onReload }
}
