import { createAction } from 'redux-actions'

import { createApiActions } from '@tabeeb/shared/utils/actions'

export const getContract = createApiActions('GET_CONTRACT')
export const getContracts = createApiActions('GET_CONTRACTS')
export const getContractVersion = createApiActions('GET_CONTRACT_VERSION')
export const getContractVersions = createApiActions('GET_CONTRACT_VERSIONS')

export const approveContractVersion = createApiActions('APPROVE_CONTRACT_VERSION')
export const createContractVersion = createApiActions('CREATE_CONTRACT_VERSION')
export const rejectContractVersion = createApiActions('REJECT_CONTRACT_VERSION')
export const updateContractVersion = createApiActions('UPDATE_CONTRACT_VERSION')

export const createContract = createApiActions('CREATE_CONTRACT')
export const deleteContract = createApiActions('DELETE_CONTRACT')
export const revokeContract = createApiActions('REVOKE_CONTRACT')

export const getUsersContractorTenants = createApiActions('GET_USERS_CONTRACTOR_TENANTS')

export const resetState = createAction('RESET_CONTRACTORS_STATE')
