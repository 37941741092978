import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import routes from '@tabeeb/routes'
import { TenantPermission } from '@tabeeb/enums'

import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import NavbarTab from '../NavbarTab'

const DashboardTab = ({ hasCreateReportsAccess, hasAllSessionsAccess, hasAllFormsViewAccess, ...rest }) => {
  const routesDictionary = {
    [routes.tenantSessions]: hasAllSessionsAccess,
    [routes.powerBIReports]: true,
    [routes.allForms]: hasAllFormsViewAccess,
    [routes.generatedReports]: hasCreateReportsAccess,
  }

  const route = Object.keys(routesDictionary).find((i) => routesDictionary[i] === true)

  const props = {
    item: {
      label: 'Reports',
      to: route,
    },
    display: true,
    ...rest,
  }

  return <NavbarTab {...props} />
}

DashboardTab.propTypes = {
  hasCreateReportsAccess: PropTypes.bool.isRequired,
  hasAllSessionsAccess: PropTypes.bool.isRequired,
  hasAllFormsViewAccess: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    hasCreateReportsAccess: hasTenantPermission(state, TenantPermission.CreateReports),
    hasAllSessionsAccess: hasTenantPermission(state, TenantPermission.AllSessionsAccess),
    hasAllFormsViewAccess: hasTenantPermission(state, TenantPermission.AllFormsView),
  }
}

export default connect(mapStateToProps, null)(DashboardTab)
