import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getFormTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import * as formsActions from '../actions'

import { ConfirmationDialog } from '@tabeeb/uikit'

class SwitchFormDialogContainer extends Component {
  _switchForm = (e) => {
    const {
      actions: { openForm, closeSwitchFormDialog },
      lastAttachedForm,
      contentForms,
    } = this.props
    const form = contentForms.find((contentForm) => contentForm.FormId === lastAttachedForm.formId)
    form && openForm(form.Id)
    closeSwitchFormDialog()
  }

  render() {
    const {
      actions: { closeSwitchFormDialog },
      isSwitchFormDialogOpen,
      formTitle,
    } = this.props
    const title = `Do you want to switch to the attached ${formTitle.toLowerCase()}?`
    const props = {
      onCloseDialog: closeSwitchFormDialog,
      onSubmit: this._switchForm,
      isOpen: isSwitchFormDialogOpen,
      title,
      cancelButtonText: 'No',
      submitButtonText: 'Yes',
    }
    return <ConfirmationDialog {...props} />
  }
}

SwitchFormDialogContainer.propTypes = {
  isSwitchFormDialogOpen: PropTypes.bool.isRequired,
  lastAttachedForm: PropTypes.object.isRequired,
  contentForms: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    openForm: PropTypes.func.isRequired,
    closeSwitchFormDialog: PropTypes.func.isRequired,
  }).isRequired,
  formTitle: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  isSwitchFormDialogOpen: state.forms.isSwitchFormDialogOpen,
  lastAttachedForm: state.forms.lastAttachedForm,
  contentForms: state.forms.contentForms,
  formTitle: getFormTitleLowerCase(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(formsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SwitchFormDialogContainer)
