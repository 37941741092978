import { AnswerStatuses } from '@tabeeb/enums'

export const AnswerStatusesDisplayName = {
  [AnswerStatuses.Pending]: 'Submitted',
  [AnswerStatuses.Approve]: 'Approved',
  [AnswerStatuses.Reject]: 'Rejected',
  [AnswerStatuses.NoAction]: 'No action',
  [AnswerStatuses.ApprovedByAI]: 'Approved by AI',
  [AnswerStatuses.RejectedByAI]: 'Rejected by AI',
  [AnswerStatuses.ApprovedByReviewer]: 'Approved by a reviewer',
  [AnswerStatuses.RejectedByReviewer]: 'Rejected by a reviewer',
}
