import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPowerBIReportModalTenantState } from '@tabeeb/modules/powerBIReports/selectors'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { Add, Close, Save } from '@mui/icons-material'
import {
  closePowerBIReportTenantModal,
  createPowerBIReportTenant,
  updatePowerBIReportTenant,
} from '@tabeeb/modules/powerBIReports/actions'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { FetchStatus } from '@tabeeb/enums'
import usePowerBIReportTenantRoles from '@tabeeb/modules/powerBIReports/hooks/usePowerBIReportTenantRoles'
import UserTenantsSelect from '@tabeeb/modules/shared/components/UserTenantsSelect'
import { getCurrentUserGuid } from '@tabeeb/modules/account/selectors'
import { getTenantRoleDisplayName } from '@tabeeb/modules/appConfigState/selectors'
import { requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'
import PowerBIReportTenantRoleSelect from './PowerBIReportTenantRoleSelect'

const validationSchema = Yup.object({
  reportId: Yup.number().required().min(1),
  tenantId: Yup.number().required().min(1, requiredField),
  securityModels: Yup.array().of(Yup.number()).min(1, requiredField),
  tenantRoles: Yup.array().of(Yup.string()).min(1, requiredField),
})

const PowerBIReportTenantModal = () => {
  const dispatch = useDispatch()

  const { open, report, fetchStatus, reportTenant, isEditMode } = useSelector(getPowerBIReportModalTenantState)
  const currentUserId = useSelector(getCurrentUserGuid)

  const { fetchStatus: getRolesFetchStatus, roles } = usePowerBIReportTenantRoles(reportTenant.Id, isEditMode)

  const loading = useMemo(() => {
    return fetchStatus === FetchStatus.Loading || getRolesFetchStatus === FetchStatus.Loading
  }, [fetchStatus, getRolesFetchStatus])

  const handleClose = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') {
        return
      }
      dispatch(closePowerBIReportTenantModal())
    },
    [dispatch]
  )

  useEffect(() => {
    if (fetchStatus === FetchStatus.Loaded) {
      handleClose()
    }
  }, [fetchStatus, handleClose])

  const onSubmit = useCallback(
    (values) => {
      if (isEditMode) {
        const requestPayload = {
          tenantRoles: values.tenantRoles,
        }
        dispatch(updatePowerBIReportTenant.request({ id: reportTenant.Id, data: requestPayload }))
      } else {
        const requestPayload = {
          reportId: values.reportId,
          tenantId: values.tenantId,
          securityModels: values.securityModels,
          tenantRoles: values.tenantRoles,
        }
        dispatch(createPowerBIReportTenant.request(requestPayload))
      }
    },
    [dispatch, isEditMode, reportTenant.Id]
  )

  const form = useFormik({
    initialValues: {
      reportId: report.Id,
      tenantId: isEditMode ? reportTenant.TenantId : 0,
      securityModels: [report.SecurityModel.Id],
      tenantRoles: isEditMode ? roles : [],
    },
    onSubmit,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  })

  const onTenantChange = useCallback(
    (_event, value, reason) => {
      if (reason === 'selectOption') {
        form.setValues((values) => ({ ...values, tenantId: value.tenantId }))
      } else {
        form.setValues((values) => ({ ...values, tenantId: 0 }))
      }
    },
    [form]
  )

  const onTenantRolesChange = useCallback(
    (_event, value) => {
      form.setValues((values) => ({ ...values, tenantRoles: value.map((option) => option.role) }))
    },
    [form]
  )

  const submitButtonStartIcon = useMemo(() => {
    if (loading) {
      return <CircularProgress size={18} />
    }
    return isEditMode ? <Save color='primary' /> : <Add color='primary' />
  }, [isEditMode, loading])

  const selectedTenantRoles = useSelector((state) =>
    form.values.tenantRoles.map((role) => ({ role, label: getTenantRoleDisplayName(state, { Role: role }) }))
  )

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>{isEditMode ? 'Edit' : 'Add'} PowerBI report tenant</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 12,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Grid container component='form' autoComplete='off' flexDirection='column' spacing={1}>
          <Grid item xs={12}>
            {isEditMode ? (
              <Typography>Tenant: {reportTenant.TenantName}</Typography>
            ) : (
              <UserTenantsSelect
                userId={currentUserId}
                disabled={loading}
                onChange={onTenantChange}
                helperText={form.errors.tenantId}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <PowerBIReportTenantRoleSelect
              value={selectedTenantRoles}
              disabled={loading}
              onChange={onTenantRolesChange}
              helperText={form.errors.tenantId}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          color='primary'
          startIcon={submitButtonStartIcon}
          disabled={!form.isValid || !form.dirty || loading}
          onClick={form.handleSubmit}
        >
          {isEditMode ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PowerBIReportTenantModal
