import { useCallback } from 'react'

import { importSheets } from '@tabeeb/modules/contentMetadata/actions'
import { onAddErrorNotification, onAddInfoNotification } from '@tabeeb/modules/notification/actions'
import { callAPIPromise } from '@tabeeb/modules/shared/utils/requests'

const useImportSheets = (contentId, dispatch, sheetsToImport, sheetsImportMap, workbook, closeDialog) => {
  return useCallback(
    (assetUrl) => {
      if (!assetUrl) return

      const payload = {
        ContentId: contentId,
        FileUrl: assetUrl,
        Sheets: sheetsToImport.map((sheetIndex) => ({
          Index: sheetIndex + 1,
          Name: sheetsImportMap[sheetIndex]?.customName || workbook?.SheetNames[sheetIndex],
        })),
      }

      const apiCall = callAPIPromise(importSheets.request(payload))
      apiCall
        .then(() => {
          dispatch(onAddInfoNotification({ message: 'Import started' }))
          closeDialog()
        })
        .catch(() => {
          dispatch(onAddErrorNotification({ message: 'Failed to import' }))
        })
    },
    [contentId, dispatch, sheetsToImport, sheetsImportMap, workbook, closeDialog]
  )
}

export default useImportSheets
