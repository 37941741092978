import { makeStyles } from '@material-ui/core'

export default makeStyles(() => {
  return {
    container: {
      position: 'absolute',
      cursor: 'crosshair',
      top: 0,
      left: 0,
      zIndex: 1,
    },
  }
})
