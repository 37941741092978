import { combineReducers } from 'redux'

import { persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

import configuration from './configuration'
import dialogs from './dialogs'
import filtration from './filtration/index.js'
import sorting from './sorting'
import isNeedPermissionToActivate from './isNeedPermissionToActivate'
import isSessionCreationInProgress from './isSessionCreationInProgress'
import foldersList from './foldersList'
import foundUsersList from './foundUsersList'
import currentFolder from './currentFolder'
import isTrashBinOpen from './isTrashBinOpen'
import selectedTenantForSharing from './selectedTenantForSharing'
import selectedOwnerForSharing from './selectedOwnerForSharing'
import sessionsList from './sessionsList'
import isDataLoading from './isDataLoading'
import pagination from './pagination'
import storages from './storages'
import selectedStorage from './selectedStorage'
import activateSessionDialog from './activateSessionDialog'
import targetContentSearchResult from './targetContentSearchResult'
import selectedTargetContentForMerging from './selectedTargetContentForMerging'
import targetContentSearchValue from './targetContentSearchValue'
import layout from './layout'
import progressDialog from './progressDialog'
import mode from './mode'
import currentFolderId from './currentFolderId'
import selectedSessionsList from './selectedSessionsList'
import usersSearchInputValue from './usersSearchInputValue'

export default persistReducer(
  {
    key: 'sessionsPage',
    storage,
    whitelist: ['layout'],
  },
  combineReducers({
    configuration,
    dialogs,
    filtration,
    sorting,
    isNeedPermissionToActivate,
    isSessionCreationInProgress,
    foldersList,
    foundUsersList,
    currentFolder,
    isTrashBinOpen,
    selectedSessionsList,
    selectedTenantForSharing,
    selectedOwnerForSharing,
    sessionsList,
    isDataLoading,
    pagination,
    storages,
    selectedStorage,
    activateSessionDialog,
    targetContentSearchResult,
    selectedTargetContentForMerging,
    targetContentSearchValue,
    layout,
    progressDialog,
    mode,
    currentFolderId,
    usersSearchInputValue,
  })
)
