import { handleActions } from 'redux-actions'
import _ from 'lodash'

import { TopicType } from '@tabeeb/enums'

import * as chatActions from '../actions'
import { defaultChatState } from '../constans'
import { getChatId } from '../services/chat'

const defaultState = {}

function getChatByUserId(state, topicType, topicId, userId) {
  return state[getChatId({ userId, topicId, topicType })] || defaultChatState
}

function updateChatForUser(state, topicType, topicId, userId, chat) {
  return {
    ...state,
    [getChatId({ userId, topicId, topicType })]: chat,
  }
}

export default handleActions(
  {
    [chatActions.addChatMessage](state, action) {
      const { topicType, topicId, userId = null, message } = action.payload

      const chat = getChatByUserId(state, topicType, topicId, userId)

      const containsMessage = _.some(chat.messages, (msg) => msg.id === message.id)
      if (containsMessage) {
        return state
      }

      const updatedMessages = _.orderBy([...chat.messages, message], (message) => message.timeStamp)

      return updateChatForUser(state, topicType, topicId, userId, {
        ...chat,
        lastMessageDate: _.last(updatedMessages)?.timeStamp || chat.lastMessageDate,
        unreadMessagesCount: message.unread ? chat.unreadMessagesCount + 1 : chat.unreadMessagesCount,
        messages: updatedMessages,
      })
    },
    [chatActions.updateChatMessage](state, action) {
      const { topicType, topicId, userId = null, message: updatedMessage } = action.payload

      const chat = getChatByUserId(state, topicType, topicId, userId)

      return updateChatForUser(state, topicType, topicId, userId, {
        ...chat,
        messages: chat.messages.map((message) => {
          if (message.id === updatedMessage.id) {
            return {
              ...message,
              ...updatedMessage,
              translations: {
                ...message.translations,
                ...updatedMessage.translations,
              },
            }
          }

          return message
        }),
      })
    },
    [chatActions.getChatsSuccess](state, action) {
      const chats = action.response.data

      const { contentId: topicId } = action.payload
      const topicType = TopicType.Session

      let newState = state
      for (const chatData of chats) {
        const {
          UserId: userId,
          LastMessageDate: lastMessageDate,
          LastReadedMessageId: lastReadedMessageId,
          UnreadedMessagesCount: unreadMessagesCount,
        } = chatData

        const chat = getChatByUserId(state, topicType, topicId, userId)

        newState = updateChatForUser(newState, topicType, topicId, userId, {
          ...chat,
          lastMessageDate,
          lastReadedMessageId,
          unreadMessagesCount,
        })
      }

      return newState
    },
    [chatActions.setChatMessages](state, action) {
      const { topicType, topicId, userId = null, messages } = action.payload

      const chat = getChatByUserId(state, topicType, topicId, userId)

      const updatedMessages = _.orderBy(
        _.uniqBy(messages.concat(chat.messages), (message) => message.id),
        (message) => message.timeStamp
      )

      return updateChatForUser(state, topicType, topicId, userId, {
        ...chat,
        lastMessageDate: _.last(updatedMessages)?.timeStamp || chat.lastMessageDate,
        messages: updatedMessages,
      })
    },
    [chatActions.markAsReadRequest](state, action) {
      const { topicType, topicId, userId = null, messageId } = action.payload

      const chat = getChatByUserId(state, topicType, topicId, userId)

      const readedMessagesCount = _.countBy(chat.messages, (message) => message.id > chat.lastReadedMessageId).true

      return updateChatForUser(state, topicType, topicId, userId, {
        ...chat,
        messages: chat.messages.map((message) => (message.unread ? { ...message, unread: false } : message)),
        unreadMessagesCount: Math.max(0, chat.unreadMessagesCount - readedMessagesCount),
        lastReadedMessageId: messageId,
      })
    },
    [chatActions.setChatFullyLoaded](state, action) {
      const { topicType, topicId, userId = null } = action.payload

      const chat = getChatByUserId(state, topicType, topicId, userId)

      return updateChatForUser(state, topicType, topicId, userId, {
        ...chat,
        isFullyLoaded: true,
      })
    },
    [chatActions.getChatMessagesByContentIdRequest](state, action) {
      const { topicType, topicId, userId = null } = action.payload

      const chat = getChatByUserId(state, topicType, topicId, userId)

      return updateChatForUser(state, topicType, topicId, userId, {
        ...chat,
        isInitialized: true,
        isLoading: true,
      })
    },
    [chatActions.getChatMessagesByContentIdSuccess](state, action) {
      const { topicType, topicId, userId = null } = action.payload

      const chat = getChatByUserId(state, topicType, topicId, userId)

      return updateChatForUser(state, topicType, topicId, userId, {
        ...chat,
        isLoading: false,
      })
    },
    [chatActions.resetChatState](state, action) {
      return defaultState
    },
  },
  defaultState
)
