import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = 0

export default handleActions(
  {
    [rawActions.setSelectedIterationId](state, action) {
      return action.payload
    },
    [combineActions(rawActions.resetSelectedIterationId, rawActions.setSelectedProject)](state, action) {
      return defaultState
    },
  },
  defaultState
)
