import { handleActions } from 'redux-actions'
import { setFeatures, resetState } from '../../actions'

const defaultValue = []

export default handleActions(
  {
    [setFeatures](_, { payload: features }) {
      return features
    },
    [resetState]() {
      return defaultValue
    },
  },
  defaultValue
)
