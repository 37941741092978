import { put, takeLatest, all } from 'redux-saga/effects'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import getErrorMessageFromResponse from '../../shared/utils/getErrorMessageFromResponse'

function* publishSessionSuccess(action) {
  const { isRepublish } = action.payload
  const messageText = isRepublish ? 're-published' : 'published'
  yield put(
    notificationActions.onAddInfoNotification({ message: sessionTitleFormatter.format(`Session is ${messageText}.`) })
  )
}

function* publishSessionFailed({ response }) {
  const message = getErrorMessageFromResponse(response.data)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* unpublishSessionSuccess(action) {
  yield put(
    notificationActions.onAddInfoNotification({ message: sessionTitleFormatter.format(`Session is unpublished.`) })
  )
}

function* publishSession() {
  yield all([
    takeLatest(rawActions.publishSession.success, publishSessionSuccess),
    takeLatest(rawActions.publishSession.failed, publishSessionFailed),
    takeLatest(rawActions.unpublishSession.success, unpublishSessionSuccess),
  ])
}

export default publishSession
