import { useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'

import useUsersQualifications from '@tabeeb/modules/qualifications/hooks/useUsersQualifications'
import UserInfoWithQualifications from '@tabeeb/modules/shared/components/UserInfoWithQualifications'
import { UserInfo } from '@tabeeb/uikit'
import UsersChip from '@tabeeb/modules/invites/components/UsersMultiSelect/UserChip'
import { getQualificationsForInvitesEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { useUsers } from '../../../../users/hooks'

const UsersMultiSelectWithQualifications = ({ field, contentId, disabled, displayValues, endAdornment, strict }) => {
  const qualificationsForInvitesEnabled = useSelector(getQualificationsForInvitesEnabled)
  const [text, setText] = useState('')
  const { setFieldValue, values: formikValues } = useFormikContext()
  const requiredQualificationIds = useMemo(() => {
    return Array.isArray(formikValues.Qualifications) && formikValues.Qualifications.length > 0
      ? formikValues.Qualifications.map((_) => _.Id)
      : null
  }, [formikValues.Qualifications])

  const { users } = useUsers({
    contentId,
    search: text,
  })

  const { loadingQualifications, usersQualifications } = useUsersQualifications({ users, requiredQualificationIds })

  const onInputChange = (event, value) => {
    setText(value || '')
  }

  const onChange = (_, values, reason, { option: email }) => {
    if (reason === 'createOption') {
      if (values.some((value) => value.Email === email)) {
        values.pop()
      } else {
        // eslint-disable-next-line no-param-reassign
        values[values.length - 1] = { Email: email }
      }
    }

    setFieldValue(field.name, values)
    setText('')
  }

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      freeSolo={!strict}
      fullWidth
      size='small'
      onKeyDown={(event) => {
        if (event.key === ' ' && !strict) {
          event.preventDefault()

          if (event.target.value === '') {
            return
          }

          const option = { option: event.target.value }
          onChange(event, [...field.value, option], 'createOption', option)
        }
      }}
      inputValue={text}
      clearOnEscape
      value={field.value}
      options={users}
      filterOptions={(options) => {
        return options.filter((option) => !field.value.some((user) => user.Email === option.Email))
      }}
      filterSelectedOptions
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.Email}
      renderOption={(props, option) => (
        <li {...props} key={option.Email}>
          {qualificationsForInvitesEnabled ? (
            <UserInfoWithQualifications
              item={option}
              requiredQualificationIds={requiredQualificationIds}
              usersQualifications={usersQualifications}
              loadingQualifications={loadingQualifications}
            />
          ) : (
            <UserInfo item={option} />
          )}
        </li>
      )}
      renderTags={(value, getTagProps) => {
        if (!displayValues) {
          return null
        }

        return value.map((user, index) => (
          <UsersChip key={user.Email} user={user} index={index} getTagProps={getTagProps} />
        ))
      }}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
          }}
          style={{ minWidth: 100 }}
          InputProps={{ ...params.InputProps, endAdornment, style: { paddingRight: 6 } }}
          placeholder={!displayValues || field.value.length === 0 ? 'Search name or email...' : undefined}
        />
      )}
    />
  )
}

UsersMultiSelectWithQualifications.defaultProps = {
  disabled: false,
  displayValues: true,
  endAdornment: null,
  strict: false,
}

UsersMultiSelectWithQualifications.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        Email: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  contentId: PropTypes.number,
  disabled: PropTypes.bool,
  displayValues: PropTypes.bool,
  endAdornment: PropTypes.element,
  strict: PropTypes.bool,
}

export default UsersMultiSelectWithQualifications
