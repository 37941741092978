import { combineReducers } from 'redux'
import flashValue from './flashValue'
import flashInProgress from './flashInProgress'
import snapshotInProgress from './snapshotInProgress'
import muteAudioInProgress from './muteAudioInProgress'
import muteVideoInProgress from './muteVideoInProgress'

export default combineReducers({
  flashValue,
  flashInProgress,
  snapshotInProgress,
  muteAudioInProgress,
  muteVideoInProgress,
})
