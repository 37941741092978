const activitiesRequests = {
  getActivitiesByContentIdRequest: ({ contentId, activitiesSkip, activitiesTake, filterOptions }) => ({
    url: `api/annotation/getAnnotationsByContentId`,
    method: 'get',
    params: {
      contentId,
      activitiesSkip,
      activitiesTake,
      pageId: filterOptions.PageId,
      userId: filterOptions.UserId,
      type: filterOptions.Type,
      searchQuery: filterOptions.SearchQuery,
    },
  }),
}

export default activitiesRequests
