import { memo } from 'react'

import useStyles from './styles'

const RecordingProgressBar = () => {
  const classes = useStyles()

  return <section className={classes.bar} />
}

export default memo(RecordingProgressBar)
