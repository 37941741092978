import PropTypes from 'prop-types'

import './styles.less'

const SearchFilter = ({ searchFilter, handleChangeSearchFilter, isDisabled }) => {
  return (
    <div className='searchActivitiesFilter'>
      <input
        disabled={isDisabled}
        type='text'
        maxLength='100'
        className='searchActivities'
        placeholder='Search Activities'
        onChange={handleChangeSearchFilter}
        value={searchFilter}
      />
    </div>
  )
}

SearchFilter.propTypes = {
  searchFilter: PropTypes.string.isRequired,
  handleChangeSearchFilter: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default SearchFilter
