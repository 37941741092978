import { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { Typography } from '@material-ui/core'

const SentDate = ({ date }) => {
  return <Typography variant='caption'>{moment(date).format('HH:mm')}</Typography>
}

SentDate.propTypes = {
  date: PropTypes.string.isRequired,
}

export default memo(SentDate)
