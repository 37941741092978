import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      maxWidth: '100%',
      padding: theme.spacing(0.5),
      paddingTop: theme.spacing(),
    },
    list: {
      display: 'flex',
      alignItems: 'center',
    },
  }
})
