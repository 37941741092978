export default (theme) => {
  return {
    popper: {
      minWidth: 120,
    },
    listbox: {
      maxHeight: 200,
      overflow: 'auto',
    },
    root: {
      padding: 0,
      maxWidth: 80,
      height: '100%',

      '& .MuiFormControl-root': {
        height: '100%',
      },

      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
        padding: 0,
        height: '100%',
      },
    },
  }
}
