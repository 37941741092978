import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultValue = false

export default handleActions(
  {
    [combineActions(rawActions.setSessionListLoading)]() {
      return true
    },
    [combineActions(rawActions.getSessions.success, rawActions.getSessions.failed)]() {
      return false
    },
    [rawActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
