import PropTypes from 'prop-types'
import classNames from 'classnames'

import TimelineList from '../TimelineList'
import TimelineButtons from '../TimelineButtons'
import ShareSessionByFormTimelineList from '../ShareSessionByFormTimelineList'

import './styles.less'

const Timeline = ({
  isOpen,
  isSelected,
  actions,
  timeLineList,
  handleDeleteItem,
  handleUpdateList,
  isShareByFormTimeline,
  contentForms,
  isForReview,
}) => {
  return (
    <div
      className={classNames('timeline-container', {
        closed: !isOpen || !isSelected,
      })}
    >
      <TimelineButtons actions={actions} />
      {isShareByFormTimeline ? (
        <ShareSessionByFormTimelineList
          timeLineList={timeLineList}
          handleDeleteItem={handleDeleteItem}
          contentForms={contentForms}
          isForReview={isForReview}
        />
      ) : (
        <TimelineList
          timeLineList={timeLineList}
          handleDeleteItem={handleDeleteItem}
          handleUpdateList={handleUpdateList}
        />
      )}
    </div>
  )
}

Timeline.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
    })
  ),
  timeLineList: PropTypes.any,
  handleDeleteItem: PropTypes.func.isRequired,
  handleUpdateList: PropTypes.func,
  isShareByFormTimeline: PropTypes.bool,
  contentForms: PropTypes.array,
  isForReview: PropTypes.bool,
}

export default Timeline
