import { createApiActions } from '@tabeeb/shared/utils/actions'

export const createSystemAnnouncement = createApiActions('CREATE_SYSTEM_ANNOUNCEMENT')
export const createTenantAnnouncement = createApiActions('CREATE_TENANT_ANNOUNCEMENT')
export const deleteAnnouncement = createApiActions('DELETE_ANNOUNCEMENT')
export const updateAnnouncement = createApiActions('UPDATE_ANNOUNCEMENT')
export const updateAnnouncementStatus = createApiActions('UPDATE_ANNOUNCEMENT_STATUS')
export const updateAnnouncementSeverity = createApiActions('UPDATE_ANNOUNCEMENT_SEVERITY')
export const getAnnouncement = createApiActions('GET_ANNOUNCEMENT')
export const getAnnouncements = createApiActions('GET_ANNOUNCEMENTS')
export const getUnreadAnnouncements = createApiActions('GET_UNREAD_ANNOUNCEMENTS')
export const markAnnouncementsAsRead = createApiActions('MARK_ANNOUNCEMENTS_AS_READ')

export const getTrainingMaterial = createApiActions('GET_TRAINING_MATERIAL')
export const getTrainingMaterials = createApiActions('GET_TRAINING_MATERIALS')
export const updateTrainingMaterial = createApiActions('UPDATE_TRAINING_MATERIAL')
export const deleteTrainingMaterial = createApiActions('DELETE_TRAINING_MATERIAL')
export const createTrainingMaterial = createApiActions('CREATE_TRAINING_MATERIAL')

export const createContentTrainingMaterial = createApiActions('CREATE_CONTENT_TRAINING_MATERIAL')
export const deleteContentTrainingMaterial = createApiActions('DELETE_CONTENT_TRAINING_MATERIAL')
export const getContentTrainingMaterial = createApiActions('GET_CONTENT_TRAINING_MATERIAL')
export const getContentTrainingMaterials = createApiActions('GET_CONTENT_TRAINING_MATERIALS')

export const getUnreadContentTrainingMaterialsCount = createApiActions('GET_UNREAD_CONTENT_TRAINING_MATERIALS_COUNT')
export const markContentTrainingMaterialAsRead = createApiActions('MARK_CONTENT_TRAINING_MATERIAL_AS_READ')

export const assignContentTrainingMaterialToUsers = createApiActions('ASSIGN_CONTENT_TRAINING_MATERIAL_TO_USERS')
export const getContentTrainingMaterialUsers = createApiActions('GET_CONTENT_TRAINING_MATERIAL_USERS')
