import { put, takeLatest, select, all } from 'redux-saga/effects'

import { galleryActions } from '@tabeeb/modules/gallery'
import { notificationActions } from '@tabeeb/modules/notification'

import { isImagePage } from '@tabeeb/services/pageService'
import * as rawActions from '../actions'

function* onAddFolderToStructureModel({ payload: { folderId } }) {
  const galleryList = yield select((state) => state.gallery.galleryList)

  const pages = galleryList.filter((item) => item.folderId === folderId && isImagePage(item.contentType))

  if (pages.length < 1) {
    yield put(
      notificationActions.onAddWarningNotification({
        message: 'Folder is empty!',
      })
    )

    return
  }

  yield put(rawActions.addAllPagesToStructureModel(pages))
}

function* getNotLoadedPages() {
  yield all([takeLatest(galleryActions.addFolderToStructureModel, onAddFolderToStructureModel)])
}

export default getNotLoadedPages
