import { combineReducers } from 'redux'

import isMetadataOpen from './isMetadataOpen'
import metadata from './metadata'
import metadataTabs from './metadataTabs'
import isMetadataLoading from './isMetadataLoading'
import areTabsLoading from './areTabsLoading'
import isExportModeEnabled from './isExportModeEnabled'
import metadataTabsForExport from './metadataTabsForExport'
import redoActions from './redoActions'
import undoActions from './undoActions'
import getIsSheetNameUnique from './getIsSheetNameUnique'

export default combineReducers({
  isMetadataOpen,
  metadataTabs,
  metadata,
  isMetadataLoading,
  areTabsLoading,
  isExportModeEnabled,
  metadataTabsForExport,
  redoActions,
  undoActions,
  getIsSheetNameUnique,
})
