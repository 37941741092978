import PropTypes from 'prop-types'
import { FormControl, FormHelperText, FormControlLabel, Checkbox } from '@material-ui/core'

const FormikCheckbox = ({ field, form, label, labelProps = {}, ...props }) => {
  const { errors, touched } = form
  const error = touched[field.name] && errors[field.name]

  return (
    <FormControl error={!!error}>
      <FormControlLabel
        {...labelProps}
        control={<Checkbox {...field} {...props} checked={field.value} />}
        label={label}
      />
      {error && <FormHelperText style={{ marginTop: '-4px' }}>{error}</FormHelperText>}
    </FormControl>
  )
}

FormikCheckbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
  }).isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.node,
}

export default FormikCheckbox
