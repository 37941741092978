import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { IconButton } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'

import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import NavigationLink from '../NavigationLink'
import NavigationMenu from '../NavigationMenu'

const NavigationItem = ({ href, name, subitems }) => {
  const { pathname } = useLocation()

  const isLinkSelected = (link) => {
    return pathname === link
  }

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const selected = isLinkSelected(href)
  const selectedSubitem = subitems.find((subitem) => isLinkSelected(subitem.href))

  return (
    <>
      <NavigationLink href={href} name={name} selected={selected} subitems />
      {selectedSubitem && (
        <>
          <IconButton
            ref={anchorRef}
            size='small'
            sx={{ p: 0, marginInline: 0.25 }}
            color='pagePrimaryAction'
            onClick={onOpen}
          >
            <KeyboardArrowRight />
          </IconButton>

          <NavigationMenu anchorEl={anchorRef.current} open={open} onClose={onClose} items={subitems} />

          <NavigationItem
            href={selectedSubitem.href}
            name={selectedSubitem.name}
            subitems={selectedSubitem.subitems || []}
          />
        </>
      )}
    </>
  )
}

NavigationItem.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subitems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType.isRequired,
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
}

export default NavigationItem
