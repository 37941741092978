import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { isVideoPage } from '@tabeeb/services/pageService'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import * as presentationSelectors from '@tabeeb/modules/presentation/selectors'
import { contentStateSelectors } from '@tabeeb/shared/content'

const ShowPlayerControlsContainer = ({ children }) => {
  const isVideo = useSelector((state) => isVideoPage(gallerySelectors.getSelectedGalleryItemType(state)))
  const isCallStarted = useSelector(presentationSelectors.getIsCallStarted)
  const isPresenter = useSelector(contentStateSelectors.getIsCurrentUserPresenter)

  return isVideo && (!isCallStarted || (isCallStarted && isPresenter)) ? children : null
}

ShowPlayerControlsContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default memo(ShowPlayerControlsContainer)
