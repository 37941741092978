import { put, take, takeLatest, all, select, call, race } from 'redux-saga/effects'

import { accountSelectors } from '@tabeeb/modules/account'
import {
  ErrorCode,
  HealthDataTypes,
  HealthDataTypesAutocomplete,
  HealthStatisticsTimeLine,
  IOTDeviceUnitType,
  SystemOfMeasures,
} from '@tabeeb/enums'
import { contentStateSelectors } from '@tabeeb/shared/content'
import * as adminUsersActions from '@tabeeb/modules/admin/users/actions'

import * as healthActions from '@tabeeb/modules/healthData/actions'
import {
  getCurrentUserWithHealthDataUpdateProfile,
  getUserHealthDataSetting,
  getUserPatientHealthInfoSetting,
  getChartsHealthData,
  getChartsHealthDataOfPatient,
  getChartsDataTypesAndIntervals,
  getChartsDataTypesAndIntervalsOfPatient,
  getViewedPatient,
} from '@tabeeb/modules/healthData/selectors'
import {
  createCoordinatesFromMeasurementValues,
  initialZoomForChart,
  switchTimeLine,
} from '@tabeeb/modules/healthData/helper/chartsHelper'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import Routes from '@tabeeb/routes'
import { addToArrayConditionally, getMeasureUnitIdByMeasureType } from '../helper'
import { usersActions } from '../../../users'
import { notificationActions } from '../../notification'
import * as userSettingsActions from '../../account/actions'

function* prepareCharts(getMeasureResponse) {
  // invoke some methods for configuring charts (initial zoom or switch time interval)
  if (getMeasureResponse.length > 0) {
    const dataTypesAndIntervals = yield select(getChartsDataTypesAndIntervals)
    for (const dataTypesAndInterval of dataTypesAndIntervals) {
      if (dataTypesAndInterval.interval === HealthStatisticsTimeLine.All) {
        initialZoomForChart(
          getMeasureResponse.filter((el) => el.MeasureId === dataTypesAndInterval.dataType),
          dataTypesAndInterval.dataType
        )
      } else {
        switchTimeLine(dataTypesAndInterval.interval, `chart-${dataTypesAndInterval.dataType}`, [])
      }
    }
  }
}

function* onUpdateCharts(action) {
  const dataTypesAndIntervals = yield select(getChartsDataTypesAndIntervals)
  if (dataTypesAndIntervals.length === 0) {
    return
  }

  const currentUser = yield select(accountSelectors.getMe)
  const model = {
    UserId: currentUser.Id,
    TenantId: currentUser.TenantId,
    MeasureModels: dataTypesAndIntervals.map((el) => ({
      MeasureId: el.dataType,
      MeasureUnitId: getMeasureUnitIdByMeasureType(el.dataType, Number(currentUser.SystemOfMeasure)),
    })),
  }

  const data = yield call(getAllHealthDataHistorySaga, model)
  const graphCoordinates = createCoordinatesFromMeasurementValues(data)
  yield put(healthActions.setGraphCoordinates(graphCoordinates))

  yield call(prepareCharts, data)
}

function* onUpdateViewedPatientCharts(action) {
  const dataTypesAndIntervals = yield select(getChartsDataTypesAndIntervalsOfPatient)
  const currentUser = yield select(accountSelectors.getMe)
  const patient = yield select(getViewedPatient)

  const model = {
    UserId: patient.Id,
    TenantId: patient.TenantId,
    MeasureModels: dataTypesAndIntervals.map((el) => ({
      MeasureId: el.dataType,
      MeasureUnitId: getMeasureUnitIdByMeasureType(el.dataType, Number(currentUser.SystemOfMeasure)),
    })),
  }

  const data = yield call(getAllHealthDataHistorySaga, model)
  const graphCoordinates = createCoordinatesFromMeasurementValues(data)
  yield put(healthActions.setGraphCoordinatesOfViewedPatient(graphCoordinates))

  yield call(prepareCharts, data)
}

function* getAllHealthDataHistorySaga(getAllHealthDataHistoryModel) {
  yield put(healthActions.getAllHealthDataHistory.cancel())
  const { UserId, TenantId, MeasureModels } = getAllHealthDataHistoryModel
  const contentId = yield select(contentStateSelectors.getContentId)
  yield put(
    healthActions.getAllHealthDataHistory.request({
      PatientId: UserId,
      TenantId,
      MeasureModels,
      ContentId: contentId !== 0 ? contentId : null,
    })
  )

  const result = yield take([
    healthActions.getAllHealthDataHistory.success,
    healthActions.getAllHealthDataHistory.failed,
  ])
  if (result.type === healthActions.getAllHealthDataHistory.success().type) {
    result.response.data.forEach((e) => {
      e.Value = Number(e.Value.toFixed(1))
    })
    return result.response.data
  }
}

function* handleHealthDataInProfileToSubmit(action) {
  yield put(userSettingsActions.updateAccountRequest(action.payload))
  const { success } = yield race({
    success: take(userSettingsActions.updateAccountSuccess),
    failed: take(userSettingsActions.updateAccountFailed),
  })

  if (success) {
    const { Height, Weight, SystemOfMeasure } = action.payload
    const currentUser = yield select(accountSelectors.getMe)

    const data = [
      ...addToArrayConditionally(
        Height,
        currentUser.IdentityGuid,
        Number(SystemOfMeasure) === SystemOfMeasures.Metric ? IOTDeviceUnitType.Centimeter : IOTDeviceUnitType.Inch,
        HealthDataTypes.Height
      ),
      ...addToArrayConditionally(
        Weight,
        currentUser.IdentityGuid,
        Number(SystemOfMeasure) === SystemOfMeasures.Metric ? IOTDeviceUnitType.Kilogram : IOTDeviceUnitType.Pound,
        HealthDataTypes.Weight
      ),
    ]

    if (data.length > 0) {
      yield put(healthActions.addHealthDataRequest(data))
      const result = yield take([healthActions.addHealthDataSuccess, healthActions.addHealthDataFailed])
      if (result.type === healthActions.addHealthDataSuccess().type) {
        const userSettingsFormData = yield select(getCurrentUserWithHealthDataUpdateProfile)
        const data = [
          {
            MeasureId: HealthDataTypes.Height,
            MeasureUnitId:
              Number(userSettingsFormData.SystemOfMeasure) === SystemOfMeasures.Metric
                ? IOTDeviceUnitType.Centimeter
                : IOTDeviceUnitType.Inch,
          },
          {
            MeasureId: HealthDataTypes.Weight,
            MeasureUnitId:
              Number(userSettingsFormData.SystemOfMeasure) === SystemOfMeasures.Metric
                ? IOTDeviceUnitType.Kilogram
                : IOTDeviceUnitType.Pound,
          },
        ]

        yield put(healthActions.getTheLatestHealthDataHistoryRequest(data))
      }
    }
  }
}

function* handleUpdatedHealthDataHistories(action) {
  if (action.payload.HealthDataHistoryModels) {
    yield put(healthActions.updateHealthDataHistories(action.payload.HealthDataHistoryModels))
  }
}

function* onOpenHealthStatisticsPage(action) {
  const { PatientId, PatientTenantId } = action.payload
  yield call(
    handleOpenHealthView,
    { PatientId, PatientTenantId },
    healthActions.setCurrentUserHealthInfoSetting,
    healthActions.setChartsBySetting
  )
}

function* onViewPatientHealthInfo(action) {
  const { PatientId, PatientTenantId } = action.payload
  yield put(usersActions.getUserByIdRequest({ userId: PatientId }))

  const result = yield take([usersActions.getUserByIdSuccess, usersActions.getUserByIdFailed])
  if (result.type === usersActions.getUserByIdSuccess().type) {
    yield put(healthActions.onGetViewedPatient(result.response.data))
  }

  yield call(
    handleOpenHealthView,
    { PatientId, PatientTenantId },
    healthActions.setContentUserPatientsHealthInfoSetting,
    healthActions.setPatientChartsBySetting
  )
}

function* handleOpenHealthView(
  contentUserPatientsHealthInfoSettingModel,
  setUserHealthInfoSetting,
  setChartsBySetting
) {
  const { PatientId, PatientTenantId } = contentUserPatientsHealthInfoSettingModel
  yield put(
    healthActions.getUserPatientsHealthInfoSetting.request({
      PatientId,
      PatientTenantId,
    })
  )

  const result = yield take([
    healthActions.getUserPatientsHealthInfoSetting.success,
    healthActions.getUserPatientsHealthInfoSetting.failed,
  ])
  if (result.type === healthActions.getUserPatientsHealthInfoSetting.success().type) {
    const { data, status } = result.response
    if (status === 200 && data?.HealthInfoSetting.length > 0) {
      yield put(setUserHealthInfoSetting(data))
      const healthInfoSetting = JSON.parse(data.HealthInfoSetting)

      if (healthInfoSetting.length > 0) {
        const chartsHealthDataToSet = healthInfoSetting.map((e) => ({
          dataType: e.order,
          name: e.name,
          interval: e.timeline,
          convertedData: [],
        }))
        yield put(setChartsBySetting(chartsHealthDataToSet))
      }
    } else {
      const chartsHealthDataToSet = HealthDataTypesAutocomplete.map((e) => ({
        dataType: HealthDataTypes[e.name],
        name: e.name,
        interval: HealthStatisticsTimeLine.All,
        convertedData: [],
      }))
      yield put(setChartsBySetting(chartsHealthDataToSet))
    }
  }
}

function* onCloseHealthStatisticsPage(action) {
  const chartsHealthData = yield select(getChartsHealthData)
  const userHealthInfoSetting = yield select(getUserHealthDataSetting)

  yield call(handleCloseHealthView, chartsHealthData, userHealthInfoSetting, action.payload)

  yield put(healthActions.resetState())
}

function* onCloseHealthInfoDialog(action) {
  const chartsHealthDataOfPatient = yield select(getChartsHealthDataOfPatient)
  const userPatientHealthInfoSetting = yield select(getUserPatientHealthInfoSetting)

  yield call(handleCloseHealthView, chartsHealthDataOfPatient, userPatientHealthInfoSetting, action.payload)

  yield put(healthActions.resetViewedPatientState())
}

function* handleCloseHealthView(
  chartsHealthData,

  userHealthInfoSetting,
  contentUserPatientsHealthInfoSettingModel
) {
  const { PatientId, PatientTenantId } = contentUserPatientsHealthInfoSettingModel
  const healthInfoSetting = chartsHealthData.map((e) => ({
    name: e.name,
    order: e.dataType,
    timeline: e.interval,
  }))
  const healthInfoSettingJson = JSON.stringify(healthInfoSetting)

  if (healthInfoSettingJson !== userHealthInfoSetting.HealthInfoSetting) {
    const data = {
      PatientId,
      PatientTenantId,
      HealthInfoSetting: healthInfoSettingJson,
    }

    if (data.HealthInfoSetting.length > 0) {
      yield put(healthActions.addContentUserPatientsHealthInfoSettingRequest(data))
    }
  }
}

function* onAddHealthData(action) {
  yield put(healthActions.addHealthDataRequest(action.payload))

  const result = yield take([healthActions.addHealthDataSuccess, healthActions.addHealthDataFailed])
  if (result.type === healthActions.addHealthDataSuccess().type) {
    yield put(healthActions.onUpdateCharts())

    const message = `New data was added`
    yield put(notificationActions.onAddSuccessNotification({ message }))
  }
}

function* onAddHealthDataOfViewedPatient(action) {
  yield put(healthActions.addHealthDataRequest(action.payload))

  const result = yield take([healthActions.addHealthDataSuccess, healthActions.addHealthDataFailed])
  if (result.type === healthActions.addHealthDataSuccess().type) {
    yield put(healthActions.onUpdateViewedPatientCharts())

    const message = `New data was added`
    yield put(notificationActions.onAddSuccessNotification({ message }))
  }
}

function* onUpdatePatientInfo(action) {
  yield put(adminUsersActions.updateUser.request(action.payload))

  const result = yield take([adminUsersActions.updateUser.success, adminUsersActions.updateUser.failed])
  if (result.type === adminUsersActions.updateUser.success().type) {
    yield put(notificationActions.onAddSuccessNotification({ message: 'User was successfully updated' }))

    yield put(usersActions.getUserByIdRequest({ userId: result.response.data.Id }))

    const getUserResult = yield take([usersActions.getUserByIdSuccess, usersActions.getUserByIdFailed])
    if (getUserResult.type === usersActions.getUserByIdSuccess().type) {
      yield put(healthActions.onGetViewedPatient(getUserResult.response.data))
    }
  } else {
    yield put(notificationActions.onAddErrorNotification({ message: 'User was not successfully updated' }))
  }
}

function* onEditHealthDeviceSuccess() {
  const message = 'Device was successfully updated.'
  yield put(notificationActions.onAddSuccessNotification({ message }))
  yield put(healthActions.getMyHealthDevicesRequest())
}

function* onEditHealthDeviceFailed(action) {
  const message =
    action.response.data.Error?.Code === ErrorCode.DeviceAlreadyExists
      ? action.response.data.Error?.Message
      : 'Device was not successfully updated'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* onMeasurementAdded(action) {
  const pathname = yield select((state) => state.router.location.pathname)
  if (pathname === Routes.userSettings) {
    yield call(onUpdateCharts)
  }
}

function* healthSaga() {
  yield all([
    takeLatest(
      [healthActions.onUpdateCharts, healthActions.setChartsBySetting, healthActions.addHealthDataChart],
      onUpdateCharts
    ),
    takeLatest(
      [
        healthActions.onUpdateViewedPatientCharts,
        healthActions.setPatientChartsBySetting,
        healthActions.addPatientHealthDataChart,
      ],
      onUpdateViewedPatientCharts
    ),
    takeLatest(healthActions.handleHealthDataInProfileToSubmit, handleHealthDataInProfileToSubmit),
    takeLatest(userSettingsActions.updateAccountSuccess, handleUpdatedHealthDataHistories),
    takeLatest(healthActions.onOpenHealthStatisticsPage, onOpenHealthStatisticsPage),
    takeLatest(healthActions.onCloseHealthStatisticsPage, onCloseHealthStatisticsPage),
    takeLatest(healthActions.onAddHealthData, onAddHealthData),
    takeLatest(healthActions.onViewPatientHealthInfo, onViewPatientHealthInfo),
    takeLatest(healthActions.onCloseHealthInfoDialog, onCloseHealthInfoDialog),
    takeLatest(healthActions.onUpdatePatientInfo, onUpdatePatientInfo),
    takeLatest(healthActions.onAddHealthDataOfViewedPatient, onAddHealthDataOfViewedPatient),
    takeLatest(healthActions.editHealthDevice.success, onEditHealthDeviceSuccess),
    takeLatest(healthActions.editHealthDevice.failed, onEditHealthDeviceFailed),
    takeLatest(signalrEvents[signalrConstants.healthDeviceHubName].onMeasurementAdded, onMeasurementAdded),
  ])
}

export default healthSaga
