import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.openTabSettings](state, action) {
      return [...state, action.payload]
    },
    [rawActions.updateTabSettingsList](state, action) {
      return [...action.payload]
    },
    [rawActions.closeTabSettings](state, action) {
      return state.filter((tab) => tab !== action.payload)
    },
    [rawActions.clearTabSettingsList](state, action) {
      return defaultState
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
