import PropTypes from 'prop-types'

import './styles.less'

import TimeActivity from '../TimeActivity'

const ActivityInfo = ({ activity, annotationMessage, annotationTime, durationTime, annotationText }) => {
  return (
    <div className='infoBox'>
      <article>
        <span>
          {activity.user.displayName} {annotationMessage}
        </span>
        <TimeActivity durationTime={durationTime} annotationTime={annotationTime} />
        {annotationText}
      </article>
      <footer className='activity-info-footer'>
        <div className='activity-page-info'>
          <span>{activity.pageInfo.folderName}</span>
          <span>{activity.pageInfo.pageName}</span>
        </div>
        <time>{activity.displayTime}</time>
      </footer>
    </div>
  )
}

ActivityInfo.propTypes = {
  activity: PropTypes.object.isRequired,
  annotationMessage: PropTypes.string.isRequired,
  annotationTime: PropTypes.string,
  durationTime: PropTypes.string,
  annotationText: PropTypes.string,
}

export default ActivityInfo
