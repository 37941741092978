import { memo } from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, FormControlLabel, Switch } from '@material-ui/core'

import useStyles from './styles'

const FormikCheckbox = ({ field, form, label, labelProps, ...props }) => {
  const classes = useStyles()

  const { errors, touched } = form
  const error = touched[field.name] && errors[field.name]

  return (
    <FormControl error={!!error} fullWidth>
      <FormControlLabel
        className={classes.label}
        labelPlacement='start'
        {...labelProps}
        control={<Switch {...field} {...props} checked={field.value} />}
        label={label}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

FormikCheckbox.defaultProps = {
  labelProps: {},
}

FormikCheckbox.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.node,
  labelProps: PropTypes.object,
}

export default memo(FormikCheckbox)
