/* eslint-disable jsx-a11y/media-has-caption */
import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isIOS, isMobileOnly } from 'react-device-detect'
import { Fade, Paper, withStyles } from '@material-ui/core'

import { accountSelectors } from '@tabeeb/modules/account'
import { recordingSelectors } from '@tabeeb/modules/recording'
import { gridViewSelectors } from '@tabeeb/modules/gridView'
import { presentationSelectors, tracksActions } from '@tabeeb/modules/presentation'

import { useHover } from '@tabeeb/shared/utils/hooks'
import { contentStateSelectors } from '@tabeeb/shared/content'
import CircularProgressBar from '@tabeeb/shared/tabeebCircularProgressBar'

import MuteUserAudioButton from '../MuteUserAudioButton'
import MuteUserVideoButton from '../MuteUserVideoButton'
import ScreenSharingButton from '../ScreenSharingButton'

import UserInfo from '../UserInfo'
import UserVideo from '../UserVideo'
import UserAvatar from '../UserAvatar'
import UserOptions from '../UserOptions'

import RecordingTimer from '../RecordingTimer'
import BatteryIndicator from '../BatteryIndicator'
import ConnectionQualityIndicator from '../ConnectionQualityIndicator'

import styles from './styles'

const CURRENT_USER_ORDER = -10000
const OWNER_ORDER = -9999
const ONLINE_USERS_ORDER_OFFSET = -5000

const User = ({ classes, user, index }) => {
  const dispatch = useDispatch()
  const [containerRef, hovered] = useHover()

  const currentUserId = useSelector(accountSelectors.getCurrentUserId)

  const recordingStatusSelector = useMemo(recordingSelectors.makeGetIsUserRecordingActive, [])
  const recording = useSelector((state) => recordingStatusSelector(state, { userId: user.id }))

  const presenterStatusSelector = useMemo(presentationSelectors.makeGetIsUserPresenter, [])
  const isPresenter = useSelector((state) => presenterStatusSelector(state, { userId: user.id }))

  const ownerStatusSelector = useMemo(contentStateSelectors.makeGetIsUserOwner, [])
  const isOwner = useSelector((state) => ownerStatusSelector(state, { userId: user.id }))

  const mainScreenVideoStatusSelector = useMemo(presentationSelectors.makeGetIsUserVideoOnMainScreen, [])
  const isUserVideoOnMainScreen = useSelector((state) => mainScreenVideoStatusSelector(state, { userId: user.id }))

  const isCallStarted = useSelector(presentationSelectors.getIsCallStarted)
  const isGridView = useSelector(gridViewSelectors.getIsGridViewActive)
  const isCurrentUser = currentUserId === user.id

  const order = useMemo(() => {
    if (isCurrentUser) {
      return CURRENT_USER_ORDER
    }

    if (isOwner) {
      return OWNER_ORDER
    }

    if (user.isOnline) {
      return index + ONLINE_USERS_ORDER_OFFSET
    }

    return index
  }, [isCurrentUser, isOwner, user.isOnline, index])

  const handleVideoDoubleClick = useCallback(() => {
    if (!user.isOnline || isMobileOnly) {
      return
    }

    dispatch(tracksActions.toggleLargeVideo({ userId: user.id }))
  }, [user.id, user.isOnline])

  const handleVideoClick = useCallback(() => {
    if (!user.isOnline || !isMobileOnly) {
      return
    }

    if (currentUserId === user.id && isIOS) {
      return
    }

    dispatch(tracksActions.toggleMobileLargeVideo({ userId: user.id }))
  }, [user.id, user.isOnline])

  return (
    <Paper
      component='li'
      ref={containerRef}
      style={{ order }}
      className={classNames(
        [classes.container],
        { [classes.recording]: recording },
        { [classes.sticky]: isCurrentUser },
        {
          [classes.mobileOffline]: isMobileOnly && !user.isOnline,
        }
      )}
    >
      <div
        className={classNames('tabeeb-user-box', classes.border)}
        data-id={user.id}
        data-online={user.isOnline}
        onDoubleClick={handleVideoDoubleClick}
        onClick={handleVideoClick}
      />
      {!isMobileOnly && <UserOptions user={user} />}
      {isCallStarted && user.isOnline && !isMobileOnly && (
        <div className={classes.header}>
          {recording && !isUserVideoOnMainScreen && !isGridView && <RecordingTimer />}
          <BatteryIndicator isVisible={hovered} chargeLevel={user.chargeLevel} />
          <ConnectionQualityIndicator isVisible={hovered} bitrate={user.bitrate} quality={user.connectionQuality} />
        </div>
      )}

      <footer className={classes.footer}>
        <UserInfo user={user} isCallStarted={isCallStarted} isPresenter={isPresenter} isOwner={isOwner} />

        <Fade in={isCallStarted && user.isOnline} unmountOnExit>
          <div className={classes.userControls}>
            {isMobileOnly ? (
              <ConnectionQualityIndicator isVisible bitrate={user.bitrate} quality={user.connectionQuality} />
            ) : (
              <>
                <MuteUserVideoButton user={user} />
                <MuteUserAudioButton user={user} />
                {isCurrentUser && <ScreenSharingButton user={user} />}
              </>
            )}
          </div>
        </Fade>
      </footer>

      {!isMobileOnly && <audio data-id={user.id} className='tabeebUserAudio' />}

      <UserAvatar user={user} />
      <UserVideo user={user} />
      {user.isVideoLoad && !user.isOnline && <CircularProgressBar />}
    </Paper>
  )
}

User.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    recording: PropTypes.string.isRequired,
    sticky: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    footer: PropTypes.string.isRequired,
    userControls: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
    mobileOffline: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    avatarUrl: PropTypes.string,
    isOnline: PropTypes.bool.isRequired,
    connectionQuality: PropTypes.number,
    chargeLevel: PropTypes.string.isRequired,
    bitrate: PropTypes.object,
    isVideoLoad: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

export default memo(withStyles(styles)(User))
