import { useCallback, useMemo, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { SessionStatus, SessionStatusDisplayName } from '@tabeeb/enums'
import { usePopoverState } from '@tabeeb/shared/utils/hooks'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { updateSessionStatusFilter } from '@tabeeb/modules/sessionsPage/actions'
import { getSessionStatusFilter } from '@tabeeb/modules/sessionsPage/selectors/filtration'

import FilterButton from '../FilterButton'
import FilterMenu from '../FilterMenu'
import FilterOptionsSelect from '../FilterOptionsSelect'

const SessionStatusFilter = ({ variant, ...rest }) => {
  const dispatch = useDispatch()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const initialValues = useSelector(getSessionStatusFilter)

  const options = useMemo(
    () => Object.values(SessionStatus).map((status) => ({ value: status, name: SessionStatusDisplayName[status] })),
    []
  )

  const onSubmit = useCallback(
    (values) => {
      dispatch(updateSessionStatusFilter(values))
    },
    [dispatch]
  )

  return (
    <>
      {variant === 'button' && (
        <FilterButton ref={anchorRef} active={initialValues.length !== options.length} onClick={onOpen} {...rest} />
      )}
      {variant === 'select' && (
        <FilterOptionsSelect ref={anchorRef} values={initialValues} options={options} onClick={onOpen} />
      )}
      <FilterMenu
        anchorEl={anchorRef.current}
        open={open}
        onClose={onClose}
        title={sessionTitleFormatter.format('Session status')}
        initial={initialValues}
        options={options}
        onSubmit={onSubmit}
      />
    </>
  )
}

SessionStatusFilter.defaultProps = {
  variant: 'button',
}

SessionStatusFilter.propTypes = {
  variant: PropTypes.oneOf(['button', 'select']),
}

export default memo(SessionStatusFilter)
