import $ from 'jquery'

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export function generateId() {
  return `g_${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

function dataURItoBlob(dataURI) {
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

function blobToFile(theBlob, fileName) {
  theBlob.lastModifiedDate = new Date()
  theBlob.name = fileName
  return theBlob
}

export function takeSnapshotPicture(id, selectedUser, isGridView) {
  let video = null
  if (isGridView) {
    video = $(`.tabeebGridUserVideo[data-id="${id}"]`)[0] // TODO Need to eliminate using of jQuery library
  } else if (selectedUser?.id == id) {
    video = $('.largeVideo')[0] // TODO Need to eliminate using of jQuery library
  } else {
    const videos = $('.tabeebUserVideo') // TODO Need to eliminate using of jQuery library
    Array.prototype.map.call(videos, (item) => {
      if (item.dataset.id == id) {
        video = item
      }
    })
  }

  const canvas = $(
    `<canvas width='${video.videoWidth}' height='${video.videoHeight}' style='display:none;'></canvas>`
  )[0] // TODO Need to eliminate using of jQuery library
  const ctx = canvas.getContext('2d')
  ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

  const dataURL = canvas.toDataURL('image/jpeg', 1.0)
  const file = blobToFile(dataURItoBlob(dataURL), 'snapshot.jpg')

  ctx.clearRect(0, 0, canvas.width, canvas.height)
  $(canvas).remove() // TODO Need to eliminate using of jQuery library

  return file
}
