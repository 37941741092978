import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Autocomplete } from '@material-ui/lab'
import { TextField, Typography } from '@material-ui/core'
import { ViewInArOutlined } from '@mui/icons-material'

import { AnnotationType } from '@tabeeb/enums'
import { drawingActions, playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'
import { setSelectedModel } from '@tabeeb/modules/pointCloud/actions'
import { use3dModels } from '@tabeeb/modules/threeDimensionalModels/hooks'
import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'

const BUTTON_NAME = 'model'

const ModelButton = () => {
  const dispatch = useDispatch()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const { models } = use3dModels()

  const color = useSelector(playerToolbarSelectors.getSelectedColor)
  const selected = useSelector((state) => playerToolbarSelectors.getIsButtonSelected(state, BUTTON_NAME))
  const isDrawingEnabled = useSelector(playerToolbarSelectors.getIsDrawingEnabled)

  const onClick = useCallback(() => {
    if (selected) {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: '' }))
      dispatch(drawingActions.onDisableDrawing())
    } else {
      onOpen()
    }
  }, [dispatch, onOpen, selected])

  const onSelectModel = useCallback(
    (event, model) => {
      dispatch(setSelectedModel(model))
      dispatch(drawingActions.onChangePressedButton({ pressedButton: BUTTON_NAME }))

      dispatch(drawingActions.onSetDrawingType(AnnotationType.Model))
      dispatch(drawingActions.onEnableDrawing())
    },
    [dispatch]
  )

  return (
    <>
      <ToolbarButton
        ref={anchorRef}
        Icon={ViewInArOutlined}
        htmlColor={isDrawingEnabled && selected ? color : null}
        selected={selected || open}
        title='Add Model annotation'
        onClick={onClick}
      />
      <ToolbarPopover anchorEl={anchorRef.current} open={open} onClose={onClose}>
        <Autocomplete
          fullWidth
          disableClearable
          options={models}
          onChange={onSelectModel}
          getOptionLabel={(option) => option.Name}
          renderOption={(option) => (
            <Typography noWrap title={option.Name}>
              {option.Name}
            </Typography>
          )}
          renderInput={(params) => <TextField style={{ minWidth: 192 }} {...params} label='Model' variant='outlined' />}
        />
      </ToolbarPopover>
    </>
  )
}

export default memo(ModelButton)
