export default (theme) => {
  return {
    container: {
      display: 'flex',
      maxWidth: '100%',
    },
    tabs: {
      marginBottom: theme.spacing() / 4,
      '& .MuiTab-root': {
        minWidth: 0,
      },
    },
    avatar: {
      marginRight: 12,
      marginTop: 52,
    },
    content: {
      flexGrow: 1,
      maxWidth: '89.5%',
    },
    actions: {
      minHeight: 35,
      display: 'flex',
      marginTop: theme.spacing(),
      justifyContent: 'flex-end',
      '& button': {
        marginRight: theme.spacing(),

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  }
}
