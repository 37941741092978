import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  height: 750,
  width: 1000,
}

export default handleActions(
  {
    [rawActions.setContentSize](state, action) {
      const contentSize = action.payload
      if (!contentSize.height) {
        return { ...defaultState }
      }

      return {
        ...contentSize,
      }
    },
    [rawActions.resetContentViewerState](state, action) {
      return defaultState
    },
  },
  defaultState
)
