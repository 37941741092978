import { put, takeLatest, all } from 'redux-saga/effects'
import * as rawActions from '../actions'

function* openFolder(action) {
  const folder = action.payload

  yield put(rawActions.setCurrentFolder(folder))

  const folderId = folder.id
  if (folderId) {
    yield put(rawActions.getAvailableFormsByFolderIdRequest(folderId))
  } else {
    yield put(rawActions.getAvailableFormsRequest())
    yield put(rawActions.getAllFoldersInFormsRequest())
  }
}

function* foldersSaga() {
  yield all([takeLatest(rawActions.openFolder, openFolder)])
}

export default foldersSaga
