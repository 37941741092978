import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    inputText: {
      '& .MuiInputBase-input': {
        '&[disabled]': {
          color: theme.palette.grey[700],
        },
      },
    },
    flexCenteredGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    adornmentText: {
      fontWeight: 'bold',
      color: `${theme.palette.grey[700]} !important`,
    },
    divider: {
      width: '100%',
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1.25),
    },
    addressButtonGrid: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    address1Grid: {
      paddingBottom: '0px !important',
    },
  }
})
