import { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrashOutlined'
import Delete from '@material-ui/icons/DeleteOutline'

import { useSelector } from 'react-redux'
import { dialogItems } from '../../services/dialogItems'
import * as contentTrashBinSelectors from '../../selectors'

const Folder = ({ folder, onFolderClick, dialogFolder }) => {
  const canDeleteRestoreFolderSelector = useMemo(contentTrashBinSelectors.makeGetCanDeleteRestoreFolder, [])
  const canDeleteRestoreFolder = useSelector((state) => canDeleteRestoreFolderSelector(state, folder.Id))

  return (
    <div className={classNames('session-folders-item')}>
      <div
        className='layout'
        data-id={folder.Id}
        title={folder.Name}
        onClick={() => {
          onFolderClick(folder)
        }}
      />
      <i className={classNames('material-icons', 'session-folders-item-icon')}>folder</i>
      <span className='session-folders-item-name'>{folder.Name}</span>
      {canDeleteRestoreFolder && (
        <>
          <RestoreFromTrash
            className='tabeeb-icon-edit-black'
            onClick={() => dialogFolder(folder.Id, dialogItems.restoreFolder)}
            style={{ height: 19, width: 19 }}
          />

          <Delete
            className='tabeeb-icon-trash-empty'
            onClick={() => dialogFolder(folder.Id, dialogItems.deleteFolder)}
            style={{ height: 19, width: 19 }}
          />
        </>
      )}
    </div>
  )
}

Folder.propTypes = {
  folder: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
  onFolderClick: PropTypes.func.isRequired,
  dialogFolder: PropTypes.func.isRequired,
}

export default Folder
