import { memo } from 'react'
import PropTypes from 'prop-types'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FormControlButton from '../FormControlButton'

const ApplyAnswerEditingButton = ({ handleSubmit, isEditingAnswer }) => {
  if (!isEditingAnswer) return null

  const componentProps = {
    onClick: handleSubmit,
    Icon: CheckCircleIcon,
    title: 'Submit',
    iconColor: '#4CAF50',
  }

  return <FormControlButton {...componentProps} />
}

ApplyAnswerEditingButton.propTypes = {
  isEditingAnswer: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default memo(ApplyAnswerEditingButton)
