import { handleActions } from 'redux-actions'

import * as healthActions from '@tabeeb/modules/healthData/actions'

const defaultState = []

export default handleActions(
  {
    [healthActions.getHealthDevicesByUserIdSuccess](state, action) {
      return action.response.data
    },
    [healthActions.resetViewedPatientState](state, action) {
      return defaultState
    },
  },
  defaultState
)
