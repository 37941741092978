import { memo } from 'react'
import PropTypes from 'prop-types'

import { ErrorOutline } from '@material-ui/icons'
import { Typography, withStyles } from '@material-ui/core'

import styles from './styles'

const FailedToLoadAnswersPlaceholder = ({ classes }) => {
  return (
    <div className={classes.container}>
      <ErrorOutline className={classes.icon} />
      <Typography className={classes.text}>Failed to load answers...</Typography>
    </div>
  )
}

FailedToLoadAnswersPlaceholder.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(FailedToLoadAnswersPlaceholder))
