import PropTypes from 'prop-types'

import { generatePath } from 'react-router-dom'

import Routes from '@tabeeb/routes'

import OpenInNewTabIconButton from '../OpenInNewTabIconButton'

const TrainingMaterialLinkButton = ({ id, disabled }) => {
  return <OpenInNewTabIconButton disabled={disabled} url={generatePath(Routes.trainingMaterialView, { id })} />
}

TrainingMaterialLinkButton.propTypes = {
  id: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

export default TrainingMaterialLinkButton
