import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const getTheLatestHealthDataHistoryRequest = createAction('GET_THE_LATEST_HEALTH_DATA_HISTORY_REQUEST')
export const getTheLatestHealthDataHistorySuccess = createAction('GET_THE_LATEST_HEALTH_DATA_HISTORY_SUCCESS')
export const getTheLatestHealthDataHistoryFailed = createAction('GET_THE_LATEST_HEALTH_DATA_HISTORY_FAILED')

export const getAllHealthDataHistory = createApiActions('GET_ALL_HEALTH_DATA_HISTORY')

export const handleHealthDataInProfileToSubmit = createAction('HANDLE_HEALTH_DATA_IN_PROFILE_TO_SUBMIT')
export const setGraphCoordinates = createAction('SET_GRAPH_COORDINATES')

export const addHealthDataChart = createAction('ADD_HEALTH_DATA_CHART')
export const removeHealthDataChart = createAction('REMOVE_HEALTH_DATA_CHART')
export const setChartInterval = createAction('SET_CHART_INTERVAL')
export const setChartsBySetting = createAction('SET_CHARTS_BY_SETTING')

export const addPatientHealthDataChart = createAction('ADD_PATIENT_HEALTH_DATA_CHART')
export const removePatientHealthDataChart = createAction('REMOVE_PATIENT_HEALTH_DATA_CHART')
export const setPatientChartInterval = createAction('SET_PATIENT_CHART_INTERVAL')
export const setPatientChartsBySetting = createAction('SET_PATIENT_CHARTS_BY_SETTING')

export const getLife365ListOfProgramsRequest = createAction('GET_LIFE365_LIST_OF_PROGRAMS_REQUEST')
export const getLife365ListOfProgramsSuccess = createAction('GET_LIFE365_LIST_OF_PROGRAMS_SUCCESS')
export const getLife365ListOfProgramsFailed = createAction('GET_LIFE365_LIST_OF_PROGRAMS_FAILED')

export const getAssignedLife365DeviceStatusRequest = createAction('GET_ASSIGNED_LIFE365_DEVICE_STATUS_REQUEST')
export const getAssignedLife365DeviceStatusSuccess = createAction('GET_ASSIGNED_LIFE365_DEVICE_STATUS_SUCCESS')
export const getAssignedLife365DeviceStatusFailed = createAction('GET_ASSIGNED_LIFE365_DEVICE_STATUS_FAILED')
export const getAssignedLife365DeviceStatusWithContent = createAction('GET_ASSIGNED_LIFE365_DEVICE_STATUS_WITH_CONTENT')

export const updateHealthDataHistories = createAction('UPDATE_HEALTH_DATA_HISTORIES')
export const onOpenHealthStatisticsPage = createAction('ON_OPEN_HEALTH_STATISTICS_PAGE')
export const onCloseHealthStatisticsPage = createAction('ON_CLOSE_HEALTH_STATISTICS_PAGE')

export const getMyHealthDevicesRequest = createAction('GET_MY_HEALTH_DEVICES_REQUEST')
export const getMyHealthDevicesSuccess = createAction('GET_MY_HEALTH_DEVICES_SUCCESS')
export const getMyHealthDevicesFailed = createAction('GET_MY_HEALTH_DEVICES_FAILED')
export const onGetLife365Device = createAction('ON_GET_LIFE365_DEVICE')

export const handleCreateHealthDeviceOnProfilePage = createAction('HANDLE_CREATE_HEALTH_DEVICE_ON_PROFILE_PAGE')
export const handleCreateHealthDeviceOnDevicesPage = createAction('HANDLE_CREATE_HEALTH_DEVICE_ON_DEVICES_PAGE')
export const handleCreateHealthDeviceOnUsersPage = createAction('HANDLE_CREATE_HEALTH_DEVICE_ON_USERS_PAGE')
export const handleDeleteHealthDeviceOnProfilePage = createAction('HANDLE_DELETE_HEALTH_DEVICE_ON_PROFILE_PAGE')
export const handleDeleteHealthDeviceOnDevicesPage = createAction('HANDLE_DELETE_HEALTH_DEVICE_ON_DEVICES_PAGE')

export const createHealthDeviceRequest = createAction('CREATE_HEALTH_DEVICE_REQUEST')
export const createHealthDeviceSuccess = createAction('CREATE_HEALTH_DEVICE_SUCCESS')
export const createHealthDeviceFailed = createAction('CREATE_HEALTH_DEVICE_FAILED')

export const editHealthDevice = createApiActions('EDIT_HEALTH_DEVICE')

export const deleteHealthDeviceRequest = createAction('DELETE_HEALTH_DEVICE_REQUEST')
export const deleteHealthDeviceSuccess = createAction('DELETE_HEALTH_DEVICE_SUCCESS')
export const deleteHealthDeviceFailed = createAction('DELETE_HEALTH_DEVICE_FAILED')

export const onAddHealthData = createAction('OM_ADD_HEALTH_DATA')
export const addHealthDataRequest = createAction('ADD_HEALTH_DATA_REQUEST')
export const addHealthDataSuccess = createAction('ADD_HEALTH_DATA_SUCCESS')
export const addHealthDataFailed = createAction('ADD_HEALTH_DATA_FAILED')

export const getHealthDevicesByUserIdRequest = createAction('GET_HEALTH_DEVICES_BY_USER_ID_REQUEST')
export const getHealthDevicesByUserIdSuccess = createAction('GET_HEALTH_DEVICES_BY_USER_ID_SUCCESS')
export const getHealthDevicesByUserIdFailed = createAction('GET_HEALTH_DEVICES_BY_USER_ID_FAILED')

export const onCloseHealthInfoDialog = createAction('ON_CLOSE_HEALTH_INFO_DIALOG')
export const addContentUserPatientsHealthInfoSettingRequest = createAction(
  'ADD_CONTENT_USER_PATIENTS_HEALTH_INFO_SETTING_REQUEST'
)
export const addContentUserPatientsHealthInfoSettingSuccess = createAction(
  'ADD_CONTENT_USER_PATIENTS_HEALTH_INFO_SETTING_SUCCESS'
)
export const addContentUserPatientsHealthInfoSettingFailed = createAction(
  'ADD_CONTENT_USER_PATIENTS_HEALTH_INFO_SETTING_FAILED'
)

export const getUserPatientsHealthInfoSetting = createApiActions('GET_USER_PATIENTS_HEALTH_INFO_SETTING')

export const setContentUserPatientsHealthInfoSetting = createAction('SET_CONTENT_USER_PATIENTS_HEALTH_INFO_SETTING')
export const setCurrentUserHealthInfoSetting = createAction('SET_CURRENT_USER_HEALTH_INFO_SETTING')

export const onUpdateCharts = createAction('ON_UPDATE_CHARTS')
export const onViewPatientHealthInfo = createAction('ON_VIEW_PATIENT_HEALTH_INFO')
export const onUpdatePatientInfo = createAction('ON_UPDATE_PATIENT_INFO')
export const onGetViewedPatient = createAction('ON_GET_VIEWED_PATIENT')
export const resetViewedPatientState = createAction('RESET_VIEWED_PATIENT_STATE')

export const setGraphCoordinatesOfViewedPatient = createAction('SET_GRAPH_COORDINATES_OF_VIEWED_PATIENT')

export const onUpdateViewedPatientCharts = createAction('ON_UPDATE_VIEWED_PATIENT_CHARTS')
export const onAddHealthDataOfViewedPatient = createAction('ON_ADD_HEALTH_DATA_OF_VIEWED_PATIENT')
export const resetState = createAction('RESET_HEALTH_DATA_STATE')
export const resetHealthChartsState = createAction('RESET_HEALTH_CHARTS_STATE')
export const resetHealthChartsStateOfViewedPatient = createAction('RESET_HEALTH_CHARTS_STATE_OF_VIEWED_PATIENT')

export const getAvailableDeviceTypes = createApiActions('GET_AVAILABLE_DEVICE_TYPES')
