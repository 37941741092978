import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

const SenderName = ({ name }) => {
  return (
    <Typography variant='caption' noWrap>
      {name}
    </Typography>
  )
}

SenderName.propTypes = {
  name: PropTypes.string.isRequired,
}

export default memo(SenderName)
