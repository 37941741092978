import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = {
  assets: [],
  inProgress: false,
}

export default handleActions(
  {
    [rawActions.getAssetsByContentIdRequest](state, action) {
      return state
    },
    [rawActions.getAssetsByContentIdSuccess](state, action) {
      return {
        ...state,
        assets: action.response.data,
      }
    },
    [rawActions.getAssetsByContentIdFailed](state, action) {
      return state
    },
    [rawActions.setRequestInProgress](state, action) {
      return {
        ...state,
        inProgress: action.payload,
      }
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
