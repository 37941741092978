export default (theme) => {
  return {
    container: {
      padding: theme.spacing() / 2,
      margin: theme.spacing() / 2,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.background.user.action,
      color: theme.palette.getContrastText(theme.palette.background.user.action),
      boxShadow: theme.shadows[2],
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      gap: theme.spacing() / 2,
    },
    icon: {
      color: theme.palette.error.main,
      fontSize: 16,
      marginTop: 2,
    },
  }
}
