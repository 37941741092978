import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

const AddRemoveStorageButton = ({
  isAttachedToTenant,
  storageId,
  tenantStorageId,
  onAddClick,
  onRemoveClick,
  isDisabled,
}) => {
  const text = isAttachedToTenant ? 'Remove' : 'Add'

  const onClick = () => {
    if (isAttachedToTenant) {
      onRemoveClick(tenantStorageId)
    } else {
      onAddClick(storageId)
    }
  }

  return (
    <Button disableElevation onClick={onClick} disabled={isDisabled}>
      {text}
    </Button>
  )
}

AddRemoveStorageButton.propTypes = {
  isAttachedToTenant: PropTypes.bool,
  storageId: PropTypes.number,
  tenantStorageId: PropTypes.number,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default AddRemoveStorageButton
