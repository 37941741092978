import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import AnnotationContainer from './AnnotationContainer'
import HotspotDialogContainer from './HotspotDialogContainer'
import AIHotspot from '../components/AIHotspot'
import { contentViewerSelectors } from '../../contentViewer'
import * as annotationsActions from '../actions'
import * as annotationsSelectors from '../selectors'

class AIHotspotContainer extends AnnotationContainer {
  constructor(props) {
    super(props)

    this.selectionProps = {
      onClick: this._handleSelect,
    }

    this.contextMenuProps = {
      onContextMenu: this._handleContextMenu,
    }

    this.hoverProps = {
      onMouseEnter: this._handleMouseEnter,
      onMouseLeave: this._handleMouseLeave,
    }

    this.dragAndDropProps = {
      draggable: true,
      dragBoundFunc: this._handleDragBound,
      onDragStart: this._handleDragStart,
      onDragMove: this._handleDragMove,
      onDragEnd: this._handleEndUpdate,
    }
  }

  _handleSelect = (e) => {
    if (e.evt.which !== 1) {
      return
    }

    const { annotation } = this.state

    const {
      annotationActions: { openHotspotDialog },
    } = this.props

    openHotspotDialog({ annotationId: annotation.Id })
  }

  _handleDragMove = (e) => {
    const { target } = e
    const { annotation } = this.state

    const newAnnotation = {
      ...annotation,
      Anchor: {
        X: target.x(),
        Y: target.y(),
      },
    }
    this.setState({ annotation: newAnnotation })
  }

  render() {
    const { opened, contentScale, opacity, parentHovered, parentSelected } = this.props
    const { hovered, selected, annotation } = this.state

    let position = null
    if (opened) {
      position = this.getElementAbsolutePosition()

      if (position) {
        const size = AIHotspot.getSize(annotation)
        position.x += size.width
      }
    }

    const props = {
      annotation,
      opacity,
      hovered: opened || hovered || parentHovered,
      selected: selected || parentSelected,
      contentScale,
      hoverProps: this.hoverProps,
      selectionProps: this.selectionProps,
      contextMenuProps: this.contextMenuProps,
      dragAndDropProps: this.dragAndDropProps,
      annotationRef: this.annotationRef,
    }

    return (
      <>
        {opened && position && <HotspotDialogContainer Id={annotation.Id} x={position.x} y={position.y} />}
        <AIHotspot {...props} />
      </>
    )
  }
}

AIHotspotContainer.propTypes = {
  contentScale: PropTypes.number.isRequired,
  currentAnnotation: PropTypes.object.isRequired,
}

const mapStateToProps = (state, { Id }) => {
  return {
    contentSize: contentViewerSelectors.getContentSize(state),
    scale: contentViewerSelectors.getScale(state),
    contentScale: contentViewerSelectors.getContentScale(state),
    opened: annotationsSelectors.getHotspotDialogAnnotationId(state) === Id,
    opacity: annotationsSelectors.getAnnotationOpacity(state, { Id }),
    selected: annotationsSelectors.getIsAnnotationSelected(state, { Id }),
    currentAnnotation: annotationsSelectors.getAnnotationById(state, { Id }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    annotationActions: bindActionCreators(annotationsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AIHotspotContainer)
