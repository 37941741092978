import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setSelectedStorage](state, action) {
      return { ...action.payload }
    },
    [rawActions.getTenantStorages.success](state, action) {
      const storages = action.response.data

      return storages.find((storage) => storage.IsDefault === true) || null
    },
  },
  defaultState
)
