import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import * as chatActions from '../actions'
import { useChatContext } from '../contexts'
import { TranslatedMessageDisplayVariant, TranslationLanguage } from '../constans'
import ChatTranslatableText from '../components/ChatTranslatableText'

import { getChatMessageTextTranslation } from '../services/chat'

const ChatTranslatableTextContainer = ({ messageData: message }) => {
  const dispatch = useDispatch()

  const [isTranslationDisabled, setIsTranslationDisabled] = useState(message.message)
  const [displayVariant, setDisplayVariant] = useState(TranslatedMessageDisplayVariant.Original)

  const { language } = useChatContext()

  const translation = getChatMessageTextTranslation(message, language)
  const isTranslationReady = Boolean(translation)
  const isTranslationEnabled = language !== TranslationLanguage.Original

  const onSwitchVariant = useCallback(() => {
    setDisplayVariant(
      displayVariant === TranslatedMessageDisplayVariant.Original
        ? TranslatedMessageDisplayVariant.Translated
        : TranslatedMessageDisplayVariant.Original
    )
  }, [displayVariant])

  useEffect(() => {
    if (message.message && isTranslationDisabled) {
      setIsTranslationDisabled(false)
    }
  }, [isTranslationDisabled, message.message])

  useEffect(() => {
    if (isTranslationDisabled) {
      return
    }

    if (language === TranslationLanguage.Original) {
      setDisplayVariant(TranslatedMessageDisplayVariant.Original)
    } else if (translation) {
      setDisplayVariant(TranslatedMessageDisplayVariant.Translated)
    } else {
      setDisplayVariant(TranslatedMessageDisplayVariant.Original)

      dispatch(
        chatActions.getMessageTranslationRequest({
          message: {
            id: message.id,
            topicId: message.topicId,
            topicType: message.topicType,
            userId: message.userId,
            receiverId: message.receiverId,
          },
          targetLanguage: language,
        })
      )
    }
  }, [
    translation,
    language,
    isTranslationDisabled,
    dispatch,
    message.id,
    message.topicId,
    message.topicType,
    message.userId,
    message.receiverId,
  ])

  const variant =
    displayVariant === TranslatedMessageDisplayVariant.Translated && isTranslationReady && isTranslationEnabled
      ? TranslatedMessageDisplayVariant.Translated
      : TranslatedMessageDisplayVariant.Original

  const props = {
    onSwitchVariant,
    isTranslationReady,
    isTranslationEnabled,
    isTranslationAvailable: !isTranslationDisabled,
    isTranslationInProgress: !isTranslationDisabled && !isTranslationReady && isTranslationEnabled,
    displayVariant: variant,
    text: variant === TranslatedMessageDisplayVariant.Translated ? translation ?? message.message : message.message,
  }

  return <ChatTranslatableText {...props} />
}

ChatTranslatableTextContainer.propTypes = {
  messageData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    message: PropTypes.string,
    translations: PropTypes.object,
  }).isRequired,
}

export default memo(ChatTranslatableTextContainer)
