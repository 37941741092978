import { handleActions } from 'redux-actions'

import * as snapshotActions from '../actions/snapshot'

const defaultValue = false

export default handleActions(
  {
    [snapshotActions.onSnapshotProgressStateUpdate](state, action) {
      return action.payload
    },
  },
  defaultValue
)
