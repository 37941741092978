import { memo } from 'react'
import PropTypes from 'prop-types'

import { alpha } from '@material-ui/core/styles'

import Avatar from '@tabeeb/shared/userAvatar'
import { getUserColor } from '@tabeeb/shared/utils/color'

import useStyles from './styles'

const BACKGROUND_OPACITY = 0.2

const UserAvatar = ({ user }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <img
        className={classes.background}
        style={{ backgroundColor: alpha(getUserColor(user), BACKGROUND_OPACITY) }}
        src={user.avatarUrl}
        alt='background'
      />
      {user.avatarUrl ? (
        <img className={classes.image} src={user.avatarUrl} alt='avatar' />
      ) : (
        <Avatar className={classes.avatar} user={user} />
      )}
    </div>
  )
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    avatarUrl: PropTypes.string,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(UserAvatar)
