import React from 'react'
import PropTypes from 'prop-types'

import { Fade, IconButton, Paper } from '@material-ui/core'
import { CameraAltOutlined, Close } from '@material-ui/icons'

import useStyles from './styles'

const Toolbar = ({ x, y, onCancelSnapshot, onSubmitSnapshot }) => {
  const classes = useStyles()

  return (
    <Fade in unmountOnExit>
      <Paper className={classes.root} style={{ right: x, top: y }}>
        <IconButton onClick={onCancelSnapshot} title='Cancel snapshot'>
          <Close />
        </IconButton>
        <IconButton onClick={onSubmitSnapshot} title='Take snapshot'>
          <CameraAltOutlined />
        </IconButton>
      </Paper>
    </Fade>
  )
}

Toolbar.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  onCancelSnapshot: PropTypes.func.isRequired,
  onSubmitSnapshot: PropTypes.func.isRequired,
}

export default React.memo(Toolbar)
