import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BackupTable } from '@mui/icons-material'

import { getSelectedGalleryItem } from '@tabeeb/modules/gallery/selectors'
import { sendForTableRecognition } from '@tabeeb/modules/gallery/actions'
import { getIsTableRecognitionEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'
import { SessionPermission } from '@tabeeb/enums'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'

import ToolbarButton from '../ToolbarButton'

const RecognizeTableButton = () => {
  const dispatch = useDispatch()

  const currentPage = useSelector(getSelectedGalleryItem)
  const contentId = useSelector(getContentId)

  const allowedFormats = ['JPEG', 'PNG', 'PDF', 'TIFF', 'JPG', 'TIF']
  const urlWithSas = currentPage?.assets[0]?.Url
  const rawUrl = urlWithSas?.split('?')[0]

  const onClick = () => {
    dispatch(sendForTableRecognition.request({ fileUrl: urlWithSas, contentId }))
  }

  const isFeatureEnabled = useSelector(getIsTableRecognitionEnabled)
  const hasPermission = useSelector((state) => hasSessionPermission(state, SessionPermission.GetContentMetadata))

  const showButton =
    isFeatureEnabled &&
    hasPermission &&
    allowedFormats.some((allowedFormat) => rawUrl?.toUpperCase().endsWith(allowedFormat))

  return showButton ? <ToolbarButton Icon={BackupTable} title='Recognize Table' onClick={onClick} /> : null
}

export default memo(RecognizeTableButton)
