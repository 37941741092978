export function _getTagsRequest() {
  return {
    url: `api/tags`,
    method: 'get',
  }
}

const tagsRequests = {
  getTagsListRequest: _getTagsRequest,
  getTagsForVqaPolicyRequest: _getTagsRequest,
  getTagsWithAiObjectsRequest: () => ({
    url: `api/aiobjecttags`,
    method: 'get',
  }),
  attachAiObjectToTagRequest: (data) => ({
    url: `api/aiobjecttags/attach`,
    method: 'post',
    data,
  }),
  detachAiObjectFromTagRequest: (data) => ({
    url: `api/aiobjecttags/detach`,
    method: 'post',
    data,
  }),
  createTagRequest: (data) => ({
    url: `api/tags`,
    method: 'post',
    data,
  }),
}

export default tagsRequests
