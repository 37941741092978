import { combineActions, handleActions } from 'redux-actions'

import * as healthActions from '@tabeeb/modules/healthData/actions'

const defaultState = {
  currentUser: [],
  patientUser: [],
}

export default handleActions(
  {
    [healthActions.setCurrentUserHealthInfoSetting](state, action) {
      return { ...state, currentUser: action.payload }
    },
    [combineActions(healthActions.resetHealthChartsState, healthActions.resetState)](state, action) {
      return { ...state, currentUser: [] }
    },
    [healthActions.setContentUserPatientsHealthInfoSetting](state, action) {
      return { ...state, patientUser: action.payload }
    },
    [healthActions.resetViewedPatientState](state, action) {
      return { ...state, patientUser: [] }
    },
  },
  defaultState
)
