import { put, take, call } from 'redux-saga/effects'
import externalEventHandler from '../externalEventHandler'

function* bindEvent(target, eventName, callback, externalEventChannels) {
  const channel = yield call(externalEventHandler, target, eventName)
  externalEventChannels.push(channel)
  try {
    while (true) {
      const { event } = yield take(channel)
      let payload = {}

      switch (event.length) {
        case 0:
          payload = {}
          break
        case 1:
          payload = event[0]
          break
        default:
          payload = Array.isArray(event) ? event.slice(0, event.length - 2) : event
          break
      }
      yield put(callback(payload))
    }
  } finally {
    channel.close()
    externalEventChannels.splice(externalEventChannels.indexOf(channel), 1)
  }
}

export default bindEvent
