import { memo } from 'react'
import PropTypes from 'prop-types'

import { MenuItem, Popover, withStyles } from '@material-ui/core'

import styles from './styles'

const AnswerStatusDropdown = ({ classes, anchorEl, open, options, onClose, onSelect }) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      className={classes.container}
      anchorOrigin={AnswerStatusDropdown.anchorOrigin}
      transformOrigin={AnswerStatusDropdown.transformOrigin}
    >
      {options.map((status) => {
        const StatusIcon = status.icon

        return (
          <MenuItem
            key={status.value}
            className={classes.item}
            title={status.displayValue}
            onClick={onSelect(status.value)}
          >
            <StatusIcon className={classes.icon} htmlColor={status.color} />
            {status.displayValue}
          </MenuItem>
        )
      })}
    </Popover>
  )
}

AnswerStatusDropdown.anchorOrigin = { vertical: 'bottom', horizontal: 'right' }

AnswerStatusDropdown.transformOrigin = { vertical: 'top', horizontal: 'right' }

AnswerStatusDropdown.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired,
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func.isRequired,
      color: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      displayValue: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default memo(withStyles(styles)(AnswerStatusDropdown))
