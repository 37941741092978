import PropTypes from 'prop-types'
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, ListItemIcon, Box } from '@mui/material'
import { get } from 'lodash'

const FormikSelect = ({
  options = [],
  field,
  form,
  label,
  id,
  name,
  variant,
  selectInputProps,
  size,
  disabled,
  required,
  ...props
}) => {
  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  const labelId = `${id}-label`

  return (
    <FormControl fullWidth error={!!error} disabled={disabled} required={required} size={size}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select
        labelId={labelId}
        id={id}
        label={label}
        name={name}
        error={!!error}
        disabled={disabled}
        required={required}
        size={size}
        variant={variant}
        {...field}
        {...selectInputProps}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

FormikSelect.defaultProps = {
  disabled: false,
  required: false,
  size: 'medium',
  variant: 'outlined',
}

FormikSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      icon: PropTypes.node,
    }).isRequired
  ).isRequired,
  name: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
  }).isRequired,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  selectInputProps: PropTypes.shape({}),
  size: PropTypes.oneOf(['small', 'medium']),
}

export default FormikSelect
