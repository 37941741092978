import { getSessionPermissionsSuccess } from '@tabeeb/modules/permissions/actions'
import { checkSessionInviteRequest } from '@tabeeb/modules/whiteboard/actions'
import { handleActions } from 'redux-actions'

const defaultValue = true

export default handleActions(
  {
    [checkSessionInviteRequest]() {
      return defaultValue
    },
    [getSessionPermissionsSuccess]() {
      return false
    },
  },
  defaultValue
)
