import { InputAdornment, Typography } from '@material-ui/core'

import {
  HealthDataTypes,
  IOTDeviceUnitType,
  Life365ProgramsDisplayName,
  Life365ProgramsIds,
  SystemOfMeasures,
} from '@tabeeb/enums'

import { getInchesForSave } from '@tabeeb/modules/healthData/helper/measurementConversion'

export const addToArrayConditionally = (value, userIdGuid, measureUnitId, measureId) => {
  return value > 0
    ? [
        {
          UserId: userIdGuid,
          MeasureId: measureId,
          MeasureUnitId: measureUnitId,
          Value: Number(value),
        },
      ]
    : []
}

export const getLife365ProgramName = (programId) => {
  const enumId = Object.entries(Life365ProgramsIds)
    .find(([key, value]) => value === programId)
    .shift()

  return Object.entries(Life365ProgramsDisplayName)
    .find(([key, value]) => key == enumId)
    .pop()
}

export const getInputPropsTitleBySystemOfMeasure = (name, systemOfMeasure) => {
  switch (name) {
    case 'Temperature':
      return systemOfMeasure === SystemOfMeasures.Metric ? '℃' : '℉'
    case 'BloodPressureSystolic':
    case 'BloodPressureDiastolic':
      return 'mmHg'
    case 'GlucoseLevel':
      return systemOfMeasure === SystemOfMeasures.Metric ? 'mmol/L' : 'mg/dL'
    case 'Pulse':
    case 'RestingPulse':
      return 'bpm'
    case 'OxygenSaturation':
      return '%'
    case 'Height':
      return systemOfMeasure === SystemOfMeasures.Metric ? 'cm.' : ['ft.', 'in.']
    case 'Weight':
      return systemOfMeasure === SystemOfMeasures.Metric ? 'kg.' : 'lbs.'
    case 'PerfusionIndex':
      return '%'
    case 'PeakExpiratoryFlow':
      return 'L/min'
    case 'ForcedExpiratoryVolume':
      return 'L'
    default:
      return ''
  }
}

export const setHealthHistoriesWhenSaving = (data, initialValues) => {
  switch (Number(data.SystemOfMeasure)) {
    case SystemOfMeasures.Metric:
      if (Number(data.HeightCm) !== Number(initialValues.HeightCm)) {
        data.Height = data.HeightCm
      }

      if (Number(data.WeightKg) !== Number(initialValues.WeightKg)) {
        data.Weight = data.WeightKg
      }
      break
    case SystemOfMeasures.Imperial:
      if (
        Number(data.HeightFt) !== Number(initialValues.HeightFt) ||
        Number(data.HeightIn) !== Number(initialValues.HeightIn)
      ) {
        data.Height = getInchesForSave(data.HeightFt, data.HeightIn)
      }

      if (Number(data.WeightLbs) !== Number(initialValues.WeightLbs)) {
        data.Weight = data.WeightLbs
      }
      break
  }

  return data
}

export const getAddress1GoogleMapsLink = (address) =>
  `http://maps.google.com/?q=${address.Country ? `${address.Country}` : ''} ${address.City ? `${address.City}` : ''} ${
    address.Line1 ? `${address.Line1}` : ''
  }`

export const getAddress2GoogleMapsLink = (address) =>
  `http://maps.google.com/?q=${address.Country ? `${address.Country}` : ''} ${address.City ? `${address.City}` : ''} ${
    address.Line2 ? `${address.Line2}` : ''
  }`

export const createInputProps = (name, adornmentTextClass) => ({
  startAdornment: (
    <InputAdornment position='start'>
      <Typography className={adornmentTextClass}>{name}: </Typography>
    </InputAdornment>
  ),
})

export const validateMobileUserEmail = (email) => {
  const regex = /^[0-9]*@collaborate\.center$/
  return regex.test(email)
}

export const getMeasureUnitIdByMeasureType = (measureType, systemOfMeasure) => {
  let measureUnitId
  switch (measureType) {
    case HealthDataTypes.Temperature: {
      measureUnitId =
        Number(systemOfMeasure) === SystemOfMeasures.Metric
          ? IOTDeviceUnitType.DegreesCelsius
          : IOTDeviceUnitType.DegreesFahrenheit
      break
    }
    case HealthDataTypes.BloodPressureSystolic:
    case HealthDataTypes.BloodPressureDiastolic: {
      measureUnitId = IOTDeviceUnitType.MillimetersOfMercury
      break
    }
    case HealthDataTypes.GlucoseLevel: {
      measureUnitId =
        Number(systemOfMeasure) === SystemOfMeasures.Metric
          ? IOTDeviceUnitType.MillimolesPerLiter
          : IOTDeviceUnitType.MilligramsPerDeciliter
      break
    }
    case HealthDataTypes.Pulse:
    case HealthDataTypes.RestingPulse:
    case HealthDataTypes.PerfusionIndex: {
      measureUnitId = IOTDeviceUnitType.BeatsPerMinute
      break
    }
    case HealthDataTypes.OxygenSaturation: {
      measureUnitId = IOTDeviceUnitType.Percentage
      break
    }
    case HealthDataTypes.Weight: {
      measureUnitId =
        Number(systemOfMeasure) === SystemOfMeasures.Metric ? IOTDeviceUnitType.Kilogram : IOTDeviceUnitType.Pound
      break
    }
    case HealthDataTypes.Height: {
      measureUnitId =
        Number(systemOfMeasure) === SystemOfMeasures.Metric ? IOTDeviceUnitType.Centimeter : IOTDeviceUnitType.Inch
      break
    }
    case HealthDataTypes.PeakExpiratoryFlow: {
      measureUnitId = IOTDeviceUnitType.LitersPerMinute
      break
    }
    case HealthDataTypes.ForcedExpiratoryVolume: {
      measureUnitId = IOTDeviceUnitType.Liters
      break
    }
  }

  return measureUnitId
}
