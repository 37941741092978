import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    chartsSelect: {
      maxWidth: 810,
      [theme.breakpoints.up('lg')]: {
        maxWidth: 650,
      },
    },
  }
})
