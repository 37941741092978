import pluralize from 'pluralize'
import _ from 'lodash'

import { DEFAULT_TRAINING_MATERIALS_TITLE } from '../../constants'

export class TrainingMaterialTitleFormatter {
  title = DEFAULT_TRAINING_MATERIALS_TITLE

  setTitle(title) {
    if (!title) {
      return
    }

    this.title = title
  }

  format(text) {
    const multipleTrainingMaterialsTitle = this.title
    const singleTrainingMaterialTitle = pluralize.singular(multipleTrainingMaterialsTitle)

    return text
      .replace('Training materials', _.capitalize(multipleTrainingMaterialsTitle))
      .replace('training materials', _.lowerFirst(multipleTrainingMaterialsTitle))
      .replace('Training material', _.capitalize(singleTrainingMaterialTitle))
      .replace('training material', _.lowerFirst(singleTrainingMaterialTitle))
  }
}

export const trainingMaterialTitleFormatter = new TrainingMaterialTitleFormatter()
