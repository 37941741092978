import { all } from 'redux-saga/effects'

import addClassificationAnnotations from './addClassificationAnnotations'
import addFolderPages from './addFolderPages'

function* classificationAnnotationsPagesTimeline() {
  yield all([addClassificationAnnotations(), addFolderPages()])
}

export default classificationAnnotationsPagesTimeline
