import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      height: '100%',
      width: '100%',
    },
    icon: {
      fontSize: 42,
    },
  }
})
