import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

import { FormAnswersFromFileLoadingStatus } from '../services/enums'

const defaultState = {
  status: FormAnswersFromFileLoadingStatus.None,
  errors: [],
}

export default handleActions(
  {
    [rawActions.addFormAnswersFromFileRequest](state) {
      return { ...state, status: FormAnswersFromFileLoadingStatus.Loading }
    },
    [rawActions.setFormAnswersFromFileLoadingState](state, action) {
      const { status, errors } = action.payload
      return { status, errors: status === FormAnswersFromFileLoadingStatus.Failed ? errors : [] }
    },
    [rawActions.resetFormsState]() {
      return defaultState
    },
  },
  defaultState
)
