import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { getFormattedUtcDate } from '@tabeeb/services/dateTimeService'
import { ContentReviewStatus, ContentSharingType } from '@tabeeb/enums'
import SharedForReviewContent from '../SharedForReviewContent'
import ContentDescription from '../SharedForReviewContent/ContentDescription'
import './styles.less'

const SharedForReviewForm = ({ form, formReview, revokeReview }) => {
  const reviewerName = formReview.ReviewerName
  const createdOn = getFormattedUtcDate(formReview.CreatedOn, 'MMM D, YYYY h:mm A')
  const isMergeReview = formReview.Type === ContentSharingType.MergeForReview

  return (
    <div className='selected-forms-item-container'>
      <div className='shared-for-review-form'>
        <span className='shared-for-review-form-name'>{form.Name}</span>
        {formReview.ReviewStatus === ContentReviewStatus.Pending && (
          <Button onClick={revokeReview} variant='contained' color='primary' size='small'>
            Revoke
          </Button>
        )}
      </div>
      <span className='shared-for-review-form-message'>{`Exported for review to ${reviewerName} on ${createdOn}`}</span>
      {isMergeReview ? (
        <ContentDescription description={formReview.TargetContentName} />
      ) : (
        <SharedForReviewContent tenantId={formReview.ReviewerTenantId} />
      )}
    </div>
  )
}

SharedForReviewForm.propTypes = {
  formReview: PropTypes.shape({
    ReviewerName: PropTypes.string.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    Type: PropTypes.number.isRequired,
    ReviewStatus: PropTypes.number.isRequired,
    ReviewerTenantId: PropTypes.number.isRequired,
    TargetContentName: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    Name: PropTypes.string.isRequired,
  }).isRequired,
  revokeReview: PropTypes.func.isRequired,
}

export default SharedForReviewForm
