import { handleActions, combineActions } from 'redux-actions'

import {
  onSetRecordingStopData,
  updateRecordingState,
  onRecordingStarting,
  onRecordingStarted,
  onRecordingStopped,
  onRecordingPaused,
  onSetErrorState,
  resetRecordingState,
} from '../actions'

const defaultValue = 0

export default handleActions(
  {
    [onSetRecordingStopData](state, { payload }) {
      return payload
    },
    [combineActions(updateRecordingState, onRecordingPaused, onRecordingStopped, onSetErrorState)](state, { payload }) {
      return typeof payload.stopDate !== 'undefined' ? payload.stopDate : state
    },
    [combineActions(onRecordingStarting, onRecordingStarted)](state, { payload }) {
      return 0
    },
    [resetRecordingState](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
