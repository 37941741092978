import { handleActions } from 'redux-actions'

import * as muteAudioActions from '../actions/muteAudio'

const defaultValue = false

export default handleActions(
  {
    [muteAudioActions.onMuteAudioProgressStateUpdate](state, action) {
      return action.payload
    },
  },
  defaultValue
)
