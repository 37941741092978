import { useCallback, useMemo, useRef, useState } from 'react'

function useGoogleMaps({ defaultZoom, onGoogleApiLoaded }) {
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const mapRef = useRef(/** @type {google.maps.Map | null} */ null)
  const mapsApiRef = useRef(/** @type {google.maps | null} */ null)

  const [zoom, setZoom] = useState(defaultZoom)
  const [bounds, setBounds] = useState([0, 0, 0, 0])

  const handleMapChange = useCallback(({ zoom: newZoom, bounds: newBounds }) => {
    setZoom(newZoom)
    setBounds([newBounds.nw.lng, newBounds.se.lat, newBounds.se.lng, newBounds.nw.lat])
  }, [])

  const handleGoogleApiLoaded = useCallback(
    ({ map, maps }) => {
      mapRef.current = map
      mapsApiRef.current = maps
      onGoogleApiLoaded({ map, maps })
      setIsMapLoaded(true)
    },
    [onGoogleApiLoaded]
  )

  return useMemo(
    () => ({
      isMapLoaded,
      mapRef,
      mapsApiRef,
      zoom,
      bounds,
      onMapChange: handleMapChange,
      onGoogleApiLoaded: handleGoogleApiLoaded,
    }),
    [bounds, handleMapChange, isMapLoaded, zoom, handleGoogleApiLoaded]
  )
}

export default useGoogleMaps
