import { Autocomplete, TextField, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { darken, lighten } from '@mui/system'
import PropTypes from 'prop-types'

const AutocompleteCertificateType = ({
  getAllTypesResponse,
  category,
  scope,
  value,
  onChange,
  getAllTypesLoading,
  autocompleteProps,
  inputProps,
  limitTags,
  onInputChange,
}) => {
  const theme = useTheme()
  const options = useMemo(
    () =>
      getAllTypesResponse
        .map((option) => {
          const firstLetter = option.Name[0].toUpperCase()
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
          }
        })
        .sort((a, b) => -b.Name.localeCompare(a.Name)),
    [getAllTypesResponse]
  )

  return (
    <Autocomplete
      sx={{ flexGrow: 1 }}
      limitTags={limitTags}
      ChipProps={{ size: 'small', sx: { mb: '2px !important' } }}
      renderInput={(params) => (
        <TextField {...params} label='Types' variant='standard' placeholder='Search type name...' {...inputProps} />
      )}
      options={
        !category && !scope
          ? options
          : options.filter((type) => {
              let categoryMatches = true
              let scopeMatches = true

              if (category) {
                categoryMatches = type.CategoryId === Number(category)
              }

              if (scope) {
                scopeMatches = type.ScopeId === Number(scope)
              }

              return categoryMatches && scopeMatches
            })
      }
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.Name}
      renderOption={(props, option) => (
        <li {...props} key={option.Id}>
          {option.Name}
        </li>
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <div
            style={{
              position: 'sticky',
              top: '-8px',
              padding: '4px 10px',
              color: theme.palette.primary.main,
              backgroundColor:
                theme.palette.mode === 'light'
                  ? lighten(theme.palette.primary.light, 0.85)
                  : darken(theme.palette.primary.main, 0.8),
            }}
          >
            {params.group}
          </div>
          <ul style={{ padding: 0 }}>{params.children}</ul>
        </li>
      )}
      value={value}
      onChange={onChange}
      onInputChange={(event, stringValue) => onInputChange(stringValue)}
      loading={getAllTypesLoading}
      isOptionEqualToValue={(option, optionValue) => option.Id === optionValue.Id}
      {...autocompleteProps}
    />
  )
}

AutocompleteCertificateType.defaultProps = {
  limitTags: 1,
}

AutocompleteCertificateType.propTypes = {
  getAllTypesResponse: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      CategoryId: PropTypes.number.isRequired,
      ScopeId: PropTypes.number.isRequired,
    })
  ).isRequired,
  category: PropTypes.number,
  scope: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
        CategoryId: PropTypes.number.isRequired,
        ScopeId: PropTypes.number.isRequired,
      })
    ),
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      CategoryId: PropTypes.number.isRequired,
      ScopeId: PropTypes.number.isRequired,
    }),
  ]),
  onChange: PropTypes.func.isRequired,
  getAllTypesLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autocompleteProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  limitTags: PropTypes.number,
  onInputChange: PropTypes.func.isRequired,
}

export default AutocompleteCertificateType
