import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Rect, Shape } from 'react-konva'
import { useTheme } from '@material-ui/core'

const SelectionArea = ({ stageWidth, stageHeight, x, y, width, height, draggable, onMove }) => {
  const ref = useRef()
  const theme = useTheme()

  const dragBoundFunction = (position, a, b) => {
    return {
      x: Math.min(Math.max(0, position.x), Math.max(stageWidth - width, 0)),
      y: Math.min(Math.max(0, position.y), Math.max(stageHeight - height, 0)),
    }
  }

  const setCursor =
    (cursor) =>
    ({ target }) => {
      const stage = target.getStage()
      if (!stage) {
        return
      }

      stage.container().style.cursor = cursor
    }

  return (
    <>
      <Shape
        width={stageWidth}
        height={stageHeight}
        fill={theme.palette.action.active}
        sceneFunc={(context, shape) => {
          context.beginPath()
          context.moveTo(0, 0)
          context.lineTo(shape.width(), 0)
          context.lineTo(shape.width(), shape.height())
          context.lineTo(0, shape.height())
          context.lineTo(0, 0)

          context.lineTo(x, y)
          context.lineTo(x, y + height)
          context.lineTo(x + width, y + height)
          context.lineTo(x + width, y)
          context.lineTo(x, y)
          context.lineTo(0, 0)

          context.fillStrokeShape(shape)
        }}
      />
      <Rect
        ref={ref}
        x={x}
        y={y}
        width={width}
        height={height}
        stroke='white'
        strokeWidth={2}
        draggable={draggable}
        dragBoundFunc={dragBoundFunction}
        onDragMove={onMove}
        onMouseEnter={draggable && setCursor('move')}
        onMouseLeave={draggable && setCursor(null)}
      />
    </>
  )
}

SelectionArea.propTypes = {
  stageWidth: PropTypes.number.isRequired,
  stageHeight: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  draggable: PropTypes.bool.isRequired,
  onMove: PropTypes.func.isRequired,
}

export default React.memo(SelectionArea)
