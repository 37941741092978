import { select, all, take, takeEvery } from 'redux-saga/effects'

import * as signalrActions from '../actions'

import { getSignalRConnection, getSignalRConnectionState } from '../selectors'
import { tabeebHubName } from '../services/constants'

function* invokeHubAction(action) {
  const { method, args = [], hubName = tabeebHubName } = action.payload

  const isEstablished = yield select((state) => getSignalRConnectionState(state, hubName))
  if (!isEstablished) {
    while (true) {
      const successAction = yield take(signalrActions.createConnectionSuccess)
      if (successAction.payload.hubName === hubName) {
        break
      }
    }
  }
  const connection = yield select((state) => getSignalRConnection(state, hubName))
  connection.invoke(method, ...args).catch((e) => {})
}

export default function* hubCalls() {
  yield all([takeEvery(signalrActions.invokeHubAction, invokeHubAction)])
}
