import PropTypes from 'prop-types'
import routes from '@tabeeb/routes'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'

import { Header, Body, Wrapper, Footer, BottomLinksContainer } from './styles'
import ErrorMessage from '../errorMessage'

const MobileLayout = ({ header, form, alts, errorMessage, showPrivacyPolicyAndTermsOfUse }) => {
  return (
    <Wrapper>
      <Body>
        <Header>{header}</Header>
        <ErrorMessage errorMessage={errorMessage} />
        {form}
        {alts}
      </Body>
      <Footer>
        {showPrivacyPolicyAndTermsOfUse && (
          <BottomLinksContainer>
            <Link variant='body2' to={routes.appTermsOfUse}>
              <Typography variant='body2'>Terms of use</Typography>
            </Link>
            <Link variant='body2' to={routes.appPrivacyPolicy}>
              <Typography variant='body2'>Privacy policy</Typography>
            </Link>
          </BottomLinksContainer>
        )}
      </Footer>
    </Wrapper>
  )
}

MobileLayout.defaultProps = {
  showPrivacyPolicyAndTermsOfUse: false,
}

MobileLayout.propTypes = {
  header: PropTypes.node.isRequired,
  form: PropTypes.node.isRequired,
  alts: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
  showPrivacyPolicyAndTermsOfUse: PropTypes.bool,
}

export default MobileLayout
