import { memo } from 'react'

import { Box, Fade, Typography } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'

const NoSncQuestionsFoundPlaceholder = () => {
  return (
    <Fade in>
      <Box height='100%' width='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <SearchOutlined fontSize='large' />
        <Typography variant='body1' textAlign='center'>
          No available options. Please enable session naming convention to use this feature
        </Typography>
      </Box>
    </Fade>
  )
}

export default memo(NoSncQuestionsFoundPlaceholder)
