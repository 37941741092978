import PropTypes from 'prop-types'
import classNames from 'classnames'

import ActivityItemContainer from '../../containers/ActivityItemContainer'

import './styles.less'

const ActivityList = ({ activities, getListRef, onScroll, searchInProgress }) => {
  return (
    <ul
      ref={getListRef}
      className={classNames({ activitiesList: true, 'activities-progress-bar': searchInProgress })}
      onScroll={onScroll}
    >
      {activities.map((activity, index) => (
        <ActivityItemContainer key={`${activity.id}-${index}`} activity={activity} />
      ))}
    </ul>
  )
}

ActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
  onScroll: PropTypes.func.isRequired,
  getListRef: PropTypes.func.isRequired,
  searchInProgress: PropTypes.bool.isRequired,
}

export default ActivityList
