import { combineActions, handleActions } from 'redux-actions'

import { resetContentState } from '@tabeeb/modules/shared/content/actions'
import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setOperatingFolderId](state, action) {
      return action.payload
    },
    [combineActions(rawActions.resetContentTrashBinDialogItem, resetContentState)](state, action) {
      return defaultState
    },
  },
  defaultState
)
