import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import { onLogout } from '@tabeeb/modules/account/actions'

import { getSessionPermissionsRequest, getSessionPermissionsSuccess, setSessionPermissions } from '../actions'

const defaultValue = {
  status: FetchStatus.Idle,
  value: [],
}

export default handleActions(
  {
    [getSessionPermissionsRequest](state) {
      return {
        ...state,
        status: FetchStatus.Loading,
      }
    },
    [getSessionPermissionsSuccess](state, { response }) {
      const permissions = response.data.Permissions

      return {
        status: FetchStatus.Loaded,
        value: permissions,
      }
    },
    [setSessionPermissions](state, { payload }) {
      return {
        ...state,
        value: payload,
      }
    },
    [onLogout]: (state, action) => {
      return defaultValue
    },
  },
  defaultValue
)
