import { handleActions } from 'redux-actions'
import {
  deleteContentsLinkFromList,
  getLinkedContents,
  linkContents,
  updateContentsLink,
} from '@tabeeb/modules/contentSharings/actions'

const defaultValue = {
  list: [],
  isLoaded: false,
}

export default handleActions(
  {
    [getLinkedContents.success](state, { response: { data } }) {
      return { ...state, list: data, isLoaded: true }
    },
    [linkContents.success](state, { response: { data } }) {
      return { ...state, list: state.list.some((lc) => lc.Id === data.Id) ? [...state.list] : [...state.list, data] }
    },
    [updateContentsLink](state, { payload }) {
      return {
        ...state,
        list: state.list.map((link) => (link.Id === payload.Id ? { ...link, Status: payload.Status } : link)),
      }
    },
    [deleteContentsLinkFromList](state, { payload }) {
      return { ...state, list: state.list.filter((link) => link.Id !== payload) }
    },
  },
  defaultValue
)
