import { Block, Done, Error, HourglassBottom, Loop } from '@mui/icons-material'
import { Chip } from '@mui/material'
import PropTypes from 'prop-types'

import { UserAssignedQualificationStatusType, UserAssignedQualificationStatusTypeDisplayName } from '@tabeeb/enums'

const UserQualificationChip = ({ statusId }) => {
  return (
    <Chip
      label={UserAssignedQualificationStatusTypeDisplayName[statusId]}
      size='small'
      sx={{
        height: 'auto',
        minHeight: '24px',
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
      color={
        statusId === UserAssignedQualificationStatusType.Active
          ? 'success'
          : statusId === UserAssignedQualificationStatusType.Revoked ||
            statusId === UserAssignedQualificationStatusType.CertificateTypeDeleted ||
            statusId === UserAssignedQualificationStatusType.QualificationDeleted
          ? 'error'
          : statusId === UserAssignedQualificationStatusType.CertificateExpired ||
            statusId === UserAssignedQualificationStatusType.VersionExpired
          ? 'warning'
          : statusId === UserAssignedQualificationStatusType.Pending
          ? 'primary'
          : 'default'
      }
      icon={
        statusId === UserAssignedQualificationStatusType.Active ? (
          <Done />
        ) : statusId === UserAssignedQualificationStatusType.Revoked ||
          statusId === UserAssignedQualificationStatusType.CertificateTypeDeleted ||
          statusId === UserAssignedQualificationStatusType.QualificationDeleted ? (
          <Block />
        ) : statusId === UserAssignedQualificationStatusType.Pending ? (
          <Loop />
        ) : statusId === UserAssignedQualificationStatusType.CertificateExpired ||
          statusId === UserAssignedQualificationStatusType.VersionExpired ? (
          <Error />
        ) : (
          <HourglassBottom />
        )
      }
    />
  )
}

UserQualificationChip.propTypes = {
  statusId: PropTypes.number,
}

export default UserQualificationChip
