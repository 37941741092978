import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import * as rawActions from '../actions'
import * as galleryActions from '../../gallery/actions'

import { FileUploadStatus } from '../constants'
import FileUploadsControls from '../components/FileUploadsControls'

import { getFailedUploadsCount, getFileUploads, getIncompleteUploadsCount } from '../selectors'

class FileUploadsControlsContainer extends Component {
  state = {
    isDialogOpened: false,
  }

  _handleRetryAll = () => {
    const {
      fileUploads,
      galleryActions: { addFilesToQueue },
    } = this.props

    const files = fileUploads
      .filter((item) => item.status === FileUploadStatus.Canceled || item.status === FileUploadStatus.Failed)
      .map((item) => item.file)

    if (files.length > 0) {
      addFilesToQueue({ files, retry: true })
    }
  }

  _handleCancelClosing = () => {
    this.setState({ isDialogOpened: false })
  }

  _handleSubmitClosing = () => {
    this.setState({ isDialogOpened: false })

    const {
      actions: { clearFileUploads, cancelFileUploads },
      fileUploads,
    } = this.props

    clearFileUploads()
    const unfinishedUploads = fileUploads.filter((fileUpload) => fileUpload.status !== FileUploadStatus.Done)
    cancelFileUploads(unfinishedUploads)
  }

  _handleClose = () => {
    const {
      incompleteUploadsCount,
      actions: { clearFileUploads },
    } = this.props

    if (incompleteUploadsCount > 0) {
      this.setState({ isDialogOpened: true })

      return
    }

    clearFileUploads()
  }

  render() {
    const { isDialogOpened } = this.state
    const { buttonColor, failedUploadsCount } = this.props

    const props = {
      buttonColor,
      isDialogOpened,
      isRetryButtonDisabled: failedUploadsCount === 0,
      handleClose: this._handleClose,
      handleRetryAll: this._handleRetryAll,
      handleCancelClosing: this._handleCancelClosing,
      handleSubmitClosing: this._handleSubmitClosing,
    }

    return <FileUploadsControls {...props} />
  }
}

FileUploadsControlsContainer.propTypes = {
  fileUploads: PropTypes.array.isRequired,
  failedUploadsCount: PropTypes.number.isRequired,
  incompleteUploadsCount: PropTypes.number.isRequired,
  buttonColor: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    clearFileUploads: PropTypes.func.isRequired,
  }).isRequired,
  galleryActions: PropTypes.shape({
    addFilesToQueue: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    fileUploads: getFileUploads(state),
    failedUploadsCount: getFailedUploadsCount(state),
    incompleteUploadsCount: getIncompleteUploadsCount(state),
    buttonColor: appConfigStateSelectors.getAddButtonColor(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
  galleryActions: bindActionCreators(galleryActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadsControlsContainer)
