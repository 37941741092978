import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, CircularProgress, Divider, InputBase, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'

import { FetchStatus, TenantPermission } from '@tabeeb/enums'
import domainService from '@tabeeb/services/domainService'
import { getTenantName } from '@tabeeb/modules/appConfigState/selectors'
import { getIdentityMeSelector, getIdentityMeFetchStatus } from '@tabeeb/modules/account/selectors'
import { getIdentityMe } from '@tabeeb/modules/account/actions'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import routes from '@tabeeb/routes'

import useStyles from './styles'

const SwitchTenantAutocomplete = forwardRef(({ handleClose }, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentTenantName = useSelector(getTenantName)
  const identityMe = useSelector(getIdentityMeSelector)
  const identityMeFetchStatus = useSelector(getIdentityMeFetchStatus)
  const [filteredOptions, setFilteredOptions] = useState([])
  const hasAllTenantsAccess = useSelector((state) => hasTenantPermission(state, TenantPermission.AllTenantsAccess))

  const { tenantGroups, tenantGroupKeys } = useMemo(() => {
    const groups = Object.groupBy(filteredOptions, (tenant) => tenant.businessDomainName || 'Unknown domain')
    return { tenantGroups: groups, tenantGroupKeys: Object.keys(groups) }
  }, [filteredOptions])

  const fetchedOptions = useMemo(() => {
    const options = identityMe.userTenants?.map((userTenant) => {
      return {
        label: userTenant.tenantName,
        id: userTenant.tenantId,
        subdomain: userTenant.tenantSubdomain,
        businessDomainName: userTenant.businessDomainName,
      }
    })
    const resultOptions = options || []
    setFilteredOptions(resultOptions)
    return resultOptions
  }, [identityMe.userTenants])

  useEffect(() => {
    dispatch(getIdentityMe.request())
  }, [dispatch])

  const filterOptions = debounce((name) => {
    let options = fetchedOptions
    if (name && fetchedOptions) {
      options = fetchedOptions.filter((userTenant) => userTenant.label.toLowerCase().includes(name.toLowerCase()))
    }
    setFilteredOptions(options)
  }, 300)

  const handleSearchChange = useCallback(
    (e) => {
      filterOptions(e.target.value)
    },
    [filterOptions]
  )

  const loading = useMemo(() => identityMeFetchStatus === FetchStatus.Loading, [identityMeFetchStatus])

  return (
    <div className={classes.container}>
      <div className={classes.searchInputContainer}>
        <SearchIcon />
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder='Search Tenants'
          onChange={handleSearchChange}
          inputProps={{
            maxLength: 40,
          }}
        />
      </div>
      <Divider />
      <div className={classes.tenantsListContainer}>
        {hasAllTenantsAccess && (
          <>
            <Link className={classes.link} to={routes.tenants} value={routes.tenants} onClick={handleClose}>
              <Typography>Go to tenants management page</Typography>
            </Link>
            <Divider />
          </>
        )}
        {loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress color='info' size={30} thickness={6} sx={{ alignSelf: 'center', margin: '10px 0' }} />
          </div>
        )}
        {!loading &&
          tenantGroupKeys &&
          tenantGroupKeys.map((groupName) => (
            <>
              {tenantGroupKeys.length > 1 && (
                <li className={classes.groupHeader} key={groupName}>
                  <Typography
                    title={groupName}
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    {groupName}
                  </Typography>
                </li>
              )}
              {tenantGroups[groupName].map((option) => (
                <li className={classes.listOptionItem} key={option.id}>
                  <Button
                    fullWidth
                    disabled={option.label === currentTenantName}
                    sx={{
                      borderRadius: 0,
                      minWidth: 'unset',
                      backgroundColor: option.label === currentTenantName ? 'rgba(66, 66, 66, 0.04)' : 'white',
                    }}
                    href={domainService.createOriginWithSubdomain(`${option.subdomain}.`)}
                  >
                    <Typography
                      title={option.label}
                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                      {option.label}
                    </Typography>
                  </Button>
                </li>
              ))}
            </>
          ))}
      </div>
    </div>
  )
})

SwitchTenantAutocomplete.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default SwitchTenantAutocomplete
