export function getThumbnailUrl(url) {
  if (isYouTubeUrl(url)) {
    return getThumbnailForVideo(url)
  }

  if (isVimeoUrl(url)) {
    return getThumbnailForVideoVimeo(url)
  }
}

export function isYouTubeUrl(url) {
  return /(youtube\.com)|(youtu\.be)/i.test(url)
}

export function isVimeoUrl(url) {
  return /vimeo\.com/i.test(url)
}

export function getThumbnailForVideo(url) {
  const videoId = _getYouTubeVideoId(url)
  const thumbnailUrl = videoId
    ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    : 'Content/img/video-placeholder.png'

  return thumbnailUrl
}

export function getThumbnailForVideoVimeo(url) {
  const urlSplit = url.split('/')
  const id = urlSplit[urlSplit.length - 1]
  return window
    .fetch(`https://vimeo.com/api/v2/video/${id}.json`)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      const error = new Error(response.statusText || response.status)
      error.response = response
      return Promise.reject(error)
    })
    .then(
      (response) => {
        return response[0].thumbnail_large
      },
      (error) => {
        console.error('Video vimeo.com error:', error)
        return null
      }
    )
}

function _getYouTubeVideoId(url) {
  return isYouTubeUrl(url) ? _getQueryParameterByName(url, 'v') : null
}

function _getQueryParameterByName(url, name) {
  const replacedName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp(`[\\?&]${replacedName}=([^&#]*)`)
  const results = regex.exec(url)
  return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null
}
