import { combineActions, handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultValue = false

export default handleActions(
  {
    [rawActions.setNotificationsListLoading](state, { payload }) {
      return payload
    },
    [combineActions(rawActions.resetState, rawActions.resetNotificationsList)](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
