import { TopicType } from '@tabeeb/enums'
import _ from 'lodash'
import moment from 'moment'

export function convertDateToString(date) {
  date = moment(date)

  const now = moment()

  const isLessThanWeekAgo = now.startOf('day').diff(date.startOf('day'), 'weeks') < 1
  if (isLessThanWeekAgo) {
    const isToday = now.isSame(date, 'day')
    if (isToday) {
      return 'Today'
    }

    const isYesterday = now.subtract(1, 'day').isSame(date, 'day')
    if (isYesterday) {
      return 'Yesterday'
    }

    return date.format('dddd')
  }
  return date.format('dddd, MMMM D, yyyy')
}

export function groupMessages(messages, lastReadedMessageBarId, timeThreshold = 60000) {
  const messageGroups = []

  let containsUnreadMessages = false
  let previousMessage = {}

  for (const message of messages) {
    const currentMessageDate = moment(message.timeStamp)
    const previousMessageDate = moment(previousMessage.timeStamp || 0)

    const sameSender = message.userId === previousMessage.userId
    const sentOnSameDay = currentMessageDate.isSame(previousMessageDate, 'date')
    const sentWithinTimeThreshold = currentMessageDate - previousMessageDate < timeThreshold
    const firstUnreadMessage =
      !message.self && lastReadedMessageBarId && message.id > lastReadedMessageBarId && !containsUnreadMessages

    if (!sameSender || !sentOnSameDay || !sentWithinTimeThreshold || firstUnreadMessage) {
      messageGroups.push({
        firstUnread: false,
        newDate: false,
        list: [],
      })
    }

    const messagesGroup = _.last(messageGroups)

    if (firstUnreadMessage) {
      messagesGroup.firstUnread = true
      containsUnreadMessages = true
    }

    if (!sentOnSameDay) {
      messagesGroup.newDate = true
    }

    messagesGroup.list.push(message)

    previousMessage = message
  }

  return messageGroups
}

export const getChatMessageTextTranslation = (message, language) => {
  if (message && message.translations) {
    return message.translations[language]
  }

  return undefined
}

export const getIsTopicSupportPrivateMessages = (topicType) => topicType === TopicType.Session
export const getIsTopicSupportUnreadMessages = (topicType) => topicType === TopicType.Session
export const getIsTopicSupportTranslation = (topicType) => topicType === TopicType.Session

export const getChatId = ({ topicType, topicId, userId }) => `${topicType}-${topicId}-${userId}`

export const getChatDetailsById = (chatId) => {
  const chatIdDetails = chatId.split('-')

  return {
    topicType: Number(chatIdDetails[0]),
    topicId: Number(chatIdDetails[1]),
    userId: Number(chatIdDetails[2]),
  }
}
