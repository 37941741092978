import { handleActions, combineActions } from 'redux-actions'

import {
  createReminderRequest,
  updateReminderRequest,
  deleteReminderRequest,
  createReminderSuccess,
  createReminderFailed,
  updateReminderSuccess,
  updateReminderFailed,
  deleteReminderSuccess,
  deleteReminderFailed,
} from '../actions'

const defaultValue = false

export default handleActions(
  {
    [combineActions(createReminderRequest, updateReminderRequest, deleteReminderRequest)]() {
      return true
    },
    [combineActions(
      createReminderSuccess,
      createReminderFailed,
      updateReminderSuccess,
      updateReminderFailed,
      deleteReminderSuccess,
      deleteReminderFailed
    )]() {
      return false
    },
  },
  defaultValue
)
