const chatMessagesRequests = {
  getChatMessagesByContentIdRequest: ({ contentId, topicId, topicType, userId, messagesSkip, messagesTake }) => ({
    url: `api/chatMessage/bycontent/${contentId}`,
    method: 'get',
    params: {
      userId,
      topicId,
      topicType,
      messagesSkip,
      messagesTake,
    },
  }),
  sendChatMessageRequest: (data) => ({
    url: `api/chatMessage`,
    method: 'post',
    data,
  }),
  getMessageTranslationRequest: (data) => ({
    url: `api/chatMessage/translate`,
    method: 'post',
    data: {
      messageId: data.message.id,
      targetLanguage: data.targetLanguage,
    },
  }),
  getTranslationLanguagesRequest: () => ({
    url: `api/chatMessage/translate/languages`,
    method: 'get',
  }),
  uploadAudioFileToChatBlobStorageRequest: ({ fileData, contentId }) => ({
    url: `api/uploads/audio`,
    method: 'post',
    data: {
      data: fileData,
      ContentId: contentId,
    },
  }),
  sendChatFileRequest: ({ topicId, topicType, contentId, receiverId, type, url, duration }) => ({
    url: 'api/chatMessage/file',
    method: 'post',
    data: {
      TopicId: topicId,
      TopicType: topicType,
      ContentId: contentId,
      ReceiverId: receiverId,
      Type: type,
      Url: url,
      Duration: duration,
    },
  }),
  getChatsRequest: ({ contentId }) => ({
    url: `api/chatMessage/chats/bycontent/${contentId}`,
    method: 'get',
  }),
  markAsReadRequest: ({ messageId }) => ({
    url: 'api/chatMessage/markAsRead',
    method: 'post',
    data: {
      MessageId: messageId,
    },
  }),
}

export default chatMessagesRequests
