import { useEffect, useMemo, useState } from 'react'
import { Checkbox, CircularProgress, Fade, ListItem, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import * as yup from 'yup'

import { useApiRequest, useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'
import { getIsSheetNameUnique } from '@tabeeb/modules/contentMetadata/actions'
import { SheetNameMaxLength } from '@tabeeb/modules/contentMetadata/services/constants'
import { maxLength, requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'

const sheetNameSchema = yup
  .string()
  .trim()
  .required(requiredField)
  .max(SheetNameMaxLength, maxLength(SheetNameMaxLength))

const SheetImportItem = ({ sheetIndex, originalSheetName, onChange, contentId }) => {
  const [customName, setCustomName] = useState(originalSheetName)
  const [overwrite, setOverwrite] = useState(false)

  const debouncedCustomName = useDebouncedValue(customName, 300)

  const { validationMessage, isNameInvalid } = useMemo(() => {
    try {
      sheetNameSchema.validateSync(debouncedCustomName)
      return { validationMessage: '', isNameInvalid: false }
    } catch (e) {
      return { validationMessage: e.message || '', isNameInvalid: true }
    }
  }, [debouncedCustomName])

  const checkIsNameUniquePayload = useMemo(
    () => ({ contentId, name: debouncedCustomName.trim() }),
    [contentId, debouncedCustomName]
  )

  const { response, loading, loaded } = useApiRequest({
    enabled: !isNameInvalid,
    defaultResponse: [],
    request: getIsSheetNameUnique.request,
    payload: checkIsNameUniquePayload,
  })

  const showOverwriteCheckbox = useMemo(() => !response?.IsUnique && loaded, [response, loaded])

  const nameIsNotUnique = showOverwriteCheckbox && !overwrite
  const isError = nameIsNotUnique || isNameInvalid

  useEffect(() => {
    if (loaded) {
      onChange({ newCustomName: customName.trim(), hasError: isError })
    }
    if (loading) {
      onChange({ isLoading: loading })
    }
  }, [loaded, isError, loading])

  const helperText = nameIsNotUnique
    ? 'This name is taken. Please enter a new name or check the overwrite option.'
    : validationMessage

  return (
    <ListItem sx={{ p: 0 }} key={sheetIndex}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          gap: 2,
          mb: 2,
        }}
      >
        <Typography sx={{ width: '40%' }}>Original Name: {originalSheetName}</Typography>
        <Box sx={{ width: '60%', display: 'flex' }}>
          <TextField
            sx={{ flex: '1 1 0' }}
            size='small'
            label='Import Name'
            value={customName}
            onChange={(e) => {
              setCustomName(e.target.value)
            }}
            error={isError}
            helperText={helperText}
            InputProps={{
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Fade in={showOverwriteCheckbox} unmountOnExit>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={overwrite}
                        onChange={(event) => {
                          const { checked } = event.target
                          setOverwrite(checked)
                          onChange(customName)
                        }}
                      />
                      <Typography>Overwrite</Typography>
                    </Box>
                  </Fade>
                  {loading && <CircularProgress size={24} />}
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
    </ListItem>
  )
}

SheetImportItem.propTypes = {
  sheetIndex: PropTypes.number.isRequired,
  originalSheetName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  contentId: PropTypes.number.isRequired,
}

export default SheetImportItem
