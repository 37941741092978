import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = false

const isMetadataLoading = handleActions(
  {
    [actions.setContentMetadataExportMode](state, action) {
      return true
    },
    [actions.resetContentMetadataExportMode](state, action) {
      return false
    },
    [actions.closeContentMetadataDialog](state, action) {
      return false
    },
  },
  defaultState
)

export default isMetadataLoading
