import { ReminderRepeatMode, ReminderCustomRepeatMode, ReminderCustomRepeatEndOnType, DaysOfWeek } from '@tabeeb/enums'

export const customRepeatModeOptions = {
  [ReminderCustomRepeatMode.Minute]: 'Minute',
  [ReminderCustomRepeatMode.Hour]: 'Hour',
  [ReminderCustomRepeatMode.Day]: 'Day',
  [ReminderCustomRepeatMode.Week]: 'Week',
  [ReminderCustomRepeatMode.Month]: 'Month',
  [ReminderCustomRepeatMode.Year]: 'Year',
}

export const customRepeatEndModeOptions = {
  [ReminderCustomRepeatEndOnType.Never]: 'Never',
  [ReminderCustomRepeatEndOnType.Date]: 'On',
  [ReminderCustomRepeatEndOnType.Occurrences]: 'After',
}

function _getSelectedDays(reminderSelectedDays) {
  const selectedDays = []

  Object.keys(DaysOfWeek).map((stringDay) => {
    const dayNumber = parseInt(DaysOfWeek[stringDay], 10)

    const isSelected = (reminderSelectedDays & dayNumber) === dayNumber

    if (isSelected) selectedDays.push(stringDay)
  })

  return selectedDays
}

function _getCustomRepeatText(reminder) {
  const { Mode, Interval, SelectedDaysOfWeek, EndType, EndDate, EndOccurrences } = reminder.CustomRepeatSettings

  const mode = ` ${customRepeatModeOptions[Mode].toLowerCase()}${Interval > 1 ? 's' : ''}`

  const selectedDays =
    reminder.RepeatMode === ReminderRepeatMode.Weekly ? ` on ${_getSelectedDays(SelectedDaysOfWeek)}. ` : '. '

  const endText =
    EndType === ReminderCustomRepeatEndOnType.Date
      ? `on ${EndDate}.`
      : `after ${EndOccurrences} occurrence${EndOccurrences > 1 ? 's.' : '.'}`

  const ends = EndType === ReminderCustomRepeatEndOnType.Never ? '' : `Ends ${endText}`

  return `Repeat every ${Interval}${mode}${selectedDays}${ends}`
}

export function getRepeatModeOptions(reminder) {
  let customRepeatOptionText = 'Custom...'

  if (reminder.RepeatMode === ReminderRepeatMode.Custom) {
    if (!reminder.CustomRepeatSettings.IsDefault) {
      customRepeatOptionText = `Custom: ${_getCustomRepeatText(reminder)}`
    }
  }

  return {
    [ReminderRepeatMode.NoRepeat]: 'Does not repeat',
    [ReminderRepeatMode.Daily]: 'Daily',
    [ReminderRepeatMode.Weekly]: 'Weekly',
    [ReminderRepeatMode.Monthly]: 'Monthly',
    [ReminderRepeatMode.Annually]: 'Annually',
    [ReminderRepeatMode.EveryWeekday]: 'Every weekday (Monday to Friday)',
    [ReminderRepeatMode.Custom]: customRepeatOptionText,
  }
}
