import { put, take, takeEvery, takeLatest, select, all } from 'redux-saga/effects'

import { AnnotationType } from '@tabeeb/enums'

import * as drawingActions from '@tabeeb/modules/playerToolbar/actions'
import * as playerToolbarSelectors from '@tabeeb/modules/playerToolbar/selectors'

import * as galleryActions from '@tabeeb/modules/gallery/actions'
import * as aiActions from '@tabeeb/modules/artificialIntelligence/actions'
import * as contentStateSelectors from '@tabeeb/modules/shared/content/selectors'
import * as annotationsActions from '../actions'
import * as annotationsSelectors from '../selectors'

function* onSetDrawingColor(action) {
  const color = yield select(playerToolbarSelectors.getSelectedColor)
  const updatedProps = {
    Color: color,
  }

  yield updateSelectedAnnotation(updatedProps)
}

function* onSendStrokeAttributes(action) {
  const color = yield select(playerToolbarSelectors.getSelectedColor)
  const width = yield select(playerToolbarSelectors.getWidth)
  const fontSize = yield select(playerToolbarSelectors.getFontSize)
  const updatedProps = {
    Color: color,
    Width: width,
    FontSize: fontSize,
  }

  yield updateSelectedAnnotation(updatedProps)
}

function* updateSelectedAnnotation(updatedProps) {
  const [annotationId] = yield select(annotationsSelectors.getSelectedAnnotationsIds)
  if (annotationId) {
    const selectedAnnotation = yield select((state) =>
      annotationsSelectors.getAnnotationById(state, { Id: annotationId })
    )
    if (!selectedAnnotation) {
      return
    }

    if (selectedAnnotation.Type === AnnotationType.Text) {
      updatedProps.Width = undefined
    } else {
      updatedProps.FontSize = undefined
    }

    yield put(
      annotationsActions.updateAnnotationRequest({
        previous: selectedAnnotation,
        annotation: { ...selectedAnnotation, ...updatedProps },
      })
    )
  }
}

function* updateContentAnnotation(action) {
  const { annotation, ...rest } = action.payload

  const prevAnnotation = yield select((state) => annotationsSelectors.getAnnotationById(state, { Id: annotation.Id }))

  yield put(galleryActions.updateAnnotation(annotation))
  yield put(annotationsActions.updateAnnotationRequest({ previous: prevAnnotation, annotation, ...rest }))

  const result = yield take([annotationsActions.updateAnnotationFailed, annotationsActions.updateAnnotationSuccess])
  if (prevAnnotation && result.type === annotationsActions.updateAnnotationFailed().type) {
    yield put(galleryActions.updateAnnotation(prevAnnotation))
  }
}

function* updateAiAnnotationSuccess(action) {
  const contentId = yield select(contentStateSelectors.getContentId)
  const { contentForms } = yield select((state) => state.forms)

  const hasCounters = contentForms.some((cf) => cf.HasCounters || (cf.Counters && cf.Counters.length > 0))

  if (action.payload.Type === AnnotationType.AI) {
    if (hasCounters) {
      yield put(aiActions.getAiCounterInfoRequest({ contentId }))
      yield put(aiActions.checkConditionsRequest({ contentId }))
      yield put(aiActions.getAiObjectsCountRequest({ contentId }))
    }
  }
}

function* updateAnnotationSaga() {
  yield all([
    takeLatest(drawingActions.onSetDrawingColor, onSetDrawingColor),
    takeLatest(drawingActions.onSendStrokeAttributes, onSendStrokeAttributes),
    takeEvery(annotationsActions.updateContentAnnotation, updateContentAnnotation),
    takeEvery(annotationsActions.updateAiAnnotationSuccess, updateAiAnnotationSuccess),
  ])
}

export default updateAnnotationSaga
