import { handleActions } from 'redux-actions'

import { setFieldOfView } from '../actions'
import { Defaults } from '../constants'

const defaultState = Defaults.FIELD_OF_VIEW

export default handleActions(
  {
    [setFieldOfView](state, { payload: fov }) {
      return fov
    },
  },
  defaultState
)
