import { Grid, Skeleton } from '@mui/material'

const AddEditCertificateFormSkeleton = () => {
  return (
    <>
      <Grid item xs={12}>
        <Skeleton variant='rectangular' width={400} height={300} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={55} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={55} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height={55} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height={55} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={55} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height={55} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton height={55} />
      </Grid>
    </>
  )
}

export default AddEditCertificateFormSkeleton
