import { put, take, takeLatest, select, fork, all } from 'redux-saga/effects'
import { playerActions } from '@tabeeb/modules/player'
import { convertServiceAnnotationToPluginAnnotation } from '../../../services/dataConverter'
import selector from '../../shared/utils/selector'
import activitiesSelector from '../selectors'
import * as activityActions from '../actions'
import * as galleryActions from '../../gallery/actions'
import { accountSelectors } from '../../account'
import { contentStateSelectors, rawContentActions } from '../../shared/content'
import { annotationsActions } from '../../annotations'

function* updateActivitiesItems(action) {
  const page = action.payload
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const ownerId = yield select(contentStateSelectors.getOwnerId)
  const apiHostName = yield select(selector.getApiUrl)

  const isOwner = ownerId === page.Owner.Id
  const resultActivities = []
  page.Annotations.forEach((annotation) => {
    const convertedAnnotation = convertServiceAnnotationToPluginAnnotation(
      annotation,
      page,
      isOwner,
      currentUserId,
      apiHostName
    )
    resultActivities.push(convertedAnnotation)
  })
  yield put(activityActions.addActivities(resultActivities))
}

function* selectActivity({ payload }) {
  const selectedActivityId = payload

  const activitiesList = yield select((state) => state.activities.activitiesList.activities)
  const selectedActivity = activitiesList.find((activity) => activity.id === selectedActivityId)
  const selectedGalleryItemId = yield select((state) => state.gallery.galleryState.selectedGalleryItemId)

  if (selectedActivity && selectedActivity.pageId !== selectedGalleryItemId) {
    yield put(galleryActions.onSelectGalleryItem({ pageId: selectedActivity.pageId, updateWeb: true }))
    yield take([galleryActions.onGalleryItemSwitched])
  }

  if (selectedActivity) {
    let activity = selectedActivity
    while (activity && activity.parentId && activity.timestamp < 0) {
      activity = activitiesList.find((item) => item.id === activity.parentId)
    }

    if (activity && activity.timestamp >= 0) {
      yield put(playerActions.seekVideoToTimestamp(activity.timestamp * 1000))
    }

    if (selectedActivity.parentId) {
      yield put(annotationsActions.openHotspotDialog({ annotationId: selectedActivity.parentId }))
    } else {
      yield put(annotationsActions.setHighlightedAnnotationId(selectedActivityId))
    }
  }
}

function* onGetActivitiesByContentIdRequest(action) {
  yield put(activityActions.setSearchInProgress(true))
}

function* onGetActivitiesByContentIdSuccess(action) {
  const contentState = yield select(selector.getContent)
  const apiHostName = yield select(selector.getApiUrl)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  const resultActivities = []
  action.response.data.forEach((annotation) => {
    const convertedAnnotation = convertServiceAnnotationToPluginAnnotation(
      annotation,
      { Id: annotation.PageId },
      contentState.isOwner,
      currentUserId,
      apiHostName
    )
    resultActivities.push(convertedAnnotation)
  })
  yield put(activityActions.setActivities(resultActivities))
  yield put(activityActions.setSearchInProgress(false))
}

function* onGetActivitiesByFilter() {
  const contentState = yield select(selector.getContent)
  const activitiesSkip = yield select(activitiesSelector.getActivitiesSkip)
  const activitiesTake = yield select(activitiesSelector.getActivitiesTake)
  const activitiesFilter = yield select(activitiesSelector.getFilterOptions)

  const pageId =
    activitiesFilter.displayPage !== 'all'
      ? yield select((state) => state.gallery.galleryState.selectedGalleryItemId)
      : null
  const userId = activitiesFilter.userId !== 'all' ? activitiesFilter.userId : null
  const type = activitiesFilter.activityType !== 'all' ? activitiesFilter.activityType : null

  const isDisabledTextSearch = ['Image', 'Shape', 'Audio', 'Drawing'].includes(activitiesFilter.activityType)
  const isValidSearchText = activitiesFilter.searchFilter.trim() !== '' && activitiesFilter.searchFilter.length >= 2

  const searchFilter = isDisabledTextSearch || !isValidSearchText ? '' : activitiesFilter.searchFilter

  yield put(
    activityActions.getActivitiesByContentIdRequest({
      contentId: contentState.contentId,
      activitiesSkip,
      activitiesTake,
      filterOptions: {
        PageId: pageId,
        UserId: userId,
        Type: type,
        SearchQuery: searchFilter,
      },
    })
  )
}

function* onShouldRequestSend() {
  const shouldRequestSend = yield select(activitiesSelector.getShouldRequestSend)
  const searchInProgress = yield select(activitiesSelector.getSearchInProgress)
  if (shouldRequestSend && !searchInProgress) {
    yield put(activityActions.setActivitiesContentHeight(0))
    yield put(activityActions.resetActivities())
    yield put(activityActions.resetActivitiesSkipCount())

    yield fork(onGetActivitiesByFilter)
    yield put(activityActions.setShouldRequestSend(false))
  }
}

function* onFilterChange() {
  const activitiesFilter = yield select(activitiesSelector.getFilterOptions)
  const isValidSearchText = activitiesFilter.searchFilter.trim() !== '' && activitiesFilter.searchFilter.length >= 2

  if (activitiesFilter.activityType === 'all' && !isValidSearchText) {
    yield put(activityActions.setShouldRequestSend(false))
    yield put(activityActions.cleanActivitiesList())
    return
  }

  yield put(activityActions.setShouldRequestSend(true))
}

function* onGetContentSuccess() {
  const searchInProgress = yield select(activitiesSelector.getSearchInProgress)
  if (searchInProgress) {
    return
  }

  yield fork(onGetActivitiesByFilter)
}

function* onSelectGalleryItem() {
  const activitiesPanelIsOpen = yield select(activitiesSelector.getActivitiesPanelIsOpen)
  const { displayPage } = yield select(activitiesSelector.getFilterOptions)

  if (activitiesPanelIsOpen && displayPage === 'current') {
    yield onFilterChange()
  }
}

function* resetState() {
  yield put(activityActions.resetState())
}

function* activitiesSaga() {
  yield all([
    // throttle(500, activityActions.onEnterSearchTextFilter, onEnterSearchTextFilter)
    takeLatest(activityActions.updateActivitiesSuccess, updateActivitiesItems),
    takeLatest(activityActions.selectActivity, selectActivity),
    takeLatest(activityActions.getActivitiesByContentIdRequest, onGetActivitiesByContentIdRequest),
    takeLatest(activityActions.getActivitiesByContentIdSuccess, onGetActivitiesByContentIdSuccess),
    takeLatest(
      [
        activityActions.setPageFilter,
        activityActions.setUserFilter,
        activityActions.setActivityTypeFilter,
        activityActions.onEnterSearchTextFilter,
      ],
      onFilterChange
    ),
    takeLatest([activityActions.loadMoreActivities, activityActions.updateActivities], onGetContentSuccess),
    takeLatest([activityActions.setSearchInProgress, activityActions.setShouldRequestSend], onShouldRequestSend),
    takeLatest(galleryActions.selectGalleryItem, onSelectGalleryItem),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default activitiesSaga
