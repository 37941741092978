import { memo } from 'react'
import PropTypes from 'prop-types'

import { Assignment } from '@material-ui/icons'
import { withStyles } from '@material-ui/core'

import Title from '../Title'
import InfoLine from '../InfoLine'

import styles from './styles'

const QuestionInfo = ({ classes, question }) => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Title title={question.Label} />

        <InfoLine icon={Assignment} value={question.FormName} title={question.FormName} />
      </div>
    </div>
  )
}

QuestionInfo.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  question: PropTypes.shape({
    Label: PropTypes.string.isRequired,
    FormName: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(QuestionInfo))
