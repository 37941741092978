import { useMemo } from 'react'
import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'
import { getIdentityServerUser } from '@tabeeb/modules/account/actions'

const useIdentityServerUser = (userId, enabled) => {
  const requestParams = useMemo(() => userId, [userId])

  const { status: fetchStatus, response: user } = useApiRequest({
    request: getIdentityServerUser.request,
    payload: requestParams,
    enabled,
  })

  const mappedUser = useMemo(() => ({ Name: user?.userName, Email: user?.email }), [user])

  return { fetchStatus, user: mappedUser }
}

export default useIdentityServerUser
