import { useEffect, useRef, useState } from 'react'
import { Skeleton } from '@mui/material'
import PropTypes from 'prop-types'

const LoadingImage = ({ imgSrc, height = 300, width = 300 }) => {
  const imgRef = useRef(null)
  const [imageLoading, setImageLoading] = useState(true)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setImageLoading(false)
    }
    img.src = imgSrc
    imgRef.current = img
    return () => {
      img.onload = () => {}
    }
  }, [imgSrc])

  return imageLoading ? (
    <Skeleton variant='rectangular' height={height} width={width} />
  ) : (
    <input
      alt='Certificate image preview'
      title='Open image'
      style={{ maxWidth: 400, maxHeight: 400, pointerEvents: 'none' }}
      type='image'
      src={imgSrc}
    />
  )
}

LoadingImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default LoadingImage
