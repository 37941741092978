import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = []

const undoActions = handleActions(
  {
    [actions.setUndoActions](state, { payload }) {
      return [...payload]
    },
    [actions.addUndoAction](state, { payload }) {
      return [...state, payload]
    },
    [actions.deleteRow.success](state, { payload }) {
      return state.filter(({ action }) => action.payload.item?.rowId !== payload.itemId)
    },
    [actions.resetContentMetadata](state, { payload }) {
      return defaultState
    },
  },
  defaultState
)

export default undoActions
