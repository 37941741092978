import PropTypes from 'prop-types'

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import './styles.less'

const ExpandButton = ({ isGalleryExpanded, handleExpandClick }) => {
  return (
    <span className='expand-gallery-button'>
      {isGalleryExpanded ? (
        <KeyboardArrowRight fontSize='inherit' onClick={handleExpandClick} />
      ) : (
        <KeyboardArrowLeft fontSize='inherit' onClick={handleExpandClick} />
      )}
    </span>
  )
}

ExpandButton.propTypes = {
  isGalleryExpanded: PropTypes.bool.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
}

export default ExpandButton
