import { combineActions, handleActions } from 'redux-actions'

import { leaveContent } from '@tabeeb/shared/content/actions'

import { endCall, startCall } from '../actions/connection'
import { resetCallLeavingInProgress } from '../actions/conference'

const defaultValue = false

export default handleActions(
  {
    [leaveContent](state, { payload }) {
      return true
    },
    [combineActions(startCall, endCall)](state, { payload }) {
      return false
    },
    [resetCallLeavingInProgress]() {
      return defaultValue
    },
  },
  defaultValue
)
