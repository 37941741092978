import { handleActions, combineActions } from 'redux-actions'

import { onPlayerFullScreen, setCurrentVideoFullScreen, resetCurrentVideoTimestamp } from '../actions'

const defaultValue = false

export default handleActions(
  {
    [combineActions(setCurrentVideoFullScreen, onPlayerFullScreen)](state, { payload }) {
      return !!payload
    },
    [resetCurrentVideoTimestamp](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
