import { handleActions } from 'redux-actions'
import * as formsActions from '../actions'

export default handleActions(
  {
    [formsActions.openSwitchFormDialog](state, action) {
      return true
    },
    [formsActions.closeSwitchFormDialog](state, action) {
      return false
    },
    [formsActions.resetFormsState](state, action) {
      return false
    },
  },
  false
)
