import { combineReducers } from 'redux'

import linkedContents from './linkedContents'
import contentSharingDialogState from './contentSharingDialogState'
import userSearch from './userSearch'
import contentSearch from './contentSearch'
import selectedPagesForMerging from './selectedPagesForMerging'

export default combineReducers({
  contentSearch,
  contentSharingDialogState,
  linkedContents,
  selectedPagesForMerging,
  userSearch,
})
