import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { InputAdornment } from '@mui/material'
import { setSearchText } from '@tabeeb/modules/sessionsPage/actions'
import { getSearchQuery } from '@tabeeb/modules/sessionsPage/selectors/filtration'

import { StyledInput, StyledSearchIcon } from './styles'

const MAX_LENGTH = 512

const SearchInput = ({ endAdornment }) => {
  const dispatch = useDispatch()
  const searchQuery = useSelector(getSearchQuery)

  return (
    <StyledInput
      value={searchQuery}
      placeholder='Search'
      onChange={(e) => dispatch(setSearchText({ text: e.target.value.slice(0, MAX_LENGTH) }))}
      disableUnderline
      startAdornment={
        <InputAdornment position='start'>
          <StyledSearchIcon />
        </InputAdornment>
      }
      endAdornment={endAdornment}
    />
  )
}

SearchInput.propTypes = {
  endAdornment: PropTypes.element.isRequired,
}

export default SearchInput
