import { handleActions } from 'redux-actions'

import { setPlayerPlaying, onPlayerPaused, onPlayerPlay, resetCurrentVideoTimestamp } from '../actions'

const defaultValue = false

export default handleActions(
  {
    [setPlayerPlaying](state, { payload }) {
      return !!payload
    },
    [onPlayerPaused](state, { payload }) {
      return false
    },
    [onPlayerPlay](state, { payload }) {
      return true
    },
    [resetCurrentVideoTimestamp](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
