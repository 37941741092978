import { handleActions } from 'redux-actions'

import { AUDIO_DEVICE, CAMERA_FACING_MODE, VIDEO_DEVICE } from '@tabeeb/modules/presentation/constants'
import * as deviceActions from '../actions/devices'
import * as jitsiTrackActions from '../actions/jitsiTrack'

const initialState = {
  audioInputId: '',
  audioOutputId: '',
  videoInputId: '',
  facingMode: CAMERA_FACING_MODE.USER,
}

export default handleActions(
  {
    [jitsiTrackActions.addTrackToRoom](state, action) {
      return setInitialDevice(state, action.payload)
    },
    [deviceActions.setAudioInputDevice](state, action) {
      return { ...state, audioInputId: action.payload }
    },
    [deviceActions.setAudioOutputDevice](state, action) {
      return { ...state, audioOutputId: action.payload }
    },
    [deviceActions.setVideoInputDevice](state, action) {
      return { ...state, videoInputId: action.payload.deviceId }
    },
    [deviceActions.setMobileCameraFacingMode](state, action) {
      return { ...state, facingMode: action.payload }
    },
    [deviceActions.resetDevices](state, action) {
      return initialState
    },
  },
  initialState
)

const setInitialDevice = (state, track) => {
  const trackType = track.getType()
  const deviceId = track.getDeviceId()

  switch (trackType) {
    case VIDEO_DEVICE:
      return { ...state, videoInputId: deviceId }
    case AUDIO_DEVICE:
      return { ...state, audioInputId: deviceId }
  }
}
