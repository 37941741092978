import { combineReducers } from 'redux'
import activitiesList from './activitiesList'
import activitiesFilter from './activitiesFilter'
import activitiesState from './activitiesState'
import activitiesSearchInProgress from './activitiesSearchInProgress'
import shouldRequestSend from './activitiesShouldRequestSend'

export default combineReducers({
  activitiesList,
  activitiesFilter,
  activitiesState,
  activitiesSearchInProgress,
  shouldRequestSend,
})
