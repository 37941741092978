import { put, takeLatest, all, select } from 'redux-saga/effects'

import { ErrorCode } from '@tabeeb/enums'
import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

import {
  getSelectedAIObjects,
  getSelectedAnnotationDates,
  getSelectedAnnotationOwners,
  getSelectedContents,
  getSelectedForms,
  getSelectedPagesForExclusion,
  getSelectedProject,
  getTrainingSettings,
  getSelectedAnswerers,
  getSelectedReviewers,
  getSelectedApprovers,
  getSelectedFormAnswerStatuses,
  getSelectedFormControls,
  getSelectedFormAnswerValue,
} from '../selectors'

function getErrorMessage(Error) {
  switch (Error.Code) {
    case ErrorCode.ProjectNotFound:
    case ErrorCode.IterationNotCreated:
    case ErrorCode.NoTrainProjectWithIncompleteIteration:
      return Error.Message
    case ErrorCode.PageNotFound:
      return Error.Details || Error.Message
    default:
      return 'Project training failed to start.'
  }
}

function* onTrainProjectSuccess(action) {
  yield put(notificationActions.onAddInfoNotification({ message: 'Project training started successfully.' }))
  yield put(actions.setSelectedIterationId(action.response.data.Id))
}

function* onTrainProjectFailed(action) {
  const message = getErrorMessage(action.response.data.Error)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* onStartProjectTraining(action) {
  const selectedAIObjects = yield select(getSelectedAIObjects)
  const selectedAnnotationDates = yield select(getSelectedAnnotationDates)
  const selectedAnnotationOwners = yield select(getSelectedAnnotationOwners)
  const selectedContents = yield select(getSelectedContents)
  const selectedForms = yield select(getSelectedForms)
  const selectedPagesForExclusion = yield select(getSelectedPagesForExclusion)
  const selectedProject = yield select(getSelectedProject)
  const trainingSettings = yield select(getTrainingSettings)
  const selectedAnswerers = yield select(getSelectedAnswerers)
  const selectedApprovers = yield select(getSelectedApprovers)
  const selectedReviewers = yield select(getSelectedReviewers)
  const selectedFormAnswerStatuses = yield select(getSelectedFormAnswerStatuses)
  const selectedFormControls = yield select(getSelectedFormControls)
  const selectedFormAnswerValue = yield select(getSelectedFormAnswerValue)

  const model = {
    ProjectId: selectedProject.Id,
    IterationType: trainingSettings.iterationType,
    ContentIds: selectedContents.map((content) => content.Id),
    AIObjectIds: selectedAIObjects.map((aiObject) => aiObject.Id),
    FormIds: selectedForms.map((form) => form.Id),
    UserIds: selectedAnnotationOwners.map((annotationOwner) => annotationOwner.Id),
    ExcludedPageIds: selectedPagesForExclusion.map((page) => page.Id),
    StartDate: selectedAnnotationDates.StartDate,
    EndDate: selectedAnnotationDates.EndDate,
    AdvancedSettings: trainingSettings.settings,
    ApproverIds: selectedApprovers.map((approver) => approver.Id),
    ReviewerIds: selectedReviewers.map((reviewer) => reviewer.Id),
    AnswererIds: selectedAnswerers.map((answerer) => answerer.Id),
    FormAnswerStatuses: selectedFormAnswerStatuses,
    FormControlIds: selectedFormControls.map((formControl) => formControl.Id),
    FormAnswerValue: selectedFormAnswerValue,
  }

  yield put(actions.trainProject.request(model))
}

function* training() {
  yield all([
    takeLatest(actions.trainProject.failed, onTrainProjectFailed),
    takeLatest(actions.trainProject.success, onTrainProjectSuccess),
    takeLatest(actions.startProjectTraining, onStartProjectTraining),
  ])
}

export default training
