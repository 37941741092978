import { Fragment, memo } from 'react'
import PropTypes from 'prop-types'

const Repeater = ({ count, children }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`instance-${index}`}>{children}</Fragment>
      ))}
    </>
  )
}

Repeater.defaultProps = {
  count: 1,
}

Repeater.propTypes = {
  count: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default memo(Repeater)
