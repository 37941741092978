import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import { FilterDrama, InvertColorsOutlined, WallpaperOutlined } from '@mui/icons-material'

import { setViewerBackground } from '@tabeeb/modules/pointCloud/actions'
import { Backgrounds } from '@tabeeb/modules/pointCloud/constants'
import { getViewerBackground } from '@tabeeb/modules/pointCloud/selectors'

import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import ToolbarButton from '../ToolbarButton'
import ToolbarMenu from '../ToolbarMenu'

const ViewerBackgroundButton = () => {
  const dispatch = useDispatch()

  const background = useSelector(getViewerBackground)
  const backgrounds = useMemo(
    () => [
      {
        icon: <InvertColorsOutlined style={{ stroke: '#616161', strokeWidth: 1 }} htmlColor='white' />,
        type: Backgrounds.White,
        title: 'White',
      },
      {
        icon: <InvertColorsOutlined htmlColor='black' />,
        type: Backgrounds.Black,
        title: 'Black',
      },
      {
        icon: <FilterDrama />,
        type: Backgrounds.Skybox,
        title: 'Skybox',
      },
    ],
    []
  )

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const onBackgroundChange = useCallback(
    (type) => {
      dispatch(setViewerBackground(type))
    },
    [dispatch]
  )

  return (
    <>
      <ToolbarButton ref={anchorRef} Icon={WallpaperOutlined} onClick={onOpen} title='Change viewer background' />
      <ToolbarMenu anchorEl={anchorRef.current} open={open} onClose={onClose}>
        {backgrounds.map(({ type, title, icon }) => (
          <MenuItem key={type} selected={type === background} onClick={() => onBackgroundChange(type)} title={title}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{title}</ListItemText>
          </MenuItem>
        ))}
      </ToolbarMenu>
    </>
  )
}

export default memo(ViewerBackgroundButton)
