import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ArrowUpward } from '@material-ui/icons'

import { galleryActions, gallerySelectors } from '@tabeeb/modules/gallery'

import PanelButton from '../../ZoomButtons/PanelButton'

const PreviousPageButton = () => {
  const dispatch = useDispatch()

  const display = useSelector(gallerySelectors.getCanSwitchPages)
  const previousPageId = useSelector(gallerySelectors.getPreviousPageId)

  const onSelectPreviousPage = useCallback(() => {
    dispatch(galleryActions.onSelectGalleryItem({ pageId: previousPageId }))
  }, [dispatch, previousPageId])

  return (
    <PanelButton
      Icon={ArrowUpward}
      title='Previous page'
      onClick={onSelectPreviousPage}
      display={display}
      disabled={!previousPageId}
    />
  )
}

export default memo(PreviousPageButton)
