import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import ActivityItem from '../components/ActivityItem'
import * as activityActions from '../actions'

class ActivityItemContainer extends Component {
  handleSelectActivity = () => {
    const { actions, activity } = this.props
    actions.selectActivity(activity.id)
  }

  render() {
    const { selectedActivityId, activity } = this.props
    const props = {
      activity,
      isSelectedActivity: selectedActivityId === activity.id,
      handleSelectActivity: this.handleSelectActivity,
    }
    return <ActivityItem {...props} />
  }
}

ActivityItemContainer.propTypes = {
  activity: PropTypes.object.isRequired,
  selectedActivityId: PropTypes.number,
  actions: PropTypes.shape({
    selectActivity: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ activities }, { activity }) => {
  return {
    selectedActivityId: activities.activitiesState.selectedActivityId,
    activity,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(activityActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityItemContainer)
