import { handleActions } from 'redux-actions'

import { OverlayPosition } from '@tabeeb/enums'

import { setConfigForUpdate, getConfigForUpdate } from '../actions'
import { TenantConfig } from '../types'

interface TenantConfigState extends TenantConfig {}

const defaultState: TenantConfigState = {
  bannerUrl: '',
  logoUrl: '',
  introImageUrl: '',
  introImageTextColor: '',
  name: '',
  subdomain: '',
  supportsPrograms: false,
  apiUrl: '',
  guestRoleDisplayName: '',
  sessionGuestRoleDisplayName: '',
  selectedTenantsForContracts: [],
  selectedTenantsForFormPublishing: [],
  selectedTenantsForReviewing: [],
  selectedTenantsForSharing: [],
  videoBridgeRegions: [],
  bullets: [],
  showStructureModel: false,
  pageInfoOverlayPosition: OverlayPosition.TopLeft.toString(),
  useInternalFTP: false,
  internalFTPUsername: '',
  isSessionGuestRoleEnabled: false,
  isNewMainPageViewEnabled: false,
  isOutgoingContractsEnabled: false,
  useCustomerFTP: false,
  isAIReportEnabled: false,
  isModelTrainingEnabled: false,
  isMobileFormViewSwitchEnabled: false,
  showPageInfoOverlay: false,
  isBillingSettingsRoleEnabled: false,
  useSessionNameConvention: false,
  showCertificates: false,
  showQualifications: false,
  isFormPublishingEnabled: false,
  isSessionReviewingEnabled: false,
  isMergeForReviewEnabled: false,
  isSessionSharingEnabled: false,
  showHealthData: false,
  isVideoCallsEnabled: false,
  isRecordingEnabled: false,
  showVideobridgeRegionDropdown: false,
  isChatEnabled: false,
  isAdvancedFormSettingsEnabled: false,
  showBentleyModel: false,
  isCustomIframeEnabled: false,
  isTrainingMaterialsEnabled: false,
  isContentMetadataEnabled: false,
}

export default handleActions<TenantConfigState, any>(
  {
    [setConfigForUpdate.toString()](state, action) {
      const typedAction = action as ReturnType<typeof setConfigForUpdate>

      return { ...typedAction.payload }
    },
    [getConfigForUpdate.success.toString()](state, action) {
      const typedAction = action as ReturnType<typeof getConfigForUpdate.success>

      const config = typedAction.response.data
      return { ...state, ...config }
    },
  },
  defaultState
)
