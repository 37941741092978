import { useEffect, useState } from 'react'

export default function useWindowFocus() {
  const [value, setValue] = useState(false)

  const handleFocus = () => setValue(true)
  const handleBlur = () => setValue(false)

  useEffect(() => {
    window.addEventListener('blur', handleBlur)
    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('blur', handleBlur)
      window.removeEventListener('focus', handleFocus)
    }
  }, [])
  return value
}
