import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    toolbar: {
      marginBottom: theme.spacing(1.25),
      display: 'flex',
      justifyContent: 'space-between',
    },
    paper: {
      display: 'flex',
      border: `1px solid ${theme.palette.divider}`,
    },
    toggleGroup: {
      '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
          borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
  }
})
