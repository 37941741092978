import { memo } from 'react'
import PropTypes from 'prop-types'

import { Button, Tooltip } from '@mui/material'
import { RefreshOutlined } from '@mui/icons-material'

const RefreshButton = ({ disabled, onClick }) => {
  return (
    <Tooltip title='Refresh'>
      <span>
        <Button
          disabled={disabled}
          startIcon={<RefreshOutlined color={disabled ? 'disabled' : 'primary'} />}
          variant='outlined'
          onClick={onClick}
        >
          Refresh
        </Button>
      </span>
    </Tooltip>
  )
}

RefreshButton.defaultProps = {
  disabled: false,
}

RefreshButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default memo(RefreshButton)
