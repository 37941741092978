import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_TOWER_SIDE_PROFILE_STATE')

export const addTowerSideProfile = createAction('ADD_TOWER_SIDE_PROFILE')
export const addTowerSideProfileRequest = createAction('ADD_TOWER_SIDE_PROFILE_REQUEST')
export const addTowerSideProfileSuccess = createAction('ADD_TOWER_SIDE_PROFILE_SUCCESS')
export const addTowerSideProfileFailed = createAction('ADD_TOWER_SIDE_PROFILE_FAILED')

export const openTowerSideProfileDialog = createAction('OPEN_TOWER_SIDE_PROFILE_DIALOG')
export const closeTowerSideProfileDialog = createAction('CLOSE_TOWER_SIDE_PROFILE_DIALOG')

export const addPageToTowerSideProfile = createAction('ADD_PAGE_TO_TOWER_SIDE_PROFILE')
export const addAllPagesToTowerSideProfile = createAction('ADD_ALL_PAGES_TO_TOWER_SIDE_PROFILE')
export const deletePageFromTowerSideProfile = createAction('DELETE_PAGE_FROM_TOWER_SIDE_PROFILE')
export const updatePagesTowerSideProfile = createAction('UPDATE_PAGES_TOWER_SIDE_PROFILE')

export const stopTowerSideProfileProcessing = createAction('STOP_TOWER_SIDE_PROFILE_PROCESSING')
