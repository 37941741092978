import { combineActions, handleActions } from 'redux-actions'

import { closeSplatModelTimeline, openSplatModelTimeline, resetState } from '../actions'

export default handleActions(
  {
    [openSplatModelTimeline]() {
      return true
    },
    [combineActions(closeSplatModelTimeline, resetState)]() {
      return false
    },
  },
  false
)
