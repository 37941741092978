export const VIDEO_DEVICE = 'video'
export const AUDIO_DEVICE = 'audio'
export const DESKTOP_DEVICE = 'desktop'

export const VIDEO_INPUT_KIND = 'videoinput'
export const AUDIO_INPUT_KIND = 'audioinput'
export const AUDIO_OUTPUT_KIND = 'audiooutput'

export const DEFAULT_AUDIO_DEVICE_ID = 'default'
export const DEFAULT_VIDEO_DEVICE_ID = ''

export const AUDIO_INPUT_DEVICE_KEY = 'audioInputDeviceId'
export const AUDIO_OUTPUT_DEVICE_KEY = 'audioOutputDeviceId'
export const VIDEO_INPUT_DEVICE_KEY = 'videoInputDeviceId'
export const VIDEO_MUTED = 'videoMuted'
export const AUDIO_MUTED = 'audioMuted'

export const CAMERA_FACING_MODE = {
  ENVIRONMENT: 'environment',
  USER: 'user',
}
