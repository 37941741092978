import PropTypes from 'prop-types'

import { MenuItem, FormControl, Select, ListItemText } from '@material-ui/core'

import { ReminderCustomRepeatMode } from '@tabeeb/enums'
import { customRepeatModeOptions } from '../../services/repeatModeOptions'
import { MINUTES_MIN_INTERVAL } from '../../services/constants'
import { ReminderError } from '../../services/reminderErrors'

import './styles.less'

const CustomRepeatInterval = ({ settings, setSettings, errors }) => {
  const _getCurrentInterval = (newMode) => {
    if (newMode === ReminderCustomRepeatMode.Minute) return MINUTES_MIN_INTERVAL

    return settings.Mode === ReminderCustomRepeatMode.Minute ? 1 : settings.Interval
  }

  const _getMinInterval = () => (settings.Mode === ReminderCustomRepeatMode.Minute ? MINUTES_MIN_INTERVAL : 1)

  const _handleChangeMode = (e) => {
    const value = parseInt(e.target.value, 10)

    setSettings({
      ...settings,
      Mode: value,
      Interval: _getCurrentInterval(value),
    })
  }

  const _handleChangeInterval = (e) => {
    const value = parseInt(e.target.value, 10)

    setSettings({ ...settings, Interval: value })
  }

  const error = errors.find((e) => e === ReminderError.InvalidRepeatInterval)

  return (
    <div className='reminder-custom-repeat-interval-container'>
      <div className='reminder-custom-repeat-interval'>
        <span className='reminder-custom-repeat-interval-label'>Repeat every</span>
        <input
          type='number'
          className='reminder-custom-repeat-interval-value'
          value={settings.Interval}
          min={_getMinInterval()}
          onChange={_handleChangeInterval}
        />
        <FormControl className='reminder-custom-repeat-interval-type'>
          <Select
            value={settings.Mode}
            onChange={_handleChangeMode}
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
              getContentAnchorEl: null,
            }}
            renderValue={() => {
              let selectedMode = customRepeatModeOptions[settings.Mode]

              if (settings.Interval > 1) selectedMode = `${selectedMode}s`
              return selectedMode
            }}
          >
            {Object.keys(customRepeatModeOptions).map((key) => (
              <MenuItem value={key} key={key}>
                <ListItemText primary={customRepeatModeOptions[key]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {error && <span className='reminder-error'>{`${error} Min value is ${_getMinInterval()}`}</span>}
    </div>
  )
}

CustomRepeatInterval.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
}

export default CustomRepeatInterval
