import { memo } from 'react'
import PropTypes from 'prop-types'

import { getFormsTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { contentFormsLoadingState, getContentForms } from '@tabeeb/modules/forms/selectors'
import FormTabs from '@tabeeb/modules/forms/components/FormTabs'
import FilterPanel from '@tabeeb/modules/forms/components/FilterPanel'
import FormControlsList from '@tabeeb/modules/forms/components/FormControlsList'
import AnswerStatusStatistics from '@tabeeb/modules/forms/components/AnswerStatusStatistics'
import FormsButtons from '@tabeeb/modules/forms/components/FormsButtons'
import FormRecords from '@tabeeb/modules/forms/components/FormRecords'
import FormsTopButtons from '@tabeeb/modules/forms/components/FormsTopButtons'
import styles from './styles'

const CurrentForm = ({ classes }) => {
  const formsTitle = useSelector(getFormsTitleLowerCase)

  const { loading, loaded } = useSelector(contentFormsLoadingState)
  const contentForms = useSelector(getContentForms)
  const isEmpty = loaded && contentForms.length === 0

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularProgressBar />
      ) : (
        <>
          {isEmpty ? (
            <p className={classes.message}>There are no {formsTitle} attached to the session</p>
          ) : (
            <>
              <FilterPanel />
              <FormRecords />
              <FormsTopButtons />
              <AnswerStatusStatistics />
              <FormTabs />
              <FormControlsList />
            </>
          )}
          <FormsButtons />
        </>
      )}
    </div>
  )
}

CurrentForm.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(CurrentForm))
