export default (theme) => {
  return {
    formAction: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 12,
      wordBreak: 'initial',
      overflow: 'hidden',

      '&:last-child': {
        marginBottom: 2,
      },
      padding: 10,
    },
    button: {
      marginTop: 8,
    },
  }
}
