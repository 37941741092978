import { handleActions } from 'redux-actions'

import { persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

import { billingSettingsActions } from '@tabeeb/modules/billingSettingsPage'
import * as sessionActions from '../actions/sessionActions'

const defaultState = {
  sessionApprovalSettings: {},
  billingSettings: {
    IsBillingEnabled: false,
  },
}

export default persistReducer(
  {
    key: 'sessionState',
    storage,
  },
  handleActions(
    {
      [billingSettingsActions.getBillingSettingsSuccess](state, action) {
        return { ...state, billingSettings: action.response.data }
      },
      [billingSettingsActions.getSessionApprovalSettingsSuccess](state, action) {
        return { ...state, sessionApprovalSettings: action.response.data }
      },
      [sessionActions.resetSessionState](state, action) {
        return defaultState
      },
    },
    defaultState
  )
)
