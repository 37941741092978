import { handleActions } from 'redux-actions'
import * as aiActions from '../actions'

const counterValuesList = handleActions(
  {
    [aiActions.setCounterListValues](state, action) {
      return [...action.payload]
    },
    [aiActions.getAiCounterInfoByFormRecordSuccess](state, action) {
      return [...action.response.data]
    },
    [aiActions.resetState](state, payload) {
      return []
    },
  },
  []
)

export default counterValuesList
