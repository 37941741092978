import { combineActions, handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../../actions'

const emptyReport = {
  Id: 0,
  SecurityModel: {
    Id: 0,
    Name: '',
  },
}

const initialState = {
  open: false,
  isEditMode: false,
  fetchStatus: FetchStatus.Idle,
  report: emptyReport,
}

export default handleActions(
  {
    [rawActions.openPowerBIReportUserModal]: (state, action) => {
      const { report } = action.payload
      return {
        ...state,
        open: true,
        report,
      }
    },
    [rawActions.closePowerBIReportUserModal]: () => {
      return initialState
    },
    [rawActions.createPowerBIReportUser.request]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [rawActions.createPowerBIReportUser.success]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Loaded }
    },
    [rawActions.createPowerBIReportUser.failed]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
  },
  initialState
)
