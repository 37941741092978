import { put, takeLatest, select, all, delay } from 'redux-saga/effects'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import * as rawActions from '../actions'

function* updateTargetContentSearchValue(action) {
  const searchQuery = action.payload
  yield delay(500)
  const { targetContentSearchValue } = yield select((state) => state.sessionsPage)

  if (searchQuery && searchQuery === targetContentSearchValue) {
    const { selectedOwnerForSharing, selectedTenantForSharing } = yield select((state) => state.sessionsPage)

    const data = {
      searchQuery,
      contentOwnerId: selectedOwnerForSharing?.Id,
      tenantId: selectedTenantForSharing?.Id,
    }

    yield put(rawActions.searchTargetContentRequest(data))
  }

  if (!searchQuery) {
    yield put(rawActions.resetTargetContentSearchResult())
  }
}

function* searchTargetContentSuccess(action) {
  const result = action.response.data
  const currentContentId = yield select(getContentId)
  const filteredList = result.filter((content) => content.Id !== currentContentId)

  yield put(rawActions.setTargetContentSearchResult(filteredList))
}

function* targetContent() {
  yield all([
    takeLatest(rawActions.updateTargetContentSearchValue, updateTargetContentSearchValue),
    takeLatest(rawActions.searchTargetContentSuccess, searchTargetContentSuccess),
  ])
}

export default targetContent
