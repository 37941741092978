import { handleActions } from 'redux-actions'

import * as aiActions from '../actions'

function _sortByName(aiObjects) {
  return aiObjects.sort((a, b) => {
    if (a.Name < b.Name) {
      return -1
    }
    if (a.Name > b.Name) {
      return 1
    }
    return 0
  })
}

const defaultState = []

export default handleActions(
  {
    [aiActions.getAiObjects.success](state, { response }) {
      return [...response.data]
    },
    [aiActions.addAiObject.success](state, { response }) {
      return _sortByName([...state, response.data])
    },
    [aiActions.updateAiObject.success](state, { payload }) {
      return state.map((aiObject) => (aiObject.Id === payload.Id ? { ...aiObject, ...payload } : aiObject))
    },
    [aiActions.deleteAiObject.success](state, { payload: aiObjectId }) {
      return state.filter((aiObject) => aiObject.Id !== aiObjectId)
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
