import { memo } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { useFormikContext } from 'formik'

import DateFnsUtils from '@date-io/date-fns'

import { FormHelperText, withStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import DateTimePicker from '../../components/DateTimePicker'

import styles from './styles'

const FormikDateRangePicker = ({ classes, form, field, variant, size, disabled }) => {
  const { setFieldValue } = useFormikContext()

  const { errors } = form
  const error = get(errors, field.name)?.start || get(errors, field.name)?.end

  const handleStartDateTimeChange = (dateTime) => {
    setFieldValue(field.name, { ...field.value, start: dateTime })
  }

  const handleEndDateTimeChange = (dateTime) => {
    setFieldValue(field.name, { ...field.value, end: dateTime })
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <div className={classes.pickers}>
          <DateTimePicker
            error={!!error}
            disabled={disabled}
            size={size}
            label='Start'
            variant={variant}
            value={field.value.start}
            onChange={handleStartDateTimeChange}
          />
          <div className={classes.delimeter}>–</div>
          <DateTimePicker
            error={!!error}
            disabled={disabled}
            size={size}
            label='End'
            variant={variant}
            value={field.value.end}
            onChange={handleEndDateTimeChange}
          />
        </div>
        {error && (
          <FormHelperText className={classes.error} error>
            {error}
          </FormHelperText>
        )}
      </div>
    </MuiPickersUtilsProvider>
  )
}

FormikDateRangePicker.defaultProps = {
  disabled: false,
}

FormikDateRangePicker.propTypes = {
  classes: PropTypes.shape({
    delimeter: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    pickers: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
      start: PropTypes.object,
      end: PropTypes.object,
    }).isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
}

export default memo(withStyles(styles)(FormikDateRangePicker))
