import { handleActions } from 'redux-actions'
import * as actions from '../actions'

const defaultValue = {
  loaded: false,
  list: [],
}

export default handleActions(
  {
    [actions.getTenantsListForReviewSuccess](state, action) {
      return { loaded: true, list: action.response.data }
    },
  },
  defaultValue
)
