import { Avatar, Divider, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import useStyles from './styles'

const UserProfileSkeleton = () => {
  const classes = useStyles()
  return (
    <>
      <Grid item container direction='column'>
        <Grid item className={classes.avatarGrid}>
          <Skeleton variant='circle'>
            <Avatar className={classes.avatarBlock} />
          </Skeleton>
        </Grid>
        <Grid item className={classes.nameGrid}>
          <Skeleton width='60%' variant='text' />
        </Grid>
        <Skeleton width='40%' variant='text' />
        <Skeleton width='45%' variant='text' />
        <Skeleton width='45%' variant='text' />
        <Skeleton width='20%' variant='text' />
      </Grid>
      <Divider className={classes.divider} />
      <Grid item>
        <Skeleton width='50%' variant='text' />
        <Skeleton width='50%' variant='text' />
        <Skeleton width='50%' variant='text' />
        <Skeleton width='65%' variant='text' />
        <Skeleton width='65%' variant='text' />
      </Grid>
    </>
  )
}

export default UserProfileSkeleton
