import { handleActions, combineActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'

import _ from 'lodash'

import { getFormPermissionsByForm, getFormPermissionsByNewForm, getFormPermissionsByTenant } from '../actions'

const defaultState = {
  fetchingStatus: FetchStatus.Idle,
  data: {
    permissions: {
      byContentRoles: {
        sync: false,
        base: {},
        override: {},
        editableContentRoles: [],
      },
    },
  },
}

export default handleActions(
  {
    [combineActions(
      getFormPermissionsByForm.success,
      getFormPermissionsByTenant.success,
      getFormPermissionsByNewForm.success
    )](
      state,
      {
        response: {
          data: {
            Permissions: {
              ByContentRoles: { IsSyncWithBaseLevelEnabled, Base, Override, EditableContentRoles },
            },
          },
        },
      }
    ) {
      return {
        ...state,
        fetchingStatus: FetchStatus.Loaded,
        data: {
          permissions: {
            byContentRoles: {
              sync: IsSyncWithBaseLevelEnabled,
              base: _.keyBy(Base, 'ContentRoleId'),
              override: _.keyBy(Override, 'ContentRoleId'),
              editableContentRoles: EditableContentRoles,
            },
          },
        },
      }
    },
    [combineActions(
      getFormPermissionsByForm.request,
      getFormPermissionsByTenant.request,
      getFormPermissionsByNewForm.request
    )](state) {
      return {
        ...state,
        fetchingStatus: FetchStatus.Loading,
      }
    },
    [combineActions(
      getFormPermissionsByForm.cancel,
      getFormPermissionsByTenant.cancel,
      getFormPermissionsByNewForm.cancel
    )](state) {
      return {
        ...state,
        fetchingStatus: FetchStatus.Idle,
      }
    },
    [combineActions(
      getFormPermissionsByForm.failed,
      getFormPermissionsByTenant.failed,
      getFormPermissionsByNewForm.failed
    )](state) {
      return {
        ...state,
        fetchingStatus: FetchStatus.Failed,
      }
    },
    [combineActions(
      getFormPermissionsByForm.resetState,
      getFormPermissionsByTenant.resetState,
      getFormPermissionsByNewForm.resetState
    )]() {
      return defaultState
    },
  },
  defaultState
)
