import { useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LazyLoad from 'react-lazyload'

import { getImageRatio } from '@tabeeb/services/pageService'

import * as contentTrashBinActions from '../../actions'
import * as contentTrashBinSelectors from '../../selectors'
import Item from '../Item'

const getGalleryItemPlaceholder = (item) => {
  const pageRatio = getImageRatio(item)
  const pageHeight = 160 * pageRatio + 68
  return <div id={item.id} className='gallery-item-placeholder' style={{ height: pageHeight }} />
}

const ItemList = () => {
  const listRef = useRef()
  const dispatch = useDispatch()

  const isTrashBinEmpty = useSelector(contentTrashBinSelectors.getIsTrashBinEmpty)

  const deletedPages = useSelector(contentTrashBinSelectors.getCurrentFolderDeletedPages)

  const dialogPage = useCallback(
    (pageId, dialogItem) => {
      dispatch(contentTrashBinActions.setOperatingPageId(pageId))
      dispatch(contentTrashBinActions.setContentTrashBinDialogItem(dialogItem))
    },
    [dispatch]
  )

  const onPageClick = useCallback(
    (pageId, isPageSelected) => {
      if (isPageSelected) {
        dispatch(contentTrashBinActions.deselectContentTrashBinPage({ Id: pageId }))
      } else {
        dispatch(contentTrashBinActions.selectContentTrashBinPage({ Id: pageId }))
      }
    },
    [dispatch]
  )

  return (
    <ul className='gallery-list'>
      {isTrashBinEmpty ? (
        <div className='gallery-help-text' style={{ fontSize: 18 }}>
          <span>Trash bin is empty</span>
        </div>
      ) : (
        deletedPages.map((page, index) => (
          <LazyLoad
            key={page.id}
            offset={2000}
            scroll
            once
            debounce
            scrollContainer='.trash-bin-scrollable'
            placeholder={getGalleryItemPlaceholder(page)}
          >
            <Item item={page} listRef={listRef} dialogPage={dialogPage} onPageClick={onPageClick} />
          </LazyLoad>
        ))
      )}
    </ul>
  )
}

export default ItemList
