import { useDispatch, useSelector } from 'react-redux'
import * as contentTrashBinActions from '../../actions'
import * as contentTrashBinSelectors from '../../selectors'

const FolderNavbar = () => {
  const dispatch = useDispatch()

  const selectedFolder = useSelector(contentTrashBinSelectors.getSelectedFolder)
  const deletedFolders = useSelector(contentTrashBinSelectors.getDeletedFolders)
  const folderName = selectedFolder.Name

  const onArrowClick = () => {
    dispatch(
      contentTrashBinActions.selectContentTrashBinFolder(
        deletedFolders.find((folder) => folder.Id === selectedFolder.ParentId)
      )
    )
  }

  return (
    <div className='gallery-navbar-container'>
      <div className='gallery-navbar-back-arrow' onClick={onArrowClick}>
        &lt;
      </div>
      <div className='gallery-navbar-folder-name'>{folderName}</div>
    </div>
  )
}

export default FolderNavbar
