import proj4 from 'proj4'

const convertLangLatToUTM = (latitude, longitude) => {
  if (!Number.isFinite(latitude) || !Number.isFinite(longitude)) {
    return undefined
  }

  const utmZone = Math.ceil((longitude + 180) / 6)
  const utm = `+proj=utm +zone=${utmZone}`

  return proj4(utm, { x: longitude, y: latitude })
}

export default convertLangLatToUTM
