import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = 0

export default handleActions(
  {
    [rawActions.setPdfSearchMatchesCount](state, action) {
      return action.payload
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
