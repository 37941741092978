import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Autocomplete, TextField, Typography } from '@mui/material'
import useTenantRoles from '@tabeeb/modules/powerBIReports/hooks/useTenantRoles'
import { FetchStatus } from '@tabeeb/enums'
import { getTenantRoleDisplayName } from '@tabeeb/modules/appConfigState/selectors'

const PowerBIReportTenantRoleSelect = ({ disabled, value, onChange, helperText, variant = 'standard' }) => {
  const { fetchStatus, roles } = useTenantRoles()

  const options = useSelector((state) =>
    roles.map((role) => ({ role, label: getTenantRoleDisplayName(state, { Role: role }) }))
  )

  return (
    <Autocomplete
      multiple
      fullWidth
      size='small'
      options={options}
      disableClearable
      loading={fetchStatus === FetchStatus.Loading}
      onChange={onChange}
      disabled={disabled}
      value={value}
      filterOptions={(filterOptions) => {
        return filterOptions.filter((option) => !value.some((val) => val.role === option.role))
      }}
      filterSelectedOptions
      renderOption={(props, option) => (
        <Typography
          {...props}
          title={option.label}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {option.label}
        </Typography>
      )}
      renderInput={(params) => <TextField {...params} label='Tenant roles' variant={variant} helperText={helperText} />}
    />
  )
}

PowerBIReportTenantRoleSelect.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default PowerBIReportTenantRoleSelect
