import { Euler } from 'three'
import { Backgrounds } from './Backgrounds'
import { CameraProjectionTypes } from './CameraTypes'

import { CameraControlTypes } from './ControlTypes'
import { Units } from './Units'
import { View } from './View'

export const Defaults = {
  BACKGROUND: Backgrounds.Black,
  CALCULATIONS_PRECISION: 2,
  POINT_BUDGET: 20_000_000,
  POINT_SIZE: 0.000001,
  PROJECTION_TYPE: CameraProjectionTypes.Perspective,
  MIN_NODE_SIZE: 1,
  VIEW: View.Front,
  FIELD_OF_VIEW: 75,
  CAMERA: CameraControlTypes.Orbit,
  PLY_ROTATION: new Euler(-Math.PI / 2, 0, 0),
  UNIT: Units.Feet,
  HTML_Z_INDEX: [0, 0],
  HOVER_OPACITY: 0.85,
  OPACITY: 0.25,
}
