import { Button, Dialog, Typography, DialogActions, DialogContent } from '@material-ui/core'
import PropTypes from 'prop-types'

const AlertOwnerModal = (props) => {
  const { description, handleClickYes, handleClickNo, isOpen } = props

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Typography variant='h6'>{`${description}. Would you like to go there now?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickYes} variant='contained' color='primary'>
          Yes
        </Button>
        <Button onClick={handleClickNo} variant='contained' color='secondary'>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertOwnerModal

AlertOwnerModal.propTypes = {
  description: PropTypes.string.isRequired,
  handleClickYes: PropTypes.func.isRequired,
  handleClickNo: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}
