import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import PropTypes from 'prop-types'

import { getTenantName, getMainBackgroundColor } from '@tabeeb/modules/appConfigState/selectors'

import Navbar from '@tabeeb/modules/navbar'

import routes from '@tabeeb/routes'

const environmentBannerText = process.env.ENVIRONMENT_BANNER_TEXT

const hideNavbarOn = [
  routes.createProfile,
  routes.appPrivacyPolicy,
  routes.appTermsOfUse,
  routes.joinSessionViaInvite,
  routes.signInSilentCallback,
  routes.signInRedirectCallback,
  routes.signOutRedirectCallback,
  routes.switchTenant,
  routes.legacyRegister,
  routes.legacyConfirmEmail,
]

const Root = ({ children }) => {
  const location = useLocation()

  const tenantName = useSelector(getTenantName)
  const backgroundColor = useSelector(getMainBackgroundColor)

  const hideNavbar = useMemo(
    () => hideNavbarOn.some((path) => matchPath(location.pathname, { path })) || isMobileOnly,
    [location.pathname]
  )

  return (
    <>
      <Helmet>
        <title>{tenantName}</title>
      </Helmet>
      {!hideNavbar && <Navbar />}
      {environmentBannerText && (
        <div
          style={{
            backgroundColor: '#e4a11b',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          {environmentBannerText}
        </div>
      )}
      <div
        style={{
          height: '100%',
          overflowY: 'auto',
          backgroundColor,
        }}
      >
        {children}
      </div>
    </>
  )
}

Root.propTypes = {
  children: PropTypes.element,
}

export default Root
