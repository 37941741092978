export const getSessionPermissionsRequest = ({ contentId }) => ({
  url: `api/permissions/session/${contentId}`,
  method: 'get',
  transformResponse: (response) => {
    const parsedResponse = JSON.parse(response)
    return { ...parsedResponse, Permissions: parsedResponse.Permissions }
  },
})

export const getFolderPermissionsRequest = ({ folderId }) => ({
  url: `api/permissions/folder/${folderId}`,
  method: 'get',
  transformResponse: (response) => {
    const parsedResponse = JSON.parse(response)
    return { ...parsedResponse, Permissions: parsedResponse.Permissions }
  },
})

export const getTenantPermissionsRequest = () => ({
  url: `api/permissions/tenant`,
  method: 'get',
  transformResponse: (response) => {
    const parsedResponse = JSON.parse(response)
    return { ...parsedResponse, Permissions: parsedResponse.Permissions }
  },
})
