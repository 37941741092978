import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      listStyle: 'none',
      flexGrow: 1,
      minWidth: 0,

      marginBottom: theme.spacing(),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flexGrow: 1,
      minWidth: 0,
    },
    title: {
      ...theme.typography.body2,
      color: theme.palette.text.primary,

      '& a': {
        color: 'inherit',
      },
    },
    subtitle: {
      ...theme.typography.caption,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
    },
    icon: {
      marginRight: theme.spacing(0.25),
    },
  }
})
