import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Filters from '../Filters'
import NotificationsList from '../NotificationsList'

import * as notificationsPageActions from '../../actions'
import { getBatchedLoadedCount } from '../../selectors'

import useStyles from './styles'

const NotificationsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const initialized = useSelector(getBatchedLoadedCount) >= 1

  useEffect(() => {
    if (!initialized) {
      dispatch(notificationsPageActions.setNotificationsListLoading(true))
      dispatch(notificationsPageActions.loadNotifications())
    }

    return () => {
      dispatch(notificationsPageActions.markNotificationsAsViewed())
      dispatch(notificationsPageActions.resetNotificationsList())
    }
  }, [])

  return (
    <section className={classes.container} title='Notifications'>
      <Filters />
      <NotificationsList />
    </section>
  )
}

export default NotificationsPage
