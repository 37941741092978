import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import PropTypes from 'prop-types'
import './index.css'

const PowerBIReport = ({ report, setEmbeddedReport }) => {
  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        id: report.Id,
        embedUrl: report.EmbedUrl,
        accessToken: report.EmbedToken,
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            filters: {
              expanded: false,
            },
            pageNavigation: {
              position: 1,
            },
          },
        },
      }}
      cssClassName='powerbi-embed'
      getEmbeddedComponent={(embeddedComponent) => setEmbeddedReport(embeddedComponent)}
    />
  )
}

PowerBIReport.propTypes = {
  report: PropTypes.shape({
    Id: PropTypes.string,
    EmbedUrl: PropTypes.string,
    EmbedToken: PropTypes.string,
  }),
  setEmbeddedReport: PropTypes.func.isRequired,
}

export default PowerBIReport
