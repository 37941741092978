import { createSelector } from 'reselect'
import { FileUploadStatus } from '../constants'

export const getFileUploads = (state) => state.fileUploads.list

export const getIncompleteUploadsCount = createSelector([getFileUploads], (fileUploads) => {
  const incompleteUploadsCount = fileUploads.filter(
    (item) => item.status === FileUploadStatus.Pending || item.status === FileUploadStatus.InProgress
  ).length

  return incompleteUploadsCount
})

export const getFailedUploadsCount = createSelector([getFileUploads], (fileUploads) => {
  const failedUploadsCount = fileUploads.filter(
    (item) => item.status === FileUploadStatus.Failed || item.status === FileUploadStatus.Canceled
  ).length

  return failedUploadsCount
})

export const getFileUploadsCount = createSelector([getFileUploads], (fileUploads) => {
  return fileUploads.length
})

export const getActiveFileUploadsCount = createSelector(
  [getIncompleteUploadsCount, getFailedUploadsCount],
  (incompleteUploadsCount, failedUploadsCount) => {
    return incompleteUploadsCount + failedUploadsCount
  }
)
