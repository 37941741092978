import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useProgressiveLoadingResource } from '@tabeeb/modules/sncFilters/hooks'
import { FetchStatus } from '@tabeeb/enums'
import { getPowerBIReportUsers, setPowerBIReportUsersRefetch } from '../actions'
import { getPowerBIReportUsersState } from '../selectors'

const usePowerBIReportUsers = (reportId) => {
  const dispatch = useDispatch()
  const requestParams = useMemo(() => ({ reportId }), [reportId])
  const { requireRefetch, deleteStatus, items } = useSelector(getPowerBIReportUsersState)

  const { status, loading, hasMore, onLoadMore, onReload } = useProgressiveLoadingResource({
    request: getPowerBIReportUsers.request,
    payload: requestParams,
  })

  useEffect(() => {
    if (requireRefetch) {
      onReload()
      dispatch(setPowerBIReportUsersRefetch(false))
    }
  }, [dispatch, onReload, requireRefetch])

  return {
    fetchStatus: status,
    loading: loading || deleteStatus === FetchStatus.Loading,
    users: items || [],
    hasMore,
    onLoadMore,
    onReload,
  }
}

export default usePowerBIReportUsers
