import PropTypes from 'prop-types'

import { Group, Rect, Text as CanvasText } from 'react-konva'

import { getHighlightedColor } from '../../services/colorsService'

const Text = ({
  annotation,
  opacity,
  hovered,
  hoverProps,
  visible,
  annotationRef,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
}) => {
  if (!annotation.TopLeft) {
    return null
  }

  const position = Text.getXY(annotation)
  const color = selected || hovered ? getHighlightedColor(annotation.Color) : annotation.Color

  const styles = {
    fill: color,
    lineCap: 'round',
    lineJoin: 'round',
    fontFamily: 'Courier',
    fontSize: annotation.FontSize,
    lineHeight: 1,
    strokeWidth: annotation.Width,
    visible,
    opacity,
  }

  const borderStyles = {
    dash: [10, 10],
    stroke: color,
    fillEnabled: false,
    strokeWidth: 1,
    strokeScaleEnabled: false,
  }

  return (
    <Group {...position} {...dragAndDropProps}>
      <CanvasText
        id={annotation.Id}
        ref={annotationRef}
        text={annotation.Text}
        {...styles}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
      />
      {hovered && <Rect {...Text.getSize(annotation)} {...borderStyles} />}
    </Group>
  )
}

Text.getXY = (annotation) => ({
  x: annotation.TopLeft.X,
  y: annotation.TopLeft.Y,
})

Text.getSize = (annotation) => ({
  width: Text.getNumberOfCols(annotation.Text) * annotation.FontSize * 0.6,
  height: Text.getNumberOfRows(annotation.Text) * annotation.FontSize,
})

Text.getNumberOfRows = (text) => {
  return text.split(/\r\n|\r|\n/).length
}

Text.getNumberOfCols = (text) => {
  return Math.max(...text.split(/\r\n|\r|\n/).map((line) => line.length))
}

Text.getViewBox = (annotation) => {
  const { x, y } = Text.getXY(annotation)
  const { width, height } = Text.getSize(annotation)

  return {
    topLeftX: x,
    topLeftY: y,
    bottomRightX: x + width,
    bottomRightY: y + height,
  }
}

Text.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Color: PropTypes.string.isRequired,
    Text: PropTypes.string.isRequired,
    TopLeft: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
    }).isRequired,
    FontSize: PropTypes.number.isRequired,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  annotationRef: PropTypes.func,
  visible: PropTypes.bool,
  opacity: PropTypes.number,
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onDblClick: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
}

export default Text
