import { memo, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Divider, Paper, IconButton, InputBase, Popper, Tooltip, Grow, ClickAwayListener } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import TuneIcon from '@material-ui/icons/Tune'
import SearchIcon from '@material-ui/icons/Search'

import { useStyles } from './styles'

const Search = ({
  query,
  isDataExists,
  filter,
  isFilterOpen,
  onFilterOpen,
  onFilterClose,
  onQueryChange,
  onQueryClear,
  onSubmit,
  InputProps,
  PaperProps,
  FilterPaperProps,
}) => {
  const classes = useStyles()

  const [isSearchInFocus, setIsSearchInFocus] = useState(false)
  const searchFilterAnchor = useRef()

  const handleSearchFocus = () => {
    setIsSearchInFocus(true)
  }

  const handleSearchBlur = () => {
    setIsSearchInFocus(false)
  }

  const handleSubmit = useCallback(
    (event) => {
      if (isFilterOpen) onFilterClose()
      onSubmit(event)
    },
    [isFilterOpen, onFilterClose, onSubmit]
  )

  const handleSearchFilterClick = useCallback(() => {
    if (isFilterOpen) onFilterClose()
    else onFilterOpen()
  }, [isFilterOpen, onFilterOpen, onFilterClose])

  const handleFilterClickAway = useCallback(() => {
    if (isFilterOpen) onFilterClose()
  }, [isFilterOpen, onFilterClose])

  return (
    <form className={classes.root} onSubmit={handleSubmit} onFocus={handleSearchFocus} onBlur={handleSearchBlur}>
      <Paper ref={searchFilterAnchor} variant={isSearchInFocus ? 'elevation' : 'outlined'} {...PaperProps}>
        <div className={classes.inputBox}>
          <IconButton type='submit' aria-label='search'>
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.inputField}
            value={query}
            onChange={onQueryChange}
            name='q'
            autoComplete='off'
            autoCorrect='off'
            inputProps={{
              'aria-label': 'Search query',
            }}
            {...InputProps}
          />
          <IconButton
            aria-hidden={isDataExists}
            style={{ visibility: isDataExists ? 'visible' : 'hidden' }}
            onClick={onQueryClear}
          >
            <CloseIcon />
          </IconButton>
          {filter && (
            <>
              <Divider className={classes.inputVerticalDivider} orientation='vertical' />
              <Tooltip title='Search filter'>
                <IconButton onClick={handleSearchFilterClick}>
                  <TuneIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </Paper>
      {filter && (
        <Popper
          open={isFilterOpen}
          anchorEl={searchFilterAnchor.current}
          placement='bottom-start'
          disablePortal
          transition
          className={classes.popper}
          popperOptions={{
            modifiers: {
              // Update for MUI 5: https://github.com/popperjs/popper-core/issues/794#issuecomment-644034386
              setWidth: {
                enabled: true,
                order: 840,
                fn: (data) => {
                  data.offsets.popper.left = data.offsets.reference.left
                  data.offsets.popper.right = data.offsets.reference.right
                  data.offsets.popper.width = data.styles.width = Math.round(data.offsets.reference.width)
                  return data
                },
              },
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'top',
              }}
            >
              <Paper elevation={8} {...FilterPaperProps}>
                <ClickAwayListener onClickAway={handleFilterClickAway}>
                  <div>{filter}</div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </form>
  )
}

Search.propTypes = {
  query: PropTypes.string,
  isDataExists: PropTypes.bool,
  onQueryChange: PropTypes.func,
  onQueryClear: PropTypes.func,
  onSubmit: PropTypes.func,
  filter: PropTypes.node,
  isFilterOpen: PropTypes.bool,
  onFilterOpen: PropTypes.func,
  onFilterClose: PropTypes.func,
  InputProps: PropTypes.object,
  PaperProps: PropTypes.object,
  FilterPaperProps: PropTypes.object,
}

export default memo(Search)
