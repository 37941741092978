import { put, takeLatest, all } from 'redux-saga/effects'

import * as actions from '../actions'
import { rawContentActions } from '../../shared/content'

function* resetState() {
  yield put(actions.resetState())
}

function* resetStateSaga() {
  yield all([takeLatest(rawContentActions.resetContentState, resetState)])
}

export default resetStateSaga
