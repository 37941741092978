import { combineActions, handleActions } from 'redux-actions'

import * as actions from '../../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(actions.openEditSelectionListDialog, actions.openAddSelectionListDialog)](state, action) {
      return true
    },
    [combineActions(
      actions.closeEditSelectionListDialog,
      actions.createSelectionList.success,
      actions.updateSelectionList.success
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
