export default (theme) => {
  return {
    button: {
      height: 20,
      width: 20,
      minHeight: 20,
      marginRight: 4,
    },
    icon: {
      width: 14,
    },
  }
}
