import { combineReducers } from 'redux'

import isTimelineOpen from './isTimelineOpen'
import modelsList from './modelsList'
import isProcessing from './isProcessing'

export default combineReducers({
  isTimelineOpen,
  modelsList,
  isProcessing,
})
