import { takeLatest, put, all } from 'redux-saga/effects'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import { signalrEvents, signalrConstants } from '../../signalr'
import * as rawActions from '../actions'

function* onAutodeskUpdate(action) {
  const translationResult = action.payload[0]
  yield put(rawActions.setAutodeskViewable(translationResult))
}

function* onGetTranslationSuccess(action) {
  yield put(rawActions.setAutodeskViewable(action.response.data))
}

function* onGetTranslationFailed() {
  yield put(onAddErrorNotification({ message: 'Failed to open CAD model' }))
}

function* mainScreenSaga() {
  yield all([
    takeLatest(signalrEvents[signalrConstants.autodeskHubName].onAutodeskUpdate, onAutodeskUpdate),
    takeLatest(rawActions.getAutodeskViewable.success, onGetTranslationSuccess),
    takeLatest(rawActions.getAutodeskViewable.failed, onGetTranslationFailed),
  ])
}

export default mainScreenSaga
