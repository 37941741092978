class DomainService {
  #baseDomains = JSON.parse(process.env.TABEEB_REACT_APP_DOMAINS)

  defaultSubdomain() {
    return 'www'
  }

  get baseDomain() {
    return this.#baseDomains.find((domain) => window.location.host.includes(domain))
  }

  get subdomain() {
    const reactBaseDomain = this.baseDomain
    const isUrlIncludeSubdomain = window.location.host.includes(`.${reactBaseDomain}`)
    return isUrlIncludeSubdomain ? window.location.host.split(`.${reactBaseDomain}`)[0] : this.defaultSubdomain()
  }

  createOriginWithSubdomain(subdomain) {
    return `https://${this.createHostnameWithSubdomain(subdomain)}`
  }

  createHostnameWithSubdomain(subdomain) {
    return `${subdomain}${this.baseDomain}`
  }
}

const service = new DomainService()

export default service
