export default (theme) => {
  return {
    logo: {
      maxWidth: 200,
      objectFit: 'contain',
      height: 48,
      cursor: 'pointer',
    },
  }
}
