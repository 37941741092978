import { memo } from 'react'
import PropTypes from 'prop-types'

import OverflowedUser from '../OverflowedUser'
import User from '../User'

import useStyles from './styles'

const GridView = ({ displayingUsers, truncatedUsersAmount, gridTemplateColumns, overflowedUser }) => {
  const classes = useStyles()

  return (
    <div
      className={classes.container}
      style={{
        gridTemplateColumns,
      }}
    >
      {displayingUsers.map((item, index) => {
        return <User index={index} key={item.id} user={item} />
      })}
      {overflowedUser && <OverflowedUser amount={truncatedUsersAmount} overflowedUser={overflowedUser} />}
    </div>
  )
}

GridView.propTypes = {
  displayingUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      avatarUrl: PropTypes.string,
    }).isRequired
  ),
  truncatedUsersAmount: PropTypes.number.isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
  overflowedUser: PropTypes.shape({
    avatarUrl: PropTypes.string,
    displayName: PropTypes.string.isRequired,
  }),
}

export default memo(GridView)
