import { createSelector } from 'reselect'

import { getIsCurrentUserReviewer } from '@tabeeb/modules/../users/selectors'
import { getFileUploads } from '@tabeeb/modules/fileUploads/selectors'
import { getIsChatEnabled } from '@tabeeb/modules/appConfigState/selectors'
import {
  getIsContentLinkingEnabled,
  getIsSelectedPagesForMergingOpen,
  getLinkedContentsList,
} from '@tabeeb/modules/contentSharings/selectors'

export const getSelectedPanel = (state, side) => state.navbar.currentPanel[side]

export const showCustomVideoTab = (state) => state.customVideo.isTimelineOpen
export const showSpatialViewTab = (state) => state.spatialModel.isTimelineOpen
export const showThreeDimensionalModelTab = (state) => state.threedeeModel.isTimelineOpen
export const showShareSessionTab = (state) => state.shareSessionPagesList.isTimelineOpen
export const showStructureModelTab = (state) => state.structureModel.isTimelineOpen
export const showReviewSessionTab = (state) => state.reviewSessionPagesList.isTimelineOpen
export const showTowerSideProfileTab = (state) => state.towerSideProfile.isTimelineOpen
export const showTwoDLineDrawingTab = (state) => state.twoDLineDrawing.isTimelineOpen
export const showBentleyModelTab = (state) => state.bentleyModel.isTimelineOpen
export const showBentleyAnalyticsReportTab = (state) => state.bentleyAnalyticsReport.isTimelineOpen

export const getIsTabOpen = (state, tab) => {
  return Object.values(state.navbar.currentPanel).includes(tab)
}

export const showFileUploadsTab = createSelector([getFileUploads], (fileUploads) => {
  return fileUploads.length > 0
})

export const showChatTab = createSelector(
  [getIsCurrentUserReviewer, getIsChatEnabled],
  (isCurrentUserReviewer, isChatEnabled) => {
    return isChatEnabled && !isCurrentUserReviewer
  }
)

export const showActivitiesTab = createSelector([getIsCurrentUserReviewer], (isCurrentUserReviewer) => {
  return !isCurrentUserReviewer
})

export const showContentSharingsTab = createSelector(
  [getIsContentLinkingEnabled, getLinkedContentsList],
  (isContentsLinkingEnabled, linkedContents) => {
    return isContentsLinkingEnabled && linkedContents.length > 0
  }
)

export const showSelectedPagesForMergingTab = createSelector([getIsSelectedPagesForMergingOpen], (isOpen) => {
  return isOpen
})
