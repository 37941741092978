import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'
import * as galleryActions from '../../gallery/actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setAutodeskViewable](state, action) {
      const { Urn, Token, IsReady, ProgressPercentage } = action.payload
      return {
        Urn,
        Token,
        IsReady,
        ProgressPercentage,
      }
    },
    [combineActions(galleryActions.onGalleryItemSwitching, rawActions.resetAutodeskViewable)](state, action) {
      return defaultState
    },
  },
  defaultState
)
