import { put, takeLatest, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import Routes from '@tabeeb/routes'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onAddProjectFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to create a project.' }))
}

function* onAddProjectSuccess({ response: { data: project } }) {
  yield put(push(generatePath(Routes.aiProject, { id: project.Id })))
  yield put(notificationActions.onAddInfoNotification({ message: 'Project created successfully.' }))
}

function* createProject() {
  yield all([
    takeLatest(actions.addProject.success, onAddProjectSuccess),
    takeLatest(actions.addProject.failed, onAddProjectFailed),
  ])
}

export default createProject
