import { memo } from 'react'
import PropTypes from 'prop-types'

import { Chip, withStyles } from '@material-ui/core'
import { renameContentForTenant } from '@tabeeb/modules/contentReviews/actions'
import { useDispatch } from 'react-redux'
import styles from './styles'

const RenameButton = ({ tenantId, classes }) => {
  const dispatch = useDispatch()

  if (!tenantId) {
    return null
  }

  return (
    <Chip
      className={classes.renameButton}
      size='small'
      label='Rename'
      color='primary'
      clickable
      onClick={() => dispatch(renameContentForTenant(tenantId))}
    />
  )
}

RenameButton.propTypes = {
  tenantId: PropTypes.number,
  classes: PropTypes.shape({
    renameButton: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(memo(RenameButton))
