import { memo } from 'react'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import { Form, Formik } from 'formik'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

import { generatePassword } from '../../utils'
import { PasswordField, VALIDATION_SCHEMAS } from '../../forms'

const schema = Yup.object().shape({
  Password: VALIDATION_SCHEMAS.Password,
})

const EditInvitePasswordDialog = ({ open, invite, onClose, onSave }) => {
  const initial = {
    Password: generatePassword(),
  }

  const onSubmit = async ({ Password }) => {
    onClose()

    await onSave({
      Id: invite.Id,
      Password,
    })
  }

  return (
    <Formik initialValues={initial} validateOnMount validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
      {(formik) => (
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Edit invite password</DialogTitle>
          <DialogContent>
            <Form id='edit-invite-password-form' autoComplete='off'>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <PasswordField name='Password' label='Password' />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!formik.isValid}
              variant='contained'
              color='primary'
              type='submit'
              form='edit-invite-password-form'
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

EditInvitePasswordDialog.propTypes = {
  invite: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default memo(EditInvitePasswordDialog)
