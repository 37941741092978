import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as rawActions from '../actions'

import Timeline from '../../timeline/components/Timeline'

class SpatialViewImagesListContainer extends Component {
  constructor(props) {
    super(props)

    this.actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        class: 'submit',
      },
    ]
  }

  _handleClose = () => {
    const {
      actions: { closePagesDialog },
    } = this.props
    closePagesDialog()
  }

  _handleSubmit = () => {
    const {
      actions: { createSpatialModel },
    } = this.props
    createSpatialModel()
  }

  _handleDeleteItem = (itemIndex) => {
    const {
      actions: { deleteImageFromModel },
    } = this.props

    deleteImageFromModel(itemIndex)
  }

  _handleUpdateList = (newList) => {
    const {
      actions: { updateImagesSpatialModel },
    } = this.props

    updateImagesSpatialModel(newList)
  }

  render() {
    const { isOpen, imagesList, currentPanel } = this.props

    const props = {
      actions: this.actions,
      timeLineList: imagesList,
      handleDeleteItem: this._handleDeleteItem,
      handleUpdateList: this._handleUpdateList,

      isOpen,
      isSelected: currentPanel === TabPanel.SpatialView,
    }

    return <Timeline {...props} />
  }
}

SpatialViewImagesListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentPanel: PropTypes.string.isRequired,
  imagesList: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    closePagesDialog: PropTypes.func.isRequired,
    createSpatialModel: PropTypes.func.isRequired,
  }),
}

const mapStateToProps = (state) => ({
  isOpen: state.spatialModel.isTimelineOpen,
  imagesList: state.spatialModel.imagesList,
  currentPanel: state.navbar.currentPanel[TabPanelLocation.Bottom],
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SpatialViewImagesListContainer)
