import { useDispatch, useSelector } from 'react-redux'
import { memo, useEffect } from 'react'

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { PageLayout, PageHeader, TableLoadingProgress, TableWrapper } from '@tabeeb/uikit'
import { getWorkflows, getTenantWorkflows, addTenantWorkflow, removeTenantWorkflow } from '../../actions'
import { getTenantEnabledWorkflowsState } from '../../selectors'

const Workflows = () => {
  const dispatch = useDispatch()
  const { workflows, isLoading } = useSelector(getTenantEnabledWorkflowsState)

  useEffect(() => {
    dispatch(getWorkflows.request())
    dispatch(getTenantWorkflows.request())
  }, [dispatch])

  return (
    <PageLayout component={Paper} variant='outlined' spacing='page'>
      <PageHeader title='Tenant workflows' />
      <TableContainer>
        {isLoading && <TableLoadingProgress />}
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Name</TableCell>
                <TableCell align='center'>Description</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {workflows.length > 0 ? (
                workflows.map((workflow) => (
                  <TableRow key={workflow.Id}>
                    <TableCell align='left'>{workflow.Name}</TableCell>
                    <TableCell align='left'>{workflow.Description || 'No description'}</TableCell>
                    <TableCell align='right' width={50}>
                      {workflow.IsEnabled ? (
                        <Button
                          variant='outlined'
                          color='error'
                          onClick={() => dispatch(removeTenantWorkflow.request(workflow.Id))}
                        >
                          Disable
                        </Button>
                      ) : (
                        <Button
                          variant='outlined'
                          color='primary'
                          onClick={() => dispatch(addTenantWorkflow.request(workflow.Id))}
                        >
                          Enable
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align='center'>
                    <Typography variant='subtitle1'>{isLoading ? 'Loading workflows' : 'No workflows'}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </TableContainer>
    </PageLayout>
  )
}

export default memo(Workflows)
