export default (theme) => {
  return {
    container: {
      display: 'flex',
    },
    wrapper: {
      display: 'flex',
      overflowX: 'auto',
      flexGrow: 1,

      '&>div': {
        minWidth: 200,
        maxWidth: 300,
        width: '33%',
        flexGrow: 1,
        margin: theme.spacing(),
        marginLeft: 0,

        '&:first-child': {
          marginLeft: theme.spacing(),
        },
      },
    },
  }
}
