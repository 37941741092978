import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Popover, Checkbox, FormGroup, FormControlLabel, Button } from '@material-ui/core'
import { AnswerStatusFilterOptions, DisplayAnswersMode } from '@tabeeb/enums'
import { getDisplayAnswersMode, getFormAnswerStatusFilter } from '@tabeeb/modules/forms/selectors'
import { setAnswerStatusFilter } from '@tabeeb/modules/forms/actions'
import './styles.less'

const AnswerStatusFilter = () => {
  const selectedFilterOptions = useSelector(getFormAnswerStatusFilter)
  const displayAnswersMode = useSelector(getDisplayAnswersMode)
  const dispatch = useDispatch()

  const [selectedOptions, setSelectedOptions] = useState(selectedFilterOptions)
  const [anchorEl, setAnchorEl] = useState(null)

  const isAllLatestView = displayAnswersMode === DisplayAnswersMode.AllLatest

  const values = Object.values(AnswerStatusFilterOptions)
  const allSelected = selectedOptions.length === values.length

  const _handleFilterButtonClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const _handleFilterMenuClose = () => {
    setAnchorEl(null)
    setSelectedOptions(selectedFilterOptions)
  }

  const _handleFilterOptionClick = (e) => {
    const { value } = e.target
    const { checked } = e.target

    if (checked) setSelectedOptions([...selectedOptions, value])
    else setSelectedOptions(selectedOptions.filter((o) => o !== value))
  }

  const _handleSelectAllClick = () => {
    if (allSelected) {
      setSelectedOptions([])
    } else {
      setSelectedOptions(values)
    }
  }

  const _handleApplyBtnClick = () => {
    setAnchorEl(null)
    dispatch(setAnswerStatusFilter(selectedOptions))
  }

  return (
    <>
      <div
        className='form-answer-status-statistics-filter-icon'
        onClick={_handleFilterButtonClick}
        style={{
          color: allSelected ? 'unset' : '#0089cf',
          marginLeft: isAllLatestView ? 'unset' : 'auto',
        }}
      >
        <FilterListIcon fontSize='inherit' />
      </div>
      <Popover
        id='answer-status-filter-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={_handleFilterMenuClose}
        keepMounted
      >
        <div className='form-answer-status-filter-menu'>
          <div className='form-answer-status-filter-menu-header'>
            <span style={{ fontWeight: 'bold' }}>Status</span>
            <Button variant='text' className='form-answer-status-filter-select-all' onClick={_handleSelectAllClick}>
              {allSelected ? 'Unselect all' : 'Select all'}
            </Button>
          </div>
          <div className='form-answer-status-filter-options'>
            <FormGroup>
              {Object.values(AnswerStatusFilterOptions).map((option) => {
                const isChecked = selectedOptions && selectedOptions.includes(option)

                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={_handleFilterOptionClick}
                        value={option}
                        color='primary'
                      />
                    }
                    label={option}
                    key={option}
                  />
                )
              })}
            </FormGroup>
          </div>
          <div className='form-answer-status-filter-apply'>
            <Button
              variant='contained'
              color='primary'
              className='form-answer-status-filter-apply-btn'
              onClick={_handleApplyBtnClick}
            >
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default AnswerStatusFilter
