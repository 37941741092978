import { handleActions } from 'redux-actions'

import { onGalleryItemSwitching } from '@tabeeb/modules/gallery/actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.setLoadingInProgress](state, action) {
      return true
    },
    [onGalleryItemSwitching](state, action) {
      return true
    },
    [rawActions.resetLoadingInProgress](state, action) {
      return false
    },
  },
  defaultState
)
