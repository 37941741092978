const modelTrainingRequests = {
  getPagesStatisticsByFormsForProjectTrainingRequest: (data) => ({
    url: `api/pages/project/statistics/forms`,
    method: 'post',
    data,
  }),
  getPagesStatisticsByAiObjectsForProjectTrainingRequest: (data) => ({
    url: `api/pages/project/statistics/aiObjects`,
    method: 'post',
    data,
  }),
  getPagesForProjectTrainingRequest: (data) => ({
    url: `api/pages/project`,
    method: 'post',
    data,
  }),
  getPagesByIterationIdRequest: (data) => ({
    url: `api/iterations/pages`,
    method: 'get',
    params: data,
  }),
  getContentsByIterationIdRequest: (iterationId) => ({
    url: `api/iterations/${iterationId}/contents`,
    method: 'get',
  }),
  getAiObjectsByIterationIdRequest: (iterationId) => ({
    url: `api/iterations/${iterationId}/aiObjects`,
    method: 'get',
  }),
  getFormsByIterationIdRequest: (iterationId) => ({
    url: `api/iterations/${iterationId}/forms`,
    method: 'get',
  }),
  getAnnotationOwnersByIterationIdRequest: (iterationId) => ({
    url: `api/iterations/${iterationId}/annotationOwners`,
    method: 'get',
  }),
  getReviewersByIterationIdRequest: (iterationId) => ({
    url: `api/iterations/${iterationId}/reviewers`,
    method: 'get',
  }),
  getApproversByIterationIdRequest: (iterationId) => ({
    url: `api/iterations/${iterationId}/approvers`,
    method: 'get',
  }),
  getAnswerersByIterationIdRequest: (iterationId) => ({
    url: `api/iterations/${iterationId}/answerers`,
    method: 'get',
  }),
  getFormControlsByIterationIdRequest: (iterationId) => ({
    url: `api/iterations/${iterationId}/formControls`,
    method: 'get',
  }),
  getAiObjectsForProjectRequest: (data) => ({
    url: `api/ai/objects/tenant/project`,
    method: 'get',
    params: data,
  }),
  getFormsForProjectRequest: (data) => ({
    url: `api/forms/tenant/project`,
    method: 'get',
    params: data,
  }),
  getFormControlsForProjectRequest: (data) => ({
    url: `api/forms/tenant/project/formControls`,
    method: 'get',
    params: data,
  }),
  getUsersForProjectRequest: ({ searchQuery }) => ({
    url: `api/users?search=${encodeURIComponent(searchQuery)}`,
    method: 'get',
  }),
}

export default modelTrainingRequests
