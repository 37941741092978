import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import useStyles from './styles.js'

const SettingsField = ({ icon: Icon, label, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Icon className={classes.icon} />
      <Typography className={classes.label}>{label}</Typography>
      {children}
    </div>
  )
}

SettingsField.propTypes = {
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default memo(SettingsField)
