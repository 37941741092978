import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { useSelectedItemMenu } from '@tabeeb/shared/utils/hooks'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { getContentsList, getIsDataLoading, getIsFeatureEnabled } from '@tabeeb/modules/sessionsPage/selectors'
import {
  getIsCopySessionActionEnabled,
  getIsMoveSessionActionEnabled,
  getIsRestoreSessionActionEnabled,
} from '@tabeeb/modules/sessionsPage/selectors/sessionActions'

import { openSession } from '../../actions'
import { DialogTypes, Features, LayoutType } from '../../enums'
import { useLayout, usePageDialog, useSelection } from '../../hooks'

import SessionTile from './SessionTile'
import SessionsTableRow from './SessionsTableRow'
import SessionsTableHead from './SessionsTableHead'
import { SessionMenu } from '../Menus'
import NoSessionsPlaceholder from './NoSessionsPlaceholder'

const Sessions = () => {
  const dispatch = useDispatch()

  const sessions = useSelector(getContentsList)
  const isDataLoading = useSelector(getIsDataLoading)

  const [anchorEl, selectedSession, open, onOpenMenu, onCloseMenu] = useSelectedItemMenu()

  const { onOpen: onOpenSessionActivationDialog } = usePageDialog(DialogTypes.ActivateSession)
  const { onOpen: onOpenSessionRestorationDialog } = usePageDialog(DialogTypes.RestoreSession)
  const { onOpen: onOpenSessionCopyingDialog } = usePageDialog(DialogTypes.CopySession)

  const selection = useSelector((state) => getIsFeatureEnabled(state, Features.Selection))

  const copying = useSelector(getIsCopySessionActionEnabled)
  const foldering = useSelector(getIsMoveSessionActionEnabled)
  const restoration = useSelector(getIsRestoreSessionActionEnabled)

  const { selectedContentIds, addToSelection, removeFromSelection } = useSelection()

  const { layout } = useLayout()

  const onClick = useCallback(
    (session) => {
      if (copying && session.IsPublished && session.PublishedSessionContentSharingId) {
        onOpenSessionCopyingDialog({ session })
        return
      }

      if (restoration && session.IsDeleted) {
        onOpenSessionRestorationDialog({ session })
        return
      }

      if (!session.IsActive) {
        onOpenSessionActivationDialog({ session })

        return
      }

      dispatch(openSession({ session }))
    },
    [
      copying,
      dispatch,
      onOpenSessionActivationDialog,
      onOpenSessionCopyingDialog,
      onOpenSessionRestorationDialog,
      restoration,
    ]
  )

  const onSelect = useCallback(
    (selected, session) => {
      if (selected) {
        addToSelection([session])
      } else {
        removeFromSelection([session])
      }
    },
    [addToSelection, removeFromSelection]
  )

  const onSelectAll = useCallback(
    (select) => {
      if (select) {
        addToSelection(sessions)
      } else {
        removeFromSelection(sessions)
      }
    },
    [sessions, addToSelection, removeFromSelection]
  )

  const onMenu = useCallback(
    (e, session) => {
      onOpenMenu({ anchor: e.target, item: session })
    },
    [onOpenMenu]
  )

  const onDragStart = useCallback((e, session) => {
    e.dataTransfer.dropEffect = 'move'
    e.dataTransfer.setData('text', JSON.stringify(session))
  }, [])

  const draggable = foldering
  const showNoSessionsPlaceholder = !isDataLoading && sessions.length === 0

  return (
    <Box display='flex' mb={2} flexGrow={1}>
      {isDataLoading && <CircularProgressBar />}

      {layout === LayoutType.Grid && (
        <Grid container rowSpacing={3} columnSpacing={3.5} height={showNoSessionsPlaceholder ? 'auto' : 'fit-content'}>
          {showNoSessionsPlaceholder && (
            <Grid item display='flex' xs={12}>
              <NoSessionsPlaceholder />
            </Grid>
          )}
          {sessions.map((session) => (
            <Grid key={`tile-${session.Id}`} item xs={6} sm={4} md={4} lg={3} xl={3}>
              <SessionTile
                session={session}
                draggable={draggable}
                selectable={selection}
                selected={selectedContentIds.includes(session.Id)}
                onClick={onClick}
                onDragStart={onDragStart}
                onSelect={onSelect}
                onMenu={onMenu}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {layout === LayoutType.Table && (
        <Paper
          variant='outlined'
          sx={{
            minHeight: '100%',
            bgcolor: (theme) => theme.palette.background.session,
            color: (theme) => theme.palette.text.session,
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <Table size='small' sx={{ minHeight: showNoSessionsPlaceholder ? '100%' : undefined }} stickyHeader>
            <TableHead>
              <SessionsTableHead
                selectable={selection}
                allSelected={
                  sessions.length > 0 && sessions.every((session) => selectedContentIds.includes(session.Id))
                }
                onSelectAll={onSelectAll}
              />
            </TableHead>
            <TableBody>
              {showNoSessionsPlaceholder && (
                <TableRow>
                  <TableCell colSpan={selection ? 10 : 9} align='center'>
                    <NoSessionsPlaceholder />
                  </TableCell>
                </TableRow>
              )}

              {sessions.map((session) => (
                <SessionsTableRow
                  key={`row-${session.Id}`}
                  session={session}
                  draggable={draggable}
                  selectable={selection}
                  selected={selectedContentIds.includes(session.Id)}
                  onClick={onClick}
                  onDragStart={onDragStart}
                  onSelect={onSelect}
                  onMenu={onMenu}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}

      {selectedSession && (
        <SessionMenu anchorEl={anchorEl} open={open} onClose={onCloseMenu} session={selectedSession} />
      )}
    </Box>
  )
}

export default Sessions
