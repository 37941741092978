import { getShowGridViewButton } from '@tabeeb/modules/appConfigState/selectors'
import { getIsCallStarted } from '@tabeeb/modules/presentation/selectors'
import { createSelector } from 'reselect'

export const getIsGridViewActive = (state) => state.gridView.isActive
export const getIsGridViewButtonVisible = createSelector(
  [getIsCallStarted, getShowGridViewButton],
  (isCallStarted, showGridViewButton) => {
    return isCallStarted && showGridViewButton
  }
)
