import { handleActions, combineActions } from 'redux-actions'

import * as actions from '../actions/index'

const defaultState = false

export default handleActions(
  {
    [combineActions(actions.getGeneralContentsToSwitchRequest, actions.getFolderContentsToSwitchRequest)](
      state,
      { payload }
    ) {
      return true
    },
    [combineActions(actions.getGeneralContentsToSwitchSuccess, actions.getFolderContentsToSwitchSuccess)](
      state,
      { payload }
    ) {
      return false
    },
  },
  defaultState
)
