export default (theme) => {
  const blurRadius = 10

  return {
    container: {},
    avatar: {
      width: 100,
      height: 100,
      fontSize: 38,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: theme.shadows[4],
    },
    background: {
      position: 'absolute',
      width: `calc(100% + ${blurRadius * 4}px)`,
      height: `calc(100% + ${blurRadius * 4}px)`,
      top: -blurRadius * 2,
      left: -blurRadius * 2,
      filter: `blur(${blurRadius}px)`,
      objectFit: 'cover',
    },
    image: {
      position: 'absolute',
      height: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      margin: 'auto',
      boxShadow: theme.shadows[5],
      zIndex: 0,
      left: '50%',
      top: 0,
      transform: 'translate(-50%, 0px)',
    },
  }
}
