import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.openShareSessionPagesDialog](state, action) {
      return action.payload
    },
    [rawActions.closeShareSessionPagesDialog](state, action) {
      return false
    },
    [rawActions.resetState](state, action) {
      return false
    },
  },
  false
)
