import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { displayActivityTypes } from '../services/activityHelper'
import ActivityList from '../components/ActivityList'
import { AnnotationType } from '../../../Enums'
import { fallbackUser } from '../../shared/users/common'
import * as rawActions from '../actions'

class ActivitiesListContainer extends Component {
  constructor(props) {
    super(props)

    this.listRef = null
  }

  getListRef = (ref) => {
    this.listRef = ref
  }

  getFilteredActivities() {
    const { activities, activitiesFilter, currentPageId } = this.props
    const isValidSearchText = activitiesFilter.searchFilter.trim() !== '' && activitiesFilter.searchFilter.length >= 2

    const activitiesList = activitiesFilter.activityType === 'all' && !isValidSearchText ? [] : activities

    const displayPageFilter = (activity) =>
      activitiesFilter.displayPage === 'all' ||
      (activitiesFilter.displayPage === 'current' && currentPageId === activity.pageId)

    const userFilter = (activity) => activitiesFilter.userId === 'all' || activitiesFilter.userId === activity.userId

    const ActivityTypeFilter = (activity) => {
      if (activitiesFilter.activityType === 'all') {
        return true
      }

      if (!displayActivityTypes[activitiesFilter.activityType]) {
        return false
      }

      switch (activitiesFilter.activityType) {
        case 'Flag':
          return displayActivityTypes[activitiesFilter.activityType].includes(activity.type) && activity.parentId
        case 'Text':
          return displayActivityTypes[activitiesFilter.activityType].includes(activity.type)
        default:
          return displayActivityTypes[activitiesFilter.activityType].includes(activity.type)
      }
    }

    const searchFilter = activitiesFilter.searchFilter.trim().toLowerCase()
    const searchTextFilter = (activity) => {
      const isDisabledTextSearch = ['Image', 'Shape', 'Audio', 'Drawing'].includes(activitiesFilter.activityType)
      if (isDisabledTextSearch) {
        return true
      }
      if (isValidSearchText) {
        if (activity.type === AnnotationType.Text || activity.type === AnnotationType.AI) {
          const annotationText = activity.text || activity.textInfo.text
          return annotationText.toLowerCase().indexOf(searchFilter) !== -1
        }
        return false
      }
      return true
    }

    const hotspotFilter = (activity) => {
      if (
        activity.type === AnnotationType.Hotspot ||
        activity.isPartOfFlagAnnotation ||
        activity.type === AnnotationType.AIHotspot
      ) {
        return false
      }

      const hasChildAnnotations = activitiesList.some((item) => item.parentId === activity.id)
      const isOneOfFlagAnnotations = [
        AnnotationType.Rectangle,
        AnnotationType.Ellipse,
        AnnotationType.Stroke,
        AnnotationType.ArrowEnd,
        AnnotationType.Line,
      ].includes(activity.type)

      return !(isOneOfFlagAnnotations && hasChildAnnotations)
    }

    const result = activitiesList
      .filter(displayPageFilter)
      .filter(userFilter)
      .filter(ActivityTypeFilter)
      .filter(hotspotFilter)
      .filter(searchTextFilter)

    return result
  }

  getUserDictionary() {
    const userDictionary = {}
    this.props.users.forEach((user) => {
      userDictionary[user.id] = user
    })
    return userDictionary
  }

  getFoldersDictionary() {
    const foldersDictionary = {}
    foldersDictionary.null = 'Main Gallery'

    this.props.foldersList.forEach((folder) => {
      foldersDictionary[folder.Id] = folder.Name
    })

    return foldersDictionary
  }

  getPageNamesDictionary() {
    const foldersDictionary = this.getFoldersDictionary()
    const pageInfoDictionary = {}

    const groupredPages = _.groupBy(this.props.galleryList, (i) => i.folderId)
    for (const key in groupredPages) {
      const group = groupredPages[key]

      group.forEach((page, itemNo) => {
        const folderName = foldersDictionary[page.folderId]

        pageInfoDictionary[page.id] = { folderName: `Folder: ${folderName}`, pageName: `Page: ${itemNo + 1}` }
      })
    }

    return pageInfoDictionary
  }

  getDisplayTime(timestampCreated) {
    const createdDate = new Date(timestampCreated)
    if (new Date().toDateString() !== createdDate.toDateString()) {
      return createdDate.toLocaleDateString()
    }

    const resultTime = createdDate.toLocaleTimeString(window.navigator.language, { hour: '2-digit', minute: '2-digit' })
    return `${resultTime}, today`
  }

  onScroll = (e) => {
    const { actions, activitiesContentHeight } = this.props

    const { scrollHeight, scrollTop, clientHeight } = $(this.listRef)[0]

    if (Math.floor(Math.abs(scrollHeight - clientHeight - scrollTop)) <= 1) {
      if (activitiesContentHeight < scrollHeight) {
        actions.loadMoreActivities()
        actions.setActivitiesContentHeight(scrollHeight)
      }
    }
  }

  render() {
    const users = this.getUserDictionary()
    const pageNames = this.getPageNamesDictionary()
    const filteredActivities = this.getFilteredActivities()

    const activities = filteredActivities.map((activity) => {
      return {
        ...activity,
        user: users[activity.userId] || fallbackUser,
        pageInfo: pageNames[activity.pageId] || {},
        displayTime: this.getDisplayTime(activity.timestampCreated),
      }
    })

    const { searchInProgress } = this.props

    const props = {
      getListRef: this.getListRef,
      activities,
      onScroll: this.onScroll,
      searchInProgress,
    }

    return <ActivityList {...props} />
  }
}

ActivitiesListContainer.propTypes = {
  activities: PropTypes.array.isRequired,
  activitiesFilter: PropTypes.object.isRequired,
  currentPageId: PropTypes.number,
  users: PropTypes.array.isRequired,
  galleryList: PropTypes.array.isRequired,
  foldersList: PropTypes.array.isRequired,
  actions: PropTypes.object,
  activitiesContentHeight: PropTypes.number.isRequired,
  searchInProgress: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ activities, users, gallery }) => {
  return {
    activities: activities.activitiesList.activities,
    activitiesFilter: activities.activitiesFilter,
    currentPageId: gallery.galleryState.selectedGalleryItemId,
    users: users.users,
    galleryList: gallery.galleryList,
    foldersList: gallery.foldersList,
    activitiesContentHeight: activities.activitiesList.contentHeight,
    searchInProgress: activities.activitiesSearchInProgress.searchInProgress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesListContainer)
