import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { Dialog, DialogContent, DialogTitle, Button, DialogActions, Grid, InputAdornment } from '@material-ui/core'

import { FormikTextField } from '@tabeeb/modules/shared/forms'
import { HealthDataTypesDisplayName, SystemOfMeasures } from '@tabeeb/enums'

import { getInputPropsTitleBySystemOfMeasure } from '../../helper'

import useStyles from './styles'

const addHealthDataSchema = Yup.object().shape({
  SystemOfMeasure: Yup.string(),
  Temperature: Yup.number().when('SystemOfMeasure', {
    is: (val) => Number(val) === SystemOfMeasures.Metric,
    then: Yup.number().min(0, 'Min 0 ℃.').max(50, 'Max 50 ℃.'),
    otherwise: Yup.number().min(0, 'Min 0 ℉.').max(122, 'Max 122 ℉.'),
  }),
  BloodPressureSystolic: Yup.number().min(0, 'Min 0 mmHg.').max(200, 'Max 200 mmHg.'),
  BloodPressureDiastolic: Yup.number().min(0, 'Min 0 mmHg.').max(150, 'Max 150 mmHg.'),
  GlucoseLevel: Yup.number().when('SystemOfMeasure', {
    is: (val) => Number(val) === SystemOfMeasures.Metric,
    then: Yup.number().min(0, 'Min 0 mmol/L.').max(10, 'Max 10 mmol/L.'),
    otherwise: Yup.number().min(0, 'Min 0 mg/dL.').max(180, 'Min 180 mg/dL.'),
  }),
  Pulse: Yup.number().min(0, 'Min 0 bpm.').max(220, 'Max 220 bpm.'),
  RestingPulse: Yup.number().min(0, 'Min 0 bpm.').max(220, 'Max 220 bpm.'),
  OxygenSaturation: Yup.number().min(0, 'Min 0%.').max(100, 'Max 100%.'),
  Height: Yup.number().when('SystemOfMeasure', {
    is: (val) => Number(val) === SystemOfMeasures.Metric,
    then: Yup.number().min(0, 'Min 0 cm.').max(303, 'Max 303 cm.'),
  }),
  HeightFt: Yup.number().min(0, 'Min 0 ft.').max(9, 'Max 9 ft.'),
  HeightIn: Yup.number().min(0, 'Min 0 in.').max(11.99, '12 inches = 1 ft.'),
  Weight: Yup.number().when('SystemOfMeasure', {
    is: (val) => Number(val) === SystemOfMeasures.Metric,
    then: Yup.number().min(0, 'Min 0 kg.').max(500, 'Max 500 kg.'),
    otherwise: Yup.number().min(0, 'Min 0 pounds.').max(1102.31, 'Max 1102.31 pounds.'),
  }),
  PerfusionIndex: Yup.number().min(0, 'Min 0%.').max(100, 'Max 100%.'),
  PeakExpiratoryFlow: Yup.number().min(0, 'Min 0 L/min.').max(1000, 'Max 1000 L/min.'),
  ForcedExpiratoryVolume: Yup.number().min(0, 'Min 0 L.').max(10, 'Max 10 L.'),
})

const AddHealthDataDialog = ({ isOpen, chartHealthDataToSupplement, onClose, handleSubmit, userSystemOfMeasure }) => {
  const classes = useStyles()
  const inputPropsTitle = getInputPropsTitleBySystemOfMeasure(chartHealthDataToSupplement?.name, userSystemOfMeasure)
  const inputProps = !Array.isArray(inputPropsTitle)
    ? { endAdornment: <InputAdornment position='end'>{inputPropsTitle}</InputAdornment> }
    : {
        HeightFt: { endAdornment: <InputAdornment position='end'>{inputPropsTitle.shift()}</InputAdornment> },
        HeightIn: { endAdornment: <InputAdornment position='end'>{inputPropsTitle.pop()}</InputAdornment> },
      }

  const initFormData = {
    [chartHealthDataToSupplement?.name]: '',
    SystemOfMeasure: userSystemOfMeasure,
    HealthDataTypeId: chartHealthDataToSupplement?.dataType,
  }

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle className={classes.title}>
        Add {HealthDataTypesDisplayName[chartHealthDataToSupplement?.name]}
      </DialogTitle>
      <Formik
        initialValues={initFormData}
        enableReinitialize
        validationSchema={addHealthDataSchema}
        onSubmit={(data) => handleSubmit(data)}
      >
        <Form className={classes.form} autoComplete='off'>
          <DialogContent>
            <Grid container wrap='nowrap' direction='row' spacing={1}>
              {chartHealthDataToSupplement?.name === 'Height' && userSystemOfMeasure === SystemOfMeasures.Imperial ? (
                <>
                  <Grid item xs={6}>
                    <Field
                      name='HeightFt'
                      required
                      type='number'
                      component={FormikTextField}
                      InputProps={inputProps.HeightFt}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name='HeightIn'
                      required
                      type='number'
                      component={FormikTextField}
                      InputProps={inputProps.HeightIn}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Field
                    name={chartHealthDataToSupplement?.name}
                    required
                    type='number'
                    InputProps={inputProps}
                    component={FormikTextField}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color='primary' type='submit' className={classes.button}>
              Add
            </Button>
            <Button onClick={onClose} className={classes.button}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

AddHealthDataDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  userSystemOfMeasure: PropTypes.number,
  chartHealthDataToSupplement: PropTypes.shape({
    name: PropTypes.string.isRequired,
    dataType: PropTypes.number.isRequired,
  }),
}

export default AddHealthDataDialog
