import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const getFormPermissionsByTenant = createApiActions('GET_FORM_PERMISSIONS_BY_TENANT')
export const updateFormPermissionsByTenant = createApiActions('UPDATE_FORM_PERMISSIONS_BY_TENANT')
export const enableFormPermissionsSyncByTenant = createApiActions('ENABLE_FORM_PERMISSIONS_SYNC_BY_TENANT')

export const getFormPermissionsByNewForm = createApiActions('GET_FORM_PERMISSIONS_BY_NEW_FORM')

export const getFormPermissionsByForm = createApiActions('GET_FORM_PERMISSIONS_BY_FORM')
export const updateFormPermissionsByForm = createApiActions('UPDATE_FORM_PERMISSIONS_BY_FORM')
export const enableFormPermissionsSyncByForm = createApiActions('ENABLE_FORM_PERMISSIONS_SYNC_BY_FORM')

export const resetState = createAction('RESET_FORM_PERMISSIONS_STATE')
