import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'stretch',
      padding: theme.spacing(0.5),
      background: '#E6EEF9',
      borderRadius: theme.shape.borderRadius,
    },
  }
})
