import { all, takeLatest, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { notificationActions } from '@tabeeb/modules/notification'
import Routes from '@tabeeb/routes'
import * as rawActions from '../actions'

function* powerBIReportCreated(_action) {
  yield put(rawActions.loadPowerBIReports())
}

function* powerBIReportCreateFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to create report'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportDeleted(_action) {
  yield put(push(Routes.powerBIReports))
}

function* powerBIReportDeleteFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to delete report'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportUpdateFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to update report'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportTenantDeleteFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to delete report tenant'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportTenantUpdated(_action) {
  yield put(notificationActions.onAddSuccessNotification({ message: 'Report tenant updated' }))
}

function* powerBIReportTenantCreateFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to create report tenant'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportUserCreateFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to create report user'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportUserDeleteFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to delete report user'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* refetchPowerBIReportTenants(_action) {
  yield put(rawActions.setPowerBIReportTenantsRefetch(true))
}

function* refetchPowerBIReportUsers(_action) {
  yield put(rawActions.setPowerBIReportUsersRefetch(true))
}

function* refetchPowerBIReportDependentModules(_action) {
  yield put(rawActions.setPowerBIReportDependentModulesRefetch(true))
}

function* dashboardSaga() {
  yield all([
    takeLatest(rawActions.createPowerBIReport.success, powerBIReportCreated),
    takeLatest(rawActions.createPowerBIReport.failed, powerBIReportCreateFailed),
    takeLatest(rawActions.deletePowerBIReport.success, powerBIReportDeleted),
    takeLatest(rawActions.deletePowerBIReport.failed, powerBIReportDeleteFailed),
    takeLatest(
      [rawActions.updatePowerBIReport.failed, rawActions.updatePowerBIReportName.failed],
      powerBIReportUpdateFailed
    ),
    takeLatest(rawActions.deletePowerBIReportTenant.failed, powerBIReportTenantDeleteFailed),
    takeLatest(rawActions.createPowerBIReportTenant.failed, powerBIReportTenantCreateFailed),
    takeLatest(rawActions.createPowerBIReportTenant.success, refetchPowerBIReportTenants),
    takeLatest(rawActions.createPowerBIReportUser.success, refetchPowerBIReportUsers),
    takeLatest(rawActions.createPowerBIReportDependentModule.success, refetchPowerBIReportDependentModules),
    takeLatest(rawActions.updatePowerBIReportTenant.success, powerBIReportTenantUpdated),
    takeLatest(rawActions.createPowerBIReportUser.failed, powerBIReportUserCreateFailed),
    takeLatest(rawActions.deletePowerBIReportUser.failed, powerBIReportUserDeleteFailed),
  ])
}

export default dashboardSaga
