import { handleActions } from 'redux-actions'

import { setViewerBackground } from '../actions'
import { Defaults } from '../constants'

const defaultState = Defaults.BACKGROUND

export default handleActions(
  {
    [setViewerBackground](state, { payload }) {
      return payload
    },
  },
  defaultState
)
