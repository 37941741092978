import { handleActions, combineActions } from 'redux-actions'

import { clearFoundUsersList, setFoundUsersList, resetShareSessionDialog, resetState } from '../actions'

const defaultState = []

export default handleActions(
  {
    [setFoundUsersList](state, action) {
      return action.payload
    },
    [combineActions(clearFoundUsersList, resetShareSessionDialog, resetState)](state, action) {
      return defaultState
    },
  },
  defaultState
)
