import { ControlType, FormItemType } from '../../../Enums'
import { generateId } from './generateTempId'

export function createCounter(control, controlsList, mode, formId, tooltip, controlLabel, order) {
  let newCounter = {}

  newCounter.TempId = mode === 'import' ? order : generateId(controlsList)
  newCounter.Name = 'Counter'
  newCounter.Type = 'Counter'
  newCounter.FormItemType = FormItemType.Counter
  newCounter.Order = mode === 'import' ? order : controlsList.length
  newCounter.Label = mode === 'import' ? controlLabel : control
  newCounter.ControlType = ControlType.Counter
  newCounter.CounterLimit = null
  newCounter.IncrementValue = null
  newCounter.IncrementPeriod = null
  newCounter.AIObjectId = ''
  newCounter.Conditions = null
  newCounter.Info = null
  newCounter.CountAIObjects = false

  if (mode === 'edit') {
    newCounter = {
      ...newCounter,
      FormId: formId,
    }
  }
  return newCounter
}
