import { createAction } from 'redux-actions'

export const enableTrackingFilter = createAction('EBANLE_TRACKING_FILTER')
export const disableTrackingFilter = createAction('DISABLE_TRACKING_FILTER')
export const setTrackingFilterValue = createAction('SET_TRACKING_FILTER_VALUE')

export const getTrackings = createAction('GET_TRACKINGS')
export const getTrackingsRequest = createAction('GET_TRACKINGS_REQUEST')
export const getTrackingsSuccess = createAction('GET_TRACKINGS_SUCCESS')
export const getTrackingsFailed = createAction('GET_TRACKINGS_FAILED')

export const resetTracking = createAction('RESET_TRACKING')

export const openTracking = createAction('OPEN_TRACKING')
export const closeTracking = createAction('CLOSE_TRACKING')

export const addTracking = createAction('ADD_TRACKING')
export const addTrackingRequest = createAction('ADD_TRACKING_REQUEST')
export const addTrackingSuccess = createAction('ADD_TRACKING_SUCCESS')
export const addTrackingFailed = createAction('ADD_TRACKING_FAILED')

export const deleteTrackingRequest = createAction('DELETE_TRACKING_REQUEST')
export const deleteTrackingSuccess = createAction('DELETE_TRACKING_SUCCESS')
export const deleteTrackingFailed = createAction('DELETE_TRACKING_FAILED')
