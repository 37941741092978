import { handleActions } from 'redux-actions'

import * as muteVideoActions from '../actions/muteVideo'

const defaultValue = false

export default handleActions(
  {
    [muteVideoActions.onMuteVideoProgressStateUpdate](state, action) {
      return action.payload
    },
  },
  defaultValue
)
