import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    weekday: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
})
