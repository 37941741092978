/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Paper, IconButton, CircularProgress } from '@material-ui/core'

import { FileUploadStatus } from '../../constants'
import { isPdf, isImage, isVideo, isDocument } from '../../services'

import './styles.less'

function getFileUploadIconByFile(file) {
  if (isPdf(file)) return 'picture_as_pdf'
  if (isImage(file)) return 'image'
  if (isVideo(file)) return 'slideshow'
  if (isDocument(file)) return 'description'

  return 'insert_drive_file'
}

const FileUpload = ({ item, handleRetryUpload, handleCancelUpload }) => {
  const fileTypeIcon = getFileUploadIconByFile(item.file)

  return (
    <Paper className='file-upload-container'>
      <i className='material-icons'>{fileTypeIcon}</i>

      <p className='file-upload-name'>{item.file.name}</p>

      {(item.status === FileUploadStatus.Pending || item.status === FileUploadStatus.InProgress) && (
        <IconButton
          className={classNames('file-upload-status-icon', 'file-upload-status-error')}
          size='small'
          title='Cancel'
          onClick={handleCancelUpload}
        >
          <i className='material-icons'>clear</i>
        </IconButton>
      )}

      {(item.status === FileUploadStatus.Failed || item.status === FileUploadStatus.Canceled) && (
        <div className='switch-on-hover-buttons'>
          <IconButton className='file-upload-status-icon' size='small' title='Retry' onClick={handleRetryUpload}>
            <i className='material-icons'>repeat</i>
          </IconButton>
          <i
            className={classNames('material-icons', 'file-upload-status-icon', 'file-upload-status-error')}
            title={`${item.status === FileUploadStatus.Failed ? 'Failed to upload file' : 'Upload was cancelled'} `}
          >
            error
          </i>
        </div>
      )}

      {item.status === FileUploadStatus.Done && (
        <i
          className={classNames('material-icons', 'file-upload-status-icon', 'file-upload-status-success')}
          title='Uploaded'
        >
          check_circle
        </i>
      )}
    </Paper>
  )
}

FileUpload.propTypes = {
  handleRetryUpload: PropTypes.func.isRequired,
  handleCancelUpload: PropTypes.func.isRequired,
}

export default memo(FileUpload)
