import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { IconButton, Tooltip } from '@mui/material'
import { Ballot } from '@mui/icons-material'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { getHasSwitchReviewerPermission } from '@tabeeb/modules/sessionsPage/selectors/permissions'

const SwitchReviewerButton = ({ onClick }) => {
  const isVisible = useSelector(getHasSwitchReviewerPermission)
  if (!isVisible) {
    return null
  }

  return (
    <Tooltip title={sessionTitleFormatter.format('Switch reviewer of selected sessions')}>
      <IconButton size='small' onClick={onClick}>
        <Ballot />
      </IconButton>
    </Tooltip>
  )
}

SwitchReviewerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default memo(SwitchReviewerButton)
