import '../../css/styles.less'

import { MainScreenContentType } from '@tabeeb/enums'

import ToolbarGate from '@tabeeb/modules/playerToolbar/components/ToolbarGate'

import PlayerProgressBarContainer from '../../containers/PlayerProgressBarContainer'
import ShowPlayerControlsContainer from '../../containers/ShowPlayerControlsContainer'

const ProgressBar = () => (
  <ToolbarGate contentType={MainScreenContentType.Gallery}>
    <ShowPlayerControlsContainer>
      <PlayerProgressBarContainer />
    </ShowPlayerControlsContainer>
  </ToolbarGate>
)

export default ProgressBar
