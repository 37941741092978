import { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { IconButton, Tooltip } from '@mui/material'
import { FilterAlt } from '@mui/icons-material'

import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import QuestionsMenu from '../QuestionsMenu'
import { getSncFiltersState } from '../../selectors'

const AdvancedFiltersButton = ({ onApply }) => {
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const { enabled } = useSelector(getSncFiltersState)

  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (open) {
      setTouched(false)
    }
  }, [open])

  const onTouch = useCallback(() => {
    setTouched(true)
  }, [])

  const onCloseMenu = useCallback(() => {
    onClose()

    if (touched) {
      onApply()
    }
  }, [touched, onApply, onClose])

  const isVisible = useSelector((state) => state.appConfigState.showAdvancedFilterButton)
  if (!isVisible) {
    return null
  }

  return (
    <>
      <Tooltip title='Filter by session naming convention'>
        <IconButton ref={anchorRef} size='small' onClick={onOpen}>
          <FilterAlt color={enabled ? 'primary' : 'inherit'} />
        </IconButton>
      </Tooltip>
      <QuestionsMenu anchorEl={anchorRef.current} open={open} onClose={onCloseMenu} onTouch={onTouch} />
    </>
  )
}

AdvancedFiltersButton.defaultProps = {
  onApply: () => {},
}

AdvancedFiltersButton.propTypes = {
  onApply: PropTypes.func,
}

export default memo(AdvancedFiltersButton)
