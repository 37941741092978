import { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Tooltip, withStyles } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'

import styles from './styles'

const UserOnlineStatus = ({ classes, online }) => {
  return (
    <Tooltip arrow title={online ? 'Online' : 'Offline'}>
      <FiberManualRecord className={classNames(classes.icon, { [classes.online]: online })} />
    </Tooltip>
  )
}

UserOnlineStatus.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    online: PropTypes.string.isRequired,
  }).isRequired,
  online: PropTypes.bool.isRequired,
}

export default memo(withStyles(styles)(UserOnlineStatus))
