const sessionsPageRequests = {
  getSessionsRequest: (data) => ({
    url: `api/contents`,
    method: 'get',
    params: data,
    transformResponse: (response) => {
      const parsedResponse = JSON.parse(response)
      return parsedResponse.map((e) => ({
        ...e,
        Permissions: e.Permissions,
      }))
    },
  }),
  createSessionRequest: ({ data, needPermission }) => ({
    url: `api/contents/createcontent`,
    method: 'post',
    data,
  }),
  loadContentsRequest: () => ({
    url: `api/contents`,
    method: 'get',
  }),
  deleteSessionRequest: ({ contentId }) => ({
    url: `api/contents/${contentId}`,
    method: 'delete',
  }),
  deleteSessionPermanentlyRequest: ({ contentId }) => ({
    url: `api/contents/deletepermanently/${contentId}`,
    method: 'post',
  }),
  leaveSessionRequest: ({ contentId }) => ({
    url: `api/contents/${contentId}/leave`,
    method: 'post',
  }),
  activateSessionRequest: (data) => ({
    url: `api/contents/activate`,
    method: 'post',
    data,
  }),
  createFolderRequest: (data) => ({
    url: `api/folders/create`,
    method: 'post',
    data,
  }),
  getFoldersRequest: () => ({
    url: `api/folders`,
    method: 'get',
    transformResponse: (response) => {
      const parsedResponse = JSON.parse(response)
      return parsedResponse.map((e) => ({
        ...e,
        FolderUsersPermissions: e.FolderUsersPermissions,
      }))
    },
  }),
  deleteFolderRequest: (folderId) => ({
    url: `api/folders/${folderId}`,
    method: 'delete',
  }),
  deleteFolderPermanentlyRequest: (folderId) => ({
    url: `api/folders/deletePermanently/${folderId}`,
    method: 'post',
  }),
  restoreFolderRequest: (folderId) => ({
    url: `api/folders/restore/${folderId}`,
    method: 'post',
  }),
  setVideoBridgeRequest: (data) => ({
    url: `api/contents/setvideobridge`,
    method: 'post',
    data,
  }),
  getDeletedFoldersRequest: () => ({
    url: `api/folders/deleted`,
    method: 'get',
  }),
  updateFolderRequest: (data) => ({
    url: `api/folders/rename`,
    method: 'put',
    data,
  }),
  getFolderRequest: (folderId) => ({
    url: `api/folders/currentFolder/${folderId}`,
    method: 'get',
  }),
  switchSessionFolderRequest: (data) => ({
    url: `api/contentfolder/switchContentFolder`,
    method: 'post',
    data,
  }),
  getGeneralContentsToSwitchRequest: () => ({
    url: `api/contents/getToSwitch`,
    method: 'get',
  }),
  getFolderContentsToSwitchRequest: (folderId) => ({
    url: `api/contents/getToSwitch?folderId=${folderId}`,
    method: 'get',
  }),
  restoreSessionRequest: ({ contentId }) => ({
    url: `api/contents/restorecontent/${contentId}`,
    method: 'post',
  }),
  createContentSharingRequest: (data) => ({
    url: `api/contentsharings`,
    method: 'post',
    data,
  }),
  publishSessionRequest: ({ contentId }) => ({
    url: `api/contents/publishcontent/${contentId}`,
    method: 'post',
  }),
  bulkCopySessionRequest: ({ contentId }) => ({
    url: `api/contents/bulkcopy/${contentId}`,
    method: 'post',
  }),
  getTenantUsersCountRequest: ({ tenantId }) => ({
    url: `api/admin/tenantuserscount/${tenantId}`,
    method: 'get',
  }),
  addUsersToFolderRequest: (data) => ({
    url: `api/folders/addUsers`,
    method: 'post',
    data,
  }),
  unpublishSessionRequest: (contentId) => ({
    url: `api/contents/unpublishcontent/${contentId}`,
    method: 'post',
  }),
  getJitsiTokenRequest: (roomName) => ({
    url: `api/contents/getjitsitoken?roomName=${roomName}`,
    method: 'get',
  }),
  getTenantStoragesRequest: () => ({
    url: `tenant-service/api/tenantStorages`,
    method: 'get',
  }),
  switchSessionStorageRequest: ({ contentId, storageId }) => ({
    url: `api/contents/${contentId}/storage`,
    method: 'put',
    data: {
      storageId,
    },
  }),
  switchSessionOwnerRequest: ({ contentId, newOwnerId }) => ({
    url: `api/contents/switchOwner`,
    method: 'post',
    data: {
      ContentId: contentId,
      NewOwnerId: newOwnerId,
      IgnoreSessionStatus: true,
    },
  }),
  getUsersInFolderRequest: ({ folderId }) => ({
    url: `api/folders/getUsers?folderId=${folderId}`,
    method: 'get',
  }),
  editUsersInFolderRequest: (data) => ({
    url: `api/folders/editUser`,
    method: 'put',
    data,
  }),
  leaveFolderRequest: (data) => ({
    url: `api/folders/leave`,
    method: 'post',
    data,
  }),
  getIsFolderParticipantRequest: ({ folderId, contentId }) => ({
    url: `api/folders/isContentFolderParticipant?folderId=${folderId}&contentId=${contentId}`,
    method: 'get',
  }),
  searchTargetContentRequest: (data) => ({
    url: `api/contents/searchTargetForMerging`,
    method: 'post',
    data,
  }),
  // new
  addUsersToSessionRequest: (data) => ({
    url: `api/contents/addUsers`,
    method: 'post',
    data,
  }),
  switchSessionReviewerRequest: (data) => ({
    url: `api/contentsharings/switchReviewer`,
    method: 'post',
    data,
  }),
  // delete
  getPublishedSessionsRequest: (data) => ({
    url: `api/contents/publishedcontents`,
    method: 'get',
    params: data,
  }),
  searchUsersRequest: ({ searchText, tenantId, roles }) => {
    let url = `api/Users?search=${encodeURIComponent(searchText)}`
    if (tenantId) url += `&tenantId=${tenantId}`
    if (roles) url += roles.map((roleId) => `&roles=${roleId}`).join('')
    return {
      url,
      method: 'get',
    }
  },
}

export default sessionsPageRequests
