import { handleActions } from 'redux-actions'

import { enableSncFilters, disableSncFilters, resetState } from '../actions'

const defaultState = false

export default handleActions(
  {
    [enableSncFilters]() {
      return true
    },
    [disableSncFilters]() {
      return false
    },
    [resetState]() {
      return false
    },
  },
  defaultState
)
