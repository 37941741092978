/* eslint-disable no-shadow */
import { TenantSessionsMode } from '@tabeeb/enums'
import { put, takeLatest, select, all } from 'redux-saga/effects'

import { ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID } from '@tabeeb/modules/tenantSessionsPage/services/dashboards'
import * as tenantSessionsSelectors from '@tabeeb/modules/tenantSessionsPage/selectors'
import * as rawActions from '@tabeeb/modules/tenantSessionsPage/actions'
import { setSelectedSessions } from '@tabeeb/modules/sessionsPage/actions/index'

function* getTenantContentsDashboardsSuccess(action) {
  const dashboards = action.response.data
  const defaultCustomDashboard = dashboards.find((v) => v.IsDefault)

  if (!defaultCustomDashboard) {
    yield put(rawActions.loadTenantContents())
    return
  }

  yield put(rawActions.selectTenantContentsDashboard({ selectedDashboard: defaultCustomDashboard }))
}

function* setDefaultDashboard(action) {
  const selectedDashboardId = yield select(tenantSessionsSelectors.getSelectedDashboardId)

  if (!selectedDashboardId) {
    yield put(rawActions.resetDefaultDashboard.request())
  } else {
    yield put(rawActions.setDashboardAsDefault.request(selectedDashboardId))
  }
}

function* selectTenantContentsDashboard(action) {
  const { selectedDashboard, isModeChanged } = action.payload
  const isCustomDashboardsEnabled = yield select(tenantSessionsSelectors.getIsCustomDashboardsEnabled)

  if (!isModeChanged) {
    yield put(rawActions.getTenantContentsDashboardColumns.request(selectedDashboard.Id))
    return
  } else {
    yield put(setSelectedSessions([]))
  }

  let newMode = TenantSessionsMode.Actual

  if (selectedDashboard.IsDeletedMode) {
    newMode = TenantSessionsMode.Deleted
  }

  const isDeletedMode = newMode === TenantSessionsMode.Deleted

  yield put(rawActions.setSortingColumnWithoutReload(isDeletedMode ? 'CreatedOn' : 'LastActivity'))
  yield put(rawActions.selectTenantSessionsMode(newMode))

  if (isDeletedMode || !isCustomDashboardsEnabled) {
    yield put(rawActions.loadTenantContents())
    return
  }

  yield put(rawActions.getTenantContentsDashboardColumns.request(selectedDashboard.Id))
}

function* updateDashboardSuccess(action) {
  const { id, isReloadColumnsRequired } = action.payload
  const selectedDashboardId = yield select(tenantSessionsSelectors.getSelectedDashboardId)

  if (selectedDashboardId === id && isReloadColumnsRequired) {
    yield put(rawActions.getTenantContentsDashboardColumns.request(selectedDashboardId))
  }
}

function* deleteDashboardSuccess(action) {
  const { list } = yield select((state) => state.tenantSessionsPage.dashboards)

  const deletedDashboardId = action.payload
  const updatedDashboardsList = list.filter((dashboard) => dashboard.Id !== deletedDashboardId)
  const defaultDashboardId = updatedDashboardsList.find((v) => v.IsDefault)?.Id || ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID

  yield put(rawActions.getTenantContentsDashboardColumns.request(defaultDashboardId))
}

function* dashboards() {
  yield all([
    takeLatest(rawActions.getTenantContentsDashboards.success, getTenantContentsDashboardsSuccess),
    takeLatest(rawActions.setDefaultDashboard, setDefaultDashboard),
    takeLatest(rawActions.selectTenantContentsDashboard, selectTenantContentsDashboard),
    takeLatest(rawActions.updateDashboard.success, updateDashboardSuccess),
    takeLatest(rawActions.deleteDashboard.success, deleteDashboardSuccess),
  ])
}

export default dashboards
