import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { showUserList } from '@tabeeb/modules/mobileWhiteboard/actions'
import { Person } from '@material-ui/icons'

import { AmountContainer, AmountTypography, DarkFilter, OverflowedUserContainer } from './styles'
import UserAvatar from '../UserAvatar'

const OverflowedUser = ({ amount, overflowedUser }) => {
  const dispatch = useDispatch()

  const openContactList = () => {
    dispatch(showUserList())
  }

  return (
    <OverflowedUserContainer onClick={openContactList}>
      <UserAvatar user={overflowedUser} />
      <DarkFilter />
      <AmountContainer>
        <Person />
        <AmountTypography>{amount}+</AmountTypography>
      </AmountContainer>
    </OverflowedUserContainer>
  )
}

OverflowedUser.propTypes = {
  amount: PropTypes.number.isRequired,
  overflowedUser: PropTypes.shape({
    avatarUrl: PropTypes.string,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
}

export default OverflowedUser
