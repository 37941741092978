import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tooltip, Paper } from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { getFormattedUtcDate } from '@tabeeb/services/dateTimeService'
import { SearchStatus } from '@tabeeb/enums'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { setSelectedTargetContentForMerging } from '../../../../actions'
import useStyles from './styles'

const TargetContentSearchResult = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { list, status } = useSelector((state) => state.sessionsPage.targetContentSearchResult)

  if (list.length === 0) {
    if (status === SearchStatus.NotStarted) {
      return null
    }

    if (status === SearchStatus.Completed) {
      return <div className={classes.emptyResult}>Session not found</div>
    }
  }

  return (
    <div className={classes.container}>
      {status === SearchStatus.InProgress && <CircularProgressBar />}
      {list.map((content) => (
        <div className={classes.contentItem} key={content.Id}>
          <Paper className={classes.previewContainer}>
            <img
              className={classes.preview}
              src={content.ThumbnailUrl || '/Content/img/image_placeholder.png'}
              alt='Session preview'
            />
            {content.IsActive === false && (
              <Tooltip title='Expired'>
                <LockOutlined className={classes.expired} />
              </Tooltip>
            )}
          </Paper>
          <div className={classes.info}>
            <Tooltip title={content.Description}>
              <span className={classes.description}>{content.Description}</span>
            </Tooltip>
            <span className={classes.smallText}>{`Owner: ${content.OwnerName}`}</span>
            <span className={classes.smallText}>
              {`Created on: ${getFormattedUtcDate(content.CreatedOn, 'MMM D, YYYY h:mm A')}`}
            </span>
          </div>
          <Button
            variant='contained'
            color='secondary'
            className={classes.button}
            onClick={() => dispatch(setSelectedTargetContentForMerging(content))}
          >
            <span className={classes.buttonText}>select</span>
          </Button>
        </div>
      ))}
    </div>
  )
}

export default memo(TargetContentSearchResult)
