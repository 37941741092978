import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import Routes from '@tabeeb/routes'
import { getIsTrainingMaterialsEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { RouteWrapper } from '@tabeeb/shared/routes/components'

import AnnouncementPages from './AnnouncementPages'
import ContentTrainingMaterialPages from './ContentTrainingMaterialPages'
import TrainingMaterialPages from './TrainingMaterialPages'

const Articles = () => {
  const isTrainingMaterialsFeatureEnabled = useSelector(getIsTrainingMaterialsEnabled)

  return (
    <Switch>
      <Route key={Routes.announcements} path={Routes.announcements} render={() => <AnnouncementPages />} />

      <Route
        key={Routes.contentTrainingMaterials}
        path={Routes.contentTrainingMaterials}
        render={() => (
          <RouteWrapper authorize routeEnabled={isTrainingMaterialsFeatureEnabled}>
            <ContentTrainingMaterialPages />
          </RouteWrapper>
        )}
      />

      <Route
        key={Routes.trainingMaterials}
        path={Routes.trainingMaterials}
        render={() => (
          <RouteWrapper authorize routeEnabled={isTrainingMaterialsFeatureEnabled}>
            <TrainingMaterialPages />
          </RouteWrapper>
        )}
      />

      <Redirect to={Routes.home} />
    </Switch>
  )
}

export default memo(Articles)
