import { createAction } from 'redux-actions'

export const showCallNotification = createAction('SHOW_CALL_NOTIFICATION')
export const hideCallNotification = createAction('HIDE_CALL_NOTIFICATION')

export const showSystemCallNotification = createAction('SHOW_SYSTEM_CALL_NOTIFICATION')

export const addCallNotification = createAction('ADD_CALL_NOTIFICATION')

export const acceptCallNotification = createAction('ACCEPT_CALL_NOTIFICATION')
export const declineCallNotification = createAction('DECLINE_CALL_NOTIFICATION')
export const resetAcceptCallNotification = createAction('RESET_ACCEPT_CALL_NOTIFICATION')
