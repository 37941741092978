import { combineActions, handleActions } from 'redux-actions'
import * as actions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [actions.addPageToClassificationAnnotationsPages](state, action) {
      return state.some((page) => page.id === action.payload.id) ? state : [...state, action.payload]
    },
    [actions.addPagesToClassificationAnnotationsPages](state, { payload: pages }) {
      const notAddedPages = pages.filter((item) => !state.some((page) => page.id === item.id))
      return [...state, ...notAddedPages]
    },
    [actions.updateClassificationAnnotationsPages](state, action) {
      return [...action.payload]
    },
    [actions.deletePageFromClassificationAnnotationsPages](state, action) {
      const index = action.payload
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [combineActions(actions.resetState, actions.closeClassificationAnnotationsTimeline)](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
