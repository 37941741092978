import { createAction } from 'redux-actions'

export const searchDevicesRequest = createAction('SEARCH_DEVICES_REQUEST')
export const searchDevicesSuccess = createAction('SEARCH_DEVICES_SUCCESS')
export const searchDevicesFailed = createAction('SEARCH_DEVICES_FAILED')

export const createDeviceRequest = createAction('CREATE_DEVICE_REQUEST')
export const createDeviceSuccess = createAction('CREATE_DEVICE_SUCCESS')
export const createDeviceFailed = createAction('CREATE_DEVICE_FAILED')

export const editDeviceRequest = createAction('EDIT_DEVICE_REQUEST')
export const editDeviceSuccess = createAction('EDIT_DEVICE_SUCCESS')
export const editDeviceFailed = createAction('EDIT_DEVICE_FAILED')

export const deleteDeviceRequest = createAction('DELETE_DEVICE_REQUEST')
export const deleteDeviceSuccess = createAction('DELETE_DEVICE_SUCCESS')
export const deleteDeviceFailed = createAction('DELETE_DEVICE_FAILED')

export const snapshotDeviceFilters = createAction('SNAPSHOT_DEVICE_FILTERS')
