import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'

import { getIsCurrentUserPresenter } from '@tabeeb/shared/content/selectors'
import { getSelectedUserId } from '@tabeeb/modules/recording/selectors'
import { isLocalRecordingActive } from '@tabeeb/modules/recording/services/localRecordingService'
import { openMuteCameraDialog } from '@tabeeb/modules/whiteboard/actions'
import { muteRemoteVideo, muteVideo } from '@tabeeb/modules/presentationToolbar/actions/muteVideo'

import { VIDEO_MUTED } from '../constants'
import { getCurrentUserId, getIsCallPreviewOpen, getIsUserVideoPlaying } from '../selectors'

function useMuteUserVideo({ user }) {
  const dispatch = useDispatch()

  const currentUserId = useSelector(getCurrentUserId)
  const isPresenter = useSelector(getIsCurrentUserPresenter)
  const isMuted = useSelector((state) => !getIsUserVideoPlaying(state, { Id: user.id }))
  const isMuteInProgress = useSelector((state) => state.presentationToolbar.muteVideoInProgress)
  const selectedUserId = useSelector((state) => getSelectedUserId(state))
  const isCallPreviewOpen = useSelector(getIsCallPreviewOpen)

  const handleClick = useCallback(() => {
    if (isMuteInProgress === true || (user.isOnline === false && !isCallPreviewOpen)) {
      return
    }

    if (user.id === selectedUserId && !isMuted && isLocalRecordingActive()) {
      dispatch(openMuteCameraDialog())
      return
    }

    if (user.id === currentUserId) {
      dispatch(muteVideo())
      if (isCallPreviewOpen) {
        localStorage.setItem(VIDEO_MUTED, JSON.stringify(!isMuted))
      }
    } else if (isPresenter) {
      dispatch(muteRemoteVideo(user.id))
    }
  }, [
    currentUserId,
    dispatch,
    isCallPreviewOpen,
    isMuteInProgress,
    isMuted,
    isPresenter,
    selectedUserId,
    user.id,
    user.isOnline,
  ])

  const returnValues = useMemo(
    () => ({
      isMuted,
      isPresenter,
      currentUserId,
      handleClick,
    }),
    [currentUserId, handleClick, isMuted, isPresenter]
  )

  return returnValues
}

export default useMuteUserVideo
