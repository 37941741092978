import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_ACTIVITIES_STATE')

export const setPageFilter = createAction('SET_PAGE_FILTER')
export const setUserFilter = createAction('SET_USER_FILTER')
export const setActivityTypeFilter = createAction('SET_ACTIVITY_TYPE_FILTER')
export const setActivities = createAction('SET_ACTIVITIES')
export const updateActivities = createAction('UPDATE_ACTIVITIES')

export const addActivity = createAction('ADD_ACTIVITY')
export const addActivities = createAction('ADD_ACTIVITIES')
export const updateActivitiesSuccess = createAction('UPDATE_ACTIVITIES_SUCCESS')
export const updateActivity = createAction('UPDATE_ACTIVITY')
export const delActivity = createAction('DEL_ACTIVITY')
export const lockActivity = createAction('LOCK_ACTIVITY', (activityId, lock) => {
  return { activityId, lock }
})
export const selectActivity = createAction('SELECT_ACTIVITY')
export const onEnterSearchTextFilter = createAction('ON_ENTER_SEARCH_TEXT_FILTER')

export const getActivitiesByContentIdRequest = createAction('GET_ACTIVITIES_BY_CONTENT_ID_REQUEST')
export const getActivitiesByContentIdSuccess = createAction('GET_ACTIVITIES_BY_CONTENT_ID_SUCCESS')
export const getActivitiesByContentIdFailed = createAction('GET_ACTIVITIES_BY_CONTENT_ID_FAILED')

export const setActivitiesContentHeight = createAction('GET_ACTIVITIES_CONTENT_HEIGHT')
export const loadMoreActivities = createAction('LOAD_MORE_ACTIVITIES')
export const resetActivitiesSkipCount = createAction('RESET_ACTIVITIES_SKIP_COUNT')
export const resetActivities = createAction('RESET_ACTIVITIES')
export const setShouldRequestSend = createAction('SET_SHOULD_REQUEST_SEND')
export const setSearchInProgress = createAction('SET_SEARCH_IN_PROGRESS')

export const cleanActivitiesList = createAction('CLEAN_ACTIVITIES_LIST')
