import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CirclePicker } from 'react-color'

import { useTheme } from '@material-ui/core'

import { TabeebColors } from '@tabeeb/enums'
import { drawingActions, playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import useStyles from './styles'

const Palette = () => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()

  const color = useSelector(playerToolbarSelectors.getSelectedColor)
  const onColorChange = useCallback(
    ({ hex }) => {
      dispatch(drawingActions.onSetDrawingColor(hex))
    },
    [dispatch]
  )

  return (
    <CirclePicker
      className={classes.picker}
      color={color}
      colors={Object.values(TabeebColors)}
      circleSize={24}
      circleSpacing={theme.spacing(1.25)}
      onChange={onColorChange}
    />
  )
}

export default memo(Palette)
