import { takeLatest, put, all, select } from 'redux-saga/effects'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as galleryActions from '../actions'
import * as structureModelActions from '../../structureModel/actions'

function* galleryAddStructureModel(action) {
  const isStructureModelOpen = yield select((state) => state.structureModel.isTimelineOpen)
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  if (!isStructureModelOpen) yield put(structureModelActions.openStructureModelDialog())

  if (currentPanel !== TabPanel.StructureModel)
    yield put(navbarActions.switchPanel(TabPanel.StructureModel, TabPanelLocation.Bottom))
}

function* addStructureModel() {
  yield all([takeLatest(galleryActions.galleryAddStructureModel, galleryAddStructureModel)])
}

export default addStructureModel
