import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'

import { callApiAsync } from '@tabeeb/shared/utils/requests'

import { getListOfDependentModels, createModelToClassDependency, deleteModelToClassDependency } from '../actions'

export default ({ classId }) => {
  const [loading, setLoading] = useState(true)
  const [models, setModels] = useState([])

  useEffect(() => {
    if (!classId) {
      return
    }

    setModels([])
    setLoading(true)

    callApiAsync(getListOfDependentModels.request({ classId }))
      .then(({ response }) => {
        setModels(response.data)
        setLoading(false)
      })
      .catch((e) => {
        // ignored
      })
  }, [classId])

  const onAddDependency = useCallback(
    async (model) => {
      try {
        await callApiAsync(createModelToClassDependency.request({ classId, modelId: model.Id }))
        setModels((prevModels) => _.uniqBy([...prevModels, model], (m) => m.Id))
      } catch {
        // ignored
      }
    },
    [classId]
  )

  const onDeleteDependency = useCallback(
    async (model) => {
      try {
        await callApiAsync(deleteModelToClassDependency.request({ classId, modelId: model.Id }))
        setModels((prevModels) => prevModels.filter((m) => m.Id !== model.Id))
      } catch {
        // ignored
      }
    },
    [classId]
  )

  return {
    loading,
    models,
    onAddDependency,
    onDeleteDependency,
  }
}
