import PropTypes from 'prop-types'

import { Popover, TextField } from '@material-ui/core'
import CircularProgressBar from '../../../presentation/components/CircularProgressBar'

import './styles.less'

const SwitchSession = ({
  handleRequestClose,
  handleTextChange,
  open,
  anchorEl,
  sessions,
  handleSessionClick,
  isContentLoading,
  contentId,
  sessionsNavbarTitle,
}) => {
  return (
    <Popover
      open={open}
      className='available-sessions-popover'
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      onClose={handleRequestClose}
    >
      <div className='available-sessions-container'>
        <TextField placeholder={`Search ${sessionsNavbarTitle}...`} fullWidth onChange={handleTextChange} />
        <ul className='available-sessions-list'>
          {sessions.map((content) => (
            <li
              className='available-session'
              key={content.Id}
              title={content.Description}
              onClick={content.Id === contentId ? null : () => handleSessionClick(content)}
              disabled={content.Id === contentId}
            >
              {content.Description}
            </li>
          ))}
        </ul>
        {isContentLoading && <CircularProgressBar />}
      </div>
    </Popover>
  )
}

SwitchSession.propTypes = {
  open: PropTypes.bool.isRequired,
  handleRequestClose: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleSessionClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  sessions: PropTypes.array.isRequired,
  isContentLoading: PropTypes.bool.isRequired,
  contentId: PropTypes.number.isRequired,
  sessionsNavbarTitle: PropTypes.string.isRequired,
}

export default SwitchSession
