import { combineReducers } from 'redux'

import isTimelineOpen from './isTimelineOpen'
import pagesList from './pagesList'
import shareByFormDialogState from './shareByFormDialogState'

export default combineReducers({
  isTimelineOpen,
  pagesList,
  shareByFormDialogState,
})
