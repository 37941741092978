import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { isTabVisibleSelectors, TabBubbleSelectors, TabIcons, isTabInProgressSelectors } from '../../constants'
import ButtonBubble from '../ButtonBubble'

const TabButton = ({ tabName, location, selectedTabName, onClick }) => {
  const bubbleValueSelector = TabBubbleSelectors[tabName] || (() => 0)
  const bubbleValue = useSelector(bubbleValueSelector)

  const icon = TabIcons[tabName]

  const isInProgressSelector = isTabInProgressSelectors[tabName] || (() => false)
  const isInProgress = useSelector(isInProgressSelector)

  const isPanelVisible = useSelector(isTabVisibleSelectors[tabName])

  return (
    isPanelVisible && (
      <li
        key={tabName}
        data-name={tabName}
        className={classNames({ active: tabName === selectedTabName })}
        onClick={() => onClick(tabName, location)}
      >
        {isInProgress ? <span className='tabeeb-icon-progress' /> : icon}
        <ButtonBubble number={bubbleValue} />
      </li>
    )
  )
}

TabButton.propTypes = {
  selectedTabName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
}

export default memo(TabButton)
