import { memo } from 'react'
import PropTypes from 'prop-types'

import { IconButton, withStyles } from '@material-ui/core'

import styles from './styles'

const PageInfoControl = ({ classes, icon, ...rest }) => {
  const Icon = icon

  return (
    <IconButton className={classes.button} size='small' {...rest}>
      <Icon />
    </IconButton>
  )
}

PageInfoControl.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
  }).isRequired,
  icon: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]).isRequired,
}

export default memo(withStyles(styles)(PageInfoControl))
