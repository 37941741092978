import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

import { PAGE_SIZE } from '../constants'

const defaultState = PAGE_SIZE

export default handleActions(
  {
    [rawActions.setIterationPagesPageSize](state, action) {
      return action.payload
    },
    [rawActions.setSelectedProject](state, action) {
      return defaultState
    },
  },
  defaultState
)
