/* eslint-disable react/no-danger */
import { memo } from 'react'
import { useSelector } from 'react-redux'
import DOMPurify from 'dompurify'
import { getCurrentFormRecordSignature } from '@tabeeb/modules/forms/selectors'
import { Container, Title, SignatureValue } from './styles'

const SignatureViewer = () => {
  const signature = useSelector(getCurrentFormRecordSignature)
  if (!signature) {
    return null
  }

  return (
    <Container>
      <Title>Signature</Title>
      <SignatureValue dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signature) }} />
    </Container>
  )
}

export default memo(SignatureViewer)
