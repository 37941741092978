import { memo } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

import ToolbarLeftPanel from '../ToolbarLeftPanel'
import UserList from '../UserList'

import styles from './styles'

const UsersToolbar = ({ classes }) => {
  return (
    <section className={classes.container}>
      <ToolbarLeftPanel />
      <UserList />
    </section>
  )
}

UsersToolbar.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(UsersToolbar))
