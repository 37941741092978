import _ from 'lodash'

import { MathUtils, Vector3 } from 'three'

import {
  getElevation,
  getAzimuthDirection,
  getAnchorPoint,
  getHorizontalOffset,
  getChildren,
  getParent,
  getAzimuth,
} from './autofill'

export const UtilityPoleObjectType = {
  Pole: 'Pole',
  Crossarm: 'Crossarm',
  Insulator: 'Insulator',
  Wire: 'Wire',
}

export const getAIObjectType = (aiObject) => {
  return aiObject?.Properties?.find((p) => p.Name === 'Object Type')?.DefaultValue
}

export const getObjectType = (object) => {
  return getAIObjectType(object?.aiObject)
}

const getAlphabeticOrdinalFromNumeric = (index) => {
  if (index < 0) {
    return 'A'
  }

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let current = index
  let ordinal = ''

  do {
    ordinal = alphabet[index % alphabet.length] + ordinal

    current = Math.floor(current / alphabet.length) - 1
  } while (current > 0)

  return ordinal
}

const getChildrenObjectsOfType = (object, objects, type) => {
  return getChildren(object, objects).filter((o) => getObjectType(o) === type)
}

export const isUtilityPoleObject = (object) => {
  const objectType = getObjectType(object)

  return Object.values(UtilityPoleObjectType).includes(objectType)
}

export const getUtilityPoleObjectCustomName = (object, objects, worldScale, worldRotation, namesMap) => {
  const objectType = getObjectType(object)

  const parent = getParent(object, objects)

  if (objectType === UtilityPoleObjectType.Pole) {
    return null
  }

  if (objectType === UtilityPoleObjectType.Crossarm) {
    const { direction: crossarmAzimuthDirection } = getAzimuthDirection(object)

    const crossarms = _.orderBy(
      getChildrenObjectsOfType(parent, objects, UtilityPoleObjectType.Crossarm).filter(
        (o) => getAzimuthDirection(o).direction === crossarmAzimuthDirection
      ),
      (o) => getElevation(o, objects, worldScale, worldRotation),
      'desc'
    )

    const SAME_LEVEL_THRESHOLD = 0.5
    const crossarmsElevationGroups = []

    for (const crossarm of crossarms) {
      const currentCrossarmElevation = getElevation(crossarm, objects, worldScale, worldRotation)

      const currentGroupFirstCrossarm = crossarmsElevationGroups[crossarmsElevationGroups.length - 1]?.[0]
      if (currentGroupFirstCrossarm) {
        const currentGroupFirstCrossarmElevation = getElevation(
          currentGroupFirstCrossarm,
          objects,
          worldScale,
          worldRotation
        )

        if (Math.abs(currentCrossarmElevation - currentGroupFirstCrossarmElevation) < SAME_LEVEL_THRESHOLD) {
          crossarmsElevationGroups[crossarmsElevationGroups.length - 1].push(crossarm)

          continue
        }
      }

      crossarmsElevationGroups.push([crossarm])
    }

    const crossarmGroupIndex = _.findIndex(crossarmsElevationGroups, (level) =>
      level.some((o) => o.uniqueAIObject.Id === object.uniqueAIObject.Id)
    )
    if (crossarmGroupIndex === -1) {
      return null
    }

    const crossarmGroup = _.orderBy(
      crossarmsElevationGroups[crossarmGroupIndex].filter(
        (crossarm) => getAzimuthDirection(crossarm).direction === crossarmAzimuthDirection
      ),
      (o) => o.id
    )

    let crossarmElevationOrdinal = `${crossarmGroupIndex + 1}`
    if (crossarmGroup.length > 1) {
      const anchor = getAnchorPoint(crossarmGroup[0].shape, worldRotation)

      const azimuthDegrees = getAzimuth(crossarmGroup[0].shape)
      const azimuthRadians = MathUtils.degToRad(azimuthDegrees % 180)

      const perpendicularVector = new Vector3(Math.cos(azimuthRadians), 0, Math.sin(azimuthRadians)).normalize()

      const crossarmOrdinalWithinGroup = _.findIndex(
        _.orderBy(crossarmGroup, (o) => getAnchorPoint(o.shape, worldRotation).sub(anchor).dot(perpendicularVector)),
        (o) => o.uniqueAIObject.Id === object.uniqueAIObject.Id
      )

      crossarmElevationOrdinal += crossarmOrdinalWithinGroup === 0 ? 'L' : 'R'
    }

    return `${crossarmAzimuthDirection} - ${crossarmElevationOrdinal}`
  }

  if (objectType === UtilityPoleObjectType.Insulator) {
    if (!parent) {
      return null
    }

    const parentType = getObjectType(parent)

    let insulators = getChildrenObjectsOfType(parent, objects, UtilityPoleObjectType.Insulator)

    if (parentType === UtilityPoleObjectType.Crossarm) {
      const { reversed } = getAzimuthDirection(parent)

      insulators = _.orderBy(
        getChildrenObjectsOfType(parent, objects, UtilityPoleObjectType.Insulator),
        (o) => getHorizontalOffset(o, objects, worldScale, worldRotation),
        reversed ? 'desc' : 'asc'
      )
    }

    if (parentType === UtilityPoleObjectType.Pole) {
      insulators = _.orderBy(getChildrenObjectsOfType(parent, objects, UtilityPoleObjectType.Insulator), (o) =>
        getElevation(o, objects, worldScale, worldRotation)
      )
    }

    const insulatorIndex = _.findIndex(insulators, (o) => o.uniqueAIObject.Id === object.uniqueAIObject.Id)
    const insulatorOrdinal = getAlphabeticOrdinalFromNumeric(insulatorIndex)

    const parentName = namesMap.get(parent?.uniqueAIObject?.Id)

    return `${parentName || 'Not attached'} - ${insulatorOrdinal}`
  }

  return null
}
