import { takeLatest, put, select, all } from 'redux-saga/effects'

import { notificationActions } from '@tabeeb/modules/notification'
import { galleryActions, gallerySelectors } from '@tabeeb/modules/gallery'

import * as rawActions from '../actions'

function* addGalleryItem(action) {
  const page = action.payload
  const selectedGalleryItemId = yield select((state) => state.gallery.galleryState.selectedGalleryItemId)
  if (page.id === selectedGalleryItemId) {
    yield put(rawActions.setContentSize({ width: page.width, height: page.height }))
  }
}

function* setContentSize(action) {
  const pageId = action.payload
  const selectedPage = yield select((state) => gallerySelectors.getGalleryItemById(state, { id: pageId }))

  if (selectedPage) {
    yield put(rawActions.setContentSize({ width: selectedPage.width, height: selectedPage.height }))
  }
}

function* resetCoordinatesInfo(action) {
  yield put(rawActions.setCoordinatesInfo(null))
}

function* sendUnarchiveModelSuccess(action) {
  yield put(rawActions.showWebPageIframe())
  yield put(notificationActions.onAddSuccessNotification({ message: 'Unarchived model uploaded successfully' }))
}

function* selectPage({ payload }) {
  const pageId = payload

  const selectedGalleryItemId = yield select((state) => state.gallery.galleryState.selectedGalleryItemId)
  const galleryList = yield select((state) => state.gallery.galleryList)
  const isPageExits = Boolean(galleryList.find((item) => item.id === pageId))

  if (pageId && isPageExits && pageId !== selectedGalleryItemId) {
    yield put(galleryActions.onSelectGalleryItem({ pageId, updateWeb: true }))
  } else if (pageId && !isPageExits) {
    yield put(notificationActions.onAddErrorNotification({ message: 'Page not found' }))
  }
}

function* pageSelectionSaga() {
  yield all([
    takeLatest(galleryActions.selectGalleryItem, setContentSize),
    takeLatest(galleryActions.selectGalleryItem, resetCoordinatesInfo),
    takeLatest(galleryActions.addGalleryItem, addGalleryItem),
    takeLatest(rawActions.sendUnarchiveModelSuccess, sendUnarchiveModelSuccess),
    takeLatest(rawActions.goToPage, selectPage),
  ])
}

export default pageSelectionSaga
