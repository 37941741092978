import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import classNames from 'classnames'
import { TextField } from '@material-ui/core'
import * as rawActions from '../../actions'

import { getCanAddGalleryContent, getSelectedFolderId } from '../../selectors'
import { contentStateSelectors } from '../../../shared/content'

import './styles.less'

const CreateFolderButton = ({
  backgroundColor,
  isVisible,
  isExpanded,
  actions: { createSessionFolderRequest },
  contentId,
  selectedFolderId,
}) => {
  const inputRef = useRef()
  const [folderName, setFolderName] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const _handleCreateFolder = () => {
    if (isEditing) return
    setIsEditing(true)
    if (inputRef) inputRef.current.focus()
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter' && isEditing && folderName) _handleSubmitCreation()
    if (e.key === 'Escape') _resetState()
  }

  const _handleSubmitCreation = () => {
    if (!folderName || !isEditing) {
      _resetState()
      return
    }

    const data = {
      Name: folderName,
      ContentId: contentId,
      ParentId: selectedFolderId,
    }

    createSessionFolderRequest(data)
    _resetState()
  }

  const _resetState = () => {
    setFolderName('')
    setIsEditing(false)
    if (inputRef) inputRef.current.value = null
  }

  return isVisible ? (
    <div className={classNames('create-session-folder-button', { expanded: isExpanded })} onClick={_handleCreateFolder}>
      <div className='layout' style={{ backgroundColor }} />
      <i className={classNames('material-icons', 'create-session-folder-icon')}>create_new_folder</i>
      <TextField
        id='tabeeb-create-folder-button'
        inputRef={inputRef}
        onBlur={_handleSubmitCreation}
        onChange={(e) => setFolderName(e.target.value)}
        onKeyDown={_handleKeyDown}
        style={{ opacity: isEditing ? 1 : 0, width: isEditing ? 120 : 0 }}
      />
      {!isEditing && <span className='create-session-folder-button-label'>Create folder</span>}
    </div>
  ) : null
}

CreateFolderButton.propTypes = {
  actions: PropTypes.shape({
    createSessionFolderRequest: PropTypes.func.isRequired,
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  contentId: PropTypes.number.isRequired,
  selectedFolderId: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

function mapStateToProps(state) {
  const {
    appConfigState: { createFolderButtonColor, isGalleryExpansionEnabled },
    gallery: { isGalleryExpanded },
  } = state

  return {
    contentId: contentStateSelectors.getContentId(state),
    selectedFolderId: getSelectedFolderId(state),
    backgroundColor: createFolderButtonColor,
    isExpanded: isGalleryExpansionEnabled && isGalleryExpanded,
    isVisible: getCanAddGalleryContent(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFolderButton)
