import { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import moment from 'moment'
import { Formik, Form, Field } from 'formik'

import { Grid, Paper } from '@mui/material'

import { UploadContentType } from '@tabeeb/enums'
import { PageHeader, PageLayout } from '@tabeeb/uikit'
import { uploadFile } from '@tabeeb/services/uploadService'
import { FormikDateRangePicker, FormikRichTextEditor, FormikSelect, FormikTextField } from '@tabeeb/shared/forms'
import { maxLengthWithName, noWhitespaces, requiredFieldWithName } from '@tabeeb/shared/utils/validationErrorMessages'

import {
  AnnouncementScope,
  ArticlePublicationStatus,
  AnnouncementSeverity,
  AnnouncementSeverityDisplayName,
  ArticleContentType,
} from '@tabeeb/modules/articles/enums'
import ArticleSaveButton from '../../../components/ArticleSaveButton'

const announcementSchema = Yup.object().shape({
  Name: Yup.string()
    .strict()
    .trim(noWhitespaces)
    .max(256, maxLengthWithName('Name', 256))
    .required(requiredFieldWithName('Name')),
  Content: Yup.object()
    .shape({
      Type: Yup.number().oneOf(Object.values(ArticleContentType)),
      Content: Yup.string().required(),
    })
    .required(),
  Severity: Yup.number().oneOf(Object.values(AnnouncementSeverity)),
  Status: Yup.number().oneOf(Object.values(ArticlePublicationStatus)),
  Scope: Yup.number().oneOf(Object.values(AnnouncementScope)),
  Date: Yup.object().shape({
    start: Yup.date().required('Start date is required'),
    end: Yup.date()
      .required('End date is required')
      .min(Yup.ref('start'), "End date can't be before start date")
      .min(new Date(), "End date can't be before the current date"),
  }),
})

const AnnouncementEditorPage = ({ initial, title, scope, onSubmit }) => {
  const onUploadFile = useCallback(async ({ file, onProgress }) => {
    const { url, sas } = await uploadFile({
      file,
      uploadContentType: UploadContentType.ArticleDocument,
      onProgress,
    })

    return `${url.split('?')[0]}${sas.sasRead}`
  }, [])

  const initialValues = useMemo(() => {
    return {
      Id: initial.Id,
      Name: initial.Name,
      Content: initial.Content,
      Severity: initial.Severity,
      Status: initial.Status,
      Scope: scope || initial.Scope,
      TenantId: initial.TenantId,
      Date: {
        start: initial.StartDate,
        end: initial.ExpiryDate,
      },
    }
  }, [initial, scope])

  const onSubmitForm = useCallback(
    (data) => {
      onSubmit({
        Id: data.Id,
        Name: data.Name,
        Content: data.Content,
        Severity: data.Severity,
        Status: data.Status,
        StartDate: data.Date.start,
        ExpiryDate: data.Date.end,
      })
    },
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={announcementSchema}
      onSubmit={onSubmitForm}
    >
      {({ isSubmitting }) => {
        return (
          <Form style={{ display: 'flex', height: '100%', width: '100%' }} autoComplete='off'>
            <PageLayout variant='outlined' spacing='page' component={Paper} maxWidth='lg'>
              <PageHeader title={title} actions={<Field name='Status' component={ArticleSaveButton} />} />
              <Grid container wrap='nowrap' direction='column' spacing={2} flexGrow={1}>
                <Grid item container direction='row' spacing={2}>
                  <Grid item xs={12} md={9}>
                    <Field
                      disabled={isSubmitting}
                      size='small'
                      color='primary'
                      name='Name'
                      label='Name'
                      component={FormikTextField}
                    />
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <Field
                      id='announcement-severity-select'
                      disabled={isSubmitting}
                      size='small'
                      name='Severity'
                      label='Severity'
                      component={FormikSelect}
                      options={Object.values(AnnouncementSeverity).map((severity) => ({
                        name: AnnouncementSeverityDisplayName[severity],
                        value: severity,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <Field
                      name='Date'
                      disabled={isSubmitting}
                      required
                      size='small'
                      variant='outlined'
                      component={FormikDateRangePicker}
                      minutesStep={60}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='Content.Content'
                    component={FormikRichTextEditor}
                    disabled={isSubmitting}
                    uploadsHandler={onUploadFile}
                  />
                </Grid>
              </Grid>
            </PageLayout>
          </Form>
        )
      }}
    </Formik>
  )
}

AnnouncementEditorPage.defaultProps = {
  initial: {
    Id: 0,
    Name: '',
    Content: {
      Type: ArticleContentType.Html,
      Content: '',
    },
    Scope: AnnouncementScope.System,
    Severity: AnnouncementSeverity.Information,
    Status: ArticlePublicationStatus.Draft,
    TenantId: null,
    StartDate: moment().format(),
    ExpiryDate: moment().add(7, 'days').format(),
  },
}

AnnouncementEditorPage.propTypes = {
  initial: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Content: PropTypes.shape({
      Type: PropTypes.oneOf(Object.values(ArticleContentType)).isRequired,
      Content: PropTypes.string.isRequired,
    }).isRequired,
    Scope: PropTypes.oneOf(Object.values(AnnouncementScope)).isRequired,
    Severity: PropTypes.oneOf(Object.values(AnnouncementSeverity)).isRequired,
    Status: PropTypes.oneOf(Object.values(ArticlePublicationStatus)).isRequired,
    TenantId: PropTypes.number,
    StartDate: PropTypes.string.isRequired,
    ExpiryDate: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  scope: PropTypes.oneOf(Object.values(AnnouncementScope)),
  onSubmit: PropTypes.func.isRequired,
}

export default memo(AnnouncementEditorPage)
