import { createSelector } from 'reselect'

import { TenantPermission, UserTenantRole } from '@tabeeb/enums'

import * as appConfigStateSelectors from '@tabeeb/modules/appConfigState/selectors'
import * as accountSelectors from '@tabeeb/modules/account/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

const hasShowVQAButtonPermission = (state) => hasTenantPermission(state, TenantPermission.ShowAddVQAButton)

export const getAttachAIExpressionDialogState = (state) => state.formInfoPage.attachAIExpressionDialog
export const getAvailableTags = (state) => state.formInfoPage.availableTags
export const getCurrentOpenedForm = (state) => state.formInfoPage.openedForm

export const getIsTagsAreaVisibleForUser = (state) =>
  accountSelectors.getIsUserInAnyRole(state, [UserTenantRole.BillingAdmin, UserTenantRole.AIAdmin])

export const getIsTagsAreaVisible = createSelector(
  [getIsTagsAreaVisibleForUser, getCurrentOpenedForm],
  (hasPermission, form) => {
    return hasPermission && !form.IsAnotherTenant
  }
)

export const getIsVqaModelButtonVisible = createSelector(
  [appConfigStateSelectors.getShowMenuAI, getCurrentOpenedForm, hasShowVQAButtonPermission],
  (isAiEnabled, form, hasShowVqaButtonPermission) => {
    return hasShowVqaButtonPermission && isAiEnabled && !form.IsAnotherTenant
  }
)

export const getIsVqaModelProcessingToggleVisible = createSelector(
  [
    appConfigStateSelectors.getShowMenuAI,
    appConfigStateSelectors.getShowProcessingByVQAModelButton,
    getCurrentOpenedForm,
  ],
  (isAiEnabled, showProcessingByVQAModelButton, form) => {
    return isAiEnabled && showProcessingByVQAModelButton && !form.IsAnotherTenant
  }
)
export const getIsAttachAIExpressionButtonVisible = createSelector(
  [appConfigStateSelectors.getShowMenuAI, appConfigStateSelectors.getIsAIExpressionsEnabled, getIsTagsAreaVisible],
  (isAiEnabled, isAIExpressionsEnabled, hasPermission) => {
    return isAiEnabled && isAIExpressionsEnabled && hasPermission
  }
)

export const getSelectedFormControlOption = (state) => state.formInfoPage.selectedFormControlOption
export const getVQAPolicies = (state) => state.formInfoPage.vqaPolicies
export const getIsFormControlOptionUpdating = (state) => state.formInfoPage.isFormControlOptionUpdating
export const getFormInfoControlsList = (state) => state.formInfoPage.openedForm.controlsList

export const getTagsOfSelectedFormControl = createSelector(
  [getCurrentOpenedForm, getSelectedFormControlOption],
  (openedForm, selectedFormControlOption) => {
    if (!openedForm || !selectedFormControlOption) {
      return []
    }

    const control = openedForm.Controls.find((control) =>
      control.Options.some((option) => option.Id === selectedFormControlOption.Id)
    )

    if (control) {
      return control.Tags.map((tag) => ({ TagName: tag.Name, TagId: tag.TagId }))
    }

    return []
  }
)

export const getIsAdvancedSettingsVisible = createSelector(
  [accountSelectors.getCurrentUserId, getCurrentOpenedForm, appConfigStateSelectors.getIsAdvancedFormSettingsEnabled],
  (currentUserId, openedForm, isFeatureEnabeld) => {
    return isFeatureEnabeld && currentUserId === openedForm.UserId
  }
)
