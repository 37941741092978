import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.showAddWebPageDialog](state, action) {
      return true
    },
    [rawActions.hideAddWebPageDialog](state, action) {
      return false
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
