import { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, withStyles } from '@material-ui/core'
import { getSelectedTabs } from '@tabeeb/modules/forms/selectors'
import { setCurrentTabs } from '@tabeeb/modules/forms/actions'
import styles from './styles'

const FormTab = ({ tab, index, width, classes }) => {
  const dispatch = useDispatch()
  const selectedTabs = useSelector(getSelectedTabs)
  const isSelected = selectedTabs.includes(tab.Id)

  const _handleClick = () => {
    if (!isSelected) {
      selectedTabs[index] = tab.Id
      dispatch(setCurrentTabs(selectedTabs))
    }
  }

  return (
    <Tooltip placement='top' arrow title={tab.Value || ''}>
      <div
        className={isSelected ? classes.tabSelected : classes.tab}
        style={{ minWidth: width }}
        onClick={_handleClick}
      >
        <span className={classes.text}>{tab.Value}</span>
      </div>
    </Tooltip>
  )
}

FormTab.propTypes = {
  tab: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    tabSelected: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(FormTab))
