import { put, takeLatest, select, all } from 'redux-saga/effects'

import { AnswerStatuses, ContentSharingModelType } from '@tabeeb/enums'
import {
  deleteContentsLink,
  deleteContentsLinkFromList,
  getLinkedContents,
  linkContents,
  shareContent,
  updateContentsLink,
  searchTargetContentForSharing,
  setSearchContentForSharingResult,
  selectTenantDestination,
  setStepCompleted,
  setUserForSharing,
  setContentsForSharing,
  resetUsersSearchState,
  resetContentsSearchState,
  addAllFormsForMerging,
  addStatusToAllFormsForMerging,
  setFormsForMerging,
  addAllPagesForMerging,
} from '@tabeeb/modules/contentSharings/actions'
import {
  getContentSharingDialogState,
  getContentSharingForms,
  getHasShareContentPermission,
  getLinkedContentsList,
} from '@tabeeb/modules/contentSharings/selectors'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import {
  ContentSharingDialogType,
  LinkedContentStatus,
  SharedContentDataType,
  ContentSharingDialogStepId,
} from '@tabeeb/modules/contentSharings/constants/enums'
import { getFormsWithStatusesList } from '@tabeeb/modules/sessionsPage/services/getFormsWithStatusesList'
import { createContentSharingRequest } from '@tabeeb/modules/sessionsPage/actions'
import { getSessionPermissionsSuccess } from '@tabeeb/modules/permissions/actions'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import {
  onAddInfoNotification,
  onAddSuccessNotification,
  onAddWarningNotification,
} from '@tabeeb/modules/notification/actions'
import { addFolderForMerging } from '@tabeeb/modules/gallery/actions'
import { getPagesByFolderWithNesting } from '@tabeeb/modules/gallery/selectors'
import { setDefaultLeftPanel } from '@tabeeb/modules/sessionTabs/sagas'

function* onSessionPermissionsSuccess() {
  const hasShareContentPermission = yield select(getHasShareContentPermission)
  if (!hasShareContentPermission) return

  const contentId = yield select(getContentId)
  yield put(getLinkedContents.request(contentId))
}

function* onShareContent() {
  const { contentForms, pages, targetContents, type } = yield select(getContentSharingDialogState)
  const contentId = yield select(getContentId)

  if (type === ContentSharingDialogType.RequestToLinkContent) {
    const data = { contentId, linkedContentId: targetContents[0]?.Id }
    yield put(linkContents.request(data))
    return
  }

  const baseModel = {
    contentId,
    pagesIds: pages.map((p) => p.id),
    contentForms: getFormsWithStatusesList(contentForms),
  }

  if (type === ContentSharingDialogType.SyncContentData) {
    const actions = []

    targetContents.forEach((targetContent) => {
      const data = {
        ...baseModel,
        type: ContentSharingModelType.LinkedContentsMerging,
        targetContentId: targetContent.Id,
        dataType: SharedContentDataType.SelectedData,
      }

      actions.push(put(createContentSharingRequest(data)))
    })

    yield all(actions)
  }
}

function* onContentsLinkUpdated(action) {
  const [updatedLink] = action.payload

  if (
    updatedLink.Status === LinkedContentStatus.Active ||
    updatedLink.Status === LinkedContentStatus.MergingInProgress
  ) {
    yield put(updateContentsLink(updatedLink))
  } else {
    yield put(deleteContentsLinkFromList(updatedLink.Id))
  }
}

function* onContentsLinkDeleted(action) {
  const [contentsLinkId] = action.payload
  yield put(deleteContentsLinkFromList(contentsLinkId))
}

function* linkContentsSuccess(action) {
  const contentsLink = action.response.data

  if (contentsLink.Status === LinkedContentStatus.Active) {
    yield put(onAddSuccessNotification({ message: 'Contents have been linked successfully.' }))
  } else {
    yield put(onAddInfoNotification({ message: 'Request to link contents is sent to destination owner.' }))
  }
}

function* deleteContentsLinkSuccess({ payload }) {
  const linkedContents = yield select(getLinkedContentsList)
  const isLastContent = linkedContents.length === 1 && linkedContents[0].Id === payload

  yield put(onAddInfoNotification({ message: 'Contents link deleted successfully.' }))
  yield put(deleteContentsLinkFromList(payload))

  if (isLastContent) {
    yield setDefaultLeftPanel()
  }
}

function* searchTargetContentForSharingSuccess({ response: { data } }) {
  const { type } = yield select(getContentSharingDialogState)
  let validContents = data

  if (type === ContentSharingDialogType.RequestToLinkContent) {
    const alreadyLinkedContents = yield select(getLinkedContentsList)
    const currentContentId = yield select(getContentId)
    validContents = data.filter(
      (c) => c.Id !== currentContentId && !alreadyLinkedContents.some((lc) => lc.ContentId === c.Id)
    )
  }

  yield put(setSearchContentForSharingResult(validContents))
}

function* onSelectTenantDestination() {
  const { stages } = yield select(getContentSharingDialogState)
  const stepId = ContentSharingDialogStepId.SelectTenantDestination
  const stageId = stages.find((stage) => stage.steps.some((step) => step.id === stepId))?.id

  yield put(setStepCompleted({ stageId, stepId, isCompleted: true }))
  yield put(resetUsersSearchState())
  yield put(setUserForSharing(null))
}

function* onSetUserForSharing({ payload }) {
  const user = payload
  const { stages } = yield select(getContentSharingDialogState)
  const stepId = ContentSharingDialogStepId.SelectUser
  const stageId = stages.find((stage) => stage.steps.some((step) => step.id === stepId))?.id
  const isCompleted = user !== null

  yield put(setStepCompleted({ stageId, stepId, isCompleted }))

  if (!isCompleted) {
    yield put(resetContentsSearchState())
    yield put(setContentsForSharing([]))
  }
}

function* onSetContentsForSharing({ payload }) {
  const contents = payload
  const { stages } = yield select(getContentSharingDialogState)
  const stepId = ContentSharingDialogStepId.SelectContentDestination
  const stageId = stages.find((stage) => stage.steps.some((step) => step.id === stepId))?.id
  const isCompleted = contents !== null && contents.length > 0

  yield put(setStepCompleted({ stageId, stepId, isCompleted }))
}

function* onAddAllFormsForMerging() {
  const contentForms = yield select((state) => state.forms.contentForms)
  const selectedForms = contentForms.map((form) => {
    const selectedForm = {
      ContentFormId: form.Id,
      SelectedAnswerStatuses: [AnswerStatuses.Approve, AnswerStatuses.Pending, AnswerStatuses.Reject],
    }
    return selectedForm
  })

  yield put(setFormsForMerging(selectedForms))
}

function* onAddStatusToAllFormsForMerging({ payload }) {
  const status = payload
  const contentForms = yield select((state) => state.forms.contentForms)

  const alreadySelectedForms = yield select(getContentSharingForms)
  const selectedFormIds = alreadySelectedForms.map((selectedForm) => selectedForm.ContentFormId)

  const selectedForms = contentForms.map((form) => {
    if (!selectedFormIds.includes(form.Id)) {
      const selectedForm = {
        ContentFormId: form.Id,
        SelectedAnswerStatuses: [status],
      }
      return selectedForm
    }

    const formToUpdate = alreadySelectedForms.find(
      (alreadySelectedForm) => alreadySelectedForm.ContentFormId === form.Id
    )

    if (formToUpdate.SelectedAnswerStatuses.includes(status)) {
      return formToUpdate
    }
    return {
      ContentFormId: form.Id,
      SelectedAnswerStatuses: [...formToUpdate.SelectedAnswerStatuses, status],
    }
  })

  yield put(setFormsForMerging(selectedForms))
}

function* onAddFolderForMerging({ payload: { folderId } }) {
  const pages = yield select((state) => getPagesByFolderWithNesting(state, { id: folderId }))

  if (pages.length < 1) {
    yield put(onAddWarningNotification({ message: 'Folder is empty!' }))
    return
  }

  yield put(addAllPagesForMerging(pages))
}

function* contentSharingsSaga() {
  yield all([
    takeLatest(shareContent, onShareContent),
    takeLatest(selectTenantDestination, onSelectTenantDestination),
    takeLatest(setUserForSharing, onSetUserForSharing),
    takeLatest(setContentsForSharing, onSetContentsForSharing),
    takeLatest(getSessionPermissionsSuccess, onSessionPermissionsSuccess),
    takeLatest(linkContents.success, linkContentsSuccess),
    takeLatest(deleteContentsLink.success, deleteContentsLinkSuccess),
    takeLatest(searchTargetContentForSharing.success, searchTargetContentForSharingSuccess),
    takeLatest(addAllFormsForMerging, onAddAllFormsForMerging),
    takeLatest(addFolderForMerging, onAddFolderForMerging),
    takeLatest(addStatusToAllFormsForMerging, onAddStatusToAllFormsForMerging),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onContentsLinkUpdated, onContentsLinkUpdated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onContentsLinkDeleted, onContentsLinkDeleted),
  ])
}

export default contentSharingsSaga
