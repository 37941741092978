import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'

import { setInitFormSettings, resetFormBuilderState, saveFormSettings } from '../../../actions'

const defaultState = {
  fetchingStatus: FetchStatus.Idle,
  data: {
    permissions: {
      byContentRoles: {
        sync: true,
        base: {},
        override: {},
        editableContentRoles: [],
      },
    },
  },
}

export default handleActions(
  {
    [setInitFormSettings](state, { payload }) {
      return payload
    },
    [saveFormSettings](state, { payload: { settings } }) {
      return {
        ...state,
        data: settings,
      }
    },
    [resetFormBuilderState]() {
      return defaultState
    },
  },
  defaultState
)
