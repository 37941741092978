import { handleActions } from 'redux-actions'
import {
  getUnreadContentTrainingMaterialsCount,
  getContentTrainingMaterials,
  markContentTrainingMaterialAsRead,
} from '../../actions'

const defaultState = 0

export default handleActions(
  {
    [getUnreadContentTrainingMaterialsCount.success](state, action) {
      return action.response.data
    },
    [getContentTrainingMaterials.success](state, action) {
      return action.response.data.Items.filter(
        (trainingMaterial) => trainingMaterial.Assigned && !trainingMaterial.Read
      ).length
    },
    [markContentTrainingMaterialAsRead.success](state, action) {
      return Math.max(0, state - 1)
    },
  },
  defaultState
)
