export default (theme) => {
  return {
    container: {
      border: 'none',
    },
    toolbar: {
      minHeight: 48,
    },
    side: {
      display: 'flex',
    },
    left: {
      justifyContent: 'flex-start',
      marginRight: theme.spacing() * 2,
    },
    right: {
      justifyContent: 'flex-end',
      marginLeft: theme.spacing() * 2,
    },
    grow: {
      maxWidth: 200,
      flexGrow: 1,
    },
    placeholder: {
      height: 48,
    },
  }
}
