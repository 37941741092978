import axios from 'axios'
import _ from 'lodash'

import domainService from '@tabeeb/services/domainService'

import { onTenantAccountError } from '@tabeeb/modules/account/actions'
import { TenantAuthErrorTypes } from '@tabeeb/modules/account/constants'

let applicationStore = null

export const injectStore = (store) => {
  applicationStore = store
}

function getHeaders(method, accessToken, customHeaders = {}) {
  let headers = {
    'Content-Type': 'application/json',
    'X-Tenant-Subdomain': domainService.subdomain,
    ...customHeaders,
  }

  if (_.isEqual('post', _.lowerCase(method))) {
    headers.Accept = 'application/json'
  }

  if (_.isString(accessToken)) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  if (_.isNull(customHeaders.Authorization)) {
    headers = _.omit(headers, ['Authorization'])
  }

  return headers
}

export default (paramsObj) => {
  const { hostName, accessToken, data, signal } = paramsObj
  return axios({
    ...data,
    headers: getHeaders(data.method, accessToken, data.headers),
    url: data.url && data.url.indexOf('http') === 0 ? data.url : `${hostName}${data.url}`,
    signal,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        const identityAuthErrors = Object.values(TenantAuthErrorTypes)
        if (
          axios.isAxiosError(error) &&
          error.response.status === 403 &&
          identityAuthErrors.includes(error.response.data)
        ) {
          applicationStore?.dispatch(onTenantAccountError(error.response.data))
          error.response.data = {
            Error: { Code: error.response.data, Message: error.response.data },
          }
        }

        const { statusText, status } = error.response || {}

        const errorObj = { statusText, status, response: error.response }

        if (process.env.DEBUG_LOGGING_ENABLED === 'true') {
          /* eslint-disable no-console */
          console.error('paramsObj:', paramsObj, '; errorObj:', errorObj)
        }
        throw errorObj
      }
    })
}
