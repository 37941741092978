import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(rawActions.getUnattachedAiModels.request)](state, action) {
      return true
    },
    [combineActions(rawActions.getUnattachedAiModels.success)](state, action) {
      return false
    },
    [rawActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
