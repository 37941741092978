import { useCallback, useMemo } from 'react'
import { Chip, Fade, List, MenuItem, Select, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import PropTypes from 'prop-types'

import SheetImportItem from '../SheetImportItem'

const SelectSheetsStep = ({ workbook, sheetsToImport, onSheetSelected, setSheetsImportMap, hasDuplicateNames }) => {
  const contentId = useSelector(getContentId)

  const sheetNames = useMemo(() => workbook?.SheetNames || [], [workbook])
  const handleSheetImportChange = useCallback(
    (sheetIndex, { newCustomName, hasError, isLoading }) => {
      setSheetsImportMap((prevMap) => ({
        ...prevMap,
        [sheetIndex]: {
          ...prevMap[sheetIndex],
          customName: newCustomName,
          hasError,
          isLoading,
        },
      }))
    },
    [setSheetsImportMap]
  )

  return (
    <Fade in>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: 4,
        }}
      >
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            gap: 2,
            padding: 4,
          }}
        >
          <Typography sx={{ width: '40%' }}>Select Sheets to import:</Typography>
          {Boolean(workbook) && (
            <Select
              multiple
              MenuProps={{ disableEnforceFocus: true }}
              inputProps={{ sx: { pt: 1 } }}
              sx={{ flex: '1 1 0' }}
              variant='filled'
              value={sheetsToImport}
              onChange={(e) => onSheetSelected(e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={sheetNames[value]} />
                  ))}
                </Box>
              )}
            >
              {sheetNames &&
                sheetNames.length > 0 &&
                sheetNames.map((sheetName, index) => (
                  <MenuItem value={index} key={sheetName}>
                    <Typography title={sheetName}>{sheetName}</Typography>
                  </MenuItem>
                ))}
            </Select>
          )}
        </Box>

        {hasDuplicateNames && (
          <Fade in>
            <Typography color='error'>Sheet names cannot be duplicated</Typography>
          </Fade>
        )}

        <Fade in={Boolean(sheetsToImport?.length)}>
          <Box
            sx={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              padding: 4,
            }}
          >
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {sheetsToImport.map((sheetIndex) => (
                <SheetImportItem
                  key={sheetIndex}
                  sheetIndex={sheetIndex}
                  originalSheetName={sheetNames[sheetIndex]}
                  contentId={contentId}
                  onChange={(changes) => handleSheetImportChange(sheetIndex, changes)}
                />
              ))}
            </List>
          </Box>
        </Fade>
      </Box>
    </Fade>
  )
}

SelectSheetsStep.propTypes = {
  workbook: PropTypes.shape({
    SheetNames: PropTypes.arrayOf(PropTypes.string),
  }),
  sheetsToImport: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSheetSelected: PropTypes.func.isRequired,
  setSheetsImportMap: PropTypes.func.isRequired,
  hasDuplicateNames: PropTypes.bool.isRequired,
}

export default SelectSheetsStep
