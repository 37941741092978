import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { PhotoSizeSelectLargeOutlined, RotateRight, CompareOutlined } from '@material-ui/icons'

import { RotateType } from '@tabeeb/enums'
import { gallerySelectors } from '@tabeeb/modules/gallery'
import { playerToolbarSelectors, rotatePageActions } from '@tabeeb/modules/playerToolbar'

import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'
import ToolbarButton from '../ToolbarButton'

const TransformImageButton = () => {
  const dispatch = useDispatch()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const pageId = useSelector(gallerySelectors.getSelectedGalleryItemId)
  const isRotationInProgress = useSelector(playerToolbarSelectors.getIsRotationInProgress)

  const onRotate = useCallback(
    (rotateType) => {
      dispatch(rotatePageActions.onRotatePageRequest({ pageId, rotateType }))
    },
    [dispatch, pageId]
  )

  const display = useSelector(playerToolbarSelectors.getCanRotateSelectedPage)
  if (!display) {
    return null
  }

  return (
    <>
      <ToolbarButton
        ref={anchorRef}
        Icon={PhotoSizeSelectLargeOutlined}
        onClick={onOpen}
        disabled={isRotationInProgress}
        title='Transform image'
      />
      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{
          style: {
            borderRadius: 20,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          disabled={isRotationInProgress}
          onClick={() => onRotate(RotateType.Rotate90Clockwise)}
          title='Rotate clockwise'
        >
          <ListItemIcon>
            <RotateRight />
          </ListItemIcon>
          <ListItemText>Rotate clockwise</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={isRotationInProgress}
          onClick={() => onRotate(RotateType.Rotate90Anticlockwise)}
          title='Rotate counterclockwise'
        >
          <ListItemIcon>
            <RotateRight style={{ transform: 'scaleX(-1)' }} />
          </ListItemIcon>
          <ListItemText>Rotate counterclockwise</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={isRotationInProgress}
          onClick={() => onRotate(RotateType.FlipHorizontal)}
          title='Flip horizontal'
        >
          <ListItemIcon>
            <CompareOutlined />
          </ListItemIcon>
          <ListItemText>Flip horizontal</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={isRotationInProgress}
          onClick={() => onRotate(RotateType.FlipVertical)}
          title='Flip vertical'
        >
          <ListItemIcon>
            <CompareOutlined style={{ transform: 'rotate(-90deg)' }} />
          </ListItemIcon>
          <ListItemText>Flip vertical</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default React.memo(TransformImageButton)
