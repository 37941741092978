import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Stop, FiberManualRecord } from '@material-ui/icons'
import { Button, Typography, withStyles } from '@material-ui/core'
import moment from 'moment'

import useAudioRecorder from '../../../shared/utils/hooks/useAudioRecorder'
import StreamVisualizer from './StreamVisualizer'
import styles from './styles'

const AudioAnnotationEditor = ({ classes, onChange }) => {
  const [audioFile, setAudioFile] = useState(null)

  const onRecordingCompliteHandler = useCallback(
    ({ isSuccess, file, error }) => {
      if (isSuccess) {
        setAudioFile(file)

        onChange({
          Data: file.data,
          Duration: file.duration,
        })
      } else {
        console.log(error)
      }
    },
    [onChange]
  )

  const [isRecording, recordingDuration, startRecording, stopRecording, recorder] = useAudioRecorder({
    onComplete: onRecordingCompliteHandler,
  })

  const onStartRecordingHandler = useCallback(() => {
    setAudioFile(null)
    startRecording()
  }, [startRecording])

  return (
    <div className={classes.container}>
      <div className={classes.controls}>
        {isRecording ? (
          <Button className={classes.button} variant='outlined' onClick={stopRecording} title='Pause recording'>
            <Stop />
            Stop
          </Button>
        ) : (
          <Button
            className={classes.button}
            variant='outlined'
            onClick={onStartRecordingHandler}
            title='Start recording'
          >
            <FiberManualRecord className={classes.start} />
            Start
          </Button>
        )}
        <Typography className={classes.time}>
          {moment(audioFile?.duration || recordingDuration).format('mm:ss.S')}
        </Typography>
      </div>
      <StreamVisualizer
        barColor='red'
        width={305}
        height={76}
        enabled={isRecording && Boolean(recorder && recorder.stream)}
        stream={recorder && recorder.stream}
      />
    </div>
  )
}

AudioAnnotationEditor.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    controls: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(AudioAnnotationEditor)
