import { handleActions, combineActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(actions.downloadIterationMetrics.request, actions.getMetricsByIterationId.request)]() {
      return true
    },
    [combineActions(
      actions.downloadIterationMetrics.failed,
      actions.downloadIterationMetrics.success,
      actions.downloadIterationMetrics.cancel,
      actions.getMetricsByIterationId.failed,
      actions.getMetricsByIterationId.success,
      actions.getMetricsByIterationId.cancel
    )]() {
      return false
    },
  },
  defaultState
)
