import ViewBoxCalculationService from './viewBoxCalculationService'

class AnnotationsSelectionService {
  viewBoxHelper = new ViewBoxCalculationService()

  isAreaIncludeAnnotation = (annotation, area) => {
    if (annotation.ParentAnnotationId) {
      return false
    }

    const annotationViewBox = this.viewBoxHelper.getAnnotationViewBox(annotation)
    if (!annotationViewBox) {
      return false
    }

    const { topLeftX, topLeftY, bottomRightX, bottomRightY } = annotationViewBox

    const { x, y, width, height } = area

    const areaTopLeftX = Math.min(x, x + width)
    const areaBottomRightX = Math.max(x, x + width)
    const areaTopLeftY = Math.min(y, y + height)
    const areaBottomRightY = Math.max(y, y + height)

    return (
      areaTopLeftX <= topLeftX &&
      areaBottomRightX >= bottomRightX &&
      areaTopLeftY <= topLeftY &&
      areaBottomRightY >= bottomRightY
    )
  }

  getIncludedAnnotationsIds = (annotations, area) => {
    return annotations
      .filter((annotation) => this.isAreaIncludeAnnotation(annotation, area))
      .map((annotation) => annotation.Id)
  }
}

export default AnnotationsSelectionService
