export function getStartDate() {
  const currentStartDateTimeStamp = new Date().setDate(new Date().getDate() - 2)
  const startDateWithTimezone = new Date(currentStartDateTimeStamp)
  const startDate = setStartTime(startDateWithTimezone)
  return startDate
}

export function getEndDate() {
  const endDateWithTimezone = new Date()
  const endDate = setEndTime(endDateWithTimezone)
  return endDate
}

export function setStartTime(startDate) {
  if (!startDate) {
    startDate = getStartDate()
  }
  startDate.setHours(0, 0, 0, 0)
  return getDateWithoutTimeZone(startDate)
}

export function setEndTime(endDate) {
  if (!endDate) {
    endDate = getEndDate()
  }
  endDate.setHours(23, 59, 59, 999)
  return getDateWithoutTimeZone(endDate)
}

export function getDateWithoutTimeZone(date) {
  const timestamp = date.getTime()
  const offset = date.getTimezoneOffset() * 60000
  return new Date(timestamp - offset)
}
