import { handleActions, combineActions } from 'redux-actions'
import { getDataBySharedContentForms } from '@tabeeb/modules/contentSharings/actions'
import { closeReviewSessionPagesDialog } from '@tabeeb/modules/reviewSessionPagesList/actions'
import { AnswerStatuses, ContentSharingType } from '@tabeeb/enums'
import * as actions from '../actions'

const defaultValue = {
  isOpen: false,
  selectedForms: null,
  sharingType: ContentSharingType.Shared,
  isLoading: false,
}

export default handleActions(
  {
    [actions.setIsShareContentByFormDialogOpen](state, { payload }) {
      const { isOpen, sharingType } = payload

      if (isOpen) return { ...state, isOpen: true, sharingType }
      return { ...state, isOpen: false }
    },
    [actions.setFormsForSharing](state, { payload }) {
      return { ...state, selectedForms: payload }
    },
    [actions.removeAllFormsForSharing](state) {
      return { ...state, selectedForms: [] }
    },
    [actions.removeStatusFromAllFormsForSharing](state, { payload }) {
      const selectedStatus = payload
      const updatedForms = state.selectedForms.reduce((result, form) => {
        const updatedStatuses = form?.SelectedAnswerStatuses?.filter((status) => status !== selectedStatus)
        const hasStatusesRemain = updatedStatuses && updatedStatuses.length > 0
        if (hasStatusesRemain) {
          result.push({
            ...form,
            SelectedAnswerStatuses: [...updatedStatuses],
          })
        }
        return result
      }, [])

      return { ...state, selectedForms: [...updatedForms] }
    },
    [actions.addFormForSharing](state, { payload }) {
      const addedForm = {
        ContentFormId: payload,
        SelectedAnswerStatuses: [AnswerStatuses.Approve, AnswerStatuses.Pending, AnswerStatuses.Reject],
      }
      return { ...state, selectedForms: [...state.selectedForms, addedForm] }
    },
    [actions.removeFormForSharing](state, { payload }) {
      const contentFormId = payload
      return { ...state, selectedForms: state.selectedForms.filter((f) => f.ContentFormId !== contentFormId) }
    },
    [actions.addAnswerStatusForSharing](state, { payload }) {
      const { formId, status } = payload
      const isFormAdded = state.selectedForms.some((form) => form.ContentFormId === formId)

      if (isFormAdded) {
        const updatedForms = state.selectedForms.map((form) =>
          form.ContentFormId === formId
            ? { ...form, SelectedAnswerStatuses: [...form.SelectedAnswerStatuses, status] }
            : form
        )

        return { ...state, selectedForms: updatedForms }
      }
      const addedForm = {
        ContentFormId: formId,
        SelectedAnswerStatuses: [status],
      }

      return { ...state, selectedForms: [...state.selectedForms, addedForm] }
    },
    [actions.removeAnswerStatusForSharing](state, { payload }) {
      const { formId, status } = payload
      const form = state.selectedForms.find((form) => form.ContentFormId === formId)
      const isLastStatus = form && form.SelectedAnswerStatuses.length === 1

      if (isLastStatus) {
        return { ...state, selectedForms: state.selectedForms.filter((f) => f.ContentFormId !== formId) }
      }
      const updatedForms = state.selectedForms.map((form) =>
        form.ContentFormId === formId
          ? {
              ...form,
              SelectedAnswerStatuses: form.SelectedAnswerStatuses.filter((answerStatus) => answerStatus !== status),
            }
          : form
      )

      return { ...state, selectedForms: updatedForms }
    },
    [getDataBySharedContentForms.request](state) {
      return { ...state, isLoading: true }
    },
    [combineActions(getDataBySharedContentForms.success, getDataBySharedContentForms.failed)](state) {
      return { ...state, isLoading: false }
    },
    [combineActions(actions.closeShareSessionPagesDialog, closeReviewSessionPagesDialog, actions.resetState)]() {
      return defaultValue
    },
  },
  defaultValue
)
