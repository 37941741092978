import { handleActions, combineActions } from 'redux-actions'

import * as playerToolbarActions from '../actions'
import { onRotatePageRequest, onRotatePageSuccess, onRotatePageFailed } from '../actions/rotatePage'

const defaultValue = {
  isEnableButton: true,
}

export default handleActions(
  {
    [onRotatePageRequest](state) {
      return { ...state, isEnableButton: false }
    },
    [combineActions(onRotatePageSuccess, onRotatePageFailed)](state) {
      return { ...state, isEnableButton: true }
    },
    [playerToolbarActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
