import { all } from 'redux-saga/effects'

import pageSelectionSaga from './pageSelectionSaga'
import snippingToolSaga from './snippingToolSaga'
import mainScreenSaga from './mainScreenSaga'

function* contentViewerSaga() {
  yield all([pageSelectionSaga(), snippingToolSaga(), mainScreenSaga()])
}

export default contentViewerSaga
