import { createAction } from 'redux-actions'

export const resetChatState = createAction('RESET_CHAT_STATE')

export const addChatMessage = createAction('ADD_CHAT_MESSAGE')

export const updateChatMessage = createAction('UPDATE_CHAT_MESSAGE')

export const sendMessage = createAction('SEND_MESSAGE')

export const markAsRead = createAction('MARK_CHAT_MESSAGE_AS_READ')
export const markAsReadRequest = createAction('MARK_AS_READ_REQUEST')
export const markAsReadSuccess = createAction('MARK_AS_READ_SUCCESS')
export const markAsReadFailed = createAction('MARK_AS_READ_FAILED')

export const setChatMessages = createAction('SET_CHAT_MESSAGES')

export const getChatMessagesByContentIdRequest = createAction('GET_CHAT_MESSAGES_BY_CONTENT_ID_REQUEST')
export const getChatMessagesByContentIdSuccess = createAction('GET_CHAT_MESSAGES_BY_CONTENT_ID_SUCCESS')

export const loadMoreMessages = createAction('LOAD_MORE_MESSAGES')

export const sendChatMessageRequest = createAction('SEND_CHAT_MESSAGE_REQUEST')
export const sendChatMessageSuccess = createAction('SEND_CHAT_MESSAGE_SUCCESS')

export const getTranslationLanguagesRequest = createAction('GET_TRANSLATION_LANGUAGES_REQUEST')
export const getTranslationLanguagesFailed = createAction('GET_TRANSLATION_LANGUAGES_FAILED')
export const getTranslationLanguagesSuccess = createAction('GET_TRANSLATION_LANGUAGES_SUCCESS')

export const getMessageTranslationRequest = createAction('GET_MESSAGE_TRANSLATION_REQUEST')
export const getMessageTranslationFailed = createAction('GET_MESSAGE_TRANSLATION_FAILED')
export const getMessageTranslationSuccess = createAction('GET_MESSAGE_TRANSLATION_SUCCESS')

export const getChatsRequest = createAction('GET_CHATS_REQUEST')
export const getChatsSuccess = createAction('GET_CHATS_SUCCESS')
export const getChatsFailed = createAction('GET_CHATS_FAILED')

export const uploadAudioFileToChatBlobStorageRequest = createAction('UPLOAD_AUDIO_FILE_TO_CHAT_BLOB_STORAGE_REQUEST')
export const uploadAudioFileToChatBlobStorageFailed = createAction('UPLOAD_AUDIO_FILE_TO_CHAT_BLOB_STORAGE_FAILED')
export const uploadAudioFileToChatBlobStorageSuccess = createAction('UPLOAD_AUDIO_FILE_TO_CHAT_BLOB_STORAGE_SUCCESS')

export const sendChatFileRequest = createAction('SEND_CHAT_FILE_REQUEST')
export const sendChatFileFailed = createAction('SEND_CHAT_FILE_FAILED')
export const sendChatFileSuccess = createAction('SEND_CHAT_FILE_SUCCESS')

export const setChatFullyLoaded = createAction('SET_CHAT_LOADED')
