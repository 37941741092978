import { put, takeLatest, all, take, select } from 'redux-saga/effects'

import { notificationActions } from '@tabeeb/modules/notification'
import { getUsersListForCurrentUser } from '@tabeeb/modules/../users/selectors'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'

import loadGallery from './loadGallery'
import * as actions from '../actions'
import { getConfigRequest } from '@tabeeb/modules/appConfigState/actions'
import { getSubdomain } from '@tabeeb/modules/appConfigState/selectors'
import { ErrorCode } from '@tabeeb/enums'

function* onGetContentUsersSessionFolderPermissionsSuccess({ response }) {
  const contentUsers = yield select(getUsersListForCurrentUser)

  const mappedModels = response.data.map((model) => ({
    userId: model.UserId,
    folderId: model.FolderId,
    permissions: model.Permissions,
  }))

  const notEmptyModels = mappedModels.filter((model) => Boolean(model.permissions.length))

  const result = notEmptyModels.map((model) => ({
    ...model,
    displayName: contentUsers.find((user) => user.id === model.userId)?.displayName,
  }))

  yield put(actions.setContentUsersPageFolderPermissions(result))
}

function* onSetPageFolderPermissionsSuccess() {
  yield put(notificationActions.onAddSuccessNotification({ message: 'Folder rights have been set successfully' }))
}

function* onPageFolderPermissionsUpdated() {
  yield put(actions.selectSessionFolder({ folderId: null }))
  yield put(notificationActions.onAddInfoNotification({ message: 'Folder rights have been changed' }))

  yield loadGallery({ preserveSelectedPage: true })
}

function* onFailure({ response }) {
  if (response.data?.Error.Code === ErrorCode.TenantFeatureDisabled) {
    yield put(notificationActions.onAddInfoNotification({ message: 'Tenant config was updated' }))
    yield put(actions.closeEditFolderDialog())

    const subdomain = yield select(getSubdomain)

    yield put(getConfigRequest({ subdomain }))
  }
}

function* setPageFolderPermissions() {
  yield all([
    takeLatest(actions.setPageFolderPermissions.success, onSetPageFolderPermissionsSuccess),
    takeLatest(
      actions.getContentUsersSessionFolderPermissions.success,
      onGetContentUsersSessionFolderPermissionsSuccess
    ),
    takeLatest(
      [
        signalrEvents[signalrConstants.tabeebHubName].onPageFolderPermissionsUpdated,
        signalrEvents[signalrConstants.tabeebHubName].onPageFolderPrivacyUpdated,
      ],
      onPageFolderPermissionsUpdated
    ),
    takeLatest(
      [
        actions.getContentUsersSessionFolderPermissions.failed,
        actions.updateSessionFolderPrivacy.failed,
        actions.setPageFolderPermissions.failed,
      ],
      onFailure
    ),
  ])
}

export default setPageFolderPermissions
