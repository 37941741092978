import { put, takeLatest, all, select } from 'redux-saga/effects'

import { ReminderType } from '@tabeeb/enums'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { notificationActions } from '@tabeeb/modules/notification'
import { acceptNotificationSuccess } from '@tabeeb/modules/notificationsPage/actions'
import { getCurrentForm, getSelectedTabs } from '@tabeeb/modules/forms/selectors'
import { getContentFormsSuccess } from '@tabeeb/modules/forms/actions'
import {
  createReminderRequest,
  getRemindersByContentRequest,
  resetRemindersNotifications,
  createReminderSuccess,
  createReminderFailed,
  updateReminderSuccess,
  updateReminderFailed,
  deleteReminderSuccess,
  deleteReminderFailed,
  createReminder,
} from '../actions'

function* handleCreateReminder(action) {
  let reminder = action.payload
  const contentId = yield select(getContentId)
  const currentForm = yield select(getCurrentForm)
  const selectedTabs = yield select(getSelectedTabs)

  switch (reminder.Type) {
    case ReminderType.AnswerFormQuestion:
      reminder = {
        ...reminder,
        ContentId: contentId,
        ContentFormId: currentForm.Id,
        FormTabsIds: selectedTabs,
      }
      break
    default:
      break
  }

  yield put(createReminderRequest(reminder))
}

function* getContentReminders() {
  const contentId = yield select(getContentId)

  yield put(getRemindersByContentRequest(contentId))
}

function* actionSuccess(action) {
  let actionType

  switch (action.type) {
    case updateReminderSuccess().type:
      actionType = 'update'
      break
    case deleteReminderSuccess().type:
      actionType = 'delete'
      break
    default:
      actionType = 'create'
      break
  }

  const message = `Reminder has been ${actionType}d.`

  yield put(notificationActions.onAddSuccessNotification({ message }))
}

function* actionFailed(action) {
  let actionType

  switch (action.type) {
    case updateReminderFailed().type:
      actionType = 'update'
      break
    case deleteReminderFailed().type:
      actionType = 'delete'
      break
    default:
      actionType = 'create'
      break
  }

  const message = `Failed to ${actionType} reminder.`

  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* acceptNotification(action) {
  const contentId = yield select(getContentId)

  if (!contentId) {
    yield put(resetRemindersNotifications())
  }
}

function* remindersSaga() {
  yield all([
    takeLatest(createReminder, handleCreateReminder),
    takeLatest(getContentFormsSuccess, getContentReminders),
    takeLatest([createReminderSuccess, updateReminderSuccess, deleteReminderSuccess], actionSuccess),
    takeLatest([createReminderFailed, updateReminderFailed, deleteReminderFailed], actionFailed),
    takeLatest(acceptNotificationSuccess, acceptNotification),
  ])
}

export default remindersSaga
