import { Box, Grid, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import IdentityServerUserInfo from '@tabeeb/shared/components/IdentityServerUserInfo'
import { CloseOutlined } from '@mui/icons-material'
import PowerBIDevelopersSelect from '../PowerBIDevelopersSelect'

const PowerBIProviderUsers = ({ form, onRemoveUser, onUserSelectChange }) => {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <PowerBIDevelopersSelect onChange={onUserSelectChange} fieldValue={form.values.userIds} />
      </Grid>
      {form.values.userIds && (
        <Grid item container xs={12} spacing={2} flexGrow={1} mt={1}>
          {form.values.userIds.map((userId) => (
            <Grid key={userId} item xs={4}>
              <Box display='flex' alignItems='center'>
                <IdentityServerUserInfo userIdentityId={userId} />
                <IconButton size='small' color='error' onClick={() => onRemoveUser(userId)}>
                  <CloseOutlined />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      {form.values.userIds.length === 0 && (
        <Grid item xs={12}>
          <Typography sx={{ textAlign: 'center' }}>Users list is empty.</Typography>
        </Grid>
      )}
    </Grid>
  )
}

PowerBIProviderUsers.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      userIds: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  onRemoveUser: PropTypes.func.isRequired,
  onUserSelectChange: PropTypes.func.isRequired,
}

export default PowerBIProviderUsers
