import { Paper, styled, Typography } from '@mui/material'
import { ColumnFlexContainer } from '@tabeeb/uikit'

export const Container = styled(ColumnFlexContainer)(({ theme }) => ({
  width: '99.6%',
  height: '100%',
  direction: 'ltr',
}))

export const LinkedContent = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(),
  padding: theme.spacing(),
}))

export const LinkedContentText = styled(Typography)(({ theme }) => ({
  color: '#616161',
  fontSize: 13,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

export const LinkedContentName = styled(LinkedContentText)(({ theme }) => ({
  fontWeight: 'bold',
}))
