export default (theme) => {
  return {
    container: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      overflowX: 'auto',
      listStyle: 'none',
      height: '100%',
      margin: 0,
      padding: theme.spacing(),
      paddingLeft: 0,
    },
  }
}
