import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = {
  withAnnotations: false,
}

export default handleActions(
  {
    [rawActions.setExportImagesWithAnnotations](state, action) {
      return {
        ...state,
        withAnnotations: action.payload,
      }
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
