import { styled, Typography } from '@material-ui/core'

export const OverflowedUserContainer = styled('div')(({ theme }) => ({
  position: 'relative',
}))

export const AmountTypography = styled(Typography)(({ theme }) => ({
  fontSize: '10pt',
  fontWeight: theme.typography.fontWeightBold,
}))

export const DarkFilter = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: 'rgba(0,0,0,0.7)',
}))

export const AmountContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: 24,
  width: 50,
  background: '#333435',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  color: '#D8D6D6',
  margin: theme.spacing(),
  borderRadius: theme.shape.borderRadius,
}))
