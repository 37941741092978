import { addPageFailed, addPageRequest, addPageSuccess } from '@tabeeb/modules/gallery/actions'
import { handleActions } from 'redux-actions'
import { ContentType } from '@tabeeb/enums'
import { convertServicePageToPluginPage } from '@tabeeb/services/dataConverter'
import { resetImportFileUploading } from '../actions'

const defaultState = {
  page: null,
  loading: false,
  loaded: false,
  succeeded: false,
}

const importFileUploading = handleActions(
  {
    [addPageRequest](state, action) {
      return { ...defaultState, loading: true }
    },
    [addPageFailed](state, action) {
      if (action.payload.contentType === ContentType.ExcelDocumentPage) {
        return {
          pageId: 0,
          loaded: true,
          succeeded: false,
        }
      }

      return defaultState
    },
    [addPageSuccess](state, action) {
      if (action.payload.contentType === ContentType.ExcelDocumentPage) {
        const pages = [action.response.data].flat()
        return {
          page: convertServicePageToPluginPage({ page: pages[0] }),
          loaded: true,
          succeeded: true,
        }
      }

      return defaultState
    },
    [resetImportFileUploading]() {
      return defaultState
    },
  },
  defaultState
)

export default importFileUploading
