import { createSelector } from 'reselect'

import { calculateAzimuth } from '../services'

export const getIsOpen = (state) => state.compass.isOpen

export const getAzimuth = createSelector(
  [
    (_, { centerCoordinatesUTM }) => centerCoordinatesUTM,
    (_, { endLongitude }) => endLongitude,
    (_, { endLatitude }) => endLatitude,
  ],
  (centerCoordinatesUTM, endLongitude, endLatitude) => {
    return calculateAzimuth(centerCoordinatesUTM, endLongitude, endLatitude)
  }
)
