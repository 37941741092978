import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TenantFormControl from '../components/TenantFormControl'

import { setTenantFormAnswer, setTenantFormMultipleAnswer, checkTenantFormConditions } from '../actions'

import DropDownList from '../../forms/components/DropDownList'
import TextBox from '../../forms/components/TextBox'
import CheckBox from '../../forms/components/CheckBox'

import { ControlType } from '../../../Enums'

class TenantFormControlContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      textInputValue: { value: '' },
    }
  }

  onTextAnswerChange(event) {
    const { value } = event.target
    const { Order, Id, FormId } = this.props.formControl
    this.setState({ textInputValue: { order: Order, value, formControlId: Id, formId: FormId } })
  }

  onTextAnswerSave() {
    const { tenantFormControlAnswer, formControl, setTenantFormAnswer, checkTenantFormConditions } = this.props
    const { Order, Id, FormId } = formControl
    const previousAnswer = tenantFormControlAnswer
    const newAnswer = this.state.textInputValue.value

    setTenantFormAnswer({ order: Order, value: newAnswer, formControlId: Id, formId: FormId })
    if (newAnswer && newAnswer.length > 0 && previousAnswer !== newAnswer) {
      checkTenantFormConditions({ order: Order, value: newAnswer, formControlId: Id, formId: FormId })
    }
  }

  onDropDownAnswerChange(event) {
    const { Options, Order, Id, FormId } = this.props.formControl
    const optionId = parseInt(event.target.value, 10)
    const option = Options.find((item) => item.Id === optionId)
    this.props.setTenantFormAnswer({
      order: Order,
      value: option.Value,
      formControlId: Id,
      optionId: option.Id,
      formId: FormId,
    })
    this.props.checkTenantFormConditions({
      order: Order,
      value: optionId,
      formControlId: Id,
      optionId: option.Id,
      formId: FormId,
    })
  }

  onCheckboxClick(event) {
    const { tenantFormControlAnswers } = this.props
    const { Order, Id, Options } = this.props.formControl
    const optionId = parseInt(event.target.value, 10)
    const option = Options.find((item) => item.Id === optionId)

    if (!Object.values(tenantFormControlAnswers).some((item) => item.optionId === option.Id && item.value !== null)) {
      this.props.setTenantFormMultipleAnswer({
        order: Order,
        optionOrder: option.Order,
        value: option.Value,
        formControlId: Id,
        optionId: option.Id,
      })
    } else {
      this.props.setTenantFormMultipleAnswer({
        order: Order,
        optionOrder: option.Order,
        value: null,
        formControlId: Id,
        optionId: option.Id,
      })
    }
  }

  renderControl() {
    const { formControl, tenantFormControlAnswer, tenantFormControlAnswers } = this.props
    switch (formControl.ControlType) {
      case ControlType.Textbox: {
        const props = {
          control: formControl,
          disabled: false,
          answer: { value: this.state.textInputValue.value },
          handleChange: this.onTextAnswerChange.bind(this),
          handleSubmit: this.onTextAnswerSave.bind(this),
        }
        return <TextBox {...props} />
      }
      case ControlType.CheckboxList: {
        const props = {
          control: formControl,
          disabled: false,
          answer: {
            selectedOptionsIds: Object.values(tenantFormControlAnswers)
              .filter((item) => item.value !== null)
              .map((item) => item.optionId),
          },
          handleChange: this.onCheckboxClick.bind(this),
        }
        return <CheckBox {...props} />
      }
      case ControlType.Dropdown: {
        const selectedOption = formControl.Options.find((item) => item.Value === tenantFormControlAnswer)
        const props = {
          control: formControl,
          answer: { selectedOptionsIds: selectedOption ? [selectedOption.Id] : null },
          handleChange: this.onDropDownAnswerChange.bind(this),
          disabled: false,
        }
        return <DropDownList {...props} />
      }
      default:
        return null
    }
  }

  render() {
    const props = {
      formControl: this.props.formControl,
      renderControl: this.renderControl.bind(this),
      onAnswerChange: this.onAnswerChange,
    }
    return <TenantFormControl {...props} />
  }
}

TenantFormControlContainer.propTypes = {
  formControl: PropTypes.object.isRequired,
  setTenantFormAnswer: PropTypes.func.isRequired,
  setTenantFormMultipleAnswer: PropTypes.func.isRequired,
  checkTenantFormConditions: PropTypes.func.isRequired,
  tenantFormControlAnswer: PropTypes.string,
  tenantFormControlAnswers: PropTypes.object,
}

function mapStateToProps({ tenantForms }, ownProps) {
  const { tenantFormAnswers } = tenantForms

  return {
    tenantFormControlAnswer:
      tenantFormAnswers && typeof tenantFormAnswers[ownProps.formControl.Id] === 'string'
        ? tenantFormAnswers[ownProps.formControl.Id]
        : '',
    tenantFormControlAnswers:
      tenantFormAnswers && typeof tenantFormAnswers[ownProps.formControl.Id] === 'object'
        ? tenantFormAnswers[ownProps.formControl.Id]
        : {},
  }
}

const mapDispatchToProps = (dispatch) => ({
  setTenantFormAnswer: (answer) => dispatch(setTenantFormAnswer(answer)),
  setTenantFormMultipleAnswer: (answer) => dispatch(setTenantFormMultipleAnswer(answer)),
  checkTenantFormConditions: (data) => dispatch(checkTenantFormConditions(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TenantFormControlContainer)
