import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  mergedRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  underReviewMessage: {
    fontSize: '12px',
    color: '#616161',
  },
}))
