import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as rawActions from '../actions'

import BentleySettingsDialog from '../components/BentleySettingsDialog'

const BentleySettingsDialogContainer = ({
  isOpen,
  isManualBoundingBoxInputEnabled,
  actions: { closeBentleySettingsDialog, createBentleyModel },
}) => {
  const settingsDialogProps = {
    isOpen,
    isManualBoundingBoxInputEnabled,
    closeDialog: closeBentleySettingsDialog,
    createBentleyModel,
  }

  return <BentleySettingsDialog {...settingsDialogProps} />
}

BentleySettingsDialogContainer.defaultProps = {}

BentleySettingsDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isManualBoundingBoxInputEnabled: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    closeBentleySettingsDialog: PropTypes.func.isRequired,
    createBentleyModel: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.bentleyModel.isSettingsDialogOpen,
    isManualBoundingBoxInputEnabled: state.appConfigState.isBentleyBoundingBoxManualInputEnabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BentleySettingsDialogContainer)
