import { combineReducers } from 'redux'

import isOpen from './isOpen'
import isLoaded from './isLoaded'
import isLoading from './isLoading'
import trackings from './trackings'
import filtration from './filtration'

export default combineReducers({
  isOpen,
  isLoaded,
  isLoading,
  trackings,
  filtration,
})
