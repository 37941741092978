import { _getAiClassesRequest } from './aiRequests'

const formStatisticsPageRequests = {
  getFormByIdRequest: (formId) => ({
    url: `api/forms/${formId}`,
    method: 'get',
  }),
  addTagToControlRequest: (data) => ({
    url: `api/formcontroltags/create`,
    method: 'post',
    data,
  }),
  createTagAndAddToControlRequest: (data) => ({
    url: `api/formcontroltags/create/newtag`,
    method: 'post',
    data,
  }),
  removeTagFromControlRequest: ({ Id }) => ({
    url: `api/formcontroltags/delete/${Id}`,
    method: 'delete',
  }),
  updateFormControlProcessingRequest: (data) => ({
    url: `api/forms/formcontrols/processing`,
    method: 'put',
    data,
  }),
  getAiClassesForFormInfoRequest: _getAiClassesRequest,
}

export default formStatisticsPageRequests
