import { handleActions } from 'redux-actions'
import * as customVideoActions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [customVideoActions.addTimelineItem](state, action) {
      return [...state, action.payload]
    },
    [customVideoActions.addMultipleTimelineItems](state, action) {
      return [...state, ...action.payload]
    },
    [customVideoActions.updateTimelineList](state, action) {
      return [...action.payload]
    },
    [customVideoActions.closeTimeline](state, action) {
      return defaultValue
    },
    [customVideoActions.deleteTimelineItem](state, action) {
      const index = action.payload
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [customVideoActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
