import { call, put, takeLatest, select, all, delay, take } from 'redux-saga/effects'

import { resetPickRecordingTypeDialogResult } from '@tabeeb/modules/whiteboard/actions'
import { getTrack } from '@tabeeb/modules/presentation/services/trackService'
import { getIsUserVideoPlaying } from '@tabeeb/modules/presentation/selectors'
import { VIDEO_DEVICE } from '@tabeeb/modules/presentation/constants'
import { addFilesToQueue } from '@tabeeb/modules/gallery/actions'
import * as recordingActions from '../actions'
import * as notificationActions from '../../notification/actions'
import * as dataConverter from '../../../services/dataConverter'
import {
  RecordingStatus,
  RecordingErrorCode,
  RecordingType,
  LocalRecordingOwnerActionType,
  ServerRecordingState,
} from '../../../Enums'
import selector from '../../shared/utils/selector'
import { signalrEvents, signalrActions, signalrConstants } from '../../signalr'
import * as accountSelectors from '../../account/selectors'
import { contentStateSelectors } from '../../shared/content'
import {
  getMediaRecorder,
  getRecordedFile,
  isCurrentUserRecorder,
  isFileProcessing,
  isLocalRecordingActive,
  resetLocalRecordingStatus,
  setLocalRecordingInitializedInCall,
} from '../services/localRecordingService'

import { getIsRecordingActive, getRecordingState, getRecordingStatus } from '../selectors'

const STOP_TIMEOUT = 1500
const PAUSE_TIMEOUT = 1500
const CONTINUE_TIMEOUT = 1500
const CHECK_STATUS_TIMEOUT = 30000
const START_TIMEOUT = 10000
const LOCAL_RECORDING_RESTART_DELAY = 500

function* continueLocalRecording(userId) {
  const mediaRecorder = getMediaRecorder()
  if (!mediaRecorder) {
    return
  }
  mediaRecorder.resume()
  yield put(recordingActions.onRecordingStarted())

  const { startDate, session } = yield select(getRecordingState)
  yield put(
    signalrActions.invokeHubAction({
      method: 'RecordingStatusUpdate',
      args: [
        {
          state: ServerRecordingState.processing,
          recordingType: RecordingType.local,
          session,
          selectedUser: userId,
          startDate: startDate / 1000,
        },
      ],
    })
  )
}

function* pauseLocalRecording() {
  const mediaRecorder = getMediaRecorder()
  if (!mediaRecorder) {
    return
  }
  mediaRecorder.pause()
  yield put(recordingActions.onRecordingPaused())
  const { session } = yield select(getRecordingState)
  yield put(
    signalrActions.invokeHubAction({
      method: 'RecordingStatusUpdate',
      args: [
        {
          state: ServerRecordingState.pause,
          recordingType: RecordingType.local,
          session,
        },
      ],
    })
  )
}

function* startLocalRecording(userId) {
  const mediaRecorder = getMediaRecorder()
  if (mediaRecorder.state !== 'recording') {
    mediaRecorder.start()
  }

  const { contentId } = yield select((state) => state.contentState)
  const session = `${contentId}`
  const startDate = Date.now()
  yield put(recordingActions.updateRecordingState({ startDate, session }))

  yield put(
    signalrActions.invokeHubAction({
      method: 'RecordingStatusUpdate',
      args: [
        {
          state: ServerRecordingState.processing,
          recordingType: RecordingType.local,
          session,
          selectedUser: userId,
          startDate: startDate / 1000,
        },
      ],
    })
  )
}

function* stopLocalRecording(loggedOutUserId) {
  const { startDate, session } = yield select(getRecordingState)

  if (isCurrentUserRecorder()) {
    const mediaRecorder = getMediaRecorder()
    if (mediaRecorder) {
      mediaRecorder.stop()
      yield put(recordingActions.onRecordingStopped())
      while (isLocalRecordingActive()) {
        yield delay(500)
      }
      const currentUserId = yield select(accountSelectors.getCurrentUserId)
      const endDate = Date.now()
      const file = yield call(getRecordedFile, endDate - startDate)
      if (file && currentUserId !== loggedOutUserId) {
        yield put(addFilesToQueue({ files: [file], uploadOfLocalRecording: { endDate, startDate } }))
      }

      yield put(
        signalrActions.invokeHubAction({
          method: 'RecordingStatusUpdate',
          args: [
            {
              state: ServerRecordingState.none,
              recordingType: RecordingType.local,
              session,
            },
          ],
        })
      )
    }
  } else {
    resetLocalRecordingStatus()
    yield put(recordingActions.onRecordingStopped())
  }
}

function* onRecordingStarting({ payload }) {
  if (payload.recordingType === RecordingType.cloud) {
    const { contentId } = yield select((state) => state.contentState)
    const { serverName, recordId } = yield select((state) => state.recording)
    const recordingModel = {
      contentId,
      serverName,
      recordId,
      selectedUserId: payload.selectedUserId,
    }
    yield put(recordingActions.recordingPrepareRequest(recordingModel))
    yield put(
      notificationActions.onAddInfoNotification({
        message: 'Recording is preparing.',
        options: {
          key: 'RecordingPreparing',
          persist: true,
        },
      })
    )
  } else if (payload.recordingType === RecordingType.local) {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const videoMuted = yield select((state) => !getIsUserVideoPlaying(state, { Id: payload.selectedUserId }))

      if (videoMuted) {
        yield put(
          notificationActions.onAddErrorNotification({
            message: 'User with a turned off camera cannot be recorded locally.',
          })
        )
        yield put(recordingActions.onRecordingStopped())
        yield put(resetPickRecordingTypeDialogResult())
        return
      }

      const presentationState = yield select((state) => state.presentation.tracks)
      const currentUserId = yield select((state) => accountSelectors.getCurrentUserId(state))

      while (isFileProcessing() || isLocalRecordingActive()) {
        yield delay(500)
      }

      const videoTrack = getTrack(presentationState, payload.selectedUserId, currentUserId, VIDEO_DEVICE).track

      const mediaRecorder = getMediaRecorder(videoTrack)
      if (mediaRecorder) {
        yield call(startLocalRecording, payload.selectedUserId)

        yield put(notificationActions.onAddInfoNotification({ message: 'Recording has started.' }))
        yield put(
          recordingActions.onRecordingStarted({
            recordingStatus: RecordingStatus.started,
          })
        )
      }
    } else {
      yield put(
        notificationActions.onAddInfoNotification({ message: 'Local recording is not supported by your browser.' })
      )
      yield put(recordingActions.onRecordingStopped())
    }
  }
}

function* onRecordingContinuing({ payload }) {
  if (isLocalRecordingActive()) {
    yield call(continueLocalRecording, payload.selectedUserId)
  } else {
    yield delay(CONTINUE_TIMEOUT)
    yield put(recordingActions.recordingContinueRequest(payload))
  }
}

function* onRecordingPausing({ payload }) {
  if (isLocalRecordingActive()) {
    yield call(pauseLocalRecording)
  } else {
    yield delay(PAUSE_TIMEOUT)
    const { contentId } = yield select((state) => state.contentState)
    const { serverName, recordId, selectedUserId } = yield select((state) => state.recording)
    const recordingModel = {
      contentId,
      serverName,
      recordId,
      selectedUserId,
    }
    yield put(recordingActions.recordingPauseRequest(recordingModel))
  }
}

function* onRecordingStopping({ payload = {} }) {
  if (isLocalRecordingActive()) {
    yield call(stopLocalRecording, payload.loggedOutUserId)
  } else if (!payload.presenterChanged) {
    const { noDelay = false } = payload
    if (!noDelay) {
      yield delay(STOP_TIMEOUT)
    }

    const contentId = yield select(contentStateSelectors.getContentId)
    const presenterId = yield select(contentStateSelectors.getPresenterId)
    const currentUserId = yield select(accountSelectors.getCurrentUserId)

    if (currentUserId !== presenterId) {
      return
    }
    const { serverName, recordId, selectedUserId } = yield select((state) => state.recording)
    const recordingModel = {
      contentId,
      serverName,
      recordId,
      selectedUserId,
    }
    yield put(recordingActions.recordingStopRequest(recordingModel))
  }
  yield put(notificationActions.onAddInfoNotification({ message: 'Recording is off.' }))
}

function* onStopRecordingForUser(action) {
  const { userId } = action.payload

  const { selectedUserId } = yield select((state) => state.recording)
  const isRecordingActive = yield select(getIsRecordingActive)
  const recordingStatus = yield select(getRecordingStatus)

  const needToStopRecording =
    (isRecordingActive || recordingStatus === RecordingStatus.paused) && selectedUserId === userId

  if (needToStopRecording) {
    yield put(recordingActions.onRecordingStopping({ ...action.payload, loggedOutUserId: userId }))
  }
}

function* recordingPrepareSuccess({ response }) {
  const convertedData = dataConverter.convertRecordingStatusObject(response.data)
  if (convertedData.recordingStatus === ServerRecordingState.error) {
    yield put(recordingActions.onSetErrorState(convertedData))
  } else {
    yield put(recordingActions.updateRecordingState(convertedData))
    yield put(recordingActions.checkRecordingStatus())
  }
}

function* recordingStartSuccess({ response }) {
  const convertedData = dataConverter.convertRecordingStatusObject(response.data)
  if (convertedData.recordingType !== RecordingType.local) {
    if (convertedData.recordingStatus === ServerRecordingState.error) {
      yield put(recordingActions.onSetErrorState(convertedData))
    } else {
      yield put(recordingActions.onRecordingStarted(convertedData))
      yield put(recordingActions.checkRecordingStatus())
      yield put(notificationActions.onDismissNotification('RecordingPreparing'))
      yield put(notificationActions.onAddInfoNotification({ message: 'Recording has started.' }))
    }
  }
}

function* recordingPauseSuccess({ payload, response }) {
  const convertedData = dataConverter.convertRecordingStatusObject(response.data)
  if (convertedData.recordingStatus === ServerRecordingState.error) {
    yield put(recordingActions.onSetErrorState(convertedData))
  } else {
    yield put(recordingActions.onRecordingPaused(convertedData))
    yield put(recordingActions.checkRecordingStatus())
  }
}

function* recordingContinueSuccess({ payload, response }) {
  const convertedData = dataConverter.convertRecordingStatusObject(response.data)
  if (convertedData.recordingStatus === ServerRecordingState.error) {
    yield put(recordingActions.onSetErrorState(convertedData))
  } else {
    yield put(recordingActions.onRecordingStarted(convertedData))
    yield put(recordingActions.checkRecordingStatus())
  }
}

function* recordingStopSuccess({ payload, response }) {
  yield put(signalrActions.invokeHubAction({ method: 'RecordingStatusUpdate', args: [response.data] }))

  const convertedData = dataConverter.convertRecordingStatusObject(response.data)
  if (convertedData.recordingStatus === ServerRecordingState.error) {
    yield put(recordingActions.onSetErrorState(convertedData))
  } else {
    yield put(recordingActions.onRecordingStopped(convertedData))
  }
}

function* recordingStatusSuccess({ payload, response }) {
  const convertedData = dataConverter.convertRecordingStatusObject(response.data)
  if (convertedData.recordingStatus === ServerRecordingState.error) {
    yield put(recordingActions.onSetErrorState(convertedData))
  } else {
    let recordingStatus = RecordingStatus.started
    if (convertedData.recordingStatus === ServerRecordingState.pause) {
      recordingStatus = RecordingStatus.paused
    }
    yield put(recordingActions.updateRecordingState({ ...convertedData, recordingStatus }))
    yield put(recordingActions.checkRecordingStatus())
  }
}

function* updateState({ payload }) {
  const convertedData = dataConverter.convertRecordingStatusObject(payload)
  if (convertedData.recordingType === RecordingType.local) {
    const mediaRecorder = getMediaRecorder()
    let file = null
    switch (convertedData.recordingStatus) {
      case ServerRecordingState.processing:
        setLocalRecordingInitializedInCall()
        yield put(recordingActions.onRecordingStarted())
        yield put(
          recordingActions.updateRecordingState({
            ...convertedData,
            recordingStatus: RecordingStatus.started,
          })
        )
        break
      case ServerRecordingState.none:
        if (isCurrentUserRecorder()) {
          if (!mediaRecorder) {
            return
          }
          mediaRecorder.stop()
          while (isLocalRecordingActive()) {
            yield delay(500)
          }
          const endDate = Date.now()
          const { startDate } = yield select((state) => state.recording)
          file = yield call(getRecordedFile, endDate - startDate)
          if (file) {
            yield put(addFilesToQueue({ files: [file] }))
          }
        }
        yield put(recordingActions.onRecordingStopped())
        resetLocalRecordingStatus()
        break
      case ServerRecordingState.pause:
        yield put(recordingActions.onRecordingPaused())
        break
      default:
        break
    }
  } else if (convertedData.recordingType === RecordingType.cloud) {
    let recordingStatus = RecordingStatus.stopped
    switch (convertedData.recordingStatus) {
      case ServerRecordingState.pending:
        recordingStatus = RecordingStatus.starting
        break
      case ServerRecordingState.processing:
      case RecordingStatus.started:
        recordingStatus = RecordingStatus.started
        yield put(recordingActions.onRecordingStarted({ ...convertedData, recordingStatus }))
        break
      case ServerRecordingState.pause:
        recordingStatus = RecordingStatus.paused
        break
      case ServerRecordingState.ready:
      case ServerRecordingState.error:
        recordingStatus = RecordingStatus.stopped
        break
      default:
        break
    }

    yield put(recordingActions.updateRecordingState({ ...convertedData, recordingStatus }))
    yield put(recordingActions.checkRecordingStatus())
  }
}

function* checkRecordingStatus() {
  const getCheckRecordingStatus = (recordingStatus) =>
    [RecordingStatus.stopped, RecordingStatus.stopping, RecordingStatus.starting, RecordingStatus.pausing].includes(
      recordingStatus
    )

  const recordingState = yield select((state) => state.recording)
  if (getCheckRecordingStatus(recordingState.recordingStatus)) {
    return
  }

  const delayTimeout =
    recordingState.recordingStatus === ServerRecordingState.pending ? START_TIMEOUT : CHECK_STATUS_TIMEOUT
  yield delay(delayTimeout)

  const { isCallStarted } = yield select(selector.getConnectionState)
  if (!isCallStarted) {
    return
  }

  const { serverName, recordId, selectedUserId, recordingStatus } = yield select((state) => state.recording)

  if (getCheckRecordingStatus(recordingStatus)) {
    return
  }

  const { contentId } = yield select((state) => state.contentState)
  const recordingModel = {
    contentId,
    serverName,
    recordId,
    selectedUserId,
  }
  yield put(recordingActions.recordingStatusRequest(recordingModel))
}

function* onRecordingVideoAttached({ payload }) {
  const { serverName, recordId, selectedUserId, recordingStatus } = yield select((state) => state.recording)

  if (
    recordingStatus !== ServerRecordingState.pending ||
    [RecordingStatus.stopping, RecordingStatus.stopped].includes(recordingStatus)
  ) {
    return
  }

  const users = yield select((state) => state.users)
  const { contentId } = yield select((state) => state.contentState)
  const recordingModel = {
    contentId,
    serverName,
    recordId,
    selectedUserId,
  }

  if (users.users.some((user) => user.isOnline && user.id === selectedUserId)) {
    console.info('EMIT "StartRecording" by JITSI Response')
    yield put(recordingActions.recordingStartRequest(recordingModel))
  } else {
    yield put(recordingActions.recordingStopRequest(recordingModel))
  }
}

function* startRecordingTimer({ payload }) {
  while (true) {
    const recording = yield select((state) => state.recording)

    if (recording.recordingStatus === RecordingStatus.paused) {
      yield put(recordingActions.updateRecordingState({ startDate: recording.startDate + 1000 }))
      yield delay(1000)
      continue
    } else if (recording.recordingStatus === RecordingStatus.stopped) {
      break
    }

    const startDate = recording.startDate || Date.now()
    const time = Math.max(Date.now() - startDate, 0)
    yield put(recordingActions.onSetElapsedTime(time))
    yield delay(1000)
  }
}

function* onErrorHandler(actionObj) {
  const { message } = actionObj
  const errorModel = {
    errorCode: RecordingErrorCode.errorIsUndefined,
    errorMessage: message,
    recordingStatus: ServerRecordingState.error,
  }
  yield put(recordingActions.onSetErrorState(errorModel))
  yield put(resetPickRecordingTypeDialogResult())
}

function* onSetErrorState({ payload }) {
  const { errorCode } = yield select((state) => state.recording)

  let message = ''
  switch (errorCode) {
    case RecordingErrorCode.moreThan90Min:
      message = 'Recording was off because time limit has been reached.'
      break
    case RecordingErrorCode.sessionWasNotStarted:
      message = 'Recording was off because selected user left session.'
      break
    case RecordingErrorCode.sessionIsAlreadyRecording:
      message = 'Recording is processing for this session now, please try again in a few minutes.'
      break
    case RecordingErrorCode.recordingPageWasCrashed:
      message = 'Unfortunately, recording has stopped with an error. Please try starting recording again.'
      break
    default:
      message = 'Recording was stopped with error.'
      break
  }
  yield put(notificationActions.onDismissNotification('RecordingPreparing'))
  yield put(notificationActions.onAddErrorNotification({ message, options: { autoHideDuration: 10000 } }))
}

function* onRecordingStatusUpdate(action) {
  const [emitEventuserId, recordingStatus] = action.payload

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  if (currentUserId !== emitEventuserId) {
    yield put(recordingActions.updateState(recordingStatus))
  }
}

function* onLocalRecordingOwnerControlRecording(action) {
  const [selectedUserId, actionType] = action.payload
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  if (currentUserId !== selectedUserId) {
    if (actionType === LocalRecordingOwnerActionType.restart) {
      yield delay(LOCAL_RECORDING_RESTART_DELAY)
      yield put(recordingActions.onRecordingStarting({ userId: selectedUserId, recordingType: RecordingType.local }))
    } else if (actionType === LocalRecordingOwnerActionType.stop) {
      yield put(recordingActions.onRecordingStopping())
      yield put(
        notificationActions.onAddInfoNotification({ message: 'Recording was stopped because you muted camera.' })
      )
    }
  }
}

function* onRecordingStopAndStart({ payload: { selectedUserId, recordingType } }) {
  yield put(recordingActions.onRecordingStopping())
  yield take(recordingActions.onRecordingStopped)

  yield put(recordingActions.onRecordingStarting({ userId: selectedUserId, recordingType }))
}

function* recordingSaga() {
  yield all([
    takeLatest(recordingActions.onRecordingStarting, onRecordingStarting),
    takeLatest(recordingActions.onRecordingContinuing, onRecordingContinuing),
    takeLatest(recordingActions.recordingPrepareSuccess, recordingPrepareSuccess),
    takeLatest(recordingActions.recordingStartSuccess, recordingStartSuccess),
    takeLatest(recordingActions.onRecordingPausing, onRecordingPausing),
    takeLatest(recordingActions.recordingPauseSuccess, recordingPauseSuccess),
    takeLatest(recordingActions.recordingContinueSuccess, recordingContinueSuccess),
    takeLatest(recordingActions.onStopRecordingForUser, onStopRecordingForUser),
    takeLatest(recordingActions.onRecordingStopping, onRecordingStopping),
    takeLatest(recordingActions.recordingStopSuccess, recordingStopSuccess),
    takeLatest(recordingActions.recordingStatusSuccess, recordingStatusSuccess),
    takeLatest(recordingActions.checkRecordingStatus, checkRecordingStatus),
    takeLatest(recordingActions.updateState, updateState),
    takeLatest(recordingActions.onRecordingVideoAttached, onRecordingVideoAttached),
    takeLatest(recordingActions.onRecordingStarted, startRecordingTimer),
    takeLatest(recordingActions.onSetErrorState, onSetErrorState),
    takeLatest(recordingActions.onRecordingStopAndStart, onRecordingStopAndStart),

    takeLatest(
      [
        recordingActions.recordingPrepareFailed,
        recordingActions.recordingStartFailed,
        recordingActions.recordingPauseFailed,
        recordingActions.recordingContinueFailed,
        recordingActions.recordingStopFailed,
        recordingActions.recordingStatusFailed,
      ],
      onErrorHandler
    ),

    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onRecordingStatusUpdate, onRecordingStatusUpdate),
    takeLatest(
      signalrEvents[signalrConstants.tabeebHubName].onLocalRecordingOwnerControlRecording,
      onLocalRecordingOwnerControlRecording
    ),
  ])
}

export default recordingSaga
