import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Autocomplete,
  Chip,
  TextField,
} from '@mui/material'

import { getAIObjects } from '@tabeeb/modules/artificialIntelligence/selectors'
import { getAnnotationTypeName } from '@tabeeb/modules/annotations/services/annotationsService'
import { AnnotationType } from '@tabeeb/enums'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { getIsAddClassificationAnnotationsDialogOpen, getPagesList, getIsProcessing } from '../../selectors'

import * as actions from '../../actions'

const AddClassificationAnnotationsDialog = () => {
  const dispatch = useDispatch()

  const [selectedAIObjects, setSelectedAIObjects] = useState([])

  const contentId = useSelector(contentStateSelectors.getContentId)
  const aiObjects = useSelector(getAIObjects)
  const pages = useSelector(getPagesList)
  const isOpen = useSelector(getIsAddClassificationAnnotationsDialogOpen)
  const isProcessing = useSelector(getIsProcessing)

  const handleChange = (event, newValue) => {
    setSelectedAIObjects(newValue)
  }

  const handleClose = () => {
    dispatch(actions.closeAddClassificationAnnotationsDialog())
    setSelectedAIObjects(() => [])
  }

  const handleSubmit = () => {
    const model = {
      ContentId: contentId,
      AIObjectIds: selectedAIObjects.map((aiObject) => aiObject.Id),
      PageIds: pages.map((page) => page.id),
    }
    dispatch(actions.addClassificationAnnotations.request(model))
    handleClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>Select CV classes</DialogTitle>
      <DialogContent>
        <Alert severity='info' sx={{ mb: 1 }}>
          <p>
            {`Each selected CV class will be added to each selected page as a new ${getAnnotationTypeName(
              AnnotationType.AIHotspot
            )} annotation.`}
          </p>
          <p>
            {`A new ${getAnnotationTypeName(
              AnnotationType.AIHotspot
            )} annotation will not be added to a page if the page
            already has at least one AI annotation (${getAnnotationTypeName(AnnotationType.AIHotspot)}, 
            ${getAnnotationTypeName(AnnotationType.Stroke)}, ${getAnnotationTypeName(AnnotationType.Rectangle)}, 
            ${getAnnotationTypeName(AnnotationType.Polygon)}) with the same CV class.`}
          </p>
        </Alert>
        <Autocomplete
          fullWidth
          multiple
          value={selectedAIObjects}
          onChange={handleChange}
          options={aiObjects}
          getOptionLabel={(option) => option.Name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                label={option.Name}
                {...getTagProps({ index })}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant='outlined' label='CV classes' placeholder='Type a CV class name' />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleSubmit} disabled={selectedAIObjects.length === 0 || isProcessing}>
          Add
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddClassificationAnnotationsDialog
