export default (theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
    },
    bitrateContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    upstream: {
      color: theme.palette.success.light,
    },
    downstream: {
      color: theme.palette.error.light,
    },
  }
}
