import { createRef, Component } from 'react'
import PropTypes from 'prop-types'

import { getAnnotationComponentByType } from '../services/annotationsService'
import * as annotationsService from '../services/annotationsService'

class AnnotationContainer extends Component {
  transformerRef = createRef()

  annotationRef = (ref) => {
    this.setState({ annotationRef: ref })
  }

  state = {
    hovered: false,
    selected: false,
    annotationRef: undefined,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentAnnotation, selected } = nextProps
    if (!prevState.isUpdating) {
      return {
        ...prevState,
        selected,
        annotation: currentAnnotation,
      }
    }
    return null
  }

  componentDidUpdate(prevProps, prevState) {
    const { annotation } = this.state

    if (!prevState.selected && this.state.selected) {
      if (annotationsService.getIsAnnotationSupportsTransformation(annotation.Type)) {
        if (this.transformerRef.current) {
          this.transformerRef.current.setNode(this.state.annotationRef)
          this.transformerRef.current.getLayer().batchDraw()
        }
      }
    }
  }

  componentWillUnmount() {
    const { hovered, annotationRef } = this.state
    if (hovered && annotationRef) {
      const stage = annotationRef.getStage()
      if (stage) {
        const container = stage.container()
        if (container) {
          container.style.cursor = null
        }
      }
    }
  }

  getElementAbsolutePosition = () => {
    const { annotationRef } = this.state

    if (!annotationRef) {
      return null
    }

    const rect = annotationRef.getStage().content.getBoundingClientRect()
    const position = annotationRef.getAbsolutePosition()

    return {
      x: rect.left + position.x,
      y: rect.top + position.y,
    }
  }

  getElementStagePosition = () => {
    const { annotationRef } = this.state

    if (!annotationRef) {
      return null
    }

    const position = annotationRef.getAbsolutePosition()

    return {
      x: position.x,
      y: position.y,
    }
  }

  _handleContextMenu = (e) => {
    e.evt.preventDefault()
    e.evt.stopPropagation()

    const {
      annotationActions: { addAnnotationToSelection, openAnnotationMenu },
    } = this.props
    const { annotation } = this.state

    addAnnotationToSelection(annotation.Id)

    openAnnotationMenu({
      x: e.evt.clientX,
      y: e.evt.clientY,
    })
  }

  _handleSelect = ({ evt: { ctrlKey, shiftKey, which } }) => {
    if (which !== 1) {
      return
    }

    const {
      annotationActions: { addAnnotationToSelection, removeAnnotationFromSelection, setSelectedAnnotationsIds },
    } = this.props

    const { annotation } = this.state

    if (ctrlKey) {
      addAnnotationToSelection(annotation.Id)
    } else if (shiftKey) {
      removeAnnotationFromSelection(annotation.Id)
    } else {
      setSelectedAnnotationsIds([annotation.Id])
    }
  }

  _handleMouseEnter = (e) => {
    this.setState({
      hovered: true,
    })

    const stage = e.target.getStage()
    if (stage) {
      stage.container().style.cursor = 'move'
    }
  }

  _handleMouseLeave = (e) => {
    this.setState({
      hovered: false,
    })

    const stage = e.target.getStage()
    if (stage) {
      stage.container().style.cursor = null
    }
  }

  _handleDragBound = (position) => {
    const { annotation } = this.state
    const { contentSize, contentScale } = this.props
    const annotationSize = getAnnotationComponentByType(annotation.Type).getSize(annotation)

    return {
      x: Math.min(
        Math.max(0, position.x),
        Math.max(contentSize.width * contentScale - annotationSize.width * contentScale, 0)
      ),
      y: Math.min(
        Math.max(0, position.y),
        Math.max(contentSize.height * contentScale - annotationSize.height * contentScale, 0)
      ),
    }
  }

  _handleDragStart = (e) => {
    const { dragging } = e.target
    if (dragging === true) {
      return
    }

    e.target.dragging = true
    this.setState({ isUpdating: true })
  }

  _handleStartUpdate = (e) => {
    this.setState({ isUpdating: true })
  }

  _handleEndUpdate = (e) => {
    const { annotation, isUpdating } = this.state
    const {
      annotationActions: { updateContentAnnotation },
    } = this.props

    if (isUpdating) {
      updateContentAnnotation({ annotation })

      if (e) {
        e.target.dragging = false
      }
      this.setState({ isUpdating: false })
    }
  }

  _handleTransformStart = (e) => {
    this.setState({ isUpdating: true })
  }

  render() {
    return null
  }
}

AnnotationContainer.propTypes = {
  currentAnnotation: PropTypes.object,
  contentScale: PropTypes.number.isRequired,
  contentSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  annotationActions: PropTypes.shape({
    openAnnotationMenu: PropTypes.func.isRequired,
    addAnnotationToSelection: PropTypes.func.isRequired,
    removeAnnotationFromSelection: PropTypes.func.isRequired,
    setSelectedAnnotationsIds: PropTypes.func.isRequired,
    updateContentAnnotation: PropTypes.func.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
}

export default AnnotationContainer
