import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }
})
