import { handleActions } from 'redux-actions'

import { resetContentState } from '@tabeeb/modules/shared/content/actions'
import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.getDeletedSessionFoldersSuccess](state, action) {
      return action.response.data
    },
    [rawActions.addDeletedSessionFolder](state, action) {
      return [...state, action.payload]
    },
    [rawActions.filterDeletedFolders](state, action) {
      const idsToRemove = action.payload

      return state.filter((folder) => idsToRemove.findIndex((id) => id === folder.id) === -1)
    },
    [resetContentState]() {
      return defaultState
    },
  },
  defaultState
)
