import { handleActions } from 'redux-actions'

import { getTenantsList } from '../actions'
import { Tenant } from '../types'

export default handleActions<Tenant | null, any>(
  {
    [getTenantsList.success.toString()](state, action) {
      const responseAction = action as ReturnType<typeof getTenantsList.success>

      return responseAction.response.data.currentTenant
    },
  },
  null
)
