import { handleActions, combineActions } from 'redux-actions'

import * as actions from '../../actions'

const defaultState = false

export default handleActions(
  {
    [actions.setConflictingAiObjects](state, action) {
      return true
    },
    [combineActions(
      actions.resetConflictingAiObjects,
      actions.restoreAiObject.success,
      actions.updateAiObject.success
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
