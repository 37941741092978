import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const resetState = createAction('RESET_GEOSEARCH_STATE')

export const selectGeosearchContent = createAction('SELECT_GEOSEARCH_SESSION')
export const openGeoserchThumbnailsDialog = createAction('OPEN_GEOSEARCH_THUMBNAIL_DIALOG')
export const closeGeoserchThumbnailsDialog = createAction('CLOSE_GEOSEARCH_THUMBNAIL_DIALOG')

export const getGeosearchPoints = createApiActions('GET_GEOSEARCH_POINTS')
export const getThumbnailsByPageIds = createApiActions('GET_GEOSEARCH_THUMBNAILS_BY_PAGE_IDS')

export const setGeosearchState = createAction('SET_GEOSEARCH_STATE')
