export default (theme) => {
  return {
    statuses: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      ...theme.typography.body2,
    },
  }
}
