import { all } from 'redux-saga/effects'

import addBentleyAnalyticsReport from './addBentleyAnalyticsReport'
import resetState from './resetState'

function* bentleyAnalyticsReport() {
  yield all([addBentleyAnalyticsReport(), resetState()])
}

export default bentleyAnalyticsReport
