import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AddOutlined } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

import { initiateSessionCreation } from '../../../actions'
import { DialogTypes, Features } from '../../../enums'
import { getIsFeatureEnabled, getIsNeedAdminPermission, getIsSessionCreationInProgress } from '../../../selectors'
import { getHasCreateContentsPermission } from '../../../selectors/permissions'
import { usePageDialog } from '../../../hooks'

import HeaderButton from '../HeaderButton'

const CreateSessionButton = () => {
  const dispatch = useDispatch()

  const { onOpen } = usePageDialog(DialogTypes.CreateSessionWithAdminPermissionRequest)

  const creationIsInProgress = useSelector(getIsSessionCreationInProgress)
  const isAdminPermissionRequired = useSelector((state) => getIsNeedAdminPermission(state, { session: null }))

  const onClick = useCallback(() => {
    if (isAdminPermissionRequired) {
      onOpen()
    } else {
      dispatch(initiateSessionCreation({ needPermission: isAdminPermissionRequired }))
    }
  }, [dispatch, isAdminPermissionRequired, onOpen])

  const isVisible = useSelector(
    (state) => getHasCreateContentsPermission(state) && getIsFeatureEnabled(state, Features.SessionCreation)
  )
  if (!isVisible) {
    return null
  }

  return (
    <HeaderButton
      disabled={creationIsInProgress}
      label={sessionTitleFormatter.format('New session')}
      startIcon={creationIsInProgress ? <CircularProgress size={18} /> : <AddOutlined color='pagePrimaryAction' />}
      onClick={onClick}
    />
  )
}

export default CreateSessionButton
