export const FormActionDialogStatus = {
  Hidden: 1,
  ShowData: 2,
  ShowError: 3,
}

export const FormAnswersFromFileLoadingStatus = {
  None: 0,
  Loading: 1,
  Loaded: 2,
  Failed: 3,
}

export const FormButtons = {
  AddForm: 1,
  ExportForm: 2,
  DetachForm: 3,
  CompleteReview: 4,
  SendReviewFeedback: 5,
  SetInitialValues: 6,
  UploadCsv: 7,
  SendForInContentReview: 8,
  CompleteInContentReview: 9,
  FeedbackInContentReview: 10,
}

export const FormButtonsGroup = {
  Common: 1,
  InContentReview: 2,
  Review: 3,
  MergeReview: 4,
}
