import { combineActions, handleActions } from 'redux-actions'

import { AnswerStatusFilterOptions } from '@tabeeb/enums'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'

import * as actions from '../actions'
import {
  defaultState,
  handleCloseAction,
  handleFailedAction,
  handleFiltersUpdateAction,
  handleOpenAction,
  handleRequestAction,
  handleStatusUpdate,
  handleSuccessAction,
} from './shared'

const pageAnswersDiscussionState = {
  ...defaultState,
  page: undefined,
  filters: {
    userId: -1,
    formId: -1,
    statuses: [
      AnswerStatusFilterOptions.Approved,
      AnswerStatusFilterOptions.Rejected,
      AnswerStatusFilterOptions.Submitted,
    ],
  },
}

const pageAnswersDiscussion = handleActions(
  {
    [actions.openPageFormAnswersDialog](state, action) {
      return handleOpenAction(state, action)
    },
    [actions.closePageFormAnswersDialog](state, action) {
      return handleCloseAction(state, action)
    },
    [actions.getFormAnswersByPageRequest](state, action) {
      return handleRequestAction(state, action)
    },
    [actions.getFormAnswersByPageFailed](state, action) {
      return handleFailedAction(state, action)
    },
    [actions.getFormAnswersByPageSuccess](state, action) {
      return handleSuccessAction(state, action)
    },
    [actions.updatePageFormAnswersFilters](state, action) {
      return handleFiltersUpdateAction(state, action)
    },
    [combineActions(
      signalrEvents[signalrConstants.tabeebHubName].onAnswerStatusUpdated,
      signalrEvents[signalrConstants.tabeebHubName].onAnswerStatusUpdatedByReviewer
    )](state, action) {
      return handleStatusUpdate(state, action)
    },
    [actions.resetPageAnswersState](state, action) {
      return pageAnswersDiscussionState
    },
  },
  pageAnswersDiscussionState
)

export default pageAnswersDiscussion
