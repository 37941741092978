import { combineReducers } from 'redux'

import me from './me'
import authState from './authState'
import identityMe from './identityMe'

export default combineReducers({
  me,
  authState,
  identityMe,
})
