const projectsRequests = {
  getProjectsRequest: ({
    pageNumber = 1,
    pageSize = 25,
    order = 'asc',
    orderBy = 'Name',
    filters = { Name: '', Description: '', Type: null },
  }) => ({
    url: `api/projects`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      column: orderBy,
      order,
      Name: filters.Name,
      Description: filters.Description,
      Type: filters.Type,
    },
  }),
  addProjectRequest: (data) => ({
    url: `api/projects`,
    method: 'post',
    data,
  }),
  updateProjectRequest: (data) => ({
    url: `api/projects`,
    method: 'put',
    data,
  }),
  deleteProjectRequest: ({ projectId }) => ({
    url: `api/projects/${projectId}`,
    method: 'delete',
  }),
  trainProjectRequest: (data) => ({
    url: `api/projects/train/start`,
    method: 'post',
    data,
  }),
  getProjectRequest: ({ projectId }) => ({
    url: `api/projects/${projectId}`,
    method: 'get',
  }),
}

export default projectsRequests
