import PropTypes from 'prop-types'
import { Input, MenuItem, FormControl, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { menuItemStyles, menuProps } from './inlineStyles'
import './styles.less'

const useStyles = makeStyles(menuItemStyles)

const SessionSharingTenantsDropdown = ({ handleChange, tenantsList, selectedTenant }) => {
  const classes = useStyles()
  return (
    <div className='sharing-tenants-list'>
      {selectedTenant.IsPredefined ? (
        <TextField value={selectedTenant.Name} className='share-session-dialog-input' disabled />
      ) : (
        <FormControl className={classes.formControl}>
          <Select
            title={selectedTenant.Name}
            displayEmpty
            value={selectedTenant.Name || ''}
            onChange={handleChange}
            input={<Input id='select-placeholder' />}
            MenuProps={menuProps}
          >
            {tenantsList &&
              tenantsList.map((tenant) => (
                <MenuItem key={tenant.Id} value={tenant.Name} className={classes.menuItem}>
                  {tenant.Name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

SessionSharingTenantsDropdown.propTypes = {
  tenantsList: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedTenant: PropTypes.object,
}

export default SessionSharingTenantsDropdown
