import { handleActions, combineActions } from 'redux-actions'

import {
  onSetServerRecordingName,
  updateRecordingState,
  onRecordingStarted,
  onRecordingStopped,
  onRecordingPaused,
  resetRecordingState,
} from '../actions'

const defaultValue = ''

export default handleActions(
  {
    [onSetServerRecordingName](state, { payload }) {
      return payload
    },
    [combineActions(updateRecordingState, onRecordingStarted, onRecordingPaused)](state, { payload }) {
      return typeof payload.serverName !== 'undefined' ? payload.serverName : state
    },
    [combineActions(onRecordingStopped)](state, { payload }) {
      return ''
    },
    [resetRecordingState](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
