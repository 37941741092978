import { handleActions, combineActions } from 'redux-actions'
import _ from 'lodash'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.getVqaPoliciesSuccess](state, { response }) {
      return [...response.data]
    },
    [combineActions(rawActions.addVqaPolicySuccess, rawActions.updateVqaPolicySuccess)](state, { response }) {
      return _addOrUpdateVQAPolicy(state, response.data)
    },
    [rawActions.deleteVqaPolicySuccess](state, { payload, response }) {
      return state.filter((v) => v.Id !== payload)
    },
  },
  defaultState
)

function _addOrUpdateVQAPolicy(state, vqaPolicy) {
  const index = state.findIndex((vqaP) => vqaP.Id === vqaPolicy.Id)
  let result
  if (index === -1) {
    result = [...state, vqaPolicy]
    result = _.sortBy(result, (vqaPolicy) => vqaPolicy.Name)
  } else {
    result = [...state]
    result[index] = vqaPolicy
  }

  return result
}
