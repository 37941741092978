import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as contentTrashBinActions from '@tabeeb/modules/contentTrashBin/actions'
import * as contentTrashBinSelectors from '@tabeeb/modules/contentTrashBin/selectors'
import { isPdfPage } from '@tabeeb/services/pageService'
import * as gallerySelectors from '../selectors'
import { contentNotificationService } from '../../shared/content'
import * as rawActions from '../actions'

function* selectGalleryItem(action) {
  const { pageId } = action.payload
  const { selectedFolderId, selectedGalleryItemId } = yield select((state) => state.gallery.galleryState)

  const galleryList = yield select((state) => state.gallery.galleryList)
  const deletedFolders = yield select(contentTrashBinSelectors.getDeletedFolders)
  const galleryFolders = yield select(gallerySelectors.getFoldersList)

  let rootGalleryList = galleryList.filter((item) => item.folderId === null)
  let folderGalleryList = galleryList.filter((item) => item.folderId === selectedFolderId)
  const deletedItem = galleryList.find((item) => item.id === pageId)

  const pageFolderId = deletedItem.folderId

  const deletedFolder = deletedFolders.find((folder) => folder.Id === pageFolderId)

  if (pageFolderId && !deletedFolder) {
    const folder = galleryFolders.find((folder) => folder.Id === pageFolderId)

    if (folder) {
      yield put(contentTrashBinActions.addDeletedSessionFolder(folder))
    }
  }

  yield put(rawActions.deleteGalleryItem({ pageId }))

  const pagesToDelete = isPdfPage(deletedItem.contentType)
    ? galleryList.filter((page) => page.contentUrl === deletedItem.contentUrl)
    : [deletedItem]

  yield put(contentTrashBinActions.addDeletedPages(pagesToDelete))

  if (pagesToDelete.some((item) => item.id === selectedGalleryItemId)) {
    let selectIndex = folderGalleryList.findIndex((item) => item.id === pageId) - 1
    if (selectIndex < 0) {
      rootGalleryList = rootGalleryList.filter(
        (page) => page.id !== pageId && page.contentUrl !== deletedItem.contentUrl
      )
      folderGalleryList = folderGalleryList.filter(
        (page) => page.id !== pageId && page.contentUrl !== deletedItem.contentUrl
      )

      selectIndex = 0
    }

    if (folderGalleryList.length > selectIndex) {
      yield put(rawActions.onSelectGalleryItem({ pageId: folderGalleryList[selectIndex].id, updateWeb: true }))
    } else {
      yield put(rawActions.onSelectGalleryItem({ pageId: rootGalleryList[0].id, updateWeb: true }))
    }
  }
}

function* deleteEmit(action) {
  const { pageId } = action.payload
  yield contentNotificationService.notifyOfDeletePage({ pageId })
}

function* deleteItem() {
  yield all([
    takeLatest([rawActions.deleteGalleryItemSuccess, rawActions.onDeleteGalleryItem], selectGalleryItem),
    takeLatest(rawActions.deleteGalleryItemSuccess, deleteEmit),
  ])
}

export default deleteItem
