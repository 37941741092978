import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = null

export default handleActions(
  {
    [actions.getAdminSessionApprovalSettingsSuccess](state, { response }) {
      return response.data
    },
    [actions.updateSessionApprovalSettingsSuccess](state, { payload }) {
      return payload
    },
  },
  defaultState
)
