import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import FolderIcon from '@material-ui/icons/Folder'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import { getCurrentUserId } from '../../../account/selectors'

import './styles.less'

const FoldersItem = ({ folder, open }) => {
  const currentUserId = useSelector(getCurrentUserId)

  return (
    <div className='shared-folders-item'>
      <div className='folders-item-info'>
        <div className='layout' onClick={() => open(folder)} title={folder.name} />
        <span className='icon-folder'>
          {folder.ownerId !== currentUserId ? (
            <FolderSharedIcon color='action' fontSize='inherit' className='form-item-icon' />
          ) : (
            <FolderIcon color='action' fontSize='inherit' className='form-item-icon' />
          )}
        </span>
        <span className='folders-item-name'>{folder.name}</span>
      </div>
    </div>
  )
}

FoldersItem.propTypes = {
  folder: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
}

export default memo(FoldersItem)
