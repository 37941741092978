import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Slider, Typography } from '@material-ui/core'

import { drawingActions, playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import useStyles from './styles'

const TransparencySlider = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const transparency = useSelector(playerToolbarSelectors.getTransparency)

  const onDragStop = useCallback(() => {
    dispatch(drawingActions.onSendStrokeAttributes())
  }, [dispatch])

  const onTransparencyChange = useCallback(
    (_, newValue) => {
      dispatch(drawingActions.onSetTransparency(newValue))
    },
    [dispatch]
  )

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography id='transparency-slider' color='textSecondary' variant='body2'>
          Opacity
        </Typography>
        <Typography color='textSecondary' variant='body2'>
          {(transparency * 100).toFixed(0)}%
        </Typography>
      </div>
      <Slider
        min={0.1}
        max={1}
        step={0.1}
        aria-labelledby='transparency-slider'
        value={transparency}
        onChange={onTransparencyChange}
        onChangeCommitted={onDragStop}
      />
    </div>
  )
}

export default memo(TransparencySlider)
