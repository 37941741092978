function deleteEmptyStringProperties(obj) {
  for (const key in obj) {
    const value = obj[key]
    if (value === '') {
      delete obj[key]
    }
  }
}

export function removeTemporaryFields(tenantConfig) {
  // These values must not be stored in the config
  delete tenantConfig.supportsPrograms
  delete tenantConfig.name
  delete tenantConfig.isBillingEnabled
  delete tenantConfig.ftpHost
  delete tenantConfig.ftpUser
  delete tenantConfig.ftpPassword

  deleteEmptyStringProperties(tenantConfig)
}
