const adminRequests = {
  adminGetTenantsRequest: () => ({
    url: `tenant-service/api/tenants`,
    method: 'get',
  }),

  adminGetRolesRequest: () => ({
    url: `api/admin/userroles/getroles`,
    method: 'get',
  }),

  adminAssignRoleRequest: (data) => ({
    url: `api/admin/userroles/assignRole`,
    method: 'post',
    data,
  }),

  adminSearchUsersRequest: ({ pageNumber = 1, pageSize = 25, order = 'asc', orderBy = 'Name', filters = {} }) => ({
    url: `api/admin/adminusers`,
    method: 'post',
    data: {
      pageNumber,
      pageSize,
      Sorting: { Column: orderBy, Order: order },
      Filtering: filters,
    },
  }),

  adminResetUserPasswordRequest: (userId) => ({
    url: `api/admin/adminusers/resetPassword/${userId}`,
    method: 'put',
  }),

  adminSetUserDisabledByUserIdRequest: (userId) => ({
    url: `api/admin/adminusers/disable/${userId}`,
    method: 'put',
  }),
  adminSetUserEnabledByUserIdRequest: (userId) => ({
    url: `api/admin/adminusers/disable/${userId}`,
    method: 'delete',
  }),

  adminSaveUserRequest: (user) => ({
    url: `api/admin/adminusers/${user.Id}`,
    method: 'put',
    data: user,
  }),

  adminCreateUserRequest: (user) => ({
    url: `api/adminusers/create`,
    method: 'post',
    data: user,
  }),

  adminCreateUsersRequest: (users) => ({
    url: `api/adminusers/createusers`,
    method: 'post',
    data: users,
  }),

  updateUserRequest: (user) => ({
    url: `api/users`,
    method: 'put',
    data: user,
  }),
}

export default adminRequests
