import { combineReducers } from 'redux'

import date from './date'
import reviewStatus from './reviewStatus'
import searchText from './searchText'
import sessionStatus from './sessionStatus'

export default combineReducers({
  date,
  reviewStatus,
  searchText,
  sessionStatus,
})
