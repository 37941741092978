import { Redirect } from 'react-router'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getIsAppAccountCreated, getIsIdentityAuthenticated } from '@tabeeb/modules/account/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { OidcSecure } from '@tabeeb/shared/routes/components/OidcSecure'
import routes from '@tabeeb/routes'

function useAuthMiddleware({ authorize, routeEnabled, requirements: { permissions } }) {
  const isIdentityAuthenticated = useSelector(getIsIdentityAuthenticated)
  const isAppAccountCreated = useSelector(getIsAppAccountCreated)
  const hasPermission = useSelector((state) => hasTenantPermission(state, permissions))

  const middleware = useCallback(
    (children) => {
      if (authorize) {
        if (isIdentityAuthenticated) {
          if (isAppAccountCreated) {
            if (routeEnabled && hasPermission) {
              return children
            }
            return <Redirect to={routes.home} />
          }

          return <Redirect to={routes.createProfile} />
        }

        return <OidcSecure />
      }

      if (routeEnabled) {
        return children
      }

      return <Redirect to={routes.home} />
    },
    [authorize, hasPermission, isAppAccountCreated, isIdentityAuthenticated, routeEnabled]
  )

  return middleware
}

export default useAuthMiddleware
