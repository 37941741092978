import { put, takeLatest, select, all, take } from 'redux-saga/effects'

import { LOCATION_CHANGE } from 'connected-react-router'

import * as accountActions from '@tabeeb/modules/account/actions'
import { getIsTenantAuthFlowCompleted, getIsUserAuthorized } from '@tabeeb/modules/account/selectors'

import * as rawActions from '../actions'
import * as usersActions from '../../../users/actions'
import * as sessionActions from '../../../state/actions/sessionActions'
import * as notificationsActions from '../../notification/actions'

function* openPoliciesViewer() {
  yield put(rawActions.getLatestPoliciesWithAcceptanceStatusRequest())
}

let previousRoute = null

export function* checkPoliciesUpdates(action) {
  const authorizeCompleted = yield select(getIsTenantAuthFlowCompleted)
  if (!authorizeCompleted) {
    yield take([accountActions.tenantAuthFlowCompleted])
  }

  if (action.type === LOCATION_CHANGE) {
    const currentRoute = action.payload.location.pathname.split('/')[1]
    if (previousRoute === currentRoute) {
      return
    }

    if (action.payload.isFirstRendering) {
      return
    }

    previousRoute = currentRoute
  }

  const isLoggedIn = yield select(getIsUserAuthorized)
  if (isLoggedIn) {
    yield put(rawActions.getIsUserNeedsToAcceptChangesRequest())
  }
}

function* logout() {
  yield put(rawActions.reset())
}

function* createPolicyFailed() {
  notificationsActions.onAddErrorNotification({ message: 'Failed to create policy!' })
}

function* acceptPoliciesFailed(action) {
  notificationsActions.onAddErrorNotification({ message: 'Failed to accept policies!' })
}

function* getIsUserNeedsToAcceptChangesSuccess(action) {
  const isUserNeedsToAcceptChangesSuccess = action.response.data
  if (isUserNeedsToAcceptChangesSuccess) {
    yield put(rawActions.openPoliciesViewer())
  }
}

function* acceptPoliciesSuccess(action) {
  yield put(rawActions.closePoliciesViewer())
}

function* policiesSaga() {
  yield all([
    takeLatest([sessionActions.onSetsessionSate, LOCATION_CHANGE], checkPoliciesUpdates),
    takeLatest(rawActions.openPoliciesViewer, openPoliciesViewer),
    takeLatest(rawActions.createPolicyFailed, createPolicyFailed),
    takeLatest(rawActions.acceptPoliciesFailed, acceptPoliciesFailed),
    takeLatest(rawActions.acceptPoliciesSuccess, acceptPoliciesSuccess),
    takeLatest(rawActions.getIsUserNeedsToAcceptChangesSuccess, getIsUserNeedsToAcceptChangesSuccess),
    takeLatest([accountActions.onLogout, usersActions.logout], logout),
  ])
}
export default policiesSaga
