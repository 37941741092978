import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const resetState = createAction('RESET_TENANT_SESSIONS_PAGE_STATE')

export const getLastUpdateTime = createApiActions('GET_LAST_UPDATE_TIME')

export const selectTenantSessionsMode = createAction('SELECT_TENANT_SESSIONS_MODE')

export const updateGeneralFilter = createAction('UPDATE_GENERAL_FILTER')
export const clearGeneralFilter = createAction('CLEAR_GENERAL_FILTER')

export const setTenantContentsList = createAction('SET_TENANT_CONTENTS_LIST')
export const clearTenantContentsList = createAction('CLEAR_TENANT_CONTENTS_LIST')

export const setStartDate = createAction('SET_START_DATE')
export const setEndDate = createAction('SET_END_DATE')

export const loadTenantContents = createAction('LOAD_TENANT_CONTENTS')
export const getTenantContentsRequest = createAction('GET_TENANT_CONTENTS_REQUEST')
export const getTenantContentsSuccess = createAction('GET_TENANT_CONTENTS_SUCCESS')

export const getDeletedTenantContentsRequest = createAction('GET_DELETED_TENANT_CONTENTS_REQUEST')
export const getDeletedTenantContentsSuccess = createAction('GET_DELETED_TENANT_CONTENTS_SUCCESS')

export const setPageNumber = createAction('SET_PAGE_NUMBER')
export const updatePageNumber = createAction('UPDATE_PAGE_NUMBER')
export const setPageSize = createAction('SET_PAGE_SIZE')

export const redirectToSession = createAction('REDIRECT_TO_SESSION')

export const exportTenantSessionsCsv = createAction('EXPORT_TENANT_SESSIONS_CSV')
export const exportTenantSessionsCsvRequest = createAction('EXPORT_TENANT_SESSIONS_CSV_REQUEST')
export const exportTenantSessionsCsvSuccess = createAction('EXPORT_TENANT_SESSIONS_CSV_SUCCESS')

export const setLoadingContent = createAction('SET_LOADING_CONTENT')

export const setSortingColumn = createAction('SET_SORTING_COLUMN')
export const setSortingColumnWithoutReload = createAction('SET_SORTING_COLUMN_WITHOUT_RELOAD')

export const updateFilter = createAction('UPDATE_SESSION_FILTER')

export const handleSessionNameClick = createAction('HANDLE_SESSION_NAME_CLICK')

export const selectTenantContentsDashboard = createAction('SELECT_ALL_CONTENTS_TABLE_DASHBOARD')
export const getTenantContentsDashboards = createApiActions('GET_TENANT_CONTENTS_DASHBOARDS')

export const getTenantCustomColumns = createApiActions('GET_TENANT_CUSTOM_COLUMNS')
export const resetTenantCustomColumnState = createAction('RESET_TENANT_CUSTOM_COLUMNS_STATE')
export const getTenantPrimaryColumns = createApiActions('GET_TENANT_PRIMARY_COLUMNS')
export const createDashboard = createApiActions('CREATE_DASHBOARD')

export const setDashboardAsDefault = createApiActions('SET_DASHBOARD_AS_DEFAULT')
export const setDefaultDashboard = createAction('SET_DEFAULT_DASHBOARD')
export const setTableColumns = createAction('SET_TABLE_COLUMNS')

export const resetDefaultDashboard = createApiActions('RESET_DEFAULT_DASHBOARD')
export const deleteDashboard = createApiActions('DELETE_DASHBOARD')
export const updateDashboard = createApiActions('UPDATE_DASHBOARD')
export const getTenantContentsDashboardColumns = createApiActions('GET_TENANT_CONTENTS_DASHBOARD_COLUMNS')

export const initTenantContentsPage = createAction('INIT_TENANT_CONTENTS_PAGE')
