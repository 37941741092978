import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getContentTrainingMaterials } from '../actions'

const EMPTY_LIST = {
  Items: [],
  TotalCount: 0,
}

export default ({ enabled, skip = 0, take = 50, search = null, contentId, onError }) => {
  const payload = useMemo(
    () => ({
      skip,
      take,
      search,
      contentId,
    }),
    [contentId, search, skip, take]
  )

  const {
    loading,
    loaded,
    response: { Items: contentTrainingMaterials, TotalCount: totalCount },
    onMutated,
  } = useApiRequest({
    request: getContentTrainingMaterials.request,
    defaultResponse: EMPTY_LIST,
    enabled,
    payload,
    onError,
  })

  return { loading, loaded, totalCount, contentTrainingMaterials, onReload: onMutated }
}
