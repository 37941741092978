import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  tenantCardHeaderRoot: {
    padding: theme.spacing(1),
  },
  tenantCardHeaderAction: {
    marginTop: 0,
    marginRight: 0,
  },
}))
