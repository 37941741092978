import { styled } from '@mui/material'
import { ColumnFlexContainer } from '@tabeeb/uikit'

export const Container = styled(ColumnFlexContainer)(({ theme }) => ({
  padding: theme.spacing(),
  border: '1px solid lightgrey',
  borderRadius: 8,
  '& svg': { height: 30 },
}))

export const Title = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  color: '#616161',
}))

export const SignatureValue = styled('span')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 30,
}))
