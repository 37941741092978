import PropTypes from 'prop-types'

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { AssignmentLate, AssignmentTurnedIn } from '@mui/icons-material'

const ContentTrainingMaterialListItem = ({ children, disabled, item, onDoubleClick }) => {
  return (
    <ListItemButton key={item.Id} disabled={disabled} disableTouchRipple onDoubleClick={() => onDoubleClick(item)}>
      <ListItemIcon>
        {item.Assigned ? (
          item.Read ? (
            <AssignmentTurnedIn color='success' fontSize='medium' />
          ) : (
            <AssignmentLate color='primary' fontSize='medium' />
          )
        ) : (
          <AssignmentLate color='disabled' fontSize='medium' />
        )}
      </ListItemIcon>

      <ListItemText
        primary={item.TrainingMaterial.Name}
        primaryTypographyProps={{ variant: 'subtitle2', title: item.TrainingMaterial.Name, noWrap: true }}
        secondary={item.Assigned ? (item.Read ? 'Read' : 'Unread') : 'Not assigned'}
        secondaryTypographyProps={{
          variant: 'body2',
          fontWeight: 500,
          color: item.Assigned ? (item.Read ? (theme) => theme.palette.success.main : 'primary') : 'text.secondary',
          display: 'flex',
          alignItems: 'center',
          noWrap: true,
        }}
      />

      {children}
    </ListItemButton>
  )
}

ContentTrainingMaterialListItem.defaultProps = {
  disabled: false,
  onDoubleClick: () => {},
}

ContentTrainingMaterialListItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  item: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Assigned: PropTypes.bool.isRequired,
    Read: PropTypes.bool.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    TrainingMaterial: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onDoubleClick: PropTypes.func,
}

export default ContentTrainingMaterialListItem
