import { memo, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, List, ListItem } from '@mui/material'

import { UserInfo } from '@tabeeb/uikit'
import { useUsers } from '@tabeeb/modules/../users/hooks'
import { useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'
import { switchSelectedSessionsReviewer } from '@tabeeb/modules/sessionsPage/actions'

const SwitchSessionsReviewerDialog = ({ open, sessions, onClose }) => {
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState('')
  const debouncedSearchValue = useDebouncedValue(searchText, 500)

  const { users } = useUsers({ search: debouncedSearchValue })

  const onSwitchReviewer = useCallback(
    (user) => {
      dispatch(switchSelectedSessionsReviewer({ newReviewer: user }))

      onClose()
    },
    [dispatch, onClose]
  )

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <DialogTitle>Switch reviewer</DialogTitle>
      <DialogContent>
        <TextField
          size='small'
          fullWidth
          placeholder='Search name or email...'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <List dense disablePadding>
          {users.map((user) => (
            <ListItem key={user.Id} dense disableGutters>
              <UserInfo item={user} />
              <Button
                title='Make a reviewer'
                size='small'
                color='primary'
                variant='contained'
                onClick={() => onSwitchReviewer(user)}
              >
                Make a reviewer
              </Button>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

SwitchSessionsReviewerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(SwitchSessionsReviewerDialog)
