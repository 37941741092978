import { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { useDropzone } from 'react-dropzone'
import { Box, IconButton, Typography } from '@mui/material'
import { CloseOutlined, CloudUploadOutlined } from '@mui/icons-material'

const FormikFileField = ({ accept, color, field, form, variant, height }) => {
  const { setFieldValue } = useFormikContext()

  const onDrop = useCallback(
    (files) => {
      setFieldValue(field.name, files[0])
    },
    [field.name, setFieldValue]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept, onDrop })

  return (
    <Box position='relative'>
      {!field.value ? (
        <Box
          {...getRootProps()}
          height={height}
          borderRadius={1}
          border='1px dashed rgb(97, 97, 97)'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <input {...getInputProps()} />
          <CloudUploadOutlined fontSize='large' />
          <Typography variant='body1'>
            {isDragActive ? 'Drop the file here ...' : 'Drag and drop file here, or click to select file'}
          </Typography>
        </Box>
      ) : (
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='body1' noWrap>
            {field.value.name}
          </Typography>
          <IconButton size='small' title='Clear' onClick={() => setFieldValue(field.name, null)}>
            <CloseOutlined />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

FormikFileField.defaultProps = {
  accept: '*',
  color: 'primary',
  variant: 'outlined',
  height: 386,
}

FormikFileField.propTypes = {
  accept: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  height: PropTypes.number,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(File),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.instanceOf(Object).isRequired,
    touched: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
}

export default FormikFileField
