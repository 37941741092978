import { put, takeLatest, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { isMobileOnly } from 'react-device-detect'

import routes from '@tabeeb/routes'
import { notificationActions } from '../../notification'
import * as userSettingsActions from '../actions'
import { getIsUserAuthorized } from '../selectors'
import * as sessionActions from '../../../state/actions/sessionActions'

function* updateAccountSuccess() {
  const message = 'Account changed successfully.'
  yield put(notificationActions.onAddSuccessNotification({ message }))
  yield put(userSettingsActions.getMeRequest())
}

function* updateAccountFailed({ response }) {
  const message =
    typeof response.data === 'string' ? response.data : 'The account info is not updated. Please, try later.'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* updateAvatarSuccess({ response }) {
  const message = 'Profile photo has been changed successfully'
  yield put(notificationActions.onAddSuccessNotification({ message }))
}

function* updateAvatarFailed() {
  const message = 'Invalid image.'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

export function* fetchAccountSettings() {
  const isLoggedIn = yield select(getIsUserAuthorized)
  if (!isLoggedIn) {
    return
  }

  yield put(userSettingsActions.getMeRequest())
}

function* deleteAccountSuccess(action) {
  yield put(notificationActions.onAddSuccessNotification({ message: 'Profile deleted successfully' }))
  yield put(userSettingsActions.onLogout())
}

function* deleteAccountFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to delete profile' }))
}

function* userSettingsSaga() {
  yield all([
    takeLatest(userSettingsActions.updateAccountSuccess, updateAccountSuccess),
    takeLatest(userSettingsActions.updateAccountFailed, updateAccountFailed),
    takeLatest(userSettingsActions.deleteAccountSuccess, deleteAccountSuccess),
    takeLatest(userSettingsActions.deleteAccountFailed, deleteAccountFailed),
    takeLatest([userSettingsActions.setAvatarSuccess, userSettingsActions.removeAvatarSuccess], updateAvatarSuccess),
    takeLatest([userSettingsActions.setAvatarFailed, userSettingsActions.removeAvatarFailed], updateAvatarFailed),
    takeLatest(sessionActions.onSetsessionSate, fetchAccountSettings),
  ])
}

export default userSettingsSaga
