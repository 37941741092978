import { ProjectType, AnnotationSource, IterationType, IterationStatus } from '@tabeeb/enums'
import { ObjectDetectorType } from '../enums'

export * from './trainingSettings'

export * from './charts'

export const isAIObjectsSelectionSupported = (projectType) => {
  switch (projectType) {
    case ProjectType.ImageClassification:
    case ProjectType.ObjectDetection:
      return true
    default:
      return false
  }
}

export const isFormSelectionSupported = (projectType) => {
  switch (projectType) {
    case ProjectType.VQA:
      return true
    default:
      return false
  }
}

export const convertToIterationType = (annotationSource, { ObjectDetector }) => {
  switch (annotationSource) {
    case AnnotationSource.ImageAndQuestionPairs:
      return IterationType.FineTuneCLIP
    case AnnotationSource.ImageAndNlpTagSets:
      return IterationType.ImageNLPClassificationWithPretrainedEncoder
    case AnnotationSource.ImageAndClassificationAnnotations:
      return IterationType.ClassificationWithPretrainedEncoder
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
      switch (ObjectDetector) {
        case ObjectDetectorType.YoloXSmall:
        case ObjectDetectorType.YoloXMedium:
        case ObjectDetectorType.YoloXLarge:
          return IterationType.ObjectDetectionYolox
        default:
          return IterationType.ObjectDetection
      }
    case AnnotationSource.ImageAndPolygonAnnotations:
      return IterationType.InstanceSegmentation
    default:
      throw new Error(`Unknown annotationSource: ${annotationSource}`)
  }
}

export const getIncludesByAnnotationSource = (annotationSource) => {
  const result = {
    IncludeTags: false,
    IncludeFormControls: false,
    IncludeAIClassificationAnnotations: false,
    IncludeAIBoundingBoxAnnotations: false,
    IncludeAIPolygonAnnotations: false,
  }

  switch (annotationSource) {
    case AnnotationSource.ImageAndQuestionPairs:
      result.IncludeFormControls = true
      break
    case AnnotationSource.ImageAndNlpTagSets:
      result.IncludeTags = true
      break
    case AnnotationSource.ImageAndClassificationAnnotations:
      result.IncludeAIClassificationAnnotations = true
      result.IncludeAIBoundingBoxAnnotations = true
      result.IncludeAIPolygonAnnotations = true
      break
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
      result.IncludeAIBoundingBoxAnnotations = true
      break
    case AnnotationSource.ImageAndPolygonAnnotations:
      result.IncludeAIPolygonAnnotations = true
      break
    default:
  }

  return result
}

export const getIsIterationCompleted = (iterationStatus) => {
  return iterationStatus === IterationStatus.Completed
}

export const convertSettingTypeToOptions = (arrayOrEnum, displayNameEnum) => {
  const typeValues = Array.isArray(arrayOrEnum) ? arrayOrEnum : Object.values(arrayOrEnum)

  return typeValues.map((typeValue) => ({
    id: typeValue,
    value: displayNameEnum[typeValue],
  }))
}

export const defaultPagesStatisticsModel = {
  Id: null,
  Name: null,

  PagesCountAnnotationBbox: 0,
  PagesCountAnnotationPolygon: 0,
  PagesCountAnnotationClassification: 0,
  PagesCountAnnotationAll: 0,

  ItemsCountAnnotationBbox: 0,
  ItemsCountAnnotationPolygon: 0,
  ItemsCountAnnotationClassification: 0,
  ItemsCountAnnotationAll: 0,

  PagesCountFormQuestions: 0,
  PagesCountNlpTags: 0,
  ItemsCountFormQuestions: 0,
  ItemsCountNlpTags: 0,
}
