import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    avatar: {
      height: 24,
      width: 24,
      marginRight: theme.spacing(0.75),
    },
    icon: {
      fontSize: theme.typography.fontSize,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      marginTop: theme.spacing(0.75),
      marginBottom: theme.spacing(2),
    },
    chartsGrid: {
      marginTop: theme.spacing(1.25),
    },
  }
})
