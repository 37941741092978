import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Stage, Layer } from 'react-konva'
import { Html } from 'react-konva-utils'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { createUserSignature } from '@tabeeb/modules/forms/actions'
import { createSvgFromString } from '@tabeeb/modules/forms/services/svgHelper'
import { UserSignatureType } from '@tabeeb/enums'
import { SignatureInput } from './styles'

const ManageSignatureDialog = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const currentUserId = useSelector(getCurrentUserId)
  const [text, setText] = useState('')

  const _handleChange = (e) => {
    setText(e.target.value)
  }

  const _handleSave = () => {
    const svg = createSvgFromString(text)
    const data = {
      UserId: currentUserId,
      Value: svg,
      Type: UserSignatureType.Text,
    }

    dispatch(createUserSignature(data))
    onClose()
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle sx={{ paddingBottom: 4, color: '#616161' }}>Create signature</DialogTitle>
      <DialogContent>
        <Stage width={300} height={40}>
          <Layer>
            <Html>
              <SignatureInput
                type='text'
                value={text}
                placeholder='Type your name'
                maxLength={30}
                onChange={_handleChange}
              />
            </Html>
          </Layer>
        </Stage>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='primary' disabled={text.length < 1} onClick={_handleSave}>
          Save and sign form
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ManageSignatureDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ManageSignatureDialog
