import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setContentsForSharing } from '@tabeeb/modules/contentSharings/actions'
import { getContentSearchState } from '@tabeeb/modules/contentSharings/selectors'
import { SearchResultRowBtn } from '@tabeeb/modules/contentSharings/constants/enums'
import ContentRow from './contentRow'

const SearchContentResult = () => {
  const dispatch = useDispatch()
  const { contentsList } = useSelector(getContentSearchState)

  const _handleContentClick = useCallback(
    (content) => {
      dispatch(setContentsForSharing([content]))
    },
    [dispatch]
  )

  return contentsList.map((content) => (
    <ContentRow
      content={content}
      button={SearchResultRowBtn.Select}
      handleButtonClick={_handleContentClick}
      key={content.Id}
    />
  ))
}

export default memo(SearchContentResult)
