import { createSelector } from 'reselect'

import { selectUsersFilterDeletedUserIds } from '@tabeeb/modules/forms/selectors'

import { UserRole, AnswerStatusesGroups } from '@tabeeb/enums'

import { getUsersListForCurrentUser } from '@tabeeb/modules/../users/selectors'

import { FilterDefaultValues, FilterTypes } from '../constants'

export const selectUsersFilter = createSelector(
  [getUsersListForCurrentUser, selectUsersFilterDeletedUserIds],
  (usersList, deletedUserIds) => {
    return usersList.filter((user) =>
      user.isDeleted ? deletedUserIds.includes(user.id) || user.role === UserRole.AIUser : true
    )
  }
)

export const getPageAnswersListLoadingState = (state) => state.discussion.pageAnswersDiscussion
export const getPageAnswersDialogOpen = (state) => state.discussion.pageAnswersDiscussion.open
export const getPageAnswersList = (state) => state.discussion.pageAnswersDiscussion.list
export const getPageAnswersFilters = createSelector(
  [(state) => state.discussion.pageAnswersDiscussion.filters, selectUsersFilter],
  (filters, users) => {
    const userExists = users.some((user) => user.id === filters[FilterTypes.ByUser])
    return userExists
      ? filters
      : {
          ...filters,
          [FilterTypes.ByUser]: FilterDefaultValues[FilterTypes.ByUser],
        }
  }
)
export const getDiscussionPage = (state) => state.discussion.pageAnswersDiscussion.page

export const getQuestionAnswersListLoadingState = (state) => state.discussion.questionAnswersDiscussion
export const getQuestionAnswersList = (state) => state.discussion.questionAnswersDiscussion.list
export const getQuestionAnswersFilters = createSelector(
  [(state) => state.discussion.questionAnswersDiscussion.filters, selectUsersFilter],
  (filters, users) => {
    const userExists = users.some((user) => user.id === filters[FilterTypes.ByUser])
    return userExists
      ? filters
      : {
          ...filters,
          [FilterTypes.ByUser]: FilterDefaultValues[FilterTypes.ByUser],
        }
  }
)
export const getQuestionAnswersDialogOpen = (state) => state.discussion.questionAnswersDiscussion.open
export const getDiscussionQuestion = (state) => state.discussion.questionAnswersDiscussion.question

const userIdFilter = (userId) => (item) => userId === -1 || item.UserId === userId
const formIdFilter = (formId) => (item) => formId === -1 || item.FormId === formId
const statusesFilter =
  (statuses = []) =>
  (item) =>
    statuses.length === 0 ||
    statuses
      .map((status) => AnswerStatusesGroups[status])
      .flat()
      .includes(item.FormAnswerStatus)

export const getFilteredPageAnswers = createSelector(
  [getPageAnswersList, getPageAnswersFilters],
  (answers, filters) => {
    const { userId, formId, statuses } = filters

    const filtered = answers.filter(userIdFilter(userId)).filter(formIdFilter(formId)).filter(statusesFilter(statuses))

    return filtered
  }
)

export const getFilteredQuestionAnswers = createSelector(
  [getQuestionAnswersList, getQuestionAnswersFilters],
  (answers, filters) => {
    const { userId, statuses } = filters

    const filtered = answers.filter(userIdFilter(userId)).filter(statusesFilter(statuses))

    return filtered
  }
)
