import PropTypes from 'prop-types'

import { DeleteOutlined } from '@mui/icons-material'
import { InputAdornment, IconButton } from '@mui/material'

const EndAdornmentRemove = ({ onClick }) => {
  const handleMouseDown = (event) => {
    event.preventDefault()
  }

  return (
    <InputAdornment position='end'>
      <IconButton onClick={onClick} onMouseDown={handleMouseDown} edge='end'>
        <DeleteOutlined color='error' />
      </IconButton>
    </InputAdornment>
  )
}

EndAdornmentRemove.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EndAdornmentRemove
