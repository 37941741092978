import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    paper: {
      borderRadius: theme.shape.borderRadius * 2,
      minWidth: 150,
      maxWidth: 200,
    },
  }
})
