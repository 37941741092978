import { combineReducers } from 'redux'
import isAvailableTenantFormsDialogOpen from './isAvailableTenantFormsDialogOpen'
import tenantForms from './tenantForms'
import loadingFormsForTenant from './loadingFormsForTenant'
import formsForTenant from './formsForTenant'
import tenantFormsDialogState from './tenantFormsDialogState'
import openedTenantForms from './openedTenantForms'
import tenantFormAnswers from './tenantFormAnswers'
import availableTenantFormsSearchText from './availableTenantFormsSearchText'
import foundTenantForms from './foundTenantForms'
import currentFolder from './currentFolder'
import foldersList from './foldersList'

export default combineReducers({
  isAvailableTenantFormsDialogOpen,
  tenantForms,
  loadingFormsForTenant,
  formsForTenant,
  tenantFormsDialogState,
  openedTenantForms,
  tenantFormAnswers,
  availableTenantFormsSearchText,
  foundTenantForms,
  currentFolder,
  foldersList,
})
