import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Menu, MenuItem, Button } from '@material-ui/core'
import { isMobileOnly } from 'react-device-detect'

import { useDialogState } from '@tabeeb/shared/utils/hooks'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'
import SwitchTenantContainer from '@tabeeb/modules/account/containers/SwitchTenantContainer'

import { getIsSwitchTenantFeatureEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { useSelector } from 'react-redux'
import Notifications from '../Notifications'

import useStyles from './styles'

const User = ({
  handleLogout,
  handleOpenCalendar,
  handleOpenUserSettings,
  isCalendarEnabled,
  textColor,
  backgroundColor,
  disabled,
  currentUser,
}) => {
  const classes = useStyles()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(undefined)
  const [isSwitchTenantOpen, onSwitchTenantOpen, onSwitchTenantClose] = useDialogState()
  const isSwitchTenantFeatureEnabled = useSelector(getIsSwitchTenantFeatureEnabled)

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget)
    setIsMenuOpen(true)
  }

  const handleClose = () => {
    setIsMenuOpen(false)
  }

  const handleSelect = (action) => (e) => {
    handleClose()
    action(e)
  }

  const handleHelpClick = () => {
    const link = `https://talonsupport.freshdesk.com/support/home`
    window.open(link, '_blank')
  }

  const commonStyles = {
    color: textColor,
    minWidth: 160,
  }

  const paperProps = {
    style: isMobileOnly ? {} : { backgroundColor },
  }

  return (
    <>
      <Box className={classes.root} title={currentUser.Name}>
        {!disabled && !isMobileOnly && <Notifications />}
        <Button className={classes.button} disabled={disabled} onClick={handleOpen}>
          <UserAvatar user={currentUser} />
        </Button>
        <Menu
          className={classes.menu}
          PaperProps={paperProps}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
        >
          {isCalendarEnabled && (
            <MenuItem style={commonStyles} onClick={handleSelect(handleOpenCalendar)}>
              Calendar
            </MenuItem>
          )}
          {!isMobileOnly && (
            <MenuItem style={commonStyles} onClick={handleSelect(handleOpenUserSettings)}>
              Profile
            </MenuItem>
          )}
          <MenuItem style={commonStyles} onClick={handleHelpClick}>
            Need Help?
          </MenuItem>
          {isMobileOnly && isSwitchTenantFeatureEnabled && (
            <MenuItem style={commonStyles} onClick={handleSelect(onSwitchTenantOpen)}>
              Switch tenant
            </MenuItem>
          )}
          <MenuItem style={commonStyles} onClick={handleSelect(handleLogout)}>
            Log out
          </MenuItem>
        </Menu>
      </Box>
      {isSwitchTenantFeatureEnabled && (
        <SwitchTenantContainer open={isSwitchTenantOpen} onClose={onSwitchTenantClose} dialog />
      )}
    </>
  )
}

User.propTypes = {
  currentUser: PropTypes.shape({
    AvatarUrl: PropTypes.string,
    Name: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  isCalendarEnabled: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleOpenCalendar: PropTypes.func.isRequired,
  handleOpenUserSettings: PropTypes.func.isRequired,
}

export default User
