import { handleActions, combineActions } from 'redux-actions'

import * as aiActions from '../actions'

const defaultState = []

function _markAsLoaded(model) {
  return { ...model, IsLoaded: true }
}

function _addOrUpdateNlpModel(state, nlpModel) {
  const index = state.findIndex((nlpM) => nlpM.Id === nlpModel.Id)
  let result
  if (index === -1) {
    result = [...state, nlpModel]
  } else {
    result = [...state]
    result[index] = nlpModel
  }
  return result
}

export default handleActions(
  {
    [aiActions.getNlpModels.success](state, { response }) {
      return response.data.map((nlpModel) => _markAsLoaded(nlpModel))
    },
    [combineActions(aiActions.updateNlpModel.success, aiActions.addNlpModel.success)](state, { response }) {
      return _addOrUpdateNlpModel(state, response.data)
    },
    [aiActions.deleteNlpModel.success](state, { payload: { nlpModelId } }) {
      return state.filter((nlpModel) => nlpModel.Id !== nlpModelId)
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
