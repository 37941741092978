import { createAction } from 'redux-actions'

export const resetTenantPermissions = createAction('RESET_TENANT_PERMISSIONS')
export const resetSessionPermissions = createAction('RESET_SESSION_PERMISSIONS')
export const resetFolderPermissions = createAction('RESET_FOLDER_PERMISSIONS')

export const getTenantPermissionsRequest = createAction('GET_TENANT_PERMISSIONS_REQUEST')
export const getTenantPermissionsSuccess = createAction('GET_TENANT_PERMISSIONS_SUCCESS')
export const getTenantPermissionsFailed = createAction('GET_TENANT_PERMISSIONS_FAILED')

export const setSessionPermissions = createAction('SET_SESSION_PERMISSIONS')

export const getSessionPermissionsRequest = createAction('GET_SESSION_PERMISSIONS_REQUEST')
export const getSessionPermissionsSuccess = createAction('GET_SESSION_PERMISSIONS_SUCCESS')
export const getSessionPermissionsFailed = createAction('GET_SESSION_PERMISSIONS_FAILED')

export const getFolderPermissionsRequest = createAction('GET_FOLDER_PERMISSIONS_REQUEST')
export const getFolderPermissionsSuccess = createAction('GET_FOLDER_PERMISSIONS_SUCCESS')
export const getFolderPermissionsFailed = createAction('GET_FOLDER_PERMISSIONS_FAILED')
