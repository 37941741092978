import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setDeleteVqaModel](state, action) {
      return action.payload
    },
    [rawActions.deleteVqaModel.request](state, action) {
      return null
    },
    [rawActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
