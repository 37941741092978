import { ContentSharingDialogType, ContentSharingDialogStepId, ContentSharingDialogStageId } from '../constants/enums'

const propsTemplate = {
  isOptional: false,
  isCompleted: false,
}

const stages = {
  Destination: { ...propsTemplate, id: ContentSharingDialogStageId.Destination, label: 'Destination' },
}

const steps = {
  SelectContentForms: { ...propsTemplate, label: 'Select forms', id: ContentSharingDialogStepId.SelectContentForms },
  SelectGalleryPages: { ...propsTemplate, label: 'Select pages', id: ContentSharingDialogStepId.SelectGalleryPages },
  SelectTenantDestination: {
    ...propsTemplate,
    label: 'Select tenant destination',
    id: ContentSharingDialogStepId.SelectTenantDestination,
  },
  SelectContentDestinationType: {
    ...propsTemplate,
    label: 'Select content destination type',
    id: ContentSharingDialogStepId.SelectContentDestinationType,
  },
  SelectContentDestination: {
    ...propsTemplate,
    label: 'Select existing content',
    id: ContentSharingDialogStepId.SelectContentDestination,
  },
  SelectUser: { ...propsTemplate, label: 'Select user', id: ContentSharingDialogStepId.SelectUser },
  NotifyReviewers: { ...propsTemplate, label: 'Notify reviewers', id: ContentSharingDialogStepId.NotifyReviewers },
}

const shareForCopyingStages = [
  [{ ...steps.SelectGalleryPages }],
  [{ ...steps.SelectContentForms, isOptional: true }],
  [{ ...steps.SelectTenantDestination }, { ...steps.SelectContentDestinationType }, { ...steps.SelectUser }],
]

const shareForReviewStages = [
  [{ ...steps.SelectContentForms }],
  [{ ...steps.SelectGalleryPages, isOptional: true }],
  [{ ...steps.SelectTenantDestination }, { ...steps.NotifyReviewers }],
]

const requestToLinkContentStages = [
  {
    ...stages.Destination,
    steps: [steps.SelectTenantDestination, steps.SelectUser, steps.SelectContentDestination],
  },
]

export function getContentSharingDialogStages(contentSharingDialogType) {
  switch (contentSharingDialogType) {
    case ContentSharingDialogType.ShareForCopying:
      return shareForCopyingStages
    case ContentSharingDialogType.ShareForReview:
      return shareForReviewStages
    default:
      return requestToLinkContentStages
  }
}
