import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

const OptionsDialog = ({ isOpen, closeDialog, next, setOption, selectedOption, exportOptions }) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Select data you would like to export</DialogTitle>
      <DialogContent>
        <RadioGroup onChange={setOption} value={selectedOption}>
          {exportOptions.map((option) => (
            <FormControlLabel
              key={option.Id}
              value={option.Id}
              label={option.Value}
              control={<Radio color='default' />}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={next} color='primary'>
          Continue
        </Button>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

OptionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  setOption: PropTypes.func.isRequired,
  exportOptions: PropTypes.array.isRequired,
  selectedOption: PropTypes.number.isRequired,
}

export default OptionsDialog
