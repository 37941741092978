import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.setFormsForTenant](state, action) {
      return action.payload
    },
    [combineActions(
      rawActions.resetFormsForTenant,
      rawActions.getAvailableFormsForTenantByFolderIdRequest,
      rawActions.getAvailableFormsForTenantRequest
    )](state, action) {
      return []
    },
  },
  defaultState
)
