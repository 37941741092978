import { memo } from 'react'
import PropTypes from 'prop-types'

import { Divider, Typography } from '@material-ui/core'

import { convertDateToString } from '../../../services/chat'

import useStyles from './styles'

const DateBar = ({ date }) => {
  const classes = useStyles()

  return (
    <li className={classes.container}>
      <Divider className={classes.divider} />
      <Typography className={classes.date} variant='caption' color='textSecondary'>
        {convertDateToString(date)}
      </Typography>
      <Divider className={classes.divider} />
    </li>
  )
}

DateBar.propTypes = {
  date: PropTypes.string.isRequired,
}

export default memo(DateBar)
