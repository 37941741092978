import { memo } from 'react'

import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import useStyles from './styles'

const UserInfoSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Box mr={1}>
        <Skeleton className={classes.avatar} variant='circle' />
      </Box>
      <div className={classes.content}>
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  )
}

export default memo(UserInfoSkeleton)
