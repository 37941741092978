import { connect } from 'react-redux'

import routes from '@tabeeb/routes'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import NavbarTab from '../NavbarTab'

const Geosearch = ({ display, ...rest }) => {
  const props = {
    item: {
      label: Geosearch.label,
      to: Geosearch.to,
    },
    display,
    ...rest,
  }

  return <NavbarTab {...props} />
}

Geosearch.to = routes.geosearch
Geosearch.label = 'Geosearch'

function mapStateToProps(state) {
  return {
    display: appConfigStateSelectors.getIsGeosearchEnabled(state),
  }
}

export default connect(mapStateToProps, null)(Geosearch)
