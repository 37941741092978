import { useCallback, useEffect, useMemo, useState } from 'react'
import { Chip, Grid } from '@mui/material'
import { AssignmentOutlined, Close, Restore } from '@mui/icons-material'
import { getGridSingleSelectOperators, getGridStringOperators, GridActionsCellItem } from '@mui/x-data-grid-pro'
import PropTypes from 'prop-types'

import { DataGrid } from '@tabeeb/uikit'
import useDataGrid from '@tabeeb/modules/shared/useDataGrid'
import { UserAssignedQualificationStatusType, UserAssignedQualificationStatusTypeDisplayName } from '@tabeeb/enums'
import { useDialogState } from '@tabeeb/shared/utils/hooks'
import GridUserListItem from '@tabeeb/modules/certificates/components/GridUserListItem'
import CertificateTypeCustomFilterOperator from '@tabeeb/modules/certificates/components/CertificateTypeCustomFilterOperator'
import UserCustomGridFilterOperator from '@tabeeb/modules/certificates/components/UserCustomGridFilterOperator'
import { userDeletedDisplayText } from '@tabeeb/modules/certificates/constants'
import useUserQualificationsGrid from '../../hooks/useUserQualificationsGrid'
import UserQualificationChip from '../UserQualificationChip'
import AddUserQualificationDialog from '../AddUserQualificationDialog'
import ViewUserQualificationDialog from '../ViewUserQualificationDialog'
import UserQualificationsPageHeader from '../UserQualificationsPageHeader'

const checkBoxFilterOperators = getGridSingleSelectOperators().filter(({ value }) => value === 'isAnyOf')
const assignedToFilterOperator = getGridStringOperators()
  .filter(({ value }) => value === 'contains')
  .map((operator) => ({ ...operator, InputComponent: UserCustomGridFilterOperator }))

const assignerFilterOperator = getGridStringOperators()
  .filter(({ value }) => value === 'contains')
  .map((operator) => ({ ...operator, InputComponent: UserCustomGridFilterOperator }))

const UserQualificationsGrid = ({ dataGridSx, simpleView, user }) => {
  const filterValueFormatter = useCallback((filter) => {
    return filter.field === 'QualificationVersionCertificateTypes'
      ? JSON.stringify(filter.value.typeIds.map((_) => _.Id))
      : filter.field === 'AssignerId' || filter.field === 'AssignedToUserId'
      ? JSON.stringify(filter.value.map((_) => _.IdentityGuid))
      : JSON.stringify(filter.value)
  }, [])
  const {
    setSort,
    filterModel,
    setFilterModel,
    search,
    rows,
    setRows,
    rowCountState,
    setRowCountState,
    paginationModel,
    payload,
    onDefaultErrorMutation,
    onDefaultSuccessMutation,
  } = useDataGrid({ filterValueFormatter })

  const [qualificationToView, setQualificationToView] = useState(null)

  const getUserQualificationsParams = useMemo(
    () => ({
      ...payload,
      ...(Boolean(user?.IdentityGuid) &&
        Boolean(user?.Id) && {
          userGuid: user.IdentityGuid,
          userId: user.Id,
        }),
    }),
    [payload, user?.Id, user?.IdentityGuid]
  )
  const {
    loading,
    abortAll,
    getUserQualificationsResponse,
    onGetUserQualificationsMutated,
    // create
    createUserQualificationMutate,
    // revoke
    revokeUserQualificationMutate,
    // restore
    restoreUserQualificationMutate,
    // get all types
    getAllCertificateTypesResponse,
    getAllCertificateTypesLoading,
    // get approved certificateTypeIds of selected user
    getApprovedCertificateTypeIdsByUserGuidResponse,
    getAllCertificateTypeIdsByUserGuidLoading,
    setCertificateTypesSearch,
    // get users
    loadingGetUsers,
    responseGetUsers,
  } = useUserQualificationsGrid(getUserQualificationsParams, qualificationToView?.AssignedToUserId)

  const certificateTypeCustomFilterOperator = useMemo(
    () => ({
      label: 'types are any of',
      value: 'typeAreAnyOf',
      InputComponent: CertificateTypeCustomFilterOperator,
      InputComponentProps: {
        getAllCertificateTypesResponse,
        loading: getAllCertificateTypesLoading,
        showCategoriesAndScopes: false,
        setCertificateTypesSearch,
      },
    }),
    [getAllCertificateTypesLoading, getAllCertificateTypesResponse, setCertificateTypesSearch]
  )

  useEffect(() => {
    return () => {
      abortAll()
    }
  }, [abortAll])

  useEffect(() => {
    setRows(getUserQualificationsResponse.UserQualifications)
    setRowCountState(getUserQualificationsResponse.TotalCount)
  }, [
    getUserQualificationsResponse.TotalCount,
    getUserQualificationsResponse.UserQualifications,
    setRowCountState,
    setRows,
  ])

  const onSucceededMutation = useCallback(
    (message) => {
      onDefaultSuccessMutation(message)
      onGetUserQualificationsMutated()
    },
    [onDefaultSuccessMutation, onGetUserQualificationsMutated]
  )

  // dialogs
  const [viewDialogOpen, onViewDialogOpen, onViewDialogClose] = useDialogState()

  const [addDialogOpen, onAddDialogOpen, onAddDialogClose] = useDialogState()
  const onAddDialogSubmit = useCallback(
    async (values) => {
      onAddDialogClose()
      const userQualificationToCreate = {
        AssignedToUserGuid: values.AssignedToUserGuid,
        AssignedToUserId: values.AssignedToUserId,
        QualificationVersionId: Number(values.QualificationVersionId),
      }

      await createUserQualificationMutate(userQualificationToCreate, {
        onSuccess: () => onSucceededMutation('User qualification was successfully created'),
        onError: () => onDefaultErrorMutation('Failed to create user qualification'),
      })
    },
    [createUserQualificationMutate, onAddDialogClose, onDefaultErrorMutation, onSucceededMutation]
  )

  // actions
  const handleRestoreClick = useCallback(
    async (row) => {
      await restoreUserQualificationMutate(
        {
          userQualificationId: row.Id,
          userGuid: row.AssignedToUserId,
        },
        {
          onSuccess: () => onSucceededMutation('Qualification was successfully restored'),
          onError: () => onDefaultErrorMutation('Failed to restore qualification'),
        }
      )
    },
    [onDefaultErrorMutation, onSucceededMutation, restoreUserQualificationMutate]
  )

  const handleRevokeClick = useCallback(
    async (row) => {
      await revokeUserQualificationMutate(
        {
          userQualificationId: row.Id,
        },
        {
          onSuccess: () => onSucceededMutation('Qualification was successfully revoked'),
          onError: () => onDefaultErrorMutation('Failed to revoke qualification'),
        }
      )
    },
    [onDefaultErrorMutation, onSucceededMutation, revokeUserQualificationMutate]
  )

  const handleViewClick = useCallback(
    (entityToView) => {
      setQualificationToView(entityToView)
      onViewDialogOpen()
    },
    [onViewDialogOpen]
  )

  return (
    <UserQualificationsPageHeader
      loading={loading}
      onGetUserQualificationsMutated={onGetUserQualificationsMutated}
      isHidden={simpleView}
      onAddDialogOpen={onAddDialogOpen}
    >
      <DataGrid
        sx={dataGridSx}
        setSort={setSort}
        paginationModel={paginationModel}
        filterModel={filterModel}
        gridRows={rows}
        loading={loading}
        search={search}
        rowCountState={rowCountState}
        setFilterModel={setFilterModel}
        disableColumnSelector
        disableSearch
        valueInputSxProps={{ minWidth: '190px', width: 'unset' }}
        columnVisibilityModel={{
          ...(user?.IdentityGuid && { AssignedToUserId: false }),
        }}
        DataGridProProps={{
          onCellDoubleClick: (params) => handleViewClick(params.row),
        }}
        dialogComponent={
          <>
            <ViewUserQualificationDialog
              open={viewDialogOpen}
              onClose={onViewDialogClose}
              onExited={() => setQualificationToView(null)}
              selectedQualification={qualificationToView}
              getApprovedCertificateTypeIdsByUserGuidResponse={getApprovedCertificateTypeIdsByUserGuidResponse}
              getAllCertificateTypeIdsByUserGuidLoading={getAllCertificateTypeIdsByUserGuidLoading}
              assignedUser={responseGetUsers.find((_) => _.IdentityGuid === qualificationToView?.AssignedToUserId)}
              assignerUser={responseGetUsers.find((_) => _.IdentityGuid === qualificationToView?.AssignerId)}
              loadingGetUsers={loadingGetUsers}
            />
            <AddUserQualificationDialog open={addDialogOpen} onClose={onAddDialogClose} onSubmit={onAddDialogSubmit} />
          </>
        }
        gridColumns={[
          {
            field: 'AssignedToUserId',
            headerName: 'Assigned to',
            sortable: false,
            ...(user?.IdentityGuid && { filterable: false }),
            renderCell: (params) => {
              const assignedUser = responseGetUsers.find((_) => _.IdentityGuid === params.row.AssignedToUserId)
              const primaryText = assignedUser?.Name ?? userDeletedDisplayText
              return (
                <GridUserListItem
                  title={primaryText}
                  secondaryText={assignedUser?.Email}
                  primaryText={primaryText}
                  usersLoading={loadingGetUsers}
                />
              )
            },
            filterOperators: assignedToFilterOperator,
            flex: 1,
          },
          {
            field: 'AssignerId',
            headerName: 'Assigner',
            sortable: false,
            renderCell: (params) => {
              const assignerUser = responseGetUsers.find((_) => _.IdentityGuid === params.row.AssignerId)
              const primaryText = assignerUser?.Name ?? userDeletedDisplayText
              return (
                <GridUserListItem
                  title={primaryText}
                  secondaryText={assignerUser?.Email}
                  primaryText={primaryText}
                  usersLoading={loadingGetUsers}
                />
              )
            },
            filterOperators: assignerFilterOperator,
            flex: 1,
          },
          {
            field: 'StatusId',
            headerName: 'Status',
            sortable: false,
            type: 'singleSelect',
            valueOptions: Object.values(UserAssignedQualificationStatusType).map((value) => ({
              value,
              label: UserAssignedQualificationStatusTypeDisplayName[value],
            })),
            width: 125,
            filterOperators: checkBoxFilterOperators,
            renderCell: ({ row }) => <UserQualificationChip statusId={row.StatusId} />,
          },
          {
            field: 'QualificationVersionName',
            headerName: 'Qualification version',
            sortable: false,
            filterable: false,
            flex: 1,
          },
          {
            field: 'QualificationVersionCertificateTypes',
            headerName: 'Certificate types',
            sortable: false,
            renderCell: ({ row }) => {
              const limitTags = 1
              const numberOfTags = row.QualificationVersionCertificateTypes.length
              return (
                <Grid container direction='row' spacing={1} alignItems='center' wrap='nowrap'>
                  {row.QualificationVersionCertificateTypes.slice(0, limitTags).map((el) => (
                    <Grid item key={el.Id}>
                      <Chip size='small' label={el.Name} sx={{ maxWidth: '150px !important' }} />
                    </Grid>
                  ))}
                  {numberOfTags > limitTags && (
                    <Grid item>
                      <span>{`+${numberOfTags - limitTags}`}</span>
                    </Grid>
                  )}
                </Grid>
              )
            },
            filterOperators: [certificateTypeCustomFilterOperator],
            flex: 1,
          },
          {
            field: 'QualificationVersionExpirationDate',
            headerName: 'Valid till',
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => (value ? value.toLocaleDateString('en-US') : 'Unlimited'),
            filterable: false,
            width: 90,
          },
          {
            field: 'actions',
            type: 'actions',
            width: 70,
            getActions: ({ id, row }) => [
              ...[
                <GridActionsCellItem
                  icon={<AssignmentOutlined />}
                  title='View details'
                  label='View details'
                  onClick={() => handleViewClick(row)}
                />,
              ],
              ...(!simpleView && row.StatusId === UserAssignedQualificationStatusType.Revoked
                ? [
                    <GridActionsCellItem
                      icon={<Restore />}
                      title='Restore'
                      label='Restore'
                      onClick={() => handleRestoreClick(row)}
                    />,
                  ]
                : []),
              ...((!simpleView && row.StatusId === UserAssignedQualificationStatusType.Pending) ||
              row.StatusId === UserAssignedQualificationStatusType.Active
                ? [
                    <GridActionsCellItem
                      icon={<Close />}
                      title='Revoke'
                      label='Revoke'
                      onClick={() => handleRevokeClick(row)}
                    />,
                  ]
                : []),
            ],
          },
        ]}
      />
    </UserQualificationsPageHeader>
  )
}

UserQualificationsGrid.defaultProps = {
  simpleView: false,
}

UserQualificationsGrid.propTypes = {
  simpleView: PropTypes.bool,
  user: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IdentityGuid: PropTypes.string.isRequired,
  }),
  dataGridSx: PropTypes.shape({
    minWidth: PropTypes.string,
  }),
}

export default UserQualificationsGrid
