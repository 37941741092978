import { Link } from 'react-router-dom'

import { Assignment } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import Routes from '@tabeeb/routes'
import { trainingMaterialTitleFormatter } from '@tabeeb/modules/shared/utils/text'

const NoTrainingMaterialsFoundPlaceholder = () => {
  return (
    <Box display='flex' height='100%' alignItems='center' justifyContent='center' flexGrow={1}>
      <Assignment fontSize='large' />
      <Box ml={1.5}>
        <Typography variant='body1' fontWeight={500}>
          {trainingMaterialTitleFormatter.format('No training materials found...')}
        </Typography>

        <Link to={Routes.trainingMaterials} target='_blank'>
          <Typography variant='body2' color='text.secondary'>
            {trainingMaterialTitleFormatter.format('Click here to open all training materials page')}
          </Typography>
        </Link>
      </Box>
    </Box>
  )
}

export default NoTrainingMaterialsFoundPlaceholder
