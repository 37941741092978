import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setPageLayout } from '../actions'
import { getLayout } from '../selectors'

export default () => {
  const dispatch = useDispatch()
  const layout = useSelector(getLayout)

  const setLayout = useCallback(
    (newLayout) => {
      dispatch(setPageLayout(newLayout))
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      layout,
      setLayout,
    }),
    [layout, setLayout]
  )
}
