export default (theme) => {
  return {
    name: {
      fontWeight: 500,
      marginBottom: theme.spacing(),
    },
    text: {
      wordBreak: 'break-word',
    },
    time: {
      fontSize: 9,
    },
    avatar: {
      marginRight: 15,
    },
    content: {
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      marginBottom: theme.spacing() * 2,
      alignItems: 'flex-start',
    },
  }
}
