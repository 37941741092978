import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { MenuItem, Select, FormControl } from '@mui/material'

import { FolderUserRole } from '@tabeeb/enums'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

const FolderUserRoleSelect = ({ disabled, role: selectedRole, onChange, userId }) => {
  const availableRoles = [FolderUserRole.User, FolderUserRole.Viewer]

  const isSessionGuestRoleEnabled = useSelector(appConfigStateSelectors.getIsSessionGuestRoleEnabled)
  if (isSessionGuestRoleEnabled) {
    availableRoles.push(FolderUserRole.SessionGuest)
  }

  const roles = useSelector((state) =>
    availableRoles.map((role) => ({
      role,
      displayName: appConfigStateSelectors.getFolderRoleDisplayName(state, { Role: role }),
    }))
  )

  return (
    <FormControl disabled={disabled}>
      <Select
        value={selectedRole}
        size='small'
        onChange={onChange}
        disableUnderline
        SelectDisplayProps={{ style: { paddingBottom: 0, maxWidth: 200, paddingRight: 24 } }}
        variant='standard'
      >
        {roles.map(({ role, displayName }) => (
          <MenuItem key={role} value={role}>
            {displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

FolderUserRoleSelect.defaultProps = {
  disabled: false,
}

FolderUserRoleSelect.propTypes = {
  disabled: PropTypes.bool,
  role: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  userId: PropTypes.number,
}

export default memo(FolderUserRoleSelect)
