import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { getCanAddAnnotationsOnSelectedPage } from '../../selectors'

const AnnotationControls = ({ children }) => {
  const display = useSelector(getCanAddAnnotationsOnSelectedPage)
  if (!display) {
    return null
  }

  return children
}

AnnotationControls.propTypes = {
  children: PropTypes.any.isRequired,
}

export default memo(AnnotationControls)
