import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { SatelliteOutlined } from '@material-ui/icons'

import { toggleSourcePagesNavigation } from '@tabeeb/modules/pointCloud/actions'

import PageInfoControl from '../PageInfoControl'

const PointCloudSourcePagesNavigationButton = () => {
  const dispatch = useDispatch()

  const onToggle = useCallback(() => {
    dispatch(toggleSourcePagesNavigation())
  }, [dispatch])

  return <PageInfoControl icon={SatelliteOutlined} title='Toggle source images navigation' onClick={onToggle} />
}

export default memo(PointCloudSourcePagesNavigationButton)
