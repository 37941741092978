import { combineActions, handleActions } from 'redux-actions'
import * as actions from '../../actions'

const defaultState = []

export default handleActions(
  {
    [actions.setContentUsersPageFolderPermissions](state, { payload }) {
      return payload
    },
    [combineActions(actions.resetGalleryState, actions.closeEditFolderDialog)]() {
      return defaultState
    },
  },
  defaultState
)
