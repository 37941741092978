import { memo } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, ListItemText } from '@material-ui/core'

const GalleryMenuItem = ({ menuItem, handleMenuClick }) => {
  return menuItem.isVisible ? (
    <MenuItem
      onClick={menuItem.disabled ? null : () => handleMenuClick(menuItem.menuItemType)}
      disabled={menuItem.disabled}
      title={menuItem.title}
    >
      <ListItemText primary={menuItem.menuItemType} />
    </MenuItem>
  ) : null
}

GalleryMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    menuItemType: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    title: PropTypes.string,
  }).isRequired,
  handleMenuClick: PropTypes.func.isRequired,
}

export default memo(GalleryMenuItem)
