import PropTypes from 'prop-types'
import { memo } from 'react'
import { Box, Tooltip } from '@mui/material'
import { Info } from '@mui/icons-material'

import { BarContainer, HelpContainer, LabelContainer, LinearProgressContainer } from './styles'

const LimitBar = ({ labelComponent: Label, used, upcoming, limit }) => {
  const normaliseProgressValue = (value) => (value * 100) / limit
  const roundTwoDecimalPlaces = (value) => Math.round((value + Number.EPSILON) * 100) / 100

  const isLimitExceeded = limit - used - upcoming < 0

  const limitInfo = [
    `Used: ${roundTwoDecimalPlaces(used)}`,
    `Available: ${limit - used > 0 ? roundTwoDecimalPlaces(limit - used) : 0}`,
    `Upcoming: ${roundTwoDecimalPlaces(upcoming)}`,
    `Total limit: ${roundTwoDecimalPlaces(limit)}`,
  ]

  return (
    <BarContainer>
      {Label}
      <Tooltip title={<Box style={{ whiteSpace: 'pre-line' }}>{limitInfo.join('\n')}</Box>}>
        <HelpContainer>
          <Info />
        </HelpContainer>
      </Tooltip>
      <LinearProgressContainer
        $exceeded={isLimitExceeded}
        variant='buffer'
        value={normaliseProgressValue(used < limit ? used : limit)}
        valueBuffer={normaliseProgressValue(used + upcoming > limit ? limit : used + upcoming)}
      />
      {isLimitExceeded && <LabelContainer component='div'>Out of limit</LabelContainer>}
    </BarContainer>
  )
}

LimitBar.propTypes = {
  labelComponent: PropTypes.element.isRequired,
  used: PropTypes.number.isRequired,
  upcoming: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
}

export default memo(LimitBar)
