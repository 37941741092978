import { handleActions } from 'redux-actions'

import { setMeasurementSettings, resetAnnotationsState } from '../actions'

const defaultState = {
  PageId: null,
  Dpi: 10,
}

export default handleActions(
  {
    [setMeasurementSettings](state, action) {
      return { ...defaultState, ...action.payload }
    },
    [resetAnnotationsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
