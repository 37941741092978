import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Delete, Edit } from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

const PowerBIReportTenantMenu = ({ anchorEl, open, onClose, onEdit, onDelete }) => {
  const handleEdit = useCallback(() => {
    onEdit()
    onClose()
  }, [onClose, onEdit])

  const handleDelete = useCallback(() => {
    onDelete()
    onClose()
  }, [onClose, onDelete])

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <MenuItem title='Edit' onClick={handleEdit}>
        <ListItemIcon>
          <Edit color='primary' />
        </ListItemIcon>
        <ListItemText primary='Edit' />
      </MenuItem>
      <MenuItem title='Delete' onClick={handleDelete}>
        <ListItemIcon>
          <Delete color='error' />
        </ListItemIcon>
        <ListItemText primary='Delete' />
      </MenuItem>
    </Menu>
  )
}

PowerBIReportTenantMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default PowerBIReportTenantMenu
