import { handleActions } from 'redux-actions'
import { getPolesUniqueAiObjects, setUniqueAiObjectsDialogState } from '../actions'

const defaultState = {
  isOpen: false,
  isLoading: false,
  list: [],
}

export default handleActions(
  {
    [getPolesUniqueAiObjects.request](state, action) {
      return { ...state, isLoading: true }
    },
    [getPolesUniqueAiObjects.success](state, action) {
      return { ...state, isLoading: false, list: action.response.data }
    },
    [getPolesUniqueAiObjects.failed](state, action) {
      return { ...state, isLoading: false }
    },
    [setUniqueAiObjectsDialogState](state, action) {
      return { ...state, ...action.payload }
    },
  },
  defaultState
)
