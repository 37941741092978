import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [combineActions(rawActions.createBentleyModelRequest)](state, action) {
      return true
    },
    [combineActions(rawActions.createBentleyModelFailed, rawActions.createBentleyModelSuccess)](state, action) {
      return false
    },
    [rawActions.resetState](state, action) {
      return false
    },
  },
  false
)
