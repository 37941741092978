export default (theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing() / 4,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.background.user.action,
      color: theme.palette.success.light,
      marginRight: theme.spacing() / 2,
    },
    backgroundIcon: {
      color: theme.palette.secondary.light,
    },
    icon: {
      position: 'absolute',
      zIndex: 1,
      color: theme.palette.primary.main,
    },
  }
}
