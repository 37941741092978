import { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { ConfirmationDialog } from '@tabeeb/uikit'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { bulkCopySession } from '@tabeeb/modules/sessionsPage/actions'
import { useTenantUsersCount } from '@tabeeb/modules/sessionsPage/hooks'

const BulkCopySessionDialog = ({ open, session, onClose }) => {
  const dispatch = useDispatch()

  const { usersCount, loaded } = useTenantUsersCount({ tenantId: session?.TenantId })

  const onSubmit = useCallback(() => {
    dispatch(
      bulkCopySession.request({
        contentId: session.Id,
        sessionName: session.Description,
      })
    )

    onClose()
  }, [dispatch, onClose, session.Description, session.Id])

  return (
    <ConfirmationDialog
      isOpen={open && loaded}
      title='Copy to all users?'
      dialogMessage={sessionTitleFormatter.format(
        usersCount > 1
          ? `Would you like to copy the session to all ${usersCount - 1} users? It may take some time.`
          : 'Please, add users to the tenant before copying the session.'
      )}
      cancelButtonText='Cancel'
      submitButtonText='Continue'
      showSubmitButton={Boolean(usersCount > 1)}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
    />
  )
}

BulkCopySessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    TenantId: PropTypes.number.isRequired,
    Description: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(BulkCopySessionDialog)
