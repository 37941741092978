import { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import moment from 'moment'

import { IconButton, Typography, Paper, Tooltip } from '@material-ui/core'
import { CloseOutlined, LockOutlined, PersonOutlined, WatchLaterOutlined } from '@material-ui/icons'

import SessionInfoSkeleton from './Skeleton'

import useStyles from './styles'

const SessionThumbnailPlaceholderUrl = new URL('@tabeeb/assets/images/image_placeholder.png', import.meta.url)

const SessionInfo = ({ item, onRemove }) => {
  const classes = useStyles()

  return (
    <div className={classes.container} title={item.Description}>
      <Paper className={classes.previewContainer}>
        <img
          className={classes.preview}
          src={item.ThumbnailUrl || SessionThumbnailPlaceholderUrl}
          alt='Session preview'
        />
        {item.IsActive === false && (
          <Tooltip title='Expired'>
            <LockOutlined className={classes.expired} />
          </Tooltip>
        )}
      </Paper>
      <div className={classes.content}>
        <Typography className={classes.title} noWrap>
          <Link target='_blank' to={`/whiteboard/plugin?sessionId=${item.Id}`}>
            {item.Description}
          </Link>
        </Typography>
        <Typography className={classes.subtitle} noWrap>
          <PersonOutlined className={classes.icon} fontSize='inherit' />
          {item.Owner?.Name}
        </Typography>
        <Typography className={classes.subtitle} noWrap>
          <WatchLaterOutlined className={classes.icon} fontSize='inherit' />
          {moment(item.CreatedOn).format('dddd, MMMM D, YYYY')}
        </Typography>
      </div>
      {onRemove && (
        <IconButton size='small' onClick={onRemove}>
          <CloseOutlined size='small' color='error' />
        </IconButton>
      )}
    </div>
  )
}

SessionInfo.propTypes = {
  item: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Description: PropTypes.string.isRequired,
    IsActive: PropTypes.bool,
    ThumbnailUrl: PropTypes.string,
    CreatedOn: PropTypes.string.isRequired,
    Owner: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onRemove: PropTypes.func,
}

export default memo(SessionInfo)

export { SessionInfoSkeleton }
