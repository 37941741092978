import { createAction } from 'redux-actions'
import { signalrActions } from '@tabeeb/modules/signalr'

export const resetCalendarUpdatesConnections = createAction('RESET_CALENDAR_UPDATES_CONNECTIONS')

export const subscribeToCalendarUpdates = ({ calendarId }) =>
  signalrActions.invokeHubAction({ method: 'SubscribeToCalendarUpdates', args: [calendarId] })

export const unsubscribeFromCalendarUpdates = ({ calendarId }) =>
  signalrActions.invokeHubAction({ method: 'UnsubscribeFromCalendarUpdates', args: [calendarId] })
