import { combineReducers } from 'redux'

import deletedPages from './deletedPages'
import dialogItem from './dialogItem'
import operatingPageId from './operatingPageId'
import operatingFolderId from './operatingFolderId'
import selectedPages from './selectedPages'
import deletedFolders from './deletedFolders'
import selectedFolder from './selectedFolder'

export default combineReducers({
  deletedPages,
  dialogItem,
  operatingPageId,
  operatingFolderId,
  selectedPages,
  deletedFolders,
  selectedFolder,
})
