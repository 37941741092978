import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.adminSearchUsersRequest](state, action) {
      return true
    },
    [combineActions(rawActions.adminSearchUsersSuccess, rawActions.adminSearchUsersFailed)](state, action) {
      return defaultState
    },
    [rawActions.resetPage]() {
      return defaultState
    },
  },
  defaultState
)
