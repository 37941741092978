import { put, takeLatest, select, all, delay } from 'redux-saga/effects'
import _ from 'lodash'

import {
  searchUsersRequest,
  searchUsersSuccess,
  clearFoundUsersList,
  setFoundUsersList,
  updateUsersSearchValue,
} from '../actions'
import { getSortedUsers } from '../services/usersSorting'

function* searchUsers(action) {
  const { searchText, tenantId, roles } = action.payload
  yield delay(800)
  const { usersSearchInputValue } = yield select((state) => state.sessionsPage)
  if (searchText === usersSearchInputValue && usersSearchInputValue) {
    yield put(searchUsersRequest({ searchText, tenantId, roles }))
  }

  if (!usersSearchInputValue) yield put(clearFoundUsersList())
}

function* onSearchUsersSuccess(action) {
  const { data } = action.response
  const { searchText } = action.payload

  const sortedFoundUsers = getSortedUsers(data, searchText)

  yield put(setFoundUsersList(sortedFoundUsers))
}

function* usersSearch() {
  yield all([takeLatest(updateUsersSearchValue, searchUsers), takeLatest(searchUsersSuccess, onSearchUsersSuccess)])
}

export default usersSearch
