import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  contextMenuPopper: {
    // Deprecated in MUI 5 (Popper.js v2) https://popper.js.org/docs/v2/modifiers/hide/
    '&[x-out-of-boundaries]': {
      visibility: 'hidden',
      pointerEvents: 'none',
    },
  },
}))
