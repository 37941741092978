import { memo, useCallback } from 'react'
import { generatePath, Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import {
  CloudUploadOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  MoreVertOutlined,
  SaveAsOutlined,
} from '@mui/icons-material'

import Routes from '@tabeeb/routes'
import { useDialogState, usePopoverState } from '@tabeeb/shared/utils/hooks'

import AnnouncementDeleteConfirmationDialog from '../AnnouncementDeleteConfirmationDialog'

import { ArticlePublicationStatus } from '../../enums'

const AnnouncementMenu = ({ announcement, onDelete, onUpdateStatus }) => {
  const [menuAnchorRef, isMenuOpen, onOpenMenu, onCloseMenu] = usePopoverState()
  const [isDeleteAnnouncementDialogOpen, onOpenDeleteAnnouncementDialog, onCloseDeleteAnnouncementDialog] =
    useDialogState()

  const onDeleteAnnouncementSubmit = useCallback(() => {
    onDelete({ id: announcement.Id })
  }, [announcement.Id, onDelete])

  return (
    <>
      <IconButton ref={menuAnchorRef} onClick={onOpenMenu}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={onCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Link to={generatePath(Routes.announcementEdit, { id: announcement.Id })}>
          <MenuItem
            title='Edit'
            onClick={() => {
              onCloseMenu()
            }}
          >
            <ListItemIcon>
              <EditOutlined />
            </ListItemIcon>
            <ListItemText primary='Edit' />
          </MenuItem>
        </Link>
        {announcement.Status !== ArticlePublicationStatus.Published && (
          <MenuItem
            title='Publish'
            onClick={() => {
              onCloseMenu()
              onUpdateStatus({ id: announcement.Id, status: ArticlePublicationStatus.Published })
            }}
          >
            <ListItemIcon>
              <CloudUploadOutlined />
            </ListItemIcon>
            <ListItemText primary='Publish' />
          </MenuItem>
        )}
        {announcement.Status !== ArticlePublicationStatus.Draft && (
          <MenuItem
            title='Mark as draft'
            onClick={() => {
              onCloseMenu()
              onUpdateStatus({ id: announcement.Id, status: ArticlePublicationStatus.Draft })
            }}
          >
            <ListItemIcon>
              <SaveAsOutlined />
            </ListItemIcon>
            <ListItemText primary='Mark as draft' />
          </MenuItem>
        )}
        <MenuItem
          title='Delete'
          onClick={() => {
            onCloseMenu()
            onOpenDeleteAnnouncementDialog()
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlined color='error' />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>

      <AnnouncementDeleteConfirmationDialog
        open={isDeleteAnnouncementDialogOpen}
        onClose={onCloseDeleteAnnouncementDialog}
        onSubmit={onDeleteAnnouncementSubmit}
      />
    </>
  )
}

AnnouncementMenu.propTypes = {
  announcement: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Status: PropTypes.oneOf(Object.values(ArticlePublicationStatus)).isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
}

export default memo(AnnouncementMenu)
