import { put, takeEvery, all } from 'redux-saga/effects'

import { onAddInfoNotification } from '@tabeeb/modules/notification/actions'
import * as rawActions from '../actions'

function* sendForTableRecognitionSuccess(action) {
  yield put(onAddInfoNotification({ message: 'The page has been sent for table recognition' }))
}

function* uploadFiles() {
  yield all([takeEvery(rawActions.sendForTableRecognition.success, sendForTableRecognitionSuccess)])
}

export default uploadFiles
