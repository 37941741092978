import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { DialogContent, DialogTitle, Dialog, Tabs, Tab } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'

import MainSettings from '../MainSettings'
import ExistingReminders from '../ExistingReminders'

import './styles.less'

const StyledTabs = withStyles({
  root: { backgroundColor: '#e6e6e6', color: '#616161' },
  indicator: { backgroundColor: '#616161' },
})(Tabs)

const StyledTab = withStyles({ root: { width: '50%', maxWidth: 'none' } })(Tab)

const ReminderManager = ({ onCloseDialog, isOpen, reminders, defaultReminder, inProgress }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (reminders.length === 0) setSelectedTab(0)
  }, [reminders.length])

  const _handleClose = () => {
    if (selectedTab !== 0) setSelectedTab(0)
    onCloseDialog()
  }

  const mainSettingsProps = {
    onCloseDialog: _handleClose,
    defaultReminder,
  }

  return (
    <Dialog open={isOpen} onClose={_handleClose}>
      {reminders.length > 0 ? (
        <StyledTabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
          <StyledTab label='Create new' index={0} />
          <StyledTab label='Edit existing' index={1} />
        </StyledTabs>
      ) : (
        <DialogTitle>Create reminder</DialogTitle>
      )}
      {inProgress ? (
        <DialogContent className='reminder-dialog-content'>
          <CircularProgressBar />
        </DialogContent>
      ) : (
        <>
          {selectedTab === 0 && <MainSettings {...mainSettingsProps} />}
          {selectedTab === 1 && <ExistingReminders reminders={reminders} onCloseDialog={_handleClose} />}
        </>
      )}
    </Dialog>
  )
}

ReminderManager.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  reminders: PropTypes.array.isRequired,
  defaultReminder: PropTypes.object.isRequired,
  inProgress: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const {
    reminders: { actionInProgress },
  } = state

  return {
    inProgress: actionInProgress,
  }
}

export default connect(mapStateToProps, null)(ReminderManager)
