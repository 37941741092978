import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ReactReduxContext } from 'react-redux'

import { Divider, Button, Card, CardContent, CardActions, Modal, withStyles, Fade } from '@material-ui/core'

import { AnnotationType } from '@tabeeb/enums'
import { Portal } from '@tabeeb/modules/contentViewer'

import HotspotEditor from '../HotspotEditor'
import MessageContainer from '../../containers/MessageContainer'
import { PlayingAudioUrlProvider } from '../../../shared/audioPlayer'

import styles from './styles'

const HotspotDialog = ({ classes, annotation, childAnnotations, x, y, handleClose, handleReply }) => {
  const [height, setHeight] = useState(0)
  const [editAnnotation, setEditAnnotation] = useState()
  const [isEditing, setIsEditing] = useState(childAnnotations.length === 0)

  const [dialog, setDialogRef] = useState(null)
  const handleDialogMount = (element) => {
    if (element && !dialog) {
      setDialogRef(element)
      setHeight(element.getBoundingClientRect().height)
    }
  }

  const content = useRef(null)
  useEffect(() => {
    if (content.current) {
      content.current.scrollTop = content.current.scrollHeight
    }
  })

  useEffect(() => {
    if (dialog) {
      setHeight(dialog.getBoundingClientRect().height)
    }
  }, [isEditing, childAnnotations])

  const handleEditing = () => {
    setIsEditing(!isEditing)
  }

  const handleEditAnnotation = (annotation) => {
    setEditAnnotation(annotation)
    setIsEditing(true)
  }

  const handleCancel = () => {
    setIsEditing(false)
    setEditAnnotation()
    if (childAnnotations.length === 0) {
      handleClose()
    }
  }

  const handleSubmit = (data, annotationType) => {
    setIsEditing(false)
    setEditAnnotation()
    handleReply(data, annotationType, editAnnotation)
  }

  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <Portal store={store}>
          <PlayingAudioUrlProvider>
            <Modal className={classes.modal} open BackdropProps={{ invisible: true }} onClose={handleClose}>
              <Fade in>
                <Card
                  ref={handleDialogMount}
                  className={classes.container}
                  style={{ top: `min(100% - ${height}px, ${y}px)`, left: `min(${x}px, 100% - 518px)` }}
                >
                  <CardContent ref={content} className={classes.content}>
                    {!editAnnotation && (
                      <>
                        {childAnnotations.map((item) => {
                          return <MessageContainer key={item.Id} Id={item.Id} onEdit={handleEditAnnotation} />
                        })}
                      </>
                    )}
                    {isEditing && (
                      <HotspotEditor
                        onCancel={handleCancel}
                        onSubmit={handleSubmit}
                        availableAnnotationTypes={
                          editAnnotation
                            ? [editAnnotation.Type]
                            : annotation.Type === AnnotationType.AIHotspot && childAnnotations.length === 0
                            ? [AnnotationType.AI]
                            : [AnnotationType.Text, AnnotationType.Audio]
                        }
                      />
                    )}
                  </CardContent>
                  {!isEditing && childAnnotations.length > 0 && <Divider />}
                  {!isEditing && (
                    <CardActions className={classes.actions}>
                      <Button variant='outlined' color='primary' onClick={handleEditing}>
                        Reply
                      </Button>
                      <Button onClick={handleClose}>Close</Button>
                    </CardActions>
                  )}
                </Card>
              </Fade>
            </Modal>
          </PlayingAudioUrlProvider>
        </Portal>
      )}
    </ReactReduxContext.Consumer>
  )
}

HotspotDialog.propTypes = {
  classes: PropTypes.shape({
    actions: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    modal: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleReply: PropTypes.func.isRequired,
  annotation: PropTypes.shape({
    Type: PropTypes.number.isRequired,
  }).isRequired,
  childAnnotations: PropTypes.array.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

export default withStyles(styles)(HotspotDialog)
