import { put, takeLatest, select, all } from 'redux-saga/effects'
import _ from 'lodash'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { isImagePage } from '@tabeeb/services/pageService'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'

import * as galleryActions from '../../gallery/actions'
import { getPagesByFolderWithNesting, getInsertionIndex, getFolderById } from '../../gallery/selectors'
import { rawContentActions } from '../../shared/content'

function* createSpatialModel(action) {
  const contentId = yield select((state) => state.contentState.contentId)
  const imagesList = yield select((state) => state.spatialModel.imagesList)
  const sourceFolders = yield select((state) => state.spatialModel.sourceFolders)

  const nameParts = []
  for (const folderId of sourceFolders) {
    const folder = yield select((state) => getFolderById(state, { id: folderId }))
    if (folder) {
      nameParts.push(folder.Name)
    }
  }

  const name = nameParts.length === 0 ? null : _.join(nameParts, '_')

  if (imagesList.length < 1) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'Images list is empty!',
      })
    )
    return
  }

  const order = yield select((state) => getInsertionIndex(state))

  const timeOffset = new Date().getTimezoneOffset()
  const page = {
    contentId,
    order,
    name,
    timeZoneOffset: -timeOffset,
    pageIds: imagesList.map((page) => page.id),
  }

  yield put(rawActions.createSpatialModelRequest(page))

  const currentBottomPanel = yield select((state) => state.navbar.currentPanel.bottom)
  if (currentBottomPanel == TabPanel.SpatialView) {
    yield put(navbarActions.switchPanel(TabPanel.SpatialView, TabPanelLocation.Bottom))
  }

  yield put(rawActions.closePagesDialog())

  yield put(
    notificationActions.onAddInfoNotification({
      message: 'Model sent for creation',
    })
  )
}

function* addAllPagesToSpatialModel() {
  const galleryList = yield select((state) => state.gallery.galleryList)
  const { selectedFolderId } = yield select((state) => state.gallery.galleryState)

  const pages = galleryList.filter((item) => item.folderId === selectedFolderId && isImagePage(item.contentType))

  yield put(rawActions.addAllPagesToSpatialModel(pages))
}

function* addFolderToSpatialModel({ payload: { folderId } }) {
  let pages = yield select((state) => getPagesByFolderWithNesting(state, { id: folderId }))

  pages = pages.filter((item) => isImagePage(item.contentType))

  if (pages.length < 1) {
    yield put(
      notificationActions.onAddWarningNotification({
        message: 'Folder is empty!',
      })
    )

    return
  }

  yield put(rawActions.addImagesSourceFolder(folderId))
  yield put(rawActions.addAllPagesToSpatialModel(pages))
}

function* createSpatialModelSuccess() {
  yield put(notificationActions.onAddSuccessNotification({ message: 'Model created successfully' }))
}

function* resetState() {
  yield put(rawActions.resetState())
}

function* spatialModelSaga() {
  yield all([
    takeLatest(rawActions.createSpatialModel, createSpatialModel),
    takeLatest(rawActions.createSpatialModelSuccess, createSpatialModelSuccess),
    takeLatest(rawActions.addAllPagesToModel, addAllPagesToSpatialModel),
    takeLatest(galleryActions.addFolderToSpatialModel, addFolderToSpatialModel),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default spatialModelSaga
