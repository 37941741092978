import { memo } from 'react'
import { useSelector } from 'react-redux'
import AddFormButton from '@tabeeb/modules/forms/components/AddFormButton'
import ExportFormButton from '@tabeeb/modules/forms/components/ExportFormButton'
import CompleteReviewButton from '@tabeeb/modules/forms/components/CompleteReviewButton'
import SendReviewFeedbackButton from '@tabeeb/modules/forms/components/SendReviewFeedbackButton'
import DetachFormButton from '@tabeeb/modules/forms/components/DetachFormButton'
import SendForInContentReviewButton from '@tabeeb/modules/forms/components/SendForInContentReviewButton'
import SendInContentReviewFeedbackButton from '@tabeeb/modules/forms/components/SendInContentReviewFeedbackButton'
import CompleteInContentReviewButton from '@tabeeb/modules/forms/components/CompleteInContentReviewButton'
import { getFormButtonsGroups, getIsSignFormButtonVisible } from '@tabeeb/modules/forms/selectors'
import { FormButtonsGroup } from '@tabeeb/modules/forms/services/enums'
import Signature from '../Signature'
import './styles.less'

const FormsButtons = () => {
  const groups = useSelector(getFormButtonsGroups)
  const isCommon = groups.some((g) => g === FormButtonsGroup.Common)
  const isReview = groups.some((g) => g === FormButtonsGroup.Review)
  const isInContentReview = groups.some((g) => g === FormButtonsGroup.InContentReview)
  const isMergeReview = groups.some((g) => g === FormButtonsGroup.MergeReview)
  const isSignatureEnabled = useSelector(getIsSignFormButtonVisible)

  return (
    <div className='form-buttons-container'>
      {isCommon && (
        <>
          {isSignatureEnabled && (
            <div className='form-buttons'>
              <Signature />
            </div>
          )}
          <div className='form-buttons'>
            <AddFormButton />
            <ExportFormButton />
            <DetachFormButton />
          </div>
        </>
      )}

      {isInContentReview && (
        <div className='form-buttons'>
          <SendForInContentReviewButton />
          <CompleteInContentReviewButton />
          <SendInContentReviewFeedbackButton />
        </div>
      )}

      {isReview && (
        <div className='form-buttons'>
          <CompleteReviewButton />
          <SendReviewFeedbackButton />
        </div>
      )}

      {isMergeReview && (
        <>
          <div className='form-buttons'>
            <AddFormButton />
          </div>
          <div className='form-buttons'>
            <CompleteReviewButton />
            <SendReviewFeedbackButton />
          </div>
        </>
      )}
    </div>
  )
}

export default memo(FormsButtons)
