import {
  validateLowercase,
  validateUppercase,
  validateNumber,
  validateSpecialCharacter,
  validateMinLength,
} from '../helpers/validators'

export const passwordMaxLength = 128
export const passwordMinimumLength = 8

export const passwordRequirements = [
  { label: `At least ${passwordMinimumLength} characters`, validator: validateMinLength },
  { label: 'At least 1 lowercase letter (a-z)', validator: validateLowercase },
  { label: 'At least 1 uppercase letter (A-Z)', validator: validateUppercase },
  { label: 'At least one number (0-9)', validator: validateNumber },
  { label: 'At least one special character (!"#$%&()*+,-./:;<=>?@[]^_`{|}~\\)', validator: validateSpecialCharacter },
]
