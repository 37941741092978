import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [rawActions.setExportEmailRecipients](state, action) {
      return action.payload
    },
    [combineActions(rawActions.resetGalleryState, rawActions.exportImagesRequest, rawActions.exportAsPdfRequest)](
      state,
      action
    ) {
      return defaultValue
    },
  },
  defaultValue
)
