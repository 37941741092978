/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  withStyles,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { validateEmail } from '../../../utils/validators/emailValidator'

import RecipientItem from './RecipientItem'

import styles from './styles'

const RecipientsDialog = ({ initialRecipientsList, onSubmit, onClose, title, warningText, isDisabled, classes }) => {
  const [text, setText] = useState('')
  const [recipientsList, setRecipientsList] = useState([...initialRecipientsList])

  const recipientsListRef = useRef()

  const handleClose = useCallback(() => onClose(), [onClose])

  useEffect(() => {
    if (recipientsListRef.current) {
      recipientsListRef.current.scrollTop = recipientsListRef.current.scrollHeight
    }
  }, [recipientsList])

  const handleTextChanged = (event) => {
    setText(event.target.value)
  }

  const onAddRecipient = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setRecipientsList([...recipientsList, text])

    setText('')
  }

  const onDeleteRecipient = (e, recipient) => {
    e.preventDefault()
    e.stopPropagation()

    const index = recipientsList.lastIndexOf(recipient)
    setRecipientsList(recipientsList.slice(0, index).concat(recipientsList.slice(index + 1)))
  }

  const handleKeyUp = (e) => {
    if (e.which === 13) {
      const isEmailValid = validateEmail(text)
      if (isEmailValid) {
        onAddRecipient(e)
      }
    }
  }

  const handleSubmit = useCallback(() => {
    onSubmit(recipientsList)
    handleClose()
  }, [onSubmit, recipientsList, handleClose])

  const isEmailValid = validateEmail(text)

  return (
    <Dialog modal='false' open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        style={{
          width: 450,
        }}
      >
        <Box ref={recipientsListRef} className={classes.recipients}>
          {recipientsList.length > 0 ? (
            recipientsList.map((item) => (
              <RecipientItem item={item} key={item} handleDeleteRecipient={onDeleteRecipient} />
            ))
          ) : (
            <Typography color='error' variant='body1'>
              {warningText}
            </Typography>
          )}
        </Box>

        <Box className={classes.input}>
          <TextField
            fullWidth
            value={text}
            placeholder='Enter the email...'
            onChange={handleTextChanged}
            onKeyUp={handleKeyUp}
            InputProps={{
              endAdornment: (
                <IconButton size='small' disabled={!isEmailValid} onClick={onAddRecipient}>
                  {isEmailValid ? (
                    <CheckCircleIcon className={classes.success} />
                  ) : (
                    <CancelIcon className={classes.error} />
                  )}
                </IconButton>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color='primary' disabled={recipientsList.length === 0 || isDisabled} onClick={handleSubmit}>
          Continue
        </Button>
        <Button disabled={isDisabled} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RecipientsDialog.defaultProps = {
  initialRecipientsList: [],
}

RecipientsDialog.propTypes = {
  initialRecipientsList: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  warningText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  classes: PropTypes.shape({
    error: PropTypes.string.isRequired,
    success: PropTypes.string.isRequired,
    recipients: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(RecipientsDialog)
