import { useCallback, memo } from 'react'
import PropTypes from 'prop-types'

import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Folder as FolderIcon, FolderShared as FolderSharedIcon, MoreVertOutlined } from '@mui/icons-material'

import DroppableLayout from '../../DroppableLayout'

import { Container } from './styles'

const Folder = ({ folder, onClick, onDrop, onMenu }) => {
  const onMenuClick = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      onMenu(e, folder)
    },
    [folder, onMenu]
  )

  return (
    <Container variant='outlined' title={folder.Name} onClick={(e) => onClick(e, folder)}>
      <DroppableLayout onDrop={(e) => onDrop(e, folder)} />
      <Box display='flex' alignItems='center' mr={1.5}>
        {folder.IsFolderShared ? <FolderSharedIcon /> : <FolderIcon />}
      </Box>
      <Tooltip title={folder.Name} placement='top'>
        <Typography variant='body2' fontWeight={500} flexGrow={1} noWrap>
          {folder.Name}
        </Typography>
      </Tooltip>
      {onMenu && (
        <IconButton color='inherit' size='small' onClick={onMenuClick}>
          <MoreVertOutlined />
        </IconButton>
      )}
    </Container>
  )
}

Folder.propTypes = {
  folder: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    IsFolderShared: PropTypes.bool.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onMenu: PropTypes.func.isRequired,
}

export default memo(Folder)
