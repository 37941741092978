import { put, select, take, all } from 'redux-saga/effects'

import _ from 'lodash'
import { getContentId, getOwnerId } from '@tabeeb/modules/shared/content/selectors'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { convertServicePageToPluginPage } from '@tabeeb/services/dataConverter'
import * as galleryActions from '../actions'

export default function* loadGallery({ selectedPageId, preserveSelectedPage = false }) {
  const contentId = yield select(getContentId)
  const contentOwnerId = yield select(getOwnerId)
  const currentUserId = yield select(getCurrentUserId)

  const pageToPluginPageParams = {
    contentId,
    contentOwnerId,
    currentUserId,
  }

  yield put(galleryActions.setGalleryIsLoading(true))

  yield all([
    put(galleryActions.getSessionFoldersRequest(contentId)),
    put(galleryActions.getPagesByContentIdRequest(contentId)),
  ])

  const [
    {
      response: { data: folders },
    },
    {
      response: { data: contentPages },
    },
  ] = yield all([take(galleryActions.getSessionFoldersSuccess), take(galleryActions.getPagesByContentIdSuccess)])

  const pages = contentPages.map((page) => convertServicePageToPluginPage({ ...pageToPluginPageParams, page }))

  const actions = [put(galleryActions.setGalleryItems(pages)), put(galleryActions.setFoldersList(folders))]

  let pageIdToSelect = selectedPageId

  if (pages.length > 0 && !preserveSelectedPage) {
    if (!selectedPageId) {
      const firstPageInMainList = _.minBy(
        pages.filter((page) => page.folderId === null),
        (page) => page.order
      )

      if (firstPageInMainList) {
        pageIdToSelect = firstPageInMainList.id
      } else {
        pageIdToSelect = pages[0]?.id
      }
    }

    actions.push(put(galleryActions.onSelectGalleryItem({ pageId: pageIdToSelect, updateWeb: true })))
  }

  yield all(actions)

  yield put(galleryActions.setGalleryIsLoading(false))
}
