import { memo } from 'react'
import PropTypes from 'prop-types'

import { TableCell, TableSortLabel, Typography } from '@mui/material'

import { SortingOrder } from '@tabeeb/enums'
import { FilterAlt } from '@mui/icons-material'

const TableHeaderFilterableCell = ({ align, children, filter }) => {
  return (
    <TableCell align={align} sortDirection={SortingOrder.Descending}>
      <TableSortLabel active IconComponent={filter} direction={SortingOrder.Descending}>
        <Typography variant='body2' fontWeight={500} noWrap>
          {children}
        </Typography>
      </TableSortLabel>
    </TableCell>
  )
}

TableHeaderFilterableCell.defaultProps = {
  align: 'left',
  filter: FilterAlt,
}

TableHeaderFilterableCell.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  children: PropTypes.node.isRequired,
  filter: PropTypes.elementType,
}

export default memo(TableHeaderFilterableCell)
