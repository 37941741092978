import { Component } from 'react'
import PropTypes from 'prop-types'
import InlineEditInput from '../components/InlineEditInput'
import InlineEditSpan from '../components/InlineEditSpan'

class InlineEditContainer extends Component {
  state = {
    isInEditMode: false,
    value: '',
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value || this.state.isInEditMode !== nextProps.isInEditMode) {
      this.setState({
        value: nextProps.value,
        isInEditMode: !!nextProps.isInEditMode,
      })
    }
  }

  /*
  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.value !== nextProps.value || prevState.isInEditMode !== nextProps.isInEditMode) {
      return {
        value: nextProps.value,
        isInEditMode: !!nextProps.isInEditMode
      }
    }

    return null
  }
*/

  handleChangeName = (e) => {
    const { validateChange, handleChangeName } = this.props
    if (validateChange && !validateChange(e.target.value)) {
      return
    }
    this.setState({
      value: e.target.value,
    })
    handleChangeName && handleChangeName(e)
  }

  handleOnMouseDownSpan = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { validateMouseDown, handleMouseDownSpan, disabled } = this.props

    if (disabled || (validateMouseDown && !validateMouseDown(e.target.value))) {
      return
    }
    this.setState({
      isInEditMode: true,
      value: this.state.value || this.props.value,
    })
    handleMouseDownSpan && handleMouseDownSpan(e)
  }

  moveCaretToEnd = (e) => {
    const { length } = e.target.value
    e.target.setSelectionRange(length, length)
  }

  handleBlurInput = (e) => {
    const { validateBlur, handleBlurInput, value } = this.props

    if (validateBlur && !validateBlur(e.target.value, value)) {
      this.setState({
        isInEditMode: false,
        value,
      })
      return
    }

    this.setState({
      isInEditMode: false,
      value: e.target.value,
    })

    handleBlurInput && handleBlurInput(e)
  }

  render() {
    const { handleKeyDown, maxLength, classNameSpan, classNameInput, disabled, title } = this.props
    const { value, isInEditMode } = this.state

    const propsInput = {
      value,
      disabled,
      classNameInput,
      maxLength,
      handleBlurInput: this.handleBlurInput,
      handleChangeName: this.handleChangeName,
      handleKeyDown,
      moveCaretToEnd: this.moveCaretToEnd,
    }

    const propsSpan = {
      value: value || this.props.value,
      disabled,
      classNameSpan,
      title,
      handleOnMouseDownSpan: this.handleOnMouseDownSpan,
    }

    return isInEditMode ? <InlineEditInput {...propsInput} /> : <InlineEditSpan {...propsSpan} />
  }
}

InlineEditContainer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  isInEditMode: PropTypes.bool,
  maxLength: PropTypes.string,
  handleBlurInput: PropTypes.func,
  handleChangeName: PropTypes.func,
  handleKeyDown: PropTypes.func,
  handleMouseDownSpan: PropTypes.func,
  classNameSpan: PropTypes.string,
  classNameInput: PropTypes.string,
  validateBlur: PropTypes.func,
  validateChange: PropTypes.func,
  validateMouseDown: PropTypes.func,
  title: PropTypes.string,
}

InlineEditContainer.defaultProps = {
  value: '',
  disabled: false,
  isInEditMode: false,
  maxLength: '256',
}

export default InlineEditContainer
