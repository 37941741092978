import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { push } from 'connected-react-router'

import routes from '@tabeeb/routes'
import { getLoginButtonColor } from '@tabeeb/modules/appConfigState/selectors'

import { createProfile, getIdentityMe } from '../actions'
import CreateProfile from '../components/CreateProfile'
import CreateProfileMobile from '../components/CreateProfileMobile'
import { getIdentityMeSelector } from '../selectors'

const CreateProfileContainer = () => {
  const dispatch = useDispatch()
  const loginButtonColor = useSelector(getLoginButtonColor)
  const identityMe = useSelector(getIdentityMeSelector)

  useEffect(() => {
    dispatch(getIdentityMe.request())
    return () => {
      dispatch(getIdentityMe.resetState())
    }
  }, [dispatch])

  const onRegisterUser = useCallback(
    (data) => {
      const registrationDataToSend = {
        FirstName: data.FirstName.trim(),
        LastName: data.LastName.trim(),
      }
      dispatch(createProfile.request(registrationDataToSend))
    },
    [dispatch]
  )

  const onClickBack = useCallback(() => {
    dispatch(push(routes.switchTenant))
  }, [dispatch])

  return isMobileOnly ? (
    <CreateProfileMobile
      onRegisterUser={onRegisterUser}
      userHasAnyTenants={Array.isArray(identityMe?.userTenants) && identityMe.userTenants.length > 0}
      onClickBack={onClickBack}
    />
  ) : (
    <CreateProfile
      loginButtonColor={loginButtonColor}
      onRegisterUser={onRegisterUser}
      userHasAnyTenants={Array.isArray(identityMe?.userTenants) && identityMe.userTenants.length > 0}
      onClickBack={onClickBack}
    />
  )
}

export default CreateProfileContainer
