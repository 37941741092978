import { memo } from 'react'
import { useSelector } from 'react-redux'

import { Box, CircularProgress, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material'

const SelectedSessionsTaskStateDialog = () => {
  const { isOpen, currentCount, totalCount, message } = useSelector((state) => state.sessionsPage.progressDialog)

  return (
    <Dialog open={isOpen} disableEscapeKeyDown fullWidth maxWidth='xs'>
      <DialogTitle>Please wait...</DialogTitle>
      <DialogContent>
        <Box display='flex' alignItems='flex-start' mb={2}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Box mb={1}>
              <CircularProgress size={30} />
            </Box>
            <Typography variant='caption'>
              {currentCount} of {totalCount}
            </Typography>
          </Box>
          <Box ml={2}>
            <Typography variant='body1'>{message}</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default memo(SelectedSessionsTaskStateDialog)
