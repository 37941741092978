import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setOwnerForSharing](state, action) {
      return action.payload
    },
    [combineActions(rawActions.resetShareSessionDialog, rawActions.removeOwner, rawActions.setTenantForSharing)](
      state,
      action
    ) {
      return defaultState
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
