import { ListItem, ListItemText, Skeleton } from '@mui/material'
import PropTypes from 'prop-types'

const GridUserListItem = ({ title, primaryText, secondaryText, usersLoading }) => {
  return (
    <ListItem component='div' title={usersLoading ? <Skeleton /> : title} disableGutters dense>
      <ListItemText
        primary={usersLoading ? <Skeleton /> : primaryText}
        secondary={usersLoading ? <Skeleton /> : secondaryText}
      />
    </ListItem>
  )
}

GridUserListItem.propTypes = {
  title: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  usersLoading: PropTypes.bool,
}

export default GridUserListItem
