import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import { TableRow, TableCell } from '@material-ui/core'

import * as shareContentActions from '@tabeeb/modules/shareSessionPagesList/actions'
import * as shareContentSelectors from '@tabeeb/modules/shareSessionPagesList/selectors'
import SharedForReviewContent from '@tabeeb/modules/contentReviews/components/SharedForReviewContent'
import {
  addAnswerStatusForMerging,
  addFormForMerging,
  removeAnswerStatusForMerging,
  removeFormForMerging,
} from '@tabeeb/modules/contentSharings/actions'
import { ContentSharingType } from '@tabeeb/enums'
import { getContentSharingForms } from '@tabeeb/modules/contentSharings/selectors'
import { answerStatuses } from '../../constants'
import TableItem from '../TableItem'

import { useStyles } from './styles'

const FormForSharing = ({
  form,
  selectedForms,
  reviewWithThisForm,
  actions: { addFormForSharing, removeFormForSharing, addAnswerStatusForSharing, removeAnswerStatusForSharing },
  sharingType,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isMergingType = sharingType === ContentSharingType.LinkedContentsMerging
  const isUnderReview = Boolean(reviewWithThisForm)
  const formId = form.Id
  const isFormSelected = selectedForms?.some((f) => f.ContentFormId === form.Id)

  const _getIsStatusChecked = (answerStatus) => {
    return (
      isFormSelected &&
      selectedForms
        ?.find((f) => f.ContentFormId === form.Id)
        .SelectedAnswerStatuses.some((status) => status === answerStatus)
    )
  }

  const _selectForm = (e) => {
    const isChecked = e.target.checked

    if (isChecked) {
      if (isMergingType) {
        dispatch(addFormForMerging(formId))
      } else {
        addFormForSharing(formId)
      }
      return
    }

    if (isMergingType) {
      dispatch(removeFormForMerging(formId))
    } else {
      removeFormForSharing(formId)
    }
  }

  const _selectStatus = (e, status) => {
    const isChecked = e.target.checked

    if (isChecked) {
      if (isMergingType) {
        dispatch(addAnswerStatusForMerging({ formId, status }))
      } else {
        addAnswerStatusForSharing({ formId, status })
      }
      return
    }

    if (isMergingType) {
      dispatch(removeAnswerStatusForMerging({ formId, status }))
    } else {
      removeAnswerStatusForSharing({ formId, status })
    }
  }

  return (
    <>
      <TableRow className={isUnderReview ? classes.mergedRow : ''}>
        <TableItem formCell title={form.Name} isSelected={isFormSelected || false} handleSelect={_selectForm} />
        {Object.keys(answerStatuses).map((key) => {
          const isChecked = _getIsStatusChecked(answerStatuses[key])

          return (
            <TableItem
              key={answerStatuses[key]}
              title={key}
              isSelected={isChecked || false}
              handleSelect={(e) => _selectStatus(e, answerStatuses[key])}
            />
          )
        })}
      </TableRow>
      {isUnderReview && (
        <TableRow>
          <TableCell colSpan={4}>
            <span className={classes.underReviewMessage}>
              {`Currently under review by ${reviewWithThisForm.ReviewerName} of "${reviewWithThisForm.ReviewerTenantName}" tenant.`}
            </span>
            <SharedForReviewContent review={reviewWithThisForm?.ReviewerTenantId} />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

FormForSharing.propTypes = {
  form: PropTypes.object.isRequired,
  selectedForms: PropTypes.array,
  actions: PropTypes.shape({
    addFormForSharing: PropTypes.func.isRequired,
    removeFormForSharing: PropTypes.func.isRequired,
    addAnswerStatusForSharing: PropTypes.func.isRequired,
    removeAnswerStatusForSharing: PropTypes.func.isRequired,
  }).isRequired,
  reviewWithThisForm: PropTypes.object,
  sharingType: PropTypes.number.isRequired,
}

const mapStateToProps = (state, { form }) => {
  const { selectedForms, sharingType } = state.shareSessionPagesList.shareByFormDialogState
  const formsList =
    sharingType === ContentSharingType.LinkedContentsMerging ? getContentSharingForms(state) : selectedForms
  return {
    selectedForms: formsList,
    reviewWithThisForm: shareContentSelectors.getContentFormExistingReview(state, { form }),
    sharingType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(shareContentActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormForSharing)
