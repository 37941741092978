const getErrorMessageFromResponse = (responseData) => {
  if (!responseData) {
    return 'Unexpected error'
  }

  if (typeof responseData === 'string') {
    return responseData
  }
  if (responseData.Error && responseData.Error.Message) {
    return responseData.Error.Message
  }
  if (responseData[''] && responseData[''][0] && typeof responseData[''][0] === 'string') {
    return responseData[''][0]
  }
  return 'Unexpected error'
}

export default getErrorMessageFromResponse
