export default (theme) => {
  return {
    preview: {
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[4],
      minWidth: 128,
      maxWidth: 128,
      height: 'fit-content',
      maxHeight: 128,
      objectFit: 'cover',
      marginRight: theme.spacing() * 2,
      flexGrow: 1,
      userDrag: 'none',
      overflow: 'hidden',
    },
    active: {
      cursor: 'pointer',
    },
    disabled: {
      cursor: 'not-allowed',
    },
  }
}
