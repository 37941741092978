import { handleActions } from 'redux-actions'
import { setSelectedUserId, resetFormsState } from '../actions'

const defaultValue = 0

const selectedUserId = handleActions(
  {
    [setSelectedUserId](state, action) {
      return action.payload
    },
    [resetFormsState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)

export default selectedUserId
