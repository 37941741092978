import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      flexGrow: 1,
      height: '100%',
      width: '100%',
    },
  }
})
