import { createAction } from 'redux-actions'

export const resetContentState = createAction('ON_RESET_CONTENT_STATE')

export const onUpdateContentState = createAction('ON_UPDATE_CONTENT_STATE')
export const onSetContentId = createAction('SET_CONTENT_ID')

export const renameContentRequest = createAction('RENAME_CONTENT_REQUEST')
export const renameContentSuccess = createAction('RENAME_CONTENT_SUCCESS')
export const renameContentFailed = createAction('RENAME_CONTENT_FAILED')

export const leaveContentRequest = createAction('LEAVE_CONTENT_REQUEST')
export const leaveContentSuccess = createAction('LEAVE_CONTENT_SUCCESS')
export const leaveContentFailed = createAction('LEAVE_CONTENT_FAILED')

export const leaveContent = createAction('LEAVE_CONTENT')

export const leaveVideoCall = createAction('LEAVE_VIDEO_CALL')

export const getContentRequest = createAction('GET_CONTENT_REQUEST')
export const getContentSuccess = createAction('GET_CONTENT_SUCCESS')
export const getContentFailed = createAction('GET_CONTENT_FAILED')

export const makePresenter = createAction('MAKE_PRESENTER')
export const setPresenter = createAction('SET_PRESENTER')

export const setPresenterRequest = createAction('SET_PRESENTER_REQUEST')
export const setPresenterSuccess = createAction('SET_PRESENTER_SUCCESS')

export const confirmPresenterStateRequest = createAction('CONFIRM_PRESENTER_STATE_REQUEST')
export const confirmPresenterStateSuccess = createAction('CONFIRM_PRESENTER_STATE_REQUEST')
export const confirmPresenterStateFailed = createAction('CONFIRM_PRESENTER_STATE_REQUEST')

export const setLastCallStartTime = createAction('SET_LAST_CALL_START_TIME')
export const setLastCallEndTime = createAction('SET_LAST_CALL_END_TIME')
export const goToContent = createAction('GO_TO_CONTENT')
