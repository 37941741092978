import { createSelector } from 'reselect'
import { availableControls } from '../common'

export const getTenantFormAnswers = (state) => state.tenantForms.tenantFormAnswers
export const getOpenedTenantForms = (state) => state.tenantForms.openedTenantForms
export const getIsTenantFormLoading = (state) => state.tenantForms.tenantFormsDialogState.loading
export const getIsAdditionalFormLoading = (state) => state.tenantForms.tenantFormsDialogState.loadingAdditional
export const getIsCheckConditionsInProgress = (state) => state.tenantForms.tenantFormsDialogState.checkConditions
export const getSncType = (state) => state.tenantForms.tenantFormsDialogState.sncType

export const getFormControlsList = createSelector([getOpenedTenantForms], (openedTenantForms) => {
  let formControls = []

  openedTenantForms.forEach((item) => {
    formControls = formControls.concat(
      item.Controls.filter((formControl) => availableControls.includes(formControl.ControlType))
    )
  })

  return formControls
})

export const getIsSubmitButtonDisabled = createSelector(
  [getFormControlsList, getTenantFormAnswers, getIsAdditionalFormLoading, getIsCheckConditionsInProgress],
  (formControls, tenantFormAnswers, loadingAdditionalTenantForm, checkingConditionsInProgress) => {
    const answeredQuestionsCount = Object.values(tenantFormAnswers).filter(
      (answer) =>
        (answer.length > 0 && answer.trim().length > 0) ||
        (typeof answer === 'object' && Object.values(answer).filter((item) => item.value !== null)).length > 0
    ).length

    return (
      formControls?.length !== answeredQuestionsCount || loadingAdditionalTenantForm || checkingConditionsInProgress
    )
  }
)

export const getSubmitButtonName = createSelector(
  [getIsAdditionalFormLoading, getSncType],
  (loadingAdditionalTenantForm, buttonName) => {
    return loadingAdditionalTenantForm ? 'Loading...' : buttonName
  }
)

export const getSubmittedAnswers = createSelector(
  [getFormControlsList, getTenantFormAnswers],
  (formControls, tenantFormAnswers) => {
    const answers = []

    formControls.forEach((control) => {
      if (Object.keys(tenantFormAnswers).some((controlId) => controlId == control.Id)) {
        let answer = tenantFormAnswers[control.Id]

        if (typeof answer === 'object') {
          answer = Object.values(answer)
            .map((answerItem) => answerItem.value)
            .filter((value) => value !== null)
            .join('_')
        }

        answers.push(answer)
      }
    })

    return answers.join('_')
  }
)

export const getContentNameParts = createSelector(
  [getFormControlsList, getTenantFormAnswers],
  (formControls, tenantFormAnswers) => {
    const contentNameParts = []

    formControls.forEach((control, index) => {
      if (Object.keys(tenantFormAnswers).some((controlId) => controlId == control.Id)) {
        let answer = tenantFormAnswers[control.Id]

        if (typeof answer === 'object') {
          answer = Object.values(answer)
            .map((answerItem) => answerItem.value)
            .filter((value) => value !== null)
            .join(';')
        }

        const data = {
          Order: index,
          Question: control.Label,
          Answer: answer,
        }

        contentNameParts.push(data)
      }
    })

    return contentNameParts
  }
)
