import { combineActions, handleActions } from 'redux-actions'
import _ from 'lodash'
import * as rawActions from '../actions'

function _addFieldsToIteration(iteration) {
  return {
    ...iteration,
    AIObjects: [],
    AnnotationOwners: [],
    Answerers: [],
    Approvers: [],
    Contents: [],
    FormControls: [],
    Forms: [],
    Metrics: [],
    Pages: [],
    Reviewers: [],
    isAIObjectsLoaded: false,
    isAnnotationOwnersLoaded: false,
    isAnswerersLoaded: false,
    isApproversLoaded: false,
    isContentsLoaded: false,
    isFormControlsLoaded: false,
    isFormsLoaded: false,
    isMetricsLoaded: false,
    isPagesLoaded: false,
    isReviewersLoaded: false,
  }
}

function _addIteration(state, iteration) {
  let result = [...state, _addFieldsToIteration(iteration)]
  result = _.sortBy(result, (itr) => itr.Id)
  return result
}

function _updateIteration(state, updatedIteration) {
  return state.map((iteration) =>
    iteration.Id === updatedIteration.Id ? { ...iteration, ...updatedIteration } : iteration
  )
}

function _updateIterationAIObjects(state, iterationId, aiObjects) {
  return state.map((iteration) =>
    iteration.Id === iterationId ? { ...iteration, AIObjects: aiObjects, isAIObjectsLoaded: true } : iteration
  )
}

function _updateReviewers(state, iterationId, reviewers) {
  return state.map((iteration) =>
    iteration.Id === iterationId ? { ...iteration, Reviewers: reviewers, isReviewersLoaded: true } : iteration
  )
}

function _updateApprovers(state, iterationId, approvers) {
  return state.map((iteration) =>
    iteration.Id === iterationId ? { ...iteration, Approvers: approvers, isApproversLoaded: true } : iteration
  )
}

function _updateAnswerers(state, iterationId, answerers) {
  return state.map((iteration) =>
    iteration.Id === iterationId ? { ...iteration, Answerers: answerers, isAnswerersLoaded: true } : iteration
  )
}

function _updateIterationAnnotationOwners(state, iterationId, annotationOwners) {
  return state.map((iteration) =>
    iteration.Id === iterationId
      ? { ...iteration, AnnotationOwners: annotationOwners, isAnnotationOwnersLoaded: true }
      : iteration
  )
}

function _updateIterationPages(state, { IterationId }, pages) {
  return state.map((iteration) => (iteration.Id === IterationId ? { ...iteration, Pages: pages } : iteration))
}

function _updateIterationContents(state, iterationId, contents) {
  return state.map((iteration) =>
    iteration.Id === iterationId ? { ...iteration, Contents: contents, isContentsLoaded: true } : iteration
  )
}

function _updateIterationForms(state, iterationId, forms) {
  return state.map((iteration) =>
    iteration.Id === iterationId ? { ...iteration, Forms: forms, isFormsLoaded: true } : iteration
  )
}

function _updateIterationFormControls(state, iterationId, formControls) {
  return state.map((iteration) =>
    iteration.Id === iterationId ? { ...iteration, FormControls: formControls, isFormControlsLoaded: true } : iteration
  )
}

function _updateIterationMetrics(state, iterationId, metrics) {
  return state.map((iteration) =>
    iteration.Id === iterationId ? { ...iteration, Metrics: metrics, isMetricsLoaded: true } : iteration
  )
}

const defaultState = []

export default handleActions(
  {
    [rawActions.getIterations.success](state, { response }) {
      return response.data.map((iteration) => _addFieldsToIteration(iteration))
    },
    [rawActions.getIterationById.success](state, { response }) {
      return _addIteration(state, response.data)
    },
    [combineActions(
      rawActions.updateIteration.success,
      rawActions.deployIteration.success,
      rawActions.getIterationStatus.success,
      rawActions.getIterationDeploymentStatus.success
    )](state, { response }) {
      return _updateIteration(state, response.data)
    },
    [rawActions.getAiObjectsByIterationId.success](state, { payload, response }) {
      return _updateIterationAIObjects(state, payload, response.data)
    },
    [rawActions.getPagesByIterationId.success](state, { payload, response }) {
      return _updateIterationPages(state, payload, response.data)
    },
    [rawActions.getContentsByIterationId.success](state, { payload, response }) {
      return _updateIterationContents(state, payload, response.data)
    },
    [rawActions.getFormsByIterationId.success](state, { payload, response }) {
      return _updateIterationForms(state, payload, response.data)
    },
    [rawActions.getAnnotationOwnersByIterationId.success](state, { payload, response }) {
      return _updateIterationAnnotationOwners(state, payload, response.data)
    },
    [rawActions.getReviewersByIterationId.success](state, { payload, response }) {
      return _updateReviewers(state, payload, response.data)
    },
    [rawActions.getApproversByIterationId.success](state, { payload, response }) {
      return _updateApprovers(state, payload, response.data)
    },
    [rawActions.getAnswerersByIterationId.success](state, { payload, response }) {
      return _updateAnswerers(state, payload, response.data)
    },
    [rawActions.getFormControlsByIterationId.success](state, { payload, response }) {
      return _updateIterationFormControls(state, payload, response.data)
    },
    [combineActions(rawActions.getMetricsByIterationId.success, rawActions.downloadIterationMetrics.success)](
      state,
      { payload, response }
    ) {
      return _updateIterationMetrics(state, payload, response.data)
    },
    [rawActions.trainProject.success](state, { response }) {
      return _addIteration(state, response.data)
    },
    [rawActions.updateIterationTrainingStatus](state, { payload }) {
      return _updateIteration(state, payload)
    },
    [rawActions.updateIterationDeploymentStatus](state, { payload }) {
      return _updateIteration(state, {
        Id: payload.Id,
        DeploymentStatus: payload.Status,
        DeploymentExpirationTime: payload.ExpirationTime,
      })
    },
  },
  defaultState
)
