import { handleActions } from 'redux-actions'

import { SortingOrder } from '@tabeeb/enums'

import * as rawActions from '../actions'
import { SortingColumn } from '../enums'

const defaultState = { column: SortingColumn.Date, order: SortingOrder.Descending }

export default handleActions(
  {
    [rawActions.setSorting](state, action) {
      return {
        ...action.payload,
      }
    },
    [rawActions.setSortingColumn](state, action) {
      return {
        ...state,
        column: action.payload,
      }
    },
    [rawActions.setSortingOrder](state, action) {
      return {
        ...state,
        order: action.payload,
      }
    },
  },
  defaultState
)
