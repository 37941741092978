import { createSelector } from 'reselect'

export const callNotificationsSelector = (state) => state.callNotification.notifications
export const isCallAcceptedSelector = (state) => state.callNotification.isCallAccepted

export const callNotificationSelector = createSelector(
  [callNotificationsSelector, (_, { sessionId }) => sessionId],
  (notifications, sessionId) => {
    return notifications.find((notification) => notification.sessionId === sessionId)
  }
)
