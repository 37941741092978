import { put, takeLatest, all, take, call } from 'redux-saga/effects'

import * as healthActions from '@tabeeb/modules/healthData/actions'
import { deleteDeviceSuccess, coreOnAddDeviceSuccess } from '@tabeeb/modules/admin/devices/sagas'
import { ErrorCode } from '@tabeeb/enums'
import { notificationActions } from '../../notification'

function* onCreateHealthDeviceSuccess() {
  yield put(healthActions.getMyHealthDevicesRequest())
}

function* onCreateDeviceFailed(action) {
  const message =
    action.response.data.Error?.Code === ErrorCode.DeviceAlreadyExists
      ? action.response.data.Error?.Message
      : 'Failed to create device'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* onCreateHealthDeviceOnUsersPageSuccess(action) {
  yield put(healthActions.getHealthDevicesByUserIdRequest({ userId: action.payload.UserId }))
}

function* deleteHealthDeviceSuccess(action) {
  const message = 'Device deleted successfully.'
  yield put(notificationActions.onAddSuccessNotification({ message }))
  yield put(healthActions.getMyHealthDevicesRequest())
}

function* onGetLife365Device({ response }) {
  yield put(healthActions.getAssignedLife365DeviceStatusRequest())
}

function* getAssignedLife365DeviceStatusSuccess({ response }) {
  if (response.status === 200) yield put(healthActions.getAssignedLife365DeviceStatusWithContent(response.data))
}

function* onHandleCreateHealthDevice(device, successCallback, failCallback) {
  yield put(healthActions.createHealthDeviceRequest(device))

  const result = yield take([healthActions.createHealthDeviceSuccess, healthActions.createHealthDeviceFailed])
  if (result.type === healthActions.createHealthDeviceSuccess().type) {
    const message = 'Device created successfully.'
    yield put(notificationActions.onAddSuccessNotification({ message }))
    yield call(successCallback, result)
  } else {
    yield call(failCallback, result)
  }
}

function* onHandleCreateHealthDeviceOnUsersPage(action) {
  const device = action.payload
  yield call(onHandleCreateHealthDevice, device, onCreateHealthDeviceOnUsersPageSuccess, onCreateDeviceFailed)
}

function* onHandleCreateHealthDeviceOnProfilePage(action) {
  const device = action.payload
  yield call(onHandleCreateHealthDevice, device, onCreateHealthDeviceSuccess, onCreateDeviceFailed)
}

function* onHandleCreateHealthDeviceOnDevicesPage(action) {
  const device = action.payload
  yield call(onHandleCreateHealthDevice, device, coreOnAddDeviceSuccess, onCreateDeviceFailed)
}

function* onHandleDeleteHealthDeviceOnProfilePage(action) {
  const deletedDeviceId = action.payload
  yield put(healthActions.deleteHealthDeviceRequest(deletedDeviceId))

  const result = yield take([healthActions.deleteHealthDeviceSuccess, healthActions.deleteHealthDeviceFailed])
  if (result.type === healthActions.deleteHealthDeviceSuccess().type) {
    yield call(deleteHealthDeviceSuccess)
  }
}

function* onHandleDeleteHealthDeviceOnDevicesPage(action) {
  const deletedDeviceId = action.payload
  yield put(healthActions.deleteHealthDeviceRequest(deletedDeviceId))

  const result = yield take([healthActions.deleteHealthDeviceSuccess, healthActions.deleteHealthDeviceFailed])
  if (result.type === healthActions.deleteHealthDeviceSuccess().type) {
    yield call(deleteDeviceSuccess)
  }
}

function* healthDevicesSagas() {
  yield all([
    takeLatest(healthActions.handleCreateHealthDeviceOnProfilePage, onHandleCreateHealthDeviceOnProfilePage),
    takeLatest(healthActions.handleCreateHealthDeviceOnDevicesPage, onHandleCreateHealthDeviceOnDevicesPage),
    takeLatest(healthActions.handleCreateHealthDeviceOnUsersPage, onHandleCreateHealthDeviceOnUsersPage),
    takeLatest(healthActions.handleDeleteHealthDeviceOnProfilePage, onHandleDeleteHealthDeviceOnProfilePage),
    takeLatest(healthActions.handleDeleteHealthDeviceOnDevicesPage, onHandleDeleteHealthDeviceOnDevicesPage),
    takeLatest(healthActions.onGetLife365Device, onGetLife365Device),
    takeLatest(healthActions.getAssignedLife365DeviceStatusSuccess, getAssignedLife365DeviceStatusSuccess),
  ])
}

export default healthDevicesSagas
