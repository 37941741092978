import { useSelector } from 'react-redux'

import routes from '@tabeeb/routes'
import SwitchTenantAutocomplete from '@tabeeb/modules/navbar/components/SwitchTenantAutocomplete'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { TenantPermission } from '@tabeeb/enums'
import { getIsSwitchTenantFeatureEnabled } from '@tabeeb/modules/appConfigState/selectors'

import NavbarTab from '../NavbarTab'

const Tenants = ({ ...rest }) => {
  const hasAllTenantsAccess = useSelector((state) => hasTenantPermission(state, TenantPermission.AllTenantsAccess))
  const isSwitchTenantFeatureEnabled = useSelector(getIsSwitchTenantFeatureEnabled)

  const props = {
    item: {
      label: Tenants.label,
      childComponent: SwitchTenantAutocomplete,
    },
    display: hasAllTenantsAccess || isSwitchTenantFeatureEnabled,
    ...rest,
  }

  return <NavbarTab {...props} />
}

Tenants.to = routes.tenants
Tenants.label = 'Tenants'

export default Tenants
