import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getPowerBIReportState } from '@tabeeb/modules/powerBIReports/selectors'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  resetPowerBIReport,
  getPowerBIEmbedToken,
  getPowerBIReportEmbedData,
} from '@tabeeb/modules/powerBIReports/actions'
import { powerBIEmbedTokenLifetime } from '@tabeeb/modules/powerBIReports/constants'
import PowerBIReport from '@tabeeb/modules/powerBIReports/components/PowerBIReport'
import usePrevious from '@tabeeb/shared/utils/hooks/usePreviuos'
import _ from 'lodash'
import { FetchStatus } from '@tabeeb/enums'
import { Box } from '@mui/system'
import { CircularProgress, Typography } from '@mui/material'
import PreTag from '@tabeeb/uikit/components/PreTag'

const PowerBIReportContainer = ({ id }) => {
  const [embeddedReport, setEmbeddedReport] = useState(null)

  const dispatch = useDispatch()
  const { report, fetchStatus, errorDescriptionObject } = useSelector(getPowerBIReportState)
  const prevReport = usePrevious(report)
  const tokenTimerRef = useRef(null)

  useEffect(() => {
    dispatch(getPowerBIReportEmbedData.request(id))

    tokenTimerRef.current = setInterval(() => {
      dispatch(getPowerBIEmbedToken.request(id))
    }, powerBIEmbedTokenLifetime)

    return () => {
      clearInterval(tokenTimerRef.current)
      dispatch(resetPowerBIReport())
    }
  }, [dispatch, id])

  useEffect(() => {
    ;(async () => {
      if (embeddedReport && prevReport && report.EmbedToken !== prevReport.EmbedToken) {
        await embeddedReport.setAccessToken(report.EmbedToken)
      }
    })()
  }, [embeddedReport, prevReport, report.EmbedToken])

  if (fetchStatus !== FetchStatus.Loaded) {
    return (
      <Box
        sx={{
          widht: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {fetchStatus === FetchStatus.Loading && <CircularProgress />}
        {fetchStatus === FetchStatus.Failed && (
          <>
            <Typography sx={{ textAlign: 'center' }}>Failed to open report.</Typography>
            {errorDescriptionObject && <PreTag>{JSON.stringify(errorDescriptionObject, null, 2)}</PreTag>}
          </>
        )}
      </Box>
    )
  }

  const reportProps = {
    report,
    setEmbeddedReport,
  }

  return <PowerBIReport {...reportProps} />
}

PowerBIReportContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default PowerBIReportContainer
