import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'
import { FetchStatus } from '@tabeeb/enums'
import usePowerBIDevelopers from '@tabeeb/modules/powerBIReportProviders/hooks/userPowerBIDevelopers'
import { useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'
import { UserInfo } from '@tabeeb/uikit'

const PowerBIDevelopersSelect = ({ fieldValue = [], onChange }) => {
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebouncedValue(search, 500)

  const { fetchStatus, users } = usePowerBIDevelopers(debouncedSearch)

  const onSearchChange = useCallback((event) => {
    const { value } = event.target
    setSearch(value)
  }, [])

  const userOptions = useMemo(() => {
    if (!users) {
      return []
    }
    return users.map((u) => ({
      Name: u.userName,
      Email: u.email,
      identityGuid: u.identityGuid,
    }))
  }, [users])

  return (
    <Autocomplete
      multiple
      fullWidth
      size='small'
      options={userOptions}
      value={fieldValue || []}
      disableClearable
      filterOptions={(options) => {
        return options.filter((option) => !fieldValue.some((id) => id === option.identityGuid))
      }}
      loading={fetchStatus === FetchStatus.Loading}
      onInputChange={onSearchChange}
      onChange={onChange}
      renderTags={() => {
        return null
      }}
      getOptionLabel={(option) => option.Email}
      renderOption={(props, option) => (
        <li {...props} key={option.identityGuid}>
          <UserInfo item={option} />
        </li>
      )}
      renderInput={(params) => <TextField {...params} placeholder='Search name or email...' />}
    />
  )
}

PowerBIDevelopersSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldValue: PropTypes.arrayOf(PropTypes.string),
}

export default PowerBIDevelopersSelect
