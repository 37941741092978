import { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import { ConfirmationDialog } from '@tabeeb/uikit'

import './styles.less'

const FileUploadsControls = ({
  buttonColor,
  isDialogOpened,
  isRetryButtonDisabled,
  handleClose,
  handleRetryAll,
  handleCancelClosing,
  handleSubmitClosing,
}) => {
  const buttonStyles = {
    backgroundColor: buttonColor,
  }

  const props = {
    onCloseDialog: handleCancelClosing,
    onSubmit: handleSubmitClosing,
    isOpen: isDialogOpened,
    dialogMessage: 'Closing the tab will cancel the file uploading. Continue?',
    title: `Close file uploading?`,
  }

  return (
    <>
      <div className='file-uploads-controls-container'>
        <Button
          onClick={handleClose}
          className='file-uploads-control'
          style={buttonStyles}
          variant='contained'
          title='Close'
        >
          Close
        </Button>
        <Button
          disabled={isRetryButtonDisabled}
          onClick={handleRetryAll}
          className='file-uploads-control'
          style={buttonStyles}
          variant='contained'
          title='Retry'
        >
          Retry
        </Button>
      </div>
      <ConfirmationDialog {...props} />
    </>
  )
}

FileUploadsControls.propTypes = {
  buttonColor: PropTypes.string.isRequired,
  isDialogOpened: PropTypes.bool.isRequired,
  isRetryButtonDisabled: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRetryAll: PropTypes.func.isRequired,
  handleCancelClosing: PropTypes.func.isRequired,
  handleSubmitClosing: PropTypes.func.isRequired,
}

export default FileUploadsControls
