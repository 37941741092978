import { memo } from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, Icon, IconButton } from '@material-ui/core'

import MessageText from '../MessageText'

import useStyles from './styles'
import { TranslatedMessageDisplayVariant } from '../../constans'

const ChatTranslatableText = ({
  text,
  displayVariant,
  isTranslationReady,
  isTranslationEnabled,
  isTranslationAvailable,
  isTranslationInProgress,
  onSwitchVariant,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <MessageText text={text} />

      {isTranslationEnabled && isTranslationAvailable && (
        <>
          {isTranslationReady && (
            <IconButton className={classes.button} size='small' onClick={onSwitchVariant}>
              <Icon
                className={classes.icon}
                title={
                  displayVariant === TranslatedMessageDisplayVariant.Translated
                    ? 'Switch to original message'
                    : 'Switch to translated variant'
                }
              >
                {displayVariant === TranslatedMessageDisplayVariant.Translated ? 'public' : 'g_translate'}
              </Icon>
            </IconButton>
          )}

          {isTranslationInProgress && <CircularProgress size='1.4rem' className={classes.progress} />}
        </>
      )}
    </div>
  )
}

ChatTranslatableText.propTypes = {
  text: PropTypes.string,
  onSwitchVariant: PropTypes.func.isRequired,
  displayVariant: PropTypes.oneOf(Object.values(TranslatedMessageDisplayVariant)).isRequired,
  isTranslationReady: PropTypes.bool.isRequired,
  isTranslationEnabled: PropTypes.bool.isRequired,
  isTranslationAvailable: PropTypes.bool.isRequired,
  isTranslationInProgress: PropTypes.bool.isRequired,
}

export default memo(ChatTranslatableText)
