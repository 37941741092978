import { memo } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { AnswerStatuses, DisplayAnswersMode } from '@tabeeb/enums'

import { withStyles } from '@material-ui/core'
import AnswerStatusFilter from '@tabeeb/modules/forms/components/AnswerStatusFilter'
import { getDisplayAnswersMode, getFormAnswerStatusStatistics } from '@tabeeb/modules/forms/selectors'
import styles from './styles'

const statuses = {
  Submitted: AnswerStatuses.Pending,
  Approved: AnswerStatuses.Approve,
  Rejected: AnswerStatuses.Reject,
}

const AnswerStatusStatistics = ({ classes }) => {
  const displayAnswersMode = useSelector(getDisplayAnswersMode)
  const answerStatusStatistics = useSelector(getFormAnswerStatusStatistics)

  if (displayAnswersMode !== DisplayAnswersMode.AllLatest) return null

  const _getStatusCount = (answerStatus) => {
    return answerStatusStatistics.find((st) => st.FormAnswerStatus === answerStatus)?.Count || 0
  }

  const _getCssClass = (answerStatus) => {
    switch (answerStatus) {
      case AnswerStatuses.Pending:
        return classes.pending
      case AnswerStatuses.Approve:
        return classes.approve
      case AnswerStatuses.Reject:
        return classes.reject
      default:
        return null
    }
  }

  return (
    <div className={classes.container}>
      {Object.keys(statuses).map((key) => (
        <div key={key}>
          <span>{`${key}: `}</span>
          <span className={_getCssClass(statuses[key])}>{_getStatusCount(statuses[key])}</span>
        </div>
      ))}
      <AnswerStatusFilter />
    </div>
  )
}

AnswerStatusStatistics.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    pending: PropTypes.string.isRequired,
    approve: PropTypes.string.isRequired,
    reject: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(AnswerStatusStatistics))
