import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import VisibilitySensor from '@tabeeb/modules/shared/utils/imageUtils'

import ChatMessageAsset from '../../../modules/ChatMessageAsset'
import MessageText from '../../../containers/ChatTranslatableTextContainer'

import * as chatActions from '../../../actions'

import useStyles from './styles'

const MessageCloud = ({ containmentRef, message }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const markAsViewed = useCallback(
    (isVisible) => {
      if (!isVisible || !message.unread) {
        return
      }

      dispatch(chatActions.markAsRead(message))
    },
    [dispatch, message]
  )

  return (
    <VisibilitySensor
      once
      active={!message.self}
      scrollDelay={50}
      intervalCheck
      containment={containmentRef}
      onChange={markAsViewed}
    >
      <div
        className={classNames(classes.container, {
          [classes.self]: message.self,
        })}
      >
        {message.asset && <ChatMessageAsset asset={message.asset} />}
        <MessageText messageData={message} />
      </div>
    </VisibilitySensor>
  )
}

MessageCloud.propTypes = {
  containmentRef: PropTypes.object,
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    asset: PropTypes.object,
    message: PropTypes.string,
    translations: PropTypes.object,
    self: PropTypes.bool.isRequired,
    unread: PropTypes.bool.isRequired,
  }).isRequired,
}

export default memo(MessageCloud)
