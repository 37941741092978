import { handleActions } from 'redux-actions'
import { snapshotDeviceFilters } from '../actions'

const defaultState = {}

export default handleActions(
  {
    [snapshotDeviceFilters](state, action) {
      return action.payload
    },
  },
  defaultState
)
