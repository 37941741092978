import { memo } from 'react'
import PropTypes from 'prop-types'

import { CollectionsOutlined } from '@mui/icons-material'

import SessionBadge from '../SessionBadge'

const ContentPagesCountBadge = ({ pages }) => {
  return <SessionBadge icon={CollectionsOutlined} title={`${pages} page(s)`} label={pages} />
}

ContentPagesCountBadge.propTypes = {
  pages: PropTypes.number.isRequired,
}

export default memo(ContentPagesCountBadge)
