import { Autocomplete, TextField, useTheme } from '@mui/material'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { darken, lighten } from '@mui/system'

const AutocompleteLocations = ({ getLocationsResponse, autocompleteProps, inputProps, onChange, loading, value }) => {
  const theme = useTheme()
  const locationsOptions = useMemo(
    () =>
      getLocationsResponse.sort(
        (a, b) => -b.Country.Name.localeCompare(a.Country.Name) || -b.Name.localeCompare(a.Name)
      ),
    [getLocationsResponse]
  )
  return (
    <Autocomplete
      sx={{ flexGrow: 1 }}
      ChipProps={{ size: 'small', sx: { mb: '2px !important' } }}
      renderInput={(params) => (
        <TextField {...params} label='Locations' variant='standard' placeholder='Search locations...' {...inputProps} />
      )}
      options={locationsOptions}
      groupBy={(option) => option.Country.Name}
      getOptionLabel={(option) => option.Name}
      renderOption={(props, option) => (
        <li {...props} key={option.Id}>
          {option.Name}
        </li>
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <div
            style={{
              position: 'sticky',
              top: '-8px',
              padding: '4px 10px',
              color: theme.palette.primary.main,
              backgroundColor:
                theme.palette.mode === 'light'
                  ? lighten(theme.palette.primary.light, 0.85)
                  : darken(theme.palette.primary.main, 0.8),
            }}
          >
            {params.group}
          </div>
          <ul style={{ padding: 0 }}>{params.children}</ul>
        </li>
      )}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={(option, optionValue) => option.Id === optionValue.Id}
      loading={loading}
      filterOptions={(options, { inputValue }) => {
        return options.filter(
          (o) =>
            o.Name.toLowerCase().includes(inputValue.toLowerCase()) ||
            o.Country.Name.toLowerCase().includes(inputValue.toLowerCase())
        )
      }}
      {...autocompleteProps}
    />
  )
}

AutocompleteLocations.propTypes = {
  getLocationsResponse: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      CountryId: PropTypes.number.isRequired,
      Country: PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
      }),
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  autocompleteProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      IsDeleted: PropTypes.bool.isRequired,
      CreatedOn: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
}

export default AutocompleteLocations
