import { Typography, styled } from '@mui/material'

export const NavigationLinkTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 600,
  maxWidth: 180,
  cursor: 'pointer',

  transition: theme.transitions.create(['color', 'opacity', 'textDecoration']),

  '&:hover': {
    opacity: 0.7,
    textDecoration: 'underline',
  },

  '&[data-selected="true"]': {
    textDecoration: 'underline',
    fontWeight: 800,
  },
}))
