export default (theme) => {
  const tabCommon = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRight: '1px solid #fff',
    borderBottom: '1px solid #fff',
    '&:last-child': {
      borderRight: 'none',
    },
  }

  return {
    tabSelected: {
      ...tabCommon,
      borderBottom: '2px solid #0089cf',
    },
    tab: {
      ...tabCommon,
      backgroundColor: '#eee',
    },
    text: {
      maxWidth: '80%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  }
}
