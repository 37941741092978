import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const getPowerBIReportProviders = createApiActions('GET_POWER_BI_REPORT_PROVIDERS')
export const createPowerBIReportProvider = createApiActions('CREATE_POWER_BI_REPORT_PROVIDER')
export const disableReportProvider = createApiActions('DISABLE_REPORT_PROVIDER')
export const enableReportProvider = createApiActions('ENABLE_REPORT_PROVIDER')
export const deletePowerBIReportProvider = createApiActions('DELETE_POWER_BI_REPORT_PROVIDER')
export const checkPowerBIReportProviderConnection = createApiActions('CHECK_POWER_BI_REPORT_PROVIDER_CONNECTION')
export const getPowerBIReportProvider = createApiActions('GET_POWER_BI_REPORT_PROVIDER')
export const updatePowerBIReportProvider = createApiActions('UPDATE_POWER_BI_REPORT_PROVIDER')
export const updatePowerBIReportProviderConfiguration = createApiActions(
  'UPDATE_POWER_BI_REPORT_PROVIDER_CONFIGURATION'
)
export const updatePowerBIReportProviderUsers = createApiActions('UPDATE_POWER_BI_REPORT_PROVIDER_USERS')
export const getPowerBIReportProviderConfiguration = createApiActions('GET_POWER_BI_REPORT_PROVIDER_CONFIGURATION')
export const getPowerBIReportProviderUsers = createApiActions('GET_POWER_BI_REPORT_PROVIDER_USERS')

export const loadPowerBIReportProviders = createAction('LOAD_POWER_BI_REPORT_PROVIDERS')
export const setPowerBIReportProvidersFilters = createAction('SET_POWER_BI_REPORT_PROVIDERS_FILTERS')

export const setPowerBIReportProviderConfigurationFetchRequired = createAction(
  'SET_POWER_BI_REPORT_PROVIDER_CONFIGURATION_REFETCH'
)

export const resetCheckPowerBIReportProviderConnection = createAction('RESET_CHECK_POWER_BI_REPORT_PROVIDER_CONNECTION')

export const openPowerBIProviderModal = createAction('OPEN_POWERBI_PROVIDER_MODAL')
export const closePowerBIProviderModal = createAction('CLOSE_POWERBI_PROVIDER_MODAL')
