import { signalrActions } from '@tabeeb/modules/signalr'
import { autodeskHubName } from '@tabeeb/modules/signalr/services/constants'

export const subscribeToAutodeskUpdates = ({ pageId }) => {
  return signalrActions.invokeHubAction({
    method: 'SubscribeToWebhookUpdates',
    args: [pageId],
    hubName: autodeskHubName,
  })
}

export const unsubscribeFromAutodeskUpdates = ({ pageId }) =>
  signalrActions.invokeHubAction({ method: 'UnsubscribeFromWebhookUpdates', args: [pageId], hubName: autodeskHubName })
