import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ContentSharingModelType } from '@tabeeb/enums'
import { SharedContentDataType } from '@tabeeb/modules/contentSharings/constants/enums'
import ShareSessionDialogContainer from '@tabeeb/modules/sessionsPage/containers/ShareSessionDialogContainer'
import { getIsContentOwner } from '@tabeeb/modules/sessionsPage/selectors'

const ShareSessionsDialog = ({ open, onClose, sessions }) => {
  const isSingleSession = sessions.length === 1
  const isOwner = useSelector((state) => (isSingleSession ? getIsContentOwner(state, sessions[0]) : false))

  return (
    <ShareSessionDialogContainer
      open={open}
      onClose={onClose}
      isMultipleSessions={!isSingleSession}
      contentSharingType={isOwner ? ContentSharingModelType.SharedByOwner : ContentSharingModelType.SharedByAdmin}
      session={isSingleSession ? sessions[0] : null}
      dataType={SharedContentDataType.AllData}
    />
  )
}

ShareSessionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}

export default memo(ShareSessionsDialog)
