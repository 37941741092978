import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as rawActions from '../actions'

import ExpandButton from '../components/ExpandButton'

class ExpandButtonContainer extends Component {
  handleExpandClick = (e) => {
    const { actions, isGalleryExpanded } = this.props

    isGalleryExpanded ? actions.disableExpandedGallery() : actions.enableExpandedGallery()
  }

  render() {
    const { isGalleryExpansionEnabled, isGalleryExpanded, currentPanel } = this.props

    const props = {
      isGalleryExpanded,
      handleExpandClick: this.handleExpandClick,
    }

    return isGalleryExpansionEnabled && (currentPanel === TabPanel.Gallery || currentPanel === TabPanel.FileUploads) ? (
      <ExpandButton {...props} />
    ) : null
  }
}

ExpandButtonContainer.propTypes = {
  isGalleryExpanded: PropTypes.bool.isRequired,
  isGalleryExpansionEnabled: PropTypes.bool.isRequired,
  currentPanel: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    enableExpandedGallery: PropTypes.func.isRequired,
    disableExpandedGallery: PropTypes.func.isRequired,
  }),
}

const mapStateToProps = (state) => ({
  isGalleryExpanded: state.gallery.isGalleryExpanded,
  isGalleryExpansionEnabled: state.appConfigState.isGalleryExpansionEnabled,
  currentPanel: state.navbar.currentPanel[TabPanelLocation.Right],
})

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandButtonContainer)
