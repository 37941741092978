import { combineReducers } from 'redux'

import isAddClassificationAnnotationsDialogOpen from './isAddClassificationAnnotationsDialogOpen'
import isProcessing from './isProcessing'
import isTimelineOpen from './isTimelineOpen'
import pagesList from './pagesList'

export default combineReducers({
  isAddClassificationAnnotationsDialogOpen,
  isProcessing,
  isTimelineOpen,
  pagesList,
})
