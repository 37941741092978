import { handleActions } from 'redux-actions'

import { setPageLayout } from '../actions'
import { LayoutType } from '../enums'

export default handleActions(
  {
    [setPageLayout](_, { payload: layout }) {
      return layout || LayoutType.Grid
    },
  },
  LayoutType.Grid
)
