import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      overflow: 'overlay',
      margin: '0 auto',

      borderRadius: theme.shape.borderRadius,
    },
    hidden: {
      height: '0%',
    },
    mirrored: {
      transform: 'rotateY(180deg)',
    },
    video: {
      width: '100%',
      height: '100%',
    },
    videoZoomed: {
      width: 'auto',
    },
  }
})
