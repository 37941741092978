import { createAction } from 'redux-actions'

export const getConfigRequest = createAction('GET_CONFIG_REQUEST')
export const getConfigSuccess = createAction('GET_CONFIG_SUCCESS')
export const getConfigFailed = createAction('GET_CONFIG_FAILED')

export const getVideoBridgeConfigRequest = createAction('GET_VIDEO_BRIDGE_CONFIG_REQUEST')
export const getVideoBridgeConfigSuccess = createAction('GET_VIDEO_BRIDGE_CONFIG_SUCCESS')
export const getVideoBridgeConfigFailed = createAction('GET_VIDEO_BRIDGE_CONFIG_FAILED')

export const onSetAppConfigState = createAction('SET_APP_CONFIG_STATE')
