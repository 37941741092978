import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [combineActions(rawActions.addPageToSelectedPagesForExclusion)](state, { payload: pageForExclusion }) {
      if (state.some((page) => page.Id === pageForExclusion.Id)) {
        return state
      }

      return [...state, pageForExclusion]
    },
    [combineActions(rawActions.addPagesToSelectedPagesForExclusion)](state, { payload: pagesForExclusion }) {
      const notExcludedPages = pagesForExclusion.filter((pfe) => !state.some((page) => page.Id === pfe.Id))

      const result = [...state, ...notExcludedPages]

      return result
    },
    [combineActions(rawActions.deletePageFromSelectedPagesForExclusion)](state, { payload }) {
      return state.filter((page) => page.Id !== payload.Id)
    },
    [combineActions(rawActions.deletePagesFromSelectedPagesForExclusion)](state, { payload: pagesForInclusion }) {
      const result = state.filter((page) => !pagesForInclusion.some((pfe) => pfe.Id === page.Id))
      return result
    },
    [combineActions(
      rawActions.setSelectedProject,
      rawActions.resetPagesForProjectTraining,
      rawActions.resetSelectedPagesForExclusion
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
