import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

let bars = []

let audioContext = null
let analyser = null
let input = null
let scriptProcessor = null
let drawing = false

const StreamVisualizer = ({ stream, enabled, width, height, barColor, barWidth, barGutter }) => {
  const canvasRef = useRef()

  useEffect(() => {
    fillBarsWithDefaultValues()
    renderBars()

    return () => {
      bars = []
    }
  }, [canvasRef.current])

  const fillBarsWithDefaultValues = () => {
    bars = []

    const countOfBars = width / (barWidth + barGutter)
    for (let i = 0; i < countOfBars; i++) {
      bars.push(1)
    }
  }

  const getAverageVolume = (array) => {
    const { length } = array
    let values = 0

    for (let i = 0; i < length; i++) {
      values += array[i] + 1
    }

    return Math.max(values / length)
  }

  const renderBars = () => {
    if (!drawing) {
      drawing = true

      window.requestAnimationFrame(() => {
        const canvas = canvasRef.current
        if (!canvas) {
          return
        }
        const canvasContext = canvas.getContext('2d')

        canvasContext.clearRect(0, 0, width, height)

        const halfHeight = height / 2

        bars.forEach((bar, index) => {
          canvasContext.fillStyle = barColor

          canvasContext.fillRect(
            index * (barWidth + barGutter),
            halfHeight - halfHeight * (bar / 100),
            barWidth,
            halfHeight * (bar / 100)
          )
          canvasContext.fillRect(index * (barWidth + barGutter), halfHeight, barWidth, halfHeight * (bar / 100))
        })

        drawing = false
      })
    }
  }

  const processInput = () => {
    const tempArray = new Uint8Array(analyser.frequencyBinCount)

    analyser.getByteFrequencyData(tempArray)

    bars.push(getAverageVolume(tempArray))
    if (bars.length > width / (barWidth + barGutter)) {
      bars.shift()
    }

    renderBars(bars)
  }

  useEffect(() => {
    if (enabled && stream) {
      fillBarsWithDefaultValues()

      // Create the audio nodes
      audioContext = new AudioContext()

      input = audioContext.createMediaStreamSource(stream)
      analyser = audioContext.createAnalyser()
      scriptProcessor = audioContext.createScriptProcessor()

      analyser.smoothingTimeConstant = 0.3
      analyser.fftSize = 1024

      // Connect the audio nodes
      input.connect(analyser)
      analyser.connect(scriptProcessor)
      scriptProcessor.connect(audioContext.destination)

      // Add an event handler
      scriptProcessor.onaudioprocess = processInput
    }

    return () => {
      if (analyser) {
        analyser.disconnect()
        analyser = null
      }

      if (input) {
        input.disconnect()
        input = null
      }

      if (scriptProcessor) {
        scriptProcessor.onaudioprocess = null
        scriptProcessor.disconnect()
        scriptProcessor = null
      }

      if (audioContext) {
        audioContext.close()
        audioContext = null
      }
    }
  }, [enabled])

  return (
    <div>
      <canvas ref={canvasRef} width={width} height={height} />
    </div>
  )
}

StreamVisualizer.defaultProps = {
  height: 100,
  width: 200,
  barWidth: 2,
  barGutter: 1,
  barColor: '#49F1D5',
}

StreamVisualizer.propTypes = {
  stream: PropTypes.object,
  enabled: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  barColor: PropTypes.string.isRequired,
  barWidth: PropTypes.number,
  barGutter: PropTypes.number,
}

export default StreamVisualizer
