import { memo } from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, Paper, Slide } from '@material-ui/core'

import useStyles from './styles'

const LoadingSpinner = ({ loading }) => {
  const classes = useStyles()

  return (
    <Slide in={loading} timeout={250} unmountOnExit>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <CircularProgress size='2rem' />
        </Paper>
      </div>
    </Slide>
  )
}

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default memo(LoadingSpinner)
