import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ValidateValueLength } from '../../../../tenantSessionsPage/helpers/validator'

const InlineEditSpan = ({ classNameSpan, handleOnMouseDownSpan, value, disabled, title }) => {
  const contentId = useSelector((state) => state.contentState.contentId)
  return (
    <span disabled={disabled} className={classNameSpan} onMouseDown={handleOnMouseDownSpan} title={title || value}>
      {contentId ? value : ValidateValueLength(value, 15)}
    </span>
  )
}

InlineEditSpan.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  classNameSpan: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  handleOnMouseDownSpan: PropTypes.func.isRequired,
}

export default InlineEditSpan
