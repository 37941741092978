import { Views } from 'react-big-calendar'
import Month from 'react-big-calendar/lib/Month'
import Week from 'react-big-calendar/lib/Week'
import Day from 'react-big-calendar/lib/Day'
import { Year } from '../components/Calendar/Views'

export const EVENTS_BATCH_SIZE = 250

export const TIME_INTERVAL_IN_MINUTES = 5

export const TIME_SLOTS = 60 / TIME_INTERVAL_IN_MINUTES

export const ATTACH_SESSION_MODES = {
  CREATE_NEW: 'CREATE_NEW',
  ATTACH_EXISTING: 'ATTACH_EXISTING',
}

export const CalendarViews = {
  Day: Views.DAY,
  Week: Views.WEEK,
  Month: Views.MONTH,
  Year: 'year',
}

export const DEFAULT_VIEW = CalendarViews.Week

export const VIEWS_COMPONENTS = {
  [CalendarViews.Day]: Day,
  [CalendarViews.Week]: Week,
  [CalendarViews.Month]: Month,
  [CalendarViews.Year]: Year,
}

export const AVAILABLE_VIEWS = Object.keys(VIEWS_COMPONENTS)
