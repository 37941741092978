import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.openExportImagesDialog](state, action) {
      return true
    },
    [rawActions.closeExportImagesDialog](state, action) {
      return false
    },
    [rawActions.resetGalleryState](state, action) {
      return false
    },
  },
  false
)
