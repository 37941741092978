import { useState, useCallback } from 'react'

export default function useDialogState() {
  const [open, setIsOpen] = useState(false)

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return [open, onOpen, onClose]
}
