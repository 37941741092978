import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    popper: {
      zIndex: theme.zIndex.modal,
    },
    container: {
      height: 290,
      width: 374,
      overflowY: 'scroll',
      overflowX: 'hidden',
      position: 'relative',
    },
  }
})
