import * as calendarViewActions from './view'
import * as calendarEventsActions from './events'
import * as calendarDialogsActions from './dialogs'
import * as calendarSignalrActions from './signalr'
import * as calendarSubscriptionsActions from './subscriptions'

export default {
  ...calendarViewActions,
  ...calendarEventsActions,
  ...calendarDialogsActions,
  ...calendarSignalrActions,
  ...calendarSubscriptionsActions,
}
