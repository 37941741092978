import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    paper: {
      position: 'relative',
      padding: theme.spacing(),
      marginBottom: theme.spacing(3),
    },
    chartName: {
      position: 'absolute',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      top: -12,
      left: 8,
      background: 'white',
    },
  }
})
