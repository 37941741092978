const styles = () => ({
  avatarWrapper: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    minHeight: '100px',
    maxHeight: '300px',
    width: '100%',
    borderRadius: '4px',
    border: 'solid 1px rgba(0, 0, 0, 0.23)',
    overflow: 'auto',
    '&:hover ul': {
      opacity: 1,
    },
  },
  controls: {
    position: 'absolute',
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: '0.4s',
    width: '100%',
    color: 'white',
    backgroundColor: 'rgba(66,66, 66, 0.5)',
    bottom: 0,
    left: 0,
  },
  avatar: {
    objectFit: 'cover',
    width: '100%',
  },
  initials: {
    fontSize: 40,
    margin: 32,
    height: 128,
    width: 128,
  },
  avatarButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default styles
