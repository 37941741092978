import { handleActions } from 'redux-actions'
import _ from 'lodash'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.setFoldersList](state, action) {
      return _.sortBy(action.payload, (f) => f.Name)
    },
    [rawActions.updateSessionFolder](state, action) {
      const folder = action.payload

      let newState = state.map((f) => (f.Id === folder.Id ? folder : f))

      newState = _.sortBy(newState, (f) => f.Name)

      return newState
    },
    [rawActions.addSessionFolder](state, action) {
      const folder = action.payload

      let newState = state.filter((f) => f.Id !== folder.Id)

      newState.push(folder)
      newState = _.sortBy(newState, (f) => f.Name)

      return newState
    },
    [rawActions.removeSessionFolder](state, action) {
      const id = action.payload

      const newState = state.filter((f) => f.Id !== id)

      return newState
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
