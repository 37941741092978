import PropTypes from 'prop-types'
import classNames from 'classnames'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getIsSelectedPagesForMergingNavbarTabActive } from '@tabeeb/modules/contentSharings/selectors'
import { getIsFolderAddedForMerging } from '../../selectors'
import { addFolderForMerging } from '../../actions'
import './styles.less'

const Folder = ({
  folder,
  handleSelectFolder,
  handleDeleteFolder,
  canRename,
  d3dModel,
  bentleyModel,
  spatialView,
  renaming,
  customVideo,
  shareSessionMode,
  structureModel,
  classificationAnnotations,
  dragAndDrop,
  isExpanded,
  reviewSessionMode,
  canDelete,
  handleEdit,
  splatModel,
}) => {
  const dispatch = useDispatch()
  const isSelectedPagesForMergingOpen = useSelector(getIsSelectedPagesForMergingNavbarTabActive)
  const isFolderAddedForMerging = useSelector((state) => getIsFolderAddedForMerging(state, { folderId: folder.Id }))

  const addButtonClasses = classNames('gallery-item-add-button', {
    selected:
      (customVideo.isOpen && customVideo.isAdded) ||
      (spatialView.isOpen && spatialView.isAdded) ||
      (d3dModel.isOpen && d3dModel.isAdded) ||
      (shareSessionMode.isOpen && shareSessionMode.isAdded) ||
      (structureModel.isOpen && structureModel.isAdded) ||
      (reviewSessionMode.isOpen && reviewSessionMode.isAdded) ||
      (bentleyModel.isOpen && bentleyModel.isAdded) ||
      (isSelectedPagesForMergingOpen && isFolderAddedForMerging) ||
      (classificationAnnotations.isOpen && classificationAnnotations.isAdded) ||
      (splatModel.isOpen && splatModel.isAdded),
  })

  const isTimelineOpen =
    spatialView.isOpen ||
    customVideo.isOpen ||
    d3dModel.isOpen ||
    shareSessionMode.isOpen ||
    structureModel.isOpen ||
    reviewSessionMode.isOpen ||
    bentleyModel.isOpen ||
    isSelectedPagesForMergingOpen ||
    classificationAnnotations.isOpen ||
    splatModel.isOpen

  const handleAddFolderToTimeLine = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (spatialView.isOpen) {
      spatialView.handleAddFolder()
    } else if (customVideo.isOpen) {
      customVideo.handleAddFolder()
    } else if (d3dModel.isOpen) {
      d3dModel.handleAddFolder()
    } else if (shareSessionMode.isOpen) {
      shareSessionMode.handleAddFolder()
    } else if (structureModel.isOpen) {
      structureModel.handleAddFolder()
    } else if (reviewSessionMode.isOpen) {
      reviewSessionMode.handleAddFolder()
    } else if (bentleyModel.isOpen) {
      bentleyModel.handleAddFolder()
    } else if (isSelectedPagesForMergingOpen) {
      dispatch(addFolderForMerging({ folderId: folder.Id }))
    } else if (classificationAnnotations.isOpen) {
      classificationAnnotations.handleAddFolder()
    } else if (splatModel.isOpen) {
      splatModel.handleAddFolder()
    }
  }

  const showControls = !isTimelineOpen && !renaming.isRenaming && (canRename || canDelete)

  return (
    <div
      className={classNames('session-folders-item', { expanded: isExpanded })}
      onClick={handleSelectFolder}
      {...dragAndDrop}
    >
      <div className='layout' data-id={folder.Id} title={folder.Name} />
      <i className={classNames('material-icons', 'session-folders-item-icon')}>folder</i>
      {!renaming.isRenaming && (
        <span className='session-folders-item-name' showcontrols={`${showControls}`}>
          {folder.Name}
        </span>
      )}
      <TextField
        name='folder-name-input'
        className='tabeeb-rename-folder-textbox'
        disabled={false}
        inputRef={renaming.inputRef}
        onClick={(e) => e.stopPropagation()}
        onBlur={renaming.submit}
        onChange={renaming.onChange}
        onKeyDown={renaming.onKeyDown}
        style={{ width: renaming.isRenaming ? 110 : 0 }}
      />
      {showControls && (
        <>
          <i className='tabeeb-icon-edit-black' onClick={handleEdit} style={{ right: canDelete ? 20 : 4 }} />
          {canDelete && <i className='tabeeb-icon-trash-empty' onClick={handleDeleteFolder} />}
        </>
      )}
      {isTimelineOpen && (
        <span className={addButtonClasses} onClick={handleAddFolderToTimeLine}>
          <AddCircleIcon color='inherit' fontSize='inherit' />
        </span>
      )}
    </div>
  )
}

Folder.propTypes = {
  folder: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
  handleSelectFolder: PropTypes.func.isRequired,
  handleDeleteFolder: PropTypes.func.isRequired,
  canRename: PropTypes.bool.isRequired,
  renaming: PropTypes.shape({
    isRenaming: PropTypes.bool.isRequired,
    inputRef: PropTypes.func.isRequired,
    start: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
  }).isRequired,
  spatialView: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  customVideo: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  d3dModel: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  bentleyModel: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  shareSessionMode: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  structureModel: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  dragAndDrop: PropTypes.shape({
    isdragon: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    onDragOver: PropTypes.func.isRequired,
    onDragEnter: PropTypes.func.isRequired,
    onDragLeave: PropTypes.func.isRequired,
  }),
  reviewSessionMode: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  classificationAnnotations: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  splatModel: PropTypes.shape({
    isOpen: PropTypes.bool,
    isAdded: PropTypes.bool,
    handleAddFolder: PropTypes.func.isRequired,
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
}

export default Folder
