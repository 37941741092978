import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  data: null,
  isOpen: false,
}

export default handleActions(
  {
    [rawActions.openMakePresenterDialog]: (state, action) => {
      return {
        data: action.payload,
        isOpen: true,
      }
    },
    [rawActions.closeMakePresenterDialog]: (state, action) => {
      return {
        data: null,
        isOpen: false,
      }
    },
    [rawActions.resetWhiteboardState]: (state, action) => {
      return defaultState
    },
  },
  defaultState
)
