import { combineActions, handleActions } from 'redux-actions'
import {
  attachAiExpressionToFormControlOption,
  detachAiExpressionFromFormControlOption,
  closeAttachAIExpressionDialog,
  openAttachAIExpressionDialog,
} from '../actions'

const defaultState = {
  open: false,
  selectedFormControlOption: null,
}

export default handleActions(
  {
    [openAttachAIExpressionDialog](state, action) {
      return {
        open: true,
        selectedFormControlOption: action.payload,
      }
    },
    [closeAttachAIExpressionDialog](state, action) {
      return {
        ...state,
        open: false,
      }
    },
    [combineActions(attachAiExpressionToFormControlOption.success, detachAiExpressionFromFormControlOption.success)](
      state,
      { response: { data: option } }
    ) {
      if (state.selectedFormControlOption?.Id !== option.Id) {
        return state
      }

      return {
        ...state,
        selectedFormControlOption: option,
      }
    },
  },
  defaultState
)
