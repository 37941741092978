import { useDispatch, useSelector } from 'react-redux'

import routes from '@tabeeb/routes'
import { getFormsTitle } from '@tabeeb/modules/appConfigState/selectors'
import { clearCachedFolder } from '@tabeeb/modules/formsPage/actions'

import NavbarTab from '../NavbarTab'

const Forms = ({ ...rest }) => {
  const dispatch = useDispatch()

  const formsTitle = useSelector(getFormsTitle)

  const handleClick = (e) => dispatch(clearCachedFolder())

  const props = {
    item: {
      label: formsTitle,
      to: Forms.to,
    },
    handleClick,
    ...rest,
  }

  return <NavbarTab {...props} />
}

Forms.to = routes.forms

export default Forms
