import PropTypes from 'prop-types'
import { Divider, Grid, Typography } from '@material-ui/core'

import { DeviceVendorType } from '@tabeeb/enums'

import { getLife365ProgramName } from '@tabeeb/modules/healthData/helper'

import useStyles from './styles'

const HealthInfoDevice = ({ healthDevice, last }) => {
  const classes = useStyles()
  return (
    <>
      <Grid item className={classes.deviceGrid}>
        <Typography className={classes.truncatedText} title={healthDevice.ExternalId}>
          <strong>Device serial number:</strong> {healthDevice.ExternalId}
        </Typography>
        {healthDevice.VendorType === DeviceVendorType.Life365 && (
          <Typography
            className={classes.truncatedText}
            title={healthDevice.ProgramId && getLife365ProgramName(healthDevice.ProgramId)}
          >
            <strong>Device program: </strong>
            {healthDevice.ProgramId ? getLife365ProgramName(healthDevice.ProgramId) : ''}
          </Typography>
        )}
      </Grid>
      {!last && <Divider variant='middle' />}
    </>
  )
}

HealthInfoDevice.propTypes = {
  healthDevice: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    ExternalId: PropTypes.string.isRequired,
    VendorType: PropTypes.number.isRequired,
    ProgramId: PropTypes.string,
  }).isRequired,
  last: PropTypes.bool.isRequired,
}

export default HealthInfoDevice
