import { useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'

import { RefreshOutlined } from '@mui/icons-material'
import { List, Popover, Box, Typography, Divider, IconButton, Tooltip } from '@mui/material'

import { BottomLinearProgress } from '@tabeeb/uikit'
import { useDebouncedValue } from '@tabeeb/shared/utils/hooks'

import {
  addSncQuestionAnswersToSelection,
  clearSncQuestionAnswersSelection,
  removeSncQuestionAnswersFromSelection,
} from '../../actions'
import { useTenantSncQuestionAnswers } from '../../hooks'
import { getSncQuestionSelectedAnswers } from '../../selectors'

import SearchInput from '../SearchInput'
import AnswersListItem from '../AnswersListItem'

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const AnswersMenu = ({ anchorEl, open, onClose, onTouch, questionId, questionName }) => {
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebouncedValue(searchText, 500)

  const { loading, answers, hasMore, onLoadMore } = useTenantSncQuestionAnswers({
    enabled: open,
    searchText: debouncedSearchText,
    questionId,
  })
  const selectedAnswersIds = useSelector((state) => getSncQuestionSelectedAnswers(state, { questionId }))

  const onClear = () => {
    dispatch(clearSncQuestionAnswersSelection({ questionId }))
  }

  const onSelect = (selected, answerId) => {
    if (selected) {
      dispatch(addSncQuestionAnswersToSelection({ questionId, answers: [answerId] }))
    } else {
      dispatch(removeSncQuestionAnswersFromSelection({ questionId, answers: [answerId] }))
    }

    onTouch()
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        sx: {
          width: 300,
        },
      }}
    >
      <Box m={1} minWidth={0} display='flex'>
        <Typography title={questionName} variant='body1' fontWeight={500} noWrap>
          {questionName}
        </Typography>
      </Box>

      <Box display='flex' m={1}>
        <SearchInput searchQuery={searchText} setSearchQuery={setSearchText} />
        <Box ml={0.5}>
          <Tooltip title='Reset selection'>
            <IconButton size='small' onClick={onClear}>
              <RefreshOutlined color='error' />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Divider />

      <List dense disablePadding sx={{ maxHeight: 512, overflowY: 'auto' }}>
        <InfiniteScroll useWindow={false} initialLoad loadMore={onLoadMore} hasMore={hasMore}>
          {answers.map((answer) => {
            const selected = selectedAnswersIds.includes(answer.Id)
            return (
              <AnswersListItem
                key={answer.Id}
                answer={answer}
                selected={selected}
                disabled={loading}
                onSelect={onSelect}
              />
            )
          })}
        </InfiniteScroll>
      </List>

      {loading && <BottomLinearProgress />}
    </Popover>
  )
}

AnswersMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onTouch: PropTypes.func.isRequired,
  questionName: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
}

export default memo(AnswersMenu)
