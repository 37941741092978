const tenantSessionsPageRequests = {
  getLastUpdateTimeRequest: () => ({
    url: `reporting-service/api/reports/last-update-time`,
    method: 'get',
  }),
  getTenantContentsRequest: (data) => ({
    url: `reporting-service/api/reports/contents/tenant`,
    method: 'get',
    params: data,
  }),
  getDeletedTenantContentsRequest: (data) => ({
    url: `reporting-service/api/reports/contents/tenant/deleted`,
    method: 'get',
    params: data,
  }),
  exportTenantSessionsCsvRequest: (data) => ({
    url: `reporting-service/api/reports/contents/export/csv`,
    method: 'get',
    params: data,
  }),
  getTenantContentsDashboardsRequest: () => ({
    url: `reporting-service/api/dashboards`,
    method: 'get',
  }),
  getTenantCustomColumnsRequest: (data) => ({
    url: `api/contents/tenant/questions`,
    method: 'get',
    params: data,
  }),
  getTenantPrimaryColumnsRequest: () => ({
    url: `reporting-service/api/dashboards/primaryColumns`,
    method: 'get',
  }),
  createDashboardRequest: (data) => ({
    url: `reporting-service/api/dashboards`,
    method: 'post',
    data,
  }),
  setDashboardAsDefaultRequest: (dashboardId) => ({
    url: `reporting-service/api/dashboards/setDefault/${dashboardId}`,
    method: 'put',
  }),
  resetDefaultDashboardRequest: () => ({
    url: `reporting-service/api/dashboards/resetDefault`,
    method: 'put',
  }),
  deleteDashboardRequest: (dashboardId) => ({
    url: `reporting-service/api/dashboards/${dashboardId}`,
    method: 'delete',
  }),
  updateDashboardRequest: (data) => ({
    url: `reporting-service/api/dashboards`,
    method: 'put',
    data,
  }),
  getTenantContentsDashboardColumnsRequest: (dashboardId) => ({
    url: `reporting-service/api/dashboards/columns/${dashboardId}`,
    method: 'get',
  }),
}

export default tenantSessionsPageRequests
