import { eventChannel } from 'redux-saga'

function eventHandler(target, eventName) {
  return eventChannel((emitter) => {
    function listener(event) {
      emitter({ event: arguments })
    }
    target.addEventListener(eventName, listener, false)

    return () => {
      target.removeEventListener(eventName, listener, false)
    }
  })
}

export default eventHandler
