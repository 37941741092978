import { handleActions } from 'redux-actions'

import { calendarActions } from '@tabeeb/modules/calendar'
import { FetchStatus } from '@tabeeb/enums'

const defaultState = {}

const defaultRangeSynchronizationState = {
  status: FetchStatus.Idle,
  lastSynchronized: null,
}

function getRangeIdentifier(start, end) {
  return `${start.getTime()}-${end.getTime()}`
}

function getCalendarRangeSynchronizationState(state, calendarId, { start, end }) {
  const calendar = state[calendarId]
  if (!calendar) {
    return defaultRangeSynchronizationState
  }

  return calendar[getRangeIdentifier(start, end)] || defaultRangeSynchronizationState
}

function updateCalendarRangeSynchronizationState(state, calendarId, range, synchronizationState) {
  const prevCalendarSyncState = state[calendarId] || {}
  const prevState = getCalendarRangeSynchronizationState(state, calendarId, range)

  const { start, end } = range

  return {
    ...state,
    [calendarId]: {
      ...prevCalendarSyncState,
      [getRangeIdentifier(start, end)]: {
        ...prevState,
        ...synchronizationState,
      },
    },
  }
}

export default handleActions(
  {
    [calendarActions.updateCalendarRangeSynchronizationState](state, action) {
      const { calendarId, range, state: synchronizationState } = action.payload

      return updateCalendarRangeSynchronizationState(state, calendarId, range, synchronizationState)
    },
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
  },
  defaultState
)
