import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import * as zoomingActions from '../../../actions/zooming'
import { ScalesArray } from '../../../common/zooming'

import useStyles from './styles'

const ZoomValueSelect = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const scale = useSelector(playerToolbarSelectors.getScale)

  const onZoomValueChange = useCallback(
    (event) => {
      const selectedScale = event.target.value

      if (selectedScale !== scale) {
        dispatch(zoomingActions.onZoomValueChecked(selectedScale))
      }
    },
    [dispatch, scale]
  )

  return (
    <Select
      size='small'
      title='Select zoom value'
      value={scale}
      onChange={onZoomValueChange}
      disableUnderline
      renderValue={(scale) => `${scale}%`}
      className={classes.select}
      inputProps={{ className: classes.input }}
      MenuProps={{ PaperProps: { className: classes.menu } }}
      IconComponent={() => null}
    >
      {ScalesArray.map((scale) => (
        <MenuItem value={scale} key={scale}>
          {`${scale}%`}
        </MenuItem>
      ))}
    </Select>
  )
}

export default memo(ZoomValueSelect)
