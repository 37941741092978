import { useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormButtons } from '@tabeeb/modules/forms/services/enums'
import FormButton from '@tabeeb/modules/forms/components/FormButton'
import { ConfirmationDialog } from '@tabeeb/uikit'
import { getFormButtonState } from '@tabeeb/modules/forms/selectors'
import { setInitialValues } from '@tabeeb/modules/forms/actions'

const InitialValuesButton = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const dispatch = useDispatch()

  const { isVisible, isDisabled } = useSelector((state) =>
    getFormButtonState(state, { button: FormButtons.SetInitialValues })
  )

  if (!isVisible) return null

  const _handleSubmit = () => {
    setIsDialogOpen(false)
    dispatch(setInitialValues())
  }

  const dialogProps = {
    onCloseDialog: () => setIsDialogOpen(false),
    onSubmit: _handleSubmit,
    isOpen: isDialogOpen,
    title: 'Are you sure you want to set initial values to empty input fields?',
    cancelButtonText: 'No',
    submitButtonText: 'Yes',
  }

  const componentProps = {
    onClick: () => setIsDialogOpen(true),
    buttonText: 'Set initial values',
    isDisabled,
    size: 'small',
  }

  return (
    <>
      <FormButton {...componentProps} />
      {isDialogOpen && <ConfirmationDialog {...dialogProps} />}
    </>
  )
}

export default memo(InitialValuesButton)
