import { createAction } from 'redux-actions'

export const onEnableDrawing = createAction('ON_ENABLE_DRAWING')
export const onDisableDrawing = createAction('ON_DISABLE_DRAWING')
export const onSetDrawingColor = createAction('ON_SET_DRAWING_COLOR')
export const onSetDrawingType = createAction('ON_SET_DRAWING_TYPE')
export const onSetDrawingPreset = createAction('ON_SET_DRAWING_PRESET')
export const onSetDrawingScale = createAction('ON_SET_DRAWING_SCALE')
export const onSetDrawingWidth = createAction('ON_SET_DRAWING_WIDTH')
export const onSetTransparency = createAction('ON_SET_TRANSPARENCY')
export const onSendStrokeAttributes = createAction('ON_SEND_STROKE_ATTRIBUTES')

export const onChangePressedButton = createAction('ON_CHANGE_PRESSED_BUTTON')
export const onDisposeDrawing = createAction('ON_DISPOSE_DRAWING')

export const onHideAnnotations = createAction('ON_HIDE_ANNOTATIONS')
export const onShowAnnotations = createAction('ON_SHOW_ANNOTATIONS')

export const onEnableSelection = createAction('ON_ENABLE_SELECTION')
export const onDisableSelection = createAction('ON_DISABLE_SELECTION')

export const onEnableSnippingTool = createAction('ON_ENABLE_SNIPPING_TOOL')
export const onDisableSnippingTool = createAction('ON_DISABLE_SNIPPING_TOOL')
