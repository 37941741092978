import { combineReducers } from 'redux'
import powerBIReport from './powerBIReport'
import powerBIReportEditor from './powerBIReportEditor'
import powerBIReports from './powerBIReports'
import addPowerBIReportModalState from './modals/addPowerBIReportModalState'
import addPowerBIReportDependentModuleModalState from './modals/addPowerBIReportDependentModuleModalState'
import powerBIReportTenantModalState from './modals/powerBIReportTenantModalState'
import powerBIReportUserModalState from './modals/powerBIReportUserModalState'
import powerBIReportConfigurationCheck from './powerBIReportConfigurationCheck'
import powerBIReportTenants from './powerBIReportTenants'
import powerBIReportUsers from './powerBIReportUsers'
import powerBIReportDependentModules from './powerBIReportDependentModules'

export default combineReducers({
  powerBIReport,
  powerBIReportEditor,
  powerBIReports,
  addPowerBIReportModalState,
  addPowerBIReportDependentModuleModalState,
  powerBIReportTenantModalState,
  powerBIReportUserModalState,
  powerBIReportConfigurationCheck,
  powerBIReportTenants,
  powerBIReportUsers,
  powerBIReportDependentModules,
})
