import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Field, useFormikContext } from 'formik'
import { Grid, InputAdornment } from '@material-ui/core'

import { FormikTextField } from '@tabeeb/shared/forms'
import { SystemOfMeasures, ProfileFieldLabels } from '@tabeeb/enums'

import { getKgFromLbs, getLbsFromKg } from '@tabeeb/modules/healthData/helper/measurementConversion'

const RegionalWeight = ({ minMaxValues, ...props }) => {
  const {
    values: { SystemOfMeasure },
    setFieldValue,
  } = useFormikContext()

  const handleSetValue = useCallback(
    (e) => {
      const { name, value } = e.target
      setFieldValue(name, value ?? 0)

      if (name === ProfileFieldLabels.WeightKg) {
        const lbs = getLbsFromKg(value).toFixed(1)
        setFieldValue(ProfileFieldLabels.WeightLbs, lbs)
      } else if (name === ProfileFieldLabels.WeightLbs) {
        const kg = getKgFromLbs(value).toFixed(1)
        setFieldValue(ProfileFieldLabels.WeightKg, kg)
      }
    },
    [setFieldValue]
  )

  return (
    <Grid container wrap='nowrap' direction='row' spacing={1}>
      {Number(SystemOfMeasure) === SystemOfMeasures.Metric ? (
        <Grid item xs={12}>
          <Field
            name='WeightKg'
            label='Weight'
            onChange={handleSetValue}
            type='number'
            InputProps={{ endAdornment: <InputAdornment position='end'>kg.</InputAdornment> }}
            inputProps={{ min: minMaxValues.WeightKg.min, max: minMaxValues.WeightKg.max, step: '0.1' }}
            component={FormikTextField}
            {...props}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Field
            name='WeightLbs'
            label='Weight'
            onChange={handleSetValue}
            type='number'
            InputProps={{ endAdornment: <InputAdornment position='end'>lbs.</InputAdornment> }}
            inputProps={{ min: minMaxValues.WeightLbs.min, max: minMaxValues.WeightLbs.max, step: '0.1' }}
            component={FormikTextField}
            {...props}
          />
        </Grid>
      )}
    </Grid>
  )
}

RegionalWeight.propTypes = {
  minMaxValues: PropTypes.shape({
    WeightLbs: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }),
    WeightKg: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }),
  }),
}

export default memo(RegionalWeight)
