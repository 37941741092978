import { TenantPermission } from '@tabeeb/enums'
import { permissionsSelectors } from '@tabeeb/modules/permissions'

export const getPolicies = (state) => state.policies.policies
export const getPolicyByType = (state, policyType) => getPolicies(state)[policyType]

export const getIsUserNeedsUpdate = (state) =>
  Object.values(getPolicies(state)).some((policy) => policy.IsAccepted === false)

export const getIsOpen = (state) => state.policies.isOpen
export const getIsFailed = (state) => state.policies.isFailed
export const getIsLoaded = (state) => state.policies.isLoaded
export const getIsLoading = (state) => state.policies.isLoading
export const getIsCreationInProgress = (state) => state.policies.isCreationInProgress
export const getIsAcceptanceInProgress = (state) => state.policies.isAcceptanceInProgress

export const getCanCurrentUserEditPolicies = (state) => {
  if (permissionsSelectors.hasTenantPermission(state, TenantPermission.EditPolicies)) {
    return true
  }

  return false
}
