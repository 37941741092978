import { handleActions, combineActions } from 'redux-actions'

import * as aiActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [aiActions.getTagsWithAiObjectsSuccess](state, { response }) {
      return [...response.data]
    },
    [aiActions.attachAiObjectToTagSuccess](state, { payload, response }) {
      return state.map((tag) =>
        tag.Id === payload.tagId ? { ...tag, AIObjects: [...tag.AIObjects, response.data] } : tag
      )
    },
    [aiActions.detachAiObjectFromTagSuccess](state, { payload, response }) {
      return state.map((tag) =>
        tag.Id === payload.tagId
          ? { ...tag, AIObjects: tag.AIObjects.filter((object) => object.Id !== payload.aiObjectId) }
          : tag
      )
    },
  },
  defaultState
)
