import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StraightenOutlined } from '@mui/icons-material'

import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import {
  onChangePressedButton,
  onDisableDrawing,
  onEnableDrawing,
  onSetDrawingPreset,
  onSetDrawingType,
  onSetDrawingScale,
} from '../../actions/drawing'
import { getSelectedColor, getIsButtonSelected, getIsDrawingEnabled, getDrawingPreset } from '../../selectors'

import AnnotationPresetSelector, { Preset } from '../AnnotationPresetSelector'
import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'

const BUTTON_NAME = 'point-cloud-measurements'

const presets = [
  Preset.Area,
  Preset.Distance,
  Preset.Height,
  Preset.Box,
  Preset.Polyline,
  Preset.CylinderLarge,
  Preset.CylinderMedium,
  Preset.CylinderSmall,
]

const PointCloudMeasurementsButton = () => {
  const dispatch = useDispatch()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const color = useSelector(getSelectedColor)
  const preset = useSelector(getDrawingPreset)
  const selected = useSelector((state) => getIsButtonSelected(state, BUTTON_NAME))
  const isDrawingEnabled = useSelector(getIsDrawingEnabled)

  const onClick = useCallback(() => {
    if (selected) {
      dispatch(onChangePressedButton({ pressedButton: '' }))
      dispatch(onDisableDrawing())
    } else {
      onOpen()
    }
  }, [dispatch, onOpen, selected])

  const onSelectShape = useCallback(
    (selectedPreset) => {
      dispatch(onChangePressedButton({ pressedButton: BUTTON_NAME }))

      if (selectedPreset.configuration?.scale) {
        dispatch(onSetDrawingScale(selectedPreset.configuration.scale))
      }

      dispatch(onSetDrawingPreset(selectedPreset.id))
      dispatch(onSetDrawingType(selectedPreset.type))
      dispatch(onEnableDrawing())
    },
    [dispatch]
  )

  return (
    <>
      <ToolbarButton
        ref={anchorRef}
        Icon={StraightenOutlined}
        htmlColor={isDrawingEnabled && selected ? color : null}
        selected={selected || open}
        title='Add Measurement annotation'
        onClick={onClick}
      />
      <ToolbarPopover anchorEl={anchorRef.current} open={open} onClose={onClose}>
        <AnnotationPresetSelector
          presets={presets}
          color={color}
          selected={selected ? preset : null}
          enabled={isDrawingEnabled}
          onSelect={onSelectShape}
        />
      </ToolbarPopover>
    </>
  )
}

export default memo(PointCloudMeasurementsButton)
