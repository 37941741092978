import PropTypes from 'prop-types'
import { Line } from 'react-konva'

const FieldOfView = ({ fov, size }) => {
  const leftLineEndPoint = {
    x: -size,
    y: -Math.tan(((fov / 2) * Math.PI) / 180) * size,
  }

  const rightLineEndPoint = {
    x: -size,
    y: Math.tan(((fov / 2) * Math.PI) / 180) * size,
  }

  return (
    <Line
      x={0}
      y={0}
      fillLinearGradientStartPoint={{
        x: 0,
        y: 0,
      }}
      fillLinearGradientEndPoint={{
        x: -size,
        y: 0,
      }}
      fillRadialGradientEndRadius={size}
      fillRadialGradientColorStops={[0.15, 'white', 1, 'transparent']}
      opacity={0.5}
      closed
      points={[0, 0, leftLineEndPoint.x, leftLineEndPoint.y, rightLineEndPoint.x, rightLineEndPoint.y]}
    />
  )
}

FieldOfView.propTypes = {
  fov: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
}

export default FieldOfView
