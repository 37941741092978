import { handleActions, combineActions } from 'redux-actions'
import {
  updateContentForms,
  addContentForm,
  removeContentForm,
  updateForm,
  updateContentForm,
  resetFormsState,
} from '../actions'

const contentForms = handleActions(
  {
    [updateContentForms](state, action) {
      return [...action.payload]
    },
    [addContentForm](state, action) {
      return [...state, action.payload]
    },
    [removeContentForm](state, action) {
      const index = state.findIndex((contentForm) => contentForm.FormId === action.payload)
      if (index >= 0) {
        state.splice(index, 1)
      }
      return [...state]
    },
    [combineActions(updateForm, updateContentForm)](state, action) {
      return state.map((form) => {
        if (form.FormId === action.payload.FormId) {
          return { ...form, ...action.payload }
        }
        return form
      })
    },
    [resetFormsState](state, action) {
      return []
    },
  },
  []
)

export default contentForms
