import { createSelector } from 'reselect'
import _ from 'lodash'

import { AnnotationType } from '@tabeeb/enums'

import { getCurrentVideoTimestamp } from '@tabeeb/modules/player/selectors'
import {
  getSelectedGalleryItem,
  getSelectedGalleryItemType,
  getGalleryItemById,
  getFolderById,
} from '@tabeeb/modules/gallery/selectors'
import { isVideoPage } from '@tabeeb/services/pageService'

import { SHOW_ANNOTATION_DURATION } from '../constants'

export const getIsAnnotationMenuOpen = (state) => state.annotations.annotationMenuState.isOpen
export const getAnnotationMenuPosition = (state) => state.annotations.annotationMenuState.position

export const getHighlightedAnnotationId = (state) => state.annotations.highlightedAnnotationId

export const getTextAnnotationEditorAnnotationId = (state) => state.annotations.textAnnotationEditorState.annotationId

export const getUndoRedoState = (state) => state.annotations.undoRedoState
export const getUniqueAIObjectCreationMode = (state) => state.annotations.uniqueAIObjectCreationMode

export const getAnnotations = (state) => state.annotations.annotations
export const getHotspotDialogAnnotationId = (state) => state.annotations.hotspotDialogState.annotationId
export const getHotspotDialogVideoResume = (state) =>
  state.annotations.hotspotDialogState.resumeVideoAfterClose || false

export const getSelectedPageAnnotations = (state) => {
  const selectedGalleryItem = getSelectedGalleryItem(state)
  let annotations = selectedGalleryItem ? selectedGalleryItem.annotations : []
  if (!annotations) {
    annotations = []
  }

  return annotations
}

export const getAnnotationById = createSelector(
  [getSelectedPageAnnotations, (_, { Id }) => Id],
  (annotationsList, Id) => {
    return annotationsList.find((a) => a.Id === Id)
  }
)

export const getAnnotationParent = createSelector(
  [getSelectedPageAnnotations, getAnnotationById],
  (annotationsList, annotation) => {
    if (!annotation || !annotation.ParentAnnotationId) {
      return null
    }

    const parentAnnotation = annotationsList.find((a) => a.Id === annotation.ParentAnnotationId)
    return parentAnnotation || null
  }
)

export const getHotspotDialogAnnotation = (state) =>
  getAnnotationById(state, { Id: getHotspotDialogAnnotationId(state) })

export const getParentOfHotspotDialogAnnotation = createSelector(
  [getHotspotDialogAnnotationId, (state) => state],
  (hotspotDialogAnnotationId, state) => {
    if (!hotspotDialogAnnotationId) {
      return null
    }

    return getAnnotationParent(state, { Id: hotspotDialogAnnotationId })
  }
)

export const getFolderOfPageOfHotspotDialogAnnotation = createSelector(
  [getHotspotDialogAnnotation, (state) => state],
  (hotspotDialogAnnotation, state) => {
    if (!hotspotDialogAnnotation) {
      return null
    }

    const page = getGalleryItemById(state, { id: hotspotDialogAnnotation.PageId })
    if (!page || !page.folderId) {
      return null
    }

    return getFolderById(state, { id: page.folderId })
  }
)

export const getSelectedPageAnnotationsWithTimestamp = createSelector(
  [getSelectedPageAnnotations, getCurrentVideoTimestamp, getSelectedGalleryItemType],
  (annotationsList, currentVideoTimestamp, contentType) => {
    if (isVideoPage(contentType)) {
      return annotationsList.filter((a) => {
        return a.Timestamp <= currentVideoTimestamp && currentVideoTimestamp <= a.Timestamp + SHOW_ANNOTATION_DURATION
      })
    }
    return annotationsList
  }
)

export const getHighlightedAnnotation = createSelector(
  [getSelectedPageAnnotationsWithTimestamp, getHighlightedAnnotationId],
  (annotationsList, annotationId) => {
    return annotationsList.find(
      (item) =>
        item.Id === annotationId &&
        !((item.Type === AnnotationType.Text || item.Type === AnnotationType.AI) && item.ParentAnnotationId !== null)
    )
  }
)

export const getAnnotationOpacity = createSelector(
  [getAnnotationById, getHighlightedAnnotation],
  (annotation, highlightedAnnotation) => {
    return !highlightedAnnotation || !annotation || annotation.Id === highlightedAnnotation.Id ? 1.0 : 0.1
  }
)

export const getAnnotationChild = createSelector(
  [getSelectedPageAnnotations, getAnnotationById],
  (annotationsList, annotation) => {
    if (!annotation) {
      return null
    }

    const childAnnotation = annotationsList.find((a) => a.ParentAnnotationId === annotation.Id)
    return childAnnotation && childAnnotation.Type === AnnotationType.Text ? null : childAnnotation
  }
)

export const getAnnotationChildren = createSelector(
  [getSelectedPageAnnotations, getAnnotationById],
  (annotationsList, annotation) => {
    const childAnnotations = _.orderBy(
      annotationsList.filter((a) => a.ParentAnnotationId === annotation.Id),
      ['CreatedOn']
    )
    return childAnnotations
  }
)

export const getSelectedAnnotationsIds = (state) => state.annotations.selectedAnnotationsIds
export const getSelectedAnnotations = createSelector(
  [getSelectedAnnotationsIds, (state) => state],
  (annotationIds, state) => {
    return annotationIds.map((annotationId) => getAnnotationById(state, { Id: annotationId }))
  }
)

const getIdFromProps = (state, { Id }) => Id

export const getIsAnnotationSelected = createSelector(
  [getIdFromProps, getSelectedAnnotationsIds],
  (annotationId, selectedAnnotationsIds) => {
    return Boolean(selectedAnnotationsIds.includes(annotationId))
  }
)

export const getHiddenAnnotationIds = (state) => state.annotations.hiddenAnnotationIds
export const getIsAnnotationVisible = createSelector(
  [getIdFromProps, getHiddenAnnotationIds],
  (annotationId, hiddenAnnotationIds) => {
    return Boolean(!hiddenAnnotationIds.includes(annotationId))
  }
)

export const getHighlightedAnnotationIds = (state) => state.annotations.highlightedAnnotationIds
export const getIsAnnotationHighlighted = createSelector(
  [getIdFromProps, getHighlightedAnnotationIds],
  (annotationId, highlightedAnnotationIds) => {
    return Boolean(highlightedAnnotationIds.includes(annotationId))
  }
)

export const getHasUndoActions = createSelector([getUndoRedoState], (undoRedoState) => {
  return undoRedoState.undo.length > 0
})

export const getHasRedoActions = createSelector([getUndoRedoState], (undoRedoState) => {
  return undoRedoState.redo.length > 0
})

export const getLastUndoAction = createSelector([getUndoRedoState], (undoRedoState) => {
  return _.last(undoRedoState.undo)
})

export const getLastRedoAction = createSelector([getUndoRedoState], (undoRedoState) => {
  return _.last(undoRedoState.redo)
})

export const getMeasurementSettings = (state) => state.annotations.measurementSettings

export const getMeasurementSettingsConfigurationDialogState = (state) =>
  state.annotations.measurementSettingsDialogState

export const getDpi = createSelector([getMeasurementSettings], (measurementSettings) => {
  return measurementSettings.Dpi
})
