export const acceptInviteRequest = ({ Id }) => ({
  url: `api/invites/${Id}/accept`,
  method: 'put',
})

export const cancelInviteRequest = ({ Id }) => ({
  url: `api/invites/${Id}/cancel`,
  method: 'delete',
})

export const rejectInviteRequest = ({ Id }) => ({
  url: `api/invites/${Id}/reject`,
  method: 'put',
})

export const sendInvitesRequest = (data) => ({
  url: `api/invites/send`,
  method: 'post',
  data,
})

export const getContentInvitesRequest = ({ contentId, searchText }) => ({
  url: `api/invites/getcontentinvites/${contentId}${
    searchText.length > 0 ? `?search=${encodeURIComponent(searchText)}` : ''
  }`,
  method: 'get',
})

export const getInviteUrlByIdRequest = ({ Id }) => ({
  url: `api/invites/${Id}/url`,
  method: 'get',
})

export const resendInviteRequest = ({ Id }) => ({
  url: `api/invites/${Id}/resend`,
  method: 'post',
})

export const revokeQuickAccessUrlRequest = ({ Id }) => ({
  url: `api/invites/${Id}/url/revoke`,
  method: 'delete',
})

export const updateInviteRoleRequest = ({ Id, Role }) => ({
  url: `api/invites/${Id}/role`,
  method: 'put',
  data: {
    Role,
  },
})

export const updateInviteTimeRequest = ({ Id, StartDate, EndDate }) => ({
  url: `api/invites/${Id}/time`,
  method: 'put',
  data: {
    StartDate,
    EndDate,
  },
})

export const updateQuickAccessUrlPasswordRequest = ({ Id, Password }) => ({
  url: `api/invites/${Id}/url`,
  method: 'post',
  data: {
    Password,
  },
})
