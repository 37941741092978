import { OutlinedInput, InputAdornment } from '@mui/material'
import PropTypes from 'prop-types'

import { SearchOutlined } from '@mui/icons-material'

const SearchInput = ({ searchQuery, setSearchQuery, placeholder = 'Search' }) => {
  return (
    <OutlinedInput
      size='small'
      value={searchQuery}
      placeholder={placeholder}
      fullWidth
      onChange={(e) => setSearchQuery(e.target.value)}
      startAdornment={
        <InputAdornment position='start'>
          <SearchOutlined />
        </InputAdornment>
      }
    />
  )
}

SearchInput.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default SearchInput
