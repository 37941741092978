import { memo } from 'react'
import PropTypes from 'prop-types'

import { Fade, Tooltip, Typography, withStyles } from '@material-ui/core'
import { Battery20, Battery30, Battery50, Battery60, Battery80, Battery90, BatteryFull } from '@material-ui/icons'

import styles from './styles'

const getChargeLevelIconByChargePercent = (chargePercent) => {
  switch (Math.floor(chargePercent / 10)) {
    case 0:
    case 1:
    case 2:
      return Battery20
    case 3:
      return Battery30
    case 4:
    case 5:
      return Battery50
    case 6:
      return Battery60
    case 7:
    case 8:
      return Battery80
    case 9:
      return Battery90
    default:
      return BatteryFull
  }
}

const getChargeLevelByChargePercent = (chargePercent) => {
  if (chargePercent >= 65) {
    return 'high'
  }

  if (chargePercent >= 35 && chargePercent < 65) {
    return 'medium'
  }

  return 'low'
}

const BatteryIndicator = ({ classes, isVisible, chargeLevel: chargePercent }) => {
  const chargeLevel = getChargeLevelByChargePercent(chargePercent)

  const ChargeLevelIcon = getChargeLevelIconByChargePercent(chargePercent)

  return (
    <Fade in={isVisible} unmountOnExit>
      <Tooltip arrow placement='top' title={`Battery charge is ${chargePercent}%`}>
        <div className={classes.container}>
          <ChargeLevelIcon className={classes.battery} charge={chargeLevel} />
          <Typography className={classes.text} variant='caption'>
            {Math.round(chargePercent)}%
          </Typography>
        </div>
      </Tooltip>
    </Fade>
  )
}

BatteryIndicator.defaultProps = {
  chargeLevel: 100,
}

BatteryIndicator.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    battery: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  chargeLevel: PropTypes.string.isRequired,
}

export default memo(withStyles(styles)(BatteryIndicator))
