import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = false

const isContentMetadataOpen = handleActions(
  {
    [actions.openContentMetadataDialog]() {
      return true
    },
    [actions.closeContentMetadataDialog]() {
      return false
    },
  },
  defaultState
)

export default isContentMetadataOpen
