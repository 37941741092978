import { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Menu } from '@mui/material'
import { Delete, DirectionsRun, DriveFileRenameOutline, GroupOutlined, RestoreFromTrash } from '@mui/icons-material'

import { openSessionsPageDialog } from '@tabeeb/modules/sessionsPage/actions'
import { DialogTypes } from '@tabeeb/modules/sessionsPage/enums'
import {
  getCanAddFolderUsers,
  getCanDeleteFolder,
  getCanEditFolderUsers,
  getCanLeaveFolder,
  getCanRestoreFolder,
} from '@tabeeb/modules/sessionsPage/selectors/folderActions'

import MenuItem from '../MenuItem'

const FolderMenu = ({ anchorEl, folder, open, onClose }) => {
  const dispatch = useDispatch()

  const canAddUsers = useSelector((state) => getCanAddFolderUsers(state, folder))
  const canDelete = useSelector((state) => getCanDeleteFolder(state, folder))
  const canEditUsers = useSelector((state) => getCanEditFolderUsers(state, folder))
  const canLeaveFolder = useSelector((state) => getCanLeaveFolder(state, folder))
  const canRestore = useSelector((state) => getCanRestoreFolder(state, folder))

  const onOpenDialog = (type) => () => {
    dispatch(openSessionsPageDialog({ dialogType: type, dialogPayload: { folder } }))

    onClose()
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      {canRestore && (
        <MenuItem icon={RestoreFromTrash} title='Restore' onClick={onOpenDialog(DialogTypes.RestoreFolder)} />
      )}

      {(canAddUsers || canEditUsers) && (
        <MenuItem icon={GroupOutlined} title='Manage users' onClick={onOpenDialog(DialogTypes.ManageFolderUsers)} />
      )}

      {canAddUsers && (
        <MenuItem icon={DriveFileRenameOutline} title='Rename' onClick={onOpenDialog(DialogTypes.RenameFolder)} />
      )}

      {canDelete && (
        <MenuItem
          icon={Delete}
          title='Delete'
          onClick={onOpenDialog(folder.IsDeleted ? DialogTypes.DeleteFolderPermanently : DialogTypes.DeleteFolder)}
        />
      )}

      {canLeaveFolder && (
        <MenuItem icon={DirectionsRun} title='Leave' onClick={onOpenDialog(DialogTypes.LeaveFolder)} />
      )}
    </Menu>
  )
}

FolderMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  folder: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsDeleted: PropTypes.bool.isRequired,
    IsFolderShared: PropTypes.bool.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(FolderMenu)
