import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { ContentSharingModelType, TabPanel, TabPanelLocation } from '@tabeeb/enums'
import { SharedContentDataType } from '@tabeeb/modules/contentSharings/constants/enums'
import * as rawActions from '../actions'
import * as notificationsActions from '../../notification/actions'
import ShareSessionDialogContainer from '../../sessionsPage/containers/ShareSessionDialogContainer'

import Timeline from '../../timeline/components/Timeline'

class ShareSessionPagesListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isShareSessionDialogOpen: false,
    }

    this.actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        class: 'submit',
      },
    ]
  }

  _handleClose = () => {
    const {
      actions: { closeShareSessionPagesDialog },
    } = this.props
    closeShareSessionPagesDialog()
  }

  _handleSubmit = () => {
    const {
      pagesList,
      notificationsActions: { onAddErrorNotification },
    } = this.props

    if (pagesList.length > 0) {
      this.setState({ isShareSessionDialogOpen: true })
    } else {
      onAddErrorNotification({ message: 'At least one page should be added' })
    }
  }

  _handleDeleteItem = (pageId) => {
    const {
      actions: { deletePageForSharing },
    } = this.props

    deletePageForSharing(pageId)
  }

  _groupById = (pages) => {
    const resultPages = pages.reduce((r, p) => {
      r[p.groupById] = r[p.groupById] || []
      r[p.groupById].push(p)
      return r
    }, Object.create(null))

    return resultPages
  }

  render() {
    const { isOpen, pagesList, currentPanel, isProcessing, contentForms } = this.props

    const props = {
      actions: this.actions,
      timeLineList: this._groupById(pagesList),
      handleDeleteItem: this._handleDeleteItem,
      isShareByFormTimeline: true,
      isOpen,
      isSubmitted: isProcessing,
      isLoading: isProcessing,
      isSelected: currentPanel === TabPanel.ShareSession,
      contentForms,
    }

    const dialogProps = {
      onClose: () => this.setState({ isShareSessionDialogOpen: false }),
      open: this.state.isShareSessionDialogOpen,
      contentSharingType: ContentSharingModelType.SharedByOwner,
      dataType: SharedContentDataType.SelectedData,
    }

    return (
      <>
        <Timeline {...props} />
        <ShareSessionDialogContainer {...dialogProps} />
      </>
    )
  }
}

ShareSessionPagesListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  currentPanel: PropTypes.string.isRequired,
  pagesList: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    deletePageForSharing: PropTypes.func.isRequired,
    closeShareSessionPagesDialog: PropTypes.func.isRequired,
  }),
  notificationsActions: PropTypes.shape({
    onAddErrorNotification: PropTypes.func.isRequired,
  }),
  contentForms: PropTypes.array.isRequired,
}

const mapStateToProps = ({ shareSessionPagesList, navbar, forms }) => ({
  isOpen: shareSessionPagesList.isTimelineOpen,
  isProcessing: false,
  pagesList: shareSessionPagesList.pagesList,
  currentPanel: navbar.currentPanel[TabPanelLocation.Bottom],
  contentForms: forms.contentForms,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
  notificationsActions: bindActionCreators(notificationsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShareSessionPagesListContainer)
