import { useCallback } from 'react'
import PropTypes from 'prop-types'

import RefreshIcon from '@material-ui/icons/Refresh'
import PageInfoControl from '../PageInfoControl'

const RefreshPageButton = ({ setIframeKey }) => {
  const onRefresh = useCallback(() => {
    setIframeKey((state) => state + 1)
  }, [setIframeKey])

  return <PageInfoControl icon={RefreshIcon} title='Refresh web page' onClick={onRefresh} />
}

RefreshPageButton.propTypes = {
  setIframeKey: PropTypes.func.isRequired,
}

export default RefreshPageButton
