import { memo, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Popover } from '@material-ui/core'

import { UserRole } from '@tabeeb/enums'
import { ConfirmationDialog } from '@tabeeb/uikit'
import { makePresenter } from '@tabeeb/shared/content/actions'

import UserCertificatesDialog from '@tabeeb/modules/certificates/components/UserCertificatesDialog'
import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import HealthInfoDialog from '@tabeeb/modules/healthData/components/HealthInfoDialog'

import { isCurrentUserRecorder } from '@tabeeb/modules/recording/services/localRecordingService'
import { openMakePresenterDialog } from '@tabeeb/modules/whiteboard/actions'
import { getIsSessionGuestRoleEnabled, getSessionRoleDisplayName } from '@tabeeb/modules/appConfigState/selectors'
import UserOptionButton from '../UserOptionButton'

import {
  getIsMakePresenterButtonVisible,
  getIsShowCertificatesButtonVisible,
  getIsShowHealthDataButtonVisible,
  getIsSwitchRoleButtonsVisible,
  makeGetIsKickUserButtonVisible,
} from '../../selectors'

import { kickUser, switchContentUserRole } from '../../actions'
import useStyles from './styles'

const UserOptionsMenu = ({ anchorEl, isOpen, onClose, user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isKickUserDialogOpened, setIsKickUserDialogOpened] = useState(false)
  const [isShowHealthInfoPopupOpened, setIsShowHealthInfoPopupOpened] = useState(false)
  const [certificatesDialogOpen, onCertificatesDialogOpen, onCertificatesDialogClose] = useDialogState()

  const kickButtonVisibilitySelector = useMemo(makeGetIsKickUserButtonVisible, [])
  const isKickButtonVisible = useSelector((state) => kickButtonVisibilitySelector(state, { user }))
  const isSwitchRoleButtonsVisible = useSelector((state) => getIsSwitchRoleButtonsVisible(state, { user }))
  const isMakePresenterVisible = useSelector((state) => getIsMakePresenterButtonVisible(state, { user }))
  const isShowHealthDataVisible = useSelector((state) => getIsShowHealthDataButtonVisible(state, { user }))
  const isCertificatesMenuVisible = useSelector((state) => getIsShowCertificatesButtonVisible(state, { user }))

  const onKickUserRequested = () => {
    setIsKickUserDialogOpened(true)
  }

  const onKickUserAborted = () => {
    setIsKickUserDialogOpened(false)
  }

  const onKickUserSubmitted = () => {
    setIsKickUserDialogOpened(false)
    dispatch(kickUser(user.id))
  }

  const onSwitchContentUserRole = (data) => {
    dispatch(switchContentUserRole(data))
  }

  const onMakePresenter = (data) => {
    if (isCurrentUserRecorder()) {
      dispatch(openMakePresenterDialog(data))
      return
    }
    dispatch(makePresenter(data))
  }

  const onClickShowHealthInfo = () => {
    setIsShowHealthInfoPopupOpened(true)
  }

  const onCloseShowHealthInfo = () => {
    setIsShowHealthInfoPopupOpened(false)
  }

  let roles = useSelector((state) => {
    const availableRoles = [UserRole.User, UserRole.Viewer]

    const isSessionGuestRoleEnabled = getIsSessionGuestRoleEnabled(state)
    if (isSessionGuestRoleEnabled) {
      availableRoles.push(UserRole.SessionGuest)
    }

    return availableRoles.map((role) => ({
      role,
      displayName: getSessionRoleDisplayName(state, { Role: role }),
    }))
  })

  roles = roles.filter(({ role }) => role !== user.role)

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        onClick={onClose}
        PaperProps={{ className: classes.paper }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        {isCertificatesMenuVisible && (
          <UserOptionButton onClick={onCertificatesDialogOpen} buttonText='Show Certificates' />
        )}
        {isShowHealthDataVisible && <UserOptionButton onClick={onClickShowHealthInfo} buttonText='Show Health Info' />}
        {isMakePresenterVisible && (
          <UserOptionButton onClick={() => onMakePresenter(user.id)} buttonText='Make Presenter' />
        )}
        {isSwitchRoleButtonsVisible &&
          roles.map(({ role, displayName }) => (
            <UserOptionButton
              key={role}
              onClick={() => onSwitchContentUserRole({ role, userId: user.id })}
              buttonText={`Make ${displayName}`}
            />
          ))}
        {isKickButtonVisible && <UserOptionButton onClick={onKickUserRequested} buttonText='Remove User' />}
      </Popover>

      <ConfirmationDialog
        onCloseDialog={onKickUserAborted}
        onSubmit={onKickUserSubmitted}
        isOpen={isKickUserDialogOpened}
        title='Remove user?'
        submitButtonText='Yes'
        cancelButtonText='No'
      />
      <HealthInfoDialog isOpen={isShowHealthInfoPopupOpened} onClose={onCloseShowHealthInfo} userId={user.id} />
      <UserCertificatesDialog
        open={certificatesDialogOpen}
        user={{ Id: user.id, IdentityGuid: user.identityGuid }}
        onClose={onCertificatesDialogClose}
      />
    </>
  )
}

UserOptionsMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.instanceOf(HTMLElement),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    identityGuid: PropTypes.string.isRequired,
    role: PropTypes.oneOf(Object.values(UserRole)).isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(UserOptionsMenu)
