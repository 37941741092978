import { combineReducers } from 'redux'

import searchText from './searchText'
import pdfSearchResult from './pdfSearchResult'
import showPdfSearchResults from './showPdfSearchResults'
import selectedPdfSearchResult from './selectedPdfSearchResult'
import searchMatchesCount from './searchMatchesCount'
import disabledSearchResultsPages from './disabledSearchResultsPages'

export default combineReducers({
  searchText,
  pdfSearchResult,
  showPdfSearchResults,
  selectedPdfSearchResult,
  searchMatchesCount,
  disabledSearchResultsPages,
})
