import { memo } from 'react'
import PropTypes from 'prop-types'

import AudioPlayer from '../../../shared/audioPlayer'
import './styles.less'

const ChatMessageAudioAsset = ({ asset }) => {
  return (
    <div className='chat-message-asset-audio'>
      <AudioPlayer url={asset.url} />
    </div>
  )
}

ChatMessageAudioAsset.propTypes = {
  asset: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(ChatMessageAudioAsset)
