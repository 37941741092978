import { put, takeLatest, all, select } from 'redux-saga/effects'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import { removeSessionFromList } from './removeSession'
import getErrorMessageFromResponse from '../../shared/utils/getErrorMessageFromResponse'

import { getFoldersList } from '../selectors'

function* getFoldersFailed({ response }) {
  const message = getErrorMessageFromResponse(response?.data)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* addToFolderSuccess({ payload }) {
  const { contentId } = payload

  yield removeSessionFromList({ payload: { contentId } })
}

function* setCurrentFolderId({ payload }) {
  const folderId = payload
  const folders = yield select(getFoldersList)

  if (folderId) {
    const folder = folders.find((f) => f.Id === folderId)

    if (!folder) {
      yield put(rawActions.getFolder.request(folderId))
    } else {
      yield put(rawActions.setCurrentFolder(folder))
    }
  }
}

function* foldersList() {
  yield all([
    takeLatest(rawActions.getFolders.failed, getFoldersFailed),
    takeLatest(rawActions.switchSessionFolder.success, addToFolderSuccess),
    takeLatest(rawActions.setCurrentFolderId, setCurrentFolderId),
  ])
}

export default foldersList
