import { put, all, select, takeEvery } from 'redux-saga/effects'

import { push } from 'connected-react-router'

import { calendarActions, calendarSelectors } from '@tabeeb/modules/calendar'

import { urlService } from '../services'

function* setCalendarDate(action) {
  const date = action.payload
  const view = yield select(calendarSelectors.getSelectedView)

  yield put(push(urlService.getCalendarRoute(view, date)))
}

function* setCalendarView(action) {
  const date = yield select(calendarSelectors.getSelectedDate)
  const view = action.payload

  yield put(push(urlService.getCalendarRoute(view, date)))
}

function* viewSagas() {
  yield all([
    takeEvery(calendarActions.setCalendarDate, setCalendarDate),
    takeEvery(calendarActions.setCalendarView, setCalendarView),
  ])
}

export default viewSagas
