import PropTypes from 'prop-types'

import { Avatar } from '@material-ui/core'

import { getUserColor } from '@tabeeb/shared/utils/color'

const getInitials = (displayName) => {
  const nameSplits = displayName.split(' ')
  let initials = ''
  nameSplits
    .filter((item) => item.length > 0)
    .slice(0, 2)
    .forEach((str) => {
      initials += str.substring(0, 1).toUpperCase()
    })
  return initials
}

const UserAvatar = ({ user, style, ...rest }) => {
  const name = user.displayName || user.Name || ''
  const avatarUrl = user.avatarUrl || user.AvatarUrl
  const initials = user.initials || getInitials(name)

  const backgroundColor = name ? getUserColor(user) : null
  const textColor = backgroundColor ? '#ffffff' : null

  return (
    <Avatar style={{ backgroundColor, color: textColor, ...style }} title={name} alt={name} src={avatarUrl} {...rest}>
      {initials}
    </Avatar>
  )
}

UserAvatar.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      AvatarUrl: PropTypes.string,
      IsAccountDeleted: PropTypes.bool,
    }).isRequired,
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
      isDeleted: PropTypes.bool.isRequired,
      isAccountDeleted: PropTypes.bool.isRequired,
      avatarUrl: PropTypes.string,
    }).isRequired,
  ]).isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
}

export default UserAvatar
