import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ResizableDialog } from '@tabeeb/uikit'

import { QuestionInfo } from '../../Info'
import QuestionAnswersList from '../../AnswersLists/QuestionAnswersList'

import * as discussionActions from '../../../actions'
import * as discussionSelectors from '../../../selectors'

const QuestionAnswersDiscussionDialog = () => {
  const dispatch = useDispatch()

  const open = useSelector(discussionSelectors.getQuestionAnswersDialogOpen)
  const question = useSelector(discussionSelectors.getDiscussionQuestion)

  const onClose = useCallback(() => {
    dispatch(discussionActions.closeQuestionFormAnswersDialog())
  }, [])

  return (
    <ResizableDialog
      open={open}
      onClose={onClose}
      header={<QuestionInfo question={question} />}
      content={<QuestionAnswersList question={question} />}
    />
  )
}

QuestionAnswersDiscussionDialog.propTypes = {}

export default memo(QuestionAnswersDiscussionDialog)
