import { combineActions, handleActions } from 'redux-actions'

import { onUserReadyForCall } from '@tabeeb/modules/presentation/actions/connection'
import { leaveVideoCall } from '@tabeeb/shared/content/actions'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'

const initialState = 0

export default handleActions(
  {
    [onUserReadyForCall]() {
      return Date.now()
    },
    [combineActions(leaveVideoCall, signalrEvents[signalrConstants.tabeebHubName].onCallEnded)]() {
      return initialState
    },
  },
  initialState
)
