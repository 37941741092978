import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Fade } from '@material-ui/core'

import { makeGetIsUserPresenter, getCurrentUserId } from '@tabeeb/modules/presentation/selectors'
import UserInfo from '@tabeeb/modules/../users/components/UserInfo'
import ConnectionQualityIndicator from '@tabeeb/modules/../users/components/ConnectionQualityIndicator'
import RecordingTimer from '@tabeeb/modules/../users/components/RecordingTimer'
import BatteryIndicator from '@tabeeb/modules/../users/components/BatteryIndicator'
import MuteUserAudioButton from '@tabeeb/modules/../users/components/MuteUserAudioButton'
import MuteUserVideoButton from '@tabeeb/modules/../users/components/MuteUserVideoButton'
import ScreenSharingButton from '@tabeeb/modules/../users/components/ScreenSharingButton'

import useStyles from './styles'

const UserOptions = ({ user, hovered, recording }) => {
  const classes = useStyles()

  const currentUserId = useSelector(getCurrentUserId)
  const isCurrentUser = currentUserId === user.id

  const presenterStatusSelector = useMemo(makeGetIsUserPresenter, [])
  const isPresenter = useSelector((state) => presenterStatusSelector(state, { userId: user.id }))

  return (
    <div className={classes.container}>
      <div className={classes.indicators}>
        {recording && <RecordingTimer />}
        <BatteryIndicator isVisible={hovered} chargeLevel={user.chargeLevel} />
        <ConnectionQualityIndicator isVisible={hovered} bitrate={user.bitrate} quality={user.connectionQuality} />
      </div>
      <Fade in={hovered}>
        <div>
          <div className={classes.info}>
            <UserInfo user={user} isCallStarted isPresenter={isPresenter} isOwner={false} />
          </div>
          <div className={classes.controls}>
            <MuteUserVideoButton user={user} />
            <MuteUserAudioButton user={user} />
            {isCurrentUser && <ScreenSharingButton user={user} />}
          </div>
        </div>
      </Fade>
    </div>
  )
}

UserOptions.propTypes = {
  hovered: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    connectionQuality: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    chargeLevel: PropTypes.string.isRequired,
    bitrate: PropTypes.shape({
      download: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      upload: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  }).isRequired,
  recording: PropTypes.bool.isRequired,
}

export default memo(UserOptions)
