import PropTypes from 'prop-types'

import { Transformer as CanvasTransformer } from 'react-konva'

const Transformer = ({ transformerRef }) => {
  return <CanvasTransformer ref={transformerRef} rotateEnabled={false} anchorSize={7} />
}

Transformer.propTypes = {
  transformerRef: PropTypes.object.isRequired,
}

export default Transformer
