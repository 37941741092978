import { useEffect, useState } from 'react'
import { Rect, Layer } from 'react-konva'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { gallerySelectors } from '@tabeeb/modules/gallery'

import * as contentViewerActions from '../../actions'

const DrawingBoard = ({ contentViewerActions, selectedGalleryItemId }) => {
  useEffect(() => {
    contentViewerActions.resetLoadingInProgress()
  }, [selectedGalleryItemId])

  return (
    <Layer name='drawing-board'>
      <Rect fill='white' width={1000} height={750} />
    </Layer>
  )
}

DrawingBoard.propTypes = {
  selectedGalleryItemId: PropTypes.number.isRequired,
  contentViewerActions: PropTypes.shape({
    resetLoadingInProgress: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  const selectedGalleryItemId = gallerySelectors.getSelectedGalleryItemId(state)

  return {
    selectedGalleryItemId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contentViewerActions: bindActionCreators(contentViewerActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawingBoard)
