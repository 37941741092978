import { all, takeLatest, select, put } from 'redux-saga/effects'

import { resetContentState } from '@tabeeb/modules/shared/content/actions'
import { disableGridView } from '../actions'
import { getIsGridViewActive } from '../selectors'

function* onResetContentState() {
  const isGridViewActive = yield select(getIsGridViewActive)

  if (isGridViewActive) {
    yield put(disableGridView())
  }
}

function* gridViewSaga() {
  yield all([takeLatest(resetContentState, onResetContentState)])
}

export default gridViewSaga
