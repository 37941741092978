import { takeLatest, all, put, select } from 'redux-saga/effects'

import { deleteGalleryItemSuccess, getGalleryItemSuccess, onDeleteGalleryItem } from '@tabeeb/modules/gallery/actions'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getGalleryItemById } from '@tabeeb/modules/gallery/selectors'
import * as notificationActions from '../../notification/actions'
import { signalrEvents, signalrConstants } from '../../signalr'
import { getUniqueAiObjectForEditFailed, getUniqueAiObjects, getUniqueAiObjectsByIds } from '../actions'

function* getPageUniqueAIObjects({ response: { data: page } }) {
  const objectsIds = page.Annotations.map((object) => object.UniqueAIObjectId).filter((id) => !!id)
  if (!objectsIds.length) {
    return
  }

  yield put(getUniqueAiObjectsByIds.request({ uniqueAiObjectIds: objectsIds }))
}

function* onGetUniqueAiObjectsFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load objects.' }))
}

function* onGetUniqueAiObjectForEditFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load the object.' }))
}

function* onAnnotationsDeleted(action) {
  const [pageId, annotationIds] = action.payload
  const contentId = yield select(getContentId)
  const selectedObject = yield select((state) => state.artificialIntelligence.selectedAIObject)

  const page = yield select((state) => getGalleryItemById(state, { id: pageId }))
  if (!page) {
    return
  }

  const deletedAnnotations = page.annotations.filter((annotation) => annotationIds.includes(annotation.Id))
  if (deletedAnnotations.length === 0) {
    return
  }

  const uniqueAiObjectAnnotation = deletedAnnotations.find(
    (annotation) => annotation?.UniqueAIObjectId && selectedObject?.Id === annotation?.AIObjectId
  )

  if (uniqueAiObjectAnnotation) {
    yield put(getUniqueAiObjects.request({ aiObjectId: uniqueAiObjectAnnotation.AIObjectId, contentId }))
  }
}

function* onGalleryItemDeleted() {
  const contentId = yield select(getContentId)
  const selectedObject = yield select((state) => state.artificialIntelligence.selectedAIObject)

  if (selectedObject?.Id) {
    yield put(getUniqueAiObjects.request({ aiObjectId: selectedObject.Id, contentId }))
  }
}

function* getUniqueAIObjects() {
  yield all([
    takeLatest(getGalleryItemSuccess, getPageUniqueAIObjects),
    takeLatest(getUniqueAiObjects.failed, onGetUniqueAiObjectsFailed),
    takeLatest(getUniqueAiObjectForEditFailed, onGetUniqueAiObjectForEditFailed),
    takeLatest(
      [
        deleteGalleryItemSuccess,
        onDeleteGalleryItem,
        signalrEvents[signalrConstants.tabeebHubName].onSessionFolderDeleted,
      ],
      onGalleryItemDeleted
    ),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onAnnotationsDeleted, onAnnotationsDeleted),
  ])
}

export default getUniqueAIObjects
