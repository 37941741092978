import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'

function* showExportPdfNotification(action) {
  const message = 'An email will be sent to you shortly with the PDF download link.'
  yield put(notificationActions.onAddInfoNotification({ message }))
}

function* onExportAsPdf(action) {
  const { recipients } = action.payload
  const contentId = yield select((state) => state.contentState.contentId)
  const exportOption = yield select((state) => state.gallery.pdfOptionsDialogState)
  const exportGalleryOption = yield select((state) => state.gallery.exportGalleryOption)
  const selectedFolderId = yield select((state) => state.gallery.galleryState.selectedFolderId)

  yield put(
    rawActions.exportAsPdfRequest({
      contentId,
      emails: recipients,
      folderId: selectedFolderId,
      exportOption,
      galleryOption: exportGalleryOption,
    })
  )
}

function* exportAsPdfSaga() {
  yield all([
    takeLatest(rawActions.exportAsPdf, onExportAsPdf),
    takeLatest(rawActions.exportAsPdfRequest, showExportPdfNotification),
  ])
}

export default exportAsPdfSaga
