import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { Folder, FolderShared, ViewModule } from '@mui/icons-material'

import { FolderPermission } from '@tabeeb/enums'
import { switchSessionFolder } from '@tabeeb/modules/sessionsPage/actions'
import { getCurrentFolderId, getFoldersList } from '@tabeeb/modules/sessionsPage/selectors'
import { hasFolderPermission } from '@tabeeb/modules/sessionsPage/helpers'

const MoveToFolderMenu = ({ anchorEl, session, open, onClose }) => {
  const dispatch = useDispatch()

  const currentFolderId = useSelector(getCurrentFolderId)
  const folders = useSelector(getFoldersList)

  const onSwitchFolder = (newFolderId) => () => {
    dispatch(
      switchSessionFolder.request({ toFolderId: newFolderId, contentId: session.Id, fromFolderId: currentFolderId })
    )

    onClose()
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          maxWidth: 400,
        },
      }}
    >
      {currentFolderId && (
        <MenuItem onClick={onSwitchFolder(null)}>
          <ListItemIcon>
            <ViewModule />
          </ListItemIcon>
          <ListItemText primary='Main List' />
        </MenuItem>
      )}
      {folders.map(
        (folder) =>
          folder.Id !== currentFolderId &&
          hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.AddUsers) && (
            <MenuItem key={folder.Id} value={folder.Id} onClick={onSwitchFolder(folder.Id)}>
              <ListItemIcon>{folder.IsFolderShared ? <FolderShared /> : <Folder />}</ListItemIcon>
              <ListItemText primary={folder.Name} />
            </MenuItem>
          )
      )}
    </Menu>
  )
}

MoveToFolderMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
}

export default memo(MoveToFolderMenu)
