import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      position: 'absolute',
      textAlign: 'center',
      zIndex: 10,
      backgroundColor: '#404040',

      display: 'grid',
      gridAutoRows: 'minmax(130px, 1fr)',

      padding: theme.spacing(0.5),
      gridRowGap: theme.spacing(0.5),
      gridColumnGap: theme.spacing(0.5),
    },
  }
})
