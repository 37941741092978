import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.open3dModelDialog](state, action) {
      return true
    },
    [rawActions.close3dModelDialog](state, action) {
      return false
    },
    [rawActions.resetState](state, action) {
      return false
    },
  },
  false
)
