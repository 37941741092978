import { combineReducers } from 'redux'

import controlsList from './controlsList'
import tabsList from './tabsList'
import formName from './formName'
import openedTabsSettings from './openedTabsSettings'
import openedControlsSettings from './openedControlsSettings'
import fileLoadingControls from './fileLoadingControls'
import formViewMode from './formViewMode'
import selectedNLPModelId from './selectedNLPModelId'
import settings from './settings'
import isSignatureEnabled from './isSignatureEnabled'

export default combineReducers({
  controlsList,
  tabsList,
  formName,
  openedControlsSettings,
  openedTabsSettings,
  fileLoadingControls,
  formViewMode,
  selectedNLPModelId,
  settings,
  isSignatureEnabled,
})
