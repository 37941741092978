import { put, select, all, takeEvery } from 'redux-saga/effects'

import { onSelectGalleryItem } from '@tabeeb/modules/gallery/actions'

import * as annotationsActions from '../actions'
import * as annotationsSelectors from '../selectors'

import { AnnotationActionType } from '../constants'

function isManagedAction(action) {
  return action.payload.undo === true || action.payload.redo === true
}

function managedAction(type, previousState, nextState) {
  return {
    type,
    previousState,
    nextState,
  }
}

function* onAnnotationAdded(action) {
  const { isHotspot, isAiHotspot, ParentAnnotationId } = action.payload
  if ((isHotspot || isAiHotspot) && ParentAnnotationId) {
    return
  }

  if (isManagedAction(action)) {
    return
  }

  const annotation = action.response.data

  yield put(annotationsActions.addUndoAction(managedAction(AnnotationActionType.Add, null, annotation)))
}

function* onAnnotationRestored(action) {
  if (isManagedAction(action)) {
    return
  }

  const { annotations } = action.payload

  yield put(annotationsActions.addUndoAction(managedAction(AnnotationActionType.Restore, null, annotations)))
}

function* onAnnotationUpdated(action) {
  if (isManagedAction(action)) {
    return
  }

  const { previous, annotation } = action.payload

  yield put(annotationsActions.addUndoAction(managedAction(AnnotationActionType.Update, previous, annotation)))
}

function* onAnnotationDeleted(action) {
  if (isManagedAction(action)) {
    return
  }

  const { annotations } = action.payload

  yield put(annotationsActions.addUndoAction(managedAction(AnnotationActionType.Delete, annotations, null)))
}

function* undoLastAction() {
  const action = yield select(annotationsSelectors.getLastUndoAction)
  if (!action) {
    return
  }

  yield put(annotationsActions.popUndoAction())

  const tag = { undo: true }
  const { previousState, nextState, type } = action

  if (type === AnnotationActionType.Add) {
    yield put(annotationsActions.deleteContentAnnotations({ annotationIds: [nextState.Id], ...tag }))
  }

  if (type === AnnotationActionType.Restore) {
    yield put(
      annotationsActions.deleteContentAnnotations({
        annotationIds: previousState.map((annotation) => annotation.Id),
        ...tag,
      })
    )
  }

  if (type === AnnotationActionType.Delete) {
    yield put(annotationsActions.restoreContentAnnotations({ annotations: previousState, ...tag }))
  }

  if (type === AnnotationActionType.Update) {
    yield put(annotationsActions.updateContentAnnotation({ annotation: previousState, ...tag }))
  }

  yield put(annotationsActions.addRedoAction(action))
}

function* redoLastAction() {
  const action = yield select(annotationsSelectors.getLastRedoAction)
  if (!action) {
    return
  }

  yield put(annotationsActions.popRedoAction())

  const tag = { redo: true }
  const { previousState, nextState, type } = action

  if (type === AnnotationActionType.Add) {
    yield put(annotationsActions.restoreContentAnnotations({ annotations: [nextState], ...tag }))
  }

  if (type === AnnotationActionType.Restore) {
    yield put(
      annotationsActions.deleteContentAnnotations({
        annotationIds: nextState.map((annotation) => annotation.Id),
        ...tag,
      })
    )
  }

  if (type === AnnotationActionType.Delete) {
    yield put(
      annotationsActions.deleteContentAnnotations({
        annotationIds: previousState.map((annotation) => annotation.Id),
        ...tag,
      })
    )
  }

  if (type === AnnotationActionType.Update) {
    yield put(annotationsActions.updateContentAnnotation({ annotationId: nextState.Id, annotation: nextState, ...tag }))
  }

  yield put(annotationsActions.addUndoAction(action))
}

function* resetRedoActionsQueue(action) {
  if (!isManagedAction(action)) {
    yield put(annotationsActions.resetRedoActionsQueue())
  }
}

function* resetUndoRedoQueue(action) {
  yield put(annotationsActions.resetRedoActionsQueue())
  yield put(annotationsActions.resetUndoActionsQueue())
}

function* undoRedoSagas() {
  yield all([
    takeEvery(annotationsActions.addAnnotationSuccess, onAnnotationAdded),
    takeEvery(annotationsActions.restoreAnnotationsSuccess, onAnnotationRestored),
    takeEvery(annotationsActions.updateAnnotationSuccess, onAnnotationUpdated),
    takeEvery(annotationsActions.deleteAnnotationsSuccess, onAnnotationDeleted),
    takeEvery(annotationsActions.undoLastAction, undoLastAction),
    takeEvery(annotationsActions.redoLastAction, redoLastAction),
    takeEvery(
      [
        annotationsActions.addAnnotationRequest,
        annotationsActions.deleteAnnotationsRequest,
        annotationsActions.restoreAnnotationsRequest,
        annotationsActions.updateAiAnnotationRequest,
        annotationsActions.updateAnnotationRequest,
      ],
      resetRedoActionsQueue
    ),
    takeEvery(onSelectGalleryItem, resetUndoRedoQueue),
  ])
}

export default undoRedoSagas
