import { combineReducers } from 'redux'

import { persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

import tenant from './tenant'
import session from './session'

export default persistReducer(
  {
    key: 'permissions',
    storage,
  },
  combineReducers({
    tenant,
    session,
  })
)
