import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { MenuItem, ListItemText } from '@material-ui/core'

const ControlsMenuItem = ({ item, onClick }) => {
  const disabledSelector = useMemo(item.disabledSelector, [])
  const disabled = useSelector((state) => disabledSelector(state))

  const isVisibleSelector = useMemo(item.isVisibleSelector, [])
  const isVisible = useSelector((state) => isVisibleSelector(state))

  if (!isVisible) return null

  return (
    <MenuItem onClick={disabled ? null : () => onClick(item.action)} disabled={disabled} title={item.title}>
      <ListItemText primary={item.title} />
    </MenuItem>
  )
}

ControlsMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    action: PropTypes.func.isRequired,
    disabledSelector: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isVisibleSelector: PropTypes.func.isRequired,
  }),
}

export default ControlsMenuItem
