import { Autocomplete, Divider, Fade, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { ContentType } from '@tabeeb/enums'
import { getContentMetadataButtonLabel } from '@tabeeb/modules/appConfigState/selectors'
import { getGalleryItemsByTypes } from '@tabeeb/modules/gallery/selectors'
import { DragAndDropFiles, Help } from '@tabeeb/uikit'
import { Box } from '@mui/system'

const SelectSourceStep = ({ file, setFile, selectedPageId, setSelectedPageId }) => {
  const spreadsheetsLabel = useSelector(getContentMetadataButtonLabel)
  const availablePages = useSelector((state) =>
    getGalleryItemsByTypes(state, { types: [ContentType.ExcelDocumentPage] })
  )

  const getOptionLabel = useCallback(
    (pageId) => {
      const page = availablePages.find((p) => p.id === pageId)
      return page ? page.name : ''
    },
    [availablePages]
  )

  const showPageAutocomplete = availablePages?.length && !file

  return (
    <Fade in>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: 4,
        }}
      >
        <>
          <Fade in={showPageAutocomplete} unmountOnExit>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ width: '50%', textAlign: 'right' }}>Select a page from gallery: </Typography>
              <Autocomplete
                sx={{ width: '50%' }}
                value={selectedPageId}
                options={availablePages.map((p) => p.id)}
                getOptionLabel={getOptionLabel}
                onChange={(e, value) => setSelectedPageId(value)}
                renderTags={() => []}
                renderOption={(props, pageId) => (
                  <Typography {...props} key={pageId} title={getOptionLabel(pageId)}>
                    {getOptionLabel(pageId)}
                  </Typography>
                )}
                renderInput={(params) => (
                  <TextField {...params} hiddenLabel variant='filled' placeholder='Search a page...' />
                )}
              />
            </Box>
          </Fade>
          <Fade in={!selectedPageId && showPageAutocomplete} unmountOnExit>
            <Box sx={{ width: '100%', m: 2 }}>
              <Divider flexItem sx={{ background: 'none' }}>
                <Typography>Or</Typography>
              </Divider>
            </Box>
          </Fade>
        </>
        {!availablePages?.length && (
          <>
            <Typography wrap>
              There are no Excel files in the Gallery. Please upload the document you want to import.
            </Typography>
            <Box sx={{ width: '100%', m: 2 }}>
              <Divider flexItem sx={{ background: 'none' }} />
            </Box>
          </>
        )}
        <Fade in={!selectedPageId} unmountOnExit>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
                gap: 2,
                mb: 2,
              }}
            >
              <Typography>Upload a new document</Typography>
              <Help text={`The document will be placed in the Gallery and imported into ${spreadsheetsLabel}`} />
            </Box>
            <DragAndDropFiles
              file={file}
              placeholder='Please select a document to upload'
              fileUploadButtonText='Upload File'
              spacing={0}
              acceptMimeFormats={{ 'application/vnd.ms-excel': ['.xlsx'] }}
              acceptedFormatDisplayStrings={['.xlsx']}
              onDropAccepted={(files) => {
                setFile(files[0])
              }}
              onClearState={() => {
                setFile(null)
              }}
            />
          </Box>
        </Fade>
      </Box>
    </Fade>
  )
}

SelectSourceStep.propTypes = {
  file: PropTypes.instanceOf(File),
  setFile: PropTypes.func.isRequired,
  selectedPageId: PropTypes.number,
  setSelectedPageId: PropTypes.func.isRequired,
}

export default SelectSourceStep
