import { handleActions } from 'redux-actions'

import { AnswerStatusFilterOptions } from '@tabeeb/enums'
import * as rawActions from '../actions'

const defaultState = Object.values(AnswerStatusFilterOptions)

export default handleActions(
  {
    [rawActions.setAnswerStatusFilter](state, action) {
      return [...action.payload]
    },
  },
  defaultState
)
