import qs from 'qs'

const formsRequests = {
  getContentFormsRequest: ({ contentId }) => ({
    url: `api/contentform/getcontentformsbycontentId/${contentId}`,
    method: 'get',
  }),
  getFormControlsRequest: ({ contentFormId }) => ({
    url: `api/contentform/getformbycontentformid/${contentFormId}`,
    method: 'get',
  }),
  getTabsAnswersRequest: ({ currentFormId, selectedUserId, formTabs, pageId }) => ({
    url: `api/formanswers/getAnswersByTabId?contentFormId=${currentFormId}&userId=${selectedUserId}&pageId=${pageId}${formTabs}`,
    method: 'get',
  }),
  getLatestTabsAnswersRequest: ({ contentFormId, formTabs, contentSharingId }) => ({
    url: `api/formanswers/getLatestAnswersByTabId?contentFormId=${contentFormId}${formTabs}&contentSharingId=${contentSharingId}`,
    method: 'get',
  }),
  getFormAnswersRequest: ({ contentFormId, userId, pageId }) => ({
    url: `api/formanswers/getanswersbyformId/${contentFormId}/${userId}/${pageId}`,
    method: 'get',
  }),
  getLatestFormAnswersRequest: ({ contentFormId, contentSharingId }) => ({
    url: `api/formanswers/getlatestanswerbyformId/${contentFormId}/${contentSharingId}`,
    method: 'get',
  }),
  getAvailableFormsRequest: (data) => ({
    url: `api/forms/getAvailable`,
    method: 'get',
    params: {
      allForms: data?.allForms,
    },
  }),
  getAvailableFormsByFolderIdRequest: (folderId) => ({
    url: `api/forms/getAvailable`,
    method: 'get',
    params: {
      folderId,
    },
  }),
  getAllFoldersInFormsRequest: () => ({
    url: `api/formFolders`,
    method: 'get',
  }),
  sendFormAnswerRequest: (data) => ({
    url: `api/formanswers`,
    method: 'post',
    data,
  }),
  attachFormRequest: (data) => ({
    url: `api/contentform/attachformtocontent`,
    method: 'post',
    data,
  }),
  detachFormRequest: ({ contentId, formId }) => ({
    url: `api/contentform/detachFormFromContent/${contentId}/${formId}`,
    method: 'delete',
  }),
  exportFormAnswersRequest: ({ pageId }) => ({
    url: `api/pages/${pageId}/formcsv`,
    method: 'get',
  }),
  exportFormToCsvRequest: (data) => ({
    url: `api/forms/exportcsv`,
    method: 'post',
    data,
  }),
  exportFormToCustomCsvRequest: (data) => ({
    url: 'reporting-service/api/reports/contents/export/csv/custom',
    method: 'post',
    data,
  }),
  exportFormToPdfRequest: (data) => ({
    url: `api/contents/exporthtmltopdf`,
    method: 'post',
    data,
  }),
  clearFormAnswerRequest: (formAnswerId) => ({
    url: `api/formanswers/clearanswers/${formAnswerId}`,
    method: 'post',
  }),
  updateAnswerStatusRequest: (data) => ({
    url: `api/formanswers/setformanswerstatus`,
    method: 'put',
    data,
  }),
  getAnswersStatusesCountRequest: ({ contentFormId }) => ({
    url: `api/contentform/getanswersstatusescountbycontentformid/${contentFormId}`,
    method: 'get',
  }),
  getContentFormRequest: ({ ContentId, FormId }) => ({
    url: `api/contentform/getcontentform?contentId=${ContentId}&formId=${FormId}`,
    method: 'get',
  }),
  getContentFormControlsRequest: ({ contentFormId }) => ({
    url: `api/contentform/getformbycontentformid/${contentFormId}`,
    method: 'get',
  }),
  addFormAnswersFromFileRequest: ({ formData, contentFormId, pageId, timestamp, timeZoneOffsetInMinutes }) => ({
    url: `api/formanswers/fromfile`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      timeZoneOffsetInMinutes,
      contentFormId,
      pageId,
      timestamp,
    },
    data: formData,
  }),
  getAvailableFormsForTenantRequest: () => ({
    url: `api/forms`,
    method: 'get',
  }),
  getAvailableFormsForTenantByFolderIdRequest: (folderId) => ({
    url: `api/formFolders/formsInFolder/${folderId}`,
    method: 'get',
  }),
  getAllFoldersInTenantFormsRequest: () => ({
    url: `api/formFolders`,
    method: 'get',
  }),
  attachFormToTenantRequest: (data) => ({
    url: `api/tenantform/attachformtotenant`,
    method: 'post',
    data,
  }),
  detachFormFromTenantRequest: (tenantFormId) => ({
    url: `api/tenantform/detachformfromtenant/${tenantFormId}`,
    method: 'delete',
  }),
  getTenantFormsRequest: (subdomain) => ({
    url: `api/tenantform/gettenantformsbysubdomain/${subdomain}`,
    method: 'get',
  }),
  getTenantFormRequest: (subdomain) => ({
    url: `api/tenantform/gettenantformbysubdomain/${subdomain}`,
    method: 'get',
  }),
  checkTenantFormConditionsRequest: (data) => ({
    url: `api/tenantform/checkconditions`,
    method: 'post',
    data,
  }),
  getAdditionalTenantFormRequest: ({ formId }) => ({
    url: `api/tenantform/getadditionaltenantform/${formId}`,
    method: 'get',
  }),
  triggerFormActionRequest: (data) => ({
    url: `api/formActions/trigger`,
    method: 'post',
    data,
  }),
  saveFormRecordRequest: (data) => ({
    url: `api/contentformrecord/create`,
    method: 'post',
    data,
  }),
  removeFormRecordRequest: (recordId) => ({
    url: `api/contentformrecord/remove/${recordId}`,
    method: 'delete',
  }),
  getFormAnswersByRecordRequest: ({ recordId, formTabs }) => ({
    url: `api/formanswers/getAnswersByRecord?recordId=${recordId}${formTabs}`,
    method: 'get',
  }),
  updateReviewerAnswerStatusRequest: (data) => ({
    url: `api/formanswers/setformanswerstatusbyreviewer`,
    method: 'put',
    data,
  }),
  getFormAnswersByPageRequest: ({ pageId, userId, formId, statuses, skip, take }) => ({
    url: `api/formanswers/bypage/${pageId}`,
    method: 'get',
    params: {
      userId,
      formId,
      statuses,
      skip,
      take,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'indices' }),
  }),
  getFormAnswersByQuestionRequest: ({ questionId, contentFormId, userId, statuses, skip, take }) => ({
    url: `api/formanswers/byformquestion/${contentFormId}/${questionId}`,
    method: 'get',
    params: {
      userId,
      statuses,
      skip,
      take,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'indices' }),
  }),
  getDeletedUserIdsWithAnsweredQuestionsRequest: ({ contentId }) => ({
    url: `api/contentform/answers/${contentId}/deletedUserIds`,
    method: 'get',
  }),
}

export default formsRequests
