import { takeLatest, put, all, select } from 'redux-saga/effects'

import * as galleryActions from '../actions'
import updateOrAddGallery from './updateOrAddGallery'
import { contentNotificationService, contentStateSelectors } from '../../shared/content'
import { ContentType, PageType } from '../../../Enums'
import { getInsertionIndex, getSelectedFolderId } from '../selectors'

function* addDrawingPage(action) {
  const timeOffset = new Date().getTimezoneOffset()
  const selectedFolderId = yield select((state) => getSelectedFolderId(state))
  const contentId = yield select((state) => contentStateSelectors.getContentId(state))
  const documentId = yield select((state) => contentStateSelectors.getDocumentId(state))
  const insertionIndex = yield select((state) => getInsertionIndex(state))

  const page = {
    contentId,
    documentId,
    folderId: selectedFolderId,
    name: '',
    description: '',
    order: insertionIndex,
    type: PageType.Normal,
    width: 1000,
    height: 750,
    timeZoneOffset: -timeOffset,
    contentType: ContentType.DrawingBoard,
  }

  yield put(galleryActions.addDrawingPageRequest(page))
}

function* addDrawingPageSuccess(action) {
  const pages = [action.response.data]
  const isSelectAddedGalleryItem = false
  yield updateOrAddGallery(isSelectAddedGalleryItem, pages)
  yield contentNotificationService.notifyOfNewPages(pages)
}

function* addDrawingPages() {
  yield all([
    takeLatest(galleryActions.addDrawingPage, addDrawingPage),
    takeLatest(galleryActions.addDrawingPageSuccess, addDrawingPageSuccess),
  ])
}

export default addDrawingPages
