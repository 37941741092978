import { put, take, takeEvery, select, call, all } from 'redux-saga/effects'

import _ from 'lodash'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import { recordingActions } from '@tabeeb/modules/recording'
import * as rawActions from '../actions'
import * as fileUploadsActions from '../../fileUploads/actions'
import * as notificationActions from '../../notification/actions'

import { getFileMetaInfo } from '../services/fileMetaInfo'

import { contentNotificationService, contentStateSelectors } from '../../shared/content'
import { isPdf, isImage, isVideo, isDocument, isAutodeskFile, isPointCloudModel } from '../../fileUploads/services'

import { PageType, AssetType, ContentType } from '../../../Enums'
import { getFileUploads } from '../../fileUploads/selectors'
import { FileUploadStatus } from '../../fileUploads/constants'

import updateOrAddGallery from './updateOrAddGallery'

import { getInsertionIndex } from '../selectors'

function* groupFilesRecursive(files, previousPath, parentFolderId, insertionIndex) {
  const contentId = yield select(contentStateSelectors.getContentId)

  const fileGroups = _.groupBy(files, (file) => {
    if (!file.path) {
      return '?'
    }

    const nameParts = file.path.replace(previousPath, '').split('/')

    return nameParts.length > 1 ? nameParts[0] : '?'
  })

  const filesWithData = []

  const currentFolderFiles = fileGroups['?']
  if (currentFolderFiles) {
    const currentFolderFilesWithData = []
    for (const file of currentFolderFiles) {
      file.folderId = parentFolderId
      file.index = insertionIndex

      insertionIndex++

      currentFolderFilesWithData.push(file)
    }

    filesWithData.push(...currentFolderFilesWithData)
  }

  const sortedFoldersNames = _.keys(fileGroups).sort()
  for (const folderName of sortedFoldersNames) {
    const group = fileGroups[folderName]

    if (folderName !== '?') {
      const folder = {
        Name: folderName,
        ContentId: contentId,
        ParentId: parentFolderId,
      }

      yield put(rawActions.createSessionFolderRequest(folder))

      const result = yield take([rawActions.createSessionFolderSuccess])
      const folderId = result.response.data.Id

      const { files: nestedFilesData, newInsertionIndex } = yield call(
        groupFilesRecursive,
        group,
        `${previousPath}${folderName}/`,
        folderId,
        insertionIndex
      )

      filesWithData.push(...nestedFilesData)

      insertionIndex = newInsertionIndex
    }
  }

  return { files: filesWithData, newInsertionIndex: insertionIndex }
}

function* addPagesToGallery(pages) {
  const areAllPagesPdf = pages.every((page) => page.PdfURL)
  if (!areAllPagesPdf) {
    yield contentNotificationService.notifyOfNewPages(pages, true)
  }
}

function* addGalleryFiles(action) {
  const { files, retry, uploadOfLocalRecording } = action.payload
  const contentId = yield select((state) => state.contentState.contentId)

  if (!retry) {
    const fileUploads = yield select(getFileUploads)
    const maxFileUploadIndex = Math.max.apply(
      null,
      fileUploads.map((fileUpload) => (fileUpload.file.index ? fileUpload.file.index : -1))
    )
    const { selectedFolderId } = yield select((state) => state.gallery.galleryState)

    const order = yield select((state) => getInsertionIndex(state))
    const insertionIndex = Math.max(maxFileUploadIndex + 1, order)
    const { files: filesWithData } = yield call(groupFilesRecursive, files, '/', selectedFolderId, insertionIndex)
    yield put(rawActions.addGalleryUploadFiles({ files: filesWithData, contentId }))
  } else {
    yield put(rawActions.addGalleryUploadFiles({ files, retry: true, contentId }))
  }

  const fileUploads = yield select(getFileUploads)
  const resultPages = []
  for (const file of files) {
    const fileUpload = fileUploads.find((fileUpload) => fileUpload.file === file)
    if (!retry && fileUpload && fileUpload.status === FileUploadStatus.Canceled) {
      continue
    }
    const result = yield take([
      rawActions.addGalleryPdfDocumentSuccess,
      rawActions.addGalleryPdfDocumentFailed,
      rawActions.addPageSuccess,
      rawActions.addPageFailed,
      rawActions.addPointCloudModel.success,
      rawActions.addPointCloudModel.failed,
      rawActions.addAutodeskPage.success,
      rawActions.addAutodeskPage.failed,
      rawActions.addGalleryUploadFileCancel,
      fileUploadsActions.clearFileUploads,
      rawActions.addGalleryUploadFileFailed,
    ])
    if (result.type === fileUploadsActions.clearFileUploads().type) {
      break
    } else if (result.type === rawActions.addPageFailed().type) {
      if (result.payload.assets) {
        for (const asset of result.payload.assets) {
          yield put(fileUploadsActions.updateFileUploads([{ url: asset.url, status: FileUploadStatus.Failed }]))
        }
      }
    }
    if (result.type.endsWith('_SUCCESS')) {
      let pageFileUpload
      if (result.payload.assets) {
        const fileUploads = yield select(getFileUploads)
        pageFileUpload = fileUploads.find(
          (fileUpload) =>
            fileUpload.url &&
            fileUpload.url.substring(0, fileUpload.url.lastIndexOf('?')) === result.payload.assets[0].url
        )
      }
      if (pageFileUpload && pageFileUpload.status && pageFileUpload.status !== FileUploadStatus.Done) {
        yield put(rawActions.deleteGalleryItemRequest({ pageId: result.response.data.Id }))
      } else {
        const pages = [result.response.data].flat()
        const select = false
        const suppressMessage = true
        yield call(updateOrAddGallery, select, pages, suppressMessage)

        if (uploadOfLocalRecording) {
          const { ContentId, Id: PageId } = result.response.data
          yield put(
            recordingActions.linkLocalRecordingWithFormAnswers.request({
              ContentId,
              PageId,
              startDate: new Date(uploadOfLocalRecording.startDate),
              endDate: new Date(uploadOfLocalRecording.endDate),
            })
          )
        }

        resultPages.push(result)
      }
    }
  }
  const pages = resultPages.map((page) => page.response.data)
  const pagesFlat = pages.flat()
  if (pagesFlat.length > 0) {
    yield call(addPagesToGallery, pagesFlat)
    const message = pagesFlat.length === 1 ? 'A new page was added.' : 'New pages added.'
    yield put(notificationActions.onAddInfoNotification({ message }))
  }
  yield put(rawActions.dequeueFilesFromQueue())
}

function* addPointCloudModelPage(item, folderId, insertionIndex) {
  const contentId = yield select((state) => state.contentState.contentId)
  const timeOffset = new Date().getTimezoneOffset()

  const itemData = {
    name: item.file.name.replace(/\.[^/.]+$/, ''),
    description: item.file.name,
    contentId,
    folderId,
    timeZoneOffset: -timeOffset,
    urls: [item.url],
  }

  yield put(rawActions.addPointCloudModel.request(itemData))
}

function* addAutodeskFilePage(item, folderId, insertionIndex) {
  const contentId = yield select((state) => state.contentState.contentId)
  const timeOffset = new Date().getTimezoneOffset()

  const itemData = {
    name: item.file.name.replace(/\.[^/.]+$/, ''),
    description: item.file.name,
    contentId,
    folderId,
    timeZoneOffset: -timeOffset,
    url: item.url.substring(0, item.url.lastIndexOf('?')),
  }

  yield put(rawActions.addAutodeskPage.request(itemData))
}

function* addGalleryUploadFileSuccess(action) {
  const item = action.payload

  const { file } = item

  let addAction = null
  if (isPdf(file)) {
    addAction = addGalleryUploadedPdf
  }
  if (isImage(file)) {
    addAction = addGalleryImagePage
  }
  if (isVideo(file)) {
    addAction = addGalleryUploadVideoPage
  }
  if (isDocument(file)) {
    addAction = addGalleryWordDocumentPage
  }

  const isPointCloudModelsEnabled = yield select(appConfigStateSelectors.getIsPointCloudModelsEnabled)
  if (isPointCloudModelsEnabled) {
    if (isPointCloudModel(file)) {
      addAction = addPointCloudModelPage
    }
  }

  const isAutodeskViewerEnabled = yield select(appConfigStateSelectors.getIsAutodeskViewerEnabled)
  if (isAutodeskViewerEnabled) {
    if (isAutodeskFile(file)) {
      addAction = addAutodeskFilePage
    }
  }

  if (!addAction) {
    addAction = addFilePage
  }

  if (addAction) {
    yield call(addAction, item, file.folderId, file.index)
  }
}

function* addFilePage(item, folderId, insertionIndex) {
  const contentId = yield select((state) => state.contentState.contentId)
  const documentId = yield select((state) => state.contentState.documentId)
  const timeOffset = new Date().getTimezoneOffset()
  const order = yield select((state) => getInsertionIndex(state))

  const itemData = {
    name: item.file.name.replace(/\.[^/.]+$/, ''),
    description: item.file.name,
    order: insertionIndex || order,
    contentId,
    documentId,
    folderId,
    type: PageType.Normal,
    timeZoneOffset: -timeOffset,
    contentType: ContentType.File,
    assets: [
      {
        type: AssetType.File,
        url: item.url.substring(0, item.url.lastIndexOf('?')),
        size: item.file.size,
        name: item.file.name,
      },
    ],
  }

  yield put(rawActions.addPageRequest(itemData))
}

function* addGalleryUploadedPdf(item, folderId, insertionIndex) {
  const pdfDocument = item
  const pdfUrlWithoutQuery = pdfDocument.url.split('?')[0]

  const contentId = yield select((state) => state.contentState.contentId)
  const order = yield select((state) => getInsertionIndex(state))
  const timeOffset = new Date().getTimezoneOffset()
  const data = {
    contentId,
    folderId,
    insertionIndex: insertionIndex || order,
    url: pdfUrlWithoutQuery,
    name: item.file.name.replace(/\.[^/.]+$/, ''),
    timeZoneOffset: -timeOffset,

    suppressMessage: true,
    disableNotificationMessage: true,
  }

  yield put(rawActions.addGalleryPdfDocumentRequest(data))
}

function* addGalleryImagePage(item, folderId, insertionIndex) {
  const contentId = yield select((state) => state.contentState.contentId)
  const documentId = yield select((state) => state.contentState.documentId)
  const geoLocation = yield select((state) => state.deviceState.geoLocation)
  const timeOffset = new Date().getTimezoneOffset()

  const metaInfo = {
    latitude: geoLocation.latitude,
    longitude: geoLocation.longitude,
  }

  try {
    const fileMetaInfo = yield call(getFileMetaInfo, item.file)

    metaInfo.latitude = Number.isFinite(fileMetaInfo.latitude) ? fileMetaInfo.latitude : metaInfo.latitude
    metaInfo.longitude = Number.isFinite(fileMetaInfo.longitude) ? fileMetaInfo.longitude : metaInfo.longitude
  } catch (e) {
    console.error(e)
  }
  const order = yield select((state) => getInsertionIndex(state))
  const itemData = {
    name: '',
    description: '',
    order: insertionIndex || order,
    contentId,
    documentId,
    folderId,
    type: PageType.Normal,
    timeZoneOffset: -timeOffset,
    contentType: ContentType.Image,
    assets: [
      {
        type: AssetType.Image,
        url: item.url.substring(0, item.url.lastIndexOf('?')),
        size: item.file.size,
        latitude: metaInfo.latitude,
        longitude: metaInfo.longitude,
        name: item.file.name,
      },
    ],
  }

  yield put(rawActions.addPageRequest(itemData))
}

function* addGalleryUploadVideoPage(item, folderId, insertionIndex) {
  const contentId = yield select((state) => state.contentState.contentId)
  const documentId = yield select((state) => state.contentState.documentId)
  const geoLocation = yield select((state) => state.deviceState.geoLocation)

  const timeOffset = new Date().getTimezoneOffset()
  const order = yield select((state) => getInsertionIndex(state))
  const itemData = {
    contentId,
    documentId,
    folderId,
    name: '',
    description: '',
    order: insertionIndex || order,
    type: PageType.Normal,
    timeZoneOffset: -timeOffset,
    contentType: item.file.contentType || ContentType.UnencodedVideo,
    assets: [
      {
        type: item.file.assetType || AssetType.UnencodedVideo,
        url: item.url.substring(0, item.url.lastIndexOf('?')),
        size: item.file.size,
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
        name: item.file.name,
      },
    ],
  }

  yield put(rawActions.addPageRequest(itemData))
}

function* addGalleryWordDocumentPage(item, folderId, insertionIndex) {
  const contentId = yield select((state) => state.contentState.contentId)
  const documentId = yield select((state) => state.contentState.documentId)
  const timeOffset = new Date().getTimezoneOffset()

  let contentType = null
  let assetType = null

  const fileExtension = item.file.name.substr(item.file.name.lastIndexOf('.') + 1)
  switch (fileExtension) {
    case 'doc':
    case 'docx':
      contentType = ContentType.DocDocumentPage
      assetType = AssetType.DocDocument
      break
    case 'ppt':
    case 'pptx':
      contentType = ContentType.PptDocumentPage
      assetType = AssetType.PptDocument
      break
    case 'xls':
    case 'xlsx':
      contentType = ContentType.ExcelDocumentPage
      assetType = AssetType.ExcelDocument
      break
  }

  if (!contentType) {
    return
  }
  const order = yield select((state) => getInsertionIndex(state))
  const itemData = {
    name: item.file.name.replace(/\.[^/.]+$/, ''),
    description: item.file.name,
    order: insertionIndex || order,
    contentId,
    documentId,
    folderId,
    type: PageType.Normal,
    timeZoneOffset: -timeOffset,
    contentType,
    assets: [
      {
        type: assetType,
        url: item.url.substring(0, item.url.lastIndexOf('?')),
        size: item.file.size,
        name: item.file.name,
      },
    ],
  }
  yield put(rawActions.addPageRequest(itemData))
}

function* addFilesToQueue(action) {
  let { files } = action.payload
  const { retry, uploadOfLocalRecording } = action.payload

  if (!Array.isArray(files)) {
    files = [...files]
  }

  const queue = yield select((state) => state.gallery.fileUploadsQueue)
  if (queue.length <= 1) {
    yield put(rawActions.addGalleryFiles({ files, retry, uploadOfLocalRecording }))
  } else {
    yield put(fileUploadsActions.addFileUploads(files.map((file) => ({ file, value: 0 }))))
  }
  yield put(
    notificationActions.onAddInfoNotification({
      message: retry
        ? 'Your upload was retried and now is in progress. This process may take a few minutes'
        : 'Your upload is in progress. This process may take a few minutes',
    })
  )
}

function* dequeueFilesFromQueue() {
  const queue = yield select((state) => state.gallery.fileUploadsQueue)
  if (queue.length > 0) {
    let { files, retry } = queue[0]

    if (retry) {
      const fileUploads = yield select((state) => state.fileUploads.list)
      files = fileUploads
        .filter(
          (fileUpload) => fileUpload.status !== FileUploadStatus.Done && files.some((file) => file === fileUpload.file)
        )
        .map((fileUpload) => fileUpload.file)
    }

    if (files.length > 0) {
      yield put(rawActions.addGalleryFiles({ files, retry }))
    } else {
      yield put(rawActions.dequeueFilesFromQueue())
    }
  }
}

function* clearFilesQueue() {
  yield put(rawActions.clearFilesQueue())
}

function* uploadFiles() {
  yield all([
    takeEvery(rawActions.addGalleryUploadFileSuccess, addGalleryUploadFileSuccess),
    takeEvery(rawActions.addGalleryFiles, addGalleryFiles),
    takeEvery(rawActions.addFilesToQueue, addFilesToQueue),
    takeEvery(rawActions.dequeueFilesFromQueue, dequeueFilesFromQueue),
    takeEvery(fileUploadsActions.clearFileUploads, clearFilesQueue),
  ])
}

export default uploadFiles
