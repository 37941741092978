import oidcUserManager from '@tabeeb/services/oidcUserManager'

const selector = {
  getApiUrl: () => process.env.TABEEB_API_URL,
  getAccessToken: () => oidcUserManager.getUserSync()?.access_token,
  getAccessTokenPayload: () => {
    const token = oidcUserManager.getUserSync()?.access_token
    if (!token) {
      return null
    }
    const tokenSegments = token.split('.')
    const tokenPayload = JSON.parse(atob(tokenSegments[1]))
    return tokenPayload
  },
  getContent: (state) => state.contentState,
  getUsers: (state) => state.users,
  getDrawing: (state) => state.playerToolbar.drawing,
  getSelectedGalleryItemId: (state) => state.gallery.galleryState.selectedGalleryItemId,
  getGalleryList: (state) => state.gallery.galleryList,
  getConnectionState: (state) => state.presentation.connection,
}

export default selector
