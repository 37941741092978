import { createSelector } from 'reselect'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { getMe, getCurrentUserId } from '@tabeeb/modules/account/selectors'

import { FolderPermission, SessionPermission, TenantPermission, UserRole } from '@tabeeb/enums'
import { hasFolderPermission } from '@tabeeb/modules/sessionsPage/helpers'

import {
  getIsSessionPublishingEnabled,
  getIsSessionSharingEnabled,
  getIsBulkCopyEnabled,
} from '@tabeeb/modules/appConfigState/selectors'

import { SessionAction } from '../enums'

export const getContentsList = (state) => state.sessionsPage.sessionsList
export const getFoldersList = (state) => state.sessionsPage.foldersList
export const getCurrentFolder = (state) => state.sessionsPage.currentFolder
export const getCurrentFolderId = (state) => state.sessionsPage.currentFolderId
export const getIsTrashOpen = (state) => state.sessionsPage.isTrashBinOpen

export const getIsContentOwner = createSelector(
  [getCurrentUserId, (_, session) => session],
  (currentUserId, session) => {
    return session && session.Owner ? session.Owner.Id === currentUserId : false
  }
)

export const getHasSessionPermission = (session, permission) => {
  const permissions = session.Permissions || [0]
  return permissions.includes(permission)
}

export const getSessionActions = (state) => state.sessionsPage.configuration.sessionActions
export const getIsSessionActionEnabled = createSelector(
  [getSessionActions, (_, action) => action],
  (actions, action) => {
    return actions.includes(action)
  }
)

export const getIsBulkCopySessionActionEnabled = (state) => getIsSessionActionEnabled(state, SessionAction.BulkCopy)
export const getIsCopySessionActionEnabled = (state) => getIsSessionActionEnabled(state, SessionAction.Copy)
export const getIsDeleteSessionActionEnabled = (state) => getIsSessionActionEnabled(state, SessionAction.Delete)
export const getIsLeaveSessionActionEnabled = (state) => getIsSessionActionEnabled(state, SessionAction.Leave)
export const getIsMoveSessionActionEnabled = (state) => getIsSessionActionEnabled(state, SessionAction.Move)
export const getIsPublishSessionActionEnabled = (state) => getIsSessionActionEnabled(state, SessionAction.Publish)
export const getIsRestoreSessionActionEnabled = (state) => getIsSessionActionEnabled(state, SessionAction.Restore)
export const getIsShareSessionActionEnabled = (state) => getIsSessionActionEnabled(state, SessionAction.Share)

export const getHasBulkCopyPermission = (state) => hasTenantPermission(state, TenantPermission.BulkCopy)

export const getCanBulkCopySession = createSelector(
  [
    getIsBulkCopyEnabled,
    getIsBulkCopySessionActionEnabled,
    getHasBulkCopyPermission,
    getIsContentOwner,
    (_, session) => session,
  ],
  (isBulkCopyEnabled, isActionEnabled, hasPermission, isOwner, session) => {
    return hasPermission && isActionEnabled && isBulkCopyEnabled && isOwner && session.IsActive && !session.IsDeleted
  }
)

export const getCanShareSession = createSelector(
  [getIsSessionSharingEnabled, getIsShareSessionActionEnabled, getIsContentOwner, (_, session) => session],
  (isSessionSharingEnabled, isActionEnabled, isOwner, session) => {
    return (
      isSessionSharingEnabled &&
      isActionEnabled &&
      isOwner &&
      session.IsActive &&
      !session.IsDeleted &&
      getHasSessionPermission(session, SessionPermission.ExportSession)
    )
  }
)

export const getCanPublishSession = createSelector(
  [getIsSessionPublishingEnabled, getIsPublishSessionActionEnabled, getIsContentOwner, (_, session) => session],
  (isSessionPublishingEnabled, isActionEnabled, isOwner, session) => {
    return (
      isSessionPublishingEnabled &&
      isActionEnabled &&
      isOwner &&
      session.IsActive &&
      !session.IsDeleted &&
      getHasSessionPermission(session, SessionPermission.PublishSessions)
    )
  }
)

export const getCanCopySession = createSelector(
  [getIsCopySessionActionEnabled, (_, session) => session],
  (isActionEnabled, session) => {
    return isActionEnabled && !session.IsDeleted
  }
)

export const getCanDeleteSession = createSelector(
  [getIsDeleteSessionActionEnabled, getIsContentOwner, (_, session) => session],
  (isActionEnabled, isOwner, session) => {
    return isActionEnabled && isOwner
  }
)

export const getCanLeaveSession = createSelector(
  [getIsLeaveSessionActionEnabled, getIsContentOwner, (_, session) => session],
  (isActionEnabled, isOwner, session) => {
    return isActionEnabled && !isOwner && ![UserRole.Reviewer, UserRole.SessionGuest].includes(session.Role)
  }
)

export const getCanMoveSessionToFolders = createSelector(
  [getFoldersList, getCurrentFolder, getMe, getIsMoveSessionActionEnabled, (_, session) => session],
  (foldersList, currentFolder, currentUser, isActionEnabled, session) => {
    if (session.IsDeleted || !isActionEnabled) {
      return false
    }

    const canMoveContent = Array.isArray(currentFolder?.FolderUsersPermissions)
      ? hasFolderPermission(currentFolder.FolderUsersPermissions, FolderPermission.MoveContent)
      : true

    const availableFoldersForMove = foldersList.filter((f) => !f.IsFolderShared || f.OwnerName === currentUser.Name)

    return availableFoldersForMove.length > 0 && canMoveContent
  }
)

export const getCanRestoreSession = createSelector(
  [getIsRestoreSessionActionEnabled, (_, session) => session],
  (isActionEnabled, session) => {
    return isActionEnabled && session.IsDeleted
  }
)
