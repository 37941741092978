import { combineReducers } from 'redux'

import tenantConfigForUpdate from './tenantConfigForUpdate'
import tenantsList from './tenantsList'
import currentTenant from './currentTenant'
import isTenantConfigLoading from './isTenantConfigLoading'
import ftpSettings from './ftpSettings'

export default combineReducers({
  tenantConfigForUpdate,
  tenantsList,
  currentTenant,
  isTenantConfigLoading,
  ftpSettings,
})
