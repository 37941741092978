import { combineActions, handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.setOpenedForm](state, action) {
      return action.payload
    },
    [rawActions.addTagToFormControl](state, action) {
      const addedTag = action.payload
      const formControlId = addedTag.FormControlId
      return {
        ...state,
        Controls: state.Controls.map((control) => {
          if (control.Id === formControlId) {
            if (!control.Tags.some((tag) => tag.TagId === addedTag.TagId)) {
              control.Tags.push(addedTag)
            }
          }

          return control
        }),
      }
    },
    [rawActions.removeTagFromFormControl](state, { payload: { controlId, formControlTagId } }) {
      return {
        ...state,
        Controls: state.Controls.map((control) => {
          if (control.Id === controlId) {
            control.Tags = control.Tags.filter((tag) => tag.Id !== formControlTagId)
          }

          return control
        }),
      }
    },
    [rawActions.detachVqaModelFromFormSuccess](state, { payload: { VQAModelId } }) {
      const newVQAModelForms = state.VQAModelForms.filter((vqaModelForm) => vqaModelForm.VQAModelId !== VQAModelId)
      return { ...state, VQAModelForms: [...newVQAModelForms] }
    },
    [rawActions.attachVqaModelToFormSuccess](state, { response }) {
      return { ...state, VQAModelForms: [response.data] }
    },
    [combineActions(
      rawActions.detachVqaPolicyFromFormControlOptionSuccess,
      rawActions.attachVqaPolicyToFormControlOptionSuccess,
      rawActions.attachAiExpressionToFormControlOption.success,
      rawActions.detachAiExpressionFromFormControlOption.success
    )](state, { response }) {
      const updatedOption = response.data

      const controls = state.controlsList.map((control) => {
        return {
          ...control,
          Options: control.Options?.map((option) =>
            option.Id === updatedOption.Id
              ? { ...option, VQAPolicyId: updatedOption.VQAPolicyId, AIExpressionId: updatedOption.AIExpressionId }
              : option
          ),
        }
      })

      return { ...state, controlsList: [...controls] }
    },
    [rawActions.updateFormControlProcessing.success](state, { response }) {
      const updatedControl = response.data

      const controls = state.controlsList.map((control) => {
        return control.Id === updatedControl.FormControlId
          ? { ...control, ProcessByVQAModel: updatedControl.ProcessByVQAModel }
          : control
      })

      return { ...state, controlsList: [...controls] }
    },
    [rawActions.updateFormInfoControlsList](state, action) {
      return { ...state, controlsList: action.payload }
    },
  },
  {}
)
