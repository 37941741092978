import { eventChannel } from 'redux-saga'
import { put, select, fork, call, take, all, takeEvery } from 'redux-saga/effects'

import selector from '@tabeeb/modules/shared/utils/selector'

import { onLogout } from '@tabeeb/modules/account/actions'
import { getIsUserAuthorized } from '@tabeeb/modules/account/selectors'

import * as signalrEvents from '../events'
import * as signalrActions from '../actions'
import * as signalrService from '../services/signalrService'
import * as signalrConstants from '../services/constants'

import hubCalls from './hubCalls'

import { getSignalRConnection, getSignalRConnectionState, getSignalRProgressState, getSignalRState } from '../selectors'
import { tabeebHubName } from '../services/constants'

function registerActions(connection, hubName) {
  return eventChannel((emit) => {
    Object.entries(signalrEvents[hubName]).forEach(([signalrEvent, action]) => {
      connection.on(signalrEvent, (...args) => {
        emit(action(args))
      })
    })

    connection.onreconnected((connectionId) => {
      if (signalrEvents[hubName].onReconnected) {
        emit(signalrEvents[hubName].onReconnected({ connectionId }))
      }
    })

    connection.onclose((connectionId) => {
      if (signalrEvents[hubName].onClose) {
        emit(signalrEvents[hubName].onClose({ connectionId }))
      }
    })

    return () => {}
  })
}

function* handleSignalRActions(connection, hubName) {
  const channel = yield call(registerActions, connection, hubName)
  while (true) {
    const action = yield take(channel)

    yield put(action)
  }
}

function* createConnection(action) {
  const hubName = action.payload?.hubName || tabeebHubName

  const accessToken = yield select(selector.getAccessToken)
  const isAuthorized = yield select(getIsUserAuthorized)
  const isEstablished = yield select((state) => getSignalRConnectionState(state, hubName))
  const isInProgress = yield select((state) => getSignalRProgressState(state, hubName))
  if (!accessToken || !isAuthorized || isEstablished || isInProgress) {
    return
  }

  try {
    yield put(signalrActions.setConnectionProgressState())
    const connection = signalrService.configureConnection({
      hubName,
      apiUrl: signalrConstants.routes[hubName],
      accessToken,
    })

    yield fork(handleSignalRActions, connection, hubName)

    yield connection.start()

    yield put(signalrActions.createConnectionSuccess({ connection, hubName }))
    yield put(signalrActions.resetConnectionProgressState({ hubName }))
  } catch (e) {
    yield put(signalrActions.createConnectionFailed({ hubName }))
    yield put(signalrActions.resetConnectionProgressState({ hubName }))
  }
}

function* terminateConnection({ payload }) {
  const hubName = payload?.hubName || tabeebHubName

  const connection = yield select((state) => getSignalRConnection(state, hubName))
  const isEstablished = yield select((state) => getSignalRConnectionState(state, hubName))
  if (!connection || !isEstablished) {
    return
  }

  try {
    yield connection.stop()

    yield put(signalrActions.terminateConnectionSuccess({ hubName }))
  } catch {
    yield put(signalrActions.terminateConnectionFailed({ hubName }))
  }
}

function* terminateAllConnections() {
  const connection = yield select(getSignalRState)

  const hubNames = Object.keys(connection)

  for (const hubName in hubNames) {
    if (hubName) {
      yield put(signalrActions.terminateConnection({ hubName }))
    }
  }
}

export default function* signalrConnection() {
  yield all([
    takeEvery(signalrActions.terminateConnection, terminateConnection),
    takeEvery(signalrActions.createConnection, createConnection),
    takeEvery(onLogout, terminateAllConnections),
  ])
  yield fork(hubCalls)
}
