import { createSelector } from 'reselect'
import { IterationStatus, IterationDeploymentStatus } from '@tabeeb/enums'
import { getTrainingSettings } from './trainingSettings'

export * from './trainingSettings'
export * from './annotationSourceOptions'

export const getAiObjects = (state) => state.modelTraining.aiObjects
export const getAnnotationSource = (state) => state.modelTraining.trainingSettings.annotationSource
export const getCurrentIterationPagesDisplayMode = (state) => state.modelTraining.currentIterationPagesDisplayMode
export const getCustomMetricsCharts = (state) => state.modelTraining.customMetricsCharts
export const getIsDeploymentStarting = (state) => state.modelTraining.isDeploymentStarting
export const getIsFastExcludeModeOn = (state) => state.modelTraining.isFastExcludeModeOn
export const getIsIterationComponentsLoading = (state) => state.modelTraining.isIterationComponentsLoading
export const getIsIterationDeploymentStatusLoading = (state) => state.modelTraining.isIterationDeploymentStatusLoading
export const getIsIterationStatusLoading = (state) => state.modelTraining.isIterationStatusLoading
export const getIsMetricsLoading = (state) => state.modelTraining.isMetricsLoading
export const getIsPagesAnalysing = (state) => state.modelTraining.isPagesAnalysing
export const getIsPagesAnalysisDialogOpen = (state) => state.modelTraining.isPagesAnalysisDialogOpen
export const getIsPagesLoading = (state) => state.modelTraining.isPagesLoading
export const getIsProjectsLoading = (state) => state.modelTraining.isProjectsLoading
export const getIsTrainingSettingsDialogOpen = (state) => state.modelTraining.isTrainingSettingsDialogOpen
export const getIsTrainingStarting = (state) => state.modelTraining.isTrainingStarting
export const getPageNumber = (state) => state.modelTraining.pageNumber
export const getPages = (state) => state.modelTraining.pages
export const getPageSize = (state) => state.modelTraining.pageSize
export const getProjectForEdit = (state) => state.modelTraining.projectForEdit
export const getProjects = (state) => state.modelTraining.projects
export const getProjectToDelete = (state) => state.modelTraining.projectToDelete
export const getSelectedAIObjects = (state) => state.modelTraining.selectedAIObjects
export const getSelectedAnnotationDates = (state) => state.modelTraining.selectedAnnotationDates
export const getSelectedAnnotationOwners = (state) => state.modelTraining.selectedAnnotationOwners
export const getSelectedAnswerers = (state) => state.modelTraining.selectedAnswerers
export const getSelectedApprovers = (state) => state.modelTraining.selectedApprovers
export const getSelectedContents = (state) => state.modelTraining.selectedContents
export const getSelectedFormAnswerStatuses = (state) => state.modelTraining.selectedFormAnswerStatuses
export const getSelectedFormAnswerValue = (state) => state.modelTraining.selectedFormAnswerValue
export const getSelectedFormControls = (state) => state.modelTraining.selectedFormControls
export const getSelectedForms = (state) => state.modelTraining.selectedForms
export const getSelectedIterationId = (state) => state.modelTraining.selectedIterationId
export const getSelectedPagesForExclusion = (state) => state.modelTraining.selectedPagesForExclusion
export const getSelectedProjectIterations = (state) => state.modelTraining.iterations
export const getSelectedReviewers = (state) => state.modelTraining.selectedReviewers
export const getSelectedSearchField = (state) => state.modelTraining.selectedSearchField

export const getIsAnyIterationSelected = createSelector([getSelectedIterationId], (selectedIterationId) => {
  return selectedIterationId !== 0
})

export const getSelectedIteration = createSelector(
  [getSelectedIterationId, getSelectedProjectIterations],
  (selectedIterationId, iterations) => {
    const selectedIteration = iterations.find((iteration) => iteration.Id === selectedIterationId)
    return selectedIteration
  }
)

const makeSelectorBasedOnSelectedIteration = (filterSelector, callback) =>
  createSelector([filterSelector, getSelectedIteration], (items, selectedIteration) => {
    if (!selectedIteration) {
      return items
    }

    return callback(selectedIteration)
  })

export const getContentsBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedContents,
  (iteration) => iteration.Contents
)

export const getPagesBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getPages,
  (iteration) => iteration.Pages
)

export const getFormsBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedForms,
  (iteration) => iteration.Forms
)

export const getFormAnswerValueBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedFormAnswerValue,
  (iteration) => iteration.FormAnswerValue
)

export const getFormControlsBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedFormControls,
  (iteration) => iteration.FormControls
)

export const getFormAnswerStatusesBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedFormAnswerStatuses,
  (iteration) => iteration.FormAnswerStatuses
)

export const getAnswerersBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedAnswerers,
  (iteration) => iteration.Answerers
)

export const getReviewersBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedReviewers,
  (iteration) => iteration.Reviewers
)

export const getApproversBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedApprovers,
  (iteration) => iteration.Approvers
)

export const getAiObjectsBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedAIObjects,
  (iteration) => iteration.AIObjects
)

export const getAnnotationOwnersBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedAnnotationOwners,
  (iteration) => iteration.AnnotationOwners
)

export const getAnnotationDatesBasedOnSelectedIteration = makeSelectorBasedOnSelectedIteration(
  getSelectedAnnotationDates,
  (iteration) => ({
    StartDate: iteration.AnnotationStartDate,
    EndDate: iteration.AnnotationEndDate,
  })
)

export const getMetricsBasedOnSelectedIteration = createSelector([getSelectedIteration], (selectedIteration) => {
  if (!selectedIteration) {
    return []
  }

  return selectedIteration.Metrics
})

export const getCustomMetricsChartsBasedOnSelectedIteration = createSelector(
  [getSelectedIterationId, getCustomMetricsCharts],
  (iterationId, charts) => {
    return charts.filter((chart) => chart.iterationId === iterationId)
  }
)

export const getIsTrainingEnabled = createSelector(
  [getIsTrainingStarting, getTrainingSettings, getPages, getIsAnyIterationSelected],
  (isTrainingStarting, trainingSettings, pages, isAnyIterationSelected) => {
    return !isAnyIterationSelected && !isTrainingStarting && pages.length > 0 && trainingSettings.annotationSource >= 0
  }
)

export const getIsAnalysingEnabled = createSelector(
  [getIsTrainingStarting, getIsAnyIterationSelected],
  (isTrainingStarting, isAnyIterationSelected) => {
    return !isAnyIterationSelected && !isTrainingStarting
  }
)

export const getIsIterationDeploymentEnabled = createSelector(
  [getIsDeploymentStarting, getSelectedIteration],
  (isDeploymentStarting, selectedIteration) => {
    return (
      !isDeploymentStarting &&
      selectedIteration &&
      selectedIteration.Status === IterationStatus.Completed &&
      (selectedIteration.DeploymentStatus === IterationDeploymentStatus.Stopped ||
        selectedIteration.DeploymentStatus === IterationDeploymentStatus.NotDeployed ||
        selectedIteration.DeploymentStatus === IterationDeploymentStatus.Failed ||
        selectedIteration.DeploymentStatus === IterationDeploymentStatus.FailedToSendToDeploy)
    )
  }
)

export const getIsProjectComponentsLoading = createSelector(
  [getIsTrainingStarting, getIsIterationComponentsLoading],
  (isTrainingStarting, isIterationComponentsLoading) => {
    return isTrainingStarting || isIterationComponentsLoading
  }
)

export const getIsAnySearchEntitySelected = createSelector(
  [getSelectedAIObjects, getSelectedContents, getSelectedForms],
  (selectedAIObjects, selectedContents, selectedForms) => {
    return selectedAIObjects.length > 0 || selectedContents.length > 0 || selectedForms.length > 0
  }
)

export const getIsAnnotationSourceSelected = createSelector([getAnnotationSource], (annotationSource) => {
  return annotationSource !== -1
})

export const getIsSelectedIterationCompleted = createSelector([getSelectedIteration], (selectedIteration) => {
  if (!selectedIteration) {
    return false
  }

  return selectedIteration.Status === IterationStatus.Completed
})
