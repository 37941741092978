import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { SeverityLevel } from '@microsoft/applicationinsights-common'
import ai from '@tabeeb/services/telemetryService'
import selector from '@tabeeb/shared/utils/selector'
import domainService from '@tabeeb/services/domainService'

const LogLevelMap = {
  [LogLevel.Trace]: SeverityLevel.Verbose,
  [LogLevel.Debug]: SeverityLevel.Verbose,
  [LogLevel.Information]: SeverityLevel.Information,
  [LogLevel.Warning]: SeverityLevel.Warning,
  [LogLevel.Error]: SeverityLevel.Error,
  [LogLevel.Critical]: SeverityLevel.Critical,
}

class SignalrLogger {
  constructor(minimumLogLevel) {
    this._minLevel = minimumLogLevel
  }

  log(logLevel, message) {
    if (logLevel >= this._minLevel) {
      ai.appInsights?.trackTrace({
        severityLevel: LogLevelMap[logLevel],
        message,
        properties: { featureId: 'signalr' },
      })
    }
  }
}

export function configureConnection({ apiUrl, hubName }) {
  const connection = new HubConnectionBuilder()
    .withUrl(`${apiUrl}${hubName}`, {
      accessTokenFactory: () => selector.getAccessToken(),
      headers: { 'X-Tenant-Subdomain': domainService.subdomain },
    })
    .withAutomaticReconnect()
    .configureLogging(new SignalrLogger(LogLevel.Warning))
    .build()

  return connection
}
