/**
 * Used for individual file uploading. Requires jQuery
 * @param file
 * @param sasUrl
 * @param options {{maxBlockSize:Number, method:String}}
 * @constructor
 */
function AzureFileUploader(file, sasUrl, options) {
  this.file = file
  this.sasUrl = sasUrl

  const _$this = $(this)

  const _opts = $.extend(
    {
      maxBlockSize: Math.min(256 * 1024, file.size),
      method: 'PUT',
      progressCallback(progress) {},
    },
    options
  )

  let _reader = new FileReader()
  const _blockIds = []
  let _totalBytesRemaining = file.size
  let _currentFilePointer = 0
  let _bytesUploaded = 0
  let _numberOfBlocks
  let _cancelled = false
  let _deferred = null

  // Event triggers

  const _updateFileUploadProgress = function () {
    const progress = ((parseFloat(_bytesUploaded) / parseFloat(file.size)) * 100).toFixed(2)
    const progressValue = { file, progress }
    _$this.trigger($.Event(AzureFileUploader.Event.uploadPercentChanged, progressValue))
    _opts.progressCallback(progress)
  }

  const _uploadCompleted = function () {
    _$this.trigger($.Event(AzureFileUploader.Event.uploadComplete, { file, progress: 100 }))
  }

  const _uploadCancelled = function () {
    _$this.trigger(
      $.Event(AzureFileUploader.Event.uploadCancelled, {
        file,
        progress: ((parseFloat(_bytesUploaded) / parseFloat(file.size)) * 100).toFixed(2),
      })
    )
  }

  const _commitBlockList = function () {
    _reader = null // Remove extra (self-referencing) reference
    const uri = `${sasUrl}&comp=blocklist`
    let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>'
    for (let i = 0; i < _blockIds.length; i++) {
      requestBody += `<Latest>${_blockIds[i]}</Latest>`
    }
    requestBody += '</BlockList>'
    $.ajax({
      url: uri,
      type: 'PUT',
      data: requestBody,
      beforeSend(xhr) {
        xhr.setRequestHeader('x-ms-blob-content-type', file.type)
      },
      success(data, status) {
        _deferred.resolve(status)
      },
      error(xhr, desc, err) {
        console.log(desc)
        console.log(err)
        _deferred.reject('Upload has failed.')
      },
    })
  }

  const _uploadInBlocks = function () {
    if (_cancelled) {
      _deferred.reject('Upload has been cancelled.')
      return
    }

    if (_totalBytesRemaining <= 0) {
      _commitBlockList(_deferred)
      return
    }

    const fileContent = file.slice(_currentFilePointer, _currentFilePointer + _opts.maxBlockSize)
    const blockId = `block-${_pad(_blockIds.length, 6)}`
    _blockIds.push(btoa(blockId))
    _reader.readAsArrayBuffer(fileContent)
    _currentFilePointer += _opts.maxBlockSize
    _totalBytesRemaining -= _opts.maxBlockSize
    _updateFileUploadProgress()
  }

  var _pad = function (number, length) {
    let str = `${number}`
    while (str.length < length) {
      str = `0${str}`
    }
    return str
  }

  const _onVideoReaderLoadEnd = function (e) {
    if (e.target.readyState != FileReader.DONE) {
      return
    }

    const uri = `${sasUrl}&comp=block&blockid=${_blockIds[_blockIds.length - 1]}`
    const requestData = new Uint8Array(e.target.result)
    $.ajax({
      url: uri,
      type: _opts.method,
      data: requestData,
      processData: false,
      beforeSend(xhr) {
        xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob')
      },
      success() {
        _bytesUploaded += requestData.length
        _uploadCompleted()
        _uploadInBlocks()
      },
      error(xhr, desc, err) {
        console.log(desc)
        console.log(err)
        _deferred.reject('Upload has failed.')
      },
    })
  }

  // Public methods

  this.upload = function () {
    _deferred = $.Deferred()
    _reader.onloadend = _onVideoReaderLoadEnd
    _numberOfBlocks = parseInt(file.size / _opts.maxBlockSize) + (file.size % _opts.maxBlockSize == 0 ? 0 : 1)

    _uploadInBlocks()

    return _deferred
  }

  this.cancel = function () {
    _cancelled = true
    _uploadCancelled()
  }
}

AzureFileUploader.Event = {
  uploadPercentChanged: 'uploadPercentChanged',
  uploadComplete: 'uploadComplete',
  uploadCancelled: 'uploadCancelled',
}

export default AzureFileUploader
