import { put, takeLatest, select, all, delay, call } from 'redux-saga/effects'

import { contentStateSelectors } from '@tabeeb/modules/shared/content'
import { signalrActions, signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { getFacingMode, getSelectedUser } from '@tabeeb/modules/presentation/selectors'
import { FlashResponseDelay } from '../common/flash'
import * as flashActions from '../actions/flash'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { isMobileOnly } from 'react-device-detect'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { toggleMobileFlash } from '@tabeeb/modules/presentation/actions/tracks'
import { CAMERA_FACING_MODE } from '@tabeeb/modules/presentation/constants'

function* onFlashTurnOn({ payload, response }) {
  yield put(flashActions.onFlashProgressStateUpdate(true))
  const selectedUser = yield select((state) => getSelectedUser(state))

  if (!selectedUser) {
    return
  }

  const contentId = yield select(contentStateSelectors.getContentId)

  yield put(signalrActions.invokeHubAction({ method: 'FlashTurnOn', args: [contentId, selectedUser.id, payload] }))

  yield delay(FlashResponseDelay)

  const flashInProgress = yield select((state) => state.presentationToolbar.flashInProgress)
  if (flashInProgress === true) {
    yield put(flashActions.onFlashProgressStateUpdate(false))
  }
}

function* onFlashUpdate() {
  yield put(flashActions.onFlashProgressStateUpdate(false))
}

function* onFlashToggleValueUpdated(action) {
  const [emitEventuserId, value] = action.payload

  const selectedUser = yield select((state) => getSelectedUser(state))
  if (selectedUser?.id === emitEventuserId) {
    yield put(flashActions.onFlashUpdate(value))
  }
}

function* onFlashTurnedOn(action) {
  const [contentId, userId, enabled] = action.payload

  const currentUserId = yield select(getCurrentUserId)
  const currentContentId = yield select(getContentId)
  const currentFacingMode = yield select(getFacingMode)

  if (
    !isMobileOnly ||
    userId !== currentUserId ||
    currentContentId !== contentId ||
    currentFacingMode === CAMERA_FACING_MODE.USER
  ) {
    return
  }

  yield put(toggleMobileFlash(enabled))
  yield put(signalrActions.invokeHubAction({ method: 'UpdateFlashToggleValue', args: [contentId, userId, enabled] }))
}

function* flashSaga() {
  yield all([
    takeLatest(flashActions.onFlashTurnOn, onFlashTurnOn),
    takeLatest(flashActions.onFlashUpdate, onFlashUpdate),
    // signalR
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFlashToggleValueUpdated, onFlashToggleValueUpdated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFlashTurnedOn, onFlashTurnedOn),
  ])
}

export default flashSaga
