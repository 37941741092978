import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ControlType } from '@tabeeb/enums'

const DateTime = ({ disabled, control, answer, handleChange }) => {
  const value = answer.value || null
  const isTimeIncluded = control.ControlType === ControlType.DateTime

  const _onChange = (value) => {
    const utcDate = value.toISOString()
    handleChange(utcDate)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {isTimeIncluded ? (
        <DateTimePicker
          value={value}
          onChange={_onChange}
          format='MM/dd/yyyy  HH:mm'
          disabled={disabled}
          title={control.Tooltip}
          style={{ width: '100%' }}
          inputProps={{ style: { border: '1px solid lightgrey', borderRadius: 4, padding: 5 } }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      ) : (
        <DatePicker
          value={value}
          onChange={_onChange}
          format='MM/dd/yyyy'
          disabled={disabled}
          title={control.Tooltip}
          style={{ width: '100%' }}
          inputProps={{ style: { border: '1px solid lightgrey', borderRadius: 4, padding: 5 } }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      )}
    </MuiPickersUtilsProvider>
  )
}

DateTime.propTypes = {
  disabled: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default DateTime
