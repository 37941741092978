import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = []

const tabs = handleActions(
  {
    [actions.getContentMetadataTabs.success](state, { response }) {
      return [...state, ...response.data].sort((a, b) => new Date(a.CreatedOn) - new Date(b.CreatedOn)) // CreatedOn is an ISO date string
    },
    [actions.renameContentMetadata.success](state, action) {
      return state.map((tab) => (tab.Id === action.payload.Id ? { ...tab, Name: action.payload.Name } : tab))
    },
    [actions.updateSheet.success](state, action) {
      return state.map((tab) =>
        tab.Id === action.payload.sheetId ? { ...tab, ...action.payload.updateSheetModel } : tab
      )
    },
    [actions.resetContentMetadataTabNames](state, action) {
      return defaultState
    },
  },
  defaultState
)

export default tabs
