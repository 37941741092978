import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Backdrop, CircularProgress } from '@mui/material'

import oidcUserManager from '@tabeeb/services/oidcUserManager'
import domainService from '@tabeeb/services/domainService'

async function signInRequest(returnUrl) {
  await oidcUserManager.signinRedirect({
    state: returnUrl,
    extraQueryParams: { subdomain: domainService.subdomain },
  })
}

const IdentityAuthenticate = ({ children }) => {
  const [shouldRenderApp, setShouldRenderApp] = useState(false)
  const location = useLocation()

  const targetUrlPath = location.pathname + location.search

  useEffect(() => {
    if (!shouldRenderApp) {
      ;(async () => {
        const user = await oidcUserManager.getUser()
        if (user && !user.expired && Boolean(user.access_token)) {
          const session = await oidcUserManager.querySessionStatus()
          if (session.sub === user.profile.sub) {
            setShouldRenderApp(true)
          } else {
            await signInRequest(targetUrlPath)
          }
        } else {
          await signInRequest(targetUrlPath)
        }
      })()
    }
  }, [shouldRenderApp, targetUrlPath])

  return (
    <>
      <Backdrop sx={{ zIndex: 9999, backgroundColor: 'inherit' }} open={!shouldRenderApp} transitionDuration={0}>
        <CircularProgress color='info' size={40} thickness={6} />
      </Backdrop>
      {shouldRenderApp && children}
    </>
  )
}

IdentityAuthenticate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IdentityAuthenticate
