import { memo } from 'react'
import PropTypes from 'prop-types'

import { ControlType } from '@tabeeb/enums'
import RadioButtons from '../RadioButtons'
import DropDownList from '../DropDownList'
import CheckBox from '../CheckBox'
import TextArea from '../TextArea'
import TextBox from '../TextBox'
import DateTime from '../DateTime'

const FormControlAnswers = (props) => {
  const _renderControlAnswers = () => {
    const { control } = props

    switch (control.ControlType) {
      case ControlType.Textbox:
        return <TextBox {...props} />
      case ControlType.Textarea:
        return <TextArea {...props} />
      case ControlType.CheckboxList:
        return <CheckBox {...props} />
      case ControlType.Radiobuttons:
        return <RadioButtons {...props} />
      case ControlType.Dropdown:
        return <DropDownList {...props} />
      case ControlType.DateTime:
      case ControlType.Date:
        return <DateTime {...props} />
    }
  }

  return <div>{_renderControlAnswers()}</div>
}

FormControlAnswers.propTypes = {
  control: PropTypes.shape({
    ControlType: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(FormControlAnswers)
