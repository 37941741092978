import PropTypes from 'prop-types'

import InlineEditContainer from '../../../shared/inlineEdit'
import SwitchSession from '../SwitchSession'

import './styles.less'

const Navigation = ({
  handleKeyUpName,
  name,
  handleRenameSession,
  validateMouseDown,
  validateBlur,
  folderName,
  isContentLoading,
  handleSessionClick,
  handleRequestClose,
  handleTextChange,
  handleFolderClick,
  handleSessionsClick,
  open,
  anchorEl,
  sessions,
  contentId,
  sessionsNavbarTitle,
}) => {
  return (
    <div className='top-nav-bar-navigation'>
      <label className='top-nav-bar-sessions' onClick={handleSessionsClick}>
        {sessionsNavbarTitle}
      </label>
      <i className='arrow' />
      {folderName && (
        <>
          <span className='folder-name' title={folderName} onClick={handleFolderClick}>
            {folderName}
          </span>
          <i className='arrow' />
        </>
      )}

      <InlineEditContainer
        value={name}
        title={name}
        maxLength='200'
        handleKeyDown={handleKeyUpName}
        handleBlurInput={handleRenameSession}
        validateMouseDown={validateMouseDown}
        validateBlur={validateBlur}
        classNameInput='session-name-input'
        classNameSpan='session-name'
      />

      <SwitchSession
        open={open}
        anchorEl={anchorEl}
        sessions={sessions}
        handleRequestClose={handleRequestClose}
        handleTextChange={handleTextChange}
        handleSessionClick={handleSessionClick}
        isContentLoading={isContentLoading}
        contentId={contentId}
        sessionsNavbarTitle={sessionsNavbarTitle}
      />
    </div>
  )
}

Navigation.propTypes = {
  name: PropTypes.string.isRequired,
  handleKeyUpName: PropTypes.func.isRequired,
  handleRenameSession: PropTypes.func.isRequired,
  validateMouseDown: PropTypes.func.isRequired,
  validateBlur: PropTypes.func.isRequired,
  folderName: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleRequestClose: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleFolderClick: PropTypes.func.isRequired,
  handleSessionsClick: PropTypes.func.isRequired,
  handleSessionClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  sessions: PropTypes.array.isRequired,
  isContentLoading: PropTypes.bool.isRequired,
  contentId: PropTypes.number.isRequired,
  sessionsNavbarTitle: PropTypes.string.isRequired,
}

export default Navigation
