import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.reset](state, action) {
      return defaultState
    },
    [rawActions.acceptPoliciesRequest](state, action) {
      return true
    },
    [combineActions(rawActions.acceptPoliciesFailed, rawActions.acceptPoliciesSuccess)](state, action) {
      return defaultState
    },
  },
  defaultState
)
