import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VideocamOffOutlined, VideocamOutlined } from '@material-ui/icons'

import { accountSelectors } from '@tabeeb/modules/account'
import { usersSelectors } from '@tabeeb/modules/../users'
import { presentationSelectors } from '@tabeeb/modules/presentation'

import { getSelectedUserId } from '@tabeeb/modules/recording/selectors'
import { isLocalRecordingActive } from '@tabeeb/modules/recording/services/localRecordingService'
import { getIsCurrentUserPresenter } from '@tabeeb/shared/content/selectors'

import { openMuteCameraDialog } from '@tabeeb/modules/whiteboard/actions'
import ToolbarButton from '../ToolbarButton'
import * as muteVideoActions from '../../../presentationToolbar/actions/muteVideo'

const MuteVideoButton = () => {
  const dispatch = useDispatch()
  const isPresenter = useSelector(getIsCurrentUserPresenter)

  const currentUserId = useSelector(accountSelectors.getCurrentUserId)

  const selectedUser = useSelector(presentationSelectors.getSelectedUser)
  const user = useSelector((state) =>
    usersSelectors.getUserById(state, { Id: isPresenter ? selectedUser?.id : currentUserId })
  )
  const selectedUserId = useSelector(getSelectedUserId)

  const isVideoMuted = useSelector(
    (state) =>
      !presentationSelectors.getIsUserVideoPlaying(state, { Id: isPresenter ? selectedUser?.id : currentUserId })
  )
  const muteVideoInProgress = useSelector((state) => state.presentationToolbar.muteVideoInProgress)

  if (!selectedUser || !user) {
    return null
  }

  const onClick = () => {
    if (muteVideoInProgress === true || selectedUser === null) {
      return
    }

    if (user.id === selectedUserId && !isVideoMuted && isLocalRecordingActive()) {
      dispatch(openMuteCameraDialog())
      return
    }

    if (selectedUser.id === currentUserId || !isPresenter) {
      dispatch(muteVideoActions.muteVideo())
    } else {
      dispatch(muteVideoActions.muteRemoteVideo(selectedUser.id))
    }
  }

  return (
    <ToolbarButton
      Icon={isVideoMuted ? VideocamOffOutlined : VideocamOutlined}
      title={isVideoMuted ? 'Unmute video' : 'Mute video'}
      onClick={onClick}
    />
  )
}

export default memo(MuteVideoButton)
