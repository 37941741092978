import { handleActions } from 'redux-actions'

import { showCallPreview, hideCallPreview } from '../actions'

const defaultValue = false

export default handleActions(
  {
    [showCallPreview]() {
      return true
    },
    [hideCallPreview]() {
      return defaultValue
    },
  },
  defaultValue
)
