export default (theme) => {
  return {
    container: {
      display: 'flex',
      margin: '0 10px 6px',
      padding: '5px 10px',
      justifyContent: 'space-between',
      border: '1px solid #e4e4e4',
    },
    pending: {
      fontWeight: 'bold',
      color: '#00BCD4',
    },
    approve: {
      fontWeight: 'bold',
      color: '#4CAF50',
    },
    reject: {
      fontWeight: 'bold',
      color: '#F44336',
    },
  }
}
