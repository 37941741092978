import { handleActions } from 'redux-actions'

import * as actions from '../../actions'

const defaultState = false

export default handleActions(
  {
    [actions.getContentUsersSessionFolderPermissions.request]() {
      return true
    },
    [actions.setContentUsersPageFolderPermissions]() {
      return defaultState
    },
    [actions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
