import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { AddOutlined } from '@mui/icons-material'
import { Box, Button, Divider, Fade, List, TextField, Typography } from '@mui/material'

import { SessionPermission, TenantPermission } from '@tabeeb/enums'
import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { useDebouncedValue, useDialogState } from '@tabeeb/shared/utils/hooks'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import { hasSessionPermission, hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

import { deleteContentTrainingMaterial } from '../../actions'
import { useContentTrainingMaterials } from '../../hooks'

import ContentTrainingMaterialDialog from '../ContentTrainingMaterialDialog'
import ContentTrainingMaterialLinkButton from '../ContentTrainingMaterialLinkButton'
import ContentTrainingMaterialListItem from '../ContentTrainingMaterialListItem'
import ContentTrainingMaterialListItemSkeleton from '../ContentTrainingMaterialListItemSkeleton'
import ContentTrainingMaterialMenu from '../ContentTrainingMaterialMenu'
import ContentTrainingMaterialUsersDialog from '../ContentTrainingMaterialUsersDialog'

import TrainingMaterialsAttachDialog from '../TrainingMaterialsAttachDialog'
import NoTrainingMaterialsAttachedPlaceholder from '../NoTrainingMaterialsAttachedPlaceholder'

import Repeater from '../Repeater'

const ContentTrainingMaterialsTab = ({ contentId }) => {
  const dispatch = useDispatch()

  const [selectedTrainingMaterial, setSelectedTrainingMaterial] = useState(null)
  const [previewDialogOpen, onOpenPreviewDialog, onClosePreviewDialog] = useDialogState()
  const [attachDialogOpen, onOpenAttachDialog, onCloseAttachDialog] = useDialogState()
  const [manageUsersDialogOpen, onOpenManageUsersDialog, onCloseManageUsersDialog] = useDialogState()

  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebouncedValue(searchText, 500)

  const { loading, loaded, contentTrainingMaterials, onReload } = useContentTrainingMaterials({
    contentId,
    search: debouncedSearchText,
  })

  const hasManagePermission = useSelector(
    (state) =>
      hasSessionPermission(state, SessionPermission.ManageTrainingMaterials) ||
      hasTenantPermission(state, TenantPermission.ManageTrainingMaterials)
  )

  const onDetach = async (contentTrainingMaterial) => {
    try {
      await callApiAsync(
        deleteContentTrainingMaterial.request({
          id: contentTrainingMaterial.Id,
        })
      )
      onReload()
    } catch (e) {
      dispatch(
        onAddErrorNotification({ message: trainingMaterialTitleFormatter.format('Failed to detach training material') })
      )
    }
  }

  const onOpenContentTrainingMaterial = useCallback(
    (contentTrainingMaterial) => {
      setSelectedTrainingMaterial(contentTrainingMaterial)
      onOpenPreviewDialog()
    },
    [onOpenPreviewDialog]
  )

  const onOpenContentTrainingMaterialUsersManagement = useCallback(
    (contentTrainingMaterial) => {
      setSelectedTrainingMaterial(contentTrainingMaterial)
      onOpenManageUsersDialog()
    },
    [onOpenManageUsersDialog]
  )

  const assigned = contentTrainingMaterials.filter((item) => item.Assigned)
  const notAssigned = contentTrainingMaterials.filter((item) => !item.Assigned)

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      height='100%'
      bgcolor={(theme) => theme.palette.background.paper}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center' minWidth={0} p={1} pt={2} pr={2}>
        <Typography pl={2} variant='h6' fontWeight={600} noWrap>
          {trainingMaterialTitleFormatter.format('Training materials')}
        </Typography>

        <Fade in={hasManagePermission}>
          <Button variant='contained' color='primary' startIcon={<AddOutlined />} onClick={onOpenAttachDialog}>
            Attach
          </Button>
        </Fade>
      </Box>

      <Box pl={2} pr={2} pb={1}>
        <TextField
          value={searchText}
          fullWidth
          placeholder='Search...'
          size='small'
          variant='outlined'
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Box>

      <Box display='flex' flexDirection='column' pl={1} pr={1} flexGrow={1} overflow='auto'>
        <List>
          {!loaded && contentTrainingMaterials.length === 0 && (
            <Repeater count={8}>
              <ContentTrainingMaterialListItemSkeleton />
            </Repeater>
          )}

          {assigned.map((contentTrainingMaterial) => (
            <ContentTrainingMaterialListItem
              key={contentTrainingMaterial.Id}
              disabled={loading}
              item={contentTrainingMaterial}
              onDoubleClick={() => onOpenContentTrainingMaterial(contentTrainingMaterial)}
            >
              <ContentTrainingMaterialLinkButton id={contentTrainingMaterial.Id} disabled={loading} />

              <ContentTrainingMaterialMenu
                contentTrainingMaterial={contentTrainingMaterial}
                onView={onOpenContentTrainingMaterial}
                onManageUsers={hasManagePermission ? onOpenContentTrainingMaterialUsersManagement : null}
                onDetach={hasManagePermission ? onDetach : null}
              />
            </ContentTrainingMaterialListItem>
          ))}

          {notAssigned.length > 0 && (
            <Divider sx={{ color: 'text.secondary' }} variant='middle'>
              Not assigned
            </Divider>
          )}

          {notAssigned.map((contentTrainingMaterial) => (
            <ContentTrainingMaterialListItem
              key={contentTrainingMaterial.Id}
              item={contentTrainingMaterial}
              onDoubleClick={() => onOpenContentTrainingMaterial(contentTrainingMaterial)}
            >
              <ContentTrainingMaterialLinkButton id={contentTrainingMaterial.Id} disabled={loading} />

              <ContentTrainingMaterialMenu
                contentTrainingMaterial={contentTrainingMaterial}
                onView={onOpenContentTrainingMaterial}
                onManageUsers={hasManagePermission ? onOpenContentTrainingMaterialUsersManagement : null}
                onDetach={hasManagePermission ? onDetach : null}
              />
            </ContentTrainingMaterialListItem>
          ))}
        </List>

        {loaded && contentTrainingMaterials.length === 0 && (
          <NoTrainingMaterialsAttachedPlaceholder onAttach={hasManagePermission ? onOpenAttachDialog : null} />
        )}
      </Box>

      <TrainingMaterialsAttachDialog
        open={attachDialogOpen}
        contentId={contentId}
        onClose={onCloseAttachDialog}
        onUpdated={onReload}
      />

      <ContentTrainingMaterialDialog
        open={previewDialogOpen}
        contentTrainingMaterial={selectedTrainingMaterial}
        onClose={onClosePreviewDialog}
        onUpdated={onReload}
      />

      <ContentTrainingMaterialUsersDialog
        open={manageUsersDialogOpen}
        id={selectedTrainingMaterial?.Id}
        onClose={onCloseManageUsersDialog}
        onUpdated={onReload}
      />
    </Box>
  )
}

ContentTrainingMaterialsTab.propTypes = {
  contentId: PropTypes.number.isRequired,
}

export default ContentTrainingMaterialsTab
