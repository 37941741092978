import { createAction } from 'redux-actions'

export const setDeletedPages = createAction('SET_DELETED_PAGES')
export const addDeletedPages = createAction('ADD_DELETED_PAGES')
export const filterDeletedPages = createAction('FILTER_DELETED_PAGES')

export const addDeletedSessionFolder = createAction('ADD_DELETED_SESSION_FOLDERS')
export const filterDeletedFolders = createAction('FILTER_DELETED_FOLDERS')

export const setContentTrashBinDialogItem = createAction('SET_CONTENT_TRASH_BIN_DIALOG_ITEM')
export const resetContentTrashBinDialogItem = createAction('RESET_CONTENT_TRASH_BIN_DIALOG_ITEM')

export const setOperatingFolderId = createAction('SET_OPERATING_FOLDER_ID')
export const resetOperatingFolderId = createAction('RESET_OPERATING_FOLDER_ID')

export const setOperatingPageId = createAction('SET_OPERATING_PAGE_ID')
export const resetOperatingPageId = createAction('RESET_OPERATING_PAGE_ID')

export const selectContentTrashBinFolder = createAction('SELECT_CONTENT_TRASH_BIN_FOLDER')
export const deselectContentTrashBinFolder = createAction('DESELECT_CONTENT_TRASH_BIN_FOLDER')
export const deselectContentTrashBinFolderIfNotExists = createAction('DESELECT_CONTENT_TRASH_BIN_FOLDER_IF_NOT_EXISTS')

export const selectContentTrashBinPage = createAction('SELECT_CONTENT_TRASH_BIN_PAGE')
export const deselectContentTrashBinPage = createAction('DESELECT_CONTENT_TRASH_BIN_PAGE')
export const clearContentTrashBinSelectedPages = createAction('CLEAR_CONTENT_TRASH_BIN_SELECTED_PAGES')

export const submitRestorePage = createAction('SUBMIT_RESTORE_PAGE')
export const submitRestoreSelectedPages = createAction('SUBMIT_RESTORE_SELECTED_PAGES')
export const submitRestoreFolder = createAction('SUBMIT_RESTORE_FOLDER')
export const submitRestoreAllPages = createAction('SUBMIT_RESTORE_ALL_PAGES')

export const submitDeletePage = createAction('SUBMIT_DELETE_PAGE')
export const submitDeleteSelectedPages = createAction('SUBMIT_DELETE_SELECTED_PAGES')
export const submitDeleteFolder = createAction('SUBMIT_DELETE_FOLDER')
export const submitDeleteAllPages = createAction('SUBMIT_DELETE_ALL_PAGES')

export const getDeletedPagesRequest = createAction('GET_DELETED_PAGES_REQUEST')
export const getDeletedPagesSuccess = createAction('GET_DELETED_PAGES_SUCCESS')
export const getDeletedPagesFailed = createAction('GET_DELETED_PAGES_FAILED')

export const getDeletedSessionFoldersRequest = createAction('GET_DELETED_SESSION_FOLDERS_REQUEST')
export const getDeletedSessionFoldersSuccess = createAction('GET_DELETED_SESSION_FOLDERS_SUCCESS')
export const getDeletedSessionFoldersFailed = createAction('GET_DELETED_SESSION_FOLDERS_FAILED')

export const restorePageRequest = createAction('RESTORE_PAGE_REQUEST')
export const restorePageSuccess = createAction('RESTORE_PAGE_SUCCESS')
export const restorePageFailed = createAction('RESTORE_PAGE_FAILED')

export const restoreSelectedPagesRequest = createAction('RESTORE_SELECTED_PAGES_REQUEST')
export const restoreSelectedPagesSuccess = createAction('RESTORE_SELECTED_PAGES_SUCCESS')
export const restoreSelectedPagesFailed = createAction('RESTORE_SELECTED_PAGES_FAILED')

export const restoreContentTrashBinFolderRequest = createAction('RESTORE_CONTENT_TRASH_BIN_FOLDER_REQUEST')
export const restoreContentTrashBinFolderSuccess = createAction('RESTORE_CONTENT_TRASH_BIN_FOLDER_SUCCESS')
export const restoreContentTrashBinFolderFailed = createAction('RESTORE_CONTENT_TRASH_BIN_FOLDER_FAILED')

export const restoreContentTrashBinRequest = createAction('RESTORE_CONTENT_TRASH_BIN_REQUEST')
export const restoreContentTrashBinSuccess = createAction('RESTORE_CONTENT_TRASH_BIN_SUCCESS')
export const restoreContentTrashBinFailed = createAction('RESTORE_CONTENT_TRASH_BIN_FAILED')

export const deletePagePermanentlyRequest = createAction('DELETE_PAGE_PERMANENTLY_REQUEST')
export const deletePagePermanentlySuccess = createAction('DELETE_PAGE_PERMANENTLY_SUCCESS')
export const deletePagePermanentlyFailed = createAction('DELETE_PAGE_PERMANENTLY_FAILED')

export const deleteSelectedPagesPermanentlyRequest = createAction('DELETE_SELECTED_PAGES_PERMANENTLY_REQUEST')
export const deleteSelectedPagesPermanentlySuccess = createAction('DELETE_SELECTED_PAGES_PERMANENTLY_SUCCESS')
export const deleteSelectedPagesPermanentlyFailed = createAction('DELETE_SELECTED_PAGES_PERMANENTLY_FAILED')

export const deleteContentTrashBinFolderRequest = createAction('DELETE_CONTENT_TRASH_BIN_FOLDER_REQUEST')
export const deleteContentTrashBinFolderSuccess = createAction('DELETE_CONTENT_TRASH_BIN_FOLDER_SUCCESS')
export const deleteContentTrashBinFolderFailed = createAction('DELETE_CONTENT_TRASH_BIN_FOLDER_FAILED')

export const emptyContentTrashBinRequest = createAction('EMPTY_CONTENT_TRASH_BIN_REQUEST')
export const emptyContentTrashBinSuccess = createAction('EMPTY_CONTENT_TRASH_BIN_SUCCESS')
export const emptyContentTrashBinFailed = createAction('EMPTY_CONTENT_TRASH_BIN_FAILED')
