import PropTypes from 'prop-types'

import { TableCell, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core'

import { useStyles } from './styles'

const TableItem = ({ formCell, title, isSelected, handleSelect }) => {
  const classes = useStyles()

  return (
    <TableCell className={formCell ? classes.form : ''}>
      <Tooltip title={title || ''}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSelected || false}
              value={isSelected || false}
              color='primary'
              onChange={handleSelect}
            />
          }
          label={title}
        />
      </Tooltip>
    </TableCell>
  )
}

TableItem.propTypes = {
  formCell: PropTypes.bool,
  title: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
}

export default TableItem
