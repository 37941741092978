import { put, takeLatest, select, all } from 'redux-saga/effects'

import { getThumbnailFromPage } from '../../../services/dataConverter'
import * as constantsModule from '../../../state/constantsModule'
import selector from '../../shared/utils/selector'

import * as rawActions from '../actions'

function* onGetAssetsByContentIdSuccess({ payload }) {
  const pages = yield select((state) => state.gallery.galleryList)
  const assets = yield select((state) => state.gallery.assetsList.assets)

  const mappedPages = pages.map((page) => {
    const pageAssets = assets.filter((asset) => asset.PageId === page.id)
    const apiUrl = constantsModule.getApiUrl()
    page.assets = pageAssets
    page.thumbnailUrl = getThumbnailFromPage(page, apiUrl)
    return page
  })
  yield put(rawActions.setGalleryItems(mappedPages))
}

function* onAddGalleryItems({ payload }) {
  const contentState = yield select(selector.getContent)

  yield put(rawActions.getAssetsByContentIdRequest({ contentId: contentState.contentId }))
}

function* assets() {
  yield all([
    takeLatest(rawActions.getAssetsByContentIdSuccess, onGetAssetsByContentIdSuccess),
    takeLatest(rawActions.addGalleryItems, onAddGalleryItems),
  ])
}

export default assets
