import { handleActions, combineActions } from 'redux-actions'

import { TabeebColors } from '@tabeeb/enums'
import { onSelectDrawingAIObject, setSelectedAiObject, resetState } from '../actions'

const defaultState = {
  Id: 0,
  Name: '',
  Color: TabeebColors.Red,
}

export default handleActions(
  {
    [combineActions(onSelectDrawingAIObject, setSelectedAiObject)](state, { payload }) {
      return payload
    },
    [resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
