export function getTrack(state, userId, currentUserId, trackType) {
  let track = null
  if (userId === currentUserId) {
    track = state.localTracks.find((item) => item.getType() === trackType)
  } else {
    const tracks = state.remoteTracks[userId]
    track = tracks !== undefined ? tracks.find((item) => item.getType() === trackType) : null
  }
  return track
}

export function getAudioContainerById(userId) {
  const result = document.querySelectorAll(`.tabeebUserAudio[data-id="${userId}"]`)
  return result.length !== 0 ? result[0] : null
}

export function getCallPreviewAudioContainerById(userId) {
  const result = document.querySelectorAll(`.tabeebPreviewUserAudio[data-id="${userId}"]`)
  return result.length !== 0 ? result[0] : null
}

export function getVideoContainerById(userId) {
  const result = document.querySelectorAll(`.tabeebUserVideo[data-id="${userId}"]`)
  return result.length !== 0 ? result[0] : null
}

export function getGridVideoContainerById(userId) {
  const result = document.querySelectorAll(`.tabeebGridUserVideo[data-id="${userId}"]`)
  return result.length !== 0 ? result[0] : null
}

export function getCallPreviewVideoContainerById(userId) {
  const result = document.querySelectorAll(`.tabeebPreviewUserVideo[data-id="${userId}"]`)
  return result.length !== 0 ? result[0] : null
}

export function getLargeVideoContainer() {
  const result = document.querySelectorAll('.largeVideo')
  return result.length !== 0 ? result[0] : null
}

export function getPreviewVideoContainerById(deviceId) {
  const result = document.querySelectorAll(`.tabeebUserVideo[data-id="${deviceId}"]`)
  return result.length !== 0 ? result[0] : null
}

export function isUnavailableForLargeVideo(largeVideoUser, isVideo, userId) {
  return (
    (largeVideoUser && !isVideo && largeVideoUser.id !== userId) || // if another user in large screen but current has no video
    (!largeVideoUser && !isVideo) // if current user has no video and large video is empty
  )
}

export function replaceTrack(jitsiRoom, localVideo, track) {
  return jitsiRoom.replaceTrack(localVideo, track)
}
