export const menuItemStyles = {
  formControl: {
    minWidth: 190,
    width: '100%',
    fontSize: 20,
  },
  selectedMenuItem: {
    fontWeight: 'bold',
    fontSize: 17,
    backgroundColor: 'lightgray',
  },
  menuItem: {
    fontSize: 17,
  },
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
