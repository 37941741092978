import { takeLatest, all } from 'redux-saga/effects'

import { whiteboardService } from '@tabeeb/modules/whiteboard'

import getAnnotationSagas from './getAnnotation'
import addAnnotation from './addAnnotation'
import deleteAnnotation from './deleteAnnotation'
import restoreAnnotation from './restoreAnnotation'
import updateAnnotation from './updateAnnotation'
import signalr from './signalr'
import undoRedo from './undoRedo'

import * as annotationsActions from '../actions'

function* annotationsSaga() {
  yield all([
    takeLatest(
      [annotationsActions.openHotspotDialog, annotationsActions.closeHotspotDialog],
      whiteboardService.updatePresenterState
    ),
    getAnnotationSagas(),
    addAnnotation(),
    deleteAnnotation(),
    restoreAnnotation(),
    updateAnnotation(),
    signalr(),
    undoRedo(),
  ])
}

export default annotationsSaga
