export default (theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'flex-end',

      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
      },

      '& > div:first-child': {
        '& .MuiOutlinedInput-root': {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        },
      },

      '& > div:last-child': {
        '& .MuiOutlinedInput-root': {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
      },
    },
  }
}
