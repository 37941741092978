import { useState, useCallback } from 'react'

export default (initialState = true) => {
  const [expanded, setExpanded] = useState(initialState)

  const onExpand = useCallback(() => {
    setExpanded(true)
  }, [])

  const onCollapse = useCallback(() => {
    setExpanded(false)
  }, [])

  return { expanded, onExpand, onCollapse }
}
