import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ZoomOut } from '@material-ui/icons'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { isSupportZooming } from '@tabeeb/services/pageService'
import { playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import * as zoomingActions from '../../../actions/zooming'
import { MinZoomValue } from '../../../common/zooming'
import PanelButton from '../PanelButton'

const ZoomOutButton = () => {
  const dispatch = useDispatch()

  const isAvailable = useSelector((state) => playerToolbarSelectors.getScale(state) > MinZoomValue)
  const display = useSelector((state) => isSupportZooming(gallerySelectors.getSelectedGalleryItemType(state)))

  const onZoomOut = useCallback(() => {
    if (isAvailable) {
      dispatch(zoomingActions.onZoomOut())
    }
  }, [dispatch, isAvailable])

  return <PanelButton display={display} disabled={!isAvailable} Icon={ZoomOut} onClick={onZoomOut} title='Zoom out' />
}

export default memo(ZoomOutButton)
