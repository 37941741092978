import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'

import { Grid, Button, withStyles, Typography, Avatar as AvatarIcon, Divider } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'

import { FormikTextField } from '@tabeeb/shared/forms'
import { getCurrentUserUpdateProfile } from '../../selectors'
import { updateAccountRequest } from '../../actions'

import styles from './styles'

export const addressSchema = Yup.object().shape({
  Address: Yup.object().shape({
    Line1: Yup.string().max(120, 'Maximum length is 120 characters').nullable(),
    Line2: Yup.string().max(120, 'Maximum length is 120 characters').nullable(),
    City: Yup.string().max(40, 'Maximum length is 40 characters').nullable(),
    PostalCode: Yup.string().max(11, 'Maximum length is 11 characters').nullable(),
    State: Yup.string().max(50, 'Maximum length is 50 characters').nullable(),
    Country: Yup.string().max(50, 'Maximum length is 50 characters').nullable(),
  }),
})

const Address = ({ classes }) => {
  const dispatch = useDispatch()
  const userSettingsFormData = useSelector(getCurrentUserUpdateProfile)

  const onSaveUserSettings = useCallback(
    (data) => {
      const userSetting = {
        ...data,
        FirstName: data.FirstName.trim(),
        LastName: data.LastName.trim(),
      }
      dispatch(updateAccountRequest(userSetting))
    },
    [dispatch]
  )

  return (
    <Formik
      initialValues={userSettingsFormData}
      enableReinitialize
      validationSchema={addressSchema}
      onSubmit={onSaveUserSettings}
    >
      {({ dirty, isValid }) => (
        <Form className={classes.form} autoComplete='off'>
          <Grid item xs={12}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <div className={classes.header}>
                  <AvatarIcon className={classes.avatar}>
                    <HomeIcon className={classes.icon} />
                  </AvatarIcon>
                  <Typography variant='body1'>Address</Typography>
                </div>
                <Divider className={classes.divider} />
                <Grid container wrap='nowrap' direction='column' spacing={1}>
                  <Grid item>
                    <Grid container wrap='nowrap' direction='row' spacing={1}>
                      <Grid item xs={4}>
                        <Field name='Address.Line1' color='primary' label='Address 1' component={FormikTextField} />
                      </Grid>
                      <Grid item xs={4}>
                        <Field name='Address.Line2' color='primary' label='Address 2' component={FormikTextField} />
                      </Grid>
                      <Grid item xs={4}>
                        <Field
                          name='Address.PostalCode'
                          color='primary'
                          label='Zip/Postal Code'
                          component={FormikTextField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Field name='Address.City' color='primary' label='City' component={FormikTextField} />
                  </Grid>
                  <Grid item>
                    <Field name='Address.State' color='primary' label='State/Province' component={FormikTextField} />
                  </Grid>
                  <Grid item>
                    <Field name='Address.Country' color='primary' label='Country' component={FormikTextField} />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={!(isValid && dirty)}
                      size='large'
                      fullWidth
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

Address.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Address)
