import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, DialogTitle, DialogContent, DialogActions, Dialog, Tooltip } from '@material-ui/core'

import { ReminderCustomRepeatMode } from '@tabeeb/enums'
import CustomRepeatInterval from '../CustomRepeatInterval'
import CustomRepeatDaysOfWeek from '../CustomRepeatDaysOfWeek'
import CustomRepeatEnd from '../CustomRepeatEnd'
import { getCustomRepeatSettingsErrors } from '../../services/reminderErrors'

import './styles.less'

const CustomRepeatSettings = ({ onCloseDialog, isOpen, currentReminder, setReminderSettings }) => {
  const [settings, setSettings] = useState(currentReminder.CustomRepeatSettings)

  useEffect(() => {
    setSettings(currentReminder.CustomRepeatSettings)
  }, [currentReminder.CustomRepeatSettings])

  const _setCustomRepeatSettings = () => {
    setReminderSettings({
      ...currentReminder,
      CustomRepeatSettings: { ...settings, IsDefault: false },
    })

    onCloseDialog()
  }

  if (!settings) return null

  const errors = getCustomRepeatSettingsErrors(currentReminder, settings)
  const props = { settings, setSettings, errors }

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseDialog}
      disableBackdropClick
      disableEscapeKeyDown
      className='custom-repeat-dialog'
    >
      <DialogTitle>Custom recurrence</DialogTitle>
      <DialogContent className='custom-repeat-dialog-content'>
        <CustomRepeatInterval {...props} />
        {settings.Mode === ReminderCustomRepeatMode.Week && <CustomRepeatDaysOfWeek {...props} />}
        <CustomRepeatEnd {...props} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color='default' variant='outlined'>
          Cancel
        </Button>

        <Tooltip
          placement='top'
          arrow
          title={
            errors.length > 0 ? (
              <>
                {errors.map((error) => (
                  <div>{error}</div>
                ))}
              </>
            ) : (
              ''
            )
          }
        >
          <span>
            <Button onClick={_setCustomRepeatSettings} color='primary' variant='contained' disabled={errors.length > 0}>
              Done
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}

CustomRepeatSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  currentReminder: PropTypes.object.isRequired,
  setReminderSettings: PropTypes.func.isRequired,
}

export default CustomRepeatSettings
