import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ConfirmationDialog } from '@tabeeb/uikit'
import { getMe } from '@tabeeb/modules/account/selectors'
import { leaveFolder } from '@tabeeb/modules/sessionsPage/actions'

const LeaveFolderDialog = ({ open, folder, onClose }) => {
  const dispatch = useDispatch()

  const currentUser = useSelector(getMe)

  const onSubmit = useCallback(() => {
    dispatch(leaveFolder.request({ FolderId: folder.Id, UserId: currentUser.Id, isCurrentUser: true }))

    onClose()
  }, [currentUser?.Id, dispatch, folder?.Id, onClose])

  return (
    <ConfirmationDialog
      isOpen={open}
      title='Delete folder'
      dialogMessage='Are you sure you want to leave this folder?'
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      cancelButtonText='No'
      submitButtonText='Yes'
    />
  )
}

LeaveFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  folder: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(LeaveFolderDialog)
