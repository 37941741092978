import { handleActions } from 'redux-actions'
import _ from 'lodash'

import * as rawActions from '../actions'
import * as vqaPolicyActions from '../../vqaPolicy/actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.getVqaPoliciesForFormSuccess](state, { response }) {
      return [...response.data]
    },
    [vqaPolicyActions.addVqaPolicySuccess](state, { response }) {
      return _addOrUpdateVQAPolicy(state, response.data)
    },
  },
  defaultState
)

function _addOrUpdateVQAPolicy(state, vqaPolicy) {
  const index = state.findIndex((vqaP) => vqaP.Id === vqaPolicy.Id)
  let result
  if (index === -1) {
    result = [...state, vqaPolicy]
    result = _.sortBy(result, (vqaPolicy) => vqaPolicy.Name)
  } else {
    result = [...state]
    result[index] = vqaPolicy
  }
  return result
}
