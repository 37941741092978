import { all } from 'redux-saga/effects'

import flashSaga from './flashSaga'
import snapshotSaga from './snapshotSaga'
import muteAudioSaga from './muteAudioSaga'
import muteVideoSaga from './muteVideoSaga'

function* presentationToolbarSaga() {
  yield all([flashSaga(), snapshotSaga(), muteAudioSaga(), muteVideoSaga()])
}

export default presentationToolbarSaga
