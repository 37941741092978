import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as rawActions from '../actions'

import Timeline from '../../timeline/components/Timeline'

class StructureModelImagesListContainer extends Component {
  _handleClose = () => {
    const {
      actions: { closeStructureModelDialog },
    } = this.props

    closeStructureModelDialog()
  }

  _handleSubmit = () => {
    const {
      actions: { addStructureModel },
      isProcessing,
    } = this.props
    if (isProcessing) {
      return
    }

    addStructureModel()
  }

  _handleDeleteItem = (itemIndex) => {
    const {
      actions: { deleteImageFromStructureModel },
    } = this.props

    deleteImageFromStructureModel(itemIndex)
  }

  _handleUpdateList = (newList) => {
    const {
      actions: { updateImagesStructureModel },
    } = this.props

    updateImagesStructureModel(newList)
  }

  render() {
    const { isOpen, isProcessing, timeLineList, currentPanel } = this.props

    const actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        class: 'submit',
      },
    ]

    const props = {
      actions,
      timeLineList,
      handleDeleteItem: this._handleDeleteItem,
      handleUpdateList: this._handleUpdateList,

      isOpen,
      isSubmitted: isProcessing,
      isLoading: isProcessing,
      isSelected: currentPanel === TabPanel.StructureModel,
    }

    return <Timeline {...props} />
  }
}

StructureModelImagesListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  timeLineList: PropTypes.array.isRequired,
  currentPanel: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    closeStructureModelDialog: PropTypes.func.isRequired,
    addStructureModel: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.structureModel.isTimelineOpen,
    isProcessing: state.structureModel.isProcessing,
    timeLineList: state.structureModel.imagesList,
    currentPanel: state.navbar.currentPanel[TabPanelLocation.Bottom],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StructureModelImagesListContainer)
