import { handleActions } from 'redux-actions'

import {
  acceptNotificationRequest,
  acceptNotificationSuccess,
  acceptNotificationFailed,
} from '@tabeeb/modules/notificationsPage/actions'
import { addReminderNotification, removeReminderNotification, resetRemindersNotifications } from '../actions'

const defaultValue = {
  isLoading: false,
  list: [],
}

export default handleActions(
  {
    [addReminderNotification](state, { payload }) {
      return { ...state, list: [...state.list, payload] }
    },
    [removeReminderNotification](state, { payload }) {
      const notificationId = payload

      return { ...state, list: state.list.filter((n) => n.Id !== notificationId) }
    },
    [acceptNotificationRequest](state) {
      return { ...state, isLoading: true }
    },
    [acceptNotificationSuccess](state, { payload }) {
      const notificationId = payload.Id

      return { ...state, list: state.list.filter((n) => n.Id !== notificationId), isLoading: false }
    },
    [acceptNotificationFailed](state) {
      return { ...state, isLoading: false }
    },
    [resetRemindersNotifications]() {
      return defaultValue
    },
  },
  defaultValue
)
