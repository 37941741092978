import { handleActions } from 'redux-actions'

import * as conferenceActions from '../actions/conference'

const initialState = {
  room: null,
  roomError: '',
}

export default handleActions(
  {
    [conferenceActions.setJitsiRoom](state, action) {
      return { ...state, room: action.payload }
    },
    [conferenceActions.disposeConference](state, action) {
      return disposeConference(state)
    },
    [conferenceActions.openCallPreviewDialog](state, action) {
      return { ...state, isCallPreviewOpen: true }
    },
    [conferenceActions.closeCallPreviewDialog](state, action) {
      return { ...state, isCallPreviewOpen: false }
    },
  },
  initialState
)

const disposeConference = (state) => {
  state = {
    room: null,
    roomError: '',
  }
  return {
    ...state,
  }
}
