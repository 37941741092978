export const tabeebHubName = 'tabeeb'
export const spreadsheetHubName = 'spreadsheetHub'
export const healthDeviceHubName = 'healthDeviceHub'
export const autodeskHubName = 'autodeskHub'

export const routes = {
  [tabeebHubName]: process.env.TABEEB_API_URL,
  [healthDeviceHubName]: `${process.env.TABEEB_API_URL}health-device-service/`,
  [spreadsheetHubName]: `${process.env.TABEEB_API_URL}spreadsheet-service/api/`,
  [autodeskHubName]: `${process.env.TABEEB_API_URL}autodesk-service/`,
}
