import { createAction } from 'redux-actions'

import { createApiActions } from '@tabeeb/shared/utils/actions'

export const fetchCalendarsEvents = createAction('FETCH_CALENDARS_EVENTS')

export const getCalendarEventRequest = createAction('GET_CALENDAR_EVENT_REQUEST')
export const getCalendarEventSuccess = createAction('GET_CALENDAR_EVENT_SUCCESS')
export const getCalendarEventFailed = createAction('GET_CALENDAR_EVENT_FAILED')

export const createCalendarEventRequest = createAction('CREATE_CALENDAR_EVENT_REQUEST')
export const createCalendarEventSuccess = createAction('CREATE_CALENDAR_EVENT_SUCCESS')
export const createCalendarEventFailed = createAction('CREATE_CALENDAR_EVENT_FAILED')

export const getFreeAppointmentSlotsRequest = createAction('GET_FREE_APPOINTMENT_SLOTS_REQUEST')
export const getFreeAppointmentSlotsSuccess = createAction('GET_FREE_APPOINTMENT_SLOTS_SUCCESS')
export const getFreeAppointmentSlotsFailed = createAction('GET_FREE_APPOINTMENT_SLOTS_FAILED')

export const bookAppointmentSlotRequest = createAction('BOOK_APPOINTMENT_SLOT_REQUEST')
export const bookAppointmentSlotSuccess = createAction('BOOK_APPOINTMENT_SLOT_SUCCESS')
export const bookAppointmentSlotFailed = createAction('BOOK_APPOINTMENT_SLOT_FAILED')

export const createAppointmentSlotsRequest = createAction('CREATE_APPOINTMENT_SLOTS_REQUEST')
export const createAppointmentSlotsSuccess = createAction('CREATE_APPOINTMENT_SLOTS_SUCCESS')
export const createAppointmentSlotsFailed = createAction('CREATE_APPOINTMENT_SLOTS_FAILED')

export const deleteCalendarEventRequest = createAction('DELETE_CALENDAR_EVENT_REQUEST')
export const deleteCalendarEventSuccess = createAction('DELETE_CALENDAR_EVENT_SUCCESS')
export const deleteCalendarEventFailed = createAction('DELETE_CALENDAR_EVENT_FAILED')

export const getCalendarEventsByUserRequest = createAction('GET_CALENDAR_EVENTS_BY_USER_REQUEST')
export const getCalendarEventsByUserSuccess = createAction('GET_CALENDAR_EVENTS_BY_USER_SUCCESS')
export const getCalendarEventsByUserFailed = createAction('GET_CALENDAR_EVENTS_BY_USER_FAILED')

export const createSessionForCalendarEventRequest = createAction('CREATE_SESSION_FOR_CALENDAR_EVENT_REQUEST')
export const createSessionForCalendarEventSuccess = createAction('CREATE_SESSION_FOR_CALENDAR_EVENT_SUCCESS')
export const createSessionForCalendarEventFailed = createAction('CREATE_SESSION_FOR_CALENDAR_EVENT_FAILED')

export const acceptCalendarEventRequest = createAction('ACCEPT_CALENDAR_EVENT_REQUEST')
export const acceptCalendarEventSuccess = createAction('ACCEPT_CALENDAR_EVENT_SUCCESS')
export const acceptCalendarEventFailed = createAction('ACCEPT_CALENDAR_EVENT_FAILED')

export const declineCalendarEventRequest = createAction('DECLINE_CALENDAR_EVENT_REQUEST')
export const declineCalendarEventSuccess = createAction('DECLINE_CALENDAR_EVENT_SUCCESS')
export const declineCalendarEventFailed = createAction('DECLINE_CALENDAR_EVENT_FAILED')

export const updateCalendarEventTime = createApiActions('UPDATE_CALENDAR_EVENT_TIME')
export const updateCalendarEventOwner = createApiActions('UPDATE_CALENDAR_EVENT_OWNER')
export const updateCalendarEvent = createApiActions('UPDATE_CALENDAR_EVENT')
export const switchSessionOwner = createApiActions('SWITCH_SESSION_OWNER')
