import _ from 'lodash'

import { getUtilityPoleObjectCustomName, isUtilityPoleObject } from './utility'

export const getObjectNames = (objects, worldScale, worldRotation) => {
  const namesMap = new Map()

  for (const object of objects) {
    namesMap.set(object.uniqueAIObject.Id, object.uniqueAIObject.Name)
  }

  for (const object of _.orderBy(objects, (o) => o.level)) {
    if (!isUtilityPoleObject(object)) {
      continue
    }

    const name = getUtilityPoleObjectCustomName(object, objects, worldScale, worldRotation, namesMap)
    if (!name) {
      continue
    }

    namesMap.set(object.uniqueAIObject.Id, name)
  }

  return namesMap
}
