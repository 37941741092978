import { handleActions } from 'redux-actions'
import * as rawActions from '../../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.openEditFolderDialog](state, { payload }) {
      return payload
    },
    [rawActions.closeEditFolderDialog]() {
      return defaultState
    },
    [rawActions.resetGalleryState]() {
      return defaultState
    },
  },
  defaultState
)
