import { handleActions } from 'redux-actions'
import { getLastUpdateTime } from '../actions'

const defaultState = {
  time: '',
}

export default handleActions(
  {
    [getLastUpdateTime.success](state, action) {
      return { time: action.response.data }
    },
  },
  defaultState
)
