import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = {}

export default handleActions(
  {
    [rawActions.reset](state, action) {
      return defaultState
    },
    [rawActions.getLatestPoliciesWithAcceptanceStatusSuccess](state, action) {
      const policies = action.response.data

      return policies.reduce((accumulator, policy) => {
        accumulator[policy.PolicyType] = {
          ...policy,
        }

        return accumulator
      }, {})
    },
    [rawActions.acceptPoliciesSuccess](state, action) {
      return Object.values(state).reduce((accumulator, policy) => {
        accumulator[policy.PolicyType] = {
          ...policy,
          IsAccepted: true,
        }

        return accumulator
      }, {})
    },
    [combineActions(rawActions.createPolicySuccess, rawActions.getPolicySuccess, rawActions.getLatestPolicySuccess)](
      state,
      action
    ) {
      const model = action.response.data
      if (!model) {
        return state
      }

      return {
        ...state,
        [model.PolicyType]: {
          ...model,
        },
      }
    },
  },
  defaultState
)
