import { put, takeLatest, all, select } from 'redux-saga/effects'

import { getFormTitle, getFormTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import routes from '@tabeeb/routes'
import { push } from 'connected-react-router'
import * as rawActions from '../actions/index'
import * as notificationActions from '../../notification/actions'
import { dialogTypes } from '../services/enums'

import foldersList from './foldersList'

function* deleteForm(action) {
  const formTitle = yield select(getFormTitle)

  yield put(notificationActions.onAddSuccessNotification({ message: `${formTitle} deleted successfully` }))
}

function* copyFormSuccess() {
  const formTitle = yield select(getFormTitle)

  yield put(notificationActions.onAddSuccessNotification({ message: `${formTitle} has been copied to the Main List` }))
  const currentFolderId = yield select((state) => state.formsPage.currentFolder?.FolderId)
  if (!currentFolderId) {
    yield put(rawActions.getAllFormsRequest())
  }
}
function* copyFormFailed() {
  const formTitle = yield select(getFormTitleLowerCase)

  yield put(notificationActions.onAddErrorNotification({ message: `Failed to copy the ${formTitle}` }))
}

function* handleFormsPageDialogSubmit({ payload: { selectedMenuItem, form, folder } }) {
  switch (selectedMenuItem) {
    case dialogTypes.CopyForm:
      yield put(rawActions.copyFormRequest({ formId: form.FormId }))
      break
    case dialogTypes.DeleteForm:
      yield put(rawActions.deleteFormRequest(form.FormId))
      break
    case dialogTypes.DeleteFolder:
      yield put(rawActions.deleteFormFolderRequest(folder.FolderId))
      break
    case dialogTypes.EditForm:
      break
  }
}

function* openFormFolder(action) {
  const folder = action.payload

  yield put(rawActions.setCurrentFormFolder(folder))
  yield put(rawActions.setCachedFolder(folder))
  yield put(rawActions.clearFormsList())

  const folderId = folder.FolderId
  folderId ? yield put(rawActions.getFormsByFolderIdRequest(folderId)) : yield put(rawActions.getAllFormsRequest())
}

function* createFolder(action) {
  const folder = action.response.data
  yield put(rawActions.addFormFolder(folder))
}

function* deleteFolder(action) {
  const folderId = action.payload
  yield put(rawActions.deleteFormFolder(folderId))
}

function* goToUpdateFormPage(action) {
  yield put(push(`${routes.updateForm}?formId=${action.payload}`))
}

function* goToFormInfoPage(action) {
  yield put(push(`${routes.showForm}?formId=${action.payload}`))
}

function* goToCreateFormPage() {
  yield put(push(routes.createForm))
}

function* goToFormsPage() {
  yield put(push(routes.forms))
}

function* publishFormSuccess() {
  const formTitle = yield select(getFormTitleLowerCase)

  yield put(notificationActions.onAddSuccessNotification({ message: `The ${formTitle} is published successfully` }))
}

function* publishFormFailed() {
  const formTitle = yield select(getFormTitleLowerCase)

  yield put(notificationActions.onAddErrorNotification({ message: `Failed to publish ${formTitle}` }))
}

function* unpublishFormSuccess() {
  const formTitle = yield select(getFormTitleLowerCase)

  yield put(notificationActions.onAddSuccessNotification({ message: `The ${formTitle} is unpublished successfully` }))
}

function* unpublishFormFailed() {
  const formTitle = yield select(getFormTitleLowerCase)

  yield put(notificationActions.onAddErrorNotification({ message: `Failed to unpublish ${formTitle}` }))
}

function* publishFormFolderSuccess() {
  yield put(notificationActions.onAddSuccessNotification({ message: 'The folder is published successfully' }))
}

function* publishFormFolderFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to publish folder' }))
}

function* unpublishFormFolderSuccess() {
  yield put(notificationActions.onAddSuccessNotification({ message: 'The folder is unpublished successfully' }))
}

function* unpublishFormFolderFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to unpublish folder' }))
}

function* createFormFolderFailed({ response }) {
  const errorMessage = getErrorMessage(response?.data?.Error?.Code)
  yield put(notificationActions.onAddErrorNotification({ message: errorMessage }))
}

function getErrorMessage(errorCode) {
  switch (errorCode) {
    case 1001:
      return 'Folder name cannot be empty or exceed 400 characters.'
    default:
      return 'Failed to create a folder.'
  }
}

function* deleteFormFolderFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to delete the folder.' }))
}

function* switchFormFolderFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to move the folder.' }))
}

function* formsPageSaga() {
  yield all([
    takeLatest(rawActions.deleteFormSuccess, deleteForm),
    takeLatest(rawActions.copyFormSuccess, copyFormSuccess),
    takeLatest(rawActions.copyFormFailed, copyFormFailed),
    takeLatest(rawActions.handleFormsPageDialogSubmit, handleFormsPageDialogSubmit),
    takeLatest(rawActions.openFormFolder, openFormFolder),
    takeLatest(rawActions.createFormFolderSuccess, createFolder),
    takeLatest(rawActions.deleteFormFolderSuccess, deleteFolder),
    takeLatest(rawActions.goToUpdateFormPage, goToUpdateFormPage),
    takeLatest(rawActions.goToFormInfoPage, goToFormInfoPage),
    takeLatest(rawActions.goToCreateFormPage, goToCreateFormPage),
    takeLatest(rawActions.publishFormSuccess, publishFormSuccess),
    takeLatest(rawActions.publishFormFailed, publishFormFailed),
    takeLatest(rawActions.unpublishFormSuccess, unpublishFormSuccess),
    takeLatest(rawActions.unpublishFormFailed, unpublishFormFailed),
    takeLatest(rawActions.publishFormFolderSuccess, publishFormFolderSuccess),
    takeLatest(rawActions.publishFormFolderFailed, publishFormFolderFailed),
    takeLatest(rawActions.unpublishFormFolderSuccess, unpublishFormFolderSuccess),
    takeLatest(rawActions.unpublishFormFolderFailed, unpublishFormFolderFailed),
    takeLatest(rawActions.createFormFolderFailed, createFormFolderFailed),
    takeLatest(rawActions.deleteFormFolderFailed, deleteFormFolderFailed),
    takeLatest(rawActions.switchFormFolderFailed, switchFormFolderFailed),
    takeLatest(rawActions.getFormsByFolderIdSuccess, goToFormsPage),
    foldersList(),
  ])
}

export default formsPageSaga
