import PropTypes from 'prop-types'

import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from '@material-ui/core'
import RadioButtons from '../../../forms/components/RadioButtons'

const ExportGalleryOptionsDialog = ({
  isExportGalleryOptionsDialogOpen,
  closeDialog,
  next,
  setExportGalleryOption,
  selectedOption,
  exportOptions,
}) => {
  const props = {
    control: {
      Label: 'Export gallery options',
      Tooltip: 'Export gallery options',
      Options: exportOptions,
    },
    answer: { selectedOptionsIds: [selectedOption] },
    handleChange: setExportGalleryOption,
    disabled: false,
  }

  return (
    <Dialog modal='false' open={isExportGalleryOptionsDialogOpen} onClose={closeDialog}>
      <DialogTitle>Select data you would like to export</DialogTitle>
      <DialogContent
        style={{
          width: '450px',
        }}
      >
        <RadioButtons {...props} />
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={next}>
          Continue
        </Button>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

ExportGalleryOptionsDialog.propTypes = {
  isExportGalleryOptionsDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  setExportGalleryOption: PropTypes.func.isRequired,
  exportOptions: PropTypes.array.isRequired,
  selectedOption: PropTypes.number.isRequired,
}

export default ExportGalleryOptionsDialog
