import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    picker: {
      maxWidth: 172,

      '& div[title="#FFFFFF"]': {
        border: '1px solid #999',
      },
    },
  }
})
