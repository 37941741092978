import { createSelector } from 'reselect'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'

export const getIsCustomVideoTimelineOpen = (state) => state.customVideo.isTimelineOpen
export const getIsCustomVideoRecipientsDialogOpen = (state) => state.customVideo.isRecipientsDialogOpen
export const getIsProcessing = (state) => state.customVideo.isProcessing
export const getNavbarCurrentBottomPanel = (state) => state.navbar.currentPanel[TabPanelLocation.Bottom]
export const getTimelineList = (state) => state.customVideo.timelineList

export const getIsCustomVideoOpen = createSelector(
  [getIsCustomVideoTimelineOpen, getNavbarCurrentBottomPanel],
  (isTimelineOpen, currentPanel) => {
    return isTimelineOpen && currentPanel === TabPanel.CustomVideo
  }
)
