const styles = (theme) => {
  return {
    button: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      height: '55px',

      '&:hover': {
        backgroundColor: theme.palette.accent.pressed,
      },
    },

    addButton: {
      flex: 1,
      height: 'auto',
    },

    userTextField: {
      flex: 3,
    },

    userAutoComplete: {
      display: 'flex',
      gap: 10,
    },

    userList: {
      overflow: 'auto',
      flex: 1,
    },
  }
}

export default styles
