import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.getNlpModelsForFormSuccess](state, { response }) {
      return [...response.data]
    },
  },
  defaultState
)
