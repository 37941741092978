import { all } from 'redux-saga/effects'

import createProject from './createProject'
import deleteProject from './deleteProject'
import deployment from './deployment'
import getIteration from './getIteration'
import getMetrics from './getMetrics'
import getProject from './getProject'
import iterationPages from './iterationPages'
import selectIteration from './selectIteration'
import training from './training'
import updateProject from './updateProject'

function* artificialIntelligence() {
  yield all([
    createProject(),
    deleteProject(),
    deployment(),
    getIteration(),
    getMetrics(),
    getProject(),
    iterationPages(),
    selectIteration(),
    training(),
    updateProject(),
  ])
}

export default artificialIntelligence
