import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    paper: {
      [theme.breakpoints.up('lg')]: {
        overflow: 'hidden',
        height: '100%',
      },
    },
    closeIconButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    dialogContent: {
      [theme.breakpoints.up('lg')]: {
        width: '100%',
        height: '100%',
      },
    },
    rootGrid: {
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        overflow: 'hidden',
        height: '100%',
        flexDirection: 'row',
      },
    },
    leftPart: {
      width: 560,
      [theme.breakpoints.up('lg')]: {
        position: 'sticky',
        top: 0,
        alignSelf: 'start',
        width: 413,
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
      },
    },
    rightPart: {
      padding: 8,
      overflowY: 'auto',
      overflowX: 'hidden',
      [theme.breakpoints.up('lg')]: {
        minWidth: 826,
        flexWrap: 'nowrap',
        height: '100%',
      },
    },
  }
})
