import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'

import './styles.less'

import { Dialog, Button, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core'

import { FormInput } from 'modules/shared/forms'
import checkUrlIsValid from 'modules/shared/utils/validators/urlValidator'
import { ADD_WEB_PAGE_FROM_URL_DIALOG_FORM } from '../../constants'
import CircularProgressBar from '../../../presentation/components/CircularProgressBar'

const validate = (values) => {
  const errors = {}

  if (!values.url) {
    errors.url = 'Please provide a valid web page URL.'
  } else if (!checkUrlIsValid(values.url)) {
    errors.url = 'No web page was found. Please provide a valid web page URL.'
  }

  return errors
}

const AddWebPageFromUrlDialog = ({
  displayAddWebPageDialog,
  handleCloseAddWebPageDialog,
  handleSubmit,
  reset,
  submitting = true,
  pristine,
  invalid,
}) => {
  return (
    <Dialog open={displayAddWebPageDialog}>
      <DialogTitle>Add Web Page Using URL</DialogTitle>
      <Divider />
      <DialogContent className='gallery-add-web-page-from-url-dialog'>
        <form onSubmit={handleSubmit}>
          <Field name='url' label='Please enter the URL for the web page' type='text' component={FormInput} />
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            handleCloseAddWebPageDialog()
            reset()
          }}
        >
          Cancel
        </Button>
        <Button disabled={pristine || submitting || invalid} variant='outlined' color='primary' onClick={handleSubmit}>
          {submitting && <CircularProgressBar size={25} thickness={2} />}
          Add Web Page
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddWebPageFromUrlDialog.propTypes = {
  displayAddWebPageDialog: PropTypes.bool.isRequired,
  handleCloseAddWebPageDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: ADD_WEB_PAGE_FROM_URL_DIALOG_FORM,
  validate,
})(AddWebPageFromUrlDialog)
