export default (theme) => {
  return {
    container: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      direction: 'rtl',
      padding: '0 10px',
      overflowX: 'hidden',
      overflowY: 'overlay',
    },
    list: {
      direction: 'ltr',
      paddingTop: 2,
    },
    message: {
      height: '100%',
      fontSize: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
}
