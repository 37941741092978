import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setProjectToDelete](state, action) {
      return action.payload
    },
    [rawActions.deleteProject.request](state, action) {
      return defaultState
    },
  },
  defaultState
)
