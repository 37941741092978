export default (theme) => {
  return {
    container: {
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.background.user.action,

      paddingTop: theme.spacing() / 4,
      paddingBottom: theme.spacing() / 4,
      paddingRight: theme.spacing() / 4,
      paddingLeft: 0,
    },
    battery: {
      strokeWidth: 0.5,

      '&[charge=high]': {
        color: theme.palette.success.light,
        stroke: theme.palette.success.light,
      },

      '&[charge=medium]': {
        color: theme.palette.warning.light,
        stroke: theme.palette.warning.light,
      },

      '&[charge=low]': {
        color: theme.palette.error.light,
        stroke: theme.palette.error.light,
      },
    },
    text: {
      color: theme.palette.secondary.light,
      userSelect: 'none',
    },
  }
}
