import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  StartDate: null,
  EndDate: null,
}

export default handleActions(
  {
    [combineActions(rawActions.setAnnotationCreatedStartDate)](state, { payload }) {
      return { ...state, StartDate: payload }
    },
    [combineActions(rawActions.setAnnotationCreatedEndDate)](state, { payload }) {
      return { ...state, EndDate: payload }
    },
    [rawActions.setSelectedProject](state, action) {
      return defaultState
    },
  },
  defaultState
)
