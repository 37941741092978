import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'

import { Popover, MenuItem, ListItemText } from '@material-ui/core'
import * as rawActions from '../../actions'
import { shareSessionMenuItems } from '../../services/galleryMenus'

const ShareSessionOptionsMenu = ({
  setAnchorEl,
  anchorEl,
  isShareSessionByFormEnabled,
  actions: { handleShareSessionOptionsButtonMenuClick },
}) => {
  const _handleMenuClick = (menuItemType) => {
    setAnchorEl(null)
    handleShareSessionOptionsButtonMenuClick(menuItemType)
  }

  const formTitle = useSelector(getFormTitle)

  const shareSessionMenuItemsText = useMemo(
    () => ({
      [shareSessionMenuItems.AllPages]: 'All Pages',
      [shareSessionMenuItems.ByForm]: `By ${formTitle}`,
    }),
    [formTitle]
  )

  return (
    <Popover
      className='gallery-share-session-menu-popover'
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div className='gallery-share-session-menu'>
        {Object.values(shareSessionMenuItems).map((menuItem, index) => {
          const disabled = menuItem === shareSessionMenuItems.ByForm ? !isShareSessionByFormEnabled : false

          return (
            <MenuItem onClick={() => _handleMenuClick(menuItem)} key={menuItem} disabled={disabled}>
              <ListItemText primary={shareSessionMenuItemsText[menuItem]} />
            </MenuItem>
          )
        })}
      </div>
    </Popover>
  )
}

ShareSessionOptionsMenu.propTypes = {
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func.isRequired,
  isShareSessionByFormEnabled: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    handleShareSessionOptionsButtonMenuClick: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isShareSessionByFormEnabled: state.forms.contentForms.length > 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(ShareSessionOptionsMenu))
