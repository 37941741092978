/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { memo, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobileOnly } from 'react-device-detect'

import { useHover } from '@tabeeb/shared/utils/hooks'
import { makeGetIsGridUserSelected, makeGetIsUserVideoPlaying } from '@tabeeb/modules/presentation/selectors'
import { makeGetIsUserRecordingActive } from '@tabeeb/modules/recording/selectors'
import { setGridUser, toggleLargeVideo, toggleMobileLargeVideo } from '@tabeeb/modules/presentation/actions/tracks'

import UserOptions from '../UserOptions'
import UserVideo from '../UserVideo'
import UserAvatar from '../UserAvatar'

import useStyles from './styles'

const GridUser = ({ user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onClick = () => {
    if (isMobileOnly) {
      dispatch(toggleMobileLargeVideo({ userId: user.id }))
    } else {
      dispatch(setGridUser(user))
    }
  }

  const onDoubleClick = () => {
    dispatch(toggleLargeVideo({ userId: user.id }))
  }

  const [containerRef, hovered] = useHover()

  const videoPlayStatusSelector = useMemo(() => makeGetIsUserVideoPlaying(), [])
  const isVideoMuted = useSelector((state) => !videoPlayStatusSelector(state, { Id: user.id }))

  const isGridUserSelectedSelector = useMemo(() => makeGetIsGridUserSelected(), [])
  const isUserSelected = useSelector((state) => isGridUserSelectedSelector(state, { userId: user.id }))

  const recordingStatusSelector = useMemo(() => makeGetIsUserRecordingActive(), [])
  const recording = useSelector((state) => recordingStatusSelector(state, { userId: user.id }))

  return (
    <div
      className={classNames(
        classes.container,
        { [classes.selected]: isUserSelected && !recording },
        { [classes.recording]: recording }
      )}
      ref={containerRef}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {!isMobileOnly && <UserOptions user={user} hovered={hovered} recording={recording} />}
      {isVideoMuted && <UserAvatar user={user} />}
      <UserVideo user={user} isVideoMuted={isVideoMuted} />
      <div className={classNames(classes.border, 'tabeeb-user-box')} data-id={user.id} />
    </div>
  )
}

GridUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    avatarUrl: PropTypes.string,
  }).isRequired,
}

export default memo(GridUser)
