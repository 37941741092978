import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Grow, Paper, withStyles } from '@material-ui/core'

import Details from '../Details'
import DroneLocation from '../DroneLocation'

import Portal from '../../../contentViewer/components/Portal'

import { getAzimuth, getIsOpen } from '../../selectors'

import styles from './styles'

const Compass = ({ classes, containerRef, isOpen, azimuth, altitude, fov, gimbalPitch }) => {
  return (
    <Portal node={containerRef} className={classes.container}>
      <Grow in={isOpen} unmountOnExit>
        <Paper className={classes.paper}>
          <DroneLocation azimuth={azimuth} fov={fov} />
          <Details azimuth={azimuth} altitude={altitude} gimbalPitch={gimbalPitch} />
        </Paper>
      </Grow>
    </Portal>
  )
}

Compass.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
  }).isRequired,
  containerRef: PropTypes.instanceOf(HTMLElement).isRequired,
  isOpen: PropTypes.bool.isRequired,
  azimuth: PropTypes.number,
  altitude: PropTypes.number,
  fov: PropTypes.number,
  gimbalPitch: PropTypes.number,
  location: PropTypes.shape({
    Latitude: PropTypes.number,
    Longitude: PropTypes.number,
  }).isRequired,
  centerCoordinatesUTM: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
}

const mapStateToProps = (
  state,
  { azimuth, centerCoordinatesUTM, location: { Latitude: endLatitude, Longitude: endLongitude } }
) => {
  return {
    isOpen: getIsOpen(state),
    azimuth: azimuth ?? getAzimuth(state, { centerCoordinatesUTM, endLongitude, endLatitude }),
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Compass))
