import { handleActions, combineActions } from 'redux-actions'
import {
  getAvailableFormsForTenantRequest,
  getAvailableFormsForTenantSuccess,
  getAvailableFormsForTenantFailed,
  getAvailableFormsForTenantByFolderIdRequest,
  getAvailableFormsForTenantByFolderIdSuccess,
} from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(getAvailableFormsForTenantRequest, getAvailableFormsForTenantByFolderIdRequest)](state, action) {
      return true
    },
    [combineActions(
      getAvailableFormsForTenantSuccess,
      getAvailableFormsForTenantFailed,
      getAvailableFormsForTenantByFolderIdSuccess
    )](state, action) {
      return false
    },
  },
  defaultState
)
