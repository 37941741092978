import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(
      rawActions.exportFormAnswersRequest,
      rawActions.exportFormToPdfRequest,
      rawActions.exportFormToCsvRequest,
      rawActions.apiExportFormToCustomCsv.request
    )]() {
      return true
    },
    [combineActions(
      rawActions.exportFormAnswersSuccess,
      rawActions.exportFormToPdfSuccess,
      rawActions.exportFormToPdfFailed,
      rawActions.exportFormToCsvSuccess,
      rawActions.exportFormToCsvFailed,
      rawActions.apiExportFormToCustomCsv.success,
      rawActions.apiExportFormToCustomCsv.failed
    )]() {
      return defaultState
    },
  },
  defaultState
)
