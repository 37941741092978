import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as aiActions from '../../artificialIntelligence/actions'
import * as actions from '../actions'

import AnnotationPopover from '../components/AnnotationPopover'
import { gallerySelectors } from '../../gallery'
import { ContentType } from '../../../Enums'

class AnnotationPopoverContainer extends Component {
  handleClose = () => {
    const { actions } = this.props
    actions.setCoordinatesInfo(null)
  }

  handleEdit = (annotationInfo) => {
    const { actions, aiActions } = this.props

    aiActions.getUniqueAiObjectForEditRequest({ uniqueAIObjectId: annotationInfo.uniqueAIObjectId })
    actions.setCoordinatesInfo(null)
  }

  render() {
    const { isOpen, anchorReference, selectedCoordinatesInfo } = this.props

    const props = {
      anchorReference,
      selectedCoordinatesInfo,
      handleClose: this.handleClose,
      handleEdit: this.handleEdit,
    }
    return isOpen && selectedCoordinatesInfo && <AnnotationPopover {...props} />
  }
}

AnnotationPopoverContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchorReference: PropTypes.object.isRequired,
  selectedCoordinatesInfo: PropTypes.array,
  actions: PropTypes.shape({
    setCoordinatesInfo: PropTypes.func.isRequired,
  }),
  aiActions: PropTypes.shape({
    getUniqueAiObjectForEditRequest: PropTypes.func.isRequired,
  }),
}

const mapStateToProps = (state) => {
  const { selectedCoordinatesInfo } = state.contentViewer
  const selectedGalleryItemType = gallerySelectors.getSelectedGalleryItemType(state)

  return {
    selectedCoordinatesInfo,
    isOpen: Boolean(selectedGalleryItemType && selectedGalleryItemType === ContentType.SpatialModel),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    aiActions: bindActionCreators(aiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationPopoverContainer)
