import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = {
  deletedUserIds: [],
}

export default handleActions(
  {
    [rawActions.getDeletedUserIdsWithAnsweredQuestions.success]: (state, action) => {
      return { ...state, deletedUserIds: action.response.data }
    },
  },
  defaultState
)
