import { useState } from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmationDialog } from '@tabeeb/uikit'
import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'
import { getIsDeleteAnswerIconVisible } from '@tabeeb/modules/forms/selectors'
import { clearFormAnswerRequest } from '@tabeeb/modules/forms/actions'
import FormControlButton from '@tabeeb/modules/forms/components/FormControlButton'

const DeleteAnswerButton = ({ answerId, control }) => {
  const [isDeleteAnswerDialogOpen, setIsDeleteAnswerDialogOpen] = useState(false)
  const dispatch = useDispatch()

  const isVisible = useSelector((state) => getIsDeleteAnswerIconVisible(state, { control }))
  const formTitle = useSelector(getFormTitle)

  if (!isVisible) return null

  const _handleClearFormAnswer = () => {
    setIsDeleteAnswerDialogOpen(false)
    dispatch(clearFormAnswerRequest(answerId))
  }

  const dialogProps = {
    onCloseDialog: () => setIsDeleteAnswerDialogOpen(false),
    onSubmit: _handleClearFormAnswer,
    isOpen: isDeleteAnswerDialogOpen,
    dialogMessage: `${formTitle} answer will be permanently deleted.`,
  }

  const componentProps = {
    onClick: () => setIsDeleteAnswerDialogOpen(true),
    Icon: DeleteIcon,
    title: 'Delete answers',
  }

  return (
    <>
      <FormControlButton {...componentProps} />
      <ConfirmationDialog {...dialogProps} />
    </>
  )
}

DeleteAnswerButton.propTypes = {
  control: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  answerId: PropTypes.number,
}

export default DeleteAnswerButton
