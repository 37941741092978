import { memo } from 'react'

import { ListItem, ListItemText, Skeleton } from '@mui/material'

const TrainingMaterialListItemSkeleton = () => {
  return (
    <ListItem disableGutters>
      <ListItemText primary={<Skeleton width={350} />} secondary={<Skeleton width={150} />} />
    </ListItem>
  )
}

export default memo(TrainingMaterialListItemSkeleton)
