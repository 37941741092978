import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { ContentState } from '@tabeeb/enums'
import { ConfirmationDialog } from '@tabeeb/uikit'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

import { restoreSelectedSessions } from '../../../actions'

const RestoreSessionDialog = ({ open, sessions, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(() => {
    dispatch(restoreSelectedSessions())

    onClose()
  }, [dispatch, onClose])

  return (
    <ConfirmationDialog
      isOpen={open}
      title={sessionTitleFormatter.format('Restore sessions')}
      dialogMessage={sessionTitleFormatter.format(
        `Are you sure you want to restore this ${sessions?.length > 1 ? 'sessions' : 'session'}?`
      )}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      cancelButtonText='No'
      submitButtonText='Yes'
    />
  )
}

RestoreSessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Description: PropTypes.string.isRequired,
      ContentStatus: PropTypes.oneOf(Object.values(ContentState)).isRequired,
      Owner: PropTypes.shape({
        Name: PropTypes.string.isRequired,
      }),
    }).isRequired
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(RestoreSessionDialog)
