import { handleActions, combineActions } from 'redux-actions'
import {
  updateAvailableForms,
  resetFormsState,
  getAvailableFormsRequest,
  getAvailableFormsByFolderIdRequest,
} from '../actions'

const availableForms = handleActions(
  {
    [updateAvailableForms](state, action) {
      return [...action.payload]
    },
    [combineActions(resetFormsState, getAvailableFormsRequest, getAvailableFormsByFolderIdRequest)](state, action) {
      return []
    },
  },
  []
)

export default availableForms
