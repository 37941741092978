import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),

      '& .MuiIconButton-root': {
        padding: theme.spacing(),
      },
    },
    title: {
      flexGrow: 1,
    },
  }
})
