import { createAction } from 'redux-actions'

import { createApiActions } from '@tabeeb/modules/shared/utils/actions'

export const setReviews = createAction('SET_REVIEWS')

export const addContentReview = createAction('ADD_CONTENT_REVIEW')
export const updateContentReview = createAction('UPDATE_CONTENT_REVIEW')
export const onContentReviewCreated = createAction('ON_CONTENT_REVIEW_CREATED')

export const getContentReviewsRequest = createAction('GET_CONTENT_REVIEWS_REQUEST')
export const getContentReviewsSuccess = createAction('GET_CONTENT_REVIEWS_SUCCESS')
export const getContentReviewsFailed = createAction('GET_CONTENT_REVIEWS_FAILED')

export const addEditedAnswer = createAction('ADD_EDITED_ANSWER')

export const requestRework = createAction('REQUEST_REWORK')
export const sendReviewFeedbackRequest = createAction('SEND_REVIEW_FEEDBACK_REQUEST')
export const sendReviewFeedbackSuccess = createAction('SEND_REVIEW_FEEDBACK_SUCCESS')
export const sendReviewFeedbackFailed = createAction('SEND_REVIEW_FEEDBACK_FAILED')
export const onReviewFeedbackSent = createAction('ON_REVIEW_FEEDBACK_SENT')

export const sendForInContentReviewRequest = createAction('SEND_FOR_IN_CONTENT_REVIEW_REQUEST')
export const sendForInContentReviewSuccess = createAction('SEND_FOR_IN_CONTENT_REVIEW_SUCCESS')
export const sendForInContentReviewFailed = createAction('SEND_FOR_IN_CONTENT_REVIEW_FAILED')

export const beginInContentReviewRequest = createAction('BEGIN_IN_CONTENT_REVIEW_REQUEST')
export const beginInContentReviewSuccess = createAction('BEGIN_IN_CONTENT_REVIEW_SUCCESS')
export const beginInContentReviewFailed = createAction('BEGIN_IN_CONTENT_REVIEW_FAILED')

export const completeInContentReviewRequest = createAction('COMPLETE_IN_CONTENT_REVIEW_REQUEST')
export const completeInContentReviewSuccess = createAction('COMPLETE_IN_CONTENT_REVIEW_SUCCESS')
export const completeInContentReviewFailed = createAction('COMPLETE_IN_CONTENT_REVIEW_FAILED')

export const feedbackInContentReviewRequest = createAction('FEEDBACK_IN_CONTENT_REVIEW_REQUEST')
export const feedbackInContentReviewSuccess = createAction('FEEDBACK_IN_CONTENT_REVIEW_SUCCESS')
export const feedbackInContentReviewFailed = createAction('FEEDBACK_IN_CONTENT_REVIEW_FAILED')

export const getContentReviewStatusRequest = createAction('GET_CONTENT_REVIEW_STATUS_REQUEST')
export const getContentReviewStatusSuccess = createAction('GET_CONTENT_REVIEW_STATUS_SUCCESS')
export const getContentReviewStatusFailed = createAction('GET_CONTENT_REVIEW_STATUS_FAILED')
export const setContentReviewStatus = createAction('SET_CONTENT_REVIEW_STATUS')

export const updateReviewStatus = createAction('UPDATE_REVIEW_STATUS')

export const completeReview = createAction('COMPLETE_REVIEW')
export const completeReviewRequest = createAction('COMPLETE_REVIEW_REQUEST')
export const completeReviewSuccess = createAction('COMPLETE_REVIEW_SUCCESS')
export const completeReviewFailed = createAction('COMPLETE_REVIEW_FAILED')
export const onContentReviewCompleted = createAction('ON_CONTENT_REVIEW_COMPLETED')

export const onContentReviewRevoked = createAction('ON_CONTENT_REVIEW_REVOKED')
export const revokeReviewRequest = createAction('REVOKE_REVIEW_REQUEST')
export const revokeReviewSuccess = createAction('REVOKE_REVIEW_SUCCESS')
export const revokeReviewFailed = createAction('REVOKE_REVIEW_FAILED')

export const onContentReviewResumed = createAction('ON_CONTENT_REVIEW_RESUMED')

export const getTenantsListForReviewRequest = createAction('GET_TENANTS_LIST_FOR_REVIEW_REQUEST')
export const getTenantsListForReviewSuccess = createAction('GET_TENANTS_LIST_FOR_REVIEW_SUCCESS')
export const getTenantsListForReviewFailed = createAction('GET_TENANTS_LIST_FOR_REVIEW_FAILED')

export const renameContentForTenant = createAction('RENAME_CONTENT_FOR_TENANT')
export const renameContentForTenantRequest = createAction('RENAME_CONTENT_FOR_TENANT_REQUEST')
export const renameContentForTenantSuccess = createAction('RENAME_CONTENT_FOR_TENANT_SUCCESS')
export const renameContentForTenantFailed = createAction('RENAME_CONTENT_FOR_TENANT_FAILED')

export const getContentDescriptionsRequest = createAction('GET_CONTENT_DESCRIPTIONS_REQUEST')
export const getContentDescriptionsSuccess = createAction('GET_CONTENT_DESCRIPTIONS_SUCCESS')
export const getContentDescriptionsFailed = createAction('GET_CONTENT_DESCRIPTIONS_FAILED')

export const initiateJoinContentReview = createAction('INITIATE_JOIN_CONTENT_REVIEW')
export const joinContentReview = createApiActions('JOIN_CONTENT_REVIEW')
export const getTenantContentReviews = createApiActions('GET_TENANT_CONTENT_REVIEWS')
