import { updateInContentReviewStatus } from '@tabeeb/modules/contentSharings/actions'
import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.setTenantContentsList](state, action) {
      return action.payload
    },
    [rawActions.clearTenantContentsList](state, action) {
      return defaultState
    },
    [updateInContentReviewStatus.success](state, action) {
      const { contentIds, status } = action.payload
      return state.map((content) =>
        contentIds.includes(content.ContentId) ? { ...content, ReviewStatus: status } : content
      )
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
