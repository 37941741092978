import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  const borderColor = 'rgba(0, 0, 0, 0.23)'

  return {
    container: {
      backgroundColor: theme.palette.background.paper,
      borderWidth: 1,
      borderColor,
      borderStyle: 'solid',
      borderTop: 'none',
      borderBottomLeftRadius: theme.shape.borderRadius * 1.5,
      borderBottomRightRadius: theme.shape.borderRadius * 1.5,
      width: '100%',
      minHeight: 50,
      maxHeight: 50,
      display: 'flex',
      flexShrink: 0,
      flexGrow: 1,
      position: 'relative',
    },
    toolbar: {
      minHeight: 0,
      maxHeight: '100%',
      display: 'flex',
      width: '100%',

      overflowX: 'auto',
      overflowY: 'hidden',
      marginTop: 'auto',
      marginBottom: 'auto',

      '& .MuiTabScrollButton-root': {
        '&:first-child': {
          borderRightWidth: 1,
          borderRightColor: borderColor,
          borderRightStyle: 'solid',
        },

        '&:last-child': {
          borderLeftWidth: 1,
          borderLeftColor: borderColor,
          borderLeftStyle: 'solid',
        },
      },

      '& .MuiTabs-scrollButtons.Mui-disabled': {
        opacity: theme.palette.action.disabledOpacity,
      },
    },
    panel: {
      '& > *': {
        marginLeft: theme.spacing(0.25),
        marginRight: theme.spacing(0.25),
      },
    },
    center: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      margin: 'auto',
      flexGrow: 1,
    },
    centerPlaceholder: {
      minWidth: 0,
      maxWidth: 100,
      flexGrow: 1,
    },
    side: {
      maxWidth: 350,
      flexGrow: 1,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.25),
      display: 'flex',
      alignItems: 'center',
    },
    left: {
      justifyContent: 'flex-start',
      flexBasis: '33%',
    },
    right: {
      justifyContent: 'flex-end',
      flexBasis: '33%',
    },
  }
})
