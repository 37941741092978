import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = ''

export default handleActions(
  {
    [rawActions.saveFormName](state, { payload }) {
      return payload
    },
    [rawActions.clearFormName](state, action) {
      return defaultState
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
