import { combineActions, handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = 0

export default handleActions(
  {
    [rawActions.setBatchesCount](state, { payload }) {
      return payload
    },
    [combineActions(rawActions.resetState, rawActions.resetNotificationsList)](state, { payload }) {
      return defaultState
    },
  },
  defaultState
)
