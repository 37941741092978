import { combineReducers } from 'redux'

import aiObjects from './aiObjects'
import currentIterationPagesDisplayMode from './currentIterationPagesDisplayMode'
import isDeploymentStarting from './isDeploymentStarting'
import isFastExcludeModeOn from './isFastExcludeModeOn'
import isIterationComponentsLoading from './isIterationComponentsLoading'
import isIterationDeploymentStatusLoading from './isIterationDeploymentStatusLoading'
import isIterationStatusLoading from './isIterationStatusLoading'
import isMetricsLoading from './isMetricsLoading'
import isPagesAnalysing from './isPagesAnalysing'
import isPagesAnalysisDialogOpen from './isPagesAnalysisDialogOpen'
import isPagesLoading from './isPagesLoading'
import isProjectsLoading from './isProjectsLoading'
import isTrainingSettingsDialogOpen from './isTrainingSettingsDialogOpen'
import isTrainingStarting from './isTrainingStarting'
import iterations from './iterations'
import pageNumber from './pageNumber'
import pages from './pages'
import pageSize from './pageSize'
import pagesStatistics from './pagesStatistics'
import projectForEdit from './projectForEdit'
import projects from './projects'
import projectToDelete from './projectToDelete'
import selectedAnnotationDates from './selectedAnnotationDates'
import selectedFilters from './selectedFilters'
import selectedFormAnswerStatuses from './selectedFormAnswerStatuses'
import selectedFormAnswerValue from './selectedFormAnswerValue'
import selectedIterationId from './selectedIterationId'
import selectedPagesForExclusion from './selectedPagesForExclusion'
import selectedProject from './selectedProject'
import selectedSearchField from './selectedSearchField'
import trainingSettings from './trainingSettings'
import customMetricsCharts from './customMetricsCharts'

export default combineReducers({
  ...selectedFilters,
  aiObjects,
  currentIterationPagesDisplayMode,
  customMetricsCharts,
  isDeploymentStarting,
  isFastExcludeModeOn,
  isIterationComponentsLoading,
  isIterationDeploymentStatusLoading,
  isIterationStatusLoading,
  isMetricsLoading,
  isPagesAnalysing,
  isPagesAnalysisDialogOpen,
  isPagesLoading,
  isProjectsLoading,
  isTrainingSettingsDialogOpen,
  isTrainingStarting,
  iterations,
  pageNumber,
  pages,
  pageSize,
  pagesStatistics,
  projectForEdit,
  projects,
  projectToDelete,
  selectedAnnotationDates,
  selectedFormAnswerStatuses,
  selectedFormAnswerValue,
  selectedIterationId,
  selectedPagesForExclusion,
  selectedProject,
  selectedSearchField,
  trainingSettings,
})
