import { handleActions } from 'redux-actions'

import { setSearchInProgress, resetState } from '../actions'

const defaultState = {
  searchInProgress: false,
}

const activitiesSearchInProgress = handleActions(
  {
    [setSearchInProgress](state, action) {
      return _setSearchInProgress(state, action.payload)
    },
    [resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)

export default activitiesSearchInProgress

const _setSearchInProgress = (state, searchInProgress) => {
  return { ...state, searchInProgress }
}
