import CircularProgress from '@material-ui/core/CircularProgress'

import './styles.less'

const CircularProgressBar = ({ size, thickness }) => {
  return (
    <div className='tabeeb-circular-progress-bar'>
      <CircularProgress size={size} thickness={thickness} />
    </div>
  )
}

export default CircularProgressBar
