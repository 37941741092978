import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TabPanel } from '@tabeeb/enums'
import Timeline from '@tabeeb/modules/timeline/components/Timeline'
import { getNavbarCurrentBottomPanel } from '@tabeeb/modules/customVideo/selectors'
import {
  closeClassificationAnnotationsTimeline,
  deletePageFromClassificationAnnotationsPages,
  updateClassificationAnnotationsPages,
  openAddClassificationAnnotationsDialog,
} from '../../actions'
import { getIsClassificationAnnotationsPagesTimelineOpen, getPagesList, getIsProcessing } from '../../selectors'
import AddClassificationAnnotationsDialog from '../AddClassificationAnnotationsDialog'

const ClassificationAnnotationsPagesTimeline = () => {
  const dispatch = useDispatch()
  const pages = useSelector(getPagesList)
  const isOpen = useSelector(getIsClassificationAnnotationsPagesTimelineOpen)
  const isProcessing = useSelector(getIsProcessing)
  const currentPanel = useSelector(getNavbarCurrentBottomPanel)

  const _handleClose = () => {
    dispatch(closeClassificationAnnotationsTimeline())
  }

  const _handleSubmit = () => {
    if (isProcessing) {
      return
    }
    dispatch(openAddClassificationAnnotationsDialog())
  }

  const _handleDeleteItem = (pageIndex) => {
    dispatch(deletePageFromClassificationAnnotationsPages(pageIndex))
  }

  const _handleUpdateList = (newList) => {
    dispatch(updateClassificationAnnotationsPages(newList))
  }

  const actions = [
    {
      onClick: _handleClose,
      title: 'Cancel',
      class: 'close',
    },
    {
      onClick: _handleSubmit,
      title: 'Done',
      class: 'submit',
      tooltip: pages.length > 0 ? null : `At least one page or a folder of pages must be selected`,
      disabled: pages.length === 0,
    },
  ]

  const props = {
    actions,
    timeLineList: pages,
    handleDeleteItem: _handleDeleteItem,
    handleUpdateList: _handleUpdateList,
    isOpen,
    isSelected: currentPanel === TabPanel.ClassificationAnnotations,
  }

  return (
    <>
      <Timeline {...props} />
      <AddClassificationAnnotationsDialog />
    </>
  )
}

export default memo(ClassificationAnnotationsPagesTimeline)
