import { all, takeLatest, put, select } from 'redux-saga/effects'
import * as rawActions from '../actions'
import { getPowerBIReportProvidersState } from '../selectors'

function* loadPowerBIReportProviders() {
  const { filters } = yield select(getPowerBIReportProvidersState)
  const requestPayload = {
    skip: (filters.pageNumber - 1) * filters.pageSize,
    take: filters.pageSize,
  }
  yield put(rawActions.getPowerBIReportProviders.request(requestPayload))
}

function* reportProvidersSaga() {
  yield all([
    takeLatest(
      [rawActions.loadPowerBIReportProviders, rawActions.setPowerBIReportProvidersFilters],
      loadPowerBIReportProviders
    ),
  ])
}

export default reportProvidersSaga
