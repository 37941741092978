import PropTypes from 'prop-types'

import { Line as CanvasLine, Group } from 'react-konva'

import { getHighlightedColor } from '../../services/colorsService'
import { getViewBoxFromAnnotationCoordinates } from '../../helpers'

const Line = ({
  annotation,
  annotationRef,
  opacity,
  hovered,
  hoverProps,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
  children,
}) => {
  const position = Line.getXY(annotation)
  const anchor = Line.getAnchor(annotation)
  const points = Line.getPoints(annotation)

  const commonStyles = {
    lineCap: 'round',
    lineJoin: 'round',
    opacity,
  }

  const styles = {
    ...commonStyles,
    stroke: annotation.Color,
    strokeWidth: annotation.Width,
    hitStrokeWidth: annotation.Width * 2,
  }

  const hoveredStyles = {
    ...commonStyles,
    stroke: getHighlightedColor(annotation.Color),
    strokeWidth: 1,
    strokeHitEnabled: false,
    strokeScaleEnabled: false,
  }

  return (
    <Group {...position} {...dragAndDropProps}>
      <CanvasLine
        id={annotation.Id}
        ref={annotationRef}
        points={points}
        {...styles}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
      />
      {(selected || hovered) && <CanvasLine points={points} {...hoveredStyles} />}
      <Group {...anchor}>{children}</Group>
    </Group>
  )
}

Line.getXY = (annotation) => ({
  x: annotation.Coordinates[0].Start.X,
  y: annotation.Coordinates[0].Start.Y,
})

Line.getAnchor = (annotation) => ({
  x: annotation.Coordinates[0].End.X - annotation.Coordinates[0].Start.X,
  y: annotation.Coordinates[0].End.Y - annotation.Coordinates[0].Start.Y,
})

Line.getPoints = (annotation) => {
  const position = Line.getXY(annotation)
  const coords = annotation.Coordinates[0]
  return [
    coords.Start.X - position.x,
    coords.Start.Y - position.y,
    coords.End.X - position.x,
    coords.End.Y - position.y,
  ]
}

Line.getViewBox = getViewBoxFromAnnotationCoordinates

Line.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Color: PropTypes.string.isRequired,
    Coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        Start: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
        End: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
      })
    ),
    Width: PropTypes.number.isRequired,
  }).isRequired,
  annotationRef: PropTypes.func,
  opacity: PropTypes.number,
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
  children: PropTypes.node,
}

export default Line
