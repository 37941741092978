import { memo } from 'react'
import PropTypes from 'prop-types'

import { Badge, Tooltip } from '@mui/material'
import { Check, Close, Schedule } from '@mui/icons-material'

import { InviteStatus } from '@tabeeb/enums'

const InviteStatusTitle = {
  [InviteStatus.Declined]: 'Declined',
  [InviteStatus.Accepted]: 'Accepted',
  [InviteStatus.Pending]: 'Pending',
}

const InviteStatusIcon = {
  [InviteStatus.Declined]: Close,
  [InviteStatus.Accepted]: Check,
  [InviteStatus.Pending]: Schedule,
}

const StatusBadge = ({ status, children }) => {
  const StatusIcon = InviteStatusIcon[status]

  return (
    <Badge
      color={status === InviteStatus.Pending ? 'primary' : status === InviteStatus.Accepted ? 'success' : 'error'}
      overlap='circular'
      status={status}
      componentsProps={{
        badge: {
          style: {
            width: 'auto',
            height: 'auto',
            minWidth: 0,
            padding: 2,
          },
        },
      }}
      badgeContent={
        <Tooltip title={InviteStatusTitle[status]}>
          <StatusIcon fontSize='inherit' />
        </Tooltip>
      }
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </Badge>
  )
}

StatusBadge.propTypes = {
  status: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
}

export default memo(StatusBadge)
