import { handleActions } from 'redux-actions'
import _ from 'lodash'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.setAvailableTags](state, action) {
      const tags = _.sortBy(action.payload, [(tag) => tag.Name.toLowerCase()])
      return tags
    },
    [rawActions.createTagAndAddToControl.success](state, { response }) {
      const formControlTag = response.data

      if (state.some((tag) => tag.Id === formControlTag.TagId)) {
        return state
      }
      return _.sortBy(
        [...state, { Id: formControlTag.TagId, Name: formControlTag.Name }],
        [(tag) => tag.Name.toLowerCase()]
      )
    },
  },
  []
)
