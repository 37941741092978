import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'
import { createOption } from '../../services/createOption'
import { generateId } from '../../services/generateTempId'

const defaultState = []

export default handleActions(
  {
    [rawActions.addTabPanel](state, { payload }) {
      const newTab = { ...payload, Order: state.length, TempId: generateId(state) }

      return [...state, newTab]
    },
    [rawActions.updateTabsList](state, action) {
      return [...action.payload]
    },
    [rawActions.deleteTabPanel](state, { payload }) {
      return state.filter((tab) => tab.TempId !== payload.TempId)
    },
    [rawActions.saveTabName](state, { payload: { control, name } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, Name: name } : cont))
    },
    [rawActions.saveTabTooltip](state, { payload: { control, tooltip } }) {
      return state.map((cont) => (cont.TempId === control.TempId ? { ...cont, Tooltip: tooltip } : cont))
    },
    [rawActions.addTabOption](state, { payload }) {
      const control = payload
      const newOption = createOption(control)
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Tabs.push(newOption)
        }
        return cont
      })
    },
    [rawActions.deleteTabOption](state, { payload: { control, option } }) {
      const newState = state.map((cont) =>
        cont.TempId === control.TempId ? { ...cont, Tabs: cont.Tabs.filter((opt) => opt.Id !== option.Id) } : cont
      )
      const updatedList = _updateOrder(newState, control)
      return updatedList
    },
    [rawActions.saveTabOptionName](state, { payload: { control, option, newOptionName } }) {
      return state.map((cont) => {
        if (cont.TempId === control.TempId) {
          cont.Tabs.map((opt) => {
            if (opt.Id === option.Id) {
              opt.Value = newOptionName
            }
            return opt
          })
        }
        return cont
      })
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)

function _updateOrder(controlsList, control) {
  const updatedList = controlsList.map((cont) => {
    if (cont.TempId === control.TempId) {
      cont.Tabs = cont.Tabs.map((tab, index) => {
        tab.Order = index
        return tab
      })
    }

    return cont
  })

  return updatedList
}
