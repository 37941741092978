import { isMobileOnly } from 'react-device-detect'

// square root should be an integer
export const MAX_GRID_ITEMS_AMOUNT = 9

export const getIsOverflowed = (itemsAmount) => {
  return isMobileOnly && itemsAmount > MAX_GRID_ITEMS_AMOUNT
}

export const getGridColumns = (itemsAmount) => {
  const isOverflowed = getIsOverflowed(itemsAmount)

  const columnCount = isMobileOnly ? Math.floor(Math.sqrt(itemsAmount)) : Math.ceil(Math.sqrt(itemsAmount))

  return isOverflowed ? Math.sqrt(MAX_GRID_ITEMS_AMOUNT) : columnCount
}

export const getGridTemplateColumns = (itemsAmount) => {
  const columnCount = getGridColumns(itemsAmount)

  return isMobileOnly ? `repeat(${columnCount}, 1fr)` : `repeat(${columnCount}, minmax(120px, 1fr))`
}
