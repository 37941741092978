import { useState, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ConfirmationDialog } from '@tabeeb/uikit'
import { getFormButtonState } from '@tabeeb/modules/forms/selectors'
import { getAddButtonColor } from '@tabeeb/modules/appConfigState/selectors'
import { detachCurrentForm } from '@tabeeb/modules/forms/actions'
import { FormButtons } from '../../services/enums'
import FormButton from '../FormButton'

const DetachFormButton = () => {
  const [isDetachFormDialogOpen, setIsDetachFormDialogOpen] = useState(false)
  const { isVisible, isDisabled } = useSelector((state) =>
    getFormButtonState(state, { button: FormButtons.DetachForm })
  )

  const color = useSelector(getAddButtonColor)
  const dispatch = useDispatch()

  if (!isVisible) return null

  const _handleSubmit = () => {
    dispatch(detachCurrentForm())
    setIsDetachFormDialogOpen(false)
  }

  const dialogProps = {
    onCloseDialog: () => setIsDetachFormDialogOpen(false),
    isOpen: isDetachFormDialogOpen,
    onSubmit: _handleSubmit,
    title: 'Are you sure you want to detach this form?',
  }

  const componentProps = {
    onClick: () => setIsDetachFormDialogOpen(true),
    buttonText: 'Detach',
    buttonColor: color,
    isDisabled,
  }

  return (
    <>
      <FormButton {...componentProps} />
      <ConfirmationDialog {...dialogProps} />
    </>
  )
}

export default memo(DetachFormButton)
