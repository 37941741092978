import { createSelector } from 'reselect'
import {
  ContentReviewStatus,
  AnswerStatuses,
  DisplayAnswersMode,
  FormItemType,
  AnswerStatusFilterOptions,
  FormControlType,
  SessionPermission,
  ReviewStatus,
  UserRole,
  ContentSharingType,
  ContentFormRecordType,
} from '@tabeeb/enums'
import { tabeebTheme } from '@tabeeb/uikit'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'
import { Approved, Rejected, Submitted } from '@tabeeb/modules/shared/icons'
import {
  getContentReviewStatus,
  getCurrentUserReview,
  getMergeReviewWithCurrentForm,
  getReviewsList,
} from '@tabeeb/modules/contentReviews/selectors'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import {
  getFormTitle,
  getIsFormSignatureEnabledInTenant,
  getIsInContentReviewEnabled,
  getIsShowFormUsersFilter,
  getShowMenuAI,
} from '@tabeeb/modules/appConfigState/selectors'
import { getIsCallStarted } from '@tabeeb/modules/presentation/selectors'
import {
  getIsCurrentUserReviewer,
  getIsCurrentUserViewer,
  getUsersListForCurrentUser,
} from '@tabeeb/modules/../users/selectors'

import { getContentId, getIsCurrentUserPresenter } from '@tabeeb/modules/shared/content/selectors'
import { FormButtons, FormButtonsGroup } from '../services/enums'
import { calculateValue } from '../services/calculateDefaultValueOrCounterLimit'
import { formTabsIdsEquals } from '../services/formTabService'

export const getAvailableForms = (state) => state.forms.availableForms
export const getSelectedGalleryItemId = (state) => state.gallery.galleryState.selectedGalleryItemId
export const getContentForms = (state) => state.forms.contentForms
export const contentFormsLoadingState = (state) => state.forms.contentFormsLoadingState
export const getCurrentForm = (state) => state.forms.currentForm
export const selectUsersFilterDeletedUserIds = (state) => state.forms.usersFilter.deletedUserIds
export const selectUsersFilterHideOtherUsers = createSelector(
  [getCurrentForm, getContentForms],
  (currentContentForm, preloadedContentForms) => {
    const preloadedCurrentContentForm = preloadedContentForms.find(
      (contentForm) => contentForm.Id === currentContentForm.Id
    )
    return preloadedCurrentContentForm?.HideOtherUsers ?? false
  }
)
export const selectUsersFilter = createSelector(
  [
    getCurrentUserId,
    getUsersListForCurrentUser,
    selectUsersFilterDeletedUserIds,
    selectUsersFilterHideOtherUsers,
    getIsShowFormUsersFilter,
  ],
  (currentUserId, users, deletedUserIds, hideOtherUsers, showUsers) => {
    if (!showUsers) {
      return []
    }

    if (hideOtherUsers) {
      return users.filter((user) => user.id === currentUserId)
    }

    return users.filter((user) => !user.isDeleted || deletedUserIds.includes(user.id) || user.role === UserRole.AIUser)
  }
)
export const getSelectedUserId = (state) => state.forms.selectedUserId
export const getDisplayAnswersMode = createSelector(
  [(state) => state.forms.displayAnswersMode, selectUsersFilter, getSelectedUserId],
  (mode, users, selectedUserId) => {
    if (mode === DisplayAnswersMode.ByUser) {
      return users.some((user) => user.id === selectedUserId) ? DisplayAnswersMode.ByUser : DisplayAnswersMode.AllLatest
    }
    return mode
  }
)

export const getFormUsersFilterSelectedValue = createSelector(
  [getDisplayAnswersMode, getSelectedUserId],
  (displayAnswersMode, selectedUserId) => {
    switch (displayAnswersMode) {
      case DisplayAnswersMode.AllLatest:
        return 'allLatest'
      case DisplayAnswersMode.AllMyLatest:
        return 'allMyLatest'
      case DisplayAnswersMode.ByUser:
        return selectedUserId
      default:
        return 'allLatest'
    }
  }
)

export const getIsAnswerInProgress = (state) => state.forms.answerInProgress
export const getIsFormExportInProgress = (state) => state.forms.formExportInProgress
export const getCurrentFormSelectedRecord = (state) => getCurrentForm(state)?.selectedRecord
export const getFormAnswerStatusFilter = (state) => state.forms.answerStatusFilter
export const getSelectedTabs = (state) => state.forms.currentForm?.selectedTabs || []
export const getFormAnswerStatusStatistics = (state) => state.forms.answerStatusStatistics
export const getContentReviews = (state) => state.contentReviews.reviewsList
export const getContentOwnerId = (state) => state.contentState.ownerId
export const getIsExportFormToPdfButtonEnabled = (state) => state.appConfigState.showExportFormToPdfButton
export const getIsFormRecordsEnabled = (state) => state.appConfigState.isFormRecordEnabled
export const getCounterValues = (state) => state.artificialIntelligence.counterValuesList

export const getHasAnswerPermission = (state) => hasSessionPermission(state, SessionPermission.AnswerQuestions)
export const getHasApprovePermission = (state) => hasSessionPermission(state, SessionPermission.RejectApprove)
export const getHasAttachFormPermission = (state) => hasSessionPermission(state, SessionPermission.AddForms)
export const getHasDeleteAllAnswersPermission = (state) =>
  hasSessionPermission(state, SessionPermission.DeleteAllFormAnswers)
export const getHasExportFormCsvPermission = (state) => hasSessionPermission(state, SessionPermission.ExportFormAsCsv)
export const getHasExportFormPdfPermission = (state) => hasSessionPermission(state, SessionPermission.ExportFormAsPdf)
export const getHasCompleteReviewPermission = (state) => hasSessionPermission(state, SessionPermission.CompleteReview)
export const getHasSendReviewFeedbackPermission = (state) =>
  hasSessionPermission(state, SessionPermission.SendReviewFeedback)

export const getFormById = createSelector([getContentForms, (_, { Id }) => Id], (contentForms, formId) => {
  return contentForms.find((cf) => cf.FormId === formId)
})

export const getContentFormsForCurrentUser = createSelector(
  [getContentForms, getIsCurrentUserReviewer, getCurrentUserReview],
  (contentForms, isReviewer, currentUserReview) => {
    if (isReviewer) {
      if (currentUserReview) {
        const filteredForms = contentForms.filter((cf) => currentUserReview.FormsIds.some((r) => r === cf.FormId))
        return filteredForms
      }
      return contentForms
    }
    return contentForms
  }
)

export const getIsFormHiddenForCurrentUser = createSelector(
  [getCurrentForm, getContentReviews, getIsCurrentUserReviewer, getContentId, getFormTitle],
  (currentForm, reviewsList, isReviewer, contentId, formTitle) => {
    let result = { isHidden: false, textMessage: '' }

    const currentFormReview = reviewsList.find((r) => r.FormsIds.some((formId) => formId === currentForm.FormId))
    if (!currentFormReview) {
      return result
    }

    const reviewStatus = currentFormReview.ReviewStatus
    if (currentFormReview.Type === ContentSharingType.ForReview) {
      if (isReviewer && reviewStatus !== ContentReviewStatus.Active) {
        result = { isHidden: true, textMessage: `${formTitle} reworking in progress` }
      } else if (
        !isReviewer &&
        (reviewStatus === ContentReviewStatus.Active || reviewStatus === ContentReviewStatus.Pending)
      ) {
        result = { isHidden: true, textMessage: `${formTitle} is exported for review` }
      }
    } else if (currentFormReview.Type === ContentSharingType.MergeForReview) {
      if (contentId === currentFormReview.TargetContentId && reviewStatus !== ContentReviewStatus.Active) {
        result = { isHidden: true, textMessage: `${formTitle} reworking in progress` }
      }

      if (
        contentId !== currentFormReview.TargetContentId &&
        (reviewStatus === ContentReviewStatus.Active || reviewStatus === ContentReviewStatus.Pending)
      ) {
        result = { isHidden: true, textMessage: `${formTitle} is exported for review` }
      }
    }

    return result
  }
)

export const getIsDetachButtonDisabled = createSelector(
  [getContentForms, getContentReviews, getCurrentForm, (_, { form }) => form],
  (contentForms, reviewsList, currentForm, form) => {
    const formItem = form || currentForm
    const contentForm = contentForms.find((cf) => cf.FormId === formItem?.FormId)
    if (!contentForm) return false

    const currentFormReview = reviewsList.find((r) => r.FormsIds.some((formId) => formId === contentForm.FormId))
    if (!currentFormReview) return false

    const reviewStatus = currentFormReview.ReviewStatus

    if (reviewStatus !== ContentReviewStatus.Completed) {
      return true
    }

    return false
  }
)

export const getIsAddFormButtonVisible = createSelector(
  [getIsCurrentUserPresenter, getIsCallStarted, getHasAttachFormPermission],
  (isPresenter, isCallStarted, hasAttachPermission) => {
    return isPresenter || (!isCallStarted && hasAttachPermission)
  }
)

export const getIsInitialValuesButtonVisible = createSelector(
  [getDisplayAnswersMode, getSelectedUserId, getCurrentUserId, getCurrentForm, getHasAnswerPermission],
  (displayAnswersMode, selectedUserId, currentUserId, currentForm, hasAnswerPermission) => {
    const answers = currentForm.answers || []
    const controls = currentForm.Controls
    const isSelectedCurrentUser = displayAnswersMode === DisplayAnswersMode.ByUser && selectedUserId === currentUserId

    let hasControlsWithInitialValueWithoutAnswer = false

    if (controls) {
      for (let i = 0; i < controls.length; i++) {
        const control = controls[i]
        const isControlWithInitialValue = control.InitialValue

        const hasAnswer = answers.some((answ) => answ.FormControlId === control.Id)

        if (isControlWithInitialValue && !hasAnswer) {
          hasControlsWithInitialValueWithoutAnswer = true
          break
        }
      }
    }

    const isVisible = isSelectedCurrentUser && hasControlsWithInitialValueWithoutAnswer && hasAnswerPermission

    return isVisible
  }
)

export const getIsUploadAnswersFromFileButtonVisible = createSelector(
  [getDisplayAnswersMode, getSelectedUserId, getCurrentUserId, getCurrentForm, getHasAnswerPermission],
  (displayAnswersMode, selectedUserId, currentUserId, currentForm, hasAnswerPermission) => {
    const controls = currentForm.Controls || []
    const isSelectedCurrentUser = displayAnswersMode === DisplayAnswersMode.ByUser && selectedUserId === currentUserId
    const hasAnswerableControls = controls.some(
      (c) => c.FormItemType !== FormItemType.Counter && c.FormItemType !== FormItemType.Action
    )

    const isVisible = isSelectedCurrentUser && hasAnswerableControls && hasAnswerPermission

    return isVisible
  }
)

export const getHasSendForInContentReviewPermission = (state) =>
  hasSessionPermission(state, SessionPermission.SendForInContentReview)

export const getHasCompleteOrFeedbackInContentReviewPermission = (state) =>
  hasSessionPermission(state, SessionPermission.CompleteOrFeedbackInContentReview)

export const getIsSendForInContentReviewButtonVisible = createSelector(
  [getHasSendForInContentReviewPermission, getIsInContentReviewEnabled],
  (hasSendForInContentReviewPermission, isInContentReviewEnabled) => {
    return isInContentReviewEnabled && hasSendForInContentReviewPermission
  }
)

export const getIsSendForInContentReviewButtonDisabled = createSelector(
  [getContentReviewStatus],
  (sessionReviewStatus) => {
    const ReviewActiveStatuses = [ReviewStatus.InReview, ReviewStatus.ReadyForReview]

    return ReviewActiveStatuses.includes(sessionReviewStatus)
  }
)

export const getAreCompleteOrFeedbackInContentReviewButtonsVisible = createSelector(
  [getHasCompleteOrFeedbackInContentReviewPermission, getContentReviewStatus, getIsInContentReviewEnabled],
  (hasCompleteOrFeedbackInContentReviewPermission, sessionReviewStatus, isInContentReviewEnabled) => {
    const isInContentReviewActive =
      sessionReviewStatus === ReviewStatus.InReview || sessionReviewStatus === ReviewStatus.ReadyForReview
    return isInContentReviewEnabled && hasCompleteOrFeedbackInContentReviewPermission && isInContentReviewActive
  }
)

export const getIsFormButtonVisible = createSelector(
  [
    getContentForms,
    getIsAddFormButtonVisible,
    getHasExportFormCsvPermission,
    getIsInitialValuesButtonVisible,
    getIsUploadAnswersFromFileButtonVisible,
    getIsSendForInContentReviewButtonVisible,
    getAreCompleteOrFeedbackInContentReviewButtonsVisible,
    getReviewsList,
    (_, { button }) => button,
  ],
  (
    contentForms,
    isAddFormVisible,
    hasExportFormCsvPermission,
    isInitialValueButtonVisible,
    isUploadAnswersFromCsvButtonVisible,
    isSendForInContentReviewButtonVisible,
    areCompleteOrFeedbackInContentReviewButtonsVisible,
    reviews,
    button
  ) => {
    const anyContentForm = contentForms?.length > 0

    switch (button) {
      case FormButtons.AddForm:
        return isAddFormVisible
      case FormButtons.ExportForm:
        return hasExportFormCsvPermission && anyContentForm
      case FormButtons.DetachForm:
        return isAddFormVisible && anyContentForm
      case FormButtons.SetInitialValues:
        return isInitialValueButtonVisible
      case FormButtons.UploadCsv:
        return isUploadAnswersFromCsvButtonVisible
      case FormButtons.SendForInContentReview:
        return isSendForInContentReviewButtonVisible && anyContentForm && reviews.length === 0
      case FormButtons.FeedbackInContentReview:
      case FormButtons.CompleteInContentReview:
        return areCompleteOrFeedbackInContentReviewButtonsVisible && anyContentForm
      default:
        return false
    }
  }
)

export const getIsFormButtonDisabled = createSelector(
  [
    getIsFormExportInProgress,
    getIsDetachButtonDisabled,
    getIsSendForInContentReviewButtonDisabled,
    getCurrentUserReview,
    (_, { button }) => button,
  ],
  (isExportInProgress, isDetachButtonDisabled, isSendForInContentReviewButtonDisabled, review, button) => {
    switch (button) {
      case FormButtons.ExportForm:
        return isExportInProgress
      case FormButtons.DetachForm:
      case FormButtons.SetInitialValues:
      case FormButtons.UploadCsv:
        return isDetachButtonDisabled
      case FormButtons.CompleteReview:
      case FormButtons.SendReviewFeedback:
        return review ? review.ReviewStatus !== ContentReviewStatus.Active : true
      case FormButtons.SendForInContentReview:
        return isSendForInContentReviewButtonDisabled
      default:
        return false
    }
  }
)

export const getFormButtonState = createSelector(
  [getIsFormButtonVisible, getIsFormButtonDisabled, (_, { button }) => button],
  (isVisible, isDisabled) => {
    return { isVisible, isDisabled }
  }
)

export const getFormButtonsGroups = createSelector(
  [
    getCurrentUserReview,
    getIsSendForInContentReviewButtonVisible,
    getAreCompleteOrFeedbackInContentReviewButtonsVisible,
  ],
  (review, isSendForInContentReviewButtonVisible, areCompleteOrFeedbackInContentReviewButtonsVisible) => {
    if (review) {
      const group =
        review.Type === ContentSharingType.ForReview ? FormButtonsGroup.Review : FormButtonsGroup.MergeReview
      return [group]
    }

    const groups = [FormButtonsGroup.Common]
    if (isSendForInContentReviewButtonVisible || areCompleteOrFeedbackInContentReviewButtonsVisible) {
      groups.push(FormButtonsGroup.InContentReview)
    }

    return groups
  }
)

export const getContentFormsInReview = createSelector(
  [getContentForms, getContentReviews, getContentId],
  (contentForms, reviewsList, contentId) => {
    const formsInReview = []
    if (reviewsList?.length < 1) {
      return formsInReview
    }

    contentForms.forEach((contentForm) => {
      const reviewWithForm = reviewsList.find((review) =>
        review.FormsIds.some((formId) => formId === contentForm.FormId)
      )

      if (!reviewWithForm) {
        return
      }

      const isMergeReview = reviewWithForm.Type === ContentSharingType.MergeForReview
      const isMergedForReviewToCurrentContent = isMergeReview && reviewWithForm.TargetContentId === contentId

      if (isMergedForReviewToCurrentContent || reviewWithForm.ReviewStatus !== ContentReviewStatus.FeedbackSent) {
        formsInReview.push(contentForm)
      }
    })

    return formsInReview
  }
)

export const getAvailableContentForms = createSelector(
  [getContentForms, getContentFormsInReview],
  (contentForms, formsInReview) => {
    return contentForms.filter((cf) => !formsInReview.some((f) => cf.Id === f.Id))
  }
)

export const getAnswerStatusColor = (status) => {
  switch (status) {
    case AnswerStatuses.Pending:
      return tabeebTheme.palette.background.formAnswerStatus.submitted
    case AnswerStatuses.Approve:
    case AnswerStatuses.ApprovedByAI:
    case AnswerStatuses.ApprovedByReviewer:
      return tabeebTheme.palette.background.formAnswerStatus.approved
    case AnswerStatuses.Reject:
    case AnswerStatuses.RejectedByAI:
    case AnswerStatuses.RejectedByReviewer:
      return tabeebTheme.palette.background.formAnswerStatus.rejected
    default:
      return tabeebTheme.palette.background.formAnswerStatus.noAction
  }
}

export const getAnswerStatusIcon = (status) => {
  switch (status) {
    case AnswerStatuses.Approve:
    case AnswerStatuses.ApprovedByAI:
    case AnswerStatuses.ApprovedByReviewer:
      return Approved
    case AnswerStatuses.Reject:
    case AnswerStatuses.RejectedByAI:
    case AnswerStatuses.RejectedByReviewer:
      return Rejected
    default:
      return Submitted
  }
}

export const getAnswerStatus = (answer, isReviewer, review) => {
  let status

  if (!answer || !answer.Id) {
    status = AnswerStatuses.NoAction
  } else if (isReviewer && review) {
    const editedAnswer = review.EditedFormsAnswers.find((a) => a.FormAnswerId === answer.Id)

    if (editedAnswer) {
      if (editedAnswer.IsDeleted) {
        if (editedAnswer.ApproverId !== answer.ApproverId) {
          status = AnswerStatuses.Pending
        } else {
          status = editedAnswer.FormAnswerStatus
          answer.ApproverId = editedAnswer.ApproverId
          answer.ApproverName = editedAnswer.ApproverName
        }
      } else {
        status = editedAnswer.FormAnswerStatus
        answer.ApproverId = editedAnswer.ApproverId
        answer.ApproverName = editedAnswer.ApproverName
      }
    } else {
      status = AnswerStatuses.Pending
    }
  } else status = answer.FormAnswerStatus

  return status
}

const getSelectedAnswerStatusValue = (status, answer, currentUserId) => {
  let approverName

  switch (status) {
    case AnswerStatuses.Pending:
      return 'Submitted'

    case AnswerStatuses.Approve:
    case AnswerStatuses.ApprovedByReviewer:
      approverName = answer.ApproverId === currentUserId ? 'you' : answer.ApproverName

      return answer.ApproverName ? `Approved by ${approverName}` : 'Approved'

    case AnswerStatuses.ApprovedByAI:
      return 'Approved By AI'

    case AnswerStatuses.Reject:
    case AnswerStatuses.RejectedByReviewer:
      approverName = answer.ApproverId === currentUserId ? 'you' : answer.ApproverName

      return answer.ApproverName ? `Rejected by ${approverName}` : 'Rejected'

    case AnswerStatuses.RejectedByAI:
      return 'Rejected By AI'

    default:
      return 'No Action'
  }
}

export const makeGetSelectedAnswerStatus = () =>
  createSelector(
    [
      getCurrentUserId,
      getIsCurrentUserReviewer,
      getCurrentUserReview,
      getHasApprovePermission,
      getCurrentFormSelectedRecord,
      (_, { answer }) => answer,
    ],
    (currentUserId, isReviewer, review, hasApprovePermission, currentFormSelectedRecord, answer) => {
      const answerStatus = getAnswerStatus(answer, isReviewer, review)
      const isFormRecordSelected = Boolean(currentFormSelectedRecord)
      let isDisabled = answerStatus === AnswerStatuses.NoAction || !hasApprovePermission || isFormRecordSelected

      if (answer && ((isReviewer && review?.Type === ContentSharingType.ForReview) || review)) {
        if (review.ReviewStatus !== ContentReviewStatus.Active) {
          isDisabled = true
        }
      }

      const selectedStatus = {
        value: answerStatus,
        displayValue: getSelectedAnswerStatusValue(answerStatus, answer, currentUserId),
        color: getAnswerStatusColor(answerStatus),
        icon: getAnswerStatusIcon(answerStatus),
        isDisabled,
      }

      return selectedStatus
    }
  )

export const getSelectedAnswerStatus = makeGetSelectedAnswerStatus()

export const makeGetAnswerStatusDropdownOptions = () =>
  createSelector([(_, { answer }) => answer], (answer) => {
    const answerStatus = getAnswerStatus(answer)

    switch (answerStatus) {
      case AnswerStatuses.NoAction:
        return [
          {
            value: AnswerStatuses.NoAction,
            displayValue: 'No Action',
            color: getAnswerStatusColor(AnswerStatuses.NoAction),
            icon: getAnswerStatusIcon(AnswerStatuses.NoAction),
          },
        ]

      default:
        return [
          {
            value: AnswerStatuses.Approve,
            displayValue: 'Approve',
            color: getAnswerStatusColor(AnswerStatuses.Approve),
            icon: getAnswerStatusIcon(AnswerStatuses.Approve),
          },
          {
            value: AnswerStatuses.Reject,
            displayValue: 'Reject',
            color: getAnswerStatusColor(AnswerStatuses.Reject),
            icon: getAnswerStatusIcon(AnswerStatuses.Reject),
          },
        ]
    }
  })

export const getAnswerStatusDropdownOptions = makeGetAnswerStatusDropdownOptions()

export const makeGetAnswerStatusCircleIconStyles = () =>
  createSelector(
    [(_, answerStatus) => getAnswerStatusColor(answerStatus), (_, answerStatus) => answerStatus],
    (color, status) => {
      switch (status) {
        case AnswerStatuses.Pending:
        case AnswerStatuses.Approve:
        case AnswerStatuses.ApprovedByReviewer:
        case AnswerStatuses.Reject:
        case AnswerStatuses.RejectedByReviewer:
          return {
            background: color,
          }
        case AnswerStatuses.ApprovedByAI:
        case AnswerStatuses.RejectedByAI:
          return {
            background: `repeating-linear-gradient(-45deg, transparent 0 10% , ${color} 10% 23%)`,
          }
        default:
          return {
            border: `1px solid ${color}`,
          }
      }
    }
  )

export const getAnswerStatusCircleIconStyles = makeGetAnswerStatusCircleIconStyles()

///

export const getIsFormActionDisabled = createSelector(
  [getIsCurrentUserViewer, getCurrentForm, (_, { actionId }) => actionId],
  (isViewer, currentForm) => {
    const isFormRecordSelected = currentForm && !!currentForm.selectedRecord

    return isViewer || isFormRecordSelected
  }
)

export const getIsCurrentFormViewDisabled = createSelector(
  [
    getDisplayAnswersMode,
    getIsAnswerInProgress,
    getSelectedUserId,
    getCurrentUserId,
    getIsCurrentUserViewer,
    getCurrentForm,
  ],
  (displayAnswersMode, answerInProgress, selectedUserId, currentUserId, isViewer, currentForm) => {
    const isFormRecordSelected = currentForm && !!currentForm.selectedRecord

    return (
      isViewer ||
      displayAnswersMode === DisplayAnswersMode.AllLatest ||
      selectedUserId !== currentUserId ||
      answerInProgress ||
      isFormRecordSelected
    )
  }
)

export const getIsFormControlDisabled = createSelector(
  [getIsCurrentFormViewDisabled, getHasAnswerPermission, (_, props) => props],
  (isViewDisabled, hasPermission, props) => {
    const { control, isEditingAnswer } = props

    const isTemperatureQuestion = control.Type === FormControlType.BodyTemperature

    return (isViewDisabled && !isEditingAnswer) || isTemperatureQuestion || !hasPermission
  }
)

export const getIsFormControlOnReviewInOriginalContent = createSelector(
  [getReviewsList, getIsCurrentUserReviewer, getSelectedTabs, getContentId, (_, control) => control],
  (reviewsList, isReviewer, selectedTabs, contentId, control) => {
    if (isReviewer) {
      return false
    }

    const review = reviewsList.find((r) =>
      r.FormAnswers.some((fa) => fa.FormControlId === control.Id && formTabsIdsEquals(fa.FormTabsIds, selectedTabs))
    )

    if (!review || review.TargetContentId === contentId) {
      return false
    }

    return review.ReviewStatus === ContentReviewStatus.Active || review.ReviewStatus === ContentReviewStatus.Pending
  }
)

export const getIsContentFormOnReview = createSelector(
  [getIsCurrentUserReviewer, getMergeReviewWithCurrentForm],
  (isReviewer, targetContentReview) => {
    if (isReviewer) {
      return true
    }

    return !!targetContentReview
  }
)

export const getIsFormUsersFilterVisible = createSelector(
  [getIsCurrentUserReviewer, getIsContentFormOnReview],
  (isReviewer, isOnReview) => {
    if (isReviewer) {
      return false
    }

    return !isOnReview
  }
)

export const getIsEditAnswerIconVisible = createSelector(
  [getDisplayAnswersMode, getIsCurrentUserViewer, getIsContentFormOnReview, (_, { control }) => control],
  (displayAnswersMode, isViewer, isOnReview, control) => {
    const isTemperatureQuestion = control.Type === FormControlType.BodyTemperature

    return !isViewer && displayAnswersMode === DisplayAnswersMode.AllLatest && !isOnReview && !isTemperatureQuestion
  }
)

export const getIsDeleteAnswerIconVisible = createSelector(
  [
    getDisplayAnswersMode,
    getHasDeleteAllAnswersPermission,
    getSelectedUserId,
    getCurrentUserId,
    getCurrentForm,
    getIsCurrentUserViewer,
    getIsContentFormOnReview,
    (_, { control }) => control,
  ],
  (
    displayAnswersMode,
    canDeleteAllAnswers,
    selectedUserId,
    currentUserId,
    currentForm,
    isViewer,
    isOnReview,
    control
  ) => {
    const answers = currentForm.answers ? currentForm.answers.filter((item) => item.FormControlId === control.Id) : []
    const isAnswersOwner = currentUserId === selectedUserId && !isViewer
    const isAllLatest = displayAnswersMode === DisplayAnswersMode.AllLatest
    const isFormRecordSelected = currentForm && !!currentForm.selectedRecord

    return (
      (canDeleteAllAnswers || (!isAllLatest && isAnswersOwner)) &&
      answers.length > 0 &&
      !isFormRecordSelected &&
      !isOnReview
    )
  }
)

export const getIsExportFormToPdfOptionVisible = createSelector(
  [getIsExportFormToPdfButtonEnabled, getIsCurrentUserPresenter, getIsCallStarted, getHasExportFormPdfPermission],
  (isExportFormToPdfButtonEnabled, isPresenter, isCallStarted, hasExportToPdfPermission) => {
    return isExportFormToPdfButtonEnabled && (isPresenter || (!isCallStarted && hasExportToPdfPermission))
  }
)

export const getCurrentFormControls = createSelector(
  [getIsCurrentUserReviewer, getCurrentUserReview, getCurrentForm, getFormAnswerStatusFilter],
  (isReviewer, review, currentForm, answerStatusFilter) => {
    if (!currentForm || !currentForm.Controls) {
      return null
    }

    let controlsList = []
    const answers = currentForm.answers || []
    answers.forEach((answer) => {
      answer.FormAnswerStatus = getAnswerStatus(answer, isReviewer, review)
    })

    if (answerStatusFilter.length === Object.keys(AnswerStatusFilterOptions).length) {
      controlsList = currentForm.Controls
    } else if (answerStatusFilter.length === 0) {
      currentForm.Controls.forEach((control) => {
        if (control.FormItemType === FormItemType.Counter || control.FormItemType === FormItemType.Action) {
          controlsList.push(control)
        }
      })
    } else {
      currentForm.Controls.forEach((control) => {
        if (control.FormItemType === FormItemType.Counter || control.FormItemType === FormItemType.Action) {
          controlsList.push(control)
        } else if (answers.length === 0) {
          if (answerStatusFilter.includes(AnswerStatusFilterOptions.NoAction)) {
            controlsList = currentForm.Controls
          }
        } else {
          const controlAnswer = answers.find((answer) => answer.FormControlId === control.Id)

          if (controlAnswer) {
            switch (controlAnswer.FormAnswerStatus) {
              case AnswerStatuses.Pending:
                if (answerStatusFilter.includes(AnswerStatusFilterOptions.Submitted)) {
                  controlsList.push(control)
                }
                break
              case AnswerStatuses.Approve:
              case AnswerStatuses.ApprovedByReviewer:
                if (answerStatusFilter.includes(AnswerStatusFilterOptions.Approved)) {
                  controlsList.push(control)
                }
                break
              case AnswerStatuses.Reject:
              case AnswerStatuses.RejectedByReviewer:
                if (answerStatusFilter.includes(AnswerStatusFilterOptions.Rejected)) {
                  controlsList.push(control)
                }
                break
              case AnswerStatuses.RejectedByAI:
                if (answerStatusFilter.includes(AnswerStatusFilterOptions.RejectedByAI)) {
                  controlsList.push(control)
                }
                break
              case AnswerStatuses.ApprovedByAI:
                if (answerStatusFilter.includes(AnswerStatusFilterOptions.ApprovedByAI)) {
                  controlsList.push(control)
                }
                break
              default:
            }
          } else if (answerStatusFilter.includes(AnswerStatusFilterOptions.NoAction)) {
            controlsList.push(control)
          }
        }
      })
    }

    return controlsList
  }
)

export const getIsFormRecordAreaVisible = createSelector(
  [getDisplayAnswersMode, getSelectedUserId, getCurrentUserId, getIsFormRecordsEnabled],
  (displayAnswersMode, selectedUserId, currentUserId, isConfigEnabled) => {
    if (!isConfigEnabled) return false

    const isAllMyLatest = displayAnswersMode === DisplayAnswersMode.AllMyLatest
    const isByUser = displayAnswersMode === DisplayAnswersMode.ByUser

    return isAllMyLatest || (isByUser && selectedUserId !== currentUserId)
  }
)

export const getIsSaveFormRecordButtonVisible = createSelector(
  [getDisplayAnswersMode, getIsCurrentUserViewer, getIsFormRecordsEnabled],
  (displayAnswersMode, isViewer, isFormRecordsEnabled) => {
    const isAllMyLatest = displayAnswersMode === DisplayAnswersMode.AllMyLatest
    return isAllMyLatest && !isViewer && isFormRecordsEnabled
  }
)

export const getFormRecordsList = createSelector(
  [getDisplayAnswersMode, getSelectedUserId, getCurrentUserId, getCurrentForm],
  (displayAnswersMode, selectedUserId, currentUserId, currentForm) => {
    const isAllMyLatest = displayAnswersMode === DisplayAnswersMode.AllMyLatest
    const targetUserId = isAllMyLatest ? currentUserId : selectedUserId
    const records = currentForm.Records || []

    const filteredRecords = records.filter((rec) => rec.UserId === targetUserId)

    return filteredRecords
  }
)

export const getIsDeleteFormRecordButtonVisible = createSelector(
  [getFormRecordsList, getCurrentUserId],
  (records, currentUserId) => {
    const recordsCreator = records.length > 0 ? records[0].UserId : null

    return recordsCreator === currentUserId
  }
)

export const getMappedFormControlAnswer = createSelector(
  [getCurrentForm, (_, { control }) => control],
  (currentForm, control) => {
    let answer = {}

    if (!currentForm || !currentForm.answers) return answer

    const existingAnswer = currentForm.answers.find((item) => item.FormControlId === control.Id)

    if (existingAnswer) {
      answer = { ...existingAnswer }
      answer.value = existingAnswer.Value
      answer.selectedOptionsIds = existingAnswer.SelectedOptions.map((o) => o.Id)
    }

    return answer
  }
)

export const getFormControlAnswer = createSelector(
  [getCurrentForm, (_, { control }) => control],
  (currentForm, control) => {
    if (!currentForm || !currentForm.answers) return null

    return currentForm.answers.find((item) => item.FormControlId === control.Id)
  }
)

export const getCurrentPageCounterValues = createSelector(
  [getSelectedGalleryItemId, getCounterValues],
  (currentPageId, counterValuesList) => {
    return counterValuesList.filter((counter) => counter.PageId === currentPageId)
  }
)

export const getCurrentFormCounter = createSelector(
  [getCurrentForm, (_, { counter }) => counter],
  (currentForm, counter) => {
    const { CounterLimit, IncrementValue, IncrementPeriod } = counter
    const { ReattachedNumberOfTimes } = currentForm
    const counterItem = counter

    if (IncrementValue && ReattachedNumberOfTimes > 0) {
      counterItem.calculatedCounterLimit = calculateValue(
        CounterLimit,
        IncrementValue,
        IncrementPeriod,
        ReattachedNumberOfTimes
      )
    } else {
      counterItem.calculatedCounterLimit = CounterLimit
    }

    return counterItem
  }
)

export const getCounterPerSessionStats = createSelector([getCounterValues], (counterValuesList) => {
  const countPerSessionObject = {}

  counterValuesList.forEach((counter) => {
    const value = counter.ObjectsCount

    if (countPerSessionObject[counter.AIObjectId]) {
      countPerSessionObject[counter.AIObjectId].value += value

      if (
        counter.UniqueObjectId &&
        !countPerSessionObject[counter.AIObjectId].objects.some((v) => v === counter.UniqueObjectId)
      ) {
        countPerSessionObject[counter.AIObjectId].objects.push(counter.UniqueObjectId)
      }
    } else {
      countPerSessionObject[counter.AIObjectId] = {
        value,
        objects: [],
      }
      if (counter.UniqueObjectId) {
        countPerSessionObject[counter.AIObjectId].objects.push(counter.UniqueObjectId)
      }
    }
  })

  return countPerSessionObject
})

export const getFormCounterProps = createSelector(
  [getShowMenuAI, getDisplayAnswersMode, getCurrentPageCounterValues, getCurrentFormCounter, getCounterPerSessionStats],
  (isAiEnabled, displayAnswersMode, currentPageCounterValuesList, counter, countPerSessionObject) => {
    let countPerPage = 0
    let countPerSession = 0

    if (countPerSessionObject[counter.AIObjectId]) {
      if (counter.CountAIObjects) {
        countPerSession = countPerSessionObject[counter.AIObjectId].objects.length

        countPerPage = currentPageCounterValuesList.filter(
          (v) => v.AIObjectId === counter.AIObjectId && v.UniqueObjectId
        ).length
      } else {
        countPerSession = countPerSessionObject[counter.AIObjectId].value

        countPerPage = currentPageCounterValuesList
          .filter((v) => v.AIObjectId === counter.AIObjectId)
          .reduce((sum, curr) => sum + curr.ObjectsCount, 0)
      }
    }

    const { Label, calculatedCounterLimit } = counter
    const showPerPageCount = displayAnswersMode === DisplayAnswersMode.ByUser
    const disabled = !isAiEnabled
    const color = counter.calculatedCounterLimit && countPerSession > counter.calculatedCounterLimit && 'red'
    const text = `${countPerSession} ${calculatedCounterLimit ? ` out of ${calculatedCounterLimit}` : ''}`

    const viewModel = {
      label: Label,
      text,
      countPerPage,
      showPerPageCount,
      disabled,
      color,
    }

    return viewModel
  }
)

export const getIsSignFormButtonVisible = createSelector(
  [getCurrentForm, getIsSaveFormRecordButtonVisible, getCurrentFormSelectedRecord, getIsFormSignatureEnabledInTenant],
  (currentForm, isFormRecordBtnVisible, selectedRecordId, isConfigEnabled) => {
    return currentForm.IsSignatureEnabled && isFormRecordBtnVisible && !selectedRecordId && isConfigEnabled
  }
)

export const getCurrentFormRecordSignature = createSelector([getCurrentForm], (currentForm) => {
  const selectedRecordId = currentForm?.selectedRecord
  if (!currentForm || !selectedRecordId) {
    return null
  }

  const selectedRecord = currentForm.Records.find((r) => r.Id === selectedRecordId)
  if (!selectedRecord || selectedRecord.Type !== ContentFormRecordType.UserSignature) {
    return null
  }

  return selectedRecord.Signature
})
