import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = {}

export default handleActions(
  {
    [rawActions.setTenantFormAnswer](state, action) {
      return {
        ...state,
        [action.payload.formControlId]: action.payload.value,
      }
    },
    [rawActions.removeTenantFormAnswersForControl](state, action) {
      const { [action.payload]: value, ...rest } = state
      return rest
    },
    [rawActions.setTenantFormMultipleAnswer](state, action) {
      return {
        ...state,
        [action.payload.formControlId]: {
          ...state[action.payload.formControlId],
          [action.payload.optionOrder]: {
            optionId: action.payload.optionId,
            value: action.payload.value,
          },
        },
      }
    },
    [rawActions.resetTenantFormAnswers](state, action) {
      return defaultState
    },
  },
  defaultState
)
