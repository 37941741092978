import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import routes from '@tabeeb/routes'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import NavbarTab from '../NavbarTab'

const Tracking = ({ display, ...rest }) => {
  const props = {
    item: {
      label: Tracking.label,
      to: Tracking.to,
    },
    display,
    ...rest,
  }

  return <NavbarTab {...props} />
}

Tracking.propTypes = {
  display: PropTypes.bool.isRequired,
}

Tracking.to = routes.tracking
Tracking.label = 'Tracking'

function mapStateToProps(state) {
  return {
    display: appConfigStateSelectors.getIsTrackingMapEnabled(state),
  }
}

export default connect(mapStateToProps)(Tracking)
