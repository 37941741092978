import { combineReducers } from 'redux'

import isTimelineOpen from './isTimelineOpen'
import isProcessing from './isProcessing'
import imagesList from './imagesList'
import isSettingsDialogOpen from './isSettingsDialogOpen'
import contentGigapixels from './contentGigapixels'
import areGigapixelsLoading from './areGigapixelsLoading'

export default combineReducers({
  isTimelineOpen,
  isProcessing,
  imagesList,
  isSettingsDialogOpen,
  contentGigapixels,
  areGigapixelsLoading,
})
