import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getTrainingMaterial } from '../actions'

export default ({ id, onError }) => {
  const payload = useMemo(() => ({ id }), [id])

  const {
    loading,
    response: trainingMaterial,
    onMutated,
  } = useApiRequest({
    request: getTrainingMaterial.request,
    enabled: !!id,
    payload,
    onError,
  })

  return { loading, trainingMaterial, onReload: onMutated }
}
