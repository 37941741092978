import { put, takeLatest, all } from 'redux-saga/effects'

import * as actions from '../actions'

function* onGetProjectSuccess({ response: { data: project } }) {
  yield put(actions.setSelectedProject(project))
}

function* getProject() {
  yield all([takeLatest(actions.getProject.success, onGetProjectSuccess)])
}

export default getProject
