import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Menu, MenuItem } from '@mui/material'

import { UserRole } from '@tabeeb/enums'
import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import { getIsInviteSettingsEnabled } from '@tabeeb/modules/appConfigState/selectors'

import EditInviteTimeDialog from '../EditInviteTimeDialog'
import EditInvitePasswordDialog from '../EditInvitePasswordDialog'

const InviteMenu = ({
  anchorEl,
  open,
  onClose,
  invite,
  onCancelInvite,
  onCopyInviteLink,
  onResendInvite,
  onRevokeQuickAccessLink,
  onSetQuickAccessLinkPassword,
  onUpdateInviteTime,
}) => {
  const isInviteSettingsEnabled = useSelector(getIsInviteSettingsEnabled)
  const [editTimeDialogOpen, onEditTimeDialogOpen, onEditTimeDialogClose] = useDialogState()
  const [editPasswordDialogOpen, onEditPasswordDialogOpen, onEditPasswordDialogClose] = useDialogState()

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onClose}
        onClick={onClose}
      >
        {!invite?.Accepted && <MenuItem onClick={() => onCancelInvite(invite.Id)}>Cancel</MenuItem>}
        {isInviteSettingsEnabled && <MenuItem onClick={onEditTimeDialogOpen}>Edit time</MenuItem>}
        {isInviteSettingsEnabled && !invite?.IdentityProtected && (
          <MenuItem onClick={onEditPasswordDialogOpen}>Add password</MenuItem>
        )}
        {isInviteSettingsEnabled && invite?.IdentityProtected && (
          <MenuItem onClick={() => onRevokeQuickAccessLink(invite.Id)}>Revoke quick link</MenuItem>
        )}
        {isInviteSettingsEnabled && invite?.IdentityProtected && (
          <MenuItem onClick={onEditPasswordDialogOpen}>Update password</MenuItem>
        )}
        {!invite?.Accepted && !invite?.Ignored && !invite?.IdentityProtected && (
          <MenuItem onClick={() => onResendInvite(invite.Id)}>Resend</MenuItem>
        )}
        <MenuItem onClick={() => onCopyInviteLink(invite.Id)}>Copy link</MenuItem>
      </Menu>

      <EditInviteTimeDialog
        invite={invite}
        open={editTimeDialogOpen}
        onClose={onEditTimeDialogClose}
        onSave={onUpdateInviteTime}
      />
      <EditInvitePasswordDialog
        invite={invite}
        open={editPasswordDialogOpen}
        onClose={onEditPasswordDialogClose}
        onSave={onSetQuickAccessLinkPassword}
      />
    </>
  )
}

InviteMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  invite: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Accepted: PropTypes.bool.isRequired,
    Ignored: PropTypes.bool.isRequired,
    IdentityProtected: PropTypes.bool.isRequired,
    Recipient: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
    RecipientEmail: PropTypes.string,
    Role: PropTypes.oneOf(Object.values(UserRole)).isRequired,
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
    CreatedOn: PropTypes.string.isRequired,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancelInvite: PropTypes.func.isRequired,
  onCopyInviteLink: PropTypes.func.isRequired,
  onResendInvite: PropTypes.func.isRequired,
  onRevokeQuickAccessLink: PropTypes.func.isRequired,
  onSetQuickAccessLinkPassword: PropTypes.func.isRequired,
  onUpdateInviteTime: PropTypes.func.isRequired,
}

export default memo(InviteMenu)
