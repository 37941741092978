import { handleActions } from 'redux-actions'
import * as customVideoActions from '../actions'

export default handleActions(
  {
    [customVideoActions.openRecipientsDialog](state, action) {
      return true
    },
    [customVideoActions.closeRecipientsDialog](state, action) {
      return false
    },
    [customVideoActions.resetState](state, action) {
      return false
    },
  },
  false
)
