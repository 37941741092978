import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(rawActions.attachAiObjectToTagRequest, rawActions.detachAiObjectFromTagRequest)](
      state,
      { payload }
    ) {
      return true
    },
    [combineActions(
      rawActions.attachAiObjectToTagSuccess,
      rawActions.attachAiObjectToTagFailed,
      rawActions.detachAiObjectFromTagFailed,
      rawActions.detachAiObjectFromTagSuccess
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
