import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { MenuItem, Select } from '@material-ui/core'

import { UserRole } from '@tabeeb/enums'
import { getIsSessionGuestRoleEnabled, getSessionRoleDisplayName } from '@tabeeb/modules/appConfigState/selectors'

const UserRoleSelect = ({ role: selectedRole, onChange }) => {
  const roles = useSelector((state) => {
    const availableRoles = [UserRole.User, UserRole.Viewer]

    const isSessionGuestRoleEnabled = getIsSessionGuestRoleEnabled(state)
    if (isSessionGuestRoleEnabled) {
      availableRoles.push(UserRole.SessionGuest)
    }

    return availableRoles.map((role) => ({
      role,
      displayName: getSessionRoleDisplayName(state, { Role: role }),
    }))
  })

  return (
    <Select
      value={selectedRole}
      onChange={onChange}
      disableUnderline
      SelectDisplayProps={{ style: { paddingBottom: 3, maxWidth: 200 } }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
        transformOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      }}
    >
      {roles.map(({ role, displayName }) => (
        <MenuItem key={role} value={role}>
          {displayName}
        </MenuItem>
      ))}
    </Select>
  )
}

UserRoleSelect.propTypes = {
  role: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memo(UserRoleSelect)
