import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import CustomVideoOptionsMenu from '../CustomVideoOptionsMenu'
import { getIsCustomVideoOpen } from '../../../customVideo/selectors'

import './styles.less'

const CustomVideoOptionsButton = ({ isCustomVideoOpen, isPagesForTimeLineLoadInProgress }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const menuProps = { anchorEl, setAnchorEl }

  return isCustomVideoOpen ? (
    <>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className='gallery-options-button'
        variant='contained'
        disabled={isPagesForTimeLineLoadInProgress}
      >
        {isPagesForTimeLineLoadInProgress ? 'loading...' : 'add to video'}
      </Button>
      <CustomVideoOptionsMenu {...menuProps} />
    </>
  ) : null
}

CustomVideoOptionsButton.propTypes = {
  isCustomVideoOpen: PropTypes.bool.isRequired,
  isPagesForTimeLineLoadInProgress: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const {
    customVideo: { isPagesForTimeLineLoadInProgress },
  } = state

  return {
    isCustomVideoOpen: getIsCustomVideoOpen(state),
    isPagesForTimeLineLoadInProgress,
  }
}

export default connect(mapStateToProps, null)(memo(CustomVideoOptionsButton))
