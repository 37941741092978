import { combineReducers } from 'redux'

import policies from './policies'
import isOpen from './isOpen'
import isFailed from './isFailed'
import isLoaded from './isLoaded'
import isLoading from './isLoading'
import isCreationInProgress from './isCreationInProgress'
import isAcceptanceInProgress from './isAcceptanceInProgress'

export default combineReducers({
  policies,
  isOpen,
  isFailed,
  isLoaded,
  isLoading,
  isCreationInProgress,
  isAcceptanceInProgress,
})
