import { all } from 'redux-saga/effects'

import addStructureModel from './addStructureModel'
import getNotLoadedPages from './getNotLoadedPages'
import resetState from './resetState'

function* structureModel() {
  yield all([addStructureModel(), getNotLoadedPages(), resetState()])
}

export default structureModel
