import { handleActions, combineActions } from 'redux-actions'

import * as playerToolbarActions from '../actions'
import * as rawActions from '../actions/drawing'

const defaultState = false

export default handleActions(
  {
    [combineActions(rawActions.onHideAnnotations)](state, action) {
      return true
    },
    [combineActions(rawActions.onShowAnnotations, rawActions.onEnableDrawing, rawActions.onEnableSelection)](
      state,
      action
    ) {
      return false
    },
    [playerToolbarActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
