import { handleActions } from 'redux-actions'
import { closeImportSpreadsheetsDialog, openImportSpreadsheetsDialog } from '../actions'

const defaultState = false

const isImportSpreadsheetsDialogOpen = handleActions(
  {
    [openImportSpreadsheetsDialog]() {
      return true
    },
    [closeImportSpreadsheetsDialog]() {
      return false
    },
  },
  defaultState
)

export default isImportSpreadsheetsDialogOpen
