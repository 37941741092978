import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { closeSessionsPageDialog, openSessionsPageDialog } from '../actions'
import { getIsDialogOpen, getDialogPayload } from '../selectors/dialogs'

export default (dialogType) => {
  const dispatch = useDispatch()

  const open = useSelector((state) => getIsDialogOpen(state, { dialogType }))
  const payload = useSelector((state) => getDialogPayload(state, { dialogType }))

  const onOpen = useCallback(
    (dialogPayload) => {
      dispatch(openSessionsPageDialog({ dialogType, dialogPayload }))
    },
    [dialogType, dispatch]
  )

  const onClose = useCallback(() => {
    dispatch(closeSessionsPageDialog({ dialogType }))
  }, [dialogType, dispatch])

  return useMemo(
    () => ({
      open,
      payload,
      onOpen,
      onClose,
    }),
    [onClose, onOpen, open, payload]
  )
}
