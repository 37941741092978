import { memo, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Divider } from '@material-ui/core'

import Receiver from '../Receiver'
import ChatsList from '../ChatsList'
import ChatMessageList from '../ChatMessageList'
import TranslationLanguageSelector from '../TranslationLanguageSelector'
import ChatInput from '../../containers/ChatInputContainer'

import { ChatContext } from '../../contexts'
import { TranslationLanguage } from '../../constans'
import { getIsTopicSupportPrivateMessages, getIsTopicSupportTranslation } from '../../services/chat'

import useStyles from './styles'

const Chat = ({ topicId, topicType }) => {
  const classes = useStyles()

  const [language, setTranslationLanguage] = useState(TranslationLanguage.Original)
  const [receiverId, setReceiverId] = useState(null)

  const supportsPrivateMessages = getIsTopicSupportPrivateMessages(topicType)
  const supportsTranslation = getIsTopicSupportTranslation(topicType)

  const contextValue = useMemo(
    () => ({
      topicType,
      topicId,
      userId: receiverId,
      setReceiverId,
      language,
      setTranslationLanguage,
    }),
    [language, receiverId, topicId, topicType]
  )

  return (
    <ChatContext.Provider value={contextValue}>
      <section className={classes.container}>
        {supportsPrivateMessages && (
          <>
            <ChatsList />
            <Divider />
          </>
        )}
        {supportsTranslation && (
          <>
            <TranslationLanguageSelector />
            <Divider />
          </>
        )}
        <ChatMessageList />
        <Divider />
        {supportsPrivateMessages && <Receiver />}
        <ChatInput />
      </section>
    </ChatContext.Provider>
  )
}

Chat.propTypes = {
  topicId: PropTypes.number.isRequired,
  topicType: PropTypes.number.isRequired,
}

export default memo(Chat)
