import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  height: 596,
  width: 1006,
}

export default handleActions(
  {
    [rawActions.setContainerSize](state, action) {
      return { ...action.payload }
    },
    [rawActions.resetContentViewerState](state, action) {
      return defaultState
    },
  },
  defaultState
)
