import { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Add, Delete, RefreshOutlined } from '@mui/icons-material'
import { Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroller'
import { FetchStatus } from '@tabeeb/enums'
import usePowerBIReportDependentModules from '@tabeeb/modules/powerBIReports/hooks/usePowerBIReportDependentModules'
import {
  deletePowerBIReportDependentModule,
  openAddPowerBIReportDependantModuleModal,
} from '@tabeeb/modules/powerBIReports/actions'
import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import { AddPowerBIReportDependentModuleModal } from '../../Modals'
import { DeleteReportDependentModuleConfirmationDialog } from '../../Dialogs'

const PowerBIReportDependentModules = ({ report }) => {
  const dispatch = useDispatch()
  const containerRef = useRef()
  const [selectedModuleId, setSelectedModuleId] = useState(0)

  const { fetchStatus, loading, dependentModules, hasMore, onLoadMore, onReload } = usePowerBIReportDependentModules(
    report.Id
  )

  const [
    deleteReportDependentModuleDialogOpen,
    onDeleteReportDependentModuleDialogOpen,
    onDeleteReportDependentModuleDialogClose,
  ] = useDialogState()

  const buttonsDisabled = useMemo(() => {
    return loading
  }, [loading])

  const refreshButtonIcon = useMemo(() => {
    return buttonsDisabled ? <CircularProgress size={18} /> : <RefreshOutlined color='primary' />
  }, [buttonsDisabled])

  const onAdd = useCallback(() => {
    dispatch(openAddPowerBIReportDependantModuleModal(report.Id))
  }, [dispatch, report.Id])

  const onDelete = useCallback(() => {
    dispatch(deletePowerBIReportDependentModule.request(selectedModuleId))
    onDeleteReportDependentModuleDialogClose()
  }, [dispatch, onDeleteReportDependentModuleDialogClose, selectedModuleId])

  const openDeleteConfirmationDialog = useCallback(
    (id) => {
      onDeleteReportDependentModuleDialogOpen()
      setSelectedModuleId(id)
    },
    [onDeleteReportDependentModuleDialogOpen]
  )

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid container item xs={12} spacing={1} justifyContent='flex-end'>
        <Grid item>
          <Button variant='outlined' disabled={buttonsDisabled} startIcon={refreshButtonIcon} onClick={onReload}>
            Reload
          </Button>
        </Grid>
        <Grid item>
          <Button variant='outlined' disabled={buttonsDisabled} startIcon={<Add color='primary' />} onClick={onAdd}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} ref={containerRef}>
        <InfiniteScroll
          useWindow={false}
          loadMore={onLoadMore}
          hasMore={hasMore}
          getScrollParent={() => containerRef.current}
          style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}
        >
          {fetchStatus === FetchStatus.Failed && (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center' }}>Failed to load dependent reports.</Typography>
            </Grid>
          )}
          {fetchStatus === FetchStatus.Loaded && dependentModules.length === 0 && (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center' }}>Dependent reports list is empty.</Typography>
            </Grid>
          )}
          {dependentModules.map((module) => {
            return (
              <Grid key={module.Id} item xs={6} container alignItems='center'>
                <Grid container item xs={0.3} height='100%'>
                  <Divider orientation='vertical' flexItem />
                </Grid>
                <Grid item xs={5} md={7} lg={8.7}>
                  <Typography>{module.Name}</Typography>
                </Grid>
                <Grid item xs={6.7} md={4.7} lg={3}>
                  <Button
                    variant='outlined'
                    startIcon={<Delete color='error' />}
                    onClick={() => openDeleteConfirmationDialog(module.Id)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            )
          })}
        </InfiniteScroll>
      </Grid>
      <AddPowerBIReportDependentModuleModal />
      <DeleteReportDependentModuleConfirmationDialog
        open={deleteReportDependentModuleDialogOpen}
        onClose={onDeleteReportDependentModuleDialogClose}
        onSubmit={onDelete}
      />
    </Grid>
  )
}

PowerBIReportDependentModules.propTypes = {
  report: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    SecurityModel: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default PowerBIReportDependentModules
