import { createSelector } from 'reselect'
import { SortingColumn } from '../enums'

const getSortingState = (state) => state.sessionsPage.sorting

export const getSortingColumn = (state) => getSortingState(state).column
export const getSortingOrder = (state) => getSortingState(state).order

export const getEnabledSortingColumns = (state) => state.sessionsPage.configuration.sortings
export const getIsSortingByColumnEnabled = createSelector(
  [getEnabledSortingColumns, (_, sorting) => sorting],
  (sortings, sorting) => {
    return sortings.includes(sorting)
  }
)

export const getIsSortingByDateEnabled = (state) => getIsSortingByColumnEnabled(state, SortingColumn.Date)
export const getIsSortingByNameEnabled = (state) => getIsSortingByColumnEnabled(state, SortingColumn.Name)
export const getIsSortingByLastActivityEnabled = (state) =>
  getIsSortingByColumnEnabled(state, SortingColumn.LastActivity)
export const getIsSortingByOwnerEnabled = (state) => getIsSortingByColumnEnabled(state, SortingColumn.Owner)
