import { handleActions } from 'redux-actions'

import * as playerToolbarActions from '../actions'
import * as zoomingActions from '../actions/zooming'
import { InitialZoomValue, ZoomInterval } from '../common/zooming'

const defaultValue = InitialZoomValue

export default handleActions(
  {
    [zoomingActions.onZoomIn](state) {
      return state + ZoomInterval
    },
    [zoomingActions.onZoomOut](state) {
      return state - ZoomInterval
    },
    [zoomingActions.onZoomValueChecked](state, { payload }) {
      return payload
    },
    [playerToolbarActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
