import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const initialState = {
  list: [],
  totalCount: 0,
  isLoading: false,
  filters: {
    pageSize: 25,
    pageNumber: 1,
    search: '',
  },
}

export default handleActions(
  {
    [rawActions.getPowerBIReports.request]: (state, _action) => {
      return { ...state, isLoading: true }
    },
    [rawActions.getPowerBIReports.success]: (state, action) => {
      const { Reports, TotalCount } = action.response.data
      return { ...state, totalCount: TotalCount, list: Reports, isLoading: false }
    },
    [rawActions.getPowerBIReports.failed]: (state, _action) => {
      return { ...state, isLoading: false }
    },
    [rawActions.setPowerBIReportsFilters]: (state, action) => {
      return { ...state, filters: { ...state.filters, ...action.payload } }
    },
  },
  initialState
)
