import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

const ITEM_HEIGHT = 48

const ActionsMenu = ({ model, options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (onClick) => {
    onClick(model)
    handleClose()
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: '20ch',
          },
        }}
        getContentAnchorEl={null}
      >
        {options.map((option, index) =>
          option.renderCondition ? (
            option.renderCondition(model) && (
              <MenuItem
                key={`${model.Id}_${option.name}`}
                disabled={option.isDisabled}
                onClick={() => handleMenuItemClick(option.onClick)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText>{option.name}</ListItemText>
              </MenuItem>
            )
          ) : (
            <MenuItem
              key={`${model.Id}_${option.name}`}
              disabled={option.isDisabled}
              onClick={() => handleMenuItemClick(option.onClick)}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.name}</ListItemText>
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  )
}

ActionsMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      isDisabled: PropTypes.bool,
      icon: PropTypes.node.isRequired,
      renderCondition: PropTypes.func,
    })
  ).isRequired,
  model: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
}

export default ActionsMenu
