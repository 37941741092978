import { AnnotationSource } from '@tabeeb/enums'
import { PretrainedModelName, ObjectDetectorType } from '../enums'

export const isNumberOfEpochsVisible = (annotationSource, trainingSettings) => {
  switch (annotationSource) {
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
      switch (trainingSettings.PretrainedModelName) {
        case PretrainedModelName.VitSmall:
        case PretrainedModelName.ResnetRS50:
        case PretrainedModelName.SwinTransformersBase:
        case PretrainedModelName.ConvnextTiny:
          return true
        case PretrainedModelName.CLIP:
        case PretrainedModelName.DinoV2Base:
        case PretrainedModelName.SimCLR:
        default:
          return false
      }
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
    case AnnotationSource.ImageAndQuestionPairs:
    default:
      return true
  }
}

export const isLearningRateVisible = (annotationSource, trainingSettings) => {
  switch (annotationSource) {
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
      switch (trainingSettings.PretrainedModelName) {
        case PretrainedModelName.VitSmall:
        case PretrainedModelName.ResnetRS50:
        case PretrainedModelName.SwinTransformersBase:
        case PretrainedModelName.ConvnextTiny:
          return true
        case PretrainedModelName.CLIP:
        case PretrainedModelName.DinoV2Base:
        case PretrainedModelName.SimCLR:
        default:
          return false
      }
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
      switch (trainingSettings.ObjectDetector) {
        case ObjectDetectorType.YoloXSmall:
        case ObjectDetectorType.YoloXMedium:
        case ObjectDetectorType.YoloXLarge:
          return false
        default:
          return true
      }
    case AnnotationSource.ImageAndPolygonAnnotations:
    case AnnotationSource.ImageAndQuestionPairs:
    default:
      return true
  }
}

export const isEarlyStoppingMinDeltaVisible = (annotationSource, trainingSettings) => {
  return isNumberOfEpochsVisible(annotationSource, trainingSettings)
}

export const isEarlyStoppingPatienceVisible = (annotationSource, trainingSettings) => {
  return isNumberOfEpochsVisible(annotationSource, trainingSettings)
}

export const isClassifierTypeSettingVisible = (annotationSource, trainingSettings) => {
  switch (annotationSource) {
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
      switch (trainingSettings.PretrainedModelName) {
        case PretrainedModelName.CLIP:
        case PretrainedModelName.DinoV2Base:
        case PretrainedModelName.SimCLR:
          return true
        case PretrainedModelName.VitSmall:
        case PretrainedModelName.ResnetRS50:
        case PretrainedModelName.SwinTransformersBase:
        case PretrainedModelName.ConvnextTiny:
        default:
          return false
      }
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
    case AnnotationSource.ImageAndQuestionPairs:
    default:
      return false
  }
}
