import _ from 'lodash'

import { ANSWERS_TAKE } from '../constants'

export const defaultState = {
  open: false,
  loading: false,
  loaded: false,
  failed: false,
  list: [],
}

export const handleOpenAction = (state, action) => {
  return {
    ...state,
    ...action.payload,
    open: true,
  }
}

export const handleCloseAction = (state, action) => {
  return {
    ...state,
    open: false,
  }
}

export const handleRequestAction = (state, action) => {
  return {
    ...state,
    loading: true,
    loaded: false,
    failed: false,
  }
}

export const handleFailedAction = (state, action) => {
  return {
    ...state,
    loading: false,
    loaded: false,
    failed: true,
  }
}

export const handleSuccessAction = (state, action) => {
  const prevAnswers = state.list
  const newAnswers = action.response.data

  const answers = _.unionBy(prevAnswers, newAnswers, (answer) => answer.Id)

  const loaded = newAnswers.length < ANSWERS_TAKE

  return {
    ...state,
    loading: false,
    loaded,
    failed: false,
    list: answers,
  }
}

export const handleFiltersUpdateAction = (state, { payload: { filterType, value } }) => {
  return {
    ...state,
    list: [],
    filters: {
      ...state.filters,
      [filterType]: value,
    },
  }
}

export const handleStatusUpdate = (state, action) => {
  const [updatedAnswer] = action.payload

  const formAnswerId = _.min(updatedAnswer.FormAnswerIds || [updatedAnswer.FormAnswerId])

  const needsUpdate = state.list.some((answer) => answer.Id === formAnswerId)
  if (!needsUpdate) {
    return state
  }

  return {
    ...state,
    list: state.list.map((answer) =>
      answer.Id === formAnswerId
        ? {
            ...answer,
            ApproverId: updatedAnswer.ApproverId,
            ApproverName: updatedAnswer.ApproverName,
            FormAnswerStatus: updatedAnswer.FormAnswerStatus,
          }
        : answer
    ),
  }
}
