import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { get } from 'lodash'

const TextFieldComponent = ({ field, form, variant = 'standard', ...props }) => {
  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  return (
    <TextField
      error={!!error}
      variant={variant}
      fullWidth
      margin='none'
      helperText={error}
      {...field}
      value={field.value || ''}
      {...props}
    />
  )
}

TextFieldComponent.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined']),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
  }).isRequired,
}

export default TextFieldComponent
