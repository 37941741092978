import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getAnnouncement } from '../actions'

export default ({ id, onError }) => {
  const payload = useMemo(() => ({ id }), [id])

  const { loading, response: announcement } = useApiRequest({ request: getAnnouncement.request, payload, onError })

  return { loading, announcement }
}
