import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.getProjects.request](state, { payload }) {
      return true
    },
    [combineActions(rawActions.getProjects.success, rawActions.getProjects.failed)](state, { payload }) {
      return false
    },
  },
  defaultState
)
