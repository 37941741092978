import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    title: {
      ...theme.typography.body1,

      marginLeft: theme.spacing(),
      marginBottom: theme.spacing(),
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
  }
})
