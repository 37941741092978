import { handleActions } from 'redux-actions'

import { showUserList, hideUserList } from '../actions'

const defaultValue = false

export default handleActions(
  {
    [showUserList]() {
      return true
    },
    [hideUserList]() {
      return false
    },
  },
  defaultValue
)
