import { handleActions } from 'redux-actions'
import * as deviceStateActions from '../actions/deviceStateActions'

const defaultState = {
  geoLocation: {
    latitude: null,
    longitude: null,
  },
  batteryLevel: 0,
}

export default handleActions(
  {
    [deviceStateActions.onSetGeoLocation](state, action) {
      return { ...state, geoLocation: action.payload }
    },
    [deviceStateActions.onUpdateBatteryLevel](state, action) {
      return { ...state, batteryLevel: action.payload }
    },
  },
  defaultState
)
