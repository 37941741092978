import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getMe } from '@tabeeb/modules/account/selectors'

import * as healthActions from '@tabeeb/modules/healthData/actions'
import { getChartsHealthData, getChartsTypesAndNames } from '@tabeeb/modules/healthData/selectors'
import HealthStatistics from '../components/HealthStatistics'

const HealthStatisticsContainer = () => {
  const dispatch = useDispatch()
  const user = useSelector(getMe)
  const chartsHealthData = useSelector(getChartsHealthData)
  const chartsTypesAndNames = useSelector(getChartsTypesAndNames)

  useEffect(() => {
    dispatch(
      healthActions.onOpenHealthStatisticsPage({
        PatientId: user.Id,
        PatientTenantId: user.TenantId,
      })
    )
    return () => {
      dispatch(
        healthActions.onCloseHealthStatisticsPage({
          PatientId: user.Id,
          PatientTenantId: user.TenantId,
        })
      )
    }
  }, [dispatch, user.Id, user.TenantId])

  const onAddHealthDataChart = useCallback(
    (healthDataChartToAdd) => {
      dispatch(healthActions.addHealthDataChart(healthDataChartToAdd))
    },
    [dispatch]
  )

  const onRemoveHealthDataChart = useCallback(
    (healthDataChartToDelete) => {
      dispatch(healthActions.removeHealthDataChart(healthDataChartToDelete))
    },
    [dispatch]
  )

  const handleAddHealthData = useCallback(
    (data) => {
      dispatch(healthActions.onAddHealthData(data))
    },
    [dispatch]
  )

  const setChartInterval = useCallback(
    (setChartIntervalModel) => {
      dispatch(healthActions.setChartInterval(setChartIntervalModel))
    },
    [dispatch]
  )

  return (
    <HealthStatistics
      userSystemOfMeasure={Number(user.SystemOfMeasure)}
      handleAddHealthData={handleAddHealthData}
      onAddHealthDataChart={onAddHealthDataChart}
      userId={user.IdentityGuid}
      onRemoveHealthDataChart={onRemoveHealthDataChart}
      chartsHealthData={chartsHealthData}
      chartsTypesAndNames={chartsTypesAndNames}
      setChartInterval={setChartInterval}
    />
  )
}

export default HealthStatisticsContainer
