import PropTypes from 'prop-types'
import { memo } from 'react'
import Step from './step'
import { Container } from './styles'

const Content = ({ stages, currentStageIndex }) => {
  const currentStage = stages[currentStageIndex]
  if (!currentStage) {
    return null
  }

  return (
    <Container>
      {currentStage.steps.map((step, index) => (
        <Step step={step} stepIndex={index} currentStage={currentStage} key={step.id} />
      ))}
    </Container>
  )
}

Content.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          label: PropTypes.string.isRequired,
          isOptional: PropTypes.bool.isRequired,
          isCompleted: PropTypes.bool.isRequired,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
  currentStageIndex: PropTypes.number.isRequired,
}

export default memo(Content)
