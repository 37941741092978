import { handleActions } from 'redux-actions'
import { ApprovalRequestType } from '../../../Enums'

import * as rawActions from '../actions'

const defaultState = Object.values(ApprovalRequestType)

export default handleActions(
  {
    [rawActions.changeNotificationsFilters](state, action) {
      const filters = action.payload
      return filters
    },
    [rawActions.resetState](state, { payload }) {
      return defaultState
    },
  },
  defaultState
)
