import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Backdrop } from '@material-ui/core'
import { CircularProgress } from '@mui/material'

import oidcUserManager from '@tabeeb/services/oidcUserManager'
import domainService from '@tabeeb/services/domainService'

const SignOutRedirectCallback = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      await oidcUserManager.signoutRedirectCallback()
      await oidcUserManager.signinRedirect({
        extraQueryParams: { subdomain: domainService.subdomain },
      })
    })()
  }, [dispatch])

  return (
    <Backdrop style={{ zIndex: 9999, backgroundColor: 'inherit' }} open transitionDuration={0}>
      <CircularProgress color='info' size={40} thickness={6} />
    </Backdrop>
  )
}

export default SignOutRedirectCallback
