import { memo, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'

import { switchSelectedSessionsStorage, getTenantStorages } from '../../../actions'
import { getStorages } from '../../../selectors/storages'

const SwitchSessionStorageDialog = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const [selectedStorageId, setSelectedStorageId] = useState(0)

  const storages = useSelector(getStorages)

  useEffect(() => {
    if (storages.length <= 0) {
      dispatch(getTenantStorages.request())
      return
    }

    setSelectedStorageId(storages[0].Id)
  }, [storages])

  const onSelectStorage = useCallback((event) => {
    const storageId = event.target.value

    setSelectedStorageId(storageId)
  }, [])

  const onSubmit = useCallback(() => {
    dispatch(switchSelectedSessionsStorage({ storageId: selectedStorageId }))

    onClose()
  }, [dispatch, onClose, selectedStorageId])

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <DialogTitle>Switch storage</DialogTitle>
      <DialogContent>
        <FormControl size='small' variant='outlined' fullWidth>
          <InputLabel id='storage-select-label'>Storage region</InputLabel>
          <Select
            variant='outlined'
            label='Storage region'
            labelId='storage-select-label'
            value={selectedStorageId}
            onChange={onSelectStorage}
          >
            {storages.map((storage) => (
              <MenuItem key={storage.Id} value={storage.Id}>
                {storage.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button color='primary' variant='contained' onClick={onSubmit}>
          Switch
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SwitchSessionStorageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(SwitchSessionStorageDialog)
