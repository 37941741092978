import { combineReducers } from 'redux'

import generalFilter from './generalFilter'
import tenantContentsList from './tenantContentsList'
import currentMode from './currentMode'
import filtration from './filtration'
import pageNumber from './pageNumber'
import pageSize from './pageSize'
import sorting from './sorting'
import isContentLoading from './isContentLoading'
import lastUpdateTime from './lastUpdateTime'
import dateTimeFilter from './dateTimeFilter'
import dashboards from './dashboards'
import allTenantColumns from './allTenantColumns'
import columns from './columns'

export default combineReducers({
  allTenantColumns,
  columns,
  currentMode,
  dateTimeFilter,
  filtration,
  generalFilter,
  isContentLoading,
  pageNumber,
  pageSize,
  sorting,
  dashboards,
  tenantContentsList,
  lastUpdateTime,
})
