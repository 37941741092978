import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as rawActions from '../actions'
import GalleryNavbar from '../components/GalleryNavbar'

class GalleryNavbarContainer extends Component {
  state = {
    isDragOn: false,
  }

  handleChangeFolder = (id) => {
    const {
      selectedFolder,
      actions: { selectSessionFolder },
    } = this.props

    selectSessionFolder({ folderId: selectedFolder.ParentId })
  }

  _allowDrop = (e) => {
    e.preventDefault()
  }

  _onDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      isDragOn: false,
    })

    const {
      actions: { updatePageSessionFolderRequest },
      selection,
    } = this.props

    const data = {
      pageIds: selection,
      folderId: null,
    }

    updatePageSessionFolderRequest(data)
  }

  _onDragEnter = (e) => {
    this.setState({
      isDragOn: true,
    })
  }

  _onDragLeave = (e) => {
    this.setState({
      isDragOn: false,
    })
  }

  render() {
    const { selectedFolder, selectedFolderId } = this.props
    const { isDragOn } = this.state

    const props = {
      path: selectedFolderId && selectedFolder ? selectedFolder.Name : null,
      handleChangeFolder: this.handleChangeFolder,
      isDragOn,

      allowDrop: this._allowDrop,
      onDrop: this._onDrop,
      onDragEnter: this._onDragEnter,
      onDragLeave: this._onDragLeave,
    }

    return selectedFolderId && selectedFolder ? <GalleryNavbar {...props} /> : null
  }
}

GalleryNavbarContainer.propTypes = {
  selectedFolderId: PropTypes.number,
  selectedFolder: PropTypes.object,
  selection: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    selectSessionFolder: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ gallery }) => {
  return {
    selectedFolderId: gallery.galleryState.selectedFolderId,
    selectedFolder: gallery.foldersList.find((folder) => folder.Id === gallery.galleryState.selectedFolderId),
    selection: gallery.selection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryNavbarContainer)
