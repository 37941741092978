import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'
import { tabeebHubName } from '../services/constants'

const initialState = {}

export default handleActions(
  {
    [rawActions.setConnectionProgressState]: (state, action) => {
      const hubName = action.payload?.hubName || tabeebHubName

      return { ...state, [hubName]: { ...state[hubName], isInProgress: true } }
    },
    [rawActions.resetConnectionProgressState]: (state, action) => {
      const hubName = action.payload?.hubName || tabeebHubName

      return { ...state, [hubName]: { ...state[hubName], isInProgress: false } }
    },
    [rawActions.createConnectionSuccess]: (state, action) => {
      const hubName = action.payload?.hubName || tabeebHubName

      return { ...state, [hubName]: { ...state[hubName], connection: action.payload.connection } }
    },
    [rawActions.createConnectionFailed]: (state, action) => {
      const hubName = action.payload?.hubName || tabeebHubName

      return { ...state, [hubName]: { ...state[hubName], connection: undefined } }
    },
    [rawActions.terminateConnectionSuccess]: (state, action) => {
      const hubName = action.payload?.hubName || tabeebHubName

      return { ...state, [hubName]: null }
    },
  },
  initialState
)
