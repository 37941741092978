import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as rawActions from '../actions'

import BentleyBoundingBoxDialogContainer from './BentleySettingsDialogContainer'
import Timeline from '../../timeline/components/Timeline'
import { MIN_ASSETS_NUMBER_FOR_BENTLEY_MODEL } from '../constants'

class BentleyImagesListContainer extends Component {
  _handleClose = () => {
    const {
      actions: { closeBentleyModelTimeline },
    } = this.props
    closeBentleyModelTimeline()
  }

  _handleSubmit = () => {
    const {
      actions: { openBentleySettingsDialog },
      isProcessing,
    } = this.props

    if (isProcessing) {
      return
    }

    openBentleySettingsDialog()
  }

  _handleDeleteItem = (itemIndex) => {
    const {
      actions: { deleteImageFromBentleyModel },
    } = this.props

    deleteImageFromBentleyModel(itemIndex)
  }

  _handleUpdateList = (newList) => {
    const {
      actions: { updateImagesBentleyModel },
    } = this.props

    updateImagesBentleyModel(newList)
  }

  render() {
    const { isOpen, timeLineList, currentPanel } = this.props

    const actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        tooltip:
          timeLineList.length >= MIN_ASSETS_NUMBER_FOR_BENTLEY_MODEL
            ? null
            : `Bentley model requires at least ${MIN_ASSETS_NUMBER_FOR_BENTLEY_MODEL} images`,
        class: 'submit',
        disabled: timeLineList.length < MIN_ASSETS_NUMBER_FOR_BENTLEY_MODEL,
      },
    ]

    const timelineProps = {
      actions,
      timeLineList,
      handleDeleteItem: this._handleDeleteItem,
      handleUpdateList: this._handleUpdateList,
      isOpen,
      isSelected: currentPanel === TabPanel.BentleyModel,
    }

    return (
      <>
        <BentleyBoundingBoxDialogContainer />
        <Timeline {...timelineProps} />
      </>
    )
  }
}

BentleyImagesListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  timeLineList: PropTypes.array.isRequired,
  currentPanel: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    closeBentleyModelTimeline: PropTypes.func.isRequired,
    deleteImageFromBentleyModel: PropTypes.func.isRequired,
    updateImagesBentleyModel: PropTypes.func.isRequired,
    openBentleySettingsDialog: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.bentleyModel.isTimelineOpen,
    isProcessing: state.bentleyModel.isProcessing,
    timeLineList: state.bentleyModel.imagesList,
    currentPanel: state.navbar.currentPanel[TabPanelLocation.Bottom],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BentleyImagesListContainer)
