import { put, all, takeEvery } from 'redux-saga/effects'

import { calendarActions } from '@tabeeb/modules/calendar'
import { notificationActions } from '@tabeeb/modules/notification'
import getErrorMessageFromResponse from '@tabeeb/shared/utils/getErrorMessageFromResponse'

function* showErrorMessage(action) {
  const errorMessage = getErrorMessageFromResponse(action.response.data)

  yield put(notificationActions.onAddErrorNotification({ message: errorMessage }))
}

function* calendarErrorsSagas() {
  yield all([
    takeEvery(
      [
        calendarActions.getCalendarEventFailed,
        calendarActions.subscribeToUsersCalendarFailed,
        calendarActions.unsubscribeFromUsersCalendarFailed,
      ],
      showErrorMessage
    ),
  ])
}

export default calendarErrorsSagas
