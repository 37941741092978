import * as contentTrashBinActions from '../actions'

export const dialogItems = {
  unset: 0,
  restoreOne: 1,
  restoreSelected: 2,
  restoreFolder: 3,
  restoreAll: 4,
  deleteOne: 5,
  deleteSelected: 6,
  deleteFolder: 7,
  deleteAll: 8,
}

export const dialogMessages = {
  [dialogItems.restoreOne]: 'The page will be restored',
  [dialogItems.restoreSelected]: 'All selected pages will be restored',
  [dialogItems.restoreFolder]: 'The folder with its items would be restored',
  [dialogItems.restoreAll]: 'All items in the trash bin would be restored',
  [dialogItems.deleteOne]: 'The page will be permanently deleted',
  [dialogItems.deleteSelected]: 'All selected pages will be permanently deleted',
  [dialogItems.deleteFolder]: 'The folder with its items will be permanently deleted',
  [dialogItems.deleteAll]: 'All items in the trash bin will be permanently deleted',
}

export const dialogSubmitActions = {
  [dialogItems.restoreOne]: contentTrashBinActions.submitRestorePage,
  [dialogItems.restoreSelected]: contentTrashBinActions.submitRestoreSelectedPages,
  [dialogItems.restoreFolder]: contentTrashBinActions.submitRestoreFolder,
  [dialogItems.restoreAll]: contentTrashBinActions.submitRestoreAllPages,
  [dialogItems.deleteOne]: contentTrashBinActions.submitDeletePage,
  [dialogItems.deleteSelected]: contentTrashBinActions.submitDeleteSelectedPages,
  [dialogItems.deleteFolder]: contentTrashBinActions.submitDeleteFolder,
  [dialogItems.deleteAll]: contentTrashBinActions.submitDeleteAllPages,
}
