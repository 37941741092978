import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'

import * as shareContentActions from '@tabeeb/modules/shareSessionPagesList/actions'
import { formsSelectors } from '@tabeeb/modules/forms'
import { ContentSharingType } from '@tabeeb/enums'
import {
  addAllFormsForMerging,
  addStatusToAllFormsForMerging,
  removeAllFormsForMerging,
  removeStatusFromAllFormsForMerging,
} from '@tabeeb/modules/contentSharings/actions'
import { getContentSharingForms } from '@tabeeb/modules/contentSharings/selectors'
import { answerStatuses } from '../../constants'
import TableItem from '../TableItem'
import FormForSharing from '../FormForSharing'
import SharedForReviewFormContainer from '../../../contentReviews/containers/SharedForReviewFormContainer'

import { useStyles } from './styles'

const ShareByFormSelector = ({
  selectedForms,
  availableFormsForSharing,
  unavailableFormsForSharing,
  isAllSelected,
  actions: {
    addAllFormsForSharing,
    removeAllFormsForSharing,
    addStatusToAllFormsForSharing,
    removeStatusFromAllFormsForSharing,
  },
  sharingType,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isMergingType = sharingType === ContentSharingType.LinkedContentsMerging

  useEffect(() => {
    if (!selectedForms) {
      if (isMergingType) {
        dispatch(addAllFormsForMerging())
      } else {
        addAllFormsForSharing()
      }
    }
  }, [selectedForms, addAllFormsForSharing, dispatch, isMergingType])

  const _getIsAllSimilarStatusesChecked = (answerStatus) =>
    selectedForms?.length === availableFormsForSharing.length &&
    selectedForms.every((form) => form.SelectedAnswerStatuses?.includes(answerStatus))

  const _selectAllSimilarStatuses = (answerStatus) => {
    const isAllSimilarStatusesSelected = _getIsAllSimilarStatusesChecked(answerStatus)
    if (isAllSimilarStatusesSelected) {
      if (isMergingType) {
        dispatch(removeStatusFromAllFormsForMerging(answerStatus))
      } else {
        removeStatusFromAllFormsForSharing(answerStatus)
      }
      return
    }

    if (isMergingType) {
      dispatch(addStatusToAllFormsForMerging(answerStatus))
    } else {
      addStatusToAllFormsForSharing(answerStatus)
    }
  }

  const _selectAll = () => {
    if (isAllSelected) {
      if (isMergingType) {
        dispatch(removeAllFormsForMerging())
      } else {
        removeAllFormsForSharing()
      }
      return
    }

    if (isMergingType) {
      dispatch(addAllFormsForMerging())
    } else {
      addAllFormsForSharing()
    }
  }

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} size='small' stickyHeader>
        <TableHead>
          <TableRow>
            <TableItem formCell isSelected={isAllSelected} handleSelect={_selectAll} />
            {Object.keys(answerStatuses).map((key) => {
              const isChecked = _getIsAllSimilarStatusesChecked(answerStatuses[key])

              return (
                <TableItem
                  key={answerStatuses[key]}
                  isSelected={isChecked}
                  handleSelect={() => _selectAllSimilarStatuses(answerStatuses[key])}
                />
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {availableFormsForSharing.map((form) => (
            <FormForSharing answerStatuses={answerStatuses} form={form} key={form.Id} />
          ))}
          {unavailableFormsForSharing.map((form) => (
            <TableRow key={form.Id}>
              <TableCell colSpan={4}>
                <SharedForReviewFormContainer form={form} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ShareByFormSelector.propTypes = {
  selectedForms: PropTypes.arrayOf(
    PropTypes.shape({
      ContentFormId: PropTypes.number.isRequired,
      SelectedAnswerStatuses: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    })
  ),
  availableFormsForSharing: PropTypes.arrayOf(
    PropTypes.shape({
      ContentId: PropTypes.number.isRequired,
      FormId: PropTypes.number.isRequired,
      HasCounters: PropTypes.bool.isRequired,
      HasCountersWithConditions: PropTypes.bool.isRequired,
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      ReattachedNumberOfTimes: PropTypes.number.isRequired,
    })
  ).isRequired,
  unavailableFormsForSharing: PropTypes.array.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    addAllFormsForSharing: PropTypes.func.isRequired,
    removeAllFormsForSharing: PropTypes.func.isRequired,
    addStatusToAllFormsForSharing: PropTypes.func.isRequired,
    removeStatusFromAllFormsForSharing: PropTypes.func.isRequired,
  }).isRequired,
  sharingType: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
  const { selectedForms, sharingType } = state.shareSessionPagesList.shareByFormDialogState
  const { contentForms } = state.forms
  const isReview = sharingType === ContentSharingType.ForReview || sharingType === ContentSharingType.MergeForReview
  const unavailableFormsForSharing = isReview ? formsSelectors.getContentFormsInReview(state) : []
  const availableFormsForSharing = isReview ? formsSelectors.getAvailableContentForms(state) : contentForms
  const formsList =
    sharingType === ContentSharingType.LinkedContentsMerging ? getContentSharingForms(state) : selectedForms

  return {
    selectedForms: formsList,
    availableFormsForSharing,
    unavailableFormsForSharing,
    isAllSelected:
      formsList?.length === availableFormsForSharing.length &&
      formsList?.every((selectedForm) => selectedForm.SelectedAnswerStatuses.length === 3),
    sharingType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(shareContentActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareByFormSelector)
