import { createAction } from 'redux-actions'

import { createApiActions } from '@tabeeb/shared/utils/actions'

import { FtpSettings, Tenant, TenantConfig } from '../types'

export const getConfigForUpdate = createApiActions<{ subdomain: string }, TenantConfig>('GET_CONFIG_FOR_UPDATE')

export const updateConfig = createApiActions<{ subdomain: string; config: TenantConfig }, TenantConfig>('UPDATE_CONFIG')

export const setConfigForUpdate = createAction<TenantConfig>('SET_CONFIG_FOR_UPDATE')

export const getTenantsList = createApiActions<string, { currentTenant: Tenant; allTenants: Tenant[] }>(
  'GET_TENANTS_LIST'
)

export const getFtpServerSettings = createApiActions<string, FtpSettings>('GET_FTP_SERVER_SETTINGS')

export const updateFtpServerSettings = createApiActions<{ subdomain: string; settings: FtpSettings }, FtpSettings>(
  'UPDATE_FTP_SERVER_SETTINGS'
)

export const saveFormSettings = createAction('TENANT_CONFIG_PAGE_SAVE_FORM_SETTINGS')

export const regenerateFtpPassword = createApiActions<void, void>('REGENERATE_FTP_PASSWORD')
