import { memo } from 'react'
import { useSelector } from 'react-redux'

import { CreateNewFolderOutlined } from '@mui/icons-material'

import { DialogTypes, Features } from '../../../enums'
import { usePageDialog } from '../../../hooks'
import { getIsFeatureEnabled } from '../../../selectors'
import { getHasCreateContentFoldersPermission } from '../../../selectors/permissions'

import HeaderButton from '../HeaderButton'

const CreateFolderButton = () => {
  const { onOpen } = usePageDialog(DialogTypes.CreateFolder)

  const isVisible = useSelector(
    (state) => getHasCreateContentFoldersPermission(state) && getIsFeatureEnabled(state, Features.FolderCreation)
  )
  if (!isVisible) {
    return null
  }

  return (
    <HeaderButton
      label='New folder'
      startIcon={<CreateNewFolderOutlined color='pagePrimaryAction' />}
      onClick={onOpen}
    />
  )
}

export default memo(CreateFolderButton)
