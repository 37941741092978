import { styled, Typography } from '@mui/material'
import { ColumnFlexContainer } from '@tabeeb/uikit'

export const Container = styled(ColumnFlexContainer)(({ theme }) => ({
  width: '100%',
  height: '100%',
}))

export const Header = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  marginBottom: theme.spacing(),
}))
