import { memo } from 'react'
import PropTypes from 'prop-types'

import { MenuItem, ListItemText } from '@material-ui/core'

const UserOptionButton = ({ onClick, buttonText, disabled }) => (
  <MenuItem onClick={onClick} disabled={disabled}>
    <ListItemText primary={buttonText} />
  </MenuItem>
)

UserOptionButton.defaultProps = {
  disabled: false,
}

UserOptionButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default memo(UserOptionButton)
