import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import {
  checkConditionsRequest,
  getAiCounterInfoByFormRecordRequest,
  getAiCounterInfoRequest,
  getAiObjectsCountRequest,
} from '@tabeeb/modules/artificialIntelligence/actions'
import { deleteGalleryItemSuccess, onDeleteGalleryItem } from '@tabeeb/modules/gallery/actions'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { put, takeLatest, select, all } from 'redux-saga/effects'
import { getContentFormSuccess, getCounterInfo } from '../actions'

function* onGetCounterInfo() {
  const contentId = yield select((state) => state.contentState.contentId)
  const currentForm = yield select((state) => state.forms.currentForm)

  if (!currentForm) return

  const selectedRecord = currentForm.selectedRecord || 0
  const hasCounters = currentForm.Counters && currentForm.Counters.length > 0

  if (hasCounters) {
    let data = { contentId }

    if (selectedRecord > 0) {
      data = { ...data, formRecordId: selectedRecord }

      yield put(getAiCounterInfoByFormRecordRequest(data))
    }

    yield put(getAiCounterInfoRequest(data))
  }
}

function* getAIObjectsCount(action) {
  const contentId = yield select((state) => state.contentState.contentId)
  const { contentForms } = yield select((state) => state.forms)

  const hasCounters = contentForms.some((cf) => cf.HasCounters)
  if (hasCounters) {
    yield put(getAiObjectsCountRequest({ contentId }))
  }
}

function* checkCounterConditions(action) {
  const form = action.response.data
  const contentId = yield select(getContentId)
  const currentUserId = yield select(getCurrentUserId)
  const userId = action.payload.UserId

  if (form.HasCountersWithConditions && currentUserId === userId) {
    yield put(checkConditionsRequest({ contentId }))
  }
}

function* counterInfo() {
  yield all([
    takeLatest(getCounterInfo, onGetCounterInfo),
    takeLatest([deleteGalleryItemSuccess, onDeleteGalleryItem], getAIObjectsCount),
    takeLatest(getContentFormSuccess, checkCounterConditions),
  ])
}

export default counterInfo
