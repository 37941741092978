import { combineReducers } from 'redux'

import isOpen from './isOpen'
import isRefreshNeeded from './isRefreshNeeded'
import isPublishSettingsDialogOpen from './isPublishSettingsDialogOpen'
import isPublishInProgress from './isPublishInProgress'

export default combineReducers({
  isOpen,
  isRefreshNeeded,
  isPublishSettingsDialogOpen,
  isPublishInProgress,
})
