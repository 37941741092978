import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Navbar from '@tabeeb/modules/sessionTabs'
import PlayerToolbar from '@tabeeb/modules/playerToolbar'
import SelectedPagesForMerging from '@tabeeb/modules/contentSharings/components/SelectedPagesForMerging'
import UsersToolbar from '../../../users'
import PresentationContent from './PresentationContent'
import RecordingProgressBarContainer from '../../recording/containers/RecordingProgressBarContainer'
import SwitchRecordedUserConfirmationDialog from '../../recording/components/SwitchRecordedUserConfirmationDialog'
import { PlayerProgressBarPanel } from '../../player'
import CustomVideo from '../../customVideo'
import SpatialViewImagesListContainer from '../../spatialModel/containers/SpatialViewImagesListContainer'
import ThreedeeImagesListContainer from '../../3dmodel/containers/ThreedeeImagesListContainer'
import BentleyImagesListContainer from '../../bentleyModel/containers/BentleyImagesListContainer'
import ShareSessionPagesListContainer from '../../shareSessionPagesList/containers/ShareSessionPagesListContainer'
import ShareSessionByFormDialog from '../../shareSessionPagesList/components/ShareSessionByFormDialog'
import ReviewSessionPagesListContainer from '../../reviewSessionPagesList/containers/ReviewSessionPagesListContainer'
import StructureModelImagesListContainer from '../../structureModel'
import BentleyAnalyticsReportModelsListContainer from '../../bentleyAnalyticsReport'
import TowerSideProfileImagesListContainer from '../../towerSideProfile'
import ClassificationAnnotationsPagesTimeline from '../../classificationAnnotationsPagesTimeline'
import TwoDLineDrawingImagesListContainer from '../../2dLineDrawing'
import SplatModelTimeline from '@tabeeb/modules/splatModel'
import EditUniqueAIObjectDialog from '../../artificialIntelligence/components/EditUniqueAIObjectDialog'

import { ContentType, TabPanelLocation } from '../../../Enums'
import EditUniqueAIObjectConnectionsDialog from '@tabeeb/modules/artificialIntelligence/components/EditUniqueAIObjectConnectionsDialog'

class Presentation extends Component {
  render() {
    const { currentPanel, isLargeVideo, rightPanel, isGalleryExpanded } = this.props
    return (
      <main
        className='tabeebPresentationToolbar'
        style={{
          display: isGalleryExpanded && rightPanel !== '' ? 'none' : 'flex',
        }}
      >
        <PresentationContent />

        <SwitchRecordedUserConfirmationDialog />

        <RecordingProgressBarContainer />
        <PlayerProgressBarPanel />
        <PlayerToolbar />

        <EditUniqueAIObjectDialog />
        <EditUniqueAIObjectConnectionsDialog />

        <Navbar location={TabPanelLocation.Bottom} />
        <div className='cont' style={{ display: currentPanel !== '' ? 'flex' : 'none' }}>
          <UsersToolbar />
          <CustomVideo />
          <SpatialViewImagesListContainer />
          <ThreedeeImagesListContainer />
          <BentleyImagesListContainer />
          <SplatModelTimeline />
          <ShareSessionPagesListContainer />
          <ShareSessionByFormDialog />
          <ReviewSessionPagesListContainer />
          <StructureModelImagesListContainer />
          <BentleyAnalyticsReportModelsListContainer />
          <TowerSideProfileImagesListContainer />
          <TwoDLineDrawingImagesListContainer />
          <SelectedPagesForMerging />
          <ClassificationAnnotationsPagesTimeline />
        </div>
      </main>
    )
  }
}

Presentation.propTypes = {
  currentPanel: PropTypes.string,
  isLargeVideo: PropTypes.bool,
  rightPanel: PropTypes.string.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
}

function bindAction(dispatch) {
  return {}
}

function mapStateToProps(state) {
  return {
    isLargeVideo: state.presentation.tracks.largeVideoUser !== null,
    currentPanel: state.navbar.currentPanel[TabPanelLocation.Bottom],
    rightPanel: state.navbar.currentPanel[TabPanelLocation.Right],
    isGalleryExpanded: state.gallery.isGalleryExpanded,
  }
}

export default connect(mapStateToProps, bindAction)(Presentation)
