import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    avatar: {
      position: 'sticky',
      top: 0,
    },
    self: {
      flexDirection: 'row-reverse',
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '75%',
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.secondary,
    },
    list: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(),
      marginRight: 0,
      alignItems: 'flex-start',
      flex: 1,
      minWidth: 0,
    },
    selfList: {
      marginLeft: 0,
      marginRight: theme.spacing(),
      alignItems: 'flex-end',
    },
  }
})
