import { handleActions } from 'redux-actions'

import { resetCurrentVideoTimestamp, setDurationVideoTimestamp } from '../actions'

const defaultValue = -1.0

export default handleActions(
  {
    [setDurationVideoTimestamp](state, { payload }) {
      return payload
    },
    [resetCurrentVideoTimestamp](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
