import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function getUtcDate(localDate) {
  if (localDate) return dayjs.utc(localDate).format()
  return dayjs().utc().format()
}

export function getEditedServerUtcDate(serverDateTime) {
  const editedDate = `${serverDateTime}${serverDateTime.endsWith('Z') ? '' : 'Z'}`

  return editedDate
}

export function getFormattedUtcDate(date, format) {
  date = getEditedServerUtcDate(date)

  return dayjs(date).format(format)
}
