import { handleActions } from 'redux-actions'

import { setPageSize, resetState } from '../../actions'

const defaultState = 24

export default handleActions(
  {
    [setPageSize](state, action) {
      return action.payload
    },
    [resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
