import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import SwitchTenantDialog from '@tabeeb/modules/navbar/components/SwitchTenantDialog'
import { conditionalPropType } from '@tabeeb/shared/utils/helpers'

import { getIdentityMe, onLogout } from '../actions'
import { getIdentityMeSelector } from '../selectors'
import SwitchTenantPage from '../components/SwitchTenantPage'

const SwitchTenantContainer = ({ dialog, open, onClose }) => {
  const dispatch = useDispatch()
  const identityMe = useSelector(getIdentityMeSelector)
  useEffect(() => {
    if (open || !dialog) {
      dispatch(getIdentityMe.request())
    }
    return () => {
      dispatch(getIdentityMe.resetState())
    }
  }, [dialog, dispatch, open])

  const handleLogoutClick = useCallback(() => {
    dispatch(onLogout())
  }, [dispatch])

  return dialog ? (
    <SwitchTenantDialog open={open} onClose={onClose} identityMe={identityMe} />
  ) : (
    <SwitchTenantPage identityMe={identityMe} handleLogoutClick={handleLogoutClick} />
  )
}

SwitchTenantContainer.defaultProps = {
  dialog: false,
}

SwitchTenantContainer.propTypes = {
  dialog: PropTypes.bool,
  open: conditionalPropType((props, propName) => props.dialog === true && typeof props[propName] !== 'boolean'),
  onClose: conditionalPropType((props, propName) => props.dialog === true && typeof props[propName] !== 'function'),
}

export default SwitchTenantContainer
