import './styles.less'

import ControlsPanelButtons from '../ControlsPanelButtons'
import AddVideoFromUrlDialogContainer from '../../containers/AddVideoFromUrlDialogContainer'
import AddWebPageFromUrlDialogContainer from '../../containers/AddWebPageFromUrlDialogContainer'

const ControlsPannel = () => {
  return (
    <footer className='gallery-controls-panel'>
      <AddVideoFromUrlDialogContainer />
      <AddWebPageFromUrlDialogContainer />
      <ControlsPanelButtons />
    </footer>
  )
}

export default ControlsPannel
