import { combineActions, handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../actions'

const emptyReport = {
  Id: 0,
  Name: '',
  Provider: { Id: 0, Name: '' },
  WorkspaceId: '',
  DashboardId: '',
  SecurityModel: { Id: 0, Name: '' },
}

const initialState = {
  fetchStatus: FetchStatus.Idle,
  saveStatus: FetchStatus.Idle,
  report: emptyReport,
}

export default handleActions(
  {
    [rawActions.getPowerBIReport.request]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [rawActions.getPowerBIReport.failed]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
    [rawActions.getPowerBIReport.success]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loaded, report: action.response.data }
    },
    [combineActions(rawActions.updatePowerBIReportName.request)]: (state, _action) => {
      return { ...state, saveStatus: FetchStatus.Loading }
    },
    [combineActions(rawActions.updatePowerBIReportName.failed)]: (state, _action) => {
      return { ...state, saveStatus: FetchStatus.Failed }
    },
    [combineActions(rawActions.updatePowerBIReportName.success)]: (state, _action) => {
      return { ...state, saveStatus: FetchStatus.Loaded }
    },
  },
  initialState
)
