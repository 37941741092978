import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Backdrop } from '@material-ui/core'
import { CircularProgress } from '@mui/material'

import oidcUserManager from '@tabeeb/services/oidcUserManager'
import routes from '@tabeeb/routes'

const SignInRedirectCallback = () => {
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        const user = await oidcUserManager.signinRedirectCallback()
        try {
          const decodedState = decodeURIComponent(user.state)
          if (decodedState === 'undefined') {
            history.push(routes.home)
          } else {
            history.push(decodedState ?? routes.home)
          }
        } catch {
          history.push(routes.home)
        }
      } catch (e) {
        history.push('/')
      }
    })()
  }, [history])

  return (
    <Backdrop style={{ zIndex: 9999, backgroundColor: 'inherit' }} open transitionDuration={0}>
      <CircularProgress color='info' size={40} thickness={6} />
    </Backdrop>
  )
}

export default SignInRedirectCallback
