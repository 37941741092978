import { memo } from 'react'

import { SearchOutlined } from '@material-ui/icons'

import Placeholder from '../Placeholder'

const NoUsersFoundPlaceholder = () => {
  return <Placeholder icon={SearchOutlined} text='No users found...' />
}

export default memo(NoUsersFoundPlaceholder)
