import { put, takeLatest, select, all, delay } from 'redux-saga/effects'
import * as rawActions from '../actions'

function* updateGeneralFilter(action) {
  const searchText = action.payload
  yield delay(800)
  const { generalFilter } = yield select((state) => state.tenantSessionsPage)
  if (searchText === generalFilter) {
    yield put(rawActions.loadTenantContents())
  }
}

function* loadTenantContents() {
  yield put(rawActions.loadTenantContents())
}

function* generalFilter() {
  yield all([
    takeLatest(rawActions.updateGeneralFilter, updateGeneralFilter),
    takeLatest(rawActions.updateFilter, loadTenantContents),
  ])
}

export default generalFilter
