import { combineReducers } from 'redux'

import rotation from './rotation'
import scale from './scale'
import unit from './unit'

export default combineReducers({
  rotation,
  scale,
  unit,
})
