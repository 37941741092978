export default (theme) => {
  return {
    label: {
      fontWeight: 'bold',
      marginBottom: 8,
      cursor: 'pointer',
      overflow: 'auto',
    },
  }
}
