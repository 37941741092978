import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Fade, Tooltip, Typography, withStyles } from '@material-ui/core'

import { recordingSelectors } from '@tabeeb/modules/recording'
import getDurationString from '@tabeeb/shared/utils/date/getDurationString'

import styles from './styles'

const RecordingTimer = ({ classes }) => {
  const time = useSelector(recordingSelectors.getElapsedTime)
  const isRecordingActive = useSelector(recordingSelectors.getIsRecordingActive)

  return (
    <Fade in={isRecordingActive} unmountOnExit>
      <Tooltip title='Recording time' arrow placement='top'>
        <div className={classes.container}>
          <div className={classes.icon} />
          <Typography variant='caption' component='time'>
            {getDurationString(time)}
          </Typography>
        </div>
      </Tooltip>
    </Fade>
  )
}

RecordingTimer.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(RecordingTimer))
