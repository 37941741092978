import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    body: {
      display: 'flex',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(),
      position: 'relative',
    },
    content: {
      display: 'flex',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flexDirection: 'column',
      wordBreak: 'break-word',
      flexGrow: 1,
    },
    controls: {
      display: 'flex',
      position: 'absolute',
      right: theme.spacing() * 2,
      bottom: theme.spacing() / 2,
    },
    progress: {
      margin: theme.spacing(),
    },
  }
})
