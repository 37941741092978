import { handleActions } from 'redux-actions'

import { getTenantsList } from '../actions'

import { Tenant } from '../types'

export default handleActions<Tenant[], any>(
  {
    [getTenantsList.success.toString()](state, action) {
      const typedAction = action as ReturnType<typeof getTenantsList.success>

      return typedAction.response.data.allTenants
    },
  },
  []
)
