import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChatOutlined } from '@material-ui/icons'
import { Switch } from '@material-ui/core'

import { getIsMuteChatNotificationsVisible } from '@tabeeb/modules/navbar/selectors'
import { accountSelectors } from '@tabeeb/modules/account'
import { usersActions, usersSelectors } from '@tabeeb/modules/../users'
import { contentStateSelectors } from '@tabeeb/shared/content'

import SettingsField from '../SettingsField'

const MuteChatNotifications = () => {
  const dispatch = useDispatch()

  const contentId = useSelector(contentStateSelectors.getContentId)
  const user = useSelector(usersSelectors.getCurrentUser)
  const isVisible = useSelector(getIsMuteChatNotificationsVisible)

  const globalChatNotificationsEnabled = useSelector((state) =>
    Boolean(accountSelectors.getMe(state)?.NotificationsSettings?.ChatNotificationsEnabled ?? false)
  )

  const onChange = (_, value) => {
    dispatch(usersActions.toggleChatNotificationsMuteRequest({ contentId, userId: user.id, value }))
  }

  if (!isVisible) {
    return null
  }

  const disabled = !globalChatNotificationsEnabled

  return (
    <SettingsField icon={ChatOutlined} label='Mute chat notifications'>
      <Switch
        style={{ pointerEvents: 'unset' }}
        disabled={disabled}
        color='primary'
        checked={user?.chatNotificationsMuted}
        onChange={onChange}
        title={
          disabled
            ? 'Chat notifications muted globally. Please check your account settings'
            : user?.chatNotificationsMuted
            ? 'Unmute chat notifications'
            : 'Mute chat notifications'
        }
      />
    </SettingsField>
  )
}

export default memo(MuteChatNotifications)
