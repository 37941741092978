import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'
import { ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID } from '../services/dashboards'

const defaultState = {
  list: [],
  selectedDashboardId: ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID,
}

export default handleActions(
  {
    [rawActions.getTenantContentsDashboards.success](state, action) {
      return { ...state, list: action.response.data }
    },
    [rawActions.createDashboard.success](state, action) {
      return { ...state, list: [...state.list, action.response.data] }
    },
    [rawActions.selectTenantContentsDashboard](state, action) {
      return { ...state, selectedDashboardId: action.payload.selectedDashboard.Id }
    },
    [rawActions.setDashboardAsDefault.success](state, action) {
      const dashboardId = action.payload
      const updatedList = state.list.map((dashboard) =>
        dashboard.Id === dashboardId ? { ...dashboard, IsDefault: true } : { ...dashboard, IsDefault: false }
      )

      return { ...state, list: updatedList }
    },
    [rawActions.resetDefaultDashboard.success](state, action) {
      const updatedList = state.list.map((dashboard) =>
        dashboard.IsDefault ? { ...dashboard, IsDefault: false } : dashboard
      )

      return { ...state, list: updatedList }
    },
    [rawActions.deleteDashboard.success](state, action) {
      const dashboardId = action.payload
      const updatedList = state.list.filter((dashboard) => dashboard.Id !== dashboardId)
      const defaultDashboardId = updatedList.find((v) => v.IsDefault)?.Id || ACTUAL_CONTENTS_DEFAULT_DASHBOARD_ID

      return { ...state, list: updatedList, selectedDashboardId: defaultDashboardId }
    },
    [rawActions.updateDashboard.success](state, action) {
      const { Id, Name, HiddenPrimaryColumnsIds, SelectedCustomColumns } = action.response.data
      const updatedList = state.list.map((dashboard) =>
        dashboard.Id === Id ? { ...dashboard, Name, HiddenPrimaryColumnsIds, SelectedCustomColumns } : dashboard
      )

      return { ...state, list: updatedList }
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
