import { combineReducers } from 'redux'

import remindersList from './remindersList'
import newReminder from './newReminder'
import existingReminder from './existingReminder'
import actionInProgress from './actionInProgress'
import remindersNotificationsDialogState from './remindersNotificationsDialogState'
import availableReceivers from './availableReceivers'

export default combineReducers({
  remindersList,
  newReminder,
  existingReminder,
  actionInProgress,
  remindersNotificationsDialogState,
  availableReceivers,
})
