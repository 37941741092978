import { handleActions } from 'redux-actions'
import { setGalleryIsLoading, getPagesByContentIdFailed } from '../actions'

const defaultState = false

export default handleActions(
  {
    [setGalleryIsLoading](state, { payload: loading }) {
      return loading
    },
    [getPagesByContentIdFailed]() {
      return false
    },
  },
  defaultState
)
