import PropTypes from 'prop-types'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import './styles.less'

const FormsFilter = ({ forms, currentForm, handleChange, formTitle }) => {
  const selectedForm = currentForm.Id ? currentForm : forms[0]

  return (
    <div className='forms-filters-item forms-filters-item-form'>
      <FormControl className='forms-filters-item-control'>
        <InputLabel>{formTitle}</InputLabel>
        <Select
          value={selectedForm ? selectedForm.Id : ''}
          onChange={handleChange}
          title={selectedForm ? selectedForm.Name : ''}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            getContentAnchorEl: null,
            id: 'forms-filters-menu',
          }}
        >
          {forms &&
            forms.map((form) => (
              <MenuItem value={form.Id} key={form.Id}>
                <span className='forms-filters-menu-text' title={form.Name}>
                  {form.Name}
                </span>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

FormsFilter.propTypes = {
  forms: PropTypes.array.isRequired,
  currentForm: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  formTitle: PropTypes.string.isRequired,
}

export default FormsFilter
