import { put, takeLatest, all, select } from 'redux-saga/effects'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

import { getSelectedProject } from '../selectors'

function* onGetIterationsSuccess({ response }) {
  const selectedProject = yield select((state) => getSelectedProject(state))
  if (!selectedProject) {
    return
  }

  const iterations = response.data
  if (iterations.length === 0) {
    return
  }

  const lastIteration = iterations[iterations.length - 1]

  if (selectedProject.Id === lastIteration.ProjectId) {
    yield put(actions.setSelectedIterationId(lastIteration.Id))
  }
}

function* onGetIterationStatusFailed({ response }) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to refresh an iteration status.' }))
}

function* onGetIterationDeploymentStatusFailed({ response }) {
  yield put(
    notificationActions.onAddErrorNotification({ message: 'Failed to refresh an iteration deployment status.' })
  )
}

function* getIteration() {
  yield all([takeLatest(actions.getIterations.success, onGetIterationsSuccess)])
  yield all([takeLatest(actions.getIterationStatus.failed, onGetIterationStatusFailed)])
  yield all([takeLatest(actions.getIterationDeploymentStatus.failed, onGetIterationDeploymentStatusFailed)])
}

export default getIteration
