import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.addLoadingFileControlId](state, action) {
      return [...state, action.payload]
    },
    [rawActions.removeLoadingFileControlId](state, action) {
      return state.filter((id) => id !== action.payload)
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
