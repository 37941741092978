import { createAction } from 'redux-actions'

export const subscribeToUsersCalendarRequest = createAction('SUBSCRIBE_TO_USERS_CALENDAR_REQUEST')
export const subscribeToUsersCalendarSuccess = createAction('SUBSCRIBE_TO_USERS_CALENDAR_SUCCESS')
export const subscribeToUsersCalendarFailed = createAction('SUBSCRIBE_TO_USERS_CALENDAR_FAILED')

export const unsubscribeFromUsersCalendarRequest = createAction('UNSUBSCRIBE_FROM_USERS_CALENDAR_REQUEST')
export const unsubscribeFromUsersCalendarSuccess = createAction('UNSUBSCRIBE_FROM_USERS_CALENDAR_SUCCESS')
export const unsubscribeFromUsersCalendarFailed = createAction('UNSUBSCRIBE_FROM_USERS_CALENDAR_FAILED')

export const updateCalendarSubscriptionDisplayRequest = createAction('UPDATE_CALENDAR_SUBSCRIPTION_DISPLAY_REQUEST')
export const updateCalendarSubscriptionDisplaySuccess = createAction('UPDATE_CALENDAR_SUBSCRIPTION_DISPLAY_SUCCESS')
export const updateCalendarSubscriptionDisplayFailed = createAction('UPDATE_CALENDAR_SUBSCRIPTION_DISPLAY_FAILED')

export const updateCalendarSubscriptionColorRequest = createAction('UPDATE_CALENDAR_SUBSCRIPTION_COLOR_REQUEST')
export const updateCalendarSubscriptionColorSuccess = createAction('UPDATE_CALENDAR_SUBSCRIPTION_COLOR_SUCCESS')
export const updateCalendarSubscriptionColorFailed = createAction('UPDATE_CALENDAR_SUBSCRIPTION_COLOR_FAILED')

export const getCalendarsListRequest = createAction('GET_CALENDARS_LIST_REQUEST')
export const getCalendarsListSuccess = createAction('GET_CALENDARS_LIST_SUCCESS')
export const getCalendarsListFailed = createAction('GET_CALENDARS_LIST_FAILED')

export const searchCalendarUsersRequest = createAction('SEARCH_CALENDAR_USERS_REQUEST')
export const searchCalendarUsersSuccess = createAction('SEARCH_CALENDAR_USERS_SUCCESS')
export const searchCalendarUsersFailed = createAction('SEARCH_CALENDAR_USERS_FAILED')
