import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { getIsCallStarted } from '@tabeeb/modules/presentation/selectors'
import { getIsCalendarEnabled, getMainBannerColor, getMainTextColor } from '@tabeeb/modules/appConfigState/selectors'
import routes from '@tabeeb/routes'
import { onLogout } from '@tabeeb/modules/account/actions'
import { getMe } from '../../account/selectors'

import User from '../components/User'

const UserContainer = () => {
  const dispatch = useDispatch()
  const textColor = useSelector(getMainTextColor)
  const backgroundColor = useSelector(getMainBannerColor)
  const isCalendarEnabled = useSelector(getIsCalendarEnabled)
  const currentUser = useSelector(getMe)
  const isCallStarted = useSelector(getIsCallStarted)

  const handleLogout = useCallback(() => {
    dispatch(onLogout())
  }, [dispatch])

  const handleOpenUserSettings = useCallback(() => {
    dispatch(push(routes.userSettings))
  }, [dispatch])

  const handleOpenCalendar = useCallback(() => {
    dispatch(push(routes.calendarBase))
  }, [dispatch])

  const props = {
    disabled: isCallStarted,
    textColor,
    backgroundColor,
    isCalendarEnabled,
    currentUser,
    handleLogout,
    handleOpenCalendar,
    handleOpenUserSettings,
  }

  return <User {...props} />
}

export default UserContainer
