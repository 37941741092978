import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoopOutlined } from '@material-ui/icons'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { actions } from '@tabeeb/modules/2dLineDrawing'
import { gallerySelectors } from '@tabeeb/modules/gallery'
import { usersSelectors } from '@tabeeb/modules/../users'
import { is2dLineDrawingPage } from '@tabeeb/services/pageService'

import ToolbarButton from '../ToolbarButton'

const Regenerate2dLineDrawingButton = () => {
  const dispatch = useDispatch()

  const pageId = useSelector(gallerySelectors.getSelectedGalleryItemId)
  const isFeatureEnabled = useSelector(appConfigStateSelectors.getShow2dLineDrawing)

  const isReviewer = useSelector(usersSelectors.getIsCurrentUserReviewer)
  const isViewer = useSelector(usersSelectors.getIsCurrentUserViewer)

  const is2dLineDrawing = useSelector((state) =>
    is2dLineDrawingPage(gallerySelectors.getSelectedGalleryItemType(state))
  )
  if (!is2dLineDrawing) {
    return null
  }

  const handleRegenerate2dLineDrawingButtonClick = () => {
    dispatch(actions.regenerateTwoDLineDrawingRequest({ pageId }))
  }

  return (
    <ToolbarButton
      Icon={LoopOutlined}
      display={isFeatureEnabled && !isReviewer && !isViewer}
      title='Regenerate 2D Line Drawing'
      onClick={handleRegenerate2dLineDrawingButtonClick}
    />
  )
}

export default memo(Regenerate2dLineDrawingButton)
