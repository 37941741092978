import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material'
import { AssignmentTurnedInOutlined, ContentPasteOffOutlined } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getMe } from '@tabeeb/modules/account/selectors'
import { CertificateStatusType, TenantPermission } from '@tabeeb/enums'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { useApiRequest } from '@tabeeb/shared/utils/hooks'
import ReadOnlyCertificate, { ReadOnlyCertificateSkeleton } from '../ReadOnlyCertificate'
import { getReviewAllowed } from '../../helpers'
import { getCertificateById } from '../../actions'
import { getUsersByIdentityGuids } from '../../../../users/actions'

const ViewCertificateDialog = ({ open, onClose, onExited, certificateId, onReview, personal }) => {
  const currentUser = useSelector(getMe)
  const hasManageCertificatesTenantScopePermission = useSelector((state) =>
    hasTenantPermission(state, TenantPermission.CertificatesTenantScope)
  )
  const hasManageCertificatesGlobalScopePermission = useSelector((state) =>
    hasTenantPermission(state, TenantPermission.CertificatesGlobalScope)
  )

  const payload = useMemo(() => ({ id: certificateId }), [certificateId])
  const {
    response: certificate,
    loading: getCertificateLoading,
    abortRequest,
  } = useApiRequest({
    defaultResponse: {},
    request: getCertificateById.request,
    payload,
    enabled: open && Boolean(certificateId),
  })

  const getUsersPayload = useMemo(
    () => ({
      identityGuids: certificate.OwnerIdentityGuid ? [certificate.OwnerIdentityGuid] : [],
    }),
    [certificate.OwnerIdentityGuid]
  )

  const { loading: loadingGetUsers, response: responseGetUsers } = useApiRequest({
    defaultResponse: [],
    request: getUsersByIdentityGuids.request,
    payload: getUsersPayload,
    enabled: !personal && getUsersPayload.identityGuids.length > 0,
  })

  useEffect(() => {
    return () => abortRequest()
  }, [abortRequest])

  const reviewAllowedModel = useMemo(
    () => ({
      personal,
      currentUserId: currentUser.Id,
      currentUserIdentityGuid: currentUser.IdentityGuid,
      certificateStatusId: certificate?.StatusId,
      certificateScopeId: certificate?.ScopeId,
      certificateOwnerProfileId: certificate?.OwnerProfileId,
      certificateOwnerIdentityGuid: certificate?.OwnerIdentityGuid,
      hasManageCertificatesTenantScopePermission,
      hasManageCertificatesGlobalScopePermission,
    }),
    [
      certificate?.OwnerIdentityGuid,
      certificate?.OwnerProfileId,
      certificate?.StatusId,
      certificate?.ScopeId,
      currentUser.Id,
      currentUser.IdentityGuid,
      hasManageCertificatesGlobalScopePermission,
      hasManageCertificatesTenantScopePermission,
      personal,
    ]
  )

  const reviewAllowed = getReviewAllowed(reviewAllowedModel)

  const onRejectCertificate = useCallback(() => {
    onReview({ id: certificate.Id, statusId: CertificateStatusType.Rejected })
    onClose()
  }, [certificate?.Id, onReview, onClose])

  const onApproveCertificate = useCallback(() => {
    onReview({ id: certificate.Id, statusId: CertificateStatusType.Approved })
    onClose()
  }, [certificate?.Id, onReview, onClose])

  const loading = useMemo(() => getCertificateLoading || !certificate.Id, [certificate?.Id, getCertificateLoading])

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited }} fullWidth maxWidth='md'>
      <DialogTitle>View certificate</DialogTitle>
      <DialogContent>
        {loading ? (
          <ReadOnlyCertificateSkeleton />
        ) : (
          <ReadOnlyCertificate
            certificate={certificate}
            personal={personal}
            loadingGetUsers={loadingGetUsers}
            user={responseGetUsers[0] ?? {}}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box flexGrow={1} mr={1}>
          <Button variant='outlined' onClick={onClose}>
            Close
          </Button>
        </Box>
        {open && !loading && reviewAllowed && (
          <>
            <Button
              startIcon={<ContentPasteOffOutlined />}
              variant='outlined'
              color='error'
              onClick={onRejectCertificate}
            >
              Reject
            </Button>

            <Button
              startIcon={<AssignmentTurnedInOutlined />}
              variant='outlined'
              color='success'
              onClick={onApproveCertificate}
            >
              Approve
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

ViewCertificateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func,
  certificateId: PropTypes.number,
  onReview: PropTypes.func,
  personal: PropTypes.bool.isRequired,
}

export default ViewCertificateDialog
