import { combineReducers } from 'redux'

import life365Programs from './life365Programs'
import life365DeviceStatus from './life365DeviceStatus'
import healthDevices from './healthDevices'
import viewedPatient from './viewedPatient'
import chartsHealthData from './chartsHealthData'
import chartsHealthDataOfPatient from './chartsHealthDataOfPatient'
import healthInfoSettings from './healthInfoSettings'
import devicesOfPatient from './devicesOfPatient'
import currentUserHealthData from './currentUserHealthData'

export default combineReducers({
  life365Programs,
  life365DeviceStatus,
  healthDevices,
  viewedPatient,
  chartsHealthData,
  chartsHealthDataOfPatient,
  healthInfoSettings,
  devicesOfPatient,
  currentUserHealthData,
})
