import { useSelector } from 'react-redux'

import { GroupOutlined } from '@mui/icons-material'

import { FolderPermission } from '@tabeeb/enums'

import { DialogTypes } from '../../../enums'
import { hasFolderPermission } from '../../../helpers'
import { usePageDialog } from '../../../hooks'
import { getCurrentFolder } from '../../../selectors'

import HeaderButton from '../HeaderButton'

const ManageFolderUsersButton = () => {
  const { onOpen } = usePageDialog(DialogTypes.ManageFolderUsers)
  const folder = useSelector(getCurrentFolder)

  const isVisible = useSelector(
    (state) =>
      hasFolderPermission(folder?.FolderUsersPermissions, FolderPermission.AddUsers) ||
      hasFolderPermission(folder?.FolderUsersPermissions, FolderPermission.EditUsers)
  )
  if (!isVisible) {
    return null
  }

  return (
    <HeaderButton
      label='Manage users'
      startIcon={<GroupOutlined color='pagePrimaryAction' />}
      onClick={() => onOpen({ folder })}
    />
  )
}

export default ManageFolderUsersButton
