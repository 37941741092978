import PropTypes from 'prop-types'
import { TextField, withStyles } from '@material-ui/core'

import { get, isNull, isUndefined } from 'lodash'

import * as Errors from '@tabeeb/shared/forms/constants/Errors'

import styles from './styles'

const FormikTextField = ({ classes, field, form, endAdornment, InputProps, ...props }) => {
  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  return (
    <TextField
      color='primary'
      error={!!error}
      variant='outlined'
      style={{ width: '100%' }}
      helperText={error}
      FormHelperTextProps={{
        hidden: Boolean(error === Errors.empty),
      }}
      {...field}
      value={isUndefined(field.value) || isNull(field.value) ? '' : field.value}
      {...props}
      InputProps={{
        endAdornment,
        ...InputProps,
      }}
    />
  )
}

FormikTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
  endAdornment: PropTypes.element,
}

export default withStyles(styles)(FormikTextField)
