import { handleActions } from 'redux-actions'

import { UniqueAIObjectCreationMode } from '@tabeeb/enums'
import * as annotationsActions from '../actions'

const defaultState = UniqueAIObjectCreationMode.CreateNew

export default handleActions(
  {
    [annotationsActions.setUniqueAiObjectCreationMode](state, action) {
      return action.payload
    },
  },
  defaultState
)
