import PropTypes from 'prop-types'

import { Circle, Line } from 'react-konva'

import NorthPoint from '../NorthPoint'

const Dial = ({ size, strokeWidthRatio, markLengthRatio }) => {
  const markLength = size * markLengthRatio

  const marksCommonProps = {
    stroke: 'white',
    points: [0, 0, markLength, 0],
  }

  return (
    <>
      <Circle x={size / 2} y={size / 2} stroke='white' strokeWidth={size * strokeWidthRatio} radius={size / 2} />
      <Line name='East' x={size} y={size / 2} {...marksCommonProps} />
      <Line name='South' x={size / 2} y={size} rotation={90} {...marksCommonProps} />
      <Line name='West' x={0} y={size / 2} stroke='white' rotation={180} {...marksCommonProps} />

      <NorthPoint x={size / 2} y={0} size={size} />
    </>
  )
}

Dial.defaultProps = {
  markLengthRatio: 0.03,
  strokeWidthRatio: 0.0075,
}

Dial.propTypes = {
  size: PropTypes.number.isRequired,
  markLengthRatio: PropTypes.number.isRequired,
  strokeWidthRatio: PropTypes.number.isRequired,
}

export default Dial
