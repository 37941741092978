import { createSelector } from 'reselect'

import { UserRole } from '@tabeeb/enums'
import { getUsersList } from '@tabeeb/modules/contentReviews/selectors'

export const getFoundUsersList = (state) => state.sessionsPage.foundUsersList

export const getFoundUsersForReview = createSelector([getUsersList, getFoundUsersList], (usersList, foundUsersList) => {
  // Content users can't be invited as reviewers to this content (except existing content reviewers).
  const notReviewersContentUsers = usersList.filter((cu) => cu.role !== UserRole.Reviewer && !cu.isDeleted)
  const filteredFoundUsersList = foundUsersList.filter((fu) => !notReviewersContentUsers.some((cu) => cu.id === fu.Id))

  return filteredFoundUsersList
})
