import { useMemo } from 'react'
import PropTypes from 'prop-types'
import useUserTenants from '@tabeeb/modules/account/hooks/useUserTenants'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { FetchStatus } from '@tabeeb/enums'

const UserTenantsSelect = ({ userId, disabled, onChange, helperText, variant = 'standard' }) => {
  const { fetchStatus, tenants } = useUserTenants(userId)

  const options = useMemo(() => {
    return tenants.map((tenant) => ({ label: tenant.tenantName, tenantId: tenant.tenantId }))
  }, [tenants])

  return (
    <Autocomplete
      fullWidth
      size='small'
      options={options}
      disableClearable
      loading={fetchStatus === FetchStatus.Loading}
      onChange={onChange}
      disabled={disabled}
      renderOption={(props, option) => (
        <Typography
          {...props}
          title={option.label}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {option.label}
        </Typography>
      )}
      renderInput={(params) => <TextField {...params} label='Tenant' variant={variant} helperText={helperText} />}
    />
  )
}

UserTenantsSelect.propTypes = {
  userId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
}

export default UserTenantsSelect
