import { all, put, select, take, takeEvery } from 'redux-saga/effects'

import { ContentSharingModelType } from '@tabeeb/enums'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { getSelectedSessionsList } from '../selectors'
import {
  addUsersToSelectedSessions,
  addUsersToSession,
  createContentSharings,
  updateProgressPopupState,
  showPropgressPopup,
  hidePropgressPopup,
  removeSessionsFromSelection,
  restoreSelectedSessions,
  switchSelectedSessionsOwner,
  switchSelectedSessionsReviewer,
  switchSelectedSessionsStorage,
  switchSessionOwner,
  switchSessionReviewer,
  switchSessionStorage,
  restoreSession,
  createContentSharingSuccess,
  createContentSharingFailed,
  createContentSharingRequest,
} from '../actions'

function* runBackgroundTaskForSelectedSessions({
  message,
  requestAction,
  requestActionPayloadMap,
  failedAction,
  successAction,
  failedNotificationMessage,
  successNotificationMessage,
}) {
  const selectedSessions = yield select(getSelectedSessionsList)

  yield put(
    updateProgressPopupState({
      message,
      currentCount: 0,
      totalCount: selectedSessions.length,
    })
  )
  yield put(showPropgressPopup())

  let completedCount = 0

  const failed = []
  const succeeded = []

  for (const session of selectedSessions) {
    const data = requestActionPayloadMap(session.Id)

    yield put(requestAction(data))
    const actionResponse = yield take([successAction, failedAction])

    if (actionResponse.type === failedAction().type) {
      failed.push(session)
    }

    if (actionResponse.type === successAction().type) {
      succeeded.push(session)
    }

    completedCount++

    yield put(
      updateProgressPopupState({
        currentCount: completedCount,
      })
    )
    yield put(removeSessionsFromSelection([session]))
  }
  yield put(hidePropgressPopup())

  if (failed.length > 0 && failedNotificationMessage) {
    yield put(
      onAddErrorNotification({
        message: `${failedNotificationMessage}: ${failed.map((session) => session.Description).join(', ')}`,
      })
    )
  }

  if (succeeded.length > 0 && successNotificationMessage) {
    yield put(onAddSuccessNotification({ message: successNotificationMessage }))
  }

  return {
    failed,
    succeeded,
  }
}

function* addUsersToSelectedSessionsTask(action) {
  const { UserIds, RoleId } = action.payload

  yield runBackgroundTaskForSelectedSessions({
    message: 'Adding users is in progress',
    requestAction: addUsersToSession.request,
    requestActionPayloadMap: (contentId) => ({
      UserIds,
      ContentId: contentId,
      Role: RoleId,
    }),
    failedAction: addUsersToSession.failed,
    successAction: addUsersToSession.success,
    failedNotificationMessage: 'Failed to add users',
    successNotificationMessage: 'User(s) were added',
  })
}

function* createContentSharingsTask(action) {
  const { selectedTenantForSharing, selectedOwnerForSharing } = yield select((state) => state.sessionsPage)

  const userId = selectedOwnerForSharing.Id
  const tenantId = selectedTenantForSharing.Id

  yield runBackgroundTaskForSelectedSessions({
    message: sessionTitleFormatter.format('Session sharing is in progress'),
    requestAction: createContentSharingRequest,
    requestActionPayloadMap: (contentId) => ({
      hideNotification: true,
      contentId,
      userId,
      tenantId,
      type: ContentSharingModelType.SharedByAdmin,
    }),
    failedAction: createContentSharingFailed,
    successAction: createContentSharingSuccess,
    failedNotificationMessage: sessionTitleFormatter.format(`The session can't be shared by the current user`),
    successNotificationMessage: sessionTitleFormatter.format('Session is shared'),
  })
}

function* restoreSelectedSessionsTask(action) {
  yield runBackgroundTaskForSelectedSessions({
    message: sessionTitleFormatter.format('Session restoration is in progress'),
    requestAction: restoreSession.request,
    requestActionPayloadMap: (contentId) => ({
      contentId,
    }),
    failedAction: restoreSession.failed,
    successAction: restoreSession.success,
    failedNotificationMessage: sessionTitleFormatter.format('Failed to restore session(s)'),
    successNotificationMessage: sessionTitleFormatter.format('Session(s) were restored'),
  })
}

function* switchSelectedSessionsOwnerTask(action) {
  const { newOwner } = action.payload

  yield runBackgroundTaskForSelectedSessions({
    message: 'Switching ownership is in progress',
    requestAction: switchSessionOwner.request,
    requestActionPayloadMap: (contentId) => ({
      contentId,
      newOwnerId: newOwner.Id,
      redirectToSession: false,
      hideNotification: true,
      newOwner,
    }),
    failedAction: switchSessionOwner.failed,
    successAction: switchSessionOwner.success,
    failedNotificationMessage: 'Failed to switch owner',
    successNotificationMessage: 'Owner is switched',
  })
}

function* switchSelectedSessionsReviewerTask(action) {
  const { newReviewer } = action.payload

  yield runBackgroundTaskForSelectedSessions({
    message: 'Switching reviewer is in progress',
    requestAction: switchSessionReviewer.request,
    requestActionPayloadMap: (contentId) => ({
      contentId,
      newReviewerId: newReviewer.Id,
    }),
    failedAction: switchSessionReviewer.failed,
    successAction: switchSessionReviewer.success,
    failedNotificationMessage: 'Reviewer cannot be switched',
    successNotificationMessage: 'Reviewer is switched',
  })
}

function* switchSelectedSessionsStorageTask(action) {
  const { storageId } = action.payload

  yield runBackgroundTaskForSelectedSessions({
    message: 'Switching is in progress',
    requestAction: switchSessionStorage.request,
    requestActionPayloadMap: (contentId) => ({
      contentId,
      storageId,
    }),
    failedAction: switchSessionStorage.failed,
    successAction: switchSessionStorage.success,
    failedNotificationMessage: 'Failed to switch storage',
    successNotificationMessage: 'Storage is switched',
  })
}

function* addUsersSagas() {
  yield all([
    takeEvery(addUsersToSelectedSessions, addUsersToSelectedSessionsTask),
    takeEvery(createContentSharings, createContentSharingsTask),
    takeEvery(restoreSelectedSessions, restoreSelectedSessionsTask),
    takeEvery(switchSelectedSessionsOwner, switchSelectedSessionsOwnerTask),
    takeEvery(switchSelectedSessionsReviewer, switchSelectedSessionsReviewerTask),
    takeEvery(switchSelectedSessionsStorage, switchSelectedSessionsStorageTask),
  ])
}

export default addUsersSagas
