import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Container, Button, Tooltip, IconButton } from '@material-ui/core'
import { Alert, Grid } from '@mui/material'

import PropTypes from 'prop-types'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import SwitchTenantContent from '../SwitchTenantContent'

const SwitchTenantPage = ({ identityMe, handleLogoutClick }) => {
  const location = useLocation()
  const authError = location.state?.tenantAuthError

  const history = useHistory()

  const handleGoBackButtonClick = useCallback(() => {
    history.goBack()
  }, [history])
  return (
    <Container maxWidth='lg'>
      <Grid container direction='column' spacing={2}>
        <Grid item container direction='row' alignItems='center' spacing={1} sx={{ p: 1, m: -1 }}>
          <Grid item>
            <Tooltip title='Go back'>
              <IconButton onClick={handleGoBackButtonClick}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            <h1>Available Tenants</h1>
          </Grid>
          <Grid item sx={{ ml: 'auto' }}>
            <Button color='primary' variant='outlined' onClick={handleLogoutClick}>
              Log out
            </Button>
          </Grid>
        </Grid>
        {authError && (
          <Grid item>
            <Alert severity='error'>{authError}</Alert>
          </Grid>
        )}
        <Grid item>
          <SwitchTenantContent identityMe={identityMe} />
        </Grid>
      </Grid>
    </Container>
  )
}

SwitchTenantPage.propTypes = {
  identityMe: PropTypes.shape({
    userTenants: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        tenantName: PropTypes.string,
        tenantSubdomain: PropTypes.string,
      })
    ),
  }),
  handleLogoutClick: PropTypes.func.isRequired,
}

export default SwitchTenantPage
