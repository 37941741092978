const articlesRequests = {
  createSystemAnnouncementRequest: (data) => ({
    url: `article-service/api/announcements/system`,
    method: 'post',
    data,
  }),
  createTenantAnnouncementRequest: (data) => ({
    url: `article-service/api/announcements/tenant`,
    method: 'post',
    data,
  }),
  deleteAnnouncementRequest: ({ id }) => ({
    url: `article-service/api/announcements/${id}`,
    method: 'delete',
  }),
  updateAnnouncementRequest: (data) => ({
    url: `article-service/api/announcements/${data.Id}`,
    method: 'put',
    data,
  }),
  updateAnnouncementStatusRequest: ({ id, status }) => ({
    url: `article-service/api/announcements/${id}/status`,
    method: 'put',
    data: {
      status,
    },
  }),
  updateAnnouncementSeverityRequest: ({ id, severity }) => ({
    url: `article-service/api/announcements/${id}/severity`,
    method: 'put',
    data: {
      severity,
    },
  }),
  getAnnouncementRequest: ({ id }) => ({
    url: `article-service/api/announcements/${id}`,
    method: 'get',
  }),
  getAnnouncementsRequest: ({ skip, take } = { skip: 0, take: 50 }) => ({
    url: `article-service/api/announcements`,
    method: 'get',
    params: {
      skip,
      take,
    },
  }),
  getUnreadAnnouncementsRequest: () => ({
    url: `article-service/api/announcements/unread`,
    method: 'get',
  }),
  markAnnouncementsAsReadRequest: (data) => ({
    url: `article-service/api/announcements/mark-as-read`,
    method: 'post',
    data,
  }),

  createTrainingMaterialRequest: (data) => ({
    url: `article-service/api/training-materials`,
    method: 'post',
    data,
  }),
  deleteTrainingMaterialRequest: ({ id }) => ({
    url: `article-service/api/training-materials/${id}`,
    method: 'delete',
  }),
  getTrainingMaterialRequest: ({ id }) => ({
    url: `article-service/api/training-materials/${id}`,
    method: 'get',
  }),
  getTrainingMaterialsRequest: ({ skip, take, search, contentId, attach }) => ({
    url: `article-service/api/training-materials`,
    method: 'get',
    params: {
      skip,
      take,
      search,
      contentId,
      attach,
    },
  }),

  // content training materials
  createContentTrainingMaterialRequest: (data) => ({
    url: `article-service/api/content-training-materials`,
    method: 'post',
    data,
  }),
  deleteContentTrainingMaterialRequest: ({ id }) => ({
    url: `article-service/api/content-training-materials/${id}`,
    method: 'delete',
  }),

  assignContentTrainingMaterialToUsersRequest: ({ id, userIds }) => ({
    url: `article-service/api/content-training-materials/${id}/users`,
    method: 'post',
    data: {
      userIds,
    },
  }),
  getContentTrainingMaterialUsersRequest: ({ id }) => ({
    url: `article-service/api/content-training-materials/${id}/users`,
    method: 'get',
  }),
  markContentTrainingMaterialAsReadRequest: (data) => ({
    url: `article-service/api/content-training-materials/mark-as-read`,
    method: 'post',
    data,
  }),
  getUnreadContentTrainingMaterialsCountRequest: ({ contentId }) => ({
    url: `article-service/api/content-training-materials/unread/count`,
    method: 'get',
    params: {
      contentId,
    },
  }),
  updateTrainingMaterialRequest: (data) => ({
    url: `article-service/api/training-materials/${data.Id}`,
    method: 'put',
    data,
  }),

  getContentTrainingMaterialRequest: ({ id }) => ({
    url: `article-service/api/content-training-materials/${id}`,
    method: 'get',
  }),
  getContentTrainingMaterialsRequest: ({ skip, take, search, contentId }) => ({
    url: `article-service/api/content-training-materials`,
    method: 'get',
    params: {
      skip,
      take,
      search,
      contentId,
    },
  }),
}

export default articlesRequests
