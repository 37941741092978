import { FolderPermission, TenantPermission } from '@tabeeb/enums'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

import { hasFolderPermission } from '../helpers'

import { getCurrentFolder, getCurrentFolderId } from '.'

export const getHasCreateContentsPermission = (state) => {
  return (
    (getCurrentFolderId(state)
      ? hasFolderPermission(getCurrentFolder(state)?.FolderUsersPermissions, FolderPermission.CreateContent)
      : true) && hasTenantPermission(state, TenantPermission.CreateSessions)
  )
}

export const getHasCreateContentFoldersPermission = (state) =>
  hasTenantPermission(state, TenantPermission.CreateFolders)

export const getHasSwitchOwnerPermission = (state) => hasTenantPermission(state, TenantPermission.SwitchContentOwner)

export const getHasSwitchReviewerPermission = (state) =>
  hasTenantPermission(state, TenantPermission.SwitchContentReviewer)

export const getHasAddContentUsersPermission = (state) => hasTenantPermission(state, TenantPermission.AddContentUsers)

export const getHasShareContentPermission = (state) => hasTenantPermission(state, TenantPermission.ShareContents)

export const getHasSwitchStoragePermission = (state) =>
  hasTenantPermission(state, TenantPermission.UpdateSessionStorage)

export const getHasChangeReviewStatusPermission = (state) =>
  hasTenantPermission(state, TenantPermission.ChangeContentReviewStatus)
