import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const getFormRequest = createAction('GET_FORM_REQUEST')
export const getFormSuccess = createAction('GET_FORM_SUCCESS')
export const setOpenedForm = createAction('SET_OPENED_FORM')
export const updateFormInfoControlsList = createAction('UPDATE_FORM_INFO_CONTROLS_LIST')

export const setFormInfoLoading = createAction('SET_FORM_INFO_LOADING')
export const getFormInfoById = createAction('GET_FORM_INFO_BY_ID')

export const getFormByIdRequest = createAction('GET_FORM_BY_ID_REQUEST')
export const getFormByIdSuccess = createAction('GET_FORM_BY_ID_SUCCESS')
export const getFormByIdFailed = createAction('GET_FORM_BY_ID_FAILED')

export const getTagsListRequest = createAction('GET_TAGS_LIST_REQUEST')
export const getTagsListSuccess = createAction('GET_TAGS_LIST_SUCCESS')

export const setAvailableTags = createAction('SET_AVAILABLE_TAGS')

export const addTagToControl = createApiActions('ADD_TAG_TO_CONTROL')
export const addTagToFormControl = createAction('ADD_TAG_TO_FORM_CONTROL')
export const createTagAndAddToControl = createApiActions('CREATE_TAG_AND_ADD_TO_CONTROL')

export const removeTagFromControlRequest = createAction('REMOVE_TAG_FROM_CONTROL_REQUEST')
export const removeTagFromControlSuccess = createAction('REMOVE_TAG_FROM_CONTROL_SUCCESS')

export const removeTagFromFormControl = createAction('REMOVE_TAG_FROM_FORM_CONTROL')

export const openAddVqaModelToFormDialog = createAction('OPEN_ADD_VQA_MODEL_TO_FORM_DIALOG')
export const closeAddVqaModelToFormDialog = createAction('CLOSE_ADD_VQA_MODEL_TO_FORM_DIALOG')

export const getVqaModelsForFormRequest = createAction('GET_VQA_MODELS_FOR_FORM_REQUEST')
export const getVqaModelsForFormSuccess = createAction('GET_VQA_MODELS_FOR_FORM_SUCCESS')
export const getVqaModelsForFormFailed = createAction('GET_VQA_MODELS_FOR_FORM_FAILED')

export const attachVqaModelToFormRequest = createAction('ATTACH_VQA_MODEL_TO_FORM_REQUEST')
export const attachVqaModelToFormSuccess = createAction('ATTACH_VQA_MODEL_TO_FORM_SUCCESS')
export const attachVqaModelToFormFailed = createAction('ATTACH_VQA_MODEL_TO_FORM_FAILED')

export const detachVqaModelFromFormRequest = createAction('DETACH_VQA_MODEL_FROM_FORM_REQUEST')
export const detachVqaModelFromFormSuccess = createAction('DETACH_VQA_MODEL_FROM_FORM_SUCCESS')
export const detachVqaModelFromFormFailed = createAction('DETACH_VQA_MODEL_FROM_FORM_FAILED')

export const setSelectedFormControlOption = createAction('SET_SELECTED_FORM_CONTROL_OPTION')

export const getVqaPoliciesForFormRequest = createAction('GET_VQA_POLICIES_FOR_FORM_REQUEST')
export const getVqaPoliciesForFormSuccess = createAction('GET_VQA_POLICIES_FOR_FORM_SUCCESS')
export const getVqaPoliciesForFormFailed = createAction('GET_VQA_POLICIES_FOR_FORM_FAILED')

export const updateFormControlProcessing = createApiActions('UPDATE_FORM_CONTROL_PROCESSING')

export const attachVqaPolicyToFormControlOptionRequest = createAction(
  'ATTACH_VQA_POLICY_TO_FORM_CONTROL_OPTION_REQUEST'
)
export const attachVqaPolicyToFormControlOptionSuccess = createAction(
  'ATTACH_VQA_POLICY_TO_FORM_CONTROL_OPTION_SUCCESS'
)
export const attachVqaPolicyToFormControlOptionFailed = createAction('ATTACH_VQA_POLICY_TO_FORM_CONTROL_OPTION_FAILED')

export const detachVqaPolicyFromFormControlOptionRequest = createAction(
  'DETACH_VQA_POLICY_FROM_FORM_CONTROL_OPTION_REQUEST'
)
export const detachVqaPolicyFromFormControlOptionSuccess = createAction(
  'DETACH_VQA_POLICY_FROM_FORM_CONTROL_OPTION_SUCCESS'
)
export const detachVqaPolicyFromFormControlOptionFailed = createAction(
  'DETACH_VQA_POLICY_FROM_FORM_CONTROL_OPTION_FAILED'
)

export const openAttachAIExpressionDialog = createAction('OPEN_AI_EXPRESSION_DIALOG')
export const closeAttachAIExpressionDialog = createAction('CLOSE_AI_EXPRESSION_DIALOG')
export const attachAiExpressionToFormControlOption = createApiActions('ATTACH_AI_EXPRESSION_TO_FORM_CONTROL_OPTION')
export const detachAiExpressionFromFormControlOption = createApiActions('DETACH_AI_EXPRESSION_FROM_FORM_CONTROL_OPTION')
export const getAiClassesForFormInfo = createApiActions('GET_AI_CLASSES_FOR_FORM_INFO')

export const saveFormSettings = createAction('FORM_INFO_PAGE_SAVE_FORM_SETTINGS')

export const resetState = createAction('FORM_INFO_PAGE_RESET_STATE')
