import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { CircleOutlined, FiberManualRecordOutlined, ViewInArOutlined } from '@mui/icons-material'

import { AnnotationType } from '@tabeeb/enums'

import {
  AIHotspotAnnotation,
  ArrowAnnotation,
  AreaAnnotation,
  ArrowBothAnnotation,
  LineAnnotation,
  CircleAnnotation,
  CircleFilledAnnotation,
  CylinderSmallAnnotation,
  DistanceAnnotation,
  RectangleAnnotation,
  RectangleFilledAnnotation,
  HeightAnnotation,
  PolygonAnnotation,
  StrokeAnnotation,
  HotspotAnnotation,
} from '@tabeeb/shared/icons'

import useStyles from './styles'

export const Preset = {
  AIHotspot: { id: 'ai-hotspot', type: AnnotationType.AIHotspot, icon: AIHotspotAnnotation, title: 'AI Hotspot' },
  Stroke: { id: 'stroke', type: AnnotationType.Stroke, icon: StrokeAnnotation, title: 'Stroke' },
  Hotspot: { id: 'hotspot', type: AnnotationType.Hotspot, icon: HotspotAnnotation, title: 'Hotspot' },
  Circle: { id: 'circle', type: AnnotationType.Ellipse, icon: CircleAnnotation, title: 'Circle' },
  CircleFilled: {
    id: 'circle-filled',
    type: AnnotationType.Ellipse_Filled,
    icon: CircleFilledAnnotation,
    title: 'Filled circle',
  },
  Line: { id: 'line', type: AnnotationType.Line, icon: LineAnnotation, title: 'Line' },
  Polygon: { id: 'polygon', type: AnnotationType.Polygon, icon: PolygonAnnotation, title: 'Polygon' },
  Rectangle: { id: 'rectangle', type: AnnotationType.Rectangle, icon: RectangleAnnotation, title: 'Rectangle' },
  RectangleFilled: {
    id: 'rectangle-filled',
    type: AnnotationType.Rectangle_Filled,
    icon: RectangleFilledAnnotation,
    title: 'Filled rectangle',
  },
  Arrow: { id: 'arrow', type: AnnotationType.ArrowEnd, icon: ArrowAnnotation, title: 'Arrow' },
  ArrowBoth: { id: 'arrow-both', type: AnnotationType.ArrowBoth, icon: ArrowBothAnnotation, title: 'Double arrow' },
  Box: {
    id: 'box',
    type: AnnotationType.Box,
    icon: ViewInArOutlined,
    title: 'Box',
    configuration: {
      scale: 0.4,
    },
  },
  CylinderLarge: {
    id: 'cylinder-large',
    type: AnnotationType.Cylinder,
    icon: CircleOutlined,
    title: 'Large cylinder',
    configuration: {
      scale: 0.4,
    },
  },
  CylinderMedium: {
    id: 'cylinder-medium',
    type: AnnotationType.Cylinder,
    icon: FiberManualRecordOutlined,
    title: 'Medium cylinder',
    configuration: {
      scale: 0.15,
    },
  },
  CylinderSmall: {
    id: 'cylinder-small',
    type: AnnotationType.Cylinder,
    icon: CylinderSmallAnnotation,
    title: 'Small cylinder',
    configuration: {
      scale: 0.05,
    },
  },
  Polyline: { id: 'polyline', type: AnnotationType.Polyline, icon: LineAnnotation, title: 'Polyline' },
  Distance: { id: 'distance', type: AnnotationType.Distance, icon: DistanceAnnotation, title: 'Distance' },
  Height: { id: 'height', type: AnnotationType.Height, icon: HeightAnnotation, title: 'Height' },
  Area: { id: 'area', type: AnnotationType.Area, icon: AreaAnnotation, title: 'Area' },
}

const AnnotationPresetSelector = ({ presets, color, enabled, selected, onSelect }) => {
  const classes = useStyles()

  const onSelectShape = useCallback(
    (preset) => () => {
      onSelect(preset)
    },
    [onSelect]
  )

  return (
    <div className={classes.container}>
      {presets.map((preset) => {
        const { icon: Icon, title } = preset
        const annotationColor = preset.id === selected && enabled ? color.toLowerCase() : null

        return (
          <div key={preset.id} title={title} className={classes.icon}>
            <Icon
              size='small'
              color='action'
              fontSize='inherit'
              onClick={onSelectShape(preset)}
              onKeyDown={onSelectShape(preset)}
              className={classNames({
                [classes.bright]: annotationColor === '#ffffff',
              })}
              style={{ color: annotationColor }}
            />
          </div>
        )
      })}
    </div>
  )
}

AnnotationPresetSelector.propTypes = {
  presets: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Preset))).isRequired,
  color: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
}

export default memo(AnnotationPresetSelector)
