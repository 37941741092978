import { combineActions, handleActions } from 'redux-actions'
import { leaveContent } from '@tabeeb/modules/shared/content/actions'
import { closeUniqueAiObjectDialog, openAiReportDialog } from '../actions'

const defaultState = false

export default handleActions(
  {
    [openAiReportDialog]() {
      return true
    },
    [combineActions(closeUniqueAiObjectDialog, leaveContent)]() {
      return false
    },
  },
  defaultState
)
