const styles = () => ({
  form: {
    flexGrow: 1,
  },
  avatar: {
    height: 24,
    width: 24,
    marginRight: 6,
  },
  icon: {
    fontSize: 16,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '5px 0px 15px 0px',
  },
})

export default styles
