/* eslint-disable jsx-a11y/media-has-caption */
import { memo, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core'

import { presentationSelectors } from '@tabeeb/modules/presentation'

import styles from './styles'

const BACKGROUND_UPDATE_INTERVAL = 1000

const updateBackground = (video, background) => {
  if (!video || !background) return

  const width = video.offsetWidth
  const height = video.offsetHeight

  let canvas = document.createElement('canvas')

  canvas.width = width
  canvas.height = height

  const context = canvas.getContext('2d')
  context.drawImage(video, 0, 0, width, height)

  const backgroundImageBase64 = canvas.toDataURL('image/png')
  background.setAttribute('src', backgroundImageBase64)

  canvas = null
}

const UserVideo = ({ classes, user, showBackground = true, videoClassName = 'tabeebUserVideo' }) => {
  const videoRef = useRef()
  const backgroundRef = useRef()

  const mainScreenVideoStatusSelector = useMemo(presentationSelectors.makeGetIsUserVideoOnMainScreen, [])
  const isUserVideoOnMainScreen = useSelector((state) => mainScreenVideoStatusSelector(state, { userId: user.id }))

  const videoPlayStatusSelector = useMemo(presentationSelectors.makeGetIsUserVideoPlaying, [])
  const isVideoMuted = useSelector((state) => !videoPlayStatusSelector(state, { Id: user.id }))

  const videoMirroredStatusSelector = useMemo(presentationSelectors.makeGetIsUserVideoMirrored, [])
  const isVideoMirrored = useSelector((state) => videoMirroredStatusSelector(state, { userId: user.id }))

  useEffect(() => {
    if (isVideoMuted || isUserVideoOnMainScreen) {
      return
    }

    const intervalId = setInterval(
      () => updateBackground(videoRef.current, backgroundRef.current),
      BACKGROUND_UPDATE_INTERVAL
    )

    return () => {
      clearInterval(intervalId)
    }
  }, [isVideoMuted, isUserVideoOnMainScreen])

  return (
    <div
      className={classNames(classes.container, {
        [classes.hidden]: isVideoMuted || isUserVideoOnMainScreen,
        [classes.mirrored]: isVideoMirrored,
      })}
    >
      {showBackground && <img ref={backgroundRef} className={classes.background} alt='video' />}
      <video
        className={classNames(videoClassName, classes.video)}
        ref={videoRef}
        data-id={user.id}
        autoPlay='autoPlay'
        onContextMenu={() => false}
        muted
        playsInline
      />
    </div>
  )
}

UserVideo.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    hidden: PropTypes.string.isRequired,
    mirrored: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(UserVideo))
