export default (theme) => {
  return {
    container: {
      width: 218,
      position: 'absolute',
      zIndex: 1,
      outline: 'none',
      '& .MuiCardHeader-root': {
        padding: '5px 16px',

        '& .MuiCardHeader-title': {
          fontSize: 16,
        },
      },
    },
    content: {
      paddingBottom: 0,
      padding: '0px !important',

      '& svg': {
        marginRight: theme.spacing(),
      },

      '& .MuiMenuItem-root': {
        fontSize: 14,
      },
    },
    icon: {
      strokeWidth: 0.5,
      stroke: theme.palette.grey[400],
    },
  }
}
