import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ArrowDownward } from '@material-ui/icons'

import { galleryActions, gallerySelectors } from '@tabeeb/modules/gallery'

import PanelButton from '../../ZoomButtons/PanelButton'

const FollowingPageButton = () => {
  const dispatch = useDispatch()

  const display = useSelector(gallerySelectors.getCanSwitchPages)
  const followingPageId = useSelector(gallerySelectors.getFollowingPageId)

  const onSelectFollowingPage = useCallback(() => {
    dispatch(galleryActions.onSelectGalleryItem({ pageId: followingPageId }))
  }, [dispatch, followingPageId])

  return (
    <PanelButton
      Icon={ArrowDownward}
      display={display}
      title='Following page'
      onClick={onSelectFollowingPage}
      disabled={!followingPageId}
    />
  )
}

export default memo(FollowingPageButton)
