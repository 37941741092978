import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { withStyles } from '@material-ui/core'

import User from '../User'

import * as usersSelectors from '../../selectors'

import styles from './styles'

const UserList = ({ classes }) => {
  const users = useSelector(usersSelectors.getUsersListForCurrentUser)

  const usersItems = users
    .filter((item) => !item.isDeleted)
    .map((item, index) => {
      return <User index={index} key={item.id} user={item} />
    })

  return <ul className={classes.container}>{usersItems}</ul>
}

UserList.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(UserList))
