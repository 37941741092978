export default (theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: 107.34,
    },
    time: {
      padding: 5,
      marginTop: theme.spacing(),
      textAlign: 'center',
    },
    controls: {
      marginRight: theme.spacing(),
    },
    button: {
      minWidth: 100,
    },
    stop: {
      marginLeft: -5,
    },
    start: {
      marginLeft: -5,
      color: 'red',
    },
  }
}
