export default (theme) => {
  return {
    recipients: {
      maxHeight: 128,
      overflowY: 'auto',
    },
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    input: {
      marginTop: theme.spacing() * 2,
    },
  }
}
