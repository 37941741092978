import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'

import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import Routes from '@tabeeb/routes'
import { DateInfo, PageHeader, PageLayout, Pagination, TableLoadingProgress, TableWrapper } from '@tabeeb/uikit'
import { usePagination } from '@tabeeb/shared/utils/hooks'

import {
  AnnouncementCreateButton,
  AnnouncementScopeChip,
  AnnouncementSeverityChip,
  ArticlePublicationStatusChip,
} from '@tabeeb/modules/articles/components'
import { useAnnouncements } from '@tabeeb/modules/articles/hooks'
import AnnouncementMenu from '@tabeeb/modules/articles/components/AnnouncementMenu'
import { KeyboardArrowRightOutlined } from '@mui/icons-material'

const AnnouncementsPage = () => {
  const dispatch = useDispatch()
  const [announcementsCount, setAnnouncementsCount] = useState(0)

  const {
    current: currentPage,
    pages,
    size,
    onPageNumberChange,
    onPageSizeChange,
  } = usePagination({ total: announcementsCount })
  const {
    announcements,
    total,
    loading,
    onDeleteAnnouncement,
    onUpdateAnnouncementStatus,
    onUpdateAnnouncementSeverity,
  } = useAnnouncements({
    skip: (currentPage - 1) * size,
    take: size,
  })

  useEffect(() => {
    setAnnouncementsCount(total)
  }, [total])

  const onEdit = useCallback(
    (announcement) => {
      dispatch(push(generatePath(Routes.announcementEdit, { id: announcement.Id })))
    },
    [dispatch]
  )

  const onUpdateSeverity = useCallback(
    (id) => (severity) => {
      onUpdateAnnouncementSeverity({ id, severity })
    },
    [onUpdateAnnouncementSeverity]
  )

  return (
    <PageLayout component={Paper} variant='outlined' maxWidth='lg' spacing='page'>
      <PageHeader title='Announcements' actions={<AnnouncementCreateButton />} />
      <TableContainer>
        {loading && <TableLoadingProgress />}
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Name</TableCell>
                <TableCell align='center'>Scope</TableCell>
                <TableCell align='center'>Status</TableCell>
                <TableCell align='center'>Severity</TableCell>
                <TableCell align='left'>Created on</TableCell>
                <TableCell align='left'>Updated on</TableCell>
                <TableCell align='left'>Display period</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {announcements.length > 0 ? (
                announcements.map((announcement) => (
                  <TableRow
                    key={announcement.Id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onDoubleClick={() => onEdit(announcement)}
                  >
                    <TableCell align='left'>
                      <Typography variant='body2' fontWeight={500} maxWidth={450} noWrap>
                        {announcement.Name}
                      </Typography>
                    </TableCell>
                    <TableCell align='center' width={150}>
                      <AnnouncementScopeChip scope={announcement.Scope} />
                    </TableCell>
                    <TableCell align='center' width={150}>
                      <ArticlePublicationStatusChip status={announcement.Status} />
                    </TableCell>
                    <TableCell align='center' width={150}>
                      <AnnouncementSeverityChip
                        severity={announcement.Severity}
                        onChange={onUpdateSeverity(announcement.Id)}
                      />
                    </TableCell>
                    <TableCell align='left' width={150}>
                      <DateInfo date={announcement.CreatedOn} />
                    </TableCell>
                    <TableCell align='left' width={150}>
                      <DateInfo date={announcement.UpdatedOn} />
                    </TableCell>
                    <TableCell align='left' width={300}>
                      <Box display='flex' alignItems='center'>
                        <DateInfo date={announcement.StartDate} />

                        <KeyboardArrowRightOutlined sx={{ ml: 1, mr: 1 }} fontSize='small' />

                        <DateInfo date={announcement.ExpiryDate} />
                      </Box>
                    </TableCell>
                    <TableCell align='right' width={50}>
                      <AnnouncementMenu
                        announcement={announcement}
                        onDelete={onDeleteAnnouncement}
                        onUpdateStatus={onUpdateAnnouncementStatus}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align='center'>
                    <Typography variant='subtitle1'>
                      {loading ? 'Loading announcements' : 'No announcements'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        <Divider />
        <Pagination
          current={currentPage}
          pages={pages}
          pageSize={size}
          onPageNumberChange={onPageNumberChange}
          onPageSizeChange={onPageSizeChange}
        />
      </TableContainer>
    </PageLayout>
  )
}

export default memo(AnnouncementsPage)
