export function generateId(controlsList) {
  let id = 0
  if (controlsList) {
    let idExists = true
    while (idExists) {
      idExists = controlsList.some((control) => control.TempId === id)
      if (idExists) id++
    }
  }
  return id
}
