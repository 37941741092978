import PropTypes from 'prop-types'
import { Fade } from '@material-ui/core'

import './styles.less'

const ErrorMessage = ({ errorMessage }) => {
  return errorMessage ? (
    <Fade in={Boolean(errorMessage)}>
      <div className='form-error-message-bar'>{errorMessage}</div>
    </Fade>
  ) : null
}

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
}

export default ErrorMessage
