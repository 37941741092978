import { makeStyles } from '@material-ui/core'
import { darken, lighten } from '@mui/system'

export default makeStyles((theme) => {
  return {
    searchInputContainer: {
      padding: '0 5px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tenantsListContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: 450,
    },
    link: {
      padding: '10px 0',
      fontSize: 14,
      color: 'black',
      textAlign: 'center',
      textDecoration: 'none !important',
    },
    groupHeader: {
      position: 'sticky',
      top: '-8px',
      padding: '4px 10px',
      color: theme.palette.primary.main,
      backgroundColor:
        theme.palette.type === 'light'
          ? lighten(theme.palette.primary.light, 0.85)
          : darken(theme.palette.primary.main, 0.8),
      zIndex: 1,
    },
    groupItems: {
      padding: 0,
    },
    listOptionItem: {
      padding: 0,
    },
  }
})
