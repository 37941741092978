import { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { Autocomplete } from '@material-ui/lab'
import { TextField, withStyles } from '@material-ui/core'

import styles from './styles'

const getDayTimesWithInterval = (interval) => {
  const date = new Date()

  const dayStartTime = moment(date).startOf('day')
  const dayEndTime = moment(date).endOf('day')

  const values = []
  const tempTime = dayStartTime.clone()

  // eslint-disable-next-line no-unmodified-loop-condition
  while (tempTime < dayEndTime) {
    values.push(tempTime.clone().toDate())

    tempTime.add(interval, 'minutes')
  }

  return values
}

const TimeDropdownPicker = ({
  classes,
  value,
  error,
  size,
  variant,
  onChange,
  label,
  disabled,
  timeFormat,
  minutesStep,
}) => {
  const values = useMemo(() => getDayTimesWithInterval(minutesStep), [minutesStep])

  const handleChange = useCallback(
    (event, value) => {
      onChange(value)
    },
    [onChange]
  )

  const handleTimeChange = useCallback(
    (event) => {
      const time = moment(event.target.value, timeFormat)
      if (time.isValid()) {
        onChange(time.toDate())
      }
    },
    [onChange]
  )

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      event.stopPropagation()
      event.target.blur()
    }
  }, [])

  return (
    <Autocomplete
      className={classes.root}
      classes={{ popper: classes.popper, listbox: classes.listbox }}
      fullWidth
      label={label}
      disableClearable
      disabled={disabled}
      value={value}
      options={values}
      onChange={handleChange}
      renderOption={(time) => moment(time).format(timeFormat)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
          }}
          error={error}
          label={label}
          size={size}
          variant={variant}
          onBlur={handleTimeChange}
          onKeyDown={handleKeyDown}
        />
      )}
      getOptionLabel={(time) => moment(time).format(timeFormat)}
      filterOptions={(options) => options}
      getOptionSelected={(option, value) => moment(option).format(timeFormat) === moment(value).format(timeFormat)}
    />
  )
}

TimeDropdownPicker.defaultProps = {
  disabled: false,
  minutesStep: 15,
  timeFormat: 'h:mm A',
}

TimeDropdownPicker.propTypes = {
  classes: PropTypes.shape({
    listbox: PropTypes.string.isRequired,
    popper: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.bool.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  minutesStep: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired,
  timeFormat: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memo(withStyles(styles)(TimeDropdownPicker))
