export default (theme) => {
  return {
    container: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      width: '100%',
      fontSize: '14px',
      backgroundColor: 'rgb(255, 255, 255, 0.4)',
      color: '#616161',
      position: 'relative',
    },
  }
}
