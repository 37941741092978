export default (theme) => {
  return {
    container: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 0,
      flexGrow: 1,

      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  }
}
