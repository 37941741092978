import { memo } from 'react'
import PropTypes from 'prop-types'
import { Marker as GoogleMapMarker } from '@react-google-maps/api'

import './styles.less'

function pinSymbol(color) {
  return {
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
    fillColor: color,
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 2,
    scale: 1,
  }
}

const Marker = ({ latitude, longitude, color, ...rest }) => {
  return <GoogleMapMarker position={{ lat: latitude, lng: longitude }} icon={pinSymbol(color)} {...rest} />
}

Marker.propTypes = {
  color: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
}

export default memo(Marker)
