import { memo } from 'react'
import PropTypes from 'prop-types'

import { ListItem } from '@mui/material'

import { UserInfo } from '@tabeeb/uikit'

const ContentTrainingMaterialUserListItem = ({ user, children }) => {
  return (
    <ListItem disableGutters>
      <UserInfo
        item={{
          Id: user.id,
          Name: user.displayName,
          Email: user.email,
          IsAccountDeleted: user.isAccountDeleted,
          AvatarUrl: user.avatarUrl,
        }}
      />

      {children}
    </ListItem>
  )
}

ContentTrainingMaterialUserListItem.propTypes = {
  children: PropTypes.node,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isAccountDeleted: PropTypes.bool.isRequired,
    avatarUrl: PropTypes.string,
  }).isRequired,
}

export default memo(ContentTrainingMaterialUserListItem)
