import { memo } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { TenantPermission } from '@tabeeb/enums'
import Routes from '@tabeeb/routes'
import { RouteWrapper } from '@tabeeb/shared/routes/components'

import TrainingMaterialCreatePage from './TrainingMaterialCreatePage'
import TrainingMaterialEditPage from './TrainingMaterialEditPage'
import TrainingMaterialViewPage from './TrainingMaterialViewPage'
import TrainingMaterialsPage from './TrainingMaterialsPage'

const TrainingMaterialPages = () => {
  return (
    <Switch>
      <Route
        exact
        key={Routes.trainingMaterialCreate}
        path={Routes.trainingMaterialCreate}
        render={() => (
          <RouteWrapper authorize permission={TenantPermission.ManageTrainingMaterials}>
            <TrainingMaterialCreatePage />
          </RouteWrapper>
        )}
      />

      <Route
        exact
        key={Routes.trainingMaterialEdit}
        path={Routes.trainingMaterialEdit}
        render={({
          match: {
            params: { id },
          },
        }) => (
          <RouteWrapper authorize permission={TenantPermission.ManageTrainingMaterials}>
            <TrainingMaterialEditPage id={parseInt(id, 10)} />
          </RouteWrapper>
        )}
      />

      <Route
        exact
        key={Routes.trainingMaterialView}
        path={Routes.trainingMaterialView}
        render={({
          match: {
            params: { id },
          },
        }) => <TrainingMaterialViewPage id={parseInt(id, 10)} />}
      />

      <Route
        exact
        key={Routes.trainingMaterials}
        path={Routes.trainingMaterials}
        render={() => <TrainingMaterialsPage />}
      />

      <Redirect to={Routes.trainingMaterials} />
    </Switch>
  )
}

export default memo(TrainingMaterialPages)
