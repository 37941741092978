import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useProgressiveLoadingResource } from '@tabeeb/modules/sncFilters/hooks'
import { FetchStatus } from '@tabeeb/enums'
import { getPowerBIReportDependentModules, setPowerBIReportDependentModulesRefetch } from '../actions'
import { getPowerBIReportDependentModulesState } from '../selectors'

const usePowerBIReportDependentModules = (reportId) => {
  const dispatch = useDispatch()
  const requestParams = useMemo(() => ({ reportId }), [reportId])
  const { requireRefetch, deleteStatus, items } = useSelector(getPowerBIReportDependentModulesState)

  const { status, loading, hasMore, onLoadMore, onReload } = useProgressiveLoadingResource({
    request: getPowerBIReportDependentModules.request,
    payload: requestParams,
  })

  useEffect(() => {
    if (requireRefetch) {
      onReload()
      dispatch(setPowerBIReportDependentModulesRefetch(false))
    }
  }, [dispatch, onReload, requireRefetch])

  return {
    fetchStatus: status,
    loading: loading || deleteStatus === FetchStatus.Loading,
    dependentModules: items || [],
    hasMore,
    onLoadMore,
    onReload,
  }
}

export default usePowerBIReportDependentModules
