import { memo } from 'react'

import { Message } from '@material-ui/icons'
import { Typography } from '@material-ui/core'

import useStyles from './styles'

const NoMessages = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Message className={classes.icon} />
      <Typography>Chat history is empty...</Typography>
    </div>
  )
}

export default memo(NoMessages)
