import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    tab: {
      height: 566,
      width: 685,
    },
  }
})
