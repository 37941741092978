import { memo } from 'react'
import PropTypes from 'prop-types'

import { Field, useFormikContext } from 'formik'

import { RefreshOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import { FormikTextField } from '@tabeeb/shared/forms'
import { generatePassword } from '@tabeeb/modules/invites/utils'

const PasswordField = ({ disabled, name, label }) => {
  const formik = useFormikContext()

  return (
    <Field
      name={name}
      label={label}
      disabled={disabled}
      component={FormikTextField}
      size='small'
      endAdornment={
        <IconButton
          disabled={disabled}
          onClick={() => {
            formik.setFieldValue(name, generatePassword())
          }}
          size='small'
        >
          <Tooltip title='Generate password'>
            <RefreshOutlined />
          </Tooltip>
        </IconButton>
      }
    />
  )
}

PasswordField.defaultProps = {
  disabled: false,
}

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default memo(PasswordField)
