import { FormItemType } from '../../../Enums'

export function createOption(control) {
  let newOption
  if (control.FormItemType === FormItemType.Tab) {
    newOption = {
      Id: _getAvailableId(control.Tabs),
      Value: control.Tabs.length > 0 ? `Tab ${_getAvailableId(control.Tabs)}` : 'Tab 1',
      Order: control.Tabs.length,
    }
  } else {
    newOption = {
      Id: _getAvailableId(control.Options),
      Value: control.Options.length > 0 ? `Option ${_getAvailableId(control.Options)}` : 'Option 1',
      Order: control.Options.length,
      Conditions: null,
    }
  }

  return newOption
}

function _getAvailableId(options) {
  let id = 1
  if (options) {
    let idExists = true
    while (idExists) {
      idExists = options.some((option) => option.Id === id)
      if (idExists) id++
    }
  }
  return id
}
