import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.reset](state, action) {
      return defaultState
    },
    [combineActions(
      rawActions.getLatestPolicyRequest,
      rawActions.getLatestPolicySuccess,
      rawActions.getLatestPoliciesWithAcceptanceStatusRequest,
      rawActions.getLatestPoliciesWithAcceptanceStatusSuccess
    )](state, action) {
      return false
    },
    [combineActions(rawActions.getLatestPolicyFailed, rawActions.getLatestPoliciesWithAcceptanceStatusFailed)](
      state,
      action
    ) {
      return true
    },
  },
  defaultState
)
