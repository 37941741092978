import { put, takeLatest, all, select } from 'redux-saga/effects'

import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'
import * as notificationActions from '../../notification/actions'
import * as aiActions from '../actions'

function* getErrorMessage(errorCode) {
  const formTitle = yield select(getFormTitle)

  switch (errorCode) {
    case 2153: // OnlyOneVQAModelPerFormPerTenantAllowed
      return `Only one VQA model can be attached to a ${formTitle}.`
    default:
      return 'Failed to attach a model.'
  }
}

function* onAddVQAModelFailed({ response }) {
  const errorMessage = yield getErrorMessage(response.data.Error.Code)
  yield put(notificationActions.onAddErrorNotification({ message: errorMessage }))
}

function* addVQAModel() {
  yield all([takeLatest(aiActions.attachVqaModelToFormFailed, onAddVQAModelFailed)])
}

export default addVQAModel
