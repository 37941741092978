import { tabeebTheme } from '@tabeeb/uikit'

const hashCode = (s) => {
  return s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}

export const getUserColorByName = (name) => {
  const value = hashCode(name) / 100

  const c = (value & 0x00ffffff).toString(16).toUpperCase()

  return '#00000'.substring(0, 7 - c.length) + c
}

export const getUserColor = (user) => {
  if (user.isDeleted || user.isAccountDeleted || user.IsAccountDeleted) {
    return tabeebTheme.palette.background.user.deleted
  }

  return getUserColorByName(user.displayName || user.Name || '')
}
