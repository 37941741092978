import { createAction } from 'redux-actions'

export const resetAnnotationsState = createAction('RESET_ANNOTATIONS_STATE')

export const addAnnotationRequest = createAction('ADD_ANNOTATION_REQUEST')
export const addAnnotationFailed = createAction('ADD_ANNOTATION_FAILED')
export const addAnnotationSuccess = createAction('ADD_ANNOTATION_SUCCESS')

export const addAudioAnnotationRequest = createAction('ADD_AUDIO_ANNOTATION_REQUEST')
export const addAudioAnnotationFailed = createAction('ADD_AUDIO_ANNOTATION_FAILED')
export const addAudioAnnotationSuccess = createAction('ADD_AUDIO_ANNOTATION_SUCCESS')

export const deleteContentAnnotations = createAction('DELETE_CONTENT_ANNOTATIONS')
export const deleteAnnotationsRequest = createAction('DELETE_ANNOTATIONS_REQUEST')
export const deleteAnnotationsSuccess = createAction('DELETE_ANNOTATIONS_SUCCESS')
export const deleteAnnotationsFailed = createAction('DELETE_ANNOTATIONS_FAILED')

export const updateContentAnnotation = createAction('UPDATE_CONTENT_ANNOTATION')
export const updateAnnotationRequest = createAction('UPDATE_ANNOTATION_REQUEST')
export const updateAnnotationSuccess = createAction('UPDATE_ANNOTATION_SUCCESS')
export const updateAnnotationFailed = createAction('UPDATE_ANNOTATION_FAILED')

export const restoreContentAnnotations = createAction('RESTORE_CONTENT_ANNOTATIONS')
export const restoreAnnotationsRequest = createAction('RESTORE_ANNOTATIONS_REQUEST')
export const restoreAnnotationsSuccess = createAction('RESTORE_ANNOTATIONS_SUCCESS')
export const restoreAnnotationsFailed = createAction('RESTORE_ANNOTATIONS_FAILED')

export const updateAiAnnotationRequest = createAction('UPDATE_AI_ANNOTATION_REQUEST')
export const updateAiAnnotationSuccess = createAction('UPDATE_AI_ANNOTATION_SUCCESS')

export const getAnnotationsByPage = createAction('GET_ANNOTATIONS_BY_PAGE')
export const getAnnotationsByPageRequest = createAction('GET_ANNOTATIONS_BY_PAGE_REQUEST')
export const getAnnotationsByPageSuccess = createAction('GET_ANNOTATIONS_BY_PAGE_SUCCESS')

export const getAnnotationsByPageParallelRequests = createAction('GET_ANNOTATIONS_BY_PAGE_PARALLEL_REQUESTS')
export const getAnnotationsByPageParallelSuccess = createAction('GET_ANNOTATIONS_BY_PAGE_PARALLEL_SUCCESS')

export const openHotspotDialog = createAction('OPEN_HOTSPOT_DIALOG')
export const closeHotspotDialog = createAction('CLOSE_HOTSPOT_DIALOG')

export const setUniqueAiObjectCreationMode = createAction('SET_UNIQUE_AI_OBJECT_CREATION_MODE')

export const openAnnotationMenu = createAction('OPEN_ANNOTATION_MENU')
export const closeAnnotationMenu = createAction('CLOSE_ANNOTATION_MENU')

export const openTextAnnotationEditor = createAction('OPEN_TEXT_ANNOTATION_EDITOR')
export const closeTextAnnotationEditor = createAction('CLOSE_TEXT_ANNOTATION_EDITOR')

export const getAnnotationTextByCoordinatesRequest = createAction('GET_ANNOTATION_TEXT_BY_COORDINATES_REQUEST')
export const getAnnotationTextByCoordinatesSuccess = createAction('GET_ANNOTATION_TEXT_BY_COORDINATES_SUCCESS')

export const setHighlightedAnnotationId = createAction('SET_HIGHLIGHTED_ANNOTATION_ID')
export const resetHighlightedAnnotationId = createAction('RESET_HIGHLIGHTED_ANNOTATION_ID')

export const setHighlightedAnnotationsIds = createAction('SET_HIGHLIGHTED_ANNOTATION_IDS')
export const addAnnotationsToHighlighted = createAction('ADD_ANNOTATIONS_TO_HIGHLIGHTED')
export const removeAnnotationsFromHighlighted = createAction('REMOVE_ANNOTATIONS_FROM_HIGHLIGHTED')
export const resetHighlightedAnnotationsIds = createAction('RESET_HIGHLIGHTED_ANNOTATION_IDS')

export const setHiddenAnnotationsIds = createAction('SET_HIDDEN_ANNOTATION_IDS')
export const addAnnotationsToHidden = createAction('ADD_ANNOTATIONS_TO_HIDDEN')
export const removeAnnotationsFromHidden = createAction('REMOVE_ANNOTATIONS_FROM_HIDDEN')
export const resetHiddenAnnotationsIds = createAction('RESET_HIDDEN_ANNOTATION_IDS')

export const setSelectedAnnotationsIds = createAction('SET_SELECTED_ANNOTATION_ID')
export const addAnnotationToSelection = createAction('ADD_ANNOTATION_TO_SELECTION')
export const removeAnnotationFromSelection = createAction('REMOVE_ANNOTATION_FROM_SELECTION')
export const resetSelectedAnnotationsIds = createAction('RESET_SELECTED_ANNOTATION_ID')

// undo/redo

export const redoLastAction = createAction('REDO_LAST_ACTION')
export const undoLastAction = createAction('UNDO_LAST_ACTION')

export const addUndoAction = createAction('ADD_UNDO_ACTION')
export const popUndoAction = createAction('POP_UNDO_ACTION')

export const addRedoAction = createAction('ADD_REDO_ACTION')
export const popRedoAction = createAction('POP_REDO_ACTION')

export const resetUndoActionsQueue = createAction('RESET_UNDO_ACTIONS_QUEUE')
export const resetRedoActionsQueue = createAction('RESET_REDO_ACTIONS_QUEUE')

export const setMeasurementSettings = createAction('SET_MEASUREMENT_SETTINGS')

export const openMeasurementCalibrationDialog = createAction('OPEN_MEASUREMENT_CALIBRATION_DIALOG')
export const closeMeasurementCalibrationDialog = createAction('CLOSE_MEASUREMENT_CALIBRATION_DIALOG')
