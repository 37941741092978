import { HealthDataTypes, IOTDeviceUnitType, SystemOfMeasures } from '@tabeeb/enums'

export const apexChartOptions = {
  chart: {
    type: 'area',
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    min: new Date('01 Mar 2012').getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy',
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
  noData: {
    text: 'No Data',
  },
  title: {
    align: 'left',
    margin: 20,
    offsetX: 0,
    offsetY: 15,
    floating: false,
    style: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontFamily: 'inherit',
      color: '#000000',
    },
  },
}
export const maxInchesInFoot = 12

export const healthDataTypeToUnitType = [
  {
    HealthDataType: HealthDataTypes.Temperature,
    SystemOfMeasure: SystemOfMeasures.Metric,
    UnitType: IOTDeviceUnitType.DegreesCelsius,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.Temperature,
    SystemOfMeasure: SystemOfMeasures.Imperial,
    UnitType: IOTDeviceUnitType.DegreesFahrenheit,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.BloodPressureSystolic,
    UnitType: IOTDeviceUnitType.MillimetersOfMercury,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.BloodPressureDiastolic,
    UnitType: IOTDeviceUnitType.MillimetersOfMercury,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.GlucoseLevel,
    SystemOfMeasure: SystemOfMeasures.Metric,
    UnitType: IOTDeviceUnitType.MillimolesPerLiter,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.GlucoseLevel,
    SystemOfMeasure: SystemOfMeasures.Imperial,
    UnitType: IOTDeviceUnitType.MilligramsPerDeciliter,
    IsDefaultMeasure: true,
  },
  { HealthDataType: HealthDataTypes.Pulse, UnitType: IOTDeviceUnitType.BeatsPerMinute, IsDefaultMeasure: true },
  { HealthDataType: HealthDataTypes.OxygenSaturation, UnitType: IOTDeviceUnitType.Percentage, IsDefaultMeasure: true },
  {
    HealthDataType: HealthDataTypes.Weight,
    SystemOfMeasure: SystemOfMeasures.Metric,
    UnitType: IOTDeviceUnitType.Kilogram,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.Weight,
    SystemOfMeasure: SystemOfMeasures.Imperial,
    UnitType: IOTDeviceUnitType.Pound,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.Height,
    SystemOfMeasure: SystemOfMeasures.Imperial,
    UnitType: IOTDeviceUnitType.Foot,
    IsDefaultMeasure: false,
  },
  {
    HealthDataType: HealthDataTypes.Height,
    SystemOfMeasure: SystemOfMeasures.Imperial,
    UnitType: IOTDeviceUnitType.Inch,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.Height,
    SystemOfMeasure: SystemOfMeasures.Metric,
    UnitType: IOTDeviceUnitType.Meter,
    IsDefaultMeasure: false,
  },
  {
    HealthDataType: HealthDataTypes.Height,
    SystemOfMeasure: SystemOfMeasures.Metric,
    UnitType: IOTDeviceUnitType.Centimeter,
    IsDefaultMeasure: true,
  },
  { HealthDataType: HealthDataTypes.RestingPulse, UnitType: IOTDeviceUnitType.BeatsPerMinute, IsDefaultMeasure: true },
  { HealthDataType: HealthDataTypes.PerfusionIndex, UnitType: IOTDeviceUnitType.Percentage, IsDefaultMeasure: true },
  {
    HealthDataType: HealthDataTypes.PeakExpiratoryFlow,
    UnitType: IOTDeviceUnitType.LitersPerMinute,
    IsDefaultMeasure: true,
  },
  {
    HealthDataType: HealthDataTypes.ForcedExpiratoryVolume,
    UnitType: IOTDeviceUnitType.Liters,
    IsDefaultMeasure: true,
  },
]

export const healthDataTypesWithoutMeasurementSystems = [
  HealthDataTypes.BloodPressureSystolic,
  HealthDataTypes.BloodPressureDiastolic,
  HealthDataTypes.Pulse,
  HealthDataTypes.OxygenSaturation,
  HealthDataTypes.RestingPulse,
  HealthDataTypes.PerfusionIndex,
  HealthDataTypes.PeakExpiratoryFlow,
  HealthDataTypes.ForcedExpiratoryVolume,
]
