import { combineActions, handleActions } from 'redux-actions'
import {
  closeContentSharingDialog,
  resetContentsSearchState,
  resetState,
  setSearchContentForSharingResult,
  updateSearchContentText,
} from '@tabeeb/modules/contentSharings/actions'

const defaultState = {
  contentsList: [],
  searchText: '',
}

export default handleActions(
  {
    [setSearchContentForSharingResult](state, { payload }) {
      return { ...state, contentsList: payload }
    },
    [updateSearchContentText](state, { payload }) {
      return { ...state, searchText: payload }
    },
    [combineActions(resetState, resetContentsSearchState, closeContentSharingDialog)]() {
      return defaultState
    },
  },
  defaultState
)
