import { combineReducers } from 'redux'

import selectionLists from './selectionLists'
import isSelectionListsLoading from './isSelectionListsLoading'
import editSelectionListDialog from './editSelectionListDialog'

export default combineReducers({
  editSelectionListDialog,
  isSelectionListsLoading,
  selectionLists,
})
