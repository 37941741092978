import { DeviceType } from '@tabeeb/enums'
import { isAndroid, isIOS } from 'react-device-detect'

export const isFireFoxBrowser = navigator.userAgent.indexOf('Firefox') !== -1

export const getBrowserType = () => {
  if (isIOS) {
    return DeviceType.iosBrowser
  }

  if (isAndroid) {
    return DeviceType.androidBrowser
  }

  return DeviceType.webBrowser
}
