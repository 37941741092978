import { createSelector } from 'reselect'

import { TenantPermission } from '@tabeeb/enums'
import { getQualificationsEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

export const getShowQualificationManagement = createSelector(
  [getQualificationsEnabled, (state) => hasTenantPermission(state, TenantPermission.ManageQualifications)],
  (enabledOnTenant, hasPermission) => {
    return enabledOnTenant && hasPermission
  }
)
