import { createSelector } from 'reselect'
import { getIsGridViewActive } from '@tabeeb/modules/gridView/selectors'
import { getIsPriorityViewEnabled } from '@tabeeb/modules/presentation/selectors'
import { isCallAcceptedSelector } from '@tabeeb/modules/callNotification/selectors'

export const getShowCallControls = (state) => state.mobileWhiteboard.showCallControls
export const getShowCallPreview = (state) => state.mobileWhiteboard.showCallPreview
export const getShowUserList = (state) => state.mobileWhiteboard.showUserList
export const getIsLoading = (state) => state.mobileWhiteboard.isLoading
export const getShowSelfView = (state) => state.mobileWhiteboard.showSelfView

export const getIsShowSelfViewButtonDisabled = createSelector(
  [getIsPriorityViewEnabled, getIsGridViewActive, getShowSelfView],
  (priorityView, isGridViewActive, showSelfView) => !priorityView || isGridViewActive || showSelfView
)

export const getIsSelfViewVisible = createSelector(
  [getIsPriorityViewEnabled, getIsGridViewActive, getShowSelfView],
  (priorityView, isGridViewActive, showSelfView) => priorityView && !isGridViewActive && showSelfView
)

export const getShowLoader = createSelector(
  [getIsLoading, isCallAcceptedSelector],
  (isContentLoading, isCallNotificationAccepted) => isContentLoading || isCallNotificationAccepted
)
