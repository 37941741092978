import ReactDOM from 'react-dom'
import { StrictMode } from 'react'
import App from './components/App'

export default ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('react-root')
)
