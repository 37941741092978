import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment'
import moment from 'moment'
import { TextField } from '@mui/material'

const FormikDateTimePicker = ({ form, field, fullWidth = false, ...rest }) => {
  const { setFieldTouched, setFieldValue } = useFormikContext()

  const fieldValue = field.value ? moment.utc(field.value) : null

  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  const handleChange = (dateTime) => {
    setFieldValue(field.name, moment.utc(dateTime).format())
    setFieldTouched(field.name, true)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        disableIgnoringDatePartForTimeValidation
        {...field}
        {...rest}
        slotProps={{
          textField: {
            fullWidth,
            error: Boolean(error),
            helperText: error || '',
          },
        }}
        value={fieldValue}
        onChange={handleChange}
      />
    </LocalizationProvider>
  )
}

FormikDateTimePicker.propTypes = {
  fullWidth: PropTypes.bool,
  form: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
}

export default FormikDateTimePicker
