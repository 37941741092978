import { HealthDataTypes } from '@tabeeb/enums'

export const getCmFromFtAndIn = (foots, inches) => {
  const value = (!isNaN(foots) && foots * 30.48) + (!isNaN(inches) && inches * 2.54)
  return value.toFixed(1)
}
export const getFootsFromCm = (cm) => Math.trunc(cm / 30.48)
export const getFractionalPartOfFootsInInchesFromCm = (cm) => {
  const foots = cm / 30.48
  const footsInInches = foots - Math.trunc(foots)
  return footsInInches * 12
}
export const getFractionalPartOfFootsFromInches = (inches) => {
  const foots = inches / 12
  const footsInInches = foots - Math.trunc(foots)
  return footsInInches * 12
}
export const getCmFromInches = (inches) => inches * 2.54
export const getInchesFromCm = (cm) => cm / 2.54
export const getKgFromLbs = (lbs) => lbs / 2.2046
export const getLbsFromKg = (kg) => kg * 2.2046
export const getFootsFromInches = (inches) => Math.trunc(inches / 12)
export const getInchesFromFoots = (foots) => foots * 12
export const getInchesFromTotalHeight = (inches, foots) => inches - foots * 12
export const getInchesForSave = (foots, inches) => foots * 12 + Number(inches)
export const getFahrenheitFromCelsius = (celsius) => (celsius * 9) / 5 + 32
export const getCelsiusFromFahrenheit = (fahrenheit) => ((fahrenheit - 32) * 5) / 9
export const getMmolFromMgDl = (mgPerDeciliter) => mgPerDeciliter / 18
export const getMgDlFromMmol = (mmolPerLiter) => mmolPerLiter * 18

export const fromImperialToMetric = (healthDataTypeId, value) => {
  switch (healthDataTypeId) {
    case HealthDataTypes.Weight:
      return getKgFromLbs(value).toFixed(1)
    case HealthDataTypes.Height:
      return getCmFromInches(value).toFixed(1)
    case HealthDataTypes.Temperature:
      return getCelsiusFromFahrenheit(value).toFixed(1)
    case HealthDataTypes.GlucoseLevel:
      return getMmolFromMgDl(value).toFixed(1)
    default:
      return value
  }
}

export const fromMetricToImperial = (healthDataTypeId, value) => {
  switch (healthDataTypeId) {
    case HealthDataTypes.Weight:
      return getLbsFromKg(value).toFixed(1)
    case HealthDataTypes.Height:
      return getInchesFromCm(value).toFixed(1)
    case HealthDataTypes.Temperature:
      return getFahrenheitFromCelsius(value).toFixed(1)
    case HealthDataTypes.GlucoseLevel:
      return getMgDlFromMmol(value).toFixed(1)
    default:
      return value
  }
}
