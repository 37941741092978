import { SortingOrder } from '@tabeeb/enums'
import qs from 'qs'

const devicesRequests = {
  searchDevicesRequest: ({
    pageNumber = 1,
    pageSize = 25,
    order = SortingOrder.Ascending,
    orderBy = 'DeviceId',
    filters = {},
  }) => ({
    url: `health-device-service/api/HealthDevice/all`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      SortModel: {
        ColumnName: orderBy,
        Order: order === SortingOrder.Ascending ? SortingOrder.Ascending : SortingOrder.Descending,
      },
      FilterModel: filters,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),

  createDeviceRequest: (device) => ({
    url: `health-device-service/api/HealthDevice`,
    method: 'post',
    data: device,
  }),

  editDeviceRequest: (device) => ({
    url: `health-device-service/api/HealthDevice`,
    method: 'put',
    data: device,
  }),

  deleteDeviceRequest: (id) => ({
    url: `health-device-service/api/HealthDevice`,
    method: 'delete',
    params: {
      id,
    },
  }),

  getMyHealthDevicesRequest: () => ({
    url: `health-device-service/api/healthDevice/my`,
    method: 'get',
  }),

  getHealthDevicesByUserIdRequest: ({ userId, contentId }) => ({
    url: `health-device-service/api/healthDevice/${userId}`,
    method: 'get',
    params: {
      contentId,
    },
  }),

  createHealthDeviceRequest: (device) => ({
    url: `health-device-service/api/healthDevice`,
    method: 'post',
    data: device,
  }),

  editHealthDeviceRequest: (device) => ({
    url: `health-device-service/api/healthDevice`,
    method: 'put',
    data: device,
  }),

  deleteHealthDeviceRequest: (id) => ({
    url: `health-device-service/api/healthDevice`,
    method: 'delete',
    params: {
      id,
    },
  }),
  getAvailableDeviceTypesRequest: () => ({
    url: `health-device-service/api/healthDevice/deviceTypes`,
    method: 'get',
  }),
}

export default devicesRequests
