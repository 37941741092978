import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { difference } from 'lodash'

import { WarningAmber, CheckCircleOutline } from '@mui/icons-material'
import { CircularProgress, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material'

import UserAvatar from '@tabeeb/modules/shared/userAvatar'
import { useFormikContext } from 'formik'

const UserInfoWithQualifications = ({
  item,
  avatarWrapper: AvatarWrapper,
  avatarWrapperProps,
  requiredQualificationIds,
  usersQualifications,
  loadingQualifications,
}) => {
  const {
    values: { Qualifications },
  } = useFormikContext()

  const missingQualificationIds = useMemo(() => {
    if (loadingQualifications) {
      return null
    }

    if (usersQualifications.length === 0) {
      return requiredQualificationIds
    }

    const missing = difference(
      requiredQualificationIds,
      usersQualifications?.find((_) => _.UserGuid === item.IdentityGuid)?.QualificationIds
    )

    return missing
  }, [item.IdentityGuid, loadingQualifications, requiredQualificationIds, usersQualifications])

  const hasRequiredQualifications = useMemo(
    () => missingQualificationIds?.length === 0,
    [missingQualificationIds?.length]
  )
  return (
    <ListItem component='div' title={item.Name} disableGutters dense>
      <ListItemAvatar>
        <AvatarWrapper {...avatarWrapperProps}>
          <UserAvatar user={item} />
        </AvatarWrapper>
      </ListItemAvatar>
      <ListItemText primary={item.Name} secondary={item.IsAccountDeleted ? null : item.Email} />
      {requiredQualificationIds === null || requiredQualificationIds.length === 0 ? null : loadingQualifications ? (
        <CircularProgress size={25} />
      ) : (
        hasRequiredQualifications != null &&
        (hasRequiredQualifications ? (
          <div title='User has all required qualifications'>
            <CheckCircleOutline />
          </div>
        ) : (
          <div title=''>
            <Tooltip
              title={
                <>
                  <Typography variant='body2'>
                    User doesn&apos;t have all required qualifications. Missing qualifications:
                  </Typography>
                  {missingQualificationIds?.map((id) => (
                    <Typography variant='body2'>- {Qualifications.filter((_) => _.Id === id)[0]?.Name}</Typography>
                  ))}
                </>
              }
            >
              <WarningAmber />
            </Tooltip>
          </div>
        ))
      )}
    </ListItem>
  )
}

UserInfoWithQualifications.defaultProps = {
  avatarWrapper: ({ children }) => children,
  avatarWrapperProps: {},
}

UserInfoWithQualifications.propTypes = {
  item: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string.isRequired,
    Email: PropTypes.string.isRequired,
    IsAccountDeleted: PropTypes.bool,
    AvatarUrl: PropTypes.string,
    IdentityGuid: PropTypes.string,
  }).isRequired,
  avatarWrapper: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]),
  avatarWrapperProps: PropTypes.instanceOf(Object),
  requiredQualificationIds: PropTypes.arrayOf(PropTypes.number),
  loadingQualifications: PropTypes.bool.isRequired,
  usersQualifications: PropTypes.arrayOf(
    PropTypes.shape({
      UserGuid: PropTypes.string,
      QualificationIds: PropTypes.arrayOf(PropTypes.number),
    })
  ),
}

export default memo(UserInfoWithQualifications)
