import { put, takeLatest, all } from 'redux-saga/effects'

import { ContentSharingType } from '@tabeeb/enums'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { notificationActions } from '@tabeeb/modules/notification'
import { acceptNotificationRequest } from '@tabeeb/modules/notificationsPage/actions'
import { goToContent } from '@tabeeb/modules/shared/content/actions'

import * as rawActions from '../../contentReviews/actions'

function* acceptContentReview(reviewToAccept) {
  const acceptedNotificationModel = {
    ContentSharingId: reviewToAccept.Id,
    ContentSharingType: ContentSharingType.ForReview,
    ContentId: reviewToAccept.ContentId,
  }

  yield put(acceptNotificationRequest(acceptedNotificationModel))
}

function* getTenantContentReviewsFailed() {
  yield put(
    notificationActions.onAddErrorNotification({
      message: sessionTitleFormatter.format('You have no access to the reviewed sessions'),
    })
  )
}

function* joinContentReview(action) {
  const reviewToJoin = action.payload

  if (reviewToJoin.IsActive) {
    yield put(
      rawActions.joinContentReview.request({
        contentId: reviewToJoin.ContentId,
        contentSharingId: reviewToJoin.Id,
      })
    )
  } else yield acceptContentReview(reviewToJoin)
}

function* joinContentReviewSuccess(action) {
  const { contentId } = action.payload
  yield put(goToContent({ id: contentId }))
}

function* joinContentReviewFailed() {
  const message = 'Failed to join the content review'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* joinContentReviewSaga() {
  yield all([
    takeLatest(rawActions.getTenantContentReviews.failed, getTenantContentReviewsFailed),
    takeLatest(rawActions.initiateJoinContentReview, joinContentReview),
    takeLatest(rawActions.joinContentReview.success, joinContentReviewSuccess),
    takeLatest(rawActions.joinContentReview.failed, joinContentReviewFailed),
  ])
}

export default joinContentReviewSaga
