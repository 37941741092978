import { handleActions } from 'redux-actions'
import { openSelectedPagesForMerging, closeSelectedPagesForMerging } from '@tabeeb/modules/contentSharings/actions'

const defaultValue = {
  isOpen: false,
  isLoading: false,
}

export default handleActions(
  {
    [openSelectedPagesForMerging](state) {
      return { ...state, isOpen: true }
    },
    [closeSelectedPagesForMerging](state) {
      return { ...state, isOpen: false }
    },
  },
  defaultValue
)
