import PropTypes from 'prop-types'
import { useState, useCallback, memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent, Button, TextField, Box, DialogTitle, MenuItem } from '@mui/material'
import { string } from 'yup'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'
import { getFoldersList } from '@tabeeb/modules/gallery/selectors'
import { maxLength, requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'
import { PAGE_NAME_MAX_LENGTH } from '@tabeeb/modules/gallery/services/constants'

import { prepareCreateSplatModel } from '../../actions'

const pageNameSchema = string()
  .trim('There should be no spaces at the beginning and at the end')
  .strict(true)
  .required(requiredField)
  .max(PAGE_NAME_MAX_LENGTH, maxLength(PAGE_NAME_MAX_LENGTH))

const DEFAULT_FOLDER_ID = -1

const SplatSettingsDialog = ({ isOpen, closeDialog, createSplatModel, pageIds }) => {
  const [folderId, setFolderId] = useState(DEFAULT_FOLDER_ID)
  const [pageName, setPageName] = useState('')

  const enabled = useMemo(() => pageIds.length && isOpen, [isOpen, pageIds.length])
  const payload = useMemo(() => ({ pageIds }), [pageIds])

  const { response, loaded, loading } = useApiRequest({
    defaultResponse: '',
    request: prepareCreateSplatModel.request,
    payload,
    enabled,
  })

  const { validationMessage, isNameInvalid } = useMemo(() => {
    try {
      pageNameSchema.validateSync(pageName)
      return { validationMessage: '', isNameInvalid: false }
    } catch (e) {
      return { validationMessage: e.message || '', isNameInvalid: true }
    }
  }, [pageName])

  const isError = isNameInvalid && !loading

  const foldersList = useSelector(getFoldersList)

  const handleCreate = useCallback(() => {
    createSplatModel({
      FolderId: folderId === DEFAULT_FOLDER_ID ? null : folderId,
      PageName: pageName.length ? pageName : null,
    })

    closeDialog()
  }, [closeDialog, createSplatModel, folderId, pageName])

  useEffect(() => {
    if (loaded) {
      setPageName(response.PageName)
      setFolderId(response.FolderId ?? DEFAULT_FOLDER_ID)
    }
  }, [loaded, response])

  return (
    <Dialog open={isOpen} maxWidth='xs' fullWidth>
      <DialogTitle>Configure result page</DialogTitle>
      <DialogContent>
        <Box sx={{ marginBlock: 2 }}>
          <TextField
            value={pageName}
            label='Page Name'
            variant='outlined'
            onChange={(e) => setPageName(e.target.value)}
            size='small'
            name='pageName'
            disabled={loading}
            fullWidth
            error={isError}
            helperText={isError ? validationMessage : ''}
          />
        </Box>
        {foldersList && foldersList.length > 0 && (
          <Box sx={{ marginBlock: 2 }}>
            <TextField
              fullWidth
              size='small'
              select
              sx={{ mt: 1 }}
              variant='outlined'
              value={folderId}
              onChange={(e) => setFolderId(e.target.value)}
              disabled={loading}
              label='Destination Folder'
            >
              <MenuItem value={DEFAULT_FOLDER_ID}>Place in the Gallery</MenuItem>
              {foldersList.map((folder) => (
                <MenuItem value={folder.Id} key={folder.Id}>
                  {folder.Name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
        <Button onClick={handleCreate} color='primary' disabled={isNameInvalid || loading} variant='contained'>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SplatSettingsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  createSplatModel: PropTypes.func.isRequired,
  pageIds: PropTypes.arrayOf(PropTypes.number),
}

export default memo(SplatSettingsDialog)
