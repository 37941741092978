import { UserManager, WebStorageStateStore, User } from 'oidc-client-ts'
import routes from '@tabeeb/routes'
import domainService from '@tabeeb/services/domainService'

import { resetTenantAuthenticate } from '@tabeeb/modules/account/actions'

let appStore = null
export const injectStore = (store) => {
  appStore = store
}

const oidcUserManager = new UserManager({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: process.env.TABEEB_IDENTITY_URL,
  client_id: process.env.TABEEB_IDENTITY_CLIENT_ID,
  redirect_uri: `${window.location.origin}${routes.signInRedirectCallback}`,
  response_type: 'code',
  scope: 'collaborate_api openid profile offline_access',
  response_mode: 'query',
  silent_redirect_uri: `${window.location.origin}${routes.signInSilentCallback}`,
  post_logout_redirect_uri: `${window.location.origin}${routes.signOutRedirectCallback}`,
  automaticSilentRenew: true,
  monitorSession: true,
  monitorAnonymousSession: true,
})

oidcUserManager.getUserStoreKey = () => {
  return `oidc.user:${oidcUserManager.settings.authority}:${oidcUserManager.settings.client_id}`
}

oidcUserManager.getUserSync = () => {
  const oidcStorage = localStorage.getItem(oidcUserManager.getUserStoreKey())
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

oidcUserManager.events.addUserSignedOut(async () => {
  appStore?.dispatch(resetTenantAuthenticate())
  await oidcUserManager.signinRedirect({
    extraQueryParams: { subdomain: domainService.subdomain },
  })
})

export default oidcUserManager
