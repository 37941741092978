import { handleActions } from 'redux-actions'
import * as customVideoActions from '../actions'

export default handleActions(
  {
    [customVideoActions.getPagesByAnswerStatusRequest](state, action) {
      return true
    },
    [customVideoActions.getPagesByAnswerStatusSuccess](state, action) {
      return false
    },
    [customVideoActions.resetState](state, action) {
      return false
    },
  },
  false
)
