import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Mic, MicOff } from '@mui/icons-material'

import useMuteUserAudio from '@tabeeb/modules/presentation/hooks/useMuteUserAudio'
import UserActionButton from '../UserActionButton'

const MuteUserAudioButton = ({ user }) => {
  const { handleClick, isPresenter, currentUserId, isMuted } = useMuteUserAudio({ user })

  const icon = useMemo(() => {
    return isMuted ? MicOff : Mic
  }, [isMuted])

  return (
    <UserActionButton
      icon={icon}
      title={isMuted ? 'Unmute audio' : 'Mute audio'}
      disabled={!isPresenter && currentUserId !== user.id}
      isMuted={isMuted}
      onClick={handleClick}
    />
  )
}

MuteUserAudioButton.propTypes = {
  user: PropTypes.shape({
    isOnline: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }),
}

export default memo(MuteUserAudioButton)
