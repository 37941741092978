import { createApiActions } from '@tabeeb/shared/utils/actions'

export const acceptInvite = createApiActions('ACCEPT_INVITE')
export const cancelInvite = createApiActions('CANCEL_INVITE')
export const rejectInvite = createApiActions('REJECT_INVITE')

export const updateQuickAccessUrlPassword = createApiActions('UPDATE_QUICK_ACCESS_URL_PASSWORD')
export const updateInviteRole = createApiActions('UPDATE_INVITE_ROLE')
export const updateInviteTime = createApiActions('UPDATE_INVITE_TIME')
export const resendInvite = createApiActions('RESEND_INVITE')
export const revokeQuickAccessUrl = createApiActions('REVOKE_QUICK_ACCESS_URL')

export const sendInvites = createApiActions('SEND_INVITES')

export const getContentInvites = createApiActions('GET_CONTENT_INVITES')
export const getInviteUrlById = createApiActions('GET_INVITE_URL_BY_ID')
