import { createSelector } from 'reselect'
import { AssetType } from '@tabeeb/enums'

import { getSelectedGalleryItem } from '@tabeeb/modules/gallery/selectors'

import { calculateScale } from '../services'

export const getContentSize = (state) => state.contentViewer.contentSize
export const getContainerSize = (state) => state.contentViewer.containerSize
export const getIsLoading = (state) => state.contentViewer.isLoading
export const getIsWebPageIframeHidden = (state) => state.contentViewer.webPage.isIframeHidden

export const getCursorCoordinates = (state) => state.contentViewer.cursorPosition

export const getAutodeskData = (state) => state.contentViewer.autodesk

export const getScale = createSelector([getContentSize, getContainerSize], (contentSize, containerSize) => {
  return calculateScale(contentSize, containerSize)
})

export const getPlayerScale = (state) => state.playerToolbar.scale / 100
export const getContentScale = createSelector([getScale, getPlayerScale], (scale, playerScale) => {
  return scale * playerScale
})

export const getScaledContentSize = createSelector([getContentSize, getContentScale], (contentSize, scale) => {
  return {
    width: contentSize.width * scale,
    height: contentSize.height * scale,
  }
})

export const getAssetsForSpatialModel = createSelector([getSelectedGalleryItem], (galleryItem) => {
  let modelAssets = []
  if (galleryItem && galleryItem.assets) {
    modelAssets = galleryItem.assets
      .filter((item) => item.Type === AssetType.SpatialModelImage)
      .sort((a, b) => a.Order - b.Order)
  }

  return modelAssets
})
