import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@material-ui/core'

const FormikRadioGroup = ({
  field,
  form: { touched, errors, values },
  options = [],
  label,
  row = false,
  color,
  ...props
}) => {
  const error = get(touched, field.name) && get(errors, field.name)
  return (
    <FormControl component='fieldset' error={!!error} required={props.required}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup name={field.name} row={row} {...field} {...props}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.id}
            checked={option.id === Number(values[field.name])}
            control={<Radio color={color} />}
            label={option.value}
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

FormikRadioGroup.defaultProps = {
  color: 'primary',
}

FormikRadioGroup.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  row: PropTypes.bool.isRequired,
  label: PropTypes.string,
  color: PropTypes.string,
  required: PropTypes.bool,
}

export default FormikRadioGroup
