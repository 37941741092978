import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core'

import { ContentSharingModelType } from '@tabeeb/enums'
import SharedForReviewContent from '@tabeeb/modules/contentReviews/components/SharedForReviewContent'

import SessionSharingTenantsDropdown from './SessionSharingTenantsDropdown'
import FoundUsersContainer from '../../../containers/FoundUsersContainer'
import FoundUsersItem from './FoundUsersItem'
import ContentSharingTypeSelector from './ContentSharingTypeSelector'
import TargetContentSearchInput from './TargetContentSearchInput'
import TargetContentSearchResult from './TargetContentSearchResult'
import SelectedTargetContent from './SelectedTargetContent'

import './styles.less'
import './FoundUsersItem/styles.less'

const ShareSessionDialog = ({
  isOpen,
  shareContent,
  handleClose,
  updateUsersSearchValue,
  usersSearchInputValue,
  tenantsList,
  selectedTenant,
  selectTenantForSharing,
  selectOwner,
  removeOwner,
  selectedOwnerForSharing,
  contentSharingType,
  selectedTargetContentForMerging,
  selectedFormsReview,
}) => {
  const [sharingType, setSharingType] = useState(contentSharingType)
  useEffect(() => {
    if (selectedFormsReview) {
      setSharingType(selectedFormsReview.Type)
    }
  }, [selectedFormsReview])

  const isForReview = sharingType === ContentSharingModelType.ForReview
  const isMergeForReview = sharingType === ContentSharingModelType.MergeForReview
  const isMerge = sharingType === ContentSharingModelType.ContentsMerging || isMergeForReview
  const isIgnoreContentOwner = selectedTenant?.IsSearchTargetContentByTenantEnabled
  const selectUserTitle = isForReview ? 'Select Reviewer' : 'Select Owner'
  const showUserSearchInput = selectedTenant?.Id && !(isMergeForReview && isIgnoreContentOwner)
  const showTargetContentSearchInput = isIgnoreContentOwner
    ? selectedTenant?.Id && isMerge
    : selectedOwnerForSharing && isMerge

  const isContinueButtonDisabled = isMerge ? !selectedTargetContentForMerging : !selectedOwnerForSharing

  return (
    <Dialog open={isOpen} onClose={handleClose} disableEscapeKeyDown className='share-session-dialog'>
      <DialogTitle>Destination</DialogTitle>
      {!selectedTenant?.IsPredefined && (
        <ContentSharingTypeSelector
          sharingType={sharingType}
          setSharingType={setSharingType}
          selectedOwnerForSharing={selectedOwnerForSharing}
        />
      )}
      <DialogContent className='share-session-dialog-content'>
        <span className='share-session-dialog-label'>Tenant Destination</span>
        <SessionSharingTenantsDropdown
          tenantsList={tenantsList}
          selectedTenant={selectedTenant}
          handleChange={selectTenantForSharing}
        />
        {showUserSearchInput && (
          <>
            <span className='share-session-dialog-label'>{selectUserTitle}</span>
            {selectedOwnerForSharing ? (
              <FoundUsersItem user={selectedOwnerForSharing} removeOwner={removeOwner} />
            ) : (
              <>
                <TextField
                  value={usersSearchInputValue}
                  onChange={(e) => updateUsersSearchValue(e)}
                  placeholder='Search name or email...'
                  className='share-session-dialog-input'
                />
                <FoundUsersContainer selectOwner={selectOwner} isReviewMode={isForReview} />
              </>
            )}
          </>
        )}
        {selectedTenant.Id && isForReview && (
          <div style={{ marginTop: 15 }}>
            <SharedForReviewContent tenantId={selectedTenant.Id} />
          </div>
        )}
        {showTargetContentSearchInput && (
          <div style={{ marginTop: 15 }}>
            <span className='share-session-dialog-label'>Select existing session</span>
            {selectedTargetContentForMerging ? (
              <SelectedTargetContent content={selectedTargetContentForMerging} />
            ) : (
              <>
                <TargetContentSearchInput />
                <TargetContentSearchResult />
              </>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions className='share-session-dialog-actions'>
        <Button
          onClick={() => shareContent(sharingType)}
          color='primary'
          disabled={isContinueButtonDisabled}
          className='share-session-dialog-btn'
        >
          Continue
        </Button>
        <Button onClick={handleClose} color='default' className='share-session-dialog-btn'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ShareSessionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  shareContent: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  updateUsersSearchValue: PropTypes.func.isRequired,
  usersSearchInputValue: PropTypes.string,
  tenantsList: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }).isRequired
  ),
  selectedTenant: PropTypes.shape({
    Id: PropTypes.number,
    IsPredefined: PropTypes.bool,
    IsSearchTargetContentByTenantEnabled: PropTypes.bool,
  }),
  selectTenantForSharing: PropTypes.func.isRequired,
  selectOwner: PropTypes.func.isRequired,
  removeOwner: PropTypes.func.isRequired,
  selectedOwnerForSharing: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsPredefined: PropTypes.bool,
  }),
  contentSharingType: PropTypes.number.isRequired,
  selectedTargetContentForMerging: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsPredefined: PropTypes.bool,
  }),
  selectedFormsReview: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Type: PropTypes.number.isRequired,
  }),
}

export default ShareSessionDialog
