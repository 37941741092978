import { isMobileOnly } from 'react-device-detect'
import { put, select } from 'redux-saga/effects'

import { toggleLargeVideo } from '@tabeeb/modules/presentation/actions/tracks'
import { getCurrentUserId, getLargeVideoUser, getIsCallPreviewOpen } from '@tabeeb/modules/presentation/selectors'

const ACTIVE_AUDIO_LEVEL_THRESHOLD = 0.008

const ACTIVE_AUDIO_SHADOW_COLOR = 'rgba(0, 108, 255, 1)'
const ACTIVE_AUDIO_BOX_SHADOW = `inset 0 0 0 3px ${ACTIVE_AUDIO_SHADOW_COLOR}`
const MUTED_AUDIO_BOX_SHADOW = `inset 0 0 0 0 ${ACTIVE_AUDIO_SHADOW_COLOR}`

const AUDIO_LEVEL_BARRIER = 0.2

export const MIN_PREVIEW_AUDIO_LEVEL = 0
const MAX_PREVIEW_AUDIO_LEVEL = 100

export function* updateAudioLevelIndicatorForUser(userId, audioLevel) {
  const isCallPreviewOpen = yield select(getIsCallPreviewOpen)

  if (isCallPreviewOpen) {
    updatePreviewAudioLevelIndicator(audioLevel)
    return
  }

  const isActive = audioLevel > ACTIVE_AUDIO_LEVEL_THRESHOLD

  const boxShadow = isActive ? ACTIVE_AUDIO_BOX_SHADOW : MUTED_AUDIO_BOX_SHADOW

  const userBoxes = document.querySelectorAll(`.tabeeb-user-box[data-id="${userId}"]`)
  for (const userBox of userBoxes) {
    userBox.style.boxShadow = boxShadow
  }
}

const updatePreviewAudioLevelIndicator = (audioLevel) => {
  const soundBar = document.getElementById('tabeebPreviewAudioLevelBar')?.querySelector('.tabeebPreviewAudioLevel')
  if (!soundBar) {
    return
  }

  if (audioLevel <= ACTIVE_AUDIO_LEVEL_THRESHOLD) {
    audioLevel = MIN_PREVIEW_AUDIO_LEVEL
  }

  let value = Math.ceil(audioLevel * 100 * 2)
  if (value > MAX_PREVIEW_AUDIO_LEVEL) {
    value = MAX_PREVIEW_AUDIO_LEVEL
  }

  soundBar.style.transform = `translateX(${value - MAX_PREVIEW_AUDIO_LEVEL}%)`
}

export function* showSpeaker(userId, audioLevel) {
  if (!isMobileOnly) {
    return
  }

  const currentUserId = yield select(getCurrentUserId)

  if (currentUserId === userId) {
    return
  }

  const largeVideoUser = yield select(getLargeVideoUser)

  if (!largeVideoUser) {
    yield put(toggleLargeVideo({ userId }))
    return
  }

  if (largeVideoUser.id === userId) {
    return
  }

  if (audioLevel <= AUDIO_LEVEL_BARRIER) {
    return
  }

  yield put(toggleLargeVideo({ userId }))
}
