import PropTypes from 'prop-types'

import { Stage, Layer } from 'react-konva'

import { Box, withStyles } from '@material-ui/core'

import Dial from '../Dial'
import Drone from '../Drone'
import NoMetadata from '../NoMetadata'

import styles from './styles'

const DroneLocation = ({ classes, size, margin, marginTop, azimuth, fov }) => {
  const isAzimuthDefined = Number.isFinite(azimuth)

  const stageSize = size + margin * 2

  return (
    <Box className={classes.container}>
      <Stage width={stageSize} height={stageSize}>
        <Layer x={margin} y={marginTop} name='compass' width={size} height={size}>
          <Dial size={size} />
          {isAzimuthDefined && <Drone fov={fov} azimuth={azimuth} size={size} />}
        </Layer>
      </Stage>

      {!isAzimuthDefined && <NoMetadata />}
    </Box>
  )
}

DroneLocation.defaultProps = {
  size: 180,
  margin: 22.5,
  marginTop: 25,
}

DroneLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  azimuth: PropTypes.number,
  fov: PropTypes.number,
}

export default withStyles(styles)(DroneLocation)
