import { combineReducers } from 'redux'

import background from './background'
import fieldOfView from './fieldOfView'
import pointSize from './pointSize'

export default combineReducers({
  background,
  fieldOfView,
  pointSize,
})
