import { useState } from 'react'
import PropTypes from 'prop-types'

import { MenuItem, FormControl, Select } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'

import { ReminderRepeatMode } from '@tabeeb/enums'
import CustomRepeatSettings from '../CustomRepeatSettings'

import { getRepeatModeOptions } from '../../services/repeatModeOptions'
import { defaultCustomRepeatSettings } from '../../services/defaultCustomRepeatSettings'

import './styles.less'

const ReminderRepeat = ({ currentReminder, setReminderSettings }) => {
  const [isCustomRepeatDialogOpen, setIsCustomRepeatDialogOpen] = useState(false)

  const _handleChange = (e) => {
    const newRepeatMode = parseInt(e.target.value, 10)

    setReminderSettings({
      ...currentReminder,
      RepeatMode: newRepeatMode,
      CustomRepeatSettings:
        newRepeatMode === ReminderRepeatMode.Custom
          ? currentReminder.CustomRepeatSettings === null
            ? defaultCustomRepeatSettings
            : currentReminder.CustomRepeatSettings
          : null,
    })

    if (newRepeatMode === ReminderRepeatMode.Custom) setIsCustomRepeatDialogOpen(true)
  }

  const dialogProps = {
    onCloseDialog: () => setIsCustomRepeatDialogOpen(false),
    isOpen: isCustomRepeatDialogOpen,
    currentReminder,
    setReminderSettings,
  }

  const repeatModeOptions = getRepeatModeOptions(currentReminder)

  return (
    <div className='reminder-repeat'>
      <span className='reminder-repeat-icon'>
        <RefreshIcon fontSize='inherit' />
      </span>
      <FormControl className='reminder-repeat-select'>
        <Select
          value={currentReminder.RepeatMode}
          onChange={_handleChange}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            getContentAnchorEl: null,
          }}
        >
          {Object.keys(repeatModeOptions).map((key) => (
            <MenuItem value={key} key={key}>
              <span className='reminder-repeat-select-item' title={repeatModeOptions[key]}>
                {repeatModeOptions[key]}
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CustomRepeatSettings {...dialogProps} />
    </div>
  )
}

ReminderRepeat.propTypes = {
  currentReminder: PropTypes.object.isRequired,
  setReminderSettings: PropTypes.func.isRequired,
}

export default ReminderRepeat
