import { createSelector } from 'reselect'
import { TabPanel } from '@tabeeb/enums'
import { getNavbarCurrentBottomPanel } from '../../customVideo/selectors'

export const getIsReviewSessionPagesListOpen = (state) => state.reviewSessionPagesList.isTimelineOpen

export const getIsReviewSessionTimelineOpen = createSelector(
  [getIsReviewSessionPagesListOpen, getNavbarCurrentBottomPanel],
  (isTimelineOpen, currentPanel) => {
    return isTimelineOpen && currentPanel === TabPanel.ReviewSession
  }
)
