import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      '&:hover': {
        '& button': {
          visibility: 'visible',
          opacity: 1,
        },
      },
    },
    button: {
      background: 'inherit',
      '&:hover': {
        background: 'inherit',
      },
      transition: 'visibility 0.1s, opacity 0.1s linear',
      right: theme.spacing(0.25),
      bottom: theme.spacing(0.25),
      position: 'absolute',
      visibility: 'none',
      opacity: 0,
    },
    progress: {
      position: 'absolute',
      margin: 0,
      padding: 0,
      right: theme.spacing(0.75),
      bottom: theme.spacing(0.75),
    },
    icon: {
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.text.secondary,
    },
  }
})
