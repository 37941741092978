import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { startSubmit, isSubmitting } from 'redux-form'

import * as rawActions from '../actions'
import AddWebPageFromUrlDialog from '../components/AddWebPageFromUrlDialog'
import { PageType, AssetType, ContentType } from '../../../Enums'
import { ADD_WEB_PAGE_FROM_URL_DIALOG_FORM } from '../constants'
import { getInsertionIndex } from '../selectors'

class AddWebPageFromUrlDialogContainer extends Component {
  handleCloseAddWebPageDialog = () => {
    const {
      actions: { hideAddWebPageDialog },
    } = this.props

    hideAddWebPageDialog()
  }

  onSubmit = (data) => {
    const {
      actions: { addWebPageUsingUrlRequest },
      selectedFolderId,
      contentId,
      documentId,
      isSubmitting,
      startSubmit,
      insertionIndex,
    } = this.props

    const url = data.url.trim()

    const timeOffset = new Date().getTimezoneOffset()
    const thumbnailUrl = 'Content/img/web-page-placeholder.png'
    const page = {
      contentId,
      documentId,
      folderId: selectedFolderId,
      name: url,
      description: url,
      order: insertionIndex,
      type: PageType.Normal,
      width: 1000,
      height: 750,
      timeZoneOffset: -timeOffset,
      contentType: ContentType.WebPage,
      thumbnailUrl,
      assets: [
        {
          type: AssetType.WebPage,
          name: url,
          url,
          latitude: null,
          longitude: null,
        },
      ],
    }

    if (thumbnailUrl) {
      page.assets.push({
        type: AssetType.Preview,
        name: thumbnailUrl,
        url: thumbnailUrl,
        latitude: null,
        longitude: null,
      })
    }

    if (!isSubmitting) {
      addWebPageUsingUrlRequest(page)
      startSubmit()
    }

    this.handleCloseAddWebPageDialog()
  }

  render() {
    const { displayAddWebPageDialog } = this.props

    const props = {
      displayAddWebPageDialog,
      handleCloseAddWebPageDialog: this.handleCloseAddWebPageDialog,
      onSubmit: this.onSubmit,
    }

    return <AddWebPageFromUrlDialog {...props} />
  }
}

AddWebPageFromUrlDialogContainer.propTypes = {
  displayAddWebPageDialog: PropTypes.bool.isRequired,
  selectedFolderId: PropTypes.number,
  contentId: PropTypes.number,
  documentId: PropTypes.number,
  isSubmitting: PropTypes.bool,
  startSubmit: PropTypes.func,
  actions: PropTypes.shape({
    hideAddWebPageDialog: PropTypes.func.isRequired,
    showAddWebPageDialog: PropTypes.func.isRequired,
    addWebPageUsingUrlRequest: PropTypes.func.isRequired,
  }).isRequired,
  insertionIndex: PropTypes.number,
}

const mapStateToProps = (state) => {
  return {
    displayAddWebPageDialog: state.gallery.controlPannel.displayAddWebPageDialog,
    selectedFolderId: state.gallery.galleryState.selectedFolderId,
    contentId: state.contentState.contentId,
    documentId: state.contentState.documentId,
    isSubmitting: isSubmitting(ADD_WEB_PAGE_FROM_URL_DIALOG_FORM)(state),
    insertionIndex: getInsertionIndex(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
  startSubmit: () => dispatch(startSubmit(ADD_WEB_PAGE_FROM_URL_DIALOG_FORM)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddWebPageFromUrlDialogContainer)
