import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { get } from 'lodash'

import useStyles from './styles'

const FormikPhoneNumber = ({ field, form: { errors, touched, setFieldValue }, required, ...props }) => {
  const classes = useStyles()
  const error = get(touched, field.name) && get(errors, field.name)
  return (
    <>
      <PhoneInput
        country='us'
        enableSearch={props.enableSearch}
        isValid={!error}
        inputProps={{
          name: field.name,
          required,
          autoFocus: true,
        }}
        {...field}
        onChange={(newValue) => {
          setFieldValue(field.name, newValue)
        }}
        value={field.value || ''}
        inputClass={classes.input}
        dropdownClass={classes.dropdownCountriesList}
        inputStyle={props.fullWidth && { width: '100%' }}
        specialLabel={props.label}
        {...props}
      />
      {error && <div>{error}</div>}
    </>
  )
}

FormikPhoneNumber.defaultProps = {
  enableSearch: false,
  fullWidth: false,
  label: '',
}

FormikPhoneNumber.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  required: PropTypes.bool,
  enableSearch: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
}

export default FormikPhoneNumber
