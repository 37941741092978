import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Undo } from '@tabeeb/shared/icons'

import { annotationsActions, annotationsSelectors } from '@tabeeb/modules/annotations'

import ToolbarButton from '../ToolbarButton'

const Z_BUTTON_CODE = 90

const UndoButton = () => {
  const dispatch = useDispatch()

  const available = useSelector(annotationsSelectors.getHasUndoActions)

  useEffect(() => {
    function undoLastAction({ ctrlKey, keyCode }) {
      if (!ctrlKey || keyCode !== Z_BUTTON_CODE) {
        return
      }

      dispatch(annotationsActions.undoLastAction())
    }

    if (!available) {
      return
    }

    window.addEventListener('keyup', undoLastAction)

    return () => {
      window.removeEventListener('keyup', undoLastAction)
    }
  }, [available, dispatch])

  const onClick = useCallback(() => {
    dispatch(annotationsActions.undoLastAction())
  }, [dispatch])

  return <ToolbarButton disabled={!available} Icon={Undo} title='Undo' onClick={onClick} />
}

export default React.memo(UndoButton)
