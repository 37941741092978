import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../../actions'

const initialState = {
  open: false,
  fetchStatus: FetchStatus.Idle,
}

export default handleActions(
  {
    [rawActions.openAddPowerBIReportModal]: (state, action) => {
      return { ...state, open: true }
    },
    [rawActions.closeAddPowerBIReportModal]: () => {
      return initialState
    },
    [rawActions.createPowerBIReport.request]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [rawActions.createPowerBIReport.success]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loaded }
    },
    [rawActions.createPowerBIReport.failed]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
  },
  initialState
)
