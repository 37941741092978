import PropTypes from 'prop-types'

import CircularProgressBar from '../../../presentation/components/CircularProgressBar'
import { ConfirmationDialog } from '@tabeeb/uikit'

const ExportImagesDialog = ({ isExportImagesDialogOpen, closeDialog, handleExportImages, isShowProgress }) => {
  const _handleButtonClick = (isWithAnnotations) => {
    handleExportImages(isWithAnnotations)
    closeDialog()
  }

  const dialogProps = {
    onCloseDialog: () => _handleButtonClick(false),
    onSubmit: () => _handleButtonClick(true),
    isOpen: isExportImagesDialogOpen,
    title: 'Do you want to include annotations?',
    submitButtonText: 'Yes',
    cancelButtonText: 'No',
  }

  return isExportImagesDialogOpen ? <ConfirmationDialog {...dialogProps} /> : isShowProgress && <CircularProgressBar />
}

ExportImagesDialog.propTypes = {
  isExportImagesDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleExportImages: PropTypes.func.isRequired,
  isShowProgress: PropTypes.bool.isRequired,
}

export default ExportImagesDialog
