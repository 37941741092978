import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, IconButton, withStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { Tooltip } from '@mui/material'

import Value from '../Value'

import styles from './styles'

const Details = ({ classes, azimuth, altitude, gimbalPitch }) => {
  const [isElevationTooltipOpened, setIsElevationTooltipOpened] = useState(false)

  const isAzimuthDefined = Number.isFinite(azimuth)
  const isAltitudeDefined = Number.isFinite(altitude)
  const isGimbalPitchDefined = Number.isFinite(gimbalPitch)

  const altitudeInFeet = isAltitudeDefined && altitude * 3.28

  return (
    <Box className={classes.container}>
      <Value title='Azimuth' value={isAzimuthDefined ? `${azimuth.toFixed(2)}°` : 'N/A'} />
      <Value title='Directional Heading' value={isAzimuthDefined ? `${((azimuth + 180) % 360).toFixed(2)}°` : 'N/A'} />
      <Value title='Gimbal Pitch' value={isGimbalPitchDefined ? `${gimbalPitch.toFixed(2)}°` : 'N/A'} />
      <div className={classes.elevationInfoContainer}>
        <Value
          title='Elevation'
          value={isAltitudeDefined ? `${altitude.toFixed(2)}m (${altitudeInFeet.toFixed(2)}\`)` : 'N/A'}
        />
        <Tooltip
          classes={{ tooltip: classes.elevationTooltip }}
          open={isElevationTooltipOpened}
          onOpen={() => setIsElevationTooltipOpened(true)}
          onClose={() => setIsElevationTooltipOpened(false)}
          placement='right-start'
          disableInteractive
          title={
            <Box className={classes.elevationTooltipText}>
              <div>DISCLAIMER</div>
              <div>
                Elevation figures displayed in the compass overlay can vary based on drone GPS metadata (IE. "~" 140 ft
                =/- 20 ft).
              </div>
              <div>Accurate measurements are provided in the Tilt, Plumb, and Azimuth (TPA) product report.</div>
            </Box>
          }
        >
          <IconButton size='small' className={classes.infoIcon} aria-label='disclaimer info'>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </div>
    </Box>
  )
}

Details.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    elevationInfoContainer: PropTypes.string.isRequired,
    elevationTooltipText: PropTypes.string.isRequired,
    elevationTooltip: PropTypes.string.isRequired,
    infoIcon: PropTypes.string.isRequired,
  }).isRequired,
  azimuth: PropTypes.number,
  altitude: PropTypes.number,
  gimbalPitch: PropTypes.number,
}

export default withStyles(styles)(Details)
