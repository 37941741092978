import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_FORMS_PAGE_STATE')

export const createFormRequest = createAction('CREATE_FORM_REQUEST')
export const createFormSuccess = createAction('CREATE_FORM_SUCCESS')

export const getAllFormsRequest = createAction('GET_ALL_FORMS_REQUEST')
export const getAllFormsSuccess = createAction('GET_ALL_FORMS_SUCCESS')
export const getAllFormsFailed = createAction('GET_ALL_FORMS_FAILED')

export const clearFormsList = createAction('CLEAR_ALL_FORMS_LIST')

export const deleteFormRequest = createAction('DELETE_FORM_REQUEST')
export const deleteFormSuccess = createAction('DELETE_FORM_SUCCESS')

export const copyFormRequest = createAction('COPY_FORM_REQUEST')
export const copyFormSuccess = createAction('COPY_FORM_SUCCESS')
export const copyFormFailed = createAction('COPY_FORM_FAILED')

export const editFormRequest = createAction('EDIT_FORM_REQUEST')
export const editFormSuccess = createAction('EDIT_FORM_SUCCESS')

export const updateFormsSearchText = createAction('UPDATE_FORMS_SEARCH_TEXT')

export const handleFormsPageDialogSubmit = createAction('HANDLE_FORMS_PAGE_DIALOG_SUBMIT')
export const goToUpdateFormPage = createAction('GO_TO_UPDATE_FORM_PAGE')
export const goToCreateFormPage = createAction('GO_TO_CREATE_FORM_PAGE')
export const goToFormInfoPage = createAction('GO_TO_FORM_INFO_PAGE')

export const setPublishDialogState = createAction('SET_PUBLISH_DIALOG_STATE')
export const closePublishDialog = createAction('CLOSE_PUBLISH_DIALOG')

export const publishFormRequest = createAction('PUBLISH_FORM_REQUEST')
export const publishFormSuccess = createAction('PUBLISH_FORM_SUCCESS')
export const publishFormFailed = createAction('PUBLISH_FORM_FAILED')

export const unpublishFormRequest = createAction('UNPUBLISH_FORM_REQUEST')
export const unpublishFormSuccess = createAction('UNPUBLISH_FORM_SUCCESS')
export const unpublishFormFailed = createAction('UNPUBLISH_FORM_FAILED')

export const publishFormFolderRequest = createAction('PUBLISH_FORM_FOLDER_REQUEST')
export const publishFormFolderSuccess = createAction('PUBLISH_FORM_FOLDER_SUCCESS')
export const publishFormFolderFailed = createAction('PUBLISH_FORM_FOLDER_FAILED')

export const unpublishFormFolderRequest = createAction('UNPUBLISH_FORM_FOLDER_REQUEST')
export const unpublishFormFolderSuccess = createAction('UNPUBLISH_FORM_FOLDER_SUCCESS')
export const unpublishFormFolderFailed = createAction('UNPUBLISH_FORM_FOLDER_FAILED')

export const getAllFormFoldersRequest = createAction('GET_ALL_FORM_FOLDERS_REQUEST')
export const getAllFormFoldersSuccess = createAction('GET_ALL_FORM_FOLDERS_SUCCESS')
export const getAllFormFoldersFailed = createAction('GET_ALL_FORM_FOLDERS_FAILED')

export const getFormsByFolderIdRequest = createAction('GET_FORMS_BY_FOLDER_ID_REQUEST')
export const getFormsByFolderIdSuccess = createAction('GET_FORMS_BY_FOLDER_ID_SUCCESS')
export const getFormsByFolderIdFailed = createAction('GET_FORMS_BY_FOLDER_ID_FAILED')

export const loadFormsList = createAction('LOAD_FORMS_LIST')

export const createFormFolderRequest = createAction('CREATE_FORM_FOLDER_REQUEST')
export const createFormFolderSuccess = createAction('CREATE_FORM_FOLDER_SUCCESS')
export const createFormFolderFailed = createAction('CREATE_FORM_FOLDER_FAILED')

export const deleteFormFolderRequest = createAction('DELETE_FORM_FOLDER_REQUEST')
export const deleteFormFolderSuccess = createAction('DELETE_FORM_FOLDER_SUCCESS')
export const deleteFormFolderFailed = createAction('DELETE_FORM_FOLDER_FAILED')

export const renameFormFolderRequest = createAction('RENAME_FORM_FOLDER_REQUEST')
export const renameFormFolderSuccess = createAction('RENAME_FORM_FOLDER_SUCCESS')

export const openFormFolder = createAction('OPEN_FORM_FOLDER')
export const setCurrentFormFolder = createAction('SET_CURRENT_FORM_FOLDER')
export const clearCurrentFormFolder = createAction('CLEAR_CURRENT_FORM_FOLDER')

export const setCachedFolder = createAction('SET_CACHED_FOLDER')
export const clearCachedFolder = createAction('CLEAR_CACHED_FOLDER')

export const switchFormFolderRequest = createAction('SWITCH_FORM_FOLDER_REQUEST')
export const switchFormFolderSuccess = createAction('SWITCH_FORM_FOLDER_SUCCESS')
export const switchFormFolderFailed = createAction('SWITCH_FORM_FOLDER_FAILED')

export const addFormFolder = createAction('ADD_FORM_FOLDER')
export const deleteFormFolder = createAction('DELETE_FORM_FOLDER')
