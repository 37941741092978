import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = {
  isOpen: false,
  currentCount: 0,
  totalCount: 0,
  message: null,
}

export default handleActions(
  {
    [rawActions.showPropgressPopup](state, action) {
      return {
        ...state,
        isOpen: true,
      }
    },
    [rawActions.hidePropgressPopup](state, action) {
      return {
        ...state,
        isOpen: false,
      }
    },
    [rawActions.updateProgressPopupState](state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
