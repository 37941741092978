import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Pause, PlayArrow } from '@material-ui/icons'

import { playerActions, playerSelectors } from '@tabeeb/modules/player'

import ToolbarButton from '../ToolbarButton'

const PlaybackButton = () => {
  const dispatch = useDispatch()

  const isVideoPlaying = useSelector(playerSelectors.getIsVideoPlaying)

  const onClick = () => {
    if (isVideoPlaying) {
      dispatch(playerActions.onPlayerPaused())
    } else {
      dispatch(playerActions.onPlayerPlay())
    }
  }

  return (
    <ToolbarButton
      onClick={onClick}
      Icon={isVideoPlaying ? Pause : PlayArrow}
      title={isVideoPlaying ? 'Pause' : 'Play'}
    />
  )
}

export default memo(PlaybackButton)
