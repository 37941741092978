import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'

import CertificatesGrid from '../CertificatesGrid'

const UserCertificatesDialog = ({ open, user, onClose, onExited, personal }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md' TransitionProps={{ unmountOnExit: true, onExited }}>
      <DialogTitle>User certificates</DialogTitle>

      <DialogContent>
        <CertificatesGrid simpleView user={user} personal={personal} />
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserCertificatesDialog.defaultProps = {
  personal: false,
}

UserCertificatesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IdentityGuid: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  personal: PropTypes.bool,
  onExited: PropTypes.func,
}

export default UserCertificatesDialog
