export default (theme) => {
  return {
    circleIcon: {
      width: '14px',
      height: '14px',
      borderRadius: '50%',
      marginBottom: '2px',
    },
  }
}
