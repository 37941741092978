import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'
import { Button, Menu, MenuItem, Tooltip, withStyles } from '@material-ui/core'
import Clamp from 'react-multiline-clamp'
import AnswerStatusCircleIcon from '@tabeeb/modules/forms/components/AnswerStatusCircleIcon'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { setFormAnswerStatus } from '@tabeeb/modules/forms/actions'
import { makeGetAnswerStatusDropdownOptions, makeGetSelectedAnswerStatus } from '@tabeeb/modules/forms/selectors'
import styles from './styles'

const AnswerStatus = ({ classes, answer, isOnReview }) => {
  const dispatch = useDispatch()
  const contentId = useSelector(getContentId)

  const [anchorRef, open, handleOpen, handleClose] = usePopoverState()

  const handleStatusChange = (value) => {
    const statusModel = {
      FormAnswerStatus: value,
      ContentId: contentId,
      FormAnswerId: answer.Id,
    }

    dispatch(setFormAnswerStatus(statusModel))
    handleClose()
  }

  const statusSelector = useMemo(makeGetSelectedAnswerStatus, [])
  const status = useSelector((state) => statusSelector(state, { answer }))

  const optionsSelector = useMemo(makeGetAnswerStatusDropdownOptions, [])
  const options = useSelector((state) => optionsSelector(state, { answer }))
  const isDisabled = status.isDisabled || isOnReview

  return (
    <div className={classes.status} style={{ opacity: isOnReview ? 0.5 : 1 }}>
      <AnswerStatusCircleIcon answerStatus={answer.FormAnswerStatus} />
      <Tooltip placement='top' arrow title={isDisabled ? '' : status.displayValue}>
        <Button
          ref={anchorRef}
          onClick={handleOpen}
          disabled={isDisabled}
          className={classes.currentStatus}
          style={{ color: status.color }}
          variant='text'
        >
          <Clamp withTooltip={false} lines={2}>
            <span>{status.displayValue}</span>
          </Clamp>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorRef.current}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              onClick={() => handleStatusChange(option.value)}
              style={{ color: option.color }}
            >
              {option.displayValue}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

AnswerStatus.propTypes = {
  answer: PropTypes.shape({
    Id: PropTypes.number,
    FormAnswerStatus: PropTypes.number,
  }),
  classes: PropTypes.shape({
    status: PropTypes.string.isRequired,
    currentStatus: PropTypes.string.isRequired,
  }).isRequired,
  isOnReview: PropTypes.bool.isRequired,
}

export default withStyles(styles)(memo(AnswerStatus))
