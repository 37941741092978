import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  content: {
    width: 500,
    '&[disabled]': {
      pointerEvents: 'none',
      opacity: 0.4,
    },
  },
}))
