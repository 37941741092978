import qs from 'qs'

const certificatesRequests = {
  getCertificatesTypesWithPaginationRequest: ({ search, sort, filterModels, pageNumber, pageSize }) => ({
    url: `certificate-service/api/certificateTypes/paginated`,
    method: 'get',
    params: {
      search,
      sort,
      filterModels,
      pageNumber,
      pageSize,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  getAllCertificateTypesRequest: ({ search, showCurrentUserCertificates, certificateScopeTypes }) => ({
    url: `certificate-service/api/certificateTypes/all`,
    method: 'get',
    params: {
      showCurrentUserCertificates,
      certificateScopeTypes,
      search,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  getApprovedCertificateTypeIdsByUserGuidRequest: ({ userGuid }) => ({
    url: `certificate-service/api/certificateTypes/approved/user/${userGuid}`,
    method: 'get',
  }),
  createCertificateTypeRequest: (data) => ({
    url: `certificate-service/api/certificateTypes`,
    method: 'post',
    data,
  }),
  deleteCertificateTypeRequest: ({ id }) => ({
    url: `certificate-service/api/certificateTypes/${id}`,
    method: 'delete',
  }),
  updateCertificateTypeRequest: (data) => ({
    url: `certificate-service/api/certificateTypes`,
    method: 'put',
    data,
  }),
  restoreCertificateTypeRequest: ({ id }) => ({
    url: `certificate-service/api/certificateTypes/restore/${id}`,
    method: 'put',
  }),
  deleteCertificateTypePermanentlyRequest: ({ id }) => ({
    url: `certificate-service/api/certificateTypes/${id}/permanently`,
    method: 'delete',
  }),
  getMyCertificatesRequest: ({ search, sort, filterModels, pageNumber, pageSize }) => ({
    url: `certificate-service/api/certificates/my`,
    method: 'get',
    params: {
      search,
      sort,
      filterModels,
      pageNumber,
      pageSize,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  getCertificatesRequest: ({
    search,
    sort,
    filterModels,
    pageNumber,
    pageSize,
    contractorSourceTenantId,
    userGuid,
    userId,
  }) => ({
    url: `certificate-service/api/certificates/paginated`,
    method: 'get',
    params: {
      search,
      sort,
      filterModels,
      pageNumber,
      pageSize,
      contractorSourceTenantId,
      userGuid,
      userId,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  createCertificateRequest: (data) => ({
    url: `certificate-service/api/certificates`,
    method: 'post',
    data,
  }),
  updateCertificateRequest: (data) => ({
    url: `certificate-service/api/certificates`,
    method: 'put',
    data,
  }),
  getCertificateByIdRequest: ({ id }) => ({
    url: `certificate-service/api/certificates/${id}`,
    method: 'get',
  }),
  reviewCertificateRequest: (data) => ({
    url: `certificate-service/api/certificates/review`,
    method: 'put',
    data,
  }),
  getAllCertificateCategoriesRequest: () => ({
    url: `certificate-service/api/certificates/categories`,
    method: 'get',
  }),
  getQualificationsWithPaginationRequest: ({ search, sort, filterModels, pageNumber, pageSize }) => ({
    url: `certificate-service/api/qualifications/paginated`,
    method: 'get',
    params: {
      search,
      sort,
      filterModels,
      pageNumber,
      pageSize,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  getQualificationVersionsRequest: ({ qualificationId }) => ({
    url: `certificate-service/api/qualifications/${qualificationId}/versions`,
    method: 'get',
  }),
  getQualificationVersionRequest: ({ qualificationId, versionId }) => ({
    url: `certificate-service/api/qualifications/${qualificationId}/versions/${versionId}`,
    method: 'get',
  }),
  createQualificationRequest: (data) => ({
    url: `certificate-service/api/qualifications`,
    method: 'post',
    data,
  }),
  createQualificationVersionRequest: (data) => ({
    url: `certificate-service/api/qualifications/versions`,
    method: 'post',
    data,
  }),
  updateQualificationRequest: (data) => ({
    url: `certificate-service/api/qualifications`,
    method: 'put',
    data,
  }),
  updateQualificationVersionRequest: (data) => ({
    url: `certificate-service/api/qualifications/versions`,
    method: 'put',
    data,
  }),
  searchQualificationVersionsRequest: ({ search }) => ({
    url: `certificate-service/api/qualifications/versions`,
    method: 'get',
    params: { search },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  searchQualificationRequest: ({ search }) => ({
    url: `certificate-service/api/qualifications`,
    method: 'get',
    params: { search },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  deleteQualificationRequest: ({ id }) => ({
    url: `certificate-service/api/qualifications/${id}`,
    method: 'delete',
  }),
  restoreQualificationRequest: ({ id }) => ({
    url: `certificate-service/api/qualifications/restore/${id}`,
    method: 'put',
  }),
  getUserQualificationsRequest: ({ sort, filterModels, pageNumber, pageSize, userGuid }) => ({
    url: `certificate-service/api/userQualifications/paginated`,
    method: 'get',
    params: {
      sort,
      filterModels,
      pageNumber,
      pageSize,
      userGuid,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  getActiveUserQualificationIdsRequest: ({ userGuids }) => ({
    url: `certificate-service/api/userQualifications/active`,
    method: 'get',
    params: { userGuids },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  createUserQualificationRequest: (data) => ({
    url: `certificate-service/api/userQualifications`,
    method: 'post',
    data,
  }),
  revokeUserQualificationRequest: ({ userQualificationId }) => ({
    url: `certificate-service/api/userQualifications/${userQualificationId}/status/revoke`,
    method: 'put',
  }),
  restoreUserQualificationRequest: ({ userQualificationId, userGuid }) => ({
    url: `certificate-service/api/userQualifications/${userQualificationId}/user/${userGuid}/status/restore`,
    method: 'put',
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  createCertificateCategoryRequest: (data) => ({
    url: `certificate-service/api/certificateCategory`,
    method: 'post',
    data,
  }),
  deleteCertificateRequest: ({ id }) => ({
    url: `certificate-service/api/certificates/${id}`,
    method: 'delete',
  }),
  getLocationsRequest: () => ({
    url: `certificate-service/api/locations`,
    method: 'get',
  }),
}

export default certificatesRequests
