import { useMemo, useState, useEffect } from 'react'

import useProgressiveLoadingResource from './useProgressiveLoadingResource'

import { getTenantSncQuestions } from '../actions'

export default ({ enabled, searchText } = { searchText: '' }) => {
  const [cacheId, setCacheId] = useState(null)

  const payload = useMemo(
    () => ({
      isDeleted: false,
      cacheId,
      searchQuery: { Filtering: { QuestionFilter: searchText } },
    }),
    [searchText, cacheId]
  )

  const { loading, loaded, items, hasMore, onLoadMore } = useProgressiveLoadingResource({
    enabled,
    request: getTenantSncQuestions.request,
    payload,
  })

  useEffect(() => {
    if (items[0] && items[0].CacheId !== cacheId) {
      setCacheId(items[0].CacheId)
    }
  }, [items, cacheId])

  return {
    loading,
    loaded,
    questions: items,
    hasMore,
    onLoadMore,
  }
}
