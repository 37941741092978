import { handleActions } from 'redux-actions'
import { setAiObjectAnalysisNeedToRefresh, getAiObjectAnalysis, deleteUniqueAiObjectFromContent } from '../actions'

const defaultState = false

export default handleActions(
  {
    [setAiObjectAnalysisNeedToRefresh](state, action) {
      return action.payload
    },
    [getAiObjectAnalysis.request](state, action) {
      return defaultState
    },
    [deleteUniqueAiObjectFromContent.success](state, action) {
      return true
    },
  },
  defaultState
)
