import { all } from 'redux-saga/effects'

import meSagas from './meSagas'
import loginSagas from './loginSagas'

function* accountSagas() {
  yield all([meSagas(), loginSagas()])
}

export default accountSagas
