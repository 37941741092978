import { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'

const SearchField = forwardRef(({ value, onFocus, onChange, InputProps, ...rest }, ref) => {
  return (
    <TextField
      ref={ref}
      value={value}
      onFocus={onFocus}
      onChange={onChange}
      fullWidth
      size='small'
      variant='outlined'
      placeholder='Search...'
      InputProps={InputProps}
      {...rest}
    />
  )
})

SearchField.defaultProps = {
  InputProps: {},
}

SearchField.propTypes = {
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  InputProps: PropTypes.object,
}

export default memo(SearchField)
