import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../actions'

const initialState = {
  fetchStatus: FetchStatus.Idle,
  errorDescriptionObject: null,
  report: {
    Id: null,
    EmbedUrl: null,
    EmbedToken: null,
    ReportId: null,
    SecurityModel: null,
  },
}

export default handleActions(
  {
    [rawActions.getPowerBIReportEmbedData.success]: (state, action) => {
      const embedData = action.response.data
      const report = {
        Id: embedData.DashboardId,
        EmbedUrl: embedData.EmbedUrl,
        EmbedToken: embedData.EmbedToken,
        ReportId: embedData.ReportId,
        SecurityModel: embedData.SecurityModel,
      }
      return { ...state, report }
    },
    [rawActions.resetPowerBIReport]: () => {
      return initialState
    },
    [rawActions.getPowerBIEmbedToken.success]: (state, action) => {
      const embedToken = action.response.data.EmbedToken
      return { ...state, report: { ...state.report, EmbedToken: embedToken } }
    },
    [rawActions.getPowerBIReportEmbedData.request]: (state, _) => {
      return { ...state, fetchStatus: FetchStatus.Loading, errorDescriptionObject: null }
    },
    [rawActions.getPowerBIReportEmbedData.success]: (state, action) => {
      const embedData = action.response.data
      const report = {
        Id: embedData.DashboardId,
        EmbedUrl: embedData.EmbedUrl,
        EmbedToken: embedData.EmbedToken,
        ReportId: embedData.ReportId,
        SecurityModel: embedData.SecurityModel,
      }
      return { ...state, fetchStatus: FetchStatus.Loaded, report }
    },
    [rawActions.getPowerBIReportEmbedData.failed]: (state, action) => {
      const errorMessage = action.response.data.Error?.Details || null
      let errorDescriptionObject = null
      if (errorMessage) {
        errorDescriptionObject = JSON.parse(errorMessage)
      }
      return { ...state, fetchStatus: FetchStatus.Failed, errorDescriptionObject }
    },
  },
  initialState
)
