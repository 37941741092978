import { put, takeLatest, select, take, all } from 'redux-saga/effects'
import { REHYDRATE } from 'reduxjs-toolkit-persist'

import { tenantAuthFlowCompleted } from '@tabeeb/modules/account/actions'

import domainService from '@tabeeb/services/domainService'
import { sessionTitleFormatter, trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'

import * as rawActions from '../actions'
import { getSessionTitle, getTrainingMaterialsTitle } from '../selectors'
import * as appActions from '../../../state/actions/appActions'
import * as navbarActions from '../../navbar/actions'

import { signalrEvents, signalrConstants } from '../../signalr'
import * as notificationActions from '../../notification/actions'

function* onAppMounted(action) {
  yield take(tenantAuthFlowCompleted)
  yield put(rawActions.getConfigRequest({ subdomain: domainService.subdomain }))
}

function* getVideoBridgeConfigSuccess(action) {
  window.config = action.response.data
}

function* getConfigFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: `Tenant ${action.payload.subdomain} not found` }))
}

function* initNameFormatters(action) {
  const sessionTitle = yield select(getSessionTitle)
  sessionTitleFormatter.setSessionTitle(sessionTitle)

  const trainingMaterialTitle = yield select(getTrainingMaterialsTitle)
  trainingMaterialTitleFormatter.setTitle(trainingMaterialTitle)
}

function* onVideoBridgeSwitched(action) {
  const [videoBridgeUrl] = action.payload

  const videoBridgeRegions = yield select((state) => state.appConfigState.videoBridgeRegions)
  let currentVideobridge
  if (videoBridgeRegions) {
    currentVideobridge = videoBridgeRegions.find((bridge) => bridge.url === videoBridgeUrl)
  }

  if (currentVideobridge) {
    yield put(navbarActions.setSelectedVideoBridge(currentVideobridge))
  }
}

function* appConfigStateSagas() {
  yield all([
    takeLatest(appActions.onAppMounted, onAppMounted),
    takeLatest(rawActions.getVideoBridgeConfigSuccess, getVideoBridgeConfigSuccess),
    takeLatest(rawActions.getConfigFailed, getConfigFailed),
    takeLatest(
      [appActions.onAppMounted, rawActions.getConfigSuccess, rawActions.onSetAppConfigState, REHYDRATE],
      initNameFormatters
    ),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onVideoBridgeSwitched, onVideoBridgeSwitched),
  ])
}

export default appConfigStateSagas
