import { memo } from 'react'
import PropTypes from 'prop-types'

import { Fade, Tooltip, withStyles } from '@material-ui/core'

import { SignalCellularAlt1Bar, SignalCellularAlt2Bar, SignalCellularAlt } from '@mui/icons-material'
import ConnectionQualityDetails from '../ConnectionQualityDetails'

import styles from './styles'

const getConnectionQualityLevel = (quality) => {
  return Math.floor((quality > 100 ? 100 : quality) / 20) || 0
}

const getConnectionQualityIconByQualityLevel = (qualityLevel) => {
  switch (qualityLevel) {
    case 1:
      return SignalCellularAlt1Bar
    case 2:
    case 3:
      return SignalCellularAlt2Bar
    case 4:
    case 5:
      return SignalCellularAlt
    default:
      return 'div'
  }
}

const ConnectionQualityIndicator = ({ classes, isVisible, bitrate, quality }) => {
  const qualityLevel = getConnectionQualityLevel(quality)

  const QualityIcon = getConnectionQualityIconByQualityLevel(qualityLevel)

  return (
    <Fade in={isVisible} unmountOnExit>
      <Tooltip arrow placement='top' title={<ConnectionQualityDetails bitrate={bitrate} />}>
        <div className={classes.container}>
          <SignalCellularAlt className={classes.backgroundIcon} />
          <QualityIcon className={classes.icon} />
        </div>
      </Tooltip>
    </Fade>
  )
}

ConnectionQualityIndicator.defaultProps = {
  quality: 0,
  bitrate: {
    download: 'N/A',
    upload: 'N/A',
  },
}

ConnectionQualityIndicator.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  bitrate: PropTypes.shape({
    download: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    upload: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  quality: PropTypes.number.isRequired,
}

export default memo(withStyles(styles)(ConnectionQualityIndicator))
