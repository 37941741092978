import { put, takeLatest, all, select, take } from 'redux-saga/effects'

import { AnnotationType } from '@tabeeb/enums'
import { setMeasurementSettings } from '@tabeeb/modules/annotations/actions'
import { isSupportMeasurements } from '@tabeeb/services/pageService'

import { selectGalleryItem, getPageMeasurementSettings } from '../actions'
import { getGalleryItemById, getSelectedGalleryItemId } from '../selectors'

function* fetchPageMeasurementSettings({ payload }) {
  const pageId = payload

  const selectedPage = yield select((state) => getGalleryItemById(state, { id: pageId }))
  if (!selectedPage || !isSupportMeasurements(selectedPage.contentType)) {
    return
  }

  if (!selectedPage.annotations.some((annotation) => annotation.Type === AnnotationType.Distance)) {
    yield put(setMeasurementSettings({ PageId: pageId }))

    return
  }

  try {
    yield put(getPageMeasurementSettings.request(pageId))
    const {
      response: { data: measurementSettings },
    } = yield take(getPageMeasurementSettings.success)

    const currentPageId = yield select(getSelectedGalleryItemId)
    if (pageId !== currentPageId) {
      return
    }

    yield put(setMeasurementSettings(measurementSettings))
  } catch (error) {
    /// ignored
  }
}

function* pageMeasurementSettings() {
  yield all([takeLatest(selectGalleryItem, fetchPageMeasurementSettings)])
}

export default pageMeasurementSettings
