import { memo } from 'react'
import PropTypes from 'prop-types'

import { Person } from '@mui/icons-material'

import SessionBadge from '../SessionBadge'

const ContentParticipantsCountBadge = ({ participants }) => {
  return <SessionBadge icon={Person} title={`${participants} participant(s)`} label={participants} />
}

ContentParticipantsCountBadge.propTypes = {
  participants: PropTypes.number.isRequired,
}

export default memo(ContentParticipantsCountBadge)
