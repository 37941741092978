import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1728.000000 1680.000000'
    preserveAspectRatio='xMidYMid meet'
  >
    <g transform='translate(0.000000,1680.000000) scale(0.100000,-0.100000)' stroke='none'>
      <path
        d='M8260 15985 c-199 -19 -289 -31 -395 -50 -49 -9 -112 -21 -140 -26
-27 -5 -79 -16 -115 -24 -36 -9 -81 -20 -100 -24 -190 -43 -687 -211 -875
-296 -356 -160 -628 -313 -925 -522 -165 -116 -397 -314 -580 -495 -204 -201
-261 -264 -468 -513 -87 -105 -329 -474 -426 -648 -41 -74 -196 -384 -196
-391 0 -3 -11 -29 -24 -58 -78 -176 -193 -500 -251 -713 -15 -55 -31 -113 -36
-130 -29 -105 -81 -382 -99 -525 -33 -276 -40 -392 -40 -645 0 -261 2 -290 46
-680 27 -234 91 -506 187 -795 28 -85 57 -166 64 -180 7 -14 13 -28 13 -33 0
-4 9 -26 19 -50 11 -23 27 -60 36 -82 49 -123 188 -402 277 -556 123 -212 341
-547 367 -563 4 -3 22 10 41 27 19 18 53 45 75 60 46 32 48 33 255 187 85 63
200 149 257 190 248 181 246 180 200 237 -12 15 -35 46 -49 68 -15 22 -43 63
-63 90 -110 154 -256 425 -342 635 -30 74 -59 144 -64 155 -50 113 -147 476
-178 670 -7 39 -16 95 -21 125 -32 188 -37 774 -9 950 6 36 14 99 19 140 5 41
16 109 25 150 9 41 20 98 25 125 18 104 32 154 81 300 27 83 54 159 58 170 5
11 16 43 24 70 33 107 69 192 123 292 31 58 74 142 96 186 62 124 172 299 267
425 47 63 91 123 97 134 43 76 415 463 573 597 184 156 424 326 626 445 44 26
87 52 95 57 31 19 329 159 339 159 6 0 19 5 29 10 58 34 442 159 587 190 22 5
72 16 110 25 39 8 99 20 135 25 36 5 72 11 80 14 53 19 309 36 543 36 303 0
501 -18 762 -70 33 -6 85 -18 115 -26 30 -8 96 -26 145 -39 124 -33 356 -116
495 -176 25 -11 54 -24 65 -29 36 -15 218 -106 290 -145 200 -108 510 -319
640 -434 23 -20 72 -63 110 -95 300 -256 628 -662 838 -1036 68 -122 197 -381
236 -475 38 -91 125 -349 147 -439 32 -126 36 -143 50 -211 63 -293 78 -448
78 -795 0 -278 -4 -346 -36 -570 -39 -281 -93 -493 -195 -775 -52 -143 -186
-424 -261 -550 -57 -94 -211 -326 -248 -372 -19 -23 -34 -50 -33 -60 0 -10 27
-38 62 -65 34 -26 114 -87 177 -135 127 -98 387 -291 523 -390 85 -62 90 -64
108 -47 10 9 19 21 19 26 0 4 28 48 63 96 129 178 327 510 433 722 84 170 130
277 199 465 20 55 42 116 50 135 28 78 65 201 65 219 0 11 5 23 10 26 6 3 10
15 10 25 0 11 6 39 13 62 29 92 77 333 101 513 58 421 54 968 -9 1335 -44 257
-59 334 -90 455 -15 55 -30 116 -35 135 -35 149 -163 517 -245 705 -59 135
-163 348 -206 419 -10 17 -31 56 -48 87 -59 111 -258 416 -305 469 -13 14 -49
61 -80 105 -32 44 -70 94 -84 110 -15 17 -38 44 -50 60 -28 35 -129 149 -222
249 -120 129 -329 326 -457 431 -7 6 -18 15 -25 21 -76 62 -251 192 -323 239
-49 33 -91 63 -93 68 -2 4 -8 7 -12 7 -5 0 -53 28 -107 61 -224 141 -503 280
-783 392 -141 57 -395 145 -496 172 -173 48 -348 90 -434 105 -376 68 -502 80
-865 85 -198 2 -336 -1 -435 -10z'
      />
      <path
        d='M8510 12659 c-292 -34 -513 -97 -707 -201 -29 -15 -79 -42 -110 -59
-98 -53 -211 -139 -332 -254 -195 -186 -364 -450 -444 -695 -48 -148 -55 -172
-74 -285 -22 -127 -24 -524 -4 -630 35 -191 65 -298 107 -392 8 -17 14 -34 14
-37 0 -4 23 -52 50 -107 152 -304 402 -577 685 -745 120 -72 250 -136 325
-160 30 -9 60 -20 65 -24 6 -4 27 -10 48 -14 21 -4 37 -13 37 -20 0 -7 -75
-166 -166 -352 -91 -187 -175 -359 -186 -384 -23 -51 -77 -165 -168 -355 -133
-279 -149 -313 -195 -410 -26 -55 -77 -161 -113 -235 -36 -74 -91 -189 -122
-255 -31 -66 -91 -190 -132 -275 -42 -85 -87 -180 -101 -210 -39 -86 -117
-251 -202 -425 -42 -88 -110 -227 -150 -310 -40 -82 -81 -168 -90 -190 -10
-22 -47 -100 -82 -173 -35 -74 -63 -136 -63 -137 0 -2 -32 -71 -71 -152 -155
-323 -234 -490 -329 -698 -42 -93 -98 -213 -123 -265 -24 -52 -55 -117 -67
-145 -12 -27 -44 -95 -70 -150 -60 -127 -104 -220 -147 -315 -19 -41 -43 -93
-53 -115 -48 -103 -160 -345 -180 -390 -44 -98 -82 -181 -127 -275 -45 -95
-60 -126 -127 -273 -20 -42 -52 -111 -71 -152 -19 -41 -35 -77 -35 -80 0 -5
-45 -108 -65 -147 -12 -23 -11 -28 4 -42 10 -9 22 -16 27 -16 10 0 207 -68
274 -95 44 -17 230 -84 375 -135 61 -21 168 -59 239 -84 71 -25 133 -43 138
-40 4 3 22 35 39 72 17 37 46 99 64 137 31 66 53 113 141 303 20 42 49 104 64
137 15 33 44 94 63 135 19 41 54 116 77 165 23 50 57 122 75 160 35 76 94 201
157 333 39 84 76 164 118 257 12 28 57 122 100 210 42 88 87 183 100 210 68
151 102 215 115 215 8 0 31 -7 52 -16 76 -33 102 -42 283 -99 41 -13 154 -51
250 -85 610 -213 906 -310 944 -310 28 0 527 157 746 235 36 12 207 69 380
125 173 57 338 111 367 122 29 11 57 17 64 13 12 -8 309 -611 309 -629 0 -2
34 -75 76 -162 42 -88 87 -184 101 -214 14 -30 55 -116 90 -190 36 -74 77
-160 90 -190 14 -30 45 -97 69 -149 24 -51 44 -95 44 -98 0 -2 22 -50 49 -106
52 -110 97 -206 181 -387 100 -214 100 -214 162 -187 18 8 42 17 53 20 11 3
106 35 210 72 105 37 201 70 215 75 14 4 77 27 140 50 63 23 142 51 175 62 33
11 65 24 70 28 6 4 39 16 74 26 35 10 66 24 68 32 6 15 -21 79 -137 317 -49
99 -109 225 -133 280 -25 55 -62 136 -82 180 -20 44 -43 94 -50 110 -7 17 -44
95 -81 175 -36 80 -75 165 -86 190 -32 74 -48 108 -126 270 -41 85 -90 189
-109 230 -79 167 -111 237 -128 275 -10 22 -38 83 -63 135 -25 52 -59 127 -77
165 -18 39 -56 120 -85 180 -29 61 -63 135 -77 165 -40 86 -100 213 -168 355
-34 72 -84 175 -110 230 -26 55 -73 154 -105 220 -56 116 -105 220 -177 375
-67 143 -154 325 -198 415 -108 223 -235 487 -235 490 0 5 -82 177 -206 433
-52 107 -94 195 -94 197 0 2 -29 64 -64 137 -58 121 -101 212 -166 353 -13 28
-51 109 -85 180 -34 72 -70 148 -80 170 -9 22 -52 112 -95 200 -43 88 -90 187
-105 220 -14 33 -39 87 -55 120 -41 87 -60 132 -60 144 0 6 12 13 28 17 31 6
28 5 175 68 60 25 147 67 194 93 47 26 97 54 112 62 100 54 363 292 431 391
14 19 30 41 37 48 25 26 100 148 142 232 24 47 47 92 52 100 5 8 12 22 15 30
3 8 10 24 14 35 31 72 61 167 91 285 62 239 63 594 2 850 -37 158 -120 365
-193 485 -22 36 -40 68 -40 70 0 7 -96 141 -145 200 -138 170 -322 322 -530
435 -75 42 -255 119 -320 137 -158 46 -229 60 -392 79 -140 15 -181 16 -293 3z
m162 -5369 c54 -108 418 -879 418 -885 0 -1 20 -45 44 -96 24 -52 55 -119 69
-149 14 -30 48 -104 77 -165 107 -228 140 -304 140 -326 0 -21 -27 -35 -160
-77 -52 -17 -133 -45 -180 -62 -227 -83 -416 -141 -445 -137 -25 4 -193 58
-270 87 -11 5 -49 17 -85 29 -82 26 -336 114 -373 130 -16 6 -33 11 -38 11
-25 0 10 86 169 420 114 241 156 328 179 380 14 30 45 96 68 145 23 50 52 113
65 140 12 28 32 73 45 100 13 28 35 77 50 110 15 33 58 123 95 201 37 77 71
151 75 163 10 31 37 23 57 -19z'
      />
      <path
        d='M2616 6678 c-5 -24 -11 -74 -25 -203 -5 -44 -14 -111 -21 -150 -6
-38 -15 -99 -20 -135 -5 -36 -17 -112 -25 -170 -26 -172 -59 -409 -75 -540 -5
-41 -16 -127 -24 -190 -9 -63 -20 -155 -26 -205 -7 -49 -16 -119 -21 -155 -21
-140 -48 -344 -69 -535 -5 -44 -16 -134 -25 -200 -33 -248 -33 -263 7 -283 13
-7 42 -27 65 -44 61 -47 189 -142 296 -220 51 -37 103 -76 117 -86 14 -10 63
-45 110 -77 47 -33 87 -63 88 -67 2 -4 8 -8 14 -8 8 0 113 -74 253 -179 22
-17 87 -65 145 -107 58 -43 146 -107 195 -144 50 -36 180 -131 289 -210 110
-78 208 -151 219 -161 23 -21 44 -15 53 13 3 12 46 102 94 202 48 100 94 197
103 216 8 19 40 87 70 150 30 63 64 138 77 165 12 28 40 87 61 133 163 345
179 381 179 397 0 8 4 16 9 19 5 3 11 15 14 26 8 32 2 35 -553 210 -222 71
-451 146 -530 175 -36 13 -121 42 -190 64 -69 22 -150 49 -180 60 -30 10 -82
27 -115 36 -33 10 -94 28 -135 41 -41 13 -82 24 -92 24 -30 0 -41 36 -25 83 8
23 20 62 27 87 17 63 41 141 51 165 4 11 20 61 35 110 14 50 32 108 39 130 8
22 21 70 31 108 9 37 21 67 26 67 5 0 57 -16 116 -35 132 -43 520 -167 702
-225 74 -23 281 -91 460 -150 487 -160 613 -200 635 -200 14 1 28 17 47 58 15
31 61 129 103 217 43 88 88 183 100 210 13 28 55 115 93 195 104 216 126 270
116 280 -10 10 -259 115 -307 129 -18 5 -84 28 -147 51 -63 23 -142 50 -175
61 -33 11 -69 24 -80 29 -36 15 -317 115 -410 145 -38 13 -119 42 -180 65 -60
23 -121 46 -135 50 -14 5 -77 27 -140 50 -63 23 -142 50 -175 62 -33 11 -100
35 -150 53 -49 18 -124 45 -165 60 -41 15 -88 33 -105 40 -16 7 -55 21 -85 31
-64 20 -246 87 -406 150 -61 23 -128 49 -150 57 -21 8 -47 18 -56 22 -13 5
-18 1 -22 -17z'
      />
      <path
        d='M14555 6683 c-22 -8 -64 -26 -93 -39 -29 -13 -59 -24 -67 -24 -7 0
-16 -4 -19 -9 -3 -5 -16 -11 -28 -14 -13 -3 -54 -17 -93 -32 -38 -15 -99 -38
-135 -51 -36 -13 -74 -28 -85 -33 -11 -5 -105 -42 -210 -81 -104 -40 -224 -85
-265 -102 -41 -16 -102 -40 -135 -53 -69 -27 -664 -252 -865 -327 -74 -28
-207 -79 -295 -113 -88 -34 -189 -72 -225 -85 -36 -12 -78 -28 -95 -34 -41
-17 -93 -36 -133 -47 -58 -18 -57 -21 51 -249 30 -63 65 -137 77 -165 12 -27
37 -81 55 -120 59 -125 105 -226 105 -230 0 -3 23 -52 50 -110 28 -58 50 -107
50 -109 0 -2 22 -51 49 -108 63 -135 81 -175 156 -348 21 -47 50 -113 66 -148
16 -34 29 -64 29 -66 0 -2 21 -48 46 -102 85 -183 142 -308 180 -394 20 -47
49 -112 64 -145 15 -33 49 -109 75 -170 26 -60 60 -137 75 -170 15 -33 37 -82
50 -110 12 -27 42 -92 66 -144 24 -51 44 -94 44 -96 0 -1 26 -58 59 -126 32
-68 73 -158 90 -199 53 -124 100 -200 125 -200 6 0 190 -89 407 -199 902 -454
1555 -781 1561 -781 4 0 30 -14 58 -32 61 -38 71 -31 58 45 -10 55 -33 208
-48 317 -19 134 -30 205 -36 220 -4 8 -10 51 -15 95 -5 44 -16 127 -24 185 -9
58 -20 137 -25 175 -18 141 -30 221 -35 243 -3 13 -10 53 -15 90 -5 37 -16
112 -25 167 -9 55 -20 132 -25 170 -5 39 -16 113 -25 165 -8 52 -20 127 -25
165 -5 39 -16 117 -25 175 -8 58 -20 137 -25 175 -5 39 -16 111 -25 160 -9 50
-20 119 -25 155 -5 36 -16 112 -25 170 -9 58 -20 134 -25 170 -6 36 -17 108
-25 160 -9 52 -20 127 -25 165 -5 39 -16 120 -25 180 -9 61 -20 145 -25 188
-5 44 -11 91 -15 105 -3 15 -10 61 -16 102 -6 41 -15 100 -19 130 -31 192 -42
263 -50 325 -5 39 -16 113 -25 165 -9 52 -20 124 -24 160 -13 103 -31 220 -44
280 -6 30 -10 62 -8 70 1 8 -5 55 -14 105 -21 115 -31 168 -51 285 -9 52 -18
101 -20 108 -4 16 -20 15 -69 -5z'
      />
      <path
        d='M2066 3028 c-3 -13 -7 -50 -10 -83 -3 -33 -12 -112 -21 -175 -9 -63
-20 -142 -25 -175 -10 -76 -39 -269 -74 -495 -40 -252 -59 -400 -53 -406 3 -3
1 -18 -4 -33 -6 -14 -15 -57 -20 -96 -5 -38 -16 -122 -24 -185 -21 -155 -35
-284 -30 -288 3 -4 822 403 1405 698 148 74 285 151 319 177 11 8 -61 69 -259
217 -266 199 -251 188 -357 263 -48 34 -93 66 -99 71 -6 6 -69 51 -140 102
-160 115 -417 306 -506 377 -73 58 -95 65 -102 31z'
      />
      <path
        d='M7470 2844 c-38 -81 -70 -148 -70 -150 0 -1 -32 -70 -71 -151 -39
-81 -85 -177 -101 -213 -63 -134 -108 -229 -177 -375 -40 -82 -76 -159 -81
-170 -14 -33 -61 -137 -76 -172 -8 -18 -21 -49 -30 -68 -21 -48 -15 -65 26
-80 19 -7 78 -29 130 -48 249 -92 461 -171 515 -192 64 -25 495 -191 605 -232
36 -14 97 -36 135 -49 39 -14 79 -29 90 -34 11 -4 54 -20 95 -35 41 -15 98
-36 127 -47 l52 -20 78 27 c43 14 177 64 298 110 121 46 254 95 295 110 41 15
84 30 95 35 65 25 222 84 365 135 41 14 91 33 110 40 19 7 98 37 175 65 77 28
149 55 160 60 24 9 183 66 208 74 27 9 21 32 -44 165 -34 70 -85 178 -114 241
-29 63 -80 172 -114 242 -33 70 -61 130 -61 133 0 3 -26 60 -59 128 -55 115
-96 204 -126 272 -88 205 -149 325 -163 325 -7 0 -46 -13 -85 -29 -107 -43
-266 -107 -407 -162 -69 -27 -134 -54 -145 -59 -11 -5 -67 -28 -125 -50 -58
-23 -119 -48 -137 -56 -17 -8 -34 -14 -37 -14 -3 0 -25 -9 -49 -19 -92 -42
-115 -45 -160 -23 -23 11 -62 27 -87 37 -25 10 -58 23 -75 30 -16 7 -68 27
-115 45 -47 17 -98 38 -115 45 -16 8 -106 44 -200 80 -93 37 -179 70 -190 75
-11 5 -63 28 -115 51 -52 23 -109 48 -127 55 l-32 14 -71 -146z'
      />
    </g>
  </svg>,
  'PointCloudLocation'
)
