import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { AnnotationType } from '../../../Enums'
import ActivityInfo from '../components/ActivityInfo'
import * as activityActions from '../actions'
import { getAnnotationMessage, timeFormatSeconds } from '../services/activityHelper'

class ActivityInfoContainer extends Component {
  getAnnotationText(activity) {
    if (!(activity.type === AnnotationType.Text || activity.type === AnnotationType.AI)) {
      return
    }

    const annotationText = activity.text || activity.textInfo.text

    let resultText = annotationText.substring(0, 150)

    if (annotationText.length > 150) {
      resultText += '...'
    }

    return resultText
  }

  getDurationTime(activity) {
    if (typeof activity.duration !== 'undefined' && activity.type === AnnotationType.Audio) {
      return timeFormatSeconds(activity.duration / 1000)
    }

    return null
  }

  render() {
    const { activity } = this.props

    const props = {
      activity,
      annotationMessage: getAnnotationMessage(activity),
      annotationTime: timeFormatSeconds(activity.timestamp),
      durationTime: this.getDurationTime(activity),
      annotationText: this.getAnnotationText(activity),
    }

    return <ActivityInfo {...props} />
  }
}

ActivityInfoContainer.propTypes = {
  activity: PropTypes.object.isRequired,
}

const mapStateToProps = (state, { activity }) => {
  return {
    activity,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(activityActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityInfoContainer)
