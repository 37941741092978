import { handleActions, combineActions } from 'redux-actions'
import * as formActions from '../actions'

const isAttachDetachProcessing = handleActions(
  {
    [combineActions(formActions.attachFormRequest, formActions.detachFormRequest)](state, action) {
      return true
    },
    [combineActions(
      formActions.attachFormSuccess,
      formActions.attachFormFailed,
      formActions.detachFormSuccess,
      formActions.detachFormFailed
    )](state, action) {
      return false
    },
    [formActions.resetFormsState](state, action) {
      return false
    },
  },
  false
)

export default isAttachDetachProcessing
