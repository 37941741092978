import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'
import { updateTargetContentSearchValue } from '../../../../actions'
import useStyles from './styles'

const TargetContentSearchInput = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const value = useSelector((state) => state.sessionsPage.targetContentSearchValue)

  const _onSearch = (e) => {
    dispatch(updateTargetContentSearchValue(e.target.value))
  }

  return (
    <TextField
      value={value}
      onChange={_onSearch}
      placeholder='Search for session in destination tenant...'
      className={classes.input}
    />
  )
}

export default memo(TargetContentSearchInput)
