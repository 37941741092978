import { handleActions } from 'redux-actions'

import { setExistingReminder } from '../actions'

const defaultValue = null

export default handleActions(
  {
    [setExistingReminder](state, { payload }) {
      return payload
    },
  },
  defaultValue
)
