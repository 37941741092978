import { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import * as dates from 'react-big-calendar/lib/utils/dates'
import { Navigate, Views } from 'react-big-calendar'

import { Box } from '@material-ui/core'

import Month from './Month'

const Year = ({ date, onDrillDown }) => {
  const handleSelectDate = useCallback(
    (date) => {
      onDrillDown(date, Views.DAY)
    },
    [onDrillDown]
  )

  const year = date.getFullYear()

  const months = useMemo(() => {
    return Array.from(new Array(12)).map((_, monthNumber) => (
      <Month key={monthNumber} year={year} month={monthNumber} onSelectDate={handleSelectDate} />
    ))
  }, [year, handleSelectDate])

  return (
    <Box display='flex' flexWrap='wrap' justifyContent='space-between' overflow='auto'>
      {months}
    </Box>
  )
}

Year.propTypes = {
  date: PropTypes.object.isRequired,
  onDrillDown: PropTypes.func.isRequired,
}

const memoized = memo(Year, ({ date: prevDate }, { date }) => {
  return prevDate.getFullYear() === date.getFullYear()
})

memoized.range = (date) => {
  return [dates.startOf(date, 'year'), dates.endOf(date, 'year')]
}

memoized.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -1, 'year')

    case Navigate.NEXT:
      return dates.add(date, 1, 'year')

    default:
      return date
  }
}

memoized.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default memoized
