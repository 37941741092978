export default (theme) => {
  return {
    tab: {
      position: 'relative',
      textTransform: 'capitalize',
      opacity: 1,
      fontSize: 14,
    },
    label: {
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none !important',
      position: 'relative',
    },
    menuItem: {
      minWidth: '160px',
      width: '100%',
      '& i, svg': {
        marginRight: theme.spacing(),
      },
    },
  }
}
