import { handleActions, combineActions } from 'redux-actions'
import { getContentFormsFailed, getContentFormsRequest, getContentFormsSuccess, resetFormsState } from '../actions'

const defaultState = {
  loading: false,
  loaded: false,
}

export default handleActions(
  {
    [getContentFormsRequest](state) {
      return { ...state, loading: true }
    },
    [combineActions(getContentFormsSuccess, getContentFormsFailed)](state) {
      return { ...state, loading: false, loaded: true }
    },
    [resetFormsState]() {
      return defaultState
    },
  },
  defaultState
)
