import { handleActions } from 'redux-actions'

import { openEditAiClassDependentModelsDialog, closeEditAiClassDependentModelsDialog } from '../actions'

const defaultState = {
  open: false,
  classId: null,
}

export default handleActions(
  {
    [openEditAiClassDependentModelsDialog](state, action) {
      return {
        open: true,
        classId: action.payload,
      }
    },
    [closeEditAiClassDependentModelsDialog](state, action) {
      return {
        ...state,
        open: false,
      }
    },
  },
  defaultState
)
