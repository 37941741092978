import { useEffect, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  resetContentsSearchState,
  searchTargetContentForSharing,
  setContentsForSharing,
  updateSearchContentText,
} from '@tabeeb/modules/contentSharings/actions'
import { getContentSearchState, getContentSharingDialogState } from '@tabeeb/modules/contentSharings/selectors'
import { useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'
import { SearchResultRowBtn } from '@tabeeb/modules/contentSharings/constants/enums'
import SearchContentResult from './searchContentResult'
import ContentRow from './contentRow'
import { SelectContainer, StyledInput, SearchResultContainer } from './styles'

const ContentDestination = ({ isDisabled }) => {
  const dispatch = useDispatch()
  const { user, targetContents, tenant } = useSelector(getContentSharingDialogState)
  const { searchText } = useSelector(getContentSearchState)
  const debouncedSearchQuery = useDebouncedValue(searchText, 500)
  const targetContent = targetContents[0]

  useEffect(() => {
    if (debouncedSearchQuery && user) {
      dispatch(
        searchTargetContentForSharing.request({
          searchQuery: debouncedSearchQuery,
          contentOwnerId: user.Id,
          tenantId: tenant?.Id,
        })
      )
    }

    if (!debouncedSearchQuery) {
      dispatch(resetContentsSearchState())
    }
  }, [debouncedSearchQuery, dispatch, user, tenant])

  const _handleContentClick = useCallback(() => {
    dispatch(setContentsForSharing([]))
  }, [dispatch])

  return (
    <SelectContainer>
      {!targetContent && (
        <StyledInput
          value={searchText}
          onChange={(e) => dispatch(updateSearchContentText(e.target.value))}
          disableUnderline
          disabled={isDisabled}
        />
      )}
      <SearchResultContainer>
        {!targetContent && <SearchContentResult />}
        {targetContent && (
          <ContentRow
            content={targetContent}
            button={SearchResultRowBtn.Remove}
            handleButtonClick={_handleContentClick}
          />
        )}
      </SearchResultContainer>
    </SelectContainer>
  )
}

ContentDestination.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
}

export default memo(ContentDestination)
