import { handleActions } from 'redux-actions'

import * as whiteboardActions from '../actions'

const defaultValue = null

export default handleActions(
  {
    [whiteboardActions.openSwitchRecordingSourceDialog](state, action) {
      return action.payload || defaultValue
    },
    [whiteboardActions.closeSwitchRecordingSourceDialog](state, action) {
      return null
    },
    [whiteboardActions.resetWhiteboardState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
