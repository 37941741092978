export default (theme) => {
  return {
    icon: {
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.75),
      minWidth: 0,
      height: 35,
      color: theme.palette.text.secondary,
    },
  }
}
