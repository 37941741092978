export default (theme) => {
  return {
    counter: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 12,

      '&:last-child': {
        marginBottom: 2,
      },
      padding: 10,
    },
    disabledCounter: {
      opacity: 0.7,
    },
    label: {
      fontWeight: 'bold',
      marginBottom: 8,
      overflow: 'auto',
    },
  }
}
