import { memo } from 'react'

import { EventBusy } from '@material-ui/icons'

import Placeholder from '../Placeholder'

const FailedToLoadCalendarsListPlaceholder = () => {
  return <Placeholder icon={EventBusy} text='Failed to load calendars list...' />
}

export default memo(FailedToLoadCalendarsListPlaceholder)
