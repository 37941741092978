import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FormAnswersDiscussion } from '@tabeeb/modules/shared/icons'
import { openQuestionFormAnswersDialog } from '@tabeeb/modules/discussion/actions'
import { getFormById } from '@tabeeb/modules/forms/selectors'
import FormControlButton from '@tabeeb/modules/forms/components/FormControlButton'

const QuestionAnswersDiscussionButton = ({ question }) => {
  const dispatch = useDispatch()

  const form = useSelector((state) => getFormById(state, { Id: question.FormId }))
  if (!form) {
    return null
  }

  question.FormName = form.Name
  question.ContentFormId = form.ContentFormId

  const componentProps = {
    onClick: () => dispatch(openQuestionFormAnswersDialog({ question })),
    Icon: FormAnswersDiscussion,
    title: 'Open answers',
  }

  return <FormControlButton {...componentProps} />
}

QuestionAnswersDiscussionButton.propTypes = {
  question: PropTypes.shape({
    FormName: PropTypes.string,
    ContentFormId: PropTypes.number,
    FormId: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(QuestionAnswersDiscussionButton)
