import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, useTheme } from '@mui/material'

const DroppableLayout = ({ onDrop }) => {
  const theme = useTheme()

  const [backgroundColor, setBackgroundColor] = useState('')

  return (
    <Box
      position='absolute'
      top={0}
      bottom={0}
      left={0}
      right={0}
      bgcolor={backgroundColor}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={() => setBackgroundColor(theme.palette.action.hover)}
      onDragLeave={() => setBackgroundColor('')}
      onDrop={(e) => {
        setBackgroundColor('')
        onDrop(e)
      }}
    />
  )
}

DroppableLayout.propTypes = {
  onDrop: PropTypes.func.isRequired,
}

export default DroppableLayout
