import { styled } from '@mui/material'

export const SignatureInput = styled('input')(({ theme }) => ({
  width: 300,
  height: 30,
  border: '1px solid #e6e6e6',
  borderRadius: 6,
  padding: 18,
  margin: 0,
  background: 'none',
  outline: 'none',
  resize: 'none',
  color: '#616161',
  fontSize: 20,
  fontFamily: 'Mr Dafoe',
  fontStyle: 'italic',
}))
