const notificationsPageRequests = {
  getNotificationsRequest: ({ batchNumber, batchSize, showAll }) => ({
    url: `api/approvalRequests/getNotifications/${batchNumber}/${batchSize}/${showAll}`,
    method: 'get',
  }),
  getNotificationsCountRequest: () => ({
    url: `api/approvalRequests/notificationCount`,
    method: 'get',
  }),
  acceptNotificationRequest: (data) => ({
    url: `api/approvalRequests/accept`,
    method: 'put',
    data,
  }),
  rejectNotificationRequest: (data) => ({
    url: `api/approvalRequests/reject`,
    method: 'put',
    data,
  }),
  revokeSessionSharingRequest: (notification) => ({
    url: `api/contentsharings/revokesharing/${notification.ContentSharingId}`,
    method: 'get',
  }),
  markNotificationsAsViewedRequest: (notificationsToMark) => ({
    url: `api/approvalRequests/markAsViewed`,
    method: 'post',
    data: notificationsToMark,
  }),
}

export default notificationsPageRequests
