import { handleActions } from 'redux-actions'
import * as customVideoActions from '../actions'

export default handleActions(
  {
    [customVideoActions.openTimeline](state, action) {
      return action.payload
    },
    [customVideoActions.closeTimeline](state, action) {
      return false
    },
    [customVideoActions.resetState](state, action) {
      return false
    },
  },
  false
)
