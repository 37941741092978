import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as aiActions from '../actions'

function* onGetAiObjectsSuccess(action) {
  const { data: aiObjects } = action.response

  if (aiObjects.length <= 0) {
    return
  }

  const selectedObject = yield select((state) => state.artificialIntelligence.selectedAIObject)

  if (!selectedObject.Id) {
    yield put(aiActions.onSelectDrawingAIObject(aiObjects[0]))

    const contentId = yield select((state) => state.contentState.contentId)
    if (contentId) {
      yield put(aiActions.getUniqueAiObjects.request({ aiObjectId: aiObjects[0].Id, contentId }))
    }
  }
}

function* getAiObjects() {
  yield all([takeLatest(aiActions.getAiObjects.success, onGetAiObjectsSuccess)])
}

export default getAiObjects
