import sessionsPageRequests from './sessionsPageRequests'
import usersRequests from './usersRequests'

const contentSharingRequests = {
  copyContentRequest: (data) => ({
    url: `api/contentsharings/copycontent`,
    method: 'post',
    data,
  }),
  getDataBySharedContentFormsRequest: (data) => ({
    url: `api/contentsharings/dataByContentforms`,
    method: 'post',
    data,
  }),
  getLinkedContentsRequest: (contentId) => ({
    url: `api/contentsharings/linkedcontents/${contentId}`,
    method: 'get',
  }),
  linkContentsRequest: (data) => ({
    url: `api/contentsharings/link/request`,
    method: 'post',
    data,
  }),
  deleteContentsLinkRequest: (contentsLinkId) => ({
    url: `api/contentsharings/linkedcontents/${contentsLinkId}`,
    method: 'delete',
  }),
  searchUserForSharingRequest: usersRequests.searchUsersBaseRequest,
  searchTargetContentForSharingRequest: sessionsPageRequests.searchTargetContentRequest,
  updateInContentReviewStatusRequest: (data) => ({
    url: `api/contentsharings/incontentreviewstatus`,
    method: 'put',
    data,
  }),
}

export default contentSharingRequests
