export const MessageAssetTypes = {
  Audio: 1,
}

export const TranslationLanguage = {
  Original: 'original',
}

export const defaultChatState = {
  messages: [],
  isLoading: false,
  isFullyLoaded: false,
  isInitialized: false,
  lastReadedMessageId: null,
  lastMessageDate: '1970-01-01T00:00:00.000Z',
  unreadMessagesCount: 0,
}

export const TranslatedMessageDisplayVariant = {
  Original: 0,
  Translated: 1,
}

export const MessagesTake = 100
