import TrashBin from './components/TrashBin'
import * as contentTrashBinActions from './actions'
import * as contentTrashBinSelectors from './selectors'
import * as contentTrashBinServices from './services'
import contentTrashBinSagas from './sagas'
import contentTrashBinReducer from './reducers'

export {
  contentTrashBinActions,
  contentTrashBinSelectors,
  contentTrashBinReducer,
  contentTrashBinServices,
  contentTrashBinSagas,
}

export default TrashBin
