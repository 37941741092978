import { memo } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Routes from '@tabeeb/routes'

import ContentTrainingMaterialViewPage from './ContentTrainingMaterialViewPage'

const ContentTrainingMaterialPages = () => {
  return (
    <Switch>
      <Route
        exact
        key={Routes.contentTrainingMaterialView}
        path={Routes.contentTrainingMaterialView}
        render={({
          match: {
            params: { id },
          },
        }) => <ContentTrainingMaterialViewPage id={parseInt(id, 10)} />}
      />

      <Redirect to={Routes.trainingMaterials} />
    </Switch>
  )
}

export default memo(ContentTrainingMaterialPages)
