import { handleActions } from 'redux-actions'

import {
  openPickRecordingTypeDialog,
  closePickRecordingTypeDialog,
  onSetRecordingType,
  resetPickRecordingTypeDialogResult,
} from '../actions'

const defaultValue = {
  opened: false,
  result: null,
}

export default handleActions(
  {
    [openPickRecordingTypeDialog](state, action) {
      return { ...state, opened: true }
    },
    [closePickRecordingTypeDialog](state, action) {
      return { ...state, opened: false }
    },
    [onSetRecordingType](state, action) {
      return { opened: false, result: action.payload }
    },
    [resetPickRecordingTypeDialogResult](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
