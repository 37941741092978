import { CloudDone } from '@mui/icons-material'

import { sessionTitleFormatter } from '@tabeeb/modules/shared/utils/text'

import BaseStatusIcon from '../BaseStatusIcon'

const SessionPublishedStatusIcon = () => {
  return <BaseStatusIcon title={sessionTitleFormatter.format('Session is published')} icon={CloudDone} />
}

export default SessionPublishedStatusIcon
