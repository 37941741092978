import { handleActions } from 'redux-actions'
import * as accountActions from '../actions'

import { FetchStatus } from '@tabeeb/enums'

const defaultState = {
  data: {},
  fetchStatus: FetchStatus.Idle,
}

export default handleActions(
  {
    [accountActions.getIdentityMe.success](state, action) {
      return {
        data: action.response.data,
        fetchStatus: FetchStatus.Loaded,
      }
    },
    [accountActions.getIdentityMe.request](state) {
      return {
        ...state,
        fetchStatus: FetchStatus.Loading,
      }
    },
    [accountActions.getIdentityMe.failed](state) {
      return {
        ...state,
        fetchStatus: FetchStatus.Failed,
      }
    },
    [accountActions.getIdentityMe.cancel](state) {
      return {
        ...state,
        fetchStatus: FetchStatus.Idle,
      }
    },
    [accountActions.getIdentityMe.resetState]() {
      return { ...defaultState }
    },
  },
  defaultState
)
