export default (theme) => {
  return {
    container: {
      position: 'relative',
      height: 'fit-content',
    },
    icon: {
      marginRight: theme.spacing() * 2,
      fontSize: 24,
    },
    subicon: {
      position: 'absolute',
      right: theme.spacing(),
      bottom: -theme.spacing(),
    },
    success: {
      color: theme.palette.success.main,
    },
  }
}
