import { ControlType } from '@tabeeb/enums'

export const getIsControlWithoutOptions = (controlType) => {
  return (
    controlType === ControlType.Textbox ||
    controlType === ControlType.Textarea ||
    controlType === ControlType.Counter ||
    controlType === ControlType.Action
  )
}

export const getIsTextControl = (controlType) => {
  return controlType === ControlType.Textbox || controlType === ControlType.Textarea
}

export const getIsDateControl = (controlType) => {
  return controlType === ControlType.Date || controlType === ControlType.DateTime
}

export const getIsSupportedByVQAmodels = (controlType) => {
  return controlType === ControlType.Radiobuttons || controlType === ControlType.Dropdown
}
