import { PageType, AssetType, ContentType } from '../../../Enums'
import { getThumbnailUrl } from '../../../services/videoService'

export function createVideoByUrlPage(
  contentId,
  documentId,
  galleryList,
  selectedGalleryItemId,
  url,
  selectedFolderId,
  insertionIndex
) {
  const thumbnailUrl = getThumbnailUrl(url)
  const timeOffset = new Date().getTimezoneOffset()
  const page = {
    contentId,
    documentId,
    folderId: selectedFolderId,
    name: url,
    description: '',
    order: insertionIndex,
    type: PageType.Normal,
    width: 1000,
    height: 750,
    timeZoneOffset: -timeOffset,
    contentType: ContentType.VideoByUrl,
    thumbnailUrl,
    assets: [
      {
        type: AssetType.Video,
        name: url,
        url,
        latitude: null,
        longitude: null,
      },
    ],
  }

  if (thumbnailUrl) {
    page.assets.push({
      type: AssetType.Preview,
      name: thumbnailUrl,
      url: thumbnailUrl,
      latitude: null,
      longitude: null,
    })
  }

  return page
}
