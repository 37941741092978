import { combineReducers } from 'redux'

import attachAIExpressionDialog from './attachAIExpressionDialog'
import openedForm from './openedForm'
import isFormInfoLoading from './isFormInfoLoading'
import availableTags from './availableTags'
import isAddVqaModelToFormDialogOpen from './isAddVqaModelToFormDialogOpen'
import vqaModels from './vqaModels'
import isFormUpdating from './isFormUpdating'
import vqaPolicies from './vqaPolicies'
import selectedFormControlOption from './selectedFormControlOption'
import isFormControlOptionUpdating from './isFormControlOptionUpdating'

export default combineReducers({
  attachAIExpressionDialog,
  openedForm,
  isFormInfoLoading,
  availableTags,
  isAddVqaModelToFormDialogOpen,
  vqaModels,
  isFormUpdating,
  vqaPolicies,
  selectedFormControlOption,
  isFormControlOptionUpdating,
})
