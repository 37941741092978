import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.setTenantForms](state, action) {
      return action.payload
    },
    [rawActions.resetTenantForms](state, action) {
      return []
    },
  },
  defaultState
)
