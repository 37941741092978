import { put, takeLatest, select, all } from 'redux-saga/effects'
import _ from 'lodash'

import * as rawActions from '../actions'
import { exportFormToPdfSuccess } from '../../forms/actions'
import updateOrAddGallery from './updateOrAddGallery'
import * as notificationActions from '../../notification/actions'
import { contentNotificationService } from '../../shared/content'
import { getInsertionIndex } from '../selectors'

function* addGalleryUploadedPdf(action) {
  const pdfDocument = _.head(action.payload)
  const { selectedFolderId } = yield select((state) => state.gallery.galleryState)
  const contentId = yield select((state) => state.contentState.contentId)
  const insertionIndex = yield select((state) => getInsertionIndex(state))

  const timeOffset = new Date().getTimezoneOffset()
  const data = {
    contentId,
    insertionIndex,
    url: pdfDocument.url,
    folderId: selectedFolderId,
    timeZoneOffset: -timeOffset,
  }

  yield put(rawActions.addGalleryPdfDocumentRequest(data))
}

function* addGalleryUploadPdf(action) {
  const pages = action.response.data
  const { suppressMessage, disableNotificationMessage } = action.payload
  const isSelectAddedGalleryItem = false
  yield updateOrAddGallery(isSelectAddedGalleryItem, pages, suppressMessage)
  yield contentNotificationService.notifyOfNewPages(pages, disableNotificationMessage)
}

function* addExportedPdfToGallery(action) {
  const pdfUrl = action.response.data
  const contentId = yield select((state) => state.contentState.contentId)
  const insertionIndex = yield select((state) => getInsertionIndex(state))

  const timeOffset = new Date().getTimezoneOffset()
  const data = {
    contentId,
    insertionIndex,
    url: pdfUrl,
    folderId: null,
    timeZoneOffset: -timeOffset,
  }

  yield put(rawActions.addGalleryPdfDocumentRequest(data))
  yield put(
    notificationActions.onAddInfoNotification({ message: 'An exported PDF will be added to the Gallery shortly' })
  )
}

function* addPdf() {
  yield all([
    takeLatest(rawActions.addGalleryPdfDocumentsUploadFilesSuccess, addGalleryUploadedPdf),
    takeLatest(exportFormToPdfSuccess, addExportedPdfToGallery),
    takeLatest(rawActions.addGalleryPdfDocumentSuccess, addGalleryUploadPdf),
  ])
}

export default addPdf
