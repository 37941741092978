import { handleActions } from 'redux-actions'
import { getWorkflows } from '../actions'

const defaultState = {
  list: [],
  isLoaded: false,
  isLoading: false,
}

export default handleActions(
  {
    [getWorkflows.request](state, action) {
      return { ...state, isLoading: true }
    },
    [getWorkflows.success](state, action) {
      return { ...state, list: action.response.data, isLoaded: true, isLoading: false }
    },
    [getWorkflows.failed](state, action) {
      return { ...state, isLoaded: true, isLoading: false }
    },
  },
  defaultState
)
