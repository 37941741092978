import { memo } from 'react'
import PropTypes from 'prop-types'

import { Popover, useTheme } from '@material-ui/core'

const ToolbarPopover = ({ anchorEl, open, children, spacing, onClose }) => {
  const theme = useTheme()

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      PaperProps={{
        style: {
          minWidth: 192,
          padding: theme.spacing(spacing),
          overflow: 'visible',
          borderRadius: theme.shape.borderRadius * 2.5,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      }}
    >
      {children}
    </Popover>
  )
}

ToolbarPopover.defaultProps = {
  spacing: 1,
}

ToolbarPopover.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  spacing: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
}

export default memo(ToolbarPopover)
