export const PretrainedModelName = {
  CLIP: 'clip',
  SimCLR: 'simclr',
  DinoV2Base: 'dinov2',
  VitSmall: 'vit_small_patch8_224.dino',
  ResnetRS50: 'resnetrs50',
  SwinTransformersBase: 'swin_base_patch4_window7_224_in22k',
  ConvnextTiny: 'convnext_tiny_in22k',
}

export const PretrainedModelNameDisplayName = {
  [PretrainedModelName.CLIP]: 'CLIP',
  [PretrainedModelName.SimCLR]: 'SimCLR',
  [PretrainedModelName.DinoV2Base]: 'Dinov2 base',
  [PretrainedModelName.VitSmall]: 'Vit small',
  [PretrainedModelName.ResnetRS50]: 'Resnet-RS 50',
  [PretrainedModelName.SwinTransformersBase]: 'Swin transformers base',
  [PretrainedModelName.ConvnextTiny]: 'Convnext tiny',
}

export const ClassifierType = {
  MLP: 'mlp',
  LogisticRegression: 'logreg',
}

export const ClassifierTypeDisplayName = {
  [ClassifierType.MLP]: 'MLP',
  [ClassifierType.LogisticRegression]: 'Logistic regression',
}

export const ObjectDetectorType = {
  YoloXSmall: 'YOLOX-S',
  YoloXMedium: 'YOLOX-M',
  YoloXLarge: 'YOLOX-L',
  FasterRCNN50: 'Faster-R-CNN-50',
  FasterRCNN101: 'Faster-R-CNN-101',
  FasterRCNN101X: 'Faster-R-CNN-101X',
  MaskRCNN50: 'Mask-R-CNN-50',
  MaskRCNN101: 'Mask-R-CNN-101',
  MaskRCNN101X: 'Mask-R-CNN-101X',
  CascadeMaskRCNN50: 'Cascade-Mask-R-CNN-50',
}

export const ObjectDetectorTypeDisplayName = {
  [ObjectDetectorType.YoloXSmall]: 'YOLOX Small',
  [ObjectDetectorType.YoloXMedium]: 'YOLOX Medium',
  [ObjectDetectorType.YoloXLarge]: 'YOLOX Large',
  [ObjectDetectorType.FasterRCNN50]: 'Faster R-CNN 50',
  [ObjectDetectorType.FasterRCNN101]: 'Faster R-CNN 101',
  [ObjectDetectorType.FasterRCNN101X]: 'Faster R-CNN 101X',
  [ObjectDetectorType.MaskRCNN50]: 'Mask R-CNN 50',
  [ObjectDetectorType.MaskRCNN101]: 'Mask R-CNN 101',
  [ObjectDetectorType.MaskRCNN101X]: 'Mask R-CNN 101X',
  [ObjectDetectorType.CascadeMaskRCNN50]: 'Cascade Mask R-CNN 50',
}
