import { memo } from 'react'
import PropTypes from 'prop-types'

import { Fade, Typography } from '@material-ui/core'

import useStyles from './styles'

const Placeholder = ({ icon: Icon, text }) => {
  const classes = useStyles()

  return (
    <Fade in>
      <div className={classes.container}>
        <Icon className={classes.icon} />
        <Typography>{text}</Typography>
      </div>
    </Fade>
  )
}

Placeholder.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
}

export default memo(Placeholder)
