import { AllPowerBIIdentityRoles, IdentityRoles } from '@tabeeb/modules/shared/constants'
import selector from '@tabeeb/modules/shared/utils/selector'
import _ from 'lodash'

export const getGenerateReportModalState = (state) => state.reports.modals.generateReportModal
export const getCreateReportRequestStatus = (state) => getGenerateReportModalState(state).status
export const getManageReceiversModalState = (state) => state.reports.modals.manageReceiversModal
export const getGeneratedReportPartsModalState = (state) => state.reports.modals.generatedReportPartsModal

export const getGeneratedReportsCount = (state) => state.reports.generatedReportsHistory.generatedReportsCount
export const getGeneratedReportsHistoryFetchStatus = (state) => state.reports.generatedReportsHistory.status
export const getGeneratedReportsHistory = (state) => state.reports.generatedReportsHistory.reports
export const getGeneratedReportsHistoryState = (state) => state.reports.generatedReportsHistory

export const getHasPowerBIAccess = () => {
  const tokenPayload = selector.getAccessTokenPayload()
  if (!tokenPayload) {
    return false
  }
  const identityRoles = tokenPayload.role
  if (_.isArray(identityRoles)) {
    return identityRoles.some((role) => AllPowerBIIdentityRoles.includes(role))
  }
  return AllPowerBIIdentityRoles.includes(identityRoles)
}

export const getIsUserPowerBIAdmin = () => {
  const tokenPayload = selector.getAccessTokenPayload()
  if (!tokenPayload) {
    return false
  }
  const identityRoles = tokenPayload.role
  if (_.isArray(identityRoles)) {
    return identityRoles.some((role) => role === IdentityRoles.PowerBIAdmin)
  }
  return identityRoles === IdentityRoles.PowerBIAdmin
}
