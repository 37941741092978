import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as rawActions from '../actions'
import GalleryToolbar from '../components/GalleryToolbar'

import { numericFileNamesSorting } from '../../fileUploads/services'
import * as gallerySelectors from '../selectors'

class GalleryToolbarContainer extends Component {
  listRef = createRef()

  constructor(props) {
    super(props)
    this.selectedItemRef = null
  }

  setSelectedItemRef = (element) => {
    this.selectedItemRef = element
    this.handleScrollToSelectedItem()
  }

  handleScrollToSelectedItem = () => {
    const { selectedGalleryItemId } = this.props
    const listElement = this.listRef.current
    if (!this.selectedItemRef) {
      this.selectedItemRef = document.getElementById(selectedGalleryItemId)
    }

    if (this.selectedItemRef && listElement && listElement.scrollHeight > listElement.clientHeight) {
      const galleryScrollTop = listElement.scrollTop
      const elementTop = this.selectedItemRef.getBoundingClientRect().top
      let scrollTop = elementTop + galleryScrollTop
      scrollTop -= listElement.offsetHeight / 2
      listElement.scrollTop = scrollTop
    }
  }

  _handleDropFilesToGallery = (files) => {
    const {
      isDragAvailable,
      actions: { addFilesToQueue },
    } = this.props

    if (!isDragAvailable) return

    const supportedFiles = files.sort(numericFileNamesSorting)

    addFilesToQueue({ files: supportedFiles })
  }

  render() {
    const { currentPanel, showPdfSearchResults, isDragAvailable, isGalleryExpanded, isGalleryExpansionEnabled } =
      this.props

    const props = {
      currentPanel,
      showPdfSearchResults,
      setSelectedItemRef: this.setSelectedItemRef,
      handleDropFilesToGallery: this._handleDropFilesToGallery,
      listRef: this.listRef,
      isExpanded: isGalleryExpansionEnabled && isGalleryExpanded,
      isDragAvailable,
    }

    return <GalleryToolbar {...props} />
  }
}

GalleryToolbarContainer.propTypes = {
  currentPanel: PropTypes.string,
  showPdfSearchResults: PropTypes.bool.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isGalleryExpansionEnabled: PropTypes.bool.isRequired,
  selectedGalleryItemId: PropTypes.number,
  currentUserId: PropTypes.number,
  presenterId: PropTypes.number,
  isCallStarted: PropTypes.bool,
  isDragAvailable: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    addFilesToQueue: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  const { navbar, gallery, appConfigState } = state

  return {
    currentPanel: navbar.currentPanel.right,
    showPdfSearchResults: gallery.pdfSearch.showPdfSearchResults,
    isGalleryExpanded: gallery.isGalleryExpanded,
    isGalleryExpansionEnabled: appConfigState.isGalleryExpansionEnabled,
    selectedGalleryItemId: gallery.galleryState.selectedGalleryItemId,
    isDragAvailable: gallerySelectors.getCanAddGalleryContent(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryToolbarContainer)
