import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'

import { MicOff, VideocamOff } from '@material-ui/icons'
import { Typography, withStyles } from '@material-ui/core'

import { presentationSelectors } from '@tabeeb/modules/presentation'
import UserOnlineStatus from '../UserOnlineStatus'
import UserSessionStatus from '../UserSessionStatus'
import { getIsVideoCallsEnabled } from '@tabeeb/modules/appConfigState/selectors'

import styles from './styles'

const UserInfo = ({ classes, user, isCallStarted, isOwner, isPresenter }) => {
  const isAudioMuted = useSelector((state) => !presentationSelectors.getIsUserAudioPlaying(state, { Id: user.id }))
  const isVideoMuted = useSelector((state) => !presentationSelectors.getIsUserVideoPlaying(state, { Id: user.id }))
  const showUserOnlineStatus = useSelector(getIsVideoCallsEnabled)

  return (
    <div className={classes.container}>
      {!isCallStarted && isOwner && <UserSessionStatus title='Session owner' />}
      {isCallStarted && isPresenter && <UserSessionStatus title='Presenter' />}

      <Typography title={user.displayName} variant='body2' noWrap>
        {user.displayName}
      </Typography>
      {!isMobileOnly && showUserOnlineStatus && <UserOnlineStatus online={user.isOnline} />}
      {isMobileOnly && user.isOnline && isVideoMuted && <VideocamOff className={classes.icon} />}
      {isMobileOnly && user.isOnline && isAudioMuted && <MicOff className={classes.icon} />}
    </div>
  )
}

UserInfo.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.object.isRequired,
  isCallStarted: PropTypes.bool.isRequired,
  isPresenter: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
}

export default memo(withStyles(styles)(UserInfo))
