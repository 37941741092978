import { handleActions } from 'redux-actions'
import * as actions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [actions.getContentDescriptionsSuccess](state, action) {
      return action.response.data
    },
    [actions.renameContentForTenantSuccess](state, action) {
      const { tenantId, description } = action.payload
      const isExist = state.some((d) => d.TenantId === tenantId)
      const updatedModel = { TenantId: tenantId, Description: description }

      if (isExist) {
        return state.map((d) => (d.TenantId === tenantId ? updatedModel : d))
      }
      return [...state, updatedModel]
    },
  },
  defaultValue
)
