function _getVqaModelsRequest() {
  return {
    url: `api/vqaModels`,
    method: 'get',
  }
}

const vqaModelsRequests = {
  getVqaModelsRequest: (data) => _getVqaModelsRequest(data),
  getVqaModelsForFormRequest: (data) => _getVqaModelsRequest(data),
  getVqaModelRequest: ({ id }) => ({
    url: `api/vqaModels/${id}`,
    method: 'get',
  }),
  addVqaModelRequest: (data) => ({
    url: `api/vqaModels`,
    method: 'post',
    data,
  }),
  updateVqaModelRequest: (data) => ({
    url: `api/vqaModels`,
    method: 'put',
    data,
  }),
  deleteVqaModelRequest: ({ vqaModelId }) => ({
    url: `api/vqaModels/${vqaModelId}`,
    method: 'delete',
  }),

  attachVqaModelToFormRequest: (data) => ({
    url: `api/vqaModelForms`,
    method: 'post',
    data,
  }),
  detachVqaModelFromFormRequest: ({ FormId, VQAModelId }) => ({
    url: `api/vqaModelForms/${VQAModelId}/${FormId}`,
    method: 'delete',
  }),
}

export default vqaModelsRequests
