/* eslint-disable jsx-a11y/media-has-caption */
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ZOOM_MIN } from '@tabeeb/modules/../users/common/webZoom'
import { toggleLargeVideo } from '../actions/tracks'

const PresentationVideo = ({ largeVideoUser, isVideoMuted, webZoomValue, isVideoMirrored }) => {
  const dispatch = useDispatch()

  const onLargeVideoClick = () => {
    dispatch(toggleLargeVideo({ userId: largeVideoUser.id, isVideo: false }))
  }

  const isZoomed = webZoomValue > ZOOM_MIN

  return (
    <>
      <div
        className='largeVideoWrapper'
        style={{
          display: largeVideoUser ? 'block' : 'none',
        }}
      >
        <video
          autoPlay='autoPlay'
          className={classNames('largeVideo')}
          onClick={onLargeVideoClick}
          style={{
            width: isZoomed ? 'auto' : '100%',
            height: `${webZoomValue * 100}%`,
            display: isVideoMuted ? 'none' : 'block',
            transform: `rotateY(${isVideoMirrored ? '180deg' : '0deg'})`,
          }}
        />
      </div>
      {largeVideoUser && (
        <span className={classNames('largeVideoUserName', { zoomed: isZoomed })}>{largeVideoUser.displayName}</span>
      )}
    </>
  )
}

PresentationVideo.propTypes = {
  largeVideoUser: PropTypes.object,
  isVideoMuted: PropTypes.bool.isRequired,
  isVideoMirrored: PropTypes.bool.isRequired,
  webZoomValue: PropTypes.number.isRequired,
}

export default PresentationVideo
