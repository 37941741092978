export default (theme) => {
  return {
    icon: {
      fontSize: theme.typography.caption.fontSize,
      marginLeft: theme.spacing() / 2,
      color: theme.palette.error.main,
    },
    online: {
      color: theme.palette.success.main,
    },
  }
}
