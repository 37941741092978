export const DialogTypes = {
  // session
  ActivateSession: 'ActivateSession',
  BulkCopy: 'BulkCopy',
  CopySession: 'CopySession',
  DeleteSession: 'DeleteSession',
  DeleteSessionPermanently: 'DeleteSessionPermanently',
  ShareSession: 'ShareSession',
  JoinSessionReview: 'JoinSessionReview',
  LeaveSession: 'LeaveSession',
  RestoreSession: 'RestoreSession',
  PublishSession: 'PublishSession',
  UnpublishSession: 'UnpublishSession',

  CreateSessionWithAdminPermissionRequest: 'CreateSessionWithAdminPermissionRequest',

  // folder
  CreateFolder: 'CreateFolder',
  DeleteFolder: 'DeleteFolder',
  DeleteFolderPermanently: 'DeleteFolderPermanently',
  LeaveFolder: 'LeaveFolder',
  ManageFolderUsers: 'ManageFolderUsers',
  RenameFolder: 'RenameFolder',
  RestoreFolder: 'RestoreFolder',

  // sessions
  AddUsers: 'AddUsers',
  ChangeReviewStatus: 'ChangeReviewStatus',
  RestoreSessions: 'RestoreSessions',
  ShareSessions: 'ShareSessions',
  SwitchOwners: 'SwitchOwners',
  SwitchReviewers: 'SwitchReviewers',
  SwitchStorage: 'SwitchStorage',
}
