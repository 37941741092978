export default (theme) => {
  return {
    metadataButton: {
      margin: `12px 10px 0px`,
      maxWidth: `187px`,

      '& .MuiButton-label': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'block',
      },
    },
  }
}
