import { handleActions } from 'redux-actions'
import * as actions from '../actions'

const defaultState = false

export default handleActions(
  {
    [actions.acceptCallNotification](state, action) {
      return true
    },
    [actions.declineCallNotification](state, action) {
      return false
    },
    [actions.resetAcceptCallNotification](state, action) {
      return defaultState
    },
  },
  defaultState
)
