import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import { TextWithLinks } from '@tabeeb/uikit'

const MessageText = ({ text }) => {
  return (
    <TextWithLinks>
      <Typography color='textSecondary' variant='body2'>
        {text}
      </Typography>
    </TextWithLinks>
  )
}

MessageText.defaultProps = {
  text: '',
}

MessageText.propTypes = {
  text: PropTypes.string,
}

export default memo(MessageText)
