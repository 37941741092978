import { createAction } from 'redux-actions'

export const resetFormBuilderState = createAction('RESET_FORM_BUILDER_STATE')

export const saveFormName = createAction('SAVE_FORM_NAME')
export const clearFormName = createAction('CLEAR_FORM_NAME')

export const addTabPanel = createAction('ADD_TAB_PANEL')
export const addControl = createAction('ADD_CONTROL')

export const saveTabOptionName = createAction('SAVE_TAB_OPTION_NAME')
export const saveControlOptionName = createAction('SAVE_CONTROL_OPTION_NAME')

export const addControlOption = createAction('ADD_CONTROL_OPTION')
export const deleteControlOption = createAction('DELETE_CONTROL_OPTION')

export const addTabOption = createAction('ADD_TAB_OPTION')
export const deleteTabOption = createAction('DELETE_TAB_OPTION')

export const deleteTabPanel = createAction('DELETE_TAB_PANEL')
export const deleteControl = createAction('DELETE_CONTROL')

export const updateControlsList = createAction('UPDATE_CONTROLS_LIST')
export const updateTabsList = createAction('UPDATE_TABS_LIST')

export const openControlSettings = createAction('OPEN_CONTROL_SETTINGS')
export const closeControlSettings = createAction('CLOSE_CONTROL_SETTINGS')

export const openTabSettings = createAction('OPEN_TAB_SETTINGS')
export const closeTabSettings = createAction('CLOSE_TAB_SETTINGS')

export const updateControlSettingsList = createAction('UPDATE_CONTROL_SETTINGS_LIST')
export const updateTabSettingsList = createAction('UPDATE_TAB_SETTINGS_LIST')

export const clearControlSettingsList = createAction('CLEAR_CONTROL_SETTINGS_LIST')
export const clearTabSettingsList = createAction('CLEAR_TAB_SETTINGS_LIST')

export const saveControlTooltip = createAction('SAVE_CONTROL_TOOLTIP')
export const saveTabTooltip = createAction('SAVE_TAB_TOOLTIP')

export const savePlaceholder = createAction('SAVE_PLACEHOLDER')

export const saveControlName = createAction('SAVE_CONTROL_NAME')
export const saveTabName = createAction('SAVE_TAB_NAME')

export const saveInitialValue = createAction('SAVE_INITIAL_VALUE')
export const saveIncrementValue = createAction('SAVE_INCREMENT_VALUE')
export const saveIncrementPeriod = createAction('SAVE_INCREMENT_PERIOD')

export const saveInfoDescription = createAction('SAVE_INFO_DESCRIPTION')
export const toggleControlInfo = createAction('TOGGLE_CONTROL_INFO')
export const setInfoContent = createAction('SET_INFO_CONTENT')
export const clearInfoContent = createAction('CLEAR_INFO_CONTENT')

export const toggleProcessingByVqaModel = createAction('TOGGLE_PROCESSING_BY_VQA_MODEL')

export const getFormIconUrlRequest = createAction('GET_FORM_ICON_URL_REQUEST')
export const getFormIconUrlSuccess = createAction('GET_FORM_ICON_URL_SUCCESS')
export const getFormIconUrlFailed = createAction('GET_FORM_ICON_URL_FAILED')

export const createFormRequest = createAction('CREATE_FORM_REQUEST')
export const createFormSuccess = createAction('CREATE_FORM_SUCCESS')
export const createFormFailed = createAction('CREATE_FORM_FAILED')

export const updateFormRequest = createAction('UPDATE_FORM_REQUEST')
export const updateFormSuccess = createAction('UPDATE_FORM_SUCCESS')
export const updateFormFailed = createAction('UPDATE_FORM_FAILED')

export const setFormIsLoading = createAction('SET_FORM_IS_LOADING')
export const unsetFormIsLoading = createAction('UNSET_FORM_IS_LOADING')

export const updateFormControlsRequest = createAction('UPDATE_FORM_CONTROLS_REQUEST')
export const updateFormControlsSuccess = createAction('UPDATE_FORM_CONTROLS_SUCCESS')
export const updateFormControlsFailed = createAction('UPDATE_FORM_CONTROLS_FAILED')

export const updateFormTabsRequest = createAction('UPDATE_FORM_TABS_REQUEST')
export const updateFormTabsSuccess = createAction('UPDATE_FORM_TABS_SUCCESS')

export const setFormToEdit = createAction('SET_FORM_TO_EDIT')

export const setFilterValue = createAction('SET_FILTER_VALUE')

export const setMode = createAction('SET_MODE')
export const createFormItem = createAction('CREATE_FORM_ITEM')

export const openImportFromExcelDialog = createAction('OPEN_IMPORT_FROM_EXCEL_DIALOG')
export const closeImportFromExcelDialog = createAction('CLOSE_IMPORT_FROM_EXCEL_DIALOG')

export const setWorkbook = createAction('SET_WORKBOOK')
export const clearWorkbook = createAction('CLEAR_WORKBOOK')

export const setWorkbookSheetnames = createAction('SET_WORKBOOK_SHEETNAMES')
export const clearWorkbookSheetnames = createAction('CLEAR_WORKBOOK_SHEETNAMES')

export const setSelectedSheet = createAction('SET_SELECTED_SHEET')
export const clearSelectedSheet = createAction('CLEAR_SELECTED_SHEET')

export const setErrorMessage = createAction('SET_ERROR_MESSAGE')

export const setAvailableAiObjects = createAction('SET_AVAILABLE_AI_OBJECTS')
export const setSelectedAiObjectsIds = createAction('SET_SELECTED_AI_OBJECTS_IDS')
export const setCountAiObjectsFlag = createAction('SET_COUNT_AI_OBJECTS_FLAG')
export const setAiObjectId = createAction('SET_AI_OBJECT_ID')

export const saveCounterLimit = createAction('SAVE_COUNTER_LIMIT')

export const setControlConditions = createAction('SET_CONTROL_CONDITIONS')
export const setOptionConditions = createAction('SET_OPTION_CONDITIONS')

export const addControlCondition = createAction('ADD_CONTROL_CONDITION')
export const addOptionCondition = createAction('ADD_OPTION_CONDITION')

export const deleteControlCondition = createAction('DELETE_CONTROL_CONDITION')
export const deleteOptionCondition = createAction('DELETE_OPTION_CONDITION')
export const deleteTextControlCondition = createAction('DELETE_TEXT_CONTROL_CONDITION')

export const saveOptionConditionSolution = createAction('SAVE_OPTION_CONDITION_SOLUTION')
export const saveControlConditionSolution = createAction('SAVE_CONTROL_CONDITION_SOLUTION')
export const saveTextControlConditionSolution = createAction('SAVE_TEXT_CONTROL_CONDITION_SOLUTION')

export const saveConditionTrigger = createAction('SAVE_CONDITION_TRIGGER')

export const addInfoImageUploadFiles = createAction('ADD_INFO_IMAGE_UPLOAD_FILES')
export const addInfoImageUploadFilesSuccess = createAction('ADD_INFO_IMAGE_UPLOAD_FILES_SUCCESS')

export const addInfoVideoUploadFiles = createAction('ADD_INFO_VIDEO_UPLOAD_FILES')
export const addInfoVideoUploadFilesSuccess = createAction('ADD_INFO_VIDEO_UPLOAD_FILES_SUCCESS')

export const setControlInfoContent = createAction('SET_CONTROL_INFO_CONTENT')
export const clearControlInfoContent = createAction('CLEAR_CONTROL_INFO_CONTENT')

export const addLoadingFileControlId = createAction('ADD_LOADING_FILE_CONTROL_ID')
export const removeLoadingFileControlId = createAction('REMOVE_LOADING_FILE_CONTROL_ID')

export const addInputError = createAction('ADD_INPUT_ERROR')
export const removeInputError = createAction('REMOVE_INPUT_ERROR')
export const removeControlInputErrors = createAction('REMOVE_CONTROL_INPUT_ERRORS')

export const processFormControlsPredictionsRequest = createAction('PROCESS_FORM_CONTROLS_PREDICTIONS_REQUEST')

export const updateFormViewMode = createAction('UPDATE_FORM_VIEW_MODE')
export const setFormViewMode = createAction('SET_FORM_VIEW_MODE')

export const updateQuestionType = createAction('UPDATE_QUESTION_TYPE')

export const onSelectCondition = createAction('ON_SELECT_CONDITION')

export const changeDateControlType = createAction('CHANGE_DATE_CONTROL_TYPE')

export const getFormToEditRequest = createAction('GET_FORM_TO_EDIT_REQUEST')
export const getFormToEditSuccess = createAction('GET_FORM_TO_EDIT_SUCCESS')
export const getFormToEditFailed = createAction('GET_FORM_TO_EDIT_FAILED')

export const getAiClassesRequest = createAction('GET_AI_CLASSES_REQUEST')
export const getAiClassesSuccess = createAction('GET_AI_CLASSES_SUCCESS')
export const getAiClassesFailed = createAction('GET_AI_CLASSES_FAILED')

export const saveContactValue = createAction('SAVE_CONTACT_VALUE')

export const getNlpModelsForFormRequest = createAction('GET_NLP_MODELS_FOR_FORM_REQUEST')
export const getNlpModelsForFormSuccess = createAction('GET_NLP_MODELS_FOR_FORM_SUCCESS')
export const getNlpModelsForFormFailed = createAction('GET_NLP_MODELS_FOR_FORM_FAILED')

export const setSelectedNlpModelId = createAction('SET_SELECTED_NLP_MODEL_ID')

export const returnToFolder = createAction('RETURN_TO_FOLDER')

export const onSelectEmailCondition = createAction('ON_SELECT_EMAIL_CONDITION')
export const onChangeRecipientEmailCondition = createAction('ON_CHANGE_RECIPIENT_EMAIL_CONDITION')
export const setEmailOptionCondition = createAction('SET_EMAIL_OPTION_CONDITION')
export const setEmailControlCondition = createAction('SET_EMAIL_CONTROL_CONDITION')

export const getInitFormSettings = createAction('FORM_BUILDER_GET_INIT_FORM_SETTINGS')
export const setInitFormSettings = createAction('FORM_BUILDER_SET_INIT_FORM_SETTING')
export const saveFormSettings = createAction('FORM_BUILDER_SAVE_FORM_SETTINGS')

export const setFormSignatureState = createAction('SET_FORM_SIGNATURE_STATE')

export const updateFormActionWorkflow = createAction('UPDATE_FORM_ACTION_WORKFLOW')
