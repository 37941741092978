import { put, takeLatest, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import routes from '@tabeeb/routes'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import { getFolderPermissionsRequest } from '@tabeeb/modules/permissions/actions'

import * as rawActions from '../actions'
import { getCurrentFolder } from '../selectors'

function* onUserRemovedFromSessionFolder(action) {
  const [folderId] = action.payload

  const currentFolder = yield select(getCurrentFolder)
  if (currentFolder?.Id === folderId) {
    yield put(rawActions.clearCurrentFolder())

    yield put(push(routes.home))
  }
}

function* onUserChangedRole(action) {
  const [folderId] = action.payload
  yield put(getFolderPermissionsRequest({ folderId }))
}

function* updateFolderUser() {
  yield all([
    takeLatest(
      signalrEvents[signalrConstants.tabeebHubName].onUserRemovedFromSessionFolder,
      onUserRemovedFromSessionFolder
    ),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onUserChangedRole, onUserChangedRole),
  ])
}

export default updateFolderUser
