import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoopOutlined } from '@material-ui/icons'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { aiActions } from '@tabeeb/modules/artificialIntelligence'
import { regenerateBentleyAnalyticsReport } from '@tabeeb/modules/bentleyAnalyticsReport/actions'
import { gallerySelectors } from '@tabeeb/modules/gallery'
import { usersSelectors } from '@tabeeb/modules/../users'
import { isStructureModelPage, isBentleyAnalyticsReportPage } from '@tabeeb/services/pageService'

import ToolbarButton from '../ToolbarButton'

const RegenerateStructureModelButton = () => {
  const dispatch = useDispatch()

  const pageId = useSelector(gallerySelectors.getSelectedGalleryItemId)

  const isStructureModelFeatureEnabled = useSelector(appConfigStateSelectors.getShowStructureModel)
  const isSBentleyAnalyticsReportFeatureEnabled = useSelector(appConfigStateSelectors.getShowBentleyAnalyticsReport)
  const isFeatureEnabled = isStructureModelFeatureEnabled || isSBentleyAnalyticsReportFeatureEnabled

  const isReviewer = useSelector(usersSelectors.getIsCurrentUserReviewer)
  const isViewer = useSelector(usersSelectors.getIsCurrentUserViewer)

  const isStructureModel = useSelector((state) =>
    isStructureModelPage(gallerySelectors.getSelectedGalleryItemType(state))
  )

  const isBentleyAnalyticsReport = useSelector((state) =>
    isBentleyAnalyticsReportPage(gallerySelectors.getSelectedGalleryItemType(state))
  )

  if (!isStructureModel) {
    return null
  }

  const handleRegenerateStructureModelButtonClick = () => {
    if (isBentleyAnalyticsReport) {
      dispatch(regenerateBentleyAnalyticsReport.request({ pageId }))
    } else {
      dispatch(aiActions.regenerateStructureModelRequest({ pageId }))
    }
  }

  return (
    <ToolbarButton
      Icon={LoopOutlined}
      display={isFeatureEnabled && !isReviewer && !isViewer}
      title='Regenerate Analytics Report'
      onClick={handleRegenerateStructureModelButtonClick}
    />
  )
}

export default memo(RegenerateStructureModelButton)
