import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    bar: {
      height: 8,
      background: theme.palette.error.main,
    },
  }
})
