import PropTypes from 'prop-types'

import { Typography, styled } from '@mui/material'

export const SectionTitleTypography = styled(Typography)(({ theme }) => ({
  color: '#8A898E',
  fontWeight: 500,
}))

const ChangesTypography = styled(Typography)(({ theme }) => ({
  paddingInline: theme.spacing(1),
  paddingBlock: theme.spacing(0.25),
  width: 'fit-content',
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(1),
}))

export const PreviousVersionTypography = styled(ChangesTypography)(({ theme }) => ({
  backgroundColor: '#f6e7e7',
  textDecoration: 'line-through',
}))

export const NewVersionTypography = styled(ChangesTypography)(({ theme }) => ({
  backgroundColor: 'unset',
}))

export const Title = ({ title }) => {
  return (
    <Typography variant='body2' color='text.secondary' fontWeight={500} noWrap>
      {title}
    </Typography>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
}
