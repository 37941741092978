import { put, takeLatest, all, race, take } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { values, pick } from 'lodash'

import { updateFormPermissionsByTenant, enableFormPermissionsSyncByTenant } from '@tabeeb/modules/formSettings/actions'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { saveFormSettings } from '../actions'

function* handleSaveFormSettings({ payload: { settings, subdomain } }: Action<{ settings: any; subdomain: string }>) {
  if (settings.permissions.byContentRoles.sync) {
    yield put(enableFormPermissionsSyncByTenant.request({ subdomain }))
  } else {
    const permissionPatch = values(
      pick(settings.permissions.byContentRoles.override, settings.permissions.byContentRoles.editableContentRoles)
    )
    yield put(updateFormPermissionsByTenant.request({ subdomain, permissions: permissionPatch }))
  }

  const { success } = yield race({
    success: take([updateFormPermissionsByTenant.success, enableFormPermissionsSyncByTenant.success]),
    failed: take([updateFormPermissionsByTenant.failed, enableFormPermissionsSyncByTenant.failed]),
  })

  if (success) {
    yield put(onAddSuccessNotification({ message: 'Form settings have been updated successfully' }))
  } else {
    yield put(onAddErrorNotification({ message: 'Fail to update form settings' }))
  }
}

function* updateConfig() {
  yield all([takeLatest(saveFormSettings, handleSaveFormSettings)])
}

export default updateConfig
