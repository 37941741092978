import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { IconButton, Tooltip } from '@mui/material'

import { SwitchOwner } from '@tabeeb/shared/icons'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { getHasSwitchOwnerPermission } from '@tabeeb/modules/sessionsPage/selectors/permissions'

const SwitchOwnerButton = ({ onClick }) => {
  const isVisible = useSelector(getHasSwitchOwnerPermission)
  if (!isVisible) {
    return null
  }

  return (
    <Tooltip title={sessionTitleFormatter.format('Switch owner of selected sessions')}>
      <IconButton size='small' variant='contained' onClick={onClick}>
        <SwitchOwner />
      </IconButton>
    </Tooltip>
  )
}

SwitchOwnerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default memo(SwitchOwnerButton)
