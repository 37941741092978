import { memo } from 'react'

import { Skeleton } from '@material-ui/lab'

import useStyles from './styles'

const SessionInfoSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Skeleton className={classes.previewContainer} component='div' variant='rect' />
      <div className={classes.content}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  )
}

export default memo(SessionInfoSkeleton)
