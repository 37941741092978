import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {},
  inputBox: {
    display: 'flex',
    alignItems: 'center',
  },
  inputField: {
    flex: 1,
  },
  inputVerticalDivider: {
    height: 28,
    margin: 0.5,
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
}))
