import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

import { KeyboardDatePicker } from '@material-ui/pickers'

const DatePickerField = ({ form, field, size, ...rest }) => {
  const { setFieldTouched, setFieldValue } = useFormikContext()

  const fieldValue = (field.value && new Date(field.value)) || null

  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  return (
    <KeyboardDatePicker
      {...field}
      {...rest}
      error={error}
      size={size}
      value={fieldValue}
      format='dd/MM/yyyy'
      onChange={(date) => {
        setFieldValue(field.name, date)
        setFieldTouched(field.name, true)
      }}
      inputVariant='outlined'
      KeyboardButtonProps={{ size }}
      helperText={error}
      FormHelperTextProps={{
        hidden: !error,
      }}
    />
  )
}

DatePickerField.defaultProps = {
  size: 'medium',
}

DatePickerField.propTypes = {
  size: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
}

export default DatePickerField
