import { AnswerStatuses } from '@tabeeb/enums'

function getExtendedStatusesList(selectedStatuses) {
  let result = []

  selectedStatuses.map((status) => {
    if (status === AnswerStatuses.Pending) {
      result.push(AnswerStatuses.Pending)
    } else {
      if (status === AnswerStatuses.Approve) {
        const approveStatuses = [AnswerStatuses.Approve, AnswerStatuses.ApprovedByAI, AnswerStatuses.ApprovedByReviewer]
        result = [...result, ...approveStatuses]
      }

      if (status === AnswerStatuses.Reject) {
        const rejectStatuses = [AnswerStatuses.Reject, AnswerStatuses.RejectedByAI, AnswerStatuses.RejectedByReviewer]
        result = [...result, ...rejectStatuses]
      }
    }
  })

  return result
}

export function getFormsWithStatusesList(selectedFormsList) {
  const forms = selectedFormsList || []
  const result = forms.map((form) => {
    return {
      contentFormId: form.ContentFormId,
      answerStatuses: getExtendedStatusesList(form.SelectedAnswerStatuses),
    }
  })

  return result
}
