import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { updateFolder } from '@tabeeb/modules/sessionsPage/actions'

import FolderBaseDialog from '../FolderBaseDialog'

const RenameFolderDialog = ({ open, onClose, folder }) => {
  const dispatch = useDispatch()

  const initial = useMemo(() => ({ Name: folder.Name }), [folder?.Name])

  const onSubmit = useCallback(
    ({ Name }) => {
      dispatch(updateFolder.request({ Id: folder.Id, Name }))

      onClose()
    },
    [dispatch, folder?.Id, onClose]
  )

  return <FolderBaseDialog initial={initial} title='Edit folder' open={open} onClose={onClose} onSubmit={onSubmit} />
}

RenameFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  folder: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
}

export default RenameFolderDialog
