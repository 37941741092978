export default (theme) => {
  return {
    container: {},
    item: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing() / 4,
      paddingBottom: theme.spacing() / 4,
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      fontSize: 12,
    },
    icon: {
      marginRight: theme.spacing() / 4,
      fontSize: theme.typography.body1.fontSize,
    },
    reject: {
      color: theme.palette.error.main,
    },
    approve: {
      color: theme.palette.success.main,
    },
  }
}
