import { memo } from 'react'

import { Box, Chip } from '@mui/material'
import { LinkOutlined } from '@mui/icons-material'

const LinkAccessChip = () => {
  return (
    <Box mr={0.5}>
      <Chip
        color='primary'
        label='Link access'
        title='Link access'
        size='small'
        variant='outlined'
        icon={<LinkOutlined />}
      />
    </Box>
  )
}

export default memo(LinkAccessChip)
