import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@mui/material'
import { FetchStatus } from '@tabeeb/enums'
import { SafetyCheck } from '@mui/icons-material'

const CheckPowerBIReportConfigurationButton = ({ fetchStatus, disabled, onClick, fullWidth = true }) => {
  const validateConfigurationButtonStartIcon = useMemo(() => {
    return fetchStatus === FetchStatus.Loading ? <CircularProgress size={18} /> : <SafetyCheck color='primary' />
  }, [fetchStatus])

  return (
    <Button
      variant='outlined'
      color='primary'
      fullWidth={fullWidth}
      disabled={disabled || fetchStatus === FetchStatus.Loaded}
      startIcon={validateConfigurationButtonStartIcon}
      onClick={onClick}
    >
      Validate
    </Button>
  )
}

CheckPowerBIReportConfigurationButton.propTypes = {
  fetchStatus: PropTypes.oneOf([FetchStatus.Failed, FetchStatus.Idle, FetchStatus.Loaded, FetchStatus.Loading]),
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

export default CheckPowerBIReportConfigurationButton
