import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import * as galleryActions from '../../gallery/actions'
import { getPagesByFolderWithNesting } from '../../gallery/selectors'
import { rawContentActions } from '../../shared/content'

function* addFolderForReviewing({ payload: { folderId } }) {
  const pages = yield select((state) => getPagesByFolderWithNesting(state, { id: folderId }))

  if (pages.length < 1) {
    yield put(
      notificationActions.onAddWarningNotification({
        message: 'Folder is empty!',
      })
    )
    return
  }

  yield put(rawActions.addAllPagesForReviewing(pages))
}

function* resetState() {
  yield put(rawActions.resetState())
}

function* reviewSessionPagesSaga() {
  yield all([
    takeLatest(galleryActions.addFolderForReviewing, addFolderForReviewing),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default reviewSessionPagesSaga
