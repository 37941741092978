import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ZoomIn, ZoomOut } from '@material-ui/icons'
import { Grow, Typography } from '@material-ui/core'

import { DeviceType } from '@tabeeb/enums'
import * as usersActions from '@tabeeb/modules/../users/actions'
import * as usersSelectors from '@tabeeb/modules/../users/selectors'
import * as ZoomValues from '@tabeeb/modules/../users/common/mobileZoom'
import * as presentationSelectors from '@tabeeb/modules/presentation/selectors'

import ToolbarButton from '../ToolbarButton'

import useStyles from './styles'

const MobileZoomButtons = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedUser = useSelector(presentationSelectors.getSelectedUser)
  const user = useSelector((state) => usersSelectors.getUserById(state, { Id: selectedUser?.id }))

  if (!selectedUser || !user) {
    return null
  }

  const isMobileUserOnScreen = [
    DeviceType.androidDevice,
    DeviceType.appleDevice,
    DeviceType.androidBrowser,
    DeviceType.iosBrowser,
  ].includes(selectedUser.deviceType)
  if (!isMobileUserOnScreen) {
    return null
  }

  const onZoomIn = () => {
    const zoomValue = user.mobileZoomValue || ZoomValues.mobileZoomMax
    if (zoomValue >= ZoomValues.mobileZoomMax) {
      return
    }

    dispatch(usersActions.onMobileZoomIn(user.id))
  }

  const onZoomOut = () => {
    const zoomValue = user.mobileZoomValue || ZoomValues.mobileZoomMin
    if (zoomValue <= ZoomValues.mobileZoomMin) {
      return
    }

    dispatch(usersActions.onMobileZoomOut(user.id))
  }

  const zoomValue = user?.mobileZoomValue || ZoomValues.mobileZoomMin

  const isZoomInButtonActive = zoomValue < user?.mobileZoomMax || ZoomValues.mobileZoomMax
  const isZoomOutButtonActive = zoomValue > ZoomValues.mobileZoomMin
  const isIosDevice = selectedUser.deviceType === DeviceType.iosBrowser

  return (
    <Grow in>
      <div className={classes.root}>
        <ToolbarButton
          variant='outlined'
          Icon={ZoomIn}
          display={Boolean(selectedUser)}
          disabled={!isZoomInButtonActive || isIosDevice}
          title={isIosDevice ? 'This device does not support zoom feature' : 'Zoom in'}
          onClick={onZoomIn}
        />
        <Typography className={classes.zoomValue} color='textPrimary' variant='body1'>
          {zoomValue}%
        </Typography>
        <ToolbarButton
          variant='outlined'
          Icon={ZoomOut}
          display={Boolean(selectedUser)}
          disabled={!isZoomOutButtonActive || isIosDevice}
          title={isIosDevice ? 'This device does not support zoom feature' : 'Zoom out'}
          onClick={onZoomOut}
        />
      </div>
    </Grow>
  )
}

export default memo(MobileZoomButtons)
