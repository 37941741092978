import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import routes from '@tabeeb/routes'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import NavbarTab from '../NavbarTab'

const Sessions = ({ sessionsNavbarTitle, ...rest }) => {
  const props = {
    item: {
      label: `${sessionsNavbarTitle}`,
      to: routes.home,
    },
    ...rest,
  }

  return <NavbarTab {...props} />
}

Sessions.to = routes.home

Sessions.propTypes = {
  sessionsNavbarTitle: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    sessionsNavbarTitle: appConfigStateSelectors.getSessionTitle(state),
  }
}

export default connect(mapStateToProps)(Sessions)
