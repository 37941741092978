import { createSelector } from 'reselect'

import { Filter } from '../enums'

export const getEnabledFilters = (state) => state.sessionsPage.configuration.filters
export const getIsFilterEnabled = createSelector([getEnabledFilters, (_, filter) => filter], (filters, filter) => {
  return filters.includes(filter)
})

export const getIsFiltrationByDateEnabled = (state) => getIsFilterEnabled(state, Filter.ByDate)
export const getIsFiltrationByReviewStatusEnabled = (state) => getIsFilterEnabled(state, Filter.ByReviewStatus)
export const getIsFiltrationBySessionStatusEnabled = (state) => getIsFilterEnabled(state, Filter.BySessionStatus)

export const getFiltrationState = (state) => state.sessionsPage.filtration

export const getDateFilter = (state) => getFiltrationState(state).date
export const getReviewStatusFilter = (state) => getFiltrationState(state).reviewStatus
export const getSearchQuery = (state) => getFiltrationState(state).searchText
export const getSessionStatusFilter = (state) => getFiltrationState(state).sessionStatus
