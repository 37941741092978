import { memo } from 'react'

import { SearchOutlined } from '@material-ui/icons'
import Placeholder from '@tabeeb/modules/calendar/components/Placeholders/Placeholder'

const NoAIObjectFoundPlaceholder = () => {
  return <Placeholder icon={SearchOutlined} text='No CV classes found...' />
}

export default memo(NoAIObjectFoundPlaceholder)
