import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import useStyles from './styles'

const Title = ({ value }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.title} noWrap>
      {value}
    </Typography>
  )
}

Title.propTypes = {
  value: PropTypes.string.isRequired,
}

export default memo(Title)
