import { put, takeLatest, all, select } from 'redux-saga/effects'

import * as aiActions from '../actions'
import { signalrEvents, signalrConstants } from '../../signalr'

function* onAIObjectsCounterChanged(action) {
  const [model] = action.payload

  const { currentForm } = yield select((state) => state.forms)
  const selectedRecord = currentForm.selectedRecord || 0

  if (selectedRecord > 0) return

  yield put(aiActions.setCounterListValues(model))
}

function* counterListValues() {
  yield all([
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onAIObjectsCounterChanged, onAIObjectsCounterChanged),
  ])
}

export default counterListValues
