import { combineReducers } from 'redux'
import powerBIProviders from './powerBIProviders'
import powerBIProviderConnectionCheck from './powerBIProviderConnectionCheck'
import powerBIProvider from './powerBIProvider'
import powerBIProviderModal from './modals/powerBIProviderModal'
import powerBIProviderConfiguration from './powerBIProviderConfiguration'

export default combineReducers({
  powerBIProviders,
  powerBIProviderModal,
  powerBIProvider,
  powerBIProviderConnectionCheck,
  powerBIProviderConfiguration,
})
