import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const resetState = createAction('RESET_BENTLEY_ANALYTICS_REPORT_STATE')

export const addBentleyAnalyticsReport = createAction('ADD_BENTLEY_ANALYTICS_REPORT')
export const createBentleyAnalyticsReport = createApiActions('CREATE_BENTLEY_ANALYTICS_REPORT')

export const openBentleyAnalyticsReportDialog = createAction('OPEN_BENTLEY_ANALYTICS_REPORT_DIALOG')
export const closeBentleyAnalyticsReportDialog = createAction('CLOSE_BENTLEY_ANALYTICS_REPORT_DIALOG')

export const addModelToBentleyAnalyticsReport = createAction('ADD_MODEL_TO_BENTLEY_ANALYTICS_REPORT')
export const deleteModelFromBentleyAnalyticsReport = createAction('DELETE_MODEL_FROM_BENTLEY_ANALYTICS_REPORT')

export const regenerateBentleyAnalyticsReport = createApiActions('REGENERATE_BENTLEY_ANALYTICS_REPORT')
