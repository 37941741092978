import Presentation from './components/Presentation'
import PresentationReducer from './reducers'
import PresentationSagas from './sagas'
import * as presentationSelectors from './selectors'
import * as connectionActions from './actions/connection'
import * as screenSharingActions from './actions/screenSharing'
import * as tracksActions from './actions/tracks'
import * as devicesActions from './actions/devices'
import * as conferenceActions from './actions/conference'
import * as jitsiTrackActions from './actions/jitsiTrack'

export default Presentation

export {
  PresentationReducer,
  PresentationSagas,
  connectionActions,
  screenSharingActions,
  presentationSelectors,
  tracksActions,
  devicesActions,
  conferenceActions,
  jitsiTrackActions,
}
