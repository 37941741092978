import { put, takeEvery, select, take, all } from 'redux-saga/effects'

import { isPdfPage } from '@tabeeb/services/pageService'
import * as galleryActions from '../actions'

function* onAddPageToSelection({ payload: pageId }) {
  const galleryList = yield select((state) => state.gallery.galleryList)
  const item = galleryList.find((item) => item.id === pageId)

  if (isPdfPage(item.contentType)) {
    const pdfPagesIds = galleryList
      .filter((page) => page.contentUrl === item.contentUrl && isPdfPage(item.contentType))
      .map((page) => page.id)
    yield put(galleryActions.addPagesToSelection(pdfPagesIds))
  } else {
    yield put(galleryActions.addPageToSelection(pageId))
  }
}

function* onRemovePageFromSelection({ payload: pageId }) {
  const galleryList = yield select((state) => state.gallery.galleryList)
  const item = galleryList.find((item) => item.id === pageId)

  if (isPdfPage(item.contentType)) {
    const pdfPagesIds = galleryList
      .filter((page) => page.contentUrl === item.contentUrl && isPdfPage(item.contentType))
      .map((page) => page.id)

    yield put(galleryActions.removePagesFromSelection(pdfPagesIds))
  } else {
    yield put(galleryActions.removePageFromSelection(pageId))
  }
}

function* onStartDraggingPages({ payload: { pageId, e } }) {
  yield put(galleryActions.onAddPageToSelection(pageId))
  yield take([galleryActions.addPageToSelection, galleryActions.addPagesToSelection])

  const selection = yield select((state) => state.gallery.selection)

  e.dataTransfer.dropEffect = 'move'
  e.dataTransfer.setData('text/plain', JSON.stringify(selection))
}

function* selection() {
  yield all([
    takeEvery(galleryActions.onAddPageToSelection, onAddPageToSelection),
    takeEvery(galleryActions.onRemovePageFromSelection, onRemovePageFromSelection),
    takeEvery(galleryActions.onStartDraggingPages, onStartDraggingPages),
  ])
}

export default selection
