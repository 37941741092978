import { handleActions } from 'redux-actions'
import * as actions from '../actions'
import { pageConverter } from '../../shareSessionPagesList/services/pagesForSharingConverter'

const defaultValue = []

export default handleActions(
  {
    [actions.addPageForReviewing](state, action) {
      let addedPage = action.payload
      const isAlreadyAdded = state.some((page) => page.id === addedPage.id)
      if (isAlreadyAdded) {
        return state
      }
      addedPage = pageConverter(addedPage)
      return [...state, addedPage]
    },
    [actions.deletePageForReviewing](state, action) {
      const pageId = action.payload
      const index = state.findIndex((page) => page.id === pageId)
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [actions.addAllPagesForReviewing](state, action) {
      let pages = action.payload
      pages = pages.filter((item) => !state.some((page) => page.id === item.id))

      const newState = [...state]
      pages.forEach((page) => newState.push(pageConverter(page)))

      return newState
    },
    [actions.setPagesForReviewing](state, action) {
      return action.payload
    },
    [actions.closeReviewSessionPagesDialog](state, action) {
      return defaultValue
    },
    [actions.addPagesByFormsForReviewing](state, action) {
      let addedPages = action.payload.pages
      const { selectedForms } = action.payload

      let newState = state.map((page) => {
        if (addedPages.some((p) => p.id === page.id)) {
          const addedPage = addedPages.find((p) => page.id)
          page.contentFormId = addedPage.contentFormId
        }
        return page
      })

      addedPages = addedPages.filter((item) => !newState.some((page) => page.id === item.id))
      addedPages.forEach((page) => newState.push(page))

      newState = newState.filter(
        (page) => page.contentFormId === null || selectedForms.some((form) => form.ContentFormId === page.contentFormId)
      )

      return newState
    },
    [actions.resetState](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
