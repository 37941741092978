export default (theme) => {
  return {
    container: {
      margin: theme.spacing(),
    },
    button: {
      '&.MuiToggleButton-root.Mui-selected': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
      },
      flexGrow: 1,
      minWidth: '12.5%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderLeft: 'none !important',
      marginLeft: '0px !important',

      '& .MuiToggleButton-label': {
        flexDirection: 'inherit',
      },
    },
    disabledIcon: {
      position: 'absolute',
      top: -10,
      left: -10,
      fontSize: 42,
      transform: 'rotate(45deg)',
    },
  }
}
