import { memo } from 'react'

import { ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material'

const InviteSkeleton = () => {
  return (
    <ListItem disableGutters>
      <ListItemAvatar>
        <Skeleton width={40} height={40} variant='circular' />
      </ListItemAvatar>
      <ListItemText primary={<Skeleton width='75%' />} secondary={<Skeleton width='60%' />} />
    </ListItem>
  )
}

export default memo(InviteSkeleton)
