import { handleActions, combineActions } from 'redux-actions'

import { ProjectSearchFieldType } from '@tabeeb/enums'

import * as rawActions from '../actions'

const defaultState = ProjectSearchFieldType.None

export default handleActions(
  {
    [rawActions.setSelectedSearchField](state, { payload }) {
      return payload
    },
    [combineActions(rawActions.setSelectedProject, rawActions.closeSearchFields)](state, action) {
      return defaultState
    },
  },
  defaultState
)
