import PropTypes from 'prop-types'

import { getFormattedUtcDate } from '@tabeeb/services/dateTimeService'

import DeleteIcon from '@material-ui/icons/Delete'

import './styles.less'

const ExistingReminder = ({ reminder, selectedUsers, handleClick, handleDeleteBtnClick }) => {
  const occurrencesText =
    reminder.Occurrences === 0
      ? `Hasn't been triggered yet`
      : `Triggered ${reminder.Occurrences} time${reminder.Occurrences > 1 ? 's' : ''}`

  return (
    <div className='existing-reminder' onClick={() => handleClick(reminder)} key={reminder.Id}>
      <span className='existing-reminder-delete-btn' onClick={(e) => handleDeleteBtnClick(e, reminder)}>
        <DeleteIcon fontSize='inherit' />
      </span>
      <span className='existing-reminder-users' title={selectedUsers}>
        {selectedUsers}
      </span>
      <span className='existing-reminder-info'>
        {`Created on ${getFormattedUtcDate(reminder.CreatedOn, 'MMM D, YYYY h:mm A')}`}
      </span>
      <span className='existing-reminder-info'>{occurrencesText}</span>
      {reminder.Occurrences > 0 && reminder.PreviousTriggerDate && (
        <span className='existing-reminder-info'>
          {`Previous trigger on ${getFormattedUtcDate(reminder.PreviousTriggerDate, 'MMM D, YYYY h:mm A')}`}
        </span>
      )}
      <span className='existing-reminder-info'>
        {`Next trigger on ${getFormattedUtcDate(reminder.TriggerDate, 'MMM D, YYYY h:mm A')}`}
      </span>
    </div>
  )
}

ExistingReminder.propTypes = {
  reminder: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDeleteBtnClick: PropTypes.func.isRequired,
}

export default ExistingReminder
