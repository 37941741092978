import { handleActions } from 'redux-actions'
import { setFilters, resetState } from '../../actions'

const defaultValue = []

export default handleActions(
  {
    [setFilters](_, { payload: filters }) {
      return filters
    },
    [resetState]() {
      return defaultValue
    },
  },
  defaultValue
)
