import { ReportingProviderTypes } from '@tabeeb/modules/powerBIReportProviders/enums'

const rootUrl = 'reporting-service/api/reporting-providers'

const powerBIReportProvidersRequests = {
  getPowerBiReportProvidersRequest: (params) => ({
    url: `${rootUrl}/powerBI`,
    method: 'get',
    params: {
      type: ReportingProviderTypes.PowerBI,
      ...params,
    },
  }),
  createPowerBiReportProviderRequest: (payload) => ({
    url: `${rootUrl}/powerBI`,
    method: 'post',
    data: payload,
  }),
  disableReportProviderRequest: (providerId) => ({
    url: `${rootUrl}/${providerId}/disable`,
    method: 'put',
  }),
  deletePowerBiReportProviderRequest: (providerId) => ({
    url: `${rootUrl}/powerBI/${providerId}`,
    method: 'delete',
  }),
  enableReportProviderRequest: (providerId) => ({
    url: `${rootUrl}/${providerId}/enable`,
    method: 'put',
  }),
  checkPowerBiReportProviderConnectionRequest: (params) => ({
    url: `${rootUrl}/powerBI/check-connection`,
    method: 'get',
    params,
  }),
  getPowerBiReportProvidersForSearchRequest: (search) => ({
    url: `${rootUrl}/powerBI/search`,
    method: 'get',
    params: { search },
  }),
  getPowerBiReportProviderRequest: (id) => ({
    url: `${rootUrl}/${id}`,
    method: 'get',
  }),
  updatePowerBiReportProviderRequest: ({ id, data }) => ({
    url: `${rootUrl}/${id}`,
    method: 'put',
    data,
  }),
  updatePowerBiReportProviderConfigurationRequest: ({ id, data }) => ({
    url: `${rootUrl}/powerBI/${id}/configuration`,
    method: 'put',
    data,
  }),
  getPowerBiReportProviderUsersRequest: (id) => ({
    url: `${rootUrl}/${id}/users/ids`,
    method: 'get',
  }),
  updatePowerBiReportProviderUsersRequest: ({ id, data }) => ({
    url: `${rootUrl}/${id}/users`,
    method: 'put',
    data,
  }),
  getPowerBiReportProviderConfigurationRequest: (id) => ({
    url: `${rootUrl}/powerBI/${id}/configuration`,
    method: 'get',
  }),
}

export default powerBIReportProvidersRequests
