import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { TextField, withStyles } from '@material-ui/core'

import styles from './styles'

const TextMessageEditor = ({ classes, onChange }) => {
  const [text, setText] = useState('')

  const handleChangeText = useCallback((e) => {
    setText(e.target.value)
    onChange(e.target.value)
  }, [])

  return (
    <TextField
      className={classes.container}
      fullWidth
      variant='outlined'
      value={text}
      onChange={handleChangeText}
      multiline
      minRows={4}
    />
  )
}

TextMessageEditor.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(TextMessageEditor)
