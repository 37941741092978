import { getAnnotationComponentByType } from './annotationsService'

class ViewBoxCalculationService {
  annotationIdToAnnotationObjectMap = new Map()

  annotationIdToViewBoxMap = new Map()

  getAnnotationViewBox = (annotation) => {
    if (annotation === this.annotationIdToAnnotationObjectMap.get(annotation.Id)) {
      return this.annotationIdToViewBoxMap.get(annotation.Id)
    }

    const AnnotationComponent = getAnnotationComponentByType(annotation.Type)
    if (!AnnotationComponent || !AnnotationComponent.getViewBox) {
      return null
    }

    const viewBox = AnnotationComponent.getViewBox(annotation)

    this.annotationIdToViewBoxMap.set(annotation.Id, viewBox)
    this.annotationIdToAnnotationObjectMap.set(annotation.Id, annotation)

    return viewBox
  }

  resetCache = () => {
    this.annotationIdToAnnotationObjectMap.clear()
    this.annotationIdToViewBoxMap.clear()
  }
}

export default ViewBoxCalculationService
