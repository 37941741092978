import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import AvailableTenantFormItem from '../components/AvailableTenantFormItem'

import { prepareAttachingFormToTenant } from '../actions'

class AvailableTenantFormContainer extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const isEqualProps = !_.isEqual(this.props, nextProps)
    const isEqualStates = !_.isEqual(this.state, nextState)
    return isEqualProps || isEqualStates
  }

  _attachFormToTenant = (form) => {
    this.props.attachFormToTenant(form)
  }

  render() {
    const props = {
      form: this.props.form,
      attachFormToTenant: this._attachFormToTenant,
    }
    return <AvailableTenantFormItem {...props} />
  }
}

AvailableTenantFormContainer.propTypes = {
  form: PropTypes.object.isRequired,
  attachFormToTenant: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  attachFormToTenant: (form) => dispatch(prepareAttachingFormToTenant(form)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailableTenantFormContainer)
