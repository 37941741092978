import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import { AnnouncementSeverity, ArticleContentType } from '../../enums'

import ArticleContent, { ArticleContentSkeleton } from '../ArticleContent'
import ArticleName, { ArticleTitleSkeleton } from '../ArticleName'
import ArticleDate, { ArticleDateSkeleton } from '../ArticleDate'
import AnnouncementSeverityChip from '../AnnouncementSeverityChip'

const Announcement = ({ loading, announcement }) => {
  return (
    <Box
      component='section'
      width='100%'
      height='100%'
      display='flex'
      flexDirection='column'
      alignItems='stretch'
      justifyContent='stretch'
    >
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={1}>
        <Box display='flex' flexDirection='column' minWidth={0}>
          <Box display='flex' alignItems='center'>
            {loading ? <ArticleTitleSkeleton /> : <ArticleName name={announcement.Name} />}
            {!loading && <AnnouncementSeverityChip severity={announcement.Severity} />}
          </Box>

          {loading ? <ArticleDateSkeleton /> : <ArticleDate date={announcement.CreatedOn} />}
        </Box>
      </Box>

      {loading ? <ArticleContentSkeleton /> : <ArticleContent content={announcement.Content} />}
    </Box>
  )
}

Announcement.defaultProps = {
  loading: false,
}

Announcement.propTypes = {
  announcement: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    Content: PropTypes.shape({
      Type: PropTypes.oneOf(Object.values(ArticleContentType)).isRequired,
      Content: PropTypes.string.isRequired,
    }).isRequired,
    Severity: PropTypes.oneOf(Object.values(AnnouncementSeverity)).isRequired,
  }),
  loading: PropTypes.bool,
}

export default memo(Announcement)
