import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from '@material-ui/core'

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import CheckIcon from '@material-ui/icons/Check'
import { useStyles } from './styles'

import { sortingOrders } from '../../constants'

const SortingControls = ({
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  sortingFields,
  selectedSortingField,
  onSortingFieldChange,
  sortingOrder,
  onSortingOrderChange,
}) => {
  const classes = useStyles()

  const menuAnchorRef = useRef()
  const prevIsMenuOpen = useRef(isMenuOpen)

  const handleSortingOrderButtonClick = useCallback(() => {
    if (sortingOrder === sortingOrders.ascending) {
      onSortingOrderChange(sortingOrders.descending)
    } else if (sortingOrder === sortingOrders.descending) {
      onSortingOrderChange(sortingOrders.ascending)
    } else {
      onSortingOrderChange(sortingOrders.default)
    }
  }, [sortingOrder, onSortingOrderChange])

  const handleMenuKeyDown = useCallback(
    (event) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        onMenuClose()
      } else if (event.key === 'Escape') {
        onMenuClose()
      }
    },
    [onMenuClose]
  )

  useEffect(() => {
    if (prevIsMenuOpen.current === true && isMenuOpen === false) {
      menuAnchorRef.current.focus()
    }
    prevIsMenuOpen.current = isMenuOpen
  }, [isMenuOpen])

  useEffect(() => {
    return () => {
      onMenuClose()
    }
  }, [onMenuClose])

  const SortingFieldSelectorButton = useMemo(
    () => (
      <Tooltip title='Change sorting field'>
        <Button
          id='select-sorting-field-button'
          aria-controls={isMenuOpen ? 'select-sorting-field-menu' : undefined}
          aria-expanded={isMenuOpen}
          aria-haspopup
          ref={menuAnchorRef}
          className={classes.sortingButton}
          variant='text'
          onClick={onMenuOpen}
        >
          {selectedSortingField}
        </Button>
      </Tooltip>
    ),
    [classes.sortingButton, isMenuOpen, onMenuOpen, selectedSortingField]
  )

  const SortingOrderButton = useMemo(() => {
    return sortingOrder === sortingOrders.ascending ? (
      <Tooltip title='Ascending order'>
        <IconButton onClick={handleSortingOrderButtonClick}>
          <ArrowDownwardIcon fontSize='small' />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title='Descending order'>
        <IconButton onClick={handleSortingOrderButtonClick}>
          <ArrowUpwardIcon fontSize='small' />
        </IconButton>
      </Tooltip>
    )
  }, [handleSortingOrderButtonClick, sortingOrder])

  return (
    <>
      <div className={classes.root}>
        {SortingFieldSelectorButton}
        {SortingOrderButton}
      </div>
      <Popper open={isMenuOpen} anchorEl={menuAnchorRef.current} role={undefined} placement='bottom-start' transition>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top',
            }}
          >
            <Paper variant='elevation'>
              <ClickAwayListener onClickAway={onMenuClose}>
                <MenuList
                  autoFocusItem={isMenuOpen}
                  id='select-sorting-field-menu'
                  aria-labelledby='select-sorting-field-button'
                  onKeyDown={handleMenuKeyDown}
                >
                  {sortingFields.map((fieldName) => (
                    <MenuItem key={fieldName} onClick={() => onSortingFieldChange(fieldName)}>
                      {selectedSortingField === fieldName && (
                        <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>
                      )}
                      <ListItemText inset={selectedSortingField !== fieldName}>{fieldName}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

SortingControls.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  sortingFields: PropTypes.arrayOf(PropTypes.string),
  selectedSortingField: PropTypes.string.isRequired,
  onSortingFieldChange: PropTypes.func.isRequired,
  sortingOrder: PropTypes.oneOf(Object.values(sortingOrders)),
  onSortingOrderChange: PropTypes.func.isRequired,
}

export default memo(SortingControls)
