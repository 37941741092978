const allFormsRequests = {
  getPagedAllFormsRequest: ({ pageNumber, pageSize, column = 'Name', ordering = 'asc', filtering = '' }) => {
    const searchQuery = {
      Sorting: {
        Column: column,
        Order: ordering,
      },
      Filtering: {
        GeneralFilter: filtering,
      },
    }

    return {
      url: `api/forms/allForms`,
      method: 'get',
      params: {
        pageNumber,
        pageSize,
        searchQuery: JSON.stringify(searchQuery),
      },
    }
  },
}

export default allFormsRequests
