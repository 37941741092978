import { put, takeLatest, all } from 'redux-saga/effects'
import * as rawActions from '../actions'

function* processFormControlsPredictions(action) {
  const formId = action.response.data.FormId

  yield put(rawActions.processFormControlsPredictionsRequest(formId))
}

function* formControlTags() {
  yield all([
    takeLatest([rawActions.createFormSuccess, rawActions.updateFormControlsSuccess], processFormControlsPredictions),
  ])
}

export default formControlTags
