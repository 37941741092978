import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setCurrentFolderId](state, action) {
      const folderId = action.payload

      if (!folderId) {
        return defaultState
      }

      return Number(folderId)
    },
    [rawActions.clearCurrentFolder](state, action) {
      return defaultState
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
