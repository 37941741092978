import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ProgressBar from '../components/ProgressBar'
import * as playerActions from '../actions'

class PlayerProgressBarContainer extends Component {
  handleChangeAction = (event, progressBarValue) => {
    const { currentVideoTimestamp, actions } = this.props

    if (currentVideoTimestamp !== progressBarValue) {
      actions.seekVideoToTimestamp(progressBarValue)
    }
  }

  render() {
    const { currentVideoTimestamp, durationVideoTimestamp } = this.props

    const progressBarTimestamp = Math.floor(
      currentVideoTimestamp < durationVideoTimestamp ? currentVideoTimestamp : durationVideoTimestamp
    )
    const progressBarDuration = Math.ceil(durationVideoTimestamp)
    const progressBarPercent = progressBarTimestamp > 0 ? (progressBarTimestamp * 100) / progressBarDuration : 0

    const props = {
      progressBarTimestamp,
      progressBarDuration,
      progressBarPercent,
      handleChangeAction: this.handleChangeAction,
    }

    return <ProgressBar {...props} />
  }
}

PlayerProgressBarContainer.propTypes = {
  currentVideoTimestamp: PropTypes.number.isRequired,
  durationVideoTimestamp: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    seekVideoToTimestamp: PropTypes.func.isRequired,
  }).isRequired,
}

function mapStateToProps({ player }) {
  return {
    currentVideoTimestamp: player.currentVideoTimestamp < 0 ? 0 : player.currentVideoTimestamp * 1000,
    durationVideoTimestamp: player.durationVideoTimestamp < 0 ? 0 : player.durationVideoTimestamp * 1000,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(playerActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProgressBarContainer)
