import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Fullscreen, FullscreenExit } from '@material-ui/icons'

import { playerSelectors, playerActions } from '@tabeeb/modules/player'

import ToolbarButton from '../ToolbarButton'

const FullScreenButton = () => {
  const dispatch = useDispatch()

  const isFullScreen = useSelector(playerSelectors.getIsFullscreen)

  const onClick = () => {
    dispatch(playerActions.onPlayerFullScreen(!isFullScreen))
  }

  return (
    <ToolbarButton
      onClick={onClick}
      Icon={isFullScreen ? FullscreenExit : Fullscreen}
      title={isFullScreen ? 'Exit fullscreen' : 'Enter fullscreen'}
    />
  )
}

export default memo(FullScreenButton)
