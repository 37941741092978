import { memo } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

import UserRoleSelect from '@tabeeb/modules/../users/components/UserRoleSelect'

const UserRoleSelectField = ({ field }) => {
  const { setFieldValue } = useFormikContext()

  const onRoleChange = ({ target: { value: role } }) => {
    setFieldValue(field.name, role)
  }

  return <UserRoleSelect role={field.value} onChange={onRoleChange} />
}

UserRoleSelectField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

export default memo(UserRoleSelectField)
