import PropTypes from 'prop-types'

import { Box, List, ListItem, ListItemIcon, Typography, withStyles } from '@material-ui/core'

import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import { passwordRequirements } from '../../constants/Requirements'

import styles from './styles'

const PasswordRequirements = ({ classes, value, color }) => {
  return (
    <Box className={classes.container}>
      <Typography color={color}>Must contain:</Typography>
      <List className={classes.list}>
        {passwordRequirements.map(({ label, validator }, index) => {
          return (
            <ListItem key={label} className={classes.listItem}>
              <ListItemIcon className={classes.icon}>
                {validator(value) ? <CheckIcon className={classes.success} /> : <CloseIcon color='error' />}
              </ListItemIcon>
              <Typography variant='body2' color={color}>
                {label}
              </Typography>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

PasswordRequirements.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
    listItem: PropTypes.string.isRequired,
    success: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.string,
}

export default withStyles(styles)(PasswordRequirements)
