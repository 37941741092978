import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { ConfirmationDialog } from '@tabeeb/uikit'
import { completeInContentReviewRequest } from '@tabeeb/modules/contentReviews/actions'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { FormButtons } from '../../services/enums'
import { getFormButtonState } from '../../selectors'

const CompleteInContentReviewButton = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { isVisible, isDisabled } = useSelector((state) =>
    getFormButtonState(state, { button: FormButtons.CompleteInContentReview })
  )
  const contentId = useSelector(getContentId)
  const dispatch = useDispatch()

  if (!isVisible) return null

  const onDialogSubmit = () => {
    dispatch(completeInContentReviewRequest({ contentId }))
    setIsDialogOpen(false)
  }

  const dialogProps = {
    onCloseDialog: () => setIsDialogOpen(false),
    onSubmit: onDialogSubmit,
    isOpen: isDialogOpen,
    cancelButtonText: 'Close',
    submitButtonText: 'Complete',
    title:
      'Once this review is complete, you will no longer be able to send feedback. Please confirm to complete this review',
  }

  return (
    <>
      <Button
        onClick={() => setIsDialogOpen(true)}
        variant='contained'
        color='primary'
        className='form-button'
        disabled={isDisabled}
      >
        complete review
      </Button>
      <ConfirmationDialog {...dialogProps} />
    </>
  )
}

export default memo(CompleteInContentReviewButton)
