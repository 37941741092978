import { memo } from 'react'
import PropTypes from 'prop-types'

import { Button, Grid, Input, InputLabel, Typography } from '@material-ui/core'

import { useStyles } from './styles'

const TenantsFilter = ({ tenantName, onNameChange, subdomain, onSubdomainChange, onClose }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.contentHeader} variant='h6'>
          Filter tenants by
        </Typography>
        <div className={classes.contentForm} aria-labelledby='tenants-filter-keywords-header'>
          <Typography
            id='tenants-filter-keywords-header'
            component='p'
            variant='body1'
            className={classes.formGroupHeader}
            aria-label='Search keywords'
          >
            Keywords
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <InputLabel htmlFor='tenants-filter-name'>Tenant name</InputLabel>
              <Input
                id='tenants-filter-name'
                fullWidth
                name='Name'
                value={tenantName}
                onChange={onNameChange}
                autoComplete='off'
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <InputLabel htmlFor='tenants-filter-subdomain'>Subdomain</InputLabel>
              <Input
                fullWidth
                value={subdomain}
                onChange={onSubdomainChange}
                id='tenants-filter-subdomain'
                name='Subdomain'
                autoComplete='off'
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.actions}>
        <Button variant='text' onClick={onClose}>
          Close
        </Button>
        <Button variant='text' type='submit' color='primary'>
          Find
        </Button>
      </div>
    </div>
  )
}

TenantsFilter.propTypes = {
  onClose: PropTypes.func,
  tenantName: PropTypes.string,
  onNameChange: PropTypes.func,
  subdomain: PropTypes.string,
  onSubdomainChange: PropTypes.func,
}

export default memo(TenantsFilter)
