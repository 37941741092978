import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FoldersList from '../components/FoldersList'
import { getNestedFoldersForSelectedFolder } from '../selectors'

class FoldersListContainer extends Component {
  render() {
    const { foldersList, isGalleryExpanded, isGalleryExpansionEnabled } = this.props

    const props = {
      folders: foldersList,
      isExpanded: isGalleryExpansionEnabled && isGalleryExpanded,
    }

    return <FoldersList {...props} />
  }
}

FoldersListContainer.propTypes = {
  foldersList: PropTypes.array.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isGalleryExpansionEnabled: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const { gallery, appConfigState } = state

  return {
    foldersList: getNestedFoldersForSelectedFolder(state),
    isGalleryExpanded: gallery.isGalleryExpanded,
    isGalleryExpansionEnabled: appConfigState.isGalleryExpansionEnabled,
  }
}

export default connect(mapStateToProps)(FoldersListContainer)
