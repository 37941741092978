import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.setFormInfoLoading](state, { payload }) {
      return payload
    },
    [combineActions(rawActions.getFormByIdSuccess, rawActions.getFormByIdFailed)](state, action) {
      return defaultState
    },
  },
  defaultState
)
