import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@mui/material'
import { FetchStatus } from '@tabeeb/enums'
import { NetworkCheck } from '@mui/icons-material'

const CheckConnectionButton = ({ fetchStatus, disabled, onClick }) => {
  const checkConnectionButtonStartIcon = useMemo(() => {
    return fetchStatus === FetchStatus.Loading ? <CircularProgress size={18} /> : <NetworkCheck color='primary' />
  }, [fetchStatus])

  return (
    <Button
      variant='outlined'
      color='primary'
      startIcon={checkConnectionButtonStartIcon}
      disabled={disabled || fetchStatus === FetchStatus.Loaded}
      onClick={onClick}
    >
      Check connection
    </Button>
  )
}

CheckConnectionButton.propTypes = {
  fetchStatus: PropTypes.oneOf([FetchStatus.Failed, FetchStatus.Idle, FetchStatus.Loaded, FetchStatus.Loading]),
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CheckConnectionButton
