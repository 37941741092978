import { useEffect, useRef } from 'react'

import { Group } from '@tweenjs/tween.js'

function useTweenAnimation() {
  const groupRef = useRef(new Group())

  const requestIdRef = useRef()
  const animate = (timestamp) => {
    requestIdRef.current = requestAnimationFrame(animate)
    groupRef.current.update(timestamp)
  }

  useEffect(() => {
    requestIdRef.current = requestAnimationFrame(animate)
    return () => {
      cancelAnimationFrame(requestIdRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return groupRef
}

export default useTweenAnimation
