import InitialValuesButton from '@tabeeb/modules/forms/components/InitialValuesButton'
import UploadFormAnswersButton from '@tabeeb/modules/forms/components/UploadFormAnswersButton'
import './styles.less'

const FormsTopButtons = () => {
  return (
    <div className='form-top-buttons'>
      <InitialValuesButton />
      <UploadFormAnswersButton />
    </div>
  )
}

export default FormsTopButtons
