import { memo } from 'react'
import PropTypes from 'prop-types'

import { Fade, List, ListItem } from '@material-ui/core'

const LoadingPlaceholder = ({ items, component: Skeleton }) => {
  return (
    <Fade in>
      <List disablePadding>
        {Array.from(new Array(items)).map((_, index) => (
          <ListItem key={index} button>
            <Skeleton key={index} />
          </ListItem>
        ))}
      </List>
    </Fade>
  )
}

LoadingPlaceholder.defaultProps = {
  items: 4,
}

LoadingPlaceholder.propTypes = {
  items: PropTypes.number,
  component: PropTypes.elementType.isRequired,
}

export default memo(LoadingPlaceholder)
