export const getCalendarEventRequest = ({ calendarId, eventId }) => ({
  url: `api/calendars/${calendarId}/events/${eventId}`,
  method: 'get',
})

export const createCalendarEventRequest = ({ calendarId, model }) => ({
  url: `api/calendars/${calendarId}/events`,
  method: 'post',
  data: model,
})

export const createAppointmentSlotsRequest = ({ calendarId, model }) => ({
  url: `api/calendars/${calendarId}/appointmentSlots`,
  method: 'post',
  data: model,
})

export const getFreeAppointmentSlotsRequest = ({ calendarId, eventId }) => ({
  url: `api/calendars/${calendarId}/appointmentSlots/${eventId}/free`,
  method: 'get',
})

export const bookAppointmentSlotRequest = ({ calendarId, eventId, model }) => ({
  url: `api/calendars/${calendarId}/appointmentSlots/${eventId}/book`,
  method: 'post',
  data: model,
})

export const deleteCalendarEventRequest = ({ calendarId, eventId }) => ({
  url: `api/calendars/${calendarId}/events/${eventId}`,
  method: 'delete',
})

export const getCalendarEventsByUserRequest = ({ userId, startDate, endDate, updatedOn = null, skip, take }) => ({
  url: `api/calendars/${userId}/events`,
  method: 'get',
  params: {
    startDate,
    endDate,
    updatedOn,
    skip,
    take,
  },
})

export const getCalendarsListRequest = () => ({
  url: `api/calendars/list`,
  method: 'get',
})

export const subscribeToUsersCalendarRequest = ({ userId }) => ({
  url: `api/calendars/${userId}/subscribe`,
  method: 'post',
})

export const unsubscribeFromUsersCalendarRequest = ({ userId }) => ({
  url: `api/calendars/${userId}/unsubscribe`,
  method: 'delete',
})

export const updateCalendarEventRequest = ({ calendarId, model }) => ({
  url: `api/calendars/${calendarId}/events/${model.id}`,
  method: 'put',
  data: model,
})

export const updateCalendarEventOwnerRequest = ({ calendarId, eventId, userId }) => ({
  url: `api/calendars/${calendarId}/events/${eventId}/owner`,
  method: 'put',
  data: {
    userId,
  },
})

export const updateCalendarEventTimeRequest = ({ userId, eventId, startDate, endDate }) => ({
  url: `api/calendars/${userId}/events/${eventId}/time`,
  method: 'put',
  data: {
    startDate,
    endDate,
  },
})

export const updateCalendarSubscriptionDisplayRequest = ({ userId, mode }) => ({
  url: `api/calendars/${userId}/display`,
  method: 'put',
  data: {
    mode,
  },
})

export const updateCalendarSubscriptionColorRequest = ({ userId, color }) => ({
  url: `api/calendars/${userId}/color`,
  method: 'put',
  data: {
    color,
  },
})

export const searchCalendarUsersRequest = ({ searchText }) => ({
  url: `api/users?search=${encodeURIComponent(searchText)}`,
  method: 'get',
})

export const switchSessionOwnerRequest = ({ contentId, userId }) => ({
  url: `api/contents/switchOwner`,
  method: 'post',
  data: {
    ContentId: contentId,
    NewOwnerId: userId,
    IgnoreSessionStatus: true,
  },
})

export const acceptCalendarEventRequest = (notification) => ({
  url: `api/calendars/${notification.Approver.Id}/events/${notification.Id}/accept`,
  method: 'post',
})

export const declineCalendarEventRequest = (notification) => ({
  url: `api/calendars/${notification.Approver.Id}/events/${notification.Id}/decline`,
  method: 'post',
})
