import { handleActions } from 'redux-actions'

import _ from 'lodash'

import {
  clearSncSelection,
  clearSncQuestionAnswersSelection,
  addSncQuestionAnswersToSelection,
  removeSncQuestionAnswersFromSelection,
  resetState,
} from '../actions'

const defaultState = {}

const getQuestionAnswers = (set, questionId) => set[questionId] || []

export default handleActions(
  {
    [clearSncSelection](state, action) {
      return defaultState
    },
    [clearSncQuestionAnswersSelection](state, action) {
      const { questionId } = action.payload

      return {
        ...state,
        [questionId]: [],
      }
    },
    [addSncQuestionAnswersToSelection](state, action) {
      const { questionId, answers } = action.payload

      return {
        ...state,
        [questionId]: _.uniq([...getQuestionAnswers(state, questionId), ...answers]),
      }
    },
    [removeSncQuestionAnswersFromSelection](state, action) {
      const { questionId, answers } = action.payload

      return {
        ...state,
        [questionId]: getQuestionAnswers(state, questionId).filter((id) => !answers.includes(id)),
      }
    },
    [resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
