import { DialogTitle, Box, RadioGroup, styled } from '@mui/material'

export const TitleContainer = styled(DialogTitle)(({ theme }) => {
  return {
    padding: '16px 24px 8px',
  }
})

export const ContentContainer = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }
})

export const ManualInputContainer = styled(Box)(({ theme }) => {
  return {
    paddingLeft: '30px',
    maxWidth: '300px',
  }
})

export const UnitsContainer = styled(RadioGroup)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
})

export const UnitsLabelContainer = styled(Box)(({ theme }) => {
  return {
    marginRight: '6px',
  }
})

export const ManualInputValuesContainer = styled(DialogTitle)(({ theme }) => {
  return {
    display: 'flex',
    gap: '10px',
  }
})
