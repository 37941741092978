import { handleActions, combineActions } from 'redux-actions'

import {
  updateRecordingState,
  onRecordingStarting,
  onRecordingStopped,
  onRecordingPaused,
  resetRecordingState,
} from '../actions'

const defaultValue = null

export default handleActions(
  {
    [combineActions(updateRecordingState, onRecordingStarting, onRecordingPaused)](state, { payload }) {
      return typeof payload.selectedUserId !== 'undefined' ? payload.selectedUserId : state
    },
    [combineActions(onRecordingStopped)](state, { payload }) {
      return defaultValue
    },
    [resetRecordingState](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
