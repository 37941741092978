import { memo, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import PropTypes from 'prop-types'

import { Button, Paper, Skeleton, Tooltip } from '@mui/material'

import { PageHeader, PageLayout } from '@tabeeb/uikit'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import Routes from '@tabeeb/routes'
import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'
import { markContentTrainingMaterialAsRead } from '@tabeeb/modules/articles/actions'
import { useContentTrainingMaterial, useTrainingMaterial } from '@tabeeb/modules/articles/hooks'
import { ArticleContent, ArticleContentSkeleton } from '@tabeeb/modules/articles/components'

const ContentTrainingMaterialViewPage = ({ id }) => {
  const dispatch = useDispatch()

  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false)

  const onError = useCallback(() => {
    dispatch(
      onAddErrorNotification({ message: trainingMaterialTitleFormatter.format('Failed to load training material') })
    )

    dispatch(replace(Routes.trainingMaterials))
  }, [dispatch])

  const {
    loading: contentTrainingMaterialLoading,
    contentTrainingMaterial,
    onReload,
  } = useContentTrainingMaterial({ id, onError })

  const { loading: trainingMaterialLoading, trainingMaterial } = useTrainingMaterial({
    id: contentTrainingMaterial?.TrainingMaterial?.Id,
    onError,
  })

  const onMarkAsRead = useCallback(async () => {
    try {
      setIsSubmitInProgress(true)

      await callApiAsync(
        markContentTrainingMaterialAsRead.request({ ContentTrainingMaterialId: contentTrainingMaterial.Id })
      )

      dispatch(
        onAddSuccessNotification({ message: trainingMaterialTitleFormatter.format('Training material marked as read') })
      )

      onReload()
    } catch {
      dispatch(
        onAddErrorNotification({
          message: trainingMaterialTitleFormatter.format('Failed to mark training material as read'),
        })
      )
    } finally {
      setIsSubmitInProgress(false)
    }
  }, [contentTrainingMaterial?.Id, dispatch, onReload])

  return (
    <PageLayout component={Paper} spacing='page' variant='outlined' maxWidth='lg'>
      <PageHeader
        title={trainingMaterial?.Name || <Skeleton width={350} />}
        actions={
          !contentTrainingMaterial?.Read &&
          contentTrainingMaterial?.Assigned && (
            <Tooltip title='Mark as read'>
              <span>
                <Button
                  disabled={contentTrainingMaterialLoading || trainingMaterialLoading || isSubmitInProgress}
                  color='primary'
                  variant='outlined'
                  onClick={onMarkAsRead}
                >
                  Mark as read
                </Button>
              </span>
            </Tooltip>
          )
        }
      />

      {!trainingMaterial?.Content ? <ArticleContentSkeleton /> : <ArticleContent content={trainingMaterial?.Content} />}
    </PageLayout>
  )
}

ContentTrainingMaterialViewPage.propTypes = {
  id: PropTypes.number.isRequired,
}

export default memo(ContentTrainingMaterialViewPage)
