import { handleActions } from 'redux-actions'

import * as jitsiTrackActions from '../actions/jitsiTrack'

const initialState = {
  isVideoMuted: false,
  isAudioMuted: false,
  trackMuteInProcess: false,
}

export default handleActions(
  {
    [jitsiTrackActions.setVideoMuted](state, action) {
      return { ...state, isVideoMuted: !state.isVideoMuted }
    },
    [jitsiTrackActions.setAudioMuted](state, action) {
      return { ...state, isAudioMuted: !state.isAudioMuted }
    },
  },
  initialState
)
