import { takeLatest, all, put, select } from 'redux-saga/effects'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import { contentNotificationService } from '../../shared/content'

import * as galleryActions from '../actions'
import * as spatialModelActions from '../../spatialModel/actions'
import updateOrAddGallery from './updateOrAddGallery'

function* galleryAddSpatialModel() {
  const isSpatialModelOpen = yield select((state) => state.spatialModel.isTimelineOpen)
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  if (!isSpatialModelOpen) yield put(spatialModelActions.openPagesDialog(true))

  if (currentPanel !== TabPanel.SpatialView)
    yield put(navbarActions.switchPanel(TabPanel.SpatialView, TabPanelLocation.Bottom))
}

function* createSpatialModelSuccess(action) {
  const pages = [action.response.data]
  const isSelectAddedGalleryItem = false
  yield updateOrAddGallery(isSelectAddedGalleryItem, pages)
  yield contentNotificationService.notifyOfNewPages(pages)
}

function* addSpatialModelPage() {
  yield all([
    takeLatest(galleryActions.galleryAddSpatialModel, galleryAddSpatialModel),
    takeLatest(spatialModelActions.createSpatialModelSuccess, createSpatialModelSuccess),
  ])
}

export default addSpatialModelPage
