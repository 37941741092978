import PropTypes from 'prop-types'
import { Dialog, DialogContent, IconButton, DialogTitle, Collapse, useMediaQuery } from '@mui/material'
import { Grid } from '@material-ui/core'
import { Close } from '@mui/icons-material'

import useStyles from './styles'

const ContentUserOptionsDialog = ({ open, onClose, title, leftPart, rightPart, showRightPart }) => {
  const classes = useStyles()
  const moreThanLg = useMediaQuery((theme) => theme.breakpoints.up('lg'), { noSsr: true })
  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' PaperProps={{ className: classes.paper }} disableEnforceFocus>
      <div className={classes.closeIconButton}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2} className={classes.rootGrid}>
          <Grid item xs className={classes.leftPart}>
            {leftPart}
          </Grid>
          <Collapse in={showRightPart} orientation={moreThanLg ? 'horizontal' : 'vertical'} sx={{ height: '100%' }}>
            <Grid item lg={8} xs={12} className={classes.rightPart}>
              {rightPart}
            </Grid>
          </Collapse>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

ContentUserOptionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  leftPart: PropTypes.node.isRequired,
  rightPart: PropTypes.node.isRequired,
  showRightPart: PropTypes.bool.isRequired,
}

export default ContentUserOptionsDialog
