import { put, takeLatest, all, select } from 'redux-saga/effects'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'
import { signalrEvents, signalrConstants } from '../../signalr'
import { openInfoMessageDialog, triggerFormAction } from '../actions'

function* onFormActionSuccess(action) {
  yield put(onAddSuccessNotification({ message: 'Form action was triggered successfully' }))
}

function* onFormActionFailed(action) {
  yield put(onAddErrorNotification({ message: 'Failed to trigger form action' }))
}

function* onShowInfoMessage(action) {
  const [{ infoMessage, userId }] = action.payload
  const currentUserId = yield select(getCurrentUserId)

  if (currentUserId === userId) {
    yield put(openInfoMessageDialog(infoMessage))
  }
}

function* formActions() {
  yield all([
    takeLatest(triggerFormAction.success, onFormActionSuccess),
    takeLatest(triggerFormAction.failed, onFormActionFailed),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onShowInfoMessage, onShowInfoMessage),
  ])
}

export default formActions
