import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import { requiredBentleyAnalyticsReportFiles } from '@tabeeb/shared/utils/validationErrorMessages'
import * as rawActions from '../actions'

import Timeline from '../../timeline/components/Timeline'
import { ASSETS_NUMBER_FOR_BENTLEY_ANALYTICS_REPORT } from '../constants'

class BentleyAnalyticsReportModelsListContainer extends Component {
  _handleClose = () => {
    const {
      actions: { closeBentleyAnalyticsReportDialog },
    } = this.props

    closeBentleyAnalyticsReportDialog()
  }

  _handleSubmit = () => {
    const {
      actions: { addBentleyAnalyticsReport },
      isProcessing,
    } = this.props

    if (isProcessing) {
      return
    }

    addBentleyAnalyticsReport()
  }

  _handleDeleteItem = (itemIndex) => {
    const {
      actions: { deleteModelFromBentleyAnalyticsReport },
    } = this.props

    deleteModelFromBentleyAnalyticsReport(itemIndex)
  }

  render() {
    const { isOpen, timeLineList, currentPanel } = this.props

    const actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        class: 'submit',
        tooltip:
          timeLineList.length === ASSETS_NUMBER_FOR_BENTLEY_ANALYTICS_REPORT
            ? null
            : requiredBentleyAnalyticsReportFiles,
        disabled: timeLineList.length !== ASSETS_NUMBER_FOR_BENTLEY_ANALYTICS_REPORT,
      },
    ]

    const props = {
      actions,
      timeLineList,
      handleDeleteItem: this._handleDeleteItem,

      isOpen,
      isSelected: currentPanel === TabPanel.BentleyAnalyticsReport,
    }

    return <Timeline {...props} />
  }
}

BentleyAnalyticsReportModelsListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  timeLineList: PropTypes.array.isRequired,
  currentPanel: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    closeBentleyAnalyticsReportDialog: PropTypes.func.isRequired,
    addBentleyAnalyticsReport: PropTypes.func.isRequired,
    deleteModelFromBentleyAnalyticsReport: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.bentleyAnalyticsReport.isTimelineOpen,
    timeLineList: state.bentleyAnalyticsReport.modelsList,
    currentPanel: state.navbar.currentPanel[TabPanelLocation.Bottom],
    isProcessing: state.bentleyAnalyticsReport.isProcessing,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BentleyAnalyticsReportModelsListContainer)
