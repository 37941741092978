import PropTypes from 'prop-types'

import { alpha } from '@material-ui/core/styles'

import { Line as CanvasLine, Circle as CanvasCircle, Group } from 'react-konva'

import { getHighlightedColor } from '../../services/colorsService'
import { getViewBoxFromAnnotationCoordinates } from '../../helpers'

const Polygon = ({
  annotation,
  annotationRef,
  onMarkerClick,
  opacity,
  hovered,
  hoverProps,
  contentScale,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
  children,
}) => {
  const position = Polygon.getXY(annotation)
  const points = Polygon.getLinePoints(annotation)
  const markers = Polygon.getMarkersPoints(annotation)
  const anchor = Polygon.getAnchor(annotation)

  const commonStyles = {
    lineCap: 'round',
    lineJoin: 'round',
    opacity,
    closed: true,
  }

  const styles = {
    ...commonStyles,
    stroke: annotation.Color,
    strokeWidth: annotation.Width,
    fill: alpha(annotation.Color, Polygon.fillOpacity),
    hitStrokeWidth: annotation.Width * 2,
  }

  const markerStyles = {
    stroke: annotation.Color,
    strokeWidth: 2 / contentScale,
    fill: 'white',
    radius: 5 / contentScale,
  }

  const hoveredStyles = {
    ...commonStyles,
    stroke: getHighlightedColor(annotation.Color),
    strokeWidth: 1,
    strokeHitEnabled: false,
    strokeScaleEnabled: false,
    fillEnabled: false,
  }

  return (
    <Group {...position} {...dragAndDropProps}>
      <CanvasLine
        id={annotation.Id}
        ref={annotationRef}
        points={points}
        closed
        {...styles}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
      />
      {(selected || hovered) && <CanvasLine points={points} {...hoveredStyles} />}
      {markers.map((marker, index) => (
        <CanvasCircle
          key={index}
          {...marker}
          {...markerStyles}
          {...hoverProps}
          {...selectionProps}
          {...contextMenuProps}
          onMouseDown={onMarkerClick && (() => onMarkerClick({ x: marker.x + position.x, y: marker.y + position.y }))}
        />
      ))}
      <Group {...anchor}>{children}</Group>
    </Group>
  )
}

Polygon.defaultProps = {
  scale: 1,
}

Polygon.fillOpacity = 0.25

Polygon.getXY = (annotation) => ({
  x: annotation.Coordinates[0].Start.X,
  y: annotation.Coordinates[0].Start.Y,
})

Polygon.getAnchor = () => ({
  x: 0,
  y: 0,
})

Polygon.getLinePoints = (annotation) => {
  const points = []
  const position = Polygon.getXY(annotation)

  for (const coordinates of annotation.Coordinates) {
    points.push(coordinates.Start.X - position.x)
    points.push(coordinates.Start.Y - position.y)
    points.push(coordinates.End.X - position.x)
    points.push(coordinates.End.Y - position.y)
  }

  return points
}

Polygon.getMarkersPoints = (annotation) => {
  const points = []
  const position = Polygon.getXY(annotation)

  for (const coordinates of annotation.Coordinates) {
    points.push({
      x: coordinates.Start.X - position.x,
      y: coordinates.Start.Y - position.y,
    })
  }

  return points
}

Polygon.getViewBox = getViewBoxFromAnnotationCoordinates

Polygon.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Color: PropTypes.string.isRequired,
    Coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        Start: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
        End: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
      })
    ).isRequired,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  annotationRef: PropTypes.func,
  opacity: PropTypes.number,
  contentScale: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
  onMarkerClick: PropTypes.func,
  children: PropTypes.node,
}

export default Polygon
