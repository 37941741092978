import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_BENTLEY_MODEL_STATE')

export const openBentleyModelTimeline = createAction('OPEN_BENTLEY_MODEL_TIMELINE')
export const closeBentleyModelTimeline = createAction('CLOSE_BENTLEY_MODEL_TIMELINE')

export const addImageToBentleyModel = createAction('ADD_IMAGE_TO_BENTLEY_MODEL')
export const addAllPagesToBentleyModel = createAction('ADD_ALL_PAGES_TO_BENTLEY_MODEL')
export const deleteImageFromBentleyModel = createAction('DELETE_IMAGE_FROM_BENTLEY_MODEL')
export const updateImagesBentleyModel = createAction('UPDATE_IMAGES_BENTLEY_MODEL')

export const createBentleyModel = createAction('CREATE_BENTLEY_MODEL')

export const createBentleyModelRequest = createAction('CREATE_BENTLEY_MODEL_REQUEST')
export const createBentleyModelSuccess = createAction('CREATE_BENTLEY_MODEL_SUCCESS')
export const createBentleyModelFailed = createAction('CREATE_BENTLEY_MODEL_FAILED')

export const openBentleySettingsDialog = createAction('OPEN_BENTLEY_SETTINGS_DIALOG')
export const closeBentleySettingsDialog = createAction('CLOSE_BENTLEY_SETTINGS_DIALOG')

export const getContentBentleyGigapixels = createApiActions('GET_CONTENT_BENTLEY_GIGAPIXELS')
