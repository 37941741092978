import { Box, styled } from '@mui/material'

export const View = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  overflow: 'hidden',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,

  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.standard,
    easing: 'ease-in-out',
  }),

  '&[data-main-view="true"]': {
    transform: 'translateX(-100%)',
  },

  '&[data-configuration-view="true"]': {
    transform: 'translateX(100%)',
  },

  '&[data-active-view="true"]': {
    transform: 'translateX(0%)',
  },
}))
