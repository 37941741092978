import { Route } from 'react-router'
import Routes from '@tabeeb/routes'
import PowerBIReportsTable from '../components/PowerBIReportsTable/index'
import PowerBIReportContainer from '../containers/PowerBIReportContainer'
import PowerBIReportEditor from '../components/PowerBIReportEditor'

const PowerBiReportsPage = () => {
  return (
    <>
      <Route
        exact
        key={Routes.powerBIReports}
        path={Routes.powerBIReports}
        render={() => {
          return <PowerBIReportsTable />
        }}
      />
      <Route
        exact
        key={Routes.powerBIReport}
        path={Routes.powerBIReport}
        render={({
          match: {
            params: { id },
          },
        }) => {
          return <PowerBIReportContainer id={id} />
        }}
      />
      <Route
        exact
        key={Routes.editPowerBIReport}
        path={Routes.editPowerBIReport}
        render={({
          match: {
            params: { id },
          },
        }) => {
          return <PowerBIReportEditor id={id} />
        }}
      />
    </>
  )
}

export default PowerBiReportsPage
