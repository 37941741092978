import { put, takeLatest, all } from 'redux-saga/effects'
import { onAddInfoNotification } from '@tabeeb/modules/notification/actions'

import * as aiActions from '../actions'

function* onAddPredictionSetSuccess() {
  yield put(onAddInfoNotification({ message: 'Model was successfully added' }))
}

function* addModel() {
  yield all([takeLatest(aiActions.addPredictionSet.success, onAddPredictionSetSuccess)])
}

export default addModel
