import PropTypes from 'prop-types'
import { Text, Group } from 'react-konva'

const NorthPoint = ({ x, y, size, fontSizeRatio, fontWidthToSizeRatio }) => {
  const letterProps = {
    fill: 'white',
    fontSize: fontSizeRatio * size,
  }

  return (
    <Group x={x} y={y}>
      <Text
        x={(-letterProps.fontSize * fontWidthToSizeRatio) / 2}
        y={-letterProps.fontSize}
        text='N'
        {...letterProps}
      />
    </Group>
  )
}

NorthPoint.defaultProps = {
  x: 0,
  y: 0,
  fontSizeRatio: 0.1,
  fontWidthToSizeRatio: 0.75,
}

NorthPoint.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

export default NorthPoint
