import { handleActions } from 'redux-actions'

import { getConfigForUpdate } from '../actions'

export default handleActions<boolean>(
  {
    [getConfigForUpdate.request.toString()](state, action) {
      return true
    },
    [getConfigForUpdate.success.toString()](state, action) {
      return false
    },
  },
  false
)
