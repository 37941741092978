import moment from 'moment/moment'

const getDurationString = (duration) => {
  const recordingTime = moment.utc(duration)

  const hours = Math.floor(moment.duration(duration, 'milliseconds').asHours())
  if (hours > 23) {
    return `${hours}:${recordingTime.format('mm:ss')}`
  }

  if (hours > 0) {
    return `${recordingTime.format('HH:mm:ss')}`
  }

  return recordingTime.format('mm:ss')
}

export default getDurationString
