import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { Lens } from '@material-ui/icons'
import { Box, Typography, withStyles, Link, List, ListItem, ListItemIcon } from '@material-ui/core'

import routes from '@tabeeb/routes'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import ErrorMessage from '../errorMessage'

import styles from './styles'

const PageLayout = ({
  classes,
  form,
  controls,
  links,
  bottomLinks,
  mission,
  bullets,
  bannerUrl,
  bannerLink,
  backgroundColor,
  backgroundImageUrl,
  panelColor,
  textColor,
  error,
  location: { pathname },
}) => {
  const bannerImage = <img className={classes.banner} alt='banner' src={bannerUrl} />

  const displayMission = pathname === routes.login

  return (
    <Box
      className={classes.page}
      style={{
        color: textColor,
        backgroundColor,
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : null,
      }}
    >
      <Box className={classes.panel}>
        <Box className={classes.panelBackground} style={{ background: panelColor }} />
        {bannerLink ? (
          <Link className={classes.bannerLink} href={bannerLink} target='_blank' title={`Proceed to ${bannerLink}`}>
            {bannerImage}
          </Link>
        ) : (
          bannerImage
        )}
        {displayMission && mission && (
          <Box className={classes.mission}>
            <Typography>{mission}</Typography>
            <List>
              {bullets.map((bullet, index) => (
                <ListItem key={index} disableGutters className={classes.bullet} alignItems='flex-start'>
                  <ListItemIcon className={classes.bulletIcon}>
                    <Lens fontSize='inherit' />
                  </ListItemIcon>
                  {bullet}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <Box className={classes.formContainer}>
          <ErrorMessage errorMessage={error} />
          {form}
          {controls}
          <Box className={classes.linksContainer}>{links}</Box>
        </Box>
        <Box className={classes.bottomLinksContainer}>{bottomLinks}</Box>
      </Box>
    </Box>
  )
}

PageLayout.defaultProps = {
  controls: null,
  bullets: [],
  bottomLinks: null,
}

PageLayout.propTypes = {
  classes: PropTypes.shape({
    page: PropTypes.string.isRequired,
    panel: PropTypes.string.isRequired,
    banner: PropTypes.string.isRequired,
    bannerLink: PropTypes.string.isRequired,
    bottomLinksContainer: PropTypes.string.isRequired,
    bullet: PropTypes.string.isRequired,
    bulletIcon: PropTypes.string.isRequired,
    mission: PropTypes.string.isRequired,
    formContainer: PropTypes.string.isRequired,
    linksContainer: PropTypes.string.isRequired,
    panelBackground: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string,
  form: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  bottomLinks: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  panelColor: PropTypes.string,
  controls: PropTypes.object,
  mission: PropTypes.string,
  bullets: PropTypes.arrayOf(PropTypes.string),
  textColor: PropTypes.string,
  bannerUrl: PropTypes.string,
  bannerLink: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    textColor: appConfigStateSelectors.getLoginPageTextColor(state),
    panelColor: appConfigStateSelectors.getLoginBoudingBoxBackgroundColor(state),
    backgroundColor: appConfigStateSelectors.getLoginPageBackgroundColor(state),
    backgroundImageUrl: appConfigStateSelectors.getLoginBackgroundImage(state),
    mission: appConfigStateSelectors.getMission(state),
    bullets: appConfigStateSelectors.getBullets(state),
    bannerUrl: appConfigStateSelectors.getBannerUrl(state),
    bannerLink: appConfigStateSelectors.getBannerLink(state),
  }
}

export default withRouter(withStyles(styles)(connect(mapStateToProps)(PageLayout)))
