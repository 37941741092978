import { createApiActions } from '@tabeeb/shared/utils/actions'

export const getCertificatesTypesWithPagination = createApiActions('GET_CERTIFICATES_TYPES_WITH_PAGINATION')
export const getAllCertificateTypes = createApiActions('GET_ALL_CERTIFICATE_TYPES')
export const getApprovedCertificateTypeIdsByUserGuid = createApiActions(
  'GET_APPROVED_CERTIFICATE_TYPE_IDS_BY_USER_GUID'
)
export const getCertificateById = createApiActions('GET_CERTIFICATE_BY_ID')
export const createCertificateType = createApiActions('CREATE_CERTIFICATE_TYPE')
export const deleteCertificateType = createApiActions('DELETE_CERTIFICATE_TYPE')
export const updateCertificateType = createApiActions('UPDATE_CERTIFICATE_TYPE')
export const restoreCertificateType = createApiActions('RESTORE_CERTIFICATE_TYPE')
export const deleteCertificateTypePermanently = createApiActions('DELETE_CERTIFICATE_TYPE_PERMANENTLY')
export const getMyCertificates = createApiActions('GET_MY_CERTIFICATES')

export const getCertificates = createApiActions('GET_CERTIFICATES')
export const createCertificate = createApiActions('CREATE_CERTIFICATE')
export const deleteCertificate = createApiActions('DELETE_CERTIFICATE')
export const updateCertificate = createApiActions('UPDATE_CERTIFICATE')
export const reviewCertificate = createApiActions('REVIEW_CERTIFICATE')

export const getAllCertificateCategories = createApiActions('GET_ALL_CERTIFICATE_CATEGORIES')
export const getLocations = createApiActions('GET_LOCATIONS')
