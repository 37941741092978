import { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Input, withStyles } from '@material-ui/core'

import { contentViewerSelectors, Portal } from '@tabeeb/modules/contentViewer'
import Text from '../Text'

import styles from './styles'

const TextAnnotationEditor = ({ classes, annotation, x, y, scale, onSubmit }) => {
  const inputRef = useRef()
  useEffect(() => {
    const input = inputRef.current

    setWidth()

    setTimeout(() => {
      if (input) {
        input.focus()
        input.selectionStart = input.value.length
        input.selectionEnd = input.value.length
      }
    }, 50)
  }, [inputRef.current])

  const [text, setText] = useState(annotation.Text || '')

  const onChange = useCallback(({ target }) => {
    setText(target.value)

    setWidth()
  }, [])

  const onBlur = useCallback(() => {
    onSubmit(text)
  }, [text])

  const onKeyDown = useCallback(
    ({ keyCode, ctrlKey }) => {
      if (keyCode === 27 || (keyCode === 13 && ctrlKey)) {
        onSubmit(text)
      }
    },
    [text]
  )

  const setWidth = () => {
    const input = inputRef.current

    if (!input) {
      return
    }

    input.style.width = 'auto'

    if (input.offsetWidth < input.scrollWidth) {
      input.style.width = `${input.scrollWidth}px`
    }
  }

  return (
    <Portal node={document.getElementsByClassName('konvajs-content')[0]}>
      <Input
        inputRef={inputRef}
        className={classes.container}
        multiline
        value={text}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        style={{
          top: y,
          left: x,
          color: annotation.Color,
          fontSize: annotation.FontSize * scale,
        }}
        inputProps={{
          minRows: `${Text.getNumberOfRows(text)}`,
          className: classes.input,
        }}
      />
    </Portal>
  )
}

TextAnnotationEditor.defaultProps = {
  onSubmit: () => {},
}

TextAnnotationEditor.propTypes = {
  classes: PropTypes.shape({
    input: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
  annotation: PropTypes.shape({
    Text: PropTypes.string.isRequired,
    Color: PropTypes.string.isRequired,
    FontSize: PropTypes.number.isRequired,
  }).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    scale: contentViewerSelectors.getContentScale(state),
    contentSize: contentViewerSelectors.getContentSize(state),
  }
}

export default withStyles(styles)(connect(mapStateToProps)(TextAnnotationEditor))
