import PropTypes from 'prop-types'

import { FieldArray } from 'formik'

import { AIObjectPropertyType } from '@tabeeb/enums'
import UniqueAIObjectPropertyField from '../UniqueAIObjectPropertyField'

const UniqueAIObjectPropertiesField = ({ field: { name, value }, form: { errors, setFieldValue } }) => {
  return (
    <FieldArray name={name}>
      {() =>
        value
          .sort((a, b) => a.ordinal - b.ordinal)
          .map(
            (property, index) =>
              !property.isHidden &&
              (property.value || !property.isDisabled) && (
                <UniqueAIObjectPropertyField
                  key={property.name}
                  name={`properties.${index}.value`}
                  label={property.name}
                  disabled={property.isDisabled || !property.isEditable}
                  selectionListId={property.selectionListId}
                  aiObjectPropertyType={property.type}
                />
              )
          )
      }
    </FieldArray>
  )
}

UniqueAIObjectPropertiesField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.oneOf(Object.values(AIObjectPropertyType)),
        isDisabled: PropTypes.bool.isRequired,
        isEditable: PropTypes.bool.isRequired,
        isHidden: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
          PropTypes.shape({
            Value: PropTypes.string.isRequired,
            SLIId: PropTypes.number,
          }),
          PropTypes.arrayOf(
            PropTypes.shape({
              Value: PropTypes.string.isRequired,
              SLIId: PropTypes.number,
            })
          ),
        ]),
        ordinal: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({
      name: PropTypes.string,
      properties: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          type: PropTypes.oneOf(Object.values(AIObjectPropertyType)),
          isDisabled: PropTypes.bool,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number,
            PropTypes.shape({
              Value: PropTypes.string.isRequired,
              SLIId: PropTypes.number,
            }),
            PropTypes.arrayOf(
              PropTypes.shape({
                Value: PropTypes.string.isRequired,
                SLIId: PropTypes.number,
              })
            ),
          ]),
          ordinal: PropTypes.number,
        })
      ),
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
}

export default UniqueAIObjectPropertiesField
