import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Dialog, Grid, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core'
import { Collapse, Typography, Chip, TextField, CircularProgress } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { useDebouncedValue } from '@tabeeb/shared/utils/hooks'
import { callApiAsync } from '@tabeeb/shared/utils/requests'
import {
  DeviceAddAttributeType,
  DeviceVendorType,
  DeviceVendorTypeDisplayName,
  TenoviDeviceNameTypeDisplayNames,
  TenoviDeviceNameTypes,
  TenoviSensorCodeDisplayNames,
  TenoviSensorCodes,
} from '@tabeeb/enums'
import FormikTextField from '@tabeeb/shared/forms/formik/formikTextField'
import FormikSelect from '@tabeeb/shared/forms/formik/formikSelect'
import { maxLength, requiredField } from '@tabeeb/shared/utils/validationErrorMessages'
import UserSearch from '@tabeeb/shared/UserSearch'
import { searchUsersRequest } from '@tabeeb/modules/sessionsPage/actions'
import { getFoundUsersList } from '@tabeeb/modules/sessionsPage/selectors/reviews'
import UserAvatar from '@tabeeb/shared/userAvatar'

import { getLife365ListOfPrograms } from '@tabeeb/modules/healthData/selectors'
import { getAvailableDeviceTypes, getLife365ListOfProgramsRequest } from '@tabeeb/modules/healthData/actions'
import { isHealthDevice } from '@tabeeb/modules/admin/devices/helpers'
import { getUserByGuid } from '../../../../../users/actions'

import useStyles from './styles'

export const getTenoviSensorCodeByDeviceName = (tenoviDeviceName) => {
  switch (tenoviDeviceName) {
    case TenoviDeviceNameTypes.TenoviBpmS:
    case TenoviDeviceNameTypes.TenoviBpmSSignature:
    case TenoviDeviceNameTypes.TenoviBpmL:
    case TenoviDeviceNameTypes.TenoviBpmLSignature:
      return TenoviSensorCodes.BPM
    case TenoviDeviceNameTypes.TenoviPulseOx:
      return TenoviSensorCodes.PulseOx
    case TenoviDeviceNameTypes.TenoviScale:
      return TenoviSensorCodes.Scale
    case TenoviDeviceNameTypes.TenoviGlucometer:
      return TenoviSensorCodes.Glucometer
    case TenoviDeviceNameTypes.TenoviWatch:
      return TenoviSensorCodes.Watch
    default:
      return ''
  }
}

export const getTenoviSensorCodeNameByDevice = (selectedDevice) => {
  const attribute = selectedDevice.DeviceAddAttributes.find(
    (attr) => attr.AttributeTypeId === DeviceAddAttributeType.TenoviSensorCode
  )?.Value
  return TenoviSensorCodeDisplayNames[attribute]
}

const addHealthDeviceSchema = Yup.object().shape({
  ExternalId: Yup.string().when('VendorType', {
    is: (VendorType) => Number(VendorType) === DeviceVendorType.Tenovi,
    then: Yup.string(),
    otherwise: Yup.string()
      .strict()
      .required(requiredField)
      .trim('Invalid Serial Number')
      .matches(/^[A-Za-z0-9]+$/, 'Invalid Serial Number')
      .max(100, maxLength(100)),
  }),
  Name: Yup.string().trim().max(100, maxLength(100)).required(requiredField),
  VendorType: Yup.string().required(requiredField),
  ProgramId: Yup.string().when('VendorType', {
    is: (VendorType) => Number(VendorType) === DeviceVendorType.Life365,
    then: Yup.string().required(requiredField),
    otherwise: Yup.string().nullable(),
  }),
  UserId: Yup.string()
    .nullable()
    .when('VendorType', {
      is: (val) => val === DeviceVendorType.Life365 || val === DeviceVendorType.BasicHealthDevice,
      then: Yup.string()
        .matches(/^[0-9]+$/, 'Invalid User Id')
        .required(requiredField),
    }),
  TenoviDeviceNameType: Yup.mixed().when(['VendorType', 'Id'], {
    is: (VendorType, Id) => Number(VendorType) === DeviceVendorType.Tenovi && !Id,
    then: Yup.mixed().oneOf(Object.values(TenoviDeviceNameTypes), requiredField).required(requiredField),
    otherwise: Yup.mixed(),
  }),
  TenoviHardwareUuid: Yup.string().when(['VendorType', 'Id'], {
    is: (VendorType, Id) => Number(VendorType) === DeviceVendorType.Tenovi && !Id,
    then: Yup.string()
      .matches(/^[A-Za-z0-9]+$/, 'Invalid Gateway Id, must be with no hyphens or separating characters.')
      .required(requiredField)
      .trim()
      .length(12, 'Gateway Id must be 12 characters'),
    otherwise: Yup.string(),
  }),
})

const autocompleteInputProps = {
  variant: 'outlined',
  label: 'Enter name or email',
  placeholder: 'Enter name or email...',
  required: true,
}

const AddEditDeviceModal = ({
  onClose,
  onExited,
  open,
  onSubmit,
  selectedDevice,
  showEmailField,
  assignedForUserId,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isEditMode = !!selectedDevice
  const [searchUserEmail, setSearchUserEmail] = useState('')
  const [deviceUser, setDeviceUser] = useState(null)

  const foundUsersList = useSelector(getFoundUsersList)
  const life365ListOfPrograms = useSelector(getLife365ListOfPrograms)

  const handleChangeUserEmailInput = useCallback((event, value) => {
    setSearchUserEmail(value)
  }, [])

  const debouncedSearchUserEmail = useDebouncedValue(searchUserEmail, 500)

  useEffect(() => {
    if (!isEditMode || !selectedDevice?.UserId) {
      return
    }

    const fetchDeviceUser = async () => {
      const {
        response: { data },
      } = await callApiAsync(dispatch(getUserByGuid.request({ userGuid: selectedDevice.UserId })))

      setDeviceUser(data)
    }

    fetchDeviceUser()

    return () => {
      setDeviceUser(null)
    }
  }, [dispatch, isEditMode, selectedDevice?.UserId])

  useEffect(() => {
    if (open) {
      dispatch(searchUsersRequest({ searchText: debouncedSearchUserEmail }))
    }
  }, [debouncedSearchUserEmail, dispatch, open])

  useEffect(() => {
    if (open) {
      dispatch(getLife365ListOfProgramsRequest())
    }
  }, [dispatch, open])

  const [availableDeviceTypes, setAvailableDeviceTypes] = useState([])

  useEffect(() => {
    const fetchDeviceTypes = async () => {
      const {
        response: { data },
      } = await callApiAsync(getAvailableDeviceTypes.request())

      setAvailableDeviceTypes(data)
    }

    if (!isEditMode && open) {
      fetchDeviceTypes()
    }
  }, [dispatch, isEditMode, open])

  const vendorSelectOptions = useMemo(() => {
    return Object.values(DeviceVendorType)
      .filter((vendorType) => vendorType !== DeviceVendorType.Apple && vendorType !== DeviceVendorType.Google)
      .map((value) => ({
        name: `${DeviceVendorTypeDisplayName[value]}`,
        value,
      }))
  }, [])

  const programOptions = useMemo(
    () => life365ListOfPrograms.map((i) => ({ value: i.id, name: i.name })),
    [life365ListOfPrograms]
  )

  const tenoviDeviceNameOptions = useMemo(() => {
    return Object.keys(TenoviDeviceNameTypes).map((key) => ({
      name: TenoviDeviceNameTypeDisplayNames[key],
      value: TenoviDeviceNameTypes[key],
    }))
  }, [])

  const autocompleteProps = useMemo(
    () => ({
      multiple: false,
      onInputChange: handleChangeUserEmailInput,
      getOptionLabel: (option) => option.Email,
      renderOption: (option, state) => (
        <>
          <UserAvatar user={option} className={classes.avatarBlock} />
          {option.Name}
        </>
      ),
    }),
    [classes.avatarBlock, handleChangeUserEmailInput]
  )

  const initFormData = useMemo(
    () => ({
      ExternalId: '',
      Name: '',
      VendorType: '',
      UserId: Boolean(assignedForUserId) && !isEditMode ? assignedForUserId : '',
      ProgramId: '',
      TenoviSensorCodeType: '',
      TenoviDeviceNameType: '',
      TenoviHardwareUuid: '',
    }),
    [isEditMode, assignedForUserId]
  )

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited }}>
      <DialogTitle>{isEditMode ? 'Edit Device' : 'Add Device'}</DialogTitle>
      <Formik
        initialValues={
          isEditMode
            ? { ...selectedDevice, TenoviSensorCodeType: getTenoviSensorCodeNameByDevice(selectedDevice) }
            : initFormData
        }
        onSubmit={onSubmit}
        validationSchema={addHealthDeviceSchema}
        enableReinitialize
      >
        {({ values, isValid, dirty, setFieldValue, touched, errors, setTouched, handleChange }) => (
          <Form className={classes.form} autoComplete='off' id='addEditIOTDeviceForm'>
            <DialogContent>
              <Grid container direction='column' spacing={2}>
                {!isEditMode && (
                  <Grid item>
                    <DialogContentText>Choose health device type and enter required fields.</DialogContentText>
                  </Grid>
                )}
                <Grid item>
                  <Field
                    name='VendorType'
                    label='Vendor'
                    required
                    displayEmpty
                    component={FormikSelect}
                    options={vendorSelectOptions}
                    disabled={isEditMode}
                    id='addEditIOTDeviceFormTypeSelect'
                  />
                </Grid>
                <Grid item>
                  <Collapse in={!isEditMode && Boolean(values.VendorType)}>
                    <Grid container direction='column' spacing={2}>
                      {availableDeviceTypes
                        .filter((deviceType) => deviceType.VendorId === values.VendorType)
                        .map((deviceType) => (
                          <Grid item key={deviceType.Id} container direction='row' spacing={2}>
                            <Grid item>
                              <Typography>{deviceType.Name}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>{DeviceVendorTypeDisplayName[deviceType.VendorId]}</Typography>
                            </Grid>
                            <Grid item container direction='row' spacing={1}>
                              {deviceType.AvailableMeasures.map((measure) => (
                                <Grid item key={measure.Id}>
                                  <Chip label={measure.Name} size='small' />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item>
                  <Collapse in={Boolean(values.VendorType)}>
                    <Grid container direction='column' spacing={2}>
                      <>
                        {!(!isEditMode && values.VendorType === DeviceVendorType.Tenovi) && (
                          <Grid item>
                            <Field
                              name='ExternalId'
                              label='Serial Number'
                              required
                              component={FormikTextField}
                              disabled={isEditMode && values.VendorType === DeviceVendorType.Tenovi}
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <Field name='Name' label='Device Name' required component={FormikTextField} />
                        </Grid>
                        {isHealthDevice(values.VendorType) &&
                          (isEditMode
                            ? showEmailField && (
                                <Grid item>
                                  <TextField
                                    style={{ width: '100%' }}
                                    value={deviceUser?.Email || ''}
                                    title={deviceUser?.Email || ''}
                                    label='Email'
                                    disabled
                                    InputProps={{
                                      endAdornment: <div>{deviceUser === null && <CircularProgress size={30} />}</div>,
                                    }}
                                  />
                                </Grid>
                              )
                            : !Boolean(assignedForUserId) && (
                                <Grid item className={classes.autocompleteGrid}>
                                  <UserSearch
                                    options={foundUsersList}
                                    onChange={(event, value) => setFieldValue('UserId', value?.Id)}
                                    disabled={isEditMode}
                                    InputProps={{
                                      ...autocompleteInputProps,
                                      error: Boolean(touched.UserId) && Boolean(errors.UserId),
                                      helperText: Boolean(touched.UserId) && errors.UserId,
                                    }}
                                    AutocompleteProps={{
                                      ...autocompleteProps,
                                      onBlur: () => setTouched({ ...touched, UserId: true }),
                                      filterOptions: (options) => options,
                                    }}
                                  />
                                </Grid>
                              ))}
                        {values.VendorType === DeviceVendorType.Life365 && (
                          <Grid item>
                            <Field
                              name='ProgramId'
                              label='Program'
                              options={programOptions}
                              component={FormikSelect}
                              disabled={isEditMode}
                              id='addEditIOTDeviceFormTypeSelect'
                            />
                          </Grid>
                        )}
                        {values.VendorType === DeviceVendorType.Tenovi && (
                          <>
                            <Grid item>
                              {isEditMode ? (
                                <Field
                                  id='TenoviSensorCodeType'
                                  name='TenoviSensorCodeType'
                                  label='Tenovi Device Name'
                                  component={FormikTextField}
                                  disabled
                                />
                              ) : (
                                <Field
                                  id='TenoviDeviceNameType'
                                  name='TenoviDeviceNameType'
                                  label='Tenovi Device Name'
                                  required
                                  className={classes.select}
                                  options={tenoviDeviceNameOptions}
                                  component={FormikSelect}
                                  onChange={(e) => {
                                    handleChange(e)
                                    setFieldValue(
                                      'TenoviSensorCodeType',
                                      getTenoviSensorCodeByDeviceName(e.target.value)
                                    )
                                  }}
                                />
                              )}
                            </Grid>
                            {!isEditMode && (
                              <Grid item>
                                <Field
                                  name='TenoviHardwareUuid'
                                  label='Gateway Id'
                                  placeholder='AB12CD23EF45'
                                  required
                                  component={FormikTextField}
                                  helperText='You can find the ID on the back of the Gateway, where the power adapter is inserted. Id should be a 12-digit hexadecimal string with no hyphens or separating characters.'
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color='primary' type='submit' className={classes.button} disabled={!(isValid && dirty)}>
                Save
              </Button>
              <Button onClick={onClose} className={classes.button}>
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

AddEditDeviceModal.defaultProps = {
  showEmailField: true,
  assignedForUserId: null,
  excludeDeviceTypes: [],
}

AddEditDeviceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onExited: PropTypes.func,
  selectedDevice: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Type: PropTypes.number.isRequired,
    ExternalId: PropTypes.string.isRequired,
    IsDisabled: PropTypes.bool.isRequired,
    UserId: PropTypes.number.isRequired,
    ProgramId: PropTypes.string,
    TenoviSensorCodeType: PropTypes.number,
  }),
  showEmailField: PropTypes.bool,
  assignedForUserId: PropTypes.string,
}

export default AddEditDeviceModal
