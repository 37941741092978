import { combineActions, handleActions } from 'redux-actions'

import { SearchStatus } from '@tabeeb/enums'
import * as rawActions from '../actions'

const defaultState = {
  list: [],
  status: SearchStatus.NotStarted,
}

export default handleActions(
  {
    [rawActions.setTargetContentSearchResult](state, action) {
      return { list: action.payload, status: SearchStatus.Completed }
    },
    [rawActions.searchTargetContentRequest](state, action) {
      return { ...state, status: SearchStatus.InProgress }
    },
    [rawActions.searchTargetContentFailed](state, action) {
      return { list: [], status: SearchStatus.Completed }
    },
    [combineActions(
      rawActions.resetState,
      rawActions.resetShareSessionDialog,
      rawActions.removeOwner,
      rawActions.setTenantForSharing,
      rawActions.resetTargetContentSearchResult
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
