import { handleActions } from 'redux-actions'
import * as actions from '../actions'

const defaultValue = 0

export default handleActions(
  {
    [actions.getContentReviewStatusSuccess](state, action) {
      return action.response.data
    },
    [actions.setContentReviewStatus](state, action) {
      return action.payload
    },
  },
  defaultValue
)
