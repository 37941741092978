import { takeLatest, put, all } from 'redux-saga/effects'
import { resetState as resetToolbarState } from '../actions'

import { resetContentState } from '../../shared/content/actions'

function* onResetContentState() {
  yield put(resetToolbarState())
}

function* resetState() {
  yield all([takeLatest(resetContentState, onResetContentState)])
}

export default resetState
