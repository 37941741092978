import { combineReducers } from 'redux'

import createForm from './createForm'
import importForm from './importForm'
import formToEdit from './formToEdit'
import filterValue from './filterValue'
import mode from './mode'
import availableAIObjects from './availableAIObjects'
import selectedAIObjectsIds from './selectedAIObjectsIds'
import formIsLoading from './formIsLoading'
import inputErrorsList from './inputErrorsList'
import aiObjects from './aiObjects'
import nlpModels from './nlpModels'

export default combineReducers({
  createForm,
  importForm,
  formToEdit,
  filterValue,
  mode,
  availableAIObjects,
  selectedAIObjectsIds,
  formIsLoading,
  inputErrorsList,
  aiObjects,
  nlpModels,
})
