import PropTypes from 'prop-types'
import { Avatar } from '@material-ui/core'

const TenantAvatar = ({ name }) => {
  return <Avatar alt={name}>{name[0]?.toUpperCase()}</Avatar>
}

TenantAvatar.propTypes = {
  name: PropTypes.string,
}

export default TenantAvatar
