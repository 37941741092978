import PropTypes from 'prop-types'
import { Select, MenuItem } from '@material-ui/core'

const DropDownList = ({ disabled, control, answer, handleChange }) => {
  const { Options, Tooltip } = control
  const selectedOptionId = answer.selectedOptionsIds ? answer.selectedOptionsIds[0] : ''

  return (
    <Select
      fullWidth
      title={Tooltip}
      value={selectedOptionId}
      onChange={handleChange}
      disabled={disabled}
      variant='outlined'
      SelectDisplayProps={{
        style: { padding: 5 },
      }}
    >
      {Options &&
        Options.map((option) => {
          return (
            <MenuItem value={option.Id} key={option.Id}>
              {option.Value}
            </MenuItem>
          )
        })}
    </Select>
  )
}

DropDownList.propTypes = {
  disabled: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default DropDownList
