import PropTypes from 'prop-types'
import { CircularProgress, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { CloudDone, Delete, Edit } from '@material-ui/icons'
import { DeviceVendorType } from '@tabeeb/enums'

import { getLife365ProgramName } from '@tabeeb/modules/healthData/helper'

import useStyles from './styles'

const HealthDevice = ({ healthDevice, openAddEditDeviceModal, onClearTabletSubmit, assignedLife365DeviceStatus }) => {
  const classes = useStyles()
  return (
    <Grid item container direction='column' spacing={1}>
      <Grid item container wrap='nowrap' direction='row' spacing={1}>
        <Grid item xs={5}>
          <TextField
            disabled
            fullWidth
            name='ExternalId'
            label='Added device serial number'
            color='primary'
            variant='outlined'
            value={healthDevice?.ExternalId ?? ''}
          />
        </Grid>
        {healthDevice?.VendorType === DeviceVendorType.Life365 ? (
          <Grid item xs={5}>
            <TextField
              disabled
              fullWidth
              name='Program'
              label='Device program'
              color='primary'
              variant='outlined'
              value={healthDevice?.ProgramId ? getLife365ProgramName(healthDevice?.ProgramId) : ''}
            />
          </Grid>
        ) : (
          <Grid item xs={5}>
            <TextField
              disabled
              fullWidth
              name='Name'
              label='Device name'
              color='primary'
              variant='outlined'
              value={healthDevice?.Name ?? ''}
            />
          </Grid>
        )}
        <Grid item xs={1}>
          <IconButton onClick={() => openAddEditDeviceModal(healthDevice)}>
            <Edit />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => onClearTabletSubmit(healthDevice.Id)}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
      {healthDevice?.VendorType === DeviceVendorType.Life365 && (
        <Grid item>
          <div className={classes.assignedLife365DeviceStatus}>
            <Typography variant='body1' style={{ marginRight: 5 }}>
              Assignment status:
            </Typography>
            {assignedLife365DeviceStatus?.TabletId ? (
              <>
                <Typography variant='body1' style={{ marginRight: 10 }}>
                  successfully assigned.
                </Typography>
                <CloudDone />
              </>
            ) : (
              <>
                <Typography variant='body1' style={{ marginRight: 10 }}>
                  assigning to your life 365 device.
                </Typography>
                <CircularProgress size={20} />
              </>
            )}
          </div>
        </Grid>
      )}
    </Grid>
  )
}

HealthDevice.propTypes = {
  healthDevice: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    VendorType: PropTypes.number.isRequired,
    ExternalId: PropTypes.string.isRequired,
    IsDisabled: PropTypes.bool.isRequired,
    UserId: PropTypes.string.isRequired,
    ProgramId: PropTypes.string,
    TenoviSensorCodeType: PropTypes.number,
  }).isRequired,
  openAddEditDeviceModal: PropTypes.func.isRequired,
  onClearTabletSubmit: PropTypes.func.isRequired,
  assignedLife365DeviceStatus: PropTypes.shape({
    TabletId: PropTypes.string,
    BatteryLife: PropTypes.number,
    OnCharger: PropTypes.bool,
    DeviceTime: PropTypes.instanceOf(Date),
  }),
}

export default HealthDevice
