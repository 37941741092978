import PropTypes from 'prop-types'
import { withStyles, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { contentMetadataActions } from '@tabeeb/modules/contentMetadata'
import { contentStateSelectors } from '@tabeeb/modules/shared/content'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { SessionPermission } from '@tabeeb/enums'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { getIsDialogOpen } from '../../selectors'

import styles from './styles'

const MetadataButton = ({ classes }) => {
  const dispatch = useDispatch()
  const isMetadataOpen = useSelector(getIsDialogOpen)

  const handleClick = () => {
    dispatch(contentMetadataActions.resetContentMetadataTabNames())
    dispatch(contentMetadataActions.resetContentMetadata())

    const action = isMetadataOpen
      ? contentMetadataActions.closeContentMetadataDialog
      : contentMetadataActions.openContentMetadataDialog
    dispatch(action())
  }

  const disabled = !useSelector(contentStateSelectors.getContentId)
  const isContentMetadataEnabled = useSelector(appConfigStateSelectors.getIsContentMetadataEnabled)
  const buttonLabel = useSelector(appConfigStateSelectors.getContentMetadataButtonLabel)
  const hasPermission = useSelector((state) =>
    permissionsSelectors.hasSessionPermission(state, SessionPermission.GetContentMetadata)
  )

  return isContentMetadataEnabled && hasPermission ? (
    <Button
      className={classes.metadataButton}
      onClick={handleClick}
      variant='contained'
      color='primary'
      disabled={disabled}
    >
      {buttonLabel}
    </Button>
  ) : null
}

MetadataButton.propTypes = {
  classes: PropTypes.shape({
    metadataButton: PropTypes.string.isRequired,
  }),
}

export default withStyles(styles)(MetadataButton)
