import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      transition: 'border 250ms ease',
      borderColor: 'transparent',
      borderRadius: '100%',
      borderStyle: 'solid',
      borderWidth: 2,
    },
    selected: {
      borderColor: theme.palette.primary.main,
    },
    badge: {
      cursor: 'pointer',

      '& .MuiBadge-badge': {
        top: theme.spacing(0.75),
      },
    },
  }
})
