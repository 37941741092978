export default (theme) => {
  return {
    container: {
      position: 'absolute',
      bottom: theme.spacing(),
      right: theme.spacing(),
    },
    paper: {
      background: 'rgba(28, 28, 28, 0.4)',
    },
  }
}
