import PropTypes from 'prop-types'

import './styles.less'

import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
import ShareSessionByFormTimelineItem from '../ShareSessionByFormTimelineItem'

const ShareSessionByFormTimelineList = ({ timeLineList, handleDeleteItem, contentForms, isForReview }) => {
  return (
    <div className='timeline-list'>
      {Object.keys(timeLineList).map((key, index) => {
        const contentFormId = parseInt(key, 10)
        const contentForm = contentForms.find((form) => form.Id === contentFormId)
        const formName = contentForm ? contentForm.Name : ''
        const formPages = timeLineList[key]

        return (
          <div className={contentForm ? 'timeline-list-form-group' : 'no-form'} key={index}>
            <div className='timeline-list-form-group-contents'>
              {formPages.map((item, index) => (
                <ShareSessionByFormTimelineItem
                  key={index}
                  item={item}
                  handleDeleteItem={handleDeleteItem}
                  isForReview={isForReview}
                />
              ))}
            </div>
            {contentForm && (
              <div className='timeline-list-form-group-form-name'>
                <span className='timeline-list-form-group-form-name-icon'>
                  <FeaturedPlayListIcon color='action' fontSize='inherit' />
                </span>
                <span className='timeline-list-form-group-form-name-title'>{formName}</span>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

ShareSessionByFormTimelineList.propTypes = {
  timeLineList: PropTypes.object.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  contentForms: PropTypes.array.isRequired,
  isForReview: PropTypes.bool,
}

export default ShareSessionByFormTimelineList
