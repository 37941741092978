import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as rawActions from '../actions'

import PdfSearchResults from '../components/PdfSearchResults'

class PdfSearchResultsContainer extends Component {
  componentDidMount() {
    const { searchResults } = this.props
    this._countMatches(searchResults.matches)
  }

  _setSelectedPdfSearchText = (event) => {
    const pageIndex = parseFloat(event.target.getAttribute('data-page-index'))
    const matchIndex = parseFloat(event.target.getAttribute('data-match-index'))

    const {
      actions: { setSelectedPdfSearchText, setSelectedSearchResult },
    } = this.props

    setSelectedPdfSearchText({ pageIndex, matchIndex })
    setSelectedSearchResult({ pageIndex, matchIndex })
  }

  _countMatches = (matches) => {
    let count = 0

    matches &&
      matches.map((pageMatches) => {
        if (pageMatches.length > 0) count += pageMatches.length
      })

    const {
      actions: { setPdfSearchMatchesCount },
    } = this.props
    setPdfSearchMatchesCount(count)
  }

  _togglePageResults = (index) => {
    const {
      disabledPages,
      actions: { removePageIndex, addPageIndex },
    } = this.props
    const pageIndex = disabledPages.find((item) => item === index)

    if (pageIndex) removePageIndex(index)
    else addPageIndex(index)
  }

  render() {
    const { searchResults, selectedSearchResult, disabledPages } = this.props

    const props = {
      searchResults,
      handleSetPdfSearchText: this._setSelectedPdfSearchText,
      selectedSearchResult,
      togglePageResults: this._togglePageResults,
      disabledPages,
    }
    return <PdfSearchResults {...props} />
  }
}

PdfSearchResultsContainer.propTypes = {
  searchResults: PropTypes.object.isRequired,
  selectedSearchResult: PropTypes.object.isRequired,
  disabledPages: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    setSelectedPdfSearchText: PropTypes.func.isRequired,
    setSelectedSearchResult: PropTypes.func.isRequired,
    setPdfSearchMatchesCount: PropTypes.func.isRequired,
    removePageIndex: PropTypes.func.isRequired,
    addPageIndex: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    searchResults: state.gallery.pdfSearch.pdfSearchResult,
    selectedSearchResult: state.gallery.pdfSearch.selectedPdfSearchResult,
    disabledPages: state.gallery.pdfSearch.disabledSearchResultsPages,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PdfSearchResultsContainer)
