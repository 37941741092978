import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { NavigationLinkTypography } from './styles'

const NavigationLink = ({ href, name, selected }) => {
  return (
    <Link to={href} style={{ color: 'inherit' }}>
      <NavigationLinkTypography data-selected={selected} noWrap>
        {name}
      </NavigationLinkTypography>
    </Link>
  )
}

NavigationLink.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default NavigationLink
