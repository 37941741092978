import { memo } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import { IconButton } from '@material-ui/core'

import useStyles from './styles'

const Day = ({ date, selected, today, outside, onClick }) => {
  const classes = useStyles()

  return (
    <IconButton
      className={classNames(classes.date, {
        [classes.outside]: outside,
        [classes.selected]: !outside && selected,
        [classes.today]: !outside && today,
      })}
      size='small'
      onClick={() => onClick(date)}
    >
      {date.getDate()}
    </IconButton>
  )
}

Day.defaultProps = {
  outside: false,
}

Day.propTypes = {
  date: PropTypes.shape({
    getDate: PropTypes.func.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  today: PropTypes.bool.isRequired,
  outside: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(Day)
