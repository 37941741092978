import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }
})
