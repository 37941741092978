import { handleActions, combineActions } from 'redux-actions'

import { updateRecordingState, onRecordingStarted, onRecordingPaused, resetRecordingState } from '../actions'

const defaultValue = null

export default handleActions(
  {
    [combineActions(updateRecordingState, onRecordingStarted, onRecordingPaused)](state, { payload }) {
      return payload?.session ? payload.session.toString() : state
    },
    [resetRecordingState]() {
      return defaultValue
    },
  },
  defaultValue
)
