import qs from 'qs'

const usersRequests = {
  kickUserRequest: (data) => ({
    url: `api/contents/removeUser`,
    method: 'post',
    data,
  }),
  searchUserRequest: ({ contentId, searchText, roles }) => ({
    url: `api/users`,
    method: 'get',
    params: {
      search: searchText,
      contentId,
      roles,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }),
  getUsersByIdsRequest: ({ userIds }) => ({
    url: `api/users/get-by-ids`,
    method: 'post',
    data: {
      userIds,
    },
  }),
  getUsersByIdentityGuidsRequest: ({ identityGuids }) => ({
    url: `api/users/by-identity-guids`,
    method: 'get',
    params: { identityGuids },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  getUsersByEmailsOrPhoneNumbersRequest: (data) => ({
    url: `api/users/get-by-emails-or-phone-numbers`,
    method: 'post',
    data,
  }),
  getUserByIdRequest: ({ userId }) => ({
    url: `api/users/getuserbyid/${userId}`,
    method: 'get',
  }),
  getUserByGuidRequest: ({ userGuid }) => ({
    url: `api/users/getuserbyGuid/${userGuid}`,
    method: 'get',
  }),
  switchContentUserRoleRequest: (data) => ({
    url: `api/contents/user/role`,
    method: 'post',
    data,
  }),
  searchUsersBaseRequest: (data) => ({
    url: `api/users`,
    method: 'get',
    params: data,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }),
  updateUserSignatureRequest: (data) => ({
    url: `api/users/signature`,
    method: 'put',
    data,
  }),
}

export default usersRequests
