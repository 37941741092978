import { combineReducers } from 'redux'

import searchText from './searchText'
import formsList from './formsList'
import isListLoading from './isListLoading'
import publishDialogState from './publishDialogState'
import foldersList from './foldersList'
import currentFolder from './currentFolder'
import cachedFolder from './cachedFolder'

export default combineReducers({
  searchText,
  formsList,
  isListLoading,
  publishDialogState,
  foldersList,
  currentFolder,
  cachedFolder,
})
