import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ListItemText, Menu, MenuItem as MuiMenuItem } from '@mui/material'

import {
  CloudOff,
  CloudUpload,
  Delete,
  DirectionsRun,
  FileCopy,
  Folder,
  KeyboardArrowRight,
  RestoreFromTrash,
  Share,
} from '@mui/icons-material'

import { openSessionsPageDialog } from '@tabeeb/modules/sessionsPage/actions'
import {
  getCanBulkCopySession,
  getCanCopySession,
  getCanDeleteSession,
  getCanLeaveSession,
  getCanMoveSessionToFolders,
  getCanPublishSession,
  getCanRestoreSession,
  getCanShareSession,
} from '@tabeeb/modules/sessionsPage/selectors/sessionActions'

import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'
import MoveToFolderMenu from '@tabeeb/modules/sessionsPage/components/Menus/MoveToFolderMenu'

import { DialogTypes } from '@tabeeb/modules/sessionsPage/enums'

import MenuItem from '../MenuItem'

const SessionMenu = ({ anchorEl, session, open, onClose }) => {
  const dispatch = useDispatch()

  const [folderMenuAnchorRef, folderMenuOpen, onFolderMenuOpen, onFolderMenuClose] = usePopoverState()

  const canBulkCopy = useSelector((state) => getCanBulkCopySession(state, session))
  const canCopy = useSelector((state) => getCanCopySession(state, session))
  const canDelete = useSelector((state) => getCanDeleteSession(state, session))
  const canRestore = useSelector((state) => getCanRestoreSession(state, session))
  const canLeave = useSelector((state) => getCanLeaveSession(state, session))
  const canMove = useSelector((state) => getCanMoveSessionToFolders(state, session))
  const canPublish = useSelector((state) => getCanPublishSession(state, session))
  const canShare = useSelector((state) => getCanShareSession(state, session))

  const onOpenDialog = useCallback(
    (type) => () => {
      dispatch(openSessionsPageDialog({ dialogType: type, dialogPayload: { session } }))
      onClose()
    },
    [dispatch, onClose, session]
  )

  const onCloseFolderMenu = useCallback(() => {
    onFolderMenuClose()
    onClose()
  }, [onClose, onFolderMenuClose])

  const options = useMemo(() => {
    const availableOptions = []

    if (canRestore) {
      availableOptions.push(
        <MenuItem
          key='restore'
          icon={RestoreFromTrash}
          title='Restore'
          onClick={onOpenDialog(DialogTypes.RestoreSession)}
        />
      )
    }

    if (canMove) {
      availableOptions.push(
        <MenuItem
          key='move-to'
          ref={folderMenuAnchorRef}
          icon={Folder}
          title='Move to'
          endIcon={KeyboardArrowRight}
          onClick={onFolderMenuOpen}
        />
      )
    }

    if (canPublish) {
      availableOptions.push(
        <MenuItem
          key='publish'
          icon={CloudUpload}
          onClick={onOpenDialog(DialogTypes.PublishSession)}
          title={session.IsPublished ? 'Re-publish' : 'Publish'}
        />
      )
    }

    if (canPublish && session.IsPublished) {
      availableOptions.push(
        <MenuItem
          key='unpublish'
          icon={CloudOff}
          title='Unpublish'
          onClick={onOpenDialog(DialogTypes.UnpublishSession)}
        />
      )
    }

    if (canShare) {
      availableOptions.push(
        <MenuItem key='share' icon={Share} title='Share' onClick={onOpenDialog(DialogTypes.ShareSession)} />
      )
    }

    if (canBulkCopy) {
      availableOptions.push(
        <MenuItem
          key='bulk-copy'
          icon={FileCopy}
          title='Copy to all users'
          onClick={onOpenDialog(DialogTypes.BulkCopy)}
        />
      )
    }

    if (canCopy) {
      availableOptions.push(
        <MenuItem key='copy' icon={FileCopy} title='Copy' onClick={onOpenDialog(DialogTypes.CopySession)} />
      )
    }

    if (canDelete) {
      availableOptions.push(
        <MenuItem
          key='delete'
          icon={Delete}
          title='Delete'
          onClick={onOpenDialog(session.IsDeleted ? DialogTypes.DeleteSessionPermanently : DialogTypes.DeleteSession)}
        />
      )
    }

    if (canLeave) {
      availableOptions.push(
        <MenuItem key='leave' icon={DirectionsRun} title='Leave' onClick={onOpenDialog(DialogTypes.LeaveSession)} />
      )
    }

    return availableOptions
  }, [
    canBulkCopy,
    canCopy,
    canDelete,
    canLeave,
    canMove,
    canPublish,
    canRestore,
    canShare,
    folderMenuAnchorRef,
    onFolderMenuOpen,
    onOpenDialog,
    session.IsDeleted,
    session.IsPublished,
  ])

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {options}

        {options.length === 0 && (
          <MuiMenuItem disabled>
            <ListItemText primary='No options available' />
          </MuiMenuItem>
        )}
      </Menu>

      <MoveToFolderMenu
        anchorEl={folderMenuAnchorRef.current}
        open={folderMenuOpen}
        session={session}
        onClose={onCloseFolderMenu}
      />
    </>
  )
}

SessionMenu.propTypes = {
  session: PropTypes.shape({
    IsDeleted: PropTypes.bool.isRequired,
    IsPublished: PropTypes.bool.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
}

export default memo(SessionMenu)
