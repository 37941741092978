import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Field, useFormikContext } from 'formik'
import { Grid, InputAdornment } from '@material-ui/core'

import { FormikTextField } from '@tabeeb/shared/forms'
import { SystemOfMeasures, ProfileFieldLabels } from '@tabeeb/enums'

import { maxInchesInFoot } from '@tabeeb/modules/healthData/constants'
import {
  getCmFromFtAndIn,
  getFootsFromCm,
  getFractionalPartOfFootsInInchesFromCm,
} from '@tabeeb/modules/healthData/helper/measurementConversion'

const RegionalHeight = ({ minMaxValues, ...props }) => {
  const {
    values: { SystemOfMeasure, HeightFt, HeightIn },
    setFieldValue,
  } = useFormikContext()

  const handleSetValue = useCallback(
    (e) => {
      const { name, value } = e.target
      setFieldValue(name, value ?? 0)

      if (name === ProfileFieldLabels.HeightCm) {
        let foots = getFootsFromCm(value ?? 0)
        let inches = getFractionalPartOfFootsInInchesFromCm(value ?? 0).toFixed(0)
        if (Number(inches) === maxInchesInFoot) {
          foots += 1
          inches = 0
        }
        setFieldValue(ProfileFieldLabels.HeightFt, foots)
        setFieldValue(ProfileFieldLabels.HeightIn, inches)
      } else if (name === ProfileFieldLabels.HeightFt) {
        const cm = getCmFromFtAndIn(value, HeightIn)
        setFieldValue(ProfileFieldLabels.HeightCm, cm ?? 0)
      } else if (name === ProfileFieldLabels.HeightIn) {
        const cm = getCmFromFtAndIn(HeightFt, value)
        setFieldValue(ProfileFieldLabels.HeightCm, cm ?? 0)
      }
    },
    [HeightFt, HeightIn, setFieldValue]
  )

  return (
    <Grid container wrap='nowrap' direction='row' spacing={1}>
      {Number(SystemOfMeasure) === SystemOfMeasures.Metric ? (
        <Grid item xs={12}>
          <Field
            name='HeightCm'
            label='Height'
            onChange={handleSetValue}
            type='number'
            InputProps={{ endAdornment: <InputAdornment position='end'>cm.</InputAdornment> }}
            inputProps={{ min: minMaxValues.HeightCm.min, max: minMaxValues.HeightCm.max, step: '0.1' }}
            component={FormikTextField}
            {...props}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={6}>
            <Field
              name='HeightFt'
              label='Height ft.'
              onChange={handleSetValue}
              type='number'
              InputProps={{ endAdornment: <InputAdornment position='end'>ft.</InputAdornment> }}
              inputProps={{ min: minMaxValues.HeightFt.min, max: minMaxValues.HeightFt.max, step: '0.1' }}
              component={FormikTextField}
              {...props}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name='HeightIn'
              label='Height in.'
              onChange={handleSetValue}
              type='number'
              InputProps={{ endAdornment: <InputAdornment position='end'>in.</InputAdornment> }}
              inputProps={{ min: minMaxValues.HeightIn.min, max: minMaxValues.HeightIn.max, step: '0.1' }}
              component={FormikTextField}
              {...props}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

RegionalHeight.propTypes = {
  minMaxValues: PropTypes.shape({
    HeightCm: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }),
    HeightFt: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }),
    HeightIn: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }),
  }),
}

export default memo(RegionalHeight)
