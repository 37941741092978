import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [rawActions.getAllFormFoldersSuccess](state, action) {
      return action.response?.data
    },
    [rawActions.addFormFolder](state, action) {
      const newFolder = {
        FolderId: action.payload.FolderId,
        Name: action.payload.Name,
        UserId: action.payload.UserId,
        CanBeDeleted: true,
        PublishedFolderTenants: [],
        TenantId: action.payload.TenantId,
        TenantName: action.payload.TenantName,
      }
      return [newFolder, ...state]
    },
    [rawActions.deleteFormFolder](state, action) {
      const folderId = action.payload
      const index = state.findIndex((folder) => folder.FolderId === folderId)
      return [...state.slice(0, index), ...state.slice(index + 1)]
    },
    [rawActions.switchFormFolderSuccess](state, action) {
      const { toFolderId, formAnswersCount, isOwner } = action.payload

      return state.map((folder) => {
        if (folder.FolderId === toFolderId) {
          if (formAnswersCount > 0 || !isOwner) {
            return { ...folder, CanBeDeleted: false }
          }
        }
        return folder
      })
    },
    [rawActions.publishFormFolderSuccess](state, { payload: { id, selectedTenants } }) {
      return state.map((folder) =>
        folder.FolderId === id
          ? { ...folder, PublishedFolderTenants: [...folder.PublishedFolderTenants, ...selectedTenants] }
          : folder
      )
    },
    [rawActions.unpublishFormFolderSuccess](state, { payload: { id, tenantsIds } }) {
      return state.map((folder) =>
        folder.FolderId === id
          ? {
              ...folder,
              PublishedFolderTenants: folder.PublishedFolderTenants.filter(
                (pt) => !tenantsIds.some((id) => id === pt.Id)
              ),
            }
          : folder
      )
    },
    [rawActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
