import { memo, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RecordingStatus, RecordingType } from '@tabeeb/enums'

import { FiberManualRecord } from '@material-ui/icons'
import { presentationSelectors } from '@tabeeb/modules/presentation'
import { recordingActions, recordingSelectors } from '@tabeeb/modules/recording'
import { onSetRecordingType, openPickRecordingTypeDialog } from '@tabeeb/modules/whiteboard/actions'
import { getRecordingTypeDialogResult } from '@tabeeb/modules/whiteboard/selectors'

import { getIsCloudRecordingEnabled, getIsMultipleRecordingsAvailable } from '@tabeeb/modules/appConfigState/selectors'
import ToolbarButton from '../ToolbarButton'

const StartRecordingButton = () => {
  const recordingType = useSelector(getRecordingTypeDialogResult)
  const isMultipleRecordingsAvailable = useSelector(getIsMultipleRecordingsAvailable)
  const isCloudRecordingEnabled = useSelector(getIsCloudRecordingEnabled)
  const dispatch = useDispatch()

  const recordingStatus = useSelector(recordingSelectors.getRecordingStatus)
  const selectedUser = useSelector(presentationSelectors.getSelectedUser)
  const recordingModel = useSelector((state) => ({
    contentId: state.contentState.contentId,
    serverName: state.recording.serverName,
    recordId: state.recording.recordId,
    selectedUserId: state.recording.selectedUserId,
  }))

  const onClick = () => {
    const startRecording = (newRecordingType) => {
      dispatch(onSetRecordingType(newRecordingType))
      dispatch(recordingActions.onRecordingStarting({ userId: selectedUser.id, recordingType: newRecordingType }))
    }

    if (recordingStatus === RecordingStatus.stopped && selectedUser?.id) {
      if (isMultipleRecordingsAvailable) {
        dispatch(openPickRecordingTypeDialog())
      } else if (isCloudRecordingEnabled) {
        startRecording(RecordingType.cloud)
      } else {
        startRecording(RecordingType.local)
      }
    } else if (recordingStatus === RecordingStatus.paused) {
      if (recordingModel.selectedUserId !== selectedUser.id) {
        dispatch(recordingActions.openSwitchRecordedUserConfirmationDialog())
      } else {
        dispatch(recordingActions.onRecordingContinuing(recordingModel))
      }
    }
  }

  const isDisabled = useMemo(() => {
    switch (recordingType) {
      case RecordingType.cloud:
        return ![RecordingStatus.stopped, RecordingStatus.paused].includes(recordingStatus)
      case RecordingType.local:
      default:
        return false
    }
  }, [recordingStatus, recordingType])

  return (
    <ToolbarButton
      color='error'
      Icon={FiberManualRecord}
      display={![RecordingStatus.started, RecordingStatus.starting].includes(recordingStatus)}
      disabled={isDisabled}
      title='Start recording'
      onClick={onClick}
    />
  )
}

export default memo(StartRecordingButton)
