import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { closeAlertOwnerModal } from './actions'
import { acceptNotificationRequest, rejectNotificationRequest } from '../notificationsPage/actions'
import { getIsOpen, getNotification } from './selectors'
import AlertOwnerModal from './components/alertOwnerModal'

const AlertOwnerModalContainer = (props) => {
  const { isOpen, notification } = props

  const handleClickYes = () => {
    const { acceptNotificationRequest, closeAlertOwnerModal } = props

    acceptNotificationRequest(notification)
    closeAlertOwnerModal()
  }

  const handleClickNo = () => {
    const { rejectNotificationRequest, closeAlertOwnerModal } = props

    rejectNotificationRequest(notification)
    closeAlertOwnerModal()
  }

  const componentProps = {
    handleClickYes,
    handleClickNo,
    description: notification.Description,
    isOpen,
  }

  return <AlertOwnerModal {...componentProps} />
}

AlertOwnerModalContainer.propTypes = {
  notification: PropTypes.shape({
    Description: PropTypes.string.isRequired,
  }),
  acceptNotificationRequest: PropTypes.func.isRequired,
  rejectNotificationRequest: PropTypes.func.isRequired,
  closeAlertOwnerModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isAlertOwnerAvailable: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    isOpen: getIsOpen(state),
    notification: getNotification(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeAlertOwnerModal: (notification) => dispatch(closeAlertOwnerModal(notification)),
  acceptNotificationRequest: (notification) => dispatch(acceptNotificationRequest(notification)),
  rejectNotificationRequest: (notification) => dispatch(rejectNotificationRequest(notification)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertOwnerModalContainer)
