import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  ListItem,
  Paper,
  ListItemText,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import { CheckCircle, Error } from '@mui/icons-material'
import PropTypes from 'prop-types'
import moment from 'moment/moment'

import { UserInfoSkeleton, UserInfo } from '@tabeeb/uikit'
import { CertificateScopeTypeDisplayName, UserAssignedQualificationStatusType } from '@tabeeb/enums'
import { Title } from '@tabeeb/modules/contractors/components/ContractVersionDetailsSection/styles'
import UserQualificationChip from '../UserQualificationChip'
import { userDeletedDisplayText } from '@tabeeb/modules/certificates/constants'

const ViewUserQualificationDialog = ({
  open,
  onClose,
  onExited,
  selectedQualification,
  getApprovedCertificateTypeIdsByUserGuidResponse,
  getAllCertificateTypeIdsByUserGuidLoading,
  assignedUser,
  assignerUser,
  loadingGetUsers,
}) => {
  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited }} fullWidth maxWidth='sm'>
      <DialogTitle>User qualification</DialogTitle>
      <DialogContent>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={12}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Box sx={{ overflow: 'hidden' }}>
                <Title title='Qualification version' />
                <Tooltip title={selectedQualification?.QualificationVersionName}>
                  <Typography variant='body2' fontWeight={500} mt={0.5} noWrap>
                    {selectedQualification?.QualificationVersionName}
                  </Typography>
                </Tooltip>
              </Box>
              <UserQualificationChip statusId={selectedQualification?.StatusId} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Title title='Created on' />
            <Typography variant='body2' fontWeight={500} mt={0.5} noWrap>
              {moment(selectedQualification?.CreatedOn, 'YYYY-MM-DDTHH:mm:ss.SSSSSS').format('D MMMM YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Title title='Version expires' />
            <Typography variant='body2' fontWeight={500} mt={0.5} noWrap>
              {selectedQualification?.QualificationVersionExpirationDate
                ? moment(
                    selectedQualification?.QualificationVersionExpirationDate,
                    'YYYY-MM-DDTHH:mm:ss.SSSSSS'
                  ).format('D MMMM YYYY')
                : 'Unlimited'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Title title='Assigned by' />
            {loadingGetUsers ? (
              <UserInfoSkeleton />
            ) : (
              <UserInfo item={assignerUser ?? { Email: userDeletedDisplayText }} />
            )}
          </Grid>
          <Grid item xs={6}>
            <Title title='Assigned to' />
            {loadingGetUsers ? (
              <UserInfoSkeleton />
            ) : (
              <UserInfo item={assignedUser ?? { Email: userDeletedDisplayText }} />
            )}
          </Grid>
          <Grid item xs={12} container direction='row' spacing={2}>
            {selectedQualification?.QualificationVersionCertificateTypes?.length > 0 &&
              selectedQualification.QualificationVersionCertificateTypes.map((certificateType) => (
                <Grid item xs={6} key={certificateType.Id}>
                  <ListItem sx={{ width: 'unset' }} alignItems='flex-start' component={Paper} variant='outlined'>
                    <ListItemText
                      disableTypography
                      primary={
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                          <Tooltip title={certificateType.Name}>
                            <Typography
                              sx={{ textOverflow: 'ellipsis', whiteSpace: 'noWrap', overflow: 'hidden' }}
                              variant='body2'
                              display='block'
                            >
                              {certificateType.Name}
                            </Typography>
                          </Tooltip>
                          <Box display='flex' ml={0.3}>
                            {getAllCertificateTypeIdsByUserGuidLoading ? (
                              <CircularProgress size={15} />
                            ) : getApprovedCertificateTypeIdsByUserGuidResponse.includes(certificateType.Id) ? (
                              <CheckCircle color='success' fontSize='inherit' />
                            ) : (
                              <Error color='error' fontSize='inherit' />
                            )}
                          </Box>
                        </Box>
                      }
                      secondary={
                        <>
                          <Typography variant='caption' display='block'>
                            Category: {certificateType.CategoryName}
                          </Typography>
                          <Typography variant='caption' display='block'>
                            Scope: {CertificateScopeTypeDisplayName[certificateType.ScopeId]}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ViewUserQualificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func,
  selectedQualification: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    AssignedToUserId: PropTypes.string.isRequired,
    AssignerId: PropTypes.string.isRequired,
    StatusId: PropTypes.oneOf(Object.values(UserAssignedQualificationStatusType)).isRequired,
    QualificationVersionId: PropTypes.number.isRequired,
    QualificationVersionName: PropTypes.string.isRequired,
    QualificationVersionExpirationDate: PropTypes.string.isRequired,
    QualificationVersionCertificateTypes: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.number.isRequired,
        IsDeleted: PropTypes.bool.isRequired,
        Name: PropTypes.string.isRequired,
        CategoryId: PropTypes.number.isRequired,
        CategoryName: PropTypes.string.isRequired,
        ScopeId: PropTypes.number.isRequired,
      })
    ),
  }),
  getApprovedCertificateTypeIdsByUserGuidResponse: PropTypes.arrayOf(PropTypes.number),
  getAllCertificateTypeIdsByUserGuidLoading: PropTypes.bool,
  assignedUser: PropTypes.shape({
    Email: PropTypes.string,
    Name: PropTypes.string,
    AvatarUrl: PropTypes.string,
  }),
  assignerUser: PropTypes.shape({
    Email: PropTypes.string,
    Name: PropTypes.string,
    AvatarUrl: PropTypes.string,
  }),
  loadingGetUsers: PropTypes.bool.isRequired,
}

export default ViewUserQualificationDialog
