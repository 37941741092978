import { combineActions, handleActions } from 'redux-actions'
import {
  resetState,
  openEditUniqueAiObjectConnectionsDialog,
  closeEditUniqueAiObjectConnectionsDialog,
} from '../../actions'

const defaultState = null

export default handleActions(
  {
    [openEditUniqueAiObjectConnectionsDialog](state, { payload }) {
      return payload
    },
    [combineActions(closeEditUniqueAiObjectConnectionsDialog, resetState)](state, { response }) {
      return defaultState
    },
  },
  defaultState
)
