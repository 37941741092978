import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import classNames from 'classnames'

import { SessionPermission } from '@tabeeb/enums'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import ControlsPanelButton from '../ControlsPanelButton'

import './styles.less'
import AddButtonMenu from '../AddButtonMenu'
import ExportButtonMenu from '../ExportButtonMenu'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import * as gallerySelectors from '../../selectors'

const ControlsPanelButtons = ({
  isGalleryExpanded,
  isAddButtonVisible,
  isExportButtonVisible,
  exportButtonBackgroundColor,
  addButtonBackgroundColor,
}) => {
  return (
    <div className={classNames('gallery-controls-panel-buttons', { expanded: isGalleryExpanded })}>
      <ControlsPanelButton
        button='add'
        backgroundColor={addButtonBackgroundColor}
        isVisible={isAddButtonVisible}
        menu={<AddButtonMenu />}
      />
      <ControlsPanelButton
        button='export'
        backgroundColor={exportButtonBackgroundColor}
        isVisible={isExportButtonVisible}
        menu={<ExportButtonMenu />}
      />
    </div>
  )
}

ControlsPanelButtons.propTypes = {
  isGalleryExpanded: PropTypes.bool.isRequired,
  isAddButtonVisible: PropTypes.bool.isRequired,
  isExportButtonVisible: PropTypes.bool.isRequired,
  exportButtonBackgroundColor: PropTypes.string,
  addButtonBackgroundColor: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    addButtonBackgroundColor: appConfigStateSelectors.getAddButtonColor(state),
    exportButtonBackgroundColor: appConfigStateSelectors.getExportButtonColor(state),
    isAddButtonVisible: gallerySelectors.getCanAddGalleryContent(state),
    isExportButtonVisible: permissionsSelectors.hasSessionPermission(state, SessionPermission.ExportGalleryContent),
    isGalleryExpanded: state.gallery.isGalleryExpanded,
  }
}

export default connect(mapStateToProps, null)(ControlsPanelButtons)
