import { combineReducers } from 'redux'

import navigation from './navigation'
import rotation from './rotation'
import selectedModel from './selectedModel'
import settings from './settings'
import transformation from './transformation'
import viewer from './viewer'

export default combineReducers({
  navigation,
  rotation,
  selectedModel,
  settings,
  transformation,
  viewer,
})
