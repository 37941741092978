import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TabPanel } from '@tabeeb/enums'

import { updateTimelineList, deleteTimelineItem, closeTimeline, openRecipientsDialog } from '../actions'
import {
  getIsCustomVideoTimelineOpen,
  getIsProcessing,
  getNavbarCurrentBottomPanel,
  getTimelineList,
} from '../selectors'

import Timeline from '../../timeline'

const TimelineContainer = () => {
  const dispatch = useDispatch()

  const isTimelineOpen = useSelector(getIsCustomVideoTimelineOpen)
  const isProcessing = useSelector(getIsProcessing)
  const currentPanel = useSelector(getNavbarCurrentBottomPanel)
  const timelineList = useSelector(getTimelineList)

  const handleSubmit = useCallback(() => {
    if (isProcessing) return
    dispatch(openRecipientsDialog())
  }, [dispatch, isProcessing])

  const handleClose = useCallback(() => {
    dispatch(closeTimeline())
  }, [dispatch])

  const handleDelete = useCallback(
    (itemIndex) => {
      dispatch(deleteTimelineItem(itemIndex))
    },
    [dispatch]
  )

  const handleUpdate = useCallback(
    (newList) => {
      dispatch(updateTimelineList(newList))
    },
    [dispatch]
  )

  const props = {
    actions: [
      {
        onClick: handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: handleSubmit,
        title: 'Done',
        class: 'submit',
      },
    ],
    timeLineList: timelineList,
    handleDeleteItem: handleDelete,
    handleUpdateList: handleUpdate,
    isOpen: isTimelineOpen,
    isSubmitted: isProcessing,
    isSelected: currentPanel === TabPanel.CustomVideo,
  }

  return <Timeline {...props} />
}

export default TimelineContainer
