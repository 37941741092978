import { put, takeLatest, select, all } from 'redux-saga/effects'

import { contentStateSelectors, rawContentActions } from '@tabeeb/shared/content'
import { requiredTowerSideProfileFiles } from '@tabeeb/shared/utils/validationErrorMessages'
import { ContentType } from '@tabeeb/enums'
import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

import { getPagesList } from '../selectors'
import {
  MAXIMUM_STRUCTURE_MODELS_NUMBER_FOR_TOWER_SIDE_PROFILE,
  REQUIRED_3D_MODELS_NUMBER_FOR_TOWER_SIDE_PROFILE,
  MINIMUM_ASSETS_NUMBER_FOR_TOWER_SIDE_PROFILE,
  MAXIMUM_ASSETS_NUMBER_FOR_TOWER_SIDE_PROFILE,
} from '../constants'

function* onAddTowerSideProfile(action) {
  const pagesList = yield select(getPagesList)

  if (
    pagesList.length < MINIMUM_ASSETS_NUMBER_FOR_TOWER_SIDE_PROFILE ||
    pagesList.length > MAXIMUM_ASSETS_NUMBER_FOR_TOWER_SIDE_PROFILE
  ) {
    yield put(notificationActions.onAddErrorNotification({ message: requiredTowerSideProfileFiles }))
    return
  }

  let pointCloudModelPageId = null
  let structureModelPageId = null

  let structureModelsCount = 0
  let threeDModelsCount = 0

  pagesList.forEach((page) => {
    switch (page.contentType) {
      case ContentType.WebPage:
        threeDModelsCount++
        pointCloudModelPageId = page.id
        break
      case ContentType.StructureModel:
      case ContentType.StructureModelPdf:
        structureModelsCount++
        structureModelPageId = page.id
        break
      default:
        break
    }
  })

  if (
    structureModelsCount > MAXIMUM_STRUCTURE_MODELS_NUMBER_FOR_TOWER_SIDE_PROFILE ||
    threeDModelsCount !== REQUIRED_3D_MODELS_NUMBER_FOR_TOWER_SIDE_PROFILE
  ) {
    yield put(notificationActions.onAddErrorNotification({ message: requiredTowerSideProfileFiles }))
    return
  }

  const contentId = yield select(contentStateSelectors.getContentId)

  const model = {
    contentId,
    pointCloudModelPageId,
    structureModelPageId,
  }

  yield put(actions.addTowerSideProfileRequest(model))
}

function* onAddTowerSideProfileSuccess() {
  yield put(actions.closeTowerSideProfileDialog())
  yield put(notificationActions.onAddInfoNotification({ message: 'Data sent for Tower Side Profile creation' }))
}

function* onAddTowerSideProfileFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to create Tower Side Profile' }))
}

function* resetState() {
  yield put(actions.resetState())
}

function* addTowerSideProfile() {
  yield all([
    takeLatest(actions.addTowerSideProfile, onAddTowerSideProfile),
    takeLatest(actions.addTowerSideProfileSuccess, onAddTowerSideProfileSuccess),
    takeLatest(actions.addTowerSideProfileFailed, onAddTowerSideProfileFailed),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default addTowerSideProfile
