import { memo } from 'react'
import PropTypes from 'prop-types'

import { Star } from '@material-ui/icons'
import { Tooltip, withStyles } from '@material-ui/core'

import styles from './styles'

const UserSessionStatus = ({ classes, title }) => {
  return (
    <Tooltip arrow title={title}>
      <Star className={classes.icon} />
    </Tooltip>
  )
}

UserSessionStatus.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}

export default memo(withStyles(styles)(UserSessionStatus))
