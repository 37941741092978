import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = 'all'

export default handleActions(
  {
    [rawActions.setFilterValue](state, { payload }) {
      return payload
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
