import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'

import _ from 'lodash'
import moment from 'moment'

import Routes from '@tabeeb/routes'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'

import { VIEWS_COMPONENTS, AVAILABLE_VIEWS, DEFAULT_VIEW } from '../constants/calendarConfiguration'
import { eventsService, localizer } from '../services'

const matchSelector = createMatchSelector(Routes.calendar)

export const getMatches = (state) => matchSelector(state)

export const getSelectedDate = createSelector([getMatches], (matches) => {
  if (!matches) {
    return new Date()
  }

  const {
    params: { year, month, day },
  } = matches

  return new Date(year, month - 1, day)
})

export const getSelectedView = createSelector([getMatches], (matches) => {
  if (!matches) {
    return DEFAULT_VIEW
  }

  const {
    params: { view },
  } = matches

  if (!AVAILABLE_VIEWS.includes(view)) {
    return DEFAULT_VIEW
  }

  return view
})

export const getSelectedDateRange = createSelector([getSelectedDate, getSelectedView], (date, view) => {
  const viewComponent = VIEWS_COMPONENTS[view]

  const range = viewComponent.range(date, { localizer })
  if (Array.isArray(range)) {
    const start = range[0]
    const end = moment(_.last(range)).endOf('day').toDate()

    return { start, end }
  }

  return range
})

export const getEvents = (state) => state.calendar.events

export const getCalendarSubscriptionsList = (state) => state.calendar.subscriptions.list
export const getCalendarSubscriptionsListFetchStatus = (state) => state.calendar.subscriptions.status

export const getMyCalendarSubscriptionsList = createSelector(
  [getCalendarSubscriptionsList, getCurrentUserId],
  (calendars, currentUserId) => {
    return calendars.filter((calendar) => calendar.CalendarOwnerId === currentUserId)
  }
)

export const getUsersCalendarSubscriptionsList = createSelector(
  [getCalendarSubscriptionsList, getCurrentUserId],
  (calendars, currentUserId) => {
    return calendars.filter((calendar) => calendar.CalendarOwnerId !== currentUserId)
  }
)

export const getEnabledCalendarSubscriptionsList = createSelector([getCalendarSubscriptionsList], (calendars) => {
  return calendars.filter((calendar) => calendar.Enabled)
})

export const getUsersCalendarsIds = createSelector([getCalendarSubscriptionsList], (calendars) => {
  return calendars.map((calendar) => calendar.CalendarOwner.Id)
})

export const getEnabledUsersCalendarsIds = createSelector([getEnabledCalendarSubscriptionsList], (calendars) => {
  return calendars.map((calendar) => calendar.CalendarOwner.Id)
})

export const getFilteredEventsList = createSelector(
  [getEvents, getEnabledCalendarSubscriptionsList, getSelectedDateRange],
  (events, calendars, range) => {
    const filteredEvents = []

    for (const calendar of calendars) {
      let userCalendarEvents = events[calendar.CalendarOwner.Id] || []

      userCalendarEvents = userCalendarEvents.filter((event) => !event.deleted)

      const calendarEvents = eventsService.populateEventsForDateRange(userCalendarEvents, range).map((event) => ({
        ...event,
        color: calendar.Color,
      }))

      filteredEvents.push(...calendarEvents)
    }

    return filteredEvents
  }
)

export const getCalendarRangeSynchronizationState = (state, { calendarId, start, end }) => {
  const calendar = state.calendar.synchronization[calendarId]
  if (!calendar) {
    return { lastSynchronized: null, status: 0 }
  }

  return calendar[`${start.getTime()}-${end.getTime()}`] || { lastSynchronized: null, status: 0 }
}

export const getEventInfoDialogState = (state) => state.calendar.eventInfoDialog
export const getChangeEventOwnerDialogState = (state) => state.calendar.changeEventOwnerDialog
export const getSwitchSessionOwnerDialogState = (state) => state.calendar.switchSessionOwnerDialog
export const getEditEventDialogState = (state) => state.calendar.editEventDialog
export const getCreateEventDialogState = (state) => state.calendar.createEventDialog
export const getBookAppointmentSlotDialogState = (state) => state.calendar.bookAppointmentSlotDialog
export const getUpdateCalendarSubscriptionColorDialogState = (state) =>
  state.calendar.updateCalendarSubscriptionColorDialog
