import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  Column: 'LastActivity',
  Order: 'desc',
}

export default handleActions(
  {
    [combineActions(rawActions.setSortingColumn, rawActions.setSortingColumnWithoutReload)](state, action) {
      const newColumn = action.payload
      if (state.Column === newColumn) {
        return {
          ...state,
          Order: state.Order === 'desc' ? 'asc' : 'desc',
        }
      }
      return {
        ...state,
        Column: newColumn,
        Order: 'desc',
      }
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
