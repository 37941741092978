import { memo } from 'react'

import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { ViewList, ViewModule } from '@mui/icons-material'

import { LayoutType, LayoutTypeDisplayName } from '../../enums'
import { useLayout } from '../../hooks'

const getLayoutTypeIcon = (layout) => {
  switch (layout) {
    case LayoutType.Grid:
      return <ViewModule />
    case LayoutType.Table:
      return <ViewList />
    default:
      return null
  }
}

const LayoutSelector = () => {
  const { layout: selectedLayout, setLayout } = useLayout()

  return (
    <ToggleButtonGroup
      exclusive
      color='pagePrimaryAction'
      value={selectedLayout}
      sx={{ background: (theme) => theme.palette.background.paper }}
      onChange={(_, newLayout) => setLayout(newLayout)}
    >
      {[LayoutType.Table, LayoutType.Grid].map((layout) => (
        <Tooltip key={layout} title={LayoutTypeDisplayName[layout]}>
          <ToggleButton value={layout} selected={layout === selectedLayout}>
            {getLayoutTypeIcon(layout)}
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  )
}

export default memo(LayoutSelector)
