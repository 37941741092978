import { handleActions } from 'redux-actions'

import * as whiteboardActions from '../actions'

const defaultValue = {
  isOpen: false,
  message: '',
}

export default handleActions(
  {
    [whiteboardActions.openLeaveSessionPageDialog](state, action) {
      return {
        isOpen: true,
        message: getWarningMessage(action.payload),
      }
    },
    [whiteboardActions.closeLeaveSessionPageDialog](state, action) {
      return {
        ...state,
        isOpen: false,
      }
    },
    [whiteboardActions.resetWhiteboardState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)

function getWarningMessage({ showFileUploadsWarning, showCallWarning, showRecordingWarning }) {
  const warnings = []

  if (showCallWarning) {
    warnings.push('call')
  }

  if (showRecordingWarning) {
    warnings.push('recording')
  }

  if (showFileUploadsWarning) {
    warnings.push('file uploading')
  }

  const message =
    warnings.length === 1
      ? `Leaving the page will cancel the ${warnings[0]}. Continue?`
      : `Leaving the page will cancel the ${[warnings.slice(0, -1).join(', '), warnings.slice(-1)[0]].join(
          ' and '
        )}. Continue?`

  return message
}
