import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.openAvailableFormsForTenantDialog](state, action) {
      return true
    },
    [rawActions.closeAvailableFormsForTenantDialog](state, action) {
      return false
    },
  },
  defaultState
)
