import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { makeGetAnswerStatusCircleIconStyles } from '@tabeeb/modules/forms/selectors'
import styles from './styles'

const AnswerStatusCircleIcon = ({ classes, answerStatus }) => {
  const stylesSelector = useMemo(makeGetAnswerStatusCircleIconStyles, [])
  const style = useSelector((state) => stylesSelector(state, answerStatus))

  return <div className={classes.circleIcon} style={style} />
}

AnswerStatusCircleIcon.propTypes = {
  answerStatus: PropTypes.number,
  classes: PropTypes.shape({
    circleIcon: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(memo(AnswerStatusCircleIcon))
