import { isMobileOnly } from 'react-device-detect'
import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as rawActions from '../actions/index'
import { getContentsList } from '../selectors'
import { getPaginationPageNumber } from '../selectors/pagination'

export function* removeSessionFromList(action) {
  const { contentId, skipCallbackAction } = action.payload

  if (isMobileOnly) {
    const contentsList = yield select(getContentsList)
    const filteredContents = contentsList.filter((content) => content.Id !== contentId)

    yield put(rawActions.setSessionsList(filteredContents))

    return
  }

  if (skipCallbackAction) {
    return
  }

  const pageNumber = yield select(getPaginationPageNumber)
  const sessionsList = yield select(getContentsList)

  if (pageNumber > 1 && sessionsList.length === 1) {
    yield put(rawActions.setPageNumber(pageNumber - 1))
  } else {
    yield put(rawActions.loadSessionsList({ withNoReload: true }))
  }
}

export default function* removeSession() {
  yield all([
    takeLatest(
      [
        rawActions.deleteSession.success,
        rawActions.leaveSession.success,
        rawActions.deleteSessionPermanently.success,
        rawActions.restoreSession.success,
      ],
      removeSessionFromList
    ),
  ])
}
