import { put, takeLatest, select, all } from 'redux-saga/effects'

import { ContentSharingModelType } from '@tabeeb/enums'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

import * as rawActions from '../actions'

import * as notificationActions from '../../notification/actions'
import * as shareSessionPagesListActions from '../../shareSessionPagesList/actions'
import * as reviewSessionPagesListActions from '../../reviewSessionPagesList/actions'
import { getFormsWithStatusesList } from '../services/getFormsWithStatusesList'
import getErrorMessageFromResponse from '../../shared/utils/getErrorMessageFromResponse'

function* createContentSharing(action) {
  const { type, session, dataType } = action.payload
  const { selectedTenantForSharing, selectedOwnerForSharing, selectedTargetContentForMerging } = yield select(
    (state) => state.sessionsPage
  )

  const currentContentId = yield select((state) => state.contentState.contentId)
  const contentId = session ? session.Id : currentContentId

  yield put(rawActions.removeSessionsFromSelection([{ Id: contentId }]))

  const tenantId = selectedTenantForSharing.Id
  const isReviewMode = type === ContentSharingModelType.ForReview || type === ContentSharingModelType.MergeForReview

  const pagesList = isReviewMode
    ? yield select((state) => state.reviewSessionPagesList.pagesList)
    : yield select((state) => state.shareSessionPagesList.pagesList)

  const selectedFormsList = yield select((state) => state.shareSessionPagesList.shareByFormDialogState.selectedForms)
  const pagesIds = pagesList && pagesList.length > 0 ? pagesList.map((page) => page.id) : null
  let contentForms = null

  if (selectedFormsList) {
    contentForms = selectedFormsList.length > 0 ? getFormsWithStatusesList(selectedFormsList) : null
  }

  const targetContent =
    type === ContentSharingModelType.ContentsMerging || type === ContentSharingModelType.MergeForReview
      ? selectedTargetContentForMerging
      : null

  const userId = selectedOwnerForSharing?.Id || targetContent?.OwnerId

  const data = {
    contentId,
    userId,
    tenantId,
    pagesIds,
    contentForms,
    type,
    targetContentId: targetContent ? targetContent.Id : null,
    dataType,
  }

  yield put(rawActions.createContentSharingRequest(data))

  if (isReviewMode) yield put(reviewSessionPagesListActions.closeReviewSessionPagesDialog())
  else yield put(shareSessionPagesListActions.closeShareSessionPagesDialog())
}

function* createContentSharingSuccess(action) {
  const { type, hideNotification } = action.payload
  if (hideNotification) {
    return
  }

  let message

  switch (type) {
    case ContentSharingModelType.ForReview:
    case ContentSharingModelType.MergeForReview:
      message = 'Session is exported for review.'
      break
    case ContentSharingModelType.ContentsMerging:
      message = 'Session was shared to destination.'
      break
    case ContentSharingModelType.LinkedContentsMerging:
      message = 'Session is shared for merging.'
      break
    default:
      message = 'Session is shared for copying.'
  }

  yield put(notificationActions.onAddInfoNotification({ message: sessionTitleFormatter.format(message) }))
}

function* createContentSharingFailed({ response }) {
  const message = getErrorMessageFromResponse(response.data)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* bulkCopySessionSuccess(action) {
  const { sessionName } = action.payload
  yield put(notificationActions.onAddInfoNotification({ message: `Copying of ${sessionName} in progress.` }))
}

function* bulkCopySessionFailed(action) {
  yield put(
    notificationActions.onAddErrorNotification({ message: sessionTitleFormatter.format('Failed to copy session.') })
  )
}

function* shareSession() {
  yield all([
    takeLatest(rawActions.createContentSharing, createContentSharing),
    takeLatest(rawActions.createContentSharingSuccess, createContentSharingSuccess),
    takeLatest(rawActions.createContentSharingFailed, createContentSharingFailed),
    takeLatest(rawActions.bulkCopySession.success, bulkCopySessionSuccess),
    takeLatest(rawActions.bulkCopySession.failed, bulkCopySessionFailed),
  ])
}

export default shareSession
