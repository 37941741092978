import { combineReducers } from 'redux'

import scale from './scale'
import snippingToolEnabled from './snippingToolEnabled'
import rotatePage from './rotatePage'
import drawing from './drawing'
import hideAnnotations from './hideAnnotations'

export default combineReducers({
  scale,
  snippingToolEnabled,
  rotatePage,
  drawing,
  hideAnnotations,
})
