import PropTypes from 'prop-types'
import { Typography, Avatar as AvatarIcon, Divider, Grid } from '@material-ui/core'
import { InsertChart } from '@material-ui/icons'

import HealthStatisticsChartsSelect from '@tabeeb/modules/healthData/components/HealthStatisticsChartsSelect'
import HealthStatisticsCharts from '@tabeeb/modules/healthData/components/HealthStatisticsCharts'
import TabletsAssignationContainer from '../../containers/TabletsAssignationContainer'

import useStyles from './styles'

const HealthStatistics = ({
  userSystemOfMeasure,
  userId,
  handleAddHealthData,
  onAddHealthDataChart,
  onRemoveHealthDataChart,
  chartsHealthData,
  chartsTypesAndNames,
  setChartInterval,
}) => {
  const classes = useStyles()
  return (
    <Grid item>
      <Grid container direction='column' spacing={1}>
        <Grid item>
          <div className={classes.header}>
            <AvatarIcon className={classes.avatar}>
              <InsertChart className={classes.icon} />
            </AvatarIcon>
            <Typography variant='body1'> Health data</Typography>
          </div>
          <Divider className={classes.divider} />
          <Grid container wrap='nowrap' direction='column' spacing={1}>
            <Grid item>
              <HealthStatisticsChartsSelect
                chartsTypesAndNames={chartsTypesAndNames}
                onAddHealthDataChart={onAddHealthDataChart}
                onRemoveHealthDataChart={onRemoveHealthDataChart}
              />
            </Grid>
            <Grid item className={classes.chartsGrid}>
              <HealthStatisticsCharts
                showAddHealthDataOption
                userId={userId}
                userSystemOfMeasure={userSystemOfMeasure}
                handleAddHealthData={handleAddHealthData}
                chartsHealthData={chartsHealthData}
                setChartInterval={setChartInterval}
              />
            </Grid>
            <Grid item>
              <TabletsAssignationContainer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

HealthStatistics.propTypes = {
  userSystemOfMeasure: PropTypes.number,
  handleAddHealthData: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  onAddHealthDataChart: PropTypes.func.isRequired,
  onRemoveHealthDataChart: PropTypes.func.isRequired,
  chartsHealthData: PropTypes.array.isRequired,
  chartsTypesAndNames: PropTypes.array.isRequired,
  setChartInterval: PropTypes.func.isRequired,
}

export default HealthStatistics
