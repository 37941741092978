import { createSelector } from 'reselect'
import { getReviewsList } from '@tabeeb/modules/contentReviews/selectors'
import { ContentReviewStatus, ContentSharingType, TabPanel } from '@tabeeb/enums'
import { getIsSelectedPagesForMergingNavbarTabActive } from '@tabeeb/modules/contentSharings/selectors'
import { getNavbarCurrentBottomPanel } from '../../customVideo/selectors'
import { getIsReviewSessionTimelineOpen } from '../../reviewSessionPagesList/selectors'

export const getIsShareSessionPagesListOpen = (state) => state.shareSessionPagesList.isTimelineOpen
export const getSelectedFormsList = (state) => state.shareSessionPagesList.shareByFormDialogState.selectedForms
export const getShareByFormDialogType = (state) => state.shareSessionPagesList.shareByFormDialogState.sharingType

export const getIsShareSessionTimelineOpen = createSelector(
  [getIsShareSessionPagesListOpen, getNavbarCurrentBottomPanel],
  (isTimelineOpen, currentPanel) => {
    return isTimelineOpen && currentPanel === TabPanel.ShareSession
  }
)

export const getIsShareSessionOptionsButtonVisible = createSelector(
  [getIsShareSessionTimelineOpen, getIsReviewSessionTimelineOpen, getIsSelectedPagesForMergingNavbarTabActive],
  (isShareTimelineOpen, isReviewTimelineOpen, isContentsMergingPagesOpen) => {
    return isShareTimelineOpen || isReviewTimelineOpen || isContentsMergingPagesOpen
  }
)

export const getContentFormExistingReview = createSelector(
  [getReviewsList, getShareByFormDialogType, (_, { form }) => form],
  (reviewsList, sharingType, contentForm) => {
    if (sharingType !== ContentSharingType.ForReview) return null

    const reviewWithThisForm = reviewsList.find(
      (r) => r.ReviewStatus === ContentReviewStatus.FeedbackSent && r.ContentFormsIds.includes(contentForm.Id)
    )

    return reviewWithThisForm
  }
)

export const getIsSelectedFormsListValid = createSelector(
  [getReviewsList, getSelectedFormsList],
  (reviewsList, selectedForms) => {
    const reviewsIdsWithSelectedForms = []

    selectedForms &&
      selectedForms.map((form) => {
        const reviewWithThisForm = reviewsList.find(
          (r) => r.ReviewStatus === ContentReviewStatus.FeedbackSent && r.ContentFormsIds.includes(form.ContentFormId)
        )

        if (reviewWithThisForm && !reviewsIdsWithSelectedForms.includes(reviewWithThisForm.Id)) {
          reviewsIdsWithSelectedForms.push(reviewWithThisForm.Id)
        }
      })

    // All selected forms must belong to one or none review.
    // If some of selected forms belong to 2 or more different reviews the list is invalid.
    return reviewsIdsWithSelectedForms.length < 2
  }
)
