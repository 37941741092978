import { put, takeLatest, select, all } from 'redux-saga/effects'

import { isImagePage } from '@tabeeb/services/pageService'
import { getIsImagesExportEmailRecipientsEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { getCurrentUserEmail } from '@tabeeb/modules/account/selectors'
import { getPagesByFolderWithNesting } from '../selectors'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onExportImages(action) {
  yield put(rawActions.closeExportImagesDialog())
  const contentId = yield select((state) => state.contentState.contentId)
  const withAnnotations = yield select((state) => state.gallery.exportImagesWithAnnotations.withAnnotations)
  const emailRecipients = yield select((state) => state.gallery.exportEmailRecipients)
  const selectedFolderId = yield select((state) => state.gallery.galleryState.selectedFolderId)
  const exportGalleryOption = yield select((state) => state.gallery.exportGalleryOption)

  yield put(
    rawActions.exportImagesRequest({
      contentId,
      folderId: selectedFolderId,
      galleryOption: exportGalleryOption,
      withAnnotations,
      emails: emailRecipients,
    })
  )
}

function* onExportImagesIfTheyExist(action) {
  const selectedFolderId = yield select((state) => state.gallery.galleryState.selectedFolderId)

  const pagesWithImages = yield select((state) =>
    getPagesByFolderWithNesting(state, { id: selectedFolderId }).filter((page) => isImagePage(page.contentType))
  )

  if (pagesWithImages.length === 0) {
    const message = 'There are no valid images to export'
    yield put(notificationActions.onAddWarningNotification({ message }))
    return
  }

  const isImagesExportEmailRecipientsEnabled = yield select((state) => getIsImagesExportEmailRecipientsEnabled(state))

  if (isImagesExportEmailRecipientsEnabled) {
    yield put(rawActions.setEmailRecipientsDialogState('image'))
  } else {
    const currentUserEmail = yield select((state) => getCurrentUserEmail(state))
    yield put(rawActions.setExportEmailRecipients([currentUserEmail]))
    yield put(rawActions.openExportImagesDialog())
  }
}

function* exportImagesSuccess(action) {
  const message = 'An email will be sent to you shortly with the link for downloading images.'
  yield put(notificationActions.onAddInfoNotification({ message }))
}

function* exportImagesArchiveToFtpSuccess(action) {
  const message = 'Exported images will be uploaded to FTP.'
  yield put(notificationActions.onAddInfoNotification({ message }))
}

function* exportImages() {
  yield all([
    takeLatest(rawActions.exportImages, onExportImages),
    takeLatest(rawActions.exportImagesSuccess, exportImagesSuccess),
    takeLatest(rawActions.exportImagesArchiveToFtpSuccess, exportImagesArchiveToFtpSuccess),
    takeLatest(rawActions.exportImagesIfTheyExist, onExportImagesIfTheyExist),
  ])
}

export default exportImages
