import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.openSwitchRecordedUserConfirmationDialog]() {
      return true
    },
    [rawActions.closeSwitchRecordedUserConfirmationDialog]() {
      return defaultState
    },
  },
  defaultState
)
