import { combineActions, handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = []

const tabs = handleActions(
  {
    [actions.selectContentMetadataTabForExport](state, { payload }) {
      return state.concat(payload)
    },
    [actions.deselectContentMetadataTabForExport](state, { payload }) {
      return state.filter((tab) => tab.Id !== payload.Id)
    },
    [combineActions(actions.deselectAllContentMetadataTabsForExport, actions.closeContentMetadataDialog)]() {
      return defaultState
    },
  },
  defaultState
)

export default tabs
