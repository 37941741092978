import PropTypes from 'prop-types'
import { Button, Divider, Grid, IconButton, Typography } from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'

import { Genders } from '@tabeeb/enums'

import {
  getAddress1GoogleMapsLink,
  getAddress2GoogleMapsLink,
  validateMobileUserEmail,
} from '@tabeeb/modules/healthData/helper'

import useStyles from './styles'

const UserProfile = ({ patient, isEditPatientDataVisible, handleFormActivation }) => {
  const classes = useStyles()
  return (
    <>
      {patient.DateOfBirth && (
        <Grid item>
          <Typography>
            <strong>Date of birth: </strong>
            {patient.DateOfBirth}
          </Typography>
        </Grid>
      )}
      {patient.PhoneNumber && (
        <Grid item className={classes.flexCenteredGrid}>
          <Typography>
            <strong>Phone: </strong>
            {patient.PhoneNumber}
          </Typography>
          <IconButton color='primary' size='small' title='Call' href={`tel:${patient.PhoneNumber}`}>
            <OpenInNew size='small' />
          </IconButton>
        </Grid>
      )}
      {!validateMobileUserEmail(patient.Email) && (
        <Grid item className={classes.flexCenteredGrid}>
          <Typography>
            <strong>Email: </strong>
            {patient.Email}
          </Typography>
          <IconButton color='primary' size='small' title='Write an email' href={`mailto:${patient.Email}`}>
            <OpenInNew size='small' />
          </IconButton>
        </Grid>
      )}
      {patient.Gender && (
        <Grid item>
          <Typography>
            <strong>Gender: </strong>
            {`${Object.entries(Genders)
              .find(([key, value]) => value === patient.Gender)
              ?.shift()}`}
          </Typography>
        </Grid>
      )}
      <Divider className={classes.divider} />
      <Grid item container direction='row' className={classes.address1Grid}>
        <Grid item xs container spacing={1} direction='column'>
          {patient.Address?.Country && (
            <Grid item>
              <Typography>
                <strong>Country: </strong>
                {patient.Address.Country}
              </Typography>
            </Grid>
          )}
          {patient.Address?.State && (
            <Grid item>
              <Typography>
                <strong>State/Province: </strong>
                {patient.Address.State}
              </Typography>
            </Grid>
          )}
          {patient.Address?.City && (
            <Grid item>
              <Typography>
                <strong>City: </strong>
                {patient.Address.City}
              </Typography>
            </Grid>
          )}
          {patient.Address?.PostalCode && (
            <Grid item>
              <Typography>
                <strong>Zip/Postal Code: </strong>
                {patient.Address.PostalCode}
              </Typography>
            </Grid>
          )}
          {patient.Address?.Line1 && (
            <Grid item>
              <Typography>
                <strong>Address1: </strong>
                {patient.Address.Line1}
              </Typography>
            </Grid>
          )}
        </Grid>
        {(patient.Address?.Country || patient.Address?.State || patient.Address?.City || patient.Address?.Line1) && (
          <Grid item xs={1} className={classes.addressButtonGrid}>
            <IconButton
              color='primary'
              size='small'
              target='_blank'
              title='Open address in google maps'
              href={getAddress1GoogleMapsLink(patient.Address)}
            >
              <OpenInNew size='small' />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {patient.Address?.Line2 && (
        <Grid item className={classes.flexCenteredGrid}>
          <Typography>
            <strong>Address2: </strong>
            {patient.Address.Line2}
          </Typography>
          <IconButton
            color='primary'
            size='small'
            target='_blank'
            title='Open address2 in google maps'
            href={getAddress2GoogleMapsLink(patient.Address)}
          >
            <OpenInNew size='small' />
          </IconButton>
        </Grid>
      )}
      {isEditPatientDataVisible && (
        <Grid item container direction='row' spacing={2}>
          <Grid item xs={12}>
            <Button color='primary' fullWidth variant='outlined' onClick={handleFormActivation}>
              Edit patient profile
            </Button>
          </Grid>
        </Grid>
      )}
      {(patient.Address?.Country ||
        patient.Address?.State ||
        patient.Address?.City ||
        patient.Address?.PostalCode ||
        patient.Address?.Line1 ||
        patient.Address?.Line2 ||
        isEditPatientDataVisible) && <Divider className={classes.divider} />}
    </>
  )
}

UserProfile.propTypes = {
  patient: PropTypes.shape({
    PhoneNumber: PropTypes.string,
    Email: PropTypes.string.isRequired,
    Gender: PropTypes.number,
    DateOfBirth: PropTypes.string,
    Address: PropTypes.shape({
      Country: PropTypes.string,
      State: PropTypes.string,
      City: PropTypes.string,
      PostalCode: PropTypes.string,
      Line1: PropTypes.string,
      Line2: PropTypes.string,
    }),
  }).isRequired,
  isEditPatientDataVisible: PropTypes.bool.isRequired,
  handleFormActivation: PropTypes.func.isRequired,
}

export default UserProfile
