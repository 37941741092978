import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import { isEmpty, times } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import { Typography, Button } from '@mui/material'

import domainService from '@tabeeb/services/domainService'
import { Search } from '@tabeeb/modules/tenants/components'

import styles from './styles'

const SwitchTenantContent = ({ identityMe, dialog }) => {
  const currentSubdomain = domainService.subdomain
  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchQueryChange = useCallback((event) => {
    setSearchQuery(event.target.value)
  }, [])

  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Search
          query={searchQuery}
          onQueryClear={() => setSearchQuery('')}
          onQueryChange={handleSearchQueryChange}
          isDataExists={Boolean(searchQuery)}
          onSubmit={(event) => {
            event.preventDefault()
          }}
        />
      </Grid>
      <Grid item container direction='row' spacing={1}>
        {isEmpty(identityMe) ? (
          <>
            {times(6, (index) => (
              <Grid key={index} item xs={12}>
                <Skeleton variant='text' animation='wave' height={40} />
              </Grid>
            ))}
          </>
        ) : (
          (searchQuery
            ? identityMe.userTenants.filter((userTenant) => {
                const searchQueryLowerCase = searchQuery.toLowerCase()
                return (
                  userTenant.tenantSubdomain.toLowerCase().includes(searchQueryLowerCase) ||
                  userTenant.tenantName.toLowerCase().includes(searchQueryLowerCase)
                )
              })
            : identityMe.userTenants
          ).map((userTenant) => (
            <Grid key={userTenant.tenantId} item xs={12}>
              <Button
                variant='outlined'
                sx={{ minWidth: 'unset' }}
                href={domainService.createOriginWithSubdomain(`${userTenant.tenantSubdomain}.`)}
                fullWidth
                disabled={dialog && currentSubdomain === userTenant.tenantSubdomain}
              >
                <Typography
                  title={userTenant.tenantName}
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {userTenant.tenantName}
                </Typography>
              </Button>
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  )
}

SwitchTenantContent.defaultProps = {
  dialog: false,
}

SwitchTenantContent.propTypes = {
  identityMe: PropTypes.shape({
    userTenants: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        tenantName: PropTypes.string,
        tenantSubdomain: PropTypes.string,
      }).isRequired
    ),
  }),
  dialog: PropTypes.bool,
}

export default withStyles(styles)(SwitchTenantContent)
