import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

import './styles.less'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'

const FoundUsersItem = ({ user, switchAction, selectOwner, removeOwner, selectUser, buttonText }) => {
  return (
    <div className='found-user-item'>
      <div className='found-user-item-info'>
        <UserAvatar className='found-user-item-avatar' user={user} />
        <span className='found-user-item-name' title={user.Name}>
          {user.Name}
        </span>
      </div>
      {(switchAction || removeOwner) && !user.IsPredefined && (
        <Button
          variant='contained'
          color='primary'
          style={{ borderRadius: 10 }}
          onClick={switchAction ? () => switchAction(user) : () => removeOwner()}
        >
          <span className='found-user-item-button-title'>{switchAction ? buttonText : 'Remove'}</span>
        </Button>
      )}
      {(selectOwner || selectUser) && (
        <Button
          variant='contained'
          color='secondary'
          style={{ borderRadius: 10 }}
          onClick={() => (selectOwner ? selectOwner(user) : selectUser(user))}
        >
          <span className='found-user-item-button-title'>{selectOwner ? 'select' : 'add'}</span>
        </Button>
      )}
    </div>
  )
}

FoundUsersItem.propTypes = {
  user: PropTypes.object.isRequired,
  switchAction: PropTypes.func,
  selectOwner: PropTypes.func,
  selectUser: PropTypes.func,
  removeOwner: PropTypes.func,
  buttonText: PropTypes.string,
}

export default FoundUsersItem
