import { put, takeLatest, all } from 'redux-saga/effects'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onGetSelectionListsFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load the lists of options.' }))
}

function* createSelectionList() {
  yield all([takeLatest(actions.getSelectionLists.failed, onGetSelectionListsFailed)])
}

export default createSelectionList
