import * as Yup from 'yup'

import { acsii } from './regexps'
import { passwordMaxLength, passwordRequirements } from '../constants/Requirements'

import * as Errors from '../constants/Errors'
import { invalidColor } from '../../utils/validationErrorMessages'

Yup.addMethod(Yup.string, 'collaboratePassword', function (args) {
  return this.trim('There should be no spaces at the beginning and at the end of the password')
    .strict(true)
    .matches(acsii, 'Only latin letters, numbers, and common punctuation characters are allowed')
    .max(passwordMaxLength, `Maximum length is ${passwordMaxLength} characters`)
    .required('Required')
    .test('password', {}, function (value) {
      const { path, createError } = this

      if (passwordRequirements.every(({ validator }) => validator(value))) {
        return true
      }

      return createError({ path, message: Errors.empty })
    })
})

Yup.addMethod(Yup.string, 'notEmptyString', function () {
  return this.ensure().test('not-empty', 'Value cannot be empty characters.', (value) => {
    return value.split(' ').join('').length !== 0
  })
})

Yup.addMethod(Yup.string, 'validColor', function (args) {
  return this.strict(true).test('is-color', {}, function (value) {
    const { path, createError } = this
    if (typeof value === 'string' && value.length > 0) {
      const div = document.createElement('div')
      div.style.color = value
      const divCSS = div.style.color
      return divCSS === '' ? createError({ path, message: invalidColor }) : true
    }
    return true
  })
})

Yup.addMethod(Yup.boolean, 'validRecordingType', function (args) {
  return this.strict(true).test({
    name: 'recordingTypeFieldTest',
    exclusive: true,
    params: {},
    message: 'At least one field must be selected',
    test: (value, ctx) => {
      return !ctx.parent.isRecordingEnabled || !ctx.parent.isVideoCallsEnabled
        ? true
        : ctx.parent.isLocalRecordingEnabled || ctx.parent.isCloudRecordingEnabled
    },
  })
})
