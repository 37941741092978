import { memo } from 'react'

import PageNumberSelect from './PageNumberSelect'
import PreviousPageButton from './PreviousPageButton'
import FollowingPageButton from './FollowingPageButton'

import useStyles from './styles'

const Pagination = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <PreviousPageButton />
      <PageNumberSelect />
      <FollowingPageButton />
    </div>
  )
}

export default memo(Pagination)
