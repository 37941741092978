import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export * from './signalr'
export * from './selectedFilters'

const createGetIterationEntitiesActions = (name) => createApiActions(`GET_${name}_BY_ITERATION_ID`)

export const addProject = createApiActions('ADD_PROJECT')
export const deleteProject = createApiActions('DELETE_PROJECT')
export const deployIteration = createApiActions('DEPLOY_ITERATION')
export const deployIterationByAiModelId = createApiActions('DEPLOY_ITERATION_BY_AI_MODEL_ID')
export const deployIterationByVqaModelId = createApiActions('DEPLOY_ITERATION_BY_VQA_MODEL_ID')
export const downloadIterationMetrics = createApiActions('DOWNLOAD_ITERATION_METRICS')
export const getAiObjectsByIterationId = createGetIterationEntitiesActions('AI_OBJECTS')
export const getAiObjectsForProject = createApiActions('GET_AI_OBJECTS_FOR_PROJECT')
export const getAiObjectsForProjectTraining = createApiActions('GET_AI_OBJECTS_FOR_PROJECT_TRAINING')
export const getAnnotationOwnersByIterationId = createGetIterationEntitiesActions('ANNOTATION_OWNERS')
export const getAnswerersByIterationId = createGetIterationEntitiesActions('ANSWERERS')
export const getApproversByIterationId = createGetIterationEntitiesActions('APPROVERS')
export const getContentsByIterationId = createGetIterationEntitiesActions('CONTENTS')
export const getContentsForProject = createApiActions('GET_CONTENTS_FOR_PROJECT')
export const getFormControlsByIterationId = createGetIterationEntitiesActions('FORM_CONTROLS')
export const getFormControlsForProject = createApiActions('GET_FORM_CONTROLS_FOR_PROJECT')
export const getFormsByIterationId = createGetIterationEntitiesActions('FORMS')
export const getFormsForProject = createApiActions('GET_FORMS_FOR_PROJECT')
export const getIterationById = createApiActions('GET_ITERATION_BY_ID')
export const getIterationDeploymentStatus = createApiActions('GET_ITERATION_DEPLOYMENT_STATUS')
export const getIterations = createApiActions('GET_ITERATIONS')
export const getIterationStatus = createApiActions('GET_ITERATION_STATUS')
export const getMetricsByIterationId = createGetIterationEntitiesActions('METRICS')
export const getPagesByIterationId = createGetIterationEntitiesActions('PAGES')
export const getPagesForProjectTraining = createApiActions('GET_PAGES_FOR_PROJECT_TRAINING')
export const getReviewersByIterationId = createGetIterationEntitiesActions('REVIEWERS')
export const getUsersForProject = createApiActions('GET_USERS_FOR_PROJECT')
export const resetPagesForProjectTraining = createAction('RESET_PAGES_FOR_PROJECT_TRAINING')

export const getPagesStatisticsByFormsForProjectTraining = createApiActions(
  'GET_PAGES_STATISTICS_BY_FORMS_FOR_PROJECT_TRAINING'
)
export const getPagesStatisticsByAiObjectsForProjectTraining = createApiActions(
  'GET_PAGES_STATISTICS_BY_AI_OBJECTS_FOR_PROJECT_TRAINING'
)
export const startProjectTraining = createAction('START_PROJECT_TRAINING')

export const getProject = createApiActions('GET_PROJECT')
export const getProjects = createApiActions('GET_PROJECTS')
export const trainProject = createApiActions('TRAIN_PROJECT')
export const updateIteration = createApiActions('UPDATE_ITERATION')
export const updateProject = createApiActions('UPDATE_PROJECT')

export const updateIterationTrainingStatus = createAction('UPDATE_ITERATION_TRAINING_STATUS')
export const updateIterationDeploymentStatus = createAction('UPDATE_ITERATION_DEPLOYMENT_STATUS')

export const closeCreateProjectDialog = createAction('CLOSE_CREATE_PROJECT_DIALOG')
export const openCreateProjectDialog = createAction('OPEN_CREATE_PROJECT_DIALOG')
export const openEditProjectDialog = createAction('OPEN_EDIT_PROJECT_DIALOG')

export const setProjectToDelete = createAction('SET_PROJECT_TO_DELETE')

export const setSelectedIterationId = createAction('SET_SELECTED_ITERATION_ID')
export const resetSelectedIterationId = createAction('RESET_SELECTED_ITERATION_ID')

export const setSelectedProject = createAction('SET_SELECTED_PROJECT')

export const setSelectedSearchField = createAction('SET_SELECTED_SEARCH_FIELD')
export const closeSearchFields = createAction('CLOSE_SEARCH_FIELDS')

export const setAnnotationSource = createAction('SET_ANNOTATION_SOURCE')
export const setTrainingSettings = createAction('SET_TRAINING_SETTINGS')
export const setIterationType = createAction('SET_ITERATION_TYPE')
export const setAdvancedTrainingSettings = createAction('SET_ADVANCED_TRAINING_SETTINGS')

export const setIterationPagesPageNumber = createAction('SET_ITERATION_PAGES_PAGE_NUMBER')
export const setIterationPagesPageSize = createAction('SET_ITERATION_PAGES_PAGE_SIZE')
export const loadNewIterationPages = createAction('LOAD_NEW_ITERATION_PAGES')
export const loadExistingIterationPages = createAction('LOAD_EXISTING_ITERATION_PAGES')

export const closePagesAnalysisDialog = createAction('CLOSE_PAGES_ANALYSIS_DIALOG')

export const openTrainingSettingsDialog = createAction('OPEN_TRAINING_SETTINGS_DIALOG')
export const closeTrainingSettingsDialog = createAction('CLOSE_TRAINING_SETTINGS_DIALOG')

export const setCurrentIterationPagesDisplayMode = createAction('SET_CURRENT_ITERATION_PAGES_DISPLAY_MODE')
export const setIsFastExcludeModeOn = createAction('SET_IS_FAST_EXCLUDE_MODE_ON')

export const createCustomMetricsChart = createAction('CREATE_CUSTOM_METRICS_CHART')
export const deleteCustomMetricsChart = createAction('DELETE_CUSTOM_METRICS_CHART')
