export default (theme) => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 10px',
    },
    list: {
      width: '45%',
      display: 'flex',
      alignItems: 'center',
      marginRight: 5,
      paddingBottom: 3,
    },
  }
}
