import { nanoid } from 'nanoid'
import { takeLatest, put, all, call } from 'redux-saga/effects'

import { addFilesToQueue } from '@tabeeb/modules/gallery/actions'
import { notificationActions } from '@tabeeb/modules/notification'

import * as rawActions from '../actions'

const convertCanvasToBlob = (canvas, type = 'image/jpeg', quality = 1) => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob)
        } else {
          reject(new Error(`Cannot convert canvas into blob`))
        }
      },
      type,
      quality
    )
  })
}

function* takeContentSnapshot({ payload }) {
  try {
    const { x, y, width, height } = payload

    const elementsForExport = document.getElementsByClassName('viewer-content')

    const snapshot = document.createElement('canvas')
    const snapshotContext = snapshot.getContext('2d')

    // eslint-disable-next-line no-undef
    const browserDevicePixelRatio = devicePixelRatio

    snapshot.width = width * browserDevicePixelRatio
    snapshot.height = height * browserDevicePixelRatio

    const htmlToCanvasExporter = yield call(() => import('html2canvas').then((module) => module.default))

    for (const element of elementsForExport) {
      const canvas = yield htmlToCanvasExporter(element, {
        allowTaint: true,
        x,
        y,
        width,
        height,
        backgroundColor: 'transparent',
        ignoreElements: (el) => {
          if (el.classList.contains('snipping-tool')) {
            return true
          }

          return false
        },
      })

      snapshotContext.drawImage(canvas, 0, 0)
    }

    const blob = yield convertCanvasToBlob(snapshot)
    blob.name = `${nanoid()}.jpeg`

    yield put(addFilesToQueue({ files: [blob] }))
  } catch {
    yield put(notificationActions.onAddErrorNotification({ message: 'Failed to take a snapshot' }))
  }
}

function* snippingToolSaga() {
  yield all([takeLatest(rawActions.takeContentSnapshot, takeContentSnapshot)])
}

export default snippingToolSaga
