import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { feedbackInContentReviewRequest } from '@tabeeb/modules/contentReviews/actions'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getFormButtonState } from '@tabeeb/modules/forms/selectors'
import { FormButtons } from '../../services/enums'

const SendInContentReviewFeedbackButton = () => {
  const { isVisible, isDisabled } = useSelector((state) =>
    getFormButtonState(state, { button: FormButtons.FeedbackInContentReview })
  )
  const contentId = useSelector(getContentId)
  const dispatch = useDispatch()

  if (!isVisible) return null

  return (
    <Button
      onClick={() => dispatch(feedbackInContentReviewRequest({ contentId }))}
      variant='contained'
      color='primary'
      className='form-button'
      disabled={isDisabled}
    >
      send feedback
    </Button>
  )
}

export default memo(SendInContentReviewFeedbackButton)
