import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'

import { Notifications } from '@material-ui/icons'
import { Avatar, Button, Divider, Typography, Grid } from '@material-ui/core'

import { FormikSwitch } from '@tabeeb/shared/forms'
import { getCurrentUserUpdateProfile } from '../../selectors'
import { updateAccountRequest } from '../../actions'

import useStyles from './styles'

const notificationsSettingsSchema = Yup.object().shape({
  NotificationsSettings: Yup.object().shape({
    ChatNotificationsEnabled: Yup.bool().required(),
  }),
})

const NotificationSettings = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const userSettingsFormData = useSelector(getCurrentUserUpdateProfile)

  const onSave = useCallback(
    (data) => {
      dispatch(updateAccountRequest(data))
    },
    [dispatch]
  )

  return (
    <Formik
      initialValues={userSettingsFormData}
      enableReinitialize
      validationSchema={notificationsSettingsSchema}
      onSubmit={onSave}
    >
      {({ dirty, isValid }) => (
        <Form className={classes.form} autoComplete='off'>
          <Grid item xs={12}>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <div className={classes.header}>
                  <Avatar className={classes.avatar}>
                    <Notifications className={classes.icon} />
                  </Avatar>
                  <Typography variant='body1'>Notifications</Typography>
                </div>
                <Divider className={classes.divider} />
                <Grid container wrap='nowrap' direction='column' spacing={1}>
                  <Grid item>
                    <Field
                      name='NotificationsSettings.ChatNotificationsEnabled'
                      color='primary'
                      label='Send chat notifications'
                      component={FormikSwitch}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={!(isValid && dirty)}
                      size='large'
                      fullWidth
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default memo(NotificationSettings)
