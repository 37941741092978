import { passwordMinimumLength } from '../constants/Requirements'
import { lowercase, uppercase, number, specialCharacter } from './regexps'

export const validateLowercase = (value) => {
  const valid = value ? new RegExp(lowercase).test(value) : false

  return valid
}

export const validateUppercase = (value) => {
  const valid = new RegExp(uppercase).test(value)

  return valid
}

export const validateNumber = (value) => {
  const valid = new RegExp(number).test(value)

  return valid
}

export const validateSpecialCharacter = (value) => {
  const valid = new RegExp(specialCharacter).test(value)

  return valid
}

export const validateMinLength = (value) => {
  const valid = Boolean(value && value.length >= passwordMinimumLength)

  return valid
}
