import { ReminderType, SessionPermission } from '@tabeeb/enums'
import { getSelectedTabs } from '@tabeeb/modules/forms/selectors'
import { formTabsIdsEquals } from '@tabeeb/modules/forms/services/formTabService'

import { getShowReminderButton } from '@tabeeb/modules/appConfigState/selectors'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'
import { createSelector } from 'reselect'

export const getReminders = (state) => state.reminders.remindersList
export const getContentUsers = (state) => state.users.users

export const selectAvailableReceiversState = (state) => state.reminders.availableReceivers

export const getHasReminderPermission = (state) => hasSessionPermission(state, SessionPermission.AddDeleteReminder)

export const getExistingReminders = createSelector(
  [getReminders, getSelectedTabs, (_, props) => props],
  (allReminders, selectedTabs, { type, entity }) => {
    switch (type) {
      case ReminderType.AnswerFormQuestion:
        return allReminders.filter(
          (r) => r.Type === type && r.FormControlId === entity.Id && formTabsIdsEquals(r.FormTabsIds, selectedTabs)
        )

      default:
        return []
    }
  }
)

export const selectUsersList = createSelector(
  [getContentUsers, selectAvailableReceiversState],
  (users, availableReceiversState) => {
    const usersIdsWhitelist = availableReceiversState.data
    return {
      fetchingStatus: availableReceiversState.fetchingStatus,
      users: users.filter((user) => usersIdsWhitelist.includes(user.id)),
    }
  }
)

export const getReminderById = createSelector([getReminders, (_, { Id }) => Id], (allReminders, Id) => {
  return allReminders.find((r) => r.Id === Id)
})

export const getIsReminderIconVisible = createSelector(
  [getShowReminderButton, getHasReminderPermission],
  (isEnabled, hasPermission) => {
    return isEnabled && hasPermission
  }
)
