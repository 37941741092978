import { put, takeEvery, take, all, select } from 'redux-saga/effects'

import { contentStateSelectors } from '../../shared/content'
import * as trackingActions from '../actions'
import * as trackingSelectors from '../selectors'

import { TrackingFilterTypes } from '../constants'

function* addTracking(action) {
  const { tracking, element } = action.payload
  tracking.CreatedOn = new Date().toUTCString()

  yield put(trackingActions.addTrackingRequest(tracking))
  yield take([trackingActions.addTrackingFailed, trackingActions.addTrackingSuccess])
  element.setMap(null)
}

function* getTrackings() {
  const contentId = (yield select(contentStateSelectors.getContentId)) || null
  const timePeriodFilter = yield select((state) =>
    trackingSelectors.getFilterByName(state, TrackingFilterTypes.TimePeriod)
  )
  const startTime = timePeriodFilter.isEnabled ? new Date(timePeriodFilter.value.start) : null
  const endTime = timePeriodFilter.isEnabled ? new Date(timePeriodFilter.value.end) : null

  yield put(trackingActions.getTrackingsRequest({ params: { startTime, endTime, contentId } }))
}

function* filterState({ payload }) {
  const filterName = payload.name

  if (filterName === TrackingFilterTypes.TimePeriod) {
    yield getTrackings()
  }
}

function* trackingSaga() {
  yield all([
    takeEvery(trackingActions.addTracking, addTracking),
    takeEvery(trackingActions.getTrackings, getTrackings),
    takeEvery(
      [
        trackingActions.enableTrackingFilter,
        trackingActions.disableTrackingFilter,
        trackingActions.setTrackingFilterValue,
      ],
      filterState
    ),
  ])
}

export default trackingSaga
