import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button, withStyles } from '@material-ui/core'

import styles from './styles'

const UserActionButton = ({ classes, icon: Icon, title, isMuted, disabled, onClick }) => {
  const handleDoubleClick = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  return (
    <Button
      variant='contained'
      title={title}
      disabled={disabled}
      className={classNames(classes.button, { [classes.muted]: isMuted })}
      onClick={onClick}
      onDoubleClick={handleDoubleClick}
    >
      <Icon fontSize='small' />
    </Button>
  )
}

UserActionButton.defaultProps = {
  disabled: false,
}

UserActionButton.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    muted: PropTypes.string.isRequired,
  }).isRequired,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isMuted: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(withStyles(styles)(UserActionButton))
