import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import { required2dLineDrawingFiles } from '@tabeeb/shared/utils/validationErrorMessages'
import * as rawActions from '../actions'
import { MINIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING, MAXIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING } from '../constants'

import Timeline from '../../timeline/components/Timeline'

class TwoDLineDrawingImagesListContainer extends Component {
  _handleClose = () => {
    const {
      actions: { close2dLineDrawingDialog },
    } = this.props

    close2dLineDrawingDialog()
  }

  _handleSubmit = () => {
    const {
      actions: { add2dLineDrawing },
      isProcessing,
    } = this.props
    if (isProcessing) {
      return
    }
    add2dLineDrawing()
  }

  _handleDeleteItem = (itemIndex) => {
    const {
      actions: { deletePageFrom2dLineDrawing },
    } = this.props

    deletePageFrom2dLineDrawing(itemIndex)
  }

  _handleUpdateList = (newList) => {
    const {
      actions: { updatePages2dLineDrawing },
    } = this.props

    updatePages2dLineDrawing(newList)
  }

  render() {
    const { isOpen, isProcessing, timeLineList, currentPanel } = this.props

    const actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        tooltip:
          timeLineList.length < MINIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING ||
          timeLineList.length > MAXIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING
            ? required2dLineDrawingFiles
            : null,
        class: 'submit',
        disabled:
          timeLineList.length < MINIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING ||
          timeLineList.length > MAXIMUM_ASSETS_NUMBER_FOR_2D_LINE_DRAWING,
      },
    ]

    const props = {
      actions,
      timeLineList,
      handleDeleteItem: this._handleDeleteItem,
      handleUpdateList: this._handleUpdateList,

      isOpen,
      isSubmitted: isProcessing,
      isLoading: isProcessing,
      isSelected: currentPanel === TabPanel.TwoDLineDrawing,
    }

    return <Timeline {...props} />
  }
}

TwoDLineDrawingImagesListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  timeLineList: PropTypes.array.isRequired,
  currentPanel: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    close2dLineDrawingDialog: PropTypes.func.isRequired,
    add2dLineDrawing: PropTypes.func.isRequired,
    deletePageFrom2dLineDrawing: PropTypes.func.isRequired,
    updatePages2dLineDrawing: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.twoDLineDrawing.isTimelineOpen,
    isProcessing: state.twoDLineDrawing.isProcessing,
    timeLineList: state.twoDLineDrawing.pagesList,
    currentPanel: state.navbar.currentPanel[TabPanelLocation.Bottom],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TwoDLineDrawingImagesListContainer)
