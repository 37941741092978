export const screenSharingDefaults = {
  desktopSharingChromeMethod: 'ext',
  desktopSharingChromeSources: ['screen', 'window'],
  desktopSharingChromeMinExtVersion: '0.1',
  desktopSharingChromeExtId: 'pheemdcdedeeajjphgbgjliigngeodcc',
  desktopSharingFirefoxExtId: '',
  desktopSharingFirefoxDisabled: false,
  desktopSharingFirefoxMaxVersionExtRequired: 0,
  desktopSharingFirefoxExtensionURL: '',
  desktopSharingMessageForChrome:
    '<div>To enable sharing your computer screen with other users, please install this plug-in <a target="_blank" href="https://chrome.google.com/webstore/detail/collaboratecenter-desktop/pheemdcdedeeajjphgbgjliigngeodcc?hl=en">link</a>. After installing it, close your browser and start again.<br> <div class="pull-right"><a target="_blank" href="https://chrome.google.com/webstore/detail/collaboratecenter-desktop/pheemdcdedeeajjphgbgjliigngeodcc?hl=en"><button type="button" class="btn btn-primary ">Download</button></a></div>',
}
