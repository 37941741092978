import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'

import UserAvatar from '@tabeeb/shared/userAvatar'
import UserSearch from '@tabeeb/shared/UserSearch'
import { maxLength, noWhitespaces, requiredField } from '@tabeeb/shared/utils/validationErrorMessages'
import QualificationVersionsAutocomplete from '../QualificationVersionsAutocomplete'

import { useUsers } from '../../../../users/hooks'

const validationSchema = Yup.object().shape({
  AssignedToUserGuid: Yup.string().strict().trim(noWhitespaces).max(100, maxLength(100)).required(requiredField),
  QualificationVersionId: Yup.string()
    .matches(/^\d+$/, { message: 'The field should have digits only' })
    .required(requiredField),
})

const initialValues = {
  AssignedToUserGuid: '',
  QualificationVersionId: '',
}

const autocompleteInputProps = {
  variant: 'outlined',
  label: 'Enter name or email',
  placeholder: 'Enter name or email...',
  required: true,
}
const AddUserQualificationDialog = ({ open, onClose, onSubmit, onExited }) => {
  const [searchUserEmail, setSearchUserEmail] = useState('')
  const handleChangeUserEmailInput = useCallback((event, value) => {
    setSearchUserEmail(value)
  }, [])

  const { loading, users } = useUsers({ search: searchUserEmail })

  const autocompleteProps = useMemo(
    () => ({
      multiple: false,
      onInputChange: handleChangeUserEmailInput,
      getOptionLabel: (option) => option.Email,
      renderOption: (option, state) => (
        <>
          <UserAvatar user={option} style={{ width: 25, height: 25, fontSize: 12, marginRight: 8 }} />
          {option.Name}
        </>
      ),
    }),
    [handleChangeUserEmailInput]
  )

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited }} fullWidth maxWidth='sm'>
      <DialogTitle>Assign user qualification</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ isValid, dirty, errors, setFieldTouched, setFieldValue, values, touched }) => (
          <Form autoComplete='off'>
            <DialogContent>
              <Grid container direction='row' spacing={2}>
                <Grid item xs={12}>
                  <UserSearch
                    loading={loading}
                    options={users}
                    onChange={(event, value) => {
                      setFieldValue('AssignedToUserGuid', value?.IdentityGuid)
                      setFieldValue('AssignedToUserId', value?.Id)
                    }}
                    name='AssignedToUserGuid'
                    InputProps={{
                      ...autocompleteInputProps,
                      error: Boolean(touched.AssignedToUserGuid) && Boolean(errors.AssignedToUserGuid),
                      helperText: Boolean(touched.AssignedToUserGuid) && errors.AssignedToUserGuid,
                    }}
                    AutocompleteProps={{
                      ...autocompleteProps,
                      onBlur: () => setFieldTouched('AssignedToUserGuid'),
                      filterOptions: (options) => options,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <QualificationVersionsAutocomplete />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant='outlined' color='primary' type='submit' disabled={!(isValid && dirty)}>
                Assign
              </Button>
              <Button variant='outlined' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

AddUserQualificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onExited: PropTypes.func,
}

export default AddUserQualificationDialog
