import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'
import FormsFilter from '../components/FormsFilter'
import * as formsActions from '../actions'
import { getContentFormsForCurrentUser } from '../selectors'

class FormsFilterContainer extends Component {
  _handleChange = (event) => {
    const {
      actions: { openForm },
    } = this.props
    const { value } = event.target
    const selectedFormId = parseInt(value, 10)

    openForm(selectedFormId)
  }

  render() {
    const { forms, currentForm, formTitle } = this.props

    const props = {
      currentForm,
      forms,
      handleChange: this._handleChange,
      formTitle,
    }

    return <FormsFilter {...props} />
  }
}

FormsFilterContainer.propTypes = {
  forms: PropTypes.array.isRequired,
  currentForm: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    openForm: PropTypes.func.isRequired,
  }).isRequired,
  formTitle: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  forms: getContentFormsForCurrentUser(state),
  currentForm: state.forms.currentForm,
  formTitle: getFormTitle(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(formsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormsFilterContainer)
