import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    truncatedText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    deviceGrid: {
      width: '100%',
    },
  }
})
