import { all, takeLatest, put, select, call } from 'redux-saga/effects'

import { notificationActions } from '../../../notification'
import { ErrorCode } from '@tabeeb/enums'

import { getDevicesSnapshot } from '@tabeeb/modules/admin/devices/selectors'
import * as adminActions from '../actions'

export function* onAddDeviceFailed(action) {
  const message =
    action.response.data.Error?.Code === ErrorCode.DeviceAlreadyExists
      ? action.response.data.Error?.Message
      : 'Failed to create device'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* onEditDeviceFailed(action) {
  const message =
    action.response.data.Error?.Code === ErrorCode.DeviceAlreadyExists
      ? action.response.data.Error?.Message
      : 'Failed to edit device'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

export function* coreOnAddDeviceSuccess() {
  const filtersSnapshot = yield select(getDevicesSnapshot)
  yield put(adminActions.searchDevicesRequest(filtersSnapshot))
}

export function* onAddDeviceSuccess() {
  yield call(coreOnAddDeviceSuccess)

  const message = 'Device created successfully.'
  yield put(notificationActions.onAddSuccessNotification({ message }))
}

export function* deleteDeviceSuccess(action) {
  const message = 'Device deleted successfully.'
  yield put(notificationActions.onAddSuccessNotification({ message }))
}

function* devicesPageSaga() {
  yield all([
    takeLatest(adminActions.createDeviceFailed, onAddDeviceFailed),
    takeLatest(adminActions.createDeviceSuccess, onAddDeviceSuccess),
    takeLatest(adminActions.editDeviceFailed, onEditDeviceFailed),
    takeLatest(adminActions.deleteDeviceSuccess, deleteDeviceSuccess),
  ])
}

export default devicesPageSaga
