import * as React from 'react'

const TenantMenuContext = React.createContext({
  onGoToTenantMenuItemClick: () => {},
  onCopyTenantUrlMenuItemClick: () => {},
  onEditStorageMenuItemClick: () => {},
  onEditConfigMenuItemClick: () => {},
  onEditTermsMenuItemClick: () => {},
  onQuickReportMenuItemClick: () => {},
})

export default TenantMenuContext
