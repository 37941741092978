import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'

import { UserInfo } from '@tabeeb/uikit'

/**
 * @param {boolean} strict
 * @param {boolean} disabled
 * @param {boolean} loading
 * @param {T[]} options
 * @param {object} value
 * @param {(event: object, value: T|T[], reason: string) => void} onChange
 * @param {(event: object) => void} onInputChange
 * @param {string} name
 * @param {AutocompleteProps<T, boolean, undefined, boolean>} AutocompleteProps
 * @param InputProps
 * @template T
 */
const UserSearch = ({
  strict,
  disabled,
  loading,
  options,
  value,
  onChange,
  onInputChange,
  name,
  AutocompleteProps,
  InputProps,
}) => {
  const label = useMemo(() => {
    if (InputProps.showLabel === false) {
      return ''
    }
    if (InputProps.label?.length > 0) {
      return InputProps.label
    }
    return 'Enter email'
  }, [InputProps])

  return (
    <Autocomplete
      freeSolo={!strict}
      limitTags={2}
      loading={loading}
      options={options}
      disabled={disabled}
      value={value}
      onChange={onChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize='small' />}
            checkedIcon={<CheckBox fontSize='small' />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <UserInfo item={option} />
        </li>
      )}
      getOptionLabel={(option) => option.Email}
      isOptionEqualToValue={(option, optionValue) => option.Id === optionValue.Id}
      {...AutocompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          {...InputProps}
          name={name}
          onChange={onInputChange}
          label={label}
          placeholder={InputProps.placeholder?.length > 0 ? InputProps.placeholder : 'Enter an email...'}
        />
      )}
    />
  )
}

UserSearch.defaultProps = {
  strict: true,
  disabled: false,
}

UserSearch.propTypes = {
  strict: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      IdentityGuid: PropTypes.string.isRequired,
      Email: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      IdentityGuid: PropTypes.string.isRequired,
      Email: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  AutocompleteProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.object,
}

export default UserSearch
