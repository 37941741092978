import { memo } from 'react'
import { Link } from 'react-router-dom'

import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { AddOutlined, KeyboardArrowDownOutlined, PictureAsPdfOutlined, TextFieldsOutlined } from '@mui/icons-material'

import Routes from '@tabeeb/routes'
import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import { ArticleContentType } from '../../enums'

const TrainingMaterialCreateButton = () => {
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const options = [
    {
      id: ArticleContentType.Html,
      title: 'From text',
      icon: TextFieldsOutlined,
      href: `${Routes.trainingMaterialCreate}?type=${ArticleContentType.Html}`,
    },
    {
      id: ArticleContentType.Pdf,
      title: 'From PDF file',
      icon: PictureAsPdfOutlined,
      href: `${Routes.trainingMaterialCreate}?type=${ArticleContentType.Pdf}`,
    },
  ]

  return (
    <>
      <Button
        ref={anchorRef}
        startIcon={<AddOutlined color='primary' />}
        endIcon={<KeyboardArrowDownOutlined color='primary' />}
        variant='outlined'
        onClick={onOpen}
      >
        Create new
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map(({ id, href, icon: Icon, title }) => (
          <Link key={id} to={href}>
            <MenuItem>
              <ListItemIcon>
                <Icon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary={title} />
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  )
}

export default memo(TrainingMaterialCreateButton)
