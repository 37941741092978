import { useSelector } from 'react-redux'

import { getLargeVideoUser } from '@tabeeb/modules/presentation/selectors'
import RecordingProgressBar from '../components/RecordingProgressBar'
import { getIsRecordingActive, getSelectedUserId } from '../selectors'

const RecordingProgressBarContainer = () => {
  const largeVideoUser = useSelector(getLargeVideoUser)
  const recordingUserId = useSelector(getSelectedUserId)
  const isRecordingActive = useSelector(getIsRecordingActive)

  const showComponent =
    isRecordingActive && recordingUserId && largeVideoUser !== null && largeVideoUser.id === recordingUserId

  return showComponent ? <RecordingProgressBar /> : null
}

export default RecordingProgressBarContainer
