import { createAction } from 'redux-actions'

export const openQuestionFormAnswersDialog = createAction('OPEN_QUESTION_FORM_ANSWERS_DIALOG')
export const closeQuestionFormAnswersDialog = createAction('CLOSE_QUESTION_FORM_ANSWERS_DIALOG')

export const openPageFormAnswersDialog = createAction('OPEN_PAGE_FORM_ANSWERS_DIALOG')
export const closePageFormAnswersDialog = createAction('CLOSE_PAGE_FORM_ANSWERS_DIALOG')

export const updatePageFormAnswersFilters = createAction('UPDATE_PAGE_FORM_ANSWERS_FILTERS_BY_PAGE')
export const updateQuestionFormAnswersFilters = createAction('UPDATE_PAGE_FORM_ANSWERS_FILTERS_BY_QUESTION')

export const getFormAnswersByPage = createAction('GET_FORM_ANSWERS_BY_PAGE')
export const getFormAnswersByPageRequest = createAction('GET_FORM_ANSWERS_BY_PAGE_REQUEST')
export const getFormAnswersByPageSuccess = createAction('GET_FORM_ANSWERS_BY_PAGE_SUCCESS')
export const getFormAnswersByPageFailed = createAction('GET_FORM_ANSWERS_BY_PAGE_FAILED')

export const getFormAnswersByQuestion = createAction('GET_FORM_ANSWERS_BY_QUESTION')
export const getFormAnswersByQuestionRequest = createAction('GET_FORM_ANSWERS_BY_QUESTION_REQUEST')
export const getFormAnswersByQuestionSuccess = createAction('GET_FORM_ANSWERS_BY_QUESTION_SUCCESS')
export const getFormAnswersByQuestionFailed = createAction('GET_FORM_ANSWERS_BY_QUESTION_FAILED')

export const resetPageAnswersState = createAction('RESET_PAGE_FORM_ANSWERS_STATE')
export const resetQuestionAnswersState = createAction('RESET_QUESTION_FORM_ANSWERS_STATE')
