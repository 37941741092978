import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useWindowSize } from '@tabeeb/shared/utils/hooks'
import { mimeTypes } from '@tabeeb/uikit/constants'

import PdfViewer from '@tabeeb/uikit/components/PdfViewer'
import ImageViewer from '@tabeeb/uikit/components/ImageViewer'

const CertificatesImageViewer = ({ open, onClose, slides, plugins, pdfViewerProps }) => {
  const { numberOfPdfPages, setNumberOfPdfPages, nextPageClick, previousPageClick, pageNumber, setInitialPage } =
    pdfViewerProps

  const { height } = useWindowSize()
  const [{ height: pdfHeight, width: pdfWidth }, setCurrentPdfDimensions] = useState({ height: 0, width: 0 })

  const customSlideRenderProps = useMemo(
    () => ({
      customSlideRender: {
        // eslint-disable-next-line react/no-unstable-nested-components
        slide: ({ slide, offset, rect }) => {
          return slide.mimeType === mimeTypes.pdf ? (
            <PdfViewer
              pdfFileSource={slide.src}
              numberOfPdfPages={numberOfPdfPages}
              setNumberOfPdfPages={setNumberOfPdfPages}
              nextPageClick={nextPageClick}
              previousPageClick={previousPageClick}
              pageNumber={pageNumber}
              setInitialPage={setInitialPage}
              pageHeight={height - 100}
              showNavigation={false}
              setCurrentPdfDimensions={setCurrentPdfDimensions}
            />
          ) : undefined
        },
      },
      nextPageClick,
      nextPageClickAvailable: !(pageNumber >= numberOfPdfPages),
      previousPageClick,
      previousPageClickAvailable: pageNumber !== 1,
    }),
    [
      height,
      nextPageClick,
      numberOfPdfPages,
      pageNumber,
      previousPageClick,
      setInitialPage,
      setNumberOfPdfPages,
      setCurrentPdfDimensions,
    ]
  )

  return (
    <ImageViewer
      open={open}
      onClose={onClose}
      slides={slides.map((e) => ({
        ...e,
        ...(pdfHeight && { height: pdfHeight }),
        ...(pdfWidth && { width: pdfWidth }),
      }))}
      plugins={plugins}
      {...(slides.filter((_) => _.mimeType === mimeTypes.pdf).length > 0 && {
        customSlideRenderProps,
      })}
    />
  )
}

CertificatesImageViewer.defaultProps = {
  pdfViewerProps: {},
}

CertificatesImageViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      mimeType: PropTypes.string,
    })
  ),
  plugins: PropTypes.shape({
    zoom: PropTypes.bool,
    download: PropTypes.bool,
  }),
  pdfViewerProps: PropTypes.shape({
    numberOfPdfPages: PropTypes.number,
    setNumberOfPdfPages: PropTypes.func,
    nextPageClick: PropTypes.func,
    previousPageClick: PropTypes.func,
    pageNumber: PropTypes.number,
    setInitialPage: PropTypes.func,
  }),
}

export default CertificatesImageViewer
