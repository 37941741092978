import { takeLatest, put, all } from 'redux-saga/effects'
import { stopSubmit, reset } from 'redux-form'

import * as galleryActions from '../actions'
import updateOrAddGallery from './updateOrAddGallery'
import { contentNotificationService } from '../../shared/content'
import { ADD_WEB_PAGE_FROM_URL_DIALOG_FORM } from '../constants'

function* addWebPageUsingUrlSuccess(action) {
  yield put(stopSubmit(ADD_WEB_PAGE_FROM_URL_DIALOG_FORM))
  yield put(reset(ADD_WEB_PAGE_FROM_URL_DIALOG_FORM))
  const pages = [action.response.data]
  const isSelectAddedGalleryItem = false
  yield updateOrAddGallery(isSelectAddedGalleryItem, pages)
  yield contentNotificationService.notifyOfNewPages(pages)
}

function* addWebPageUsingUrlFailed(action) {
  yield put(stopSubmit(ADD_WEB_PAGE_FROM_URL_DIALOG_FORM))
  yield put(reset(ADD_WEB_PAGE_FROM_URL_DIALOG_FORM))
}

function* addWebPageByUrl() {
  yield all([
    takeLatest(galleryActions.addWebPageUsingUrlSuccess, addWebPageUsingUrlSuccess),
    takeLatest(galleryActions.addWebPageUsingUrlFailed, addWebPageUsingUrlFailed),
  ])
}

export default addWebPageByUrl
