import { createSelector } from 'reselect'
import Circlefit from 'circle-fit'
import { AssetType, ContentType, TabPanel } from '../../../Enums'
import convertLangLatToUTM from '../../shared/utils/gpsCoordinates/convertLangLatToUTM'
import { getNavbarCurrentBottomPanel } from '../../customVideo/selectors'
import { getSelectedGalleryItem } from '../../gallery/selectors'

export const getIsSpatialModelTimelineOpen = (state) => state.spatialModel.isTimelineOpen

export const getCenterCoordinatesUTM = createSelector([getSelectedGalleryItem], (page) => {
  if (!page || page.contentType !== ContentType.SpatialModel) {
    return undefined
  }

  const spatialViewAssets = page.assets.filter(
    (asset) => asset.Type == AssetType.SpatialModelImage && asset.Latitude !== null && asset.Longitude !== null
  )
  if (spatialViewAssets.length <= 1) {
    return undefined
  }

  const utmCoordinates = spatialViewAssets.map((asset) => {
    return convertLangLatToUTM(asset.Latitude, asset.Longitude)
  })

  const circlInfo = Circlefit.compute(utmCoordinates)

  return { x: circlInfo.center.x, y: circlInfo.center.y }
})

export const getIsSpatialModelOpen = createSelector(
  [getIsSpatialModelTimelineOpen, getNavbarCurrentBottomPanel],
  (isTimelineOpen, currentPanel) => {
    return isTimelineOpen && currentPanel === TabPanel.SpatialView
  }
)
