import { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Checkbox,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material'

const FilterMenu = ({ anchorEl, open, initial, options, title, onClose, onSubmit }) => {
  const [selectedValues, setSelectedValues] = useState(initial)

  useEffect(() => {
    if (open) {
      setSelectedValues(initial)
    }
  }, [initial, open])

  const onSelectOption = useCallback((selected, value) => {
    if (selected) {
      setSelectedValues((prevValues) => [...prevValues, value])
    } else {
      setSelectedValues((prevValues) => prevValues.filter((v) => v !== value))
    }
  }, [])

  const onClearFilter = () => {
    setSelectedValues([])
  }

  const onSelectAll = () => {
    setSelectedValues(options.map(({ value }) => value))
  }

  const onSubmitFilter = () => {
    onSubmit(selectedValues)
    onClose()
  }

  const noOptionsSelected = selectedValues.length === 0

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      PaperProps={{
        sx: {
          width: 250,
        },
      }}
    >
      <Box display='flex' alignItems='center' justifyContent='space-between' p={2} pt={1} pb={0}>
        <Typography variant='body1' fontWeight={500}>
          {title}
        </Typography>
        <Tooltip title={noOptionsSelected ? 'Select all' : 'Clear fitler'}>
          <Button sx={{ ml: 2 }} size='small' onClick={noOptionsSelected ? onSelectAll : onClearFilter}>
            {noOptionsSelected ? 'Select all' : 'Clear'}
          </Button>
        </Tooltip>
      </Box>
      <List dense>
        {options.map(({ value, name }) => {
          const selected = selectedValues.includes(value)

          return (
            <MenuItem key={value} dense onClick={() => onSelectOption(!selected, value)}>
              <ListItemIcon>
                <Checkbox color='primary' checked={selected} edge='start' size='small' name={name} />
              </ListItemIcon>
              <ListItemText primary={name} />
            </MenuItem>
          )
        })}
      </List>
      <Box p={2} pt={0} pb={1}>
        <Button
          fullWidth
          disabled={noOptionsSelected}
          color='primary'
          size='small'
          variant='contained'
          onClick={onSubmitFilter}
          title={noOptionsSelected ? 'At least one option must be selected' : 'Apply filtration'}
        >
          Apply
        </Button>
      </Box>
    </Popover>
  )
}

FilterMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  initial: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(FilterMenu)
