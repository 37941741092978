import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAudioInputDevice } from '@tabeeb/modules/presentation/selectors'
import { setAudioInputDevice } from '@tabeeb/modules/presentation/actions/devices'
import { getAudioInputDevices } from '@tabeeb/modules/presentation/services/conferenceService'

function useMicrophoneSettings({ open }) {
  const dispatch = useDispatch()
  const [audioInputDevices, setAudioInputDevices] = useState([])
  const selectedAudioInputDevice = useSelector((state) => getAudioInputDevice(state))
  const showInputDevices = audioInputDevices.length && audioInputDevices[0].deviceId

  const handleAudioInputDeviceClick = useCallback(
    (deviceId) => {
      if (deviceId !== selectedAudioInputDevice) {
        dispatch(setAudioInputDevice(deviceId))
      }
    },
    [dispatch, selectedAudioInputDevice]
  )

  const updateDeviceList = useCallback(() => {
    getAudioInputDevices().then((devices) => {
      setAudioInputDevices(devices)

      if (selectedAudioInputDevice && !devices.find((device) => device.deviceId === selectedAudioInputDevice)) {
        dispatch(setAudioInputDevice(devices[0]?.deviceId))
      }
    })
  }, [dispatch, selectedAudioInputDevice])

  useEffect(() => {
    updateDeviceList()
    navigator.mediaDevices.addEventListener('devicechange', updateDeviceList)

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updateDeviceList)
    }
  }, [updateDeviceList, open])

  const returnValues = useMemo(
    () => ({ showInputDevices, handleAudioInputDeviceClick, selectedAudioInputDevice, audioInputDevices }),
    [audioInputDevices, handleAudioInputDeviceClick, selectedAudioInputDevice, showInputDevices]
  )

  return returnValues
}

export default useMicrophoneSettings
