import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  item: null,
  action: null,
  type: null,
}

export default handleActions(
  {
    [rawActions.setPublishDialogState](state, action) {
      return action.payload
    },
    [combineActions(
      rawActions.closePublishDialog,
      rawActions.publishFormSuccess,
      rawActions.publishFormFailed,
      rawActions.unpublishFormSuccess,
      rawActions.unpublishFormFailed,
      rawActions.publishFormFolderSuccess,
      rawActions.publishFormFolderFailed,
      rawActions.unpublishFormFolderSuccess,
      rawActions.unpublishFormFolderFailed,
      rawActions.resetState
    )]() {
      return defaultState
    },
  },
  defaultState
)
