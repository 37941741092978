const geosearchRequests = {
  getGeosearchPointsRequest: () => {
    return {
      url: `api/pages/geosearch`,
      method: 'get',
    }
  },
  getGeosearchThumbnailsByPageIdsRequest: ({ pageIds }) => {
    return {
      url: 'api/pages/geosearch/thumbnails',
      method: 'post',
      data: pageIds,
    }
  },
}

export default geosearchRequests
