import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [combineActions(rawActions.addTwoDLineDrawingRequest)](state, action) {
      return true
    },
    [combineActions(
      rawActions.stop2dLineDrawingProcessing,
      rawActions.addTwoDLineDrawingFailed,
      rawActions.addTwoDLineDrawingSuccess
    )](state, action) {
      return false
    },
    [rawActions.resetState](state, action) {
      return false
    },
  },
  false
)
