import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { useDispatch } from 'react-redux'
import { FormikAutocomplete } from '@tabeeb/modules/shared/forms'
import useSelectionListItems from '@tabeeb/modules/selectionLists/hooks/useSelectionListItems'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

const SelectionListItemFieldComponent = ({ selectionListId, label, field, disabled = false, ...props }) => {
  const dispatch = useDispatch()

  const onError = useCallback(() => {
    dispatch(onAddErrorNotification({ message: 'Failed to load the options' }))
  }, [dispatch])

  const { options, loading } = useSelectionListItems({ selectionListId, onError })

  return (
    <Field
      {...props}
      options={options}
      loading={loading}
      disabled={disabled}
      name={field.name}
      label={label}
      isOptionEqualToValue={(option, value) => option.value.Value === value.Value}
      getOptionLabel={(option) => option.Value || ''}
      getOptionKey={(option) => option.value.Value}
      getOptionDisabled={(option) => option.disabled}
      component={FormikAutocomplete}
    />
  )
}

SelectionListItemFieldComponent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  selectionListId: PropTypes.number.isRequired,

  disabled: PropTypes.bool,
}

export default SelectionListItemFieldComponent
