import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { FormItemType } from '@tabeeb/enums'
import { withStyles } from '@material-ui/core'
import { getFormTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import { getCurrentFormControls } from '@tabeeb/modules/forms/selectors'
import FormCounter from '@tabeeb/modules/forms/components/FormCounter'
import FormAction from '@tabeeb/modules/forms/components/FormAction'
import FormQuestion from '@tabeeb/modules/forms/components/FormQuestion'
import SignatureViewer from '../Signature/SignatureViewer'
import styles from './styles'

const FormControlsList = ({ classes }) => {
  const controlsList = useSelector(getCurrentFormControls)
  const formTitle = useSelector(getFormTitleLowerCase)

  return (
    <div className={classes.container}>
      {!controlsList ? (
        <CircularProgressBar />
      ) : controlsList.length > 0 ? (
        <div className={classes.list}>
          {controlsList.map((control) => {
            switch (control.FormItemType) {
              case FormItemType.Counter:
                return <FormCounter counter={control} key={`counter-${control.AIObjectId}`} />
              case FormItemType.Action:
                return <FormAction action={control} key={`action-${control.Id}`} />
              default:
                return <FormQuestion control={control} key={`question-${control.Id}`} />
            }
          })}
          <SignatureViewer />
        </div>
      ) : (
        <p className={classes.message}>No {formTitle} answers found</p>
      )}
    </div>
  )
}

FormControlsList.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(FormControlsList)
