import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import useStyles from './styles'

const PanelButton = ({ display, Icon, onClick, title, disabled }) => {
  const classes = useStyles()

  if (!display) {
    return null
  }

  return (
    <Button
      size='small'
      startIcon={<Icon color='action' />}
      variant='contained'
      disableElevation
      title={title}
      onClick={onClick}
      disabled={disabled}
      className={classes.button}
      classes={{ startIcon: classes.icon }}
    />
  )
}

PanelButton.propTypes = {
  display: PropTypes.bool.isRequired,
  Icon: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default React.memo(PanelButton)
