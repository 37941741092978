export default (theme) => {
  return {
    container: {
      display: 'flex',
      padding: theme.spacing(),
      flexGrow: 1,
      alignItems: 'stretch',
      minWidth: 0,
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
    },
    avatar: {
      marginRight: theme.spacing() / 2,
      width: 16,
      height: 16,
      fontSize: 9,
      lineHeight: 'inherit',
    },
  }
}
