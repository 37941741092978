import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.setWorkbookSheetnames](state, action) {
      return action.payload
    },
    [rawActions.clearWorkbookSheetnames](state, action) {
      return defaultState
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
