import { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Autocomplete, TextField } from '@mui/material'

import { getPowerBIReportProvidersForSearch } from '@tabeeb/modules/powerBIReports/actions'
import { useApiRequest, useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'

const ReportingProviderSelect = ({ defaultValue, onChange, onBlur, inputName, helperText, variant = 'standard' }) => {
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebouncedValue(search, 300)

  const { loading, response } = useApiRequest({
    request: getPowerBIReportProvidersForSearch.request,
    payload: debouncedSearch,
  })

  const onSearchChange = useCallback((event) => {
    const { value } = event.target
    setSearch(value)
  }, [])

  const options = useMemo(() => {
    if (!response) {
      return []
    }
    return response.map((m) => ({ label: m.Name, id: m.Id }))
  }, [response])

  return (
    <Autocomplete
      disablePortal
      size='small'
      name={inputName}
      options={options}
      loading={loading}
      onChange={onChange}
      onBlur={onBlur}
      defaultValue={defaultValue}
      ListboxProps={{ style: { maxHeight: '150px' } }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={inputName}
          label='Reporting provider'
          variant={variant}
          onChange={onSearchChange}
          helperText={helperText}
        />
      )}
    />
  )
}

ReportingProviderSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  inputName: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  defaultValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
}

export default ReportingProviderSelect
