import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.getFormControlsRequest](state, action) {
      return true
    },
    [rawActions.setFormAnswersLoading](state, action) {
      return action.payload
    },
    [rawActions.setFormAnswers](state, action) {
      return defaultState
    },
    [rawActions.getContentFormSuccess](state, action) {
      return defaultState
    },
    [rawActions.resetFormsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
