import { createSelector } from 'reselect'

export const getStorages = (state) => state.sessionsPage.storages
export const getSelectedStorage = (state) => state.sessionsPage.selectedStorage

export const getDefaultStorage = createSelector([getStorages], (storages) => {
  return storages.find((storage) => storage.IsDefault === true) || null
})

export const getSelectedStorageOrDefault = createSelector(
  [getSelectedStorage, getDefaultStorage],
  (selectedStorage, defaultStorage) => {
    return selectedStorage || defaultStorage
  }
)
