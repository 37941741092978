import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [combineActions(rawActions.getAiObjectsForProjectTraining.success)](state, action) {
      return [...action.response.data]
    },
  },
  defaultState
)
