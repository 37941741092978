import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.getSelectionLists.success](state, action) {
      return [...action.response.data]
    },
    [rawActions.createSelectionList.success](state, action) {
      return [...state, action.response.data].sort((a, b) => a.Name.localeCompare(b.Name))
    },
    [rawActions.updateSelectionList.success](state, { response }) {
      const updatedSelectionList = response.data
      return state.map((selectionList) =>
        selectionList.Id === updatedSelectionList.Id ? { ...selectionList, ...updatedSelectionList } : selectionList
      )
    },
  },
  defaultState
)
