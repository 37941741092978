import { useCallback } from 'react'
import PropTypes from 'prop-types'

import { RichTextEditor } from '@tabeeb/uikit'

const FormikRichTextEditor = ({ field, form, ...props }) => {
  const { name } = field
  const { setFieldValue } = form

  const onChange = useCallback((newValue) => setFieldValue(name, newValue), [name, setFieldValue])

  return <RichTextEditor {...field} {...props} onChange={onChange} />
}

FormikRichTextEditor.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
}

export default FormikRichTextEditor
