import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  primaryColumns: {
    loaded: false,
    columns: [],
  },
  customColumns: {
    columns: [],
    totalCount: 0,
    pageSize: 40,
  },
  loading: false,
}

export default handleActions(
  {
    [rawActions.getTenantCustomColumns.request](state, action) {
      return { ...state, loading: true }
    },
    [rawActions.getTenantPrimaryColumns.request](state, action) {
      return { ...state, loading: true }
    },
    [rawActions.getTenantPrimaryColumns.success](state, action) {
      return { ...state, primaryColumns: { loaded: true, columns: action.response.data }, loading: false }
    },
    [rawActions.getTenantCustomColumns.success](state, action) {
      const newColumns = action.response.data.map((item) => {
        return { Id: item.Id, Name: item.Name }
      })
      let totalCount = 0
      if (newColumns.length !== 0) {
        totalCount = action.response.data[0].TotalCount
      }
      return {
        ...state,
        customColumns: { ...state.customColumns, columns: [...state.customColumns.columns, ...newColumns], totalCount },
        loading: false,
      }
    },
    [rawActions.resetTenantCustomColumnState](state) {
      return { ...state, customColumns: { ...defaultState.customColumns } }
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
