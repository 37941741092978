import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Layer, Rect } from 'react-konva'

import { AnnotationType } from '@tabeeb/enums'

import * as accountSelectors from '@tabeeb/modules/account/selectors'
import { gallerySelectors } from '@tabeeb/modules/gallery'
import * as contentViewerSelectors from '@tabeeb/modules/contentViewer/selectors'
import * as playerToolbarSelectors from '@tabeeb/modules/playerToolbar/selectors'
import * as playerActions from '@tabeeb/modules/player/actions'
import * as playerSelectors from '@tabeeb/modules/player/selectors'

import { callAPIPromise } from '@tabeeb/shared/utils/requests'
import * as rawActions from '../actions'

import Line from '../components/Line'
import Circle from '../components/Circle'
import Stroke from '../components/Stroke'
import Distance from '../components/Distance'
import Polygon from '../components/Polygon'
import Rectangle from '../components/Rectangle'
import ArrowLine from '../components/ArrowLine'
import TextAnnotationEditor from '../components/TextAnnotationEditor'

import { getAnnotationComponentByType, getPointerPosition, getStageOffset } from '../services/annotationsService'

class AnnotationDrawerContainer extends Component {
  state = {
    annotation: null,
    pending: [],
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isDrawing && !this.props.isDrawing) {
      this.setState({ annotation: null, pending: [] })
    }

    if (this.props.isDrawing && this.state.annotation && this.state.annotation.Type !== this.props.type) {
      this.setState({ annotation: null })
    }
  }

  initAnnotation(position, evt) {
    const { userId, pageId, type, width, fontSize, color, currentVideoTimestamp } = this.props

    switch (type) {
      case AnnotationType.Line:
      case AnnotationType.Stroke:
      case AnnotationType.ErasedStroke:
      case AnnotationType.ArrowEnd:
      case AnnotationType.ArrowBoth:
      case AnnotationType.Ellipse:
      case AnnotationType.Ellipse_Filled:
      case AnnotationType.Rectangle:
      case AnnotationType.Rectangle_Filled:
      case AnnotationType.Polygon: {
        return {
          UserId: userId,
          PageId: pageId,
          Type: type,
          Coordinates: [
            {
              Start: position,
              End: position,
            },
          ],
          Color: color,
          Width: width,
          Timestamp: currentVideoTimestamp,
        }
      }
      case AnnotationType.Hotspot:
      case AnnotationType.AIHotspot:
        return {
          UserId: userId,
          PageId: pageId,
          Type: type,
          Anchor: {
            X: position.X,
            Y: position.Y,
          },
          Color: color,
          Width: width,
          Timestamp: currentVideoTimestamp,
        }
      case AnnotationType.Text:
        return {
          UserId: userId,
          PageId: pageId,
          Type: type,
          TopLeft: {
            X: position.X,
            Y: position.Y,
          },
          X: evt.offsetX,
          Y: evt.offsetY,
          Text: '',
          FontSize: fontSize,
          Color: color,
          Width: width,
          Timestamp: currentVideoTimestamp,
        }
      case AnnotationType.Distance: {
        return {
          UserId: userId,
          PageId: pageId,
          Type: type,
          Points: [position, position],
          Color: color,
          Width: width,
          Timestamp: currentVideoTimestamp,
        }
      }
      default: {
        return null
      }
    }
  }

  _handleMouseDown = (e) => {
    const { annotation } = this.state
    if (!annotation) {
      this._handleStartCreation(e)
      return
    }

    const position = getPointerPosition(e.target)

    if (annotation.Type === AnnotationType.Polygon) {
      const updated = {
        ...annotation,
        Coordinates: [
          ...annotation.Coordinates,
          {
            Start: {
              X: position.X,
              Y: position.Y,
            },
            End: {
              X: position.X,
              Y: position.Y,
            },
          },
        ],
      }

      this.setState({ annotation: updated })
    }

    if (annotation.Type === AnnotationType.Distance) {
      const updated = {
        ...annotation,
        Points: [...annotation.Points, position],
      }

      this.setState({ annotation: updated })
    }
  }

  _handleStartCreation = (e) => {
    if (this.state.annotation) {
      return
    }

    const { isVideoPlaying, isHotspot, isAiHotspot } = this.props

    if (isVideoPlaying) {
      const {
        playerActions: { onPlayerPaused },
      } = this.props

      onPlayerPaused()
    }

    const { target, evt } = e

    const position = getPointerPosition(target)

    const annotation = this.initAnnotation(position, evt)
    annotation.isHotspot = isHotspot
    annotation.isAiHotspot = isAiHotspot
    annotation.isVideoPlaying = isVideoPlaying

    this.setState({ annotation })

    if (annotation.Type === AnnotationType.Hotspot || annotation.Type === AnnotationType.AIHotspot) {
      this._handleSubmitCreation()
    }
  }

  _handleMove = ({ target }) => {
    const { contentScale } = this.props
    const { annotation } = this.state
    if (!annotation) return

    const position = getPointerPosition(target)

    let updated = null
    switch (annotation.Type) {
      case AnnotationType.Line:
      case AnnotationType.ArrowEnd:
      case AnnotationType.ArrowBoth:
      case AnnotationType.Ellipse:
      case AnnotationType.Ellipse_Filled:
      case AnnotationType.Rectangle:
      case AnnotationType.Rectangle_Filled: {
        updated = {
          ...annotation,
          Coordinates: [
            {
              Start: {
                ...annotation.Coordinates[0].Start,
              },
              End: {
                X: position.X,
                Y: position.Y,
              },
            },
          ],
        }

        this.setState({ annotation: updated })

        break
      }
      case AnnotationType.Polygon: {
        const lastCoordinateIndex = annotation.Coordinates.length - 1

        updated = {
          ...annotation,
          Coordinates: [...annotation.Coordinates],
        }

        updated.Coordinates[lastCoordinateIndex].End = {
          X: position.X,
          Y: position.Y,
        }

        this.setState({ annotation: updated })

        break
      }
      case AnnotationType.Distance: {
        const lastPointIndex = annotation.Points.length - 1

        updated = {
          ...annotation,
          Points: [...annotation.Points],
        }

        updated.Points[lastPointIndex] = {
          X: position.X,
          Y: position.Y,
        }

        this.setState({ annotation: updated })

        break
      }
      case AnnotationType.Stroke:
      case AnnotationType.ErasedStroke: {
        const stageOffset = getStageOffset(target)
        const lastPointerPosition = annotation.Coordinates[annotation.Coordinates.length - 1].End

        annotation.Coordinates.push({
          Start: {
            ...lastPointerPosition,
          },
          End: {
            X: position.X,
            Y: position.Y,
          },
        })

        const context = target.getContext('2d')

        context.strokeStyle = annotation.Color
        context.lineCap = 'round'
        context.lineJoin = 'round'
        context.lineWidth = annotation.Width * contentScale
        context.globalCompositeOperation = 'source-over'

        context.beginPath()
        context.moveTo(
          (lastPointerPosition.X + stageOffset.X) * contentScale,
          (lastPointerPosition.Y + stageOffset.Y) * contentScale
        )
        context.lineTo((position.X + stageOffset.X) * contentScale, (position.Y + stageOffset.Y) * contentScale)
        context.closePath()
        context.stroke()

        break
      }
      default: {
        break
      }
    }
  }

  _handleUpdateText = (text) => {
    const { annotation } = this.state
    if (!annotation) return

    const updated = {
      ...annotation,
      Text: text,
    }

    this.setState({ annotation: updated })

    this._handleSubmitCreation()
  }

  getAnnotation = (annotation, props = {}) => {
    if (!annotation) return null

    const { contentScale } = this.props

    switch (annotation.Type) {
      case AnnotationType.Text:
        return (
          <TextAnnotationEditor
            annotation={annotation}
            x={annotation.X}
            y={annotation.Y}
            onSubmit={this._handleUpdateText}
            {...props}
          />
        )
      case AnnotationType.Stroke:
        return <Stroke annotation={annotation} {...props} />
      case AnnotationType.Line:
        return <Line annotation={annotation} {...props} />
      case AnnotationType.ArrowEnd:
      case AnnotationType.ArrowBoth:
        return <ArrowLine annotation={annotation} {...props} />
      case AnnotationType.Rectangle:
      case AnnotationType.Rectangle_Filled:
        return <Rectangle annotation={annotation} {...props} />
      case AnnotationType.Ellipse:
      case AnnotationType.Ellipse_Filled:
        return <Circle annotation={annotation} {...props} />
      case AnnotationType.Distance:
        return (
          <Distance
            annotation={annotation}
            contentScale={contentScale}
            onMarkerClick={this._handleMarkerClick}
            {...props}
          />
        )
      case AnnotationType.Polygon:
        return (
          <Polygon
            annotation={annotation}
            contentScale={contentScale}
            onMarkerClick={this._handleMarkerClick}
            {...props}
          />
        )
      default:
        return null
    }
  }

  _handleMarkerClick = ({ x, y }) => {
    const { annotation } = this.state
    if (!annotation) {
      return
    }

    if (annotation.Type === AnnotationType.Polygon) {
      const lastCoordinateIndex = annotation.Coordinates.length - 1
      const lastCoordinate = annotation.Coordinates[lastCoordinateIndex]

      if (x === lastCoordinate.Start.X && y === lastCoordinate.Start.Y) {
        this._handleSubmitCreation()
      }

      const firstCoordinate = annotation.Coordinates[0]
      if (x === firstCoordinate.Start.X && y === firstCoordinate.Start.Y) {
        this._handleSubmitCreation()
      }
    }

    if (annotation.Type === AnnotationType.Distance) {
      const SAME_POINT_THRESHOLD = 0.001

      const submitPoint = annotation.Points[annotation.Points.length - 2]

      if (Math.abs(x - submitPoint.X) < SAME_POINT_THRESHOLD && Math.abs(y - submitPoint.Y) < SAME_POINT_THRESHOLD) {
        this._handleSubmitCreation()
      }
    }
  }

  _handleSubmitCreation = (e) => {
    const { annotation, pending } = this.state
    if (!annotation) {
      return
    }

    if (annotation.Type === AnnotationType.Polygon) {
      const lastCoordinateIndex = annotation.Coordinates.length - 1

      annotation.Coordinates[lastCoordinateIndex].End.X = annotation.Coordinates[0].Start.X
      annotation.Coordinates[lastCoordinateIndex].End.Y = annotation.Coordinates[0].Start.Y
    }

    if (annotation.Type === AnnotationType.Distance) {
      annotation.Points.pop()
    }

    this.setState({ annotation: null, pending: [...pending, annotation] })

    if (annotation.Type === AnnotationType.Text && annotation.Text === '') {
      return
    }

    callAPIPromise(rawActions.addAnnotationRequest(annotation))
      .then(() => {
        this.setState({ pending: this.state.pending.filter((item) => item !== annotation) })
      })
      .catch(() => {
        this.setState({ pending: this.state.pending.filter((item) => item !== annotation) })
      })
  }

  render() {
    const { isDrawing, contentScale } = this.props
    const { annotation, pending } = this.state

    const submitOnMouseUp = Boolean(
      annotation &&
        annotation.Type !== AnnotationType.Text &&
        annotation.Type !== AnnotationType.Polygon &&
        annotation.Type !== AnnotationType.Distance
    )

    return isDrawing ? (
      <Layer
        name='drawer'
        onMouseDown={this._handleMouseDown}
        onMouseMove={this._handleMove}
        onMouseUp={submitOnMouseUp && this._handleSubmitCreation}
      >
        <Rect fill='transparent' height={10000} width={10000} />
        {this.getAnnotation(annotation)}
        {pending.map((annotation, index) => {
          const PendingAnnotation = getAnnotationComponentByType(annotation.Type)
          return (
            PendingAnnotation && <PendingAnnotation annotation={annotation} contentScale={contentScale} key={index} />
          )
        })}
      </Layer>
    ) : null
  }
}

AnnotationDrawerContainer.propTypes = {
  scale: PropTypes.number.isRequired,
  contentScale: PropTypes.number.isRequired,
  pageId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  currentVideoTimestamp: PropTypes.number.isRequired,
  isDrawing: PropTypes.bool.isRequired,
  isHotspot: PropTypes.bool.isRequired,
  isAiHotspot: PropTypes.bool.isRequired,
  isVideoPlaying: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    addAnnotationRequest: PropTypes.func.isRequired,
  }).isRequired,
  playerActions: PropTypes.shape({
    onPlayerPaused: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isDrawing: state.playerToolbar.drawing.drawingState.isDrawing,
    isHotspot: state.playerToolbar.drawing.buttonState === 'flag',
    isAiHotspot: state.playerToolbar.drawing.buttonState === 'aiflag',
    isVideoPlaying: playerSelectors.getIsVideoPlaying(state),
    color: playerToolbarSelectors.getSelectedColor(state),
    type: state.playerToolbar.drawing.drawingState.type,
    width: playerToolbarSelectors.getWidth(state),
    fontSize: playerToolbarSelectors.getFontSize(state),
    userId: accountSelectors.getCurrentUserId(state),
    pageId: gallerySelectors.getSelectedGalleryItemId(state),
    scale: contentViewerSelectors.getScale(state),
    contentScale: contentViewerSelectors.getContentScale(state),
    currentVideoTimestamp: playerSelectors.getCurrentVideoTimestamp(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
    playerActions: bindActionCreators(playerActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationDrawerContainer)
