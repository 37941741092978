export default {
  getFormPermissionsByTenantRequest: ({ subdomain }) => ({
    url: `api/form-permissions/tenants/${subdomain}`,
    method: 'get',
  }),
  updateFormPermissionsByTenantRequest: ({ permissions, subdomain }) => ({
    url: `api/form-permissions/tenants/${subdomain}`,
    method: 'put',
    data: {
      GrantedPermissions: permissions,
    },
  }),
  enableFormPermissionsSyncByTenantRequest: ({ subdomain }) => ({
    url: `api/form-permissions/tenants/${subdomain}`,
    method: 'delete',
  }),
  getFormPermissionsByNewFormRequest: () => ({
    url: `api/form-permissions/forms/default`,
    method: 'get',
  }),
  getFormPermissionsByFormRequest: ({ formId }) => ({
    url: `api/form-permissions/forms/${formId}`,
    method: 'get',
  }),
  updateFormPermissionsByFormRequest: ({ permissions, formId }) => ({
    url: `api/form-permissions/forms/${formId}`,
    method: 'put',
    data: {
      GrantedPermissions: permissions,
    },
  }),
  enableFormPermissionsSyncByFormRequest: ({ formId }) => ({
    url: `api/form-permissions/forms/${formId}`,
    method: 'delete',
  }),
}
