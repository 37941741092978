import PropTypes from 'prop-types'

import ActivityMarkers from '../ActivityMarkers'

import './styles.less'

const ProgressBar = ({ progressBarTimestamp, progressBarDuration, progressBarPercent, handleChangeAction }) => {
  const progressBarStyle = {
    backgroundImage: `linear-gradient(to right, #4CAF50 ${progressBarPercent}%, #E3FCE4 ${progressBarPercent}%)`,
  }

  return (
    <section className='playerProgressBar'>
      <input
        style={progressBarStyle}
        type='range'
        value={progressBarTimestamp}
        max={progressBarDuration}
        onChange={(event) => handleChangeAction(event, +event.target.value)}
      />
      <ActivityMarkers />
    </section>
  )
}

ProgressBar.propTypes = {
  progressBarTimestamp: PropTypes.number.isRequired,
  progressBarDuration: PropTypes.number.isRequired,
  progressBarPercent: PropTypes.number.isRequired,
  handleChangeAction: PropTypes.func.isRequired,
}

export default ProgressBar
