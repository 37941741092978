import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const getRequest = createAction('GET_TENANTS_REQUEST')
export const getSuccess = createAction('GET_TENANTS_SUCCESS')
export const getFailed = createAction('GET_TENANTS_FAILED')

export const createRequest = createAction('CREATE_TENANT_REQUEST')
export const createSuccess = createAction('CREATE_TENANT_SUCCESS')
export const createFailed = createAction('CREATE_TENANT_FAILED')

export const resetState = createAction('RESET_TENANTS_STATE')
export const resetCreateTenantState = createAction('RESET_CREATE_TENANT_STATE')

export const getBusinessDomains = createApiActions('GET_BUSINESS_DOMAINS')
export const getDefaultCreateTenantConfiguration = createApiActions('GET_DEFAULT_CREATE_TENANT_CONFIGURATION')
