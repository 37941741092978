import PropTypes from 'prop-types'

import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { useMemo } from 'react'

/**
 * @param {boolean} disabled
 * @param {boolean} loading
 * @param {T[]} options
 * @param {object} value
 * @param {(event: object, value: T|T[], reason: string) => void} onChange
 * @param {(event: object) => void} onInputChange
 * @param {string} name
 * @param {AutocompleteProps<T, boolean, undefined, boolean>} AutocompleteProps
 * @param InputProps
 * @template T
 */
const UserSearch = ({
  disabled,
  loading,
  options,
  value,
  onChange,
  onInputChange,
  name,
  AutocompleteProps,
  InputProps,
}) => {
  const label = useMemo(() => {
    if (InputProps.showLabel === false) {
      return ''
    }
    if (InputProps.label?.length > 0) {
      return InputProps.label
    }
    return 'Enter email'
  }, [InputProps])
  return (
    <Autocomplete
      freeSolo
      loading={loading}
      options={options}
      disabled={disabled}
      value={value}
      onChange={onChange}
      {...AutocompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          {...InputProps}
          name={name}
          onChange={onInputChange}
          label={label}
          placeholder={InputProps.placeholder?.length > 0 ? InputProps.placeholder : 'Enter an email...'}
        />
      )}
    />
  )
}

UserSearch.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  options: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  name: PropTypes.string,
  AutocompleteProps: PropTypes.object,
  InputProps: PropTypes.object,
}

export default UserSearch
