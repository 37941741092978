export function formTabsIdsEquals(firstTabIds, secondTabIds) {
  let firstArray = []
  let secondArray = []

  if (firstTabIds) {
    firstArray = firstTabIds
  }

  if (secondTabIds) {
    secondArray = secondTabIds
  }

  if (firstArray.length !== secondArray.length) {
    return false
  }

  for (let i = 0, l = firstArray.length; i < l; i++) {
    if (firstArray[i] !== secondArray[i]) {
      return false
    }
  }

  return true
}
