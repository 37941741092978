import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import moment from 'moment'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import { calendarSelectors } from '@tabeeb/modules/calendar'
import Day from './Day'
import Title from './Title'
import Weekdays from './Weekdays'

import useStyles from './styles'

function createMonthCalendar(year, month) {
  const today = moment()

  const monthStartDate = moment(new Date(year, month)).clone().startOf('month')
  const linesCount = 6

  return Array.from(new Array(linesCount)).map((_, weekNumber) => {
    return Array.from(new Array(7)).map((_, day) => {
      const date = monthStartDate.clone().set('date', 7 * weekNumber + day + 1 - monthStartDate.day())

      return {
        date: date.toDate(),
        today: date.isSame(today, 'date'),
        outside: !monthStartDate.isSame(date, 'month'),
      }
    })
  })
}

const Month = ({ year, month, onSelectDate }) => {
  const classes = useStyles()

  const selectedDate = useSelector(calendarSelectors.getSelectedDate)

  const weeks = useMemo(() => createMonthCalendar(year, month), [year, month])

  const calendar = useMemo(() => {
    return (
      <Table className={classes.table}>
        <TableHead>
          <Weekdays />
        </TableHead>
        <TableBody>
          {weeks.map((week, index) => (
            <TableRow key={index}>
              {week.map(({ date, today, outside }, index) => (
                <TableCell key={`week-${index}`} padding='none'>
                  <Day
                    date={date}
                    today={today}
                    selected={moment(selectedDate).isSame(date, 'date')}
                    outside={outside}
                    onClick={onSelectDate}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }, [weeks, moment(selectedDate).isSame(new Date(year, month), 'month') && selectedDate])

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Title value={moment(new Date(year, month)).format('MMMM')} />
        {calendar}
      </div>
    </div>
  )
}

Month.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  onSelectDate: PropTypes.func.isRequired,
}

export default memo(Month)
