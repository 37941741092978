import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Stop } from '@material-ui/icons'

import { RecordingStatus, RecordingType } from '@tabeeb/enums'
import { recordingActions, recordingSelectors } from '@tabeeb/modules/recording'
import { getRecordingTypeDialogResult } from '@tabeeb/modules/whiteboard/selectors'

import ToolbarButton from '../ToolbarButton'

const StopRecordingButton = () => {
  const dispatch = useDispatch()

  const recordingStatus = useSelector(recordingSelectors.getRecordingStatus)
  const recordingType = useSelector(getRecordingTypeDialogResult)

  const onClick = useCallback(() => {
    dispatch(recordingActions.onRecordingStopping())
  }, [dispatch])

  const isDisabled = useMemo(() => {
    switch (recordingType) {
      case RecordingType.cloud:
        return ![RecordingStatus.started, RecordingStatus.paused].includes(recordingStatus)
      case RecordingType.local:
      default:
        return false
    }
  }, [recordingStatus, recordingType])

  return (
    <ToolbarButton
      color='error'
      Icon={Stop}
      display={![RecordingStatus.stopping, RecordingStatus.stopped].includes(recordingStatus)}
      disabled={isDisabled}
      title='Stop recording'
      onClick={onClick}
    />
  )
}

export default memo(StopRecordingButton)
