import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(rawActions.attachVqaModelToFormRequest, rawActions.detachVqaModelFromFormRequest)](
      state,
      { payload }
    ) {
      return true
    },
    [combineActions(
      rawActions.attachVqaModelToFormSuccess,
      rawActions.attachVqaModelToFormFailed,
      rawActions.detachVqaModelFromFormSuccess,
      rawActions.detachVqaModelFromFormFailed
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
