import { put, takeLatest, select, all } from 'redux-saga/effects'
import _ from 'lodash'

import ApiMethods from 'modules/shared/utils/API'
import * as rawActions from '../actions'
import { PageType, AssetType, ContentType } from '../../../Enums'
import updateOrAddGallery from './updateOrAddGallery'
import { contentNotificationService } from '../../shared/content'
import { getInsertionIndex } from '../selectors'

function* addGalleryUploadedSnapshots(action) {
  const { payload } = action

  const { selectedFolderId } = yield select((state) => state.gallery.galleryState)
  const contentId = yield select((state) => state.contentState.contentId)
  const documentId = yield select((state) => state.contentState.documentId)
  const geoLocation = yield select((state) => state.deviceState.geoLocation)

  const insertionIndex = yield select((state) => getInsertionIndex(state))

  const data = []

  const timeOffset = new Date().getTimezoneOffset()
  _.forEach(payload, (item, index) => {
    const itemData = {
      name: '',
      description: '',
      order: insertionIndex + index,
      contentId,
      documentId,
      folderId: selectedFolderId,
      type: PageType.Normal,
      timeZoneOffset: -timeOffset,
      contentType: ContentType.Snapshot,
      assets: [
        {
          type: AssetType.Snapshot,
          url: item.url.substring(0, item.url.lastIndexOf('?')),
          size: item.file.size,
          latitude: geoLocation.latitude,
          longitude: geoLocation.longitude,
          name: item.file.name,
        },
      ],
    }

    data.push(ApiMethods.addGalleryImageUploadRequest(itemData))
  })

  yield put(rawActions.addGallerySnapshotsUploadParallelRequests(data))
}

function* addGalleryUploadSnapshots(action) {
  const pages = action.response.map((page) => page.data)
  const isSelectAddedGalleryItem = false
  yield updateOrAddGallery(isSelectAddedGalleryItem, pages)
  yield contentNotificationService.notifyOfNewPages(pages)
}

function* addSnapshots() {
  yield all([
    takeLatest(rawActions.addGallerySnapshotsUploadFilesSuccess, addGalleryUploadedSnapshots),
    takeLatest(rawActions.addGallerySnapshotsUploadParallelSuccess, addGalleryUploadSnapshots),
  ])
}

export default addSnapshots
