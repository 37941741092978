import { combineReducers } from 'redux'

import events from './events'
import session from './session'
import subscriptions from './subscriptions'
import synchronization from './synchronization'
import editEventDialog from './editEventDialog'
import eventInfoDialog from './eventInfoDialog'
import changeEventOwnerDialog from './changeEventOwnerDialog'
import createEventDialog from './createEventDialog'
import bookAppointmentSlotDialog from './bookAppointmentSlotDialog'
import switchSessionOwnerDialog from './switchSessionOwnerDialog'
import updateCalendarSubscriptionColorDialog from './updateCalendarSubscriptionColorDialog'

export default combineReducers({
  events,
  session,
  subscriptions,
  synchronization,
  editEventDialog,
  eventInfoDialog,
  createEventDialog,
  changeEventOwnerDialog,
  bookAppointmentSlotDialog,
  switchSessionOwnerDialog,
  updateCalendarSubscriptionColorDialog,
})
