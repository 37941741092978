const styles = () => ({
  '@global': {
    legend: {
      borderBottom: 'none',
    },
  },
  textField: {
    width: '100%',
  },
})

export default styles
