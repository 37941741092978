import { handleActions } from 'redux-actions'

import { getPointCloudSettings, updatePointCloudSettings } from '../actions'

const defaultState = 1.0

export default handleActions(
  {
    [getPointCloudSettings.success](state, { response }) {
      return response.data.MeasurementsScaleFactor
    },
    [updatePointCloudSettings.request](state, { payload }) {
      return payload.settings.MeasurementsScaleFactor
    },
  },
  defaultState
)
