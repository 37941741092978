import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { withModernTheme } from '@tabeeb/uikit'
import { getContentId } from '@tabeeb/shared/content/selectors'
import { useSelectedItemMenu } from '@tabeeb/shared/utils/hooks'

import InviteMenu from '../InviteMenu'
import InvitesList from '../InvitesList'
import InviteUsersForm from '../InviteUsersForm'

import { useContentInvites } from '../../hooks'

const InviteUsersDialog = ({ open, onClose }) => {
  const contentId = useSelector(getContentId)

  const {
    loaded,
    invites,
    onCancelInvite,
    onCopyInviteLink,
    onResendInvite,
    onRevokeQuickAccessLink,
    onSetQuickAccessLinkPassword,
    onSendInvites,
    onUpdateInviteTime,
    onUpdateInviteRole,
  } = useContentInvites({
    open,
    contentId,
  })

  const [anchorEl, invite, menuOpen, onOpenMenu, onCloseMenu] = useSelectedItemMenu()

  return (
    <>
      <Dialog maxWidth='md' PaperProps={{ style: { maxWidth: 700 } }} fullWidth open={open} onClose={onClose}>
        <DialogTitle>Invite users</DialogTitle>
        <DialogContent>
          <InviteUsersForm onSendInvites={onSendInvites} />
          <InvitesList
            loaded={loaded}
            invites={invites}
            onUpdateInviteRole={onUpdateInviteRole}
            onOpenMenu={onOpenMenu}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <InviteMenu
        anchorEl={anchorEl}
        invite={invite}
        open={menuOpen}
        onClose={onCloseMenu}
        onCancelInvite={onCancelInvite}
        onCopyInviteLink={onCopyInviteLink}
        onUpdateInviteTime={onUpdateInviteTime}
        onUpdateInviteRole={onUpdateInviteRole}
        onResendInvite={onResendInvite}
        onRevokeQuickAccessLink={onRevokeQuickAccessLink}
        onSetQuickAccessLinkPassword={onSetQuickAccessLinkPassword}
      />
    </>
  )
}

InviteUsersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(withModernTheme(InviteUsersDialog))
