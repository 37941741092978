import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.openTracking](state, action) {
      return true
    },
    [rawActions.closeTracking](state, action) {
      return false
    },
    [rawActions.resetTracking]() {
      return false
    },
  },
  false
)
