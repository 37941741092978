import { handleActions, combineActions } from 'redux-actions'

import * as actions from '../../actions'

const defaultState = false

export default handleActions(
  {
    [actions.getSelectionListForEditById.request]() {
      return true
    },
    [combineActions(
      actions.getSelectionListForEditById.failed,
      actions.getSelectionListForEditById.success,
      actions.getSelectionListForEditById.cancel
    )]() {
      return false
    },
  },
  defaultState
)
