import { combineActions, handleActions } from 'redux-actions'
import {
  closeContentSharingDialog,
  resetState,
  resetUsersSearchState,
  searchUserForSharing,
  updateSearchUserText,
} from '@tabeeb/modules/contentSharings/actions'
import { getSortedUsers } from '@tabeeb/modules/sessionsPage/services/usersSorting'

const defaultState = {
  usersList: [],
  searchText: '',
}

export default handleActions(
  {
    [searchUserForSharing.success](state, { payload: { search }, response: { data } }) {
      return { ...state, usersList: getSortedUsers(data, search) }
    },
    [updateSearchUserText](state, { payload }) {
      return { ...state, searchText: payload }
    },
    [combineActions(resetState, resetUsersSearchState, closeContentSharingDialog)]() {
      return defaultState
    },
  },
  defaultState
)
