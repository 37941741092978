import { memo } from 'react'
import PropTypes from 'prop-types'
import { LinkedContentControl } from '@tabeeb/modules/contentSharings/constants/enums'
import LinkedContentButton from './button'
import { Container } from './styles'

const LinkedContentControls = ({ content }) => {
  return (
    <Container>
      <LinkedContentButton button={LinkedContentControl.Merge} content={content} />
      <LinkedContentButton button={LinkedContentControl.Unlink} content={content} />
    </Container>
  )
}

LinkedContentControls.propTypes = {
  content: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Status: PropTypes.number.isRequired,
    ContentId: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(LinkedContentControls)
