import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.openReviewSessionPagesDialog](state, action) {
      return action.payload
    },
    [rawActions.closeReviewSessionPagesDialog](state, action) {
      return false
    },
    [rawActions.resetState](state, { payload }) {
      return false
    },
  },
  false
)
