import JitsiMeetJS from 'JitsiMeetJS'
import WatchRTC from './watchRTC/WatchRTC'

export function initJitsi() {
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR)
  WatchRTC.init(window.config)
  return JitsiMeetJS.init(window.config)
}

export function initJitsiConnection(accessToken) {
  return new JitsiMeetJS.JitsiConnection(window.config.appId, accessToken, window.config)
}

export function createLocalTracks(initTracks) {
  return JitsiMeetJS.createLocalTracks(initTracks)
}
