import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import { isMobileOnly } from 'react-device-detect'

import { Button, Dialog, DialogActions, Tab, Tabs, Divider, Typography } from '@material-ui/core'

import { PolicyType } from '@tabeeb/enums'
import CircularProgressBar from '@tabeeb/shared/tabeebCircularProgressBar'

import PolicyViewerContainer from '../../containers/PolicyViewerContainer'

import ErrorBar from '../ErrorBar'

import './styles.less'

const UpdateIcon = () => {
  return (
    <Typography variant='caption' className='update-icon'>
      {isMobileOnly ? '!' : 'Updated'}
    </Typography>
  )
}

const PoliciesUpdatePopup = ({
  policies,
  needsUpdate,
  isFailed,
  isLoaded,
  isLoading,
  isAcceptanceInProgress,
  handleClose,
  handleReload,
  handleDeny,
  handleAccept,
}) => {
  const [selectedTab, setSelectedTab] = useState(-1)

  const policiesList = _.orderBy(
    Object.values(policies),
    (policy) => policy.PolicyType !== PolicyType.TenantTerms,
    (policy) => policy.PolicyType
  )

  useEffect(() => {
    if (!isLoaded) {
      return
    }

    if (selectedTab === -1 && policiesList.length > 0) {
      setSelectedTab(policiesList[0].PolicyType)
    }
  }, [isLoaded, policiesList, selectedTab])

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Dialog
      className='policies-update-dialog'
      modal='false'
      PaperProps={{
        style: {
          maxWidth: 1000,
          height: '100%',
        },
      }}
      open
    >
      <div className={classNames('policies-update-dialog-content', { mobile: isMobileOnly })}>
        {isFailed && <ErrorBar text='Failed to get policies' handleReload={handleReload} />}
        {isLoaded && (
          <>
            <Tabs
              className={classNames('tabs-container', { mobile: isMobileOnly })}
              variant='fullWidth'
              indicatorColor='primary'
              value={selectedTab}
              onChange={handleChange}
            >
              <Tab style={{ display: 'none' }} value={-1} />
              {policiesList.map((policy) => (
                <Tab
                  key={policy.Id}
                  label={
                    <>
                      {policy.Name} {!policy.IsAccepted && <UpdateIcon />}
                    </>
                  }
                  value={policy.PolicyType}
                />
              ))}
            </Tabs>
            <Divider />
            <PolicyViewerContainer policyType={selectedTab} />
            <Divider />
            <DialogActions>
              {needsUpdate ? (
                <>
                  <Button title='Accect changes' variant='outlined' color='primary' onClick={handleAccept}>
                    Accept
                  </Button>
                  <Button title='Deny changes' variant='outlined' onClick={handleDeny}>
                    Deny
                  </Button>
                </>
              ) : (
                <Button title='Close' variant='outlined' color='primary' onClick={handleClose}>
                  Close
                </Button>
              )}
            </DialogActions>
          </>
        )}
        {(isLoading || isAcceptanceInProgress) && <CircularProgressBar />}
      </div>
    </Dialog>
  )
}

PoliciesUpdatePopup.propTypes = {
  handleDeny: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleReload: PropTypes.func.isRequired,
  policies: PropTypes.object.isRequired,
  needsUpdate: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAcceptanceInProgress: PropTypes.bool.isRequired,
}

export default PoliciesUpdatePopup
