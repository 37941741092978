import { put, takeLatest, select, all } from 'redux-saga/effects'

import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { TenantPermission } from '@tabeeb/enums'

import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import { mapBillingSettingsValues, mapSessionApprovalSettingsValues } from '../services/valuesMapper'

function* updateBillingSettings({ payload }) {
  const values = payload

  const hasBillingSettingsPermission = yield select((state) =>
    hasTenantPermission(state, TenantPermission.BillingSettings)
  )

  if (hasBillingSettingsPermission) {
    const billingSettings = mapBillingSettingsValues(values)
    yield put(rawActions.updateBillingSettingsRequest(billingSettings))
  }

  const sessionApprovalSettings = mapSessionApprovalSettingsValues(values)
  yield put(rawActions.updateSessionApprovalSettingsRequest(sessionApprovalSettings))
}

function* updateSettingsSuccess() {
  yield put(notificationActions.onAddSuccessNotification({ message: 'Settings updated' }))
}

function* updateSettingsFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'There was an error updating the settings' }))
}

function* billingSettingsSaga() {
  yield all([
    takeLatest(rawActions.updateBillingSettings, updateBillingSettings),
    takeLatest(rawActions.updateSessionApprovalSettingsSuccess, updateSettingsSuccess),
    takeLatest(
      [rawActions.updateSessionApprovalSettingsFailed, rawActions.updateBillingSettingsFailed],
      updateSettingsFailed
    ),
  ])
}

export default billingSettingsSaga
