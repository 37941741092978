import { handleActions } from 'redux-actions'
import {
  tenantAuthFlowCompleted,
  resetTenantAuthenticate,
  setTenantAccountCreated,
  setIsTenantAccountUnexpectedError,
  lockTenantAccount,
} from '../actions'

const defaultState = {
  isAuthFlowCompleted: false,
  isTenantAccountCreated: true,
  isTenantAccountUnexpectedError: false,
  isTenantAccountLocked: true,
}

export default handleActions(
  {
    [tenantAuthFlowCompleted](state, action) {
      return { ...state, isAuthFlowCompleted: true }
    },
    [setTenantAccountCreated](state, { payload }) {
      return { ...state, isTenantAccountCreated: payload }
    },
    [setIsTenantAccountUnexpectedError](state, { payload }) {
      return { ...state, isTenantAccountUnexpectedError: payload }
    },
    [lockTenantAccount](state, { payload }) {
      return { ...state, isTenantAccountLocked: payload }
    },
    [resetTenantAuthenticate]() {
      return defaultState
    },
  },
  defaultState
)
