import { combineReducers } from 'redux'

import billingSettings from './billingSettings'
import isBillingSettingsLoading from './isBillingSettingsLoading'
import sessionApprovalSettings from './sessionApprovalSettings'
import isSessionApprovalSettingsLoading from './isSessionApprovalSettingsLoading'

export default combineReducers({
  billingSettings,
  isBillingSettingsLoading,
  sessionApprovalSettings,
  isSessionApprovalSettingsLoading,
})
