export function calculateValue(initialValue, incrementValue, incrementPeriod, attachedTimes) {
  let isString = false
  let initValue = initialValue
  if (typeof initialValue === 'string') {
    isString = true
    initValue = parseInt(initialValue, 10)
  }

  const valueChangedTimes = Math.floor(attachedTimes / (incrementPeriod + 1))
  let newValue = typeof initValue === 'number' ? initValue + valueChangedTimes * incrementValue : 0

  if (isString) newValue = newValue.toString()

  return newValue
}
