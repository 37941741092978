import { useContext } from 'react'

import PlayingAudioUrlContext from './PlayingAudioUrlContext'

const usePlayingAudioUrl = () => {
  const context = useContext(PlayingAudioUrlContext)

  return context
}

export default usePlayingAudioUrl
