import { createApiActions } from '@tabeeb/shared/utils/actions'
import { createAction } from 'redux-actions'

export const getSelectionLists = createApiActions('GET_SELECTION_LISTS')
export const getSelectionListById = createApiActions('GET_SELECTION_LIST_BY_ID')
export const getSelectionListForEditById = createApiActions('GET_SELECTION_LIST_FOR_EDIT_BY_ID')

export const createSelectionList = createApiActions('CREATE_SELECTION_LIST')
export const updateSelectionList = createApiActions('UPDATE_SELECTION_LIST')
export const deleteSelectionList = createApiActions('DELETE_SELECTION_LIST')

export const getSelectionListItems = createApiActions('GET_SELECTION_LIST_ITEMS')

export const openAddSelectionListDialog = createAction('OPEN_ADD_SELECTION_LIST_DIALOG')
export const openEditSelectionListDialog = createAction('OPEN_EDIT_SELECTION_LIST_DIALOG')
export const closeEditSelectionListDialog = createAction('CLOSE_EDIT_SELECTION_LIST_DIALOG')
