import * as Yup from 'yup'

import { CalendarEventTransparency, CalendarEventVisibility } from '@tabeeb/enums'
import moment from 'moment'

export const VALIDATION_SCHEMAS = {
  attendees: () =>
    Yup.array()
      .of(Yup.object().shape({ Id: Yup.number().positive().required() }).required())
      .required(),
  date: () =>
    Yup.object().shape({
      start: Yup.date().required('Start date is required'),
      end: Yup.date().required('End date is required').min(Yup.ref('start'), "End date can't be before start date"),
    }),
  description: () => Yup.string().nullable().trim().max(120, 'Maximum length is 120 characters'),
  content: () => Yup.object().shape({ Id: Yup.number().positive().required() }).nullable(),
  recurrence: () => Yup.string().nullable(),
  title: () => Yup.string().nullable().trim().max(120, 'Maximum length is 120 characters'),
  transparency: () => Yup.number().oneOf(Object.values(CalendarEventTransparency)).required(),
  visibility: () => Yup.number().oneOf(Object.values(CalendarEventVisibility)).required(),
  interval: () =>
    Yup.number()
      .required()
      .positive()
      .test({
        name: 'lessThan',
        exclusive: false,
        params: {},
        message: "Interval can't be longer than the duration of the event",
        test(value) {
          const {
            date: { start, end },
          } = this.parent

          const eventDurationInMinutes = moment(end).diff(start, 'minutes')

          return value <= eventDurationInMinutes
        },
      }),
}
