import { put, takeLatest, all } from 'redux-saga/effects'

import * as actions from '../actions'
import * as sessionsPageActions from '../../sessionsPage/actions'

function* setSessionsList({ payload, response }) {
  const sessionsList = response.data
  yield put(sessionsPageActions.setSessionsList(sessionsList))
}

function* switchContent() {
  yield all([
    takeLatest(actions.getGeneralContentsToSwitchSuccess, setSessionsList),
    takeLatest(actions.getFolderContentsToSwitchSuccess, setSessionsList),
  ])
}

export default switchContent
