import { AUDIO_DEVICE } from '@tabeeb/modules/presentation/constants'

export class AudioMixerEffect {
  constructor(mixAudio) {
    if (mixAudio.getType() !== AUDIO_DEVICE) {
      throw new Error('AudioMixerEffect only supports audio JitsiLocalTracks; effect will not work!')
    }

    this._mixAudio = mixAudio
  }

  isEnabled(sourceLocalTrack) {
    return sourceLocalTrack.isAudioTrack() && this._mixAudio.isAudioTrack()
  }

  startEffect(audioStream) {
    this._originalStream = audioStream
    this._originalTrack = audioStream.getTracks()[0]

    this._audioMixer = window.JitsiMeetJS.createAudioMixer()
    this._audioMixer.addMediaStream(this._mixAudio.getOriginalStream())
    this._audioMixer.addMediaStream(this._originalStream)

    this._mixedMediaStream = this._audioMixer.start()
    this._mixedMediaTrack = this._mixedMediaStream.getTracks()[0]

    return this._mixedMediaStream
  }

  stopEffect() {
    this._audioMixer.reset()
  }

  setMuted(muted) {
    this._originalTrack.enabled = !muted
  }

  isMuted() {
    return !this._originalTrack.enabled
  }
}
