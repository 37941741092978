import { Component } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getUserById } from '@tabeeb/modules/../users/selectors'
import { getContentId } from '@tabeeb/shared/content/selectors'
import { fallbackUser } from '@tabeeb/modules/shared/users/common'
import * as aiActions from '@tabeeb/modules/artificialIntelligence/actions'
import { getAIObjects } from '@tabeeb/modules/artificialIntelligence/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { TenantPermission } from '@tabeeb/enums'
import {
  openAiReportDialog,
  selectAiObject as selectAiObjectAction,
} from '@tabeeb/modules/uniqueAIObjectAnalysis/actions'

import { getAnnotationById } from '../selectors'
import * as annotationActions from '../actions'
import Message from '../components/Message'
import { getIsAIReportEnabled } from '@tabeeb/modules/appConfigState/selectors'

class MessageContainer extends Component {
  _handleDelete = () => {
    const {
      annotation,
      annotationActions: { deleteContentAnnotations },
    } = this.props

    deleteContentAnnotations({ annotationIds: [annotation.Id] })
  }

  _handleEditConnections = () => {
    const {
      annotation,
      aiActions: { openEditUniqueAiObjectConnectionsDialog },
      annotationActions: { closeHotspotDialog },
    } = this.props

    openEditUniqueAiObjectConnectionsDialog(annotation.UniqueAIObjectId)
    closeHotspotDialog()
  }

  _handleEditAIObjectProperties = () => {
    const {
      annotation,
      aiActions: { setUniqueAiObjectAnnotationForEdit, getUniqueAiObjectForEditRequest },
      annotationActions: { closeHotspotDialog },
    } = this.props

    setUniqueAiObjectAnnotationForEdit(annotation)
    getUniqueAiObjectForEditRequest({ uniqueAIObjectId: annotation.UniqueAIObjectId })
    closeHotspotDialog()
  }

  _handleEditAIObject = () => {
    const {
      aiObjects,
      annotation,
      contentId,
      onEdit,
      aiActions: { setSelectedAiObject },
      getUniqueAiObjects,
    } = this.props

    const selectedAIObject = aiObjects.find((object) => object.Id === annotation.AIObjectId) ?? aiObjects[0]
    if (selectedAIObject) {
      setSelectedAiObject(selectedAIObject)
      getUniqueAiObjects.request({ aiObjectId: selectedAIObject.Id, contentId })
    }
    onEdit(annotation)
  }

  _handleOpenAIObjectReport = () => {
    const {
      annotation,
      aiReportActions: { openAIReportDialog, selectAiObject },
      annotationActions: { closeHotspotDialog },
    } = this.props

    closeHotspotDialog()
    openAIReportDialog()
    selectAiObject({ aiObjectId: annotation.AIObjectId, uniqueAiObjectId: annotation.UniqueAIObjectId })
  }

  render() {
    const { user, annotation, hasAIReportAccess } = this.props
    if (!annotation) {
      return null
    }

    const canEditProperties = Boolean(annotation.UniqueAIObjectId)

    const props = {
      user,
      text: annotation.Text,
      url: annotation.Url,
      createdOn: annotation.CreatedOn,
      canEditObject: Boolean(annotation.AIObjectId),
      canEditProperties,
      canSeeReport: hasAIReportAccess && canEditProperties,
      handleDelete: this._handleDelete,
      handleEditing: this._handleEditAIObject,
      handleEditAIObjectProperties: this._handleEditAIObjectProperties,
      handleOpenAIObjectReport: this._handleOpenAIObjectReport,
      handleEditConnections: this._handleEditConnections,
    }

    return <Message {...props} />
  }
}

MessageContainer.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Text: PropTypes.string,
    Url: PropTypes.string,
    CreatedOn: PropTypes.string.isRequired,
    AIObjectId: PropTypes.number,
    UniqueAIObjectId: PropTypes.number,
  }),
  aiObjects: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  contentId: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }),
  aiActions: PropTypes.shape({
    setSelectedAiObject: PropTypes.func.isRequired,
    setUniqueAiObjectAnnotationForEdit: PropTypes.func.isRequired,
    getUniqueAiObjectForEditRequest: PropTypes.func.isRequired,
    openEditUniqueAiObjectConnectionsDialog: PropTypes.func.isRequired,
  }),
  getUniqueAiObjects: PropTypes.shape({
    request: PropTypes.func.isRequired,
  }).isRequired,
  annotationActions: PropTypes.shape({
    deleteContentAnnotations: PropTypes.func.isRequired,
    closeHotspotDialog: PropTypes.func.isRequired,
  }),
  aiReportActions: PropTypes.shape({
    openAIReportDialog: PropTypes.func.isRequired,
    selectAiObject: PropTypes.func.isRequired,
  }),
  hasAIReportAccess: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, { Id }) => {
  const annotation = getAnnotationById(state, { Id })
  const user = getUserById(state, { Id: annotation?.UserId })

  return {
    user: user || fallbackUser,
    contentId: getContentId(state),
    aiObjects: getAIObjects(state),
    annotation,
    hasAIReportAccess: getIsAIReportEnabled(state) && hasTenantPermission(state, TenantPermission.AIReport),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    aiActions: bindActionCreators(aiActions, dispatch),
    getUniqueAiObjects: bindActionCreators(aiActions.getUniqueAiObjects, dispatch),
    annotationActions: bindActionCreators(annotationActions, dispatch),
    aiReportActions: bindActionCreators(
      { openAIReportDialog: openAiReportDialog, selectAiObject: selectAiObjectAction },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer)
