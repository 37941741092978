import { memo, Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import _ from 'lodash'

import { HealthDataTypesAutocomplete, HealthDataTypesDisplayName, HealthStatisticsTimeLine } from '@tabeeb/enums'

import * as healthActions from '@tabeeb/modules/healthData/actions'

import useStyles from './styles'

const HealthStatisticsChartsSelect = ({ chartsTypesAndNames, onAddHealthDataChart, onRemoveHealthDataChart }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onChange = useCallback(
    (event, newHealthDataCharts, reason) => {
      switch (reason) {
        case 'select-option':
          const healthDataChartToAdd = _.differenceBy(newHealthDataCharts, chartsTypesAndNames, 'dataType').shift()
          healthDataChartToAdd.interval = HealthStatisticsTimeLine.All
          onAddHealthDataChart(healthDataChartToAdd)
          break
        case 'remove-option':
          const healthDataChartToDelete = _.differenceBy(chartsTypesAndNames, newHealthDataCharts, 'dataType').shift()
          onRemoveHealthDataChart(healthDataChartToDelete)
          break
        case 'clear':
          dispatch(healthActions.resetHealthChartsState())
          dispatch(healthActions.resetHealthChartsStateOfViewedPatient())
          break
        default:
          dispatch(healthActions.resetState())
          break
      }
    },
    [dispatch, chartsTypesAndNames, onAddHealthDataChart, onRemoveHealthDataChart]
  )

  return (
    <Autocomplete
      className={classes.chartsSelect}
      multiple
      options={HealthDataTypesAutocomplete}
      disableCloseOnSelect
      getOptionLabel={(option) => HealthDataTypesDisplayName[option.name]}
      renderOption={(option, { selected }) => (
        <Fragment key={option.dataType}>
          <Checkbox checked={selected} color='primary' />
          {HealthDataTypesDisplayName[option.name]}
        </Fragment>
      )}
      renderInput={(props) => <TextField {...props} label='Select types' variant='outlined' />}
      value={chartsTypesAndNames}
      getOptionSelected={(option, value) => option.dataType === value.dataType}
      onChange={onChange}
    />
  )
}

HealthStatisticsChartsSelect.propTypes = {
  chartsTypesAndNames: PropTypes.array.isRequired,
  onAddHealthDataChart: PropTypes.func.isRequired,
  onRemoveHealthDataChart: PropTypes.func.isRequired,
}

export default memo(HealthStatisticsChartsSelect)
