import { handleActions } from 'redux-actions'

import { onRecordingStarting, onSetElapsedTime, resetRecordingState } from '../actions'

const defaultValue = 0

export default handleActions(
  {
    [onSetElapsedTime](state, { payload }) {
      return payload
    },
    [onRecordingStarting](state, { payload }) {
      return 0
    },
    [resetRecordingState](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
