import { useMemo } from 'react'
import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'
import { getPowerBIReportProviderUsers } from '../actions'

const usePowerBIProviderUsers = ({ providerId, enabled }) => {
  const requestParams = useMemo(() => providerId, [providerId])

  const { status: fetchStatus, response: users } = useApiRequest({
    request: getPowerBIReportProviderUsers.request,
    payload: requestParams,
    enabled,
  })

  return { fetchStatus, users: users || [] }
}

export default usePowerBIProviderUsers
