import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as rawActions from '../actions'

function* startLoading(action) {
  const { showPdfSearchResults } = yield select((state) => state.gallery.pdfSearch)
  if (showPdfSearchResults) {
    yield put(rawActions.hidePdfSearchResults())
  }
  yield put(rawActions.startLoading())
}

function* stopLoading(action) {
  const { showPdfSearchResults } = yield select((state) => state.gallery.pdfSearch)
  if (showPdfSearchResults) {
    yield put(rawActions.hidePdfSearchResults())
  }
  yield put(rawActions.stopLoading())
}

function* loadingIndicator() {
  yield all([
    takeLatest(rawActions.addGalleryUploadFiles, startLoading),
    takeLatest(rawActions.addGalleryPdfDocumentsUploadFiles, startLoading),
    takeLatest(rawActions.addGalleryVideosUploadFiles, startLoading),
    takeLatest(rawActions.uploadGalleryVideoByUrlRequest, startLoading),
    takeLatest(rawActions.getGalleryItemRequest, startLoading),
    takeLatest(rawActions.addGalleryUploadFilesFailed, stopLoading),
    takeLatest(rawActions.addGalleryPdfDocumentFailed, stopLoading),
    takeLatest(rawActions.addGalleryVideosUploadFilesFailed, stopLoading),
    takeLatest(rawActions.uploadGalleryVideoByUrlFailed, stopLoading),
    takeLatest(rawActions.addGalleryItem, stopLoading),
    takeLatest(rawActions.onSelectGalleryItem, stopLoading),
  ])
}

export default loadingIndicator
