export const dialogTypes = {
  CopyForm: 'CopyForm',
  DeleteForm: 'DeleteForm',
  PublishForm: 'PublishForm',
  UnpublishForm: 'UnpublishForm',
  EditForm: 'EditForm',
  MoveToFolder: 'MoveToFolder',
  CreateFolder: 'CreateFolder',
  DeleteFolder: 'DeleteFolder',
  RenameFolder: 'RenameFolder',
  PublishFolder: 'PublishFolder',
  UnpublishFolder: 'UnpublishFolder',
}

export const itemTypes = {
  Form: 'form',
  Folder: 'folder',
}
