import { all } from 'redux-saga/effects'

import connectionSaga from './connection'
import conferenceSaga from './conference'
import tracksSaga from './tracks'
import jitsiTrackSaga from './jitsiTrack'

function* presentationSaga() {
  yield all([connectionSaga(), conferenceSaga(), tracksSaga(), jitsiTrackSaga()])
}

export default presentationSaga
