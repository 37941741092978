import { useSelector } from 'react-redux'
import { getCustomIframeTitle, getIsCustomIframeEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { TenantPermission } from '@tabeeb/enums'
import routes from '@tabeeb/routes'
import NavbarTab from '@tabeeb/modules/navbar/components/NavbarTab'

const CustomIFrameTab = ({ ...rest }) => {
  const showCustomIframe = useSelector(
    (state) => getIsCustomIframeEnabled(state) && hasTenantPermission(state, TenantPermission.CustomIframeSettings)
  )
  const customFrameTitle = useSelector(getCustomIframeTitle)

  const props = {
    item: {
      label: customFrameTitle && customFrameTitle?.length !== 0 ? customFrameTitle : 'Custom iframe',
      to: routes.customIframe,
    },
    display: showCustomIframe,
    ...rest,
  }

  return <NavbarTab {...props} />
}

export default CustomIFrameTab
