import { FolderPermission } from '@tabeeb/enums'

export const generateSessionNameParts = (tenantFormAnswers, openedTenantForms) => {
  const sessionNameParts = []
  let formControls = []
  openedTenantForms.forEach((item) => {
    formControls = formControls.concat(
      item.Controls.filter(
        (formControl) => formControl.ControlType === 1 || formControl.ControlType === 3 || formControl.ControlType === 5
      )
    )
  })
  formControls.forEach((control, index) => {
    if (Object.keys(tenantFormAnswers).some((controlId) => controlId == control.Id)) {
      let answer = tenantFormAnswers[control.Id]
      if (typeof answer === 'object') {
        answer = Object.values(answer)
          .map((answerItem) => answerItem.value)
          .filter((value) => value !== null)
          .join(';')
      }
      const data = {
        Order: index,
        Question: control.Label,
        Answer: answer,
      }
      sessionNameParts.push(data)
    }
  })
  return sessionNameParts
}

export const hasFolderPermission = (permissions, permission) => {
  if (!permissions) {
    return false
  }

  return permissions.includes(permission)
}

export const getShowFolderMenu = (folder) => {
  return (
    folder.IsDeleted ||
    hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.DeleteFolder) ||
    hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.LeaveFolder) ||
    hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.AddUsers) ||
    hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.EditUsers)
  )
}
