import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    maxHeight: 200,
    overflowY: 'auto',
    paddingRight: theme.spacing(0.5),
  },
  contentItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 0',
    borderBottom: '1px solid #d3d3d3',
  },
  info: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    overflowWrap: 'break-word',
    fontSize: 15,
  },
  smallText: {
    fontSize: 12,
  },
  button: {
    height: 30,
    borderRadius: 10,
  },
  buttonText: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  previewContainer: {
    width: 50,
    maxWidth: 50,
    height: 50,
    maxHeight: 50,
    marginRight: theme.spacing(),
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
  },
  preview: {
    width: 50,
    maxWidth: 50,
    height: 50,
    maxHeight: 50,
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  expired: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: theme.spacing(0.5),
    background: theme.palette.action.active,
    color: theme.palette.getContrastText(theme.palette.action.active),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
  },
  emptyResult: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
