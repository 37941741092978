import { useState, useEffect, useRef, useCallback } from 'react'
import MediaRecorder from 'audio-recorder-polyfill'

const DATA_AVAILABLE_EVENT = 'dataavailable'
const STOP_EVENT = 'stop'

export default function useAudioRecorder({ onComplete, limit }) {
  const [isRecording, setIsRecording] = useState(false)
  const [duration, setDuration] = useState(0)
  const [audioRecorder, setAudioRecorder] = useState(null)
  const chunksRef = useRef(null)
  const durationTimerRef = useRef(null)
  const durationTimeoutRef = useRef(null)
  const startRecordingDateRef = useRef(null)

  const startAudioRecorder = useCallback(async () => {
    if (!isRecording) {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true })
        const recorder = new MediaRecorder(audioStream)
        setAudioRecorder(recorder)
        chunksRef.current = []

        setIsRecording(true)
      } catch (error) {
        onComplete({
          isSuccess: false,
          error: error.message,
        })
      }
    }
  }, [isRecording, onComplete])

  const stopAudioRecorder = useCallback(() => {
    if (audioRecorder) {
      audioRecorder.stop()
      audioRecorder.stream.getTracks().forEach((track) => track.stop())
    }
  }, [audioRecorder])

  useEffect(() => {
    return () => {
      stopAudioRecorder()
    }
  }, [stopAudioRecorder])

  useEffect(() => {
    if (isRecording) {
      const addChunk = ({ data }) => {
        chunksRef.current.push(data)
      }

      const setBlob = async () => {
        let fileInfo = null
        let errorMessage = null

        try {
          const chunks = chunksRef.current
          const blob = chunks[0]

          const arrayBuffer = await readAsArrayBuffer(blob)
          const audioContext = new window.AudioContext()
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
          const fileDuration = Math.round(audioBuffer.duration * 1000)

          const base64data = await readAsDataURL(blob)

          fileInfo = {
            data: base64data.replace(/^data:.+;base64,/, ''),
            duration: fileDuration,
          }
        } catch (error) {
          errorMessage = error.message
        }

        setIsRecording(false)
        setAudioRecorder(null)
        setDuration(0)
        chunksRef.current = null

        onComplete({
          isSuccess: Boolean(fileInfo),
          file: fileInfo,
          error: errorMessage,
        })
      }

      const updateDuration = () => {
        const startDate = startRecordingDateRef.current
        setDuration(new Date() - startDate)
      }

      const timeoutRecording = () => {
        stopAudioRecorder()
      }

      audioRecorder.addEventListener(DATA_AVAILABLE_EVENT, addChunk)
      audioRecorder.addEventListener(STOP_EVENT, setBlob)
      audioRecorder.start()

      startRecordingDateRef.current = new Date()
      durationTimerRef.current = setInterval(updateDuration, 100)

      if (limit) {
        durationTimeoutRef.current = setTimeout(timeoutRecording, limit)
      }

      return () => {
        audioRecorder.removeEventListener(DATA_AVAILABLE_EVENT, addChunk)
        audioRecorder.removeEventListener(STOP_EVENT, setBlob)

        clearInterval(durationTimerRef.current)
        durationTimerRef.current = null

        if (durationTimeoutRef.current) {
          clearTimeout(durationTimeoutRef.current)
          durationTimeoutRef.current = null
        }
      }
    }
  }, [isRecording, audioRecorder, stopAudioRecorder, onComplete, limit])

  return [isRecording, duration, startAudioRecorder, stopAudioRecorder, audioRecorder]
}

function readAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsArrayBuffer(file)
  })
}

function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}
