import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    icon: {
      fontSize: 30,
      flexBasis: '25%',
      padding: theme.spacing(),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',

      transition: '100ms ease 0s',
      transform: 'scale(1)',

      '&:hover': {
        transform: 'scale(1.2)',
      },
    },
    bright: {
      stroke: '#666666',
    },
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: 200,
    },
  }
})
