import { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core'

import styles from './styles'

const PagePreviewPlaceholderUrl = new URL('@tabeeb/assets/images/image_placeholder.png', import.meta.url)

const PagePreview = ({ classes, url, pageTitle, onClick, notAllowed }) => {
  const title = notAllowed ? 'You are not allowed to see this page' : `Proceed to "${pageTitle}"`

  return (
    <img
      className={classNames(
        classes.preview,
        { [classes.active]: Boolean(onClick) },
        { [classes.disabled]: notAllowed }
      )}
      src={url}
      alt={title}
      title={title}
      onClick={notAllowed ? () => {} : onClick}
    />
  )
}

PagePreview.defaultProps = {
  url: PagePreviewPlaceholderUrl,
}

PagePreview.propTypes = {
  classes: PropTypes.shape({
    preview: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  notAllowed: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

export default memo(withStyles(styles)(PagePreview))
