import { useCallback, useEffect, useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import moment from 'moment'

import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { getMaintenanceMessage } from '@tabeeb/modules/maintenance/actions'

const UpgradeNotification = ({ sx, classNames }) => {
  const [message, setMessage] = useState('')

  const replaceDates = useCallback((messageToFormat) => {
    let formattedMessage = messageToFormat.replace(/<startDateTime>(.*?)<\/startDateTime>/g, (_, match) => {
      return moment.utc(match, 'DD.MM.YYYY HH:mm').local().format('MMMM DD HH:mm')
    })

    formattedMessage = formattedMessage.replace(/<endDateTime>(.*?)<\/endDateTime>/g, (_, match) => {
      return moment.utc(match, 'DD.MM.YYYY HH:mm').local().format('MMMM DD HH:mm')
    })

    return formattedMessage
  }, [])

  useEffect(() => {
    const getMessage = async () => {
      try {
        const {
          response: { data },
        } = await callApiAsync(getMaintenanceMessage.request())

        if (data) {
          const formattedMessage = replaceDates(data)
          setMessage(formattedMessage)
        }
      } catch {
        // ignored
      }
    }
    getMessage()
  }, [replaceDates])

  return (
    message && (
      <Box sx={sx} className={classNames}>
        <Alert variant='filled' severity='warning' sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontWeight: 'fontWeightBold' }}>{message}</Typography>
        </Alert>
      </Box>
    )
  )
}

UpgradeNotification.propTypes = {
  sx: PropTypes.object,
  classNames: PropTypes.string,
}

export default UpgradeNotification
