import PropTypes from 'prop-types'
import { Rect as CanvasRect, Group } from 'react-konva'

import { AnnotationType } from '@tabeeb/enums'

import Transformer from '../Transformer'
import { getHighlightedColor } from '../../services/colorsService'
import { getViewBoxFromAnnotationCoordinates } from '../../helpers'

const Rectangle = ({
  annotation,
  annotationRef,
  opacity,
  hovered,
  hoverProps,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
  transformationProps,
  children,
  transformerRef,
}) => {
  const filled = annotation.Type === AnnotationType.Rectangle_Filled

  const position = Rectangle.getXY(annotation)
  const anchor = Rectangle.getAnchor(annotation)
  const size = Rectangle.getSize(annotation)

  const commonStyles = {
    lineCap: 'round',
    lineJoin: 'round',
    opacity,
  }

  const styles = {
    ...commonStyles,
    fill: annotation.Color,
    fillEnabled: filled,
    stroke: annotation.Color,
    strokeWidth: annotation.Width,
    hitStrokeWidth: annotation.Width * 2,
    strokeEnabled: !filled,
  }

  const hoveredStyles = {
    ...commonStyles,
    fillEnabled: false,
    stroke: getHighlightedColor(annotation.Color),
    strokeWidth: 1,
    strokeHitEnabled: false,
    strokeScaleEnabled: false,
  }

  return (
    <Group {...position} {...dragAndDropProps}>
      <CanvasRect
        id={annotation.Id}
        ref={annotationRef}
        {...size}
        {...styles}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
        {...transformationProps}
      />
      {(selected || hovered) && <CanvasRect {...size} {...hoveredStyles} />}
      {selected && <Transformer transformerRef={transformerRef} />}
      <Group {...anchor}>{children}</Group>
    </Group>
  )
}

Rectangle.getXY = (annotation) => ({
  x: Math.min(annotation.Coordinates[0].Start.X, annotation.Coordinates[0].End.X),
  y: Math.min(annotation.Coordinates[0].Start.Y, annotation.Coordinates[0].End.Y),
})

Rectangle.getAnchor = (annotation) => ({
  x: Rectangle.getSize(annotation).width,
  y: 0,
})

Rectangle.getSize = (annotation) => ({
  width: Math.abs(annotation.Coordinates[0].Start.X - annotation.Coordinates[0].End.X),
  height: Math.abs(annotation.Coordinates[0].Start.Y - annotation.Coordinates[0].End.Y),
})

Rectangle.getViewBox = getViewBoxFromAnnotationCoordinates

Rectangle.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Color: PropTypes.string.isRequired,
    Coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        Start: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
        End: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
      })
    ),
    Type: PropTypes.number.isRequired,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  annotationRef: PropTypes.func,
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  opacity: PropTypes.number,
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
  transformationProps: PropTypes.shape({
    onTransformStart: PropTypes.func.isRequired,
    onTransform: PropTypes.func.isRequired,
    onTransformEnd: PropTypes.func.isRequired,
  }),
  transformerRef: PropTypes.object,

  children: PropTypes.node,
}

export default Rectangle
