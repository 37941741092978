import { handleActions, combineActions } from 'redux-actions'
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'
import * as rawActions from '../actions'
import { GoogleMapsAnnotationTypes, TrackingEventTypes } from '../constants'

const defaultState = {
  trackingTypes: {
    isEnabled: true,
    value: Object.values(GoogleMapsAnnotationTypes),
  },
  eventTypes: {
    isEnabled: true,
    value: Object.values(TrackingEventTypes),
  },
  timePeriod: {
    isEnabled: false,
    value: {
      start: subDays(startOfDay(new Date()), 1).toUTCString(),
      end: addDays(endOfDay(new Date()), 1).toUTCString(),
    },
  },
}

export default handleActions(
  {
    [rawActions.enableTrackingFilter](state, { payload: { name } }) {
      return {
        ...state,
        [name]: {
          ...state[name],
          value: name === 'timePeriod' ? state[name].value : defaultState[name].value,
          isEnabled: true,
        },
      }
    },
    [rawActions.disableTrackingFilter](state, { payload: { name } }) {
      return {
        ...state,
        [name]: {
          ...state[name],
          value: name === 'timePeriod' ? state[name].value : [],
          isEnabled: false,
        },
      }
    },
    [rawActions.setTrackingFilterValue](state, { payload: { name, value } }) {
      return {
        ...state,
        [name]: {
          ...state[name],
          isEnabled: true,
          value,
        },
      }
    },
    [rawActions.resetTracking]() {
      return defaultState
    },
  },
  defaultState
)
