import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Popover } from '@material-ui/core'
import ControlsMenuItem from '../ControlsMenuItem'

const TrashBinControlsMenu = ({ itemList, anchorEl, setAnchorEl }) => {
  const dispatch = useDispatch()

  const handleMenuItemClick = useCallback((action) => {
    setAnchorEl(null)
    dispatch(action())
  })

  return (
    <Popover
      className='gallery-add-menu-popover'
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <div className='gallery-add-menu'>
        {itemList.map((menuItem, index) => {
          return <ControlsMenuItem key={index} item={menuItem} onClick={handleMenuItemClick} />
        })}
      </div>
    </Popover>
  )
}

TrashBinControlsMenu.propTypes = {
  itemList: PropTypes.array.isRequired,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
}

export default TrashBinControlsMenu
