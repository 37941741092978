import { memo } from 'react'
import PropTypes from 'prop-types'

import { alpha, withStyles } from '@material-ui/core/styles'

import Avatar from '@tabeeb/shared/userAvatar'
import { getUserColor } from '@tabeeb/shared/utils/color'

import styles from './styles'

const BACKGROUND_OPACITY = 0.1

const UserAvatar = ({ classes, user }) => {
  return (
    <div className={classes.container}>
      <img
        className={classes.background}
        style={{ backgroundColor: alpha(getUserColor(user), BACKGROUND_OPACITY) }}
        src={user.avatarUrl}
        alt='avatar'
      />
      {user.avatarUrl ? (
        <img className={classes.image} src={user.avatarUrl} alt='avatar' />
      ) : (
        <Avatar className={classes.avatar} user={user} />
      )}
    </div>
  )
}

UserAvatar.propTypes = {
  classes: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.oneOfType([
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      AvatarUrl: PropTypes.string,
      IsAccountDeleted: PropTypes.bool,
    }).isRequired,
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
      isDeleted: PropTypes.bool.isRequired,
      isAccountDeleted: PropTypes.bool,
      avatarUrl: PropTypes.string,
    }).isRequired,
  ]).isRequired,
}

export default memo(withStyles(styles)(UserAvatar))
