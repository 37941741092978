import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PointCloudLocationIcon from '@tabeeb/shared/icons/PointCloudLocation'

import { setPointCloudGeoSearchOpen } from '@tabeeb/modules/pointCloudGeoSearch/actions'

import { selectIsPointCloudGeoSearchOpen } from '@tabeeb/modules/pointCloudGeoSearch/selectors'

import ToolbarButton from '../ToolbarButton'

const GeosearchButton = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsPointCloudGeoSearchOpen)

  const handleClick = useCallback(() => {
    if (isOpen) {
      dispatch(setPointCloudGeoSearchOpen(false))
    } else {
      dispatch(setPointCloudGeoSearchOpen(true))
    }
  }, [dispatch, isOpen])

  return (
    <ToolbarButton
      display
      Icon={PointCloudLocationIcon}
      selected={isOpen}
      onClick={handleClick}
      title={isOpen ? 'Hide model location on the map' : 'Show model location on the map'}
    />
  )
}

export default GeosearchButton
