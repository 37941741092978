export default (theme) => {
  return {
    container: {
      width: 256,
      overflow: 'hidden',
    },
    list: {
      padding: 0,
      maxHeight: 400,
      overflowY: 'auto',
    },
    label: {
      padding: theme.spacing(),
      paddingTop: 0,
    },
    search: {
      width: '100%',
      padding: theme.spacing(),

      '& input': {
        padding: theme.spacing(),
        borderRadius: theme.shape.borderRadius,
        background: '#ECECEC',
      },
    },
  }
}

export const popoverProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
}
