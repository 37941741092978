import { UserStatus } from '@tabeeb/enums'

export const ValidateEmail = (value) => {
  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  const valid = new RegExp(mailformat).test(value)
  return valid
}

export const ValidateValueLength = (value, maxLength) =>
  value.length > maxLength ? `${value.substring(0, maxLength)}...` : value

export const ValidateInputEmails = (arr) => {
  let isValid = true
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].RegistrationStatus !== UserStatus.Registered) {
      isValid = false
      break
    }
  }
  return isValid
}
