import PropTypes from 'prop-types'

import { Layer } from 'react-konva'

import _ from 'lodash'

import { Compass } from '@tabeeb/modules/compass'
import { CompressionType } from '@tabeeb/enums'

import SpatialViewImage from '../../containers/SpatialViewImage'

const getEffectiveImageUrl = (item, zoomed, selected) => {
  let imageUrl = item.Url
  if (item.CompressedMediaUrls && item.CompressedMediaUrls.length > 0) {
    let effectiveCompressionType = CompressionType.Lowest

    if (selected) {
      effectiveCompressionType = zoomed ? CompressionType.Unscaled : CompressionType.Scaled
    }

    let compressedMedia = item.CompressedMediaUrls.find((cmu) => cmu.CompressionType === effectiveCompressionType)
    compressedMedia ??= _.last(_.orderBy(item.CompressedMediaUrls, (cmu) => cmu.CompressionType))

    imageUrl = compressedMedia.Url
  }

  return imageUrl
}

const SpatialView = ({
  assets,
  zoomed,
  contentSize,
  current,
  handleClick,
  handleGoToPage,
  handleReplaceImage,
  handleLoadImage,
  handleWheelMove,
  contentRef,
  stageRef,
  centerCoordinatesUTM,
}) => {
  const selectedAsset = assets[current]

  const compassProps = {
    altitude: selectedAsset && selectedAsset.Metadata ? selectedAsset.Metadata.Altitude : undefined,
    azimuth: selectedAsset?.Metadata?.Azimuth,
    fov: selectedAsset && selectedAsset.Metadata ? selectedAsset.Metadata.FieldOfView : undefined,
    gimbalPitch: selectedAsset?.Metadata?.GimbalPitch,
    location: {
      Latitude: selectedAsset && selectedAsset.Latitude,
      Longitude: selectedAsset && selectedAsset.Longitude,
    },
    centerCoordinatesUTM: {
      x: centerCoordinatesUTM && centerCoordinatesUTM.x,
      y: centerCoordinatesUTM && centerCoordinatesUTM.y,
    },
  }

  return (
    <>
      <Layer name='spatial-view'>
        {assets.map((item, index) => {
          const selected = index === current

          return (
            <SpatialViewImage
              key={item.Id}
              id={item.Id}
              src={getEffectiveImageUrl(item, zoomed, selected)}
              selected={selected}
              contentRef={contentRef}
              onWheel={handleWheelMove}
              onReplace={handleReplaceImage}
              onLoad={handleLoadImage}
              onClick={handleClick}
              onDblClick={handleGoToPage(item.LinkedPageId)}
              {...contentSize}
            />
          )
        })}
      </Layer>
      {stageRef && stageRef.current && <Compass containerRef={stageRef.current.content} {...compassProps} />}
    </>
  )
}

SpatialView.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      Latitude: PropTypes.number,
      Longitude: PropTypes.number,
      Metadata: PropTypes.shape({
        Altitude: PropTypes.number,
        Azimuth: PropTypes.number,
        FieldOfView: PropTypes.number,
        GimbalPitch: PropTypes.number,
      }),
    }).isRequired
  ).isRequired,
  contentSize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  current: PropTypes.number.isRequired,
  handleGoToPage: PropTypes.func.isRequired,
  handleReplaceImage: PropTypes.func.isRequired,
  handleLoadImage: PropTypes.func.isRequired,
  handleWheelMove: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  contentRef: PropTypes.func.isRequired,
  stageRef: PropTypes.instanceOf(Object).isRequired,
  centerCoordinatesUTM: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  zoomed: PropTypes.bool.isRequired,
}

export default SpatialView
