import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import { FormikTextField } from '@tabeeb/shared/forms'

import styles from '../common/styles'
import { FieldsContainer, SubmitButton, HeaderTypography } from '../mobileLogin/styles'
import { registerSchema } from '../../helper'
import MobileLayout from '../mobileLayout'
import { initialCreateProfileValues } from '../CreateProfile'

const CreateProfileMobile = ({ classes, onRegisterUser, userHasAnyTenants, onClickBack }) => {
  return (
    <MobileLayout
      header={<HeaderTypography>Create profile</HeaderTypography>}
      form={
        <Formik
          initialValues={initialCreateProfileValues}
          validationSchema={registerSchema}
          onSubmit={onRegisterUser}
          enableReinitialize
        >
          {(formik) => (
            <Form className={classes.form}>
              <FieldsContainer>
                <Field
                  color='secondary'
                  name='FirstName'
                  label='First Name'
                  size='small'
                  type='text'
                  component={FormikTextField}
                  required
                />
                <Field
                  color='secondary'
                  name='LastName'
                  label='Last Name'
                  size='small'
                  type='text'
                  component={FormikTextField}
                  required
                />
              </FieldsContainer>
              <SubmitButton disabled={!formik.dirty || !formik.isValid} fullWidth type='submit'>
                Sign Up
              </SubmitButton>
              {userHasAnyTenants && (
                <Button variant='text' title='Go back to the choose tenant page' fullWidth onClick={onClickBack}>
                  Back to choose tenant page
                </Button>
              )}
            </Form>
          )}
        </Formik>
      }
    />
  )
}

CreateProfileMobile.propTypes = {
  classes: PropTypes.shape({
    form: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  onRegisterUser: PropTypes.func.isRequired,
  userHasAnyTenants: PropTypes.bool.isRequired,
  onClickBack: PropTypes.func.isRequired,
}

export default withRouter(withStyles(styles)(CreateProfileMobile))
