import qs from 'qs'

const uniqueAIObjectConnectionRequests = {
  addUniqueAiObjectConnectionRequest: (data) => ({
    url: `api/ai/uniqueObjects/connection`,
    method: 'post',
    data,
  }),
  deleteUniqueAiObjectConnectionRequest: ({ uniqueAIObjectConnectionId }) => ({
    url: `api/ai/uniqueObjects/connection/${uniqueAIObjectConnectionId}`,
    method: 'delete',
  }),
  getUniqueAiObjectsByIdsRequest: ({ uniqueAiObjectIds }) => ({
    url: `api/ai/uniqueObjects`,
    method: 'get',
    params: { uniqueAiObjectIds },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }),
}

export default uniqueAIObjectConnectionRequests
