import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'
import { generateId } from '../../presentationToolbar/services/snapshotService'

const defaultState = {
  isDialogOpen: false,
  messages: [],
}

export default handleActions(
  {
    [rawActions.openInfoMessageDialog](state, { payload }) {
      if (payload) {
        const addedMessage = {
          id: generateId(),
          text: payload,
        }
        return { isDialogOpen: true, messages: [...state.messages, addedMessage] }
      }
      return { ...state, isDialogOpen: true }
    },
    [rawActions.closeInfoMessageDialog](state, { payload }) {
      const removedMessage = payload

      return { isDialogOpen: false, messages: state.messages.filter((mes) => mes.id !== removedMessage.id) }
    },
    [rawActions.resetFormsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
