import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Person } from '@material-ui/icons'
import { Avatar, withStyles } from '@material-ui/core'

import UserAvatar from '@tabeeb/modules/shared/userAvatar'

import Filter from '../Filter'

import { selectUsersFilter } from '../../../selectors'

import styles from './styles'

const UserFilter = ({ classes, onChange, value }) => {
  const users = useSelector(selectUsersFilter)

  const defaultItem = useMemo(
    () => ({
      icon: <Avatar className={classes.avatar} />,
      value: -1,
      title: 'All',
      subtitle: 'Display answers of all users',
    }),
    [classes.avatar]
  )

  const items = useMemo(
    () => [
      defaultItem,
      ...users.map((user) => ({
        icon: <UserAvatar className={classes.avatar} user={user} />,
        value: user.id,
        title: user.displayName,
        subtitle: user.email,
      })),
    ],
    [defaultItem, users, classes.avatar]
  )

  return <Filter value={value} icon={Person} label='User' items={items} onChange={onChange} />
}

UserFilter.propTypes = {
  classes: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memo(withStyles(styles)(UserFilter))
