import { all } from 'redux-saga/effects'

import updateConfig from './updateConfig'
import getConfig from './getConfig'
import updateFtpServerSettings from './updateFtpServerSettings'
import getFtpServerSettings from './getFtpServerSettings'
import formSettings from './formSettings'

function* configSagas() {
  yield all([updateConfig(), getConfig(), updateFtpServerSettings(), getFtpServerSettings(), formSettings()])
}

export default configSagas
