/* eslint-disable jsx-a11y/click-events-have-key-events */
import { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentFormSelectedRecord } from '../../selectors'
import { proceedToControlAnswerPage } from '../../actions'
import styles from './styles'

const FormControlLabel = ({ classes, control, isOnReview }) => {
  const dispatch = useDispatch()
  const isFormRecordSelected = Boolean(useSelector(getCurrentFormSelectedRecord))

  return (
    <div
      className={classes.label}
      onClick={isFormRecordSelected ? null : () => dispatch(proceedToControlAnswerPage(control))}
      style={{ opacity: isOnReview ? 0.5 : 1 }}
    >
      {control.Label}
    </div>
  )
}

FormControlLabel.propTypes = {
  control: PropTypes.shape({
    Label: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  isOnReview: PropTypes.bool.isRequired,
}

export default memo(withStyles(styles)(FormControlLabel))
