import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'
import { useMemo } from 'react'
import { getPowerBIReportTenantRoles } from '../actions'

const usePowerBIReportTenantRoles = (reportTenantId, enabled) => {
  const requestParams = useMemo(() => reportTenantId, [reportTenantId])

  const { status: fetchStatus, response: roles } = useApiRequest({
    request: getPowerBIReportTenantRoles.request,
    payload: requestParams,
    enabled,
  })

  return { fetchStatus, roles: roles || [] }
}

export default usePowerBIReportTenantRoles
