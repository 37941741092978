import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(),
    },
    divider: {
      flexGrow: 1,
      backgroundColor: theme.palette.primary.main,
    },
    text: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
  }
})
