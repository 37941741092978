import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'

const InlineButton = ({ label, onClick, icon }) => {
  return (
    <IconButton aria-label={label} title={label} onClick={onClick}>
      {icon}
    </IconButton>
  )
}

InlineButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
}

export default InlineButton
