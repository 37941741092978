import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

import UserFilter from './UserFilter'
import FormFilter from './FormFilter'
import StatusFilter from './StatusFilter'

import { FilterTypes } from '../../constants'

import styles from './styles'

const Filters = ({ value, classes, onChange, userFilter, formFilter, statusFilter }) => {
  const handleUsersChange = useCallback(
    (userId) => {
      onChange({
        filterType: FilterTypes.ByUser,
        value: userId,
      })
    },
    [onChange]
  )

  const handleFormChange = useCallback(
    (formId) => {
      onChange({
        filterType: FilterTypes.ByForm,
        value: formId,
      })
    },
    [onChange]
  )

  const handleStatusesChange = useCallback(
    (statuses) => {
      onChange({
        filterType: FilterTypes.ByStatuses,
        value: statuses,
      })
    },
    [onChange]
  )

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {userFilter && <UserFilter onChange={handleUsersChange} value={value.userId} />}
        {formFilter && <FormFilter onChange={handleFormChange} value={value.formId} />}
        {statusFilter && <StatusFilter onChange={handleStatusesChange} value={value.statuses} />}
      </div>
    </div>
  )
}

Filters.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  userFilter: PropTypes.bool.isRequired,
  formFilter: PropTypes.bool.isRequired,
  statusFilter: PropTypes.bool.isRequired,
  value: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    formId: PropTypes.number.isRequired,
    statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(Filters))
