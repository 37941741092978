import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [rawActions.getAllFoldersInFormsSuccess](state, action) {
      return action.response.data.map((folder) => ({
        id: folder.FolderId,
        name: folder.Name,
        ownerId: folder.UserId,
        ownerName: folder.OwnerName,
        ownerEmail: folder.OwnerEmail,
        ownerAvatarUrl: folder.OwnerAvatarUrl,
        canBeDeleted: folder.CanBeDeleted,
      }))
    },
    [rawActions.clearFoldersList](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
