import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { ConfirmationDialog } from '@tabeeb/uikit'

import * as accountActions from '../../actions'

const DeleteAccountDialog = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const onReject = useCallback(() => {
    onClose()
  }, [onClose])

  const onSubmit = useCallback(() => {
    dispatch(accountActions.deleteAccountRequest())
    onClose()
  }, [dispatch, onClose])

  return (
    <ConfirmationDialog
      isOpen={open}
      onCloseDialog={onClose}
      onReject={onReject}
      onSubmit={onSubmit}
      title='Are you sure you want to delete your account?'
      dialogMessage='Your personal data will be permanently deleted. You will no longer have access to your account, the deletion process cannot be reversed.'
      submitButtonText='Yes, I want to delete my account'
      submitButtonColor='error'
      cancelButtonText='No'
    />
  )
}

DeleteAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(DeleteAccountDialog)
