import { memo } from 'react'
import PropTypes from 'prop-types'

import { Field } from 'formik'

import { FormikSelect } from '@tabeeb/shared/forms'

const PeriodField = ({ name, label, disabled }) => {
  return (
    <Field
      id='invitation-period-select'
      name={name}
      label={label}
      disabled={disabled}
      component={FormikSelect}
      options={[
        { name: 'Hour', value: 'hour' },
        { name: 'Day', value: 'day' },
        { name: 'Month', value: 'month' },
      ]}
      size='small'
    />
  )
}

PeriodField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default memo(PeriodField)
