import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      zIndex: 10,
      position: 'absolute',
      maxWidth: 155,
    },
    controls: {
      padding: theme.spacing(0.5),
      textAlign: 'left',
    },
    info: {
      display: 'flex',
    },
    indicators: {
      display: 'flex',
      padding: theme.spacing(0.5),

      '& > div': {
        marginRight: theme.spacing(0.5),
      },
    },
  }
})
