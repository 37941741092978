import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'
import PropTypes from 'prop-types'
import * as rawActions from '../actions/index'
import { getPagesForCustomVideo } from '../../customVideo/actions'
import ExportCustomVideoOptionsDialog from '../components/ExportCustomVideoOptionsDialog/index'
import { CustomVideoOptions } from '../../../Enums'

class ExportCustomVideoOptionsDialogContainer extends Component {
  _setCustomVideoOption = (event, value) => {
    const { actions } = this.props
    actions.setCustomVideoOptionsDialogState(parseInt(value))
  }

  _next = () => {
    const { actions, getPagesForCustomVideo } = this.props
    actions.closeCustomVideoOptionsDialog()
    getPagesForCustomVideo()
  }

  render() {
    const { formTitle, isCustomVideoOptionsDialogOpen, isShowProgress, actions, customVideoOptionsDialogState } =
      this.props

    const CustomVideoOptionsValues = {
      [CustomVideoOptions.PagesInGallery]: 'Pages In Gallery',
      [CustomVideoOptions.FormQuestions]: `${formTitle} questions`,
    }

    const exportOptions = Object.values(CustomVideoOptions).map((option) => ({
      Id: option,
      Value: CustomVideoOptionsValues[option],
    }))

    const props = {
      isCustomVideoOptionsDialogOpen,
      closeDialog: actions.closeCustomVideoOptionsDialog,
      next: this._next,
      isShowProgress,
      setCustomVideoOption: this._setCustomVideoOption,
      selectedOption: customVideoOptionsDialogState,
      exportOptions,
    }
    return <ExportCustomVideoOptionsDialog {...props} />
  }
}

ExportCustomVideoOptionsDialogContainer.propTypes = {
  isCustomVideoOptionsDialogOpen: PropTypes.bool.isRequired,
  customVideoOptionsDialogState: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  getPagesForCustomVideo: PropTypes.func.isRequired,
  isShowProgress: PropTypes.bool.isRequired,
  formTitle: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
    getPagesForCustomVideo: bindActionCreators(getPagesForCustomVideo, dispatch),
  }
}

const mapStateToProps = (state) => {
  return {
    isCustomVideoOptionsDialogOpen: state.gallery.isCustomVideoOptionsDialogOpen,
    customVideoOptionsDialogState: state.gallery.customVideoOptionsDialogState,
    isShowProgress: state.gallery.assetsList.inProgress,
    formTitle: getFormTitle(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportCustomVideoOptionsDialogContainer)
