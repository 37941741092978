import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addSessionsToSelection, removeSessionsFromSelection, setSelectedSessions } from '../actions'
import { getSelectedSessionsIds } from '../selectors'

export default () => {
  const dispatch = useDispatch()

  const selectedContentIds = useSelector(getSelectedSessionsIds)

  const addToSelection = useCallback(
    (sessions) => {
      dispatch(addSessionsToSelection(sessions))
    },
    [dispatch]
  )

  const removeFromSelection = useCallback(
    (sessions) => {
      dispatch(removeSessionsFromSelection(sessions))
    },
    [dispatch]
  )

  const resetSelection = useCallback(() => {
    dispatch(setSelectedSessions([]))
  }, [dispatch])

  return useMemo(
    () => ({
      selectedContentIds,
      addToSelection,
      removeFromSelection,
      resetSelection,
    }),
    [selectedContentIds, addToSelection, removeFromSelection, resetSelection]
  )
}
