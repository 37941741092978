import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { Box, Avatar, IconButton } from '@material-ui/core'

import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import Title from './Title'
import ChatAvatar from './ChatAvatar'
import ParticipantsList from './ParticipantsList'

import { useChatContext } from '../../contexts'

import * as chatSelectors from '../../selectors'

import useStyles from './styles'

const MAX_CHATS_TO_SHOW = 6

const ChatsList = () => {
  const classes = useStyles()

  const { setReceiverId } = useChatContext()
  const [buttonRef, open, onOpen, onClose] = usePopoverState()

  const receivers = useSelector(chatSelectors.getParticipantsList)

  const onSelectParticipant = useCallback(
    (user) => () => {
      onClose()
      setReceiverId(user.id)
    },
    [onClose, setReceiverId]
  )

  const showSelector = receivers.length > MAX_CHATS_TO_SHOW

  return (
    <div className={classes.container}>
      <Title />
      <Box className={classes.list}>
        <ChatAvatar userId={null} />
        {receivers.slice(0, MAX_CHATS_TO_SHOW).map((user) => (
          <ChatAvatar key={user.id} userId={user.id} />
        ))}

        {showSelector && (
          <>
            <IconButton size='small' ref={buttonRef} onClick={onOpen}>
              <Avatar>+{receivers.length - MAX_CHATS_TO_SHOW}</Avatar>
            </IconButton>
            <ParticipantsList
              open={open}
              anchorEl={buttonRef.current}
              onClose={onClose}
              onSelect={onSelectParticipant}
            />
          </>
        )}
      </Box>
    </div>
  )
}

export default memo(ChatsList)
