import { combineActions, handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../actions'

const initialState = {
  fetchStatus: FetchStatus.Idle,
  saveStatus: FetchStatus.Idle,
  deleteStatus: FetchStatus.Idle,
  providerStatusUpdateFetchStatus: FetchStatus.Idle,
  provider: {
    Id: 0,
    Name: '',
    OwnerId: '',
    AzureTenantId: '',
    AzureClientId: '',
    AzureClientSecret: '',
  },
}

export default handleActions(
  {
    [rawActions.getPowerBIReportProvider.request]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [rawActions.getPowerBIReportProvider.failed]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
    [rawActions.getPowerBIReportProvider.success]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loaded, provider: action.response.data }
    },
    [rawActions.deletePowerBIReportProvider.request]: (state, _action) => {
      return { ...state, deleteStatus: FetchStatus.Loading }
    },
    [rawActions.deletePowerBIReportProvider.failed]: (state, _action) => {
      return { ...state, deleteStatus: FetchStatus.Failed }
    },
    [rawActions.deletePowerBIReportProvider.success]: (state, _action) => {
      return { ...state, deleteStatus: FetchStatus.Loaded }
    },
    [combineActions(rawActions.disableReportProvider.request, rawActions.enableReportProvider.request)]: (
      state,
      _action
    ) => {
      return { ...state, providerStatusUpdateFetchStatus: FetchStatus.Loading }
    },
    [combineActions(rawActions.disableReportProvider.failed, rawActions.enableReportProvider.failed)]: (
      state,
      _action
    ) => {
      return { ...state, providerStatusUpdateFetchStatus: FetchStatus.Failed }
    },
    [combineActions(rawActions.disableReportProvider.success, rawActions.enableReportProvider.success)]: (
      state,
      _action
    ) => {
      return {
        ...state,
        providerStatusUpdateFetchStatus: FetchStatus.Loaded,
        provider: { ...state.provider, IsAvailable: !state.provider.IsAvailable },
      }
    },
    [combineActions(
      rawActions.updatePowerBIReportProvider.request,
      rawActions.updatePowerBIReportProviderUsers.request
    )]: (state, _action) => {
      return { ...state, saveStatus: FetchStatus.Loading }
    },
    [combineActions(rawActions.updatePowerBIReportProvider.failed, rawActions.updatePowerBIReportProviderUsers.failed)]:
      (state, _action) => {
        return { ...state, saveStatus: FetchStatus.Failed }
      },
    [combineActions(
      rawActions.updatePowerBIReportProvider.success,
      rawActions.updatePowerBIReportProviderUsers.success
    )]: (state, _action) => {
      return { ...state, saveStatus: FetchStatus.Loaded }
    },
  },
  initialState
)
