import { memo } from 'react'
import PropTypes from 'prop-types'

import { DialogTypes } from '@tabeeb/modules/sessionsPage/enums'

import ShareSessionDialogContainer from '@tabeeb/modules/sessionsPage/containers/ShareSessionDialogContainer'

import usePageDialog from '@tabeeb/modules/sessionsPage/hooks/usePageDialog'

import ActivateSessionDialog from './ActivateSessionDialog'
import BulkCopySessionDialog from './BulkCopySessionDialog'
import CopySessionDialog from './CopySessionDialog'
import DeleteSessionDialog from './DeleteSessionDialog'
import DeleteSessionPermanentlyDialog from './DeleteSessionPermanentlyDialog'
import JoinSessionReviewDialog from './JoinSessionReviewDialog'
import LeaveSessionDialog from './LeaveSessionDialog'
import RestoreSessionDialog from './RestoreSessionDialog'
import PublishSessionDialog from './PublishSessionDialog'
import UnpublishSessionDialog from './UnpublishSessionDialog'

import CreateSessionWithAdminPermissionRequestDialog from './CreateSessionWithAdminPermissionRequestDialog'

import CreateFolderDialog from './CreateFolderDialog'
import DeleteFolderDialog from './DeleteFolderDialog'
import DeleteFolderPermanentlyDialog from './DeleteFolderPermanentlyDialog'
import LeaveFolderDialog from './LeaveFolderDialog'
import ManageFolderUsersDialog from './ManageFolderUsersDialog'
import RenameFolderDialog from './RenameFolderDialog'
import RestoreFolderDialog from './RestoreFolderDialog'

import AddUsersToSessionsDialog from './AddUsersToSessionsDialog'
import ChangeSessionsReviewStatusDialog from './ChangeSessionsReviewStatusDialog'
import RestoreSessionsDialog from './RestoreSessionsDialog'
import ShareSessionsDialog from './ShareSessionsDialog'
import SwitchSessionsOwnerDialog from './SwitchSessionsOwnerDialog'
import SwitchSessionsReviewerDialog from './SwitchSessionsReviewerDialog'
import SwitchSessionsStorageDialog from './SwitchSessionsStorageDialog'

import SelectedSessionsTaskStateDialog from './SelectedSessionsTaskStateDialog'

const DialogTypeToComponentMap = {
  // session
  [DialogTypes.ActivateSession]: ActivateSessionDialog,
  [DialogTypes.BulkCopy]: BulkCopySessionDialog,
  [DialogTypes.CopySession]: CopySessionDialog,
  [DialogTypes.DeleteSession]: DeleteSessionDialog,
  [DialogTypes.DeleteSessionPermanently]: DeleteSessionPermanentlyDialog,
  [DialogTypes.ShareSession]: ShareSessionDialogContainer,
  [DialogTypes.JoinSessionReview]: JoinSessionReviewDialog,
  [DialogTypes.LeaveSession]: LeaveSessionDialog,
  [DialogTypes.RestoreSession]: RestoreSessionDialog,
  [DialogTypes.PublishSession]: PublishSessionDialog,
  [DialogTypes.UnpublishSession]: UnpublishSessionDialog,

  [DialogTypes.CreateSessionWithAdminPermissionRequest]: CreateSessionWithAdminPermissionRequestDialog,

  // folder
  [DialogTypes.CreateFolder]: CreateFolderDialog,
  [DialogTypes.DeleteFolder]: DeleteFolderDialog,
  [DialogTypes.DeleteFolderPermanently]: DeleteFolderPermanentlyDialog,
  [DialogTypes.LeaveFolder]: LeaveFolderDialog,
  [DialogTypes.ManageFolderUsers]: ManageFolderUsersDialog,
  [DialogTypes.RenameFolder]: RenameFolderDialog,
  [DialogTypes.RestoreFolder]: RestoreFolderDialog,

  // sessions
  [DialogTypes.AddUsers]: AddUsersToSessionsDialog,
  [DialogTypes.ChangeReviewStatus]: ChangeSessionsReviewStatusDialog,
  [DialogTypes.RestoreSessions]: RestoreSessionsDialog,
  [DialogTypes.ShareSessions]: ShareSessionsDialog,
  [DialogTypes.SwitchOwners]: SwitchSessionsOwnerDialog,
  [DialogTypes.SwitchReviewers]: SwitchSessionsReviewerDialog,
  [DialogTypes.SwitchStorage]: SwitchSessionsStorageDialog,
}

const DialogWrapper = ({ type, component: DialogComponent }) => {
  const { open, payload, onClose, onSubmit } = usePageDialog(type)
  if (!open) {
    return null
  }

  return <DialogComponent open={open} {...(payload || {})} onClose={onClose} onSubmit={onSubmit} />
}

DialogWrapper.propTypes = {
  type: PropTypes.oneOf(Object.values(DialogTypes)).isRequired,
  component: PropTypes.elementType.isRequired,
}

const PageDialogs = () => {
  return (
    <>
      <SelectedSessionsTaskStateDialog key='progress-dialog' />
      {Object.keys(DialogTypeToComponentMap).map((dialogType) => (
        <DialogWrapper key={dialogType} type={dialogType} component={DialogTypeToComponentMap[dialogType]} />
      ))}
    </>
  )
}

PageDialogs.propTypes = {}

export default memo(PageDialogs)
