export function mapDeletedContents(tenantContentsList) {
  const mappedContents = tenantContentsList.map((content) => {
    const mappedContent = {
      ContentId: content.Id,
      Title: content.Title,
      Owner: [content.Owner.DisplayName, content.Owner.Email],
      CreatedOn: content.CreatedOn,
      TotalSessionsCount: content.TotalSessionsCount,
      IsDeleted: true,
    }
    return mappedContent
  })
  return mappedContents
}
