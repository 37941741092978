import { createSelector } from 'reselect'
import { ProjectType, AnnotationSource } from '@tabeeb/enums'
import { defaultPagesStatisticsModel } from '../services'

export const getPagesStatistics = (state) => state.modelTraining.pagesStatistics
export const getSelectedProject = (state) => state.modelTraining.selectedProject

const getCountedEntitiesName = (annotationSource) => {
  switch (annotationSource) {
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
      return 'bounding box annotation(s)'
    case AnnotationSource.ImageAndPolygonAnnotations:
      return 'polygon annotation(s)'
    case AnnotationSource.ImageAndClassificationAnnotations:
      return 'classification annotation(s)'
    case AnnotationSource.ImageAndQuestionPairs:
      return 'form question(s)'
    case AnnotationSource.ImageAndNlpTagSets:
      return 'NLP tag set(s)'
    default:
      return ''
  }
}

const getEntityName = (annotationSource) => {
  switch (annotationSource) {
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
    case AnnotationSource.ImageAndClassificationAnnotations:
      return 'CV class'
    case AnnotationSource.ImageAndQuestionPairs:
    case AnnotationSource.ImageAndNlpTagSets:
      return 'Form'
    default:
      return ''
  }
}

const getAnnotationSourceOptionText = (annotationSource, pagesCount, itemsOnPagesCount) =>
  `${pagesCount} page(s) with ${itemsOnPagesCount || ''} ${getCountedEntitiesName(annotationSource)}`

const getAnnotationSourceSubOptionText = (entityName, annotationSource, pagesCount, itemsOnPagesCount) =>
  `${getEntityName(annotationSource)} '${entityName}': ${getAnnotationSourceOptionText(
    annotationSource,
    pagesCount,
    itemsOnPagesCount
  )}`

const getAnnotationSourceOption = (annotationSource, pagesCount, itemsOnPagesCount, subOptions = []) => ({
  id: annotationSource,
  value: getAnnotationSourceOptionText(annotationSource, pagesCount, itemsOnPagesCount),
  disabled: pagesCount === 0,
  subOptions,
})

const getAnnotationSourceSubOption = (annotationSource, pagesCount, itemsOnPagesCount, entityId, entityName) => ({
  id: entityId,
  value: getAnnotationSourceSubOptionText(entityName, annotationSource, pagesCount, itemsOnPagesCount),
  disabled: pagesCount === 0,
})

const createOption = (pagesStatistics, annotationSource, getPagesCount, getItemsCount) => {
  const totalPagesStatistics = pagesStatistics.find((ps) => ps.Id === null) || defaultPagesStatisticsModel

  return getAnnotationSourceOption(
    annotationSource,
    getPagesCount(totalPagesStatistics),
    getItemsCount(totalPagesStatistics),
    getPagesCount(totalPagesStatistics) > 0
      ? pagesStatistics
          .filter((ps) => ps.Id !== null && getPagesCount(ps) > 0)
          .map((pagesStatisticsPerEntity) =>
            getAnnotationSourceSubOption(
              annotationSource,
              getPagesCount(pagesStatisticsPerEntity),
              getItemsCount(pagesStatisticsPerEntity),
              pagesStatisticsPerEntity.Id,
              pagesStatisticsPerEntity.Name
            )
          )
      : []
  )
}

export const getAnnotationSourceOptions = createSelector(
  [getPagesStatistics, getSelectedProject],
  (pagesStatistics, selectedProject) => {
    const result = []

    if (!selectedProject) {
      return result
    }

    if (selectedProject.Type === ProjectType.ObjectDetection) {
      result.push(
        createOption(
          pagesStatistics,
          AnnotationSource.ImageAndBoundingBoxAnnotations,
          (ps) => ps.PagesCountAnnotationBbox,
          (ps) => ps.ItemsCountAnnotationBbox
        )
      )

      result.push(
        createOption(
          pagesStatistics,
          AnnotationSource.ImageAndPolygonAnnotations,
          (ps) => ps.PagesCountAnnotationPolygon,
          (ps) => ps.ItemsCountAnnotationPolygon
        )
      )
    } else if (selectedProject.Type === ProjectType.ImageClassification) {
      result.push(
        createOption(
          pagesStatistics,
          AnnotationSource.ImageAndClassificationAnnotations,
          (ps) => ps.PagesCountAnnotationAll,
          (ps) => ps.ItemsCountAnnotationAll
        )
      )
    } else if (selectedProject.Type === ProjectType.VQA) {
      result.push(
        createOption(
          pagesStatistics,
          AnnotationSource.ImageAndQuestionPairs,
          (ps) => ps.PagesCountFormQuestions,
          (ps) => ps.ItemsCountFormQuestions
        )
      )

      result.push(
        createOption(
          pagesStatistics,
          AnnotationSource.ImageAndNlpTagSets,
          (ps) => ps.PagesCountNlpTags,
          (ps) => ps.ItemsCountNlpTags
        )
      )
    }
    return result
  }
)

export const getEntitiesFromPagesStatistics = createSelector([getPagesStatistics], (pagesStatistics) => {
  return Object.fromEntries(
    pagesStatistics.filter((ps) => ps.Id !== null).map((ps) => [ps.Id, { Id: ps.Id, Name: ps.Name }])
  )
})

export const getAnnotationSourceOptionsCheckboxes = createSelector(
  [getAnnotationSourceOptions],
  (annotationSourceOptions) => {
    return annotationSourceOptions.every((option) => option.disabled)
  }
)
