import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [combineActions(rawActions.getAllFormsSuccess, rawActions.getFormsByFolderIdSuccess)](state, action) {
      return action.response?.data
    },
    [combineActions(rawActions.deleteFormSuccess, rawActions.switchFormFolderSuccess)](state, action) {
      const formIdToRemove = action.payload.formId || action.payload
      return state.filter((form) => form.FormId !== formIdToRemove)
    },
    [rawActions.publishFormSuccess](state, { payload: { id, selectedTenants } }) {
      return state.map((form) =>
        form.FormId === id
          ? { ...form, PublishedFormTenants: [...form.PublishedFormTenants, ...selectedTenants] }
          : form
      )
    },
    [rawActions.unpublishFormSuccess](state, { payload: { id, tenantsIds } }) {
      return state.map((form) =>
        form.FormId === id
          ? {
              ...form,
              PublishedFormTenants: form.PublishedFormTenants.filter((pt) => !tenantsIds.some((id) => id === pt.Id)),
            }
          : form
      )
    },
    [rawActions.clearFormsList](state, action) {
      return defaultState
    },
    [rawActions.resetState]() {
      return defaultState
    },
  },
  defaultState
)
