import { combineActions, handleActions } from 'redux-actions'
import {
  updateForm,
  setFormAnswers,
  setSelectedTabs,
  unsetCurrentForm,
  setCurrentForm,
  updateFormAnswers,
  clearFormAnswers,
  updateAnswerStatus,
  updateReattachCount,
  resetFormsState,
  setRecords,
  setSelectedRecord,
  addFormRecord,
  removeFormRecord,
  setDisplayAnswersMode,
  setSelectedUserId,
} from '../actions'

const defaultState = {}

const setForm = (action) => ({ ...action.payload, answers: [] })
const setAnswerStatus = (state, { payload }) => {
  const { FormAnswerId, FormAnswerStatus, ApproverId, ApproverName, FormAnswerStatusCreatedOn } = payload
  return {
    ...state,
    answers: state.answers?.map((answer) =>
      answer.Id === FormAnswerId
        ? { ...answer, FormAnswerStatus, ApproverId, ApproverName, FormAnswerStatusCreatedOn }
        : answer
    ),
  }
}

const setReattachedNumberOfTimes = (state, action) => {
  return {
    ...state,
    ReattachedNumberOfTimes: state.ReattachedNumberOfTimes + 1,
  }
}

const currentForm = handleActions(
  {
    [updateForm](state, action) {
      return setForm(action)
    },
    [unsetCurrentForm](state, action) {
      return { ...action.payload }
    },
    [setFormAnswers](state, action) {
      return {
        ...state,
        answers: action.payload,
      }
    },
    [updateFormAnswers](state, action) {
      let answers
      if (state.answers.length > 0) {
        answers = [
          ...state.answers.filter((item) => item.FormControlId !== action.payload.FormControlId),
          action.payload,
        ]
      } else {
        answers = [action.payload]
      }
      return {
        ...state,
        answers,
      }
    },
    [clearFormAnswers](state, action) {
      return {
        ...state,
        answers: state.answers.filter((item) => item.Id !== action.payload.Id),
      }
    },
    [setSelectedTabs](state, action) {
      return {
        ...state,
        selectedTabs: [...action.payload],
      }
    },
    [setCurrentForm](state, action) {
      return action.payload
    },
    [updateAnswerStatus](state, action) {
      return setAnswerStatus(state, action)
    },
    [updateReattachCount](state, action) {
      return setReattachedNumberOfTimes(state, action)
    },
    [setRecords](state, action) {
      return {
        ...state,
        records: action.payload,
      }
    },
    [setSelectedRecord](state, action) {
      return {
        ...state,
        selectedRecord: action.payload,
      }
    },
    [combineActions(setSelectedUserId, setDisplayAnswersMode)](state, action) {
      return {
        ...state,
        selectedRecord: 0,
      }
    },
    [addFormRecord](state, action) {
      return {
        ...state,
        Records: [...state.Records, action.payload],
      }
    },
    [removeFormRecord](state, action) {
      return {
        ...state,
        Records: state.Records.filter((rec) => rec.Id !== action.payload),
      }
    },
    [resetFormsState](state, action) {
      return defaultState
    },
  },
  defaultState
)

export default currentForm
