import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { CheckOutlined, KeyboardArrowDownOutlined, StorageOutlined } from '@mui/icons-material'
import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import { getTenantStorages, setSelectedStorage } from '../../actions'
import { getIsFeatureEnabled } from '../../selectors'
import { getHasCreateContentsPermission } from '../../selectors/permissions'
import { getSelectedStorage, getStorages } from '../../selectors/storages'
import { Features } from '../../enums'
import HeaderButton from '../Header/HeaderButton'

const StorageSelector = () => {
  const dispatch = useDispatch()
  const selectedStorage = useSelector(getSelectedStorage)
  const storages = useSelector(getStorages)

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  useEffect(() => {
    if (storages.length === 0) {
      dispatch(getTenantStorages.request())
    }
  }, [dispatch, storages.length])

  const onChange = (storage) => () => {
    dispatch(setSelectedStorage(storage))
    onClose()
  }

  const isVisible = useSelector(
    (state) => getHasCreateContentsPermission(state) && getIsFeatureEnabled(state, Features.SessionCreation)
  )
  if (!isVisible) {
    return null
  }

  if (!selectedStorage || storages.length <= 1) {
    return null
  }

  return (
    <>
      <HeaderButton
        ref={anchorRef}
        title={`Storage: ${selectedStorage.Name}`}
        label={selectedStorage.Name}
        startIcon={<StorageOutlined color='pagePrimaryAction' />}
        endIcon={<KeyboardArrowDownOutlined color='pagePrimaryAction' />}
        onClick={onOpen}
      />
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        PaperProps={{ sx: { width: 150 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onClose}
      >
        {storages.map((storage) => {
          const selected = selectedStorage.Id === storage.Id

          return (
            <MenuItem key={storage.Id} selected={selected} title={storage.Name} onClick={onChange(storage)}>
              <ListItemIcon>{selected && <CheckOutlined />}</ListItemIcon>
              <ListItemText primary={storage.Name} />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default StorageSelector
