import { memo, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import { ReviewStatus, ReviewStatusDisplayName } from '@tabeeb/enums'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { updateInContentReviewStatus } from '@tabeeb/modules/contentSharings/actions'
import { setSelectedSessions } from '@tabeeb/modules/sessionsPage/actions'

import { GroupedReviewStatusContainer } from './styles'

const getAvailableReviewStatuses = (currentReviewStatus) => {
  switch (currentReviewStatus) {
    case ReviewStatus.NotStarted:
    case ReviewStatus.SessionFeedbackSent:
    case ReviewStatus.Complete:
      return [ReviewStatus.ReadyForReview]
    case ReviewStatus.ReadyForReview:
      return [ReviewStatus.InReview]
    case ReviewStatus.InReview:
      return [ReviewStatus.SessionFeedbackSent, ReviewStatus.Complete]
    default:
      return []
  }
}

const SelectedSessionsCountChip = ({ count }) => {
  return (
    <Chip
      size='small'
      sx={{ borderRadius: 0.5 }}
      color='primary'
      variant='filled'
      label={sessionTitleFormatter.format(`${count} session${count > 1 ? 's' : ''}`)}
    />
  )
}

SelectedSessionsCountChip.propTypes = {
  count: PropTypes.number.isRequired,
}

const ChangeSessionsReviewStatusDialog = ({ open, sessions, onClose }) => {
  const dispatch = useDispatch()
  const [updatedStatuses, setUpdatedStatuses] = useState([])

  const groupedByReviewStatus = useMemo(() => {
    return _.groupBy(
      sessions.filter((c) => !c.HasReview),
      (s) => s.ReviewStatus
    )
  }, [sessions])

  const onUpdateStatus = (prevStatus, nextStatus) => {
    let result = updatedStatuses
    if (result.some((s) => s.prevStatus === prevStatus)) {
      if (nextStatus === null) {
        result = result.filter((s) => s.prevStatus !== prevStatus)
      } else {
        result = result.map((s) => (s.prevStatus === prevStatus ? { ...s, nextStatus } : s))
      }
    } else {
      result.push({ prevStatus, nextStatus })
    }

    setUpdatedStatuses([...result])
  }

  const onSubmit = () => {
    for (const updatedStatus of updatedStatuses) {
      const sessionsToUpdate = groupedByReviewStatus[updatedStatus.prevStatus]

      const data = {
        contentIds: sessionsToUpdate.map((c) => c.Id),
        status: updatedStatus.nextStatus,
      }

      dispatch(updateInContentReviewStatus.request(data))
    }

    dispatch(setSelectedSessions([]))

    onClose()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onClose}>
      <DialogTitle>Update review status</DialogTitle>
      <DialogContent>
        {Object.keys(groupedByReviewStatus).map((reviewStatusKey) => {
          const reviewStatus = parseInt(reviewStatusKey, 10)
          const availableStatuses = getAvailableReviewStatuses(reviewStatus)
          const updatedStatus = updatedStatuses.find((s) => s.prevStatus === reviewStatus)?.nextStatus

          return (
            <GroupedReviewStatusContainer key={reviewStatus} variant='outlined'>
              <Box display='flex' alignItems='center' minWidth={0} mr={1}>
                <Typography variant='body2' fontWeight={500} ml={1} mr={1} noWrap>
                  {ReviewStatusDisplayName[reviewStatus]}
                </Typography>
                <SelectedSessionsCountChip count={groupedByReviewStatus[reviewStatus].length} />
              </Box>

              <ToggleButtonGroup
                color='primary'
                exclusive
                size='small'
                value={updatedStatus}
                onChange={(e, nextStatus) => onUpdateStatus(reviewStatus, nextStatus)}
              >
                {availableStatuses.map((status) => {
                  return (
                    <ToggleButton key={status} value={status}>
                      {ReviewStatusDisplayName[status]}
                    </ToggleButton>
                  )
                })}
              </ToggleButtonGroup>
            </GroupedReviewStatusContainer>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={onSubmit} variant='contained' color='primary' disabled={updatedStatuses.length === 0}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ChangeSessionsReviewStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      ReviewStatus: PropTypes.oneOf(Object.values(ReviewStatus)).isRequired,
      HasReview: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(ChangeSessionsReviewStatusDialog)
