import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.openControlSettings](state, action) {
      return [...state, action.payload]
    },
    [rawActions.updateControlSettingsList](state, action) {
      return [...action.payload]
    },
    [rawActions.closeControlSettings](state, action) {
      return state.filter((control) => control !== action.payload)
    },
    [rawActions.clearControlSettingsList](state, action) {
      return defaultState
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
