import qs from 'qs'

const aiExpressionsRequests = {
  createAiExpressionRequest: (data) => ({
    url: `api/ai/expressions`,
    method: 'post',
    data,
  }),
  deleteAiExpressionRequest: ({ id }) => ({
    url: `api/ai/expressions/${id}`,
    method: 'delete',
  }),
  executeAiExpressionRequest: ({ id, imageUrl }) => ({
    url: `api/ai/expressions/${id}/execute`,
    method: 'post',
    data: {
      imageUrl,
    },
  }),
  updateAiExpressionRequest: (data) => ({
    url: `api/ai/expressions/${data.Id}`,
    method: 'put',
    data,
  }),
  getAiExpressionRequest: ({ id }) => ({
    url: `api/ai/expressions/${id}`,
    method: 'get',
  }),
  getAiExpressionsRequest: ({ skip, take, search }) => ({
    url: `api/ai/expressions`,
    method: 'get',
    params: {
      skip,
      take,
      search,
    },
  }),

  getAiObjectsByModelsRequest: ({ modelIds }) => ({
    url: `api/ai/objects/byModels`,
    method: 'get',
    params: {
      modelIds,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }),

  generateAiExpressionRequest: (data) => ({
    url: `api/ai/expressions/generate`,
    method: 'post',
    data,
  }),

  attachAiExpressionToFormControlOptionRequest: ({ expressionId, formControlOptionId }) => ({
    url: `api/formControlOptions/${formControlOptionId}/aiExpression/${expressionId}`,
    method: 'post',
  }),
  detachAiExpressionFromFormControlOptionRequest: ({ formControlOptionId }) => ({
    url: `api/formControlOptions/${formControlOptionId}/aiExpression`,
    method: 'delete',
  }),
}

export default aiExpressionsRequests
