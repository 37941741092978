export default (theme) => {
  return {
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
    },
    background: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: 'gray',
      opacity: 0.25,
    },
  }
}
