import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setSpatialViewStartPageId](state, action) {
      return action.payload.pageId ? action.payload.pageId : null
    },
    [rawActions.resetContentViewerState](state, action) {
      return defaultState
    },
  },
  defaultState
)
