import { put, takeLatest, all } from 'redux-saga/effects'

import { onAddInfoNotification } from '@tabeeb/modules/notification/actions'
import {
  attachAiExpressionToFormControlOption,
  detachAiExpressionFromFormControlOption,
  attachVqaPolicyToFormControlOptionSuccess,
  detachVqaPolicyFromFormControlOptionSuccess,
} from '../actions'

function* onAiExpressionAttached() {
  yield put(onAddInfoNotification({ message: 'Expression attached successfully' }))
}

function* onAiExpressionDetached() {
  yield put(onAddInfoNotification({ message: 'Expression detached successfully' }))
}

function* onVqaPolicyAttached() {
  yield put(onAddInfoNotification({ message: 'VQA policy attached successfully' }))
}

function* onVqaPolicyDetached() {
  yield put(onAddInfoNotification({ message: 'VQA policy detached successfully' }))
}

function* attachAiExpression() {
  yield all([
    takeLatest(attachAiExpressionToFormControlOption.success, onAiExpressionAttached),
    takeLatest(detachAiExpressionFromFormControlOption.success, onAiExpressionDetached),
    takeLatest(attachVqaPolicyToFormControlOptionSuccess, onVqaPolicyAttached),
    takeLatest(detachVqaPolicyFromFormControlOptionSuccess, onVqaPolicyDetached),
  ])
}

export default attachAiExpression
