import { memo } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import {
  getCanRenameContentDescriptionForTenant,
  getContentDescriptionForTenant,
} from '@tabeeb/modules/contentReviews/selectors'

import { useSelector } from 'react-redux'
import ContentDescription from './ContentDescription'
import RenameButton from './RenameButton'
import styles from './styles'

const SharedForReviewContent = ({ tenantId, classes }) => {
  const isRenameButtonVisible = useSelector((state) => getCanRenameContentDescriptionForTenant(state, { tenantId }))
  const description = useSelector((state) => getContentDescriptionForTenant(state, { tenantId }))

  if (!tenantId) {
    return null
  }

  return (
    <div className={classes.container}>
      <ContentDescription description={description} />
      {isRenameButtonVisible && <RenameButton tenantId={tenantId} />}
    </div>
  )
}

SharedForReviewContent.propTypes = {
  tenantId: PropTypes.number,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(memo(SharedForReviewContent))
