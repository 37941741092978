import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    wrapper: {
      padding: theme.spacing(),
      flexBasis: '25%',
      minWidth: 288,
      minHeight: 252,
    },
    root: {
      width: 256,
    },
    table: {
      '& td, th': {
        borderBottom: 'none',
      },
    },
  }
})
