import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { getAudioOutputDevice } from '@tabeeb/modules/presentation/selectors'
import { setAudioOutputDevice } from '@tabeeb/modules/presentation/actions/devices'
import { getAudioOutputDevices } from '@tabeeb/modules/presentation/services/conferenceService'

function useSpeakerSettings({ open }) {
  const dispatch = useDispatch()

  const [audioOutputDevices, setAudioOutputDevices] = useState([])
  const selectedAudioOutputDevice = useSelector((state) => getAudioOutputDevice(state))
  const showOutputDevices = audioOutputDevices.length && audioOutputDevices[0].deviceId

  const handleAudioOutputDeviceClick = useCallback(
    (deviceId) => {
      if (deviceId !== selectedAudioOutputDevice) {
        dispatch(setAudioOutputDevice(deviceId))
      }
    },
    [dispatch, selectedAudioOutputDevice]
  )

  const updateDeviceList = useCallback(() => {
    getAudioOutputDevices().then((devices) => {
      setAudioOutputDevices(devices)

      if (selectedAudioOutputDevice && !devices.find((device) => device.deviceId === selectedAudioOutputDevice)) {
        dispatch(setAudioOutputDevice(devices[0]?.deviceId))
      }
    })
  }, [dispatch, selectedAudioOutputDevice])

  useEffect(() => {
    updateDeviceList()
    navigator.mediaDevices.addEventListener('devicechange', updateDeviceList)

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updateDeviceList)
    }
  }, [updateDeviceList, open])

  const returnValues = useMemo(
    () => ({
      selectedAudioOutputDevice,
      handleAudioOutputDeviceClick,
      showOutputDevices,
      audioOutputDevices,
    }),
    [audioOutputDevices, handleAudioOutputDeviceClick, selectedAudioOutputDevice, showOutputDevices]
  )

  return returnValues
}

export default useSpeakerSettings
