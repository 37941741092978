import { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ScreenShare, StopScreenShare } from '@material-ui/icons'

import { presentationSelectors, screenSharingActions } from '@tabeeb/modules/presentation'

import UserActionButton from '../UserActionButton'

const ScreenSharingButton = ({ user }) => {
  const dispatch = useDispatch()

  const isScreenSharing = useSelector(presentationSelectors.getIsScreenSharing)
  const isScreenSharingInProgress = useSelector(presentationSelectors.getIsScreenSharingInProgress)

  const handleClick = () => {
    if (isScreenSharingInProgress === true || user.isOnline === false) {
      return
    }

    if (isScreenSharing) {
      dispatch(screenSharingActions.stopScreenSharing())
    } else {
      dispatch(screenSharingActions.startScreenSharing())
    }
  }

  const icon = isScreenSharing ? ScreenShare : StopScreenShare

  return (
    <UserActionButton
      icon={icon}
      title={isScreenSharing ? 'Stop screen sharing' : 'Share screen'}
      isMuted={!isScreenSharing}
      onClick={handleClick}
    />
  )
}

ScreenSharingButton.propTypes = {
  user: PropTypes.shape({
    isOnline: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }),
}

export default memo(ScreenSharingButton)
