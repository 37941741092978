import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { ConfirmationDialog } from '@tabeeb/uikit'
import { getFormsTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import { getFormButtonState } from '@tabeeb/modules/forms/selectors'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { sendForInContentReviewRequest } from '@tabeeb/modules/contentReviews/actions'
import { FormButtons } from '../../services/enums'

const SendForInContentReviewButton = () => {
  const dispatch = useDispatch()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { isVisible, isDisabled } = useSelector((state) =>
    getFormButtonState(state, { button: FormButtons.SendForInContentReview })
  )

  const contentId = useSelector(getContentId)
  const formsTitle = useSelector(getFormsTitleLowerCase)

  const onDialogSubmit = () => {
    dispatch(sendForInContentReviewRequest({ contentId }))
    setIsDialogOpen(false)
  }

  if (!isVisible) return null

  const dialogProps = {
    onCloseDialog: () => setIsDialogOpen(false),
    onSubmit: onDialogSubmit,
    isOpen: isDialogOpen,
    cancelButtonText: 'Close',
    submitButtonText: 'Ok',
    title: `Would you like to submit ${formsTitle} for review?`,
  }

  return (
    <>
      <Button
        onClick={() => setIsDialogOpen(true)}
        variant='contained'
        color='primary'
        className='form-button'
        disabled={isDisabled}
      >
        submit for review
      </Button>
      <ConfirmationDialog {...dialogProps} />
    </>
  )
}

export default memo(SendForInContentReviewButton)
