import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Line as CanvasLine, Circle as CanvasCircle, Group } from 'react-konva'

import { getHighlightedColor } from '../../services/colorsService'
import { getDistanceInPx, getViewBoxFromAnnotationPoints } from '../../helpers'
import { getMeasurementSettings } from '../../selectors'

import Label from '../Label'

const INCHES_IN_FEET = 12

const getIntermediate = (start, end, ratio) => ({
  x: start.X + (end.X - start.X) * ratio,
  y: start.Y + (end.Y - start.Y) * ratio,
})

const getLabelPosition = (points, distance) => {
  let distanceTarget = distance / 2

  for (let i = 0; i < points.length - 1; i++) {
    const lineDistance = getDistanceInPx([points[i], points[i + 1]])
    if (lineDistance > distanceTarget) {
      return getIntermediate(points[i], points[i + 1], distanceTarget / lineDistance)
    }

    distanceTarget -= lineDistance
  }

  return {
    x: points[0].X,
    y: points[0].Y,
  }
}

const Distance = ({
  annotation,
  annotationRef,
  onMarkerClick,
  opacity,
  hovered,
  hoverProps,
  contentScale,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
  children,
}) => {
  const position = Distance.getXY(annotation)
  const points = Distance.getLinePoints(annotation)
  const markers = Distance.getMarkersPoints(annotation)
  const anchor = Distance.getAnchor(annotation)

  const commonStyles = {
    lineCap: 'round',
    lineJoin: 'round',
    opacity,
  }

  const styles = {
    ...commonStyles,
    stroke: annotation.Color,
    strokeWidth: annotation.Width,
    hitStrokeWidth: annotation.Width * 2,
  }

  const markerStyles = {
    stroke: annotation.Color,
    strokeWidth: 2 / contentScale,
    fill: 'white',
    radius: 5 / contentScale,
  }

  const hoveredStyles = {
    ...commonStyles,
    stroke: getHighlightedColor(annotation.Color),
    strokeWidth: 1,
    strokeHitEnabled: false,
    strokeScaleEnabled: false,
  }

  const distance = getDistanceInPx(annotation.Points)

  const { Dpi: dpi, PageId: pageId } = useSelector(getMeasurementSettings)

  const labelPosition = getLabelPosition(annotation.Points, distance)

  return (
    <Group {...position} {...dragAndDropProps}>
      <CanvasLine
        id={annotation.Id}
        ref={annotationRef}
        points={points}
        {...styles}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
      />
      {(selected || hovered) && <CanvasLine points={points} {...hoveredStyles} />}
      {markers.map((marker, index) => {
        const listening = index !== markers.length - 1 || !!annotation.Id

        return (
          <CanvasCircle
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...marker}
            {...markerStyles}
            {...hoverProps}
            {...selectionProps}
            {...contextMenuProps}
            listening={listening}
            onMouseDown={onMarkerClick && (() => onMarkerClick({ x: marker.x + position.x, y: marker.y + position.y }))}
          />
        )
      })}
      <Group {...anchor}>{children}</Group>

      <Label
        visible
        x={labelPosition.x - position.x}
        y={labelPosition.y - position.y}
        text={pageId === annotation.PageId ? `${(distance / dpi / INCHES_IN_FEET).toFixed(2)} ft` : '...'}
        scale={contentScale}
      />
    </Group>
  )
}

Distance.fillOpacity = 0.25

Distance.getXY = (annotation) => ({
  x: annotation.Points[0].X,
  y: annotation.Points[0].Y,
})

Distance.getAnchor = () => ({
  x: 0,
  y: 0,
})

Distance.getLinePoints = (annotation) => {
  const points = []
  const position = Distance.getXY(annotation)

  for (const point of annotation.Points) {
    points.push(point.X - position.x)
    points.push(point.Y - position.y)
  }

  return points
}

Distance.getMarkersPoints = (annotation) => {
  const points = []
  const position = Distance.getXY(annotation)

  for (const point of annotation.Points) {
    points.push({
      x: point.X - position.x,
      y: point.Y - position.y,
    })
  }

  return points
}

Distance.getViewBox = getViewBoxFromAnnotationPoints

Distance.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Color: PropTypes.string.isRequired,
    PageId: PropTypes.number.isRequired,
    Points: PropTypes.arrayOf(
      PropTypes.shape({
        X: PropTypes.number.isRequired,
        Y: PropTypes.number.isRequired,
      })
    ).isRequired,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  annotationRef: PropTypes.func,
  opacity: PropTypes.number,
  contentScale: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
  onMarkerClick: PropTypes.func,
  children: PropTypes.node,
}

export default Distance
