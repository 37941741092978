import { useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { List, ListItem, ListItemIcon, withStyles } from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'

import UserAvatar from '@tabeeb/modules/shared/userAvatar'
import * as accountSelectors from '../../selectors'

import { onAddErrorNotification } from '../../../notification/actions'
import { setAvatarRequest, removeAvatarRequest } from '../../actions'

import styles from './styles'

const Avatar = ({ classes }) => {
  const dispatch = useDispatch()
  const inputRef = useRef()

  const me = useSelector(accountSelectors.getMe)
  const avatarUrl = me.AvatarUrl

  const handleClick = () => {
    inputRef.current.click()
  }

  const onSetAvatar = useCallback(
    (data) => {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

      const file = data.currentTarget.files[0]

      if (!allowedExtensions.exec(file.name)) {
        dispatch(onAddErrorNotification({ message: 'Invalid image.' }))
        return
      }

      const reader = new FileReader()
      reader.onload = function (event) {
        const data = { name: file.name, data: event.target.result }
        dispatch(setAvatarRequest(data))
      }
      reader.readAsDataURL(file)

      data.currentTarget.value = ''
    },
    [dispatch]
  )

  const onRemoveAvatar = useCallback(() => {
    dispatch(removeAvatarRequest())
  }, [dispatch])

  return (
    <div className={classes.avatarWrapper}>
      {avatarUrl ? (
        <img alt='avatar' src={avatarUrl} className={classes.avatar} />
      ) : (
        <UserAvatar className={classes.initials} user={me} />
      )}
      <List className={classes.controls}>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <Edit color='secondary' />
          </ListItemIcon>
          Set profile image
          <input
            ref={inputRef}
            type='file'
            onChange={onSetAvatar}
            accept='.jpg, .png, .gif, .jpeg'
            style={{ display: 'none' }}
          />
        </ListItem>
        {avatarUrl && (
          <ListItem button onClick={onRemoveAvatar}>
            <ListItemIcon>
              <Delete color='secondary' />
            </ListItemIcon>
            Delete profile image
          </ListItem>
        )}
      </List>
    </div>
  )
}

Avatar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Avatar)
