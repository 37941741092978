import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import routes from '@tabeeb/routes'
import { getIsTenantAuthFlowCompleted, getIsUserAuthorized } from '@tabeeb/modules/account/selectors'
import { getMainBannerColor, getMainTextColor } from '@tabeeb/modules/appConfigState/selectors'

import Navbar from '../components/Navbar'

const NavbarContainer = () => {
  const isAuthFlowCompleted = useSelector(getIsTenantAuthFlowCompleted)
  const isAuthorized = useSelector(getIsUserAuthorized)
  const backgroundColor = useSelector(getMainBannerColor)
  const mainTextColor = useSelector(getMainTextColor)
  const location = useSelector((state) => state.router.location.pathname)

  const props = useMemo(
    () => ({
      mainTextColor,
      backgroundColor,
      displayTabs: !location.startsWith(routes.session),
      isAuthorized: isAuthorized && isAuthFlowCompleted,
    }),
    [mainTextColor, backgroundColor, location, isAuthorized, isAuthFlowCompleted]
  )

  return <Navbar {...props} />
}

export default NavbarContainer
