import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as rawActions from '../actions'

import Timeline from '../../timeline/components/Timeline'
import { MIN_ASSETS_NUMBER_FOR_3D_MODEL } from '../constants'

class ThreedeeImagesListContainer extends Component {
  _handleClose = () => {
    const {
      actions: { close3dModelDialog },
    } = this.props
    close3dModelDialog()
  }

  _handleSubmit = () => {
    const {
      actions: { create3dModel },
      isProcessing,
    } = this.props

    if (isProcessing) {
      return
    }

    create3dModel()
  }

  _handleDeleteItem = (itemIndex) => {
    const {
      actions: { deleteImageFrom3dModel },
    } = this.props

    deleteImageFrom3dModel(itemIndex)
  }

  _handleUpdateList = (newList) => {
    const {
      actions: { updateImages3dModel },
    } = this.props

    updateImages3dModel(newList)
  }

  render() {
    const { isOpen, isProcessing, timeLineList, currentPanel } = this.props

    const actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        tooltip:
          timeLineList.length >= MIN_ASSETS_NUMBER_FOR_3D_MODEL
            ? null
            : `3D model requires at least ${MIN_ASSETS_NUMBER_FOR_3D_MODEL} images`,
        class: 'submit',
        disabled: timeLineList.length < MIN_ASSETS_NUMBER_FOR_3D_MODEL,
      },
    ]

    const props = {
      actions,
      timeLineList,
      handleDeleteItem: this._handleDeleteItem,
      handleUpdateList: this._handleUpdateList,

      isOpen,
      isSubmitted: isProcessing,
      isLoading: isProcessing,
      isSelected: currentPanel === TabPanel.ThreeDModel,
    }

    return <Timeline {...props} />
  }
}

ThreedeeImagesListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  timeLineList: PropTypes.array.isRequired,
  currentPanel: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    close3dModelDialog: PropTypes.func.isRequired,
    create3dModel: PropTypes.func.isRequired,
    deleteImageFrom3dModel: PropTypes.func.isRequired,
    updateImages3dModel: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.threedeeModel.isTimelineOpen,
    isProcessing: state.threedeeModel.isProcessing,
    timeLineList: state.threedeeModel.imagesList,
    currentPanel: state.navbar.currentPanel[TabPanelLocation.Bottom],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ThreedeeImagesListContainer)
