import { Component } from 'react'
import { Provider, ReactReduxContext } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react'

import { ThemeProvider } from '@mui/material'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { ConnectedRouter } from 'connected-react-router'

import { tabeebTheme, modernV5 } from '@tabeeb/uikit'

import { HelmetProvider } from 'react-helmet-async'
import { SnackbarProvider } from 'notistack'

import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import ai from '@tabeeb/services/telemetryService'
import StubPage from '@tabeeb/shared/routes/components/StubPage'
import { store, persistor, history } from '@tabeeb/store'

import { onAppMounted } from '../../state/actions/appActions'

import UI from './App'

import TelemetryProvider from './TelemetryProvider'

class App extends Component {
  componentDidMount() {
    store.dispatch(onAppMounted())
  }

  render() {
    return (
      <AppInsightsErrorBoundary appInsights={ai.reactPlugin} onError={StubPage}>
        <MuiThemeProvider theme={tabeebTheme}>
          <ThemeProvider theme={modernV5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Provider store={store} context={ReactReduxContext}>
                <PersistGate persistor={persistor}>
                  <ConnectedRouter history={history} context={ReactReduxContext}>
                    <TelemetryProvider history={history}>
                      <HelmetProvider>
                        <SnackbarProvider
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <UI />
                        </SnackbarProvider>
                      </HelmetProvider>
                    </TelemetryProvider>
                  </ConnectedRouter>
                </PersistGate>
              </Provider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </AppInsightsErrorBoundary>
    )
  }
}

export default App
