import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box } from '@mui/system'

import AutocompleteLocations from '../AutocompleteLocations'

const LocationGridFilterOperator = ({ item, applyValue, disabled, loading, getLocationsResponse }) => {
  const [filterValue, setFilterValue] = useState(item.value ?? [])

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        minHeight: 48,
        width: 600,
      }}
    >
      <AutocompleteLocations
        getLocationsResponse={getLocationsResponse}
        loading={loading}
        autocompleteProps={{
          multiple: true,
          disableCloseOnSelect: true,
          limitTags: 5,
        }}
        value={filterValue}
        onChange={(_, newValues, reason) => {
          switch (reason) {
            case 'selectOption':
            case 'removeOption':
              setFilterValue(newValues)
              break
            case 'clear':
            default:
              setFilterValue([])
              break
          }

          applyValue({ ...item, value: newValues })
        }}
      />
    </Box>
  )
}

LocationGridFilterOperator.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.number.isRequired,
        IdentityGuid: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  applyValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  getLocationsResponse: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      CountryId: PropTypes.number.isRequired,
      Country: PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
      }),
    })
  ),
}

export default LocationGridFilterOperator
