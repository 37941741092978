import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchStatus } from '@tabeeb/enums'
import { getPowerBIReportConfigurationCheckState } from '../selectors'
import { resetValidatePowerBIReportConfigurationConnection, validatePowerBIReportConfiguration } from '../actions'

const usePowerBIReportConfigurationCheck = () => {
  const dispatch = useDispatch()

  const { fetchStatus, errorObject } = useSelector(getPowerBIReportConfigurationCheckState)

  const onInitCheck = useCallback(
    ({ workspaceId, dashboardId, reportingProviderId, securityModel }) => {
      const requestParams = {
        workspaceId,
        dashboardId,
        reportingProviderId,
        securityModel,
      }
      dispatch(validatePowerBIReportConfiguration.request(requestParams))
    },
    [dispatch]
  )

  const onResetState = useCallback(() => {
    dispatch(resetValidatePowerBIReportConfigurationConnection())
  }, [dispatch])

  return { fetchStatus, errorObject, onInitCheck, onResetState }
}

export default usePowerBIReportConfigurationCheck
