import { takeLatest, put, all, select } from 'redux-saga/effects'

import { switchPanel } from '@tabeeb/modules/sessionTabs/actions'
import { openSplatModelTimeline } from '@tabeeb/modules/splatModel/actions'
import { getIsTimelineOpen } from '@tabeeb/modules/splatModel/selectors'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as galleryActions from '../actions'

function* galleryAddSplatModel(action) {
  const isSplatModelOpen = yield select(getIsTimelineOpen)
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  if (!isSplatModelOpen) {
    yield put(openSplatModelTimeline())
  }

  if (currentPanel !== TabPanel.SplatModel) {
    yield put(switchPanel(TabPanel.SplatModel, TabPanelLocation.Bottom))
  }
}

function* addSplatModel() {
  yield all([takeLatest(galleryActions.galleryAddSplatModel, galleryAddSplatModel)])
}

export default addSplatModel
