import { all } from 'redux-saga/effects'

import resetState from './resetState'
import rotatePageSaga from './rotatePageSaga'
import regenerateStructureModel from './regenerateStructureModelSaga'

function* playerToolbarSaga() {
  yield all([resetState(), rotatePageSaga(), regenerateStructureModel()])
}

export default playerToolbarSaga
