import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isEqual, xorBy } from 'lodash'

import { useDebouncedValue, usePagination } from '@tabeeb/shared/utils/hooks'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

const valuesOfTwoFilterArraysEqual = (newArray, oldArray) => {
  for (let i = 0; i < newArray.length; i++) {
    if (!isEqual(newArray[i].value, oldArray[i].value)) {
      return false
    }
  }
  return true
}

function useDataGrid(props = {}) {
  const { filterValueFormatter = null, initialFilters = null } = props
  const dispatch = useDispatch()
  const [sort, setSort] = useState({})
  const debouncedSort = useDebouncedValue(sort, 500)
  const [filterModel, setFilterModel] = useState(initialFilters ?? [])
  const [filterRequestPayload, setFilterRequestPayload] = useState(filterModel)
  const debouncedFilterModel = useDebouncedValue(filterRequestPayload, 1000)
  const [search, setSearch] = useState('')
  const [rows, setRows] = useState([])
  const [rowCountState, setRowCountState] = useState(0)

  const {
    current: currentPage,
    pages,
    size,
    onPageNumberChange,
    onPageSizeChange,
  } = usePagination({ total: rowCountState })

  const handleSetFilterModel = useCallback(
    (newFilterModel) => {
      setFilterModel(newFilterModel.items)

      const newSearch = newFilterModel.quickFilterValues.join(' ')
      if (newSearch !== search) {
        setSearch(newSearch || '')
      }

      // to prevent set useless state after initial filter opening/creating
      const filteredNewFilterModel = newFilterModel.items.filter((item) =>
        Array.isArray(item.value) ? item.value.length !== 0 : Boolean(item.value)
      )

      if (
        filteredNewFilterModel.length === filterRequestPayload.length &&
        xorBy(filteredNewFilterModel, filterRequestPayload, 'field').length === 0 &&
        xorBy(filteredNewFilterModel, filterRequestPayload, 'operator').length === 0 &&
        (filteredNewFilterModel.length !== 0
          ? valuesOfTwoFilterArraysEqual(filteredNewFilterModel, filterRequestPayload)
          : true)
      ) {
        return
      }

      setFilterRequestPayload(filteredNewFilterModel)
    },
    [filterRequestPayload, search]
  )

  // api call
  const payload = useMemo(() => {
    const searchModel = search?.length > 0 ? search : ''
    const filterModelRequest =
      debouncedFilterModel?.length > 0
        ? debouncedFilterModel.map((filter) => {
            const value = filterValueFormatter == null ? JSON.stringify(filter.value) : filterValueFormatter(filter)
            return {
              field: filter.field,
              operator: filter.operator,
              value,
            }
          })
        : {}
    return {
      sort: debouncedSort,
      search: searchModel,
      filterModels: filterModelRequest,
      pageNumber: currentPage - 1,
      pageSize: size,
    }
  }, [search, debouncedFilterModel, debouncedSort, currentPage, size, filterValueFormatter])

  const onDefaultErrorMutation = useCallback(
    (message) => {
      dispatch(onAddErrorNotification({ message }))
    },
    [dispatch]
  )

  const onDefaultSuccessMutation = useCallback(
    (message) => {
      dispatch(onAddSuccessNotification({ message }))
    },
    [dispatch]
  )

  const paginationModel = useMemo(
    () => ({
      page: currentPage,
      pages,
      pageSize: size,
      onPageNumberChange,
      onPageSizeChange,
    }),
    [currentPage, onPageNumberChange, onPageSizeChange, pages, size]
  )

  const genericDataGridValues = useMemo(
    () => ({
      setSort,
      filterModel,
      setFilterModel: handleSetFilterModel,
      search,
      paginationModel,
      rows,
      setRows,
      rowCountState,
      setRowCountState,
      payload,
      onDefaultErrorMutation,
      onDefaultSuccessMutation,
    }),
    [
      filterModel,
      handleSetFilterModel,
      search,
      paginationModel,
      rows,
      rowCountState,
      payload,
      onDefaultErrorMutation,
      onDefaultSuccessMutation,
    ]
  )

  return genericDataGridValues
}

export default useDataGrid
