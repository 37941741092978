import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, Checkbox, TableCell, TableRow, Typography } from '@mui/material'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

import {
  getIsFiltrationByReviewStatusEnabled,
  getIsFiltrationBySessionStatusEnabled,
} from '@tabeeb/modules/sessionsPage/selectors/filtration'

import { setSorting } from '../../../actions'
import { SortingColumn } from '../../../enums'
import { getSortingColumn, getSortingOrder, getIsSortingByColumnEnabled } from '../../../selectors/sorting'

import ReviewStatusFilter from '../../Filters/ReviewStatusFilter'
import SessionStatusFilter from '../../Filters/SessionStatusFilter'
import TableHeaderSortableCell from './TableHeaderSortableCell'
import TableHeaderFilterableCell from './TableHeaderFilterableCell'

const SessionsTableHead = ({ selectable, allSelected, onSelectAll }) => {
  const dispatch = useDispatch()

  const column = useSelector(getSortingColumn)
  const order = useSelector(getSortingOrder)

  const onSort = useCallback(
    (newSorting) => {
      dispatch(setSorting(newSorting))
    },
    [dispatch]
  )

  const isSortingByDateEnabled = useSelector((state) => getIsSortingByColumnEnabled(state, SortingColumn.Date))
  const isSortingByNameEnabled = useSelector((state) => getIsSortingByColumnEnabled(state, SortingColumn.Name))
  const isSortingByOwnerEnabled = useSelector((state) => getIsSortingByColumnEnabled(state, SortingColumn.Owner))
  const isSortingByLastActivityEnabled = useSelector((state) =>
    getIsSortingByColumnEnabled(state, SortingColumn.LastActivity)
  )

  const isFiltrationByReviewStatusEnabled = useSelector(getIsFiltrationByReviewStatusEnabled)
  const isFiltrationBySessionStatusEnabled = useSelector(getIsFiltrationBySessionStatusEnabled)

  const dataColumns = useMemo(() => {
    const columns = [
      { id: 'title', name: 'Title', sortable: isSortingByNameEnabled, sorting: SortingColumn.Name, width: 130 },
      { id: 'owner', name: 'Owner', sortable: isSortingByOwnerEnabled, sorting: SortingColumn.Owner },
      { id: 'generic-id', name: 'Generic ID' },
      { id: 'created-on', name: 'Created on', sortable: isSortingByDateEnabled, sorting: SortingColumn.Date },
      {
        id: 'last-activity',
        name: 'Last activity',
        sortable: isSortingByLastActivityEnabled,
        sorting: SortingColumn.LastActivity,
      },
      {
        id: 'session-status',
        name: sessionTitleFormatter.format('Session Status'),
        filterable: isFiltrationBySessionStatusEnabled,
        filter: SessionStatusFilter,
      },
      {
        id: 'review-status',
        name: 'Review Status',
        filterable: isFiltrationByReviewStatusEnabled,
        filter: ReviewStatusFilter,
      },
      { id: 'total-pages', name: 'Pages' },
    ]

    return columns
  }, [
    isFiltrationByReviewStatusEnabled,
    isFiltrationBySessionStatusEnabled,
    isSortingByDateEnabled,
    isSortingByLastActivityEnabled,
    isSortingByNameEnabled,
    isSortingByOwnerEnabled,
  ])

  return (
    <TableRow>
      {selectable && (
        <TableCell align='left' width={30} padding='none'>
          <Checkbox color='primary' checked={allSelected} onChange={(_, checked) => onSelectAll(checked)} />
        </TableCell>
      )}

      {dataColumns.map(
        ({ id, align = 'left', name, sortable = false, sorting, width = 150, filterable, filter: Filter }) =>
          sortable ? (
            <TableHeaderSortableCell
              key={id}
              align={align}
              active={column === sorting}
              order={order}
              width={width}
              onSort={(newOrder) => onSort({ column: sorting, order: newOrder })}
            >
              {name}
            </TableHeaderSortableCell>
          ) : filterable ? (
            <TableHeaderFilterableCell key={id} align={align} width={width} filter={Filter}>
              {name}
            </TableHeaderFilterableCell>
          ) : (
            <TableCell key={id} align={align} width={width}>
              <Box display='flex' alignItems='center'>
                <Typography variant='body2' fontWeight={500} noWrap>
                  {name}
                </Typography>
                {filterable && Filter && <Filter />}
              </Box>
            </TableCell>
          )
      )}

      {/* Menu */}
      <TableCell align='left' width={50} />
    </TableRow>
  )
}

SessionsTableHead.propTypes = {
  selectable: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool.isRequired,
  onSelectAll: PropTypes.func.isRequired,
}

export default memo(SessionsTableHead)
