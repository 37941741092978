export default (theme) => {
  return {
    annotations: {
      position: 'absolute',
      top: 0,
      left: 0,
      overflow: 'hidden',
    },
    wrapper: {
      position: 'relative',
      margin: 'auto',
    },
    viewer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',

      '& .page': {
        position: 'relative',

        '& .canvasWrapper': {
          overflow: 'hidden',
        },
        '& .textLayer': {
          pointerEvents: 'none',
          zIndex: 1,
          '& > span': {
            pointerEvents: 'all',
          },
        },
      },

      '&.text-layer-disabled': {
        '& .page': {
          '& .textLayer': {
            zIndex: 0,
          },
        },
      },
    },
  }
}
