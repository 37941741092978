import { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { Box, ListItem, ListItemText } from '@mui/material'

import TrainingMaterialLinkButton from '../TrainingMaterialLinkButton'

const TrainingMaterialListItem = ({ children, disabled, item }) => {
  return (
    <ListItem key={item.Id} disableGutters>
      <ListItemText
        primary={item.Name}
        primaryTypographyProps={{
          variant: 'body2',
          fontWeight: 500,
          title: item.Name,
          noWrap: true,
        }}
        secondary={`Created ${moment(item.CreatedOn).calendar()}`}
      />

      <Box mr={2}>
        <TrainingMaterialLinkButton id={item.Id} disabled={disabled} />
      </Box>

      {children}
    </ListItem>
  )
}

TrainingMaterialListItem.defaultProps = {
  disabled: false,
}

TrainingMaterialListItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  item: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    CreatedOn: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(TrainingMaterialListItem)
