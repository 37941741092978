import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../actions'

const initialState = {
  requireRefetch: false,
  deleteStatus: FetchStatus.Idle,
  items: [],
}

export default handleActions(
  {
    [rawActions.setPowerBIReportUsersRefetch]: (state, action) => {
      return { ...state, requireRefetch: action.payload }
    },
    [rawActions.getPowerBIReportUsers.success]: (state, action) => {
      return { ...state, items: action.response.data }
    },
    [rawActions.deletePowerBIReportUser.request]: (state, _action) => {
      return { ...state, deleteStatus: FetchStatus.Loading }
    },
    [rawActions.deletePowerBIReportUser.failed]: (state, _action) => {
      return { ...state, deleteStatus: FetchStatus.Failed }
    },
    [rawActions.deletePowerBIReportUser.success]: (state, action) => {
      const newItems = state.items.filter((item) => item.Id !== action.response.data)
      return { ...state, deleteStatus: FetchStatus.Loaded, items: newItems }
    },
  },
  initialState
)
