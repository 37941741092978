import { handleActions } from 'redux-actions'

import { DEFAULT_SESSIONS_TITLE } from '@tabeeb/shared/constants'

import { persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

import * as rawActions from '../actions'

const defaultState = {
  bannerUrl: '',
  logoUrl: '',
  introImageUrl: '',
  introImageTextColor: '',
  name: '',
  subdomain: 'www',
  supportsPrograms: false,
  isGalleryExpansionEnabled: false,
  showStructureModel: false,
  showTowerSideProfile: false,
  show2dLineDrawing: false,
  isDetectObjects: false,
  showBentleyAnalyticsReport: false,
  sessionsNavbarTitle: DEFAULT_SESSIONS_TITLE,
}

export default persistReducer(
  {
    key: 'appConfigState',
    storage,
  },
  handleActions(
    {
      [rawActions.onSetAppConfigState](state, action) {
        return { ...state, ...action.payload }
      },
      [rawActions.getConfigSuccess](state, action) {
        const config = action.response.data

        return { ...defaultState, ...config }
      },
    },
    defaultState
  )
)
