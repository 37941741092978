import { handleActions, combineActions } from 'redux-actions'
import * as actions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [actions.setReviews](state, action) {
      return action.payload
    },
    [actions.addContentReview](state, action) {
      return [...state, action.payload]
    },
    [actions.updateContentReview](state, { payload: { review } }) {
      return state.map((rev) => (rev.Id === review.Id ? { ...review } : rev))
    },
    [actions.updateReviewStatus](state, { payload: { review, status } }) {
      return state.map((rev) => (rev.Id === review.Id ? { ...rev, ReviewStatus: status } : rev))
    },
    [combineActions(actions.onContentReviewCompleted, actions.onContentReviewRevoked)](state, action) {
      const deletedReview = action.payload
      return state.filter((rev) => rev.Id !== deletedReview.Id)
    },
    [actions.addEditedAnswer](state, { payload }) {
      const editedAnswer = payload

      return state.map((review) => {
        if (review.Id !== editedAnswer.ContentSharingId) {
          return review
        }

        const updatedReview = { ...review }

        const existingAnswer = updatedReview.EditedFormsAnswers.find(
          (answer) => answer.FormAnswerId === editedAnswer.FormAnswerId
        )
        if (existingAnswer) {
          updatedReview.EditedFormsAnswers = updatedReview.EditedFormsAnswers.map((answer) => {
            if (answer.FormAnswerId !== editedAnswer.FormAnswerId) {
              return answer
            }

            return {
              ...answer,
              FormAnswerStatus: editedAnswer.FormAnswerStatus,
              ApproverId: editedAnswer.ApproverId,
              ApproverName: editedAnswer.ApproverName,
            }
          })
        } else {
          updatedReview.EditedFormsAnswers = [...updatedReview.EditedFormsAnswers, editedAnswer]
        }

        return updatedReview
      })
    },
  },
  defaultValue
)
