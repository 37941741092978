import { takeLatest, put, all, select } from 'redux-saga/effects'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as galleryActions from '../actions'
import * as bentleyAnalyticsReportActions from '../../bentleyAnalyticsReport/actions'

function* galleryAddBentleyAnalyticsReport(action) {
  const isBentleyAnalyticsReportOpen = yield select((state) => state.bentleyAnalyticsReport.isTimelineOpen)
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  if (!isBentleyAnalyticsReportOpen) yield put(bentleyAnalyticsReportActions.openBentleyAnalyticsReportDialog())

  if (currentPanel !== TabPanel.BentleyAnalyticsReport)
    yield put(navbarActions.switchPanel(TabPanel.BentleyAnalyticsReport, TabPanelLocation.Bottom))
}

function* addBentleyAnalyticsReport() {
  yield all([takeLatest(galleryActions.galleryAddBentleyAnalyticsReport, galleryAddBentleyAnalyticsReport)])
}

export default addBentleyAnalyticsReport
