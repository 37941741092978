import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { IconButton, Tooltip } from '@mui/material'
import { ChangeCircle } from '@mui/icons-material'

import { getIsInContentReviewEnabled } from '@tabeeb/modules/appConfigState/selectors'

import { getHasChangeReviewStatusPermission } from '../../../selectors/permissions'

const ChangeReviewStatusButton = ({ onClick }) => {
  const isVisible = useSelector(
    (state) => getHasChangeReviewStatusPermission(state) && getIsInContentReviewEnabled(state)
  )
  if (!isVisible) {
    return null
  }

  return (
    <Tooltip title='Manage review status'>
      <IconButton size='small' onClick={onClick}>
        <ChangeCircle />
      </IconButton>
    </Tooltip>
  )
}

ChangeReviewStatusButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default memo(ChangeReviewStatusButton)
