export default (theme) => {
  return {
    form: {
      width: '100%',
    },
    link: {
      marginTop: theme.spacing(),
      color: 'inherit !important',
    },
  }
}
