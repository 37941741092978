import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const resetState = createAction('RESET_CONTENT_SHARING_DIALOG_STATE')

export const shareContent = createAction('SHARE_CONTENT')
export const setReviews = createAction('SET_REVIEWS')
export const copyContent = createApiActions('COPY_CONTENT')
export const getDataBySharedContentForms = createApiActions('GET_DATA_BY_SHARED_CONTENT_FORMS')
export const syncContent = createAction('SYNC_CONTENT')
export const setStepCompleted = createAction('SET_STEP_COMPLETED')

export const getLinkedContents = createApiActions('GET_LINKED_CONTENTS')
export const linkContents = createApiActions('LINK_CONTENTS')
export const updateContentsLink = createAction('UPDATE_CONTENTS_LINK')
export const deleteContentsLinkFromList = createAction('DELETE_CONTENTS_LINK_FROM_LIST')
export const deleteContentsLink = createApiActions('DELETE_CONTENTS_LINK')

export const openContentSharingDialog = createAction('OPEN_CONTENT_SHARING_DIALOG')
export const closeContentSharingDialog = createAction('CLOSE_CONTENT_SHARING_DIALOG')

export const selectTenantDestination = createAction('SELECT_TENANT_DESTINATION')

export const updateSearchUserText = createAction('UPDATE_SEARCH_USER_TEXT')
export const searchUserForSharing = createApiActions('SEARCH_USER_FOR_SHARING')
export const setUserForSharing = createAction('SET_USER_FOR_SHARING')
export const resetUsersSearchState = createAction('RESET_USERS_SEARCH_STATE')

export const searchTargetContentForSharing = createApiActions('SEARCH_TARGET_CONTENT_FOR_SHARING')
export const updateSearchContentText = createAction('UPDATE_SEARCH_CONTENT_TEXT')
export const setContentsForSharing = createAction('SET_CONTENTS_FOR_SHARING')
export const setSearchContentForSharingResult = createAction('SET_SEARCH_CONTENTS_FOR_SHARING_RESULT')
export const resetContentsSearchState = createAction('RESET_CONTENTS_SEARCH_STATE')

export const openSelectedPagesForMerging = createAction('OPEN_SELECTED_PAGES_FOR_MERGING')
export const closeSelectedPagesForMerging = createAction('CLOSE_SELECTED_PAGES_FOR_MERGING')

export const addPageForMerging = createAction('ADD_PAGE_FOR_MERGING')
export const addAllPagesForMerging = createAction('ADD_ALL_PAGES_FOR_MERGING')
export const deletePageForMerging = createAction('DELETE_PAGE_FOR_MERGING')

export const addPagesByFormsForMerging = createAction('ADD_PAGES_BY_FORMS_FOR_MERGING')

export const setSelectedFormsForMerging = createAction('SET_SELECTED_FORMS_FOR_MERGING')
export const setFormsForMerging = createAction('SET_FORMS_FOR_MERGING')

export const addFormForMerging = createAction('ADD_FORM_FOR_MERGING')
export const removeFormForMerging = createAction('REMOVE_FORM_FOR_MERGING')

export const addAnswerStatusForMerging = createAction('ADD_ANSWER_STATUS_FOR_MERGING')
export const removeAnswerStatusForMerging = createAction('REMOVE_ANSWER_STATUS_FOR_MERGING')

export const addAllFormsForMerging = createAction('ADD_ALL_FORMS_FOR_MERGING')
export const removeAllFormsForMerging = createAction('REMOVE_ALL_FORMS_FOR_MERGING')

export const addStatusToAllFormsForMerging = createAction('ADD_STATUS_TO_ALL_FORMS_FOR_MERGING')
export const removeStatusFromAllFormsForMerging = createAction('REMOVE_STATUS_FROM_ALL_FORMS_FOR_MERGING')

export const updateInContentReviewStatus = createApiActions('UPDATE_IN_CONTENT_REVIEW_STATUS')
