import { ChartType } from '@tabeeb/enums'

const getCategories = (metrics) => {
  let indexOfMetricWithMaxNumberOfValues = 0
  let maxNumberOfValues = 0

  metrics.forEach((metric, index) => {
    if (metric.Values.length > maxNumberOfValues) {
      maxNumberOfValues = metric.Values.length
      indexOfMetricWithMaxNumberOfValues = index
    }
  })

  return metrics[indexOfMetricWithMaxNumberOfValues].Values.map((m) => m.X)
}

const getChartOptions = ({ chartId, chartName, showToolbar, categories, series, headerCategory, customIcons = [] }) => {
  return {
    options: {
      chart: {
        id: chartId,
        type: ChartType.Line,
        zoom: {
          autoScaleYaxis: true,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: showToolbar,
          offsetY: 20,
          export: {
            csv: {
              filename: chartName,
              headerCategory,
            },
            svg: {
              filename: chartName,
            },
            png: {
              filename: chartName,
            },
          },
          tools: {
            customIcons,
          },
        },
      },
      title: { text: chartName },
      xaxis: {
        categories,
        tickAmount: 10,
        labels: {
          rotateAlways: false,
          rotate: 0,
        },
      },
      stroke: {
        width: 2,
      },
    },
    series,
  }
}

export function getGroupedMetricsAsChartOptions({ chartId, metrics, chartName, showToolbar, customIcons = [] }) {
  if (metrics.length === 0) {
    return []
  }

  return getChartOptions({
    chartId,
    chartName,
    showToolbar,
    customIcons,
    headerCategory: metrics[0].XAxisName,
    categories: getCategories(metrics),
    series: metrics.map((metric) => ({ name: metric.YAxisName, data: metric.Values.map((m) => m.Y) })),
  })
}

export function getMetricAsChartOptions({ metric }) {
  return getChartOptions({
    chartId: metric.Id,
    chartName: metric.Name,
    showToolbar: true,
    headerCategory: metric.XAxisName,
    categories: metric.Values.map((m) => m.X),
    series: [
      {
        name: metric.YAxisName,
        data: metric.Values.map((m) => m.Y),
      },
    ],
  })
}
