import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Formik, Form, Field } from 'formik'

import { Grid, Button } from '@material-ui/core'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'

import { FormikTextField } from '@tabeeb/shared/forms'

import { tabeebTheme } from '@tabeeb/uikit'

import Layout from '../layout'
import styles from '../common/styles'
import { registerSchema } from '../../helper'

export const initialCreateProfileValues = {
  FirstName: '',
  LastName: '',
}

const CreateProfile = ({ classes, loginButtonColor, onRegisterUser, userHasAnyTenants, onClickBack }) => {
  let theme = tabeebTheme
  if (loginButtonColor) {
    theme = useMemo(() => {
      return createTheme({
        ...tabeebTheme,
        palette: {
          action: {
            disabledBackground: loginButtonColor,
          },
          primary: {
            main: loginButtonColor,
          },
        },
      })
    }, [loginButtonColor])
  }

  return (
    <Layout
      form={
        <Formik
          initialValues={initialCreateProfileValues}
          validationSchema={registerSchema}
          onSubmit={onRegisterUser}
          enableReinitialize
        >
          {(formik) => (
            <Form className={classes.form} autoComplete='off'>
              <Grid container direction='column' spacing={1}>
                <Grid xs={12} item>
                  <Grid container wrap='nowrap' spacing={1}>
                    <Grid item xs={6}>
                      <Field
                        color='secondary'
                        name='FirstName'
                        label='First Name'
                        component={FormikTextField}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field color='secondary' name='LastName' label='Last Name' component={FormikTextField} required />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <ThemeProvider theme={theme}>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          disabled={!formik.dirty || !formik.isValid}
                          fullWidth
                          size='large'
                          variant='contained'
                          color='primary'
                          type='submit'
                        >
                          Accept
                        </Button>
                      </Grid>
                      {userHasAnyTenants && (
                        <Grid item xs={12}>
                          <Button
                            size='large'
                            variant='contained'
                            fullWidth
                            color='secondary'
                            onClick={onClickBack}
                            title='Go back to the choose tenant page'
                          >
                            Back to choose tenant page
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    />
  )
}

CreateProfile.propTypes = {
  classes: PropTypes.shape({
    form: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  onRegisterUser: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  loginButtonColor: PropTypes.string,
  userHasAnyTenants: PropTypes.bool.isRequired,
}

export default withStyles(styles)(CreateProfile)
