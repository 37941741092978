import { handleActions } from 'redux-actions'

import { setPointSize } from '../actions'
import { Defaults } from '../constants'

const defaultState = Defaults.POINT_SIZE

export default handleActions(
  {
    [setPointSize](state, { payload: pointSize }) {
      return pointSize
    },
  },
  defaultState
)
