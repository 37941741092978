import { createAction } from 'redux-actions'

export const notifyStartCall = createAction('NOTIFY_START_CALL')
export const onUserReadyForCall = createAction('ON_USER_READY_FOR_CALL')

export const startCall = createAction('START_CALL')

export const endCall = createAction('END_CALL')

export const disconnect = createAction('DISCONNECT')

export const onConnectionEstablished = createAction('ON_CONNECTION_ESTABLISHED')
export const onConnectionFailed = createAction('ON_CONNECTION_FAILED')

export const setRoomId = createAction('SET_ROOM_ID')
export const setPresenter = createAction('SET_PRESENTER')
export const setJitsiConnection = createAction('SET_JITSI_CONNECTION')
export const resetJitsiConnection = createAction('RESET_JITSI_CONNECTION')

export const enterRoom = createAction('ENTER_ROOM')
export const initJitsi = createAction('INIT_JITSI')
export const initJitsiConnection = createAction('INIT_JITSI_CONNECTION')
export const bindConnectionEvents = createAction('BIND_CONNECTION_EVENTS')
export const unbindConnectionEvents = createAction('UNBIND_CONNECTION_EVENTS')
export const dispose = createAction('DISPOSE')

export const getVideoBridgeHostsRequest = createAction('GET_VIDEO_BRIDGE_HOSTS_REQUEST')
export const getVideoBridgeHostsSuccess = createAction('GET_VIDEO_BRIDGE_HOSTS_SUCCESS')

export const getJitsiTokenRequest = createAction('GET_JITSI_TOKEN_REQUEST')
export const getJitsiTokenSuccess = createAction('GET_JITSI_TOKEN_SUCCESS')
export const getJitsiTokenFailed = createAction('GET_JITSI_TOKEN_FAILED')
