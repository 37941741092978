import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button } from '@tabeeb/uikit'
import EndAdornmentRemove from '@tabeeb/modules/tenantConfigPage/components/EndAdornmentRemove'

import { getConfigForUpdateSubdomain } from '../../tenantConfigPage/selectors'
import * as tenantFormsActions from '../actions'

import AttachTenantFormsDialogContainer from './AttachTenantFormsDialogContainer'
import ConfigTextField from '../../tenantConfigPage/components/Fields/ConfigTextField'

class TenantFormsContainer extends Component {
  componentDidMount() {
    const {
      actions: { getTenantFormsRequest },
      subdomain,
    } = this.props

    getTenantFormsRequest(subdomain)
  }

  handleDetachFormFromTenant = (tenantFormId) => {
    this.props.actions.detachFormFromTenantRequest(tenantFormId)
  }

  handleAddFormButtonClick = () => {
    this.props.actions.openAvailableFormsForTenantDialog()
  }

  render() {
    const { tenantForms } = this.props

    return (
      <>
        {tenantForms.length > 0 ? (
          tenantForms.map((tenantForm) => (
            <ConfigTextField
              readOnly
              value={tenantForm.Name.length > 18 ? `${tenantForm.Name.slice(0, 18)}...` : tenantForm.Name}
              title={tenantForm.Name}
              InputProps={{
                endAdornment: <EndAdornmentRemove onClick={() => this.handleDetachFormFromTenant(tenantForm.Id)} />,
              }}
            />
          ))
        ) : (
          <Button size='small' variant='contained' color='primary' onClick={this.handleAddFormButtonClick}>
            Add Form
          </Button>
        )}

        <AttachTenantFormsDialogContainer />
      </>
    )
  }
}

TenantFormsContainer.propTypes = {
  tenantForms: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    })
  ).isRequired,
  actions: PropTypes.shape({
    detachFormFromTenantRequest: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  tenantForms: state.tenantForms.tenantForms,
  subdomain: getConfigForUpdateSubdomain(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(tenantFormsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TenantFormsContainer)
