import { Button, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { read } from 'xlsx'
import { uniqBy } from 'lodash'

import { ContentType } from '@tabeeb/enums'
import { getGalleryItemsByTypes } from '@tabeeb/modules/gallery/selectors'
import { addFilesToQueue, getGalleryItemRequest } from '@tabeeb/modules/gallery/actions'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { ResizableDialog } from '@tabeeb/uikit'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import useImportSheets from '../../hooks/useImportSheets'
import { closeImportSpreadsheetsDialog, resetImportFileUploading } from '../../actions'
import SelectSourceStep from '../SelectSourceStep'
import SelectSheetsStep from '../SelectSheetsStep'

const steps = ['Select Source', 'Select Sheets']

const ImportSpreadsheetsDialog = () => {
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState(0)
  const [file, setFile] = useState(null)
  const [selectedPageId, setSelectedPageId] = useState(null)
  const [workbook, setWorkbook] = useState(null)
  const [sheetsToImport, setSheetsToImport] = useState([])
  const [sheetsImportMap, setSheetsImportMap] = useState({})

  const isOpen = useSelector((state) => state.importSpreadsheetsDialog.isImportSpreadsheetsDialogOpen)
  const contentId = useSelector(getContentId)

  const sheetImportItems = Object.values(sheetsImportMap).filter((item) => item.customName)
  const hasDuplicateNames =
    uniqBy(sheetImportItems, 'customName').length !== sheetImportItems.length && sheetImportItems.length !== 1

  const availablePages = useSelector((state) =>
    getGalleryItemsByTypes(state, { types: [ContentType.ExcelDocumentPage] })
  )
  const importFileUploading = useSelector((state) => state.importSpreadsheetsDialog.importFileUploading)
  const selectedPage = useMemo(
    () => availablePages.find((page) => page.id === selectedPageId),
    [availablePages, selectedPageId]
  )

  const closeDialog = useCallback(() => {
    setActiveStep(0)
    setFile(null)
    setSelectedPageId(null)
    setWorkbook(null)
    setSheetsToImport([])
    setSheetsImportMap({})
    dispatch(closeImportSpreadsheetsDialog())
  }, [dispatch])

  const importSheetsRequest = useImportSheets(
    contentId,
    dispatch,
    sheetsToImport,
    sheetsImportMap,
    workbook,
    closeDialog
  )

  const handleNext = useCallback(() => {
    if (activeStep === 0) {
      if (file) {
        const reader = new FileReader()
        reader.onload = () => setWorkbook(read(reader.result, { type: 'binary' }))
        reader.readAsBinaryString(file)
      } else if (selectedPageId) {
        fetch(selectedPage.assets[0].Url)
          .then((res) => res.blob())
          .then((blob) => {
            const reader = new FileReader()
            reader.onload = () => setWorkbook(read(reader.result, { type: 'binary' }))
            reader.readAsBinaryString(blob)
          })
          .catch(() => {
            dispatch(onAddErrorNotification('Failed to open the file'))
          })
      }
    } else if (activeStep === 1) {
      if (file) {
        dispatch(addFilesToQueue({ files: [file] }))
      } else if (selectedPageId) {
        importSheetsRequest(selectedPage.assets[0].Url)
      }
    }

    setActiveStep((prevStep) => prevStep + 1)
  }, [activeStep, file, selectedPageId, selectedPage, dispatch, importSheetsRequest])

  const handleBack = useCallback(() => {
    setActiveStep((prevStep) => prevStep - 1)
    setSheetsToImport([])
    setSheetsImportMap({})
  }, [])

  useEffect(() => {
    if (isOpen && importFileUploading?.loaded) {
      setFile(null)
    }
    if (importFileUploading?.succeeded) {
      importSheetsRequest(importFileUploading.page.assets[0].Url)
      setActiveStep((prevStep) => prevStep + 1)
      dispatch(resetImportFileUploading())
    }
  }, [isOpen, importFileUploading, dispatch, importSheetsRequest])

  useEffect(() => {
    if (selectedPageId) {
      dispatch(getGalleryItemRequest({ pageId: selectedPageId, isSelectAddedGalleryItem: false }))
    }
  }, [selectedPageId, dispatch])

  return (
    <ResizableDialog
      onClose={closeDialog}
      minDialogSize={{ width: 400, height: 580 }}
      open={isOpen}
      header={<Box sx={{ display: 'flex', flex: '1' }} />}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Box sx={{ width: '100%', mt: 2 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconProps={{ sx: { width: 30, height: 30 } }}>
                    <Typography>{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {activeStep === 0 && (
            <SelectSourceStep
              file={file}
              setFile={setFile}
              selectedPageId={selectedPageId}
              setSelectedPageId={setSelectedPageId}
            />
          )}

          {activeStep === 1 && (
            <SelectSheetsStep
              workbook={workbook}
              sheetsToImport={sheetsToImport}
              onSheetSelected={setSheetsToImport}
              setSheetsImportMap={setSheetsImportMap}
              hasDuplicateNames={hasDuplicateNames}
            />
          )}

          {activeStep !== steps.length && (
            <Box sx={{ display: 'flex', flexDirection: 'row', m: 2, justifyContent: 'center', gap: 2 }}>
              <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                <Typography>Back</Typography>
              </Button>
              <Button
                onClick={handleNext}
                disabled={
                  (activeStep === 0 && !file && (!selectedPageId || !selectedPage?.assets?.length)) ||
                  (activeStep === 1 &&
                    (!sheetsToImport.length ||
                      hasDuplicateNames ||
                      sheetImportItems.some((sheet) => sheet.hasError || sheet.isLoading)))
                }
              >
                <Typography>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Typography>
              </Button>
            </Box>
          )}

          {importFileUploading?.loading && <CircularProgressBar />}
        </Box>
      }
    />
  )
}

export default memo(ImportSpreadsheetsDialog)
