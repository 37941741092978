import { handleActions } from 'redux-actions'

import { resetState, setUniqueAiObjectAnnotationForEdit } from '../actions'

const defaultState = null

export default handleActions(
  {
    [setUniqueAiObjectAnnotationForEdit](state, { payload }) {
      return payload
    },

    [resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
