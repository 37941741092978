import PropTypes from 'prop-types'
import classNames from 'classnames'

import FolderContainer from '../../containers/FolderContainer'

import './styles.less'
import EditFolderDialog from '../EditFolderDialog'

const FoldersList = ({ folders, isExpanded }) => {
  return (
    <div className={classNames('sessionfolders-container', { expanded: isExpanded })}>
      <div className={classNames('list-container', { expanded: isExpanded })}>
        {folders.map((folder) => (
          <FolderContainer key={folder.Id} id={folder.Id} isExpanded={isExpanded} />
        ))}
      </div>
      <EditFolderDialog />
    </div>
  )
}

FoldersList.propTypes = {
  folders: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
}

export default FoldersList
