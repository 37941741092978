import { combineReducers } from 'redux'

import isDialogOpen from './isDialogOpen'
import isSelectionListForEditLoading from './isSelectionListForEditLoading'
import selectionListForEdit from './selectionListForEdit'

export default combineReducers({
  isDialogOpen,
  isSelectionListForEditLoading,
  selectionListForEdit,
})
