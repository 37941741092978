import { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Image as KonvaImage } from 'react-konva'

import { useUnmount } from '@tabeeb/modules/shared/utils/hooks'

import { getImageByUrl } from '../utils'

const getImageToDisplay = (images, src) => {
  if (!images[src]) {
    // fallback image
    return Object.values(images)[0]
  }

  return images[src]
}

const SpatialImage = ({ id, src, selected, contentRef, height, width, onLoad, onReplace, ...props }) => {
  const [images, setImages] = useState({})
  const imagesRef = useRef(images)

  const image = getImageToDisplay(images, src)

  useEffect(() => {
    const abortController = new AbortController()

    if (images[src]) {
      return () => {}
    }

    const downloadImageAsync = async () => {
      try {
        const downloadedImage = await getImageByUrl({ src, abortController })
        setImages((prevImages) => ({ ...prevImages, [src]: downloadedImage }))
      } catch {
        // ignored
      }
    }

    downloadImageAsync()

    return () => {
      abortController.abort()
    }
  }, [src, images])

  useEffect(() => {
    if (selected && image) {
      contentRef(image)
    }
  }, [selected, image, contentRef])

  useEffect(() => {
    if (image) {
      onLoad(id)
    }
  }, [id, image, onLoad])

  useEffect(() => {
    if (!image) {
      onReplace(id)
    }
  }, [id, image, onReplace])

  useEffect(() => {
    imagesRef.current = images
  }, [images])

  useUnmount(() => {
    for (const loadedImage of Object.values(imagesRef.current)) {
      URL.revokeObjectURL(loadedImage.src)
      loadedImage.src = ''
    }
  })

  if (!image) {
    return null
  }

  return <KonvaImage image={image} height={selected ? height : -1} width={selected ? width : -1} {...props} />
}

SpatialImage.propTypes = {
  id: PropTypes.number.isRequired,
  contentRef: PropTypes.func,
  src: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
}

export default memo(SpatialImage)
