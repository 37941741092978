import { useSelector } from 'react-redux'

import TimelineContainer from './TimelineContainer'
import RecipientsContainer from './RecipientsContainer'

import { getIsCustomVideoRecipientsDialogOpen } from '../selectors'

const CustomVideoContainer = () => {
  const isRecipientsDialogOpen = useSelector(getIsCustomVideoRecipientsDialogOpen)

  return (
    <>
      <TimelineContainer />
      {isRecipientsDialogOpen && <RecipientsContainer />}
    </>
  )
}

export default CustomVideoContainer
