import { useEffect, useState } from 'react'

export default function useUpdate(effect, dependencies) {
  const [isFirstMount, setIsFirstMount] = useState(true)

  useEffect(() => {
    setIsFirstMount(false)
  }, [])

  useEffect(() => {
    if (!isFirstMount) {
      return effect()
    }
  }, dependencies)
}
