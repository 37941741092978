import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TabPanel } from '@tabeeb/enums'
import {
  getContentSharingDialogState,
  getIsSelectedPagesForMergingOpen,
} from '@tabeeb/modules/contentSharings/selectors'
import Timeline from '@tabeeb/modules/timeline/components/Timeline'
import { getContentForms } from '@tabeeb/modules/forms/selectors'
import { getNavbarCurrentBottomPanel } from '@tabeeb/modules/customVideo/selectors'
import {
  closeSelectedPagesForMerging,
  deletePageForMerging,
  shareContent,
} from '@tabeeb/modules/contentSharings/actions'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

const SelectedPagesForMerging = () => {
  const dispatch = useDispatch()
  const { pages } = useSelector(getContentSharingDialogState)
  const contentForms = useSelector(getContentForms)
  const isOpen = useSelector(getIsSelectedPagesForMergingOpen)
  const currentPanel = useSelector(getNavbarCurrentBottomPanel)

  const _handleClose = () => {
    dispatch(closeSelectedPagesForMerging())
  }

  const _handleSubmit = () => {
    if (pages.length > 0) {
      dispatch(shareContent())
      dispatch(closeSelectedPagesForMerging())
    } else {
      dispatch(onAddErrorNotification({ message: 'At least one page must be selected' }))
    }
  }

  const _handleDeleteItem = (pageId) => {
    dispatch(deletePageForMerging(pageId))
  }

  const _groupById = () => {
    const resultPages = pages.reduce((r, p) => {
      r[p.groupById] = r[p.groupById] || []
      r[p.groupById].push(p)
      return r
    }, Object.create(null))

    return resultPages
  }

  const actions = [
    {
      onClick: _handleClose,
      title: 'Cancel',
      class: 'close',
    },
    {
      onClick: _handleSubmit,
      title: 'Done',
      class: 'submit',
    },
  ]

  const props = {
    actions,
    timeLineList: _groupById(),
    handleDeleteItem: _handleDeleteItem,
    isShareByFormTimeline: true,
    isOpen,
    isSelected: currentPanel === TabPanel.LinkedContentsSelectedPages,
    contentForms,
  }

  return <Timeline {...props} />
}

export default memo(SelectedPagesForMerging)
