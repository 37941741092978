import { combineActions, handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = false

const isMetadataLoading = handleActions(
  {
    [combineActions(actions.getContentMetadataRequest)](state, action) {
      return true
    },
    [combineActions(actions.getContentMetadataSuccess, actions.getContentMetadataFailed)](state, action) {
      return false
    },
  },
  defaultState
)

export default isMetadataLoading
