import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.clearFilesQueue](state, action) {
      return [...defaultState]
    },
    [rawActions.addFilesToQueue](state, action) {
      return [...state, action.payload]
    },
    [rawActions.dequeueFilesFromQueue](state) {
      const newState = [...state]
      newState.shift()

      return newState
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
