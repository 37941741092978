import { memo } from 'react'
import PropTypes from 'prop-types'

import { Menu, useTheme } from '@material-ui/core'

const ToolbarMenu = ({ anchorEl, open, children, onClose }) => {
  const theme = useTheme()

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      PaperProps={{
        style: {
          borderRadius: theme.shape.borderRadius * 2.5,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          minWidth: 128,
        },
      }}
      getContentAnchorEl={null}
    >
      {children}
    </Menu>
  )
}

ToolbarMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(HTMLElement),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
}

export default memo(ToolbarMenu)
