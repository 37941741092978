import { handleActions, combineActions } from 'redux-actions'
import * as actions from '../actions'

const defaultState = []

export default handleActions(
  {
    [combineActions(actions.getPagesForProjectTraining.success)](state, action) {
      return [...action.response.data]
    },
    [combineActions(actions.setSelectedProject, actions.resetPagesForProjectTraining)](state, action) {
      return defaultState
    },
  },
  defaultState
)
