import { combineActions, handleActions } from 'redux-actions'

import { enterRoom, onConnectionEstablished, onConnectionFailed } from '../actions/connection'
import { setJitsiRoom } from '../actions/conference'

const defaultValue = false

export default handleActions(
  {
    [enterRoom](state, { payload }) {
      return true
    },
    [combineActions(setJitsiRoom, onConnectionEstablished, onConnectionFailed)](state, { payload }) {
      return false
    },
  },
  defaultValue
)
