import { useEffect, useState } from 'react'

import { useUnmount } from '@tabeeb/modules/shared/utils/hooks'
import { getImageByUrl } from '../utils'

export default ({ src }) => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    const abortController = new AbortController()

    const downloadImageAsync = async () => {
      try {
        setImage(null)
        const imageObject = await getImageByUrl({ src, abortController })
        setImage(imageObject)
      } catch {
        // ignored
      }
    }

    if (!src) {
      return () => {}
    }

    downloadImageAsync()

    return () => {
      abortController.abort()
    }
  }, [src])

  useUnmount(() => {
    if (image) {
      URL.revokeObjectURL(image.src)
      image.src = ''
    }
  })

  return image
}
