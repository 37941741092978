import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'

import { withStyles } from '@material-ui/core'

import * as playerActions from '@tabeeb/modules/player/actions'
import * as playerSelectors from '@tabeeb/modules/player/selectors'

import styles from './styles'

const ActivityMarkers = ({ classes }) => {
  const dispatch = useDispatch()

  const handleChangeAction = useCallback((event) => {
    const targetTimestamp = parseFloat(event.target.dataset.timestamp)

    dispatch(playerActions.seekVideoToTimestamp(targetTimestamp * 1000))
  }, [])

  const formAnswersMarkers = useSelector(playerSelectors.getFormAnswersTimelineMarkers)
  const annotationsMarkers = useSelector(playerSelectors.getAnnotationsTimelineMarkers)

  const duration = useSelector(playerSelectors.getDuration) || -1
  if (duration <= 0) {
    return null
  }

  const coefficient = 100 / duration

  const markers = _.uniqBy([...formAnswersMarkers, ...annotationsMarkers], (marker) => marker.Timestamp)

  return (
    <>
      {markers.map((marker) => (
        <div
          key={marker.Timestamp}
          className={classes.marker}
          onClick={handleChangeAction}
          data-timestamp={marker.Timestamp}
          style={{ left: `${marker.Timestamp * coefficient}%` }}
        />
      ))}
    </>
  )
}

ActivityMarkers.propTypes = {
  classes: PropTypes.shape({
    marker: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(ActivityMarkers))
