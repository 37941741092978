import { combineActions, handleActions } from 'redux-actions'
import { PretrainedModelName, ClassifierType, ObjectDetectorType } from '../enums'

import * as rawActions from '../actions'

const advancedTrainingSettings = {
  BatchSize: 2,
  ClassifierType: ClassifierType.LogisticRegression,
  ConfidenceThreshold: 0.5,
  EarlyStoppingPatience: 5,
  EarlyStoppingMinDelta: 0.0001,
  ImageEncoderLayersPercent: 7,
  LearningRate: 0.001,
  NumberOfCrossValidationFolds: 1,
  NumberOfEpochs: 0,
  ObjectDetector: ObjectDetectorType.FasterRCNN50,
  PretrainedModelName: PretrainedModelName.DinoV2Base,
  TestDataSize: 0.2,
  TextEncoderLayersPercent: 7,
}

const defaultState = {
  iterationType: -1,
  annotationSource: -1,
  settings: advancedTrainingSettings,
}

export default handleActions(
  {
    [rawActions.setTrainingSettings](state, { payload }) {
      return payload
    },
    [rawActions.setAnnotationSource](state, { payload }) {
      return { ...state, annotationSource: payload }
    },
    [rawActions.setAdvancedTrainingSettings](state, { payload }) {
      return { ...state, settings: payload }
    },
    [rawActions.setIterationType](state, { payload }) {
      return { ...state, iterationType: payload }
    },
    [combineActions(rawActions.setSelectedProject, rawActions.resetPagesForProjectTraining)](state, action) {
      return defaultState
    },
  },
  defaultState
)
