import { createSelector } from 'reselect'

export const getAllWorkflowsListState = (state) => state.workflows.allWorkflows
export const getTenantWorkflowsListState = (state) => state.workflows.tenantWorkflows
export const getTenantWorkflows = (state) => state.workflows.tenantWorkflows.list

export const getTenantEnabledWorkflowsState = createSelector(
  [getAllWorkflowsListState, getTenantWorkflowsListState],
  (workflowsListState, tenantWorkflowsListState) => {
    const isLoading = workflowsListState.isLoading || tenantWorkflowsListState.isLoading
    const isLoaded = workflowsListState.isLoaded && tenantWorkflowsListState.isLoaded
    const workflows = workflowsListState.list.map((w) => ({
      ...w,
      IsEnabled: tenantWorkflowsListState.list.some((tw) => tw.Id === w.Id),
    }))

    return { workflows, isLoading, isLoaded }
  }
)
