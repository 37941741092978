import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ForgeViewer from 'react-forge-viewer'

import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'
import { getAutodeskData } from '@tabeeb/modules/contentViewer/selectors'
import * as contentViewerActions from '@tabeeb/modules/contentViewer/actions'
import { createConnection, terminateConnection } from '@tabeeb/modules/signalr/actions'
import { autodeskHubName } from '@tabeeb/modules/signalr/services/constants'
import { subscribeToAutodeskUpdates, unsubscribeFromAutodeskUpdates } from './signalr'

import LoadingProgressBar from '../LoadingProgressBar'

const AutodeskViewer = () => {
  const dispatch = useDispatch()
  const [view, setView] = useState(null)
  const selectedPageId = useSelector(getSelectedGalleryItemId)
  const autodeskData = useSelector(getAutodeskData)

  useEffect(() => {
    dispatch(createConnection({ hubName: autodeskHubName }))
    dispatch(subscribeToAutodeskUpdates({ pageId: selectedPageId }))
    dispatch(contentViewerActions.getAutodeskViewable.request(selectedPageId))

    return () => {
      dispatch(unsubscribeFromAutodeskUpdates({ pageId: selectedPageId }))
      dispatch(terminateConnection({ hubName: autodeskHubName }))
    }
  }, [dispatch, selectedPageId])

  const handleTokenRequested = (onAccessToken) => {
    if (onAccessToken) {
      onAccessToken(autodeskData.Token.AccessToken, autodeskData.Token.ExpiresIn)
    }
  }

  return (
    autodeskData &&
    (autodeskData.IsReady ? (
      <ForgeViewer
        urn={autodeskData.Urn}
        view={view}
        onTokenRequest={handleTokenRequested}
        onDocumentLoad={(_, viewables) => setView(viewables[0])}
        onDocumentError={() => {}}
      />
    ) : (
      <LoadingProgressBar value={autodeskData.ProgressPercentage || 0} text='Please wait, the model is getting ready' />
    ))
  )
}

export default AutodeskViewer
