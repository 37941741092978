import { isMobileOnly } from 'react-device-detect'
import { handleActions } from 'redux-actions'

import { updateInContentReviewStatus } from '@tabeeb/modules/contentSharings/actions'

import * as rawActions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [rawActions.getSessions.success](state, action) {
      const contents = isMobileOnly ? [...state, ...action.response.data] : [...action.response.data]

      return contents
    },
    [rawActions.setSessionsList](state, action) {
      return [...action.payload]
    },
    [rawActions.publishSession.success](state, action) {
      const { contentId } = action.payload
      return state.map((content) => (content.Id === contentId ? { ...content, IsPublished: true } : content))
    },
    [rawActions.unpublishSession.success](state, action) {
      const contentId = action.payload
      return state.map((content) => (content.Id === contentId ? { ...content, IsPublished: false } : content))
    },
    [rawActions.updateContentState](state, action) {
      const updatedContent = action.payload
      return state.map((content) => (content.Id === updatedContent.Id ? { ...content, ...updatedContent } : content))
    },
    // new
    [rawActions.switchSessionOwner.success](state, action) {
      const { contentId, newOwner } = action.payload
      return state.map((content) => {
        if (content.Id === contentId) {
          return { ...content, Owner: newOwner }
        }
        return content
      })
    },
    [rawActions.restoreSession.success](state, action) {
      const { contentId } = action.payload
      return state.filter((content) => content.Id !== contentId)
    },
    [updateInContentReviewStatus.success](state, action) {
      const { contentIds, status } = action.payload
      return state.map((content) => (contentIds.includes(content.Id) ? { ...content, ReviewStatus: status } : content))
    },
    //
    [rawActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
