import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { gallerySelectors } from '@tabeeb/modules/gallery'

import * as contentViewerActions from '../../actions'

const DocumentViewer = ({ contentViewerActions, url }) => {
  const handleLoad = () => {
    contentViewerActions.resetLoadingInProgress()
  }

  return (
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
      width='100%'
      height='100%'
      frameBorder='0'
      onLoad={handleLoad}
    />
  )
}

DocumentViewer.propTypes = {
  url: PropTypes.string.isRequired,
  contentViewerActions: PropTypes.shape({
    resetLoadingInProgress: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  const selectedGalleryItem = gallerySelectors.getSelectedGalleryItem(state)

  return {
    url: selectedGalleryItem.assets[0].Url,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contentViewerActions: bindActionCreators(contentViewerActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentViewer)
