import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.openTrash]() {
      return true
    },
    [rawActions.closeTrash]() {
      return false
    },
    [rawActions.resetState]() {
      return false
    },
  },
  false
)
