export default (theme) => {
  return {
    marker: {
      cursor: 'pointer',
      backgroundColor: 'red',
      height: '100%',
      width: 4,
      position: 'absolute',
      top: 0,
    },
  }
}
