import { memo } from 'react'

import { SearchOutlined } from '@material-ui/icons'
import Placeholder from '@tabeeb/modules/calendar/components/Placeholders/Placeholder'

const NoFormFoundPlaceholder = () => {
  return <Placeholder icon={SearchOutlined} text='No forms found...' />
}

export default memo(NoFormFoundPlaceholder)
