import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { ContentSharingModelType, TabPanel, TabPanelLocation } from '@tabeeb/enums'
import { getFormTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import { SharedContentDataType } from '@tabeeb/modules/contentSharings/constants/enums'

import * as rawActions from '../actions'
import * as notificationsActions from '../../notification/actions'
import ShareSessionDialogContainer from '../../sessionsPage/containers/ShareSessionDialogContainer'

import Timeline from '../../timeline/components/Timeline'
import { getContentReviewDefaultType } from '@tabeeb/modules/contentSharings/selectors'

class ReviewSessionPagesListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isShareSessionDialogOpen: false,
    }

    this.actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        class: 'submit',
      },
    ]
  }

  _handleClose = () => {
    const {
      actions: { closeReviewSessionPagesDialog },
    } = this.props
    closeReviewSessionPagesDialog()
  }

  _handleSubmit = () => {
    const {
      pagesList,
      notificationsActions: { onAddErrorNotification },
      selectedForms,
      formTitle,
    } = this.props

    if (selectedForms.length === 0) {
      onAddErrorNotification({ message: `At least one ${formTitle} should be added` })
    } else if (pagesList.length > 0) {
      this.setState({ isShareSessionDialogOpen: true })
    } else {
      onAddErrorNotification({ message: 'At least one page should be added' })
    }
  }

  _handleDeleteItem = (pageId) => {
    const {
      actions: { deletePageForReviewing },
    } = this.props

    deletePageForReviewing(pageId)
  }

  _groupById = (pages) => {
    const resultPages = pages.reduce((r, p) => {
      r[p.groupById] = r[p.groupById] || []
      r[p.groupById].push(p)
      return r
    }, Object.create(null))

    return resultPages
  }

  render() {
    const { isOpen, pagesList, currentPanel, isProcessing, contentForms, defaultReviewType } = this.props
    const props = {
      actions: this.actions,
      timeLineList: this._groupById(pagesList),
      handleDeleteItem: this._handleDeleteItem,
      isShareByFormTimeline: true,
      isOpen,
      isSubmitted: isProcessing,
      isLoading: isProcessing,
      isSelected: currentPanel === TabPanel.ReviewSession,
      contentForms,
      isForReview: true,
    }

    const { isShareSessionDialogOpen } = this.state
    const dialogProps = {
      onClose: () => this.setState({ isShareSessionDialogOpen: false }),
      open: isShareSessionDialogOpen && defaultReviewType,
      contentSharingType: defaultReviewType,
      dataType: SharedContentDataType.SelectedData,
    }

    return (
      <>
        <Timeline {...props} />
        <ShareSessionDialogContainer {...dialogProps} />
      </>
    )
  }
}

ReviewSessionPagesListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  currentPanel: PropTypes.string.isRequired,
  pagesList: PropTypes.array.isRequired,
  notificationsActions: PropTypes.shape({
    onAddErrorNotification: PropTypes.func.isRequired,
  }),
  actions: PropTypes.shape({
    deletePageForReviewing: PropTypes.func.isRequired,
    closeReviewSessionPagesDialog: PropTypes.func.isRequired,
  }),
  contentForms: PropTypes.array.isRequired,
  selectedForms: PropTypes.array.isRequired,
  formTitle: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  const { reviewSessionPagesList, navbar, forms, shareSessionPagesList } = state

  return {
    isOpen: reviewSessionPagesList.isTimelineOpen,
    isProcessing: false,
    pagesList: reviewSessionPagesList.pagesList,
    currentPanel: navbar.currentPanel[TabPanelLocation.Bottom],
    contentForms: forms.contentForms,
    selectedForms: shareSessionPagesList.shareByFormDialogState.selectedForms || [],
    formTitle: getFormTitleLowerCase(state),
    defaultReviewType: getContentReviewDefaultType(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
  notificationsActions: bindActionCreators(notificationsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSessionPagesListContainer)
