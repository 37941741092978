import { handleActions } from 'redux-actions'

import { setCallProcessingState } from '../actions/index'

const defaultValue = false

export default handleActions(
  {
    [setCallProcessingState](state, { payload }) {
      return payload
    },
  },
  defaultValue
)
