import qs from 'qs'

const aiModelsRequests = {
  addAiModelRequest: (data) => ({
    url: `api/ai/models`,
    method: 'post',
    data,
  }),
  updateAiModelRequest: (data) => ({
    url: `api/ai/models`,
    method: 'put',
    data,
  }),
  deleteAiModelRequest: ({ nlpModelId: aiModelId }) => ({
    url: `api/ai/models${aiModelId}`,
    method: 'delete',
  }),
  searchAiModelsRequest: ({ searchText, skip = 0, take = 50, attached = true, aiObjectsIds = [] }) => ({
    url: `api/ai/models`,
    method: 'get',
    params: {
      attached,
      aiObjectsIds,
      search: searchText,
      skip,
      take,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }),
  getUnattachedAiModelsRequest: ({ aiModelType = null }) => ({
    url: `api/ai/models/unattached`,
    method: 'get',
    params: {
      type: aiModelType,
    },
  }),
}

export default aiModelsRequests
