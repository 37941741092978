import { Box, Button, Paper } from '@mui/material'
import { AddOutlined, Refresh } from '@mui/icons-material'
import { PageHeader, PageLayout } from '@tabeeb/uikit'
import PropTypes from 'prop-types'

const UserQualificationsPageHeader = ({
  children,
  loading,
  onGetUserQualificationsMutated,
  onAddDialogOpen,
  isHidden,
}) => {
  return isHidden ? (
    children
  ) : (
    <Box sx={{ width: '100%' }}>
      <PageLayout component={Paper} variant='outlined' spacing='page' maxWidth='lg'>
        <PageHeader
          title='User qualifications'
          actions={
            <>
              <Button
                variant='outlined'
                startIcon={<Refresh color='primary' />}
                disabled={loading}
                onClick={onGetUserQualificationsMutated}
              >
                Refresh
              </Button>
              <Button variant='outlined' startIcon={<AddOutlined color='primary' />} onClick={onAddDialogOpen}>
                Assign user qualification
              </Button>
            </>
          }
        />
        {children}
      </PageLayout>
    </Box>
  )
}

UserQualificationsPageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  onGetUserQualificationsMutated: PropTypes.func.isRequired,
  onAddDialogOpen: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
}

export default UserQualificationsPageHeader
