import { Sema } from 'async-sema'

const PARALLEL_REQUESTS_COUNT = 50

const semaphore = new Sema(PARALLEL_REQUESTS_COUNT, {
  capacity: PARALLEL_REQUESTS_COUNT,
})

const loadImage = (src) => {
  return new Promise((resolve) => {
    const image = new Image()

    image.onload = () => {
      resolve(image)
    }

    image.src = src
  })
}

export const getImageByUrl = async ({ src, abortController = null }) => {
  await semaphore.acquire()

  try {
    const url = new URL(src)

    // eslint-disable-next-line compat/compat
    const response = await fetch(url, {
      signal: abortController?.signal,
    })

    let blob = await response.blob()

    const imageExtension = url.pathname.split('.').pop().toLowerCase()
    const isHeicHeifImage = ['heic', 'heif'].includes(imageExtension)
    if (isHeicHeifImage) {
      try {
        const { default: heic2any } = await import('heic2any')

        blob = await heic2any({
          blob,
          toType: 'image/jpeg',
          quality: 0.8,
        })
      } catch {
        // ignored
      }
    }

    if (abortController?.signal.aborted) {
      return null
    }

    const image = await loadImage(URL.createObjectURL(blob))

    return image
  } finally {
    semaphore.release()
  }
}
