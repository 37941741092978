import { combineReducers } from 'redux'

import isImportFromExcelDialogOpen from './isImportFromExcelDialogOpen'
import workbook from './workbook'
import sheetNames from './sheetNames'
import selectedSheet from './selectedSheet'
import errorMessage from './errorMessage'

export default combineReducers({
  isImportFromExcelDialogOpen,
  workbook,
  sheetNames,
  selectedSheet,
  errorMessage,
})
