import FilterPanelContainer from '../../containers/FilterPanelContainer'
import ActivitiesListContainer from '../../containers/ActivitiesListContainer'

import './styles.less'

const Activities = () => (
  <section className='activities'>
    <FilterPanelContainer />
    <ActivitiesListContainer />
  </section>
)

export default Activities
