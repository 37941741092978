import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.less'

const TimeActivity = ({ durationTime, annotationTime }) => {
  if (durationTime === null && annotationTime === null) return null

  const playButton =
    durationTime === null ? null : (
      <span>
        <i className={classNames('tabeeb-icon-play', 'play-audio-button')} />
        Duration: {durationTime}
      </span>
    )

  return (
    <p>
      {playButton}
      {annotationTime && `@${annotationTime}`}
    </p>
  )
}

TimeActivity.propTypes = {
  durationTime: PropTypes.string,
  annotationTime: PropTypes.string,
}

export default TimeActivity
