import { createAction } from 'redux-actions'

export const reset = createAction('RESET_POLICIES')

export const openPoliciesViewer = createAction('OPEN_POLICIES_VIEWER')
export const closePoliciesViewer = createAction('CLOSE_POLICIES_VIWER')

export const getPolicyRequest = createAction('GET_POLICY_REQUEST')
export const getPolicySuccess = createAction('GET_POLICY_SUCCESS')
export const getPolicyFailed = createAction('GET_POLICY_FAILED')

export const getLatestPolicyRequest = createAction('GET_LATEST_POLICY_REQUEST')
export const getLatestPolicySuccess = createAction('GET_LATEST_POLICY_SUCCESS')
export const getLatestPolicyFailed = createAction('GET_LATEST_POLICY_FAILED')

export const createPolicyRequest = createAction('CREATE_POLICY_REQUEST')
export const createPolicySuccess = createAction('CREATE_POLICY_SUCCESS')
export const createPolicyFailed = createAction('CREATE_POLICY_FAILED')

export const getIsUserNeedsToAcceptChangesRequest = createAction('GET_IS_USER_NEEDS_TO_ACCEPT_CHANGES_REQUEST')
export const getIsUserNeedsToAcceptChangesSuccess = createAction('GET_IS_USER_NEEDS_TO_ACCEPT_CHANGES_SUCCESS')
export const getIsUserNeedsToAcceptChangesFailed = createAction('GET_IS_USER_NEEDS_TO_ACCEPT_CHANGES_FAILED')

export const getLatestPoliciesWithAcceptanceStatusRequest = createAction(
  'GET_LATEST_POLICIES_WITH_ACCEPTANCE_STATUS_REQUEST'
)
export const getLatestPoliciesWithAcceptanceStatusSuccess = createAction(
  'GET_LATEST_POLICIES_WITH_ACCEPTANCE_STATUS_SUCCESS'
)
export const getLatestPoliciesWithAcceptanceStatusFailed = createAction(
  'GET_LATEST_POLICIES_WITH_ACCEPTANCE_STATUS_FAILED'
)

export const acceptPoliciesRequest = createAction('ACCEPT_POLICIES_REQUEST')
export const acceptPoliciesSuccess = createAction('ACCEPT_POLICIES_SUCCESS')
export const acceptPoliciesFailed = createAction('ACCEPT_POLICIES_FAILED')
