import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'

import styles from './styles'

const Title = ({ classes, title }) => {
  return (
    <Typography className={classes.title} title={title}>
      {title}
    </Typography>
  )
}

Title.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}

export default memo(withStyles(styles)(Title))
