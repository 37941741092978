import { takeLatest, select, put, all } from 'redux-saga/effects'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import * as playerActions from '../actions'
import * as galleryActions from '../../gallery/actions'

import { isVideoPage } from '../../../services/pageService'
import { signalrEvents, signalrConstants } from '../../signalr'
import { whiteboardService } from '../../whiteboard'

function* selectGalleryItem(action) {
  const selectedPage = yield select(gallerySelectors.getSelectedGalleryItem)

  if (selectedPage && isVideoPage(selectedPage.contentType)) {
    if (selectedPage.durationVideoTime) {
      yield put(playerActions.setDurationVideoTimestamp(selectedPage.durationVideoTime / 1000))
    }

    yield put(playerActions.getAnswersTimelineForVideoPageRequest({ pageId: selectedPage.id }))
  }
}

function* onPresenterState(action) {
  const [{ currentTime, videoPaused }] = action.payload

  if (videoPaused) {
    yield put(playerActions.onPlayerPaused())
  } else {
    yield put(playerActions.onPlayerPlay())
  }

  yield put(playerActions.seekVideoToTimestamp(currentTime))
}

function* playerSaga() {
  yield all([
    takeLatest(galleryActions.selectGalleryItem, selectGalleryItem),
    takeLatest(
      [playerActions.seekVideoToTimestamp, playerActions.onPlayerPaused, playerActions.onPlayerPlay],
      whiteboardService.updatePresenterState
    ),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onPresenterState, onPresenterState),
  ])
}

export default playerSaga
