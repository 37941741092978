import { handleActions, combineActions } from 'redux-actions'

import { ProjectType } from '@tabeeb/enums'
import * as rawActions from '../actions'

const defaultState = null
const newProject = {
  Name: '',
  Description: '',
  Type: ProjectType.VQA,
}

export default handleActions(
  {
    [rawActions.openCreateProjectDialog](state, action) {
      return { ...newProject }
    },
    [rawActions.openEditProjectDialog](state, action) {
      return { ...action.payload }
    },
    [combineActions(
      rawActions.closeCreateProjectDialog,
      rawActions.addProject.request,
      rawActions.updateProject.request
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
