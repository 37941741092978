import { signalrEvents } from '@tabeeb/modules/signalr'
import { handleActions } from 'redux-actions'
import {
  getRemindersByContentSuccess,
  createReminderSuccess,
  deleteReminderSuccess,
  updateReminderSuccess,
} from '../actions'

const defaultValue = []

const _updateReminder = (remindersList, updatedReminder) => {
  if (updatedReminder.IsDeleted) return remindersList.filter((r) => r.Id !== updatedReminder.Id)
  return remindersList.map((rem) => (rem.Id === updatedReminder.Id ? updatedReminder : rem))
}

export default handleActions(
  {
    [getRemindersByContentSuccess](state, { response }) {
      const reminders = response.data

      return reminders
    },
    [createReminderSuccess](state, { response }) {
      const createdReminder = response.data

      return [...state, createdReminder]
    },
    [deleteReminderSuccess](state, { payload }) {
      const deletedReminderId = payload

      return state.filter((r) => r.Id !== deletedReminderId)
    },
    [updateReminderSuccess](state, { response }) {
      const updatedReminder = response.data

      return _updateReminder(state, updatedReminder)
    },
    [signalrEvents.tabeeb.onReminderUpdated](state, { payload }) {
      const [updatedReminder] = payload

      return _updateReminder(state, updatedReminder)
    },
  },
  defaultValue
)
