import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_2D_LINE_DRAWING_STATE')

export const add2dLineDrawing = createAction('ADD_2D_LINE_DRAWING')
export const addTwoDLineDrawingRequest = createAction('ADD_TWO_D_LINE_DRAWING_REQUEST')
export const addTwoDLineDrawingSuccess = createAction('ADD_TWO_D_LINE_DRAWING_SUCCESS')
export const addTwoDLineDrawingFailed = createAction('ADD_TWO_D_LINE_DRAWING_FAILED')

export const open2dLineDrawingDialog = createAction('OPEN_2D_LINE_DRAWING_DIALOG')
export const close2dLineDrawingDialog = createAction('CLOSE_2D_LINE_DRAWING_DIALOG')

export const addPageTo2dLineDrawing = createAction('ADD_PAGE_TO_2D_LINE_DRAWING')
export const addAllPagesTo2dLineDrawing = createAction('ADD_ALL_PAGES_TO_2D_LINE_DRAWING')
export const deletePageFrom2dLineDrawing = createAction('DELETE_PAGE_FROM_2D_LINE_DRAWING')
export const updatePages2dLineDrawing = createAction('UPDATE_PAGES_2D_LINE_DRAWING')

export const stop2dLineDrawingProcessing = createAction('STOP_2D_LINE_DRAWING_PROCESSING')

export const regenerateTwoDLineDrawingRequest = createAction('REGENERATE_TWO_D_LINE_DRAWING_REQUEST')
export const regenerateTwoDLineDrawingSuccess = createAction('REGENERATE_TWO_D_LINE_DRAWING_SUCCESS')
export const regenerateTwoDLineDrawingFailed = createAction('REGENERATE_TWO_D_LINE_DRAWING_FAILED')
