import { cloneElement, useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

const ControlsPanelButton = ({ backgroundColor, isVisible, button, menu, disabled, title }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const menuProps = { anchorEl, setAnchorEl }

  if (!isVisible) return null

  return (
    <div title={title} className='gallery-button-container'>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant='contained'
        style={{ backgroundColor }}
        disabled={disabled}
        className='gallery-button'
      >
        {button}
      </Button>
      {cloneElement(menu, { ...menuProps })}
    </div>
  )
}

ControlsPanelButton.defaultProps = {
  disabled: false,
}

ControlsPanelButton.propTypes = {
  backgroundColor: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  button: PropTypes.string.isRequired,
  menu: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

export default ControlsPanelButton
