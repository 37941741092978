import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import * as rawActions from '../actions'
import { ConfirmationDialog } from '@tabeeb/uikit'

class DeleteDialogContainer extends Component {
  onDeleteItem = () => {
    const { actions, deleteItemId } = this.props
    actions.deleteGalleryItemRequest({ pageId: deleteItemId })
  }

  onCloseDialog = () => {
    const { actions } = this.props
    actions.setDeleteGalleryItem(null)
  }

  render() {
    const { deleteItemId } = this.props

    const dialogProps = {
      onCloseDialog: this.onCloseDialog,
      onSubmit: this.onDeleteItem,
      isOpen: !_.isNull(deleteItemId),
      title: 'The page will be deleted.',
      submitButtonText: 'Delete',
    }

    return <ConfirmationDialog {...dialogProps} />
  }
}

DeleteDialogContainer.propTypes = {
  deleteItemId: PropTypes.number,
  actions: PropTypes.shape({
    selectGalleryItem: PropTypes.func.isRequired,
    deleteGalleryItemRequest: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ gallery }) => {
  return {
    deleteItemId: gallery.deleteItemId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialogContainer)
