import PropTypes from 'prop-types'

import FileUploadContainer from '../../containers/FileUploadContainer'

import './styles.less'

const FileUploadsList = ({ fileUploads }) => {
  return (
    <div className='file-uploads-list-container'>
      {fileUploads.length === 0 ? (
        <p className='no-files-message'>No files uploaded</p>
      ) : (
        <div className='file-uploads-list'>
          {fileUploads.map((fileUpload, index) => (
            <FileUploadContainer key={index} item={fileUpload} />
          ))}
        </div>
      )}
    </div>
  )
}

FileUploadsList.propTypes = {
  fileUploads: PropTypes.array.isRequired,
}

export default FileUploadsList
