import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      padding: theme.spacing(0.75),
      borderRadius: theme.shape.borderRadius,
      borderWidth: 1,
      borderColor: theme.palette.action.disabled,
      borderStyle: 'solid',
    },
    divider: {
      opacity: 0.5,
      color: theme.palette.text.disabled,
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
    },
    duration: {
      opacity: 0.5,
    },
  }
})
