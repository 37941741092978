export default (theme) => {
  return {
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      ...theme.typography.h6,
    },
  }
}
