import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      zIndex: 1,
      marginTop: theme.spacing(2),
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
    },
    paper: {
      padding: theme.spacing(),
      borderRadius: '100%',
      width: 42,
      height: 42,
    },
  }
})
