import { combineActions, handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = []

const redoActions = handleActions(
  {
    [actions.setRedoActions](state, { payload }) {
      return [...payload]
    },
    [actions.addRedoAction](state, { payload }) {
      return [...state, payload]
    },
    [actions.deleteRow.success](state, { payload }) {
      return state.filter(({ action }) => action.payload.item?.rowId !== payload.itemId)
    },
    [actions.resetContentMetadata](state, { payload }) {
      return defaultState
    },
  },
  defaultState
)

export default redoActions
