import { all, takeLatest, takeEvery, put } from 'redux-saga/effects'

import * as galleryActions from '@tabeeb/modules/gallery/actions'
import * as aiActions from '../actions'
import * as notificationActions from '../../notification/actions'
import { signalrEvents, signalrConstants } from '../../signalr'
import { getAiAnnotationPropertiesText } from '../../annotations/services/annotationsService'

function* onUpdateUniqueAiObjectSuccess(action) {
  if (!action.payload.suppressNotification) {
    yield put(notificationActions.onAddInfoNotification({ message: 'Properties successfully updated' }))
  }
  const uniqueAIObject = action.response.data

  const propertiesText = getAiAnnotationPropertiesText(uniqueAIObject.Properties)
  yield put(
    galleryActions.updateAiAnnotationsText({
      uniqueAiObjectId: uniqueAIObject.Id,
      uniqueAiObjectName: uniqueAIObject.Name,
      propertiesText,
    })
  )
}

function* onUpdateUniqueAiObjectFailed(action) {
  if (!action.payload.suppressNotification) {
    yield put(notificationActions.onAddErrorNotification({ message: 'Failed to update the object.' }))
  }
}

function* onUniqueAIObjectUpdated(action) {
  const [uniqueAIObject] = action.payload

  const propertiesText = getAiAnnotationPropertiesText(uniqueAIObject.Properties)

  yield put(
    galleryActions.updateAiAnnotationsText({
      uniqueAiObjectId: uniqueAIObject.Id,
      uniqueAiObjectName: uniqueAIObject.Name,
      propertiesText,
    })
  )
}

function* updateUniqueAIObject() {
  yield all([
    takeLatest(aiActions.updateUniqueAiObjectSuccess, onUpdateUniqueAiObjectSuccess),
    takeLatest(aiActions.updateUniqueAiObjectFailed, onUpdateUniqueAiObjectFailed),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onUniqueAIObjectUpdated, onUniqueAIObjectUpdated),
  ])
}

export default updateUniqueAIObject
