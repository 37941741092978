import PropTypes from 'prop-types'

const InlineEditInput = ({
  maxLength,
  classNameInput,
  handleKeyDown,
  value,
  handleChangeName,
  handleBlurInput,
  moveCaretToEnd,
  disabled,
}) => {
  return (
    <input
      autoFocus
      disabled={disabled}
      onFocus={moveCaretToEnd}
      type='text'
      maxLength={maxLength}
      className={classNameInput}
      onBlur={handleBlurInput}
      onChange={handleChangeName}
      onKeyDown={handleKeyDown}
      value={value}
    />
  )
}

InlineEditInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.string,
  classNameInput: PropTypes.string,
  handleBlurInput: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func,
  moveCaretToEnd: PropTypes.func.isRequired,
}

export default InlineEditInput
