export default (theme) => {
  return {
    container: {
      position: 'absolute',
      color: 'white',
      background: 'black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    icon: {
      fontSize: 54,
      marginRight: theme.spacing() * 2,
    },
    text: {
      maxWidth: 512,
    },
  }
}
