import { createAction } from 'redux-actions'

export const openCreateVqaPolicyDialog = createAction('OPEN_CREATE_VQA_POLICY_DIALOG')
export const startOpeningCreateVqaPolicyWithPredefinedTagsDialog = createAction(
  'START_OPENING_CREATE_VQA_POLICY_WITH_PREDEFINED_TAGS_DIALOG'
)
export const openCreateVqaPolicyWithPredefinedTagsDialog = createAction(
  'OPEN_CREATE_VQA_POLICY_WITH_PREDEFINED_TAGS_DIALOG'
)
export const openEditVqaPolicyDialog = createAction('OPEN_EDIT_VQA_POLICY_DIALOG')
export const closeCreateVqaPolicyDialog = createAction('CLOSE_CREATE_VQA_POLICY_DIALOG')

export const getVqaPoliciesRequest = createAction('GET_VQA_POLICIES_REQUEST')
export const getVqaPoliciesSuccess = createAction('GET_VQA_POLICIES_SUCCESS')
export const getVqaPoliciesFailed = createAction('GET_VQA_POLICIES_FAILED')

export const addVqaPolicyRequest = createAction('ADD_VQA_POLICY_REQUEST')
export const addVqaPolicySuccess = createAction('ADD_VQA_POLICY_SUCCESS')
export const addVqaPolicyFailed = createAction('ADD_VQA_POLICY_FAILED')

export const updateVqaPolicyRequest = createAction('UPDATE_VQA_POLICY_REQUEST')
export const updateVqaPolicySuccess = createAction('UPDATE_VQA_POLICY_SUCCESS')
export const updateVqaPolicyFailed = createAction('UPDATE_VQA_POLICY_FAILED')

export const setVqaPolicyToDelete = createAction('SET_VQA_POLICY_DELETE')

export const deleteVqaPolicyRequest = createAction('DELETE_VQA_POLICY_REQUEST')
export const deleteVqaPolicySuccess = createAction('DELETE_VQA_POLICY_SUCCESS')
export const deleteVqaPolicyFailed = createAction('DELETE_VQA_POLICY_FAILED')

export const getAiObjectsForVqaPolicyRequest = createAction('GET_AI_OBJECTS_FOR_VQA_POLICY_REQUEST')
export const getAiObjectsForVqaPolicySuccess = createAction('GET_AI_OBJECTS_FOR_VQA_POLICY_SUCCESS')
export const getAiObjectsForVqaPolicyFailed = createAction('GET_AI_OBJECTS_FOR_VQA_POLICY_FAILED')

export const getTagsForVqaPolicyRequest = createAction('GET_TAGS_FOR_VQA_POLICY_REQUEST')
export const getTagsForVqaPolicySuccess = createAction('GET_TAGS_FOR_VQA_POLICY_SUCCESS')
export const getTagsForVqaPolicyFailed = createAction('GET_TAGS_FOR_VQA_POLICY_FAILED')
