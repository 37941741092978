import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

import { List } from '@mui/material'

import Invite from '../Invite'
import InviteSkeleton from '../Invite/Skeleton'

const InvitesList = ({ loaded, invites, onOpenMenu, onUpdateInviteRole }) => {
  const placeholder = useMemo(
    () => (
      <>
        <InviteSkeleton />
        <InviteSkeleton />
        <InviteSkeleton />
        <InviteSkeleton />
      </>
    ),
    []
  )

  return (
    <List disablePadding dense>
      {loaded &&
        invites.map((invite) => (
          <Invite key={invite.Id} invite={invite} onOpenMenu={onOpenMenu} onUpdateInviteRole={onUpdateInviteRole} />
        ))}
      {!loaded && placeholder}
    </List>
  )
}

InvitesList.propTypes = {
  loaded: PropTypes.bool.isRequired,
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  onUpdateInviteRole: PropTypes.func.isRequired,
}

export default memo(InvitesList)
