import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import { ArticleContentType } from '../../enums'

import ContentSkeleton from './ContentSkeleton'
import PdfContent from './PdfContent'
import HtmlContent from './HtmlContent'

const ArticleContent = ({ content }) => {
  return (
    <Box flexGrow={1} overflow='hidden'>
      <Box display='flex' justifyContent='center' overflow='auto' height='100%'>
        {content?.Type === ArticleContentType.Html && <HtmlContent html={content.Content} />}
        {content?.Type === ArticleContentType.Pdf && <PdfContent url={content.Content} />}
      </Box>
    </Box>
  )
}

ArticleContent.propTypes = {
  content: PropTypes.shape({
    Type: PropTypes.oneOf(Object.values(ArticleContentType)).isRequired,
    Content: PropTypes.string.isRequired,
  }),
}

export default memo(ArticleContent)

export { ContentSkeleton as ArticleContentSkeleton }
