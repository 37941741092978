import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Pagination as MuiPagination, PaginationItem } from '@mui/material'

import { setPageNumber } from '@tabeeb/modules/sessionsPage/actions'

import { getPagesCount, getPaginationPageNumber } from '../../selectors/pagination'

const Pagination = () => {
  const dispatch = useDispatch()

  const pagesCount = useSelector(getPagesCount)
  const pageNumber = useSelector(getPaginationPageNumber)

  const onChange = useCallback(
    (_, newPage) => {
      dispatch(setPageNumber(newPage))
    },
    [dispatch]
  )

  return (
    <MuiPagination
      disabled={pagesCount < 2}
      count={pagesCount}
      page={pageNumber}
      renderItem={(item) => <PaginationItem {...item} sx={{ color: 'inherit' }} />}
      onChange={onChange}
    />
  )
}

export default memo(Pagination)
