import { memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Button from '@material-ui/core/Button'
import * as spatialModelActions from '../../../spatialModel/actions'

import { getIsSpatialModelOpen } from '../../../spatialModel/selectors'
import { getPagesByFolder } from '../../selectors'

const SpatialModelAllPagesButton = ({
  isSpatialModelOpen,
  spatialModelActions: { addAllPagesToModel },
  galleryList,
}) => {
  const _handleAddAllPagesSpatialModel = (e) => {
    e.preventDefault()
    e.stopPropagation()

    addAllPagesToModel(galleryList)
  }

  return isSpatialModelOpen ? (
    <Button onClick={_handleAddAllPagesSpatialModel} className='gallery-options-button' variant='contained'>
      add all pages to 360° view
    </Button>
  ) : null
}

SpatialModelAllPagesButton.propTypes = {
  isSpatialModelOpen: PropTypes.bool.isRequired,
  spatialModelActions: PropTypes.shape({
    addAllPagesToModel: PropTypes.func.isRequired,
  }).isRequired,
  galleryList: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  return {
    isSpatialModelOpen: getIsSpatialModelOpen(state),
    galleryList: getPagesByFolder(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    spatialModelActions: bindActionCreators(spatialModelActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(SpatialModelAllPagesButton))
