import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultValue = null

export default handleActions(
  {
    [rawActions.getContentBentleyGigapixels.success](state, action) {
      const { data } = action.response
      return {
        UsedGigapixels: data.UsedGigapixels,
        UpcomingGigapixels: data.UpcomingGigapixels,
      }
    },
    [rawActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
