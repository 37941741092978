import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { GalleryContextMenuContext } from '../../contexts'

import GalleryItemsMenu from '../GalleryItemsMenu'

const GalleryItemsMenuContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const onOpen = useCallback((e) => {
    setPosition({ x: e.clientX, y: e.clientY })
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const value = useMemo(() => ({ open, onOpen, onClose }), [onClose, onOpen, open])

  return (
    <GalleryContextMenuContext.Provider value={value}>
      {children}
      <GalleryItemsMenu open={open} position={position} onClose={onClose} />
    </GalleryContextMenuContext.Provider>
  )
}

GalleryItemsMenuContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GalleryItemsMenuContextProvider
