import PropTypes from 'prop-types'

import './styles.less'

const TenantFormControl = ({ formControl, renderControl }) => {
  return (
    <div className='form-control-item'>
      <div className='form-control-item-header'>
        <div className='form-control-item-label'>
          <span className='form-control-item-label-text'>{formControl.Label}</span>
        </div>
      </div>
      <div className='form-control-item-body'>
        <div className='form-control-item-answers'>{renderControl()}</div>
      </div>
    </div>
  )
}

TenantFormControl.propTypes = {
  formControl: PropTypes.object.isRequired,
  renderControl: PropTypes.func.isRequired,
}

export default TenantFormControl
