import { handleActions } from 'redux-actions'

import { setMode, resetFormBuilderState } from '../actions'
import { PageMode } from '../constants'

const defaultState = PageMode.create

export default handleActions(
  {
    [setMode](state, action) {
      return action.payload
    },
    [resetFormBuilderState]() {
      return defaultState
    },
  },
  defaultState
)
