import { memo } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Typography } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'

import AIObjectInfoSkeleton from './Skeleton'

import useStyles from './styles'

const AIObjectInfo = ({ item, onRemove }) => {
  const classes = useStyles()

  return (
    <div className={classes.container} title={item.Name}>
      <div className={classes.content}>
        <Typography className={classes.title} noWrap>
          {item.Name}
        </Typography>
      </div>
      {onRemove && (
        <IconButton size='small' onClick={onRemove}>
          <CloseOutlined size='small' color='error' />
        </IconButton>
      )}
    </div>
  )
}

AIObjectInfo.propTypes = {
  item: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
  onRemove: PropTypes.func,
}

export default memo(AIObjectInfo)

export { AIObjectInfoSkeleton }
