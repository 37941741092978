import { handleActions, combineActions } from 'redux-actions'

import * as aiActions from '../actions'

const defaultState = []

function _markAsLoaded(model) {
  return { ...model, IsLoaded: true }
}

function _addOrUpdateVqaModel(state, vqaModel) {
  const index = state.findIndex((vqaM) => vqaM.Id === vqaModel.Id)
  let result
  if (index === -1) {
    result = [...state, vqaModel]
  } else {
    result = [...state]
    result[index] = vqaModel
  }
  return result
}

export default handleActions(
  {
    [aiActions.getVqaModels.success](state, { response }) {
      return response.data.map((vqaModel) => _markAsLoaded(vqaModel))
    },
    [combineActions(aiActions.updateVqaModel.success, aiActions.addVqaModel.success)](state, { response }) {
      return _addOrUpdateVqaModel(state, response.data)
    },
    [aiActions.deleteVqaModel.success](state, { payload: { vqaModelId } }) {
      return state.filter((vqaModel) => vqaModel.Id !== vqaModelId)
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
