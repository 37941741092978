import { alpha } from '@material-ui/core/styles'

export default (theme) => {
  const recordingColor = theme.palette.error.light

  return {
    container: {
      display: 'flex',
      alignItems: 'center',

      color: theme.palette.secondary.light,
      backgroundColor: theme.palette.background.user.action,

      padding: theme.spacing() / 2,
      paddingLeft: theme.spacing() * 0.75,
      paddingRight: theme.spacing() * 0.75,

      zIndex: 1,
      height: '100%',

      boxShadow: `0px 0px 1px 1px ${recordingColor}`,
      borderRadius: theme.shape.borderRadius,

      userSelect: 'none',
    },
    icon: {
      background: recordingColor,
      borderRadius: '100%',
      width: 8,
      height: 8,
      marginRight: theme.spacing() / 2,
      boxShadow: `0 0 0 0 ${recordingColor}`,
      animation: '$pulse 2s infinite',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: `0 0 0 0 ${alpha(recordingColor, 0.7)}`,
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: `0 0 0 8px ${alpha(recordingColor, 0)}`,
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: `0 0 0 0 ${alpha(recordingColor, 0)}`,
      },
    },
  }
}
