import { ControlType } from '@tabeeb/enums'
import { calculateValue } from './calculateDefaultValueOrCounterLimit'

export function hasControlBeenUpdated(updatedForm) {
  let controlHasBeenChanged = false
  const controlsWithIncrement = []
  updatedForm.Controls.forEach((control) => {
    if (control.IncrementValue !== null) controlsWithIncrement.push(control)
  })

  for (let i = 0; i < controlsWithIncrement.length; i++) {
    const control = controlsWithIncrement[i]
    const { IncrementValue, IncrementPeriod } = control
    const isCounter = control.ControlType === ControlType.Counter
    const value = isCounter ? control.CounterLimit : control.InitialValue
    const lastValue = calculateValue(value, IncrementValue, IncrementPeriod, updatedForm.ReattachedNumberOfTimes - 1)
    const newValue = calculateValue(value, IncrementValue, IncrementPeriod, updatedForm.ReattachedNumberOfTimes)
    if (lastValue !== newValue) {
      controlHasBeenChanged = true
      break
    }
  }

  return controlHasBeenChanged
}
