import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { contentStateSelectors } from '@tabeeb/shared/content'

const RecordingControls = ({ children }) => {
  const isFeatureEnabled = useSelector(appConfigStateSelectors.getIsRecordingEnabled)
  const isPresenter = useSelector(contentStateSelectors.getIsCurrentUserPresenter)
  if (!isFeatureEnabled || !isPresenter) {
    return null
  }

  return children
}

RecordingControls.propTypes = {
  children: PropTypes.any.isRequired,
}

export default memo(RecordingControls)
