import { createSelector } from 'reselect'

import { FetchStatus, SessionPermission, TenantPermission } from '@tabeeb/enums'
import { RootState } from '@tabeeb/store'

export const getTenantPermissions = (state: RootState): TenantPermission[] => state.permissions.tenant || []
export const getSessionPermissions = (state: RootState): SessionPermission[] => state.permissions.session.value || []
export const getSessionPermissionsFetchStatus = (state: RootState) =>
  state.permissions.session.status || FetchStatus.Idle
export const getIsSessionPermissionsLoaded = (state: RootState): boolean =>
  getSessionPermissionsFetchStatus(state) === FetchStatus.Loaded

export const hasSessionPermission = createSelector(
  [getSessionPermissions, (_, permission) => permission],
  (permissions, permission) => {
    if (permission === null) {
      return true
    }

    return permissions.includes(permission)
  }
)

export const hasTenantPermission = createSelector(
  [getTenantPermissions, (_, permission) => permission],
  (permissions, permission) => {
    if (permission === null) {
      return true
    }

    return permissions.includes(permission)
  }
)
