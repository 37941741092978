import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, Typography, Button } from '@material-ui/core'
import _ from 'lodash'

import { getMe } from '@tabeeb/modules/account/selectors'
import { getContentId } from '@tabeeb/shared/content/selectors'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'
import ContentUserOptionDialog from '@tabeeb/shared/content/components/ContentUserOptionDialog'
import AddEditDeviceModal, {
  getTenoviSensorCodeByDeviceName,
} from '@tabeeb/modules/admin/devices/components/AddEditDeviceModal'
import { DeviceVendorType } from '@tabeeb/enums'
import { getIsEditPatientDataVisible } from '../../../../users/selectors'

import UserProfileSkeleton from '../UserProfileSkeleton'
import UserProfileForm from '../UserProfileForm'
import UserProfile from '../UserProfile'
import HealthInfoDevices from '../HealthInfoDevices'
import HealthStatisticsChartsSelect from '../HealthStatisticsChartsSelect'
import HealthStatisticsCharts from '../HealthStatisticsCharts'
import {
  getChartsHealthDataOfPatient,
  getChartsTypesAndNamesOfPatient,
  getDevicesOfPatient,
  getViewedPatient,
} from '../../selectors'
import * as healthActions from '../../actions'

import useStyles from './styles'

const HealthInfoDialog = ({ isOpen, onClose, userId, showAddDeviceButton }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const patient = useSelector(getViewedPatient)
  const currentUser = useSelector(getMe)
  const contentId = useSelector(getContentId)
  const healthDevices = useSelector(getDevicesOfPatient)
  const isEditPatientDataVisible = useSelector((state) => getIsEditPatientDataVisible(state, { contentId }))
  const chartsHealthDataOfPatient = useSelector(getChartsHealthDataOfPatient)
  const chartsTypesAndNamesOfPatient = useSelector(getChartsTypesAndNamesOfPatient)

  const [isFormDisabled, setIsFormDisabled] = useState(true)
  const handleFormDisabling = useCallback(() => setIsFormDisabled(true), [])
  const handleFormActivation = useCallback(() => setIsFormDisabled(false), [])

  const [addDeviceModelOpen, setAddDeviceModelOpen] = useState(false)
  const onOpenAddDeviceModal = useCallback(() => setAddDeviceModelOpen(true), [])
  const onCloseAddDeviceModal = useCallback(() => setAddDeviceModelOpen(false), [])

  const onAddHealthDataChart = useCallback(
    (healthDataChartToAdd) => {
      dispatch(healthActions.addPatientHealthDataChart(healthDataChartToAdd))
    },
    [dispatch]
  )

  const onRemoveHealthDataChart = useCallback(
    (healthDataChartToDelete) => {
      dispatch(healthActions.removePatientHealthDataChart(healthDataChartToDelete))
    },
    [dispatch]
  )

  const handleAddHealthData = useCallback(
    (data) => {
      dispatch(healthActions.onAddHealthDataOfViewedPatient(data))
    },
    [dispatch]
  )

  const setPatientChartInterval = useCallback(
    (setChartIntervalModel) => {
      dispatch(healthActions.setPatientChartInterval(setChartIntervalModel))
    },
    [dispatch]
  )

  const handleAddDevice = useCallback(
    (values) => {
      onCloseAddDeviceModal()
      if (values.Type === DeviceVendorType.Tenovi) {
        values.TenoviSensorCodeType = getTenoviSensorCodeByDeviceName(values.TenoviDeviceNameType)
      }

      dispatch(healthActions.handleCreateHealthDeviceOnUsersPage(values))
    },
    [dispatch, onCloseAddDeviceModal]
  )

  useEffect(() => {
    if (isOpen) {
      dispatch(healthActions.getHealthDevicesByUserIdRequest({ userId, contentId: contentId !== 0 ? contentId : null }))
      dispatch(
        healthActions.onViewPatientHealthInfo({
          PatientId: userId,
          PatientTenantId: currentUser.TenantId,
        })
      )

      return () => {
        dispatch(
          healthActions.onCloseHealthInfoDialog({
            PatientId: userId,
            PatientTenantId: currentUser.TenantId,
          })
        )
      }
    }
  }, [contentId, dispatch, userId, isOpen, currentUser.IdentityGuid, currentUser.TenantId])

  const healthStatisticsChartsProps = {
    showAddHealthDataOption: patient.Id === currentUser.Id || isEditPatientDataVisible,
    userId: patient.IdentityGuid,
    userSystemOfMeasure: isEditPatientDataVisible ? currentUser.SystemOfMeasure : patient.SystemOfMeasure,
    handleAddHealthData,
    chartsHealthData: chartsHealthDataOfPatient,
    setChartInterval: setPatientChartInterval,
  }

  return (
    <>
      <ContentUserOptionDialog
        open={isOpen}
        onClose={onClose}
        title='Health info'
        leftPart={
          _.isEmpty(patient) ? (
            <UserProfileSkeleton />
          ) : (
            <Grid item container direction='column' spacing={1}>
              <Grid item className={classes.avatarGrid}>
                <UserAvatar user={patient} className={classes.avatarBlock} />
              </Grid>
              <Grid item className={classes.nameGrid}>
                <Typography>
                  {patient.Name}{' '}
                  {patient.DateOfBirth &&
                    `(${new Date().getUTCFullYear() - new Date(patient.DateOfBirth).getFullYear()})`}
                </Typography>
              </Grid>
              {isFormDisabled ? (
                <UserProfile
                  patient={patient}
                  isEditPatientDataVisible={isEditPatientDataVisible}
                  handleFormActivation={handleFormActivation}
                />
              ) : (
                <UserProfileForm patient={patient} handleFormDisabling={handleFormDisabling} />
              )}
              {healthDevices.length > 0 && <HealthInfoDevices healthDevices={healthDevices} />}
              {showAddDeviceButton && (
                <Grid item>
                  <Button color='primary' fullWidth variant='outlined' onClick={() => onOpenAddDeviceModal()}>
                    Add health device
                  </Button>
                </Grid>
              )}
              <Grid item>
                <HealthStatisticsChartsSelect
                  onAddHealthDataChart={onAddHealthDataChart}
                  onRemoveHealthDataChart={onRemoveHealthDataChart}
                  chartsTypesAndNames={chartsTypesAndNamesOfPatient}
                />
              </Grid>
            </Grid>
          )
        }
        rightPart={<HealthStatisticsCharts {...healthStatisticsChartsProps} />}
        showRightPart={chartsHealthDataOfPatient.length > 0}
      />
      <AddEditDeviceModal
        onClose={onCloseAddDeviceModal}
        onSubmit={handleAddDevice}
        open={addDeviceModelOpen}
        assignedForUserId={userId}
      />
    </>
  )
}

HealthInfoDialog.defaultProps = {
  showAddDeviceButton: false,
}

HealthInfoDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string,
  showAddDeviceButton: PropTypes.bool,
}

export default HealthInfoDialog
