import { PolicyType } from '../../../../Enums'

const policiesRequests = {
  getPolicyRequest: ({ id }) => ({
    url: `api/policies/${id}`,
    method: 'get',
  }),
  getLatestPolicyRequest: ({ policyType, subdomain }) => ({
    url: `api/policies/latest/${policyType}${subdomain ? `?subdomain=${subdomain}` : ''}`,
    method: 'get',
  }),
  getLatestPoliciesWithAcceptanceStatusRequest: () => ({
    url: `api/policies/latestPolicies`,
    method: 'get',
  }),
  createPolicyRequest: (data) => ({
    url: `api/policies`,
    method: 'post',
    data,
  }),
  getIsUserNeedsToAcceptChangesRequest: () => ({
    url: `api/policies/needsUpdate`,
    method: 'get',
  }),
  acceptPoliciesRequest: (data) => ({
    url: `api/policies/accept`,
    method: 'post',
    data,
  }),
}

export default policiesRequests
