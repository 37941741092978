import { memo } from 'react'
import PropTypes from 'prop-types'

import { Person, Schedule } from '@material-ui/icons'
import { withStyles } from '@material-ui/core'

import Title from '../Title'
import InfoLine from '../InfoLine'
import PagePreview from '../PagePreview'

import { convertDateToString } from '../../../services'

import styles from './styles'

const PageInfo = ({ classes, page }) => (
  <div className={classes.container}>
    <PagePreview url={page.thumbnailUrl} title={page.thumbnailCaption} />
    <div className={classes.content}>
      <Title title={page.thumbnailCaption} />

      <InfoLine icon={Person} value={page.ownerUserName} title='Owner' />
      <InfoLine icon={Schedule} value={convertDateToString(page.dateCreated)} title='Creation time' />
    </div>
  </div>
)

PageInfo.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  page: PropTypes.shape({
    thumbnailCaption: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    dateCreated: PropTypes.string.isRequired,
    ownerUserName: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(PageInfo))
