import { useState, useRef, useCallback } from 'react'

export default function usePopoverState() {
  const achorRef = useRef()
  const [open, setIsOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return [achorRef, open, handleOpen, handleClose]
}
