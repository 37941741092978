import { handleActions } from 'redux-actions'

import calendarActions from '../actions'

const defaultState = {
  open: false,
  event: null,
}

export default handleActions(
  {
    [calendarActions.openSwitchSessionOwnerDialog](state, action) {
      return {
        open: true,
        event: action.payload,
      }
    },
    [calendarActions.closeSwitchSessionOwnerDialog](state, action) {
      return {
        ...state,
        open: false,
      }
    },
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
  },
  defaultState
)
