import { memo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SwipeableDrawer,
  Typography,
  makeStyles,
} from '@material-ui/core'

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import Puller from '../Puller'

import { sortingOrders } from '../../constants'

const useStyles = makeStyles((theme) => ({
  drawer: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  drawerHeader: {
    padding: theme.spacing(2),
  },
}))

const PhoneSortingControls = ({
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  sortingFields,
  selectedSortingField,
  onSortingFieldChange,
  sortingOrder,
  onSortingOrderChange,
}) => {
  const classes = useStyles()

  const handleUpdateSortingFieldWithOrder = useCallback(
    (newSortingField) => {
      if (newSortingField !== selectedSortingField) {
        onSortingFieldChange(newSortingField)
      } else if (sortingOrder === sortingOrders.ascending) {
        onSortingOrderChange(sortingOrders.descending)
      } else if (sortingOrder === sortingOrders.descending) {
        onSortingOrderChange(sortingOrders.ascending)
      } else {
        onSortingOrderChange(sortingOrders.default)
      }
    },
    [selectedSortingField, sortingOrder, onSortingFieldChange, onSortingOrderChange]
  )

  useEffect(() => {
    return () => {
      onMenuClose()
    }
  }, [onMenuClose])

  return (
    <>
      <Button
        id='select-sorting-field-button'
        aria-haspopup
        aria-expanded={isMenuOpen}
        aria-controls={isMenuOpen ? 'sortable-fields-menu' : undefined}
        onClick={onMenuOpen}
        endIcon={
          sortingOrder === sortingOrders.ascending ? (
            <ArrowDownwardIcon fontSize='small' />
          ) : (
            <ArrowUpwardIcon fontSize='small' />
          )
        }
      >
        {selectedSortingField}
      </Button>
      <SwipeableDrawer
        open={isMenuOpen}
        anchor='bottom'
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        disableSwipeToOpen
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <Puller />
        <Typography className={classes.drawerHeader} variant='h6'>
          Sort by
        </Typography>
        <Divider variant='fullWidth' />
        <MenuList autoFocusItem={isMenuOpen} aria-labelledby='select-sorting-field-button' id='sortable-fields-menu'>
          {sortingFields.map((fieldName) => (
            <MenuItem key={fieldName} onClick={() => handleUpdateSortingFieldWithOrder(fieldName)}>
              {selectedSortingField === fieldName && (
                <ListItemIcon>
                  {sortingOrder === sortingOrders.ascending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                </ListItemIcon>
              )}
              <ListItemText inset={selectedSortingField !== fieldName}>{fieldName}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </SwipeableDrawer>
    </>
  )
}

PhoneSortingControls.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  sortingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedSortingField: PropTypes.string.isRequired,
  onSortingFieldChange: PropTypes.func.isRequired,
  sortingOrder: PropTypes.oneOf(Object.values(sortingOrders)).isRequired,
  onSortingOrderChange: PropTypes.func.isRequired,
}

export default memo(PhoneSortingControls)
