import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { GUIDRegexp } from '@tabeeb/modules/shared/constants'
import { updatePowerBIReportProvider } from '../../actions'

const usePowerBIProviderForm = (provider) => {
  const dispatch = useDispatch()

  const initialValues = useMemo(
    () => ({ name: provider.Name, ownerId: provider.OwnerId }),
    [provider.Name, provider.OwnerId]
  )

  const onProviderSubmit = useCallback(
    (values, actions) => {
      const requestData = {
        name: values.name,
      }
      dispatch(updatePowerBIReportProvider.request({ id: provider.Id, data: requestData }))
      actions.resetForm({ values })
    },
    [dispatch, provider.Id]
  )

  const providerForm = useFormik({
    initialValues,
    onSubmit: onProviderSubmit,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Provider name is required.'),
      ownerId: Yup.string().matches(GUIDRegexp, 'Must be a valid GUID.').required('Owner is required.'),
    }),
  })

  return providerForm
}

export default usePowerBIProviderForm
