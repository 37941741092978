import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { discussionActions } from '@tabeeb/modules/discussion'
import { FormAnswersDiscussion } from '@tabeeb/modules/shared/icons'

import PageInfoControl from '../PageInfoControl'

const PageAnswersDiscussionButton = () => {
  const dispatch = useDispatch()

  const page = useSelector(gallerySelectors.getSelectedGalleryItem)

  const onOpen = useCallback(() => {
    dispatch(discussionActions.openPageFormAnswersDialog({ page }))
  }, [page])

  return <PageInfoControl icon={FormAnswersDiscussion} title='Open page answers' onClick={onOpen} />
}

PageAnswersDiscussionButton.propTypes = {}

export default memo(PageAnswersDiscussionButton)
