import { handleActions, combineActions } from 'redux-actions'

import {
  onAddErrorNotification,
  onAddInfoNotification,
  onAddSuccessNotification,
  onAddWarningNotification,
  onDismissNotification,
  onRemoveNotification,
} from '../actions'

const defaultState = {
  notifications: [],
}

export default handleActions(
  {
    [combineActions(onAddSuccessNotification, onAddErrorNotification, onAddWarningNotification, onAddInfoNotification)](
      state,
      { payload: notification }
    ) {
      return {
        notifications: [...state.notifications, notification],
      }
    },
    [onDismissNotification](state, { payload }) {
      return {
        notifications: state.notifications.map((notification) =>
          payload.dismissAll || notification.key === payload.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      }
    },
    [onRemoveNotification](state, { payload }) {
      return {
        notifications: state.notifications.filter((notification) => notification.key !== payload.key),
      }
    },
  },
  defaultState
)
