import { memo, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Grid, Card, CardHeader, IconButton } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import domainService from '@tabeeb/services/domainService'

import TenantAvatar from '../TenantAvatar'
import InlineTenantMenu from '../InlineTenantMenu'

import { useStyles } from './styles'

const TenantCard = ({ actionsVariant, loading, name, subdomain, tenantIndex, onContextMenu }) => {
  const classes = useStyles()

  const CardAvatar = useMemo(() => {
    return loading ? <Skeleton variant='circle' width={40} height={40} /> : <TenantAvatar name={name} />
  }, [loading, name])

  const CardTitle = useMemo(() => (loading ? <Skeleton /> : name), [loading, name])

  const tenantSite = useMemo(() => domainService.createHostnameWithSubdomain(`${subdomain}.`), [subdomain])
  const CardSubheader = useMemo(() => (loading ? <Skeleton /> : tenantSite), [loading, tenantSite])

  const InlineMenu = useMemo(() => <InlineTenantMenu tenantIndex={tenantIndex} />, [tenantIndex])

  const handleOptionsButtonClick = useCallback(
    (event) => {
      onContextMenu(tenantIndex, event.currentTarget)
    },
    [tenantIndex, onContextMenu]
  )

  const ContextMenuButton = useMemo(
    () => (
      <IconButton aria-haspopup aria-controls='tenant-context-menu' onClick={handleOptionsButtonClick}>
        <MoreVertIcon />
      </IconButton>
    ),
    [handleOptionsButtonClick]
  )

  const CardActions = useMemo(() => {
    return !loading && (actionsVariant === 'inline' ? InlineMenu : ContextMenuButton)
  }, [loading, actionsVariant, InlineMenu, ContextMenuButton])

  return (
    <Grid item>
      <Card variant='outlined'>
        <CardHeader
          classes={{
            root: classes.tenantCardHeaderRoot,
            action: classes.tenantCardHeaderAction,
          }}
          avatar={CardAvatar}
          title={CardTitle}
          subheader={CardSubheader}
          action={CardActions}
        />
      </Card>
    </Grid>
  )
}

TenantCard.defaultProps = {
  loading: false,
}

TenantCard.propTypes = {
  actionsVariant: PropTypes.oneOf(['inline', 'context-menu']),
  loading: PropTypes.bool,
  name: PropTypes.string,
  subdomain: PropTypes.string,
  onContextMenu: PropTypes.func,
  tenantIndex: PropTypes.number,
}

export default memo(TenantCard)
