import { useCallback } from 'react'

import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import ai from '@tabeeb/services/telemetryService'

function useFallbackMiddleware({ onError }) {
  const middleware = useCallback(
    (children) => (
      <AppInsightsErrorBoundary onError={onError} appInsights={ai.reactPlugin}>
        {children}
      </AppInsightsErrorBoundary>
    ),
    [onError]
  )

  return middleware
}

export default useFallbackMiddleware
