import { memo } from 'react'
import PropTypes from 'prop-types'

import { ConfirmationDialog } from '@tabeeb/uikit'

const DeleteReportTenantConfirmationDialog = ({ open, onClose, onSubmit }) => {
  return (
    <ConfirmationDialog
      isOpen={open}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      title='Are you sure you want to delete this report tenant?'
      submitButtonText='Yes'
      cancelButtonText='No'
    />
  )
}

DeleteReportTenantConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(DeleteReportTenantConfirmationDialog)
