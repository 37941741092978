import { combineReducers } from 'redux'

import isTimelineOpen from './isTimelineOpen'
import imagesList from './imagesList'
import sourceFolders from './sourceFolders'

export default combineReducers({
  isTimelineOpen,
  imagesList,
  sourceFolders,
})
