import { all } from 'redux-saga/effects'

import formControls from './formControls'
import contentFormsSaga from './contentForms'
import currentForm from './currentForm'
import formAnswers from './formAnswers'
import exportForm from './exportForm'
import attachDetachForm from './attachDetachForm'
import formAnswersStatus from './formAnswersStatus'
import counterInfo from './counterInfo'
import formActions from './formActions'
import initialValues from './initialValues'
import folders from './folders'

function* formsSaga() {
  yield all([
    contentFormsSaga(),
    currentForm(),
    formControls(),
    formAnswers(),
    exportForm(),
    attachDetachForm(),
    formAnswersStatus(),
    counterInfo(),
    formActions(),
    initialValues(),
    folders(),
  ])
}

export default formsSaga
