import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'
import { IterationPagesDisplayMode } from '../enums'

const defaultState = IterationPagesDisplayMode.All

export default handleActions(
  {
    [combineActions(rawActions.setCurrentIterationPagesDisplayMode)](state, { payload }) {
      return payload
    },
    [combineActions(rawActions.setSelectedIterationId)](state, { payload: iterationId }) {
      return iterationId === 0 ? defaultState : IterationPagesDisplayMode.IncludedOnly
    },
    [combineActions(rawActions.resetSelectedIterationId, rawActions.setSelectedProject)](state) {
      return defaultState
    },
  },
  defaultState
)
