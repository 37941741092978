import { memo } from 'react'
import PropTypes from 'prop-types'

import Reminder from '@tabeeb/modules/reminders/components/Reminder'
import { ReminderType } from '@tabeeb/enums'
import { withStyles } from '@material-ui/core'
import DeleteAnswerButton from '@tabeeb/modules/forms/components/DeleteAnswerButton'
import EditAnswerButton from '@tabeeb/modules/forms/components/EditAnswerButton'
import QuestionAnswersDiscussionButton from '@tabeeb/modules/forms/components/QuestionAnswersDiscussionButton'
import FormControlInfoButton from '@tabeeb/modules/forms/components/FormControlInfoButton'
import AnswerStatus from '@tabeeb/modules/forms/components/AnswerStatus'
import CancelAnswerEditingButton from '@tabeeb/modules/forms/components/CancelAnswerEditingButton'
import ApplyAnswerEditingButton from '@tabeeb/modules/forms/components/ApplyAnswerEditingButton'
import { Chip } from '@mui/material'
import styles from './styles'

const FormControlButtons = ({
  answer,
  setIsEditingAnswer,
  control,
  handleSubmit,
  isEditingAnswer,
  cancelUpdateAnswer,
  isOnReview,
  classes,
}) => {
  const deleteBtnProps = { answerId: answer?.Id, control }
  const editBtnProps = { setIsEditingAnswer, control, isEditingAnswer }
  const cancelBtnProps = { cancelUpdateAnswer, isEditingAnswer }
  const applyBtnProps = { handleSubmit, isEditingAnswer }

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {isOnReview && <Chip label='Currently in review' size='small' sx={{ color: '#616161' }} />}
        {!isOnReview && (
          <>
            <DeleteAnswerButton {...deleteBtnProps} />
            <EditAnswerButton {...editBtnProps} />
            <CancelAnswerEditingButton {...cancelBtnProps} />
            <ApplyAnswerEditingButton {...applyBtnProps} />
            <QuestionAnswersDiscussionButton question={control} />
            <Reminder type={ReminderType.AnswerFormQuestion} entity={control} />
            <FormControlInfoButton control={control} />
          </>
        )}
      </div>

      <AnswerStatus answer={answer} isOnReview={isOnReview} />
    </div>
  )
}

FormControlButtons.propTypes = {
  control: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  setIsEditingAnswer: PropTypes.func.isRequired,
  answer: PropTypes.shape({
    Id: PropTypes.number,
  }),
  isEditingAnswer: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancelUpdateAnswer: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
  }).isRequired,
  isOnReview: PropTypes.bool.isRequired,
}

export default memo(withStyles(styles)(FormControlButtons))
