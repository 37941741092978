import { CertificateScopeType, CertificateStatusType } from '@tabeeb/enums'

export const getReviewAllowed = ({
  personal,
  currentUserId,
  currentUserIdentityGuid,
  certificateStatusId,
  certificateScopeId,
  certificateOwnerProfileId,
  certificateOwnerIdentityGuid,
  hasManageCertificatesTenantScopePermission,
  hasManageCertificatesGlobalScopePermission,
}) => {
  return (
    !personal &&
    certificateStatusId === CertificateStatusType.PendingReview &&
    (certificateOwnerProfileId ? certificateOwnerProfileId !== currentUserId : true) &&
    (certificateOwnerIdentityGuid ? certificateOwnerIdentityGuid !== currentUserIdentityGuid : true) &&
    (certificateScopeId === CertificateScopeType.TenantCertificate
      ? hasManageCertificatesGlobalScopePermission
      : hasManageCertificatesTenantScopePermission)
  )
}

export const getCertificateScopeTypesAccordingToPermissions = ({
  personal,
  hasManageCertificatesGlobalScopePermission,
  hasManageCertificatesTenantScopePermission,
}) => {
  return Object.values(CertificateScopeType).filter((value) => {
    if (personal) {
      return value !== CertificateScopeType.TenantCertificate
    }

    return Boolean(hasManageCertificatesTenantScopePermission || hasManageCertificatesGlobalScopePermission)
  })
}
