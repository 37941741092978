import { combineReducers } from 'redux'

import showSelfView from './showSelfView'
import showCallControls from './showCallControls'
import showCallPreview from './showCallPreview'
import showUserList from './showUserList'
import isLoading from './isLoading'

export default combineReducers({
  showSelfView,
  showCallControls,
  showCallPreview,
  showUserList,
  isLoading,
})
