import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Dialog, TextField, DialogTitle, DialogContent, Divider, IconButton, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import classNames from 'classnames'
import { getFormsTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import AvailableFormsItemContainer from '../../containers/AvailableFormsItemContainer'
import CircularProgressBar from '../../../presentation/components/CircularProgressBar'

import { openFolder } from '../../actions'
import FoldersList from '../../../shared/folders/FoldersList'

import { attachDialogStyles } from './inlineStyles'

import './styles.less'

const AttachFormsDialog = ({ forms, isOpen, onCloseDialog, loadingForms, isAttachDetachProcessing }) => {
  const [searchText, setSearchText] = useState('')
  const { foldersList, currentFolder } = useSelector((state) => state.forms)
  const formsTitle = useSelector(getFormsTitleLowerCase)

  const dispatch = useDispatch()

  const _onTextChanged = (event) => {
    const { value } = event.target
    setSearchText(value)
  }

  const _closeDialog = () => {
    setSearchText('')
    onCloseDialog()
  }

  const _handleExitFolder = () => {
    dispatch(openFolder({}))
    setSearchText('')
  }

  const _handleOpenFolder = useCallback((folder) => dispatch(openFolder(folder)), [dispatch])

  const filteredFormsList =
    forms && searchText && forms.filter((item) => item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)

  const formsList = filteredFormsList || forms

  const foldersListProps = {
    isOpen: !currentFolder.id,
    list: foldersList,
    openFolder: _handleOpenFolder,
  }

  const dialogTitle = currentFolder.id ? (
    <>
      <IconButton
        className='available-forms-exit-button'
        aria-label='exit-folder'
        title='Click to exit the folder'
        onClick={_handleExitFolder}
      >
        <ArrowBack />
      </IconButton>
      <Typography title={currentFolder.name} variant='inherit' component='span'>
        {`Available ${formsTitle} / ${currentFolder.name}`}
      </Typography>
    </>
  ) : (
    `Available ${formsTitle}`
  )

  return (
    <Dialog
      PaperProps={{
        className: 'available-forms-dialog',
        style: attachDialogStyles,
      }}
      open={isOpen}
      onClose={_closeDialog}
    >
      <DialogTitle className='available-forms-title-container'>{dialogTitle}</DialogTitle>
      <Divider />
      <DialogContent>
        {loadingForms ? (
          <CircularProgressBar />
        ) : (
          <div>
            <div className='available-forms-search'>
              <TextField value={searchText} placeholder='Search...' fullWidth onChange={_onTextChanged} />
            </div>
            <div
              className={classNames({
                forms__availableToAttach: true,
                'available-forms-dialog-disabled': isAttachDetachProcessing,
              })}
            >
              <FoldersList className='available-forms-folders-container' {...foldersListProps} />
              {formsList.map((form, index) => (
                <AvailableFormsItemContainer form={form} key={`${form.FormId}-${index}`} />
              ))}
              {formsList.length === 0 && <p className='no-available-forms-message'>No {formsTitle} found</p>}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

AttachFormsDialog.propTypes = {
  forms: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loadingForms: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  isAttachDetachProcessing: PropTypes.bool.isRequired,
}

export default AttachFormsDialog
