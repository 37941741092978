import { put, takeLatest, all } from 'redux-saga/effects'

import { ProjectEventType } from '@tabeeb/enums'
import { signalrEvents, signalrConstants } from '../../signalr'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onNotifyProject(action) {
  const [notification] = action.payload

  if (notification.eventType === ProjectEventType.IterationAdded) {
    yield put(actions.getIterationById.request({ Id: notification.iterationId }))
    return
  }

  if (notification.eventType === ProjectEventType.IterationTrainingStatusUpdated) {
    yield put(
      actions.updateIterationTrainingStatus({
        Id: notification.iterationId,
        Status: notification.status,
        ErrorMessage: notification.errorMessage,
        WarningMessage: notification.warningMessage,
      })
    )

    yield put(notificationActions.onAddInfoNotification({ message: "Iteration's status has been updated" }))
    return
  }

  if (notification.eventType === ProjectEventType.IterationDeploymentStatusUpdated) {
    yield put(
      actions.updateIterationDeploymentStatus({
        Id: notification.iterationId,
        Status: notification.status,
        ExpirationTime: notification.expirationTime,
      })
    )

    yield put(notificationActions.onAddInfoNotification({ message: "Iteration's deployment status has been updated" }))
  }
}

function* updateProject() {
  yield all([
    // signalr
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onNotifyProject, onNotifyProject),
  ])
}

export default updateProject
