import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { updatePowerBIReportName } from '@tabeeb/modules/powerBIReports/actions'
import { requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'

const usePowerBIReportPropertiesForm = (report) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    (values, actions) => {
      const requestParams = {
        name: values.name,
      }
      dispatch(updatePowerBIReportName.request({ id: report.Id, params: requestParams }))
      actions.resetForm({ values })
    },
    [dispatch, report.Id]
  )

  const initialValues = useMemo(
    () => ({
      name: report.Name,
    }),
    [report.Name]
  )

  const form = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(requiredField),
    }),
  })

  return form
}

export default usePowerBIReportPropertiesForm
