import { useState } from 'react'
import PropTypes from 'prop-types'

import { getEditedServerUtcDate, getUtcDate } from '@tabeeb/services/dateTimeService'

import DateFnsUtils from '@date-io/date-fns'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { ReminderError } from '../../services/reminderErrors'

import './styles.less'

const ReminderDate = ({ currentReminder, setReminderSettings, errors }) => {
  const [isChanged, setIsChanged] = useState(false)

  const _handleChange = (value) => {
    const utcDate = getUtcDate(value)
    setReminderSettings({ ...currentReminder, StartDate: utcDate })
    setIsChanged(true)
  }

  const startDate = getEditedServerUtcDate(currentReminder.StartDate)

  const error = errors.find((e) => e === ReminderError.InvalidStartDate)
  const showError = error && isChanged

  return (
    <div className='reminder-date-container'>
      <div className='reminder-date'>
        <span className='reminder-date-icon'>
          <ScheduleIcon fontSize='inherit' />
        </span>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            className='reminder-date-picker'
            value={startDate}
            onChange={_handleChange}
            format='MMMM d, yyyy    hh:mm aaaa'
          />
        </MuiPickersUtilsProvider>
      </div>
      {showError && <span className='reminder-error'>{error}</span>}
    </div>
  )
}

ReminderDate.propTypes = {
  currentReminder: PropTypes.object.isRequired,
  setReminderSettings: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
}

export default ReminderDate
