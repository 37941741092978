import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as tenantFormsActions from '../actions'
import AttachTenantFormsDialog from '../components/AttachTenantFormsDialog'

class AttachTenantFormsDialogContainer extends Component {
  constructor() {
    super()
    this._onTextChanged = this._onTextChanged.bind(this)
    this._onCloseDialog = this._onCloseDialog.bind(this)
  }

  componentDidMount() {
    const {
      actions: { setTenantFormsSearchText, getAllFoldersInTenantFormsRequest, getAvailableFormsForTenantRequest },
      isInsideFolder,
    } = this.props
    setTenantFormsSearchText('')
    !isInsideFolder && getAllFoldersInTenantFormsRequest()
    getAvailableFormsForTenantRequest()
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isEqualProps = !_.isEqual(this.props, nextProps)
    const isEqualStates = !_.isEqual(this.state, nextState)
    return isEqualProps || isEqualStates
  }

  _onTextChanged(event) {
    const { actions } = this.props
    const text = event.target.value
    actions.setTenantFormsSearchText(text)
  }

  _onCloseDialog() {
    const {
      actions: { closeAvailableFormsForTenantDialog, clearCurrentFolder },
    } = this.props
    closeAvailableFormsForTenantDialog()
    clearCurrentFolder()
  }

  render() {
    const { formsForTenant, foundTenantForms, isAttachDetachProcessing, availableTenantFormsSearchText } = this.props
    const props = {
      forms: availableTenantFormsSearchText ? foundTenantForms : formsForTenant,
      isOpen: this.props.isOpen,
      closeDialog: this._onCloseDialog,
      loadingForms: this.props.loadingForms,
      onTextChanged: this._onTextChanged,
      isAttachDetachProcessing,
    }
    return <AttachTenantFormsDialog {...props} />
  }
}

AttachTenantFormsDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  formsForTenant: PropTypes.array,
  foundTenantForms: PropTypes.array,
  loadingForms: PropTypes.bool.isRequired,
  availableTenantFormsSearchText: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    closeAvailableFormsForTenantDialog: PropTypes.func.isRequired,
    setTenantFormsSearchText: PropTypes.func.isRequired,
    getAllFoldersInTenantFormsRequest: PropTypes.func.isRequired,
    getAvailableFormsForTenantRequest: PropTypes.func.isRequired,
    clearCurrentFolder: PropTypes.func.isRequired,
  }).isRequired,
  isAttachDetachProcessing: PropTypes.bool.isRequired,
  isInsideFolder: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isOpen: state.tenantForms.isAvailableTenantFormsDialogOpen,
  formsForTenant: state.tenantForms.formsForTenant,
  loadingForms: state.tenantForms.loadingFormsForTenant,
  isAttachDetachProcessing: state.forms.isAttachDetachProcessing,
  availableTenantFormsSearchText: state.tenantForms.availableTenantFormsSearchText,
  foundTenantForms: state.tenantForms.foundTenantForms,
  isInsideFolder: !!state.tenantForms.currentFolder.id,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(tenantFormsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AttachTenantFormsDialogContainer)
