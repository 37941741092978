const rootUrl = 'reporting-service/api'

const powerBIRequests = {
  getPowerBiReportRequest: (id) => ({
    url: `${rootUrl}/reports/powerBI/${id}`,
    method: 'get',
  }),
  getPowerBiReportsRequest: (params) => ({
    url: `${rootUrl}/reports/powerBI`,
    method: 'get',
    params,
  }),
  getPowerBiEmbedTokenRequest: (id) => ({
    url: `${rootUrl}/reports/powerBI/${id}/embed`,
    method: 'get',
  }),
  getPowerBiReportEmbedDataRequest: (id) => ({
    url: `${rootUrl}/reports/powerBI/${id}/embed`,
    method: 'get',
  }),
  createPowerBiReportRequest: (data) => ({
    url: `${rootUrl}/reports/powerBI`,
    method: 'post',
    data,
  }),
  updatePowerBiReportRequest: ({ id, data }) => ({
    url: `${rootUrl}/reports/powerBI/${id}`,
    method: 'put',
    data,
  }),
  updatePowerBiReportNameRequest: ({ id, params }) => ({
    url: `${rootUrl}/reports/powerBI/${id}/name`,
    method: 'put',
    params,
  }),
  getPowerBiSecurityModelsRequest: () => ({
    url: `${rootUrl}/security-models`,
    method: 'get',
  }),
  deletePowerBiReportRequest: (id) => ({
    url: `${rootUrl}/reports/powerBI/${id}`,
    method: 'delete',
  }),
  validatePowerBiReportConfigurationRequest: (params) => ({
    url: `${rootUrl}/reports/powerBI/validate-configuration`,
    method: 'get',
    params,
  }),
  getPowerBiReportTenantsRequest: (params) => ({
    url: `${rootUrl}/report-tenants`,
    method: 'get',
    params,
  }),
  deletePowerBiReportTenantRequest: (id) => ({
    url: `${rootUrl}/report-tenants/${id}`,
    method: 'delete',
  }),
  createPowerBiReportTenantRequest: (data) => ({
    url: `${rootUrl}/report-tenants`,
    method: 'post',
    data,
  }),
  updatePowerBiReportTenantRequest: ({ id, data }) => ({
    url: `${rootUrl}/report-tenants/${id}`,
    method: 'put',
    data,
  }),
  getPowerBiReportTenantRolesRequest: (id) => ({
    url: `${rootUrl}/report-tenants/${id}/tenant-roles`,
    method: 'get',
  }),
  getPowerBiReportUsersRequest: (params) => ({
    url: `${rootUrl}/report-users`,
    method: 'get',
    params,
  }),
  deletePowerBiReportUserRequest: (id) => ({
    url: `${rootUrl}/report-users/${id}`,
    method: 'delete',
  }),
  createPowerBiReportUserRequest: (data) => ({
    url: `${rootUrl}/report-users`,
    method: 'post',
    data,
  }),
  getPowerBiReportDependentModulesRequest: (params) => ({
    url: `${rootUrl}/report-dependent-modules`,
    method: 'get',
    params,
  }),
  deletePowerBiReportDependentModuleRequest: (id) => ({
    url: `${rootUrl}/report-dependent-modules/${id}`,
    method: 'delete',
  }),
  createPowerBiReportDependentModuleRequest: (data) => ({
    url: `${rootUrl}/report-dependent-modules/powerBI`,
    method: 'post',
    data,
  }),
}

export default powerBIRequests
