import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, MenuItem, Select, Typography } from '@mui/material'

import { setPageSize } from '@tabeeb/modules/sessionsPage/actions'
import { getPaginationPageSize } from '@tabeeb/modules/sessionsPage/selectors/pagination'

const PageSizeSelector = () => {
  const dispatch = useDispatch()
  const pageSize = useSelector(getPaginationPageSize)

  const onChange = useCallback(
    (e) => {
      dispatch(setPageSize(e.target.value))
    },
    [dispatch]
  )

  return (
    <Box display='flex' alignItems='center'>
      <Typography variant='body2' fontWeight={500} marginRight={2}>
        Page size:
      </Typography>
      <Select disableUnderline sx={{ color: 'inherit' }} value={pageSize} onChange={onChange}>
        <MenuItem value={24}>24</MenuItem>
        <MenuItem value={40}>40</MenuItem>
        <MenuItem value={60}>60</MenuItem>
        <MenuItem value={80}>80</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </Box>
  )
}

export default memo(PageSizeSelector)
