import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.setAnswersStatusesCount](state, action) {
      return [...action.payload]
    },
    [rawActions.resetFormsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
