import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import LazyLoad, { forceCheck } from 'react-lazyload'

import { getImageRatio } from '@tabeeb/services/pageService'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import GalleryItemContainer from '../../containers/GalleryItemContainer'
import FoldersListContainer from '../../containers/FoldersListContainer'
import GalleryItemsMenuContextProvider from '../GalleryItemsMenuContextProvider'
import { getIsGalleryLoading, getSelectedFolderId } from '../../selectors'

import './styles.less'

const getGalleryItemPlaceholder = (item) => {
  const pageRatio = getImageRatio(item)
  const pageHeight = 160 * pageRatio + 68
  return <div id={item.id} className='gallery-item-placeholder' style={{ height: pageHeight }} />
}

const GalleryList = ({ galleryList, listRef, setSelectedItemRef, isGalleryExpanded, isDragAvailable }) => {
  const folderId = useSelector(getSelectedFolderId)

  useEffect(() => {
    const VISIBILITY_CHECK_DELAY_MS = 50

    const timeoutId = setTimeout(() => {
      forceCheck()
    }, VISIBILITY_CHECK_DELAY_MS)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [folderId])

  const galleryItemProps = {
    listRef,
    setSelectedItemRef,
  }

  const isLoading = useSelector(getIsGalleryLoading)

  return (
    <GalleryItemsMenuContextProvider>
      {isLoading ? (
        <CircularProgressBar />
      ) : (
        <ul className='gallery-list'>
          <FoldersListContainer />
          {galleryList.map((item, index) => {
            return (
              <LazyLoad
                key={item.id}
                offset={2000}
                scroll
                once
                debounce
                scrollContainer='.gallery-scrollable'
                placeholder={getGalleryItemPlaceholder(item)}
              >
                <GalleryItemContainer key={item.id} id={item.id} index={index} {...galleryItemProps} />
              </LazyLoad>
            )
          })}
          <div className='gallery-help-text' style={{ fontSize: isGalleryExpanded ? 24 : 18 }}>
            {isDragAvailable && <span>Drag and drop</span>}
          </div>
        </ul>
      )}
    </GalleryItemsMenuContextProvider>
  )
}

GalleryList.propTypes = {
  galleryList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  listRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  setSelectedItemRef: PropTypes.func,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isDragAvailable: PropTypes.bool.isRequired,
}

export default GalleryList
