import { put, takeLatest, all, select } from 'redux-saga/effects'

import * as actions from '../actions'

import { getSelectedIteration, getIsSelectedIterationCompleted, getSelectedProject } from '../selectors'
import { isAIObjectsSelectionSupported, isFormSelectionSupported } from '../services'

function* onSetSelectedIterationId({ payload: iterationId }) {
  if (iterationId <= 0) {
    return
  }

  const selectedIteration = yield select((state) => getSelectedIteration(state))
  const isSelectedIterationCompleted = yield select((state) => getIsSelectedIterationCompleted(state))
  const selectedProject = yield select((state) => getSelectedProject(state))

  if (!selectedIteration.isPagesLoaded) {
    yield put(actions.loadExistingIterationPages())
  }

  if (!selectedIteration.isContentsLoaded) {
    yield put(actions.getContentsByIterationId.request(iterationId))
  }

  if (isSelectedIterationCompleted && !selectedIteration.isMetricsLoaded) {
    yield put(actions.getMetricsByIterationId.request(iterationId))
  }

  if (isAIObjectsSelectionSupported(selectedProject.Type)) {
    if (!selectedIteration.isAIObjectsLoaded) {
      yield put(actions.getAiObjectsByIterationId.request(iterationId))
    }

    if (!selectedIteration.isAnnotationOwnersLoaded) {
      yield put(actions.getAnnotationOwnersByIterationId.request(iterationId))
    }
  }

  if (isFormSelectionSupported(selectedProject.Type)) {
    if (!selectedIteration.isFormsLoaded) {
      yield put(actions.getFormsByIterationId.request(iterationId))
    }

    if (!selectedIteration.isAnswerersLoaded) {
      yield put(actions.getAnswerersByIterationId.request(iterationId))
    }

    if (!selectedIteration.isReviewersLoaded) {
      yield put(actions.getReviewersByIterationId.request(iterationId))
    }

    if (!selectedIteration.isApproversLoaded) {
      yield put(actions.getApproversByIterationId.request(iterationId))
    }

    if (!selectedIteration.isFormControlsLoaded) {
      yield put(actions.getFormControlsByIterationId.request(iterationId))
    }
  }
}

function* selectIteration() {
  yield all([takeLatest(actions.setSelectedIterationId, onSetSelectedIterationId)])
}

export default selectIteration
