import { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { Skeleton, Typography } from '@mui/material'

const ArticleDate = ({ date }) => {
  return (
    <Typography variant='caption' noWrap>
      Posted {moment(date).calendar()}
    </Typography>
  )
}

ArticleDate.propTypes = {
  date: PropTypes.string.isRequired,
}

const ArticleDateSkeleton = () => {
  return <Skeleton width={150} />
}

export default memo(ArticleDate)

export { ArticleDateSkeleton }
