import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    input: {
      height: 'auto',
    },
    dropdownCountriesList: {
      [theme.breakpoints.down(380)]: {
        width: '210px !important',
      },
    },
  }
})
