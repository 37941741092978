import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getVqaModel } from '../actions'

export default ({ id, onError }) => {
  const payload = useMemo(() => ({ id }), [id])

  const { loading, response: vqaModel } = useApiRequest({ request: getVqaModel.request, payload, onError })

  return { loading, vqaModel }
}
