import { put, takeLatest, all } from 'redux-saga/effects'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onDeployIterationSuccess(action) {
  yield put(notificationActions.onAddInfoNotification({ message: 'Iteration deployment started successfully.' }))
}

function* deployment() {
  yield all([takeLatest(actions.deployIteration.success, onDeployIterationSuccess)])
}

export default deployment
