import { useEffect, useState, memo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getFormTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import UploadFormAnswersDialog from '@tabeeb/modules/forms/components/UploadFormAnswersDialog'
import { FormAnswersFromFileLoadingStatus, FormButtons } from '@tabeeb/modules/forms/services/enums'
import FormButton from '@tabeeb/modules/forms/components/FormButton'
import { getFormButtonState } from '@tabeeb/modules/forms/selectors'
import { setFormAnswersFromFileLoadingState } from '@tabeeb/modules/forms/actions'

const UploadFormAnswersButton = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const dispatch = useDispatch()

  const loadingState = useSelector((state) => state.forms.loadingFormAnswersFromFileState)

  const { isVisible, isDisabled } = useSelector((state) => getFormButtonState(state, { button: FormButtons.UploadCsv }))

  const formTitle = useSelector(getFormTitleLowerCase)

  const isLoaded = loadingState.status === FormAnswersFromFileLoadingStatus.Loaded

  const _closeDialog = () => {
    dispatch(setFormAnswersFromFileLoadingState({ status: FormAnswersFromFileLoadingStatus.None }))
    setIsDialogOpen(false)
  }

  useEffect(() => {
    if (isLoaded) return setIsDialogOpen(false)
  }, [isLoaded])

  if (!isVisible) return null

  const dialogProps = {
    onCloseDialog: _closeDialog,
    loadingState,
    isOpen: isDialogOpen,
  }

  const componentProps = {
    onClick: () => setIsDialogOpen(true),
    buttonText: `Upload ${formTitle} answers`,
    isDisabled,
    size: 'small',
  }

  return (
    <>
      <FormButton {...componentProps} />
      <UploadFormAnswersDialog {...dialogProps} />
    </>
  )
}

export default memo(UploadFormAnswersButton)
