import { combineActions, handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.createSession.request](state, action) {
      return true
    },
    [combineActions(rawActions.createSession.failed, rawActions.createSession.success, rawActions.resetState)](
      state,
      action
    ) {
      return false
    },
  },
  false
)
