export default (theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      color: theme.palette.text.secondary,
      flexGrow: 1,
      minWidth: 0,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minWidth: 0,
    },
  }
}
