import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { ReportUserAccessType, ReportUserAccessTypeNames } from '@tabeeb/modules/powerBIReports/enums'

const ReportUserAccessTypeSelect = ({ defaultValue, onChange, helperText, disabled = false, variant = 'standard' }) => {
  const options = useMemo(
    () => [
      { label: ReportUserAccessTypeNames[ReportUserAccessType.Profile], accessType: ReportUserAccessType.Profile },
      { label: ReportUserAccessTypeNames[ReportUserAccessType.Identity], accessType: ReportUserAccessType.Identity },
    ],
    []
  )

  return (
    <Autocomplete
      fullWidth
      size='small'
      options={options}
      disableClearable
      onChange={onChange}
      disabled={disabled}
      defaultValue={defaultValue}
      renderOption={(props, option) => (
        <Typography
          {...props}
          title={option.label}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {option.label}
        </Typography>
      )}
      renderInput={(params) => <TextField {...params} label='Access scope' variant={variant} helperText={helperText} />}
    />
  )
}

ReportUserAccessTypeSelect.propTypes = {
  defaultValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    accessType: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
}

export default ReportUserAccessTypeSelect
