export default (theme) => {
  return {
    container: {
      fontFamily: 'Courier',
      position: 'absolute',
      padding: 0,
    },
    input: {
      width: 'auto',
      maxHeight: '100%',
      padding: 0,
      letterSpacing: 0,
      lineHeight: 1,
      lineHeightStep: 1,
      marginTop: 0.5,
      whiteSpace: 'pre',
      overflow: 'hidden',
    },
  }
}
