import { handleActions } from 'redux-actions'

import { onSelectGalleryItem } from '@tabeeb/modules/gallery/actions'

import { toggleSourcePagesNavigation } from '../actions'

const defaultState = false

export default handleActions(
  {
    [toggleSourcePagesNavigation](state) {
      return !state
    },
    [onSelectGalleryItem]() {
      return false
    },
  },
  defaultState
)
