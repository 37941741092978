import { createAction } from 'redux-actions'

export const updateLocalTrack = createAction('UPDATE_LOCAL_TRACK')
export const removeLocalTrack = createAction('REMOVE_LOCAL_TRACK')
export const setLocalTracks = createAction('SET_LOCAL_TRACKS')
export const resetLocalTracks = createAction('RESET_LOCAL_TRACKS')
export const setTracksError = createAction('SET_TRACKS_ERROR')
export const setTrackMuteState = createAction('SET_TRACK_MUTE_STATE')
export const addRemoteTrack = createAction('ADD_REMOTE_TRACK')
export const removeRemoteTrack = createAction('REMOVE_REMOTE_TRACK')
export const resetTracksForUser = createAction('RESET_TRACKS_FOR_USER')
export const setLargeVideoUser = createAction('SET_LARGE_VIDEO_USER')
export const setGridUser = createAction('SET_GRID_USER')
export const disposeTracks = createAction('DISPOSE_TRACKS')
export const createLocalTracks = createAction('CREATE_LOCAL_TRACKS')
export const attachTrack = createAction('ATTACH_TRACK')
export const stopTracks = createAction('STOP_TRACKS')
export const toggleLargeVideo = createAction('TOGGLE_LARGE_VIDEO')
export const attachLargeVideo = createAction('ATTACH_LARGE_VIDEO')
export const replaceTrack = createAction('REPLACE_TRACK')
export const removeLocalVideoTrack = createAction('REMOVE_LOCAL_VIDEO_TRACK')
export const bindTrackEvents = createAction('BIND_TRACK_EVENTS')
export const unbindTrackEvents = createAction('UNBIND_TRACK_EVENTS')
export const onTrackMuteChanged = createAction('ON_TRACK_MUTE_CHANGED')
export const detachTracksForUser = createAction('DETACH_TRACKS_FOR_USER')
export const initTracksForUser = createAction('INIT_TRACKS_FOR_USER')
export const addPreviewTrack = createAction('ADD_PREVIEW_TRACK')
export const resetPreviewTracks = createAction('RESET_PREVIEW_TRACKS')
export const toggleGridView = createAction('TOGGLE_GRID_VIEW')
export const toggleMobilePriorityView = createAction('TOGGLE_MOBILE_PRIORITY_VIEW')
export const toggleMobileLargeVideo = createAction('TOGGLE_MOBILE_LARGE_VIDEO')
export const toggleMobileUserList = createAction('TOGGLE_MOBILE_USER_LIST')
export const toggleMobileFlash = createAction('TOGGLE_MOBILE_FLASH')
export const updateMobileZoom = createAction('UPDATE_MOBILE_ZOOM')
