import PropTypes from 'prop-types'

import { Group, Line, Path } from 'react-konva'
import { getHighlightedColor } from '../../services/colorsService'

const hotspotIcon =
  'M20.57930105 12.28689564c0-.47066557-.16749892-.88266462-.50249676-1.23583046l-9.70877061-9.69531084c-.34399778-.34416587-.80566146-.63599853-1.38499105-.8758313-.5793296-.23983278-1.10899284-.3598325-1.58882307-.3598325H1.74542272c-.47066363 0-.878161.17216626-1.22199211.5159988-.34416444.3439992-.51616333.75133159-.51616333 1.22216383V7.5070734c0 .47983222.11983256 1.00916432.359831 1.58866298.23999846.57949866.53199657 1.0364976.87582768 1.37149682l9.70893728 9.72247744c.33499784.33499923.74232854.50249884 1.2219921.50249884.4708303 0 .88282764-.16749961 1.23582536-.50249884l6.66729026-6.68081783c.33483117-.3351659.50233009-.74216495.50233009-1.22199717zM5.5815646 5.69424427c-.3394978.33949921-.74916183.50933215-1.22899206.50933215-.47966357 0-.88932759-.16983294-1.2288254-.50933215-.3394978-.33949921-.50916337-.74899826-.50916337-1.22883048 0-.47983222.16966557-.88949794.50916338-1.22883048.3394978-.33949922.74916182-.50933215 1.22882539-.50933215.47983023 0 .88949425.16983293 1.22899206.50933215.33933114.33933254.50916338.74899826.50916338 1.22883048 0 .47983222-.16983224.88933127-.50916338 1.22883048zM25.29093728 11.0106841l-9.70877062-9.69531084C15.2381689.9712074 14.7765052.67937474 14.19700894.43954196c-.57916292-.23983277-1.10882617-.3598325-1.5886564-.3598325H9.56670552c.47983024 0 1.00932682.11999973 1.5886564.3598325.57949626.23999945 1.04115995.5318321 1.38515773.8758313l9.70877061 9.69531084c.3351645.35299919.50283008.76499823.50283008 1.2356638 0 .47983222-.16766558.88683128-.50283008 1.22199717l-6.38195877 6.3821547c.27183157.280666.5114967.48416554.71966202.61099858.20816532.12683303.47533026.19016622.80116149.19016622.47083029 0 .88282763-.16749961 1.23565868-.50249883l6.6671236-6.68082067c.3351645-.33516589.50283008-.74216495.50283008-1.22199717-.00016666-.47066557-.16766558-.88266461-.50283008-1.2356638z'
const detectIcon =
  'M40.75501086.00409605H.0048414v35.0482933l20.37508473 14.95170638 20.37508473-14.95170638zm-4.62877242 32.70382007L20.37992613 44.262875 4.63361382 32.70791612V4.63286847h31.49262462zM18.06391213 29.37640269l14.0097512-11.95380478-3.00484477-3.52172435-11.2741467 9.62090348-6.2033265-4.37727579-2.6684873 3.78247853z'

const AIHotspot = ({
  annotation,
  annotationRef,
  opacity,
  contentScale,
  hovered,
  hoverProps,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
}) => {
  const position = AIHotspot.getXY(annotation)

  const color = selected || hovered ? getHighlightedColor(annotation.Color) : annotation.Color
  const isHotspot = Boolean(annotation.ParentAnnotationId)

  return (
    <>
      {annotation.ParentAnnotationId && (
        <Line
          lineCap='round'
          lineJoin='round'
          listening={false}
          strokeWidth={1}
          stroke={annotation.Color}
          strokeScaleEnabled={false}
          opacity={opacity}
          points={[0, 0, position.x, position.y]}
        />
      )}
      <Group
        id={annotation.Id}
        ref={annotationRef}
        opacity={opacity}
        {...position}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
        {...dragAndDropProps}
      >
        {AIHotspot.getPlaceholder(annotation, contentScale)}
        <Path
          fill={color}
          data={isHotspot ? hotspotIcon : detectIcon}
          scaleX={1 / contentScale}
          scaleY={1 / contentScale}
        />
      </Group>
    </>
  )
}

AIHotspot.getXY = (annotation) => ({
  x: annotation.Anchor.X,
  y: annotation.Anchor.Y,
})

AIHotspot.getSize = (annotation, contentScale = 1) => {
  const isHotspot = Boolean(annotation.ParentAnnotationId)
  if (isHotspot) {
    return {
      width: 28 / contentScale,
      height: 25 / contentScale,
    }
  }

  return {
    width: 40 / contentScale,
    height: 50 / contentScale,
  }
}

AIHotspot.getViewBox = (annotation, contentScale = 1) => {
  const { x, y } = AIHotspot.getXY(annotation, contentScale)

  return {
    topLeftX: x,
    topLeftY: y,
    bottomRightX: x,
    bottomRightY: y,
  }
}

AIHotspot.getPlaceholder = (annotation, contentScale = 1) => {
  const isHotspot = Boolean(annotation.ParentAnnotationId)
  const { width, height } = AIHotspot.getSize(annotation, contentScale)

  return isHotspot ? (
    <Line points={[0, 0, 0.53 * width, 0, width, 0.5 * height, 0.53 * width, height, 0, 0.4 * height]} closed />
  ) : (
    <Line points={[0, 0, width, 0, width, 0.7 * height, 0.5 * width, height, 0, 0.7 * height]} closed />
  )
}

AIHotspot.propTypes = {
  contentScale: PropTypes.number.isRequired,
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Color: PropTypes.string.isRequired,
    Anchor: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
    }).isRequired,
    ParentAnnotationId: PropTypes.number,
  }).isRequired,
  annotationRef: PropTypes.func,
  opacity: PropTypes.number,
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragMove: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
}

export default AIHotspot
