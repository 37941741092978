import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { createFolder } from '../../../actions'

import FolderBaseDialog from '../FolderBaseDialog'

const CreateFolderDialog = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    ({ Name }) => {
      dispatch(createFolder.request({ Name }))

      onClose()
    },
    [dispatch, onClose]
  )

  return <FolderBaseDialog title='Create folder' open={open} onClose={onClose} onSubmit={onSubmit} />
}

CreateFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(CreateFolderDialog)
