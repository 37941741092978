import { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Field } from 'formik'

import { AIObjectPropertyType } from '@tabeeb/enums'
import { FormikCheckboxMui5, FormikDateTimePicker } from '@tabeeb/modules/shared/forms'
import { renderTimeViewClock } from '@mui/x-date-pickers-pro'
import TextFieldComponent from '../TextFieldComponent'
import SelectionListItemFieldComponent from '../SelectionListItemFieldComponent'

const UniqueAIObjectPropertyField = ({
  name,
  label,
  disabled = false,
  aiObjectPropertyType,
  selectionListId,
  ...props
}) => {
  const aiObjectPropertyTypeProps = useMemo(
    () => ({
      [AIObjectPropertyType.Json]: { type: 'string', component: TextFieldComponent, multiline: true },
      [AIObjectPropertyType.String]: { type: 'string', component: TextFieldComponent, multiline: true },
      [AIObjectPropertyType.IntNumber]: { type: 'number', component: TextFieldComponent },
      [AIObjectPropertyType.DoubleNumber]: {
        type: 'number',
        component: TextFieldComponent,
        inputProps: {
          step: 0.1,
        },
      },
      [AIObjectPropertyType.DateTime]: {
        component: FormikDateTimePicker,
        minDate: moment.utc('0001-01-01T00:00:00.000'), // "0001-01-01T00:00:00Z"
        maxDate: moment.utc('9999-12-31T23:59:59.999'), // "9999-12-31T23:59:59Z"
        disablePast: false,
        disableFuture: false,
        ampm: false,
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
      [AIObjectPropertyType.Dropdown]: { component: SelectionListItemFieldComponent },
      // [AIObjectPropertyType.Checkbox]: { type: 'checkbox', component: FormikCheckboxMui5 },
      [AIObjectPropertyType.Url]: { type: 'url', component: TextFieldComponent },
      [AIObjectPropertyType.Boolean]: { component: FormikCheckboxMui5, color: 'primary' },
      [AIObjectPropertyType.Latitude]: {
        type: 'number',
        component: TextFieldComponent,
        inputProps: {
          step: 0.1,
        },
      },
      [AIObjectPropertyType.Longitude]: {
        type: 'number',
        component: TextFieldComponent,
        inputProps: {
          step: 0.1,
        },
      },
    }),
    []
  )

  return (
    <Field
      {...props}
      name={name}
      label={label}
      disabled={disabled}
      selectionListId={selectionListId}
      {...aiObjectPropertyTypeProps[aiObjectPropertyType]}
      variant='outlined'
      fullWidth
      sx={{ marginBottom: 1 }}
    />
  )
}

UniqueAIObjectPropertyField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selectionListId: PropTypes.number,
  aiObjectPropertyType: PropTypes.oneOf(Object.values(AIObjectPropertyType)),
}

export default UniqueAIObjectPropertyField
