import { handleActions, combineActions } from 'redux-actions'
import * as actions from '../actions'

const defaultState = {
  ContentId: 0,
  Description: '',
}

export default handleActions(
  {
    [actions.openAlertOwnerModal](state, action) {
      return action.payload
    },
    [actions.closeAlertOwnerModal](state, action) {
      return defaultState
    },
  },
  defaultState
)
