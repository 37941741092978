import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getContentTrainingMaterial } from '../actions'

export default ({ id, onError }) => {
  const payload = useMemo(() => ({ id }), [id])

  const {
    loading,
    response: contentTrainingMaterial,
    onMutated: onReload,
  } = useApiRequest({
    request: getContentTrainingMaterial.request,
    payload,
    onError,
  })

  return { loading, contentTrainingMaterial, onReload }
}
