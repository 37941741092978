import { put, takeLatest, all, select } from 'redux-saga/effects'

import { AnswerStatusesGroups } from '@tabeeb/enums'

import * as discussionActions from '../actions'
import * as discussionSelectors from '../selectors'

import { ANSWERS_TAKE } from '../constants'

function* getFormAnswersByPage(action) {
  const { pageId } = action.payload

  const list = yield select(discussionSelectors.getPageAnswersList)
  const { userId, formId, statuses: statusOptions } = yield select(discussionSelectors.getPageAnswersFilters)

  yield put(
    discussionActions.getFormAnswersByPageRequest({
      pageId,
      userId: userId === -1 ? null : userId,
      formId: formId === -1 ? null : formId,
      statuses: statusOptions.map((statusOption) => AnswerStatusesGroups[statusOption]).flat(),
      skip: list.length,
      take: ANSWERS_TAKE,
    })
  )
}

function* getFormAnswersByQuestion(action) {
  const { questionId, contentFormId } = action.payload

  const list = yield select(discussionSelectors.getQuestionAnswersList)
  const { userId, statuses: statusOptions } = yield select(discussionSelectors.getQuestionAnswersFilters)

  yield put(
    discussionActions.getFormAnswersByQuestionRequest({
      questionId,
      contentFormId,
      userId: userId === -1 ? null : userId,
      statuses: statusOptions.map((statusOption) => AnswerStatusesGroups[statusOption]).flat(),
      skip: list.length,
      take: ANSWERS_TAKE,
    })
  )
}

function* discussionsSaga() {
  yield all([
    takeLatest(discussionActions.getFormAnswersByPage, getFormAnswersByPage),
    takeLatest(discussionActions.getFormAnswersByQuestion, getFormAnswersByQuestion),
  ])
}

export default discussionsSaga
