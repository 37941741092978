import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MicOffOutlined, MicOutlined } from '@material-ui/icons'

import { accountSelectors } from '@tabeeb/modules/account'
import { usersSelectors } from '@tabeeb/modules/../users'
import { presentationSelectors } from '@tabeeb/modules/presentation'
import { getIsCurrentUserPresenter } from '@tabeeb/shared/content/selectors'

import * as muteAudioActions from '../../../presentationToolbar/actions/muteAudio'

import ToolbarButton from '../ToolbarButton'

const MuteAudioButton = () => {
  const dispatch = useDispatch()
  const isPresenter = useSelector(getIsCurrentUserPresenter)

  const currentUserId = useSelector(accountSelectors.getCurrentUserId)

  const selectedUser = useSelector(presentationSelectors.getSelectedUser)
  const user = useSelector((state) =>
    usersSelectors.getUserById(state, { Id: isPresenter ? selectedUser?.id : currentUserId })
  )

  const isAudioMuted = useSelector(
    (state) =>
      !presentationSelectors.getIsUserAudioPlaying(state, { Id: isPresenter ? selectedUser?.id : currentUserId })
  )
  const muteAudioInProgress = useSelector((state) => state.presentationToolbar.muteAudioInProgress)

  if (!selectedUser || !user) {
    return null
  }

  const onClick = () => {
    if (muteAudioInProgress === true || selectedUser === null) {
      return
    }

    if (selectedUser.id === currentUserId || !isPresenter) {
      dispatch(muteAudioActions.muteAudio())
    } else {
      dispatch(muteAudioActions.muteRemoteAudio(selectedUser.id))
    }
  }

  return (
    <ToolbarButton
      Icon={isAudioMuted ? MicOffOutlined : MicOutlined}
      title={isAudioMuted ? 'Unmute audio' : 'Mute audio'}
      onClick={onClick}
    />
  )
}

export default memo(MuteAudioButton)
