import { forwardRef, memo } from 'react'

import { Divider, Typography } from '@material-ui/core'

import useStyles from './styles'

const UnreadMessagesBar = forwardRef((_, ref) => {
  const classes = useStyles()

  return (
    <li ref={ref} className={classes.container}>
      <Divider className={classes.divider} />
      <Typography className={classes.text} variant='caption' color='primary'>
        Unread messages
      </Typography>
      <Divider className={classes.divider} />
    </li>
  )
})

export default memo(UnreadMessagesBar)
