import { handleActions } from 'redux-actions'
import { adminGetTenantsSuccess, resetPage } from '../actions'

const defaultState = []

export default handleActions(
  {
    [adminGetTenantsSuccess](state, action) {
      return action.response.data
    },
    [resetPage]() {
      return defaultState
    },
  },
  defaultState
)
