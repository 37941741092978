import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = {
  sncType: null,
  loading: false,
  loadingAdditional: false,
  checkConditions: false,
}

export default handleActions(
  {
    [rawActions.openTenantFormsDialog](state, { payload }) {
      return { ...state, sncType: payload.sncType }
    },
    [rawActions.closeTenantFormsDialog]() {
      return defaultState
    },
    [rawActions.getTenantFormRequest](state) {
      return { ...state, loading: true }
    },
    [combineActions(rawActions.getTenantFormSuccess, rawActions.getTenantFormFailed)](state) {
      return { ...state, loading: false }
    },
    [rawActions.getAdditionalTenantFormRequest](state) {
      return { ...state, loadingAdditional: true }
    },
    [combineActions(rawActions.getAdditionalTenantFormSuccess, rawActions.getAdditionalTenantFormFailed)](state) {
      return { ...state, loadingAdditional: false }
    },
    [rawActions.checkTenantFormConditions](state, action) {
      return { ...state, checkConditions: true }
    },
    [combineActions(rawActions.checkTenantFormConditionsSuccess, rawActions.checkTenantFormConditionsFailed)](
      state,
      action
    ) {
      return { ...state, checkConditions: false }
    },
  },
  defaultState
)
