import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/modules/shared/utils/actions'

export const getTenantSncQuestions = createApiActions('GET_TENANT_SNC_QUESTIONS')
export const getSncAnswers = createApiActions('GET_SNC_ANSWERS')

export const enableSncFilters = createAction('ENABLE_SNC_FILTERS')
export const disableSncFilters = createAction('DISABLE_SNC_FILTERS')

export const clearSncSelection = createAction('CLEAR_SNC_SELECTION')
export const clearSncQuestionAnswersSelection = createAction('CLEAR_SNC_QUESTION_ANSWERS_SELECTION')
export const addSncQuestionAnswersToSelection = createAction('ADD_SNC_QUESTION_ANSWERS_TO_SELECTION')
export const removeSncQuestionAnswersFromSelection = createAction('REMOVE_SNC_QUESTION_ANSWERS_FROM_SELECTION')

export const resetState = createAction('RESET_SNC_FILTERS_STATE')
