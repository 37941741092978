import { styled } from '@material-ui/core'
import { ColumnFlexContainer, FlexContainer } from '@tabeeb/uikit'

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'inherit',
  background: theme.palette.background.primary,
  overflow: 'auto',
  padding: theme.spacing(4),
  display: 'grid',
}))

export const Header = styled(ColumnFlexContainer)(({ theme }) => ({
  justifyContent: 'center',
  marginBottom: theme.spacing(7),
}))

export const Body = styled(ColumnFlexContainer)(({ theme }) => ({
  marginTop: 'auto',
}))

export const Footer = styled(ColumnFlexContainer)(({ theme }) => ({
  marginTop: 'auto',
}))

export const BottomLinksContainer = styled(FlexContainer)(({ theme }) => ({
  justifyContent: 'space-between',
  marginTop: theme.spacing(),
}))
