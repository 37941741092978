import { FormControlType } from '@tabeeb/enums'

export const enumColumnValues = {
  FormControlType: {
    [FormControlType.Basic]: 'Basic',
    [FormControlType.BodyTemperature]: 'Body temperature',
    [FormControlType.PhoneNumber]: 'Phone number',
    [FormControlType.Email]: 'Email',
    [FormControlType.BarcodeAndQRCode]: 'Barcode and QR code',
  },
}

export const filterValues = {
  FormControlType: Object.keys(enumColumnValues.FormControlType).map((value) => ({
    value: parseInt(value),
    title: enumColumnValues.FormControlType[value],
  })),
}
