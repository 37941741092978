import { memo } from 'react'
import { useSelector } from 'react-redux'

import { Box, Grow, Typography } from '@material-ui/core'

import { playerSelectors } from '@tabeeb/modules/player'
import { getTimeString } from '@tabeeb/modules/recording/services/helpers'

import useStyles from './styles'

const PlayerTimer = () => {
  const classes = useStyles()

  const currentVideoTimestamp = useSelector(playerSelectors.getCurrentVideoTimestamp)
  const durationVideoTimestamp = useSelector(playerSelectors.getDuration)

  const currentVideoTime = getTimeString(currentVideoTimestamp * 1000)
  const durationVideoTime = getTimeString(durationVideoTimestamp * 1000)

  return (
    <Grow in>
      <Box className={classes.container}>
        <Typography component='time' color='textPrimary' variant='body2'>
          {currentVideoTime}
        </Typography>
        <span className={classes.divider}>|</span>
        <Typography component='time' color='textSecondary' variant='body2' className={classes.duration}>
          {durationVideoTime}
        </Typography>
      </Box>
    </Grow>
  )
}

export default memo(PlayerTimer)
