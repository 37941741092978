import { createSelector } from 'reselect'
import { getSearch } from 'connected-react-router'

import { TenantPermission } from '@tabeeb/enums'
import { RootState } from '@tabeeb/store'
import { getSubdomain } from '@tabeeb/modules/appConfigState/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

export const getConfigForUpdateSubdomain = (state: RootState) => state.tenantConfigPage.tenantConfigForUpdate.subdomain
export const selectEditingTenantSubdomain = createSelector(
  [getSearch, getSubdomain, (state) => hasTenantPermission(state, TenantPermission.AllTenantsAccess)],
  (search, subdomain, hasAllTenantsAccess) => {
    if (hasAllTenantsAccess) {
      const params = new URLSearchParams(search)
      return params.get('tenantSubdomain') ?? subdomain
    }

    return subdomain
  }
)
