import { Box, Typography, LinearProgress, styled } from '@mui/material'

export const BarContainer = styled(Box)(({ theme }) => {
  return {
    '&.MuiBox-root': {
      paddingBottom: '10px',
    },
  }
})

export const LabelContainer = styled(Typography)(({ theme }) => {
  return {
    '&.MuiTypography-root': {
      textAlign: 'right',
      fontSize: '14px',
      color: theme.palette.error.main,
    },
  }
})

export const LinearProgressContainer = styled(LinearProgress, {
  // https://github.com/mui/material-ui/issues/25925#issuecomment-826147326
  shouldForwardProp: (prop) => prop[0] !== '$',
})(({ theme, $exceeded }) => {
  return {
    '&.MuiLinearProgress-root': {
      height: '16px',
      borderRadius: '4px',
    },
    '& .MuiLinearProgress-dashed': {
      backgroundColor: 'lightgrey',
      backgroundImage: 'none',
      animation: 'none',
    },
    '& .MuiLinearProgress-bar2Buffer': {
      backgroundColor: $exceeded && theme.palette.error.light,
    },
  }
})

export const HelpContainer = styled(Box)(({ theme }) => {
  return {
    display: 'inline',
    position: 'relative',
    top: '6px',
    left: '6px',
    color: theme.palette.primary.light,
    cursor: 'help',
  }
})
