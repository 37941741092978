import { createSelector } from 'reselect'
import _ from 'lodash'

export const getNotificationsCount = (state) => state.notificationsPage.notificationsCount

export const getNotificationsList = (state) => state.notificationsPage.notificationsList
export const getNotificationsFilters = (state) => state.notificationsPage.selectedFilters

export const getIsLoading = (state) => state.notificationsPage.isDataLoading

export const getBatchedLoadedCount = (state) => state.notificationsPage.batchesLoadedCount
export const getHasMoreNotifications = (state) => state.notificationsPage.hasMoreNotifications

export const getFilteredNotifications = createSelector(
  [getNotificationsList, getNotificationsFilters],
  (notificationsList, selectedFilters) => {
    return _.orderBy(
      notificationsList.filter((notification) => selectedFilters.includes(notification.Type)),
      (notification) => notification.UpdatedOn,
      'desc'
    )
  }
)

export const getNotificationById = createSelector(
  [getNotificationsList, (_, { Id }) => Id],
  (notificationsList, id) => {
    return notificationsList.find((notification) => notification.Id === id)
  }
)
