import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { OpenInNewOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

const OpenInNewTabIconButton = ({ disabled, url }) => {
  return (
    <Link to={url} target='_blank'>
      <Tooltip title='Open in new tab'>
        <span>
          <IconButton disabled={disabled} size='small' edge='end'>
            <OpenInNewOutlined />
          </IconButton>
        </span>
      </Tooltip>
    </Link>
  )
}

OpenInNewTabIconButton.propTypes = {
  url: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default OpenInNewTabIconButton
