export default (theme) => {
  return {
    container: {
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',

      '& a': {
        color: 'inherit',
        textDecoration: 'underline',
        fontWeight: 'unset',
      },
    },
    active: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    separator: {
      marginLeft: theme.spacing() / 2,
      marginRight: theme.spacing() / 2,
      ...theme.typography.caption,
    },
    text: {
      ...theme.typography.body2,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    icon: {
      marginRight: theme.spacing() / 2,
      fontSize: 16,
    },
  }
}
