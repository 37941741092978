import { put, takeLatest, select, all } from 'redux-saga/effects'

import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { attachTrack } from '@tabeeb/modules/presentation/actions/tracks'
import { VIDEO_DEVICE } from '@tabeeb/modules/presentation/constants'
import { detachTrack } from '@tabeeb/modules/presentation/actions/jitsiTrack'
import { showSelfView, hideSelfView } from '../actions'

function* onShowSelfView() {
  const currentUserId = yield select(getCurrentUserId)
  const localTrack = yield select((state) =>
    state.presentation.tracks.localTracks.find((item) => item.getType() === VIDEO_DEVICE)
  )

  if (!localTrack) {
    return
  }

  yield put(attachTrack({ track: localTrack, userId: currentUserId }))
}

function* onHideSelfView() {
  const localTrack = yield select((state) =>
    state.presentation.tracks.localTracks.find((item) => item.getType() === VIDEO_DEVICE)
  )

  if (!localTrack) {
    return
  }

  yield put(detachTrack(localTrack))
}

function* navbarSaga() {
  yield all([takeLatest(showSelfView, onShowSelfView), takeLatest(hideSelfView, onHideSelfView)])
}

export default navbarSaga
