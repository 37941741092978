import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UserTenantRole } from '@tabeeb/enums'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import { getMeRequest } from '../actions'
import UserSettings from '../components/userSettings'
import * as accountSelectors from '../selectors'

const UserSettingsPage = () => {
  const dispatch = useDispatch()

  const showHealthData = useSelector(appConfigStateSelectors.getShowHealthData)
  const showNotifications = useSelector(appConfigStateSelectors.getIsChatEnabled)
  const showCertificates = useSelector(appConfigStateSelectors.getShowCertificates)
  const showQualifications = useSelector(appConfigStateSelectors.getQualificationsEnabled)

  const showTabs = useMemo(
    () => ({
      showHealthData,
      showNotifications,
      showCertificates,
      showQualifications,
    }),
    [showCertificates, showHealthData, showNotifications, showQualifications]
  )
  const showDeleteAccountButton = useSelector((state) => {
    return (
      appConfigStateSelectors.getIsAccountDeletionEnabled(state) &&
      !accountSelectors.getIsUserInAnyRole(state, [UserTenantRole.SystemAdmin])
    )
  })

  useEffect(() => {
    dispatch(getMeRequest())
  }, [dispatch])

  return <UserSettings showTabs={showTabs} showDeleteAccountButton={showDeleteAccountButton} />
}

export default UserSettingsPage
