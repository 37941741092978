import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { IconButton, Tooltip } from '@mui/material'
import { Share } from '@mui/icons-material'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { getIsSessionSharingEnabled } from '@tabeeb/modules/appConfigState/selectors'

import { getHasShareContentPermission } from '../../../selectors/permissions'

const ShareButton = ({ onClick }) => {
  const isVisible = useSelector((state) => getHasShareContentPermission(state) && getIsSessionSharingEnabled(state))
  if (!isVisible) {
    return null
  }

  return (
    <Tooltip title={sessionTitleFormatter.format('Share selected sessions')}>
      <IconButton size='small' onClick={onClick}>
        <Share />
      </IconButton>
    </Tooltip>
  )
}

ShareButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default memo(ShareButton)
