import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const resetContentViewerState = createAction('RESET_CONTENT_VIEWER_STATE')

export const setLoadingInProgress = createAction('SET_LOADING_IN_PROGRESS')
export const resetLoadingInProgress = createAction('RESET_LOADING_IN_PROGRESS')

export const setContentSize = createAction('SET_CONTENT_SIZE')
export const setContainerSize = createAction('SET_CONTAINER_SIZE')

export const setSpatialViewStartPageId = createAction('SET_SPATIAL_VIEW_START_PAGE_ID')
export const goToPage = createAction('GO_TO_PAGE')

export const setCoordinatesInfo = createAction('SET_COORDINATES_INFO')

export const sendUnarchiveModelRequest = createAction('SEND_UNARCHIVE_MODEL_REQUEST')
export const sendUnarchiveModelSuccess = createAction('SEND_UNARCHIVE_MODEL_SUCCESS')
export const sendUnarchiveModelFailed = createAction('SEND_UNARCHIVE_MODEL_FAILED')

export const showWebPageIframe = createAction('SHOW_WEB_PAGE_IFRAME')
export const resetWebPageState = createAction('RESET_WEB_PAGE_STATE')

export const takeContentSnapshot = createAction('TAKE_CONTENT_SNAPSHOT')

export const getAutodeskViewable = createApiActions('GET_AUTODESK_VIEWABLE')

export const setAutodeskViewable = createAction('SET_AUTODESK_VIEWABLE')
export const resetAutodeskViewable = createAction('RESET_AUTODESK_VIEWABLE')
