import { handleActions } from 'redux-actions'

import * as annotationsActions from '../actions'

const defaultState = {
  annotationId: null,
}

export default handleActions(
  {
    [annotationsActions.openHotspotDialog](state, action) {
      return {
        ...action.payload,
      }
    },
    [annotationsActions.closeHotspotDialog](state, action) {
      return defaultState
    },
    [annotationsActions.resetAnnotationsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
