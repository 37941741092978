import { eventChannel } from 'redux-saga'

function jitsiCommandHandler(target, eventName) {
  return eventChannel((emitter) => {
    function listener(event) {
      emitter({ event: arguments })
    }
    target.addCommandListener(eventName, listener)

    return () => {
      target.removeCommandListener(eventName, listener)
    }
  })
}

export default jitsiCommandHandler
