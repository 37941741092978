import { takeLatest, put, all } from 'redux-saga/effects'

import * as aiActions from '../../artificialIntelligence/actions'
import * as notificationActions from '../../notification/actions'

function* onRegenerateStructureModelSuccess(action) {
  yield put(
    notificationActions.onAddInfoNotification({
      message: 'Analytics report send for regeneration',
    })
  )
}

function* onRegenerateStructureModelFailed(action) {
  yield put(
    notificationActions.onAddErrorNotification({
      message: 'Can not regenerate analytics report.',
    })
  )
}

function* regenerateStructureModel() {
  yield all([
    takeLatest(aiActions.regenerateStructureModelSuccess, onRegenerateStructureModelSuccess),
    takeLatest(aiActions.regenerateStructureModelFailed, onRegenerateStructureModelFailed),
  ])
}

export default regenerateStructureModel
