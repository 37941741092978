import { handleActions, combineActions } from 'redux-actions'
import { calendarActions } from '@tabeeb/modules/calendar'
import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.setNotificationsList](state, action) {
      const notificationsList = [...state]
      const addedNotifications = action.payload
      if (notificationsList.length === 0) return addedNotifications

      addedNotifications.forEach((addedNotification) => {
        if (!notificationsList.some((n) => n.Id === addedNotification.Id && n.Type === addedNotification.Type)) {
          notificationsList.push(addedNotification)
        }
      })
      return notificationsList
    },
    [rawActions.replaceNotification](state, { payload }) {
      const updatedNotification = payload
      const index = state.findIndex((n) => n.Id === updatedNotification.Id && n.Type === updatedNotification.Type)
      if (index > -1) {
        state.splice(index, 1, updatedNotification)
      }

      return [...state]
    },
    [combineActions(
      rawActions.removeNotification,
      rawActions.rejectNotificationSuccess,
      rawActions.revokeSessionSharingSuccess,
      calendarActions.acceptCalendarEventSuccess,
      calendarActions.declineCalendarEventSuccess
    )](state, { payload }) {
      const removedNotification = payload
      return state.filter((notification) => notification.Id !== removedNotification.Id)
    },
    [rawActions.addNotification](state, { payload }) {
      const addedNotification = payload
      const notificationsList = [...state]
      if (!notificationsList.some((n) => n.Id === addedNotification.Id && n.Type === addedNotification.Type)) {
        notificationsList.unshift(addedNotification)
      }

      return notificationsList
    },
    [rawActions.markAsViewed](state, { payload }) {
      const { notification, isVisible } = payload
      const notificationsList = [...state]
      return notificationsList.map((notific) =>
        notific.Id === notification.Id && notific.Type === notification.Type
          ? { ...notific, HasBeenViewed: isVisible }
          : notific
      )
    },
    [combineActions(rawActions.resetState, rawActions.resetNotificationsList)](state, { payload }) {
      return defaultState
    },
  },
  defaultState
)
