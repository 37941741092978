import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { tenantSubdomain } from '@tabeeb/shared/forms/helpers/regexps'

import {
  noWhitespaces,
  invalidSubdomain,
  maxLengthWithName,
  requiredFieldWithName,
} from '@tabeeb/shared/utils/validationErrorMessages'
import { FetchStatus } from '@tabeeb/enums'
import {
  Autocomplete,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  CircularProgress,
  Grid,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { useStyles } from './styles'

import { tenantsActions } from '../../actions'
import { getBusinessDomainsState } from '../../selectors/businessDomains'
import { getBusinessDomains, getDefaultCreateTenantConfiguration } from '../../actions/tenants'
import { selectCreateStatus } from '../../selectors/tenants'
import { fetchingStatus } from '../../constants'
import { getDefaultCreateTenantConfigurationState } from '../../selectors/defaultCreateTenantConfiguration'

const validationSchema = yup.object({
  Name: yup
    .string('Enter tenant name')
    .max(256, maxLengthWithName('Tenant name', 256))
    .trim(noWhitespaces)
    .required(requiredFieldWithName('Tenant name')),
  Subdomain: yup
    .string('Enter tenant subdomain')
    .max(256, maxLengthWithName('Subdomain', 256))
    .matches(tenantSubdomain, invalidSubdomain)
    .required(requiredFieldWithName('Subdomain')),
  EnvId: yup.number().nullable(),
})

const CreateTenantDialog = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { fetchStatus, businessDomains } = useSelector(getBusinessDomainsState)

  const { fetchStatus: defaultConfigurationFetchStatus, configuration } = useSelector(
    getDefaultCreateTenantConfigurationState
  )

  const createStatus = useSelector(selectCreateStatus)

  const businessDomainOptions = useMemo(() => {
    return [{ Id: null, DisplayName: 'Other' }, ...businessDomains]
  }, [businessDomains])

  useEffect(() => {
    if (isOpen && businessDomains.length === 0 && fetchStatus === FetchStatus.Idle) {
      dispatch(getBusinessDomains.request())
    }
  }, [fetchStatus, businessDomains, isOpen, dispatch])

  const handleTenantSubmit = useCallback(
    (values) => {
      const model = {
        Config: '{}',
        Name: values.Name,
        Subdomain: values.Subdomain,
        SupportsPrograms: true,
        EnvId: values.EnvId,
      }

      dispatch(tenantsActions.createRequest(model))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues: {
      Name: '',
      Subdomain: '',
      EnvId: null,
    },
    validationSchema,
    onSubmit: handleTenantSubmit,
  })

  useEffect(() => {
    if (isOpen) {
      dispatch(getDefaultCreateTenantConfiguration.request({ envId: formik.values.EnvId }))
    }
  }, [isOpen, dispatch, formik.values.EnvId])

  const handleBusinessDomainChange = useCallback(
    (_e, value, reason) => {
      if (reason === 'clear') {
        formik.setValues((values) => ({ ...values, EnvId: 0 }))
      } else {
        formik.setValues((values) => ({ ...values, EnvId: value.Id }))
      }
    },
    [formik]
  )

  const submitButtonDisabled = useMemo(() => {
    return (
      !(formik.isValid && formik.dirty) ||
      createStatus === fetchingStatus.loading ||
      !configuration.StorageGroupName ||
      !configuration.TenantConfigurationSourceTenantName
    )
  }, [
    configuration.StorageGroupName,
    configuration.TenantConfigurationSourceTenantName,
    createStatus,
    formik.dirty,
    formik.isValid,
  ])

  const handleClose = useCallback(() => {
    if (formik.dirty) {
      formik.resetForm()
    }
    dispatch(tenantsActions.resetCreateTenantState())
    onClose()
  }, [dispatch, formik, onClose])

  useEffect(() => {
    if (createStatus === fetchingStatus.succeeded) {
      handleClose()
    }
  }, [createStatus, handleClose])

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth size='md'>
      <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <DialogTitle>Add a new tenant</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} flexDirection='column'>
            <Grid item>
              <TextField
                required
                className={classes.formInput}
                fullWidth
                name='Name'
                id='tenant-name'
                label='Tenant name'
                variant='outlined'
                value={formik.values.Name}
                onChange={formik.handleChange}
                error={formik.touched.Name && Boolean(formik.errors.Name)}
                helperText={formik.touched.Name && formik.errors.Name}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                className={classes.formInput}
                fullWidth
                name='Subdomain'
                id='tenant-subdomain'
                label='Subdomain'
                variant='outlined'
                value={formik.values.Subdomain}
                onChange={formik.handleChange}
                error={formik.touched.Subdomain && Boolean(formik.errors.Subdomain)}
                helperText={formik.touched.Subdomain && formik.errors.Subdomain}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>.collaborate.center</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                required
                fullWidth
                disableClearable
                name='EnvId'
                label='Business Domain'
                options={businessDomainOptions}
                defaultValue={businessDomainOptions[0]}
                onChange={handleBusinessDomainChange}
                loading={fetchStatus === FetchStatus.Loading}
                error={formik.touched.EnvId && Boolean(formik.errors.EnvId)}
                helperText={formik.touched.EnvId && formik.errors.EnvId}
                renderOption={(props, option) => (
                  <Typography {...props} noWrap title={option.DisplayName} key={option.Id}>
                    {option.DisplayName}
                  </Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant='outlined'
                    label='Select a Business Domain'
                    placeholder='Search a Business Domain...'
                  />
                )}
                getOptionLabel={(option) => option.DisplayName}
              />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography>Storage group:</Typography>
              </Grid>
              <Grid item>
                {defaultConfigurationFetchStatus === FetchStatus.Loading && <Typography>Loading...</Typography>}
                {(defaultConfigurationFetchStatus === FetchStatus.Failed ||
                  (defaultConfigurationFetchStatus === FetchStatus.Loaded && !configuration.StorageGroupName)) && (
                  <Typography>Default storage is not configured for selected domain.</Typography>
                )}
                {defaultConfigurationFetchStatus === FetchStatus.Loaded && configuration.StorageGroupName && (
                  <Typography>{configuration.StorageGroupName}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography>Configuration will be copied from:</Typography>
              </Grid>
              <Grid item>
                {defaultConfigurationFetchStatus === FetchStatus.Loading && <Typography>Loading...</Typography>}
                {(defaultConfigurationFetchStatus === FetchStatus.Failed ||
                  (defaultConfigurationFetchStatus === FetchStatus.Loaded &&
                    !configuration.TenantConfigurationSourceTenantName)) && (
                  <Typography>Source tenant for tenant configuration is not configured for selected domain.</Typography>
                )}
                {defaultConfigurationFetchStatus === FetchStatus.Loaded &&
                  configuration.TenantConfigurationSourceTenantName && (
                    <Typography>{`${configuration.TenantConfigurationSourceTenantName} (${configuration.TenantConfigurationSourceTenantSubdomain})`}</Typography>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
          <Button
            disabled={submitButtonDisabled}
            color='primary'
            type='submit'
            variant='outlined'
            startIcon={createStatus === fetchingStatus.loading ? <CircularProgress size={18} /> : <Add />}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CreateTenantDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default CreateTenantDialog
