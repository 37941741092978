import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ClearOutlined } from '@mui/icons-material'
import { Box, Chip, Fade } from '@mui/material'

import { getSelectedSessionsList } from '@tabeeb/modules/sessionsPage/selectors'
import { setSelectedSessions } from '@tabeeb/modules/sessionsPage/actions'

import { getMe } from '@tabeeb/modules/account/selectors'

import { DialogTypes } from '../../enums'
import { usePageDialog } from '../../hooks'

import AddUsersButton from './AddUsersButton'
import ChangeReviewStatusButton from './ChangeReviewStatusButton'
import RestoreButton from './RestoreButton'
import ShareButton from './ShareButton'
import SwitchOwnerButton from './SwitchOwnerButton'
import SwitchReviewerButton from './SwitchReviewerButton'
import SwitchStorageButton from './SwitchStorageButton'

const Selection = () => {
  const dispatch = useDispatch()

  const { onOpen: onOpenAddUsersDialog } = usePageDialog(DialogTypes.AddUsers)
  const { onOpen: onOpenChangeReviewStatusDialog } = usePageDialog(DialogTypes.ChangeReviewStatus)
  const { onOpen: onOpenRestoreSessionsDialog } = usePageDialog(DialogTypes.RestoreSessions)
  const { onOpen: onOpenShareSessionsDialog } = usePageDialog(DialogTypes.ShareSessions)
  const { onOpen: onOpenSwitchOwnersDialog } = usePageDialog(DialogTypes.SwitchOwners)
  const { onOpen: onOpenSwitchStorageDialog } = usePageDialog(DialogTypes.SwitchStorage)
  const { onOpen: onOpenSwitchReviewersDialog } = usePageDialog(DialogTypes.SwitchReviewers)

  const sessions = useSelector(getSelectedSessionsList)
  const currentUser = useSelector(getMe)

  const isDeletedSessionsSelected = sessions.some((sc) => sc.IsDeleted)
  const isSessionsFromCurrentTenantSelected = sessions.some((sc) => sc.TenantId === currentUser.TenantId)
  const isSessionsWithoutReviewsSelected = sessions.some((sc) => !sc.HasReview)
  const isSessionsWithActiveReviewSelected = sessions.some((sc) => sc.HasReview && sc.TenantId !== currentUser.TenantId)

  const onUnselectAll = useCallback(() => {
    dispatch(setSelectedSessions([]))
  }, [dispatch])

  return (
    sessions.length > 0 && (
      <Fade in>
        <Box display='flex' alignItems='center' ml={2} mr={2}>
          <Chip
            sx={{ borderRadius: 1, mr: 1 }}
            color='primary'
            label={`Selected: ${sessions.length}`}
            deleteIcon={<ClearOutlined />}
            onDelete={onUnselectAll}
          />

          {!isDeletedSessionsSelected && (
            <>
              {isSessionsFromCurrentTenantSelected && (
                <ShareButton onClick={() => onOpenShareSessionsDialog({ sessions })} />
              )}

              {isSessionsFromCurrentTenantSelected && (
                <SwitchOwnerButton onClick={() => onOpenSwitchOwnersDialog({ sessions })} />
              )}

              {isSessionsFromCurrentTenantSelected && (
                <SwitchStorageButton onClick={() => onOpenSwitchStorageDialog({ sessions })} />
              )}

              {isSessionsWithActiveReviewSelected && (
                <SwitchReviewerButton onClick={() => onOpenSwitchReviewersDialog({ sessions })} />
              )}

              {isSessionsFromCurrentTenantSelected && (
                <AddUsersButton onClick={() => onOpenAddUsersDialog({ sessions })} />
              )}

              {isSessionsFromCurrentTenantSelected && isSessionsWithoutReviewsSelected && (
                <ChangeReviewStatusButton onClick={() => onOpenChangeReviewStatusDialog({ sessions })} />
              )}
            </>
          )}

          {isDeletedSessionsSelected && <RestoreButton onClick={() => onOpenRestoreSessionsDialog({ sessions })} />}
        </Box>
      </Fade>
    )
  )
}

export default Selection
