import { createAction } from 'redux-actions'

export const setCallProcessingState = createAction('SET_CALL_PROCESSING_STATE')

export const setSelectedVideoBridge = createAction('SET_SELECTED_VIDEOBRIDGE')

export const setVideoBridgeRequest = createAction('SET_VIDEO_BRIDGE_REQUEST')
export const setVideoBridgeSuccess = createAction('SET_VIDEO_BRIDGE_SUCCESS')

export const getGeneralContentsToSwitchRequest = createAction('GET_GENERAL_CONTENTS_TO_SWITCH_REQUEST')
export const getGeneralContentsToSwitchSuccess = createAction('GET_GENERAL_CONTENTS_TO_SWITCH_SUCCESS')

export const getFolderContentsToSwitchRequest = createAction('GET_FOLDER_CONTENTS_TO_SWITCH_REQUEST')
export const getFolderContentsToSwitchSuccess = createAction('GET_FOLDER_CONTENTS_TO_SWITCH_SUCCESS')

export const renameSessionUsingConvention = createAction('RENAME_SESSION_USING_CONVENTION')

export const updateContentNamePartsRequest = createAction('UPDATE_CONTENT_NAME_PARTS_REQUEST')
export const updateContentNamePartsSuccess = createAction('UPDATE_CONTENT_NAME_PARTS_SUCCESS')
