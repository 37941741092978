const assetsRequests = {
  getAssetsByContentIdRequest: ({ contentId }) => ({
    url: `api/asset/getAssetsByContentId/${contentId}`,
    method: 'get',
    params: {
      contentId,
    },
  }),
}

export default assetsRequests
