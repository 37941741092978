import StartCallButton from '../StartCallButton'
import SessionSettingsButton from '../SessionSettingsButton'

import './styles.less'

const ControlsPannel = () => {
  return (
    <div className='top-nav-bar-controls'>
      <SessionSettingsButton />
      <StartCallButton />
    </div>
  )
}

export default ControlsPannel
