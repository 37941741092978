export const lowercase = /^(?=.*[a-z]).+$/g

export const uppercase = /^(?=.*[A-Z]).+$/g

export const number = /^(?=.*[0-9]).+$/g

export const specialCharacter = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s\\]).+$/g

export const acsii = /^[\x00-\x7F]+$/

export const tenantSubdomain = /^[0-9A-z_-]*$/
