import { handleActions } from 'redux-actions'
import { SelectionListStatus } from '@tabeeb/enums'
import { closeEditSelectionListDialog, openAddSelectionListDialog, getSelectionListForEditById } from '../../actions'

const defaultState = null

export default handleActions(
  {
    [openAddSelectionListDialog](state, { payload }) {
      return { Id: undefined, Name: '', Status: SelectionListStatus.Draft, Items: [] }
    },
    [getSelectionListForEditById.success](state, action) {
      return { ...action.response.data }
    },
    [closeEditSelectionListDialog](state, { response }) {
      return defaultState
    },
  },
  defaultState
)
