export default (theme) => {
  return {
    status: {
      height: 18,
      marginLeft: theme.spacing() / 2,

      fontSize: 12,

      '& svg': {
        width: 16,
        height: 16,
        fontSize: 'inherit',
      },

      '& svg:first-child': {
        marginRight: 0,
      },

      '& span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 'inherit',
        paddingLeft: theme.spacing() / 2,
        lineHeight: 2,
      },
    },
  }
}
