import { put, takeLatest, all, select } from 'redux-saga/effects'

import * as rawActions from '../actions/index'
import { getIncludesByAnnotationSource } from '../services'
import {
  getCurrentIterationPagesDisplayMode,
  getPageNumber,
  getPageSize,
  getSelectedAIObjects,
  getSelectedAnnotationDates,
  getSelectedAnnotationOwners,
  getSelectedContents,
  getSelectedForms,
  getSelectedIteration,
  getTrainingSettings,
  getSelectedAnswerers,
  getSelectedApprovers,
  getSelectedReviewers,
  getSelectedFormAnswerStatuses,
  getSelectedFormControls,
  getSelectedFormAnswerValue,
} from '../selectors'
import { IterationPagesDisplayMode } from '../enums'

function* onLoadNewIterationPages() {
  const pageNumber = yield select(getPageNumber)
  const pageSize = yield select(getPageSize)
  const selectedAIObjects = yield select(getSelectedAIObjects)
  const selectedAnnotationDates = yield select(getSelectedAnnotationDates)
  const selectedAnnotationOwners = yield select(getSelectedAnnotationOwners)
  const selectedContents = yield select(getSelectedContents)
  const selectedForms = yield select(getSelectedForms)
  const trainingSettings = yield select(getTrainingSettings)
  const selectedAnswerers = yield select(getSelectedAnswerers)
  const selectedApprovers = yield select(getSelectedApprovers)
  const selectedReviewers = yield select(getSelectedReviewers)
  const selectedFormAnswerStatuses = yield select(getSelectedFormAnswerStatuses)
  const selectedFormControls = yield select(getSelectedFormControls)
  const selectedFormAnswerValue = yield select(getSelectedFormAnswerValue)
  const includes = getIncludesByAnnotationSource(trainingSettings.annotationSource)

  const model = {
    PageNumber: pageNumber,
    PageSize: pageSize,
    ContentIds: selectedContents.map((content) => content.Id),
    AIObjectIds: selectedAIObjects.map((aiObject) => aiObject.Id),
    FormIds: selectedForms.map((form) => form.Id),
    UserIds: selectedAnnotationOwners.map((annotationOwner) => annotationOwner.Id),
    ExcludedPageIds: [],
    StartDate: selectedAnnotationDates.StartDate,
    EndDate: selectedAnnotationDates.EndDate,
    ApproverIds: selectedApprovers.map((approver) => approver.Id),
    ReviewerIds: selectedReviewers.map((reviewer) => reviewer.Id),
    AnswererIds: selectedAnswerers.map((answerer) => answerer.Id),
    FormAnswerStatuses: selectedFormAnswerStatuses,
    FormControlIds: selectedFormControls.map((formControl) => formControl.Id),
    FormAnswerValue: selectedFormAnswerValue,
    ...includes,
  }

  yield put(rawActions.getPagesForProjectTraining.request(model))
}

function* onLoadExistingIterationPages() {
  const selectedIteration = yield select(getSelectedIteration)
  const pageNumber = yield select(getPageNumber)
  const pageSize = yield select(getPageSize)
  const pagesDisplayMode = yield select(getCurrentIterationPagesDisplayMode)

  const model = {
    IterationId: selectedIteration.Id,
    PageNumber: pageNumber,
    PageSize: pageSize,
    IsExcluded: pagesDisplayMode === IterationPagesDisplayMode.ExcludedOnly,
  }

  yield put(rawActions.getPagesByIterationId.request(model))
}

function* iterationPages() {
  yield all([
    takeLatest(rawActions.loadNewIterationPages, onLoadNewIterationPages),
    takeLatest(rawActions.loadExistingIterationPages, onLoadExistingIterationPages),
  ])
}

export default iterationPages
