import { combineReducers } from 'redux'

import isTimelineOpen from './isTimelineOpen'
import isRecipientsDialogOpen from './isRecipientsDialogOpen'
import timelineList from './timelineList'
import isPagesForTimeLineLoadInProgress from './isPagesForTimeLineLoadInProgress'
import isProcessing from './isProcessing'

export default combineReducers({
  isTimelineOpen,
  timelineList,
  isRecipientsDialogOpen,
  isPagesForTimeLineLoadInProgress,
  isProcessing,
})
