import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'

import styles from './styles'

const Value = ({ classes, title, value }) => {
  return (
    <Typography className={classes.container}>
      {title}: {value}
    </Typography>
  )
}

Value.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default withStyles(styles)(Value)
