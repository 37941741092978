import { combineActions, handleActions } from 'redux-actions'
import { getAssignedLife365DeviceStatusWithContent, resetState } from '@tabeeb/modules/healthData/actions'

const defaultState = {}

export default handleActions(
  {
    [getAssignedLife365DeviceStatusWithContent](state, action) {
      return action.payload
    },
    [combineActions(resetState)](state, action) {
      return defaultState
    },
  },
  defaultState
)
