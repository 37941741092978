import { put, takeLatest, all } from 'redux-saga/effects'

import { rawContentActions } from '@tabeeb/shared/content'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onAddClassificationAnnotationsSuccess() {
  yield put(actions.closeClassificationAnnotationsTimeline())
  yield put(notificationActions.onAddInfoNotification({ message: 'Classification annotations added successfully.' }))
}

function* onAddClassificationAnnotationsFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to create classification annotations.' }))
}

function* resetState() {
  yield put(actions.resetState())
}

function* addClassificationAnnotations() {
  yield all([
    takeLatest(actions.addClassificationAnnotations.success, onAddClassificationAnnotationsSuccess),
    takeLatest(actions.addClassificationAnnotations.failed, onAddClassificationAnnotationsFailed),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default addClassificationAnnotations
