import { createSelector } from 'reselect'
import _ from 'lodash'

import { TopicType, UserRole, TabPanel, TabPanelLocation } from '@tabeeb/enums'

import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getUsersListForCurrentUser } from '../../../users/selectors'

import { defaultChatState } from '../constans'
import { getChatId, getChatDetailsById } from '../services/chat'

export const getIsChatOpened = (state) => state.navbar.currentPanel[TabPanelLocation.Left] === TabPanel.Chat

export const getChats = (state) => state.chat.chats

export const getChatByUserId = (state, { userId, topicId, topicType }) => {
  return state.chat.chats[getChatId({ userId, topicId, topicType })] || defaultChatState
}

export const getChatMessagesList = (state, props) => getChatByUserId(state, props).messages

export const getIsChatLoading = (state, props) => getChatByUserId(state, props).isLoading
export const getIsChatFullyLoaded = (state, props) => getChatByUserId(state, props).isFullyLoaded
export const getIsChatInitialized = (state, props) => getChatByUserId(state, props).isInitialized

export const getTranslationLanguages = (state) => state.chat.languages

export const getParticipantsList = createSelector(
  [getUsersListForCurrentUser, getCurrentUserId, getContentId, (state) => state],
  (usersList, currentUserId, contentId, state) => {
    let receivers = usersList
      .filter((user) => user.id !== currentUserId)
      .filter((user) => user.role !== UserRole.AIUser && user.role !== UserRole.Reviewer)

    receivers = receivers.filter((user) => {
      const chatIsNotEmpty =
        getLastMessageDateForChat(state, { topicId: contentId, topicType: TopicType.Session, userId: user.id }) !==
        defaultChatState.lastMessageDate
      const { isDeleted } = user

      return !isDeleted || (isDeleted && chatIsNotEmpty)
    })

    receivers = _.orderBy(
      receivers,
      [
        (user) =>
          getUnreadMessagesCountForChat(state, { topicId: contentId, topicType: TopicType.Session, userId: user.id }) >
          0,
        (user) =>
          getLastMessageDateForChat(state, { topicId: contentId, topicType: TopicType.Session, userId: user.id }),
      ],
      ['desc', 'desc']
    )

    return receivers
  }
)

export const getUnreadMessagesCountForChat = createSelector([getChatByUserId], (chat) => {
  return chat.unreadMessagesCount
})

export const getTotalUnreadMessagesCount = createSelector([getChats], (chats) => {
  return Object.values(chats).reduce((unreadMessagesCount, chat) => {
    return unreadMessagesCount + chat.unreadMessagesCount
  }, 0)
})

export const getLastMessageDateForChat = createSelector([getChatByUserId], (chat) => {
  return chat.lastMessageDate
})

export const getLastReadedMessageIdForChat = createSelector([getChatByUserId], (chat) => {
  return chat.lastReadedMessageId
})

export const getChatsByTopicType = createSelector([getChats, (x, { topicType }) => topicType], (chats, topicType) => {
  const chatsByTopicType = Object.keys(chats).reduce((results, chatId) => {
    const chatIdDetails = getChatDetailsById(chatId)

    if (chatIdDetails.topicType === topicType) {
      results.push(chatIdDetails)
    }

    return results
  }, [])

  return chatsByTopicType
})

export const getLoadedFormAnswersIdsFromChats = createSelector([getChatsByTopicType], (chatsDetails) => {
  return chatsDetails.map((cd) => cd.topicId)
})
