import { memo } from 'react'
import PropTypes from 'prop-types'

import { Fab, Tooltip, withStyles } from '@material-ui/core'

import styles from './styles'

const FormControlButton = ({ classes, onClick, Icon, title, iconColor }) => {
  return (
    <Tooltip placement='top' arrow title={title || ''}>
      <Fab color='secondary' aria-label='add' onClick={onClick} className={classes.button} style={{ color: iconColor }}>
        <Icon className={classes.icon} />
      </Fab>
    </Tooltip>
  )
}

FormControlButton.defaultProps = {
  iconColor: '#616161',
}

FormControlButton.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.any.isRequired,
  title: PropTypes.string,
  iconColor: PropTypes.string,
}

export default memo(withStyles(styles)(FormControlButton))
