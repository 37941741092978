import { handleActions } from 'redux-actions'

import * as galleryActions from '../actions'

const defaultState = false

const activitiesShouldRequestSend = handleActions(
  {
    [galleryActions.setPageFilter](state, action) {
      return true
    },
    [galleryActions.setUserFilter](state, action) {
      return true
    },
    [galleryActions.setActivityTypeFilter](state, action) {
      return true
    },
    [galleryActions.onEnterSearchTextFilter](state, { payload }) {
      if (payload && payload.length >= 2) {
        return true
      }
    },
    [galleryActions.setShouldRequestSend](state, action) {
      return action.payload
    },
    [galleryActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)

export default activitiesShouldRequestSend
