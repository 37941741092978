import { memo, useEffect, useRef, useState } from 'react'
import { Group, Rect, Text } from 'react-konva'
import PropTypes from 'prop-types'

import { alpha, useTheme } from '@mui/material'

const Label = ({ x, y, text, visible, scale }) => {
  const theme = useTheme()
  const textRef = useRef(null)

  const padding = 4
  const [textWidth, setTextWidth] = useState(0)
  const [textHeight, setTextHeight] = useState(0)

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.textWidth || 0)
      setTextHeight(textRef.current.textHeight || 0)
    }
  }, [text])

  return (
    <Group x={x} y={y} visible={visible} scaleX={1 / scale} scaleY={1 / scale}>
      <Rect
        x={-textWidth / 2}
        y={-textHeight / 2}
        width={textWidth + padding * 2}
        height={textHeight + padding * 2}
        fill={alpha(theme.palette.background.paper, 0.9)}
        shadowBlur={3}
        cornerRadius={theme.shape.borderRadius}
      />

      <Text
        ref={textRef}
        x={-textWidth / 2}
        y={-textHeight / 2}
        text={text}
        fontSize={12}
        fontFamily={theme.typography.caption.fontFamily}
        fill={theme.palette.text.primary}
        padding={padding}
        align='center'
        verticalAlign='middle'
      />
    </Group>
  )
}

Label.defaultProps = {
  visible: true,
  scale: 1,
}

Label.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,

  text: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  scale: PropTypes.number,
}

export default memo(Label)
