import { handleActions } from 'redux-actions'

import * as aiActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [aiActions.getAiObjectsWithStatistics.success](state, { response }) {
      return [...response.data]
    },
    [aiActions.updateAiObject.success](state, { payload }) {
      return state.map((aiObject) => (aiObject.Id === payload.Id ? { ...aiObject, ...payload } : aiObject))
    },
    [aiActions.deleteAiObject.success](state, { payload: aiObjectId }) {
      return state.filter((aiObject) => aiObject.Id !== aiObjectId)
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
