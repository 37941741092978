import { createStore, applyMiddleware, combineReducers } from 'redux'
import { persistStore } from 'reduxjs-toolkit-persist'
import { TypedUseSelectorHook, useDispatch as useGenericDispatch, useSelector as useGenericSelector } from 'react-redux'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'

import ai from '@tabeeb/services/telemetryService'

import * as jitsiTrackActions from '@tabeeb/modules/presentation/actions/tracks'
import { injectStore as apiClientInjectStore } from '@tabeeb/services/API'
import { injectStore as oidcInjectStore } from '@tabeeb/services/oidcUserManager'

import createSagaInjector from './createSagaInjector'

import staticReducers from './reducers'
import rootSaga from './sagas'

export const history = createBrowserHistory()

const routeMiddleware = routerMiddleware(history)

const createRootReducer = (asyncReducers = {}) =>
  combineReducers({
    router: connectRouter(history),
    ...staticReducers,
    ...asyncReducers,
  })

const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  actionsDenylist: [...Object.values(jitsiTrackActions).map((action) => action.toString())],
})

const createApplicationStore = () => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
      ai.appInsights?.trackException({ exception: error, properties: { featureId: 'redux-saga', stack: sagaStack } })
      if (process.env.DEBUG_LOGGING_ENABLED === 'true') {
        /* eslint-disable no-console */
        console.error(error)
        console.error(sagaStack)
      }
    },
  })

  const middlewares = [sagaMiddleware, thunkMiddleware, routeMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]

  const composedEnhancers = composeEnhancers(...enhancers)

  const store = createStore(createRootReducer(), undefined, composedEnhancers)

  store.asyncReducers = {}
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createRootReducer(store.asyncReducers))
  }
  store.injectReducers = (reducers) => {
    store.asyncReducers = { ...store.asyncReducers, ...reducers }
    store.replaceReducer(createRootReducer(store.asyncReducers))
  }

  store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga)

  const persistor = persistStore(store)

  apiClientInjectStore(store)
  oidcInjectStore(store)

  return { store, persistor }
}

const { store, persistor } = createApplicationStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { store, persistor, store as applicationStore, persistor as applicationPersistor }

export const useDispatch: () => AppDispatch = useGenericDispatch
export const useSelector: TypedUseSelectorHook<RootState> = useGenericSelector
