import { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import * as discussionActions from '../../../actions'
import * as discussionSelectors from '../../../selectors'

import AnswersList from '../AnswersList'

const QuestionAnswersList = ({ question }) => {
  const dispatch = useDispatch()

  const { loading, loaded, failed } = useSelector(discussionSelectors.getQuestionAnswersListLoadingState)
  const filters = useSelector(discussionSelectors.getQuestionAnswersFilters)

  const answers = useSelector(discussionSelectors.getFilteredQuestionAnswers)

  useEffect(() => {
    if (!question.Id || !question.ContentFormId) {
      return
    }

    dispatch(
      discussionActions.getFormAnswersByQuestion({ questionId: question.Id, contentFormId: question.ContentFormId })
    )
  }, [dispatch, question.Id, question.ContentFormId])

  useEffect(() => {
    return () => {
      dispatch(discussionActions.resetQuestionAnswersState())
    }
  }, [dispatch])

  const handleFiltersChange = useCallback(
    (updatedFilter) => {
      dispatch(discussionActions.updateQuestionFormAnswersFilters(updatedFilter))
      dispatch(
        discussionActions.getFormAnswersByQuestion({ questionId: question.Id, contentFormId: question.ContentFormId })
      )
    },
    [dispatch, question.ContentFormId, question.Id]
  )

  const handleLoadMore = useCallback(() => {
    dispatch(
      discussionActions.getFormAnswersByQuestion({ questionId: question.Id, contentFormId: question.ContentFormId })
    )
  }, [dispatch, question.Id, question.ContentFormId])

  return (
    <AnswersList
      failed={failed}
      loaded={loaded}
      loading={loading}
      answers={answers}
      onLoadMore={handleLoadMore}
      onFiltersChange={handleFiltersChange}
      answerInfoProps={{ pagePreview: true, formName: false, questionName: false }}
      filtersProps={{ userFilter: true, statusFilter: true, formFilter: false }}
      filters={filters}
    />
  )
}

QuestionAnswersList.propTypes = {
  question: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    ContentFormId: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(QuestionAnswersList)
