import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    closeIconButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  }
})
