import { memo } from 'react'
import PropTypes from 'prop-types'

import { Skeleton, Typography } from '@mui/material'

const ArticleName = ({ name }) => {
  return (
    <Typography variant='h6' fontWeight={700} mr={1} title={name} noWrap>
      {name}
    </Typography>
  )
}

ArticleName.propTypes = {
  name: PropTypes.string.isRequired,
}

const ArticleNameSkeleton = () => {
  return <Skeleton width={350} />
}

export default memo(ArticleName)

export { ArticleNameSkeleton as ArticleTitleSkeleton }
