import { memo } from 'react'

import { Typography } from '@material-ui/core'

import useStyles from './styles'

const Title = () => {
  const classes = useStyles()

  return (
    <Typography className={classes.title} color='textSecondary' variant='body2'>
      Chats
    </Typography>
  )
}

export default memo(Title)
