export function validatePhone(phone) {
  const regex = /^[+]*[0-9]{8,15}$/i
  return regex.test(phone)
}

export function validateEmail(email) {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return regex.test(email)
}

export function convertDateToString(date) {
  date = getDateFromString(date)
  date = new Date(date)
  const now = new Date()
  if (now.toDateString() === date.toDateString()) {
    return `${date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    })}, today`
  }
  return date.toLocaleDateString()
}

function getDateFromString(str) {
  if (typeof str === 'string') {
    if (str.substring(str.length - 1) !== 'Z') {
      str += 'Z'
    }
    return new Date(str)
  }
  return str
}

export function copyText(text) {
  return Promise.resolve(
    navigator.clipboard
      .writeText(text)
      .then(() => true)
      .catch((error) => {
        console.error(`Copy failed! ${error}`)
        return false
      })
  )
}
