import { handleActions, combineActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = false

export default handleActions(
  {
    [actions.getSelectionLists.request]() {
      return true
    },
    [combineActions(actions.getSelectionLists.failed, actions.getSelectionLists.success)]() {
      return false
    },
  },
  defaultState
)
