import { useState } from 'react'
import PropTypes from 'prop-types'

import { TextField, withStyles, InputAdornment, IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { get } from 'lodash'

import PasswordRequirements from '../../components/PasswordRequirements'

import * as Errors from '../../constants/Errors'

import styles from './styles'

const FormikPassword = ({ classes, field, form, checkPasswordRequirements, textColor, ...props }) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  return (
    <>
      <TextField
        color='primary'
        error={!!error}
        variant='outlined'
        className={classes.textField}
        helperText={error}
        FormHelperTextProps={{
          hidden: Boolean(error === Errors.empty),
        }}
        {...field}
        value={field.value || ''}
        {...props}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={handleShowPassword} aria-label='toggle password visibility'>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {checkPasswordRequirements && <PasswordRequirements value={field.value} color={textColor} />}
    </>
  )
}

FormikPassword.defaultProps = {
  textColor: 'textSecondary',
  checkPasswordRequirements: false,
}

FormikPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormikPassword)
