const customVideoRequests = {
  getConcatenatedVideoRequest: (data) => ({
    url: `api/videoconcat/CreateVideo`,
    method: 'post',
    data,
  }),
  getPagesByAnswerStatusRequest: (data) => ({
    url: `api/pages/getpagesbyanswerstatus`,
    method: 'post',
    data,
  }),
  getPagesPerQuestionsOrderRequest: (contentId, folderId = '') => ({
    url: `api/pages/getpagesperquestionsorder/${contentId}/${folderId}`,
    method: 'get',
  }),
}

export default customVideoRequests
