export const getMeRequest = () => ({
  url: `api/account/me`,
  method: 'get',
})

export const updateAccountRequest = (data) => ({
  url: `api/account`,
  method: 'put',
  data,
})

export const setAvatarRequest = (data) => ({
  url: `api/account/setavatar`,
  method: 'post',
  data,
})

export const removeAvatarRequest = () => ({
  url: `api/account/removeavatar`,
  method: 'get',
})

export const deleteAccountRequest = () => ({
  url: `api/account/delete`,
  method: 'delete',
})

export const loginViaInviteRequest = (data) => ({
  url: `api/account/completeLoginViaInvite`,
  method: 'post',
  data,
})

export const createProfileRequest = (data) => ({
  url: `api/account/profile`,
  method: 'post',
  data,
})

export const tryActivateProfileRequest = () => ({
  url: `api/account/tryActivate`,
  method: 'post',
})

export const getIdentityMeRequest = () => ({
  url: `identity-service/api/account/me`,
  method: 'get',
})

export const getUserTenantsRequest = (id) => ({
  url: `identity-service/api/account/${id}/tenants`,
  method: 'get',
})

export const getPowerBiDevelopersRequest = (params) => ({
  url: `identity-service/api/account/by-roles`,
  method: 'get',
  params: {
    roleSearch: 'PowerBI',
    ...params,
  },
})

export const getIdentityServerUserRequest = (id) => ({
  url: `identity-service/api/account/${id}`,
  method: 'get',
})
