import GridViewButtonContainer from '@tabeeb/modules/gridView/containers/GridViewButtonContainer'
import InviteButton from '../InviteButton'

import './styles.less'

const ToolbarLeftPanel = () => {
  return (
    <section className='panel'>
      <div className='grid-view-button'>
        <GridViewButtonContainer />
      </div>
      <div className='invite-button'>
        <InviteButton />
      </div>
    </section>
  )
}

export default ToolbarLeftPanel
