import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
    },
    button: {
      padding: theme.spacing() / 2,
    },
    menuIcon: {
      marginRight: theme.spacing(),
    },
    menu: {
      '& > .MuiMenu-paper': {
        backgroundColor: theme.palette.background.primary,
      },
    },
  }
})
