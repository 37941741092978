import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const openAiReportDialog = createAction('OPEN_AI_REPORT_DIALOG')
export const closeUniqueAiObjectDialog = createAction('CLOSE_AI_REPORT_DIALOG')

export const selectAiObject = createAction('SELECT_AI_OBJECT')
export const selectTemplate = createAction('SELECT_TEMPLATE')

export const getAiObjectsByContent = createApiActions('GET_AI_OBJECTS_BY_CONTENT')

export const goToAnnotation = createAction('GO_TO_ANNOTATION')

export const getReportTemplates = createApiActions('GET_PUBLISH_TEMPLATES')
export const createReportTemplate = createApiActions('CREATE_PUBLISH_TEMPLATE')
export const updateReportTemplate = createApiActions('UPDATE_PUBLISH_TEMPLATE')
export const deleteReportTemplate = createApiActions('DELETE_PUBLISH_TEMPLATE')

export const setAiObjectAnalysisNeedToRefresh = createAction('SET_AI_OBJECT_ANALYSIS_NEED_TO_REFRESH')

export const openAiReportPublishSettingsDialog = createAction('OPEN_AI_REPORT_PUBLISH_SETTINGS_DIALOG')
export const closeAiReportPublishSettingsDialog = createAction('CLOSE_AI_REPORT_PUBLISH_SETTINGS_DIALOG')

export const getDefaultPublishName = createApiActions('GET_DEFAULT_PUBLISH_NAME')
export const deleteUniqueAiObjectFromContent = createApiActions('DELETE_UNIQUE_AI_OBJECT_FROM_CONTENT')

export const getUniqueAiObjectPages = createApiActions('GET_UNIQUE_AI_OBJECT_PAGES')
export const getAiObjectAnalysis = createApiActions('GET_AI_OBJECT_ANALYSIS')
