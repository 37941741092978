import dayjs from 'dayjs'

export function convertNotification(notification) {
  notification.Id =
    notification.BillingActivityId ||
    notification.ContentSharingId ||
    notification.NotificationId ||
    notification.InvitationId ||
    notification.CalendarEventId ||
    notification.ContentsLinkId ||
    notification.CertificateId

  notification.FormatedUpdatedOn = dayjs(notification.UpdatedOn).format('MMM D, YYYY h:mm A')
  notification.NeedConfirmation =
    notification.CanBeRevoked || notification.IsProcessing ? false : notification.NeedApprove

  return notification
}
