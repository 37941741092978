import { memo } from 'react'
import PropTypes from 'prop-types'

import { Checkbox, ListItemIcon, ListItemText, MenuItem } from '@mui/material'

const AnswersListItem = ({ answer, disabled, selected, onSelect }) => {
  const onToggleSelect = () => {
    onSelect(!selected, answer.Id)
  }

  return (
    <MenuItem onClick={onToggleSelect}>
      <ListItemIcon>
        <Checkbox
          size='small'
          disabled={disabled}
          value={answer.Name}
          edge='start'
          checked={selected}
          color='primary'
        />
      </ListItemIcon>
      <ListItemText primary={answer.Name} title={answer.Name} />
    </MenuItem>
  )
}

AnswersListItem.propTypes = {
  answer: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default memo(AnswersListItem)
