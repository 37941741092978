import { useMemo } from 'react'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'

import { getTenantUsersCount } from '../actions'

export default ({ tenantId, onError }) => {
  const payload = useMemo(() => ({ tenantId }), [tenantId])

  const {
    loading,
    loaded,
    response: usersCount,
  } = useApiRequest({
    request: getTenantUsersCount.request,
    enabled: Boolean(tenantId),
    payload,
    onError,
  })

  return { loaded, loading, usersCount }
}
