export const requiredField = 'Required'
export const requiredFieldWithName = (fieldName) => `${fieldName} is required`
export const invalidFieldWithName = (fieldName) => `${fieldName} is invalid`
export const noWhitespaces = 'There should be no spaces at the beginning and at the end'
export const invalidColor = 'Invalid color'
export const invalidUrl = 'Invalid URL'
export const invalidSubdomain = 'Only latin characters, numbers and - _ are allowed'
export const invalidName = 'Invalid name'
export const invalidFtpHost = 'Invalid FTP Host'
export const invalidEmail = ' Email is invalid'
export const invalidData = 'Date is invalid'
export const maxLength = (max = 0) => `Maximum length is ${max} characters`
export const maxLengthWithName = (fieldName, max = 0) => `${fieldName} should be of maximum ${max} characters length`
export const requiredTagsOrAIObjects = 'At least one tag or CV class must be selected'
export const requiredTowerSideProfileFiles =
  'Tower Side Profile requires one 3D model and optionally one Analytics Report page'
export const required2dLineDrawingFiles = '2D Line Drawing requires one 3D model'
export const requiredBentleyAnalyticsReportFiles = 'Bentley Analytics Report requires one 3D model'
