import { handleActions, combineActions } from 'redux-actions'

import { getFolderPermissionsSuccess } from '@tabeeb/modules/permissions/actions'
import { resetWhiteboardState } from '@tabeeb/modules/whiteboard/actions'

import * as rawActions from '../actions'

const defaultState = {}

export default handleActions(
  {
    [rawActions.setCurrentFolder](state, action) {
      return action.payload
    },
    [rawActions.updateFolder.success](state, action) {
      const { Id, Name } = action.payload
      return state.Id === Id ? { ...state, Name } : state
    },
    [rawActions.addUsersToFolder.success](state, action) {
      const { FolderId } = action.payload
      return state.Id === FolderId ? { ...state, IsFolderShared: true } : state
    },
    [getFolderPermissionsSuccess](state, action) {
      const { folderId } = action.payload
      if (state?.Id !== folderId) {
        return state
      }

      const { Permissions } = action.response.data

      return {
        ...state,
        FolderUsersPermissions: Permissions,
      }
    },
    [combineActions(rawActions.resetState, rawActions.clearCurrentFolder, resetWhiteboardState)](state, action) {
      return defaultState
    },
  },
  defaultState
)
