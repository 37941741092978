import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  isIframeHidden: true,
}

export default handleActions(
  {
    [rawActions.showWebPageIframe](state, action) {
      return { ...state, isIframeHidden: false }
    },
    [rawActions.resetWebPageState](state, action) {
      return defaultState
    },
  },
  defaultState
)
