export const getPowerBIReportState = (state) => state.powerBI.powerBIReport
export const getPowerBIReportsState = (state) => state.powerBI.powerBIReports
export const getPowerBIReportEditorState = (state) => state.powerBI.powerBIReportEditor
export const getAddPowerBIReportModalState = (state) => state.powerBI.addPowerBIReportModalState
export const getAddPowerBIReportDependentModuleModalState = (state) =>
  state.powerBI.addPowerBIReportDependentModuleModalState
export const getPowerBIReportModalTenantState = (state) => state.powerBI.powerBIReportTenantModalState
export const getPowerBIReportModalUserState = (state) => state.powerBI.powerBIReportUserModalState
export const getPowerBIReportConfigurationCheckState = (state) => state.powerBI.powerBIReportConfigurationCheck
export const getPowerBIReportTenantsState = (state) => state.powerBI.powerBIReportTenants
export const getPowerBIReportUsersState = (state) => state.powerBI.powerBIReportUsers
export const getPowerBIReportDependentModulesState = (state) => state.powerBI.powerBIReportDependentModules
