import { handleActions } from 'redux-actions'
import * as rawActions from '../../actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.openImportFromExcelDialog](state, action) {
      return true
    },
    [rawActions.closeImportFromExcelDialog](state, action) {
      return false
    },
    [rawActions.resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
