import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LocalOfferOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { Typography, TextField } from '@material-ui/core'

import { aiActions, aiSelectors } from '@tabeeb/modules/artificialIntelligence'
import { contentStateSelectors } from '@tabeeb/modules/shared/content'
import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import * as drawingActions from '../../actions'
import * as playerToolbarSelectors from '../../selectors'

import AnnotationPresetSelector, { Preset } from '../AnnotationPresetSelector'
import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'

import useStyles from './styles'

const BUTTON_NAME = 'aiflag'

const presets = [Preset.AIHotspot, Preset.Stroke, Preset.Rectangle, Preset.Polygon]

const AIFlagButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const aiObjects = useSelector(aiSelectors.getAIObjects)
  const selectedAIObject = useSelector(aiSelectors.getSelectedAiObject)
  const contentId = useSelector(contentStateSelectors.getContentId)
  const display = useSelector(playerToolbarSelectors.getIsAiFlagButtonVisible)

  const color = useSelector(playerToolbarSelectors.getSelectedColor)
  const preset = useSelector(playerToolbarSelectors.getDrawingPreset)
  const selected = useSelector((state) => playerToolbarSelectors.getIsButtonSelected(state, BUTTON_NAME))
  const isDrawingEnabled = useSelector(playerToolbarSelectors.getIsDrawingEnabled)

  const onClick = useCallback(() => {
    if (selected) {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: '' }))
      dispatch(drawingActions.onDisableDrawing())
    } else {
      onOpen()
    }
  }, [dispatch, onOpen, selected])

  const onSelectPreset = useCallback(
    (selectedPreset) => {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: BUTTON_NAME }))

      dispatch(drawingActions.onSetDrawingType(selectedPreset.type))
      dispatch(drawingActions.onSetDrawingPreset(selectedPreset.id))
      dispatch(drawingActions.onSetDrawingColor(selectedAIObject.Color))
      dispatch(drawingActions.onEnableDrawing())
    },
    [dispatch, selectedAIObject.Color]
  )

  const onObjectChange = (event, value) => {
    const selectedAIObject = aiObjects.find((object) => object.Id === value.Id)

    dispatch(aiActions.onSelectDrawingAIObject(selectedAIObject))
    dispatch(aiActions.getUniqueAiObjects.request({ aiObjectId: value.Id, contentId }))

    if (selectedAIObject.Color && color !== selectedAIObject.Color) {
      dispatch(drawingActions.onSetDrawingColor(selectedAIObject.Color))
    }
  }

  return (
    <>
      <ToolbarButton
        ref={anchorRef}
        Icon={LocalOfferOutlined}
        title='Add AI annotation'
        htmlColor={isDrawingEnabled && selected ? color : null}
        display={display}
        selected={selected || open}
        disabled={aiObjects.length <= 0}
        onClick={onClick}
      />
      <ToolbarPopover anchorEl={anchorRef.current} open={open} onClose={onClose}>
        <AnnotationPresetSelector
          presets={presets}
          color={color}
          selected={selected ? preset : null}
          enabled={isDrawingEnabled}
          onSelect={onSelectPreset}
        />
        <div className={classes.aiObjectSelect}>
          <Autocomplete
            fullWidth
            disableClearable
            options={aiObjects}
            onChange={onObjectChange}
            value={selectedAIObject}
            getOptionLabel={(option) => option.Name}
            renderOption={(option) => (
              <Typography noWrap title={option.Name}>
                {option.Name}
              </Typography>
            )}
            classes={{ ...classes, aiObjectSelect: undefined, container: undefined }}
            renderInput={(params) => <TextField {...params} label='CV Class' variant='outlined' />}
          />
        </div>
      </ToolbarPopover>
    </>
  )
}

export default memo(AIFlagButton)
