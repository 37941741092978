import { handleActions, combineActions } from 'redux-actions'
import _ from 'lodash'

import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.getProjects.success](state, { response }) {
      return [...response.data]
    },
    [combineActions(rawActions.updateProject.success)](state, { response }) {
      return _updateProject(state, response.data)
    },
    [rawActions.deleteProject.success](state, action) {
      return state.filter((project) => project.Id !== action.payload.projectId)
    },
  },
  defaultState
)

function _updateProject(state, updatedProject) {
  return state.map((project) => (project.Id === updatedProject.Id ? { ...project, ...updatedProject } : project))
}
