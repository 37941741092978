import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const setNewReminder = createAction('SET_NEW_REMINDER')
export const setExistingReminder = createAction('SET_EXISTING_REMINDER')
export const addReminderNotification = createAction('ADD_REMINDER_NOTIFICATION')
export const removeReminderNotification = createAction('REMOVE_REMINDER_NOTIFICATION')
export const resetRemindersNotifications = createAction('RESET_REMINDERS_NOTIFICATIONS')

export const createReminder = createAction('CREATE_REMINDER')
export const createReminderRequest = createAction('CREATE_REMINDER_REQUEST')
export const createReminderSuccess = createAction('CREATE_REMINDER_SUCCESS')
export const createReminderFailed = createAction('CREATE_REMINDER_FAILED')

export const deleteReminderRequest = createAction('DELETE_REMINDER_REQUEST')
export const deleteReminderSuccess = createAction('DELETE_REMINDER_SUCCESS')
export const deleteReminderFailed = createAction('DELETE_REMINDER_FAILED')

export const updateReminderRequest = createAction('UPDATE_REMINDER_REQUEST')
export const updateReminderSuccess = createAction('UPDATE_REMINDER_SUCCESS')
export const updateReminderFailed = createAction('UPDATE_REMINDER_FAILED')

export const getRemindersByContentRequest = createAction('GET_REMINDERS_BY_CONTENT_REQUEST')
export const getRemindersByContentSuccess = createAction('GET_REMINDERS_BY_CONTENT_SUCCESS')
export const getRemindersByContentFailed = createAction('GET_REMINDERS_BY_CONTENT_FAILED')

export const getAvailableReminderReceivers = createApiActions('GET_FORM_ANSWER_AVAILABLE_REMINDER_RECEIVERS')
