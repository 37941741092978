export default (theme) => {
  return {
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
    },
    icon: {
      ...theme.typography.h3,
    },
    text: {
      ...theme.typography.body1,
    },
  }
}
