import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPowerBIProviderConnectionCheckState } from '../selectors'
import { checkPowerBIReportProviderConnection, resetCheckPowerBIReportProviderConnection } from '../actions'

const usePowerBIConnectionCheck = () => {
  const dispatch = useDispatch()

  const { fetchStatus, errorObject } = useSelector(getPowerBIProviderConnectionCheckState)

  const onInitCheck = useCallback(
    ({ azureTenantId, azureClientId, azureClientSecret }) => {
      dispatch(
        checkPowerBIReportProviderConnection.request({
          azureTenantId,
          azureClientId,
          azureClientSecret,
        })
      )
    },
    [dispatch]
  )

  const onResetState = useCallback(() => {
    dispatch(resetCheckPowerBIReportProviderConnection())
  }, [dispatch])

  return { fetchStatus, errorObject, onInitCheck, onResetState }
}

export default usePowerBIConnectionCheck
