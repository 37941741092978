import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import * as usersSelectors from '@tabeeb/modules/../users/selectors'
import { fallbackUser } from '@tabeeb/shared/users/common'
import UserAvatar from '@tabeeb/shared/userAvatar'

import MessageCloud from './MessageCloud'
import SenderName from './SenderName'
import SentDate from './SentDate'

import useStyles from './styles'

const MessagesGroup = ({ containmentRef, messages }) => {
  const classes = useStyles()

  const firstMessage = messages[0]
  const sender = useSelector((state) => usersSelectors.getUserById(state, { Id: firstMessage.userId }) || fallbackUser)

  return (
    <li className={classNames(classes.container, { [classes.self]: firstMessage.self })}>
      <UserAvatar className={classes.avatar} user={sender} />
      <div className={classNames(classes.list, { [classes.selfList]: firstMessage.self })}>
        <div className={classes.info}>
          <SenderName name={sender.displayName} />, <SentDate date={firstMessage.timeStamp} />
        </div>
        {messages.map((message) => (
          <MessageCloud key={message.id} containmentRef={containmentRef} message={message} />
        ))}
      </div>
    </li>
  )
}

MessagesGroup.propTypes = {
  containmentRef: PropTypes.object,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number.isRequired,
      timeStamp: PropTypes.string.isRequired,
      self: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
}

export default memo(MessagesGroup)
