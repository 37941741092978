import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
import { Paper, Typography } from '@material-ui/core'

import { HealthDataTypesDisplayName } from '@tabeeb/enums'

import { apexChartOptions } from '@tabeeb/modules/healthData/constants'
import HealthStatisticsChartControls from '@tabeeb/modules/healthData/components/HealthStatisticsChartControls'
import { generateApexChartOptions, getHealthDataUnitsForCharts } from '@tabeeb/modules/healthData/helper/chartsHelper'

import useStyles from './styles'

const HealthStatisticsChart = ({
  handleToggleTimeLine,
  showAddHealthDataOption,
  handleAddHealthDataDialogOpen,
  chartHealthData,
  userSystemOfMeasure,
}) => {
  const classes = useStyles()
  const chartId = `chart-${chartHealthData.dataType}`
  const options = generateApexChartOptions(apexChartOptions, chartId)
  const chartSeries = chartHealthData.convertedData
    ? [
        {
          name: getHealthDataUnitsForCharts(chartHealthData.dataType, userSystemOfMeasure),
          data: chartHealthData.convertedData,
        },
      ]
    : []

  return (
    <Paper id={chartId} variant='outlined' className={classes.paper}>
      <Typography variant='body2' color='textSecondary' className={classes.chartName}>
        {HealthDataTypesDisplayName[chartHealthData.name]}
      </Typography>
      <HealthStatisticsChartControls
        handleToggleTimeLine={handleToggleTimeLine}
        showAddHealthDataOption={showAddHealthDataOption}
        handleAddHealthDataDialogOpen={handleAddHealthDataDialogOpen}
        chartHealthData={{
          dataType: chartHealthData.dataType,
          name: chartHealthData.name,
          interval: chartHealthData.interval,
        }}
        toggleDisabled={!(Array.isArray(chartHealthData.convertedData) && chartHealthData.convertedData.length !== 0)}
      />
      <div>
        <Chart options={options} series={chartSeries} type='area' height={350} />
      </div>
    </Paper>
  )
}

HealthStatisticsChart.propTypes = {
  handleToggleTimeLine: PropTypes.func.isRequired,
  showAddHealthDataOption: PropTypes.bool.isRequired,
  handleAddHealthDataDialogOpen: PropTypes.func.isRequired,
  chartHealthData: PropTypes.object.isRequired,
  userSystemOfMeasure: PropTypes.number.isRequired,
}

export default HealthStatisticsChart
