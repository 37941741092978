import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import { requiredTowerSideProfileFiles } from '@tabeeb/shared/utils/validationErrorMessages'
import * as rawActions from '../actions'
import { MINIMUM_ASSETS_NUMBER_FOR_TOWER_SIDE_PROFILE } from '../constants'

import Timeline from '../../timeline/components/Timeline'

class TowerSideProfileImagesListContainer extends Component {
  _handleClose = () => {
    const {
      actions: { closeTowerSideProfileDialog },
    } = this.props

    closeTowerSideProfileDialog()
  }

  _handleSubmit = () => {
    const {
      actions: { addTowerSideProfile },
      isProcessing,
    } = this.props
    if (isProcessing) {
      return
    }
    addTowerSideProfile()
  }

  _handleDeleteItem = (itemIndex) => {
    const {
      actions: { deletePageFromTowerSideProfile },
    } = this.props

    deletePageFromTowerSideProfile(itemIndex)
  }

  _handleUpdateList = (newList) => {
    const {
      actions: { updatePagesTowerSideProfile },
    } = this.props

    updatePagesTowerSideProfile(newList)
  }

  render() {
    const { isOpen, isProcessing, timeLineList, currentPanel } = this.props

    const actions = [
      {
        onClick: this._handleClose,
        title: 'Cancel',
        class: 'close',
      },
      {
        onClick: this._handleSubmit,
        title: 'Done',
        tooltip:
          timeLineList.length < MINIMUM_ASSETS_NUMBER_FOR_TOWER_SIDE_PROFILE ? requiredTowerSideProfileFiles : null,
        class: 'submit',
        disabled: timeLineList.length < MINIMUM_ASSETS_NUMBER_FOR_TOWER_SIDE_PROFILE,
      },
    ]

    const props = {
      actions,
      timeLineList,
      handleDeleteItem: this._handleDeleteItem,
      handleUpdateList: this._handleUpdateList,

      isOpen,
      isSubmitted: isProcessing,
      isLoading: isProcessing,
      isSelected: currentPanel === TabPanel.TowerSideProfile,
    }

    return <Timeline {...props} />
  }
}

TowerSideProfileImagesListContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  timeLineList: PropTypes.array.isRequired,
  currentPanel: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    closeTowerSideProfileDialog: PropTypes.func.isRequired,
    addTowerSideProfile: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.towerSideProfile.isTimelineOpen,
    isProcessing: state.towerSideProfile.isProcessing,
    timeLineList: state.towerSideProfile.pagesList,
    currentPanel: state.navbar.currentPanel[TabPanelLocation.Bottom],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TowerSideProfileImagesListContainer)
