import { Box, Typography } from '@mui/material'
import { SearchOffOutlined } from '@mui/icons-material'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

const NoSessionsPlaceholder = () => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' flexGrow={1}>
      <SearchOffOutlined fontSize='large' color='pagePrimaryAction' />
      <Typography ml={1} variant='h6' color='inherit'>
        {sessionTitleFormatter.format('There are no sessions')}
      </Typography>
    </Box>
  )
}

export default NoSessionsPlaceholder
