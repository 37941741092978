import { Autocomplete, Box, Chip, CircularProgress, Divider, Grid, TextField, Tooltip, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useApiRequest, useDebouncedValue } from '@tabeeb/shared/utils/hooks'
import { searchQualificationVersions } from '@tabeeb/modules/qualifications/actions'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

const QualificationVersionsAutocomplete = ({ multiple, AutocompleteProps, TextFieldInputProps }) => {
  const [searchTextQualificationVersions, setSearchTextQualificationVersions] = useState('')
  const debouncedSearchQualifications = useDebouncedValue(searchTextQualificationVersions, 500)
  const searchQualificationVersionsParams = useMemo(
    () => ({ search: debouncedSearchQualifications }),
    [debouncedSearchQualifications]
  )
  const handleChangeSearchQualifications = useCallback((event, value) => {
    setSearchTextQualificationVersions(value)
  }, [])

  const { response, loading } = useApiRequest({
    defaultResponse: [],
    request: searchQualificationVersions.request,
    payload: searchQualificationVersionsParams,
  })

  const { setFieldValue, touched, errors, setFieldTouched } = useFormikContext()

  return (
    <Autocomplete
      multiple={multiple}
      loading={loading}
      onInputChange={handleChangeSearchQualifications}
      onChange={(event, value) => {
        if (multiple) {
          setFieldValue('QualificationVersionId', Boolean(value) && value.map((_) => _.Id))
        } else {
          setFieldValue('QualificationVersionId', value?.Id)
        }
      }}
      name='QualificationVersionId'
      renderInput={(params) => (
        <TextField
          {...params}
          label='Qualification version'
          error={Boolean(touched.QualificationVersionId) && Boolean(errors.QualificationVersionId)}
          helperText={Boolean(touched.QualificationVersionId) && errors.QualificationVersionId}
          {...TextFieldInputProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <>
          <Box {...props} key={option.Id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Tooltip disableInteractive title={option.Name}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography
                  variant='body2'
                  sx={{ maxWidth: '65%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {option.Name}
                </Typography>
                <Typography variant='caption'>
                  Valid till:{' '}
                  {option.ExpirationDate ? new Date(option.ExpirationDate).toLocaleDateString('en-US') : 'Unlimited'}
                </Typography>
              </Box>
            </Tooltip>
            <Tooltip
              disableInteractive
              title={
                <>
                  <Typography variant='body2'>Certificate types:</Typography>
                  {option.CertificateTypes.map((type) => (
                    <Typography key={type.Id} variant='body2'>
                      - {type.Name}
                    </Typography>
                  ))}
                </>
              }
            >
              <Grid container spacing={1}>
                {option.CertificateTypes.slice(0, 3).map((type) => (
                  <Grid item key={type.Id}>
                    <Chip size='small' label={type.Name} sx={{ maxWidth: '145px !important' }} />
                  </Grid>
                ))}
                {option.CertificateTypes.length > 3 && (
                  <Grid item>
                    <Typography variant='body2'>{`+${option.CertificateTypes.length - 3}`}</Typography>
                  </Grid>
                )}
              </Grid>
            </Tooltip>
          </Box>
          <Divider />
        </>
      )}
      options={response}
      getOptionLabel={(option) => option.Name}
      onBlur={() => setFieldTouched('QualificationVersionId')}
      filterOptions={(options) => options}
      {...AutocompleteProps}
    />
  )
}

QualificationVersionsAutocomplete.defaultProps = {
  multiple: false,
}

QualificationVersionsAutocomplete.propTypes = {
  multiple: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  AutocompleteProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  TextFieldInputProps: PropTypes.object,
}

export default QualificationVersionsAutocomplete
