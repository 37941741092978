import { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'

import Routes from '@tabeeb/routes'
import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { createTenantAnnouncement } from '@tabeeb/modules/articles/actions'
import { AnnouncementScope } from '@tabeeb/modules/articles/enums'

import AnnouncementEditorPage from '../AnnouncementEditorPage'

const AnnouncementCreateTenantPage = () => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async (announcement) => {
      try {
        await callApiAsync(createTenantAnnouncement.request(announcement))
        dispatch(onAddSuccessNotification({ message: 'Announcement successfully created' }))
        dispatch(replace(Routes.announcements))
      } catch {
        dispatch(onAddErrorNotification({ message: 'Failed to create announcement' }))
      }
    },
    [dispatch]
  )

  return (
    <AnnouncementEditorPage title='Create tenant announcement' scope={AnnouncementScope.Tenant} onSubmit={onSubmit} />
  )
}

export default memo(AnnouncementCreateTenantPage)
