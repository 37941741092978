import { useSelector } from 'react-redux'

import NavbarTab from '@tabeeb/modules/navbar/components/NavbarTab'
import routes from '@tabeeb/routes'
import { getShowBulkImport } from '@tabeeb/modules/bulkImports/selectors'

const BulkImportTab = ({ ...rest }) => {
  const display = useSelector(getShowBulkImport)

  const props = {
    item: {
      label: 'Bulk import',
      to: routes.bulkImports,
    },
    display,
    ...rest,
  }

  return <NavbarTab {...props} />
}

export default BulkImportTab
