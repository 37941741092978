import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'

import { getIsCurrentUserPresenter } from '@tabeeb/shared/content/selectors'
import { muteAudio, muteRemoteAudio } from '@tabeeb/modules/presentationToolbar/actions/muteAudio'

import { AUDIO_MUTED } from '../constants'
import { getCurrentUserId, getIsCallPreviewOpen, getIsUserAudioPlaying } from '../selectors'

function useMuteUserAudio({ user }) {
  const dispatch = useDispatch()

  const currentUserId = useSelector(getCurrentUserId)
  const isPresenter = useSelector(getIsCurrentUserPresenter)
  const isMuted = useSelector((state) => !getIsUserAudioPlaying(state, { Id: user.id }))
  const isMuteInProgress = useSelector((state) => state.presentationToolbar.muteAudioInProgress)
  const isCallPreviewOpen = useSelector(getIsCallPreviewOpen)

  const handleClick = useCallback(() => {
    if (isMuteInProgress === true || (user.isOnline === false && !isCallPreviewOpen)) {
      return
    }

    if (user.id === currentUserId) {
      dispatch(muteAudio())
      if (isCallPreviewOpen) {
        localStorage.setItem(AUDIO_MUTED, JSON.stringify(!isMuted))
      }
    } else if (isPresenter) {
      dispatch(muteRemoteAudio(user.id))
    }
  }, [currentUserId, dispatch, isCallPreviewOpen, isMuteInProgress, isMuted, isPresenter, user.id, user.isOnline])

  const returnValues = useMemo(
    () => ({ handleClick, isPresenter, currentUserId, isMuted }),
    [currentUserId, handleClick, isMuted, isPresenter]
  )

  return returnValues
}

export default useMuteUserAudio
