import { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from './styles'

const ContentDescription = ({ description, classes }) => {
  return (
    <span className={classes.text} title={description}>
      {`Content name: ${description}`}
    </span>
  )
}

ContentDescription.propTypes = {
  description: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(memo(ContentDescription))
