import { alpha } from '@material-ui/core/styles'

export default (theme) => {
  const buttonColor = theme.palette.background.user.action
  const buttonTextColor = theme.palette.grey[400]

  return {
    container: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: theme.spacing() / 2,
      padding: theme.spacing() / 4,
      boxShadow: theme.shadows[2],
      minWidth: 0,
      zIndex: 10,
      color: buttonTextColor,

      background: buttonColor,
      '&:hover': {
        background: alpha(buttonColor, 0.7),
      },
    },
  }
}
