import { all } from 'redux-saga/effects'

import deleteItem from './deleteItem'
import addDrawingPages from './addDrawingPages'
import addUrlVideos from './addUrlVideos'
import addSnapshots from './addSnapshots'
import { updateOrAddGalleryPages } from './updateOrAddGallery'
import addPdf from './addPdf'
import exportImages from './exportImages'
import exportAsPdf from './exportAsPdf'
import searchInPdf from './searchInPdf'
import loadingIndicator from './loadingIndicator'
import renameGalleryItems from './renameGalleryItems'
import selectItem from './selectItem'
import assets from './assets'
import updatePageAsset from './updatePageAsset'
import addWebPageByUrl from './addWebPageByUrl'
import addFolders from './addFolders'
import addSpatialModelPage from './addSpatialModelPage'
import selection from './selection'
import uploadFiles from './uploadFiles'
import addStructureModel from './addStructureModel'
import addTowerSideProfile from './addTowerSideProfile'
import add2dLineDrawing from './add2dLineDrawing'
import add3dModel from './add3dModel'
import galleryButtonsMenus from './galleryButtonsMenus'
import addBentleyModel from './addBentleyModel'
import pageFolderPermissions from './pageFolderPermissions'
import addBentleyAnalyticsReport from './addBentleyAnalyticsReport'
import addTableRecognition from './addTableRecognition'
import addClassificationAnnotations from './addClassificationAnnotations'
import measurementSettings from './measurementSettings'
import addSplatModel from './addSplatModel'

function* gallerySaga() {
  yield all([
    addPdf(),
    addUrlVideos(),
    addDrawingPages(),
    addSnapshots(),
    exportAsPdf(),
    exportImages(),
    deleteItem(),
    searchInPdf(),
    loadingIndicator(),
    updateOrAddGalleryPages(),
    renameGalleryItems(),
    selectItem(),
    updatePageAsset(),
    assets(),
    addWebPageByUrl(),
    addFolders(),
    addSpatialModelPage(),
    selection(),
    uploadFiles(),
    addStructureModel(),
    addTowerSideProfile(),
    add2dLineDrawing(),
    add3dModel(),
    galleryButtonsMenus(),
    addBentleyModel(),
    pageFolderPermissions(),
    addBentleyAnalyticsReport(),
    addTableRecognition(),
    addClassificationAnnotations(),
    measurementSettings(),
    addSplatModel(),
  ])
}

export default gallerySaga
