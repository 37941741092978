import { memo } from 'react'
import PropTypes from 'prop-types'

import ChatMessageAudioAsset from '../ChatMessageAudioAsset'
import { ChatMessageAssetType } from './enums'

const chatMessageAssetControls = {
  [ChatMessageAssetType.audio]: ChatMessageAudioAsset,
}

const ChatMessageAsset = ({ asset }) => {
  const AssetControl = chatMessageAssetControls[asset.type]

  return AssetControl && <AssetControl asset={asset} />
}

ChatMessageAsset.propTypes = {
  asset: PropTypes.shape({
    url: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(ChatMessageAsset)
