import { useCallback, useEffect, useState } from 'react'

/** @typedef {{
    total: Number,
    pageSize: Number
 }} UsePaginationHookPayload */

/**
 * @param {UsePaginationHookPayload} props
 */
export default ({ total = 0, pageSize = 25, initialPage = 1 } = {}) => {
  const [{ size, current }, setState] = useState({ size: pageSize, current: initialPage })

  useEffect(() => {
    if (Math.ceil(total / size) < current && current > 1) {
      setState((prevState) => ({ ...prevState, current: 1 }))
    }
  }, [total, current, size])

  const onPageNumberChange = useCallback(
    /**
     * @param {Number} newPageNumber
     */
    (newPageNumber) => {
      setState((prevState) => ({ ...prevState, current: newPageNumber }))
    },
    []
  )

  const onPageSizeChange = useCallback(
    /**
     * @param {Number} newPageSize
     */
    (newPageSize) => {
      setState((prevState) => ({ ...prevState, size: newPageSize, current: 1 }))
    },
    []
  )

  return {
    current,
    pages: Math.ceil(total / size),
    size,
    onPageNumberChange,
    onPageSizeChange,
  }
}
