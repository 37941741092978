function _getNlpModelsRequest() {
  return {
    url: `api/nlpModels`,
    method: 'get',
  }
}

const nlpModelsRequests = {
  getNlpModelsRequest: (data) => _getNlpModelsRequest(data),
  getNlpModelsForFormRequest: (data) => _getNlpModelsRequest(data),
  getNlpModelRequest: ({ id }) => ({
    url: `api/nlpModels/${id}`,
    method: 'get',
  }),
  addNlpModelRequest: (data) => ({
    url: `api/nlpModels`,
    method: 'post',
    data,
  }),
  updateNlpModelRequest: (data) => ({
    url: `api/nlpModels`,
    method: 'put',
    data,
  }),
  deleteNlpModelRequest: ({ nlpModelId }) => ({
    url: `api/nlpModels/${nlpModelId}`,
    method: 'delete',
  }),
}

export default nlpModelsRequests
