import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Paper,
  TableContainer,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
} from '@mui/material'
import { RefreshOutlined, Add, InfoOutlined } from '@mui/icons-material'
import { PageHeader, Pagination } from '@tabeeb/uikit'
import { usePagination } from '@tabeeb/shared/utils/hooks'
import { FetchStatus } from '@tabeeb/enums'
import PowerBIReportProvidersTableRow from '../PowerBIReportProvidersTableRow'
import { loadPowerBIReportProviders, openPowerBIProviderModal, setPowerBIReportProvidersFilters } from '../../actions'
import { getPowerBIReportProvidersState } from '../../selectors'
import { PowerBIProviderModal } from '../Modals'

const PowerBIReportProvidersTable = () => {
  const dispatch = useDispatch()

  const { providers, fetchStatus, totalCount, filters } = useSelector(getPowerBIReportProvidersState)

  const {
    current: currentPage,
    pages,
    size,
    onPageNumberChange,
    onPageSizeChange,
  } = usePagination({ total: totalCount, pageSize: filters.pageSize, initialPage: filters.pageNumber })

  const handlePageSizeChange = useCallback(
    (pageSize) => {
      dispatch(setPowerBIReportProvidersFilters({ pageSize, pageNumber: 1 }))
      onPageSizeChange(pageSize)
    },
    [dispatch, onPageSizeChange]
  )

  const handlePageNumberChange = useCallback(
    (pageNumber) => {
      dispatch(setPowerBIReportProvidersFilters({ pageNumber }))
      onPageNumberChange(pageNumber)
    },
    [dispatch, onPageNumberChange]
  )

  const onRefresh = useCallback(() => {
    dispatch(loadPowerBIReportProviders())
  }, [dispatch])

  const onAddProvider = useCallback(() => {
    dispatch(openPowerBIProviderModal())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  const onOpenInfo = useCallback(() => {
    window
      .open('https://nxteccorp.atlassian.net/wiki/external/ZThkZWJjMWI4ZjhkNDFhYmJmYTk3MTk1OGJkMjQzMWQ', '_blank')
      .focus()
  }, [])

  return (
    <>
      <Paper sx={{ p: 2, pt: 1 }}>
        <PageHeader
          title={
            <>
              <Typography title='PowerBI report providers' variant='h6' fontWeight={600} noWrap>
                PowerBI report providers
              </Typography>
              <IconButton color='primary' onClick={onOpenInfo}>
                <InfoOutlined />
              </IconButton>
            </>
          }
          actions={
            <>
              <Button
                disabled={fetchStatus === FetchStatus.Loading}
                startIcon={<RefreshOutlined color={fetchStatus === FetchStatus.Loading ? 'disabled' : 'primary'} />}
                variant='outlined'
                onClick={onRefresh}
              >
                Refresh
              </Button>
              <Button
                disabled={fetchStatus === FetchStatus.Loading}
                startIcon={<Add color={fetchStatus === FetchStatus.Loading ? 'disabled' : 'primary'} />}
                variant='outlined'
                onClick={onAddProvider}
              >
                Add
              </Button>
            </>
          }
        />
        <TableContainer
          sx={{ position: 'relative', height: 'calc(100vh - 200px)' }}
          variant='outlined'
          component={Paper}
        >
          {fetchStatus === FetchStatus.Loading && (
            <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 3 }} />
          )}
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left' width={510}>
                  Name
                </TableCell>
                <TableCell align='left' width={450}>
                  Owner
                </TableCell>
                <TableCell align='left' width={300}>
                  Created
                </TableCell>
                <TableCell align='left' width={250}>
                  Status
                </TableCell>
                <TableCell width={100} />
              </TableRow>
            </TableHead>
            <TableBody>
              {providers.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align='center'>
                    <Typography variant='subtitle1'>
                      {fetchStatus === FetchStatus.Loading ? 'Loading providers' : 'No providers found'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {providers.map((provider, index) => (
                <PowerBIReportProvidersTableRow key={provider.Id} provider={provider} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          current={currentPage}
          pages={pages}
          pageSize={size}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Paper>
      <PowerBIProviderModal />
    </>
  )
}

export default PowerBIReportProvidersTable
