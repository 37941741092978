/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
import { createRef, createElement, Component } from 'react'

const loadLibrary = function () {
  const skin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'amp-default'

  return new Promise(function (resolve, reject) {
    if (document.querySelector('#amp-azure')) return resolve()
    const scriptTag = document.createElement('script')
    const linkTag = document.createElement('link')
    linkTag.rel = 'stylesheet'
    scriptTag.id = 'amp-azure'
    scriptTag.src = '//amp.azure.net/libs/amp/latest/azuremediaplayer.min.js'
    linkTag.href = `//amp.azure.net/libs/amp/latest/skins/${skin}/azuremediaplayer.min.css`
    document.body.appendChild(scriptTag)
    document.head.insertBefore(linkTag, document.head.firstChild)
    scriptTag.onload = function () {
      return resolve({ skin })
    }
  })
}

function createCommonjsModule(fn, module) {
  return (module = { exports: {} }), fn(module, module.exports), module.exports
}

const classnames = createCommonjsModule(function (module) {
  ;(function () {
    const hasOwn = {}.hasOwnProperty

    function classNames() {
      const classes = []

      for (let i = 0; i < arguments.length; i++) {
        const arg = arguments[i]
        if (!arg) continue

        const argType = typeof arg

        if (argType === 'string' || argType === 'number') {
          classes.push(arg)
        } else if (Array.isArray(arg) && arg.length) {
          const inner = classNames.apply(null, arg)
          if (inner) {
            classes.push(inner)
          }
        } else if (argType === 'object') {
          for (const key in arg) {
            if (hasOwn.call(arg, key) && arg[key]) {
              classes.push(key)
            }
          }
        }
      }

      return classes.join(' ')
    }

    if ('object' !== 'undefined' && module.exports) {
      classNames.default = classNames
      module.exports = classNames
    } else if (typeof undefined === 'function' && typeof undefined.amd === 'object' && undefined.amd) {
      // register as 'classnames', consistent with npm package name
      undefined('classnames', [], function () {
        return classNames
      })
    } else {
      window.classNames = classNames
    }
  })()
})

function styleInject(css, ref) {
  if (ref === void 0) ref = {}
  const { insertAt } = ref

  if (!css || typeof document === 'undefined') {
    return
  }

  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  style.type = 'text/css'

  if (insertAt === 'top') {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild)
    } else {
      head.appendChild(style)
    }
  } else {
    head.appendChild(style)
  }

  if (style.styleSheet) {
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }
}

const css =
  '.mp-component_azure-mp-container__idgeq {\n  background: #000;\n  width: 100%;\n  height: 0;\n  display: block;\n  padding: 0;\n  position: relative;\n  overflow: hidden;\n  position: relative;\n}\n\n.mp-component_azure-mp-container__idgeq > div {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n'
const styles = { 'azure-mp-container': 'mp-component_azure-mp-container__idgeq' }
styleInject(css)

const classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function')
  }
}

const createClass = (function () {
  function defineProperties(target, props) {
    for (let i = 0; i < props.length; i++) {
      const descriptor = props[i]
      descriptor.enumerable = descriptor.enumerable || false
      descriptor.configurable = true
      if ('value' in descriptor) descriptor.writable = true
      Object.defineProperty(target, descriptor.key, descriptor)
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps)
    if (staticProps) defineProperties(Constructor, staticProps)
    return Constructor
  }
})()

const defineProperty = function (obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    })
  } else {
    obj[key] = value
  }

  return obj
}

const inherits = function (subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(`Super expression must either be null or a function, not ${typeof superClass}`)
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  })
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.prototype = Object.create(superClass))
}

const possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
  }

  return call && (typeof call === 'object' || typeof call === 'function') ? call : self
}

const DEFAULT_SKIN = 'amp-default'
const DEFAULT_RATIO = [16, 9]

const AzureMP = (function (_Component) {
  inherits(AzureMP, _Component)

  function AzureMP(props) {
    classCallCheck(this, AzureMP)

    const _this = possibleConstructorReturn(this, Object.getPrototypeOf(AzureMP).call(this, props))

    _this.videoNode = createRef()
    return _this
  }

  createClass(AzureMP, [
    {
      key: 'destroyPlayer',
      value: function destroyPlayer() {
        this.player && this.player.dispose()
      },
    },
    {
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        this.destroyPlayer()
      },
    },
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        const _this2 = this

        const { skin } = this.props

        this.initialization = loadLibrary(skin).then(function () {
          _this2.createPlayer()
          _this2.setVideo()
        })
      },
    },
    {
      key: 'componentDidUpdate',
      value: function componentDidUpdate(prevProps) {
        const _this3 = this

        if (prevProps.src !== this.props.src) {
          this.initialization.then(function () {
            return _this3.setVideo()
          })
        }
      },
    },
    {
      key: 'setVideo',
      value: function setVideo() {
        const { src } = this.props

        this.player.src(src)
      },
    },
    {
      key: 'createPlayer',
      value: function createPlayer() {
        const _props = this.props
        const { options } = _props
        const { onInstanceCreated } = _props

        const defaultOptions = {
          controls: true,
          logo: { enabled: false },
        }
        this.player = window.amp(this.videoNode.current, { ...defaultOptions, ...options })
        onInstanceCreated && onInstanceCreated(this.player)
      },
    },
    {
      key: 'getRatioStyles',
      value: function getRatioStyles(ratio) {
        if (!ratio) {
          return {}
        }
        return { paddingBottom: `${(ratio[1] / ratio[0]) * 100}%` }
      },
    },
    {
      key: 'render',
      value: function render() {
        const _props2 = this.props
        const { className } = _props2
        const _props2$skin = _props2.skin
        const skin = _props2$skin === undefined ? DEFAULT_SKIN : _props2$skin
        const _props2$adaptRatio = _props2.adaptRatio
        const adaptRatio = _props2$adaptRatio === undefined ? DEFAULT_RATIO : _props2$adaptRatio
        const { cornerPlayBtn } = _props2

        return createElement(
          'div',
          {
            style: this.getRatioStyles(adaptRatio),
            className: classnames(defineProperty({}, styles['azure-mp-container'], adaptRatio), className),
          },
          createElement('video', {
            poster: _props2.poster,
            ref: this.videoNode,
            className: classnames('azuremediaplayer', `${skin}-skin`, {
              'amp-big-play-centered': !cornerPlayBtn,
            }),
          })
        )
      },
    },
  ])
  return AzureMP
})(Component)

export { AzureMP, loadLibrary }
