import { createAction } from 'redux-actions'

export const setCalendarDate = createAction('SET_CALENDAR_DATE')
export const setCalendarView = createAction('SET_CALENDAR_VIEW')

export const attachSessionToEvent = createAction('ATTACH_SESSION_TO_EVENT')
export const detachSessionFromEvent = createAction('DETACH_SESSION_FROM_EVENT')

export const updateCalendarRangeSynchronizationState = createAction('UPDATE_CALENDAR_RANGE_SYNCHRONIZATION_STATE')

export const resetCalendarState = createAction('RESET_CALENDAR_STATE')
