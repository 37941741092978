import React from 'react'

import { SvgIcon } from '@material-ui/core'

export default (props) => (
  <SvgIcon viewBox='0 0 22 22' {...props}>
    <circle cx='7.90625' cy='7.90625' r='6.90625' stroke='rgba(0, 0, 0, 0.54)' fill='white' strokeWidth='2' />
    <rect
      x='5.8125'
      y='5.12506'
      width='15.1875'
      height='13.125'
      rx='1'
      fill='white'
      stroke='rgba(0, 0, 0, 0.54)'
      strokeWidth='2'
    />
  </SvgIcon>
)
