import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { AnnotationType } from '@tabeeb/enums'

import { convertAnnotationObjectToComponent } from '../../services/annotationsService'

const AnnotationsList = ({ annotations }) => {
  return (
    <>
      {_.orderBy(annotations, (item) => item.Type !== AnnotationType.Text)
        .filter((annotation) => annotation.ParentAnnotationId === null)
        .map(convertAnnotationObjectToComponent)}
    </>
  )
}

AnnotationsList.propTypes = {
  annotations: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Type: PropTypes.oneOf(Object.values(AnnotationType)).isRequired,
      ParentAnnotationId: PropTypes.number,
    }).isRequired
  ).isRequired,
}

export default React.memo(AnnotationsList)
