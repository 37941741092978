import { handleActions } from 'redux-actions'

import * as trackActions from '../actions/tracks'

const initialState = {
  localTracks: [],
  remoteTracks: {},
  previewTracks: [],
  tracksError: '',
  trackMuteInProcess: false,
  largeVideoUser: null,
  gridUser: null,
  isPriorityView: true,
}

export default handleActions(
  {
    [trackActions.setLocalTracks](state, action) {
      return { ...state, localTracks: action.payload }
    },
    [trackActions.updateLocalTrack](state, action) {
      return addOrUpdateLocalTrack(state, action.payload)
    },
    [trackActions.removeLocalTrack](state, action) {
      return removeLocalTrack(state, action.payload)
    },
    [trackActions.resetLocalTracks](state, action) {
      return { ...state, localTracks: [] }
    },
    [trackActions.addPreviewTrack](state, action) {
      return addPreviewTrack(state, action.payload)
    },
    [trackActions.resetPreviewTracks](state, action) {
      return { ...state, previewTracks: [] }
    },
    [trackActions.setTracksError](state, action) {
      return { ...state, tracksError: action.payload }
    },
    [trackActions.setTrackMuteState](state, action) {
      return { ...state, trackMuteInProcess: action.payload }
    },
    [trackActions.addRemoteTrack](state, action) {
      return addRemoteTrack(state, action.payload)
    },
    [trackActions.removeRemoteTrack](state, action) {
      return removeRemoteTrack(state, action.payload)
    },
    [trackActions.resetTracksForUser](state, action) {
      return resetTracksForUser(state, action.payload)
    },
    [trackActions.setLargeVideoUser](state, action) {
      return { ...state, largeVideoUser: action.payload }
    },
    [trackActions.setGridUser](state, action) {
      return { ...state, gridUser: action.payload }
    },
    [trackActions.disposeTracks](state, action) {
      return disposeTracks(state)
    },
    [trackActions.initTracksForUser](state, action) {
      const userId = action.payload
      state.remoteTracks[userId] = []
      return {
        ...state,
      }
    },
    [trackActions.toggleMobilePriorityView](state, action) {
      return {
        ...state,
        isPriorityView: action.payload,
      }
    },
  },
  initialState
)

const addRemoteTrack = (state, track) => {
  let userId = track.getParticipantId()
  userId = parseInt(userId, 10)

  let userRemoteTracks = state.remoteTracks[userId]
  if (userRemoteTracks === undefined) {
    userRemoteTracks = []
  }
  const jitsiTrack = userRemoteTracks.find((jitsiTrack) => jitsiTrack.getType() === track.getType())

  if (jitsiTrack) {
    userRemoteTracks.splice(userRemoteTracks.indexOf(jitsiTrack), 1)
  }

  userRemoteTracks.push(track)
  return {
    ...state,
    ...userRemoteTracks,
  }
}

const addPreviewTrack = (state, track) => {
  const { previewTracks } = state
  previewTracks.push(track)

  return {
    ...state,
    previewTracks,
  }
}

const removeRemoteTrack = (state, track) => {
  const { remoteTracks } = state
  const index = remoteTracks.findIndex((remoteTrack) => remoteTrack.getType() === track.getType())

  if (index > -1) {
    remoteTracks.splice(index, 1)
  }
  return {
    ...state,
    ...remoteTracks,
  }
}

const removeLocalTrackByType = (state, type) => {
  const localTracks = [...state.localTracks]
  const index = localTracks.findIndex((localTrack) => localTrack.getType() === type)

  if (index > -1) {
    localTracks.splice(index, 1)
  }

  return {
    ...state,
    localTracks,
  }
}

const removeLocalTrack = (state, track) => {
  return removeLocalTrackByType(state, track.getType())
}

const resetTracksForUser = (state, userId) => {
  const remoteTracks = { ...state.remoteTracks }
  remoteTracks[userId] = []
  return {
    ...state,
    remoteTracks,
  }
}

const addOrUpdateLocalTrack = (state, track) => {
  let resultLocalTracks = [...state.localTracks]

  const index = resultLocalTracks.findIndex((localTrack) => localTrack.getType() === track.getType())

  if (index === -1) {
    resultLocalTracks = [...resultLocalTracks, track]
  } else {
    resultLocalTracks[index] = track
  }

  return {
    ...state,
    localTracks: resultLocalTracks,
  }
}

const disposeTracks = (state) => {
  state = {
    localTracks: [],
    remoteTracks: {},
    previewTracks: [],
    tracksError: '',
    trackMuteInProcess: false,
    largeVideoUser: null,
  }
  return {
    ...state,
  }
}
