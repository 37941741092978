import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import * as rawActions from '../actions/index'
import * as notificationActions from '../../notification/actions'
import ExportImagesDialog from '../components/ExportImagesDialog/index'

class ExportImagesDialogContainer extends Component {
  _exportImages = (withAnnotations) => {
    const { contentId, actions } = this.props
    actions.setExportImagesWithAnnotations(withAnnotations)
    actions.exportImages(contentId)
  }

  render() {
    const props = {
      isExportImagesDialogOpen: this.props.isExportImagesDialogOpen,
      closeDialog: this.props.actions.closeExportImagesDialog,
      handleExportImages: this._exportImages,
      isShowProgress: this.props.isShowProgress,
    }
    return <ExportImagesDialog {...props} />
  }
}

ExportImagesDialogContainer.propTypes = {
  isExportImagesDialogOpen: PropTypes.bool.isRequired,
  contentId: PropTypes.number.isRequired,
  pages: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  isShowProgress: PropTypes.bool.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(rawActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
  }
}

function mapStateToProps(state) {
  return {
    isExportImagesDialogOpen: state.gallery.isExportImagesDialogOpen,
    contentId: state.contentState.contentId,
    pages: state.gallery.galleryList,
    isShowProgress: state.gallery.assetsList.inProgress,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportImagesDialogContainer)
