import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    form: {
      flexGrow: 1,
      width: 400,
    },
    button: {
      marginTop: theme.spacing(1.25),
    },
    avatarBlock: {
      width: 25,
      height: 25,
      fontSize: 12,
      marginRight: theme.spacing(2),
    },
    autocompleteGrid: {
      overflowY: 'hidden',
    },
  }
})
