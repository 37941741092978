import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    bright: {
      '& svg': {
        stroke: '#666666',
        strokeWidth: 1,
      },
    },
  }
})
