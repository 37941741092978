const workflowsRequests = {
  runWorkflowRequest: (data) => {
    return {
      url: 'workflow-service/api/workflows/run',
      method: 'post',
      data,
    }
  },
  getWorkflowsRequest: () => {
    return {
      url: 'workflow-service/api/workflows',
      method: 'get',
    }
  },
  getTenantWorkflowsRequest: () => {
    return {
      url: 'workflow-service/api/workflows/tenant',
      method: 'get',
    }
  },
  addTenantWorkflowRequest: (workflowId) => {
    return {
      url: `workflow-service/api/workflows/${workflowId}/tenant`,
      method: 'post',
    }
  },
  removeTenantWorkflowRequest: (workflowId) => {
    return {
      url: `workflow-service/api/workflows/${workflowId}/tenant`,
      method: 'delete',
    }
  },
}

export default workflowsRequests
