import { Box, ListItemButton, ListItemText, Skeleton } from '@mui/material'

const ContentTrainingMaterialListItemSkeleton = () => {
  return (
    <ListItemButton disableTouchRipple>
      <Box mr={1.5}>
        <Skeleton variant='circular' width={18} height={18} />
      </Box>
      <ListItemText primary={<Skeleton width={175} />} secondary={<Skeleton width={125} />} />
      <Skeleton variant='circular' width={22} height={22} />
    </ListItemButton>
  )
}

export default ContentTrainingMaterialListItemSkeleton
