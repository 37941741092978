import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'

import './styles.less'

const TextArea = ({ disabled, control, answer, handleSubmit, handleChange, isEditingAnswer }) => {
  const value = answer.value || ''

  const _handleBlur = () => {
    if (isEditingAnswer) return

    handleSubmit()
  }

  return (
    <TextField
      fullWidth
      title={control.Tooltip}
      variant='outlined'
      id={`${control.Id}`}
      className='formsTextArea'
      value={value}
      onChange={handleChange}
      onBlur={_handleBlur}
      disabled={disabled}
      multiline
      rows={4}
      rowsMax={4}
    />
  )
}

TextArea.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isEditingAnswer: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default TextArea
