import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = 1

export default handleActions(
  {
    [rawActions.setIterationPagesPageNumber](state, action) {
      return action.payload
    },
    [combineActions(
      rawActions.setSelectedProject,
      rawActions.setIterationPagesPageSize,
      rawActions.setSelectedIterationId,
      rawActions.resetSelectedIterationId
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
