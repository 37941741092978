import { handleActions } from 'redux-actions'

import { openMeasurementCalibrationDialog, closeMeasurementCalibrationDialog } from '../actions'

const defaultState = {
  open: false,
  annotation: null,
}

export default handleActions(
  {
    [openMeasurementCalibrationDialog](state, action) {
      return {
        ...state,
        ...action.payload,
        open: true,
      }
    },
    [closeMeasurementCalibrationDialog](state, action) {
      return defaultState
    },
  },
  defaultState
)
