export default (theme) => {
  return {
    container: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
    },
    control: {
      flexGrow: 1,

      '& .MuiInputBase-formControl': {
        flexGrow: 1,
        overflow: 'hidden',
      },
    },
    checkbox: {
      color: theme.palette.text.secondary,
      padding: 0,
      marginRight: theme.spacing(),

      '&.MuiCheckbox-colorSecondary.Mui-checked': {
        color: 'unset',
      },
    },
    select: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      height: '100%',
      color: theme.palette.text.secondary,
      padding: theme.spacing(),

      '&:focus': {
        background: 'transparent',
      },
    },
    menu: {
      maxHeight: 400,
      maxWidth: 256,
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      left: -theme.spacing() / 2,
    },
    item: {
      padding: theme.spacing(),
      color: theme.palette.text.secondary,

      '&.Mui-selected': {
        background: 'transparent',
      },
    },
    itemContent: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      ...theme.typography.body2,
    },
    subtitle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      ...theme.typography.caption,
    },
  }
}
