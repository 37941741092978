import PropTypes from 'prop-types'

import { Assignment } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { sessionTitleFormatter, trainingMaterialTitleFormatter } from '@tabeeb/modules/shared/utils/text'

const NoTrainingMaterialsAttachedPlaceholder = ({ onAttach }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' flexGrow={1}>
      <Assignment fontSize='large' />
      <Box ml={1.5}>
        <Typography variant='body1' fontWeight={500}>
          {trainingMaterialTitleFormatter.format('No training materials attached yet...')}
        </Typography>

        {!onAttach && (
          <Typography color='text.secondary' variant='body2'>
            {sessionTitleFormatter.format('Wait for session owner to attach them')}
          </Typography>
        )}

        {onAttach && (
          <Typography
            sx={{
              ':hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            }}
            variant='body2'
            color='text.secondary'
            onClick={onAttach}
          >
            Click here to attach
          </Typography>
        )}
      </Box>
    </Box>
  )
}

NoTrainingMaterialsAttachedPlaceholder.propTypes = {
  onAttach: PropTypes.func,
}

export default NoTrainingMaterialsAttachedPlaceholder
