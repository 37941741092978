import { handleActions } from 'redux-actions'

import * as healthActions from '@tabeeb/modules/healthData/actions'

const defaultState = {}

export default handleActions(
  {
    [healthActions.onGetViewedPatient](state, action) {
      return action.payload
    },
    [healthActions.resetViewedPatientState](state, action) {
      return defaultState
    },
  },
  defaultState
)
