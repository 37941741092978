import { createSelector } from 'reselect'
import { AnnotationSource } from '@tabeeb/enums'
import { ObjectDetectorType } from '../enums'

export const getTrainingSettings = (state) => state.modelTraining.trainingSettings

export const getInitialBatchSize = createSelector([getTrainingSettings], (trainingSettings) => {
  const baseProps = {
    min: 16,
    max: 64,
    default: 16,
    marks: [16, 32, 64],
  }

  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndPolygonAnnotations:
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
      baseProps.min = 2
      baseProps.max = 16
      baseProps.default = 4
      baseProps.marks = [2, 4, 8, 16]
      break
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
      baseProps.min = 2
      baseProps.max = 32
      baseProps.default = 8
      baseProps.marks = [2, 4, 8, 16, 32]
      break
    case AnnotationSource.ImageAndQuestionPairs:
    default:
      break
  }
  return baseProps
})

export const getInitialLearningRate = createSelector([getTrainingSettings], (trainingSettings) => {
  const baseProps = {
    min: 0.00001,
    max: 0.1,
    default: 0.001,
  }

  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndQuestionPairs:
      baseProps.min = 0.00000001
      baseProps.max = 0.00001
      baseProps.default = 0.00001
      break
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
    default:
      break
  }

  return baseProps
})

export const getInitialEarlyStoppingPatience = createSelector([getTrainingSettings], (trainingSettings) => {
  const baseProps = {
    min: 1,
    max: 10,
    default: 5,
    marks: [5],
  }

  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndQuestionPairs:
      baseProps.default = 3
      baseProps.marks = [3]
      break
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
    default:
      break
  }

  return baseProps
})

export const getInitialNumberOfEpochs = createSelector([getTrainingSettings], (trainingSettings) => {
  const baseProps = {
    min: 1,
    max: 100,
    default: 100,
  }

  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndNlpTagSets:
      baseProps.min = 1
      baseProps.max = 100
      baseProps.default = 20
      break
    case AnnotationSource.ImageAndQuestionPairs:
      baseProps.min = 2
      baseProps.max = 100
      baseProps.default = 50
      break
    case AnnotationSource.ImageAndClassificationAnnotations:
      baseProps.min = 1
      baseProps.max = 100
      baseProps.default = 20
      break
    case AnnotationSource.ImageAndPolygonAnnotations:
      baseProps.min = 1
      baseProps.max = 100
      baseProps.default = 10
      break
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
      baseProps.min = 1
      baseProps.max = 50
      baseProps.default = 10
      break
    default:
      break
  }

  return baseProps
})

export const getIsPretrainedModelNameSettingVisible = createSelector([getTrainingSettings], (trainingSettings) => {
  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
      return true
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
    case AnnotationSource.ImageAndQuestionPairs:
    default:
      return false
  }
})

export const getIsTextEncoderLayersPercentSettingVisible = createSelector([getTrainingSettings], (trainingSettings) => {
  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndQuestionPairs:
      return true
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
    default:
      return false
  }
})

export const getIsImageEncoderLayersPercentSettingVisible = createSelector(
  [getTrainingSettings],
  (trainingSettings) => {
    switch (trainingSettings.annotationSource) {
      case AnnotationSource.ImageAndQuestionPairs:
        return true
      case AnnotationSource.ImageAndNlpTagSets:
      case AnnotationSource.ImageAndClassificationAnnotations:
      case AnnotationSource.ImageAndBoundingBoxAnnotations:
      case AnnotationSource.ImageAndPolygonAnnotations:
      default:
        return false
    }
  }
)

export const getIsNumberOfCrossValidationFoldsSettingVisible = createSelector(
  [getTrainingSettings],
  (trainingSettings) => {
    switch (trainingSettings.annotationSource) {
      case AnnotationSource.ImageAndQuestionPairs:
        return true
      case AnnotationSource.ImageAndNlpTagSets:
      case AnnotationSource.ImageAndClassificationAnnotations:
      case AnnotationSource.ImageAndBoundingBoxAnnotations:
      case AnnotationSource.ImageAndPolygonAnnotations:
      default:
        return false
    }
  }
)

export const getIsConfidenceThresholdSettingVisible = createSelector([getTrainingSettings], (trainingSettings) => {
  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndQuestionPairs:
      return true
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
    default:
      return false
  }
})

export const getIsObjectDetectorSettingVisible = createSelector([getTrainingSettings], (trainingSettings) => {
  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndPolygonAnnotations:
      return true
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
    case AnnotationSource.ImageAndQuestionPairs:
    default:
      return false
  }
})

export const getInitialObjectDetector = createSelector([getTrainingSettings], (trainingSettings) => {
  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndPolygonAnnotations:
      return ObjectDetectorType.MaskRCNN50
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
    case AnnotationSource.ImageAndQuestionPairs:
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
    default:
      return ObjectDetectorType.FasterRCNN50
  }
})

export const getObjectDetectorSettingOptions = createSelector([getTrainingSettings], (trainingSettings) => {
  switch (trainingSettings.annotationSource) {
    case AnnotationSource.ImageAndBoundingBoxAnnotations:
      return [
        ObjectDetectorType.FasterRCNN50,
        ObjectDetectorType.FasterRCNN101,
        ObjectDetectorType.FasterRCNN101X,
        ObjectDetectorType.YoloXSmall,
        ObjectDetectorType.YoloXMedium,
        ObjectDetectorType.YoloXLarge,
      ]
    case AnnotationSource.ImageAndPolygonAnnotations:
      return [
        ObjectDetectorType.MaskRCNN50,
        ObjectDetectorType.MaskRCNN101,
        ObjectDetectorType.MaskRCNN101X,
        ObjectDetectorType.CascadeMaskRCNN50,
      ]
    case AnnotationSource.ImageAndNlpTagSets:
    case AnnotationSource.ImageAndClassificationAnnotations:
    case AnnotationSource.ImageAndQuestionPairs:
    default:
      return []
  }
})
