import { createSelector } from 'reselect'

export const getSncFiltersState = (state) => state.sncFilters

export const getIsSncFiltersEnabled = (state) => getSncFiltersState(state).enabled

export const getSncQuestionSelectedAnswers = (state, { questionId }) =>
  getSncFiltersState(state).questions[questionId] || []

export const getSncFilterModel = createSelector([getSncFiltersState], (sncFilters) => {
  const sncQuestionAnswers = sncFilters.questions
  const sncFilterModel = Object.keys(sncQuestionAnswers)
    .map((questionId) => ({
      QuestionId: parseInt(questionId, 10),
      AnswerIds: sncQuestionAnswers[questionId],
    }))
    .filter((qna) => qna.AnswerIds.length > 0)
  return sncFilterModel
})
