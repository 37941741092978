import { handleActions, combineActions } from 'redux-actions'
import {
  closeContentSharingDialog,
  openContentSharingDialog,
  selectTenantDestination,
  setUserForSharing,
  setContentsForSharing,
  resetState,
  syncContent,
  setStepCompleted,
  addPageForMerging,
  deletePageForMerging,
  addAllPagesForMerging,
  closeSelectedPagesForMerging,
  addPagesByFormsForMerging,
  setFormsForMerging,
  removeAnswerStatusForMerging,
  addAnswerStatusForMerging,
  removeFormForMerging,
  addFormForMerging,
  removeStatusFromAllFormsForMerging,
  removeAllFormsForMerging,
} from '@tabeeb/modules/contentSharings/actions'
import { createContentSharingFailed, createContentSharingSuccess } from '@tabeeb/modules/sessionsPage/actions'
import { getContentSharingDialogStages } from '@tabeeb/modules/contentSharings/services/contentSharingSteps'
import { AnswerStatuses } from '@tabeeb/enums'

const defaultState = {
  contentForms: null,
  pages: [],
  tenant: null,
  targetContents: [],
  user: null,
  type: null,
  stages: [],
  isOpen: false,
}

export default handleActions(
  {
    [openContentSharingDialog](state, { payload: { sharingDialogType } }) {
      return {
        ...state,
        type: sharingDialogType,
        stages: getContentSharingDialogStages(sharingDialogType),
        isOpen: true,
      }
    },
    [selectTenantDestination](state, { payload }) {
      return { ...state, tenant: payload }
    },
    [setUserForSharing](state, { payload }) {
      return { ...state, user: payload }
    },
    [setContentsForSharing](state, { payload }) {
      return { ...state, targetContents: payload }
    },
    [syncContent](state, { payload }) {
      return { ...state, ...payload }
    },
    // forms
    [setFormsForMerging](state, { payload }) {
      return { ...state, contentForms: payload }
    },
    [removeAllFormsForMerging](state) {
      return { ...state, contentForms: [] }
    },
    [removeStatusFromAllFormsForMerging](state, { payload }) {
      const selectedStatus = payload
      const updatedForms = state.contentForms.reduce((result, form) => {
        const updatedStatuses = form?.SelectedAnswerStatuses?.filter((status) => status !== selectedStatus)
        const hasStatusesRemain = updatedStatuses && updatedStatuses.length > 0
        if (hasStatusesRemain) {
          result.push({
            ...form,
            SelectedAnswerStatuses: [...updatedStatuses],
          })
        }
        return result
      }, [])

      return { ...state, contentForms: [...updatedForms] }
    },
    [addFormForMerging](state, { payload }) {
      const addedForm = {
        ContentFormId: payload,
        SelectedAnswerStatuses: [AnswerStatuses.Approve, AnswerStatuses.Pending, AnswerStatuses.Reject],
      }
      return { ...state, contentForms: [...state.contentForms, addedForm] }
    },
    [removeFormForMerging](state, { payload }) {
      const contentFormId = payload
      return { ...state, contentForms: state.contentForms.filter((f) => f.ContentFormId !== contentFormId) }
    },
    [addAnswerStatusForMerging](state, { payload }) {
      const { formId, status } = payload
      const isFormAdded = state.contentForms.some((form) => form.ContentFormId === formId)

      if (isFormAdded) {
        const updatedForms = state.contentForms.map((form) =>
          form.ContentFormId === formId
            ? { ...form, SelectedAnswerStatuses: [...form.SelectedAnswerStatuses, status] }
            : form
        )

        return { ...state, contentForms: updatedForms }
      }
      const addedForm = {
        ContentFormId: formId,
        SelectedAnswerStatuses: [status],
      }

      return { ...state, contentForms: [...state.contentForms, addedForm] }
    },
    [removeAnswerStatusForMerging](state, { payload }) {
      const { formId, status } = payload
      const form = state.contentForms.find((f) => f.ContentFormId === formId)
      const isLastStatus = form && form.SelectedAnswerStatuses.length === 1

      if (isLastStatus) {
        return { ...state, contentForms: state.contentForms.filter((f) => f.ContentFormId !== formId) }
      }
      const updatedForms = state.contentForms.map((f) =>
        f.ContentFormId === formId
          ? {
              ...f,
              SelectedAnswerStatuses: form.SelectedAnswerStatuses.filter((answerStatus) => answerStatus !== status),
            }
          : f
      )

      return { ...state, contentForms: updatedForms }
    },
    // pages
    [addPageForMerging](state, action) {
      const addedPage = action.payload
      const isAlreadyAdded = state.pages.some((page) => page.id === addedPage.id)
      if (isAlreadyAdded) {
        return state
      }
      return { ...state, pages: [...state.pages, addedPage] }
    },
    [deletePageForMerging](state, action) {
      const pageId = action.payload
      return { ...state, pages: [...state.pages.filter((p) => p.id !== pageId)] }
    },
    [addAllPagesForMerging](state, action) {
      const pages = action.payload
      const newPages = pages.filter((item) => !state.pages.some((p) => p.id === item.id))
      return { ...state, pages: [...state.pages, ...newPages] }
    },
    [addPagesByFormsForMerging](state, { payload }) {
      const newState = state.pages.filter((page) => page.contentFormId === null)
      const pages = payload.filter((p) => !newState.some((page) => page.id === p.id))
      pages.forEach((page) => newState.push(page))

      return { ...state, pages: newState }
    },
    [setStepCompleted](state, { payload: { stageId, stepId, isCompleted } }) {
      const updatedSteps = state.stages
        .find((s) => s.id === stageId)
        ?.steps.map((step) => (step.id === stepId ? { ...step, isCompleted } : step))

      const updatedStages = state.stages.map((stage) =>
        stage.id === stageId ? { ...stage, steps: updatedSteps } : stage
      )

      return { ...state, stages: updatedStages }
    },
    [combineActions(
      closeContentSharingDialog,
      resetState,
      createContentSharingSuccess,
      createContentSharingFailed,
      closeSelectedPagesForMerging
    )]() {
      return defaultState
    },
  },
  defaultState
)
