import { SessionStatus, ReviewStatus } from '@tabeeb/enums'

export const sortingColumns = [
  'Title',
  'Owner',
  'CreatedOn',
  'LastActivity',
  'DaysActive',
  'Pages',
  'Videos',
  'Images',
  'Total',
  'Approved',
  'Rejected',
  'Pending',
  'ApprovedByReviewer',
  'RejectedByReviewer',
  'ApprovedByAI',
  'RejectedByAI',
]

export const filteringColumns = ['SessionStatus', 'ReviewStatus']

export const enumColumns = ['SessionStatus', 'ReviewStatus']
export const stickyColumns = ['Title']

export const enumColumnValues = {
  SessionStatus: {
    [SessionStatus.Live]: 'Live',
    [SessionStatus.InProgress]: 'In Progress',
    [SessionStatus.Published]: 'Published',
    [SessionStatus.Shared]: 'Shared',
    [SessionStatus.CallEnded]: 'Call Ended',
    [SessionStatus.CheckedIn]: 'Checked-in',
    [SessionStatus.CheckedOut]: 'Checked-out',
    [SessionStatus.NotStarted]: 'Not Started',
    [SessionStatus.CopiedReview]: 'Copied Review',
  },
  ReviewStatus: {
    [ReviewStatus.NotStarted]: 'Not Started',
    [ReviewStatus.InReview]: 'In Review',
    [ReviewStatus.ReadyForReview]: 'Ready for Review',
    [ReviewStatus.SessionFeedbackSent]: 'Feedback Sent',
    [ReviewStatus.Complete]: 'Complete',
  },
}

export const filterValues = {
  SessionStatus: Object.keys(enumColumnValues.SessionStatus).map((value) => ({
    value: parseInt(value, 10),
    title: enumColumnValues.SessionStatus[value],
  })),
  ReviewStatus: Object.keys(enumColumnValues.ReviewStatus).map((value) => ({
    value: parseInt(value, 10),
    title: enumColumnValues.ReviewStatus[value],
  })),
}

export const mappedSortingColumns = {
  Title: 'Description',
  Owner: 'Email',
  CreatedOn: 'CreatedOn',
  LastActivity: 'LastActivityDate',
  DaysActive: 'UploadDaysCount',
  Pages: 'CountPagesActive',
  Videos: 'CountVideosActive',
  Images: 'CountImagesActive',
  Total: 'AnswerPositionCount',
  Approved: 'CountAnswersApproved',
  Rejected: 'CountAnswersRejected',
  ApprovedByReviewer: 'CountAnswersApprovedByReviewer',
  RejectedByReviewer: 'CountAnswersRejectedByReviewer',
  ApprovedByAI: 'CountAnswersApprovedByAI',
  RejectedByAI: 'CountAnswersRejectedByAI',
  Pending: 'CountAnswersSubmitted',
}

export const actualColumns = [
  { PropName: 'Title', CellTitle: 'Title' },
  { PropName: 'SessionDetails', CellTitle: 'Session Details' },
  { PropName: 'Owner', CellTitle: 'Owner' },
  { PropName: 'GenericId', CellTitle: 'Generic ID' },
  { PropName: 'CreatedOn', CellTitle: 'Created On' },
  { PropName: 'LastActivity', CellTitle: 'Last Activity' },
  { PropName: 'SessionStatus', CellTitle: 'Session Status' },
  { PropName: 'ReviewStatus', CellTitle: 'Review Status' },
  { PropName: 'SentFromTenant', CellTitle: 'Sent From' },
  { PropName: 'ReviewFromTenant', CellTitle: 'Review From' },
  { PropName: 'ReviewToTenant', CellTitle: 'Review To' },
  { PropName: 'Reviewer', CellTitle: 'Reviewer' },
  { PropName: 'Total', CellTitle: 'Total' },
  { PropName: 'CompletedPercent', CellTitle: 'Coverage %' },
  { PropName: 'ApprovedPercent', CellTitle: 'Approved %' },
  { PropName: 'RejectedPercent', CellTitle: 'Rejected %' },
  { PropName: 'PendingPercent', CellTitle: 'Submitted %' },
  { PropName: 'Approved', CellTitle: 'Approved by Owner' },
  { PropName: 'Rejected', CellTitle: 'Rejected by Owner' },
  { PropName: 'ApprovedByReviewer', CellTitle: 'Approved by Reviewer' },
  { PropName: 'RejectedByReviewer', CellTitle: 'Rejected by Reviewer' },
  { PropName: 'ApprovedByAI', CellTitle: 'Approved by AI' },
  { PropName: 'RejectedByAI', CellTitle: 'Rejected by AI' },
  { PropName: 'Pending', CellTitle: 'Submitted' },
  { PropName: 'NoAction', CellTitle: 'No Action' },
  { PropName: 'Pages', CellTitle: 'Pages' },
  { PropName: 'Videos', CellTitle: 'Videos' },
  { PropName: 'Images', CellTitle: 'Images' },
  { PropName: 'Length', CellTitle: 'Length' },
  { PropName: 'DaysActive', CellTitle: 'Days Active' },
]

export const deletedColumns = [
  { PropName: 'Title', CellTitle: 'Title' },
  { PropName: 'Owner', CellTitle: 'Owner' },
  { PropName: 'CreatedOn', CellTitle: 'Created On' },
]

export const dashboardManagerType = {
  Create: 1,
  Edit: 2,
}

export const dashboardManagerTabs = {
  Primary: 0,
  Custom: 1,
}
