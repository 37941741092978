import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Grid, Typography } from '@mui/material'

import { AnnotationType } from '@tabeeb/enums'
import { getScale, getUnit } from '@tabeeb/modules/pointCloud/selectors'
import { FEET_IN_METER } from '@tabeeb/modules/pointCloud/utils/measurements'
import { Units } from '@tabeeb/modules/pointCloud/constants'

import { getParentOfHotspotDialogAnnotation } from '../../selectors'

const getCircumference = (a, b) => {
  return 2 * Math.PI * Math.sqrt((Math.pow(a, 2) + Math.pow(b, 2)) / 2)
}

const toPrecision = (value, precision) => {
  return parseFloat(value.toFixed(precision), 10)
}

const Field = ({ label, unit, value }) => {
  return (
    <Typography variant='body1' textAlign='center' noWrap>
      <strong>
        {label}, {unit}:{'  '}
      </strong>
      {toPrecision(value, 2)}
    </Typography>
  )
}

Field.propTypes = {
  label: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

const AIAnnotationEditorDimensions = () => {
  const parent = useSelector(getParentOfHotspotDialogAnnotation)
  const worldScale = useSelector(getScale)
  const unit = useSelector(getUnit)
  const unitScale = unit === Units.Meters ? 1 : FEET_IN_METER
  const totalScale = worldScale * unitScale
  const lengthUnitDisplayName = unit === Units.Meters ? 'm' : 'ft'

  return (
    parent &&
    (parent.Type === AnnotationType.Cylinder || parent.Type === AnnotationType.Box) && (
      <Grid container spacing={1} pt={1}>
        <Grid item xs={4}>
          <Field label='Width' unit={lengthUnitDisplayName} value={(parent?.Scale?.X ?? 0) * totalScale} />
        </Grid>

        <Grid item xs={4}>
          <Field label='Height' unit={lengthUnitDisplayName} value={(parent?.Scale?.Y ?? 0) * totalScale} />
        </Grid>

        <Grid item xs={4}>
          <Field label='Depth' unit={lengthUnitDisplayName} value={(parent?.Scale?.Z ?? 0) * totalScale} />
        </Grid>

        {parent.Type === AnnotationType.Cylinder && (
          <Grid item xs={4}>
            <Field
              label='Circumference'
              unit={lengthUnitDisplayName}
              value={getCircumference(
                ((parent?.Scale?.Z ?? 0) * totalScale) / 2,
                ((parent?.Scale?.X ?? 0) * totalScale) / 2
              )}
            />
          </Grid>
        )}
      </Grid>
    )
  )
}

export default AIAnnotationEditorDimensions
