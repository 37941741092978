import { handleActions } from 'redux-actions'
import { addTenantWorkflow, getTenantWorkflows, removeTenantWorkflow } from '../actions'

const defaultState = {
  list: [],
  isLoaded: false,
  isLoading: false,
}

export default handleActions(
  {
    [getTenantWorkflows.request](state, action) {
      return { ...state, isLoading: true }
    },
    [getTenantWorkflows.success](state, action) {
      return { ...state, list: action.response.data, isLoaded: true, isLoading: false }
    },
    [getTenantWorkflows.failed](state, action) {
      return { ...state, isLoaded: true, isLoading: false }
    },
    [addTenantWorkflow.success](state, action) {
      const workflowId = action.payload
      return { ...state, list: [...state.list, { Id: workflowId }] }
    },
    [removeTenantWorkflow.success](state, action) {
      const workflowId = action.payload
      return { ...state, list: state.list.filter((w) => w.Id !== workflowId) }
    },
  },
  defaultState
)
