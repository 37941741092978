import { put, takeLatest, select, all, delay } from 'redux-saga/effects'

import { getSelectedUser } from '@tabeeb/modules/presentation/selectors'
import { gridViewSelectors } from '@tabeeb/modules/gridView'
import * as snapshotActions from '../actions/snapshot'
import * as galleryActions from '../../gallery/actions/index'

import { takeSnapshotPicture } from '../services/snapshotService'

import { SnapshotResponseDelay } from '../common/snapshot'
import { accountSelectors } from '../../account'

function* onSnapshotRequestReceived({ payload, response }) {
  const selectedUser = yield select(getSelectedUser)

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const isGridView = yield select(gridViewSelectors.getIsGridViewActive)
  const contentId = yield select((state) => state.contentState.contentId)
  if (currentUserId == payload[0].attributes.targetUserId) {
    const file = takeSnapshotPicture(currentUserId, selectedUser, isGridView)
    const files = [file]
    yield put(galleryActions.addGallerySnapshotsUploadFiles({ files, ignoreFileUploads: true, contentId }))
  }
}

function* onSnapshotTaking() {
  yield put(snapshotActions.onSnapshotProgressStateUpdate(true))

  yield delay(SnapshotResponseDelay)

  const snapshotInProgress = yield select((state) => state.presentationToolbar.snapshotInProgress)
  if (snapshotInProgress === true) {
    yield put(snapshotActions.onSnapshotProgressStateUpdate(false))
  }
}

function* onSnapshotCreated({ payload, response }) {
  yield put(snapshotActions.onSnapshotProgressStateUpdate(false))
}

function* snapshotSaga() {
  yield all([
    takeLatest(snapshotActions.onSnapshotRequestReceived, onSnapshotRequestReceived),
    takeLatest(snapshotActions.onSnapshotTaking, onSnapshotTaking),
    takeLatest(snapshotActions.onSnapshotCreated, onSnapshotCreated),
  ])
}

export default snapshotSaga
