import PropTypes from 'prop-types'

import { Avatar } from '@material-ui/core'

import { DaysOfWeek } from '@tabeeb/enums'
import classNames from 'classnames'
import { ReminderError } from '../../services/reminderErrors'

import './styles.less'

const CustomRepeatDaysOfWeek = ({ settings, setSettings, errors }) => {
  const _handleChange = (selectedDay) => {
    const isAlreadySelected = (settings.SelectedDaysOfWeek & selectedDay) === selectedDay

    setSettings({
      ...settings,
      SelectedDaysOfWeek: isAlreadySelected
        ? settings.SelectedDaysOfWeek - selectedDay
        : settings.SelectedDaysOfWeek + selectedDay,
    })
  }

  const error = errors.find((e) => e === ReminderError.DayOfWeekNotSelected)

  return (
    <div className='reminder-custom-repeat-days'>
      <span className='reminder-custom-repeat-days-label'>Repeat on</span>
      <div className='reminder-custom-repeat-days-list'>
        {Object.keys(DaysOfWeek).map((key) => {
          const firstChar = key.charAt(0)
          const selectedDay = DaysOfWeek[key]
          const isSelected = (settings.SelectedDaysOfWeek & selectedDay) === selectedDay

          return (
            <div onClick={() => _handleChange(selectedDay)} title={key}>
              <Avatar className={classNames('reminder-custom-repeat-day', { selected: isSelected })}>
                {firstChar}
              </Avatar>
            </div>
          )
        })}
      </div>
      {error && <span className='reminder-error'>{error}</span>}
    </div>
  )
}

CustomRepeatDaysOfWeek.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
}

export default CustomRepeatDaysOfWeek
