import { TabPanel } from '@tabeeb/enums'

const selector = {
  getActivitiesTake: (state) => state.activities.activitiesList.activitiesTake,
  getActivitiesSkip: (state) => state.activities.activitiesList.activitiesSkip,
  getContentHeight: (state) => state.activities.activitiesList.contentHeight,
  getFilterOptions: (state) => state.activities.activitiesFilter,
  getShouldRequestSend: (state) => state.activities.shouldRequestSend,
  getSearchInProgress: (state) => state.activities.activitiesSearchInProgress.searchInProgress,
  getActivitiesPanelIsOpen: (state) => state.navbar.currentPanel.left === TabPanel.Activities,
}

export default selector
