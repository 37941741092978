import { handleActions } from 'redux-actions'

import { PageMode } from '../enums'

import { setPageMode, resetState } from '../actions'

const defaultState = PageMode.MySessions

export default handleActions(
  {
    [setPageMode](state, { payload: mode }) {
      return mode
    },
    [resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
