function optionsAreEqual(prevArray, nextArray) {
  let prev = prevArray
  let next = nextArray
  if (!prev) prev = []
  if (!next) next = []

  if (prev.length === 0 && next.length === 0) return true
  if (prev.length !== next.length) return false
  return prev.every((optionId) => next.includes(optionId))
}

export function answersValuesAreEqual(prevAnswer, newAnswer) {
  const optionsAreEquals = optionsAreEqual(prevAnswer.selectedOptionsIds, newAnswer.selectedOptionsIds)

  return newAnswer.value === prevAnswer.value && optionsAreEquals
}

export function answersPropsAreEqual(prevAnswer, newAnswer) {
  const equalValues = answersValuesAreEqual(prevAnswer, newAnswer)

  if (!equalValues) return false

  const statusesEqual = prevAnswer?.FormAnswerStatus === newAnswer?.FormAnswerStatus
  const statusesDatesEqual = prevAnswer?.FormAnswerStatusCreatedOn === newAnswer?.FormAnswerStatusCreatedOn

  return statusesEqual && statusesDatesEqual
}
