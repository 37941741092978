import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { contentViewerSelectors } from '@tabeeb/modules/contentViewer'

import AnnotationContainer from './AnnotationContainer'
import Text from '../components/Text'
import TextAnnotationEditor from '../components/TextAnnotationEditor'

import * as annotationActions from '../actions'
import * as annotationsSelectors from '../selectors'

class TextContainer extends AnnotationContainer {
  constructor(props) {
    super(props)

    this.hoverProps = {
      onMouseEnter: this._handleMouseEnter,
      onMouseLeave: this._handleMouseLeave,
    }

    this.selectionProps = {
      onClick: this._handleSelect,
      onDblClick: this._handleDoubleClick,
    }

    this.contextMenuProps = {
      onContextMenu: this._handleContextMenu,
    }

    this.dragAndDropProps = {
      draggable: true,
      onDragStart: this._handleDragStart,
      onDragEnd: this._handleDragEnd,
    }
  }

  _handleDragEnd = (e) => {
    const { target } = e
    const { annotation } = this.state

    const newAnnotation = {
      ...annotation,
      TopLeft: {
        X: target.x(),
        Y: target.y(),
      },
    }
    this.setState({ annotation: newAnnotation })
    this._handleEndUpdate(e)
  }

  _handleDoubleClick = (e) => {
    const { annotation } = this.state
    const {
      annotationActions: { openTextAnnotationEditor },
    } = this.props

    openTextAnnotationEditor({ annotationId: annotation.Id })
  }

  _handleSubmit = (text) => {
    const { annotation } = this.state
    const {
      annotationActions: { deleteContentAnnotations, closeTextAnnotationEditor },
    } = this.props

    if (text === '') {
      deleteContentAnnotations({ annotationIds: [annotation.Id] })
      closeTextAnnotationEditor()
      return
    }

    if (text === annotation.Text) {
      closeTextAnnotationEditor()
      return
    }

    this._handleStartUpdate()

    const newAnnotation = {
      ...annotation,
      Text: text,
    }

    this.setState({ annotation: newAnnotation })
    this._handleEndUpdate()

    closeTextAnnotationEditor()
  }

  render() {
    const { isEditing, opacity } = this.props
    const { hovered, selected, annotation } = this.state

    let position = null
    if (isEditing) {
      position = this.getElementStagePosition()
    }

    const props = {
      annotation,
      annotationRef: this.annotationRef,
      opacity,
      hovered,
      selected,
      visible: !isEditing,
      hoverProps: this.hoverProps,
      selectionProps: this.selectionProps,
      contextMenuProps: this.contextMenuProps,
      dragAndDropProps: this.dragAndDropProps,
    }

    return (
      <>
        {isEditing && position && (
          <TextAnnotationEditor annotation={annotation} x={position.x} y={position.y} onSubmit={this._handleSubmit} />
        )}
        <Text {...props} />
      </>
    )
  }
}

TextContainer.propTypes = {
  currentAnnotation: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, { Id }) => {
  return {
    currentAnnotation: annotationsSelectors.getAnnotationById(state, { Id }),
    contentSize: contentViewerSelectors.getContentSize(state),
    scale: contentViewerSelectors.getScale(state),
    contentScale: contentViewerSelectors.getContentScale(state),
    isEditing: annotationsSelectors.getTextAnnotationEditorAnnotationId(state) === Id,
    opacity: annotationsSelectors.getAnnotationOpacity(state, { Id }),
    selected: annotationsSelectors.getIsAnnotationSelected(state, { Id }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    annotationActions: bindActionCreators(annotationActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextContainer)
