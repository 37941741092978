export const sortingOrders = {
  ascending: 'asc',
  descending: 'desc',
  default: 'asc',
}

export const fetchingStatus = {
  idle: 'idle',
  loading: 'loading',
  succeeded: 'succeeded',
  failed: 'failed',
}
