import { createSelector } from 'reselect'

import { FolderPermission } from '@tabeeb/enums'
import { hasFolderPermission } from '../helpers'
import { FolderAction } from '../enums'

export const getFolderActions = (state) => state.sessionsPage.configuration.folderActions
export const getIsFolderActionEnabled = createSelector([getFolderActions, (_, action) => action], (actions, action) => {
  return actions.includes(action)
})

export const getIsDeleteFolderActionEnabled = (state) => getIsFolderActionEnabled(state, FolderAction.Delete)
export const getIsLeaveFolderActionEnabled = (state) => getIsFolderActionEnabled(state, FolderAction.Leave)
export const getIsRestoreFolderActionEnabled = (state) => getIsFolderActionEnabled(state, FolderAction.Restore)
export const getIsShareFolderActionEnalbed = (state) => getIsFolderActionEnabled(state, FolderAction.Share)

export const getCanAddFolderUsers = createSelector(
  [getIsShareFolderActionEnalbed, (_, folder) => folder],
  (isActionEnabled, folder) => {
    return isActionEnabled && hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.AddUsers)
  }
)

export const getCanDeleteFolder = createSelector(
  [getIsDeleteFolderActionEnabled, (_, folder) => folder],
  (isActionEnabled, folder) => {
    return (
      isActionEnabled &&
      (folder.IsDeleted || hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.DeleteFolder))
    )
  }
)

export const getCanEditFolderUsers = createSelector(
  [getIsShareFolderActionEnalbed, (_, folder) => folder],
  (isActionEnabled, folder) => {
    return isActionEnabled && hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.EditUsers)
  }
)

export const getCanRestoreFolder = createSelector(
  [getIsRestoreFolderActionEnabled, (_, folder) => folder],
  (isActionEnabled, folder) => {
    return isActionEnabled && folder.IsDeleted
  }
)

export const getCanLeaveFolder = createSelector(
  [getIsLeaveFolderActionEnabled, (_, folder) => folder],
  (isActionEnabled, folder) => {
    return isActionEnabled && hasFolderPermission(folder.FolderUsersPermissions, FolderPermission.LeaveFolder)
  }
)
