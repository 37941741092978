import { memo } from 'react'
import { useSelector } from 'react-redux'

import { IconButton, Tooltip } from '@mui/material'
import { StorageOutlined } from '@mui/icons-material'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { DialogTypes } from '@tabeeb/modules/sessionsPage/enums'
import { usePageDialog } from '@tabeeb/modules/sessionsPage/hooks'
import { getHasSwitchStoragePermission } from '@tabeeb/modules/sessionsPage/selectors/permissions'

const SwitchStorageButton = () => {
  const { onOpen } = usePageDialog(DialogTypes.SwitchStorage)

  const isVisible = useSelector(getHasSwitchStoragePermission)
  if (!isVisible) {
    return null
  }

  return (
    <Tooltip title={sessionTitleFormatter.format('Switch storage of selected sessions')}>
      <IconButton size='small' onClick={onOpen}>
        <StorageOutlined />
      </IconButton>
    </Tooltip>
  )
}

export default memo(SwitchStorageButton)
