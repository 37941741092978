import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = -1

export default handleActions(
  {
    [rawActions.openAttachAiModelDialog](state, action) {
      return action.payload.AIModelType
    },
    [combineActions(rawActions.addPredictionSet.request, rawActions.resetState, rawActions.closeAttachModelDialog)]() {
      return defaultState
    },
  },
  defaultState
)
