import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PersonAdd } from '@material-ui/icons'
import { Button, withStyles } from '@material-ui/core'

import { SessionPermission } from '@tabeeb/enums'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { useDialogState } from '@tabeeb/shared/utils/hooks'

import InviteUsersDialog from '../../../modules/invites/components/InviteUsersDialog'

import styles from './styles'

const InviteButton = ({ classes }) => {
  const [open, onOpen, onClose] = useDialogState()

  const canInviteUsers = useSelector((state) =>
    permissionsSelectors.hasSessionPermission(state, SessionPermission.InviteUsers)
  )

  if (!canInviteUsers) {
    return null
  }

  return (
    <>
      <Button className={classes.icon} title='Invite users' onClick={onOpen}>
        <PersonAdd />
      </Button>
      <InviteUsersDialog open={open} onClose={onClose} />
    </>
  )
}

InviteButton.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(InviteButton))
