import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DistanceAnnotation } from '@tabeeb/shared/icons'

import { AnnotationType } from '@tabeeb/enums'

import {
  onChangePressedButton,
  onDisableDrawing,
  onEnableDrawing,
  onSetDrawingType,
} from '@tabeeb/modules/playerToolbar/actions/drawing'
import { getSelectedColor, getIsButtonSelected } from '@tabeeb/modules/playerToolbar/selectors'

import { getSelectedGalleryItemType } from '@tabeeb/modules/gallery/selectors'

import { isSupportMeasurements } from '@tabeeb/services/pageService'

import ToolbarButton from '../ToolbarButton'

const BUTTON_NAME = 'distance-measurements'

const DistanceMeasurementsButton = () => {
  const dispatch = useDispatch()

  const display = useSelector((state) => isSupportMeasurements(getSelectedGalleryItemType(state)))
  const color = useSelector(getSelectedColor)
  const selected = useSelector((state) => getIsButtonSelected(state, BUTTON_NAME))

  const onClick = () => {
    if (selected) {
      dispatch(onChangePressedButton({ pressedButton: '' }))
      dispatch(onDisableDrawing())
    } else {
      dispatch(onSetDrawingType(AnnotationType.Distance))
      dispatch(onChangePressedButton({ pressedButton: BUTTON_NAME }))
      dispatch(onEnableDrawing())
    }
  }

  return (
    <ToolbarButton
      display={display}
      Icon={DistanceAnnotation}
      onClick={onClick}
      title='Measure distance'
      htmlColor={selected ? color : undefined}
      selected={selected}
    />
  )
}

export default memo(DistanceMeasurementsButton)
