import { DaysOfWeek, ReminderCustomRepeatEndOnType, ReminderCustomRepeatMode } from '@tabeeb/enums'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function getDefaultReminderDayOfWeek() {
  const todayDayOfWeek = new Date().getDay()

  switch (todayDayOfWeek) {
    case 0:
      return DaysOfWeek.Sunday
    case 1:
      return DaysOfWeek.Monday
    case 2:
      return DaysOfWeek.Tuesday
    case 3:
      return DaysOfWeek.Wednesday
    case 4:
      return DaysOfWeek.Thursday
    case 5:
      return DaysOfWeek.Friday
    default:
      return DaysOfWeek.Saturday
  }
}

export const defaultCustomRepeatSettings = {
  Mode: ReminderCustomRepeatMode.Day,
  Interval: 1,
  SelectedDaysOfWeek: getDefaultReminderDayOfWeek(),
  EndType: ReminderCustomRepeatEndOnType.Never,
  EndDate: dayjs().add(7, 'd').utc().second(0).format(), // a week from now
  EndOccurrences: 20,
  IsDefault: true,
}
