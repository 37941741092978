import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      flexGrow: 1,
      height: '100%',
      overflowY: 'auto',
      position: 'relative',
    },

    list: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      marginBottom: 0,
      padding: theme.spacing(),
      paddingBottom: 0,
      overflowY: 'auto',
      overflowX: 'hidden',
      direction: 'ltr',

      '& > li': {
        marginTop: theme.spacing(),
      },
    },
    bottomBar: {
      paddingTop: theme.spacing(),
    },
  }
})
