const aiReportRequests = {
  getAiObjectAnalysisRequest: ({ contentId, aiObjectId, columns, search, searchFields }) => ({
    url: `api/uniqueAIObjectAnalysis/content/${contentId}/AIObject/${aiObjectId}`,
    method: 'post',
    data: { columns, search, searchFields },
  }),
  getAiReportPublishNameSettingsRequest: () => ({
    url: `tenant-service/api/config/aiReport/publishName`,
    method: 'get',
  }),
  getPublishTemplatesRequest: ({ aiObjectId }) => ({
    url: `api/uniqueAIObjectAnalysisTemplate/AiObject/${aiObjectId}`,
    method: 'get',
  }),
  createPublishTemplateRequest: ({ template }) => ({
    url: `api/uniqueAIObjectAnalysisTemplate/`,
    method: 'post',
    data: template,
  }),
  updatePublishTemplateRequest: ({ template }) => ({
    url: `api/uniqueAIObjectAnalysisTemplate/${template.id}`,
    method: 'put',
    data: { name: template.name, columns: template.columns },
  }),
  deletePublishTemplateRequest: ({ id }) => ({
    url: `api/uniqueAIObjectAnalysisTemplate/${id}`,
    method: 'delete',
  }),
  publishAiReportRequest: ({ contentId, aiObjectId, templateId, name, rewrite }) => ({
    url: `api/uniqueAIObjectAnalysis/content/${contentId}/AIobject/${aiObjectId}/publish`,
    method: 'post',
    data: { templateId: templateId === 0 ? null : templateId, name, rewrite },
  }),
  getDefaultPublishNameRequest: ({ aiObjectId }) => ({
    url: `api/uniqueAIObjectAnalysis/AIobject/${aiObjectId}/publishName`,
    method: 'get',
  }),
  deleteUniqueAiObjectFromContentRequest: ({ contentId, uniqueAIObjectId }) => ({
    url: `api/uniqueAIObjectAnalysis/content/${contentId}/uniqueAIObject/${uniqueAIObjectId}`,
    method: 'delete',
  }),
  updateUniqueAiObjectFromJsonRequest: (data) => ({
    url: `api/uniqueAIObjectAnalysis/uniqueAiObject/${data.Id}`,
    method: 'put',
    data,
  }),
  getUniqueAiObjectPagesRequest: ({ uniqueAIObjectId, contentId }) => ({
    url: `api/pages/uniqueObjects/${uniqueAIObjectId}/contentId/${contentId}`,
    method: 'get',
  }),
}

export default aiReportRequests
