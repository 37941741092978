const metricsRequests = {
  getMetricsByIterationIdRequest: (iterationId) => ({
    url: `api/iterationMetrics/iteration/${iterationId}`,
    method: 'get',
  }),
  downloadIterationMetricsRequest: (iterationId) => ({
    url: `api/iterationMetrics/iteration/${iterationId}/download`,
    method: 'post',
  }),
}

export default metricsRequests
