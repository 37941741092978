import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import { getCurrentUserSignature } from '@tabeeb/modules/account/selectors'
import { signForm } from '@tabeeb/modules/forms/actions'
import ManageSignatureDialog from './ManageSignatureDialog'

const Signature = () => {
  const dispatch = useDispatch()
  const [isOpen, onOpen, onClose] = useDialogState()
  const signature = useSelector(getCurrentUserSignature)

  const _handleClick = () => {
    if (signature) {
      dispatch(signForm())
    } else {
      onOpen()
    }
  }

  return (
    <>
      <Button variant='outlined' onClick={_handleClick}>
        Sign form
      </Button>
      {isOpen && <ManageSignatureDialog isOpen={isOpen} onClose={onClose} />}
    </>
  )
}

export default memo(Signature)
