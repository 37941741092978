import { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'

import { IconButton, InputAdornment } from '@mui/material'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'

import { StyledInput } from '../../SearchInput/styles'

const FilterOptionsSelect = forwardRef(({ values, options, onClick }, ref) => {
  return (
    <StyledInput
      ref={ref}
      sx={{ cursor: 'pointer', maxWidth: '100%' }}
      disableUnderline
      onClick={onClick}
      fullWidth
      value={
        options.length === values.length
          ? 'All'
          : options
              .filter((option) => values.includes(option.value))
              .map((option) => option.name)
              .join(', ')
      }
      endAdornment={
        <InputAdornment position='end'>
          <IconButton size='small' edge='end' onClick={onClick}>
            <KeyboardArrowDownOutlined />
          </IconButton>
        </InputAdornment>
      }
    />
  )
})

FilterOptionsSelect.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(FilterOptionsSelect)
