import { all } from 'redux-saga/effects'

import errors from './errors'
import signalr from './signalr'
import synchronization from './synchronization'
import view from './view'

function* calendarSagas() {
  yield all([errors(), signalr(), synchronization(), view()])
}

export default calendarSagas
