const styles = (theme) => {
  return {
    header: {
      width: '100%',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 24,
      color: theme.palette.grey[700],
    },
    body: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      height: '55px',

      '&:hover': {
        backgroundColor: theme.palette.accent.pressed,
      },
    },

    saveButton: {
      margin: 'auto 0 0',
    },

    permissionsContainer: {
      flex: 1,
      margin: theme.spacing(3, 0),
    },
  }
}

export default styles
