import { handleActions, combineActions } from 'redux-actions'

import {
  setPageNumber,
  setPageSize,
  openFolder,
  resetState,
  resetFilters,
  updateReviewStatusFilter,
  updateSessionStatusFilter,
} from '../../actions'

const defaultState = 1

export default handleActions(
  {
    [setPageNumber](state, action) {
      return action.payload
    },
    [combineActions(
      openFolder,
      setPageSize,
      resetState,
      resetFilters,
      updateReviewStatusFilter,
      updateSessionStatusFilter
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
