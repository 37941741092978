import { Action, ActionFunction1, createAction } from 'redux-actions'

export interface ApiResponseAction<TPayload, TResponse> extends Action<TPayload> {
  response: {
    status: number
    data: TResponse
  }
}

export const createApiActions = <TPayload, TResponse>(name: string) => {
  const request = createAction<TPayload>(`${name}_REQUEST`)
  const success = createAction<TPayload>(`${name}_SUCCESS`) as ActionFunction1<
    TPayload,
    ApiResponseAction<TPayload, TResponse>
  >
  const failed = createAction<TPayload>(`${name}_FAILED`) as ActionFunction1<
    TPayload,
    ApiResponseAction<TPayload, TResponse>
  >
  const cancel = createAction(`${name}_CANCEL`)
  const resetState = createAction(`RESET_STATE_${name}`)

  return {
    type: name,
    request,
    success,
    failed,
    cancel,
    resetState,
  }
}
