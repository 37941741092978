export const AutofillSource = {
  None: 0,
  Height: 1,
  Width: 2,
  Depth: 3,
  Azimuth: 4,
  RollAngle: 5,
  Elevation: 6,
  PitchAngle: 7,
  HorizontalOffset: 8,
  VerticalOffset: 9,
  YawAngle: 10,
}

export const AutofillSourceDisplayName = {
  [AutofillSource.None]: 'None',
  [AutofillSource.Height]: 'Length',
  [AutofillSource.Width]: 'Width',
  [AutofillSource.Depth]: 'Depth',
  [AutofillSource.YawAngle]: 'Z / Yaw angle',
  [AutofillSource.RollAngle]: 'X / Roll / Tilt angle',
  [AutofillSource.Elevation]: 'Elevation',
  [AutofillSource.PitchAngle]: 'Y / Pitch Angle',
  [AutofillSource.HorizontalOffset]: 'Horizontal Offset',
  [AutofillSource.VerticalOffset]: 'Vertical Offset',
  [AutofillSource.Azimuth]: 'Azimuth',
}
