import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_STRUCTURE_MODEL_STATE')

export const addStructureModel = createAction('ADD_STRUCTURE_MODEL')
export const addStructureModelRequest = createAction('ADD_STRUCTURE_MODEL_REQUEST')
export const addStructureModelSuccess = createAction('ADD_STRUCTURE_MODEL_SUCCESS')
export const addStructureModelFailed = createAction('ADD_STRUCTURE_MODEL_FAILED')

export const openStructureModelDialog = createAction('OPEN_STRUCTURE_MODEL_DIALOG')
export const closeStructureModelDialog = createAction('CLOSE_STRUCTURE_MODEL_DIALOG')

export const addImageToStructureModel = createAction('ADD_IMAGE_TO_STRUCTURE_MODEL')
export const addAllPagesToStructureModel = createAction('ADD_ALL_PAGES_TO_STRUCTURE_MODEL')
export const deleteImageFromStructureModel = createAction('DELETE_IMAGE_FROM_STRUCTURE_MODEL')
export const updateImagesStructureModel = createAction('UPDATE_IMAGES_STRUCTURE_MODEL')

export const stopStructureModelProcessing = createAction('STOP_STRUCTURE_MODEL_PROCESSING')
