import { ReminderType, ReminderRepeatMode } from '@tabeeb/enums'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function getDefaultReminderSettings(type, entity) {
  let defaultSettings = {
    Type: type,
    Users: [],
    StartDate: dayjs().add(5, 'm').utc().second(0).format(),
    RepeatMode: ReminderRepeatMode.NoRepeat,
    CustomRepeatSettings: null,
  }

  switch (type) {
    case ReminderType.AnswerFormQuestion:
      defaultSettings = { ...defaultSettings, FormControlId: entity.Id }
      break
    default:
      break
  }

  return defaultSettings
}
