const sncFiltersRequests = {
  getTenantSncQuestionsRequest: (data) => ({
    url: `api/contents/tenant/questions`,
    method: 'get',
    params: data,
  }),
  getSncAnswersRequest: (data) => ({
    url: `api/contents/tenant/answers`,
    method: 'get',
    params: data,
  }),
}

export default sncFiltersRequests
