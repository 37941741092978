import { put, take, call } from 'redux-saga/effects'
import jitsiCommandHandler from '../jitsiCommandHandler'

function* bindJitsiCommand(target, commandName, callback, jitsiCommandChannels) {
  const channel = yield call(jitsiCommandHandler, target, commandName)
  jitsiCommandChannels.push(channel)
  try {
    while (true) {
      const { event } = yield take(channel)
      let payload = {}

      switch (event.length) {
        case 0:
          payload = {}
          break
        case 1:
          payload = event[0]
          break
        default:
          payload = Array.isArray(event) ? event.slice(0, event.length - 2) : event
          break
      }
      yield put(callback(payload))
    }
  } finally {
    channel.close()
    jitsiCommandChannels.splice(jitsiCommandChannels.indexOf(channel), 1)
  }
}

export default bindJitsiCommand
