import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FlashOffOutlined, FlashOnOutlined } from '@material-ui/icons'

import { DeviceType } from '@tabeeb/enums'

import { usersSelectors } from '@tabeeb/modules/../users'
import { presentationSelectors } from '@tabeeb/modules/presentation'

import ToolbarButton from '@tabeeb/modules/playerToolbar/components/ToolbarButton'
import { onFlashTurnOn } from '../../../presentationToolbar/actions/flash'

const FlashButton = () => {
  const dispatch = useDispatch()

  const enabled = useSelector((state) => state.presentationToolbar.flashValue)
  const flashInProgress = useSelector((state) => state.presentationToolbar.flashInProgress)

  const onClick = useCallback(() => {
    if (flashInProgress === true) {
      return
    }

    dispatch(onFlashTurnOn(!enabled))
  }, [dispatch, flashInProgress, enabled])

  const selectedUser = useSelector(presentationSelectors.getSelectedUser)
  const user = useSelector((state) => usersSelectors.getUserById(state, { Id: selectedUser?.id }))

  if (!selectedUser || !user) {
    return null
  }

  const isMobileUser =
    selectedUser.deviceType === DeviceType.androidDevice ||
    selectedUser.deviceType === DeviceType.appleDevice ||
    selectedUser.deviceType === DeviceType.androidBrowser ||
    selectedUser.deviceType === DeviceType.iosBrowser

  if (!isMobileUser) {
    return null
  }

  const title =
    selectedUser.deviceType === DeviceType.iosBrowser
      ? 'This device does not support flashlight feature'
      : enabled
      ? 'Disable flash'
      : 'Enable flash'

  return (
    <ToolbarButton
      Icon={enabled ? FlashOnOutlined : FlashOffOutlined}
      title={title}
      selected={enabled}
      onClick={onClick}
      disabled={selectedUser.deviceType === DeviceType.iosBrowser}
    />
  )
}

export default memo(FlashButton)
