const selectionListsRequests = {
  getSelectionListsRequest: (data) => ({
    url: `ai-service/api/selection-lists`,
    method: 'get',
  }),
  getSelectionListByIdRequest: ({ selectionListId }) => ({
    url: `ai-service/api/selection-lists/${selectionListId}`,
    method: 'get',
  }),
  getSelectionListForEditByIdRequest: ({ selectionListId }) => ({
    url: `ai-service/api/selection-lists/${selectionListId}`,
    method: 'get',
  }),
  createSelectionListRequest: (data) => ({
    url: `ai-service/api/selection-lists`,
    method: 'post',
    data,
  }),
  updateSelectionListRequest: (data) => ({
    url: `ai-service/api/selection-lists/${data.Id}`,
    method: 'put',
    data,
  }),
  deleteSelectionListRequest: ({ id }) => ({
    url: `ai-service/api/selection-lists/${id}`,
    method: 'delete',
  }),
  getSelectionListItemsRequest: ({ selectionListId }) => ({
    url: `ai-service/api/selection-list-items/${selectionListId}`,
    method: 'get',
  }),
}

export default selectionListsRequests
