import { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Group, Line, Path } from 'react-konva'

import { getHighlightedColor } from '../../services/colorsService'

const hotspotIcon =
  'M 15.957031 31.679688 C 15.957031 31.679688 27.761719 18.925781 27.761719 12.34375 C 27.761719 5.714844 22.464844 0.320312 15.957031 0.320312 C 9.492188 0.320312 4.238281 5.714844 4.238281 12.34375 C 4.238281 16.777344 9.582031 24 12.992188 28.203125 Z M 15.957031 8.273438 C 18.109375 8.273438 19.859375 10.101562 19.859375 12.34375 C 19.859375 14.445312 18.109375 16.273438 15.957031 16.273438 C 13.847656 16.273438 12.007812 14.449219 12.007812 12.34375 C 12.007812 10.101562 13.847656 8.273438 15.957031 8.273438 Z M 15.957031 8.273438'

const Hotspot = ({
  annotation,
  annotationRef,
  opacity,
  contentScale,
  hovered,
  hoverProps,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
}) => {
  const position = Hotspot.getXY(annotation)
  const color = selected || hovered ? getHighlightedColor(annotation.Color) : annotation.Color

  return (
    <>
      {annotation.ParentAnnotationId && (
        <Line
          lineCap='round'
          lineJoin='round'
          listening={false}
          strokeWidth={1}
          stroke={annotation.Color}
          strokeScaleEnabled={false}
          opacity={opacity}
          points={[0, 0, position.x, position.y]}
        />
      )}
      <Group
        id={annotation.Id}
        ref={annotationRef}
        opacity={opacity}
        {...position}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
        {...dragAndDropProps}
      >
        <Path
          fill={color}
          data={hotspotIcon}
          x={-20 / contentScale}
          y={-38 / contentScale}
          scaleX={1.25 / contentScale}
          scaleY={1.25 / contentScale}
        />
      </Group>
    </>
  )
}

Hotspot.getXY = (annotation) => ({
  x: annotation.Anchor.X,
  y: annotation.Anchor.Y,
})

Hotspot.getSize = (annotation) => {
  return {
    height: 0,
    width: 0,
  }
}

Hotspot.getViewBox = (annotation) => {
  const { x, y } = Hotspot.getXY(annotation)

  return {
    topLeftX: x,
    topLeftY: y,
    bottomRightX: x,
    bottomRightY: y,
  }
}

Hotspot.propTypes = {
  contentScale: PropTypes.number.isRequired,
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    ParentAnnotationId: PropTypes.number,
    Color: PropTypes.string.isRequired,
    Anchor: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
    }),
    Width: PropTypes.number.isRequired,
  }).isRequired,
  annotationRef: PropTypes.func,
  opacity: PropTypes.number,
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragMove: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
}

export default Hotspot
