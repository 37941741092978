import { memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { Chat } from '@material-ui/icons'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { PageDiscussionDialog } from '@tabeeb/modules/discussion'

import PageInfoControl from '../PageInfoControl'

const PageDiscussionButton = () => {
  const [{ opened, pageId }, setDiscussionState] = useState({ opened: false, pageId: null })

  const selectedPageId = useSelector(gallerySelectors.getSelectedGalleryItemId)

  const onOpen = useCallback(() => {
    setDiscussionState({ opened: true, pageId: selectedPageId })
  }, [selectedPageId])

  const onClose = useCallback(() => {
    setDiscussionState(({ pageId }) => ({ opened: false, pageId }))
  }, [])

  return (
    <>
      <PageInfoControl icon={Chat} title='Open page discussion' onClick={onOpen} />
      <PageDiscussionDialog isOpen={opened} pageId={pageId} onClose={onClose} />
    </>
  )
}

PageDiscussionButton.propTypes = {}

export default memo(PageDiscussionButton)
