import { Paper, styled } from '@mui/material'

export const Container = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.session,
  color: theme.palette.text.session,
  padding: theme.spacing(0.5),
  paddingInline: theme.spacing(0.75),
  cursor: 'pointer',
  minWidth: 0,
}))
