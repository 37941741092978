import { call, put, take, takeEvery, select, all, fork } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import _ from 'lodash'

import ai from '@tabeeb/services/telemetryService'
import { uploadFile } from '@tabeeb/services/uploadService'
import { isHeicHeifImage, isImage } from '@tabeeb/modules/fileUploads/services'
import { rotateImage, convertHeicHeifFileToJpeg, getFileMetaInfo } from '@tabeeb/modules/gallery/services/fileMetaInfo'

import * as fileUploadsActions from '@tabeeb/modules/fileUploads/actions'

import { getFileUploads } from '@tabeeb/modules/fileUploads/selectors'

import { FileUploadStatus } from '@tabeeb/modules/fileUploads/constants'
import { UploadContentType } from '@tabeeb/enums'

async function uploadPageFile({ file, contentId, uploadContentType, abortController }) {
  if (file.size === 0) {
    throw new Error(`File '${file.name}' has zero size and cannot be uploaded`)
  }

  const { url: downloadUrl } = await uploadFile({
    file,
    contentId,
    abortController,
    uploadContentType,
  })

  return downloadUrl
}

function uploadFilesConcurrently({ files, contentId, uploadContentType }) {
  return eventChannel((emitter) => {
    let uploadedFiles = 0

    const CHUNK_SIZE = 64

    const chunks = _.chunk(files, CHUNK_SIZE)

    const processChunk = async (chunk) => {
      const uploadTasks = []
      for (const { file, abortController } of chunk) {
        let fileToProcess = file

        if (isImage(fileToProcess)) {
          let meta = null
          try {
            meta = await getFileMetaInfo(fileToProcess)
          } catch (e) {
            console.error(e)
          }

          if (meta) {
            const needsRotation = meta != null && meta.orientation && meta.orientation !== 1
            const needsConvertation = isHeicHeifImage(file)

            if (needsConvertation) {
              fileToProcess = await convertHeicHeifFileToJpeg(fileToProcess)
            }

            if (needsRotation) {
              fileToProcess = await rotateImage(fileToProcess)
            }
          }
        }

        const uploadTask = uploadPageFile({
          file: fileToProcess,
          contentId,
          uploadContentType,
          abortController,
        })
          .then((downloadUrl) => {
            emitter({ file, downloadUrl, status: FileUploadStatus.Done })
          })
          .catch((error) => {
            if (error?.name !== 'AbortError') {
              ai.appInsights?.trackException({ exception: error, properties: { featureId: 'watchUploadFiles' } })
            }

            emitter({
              file,
              downloadUrl: null,
              status: error?.name === 'AbortError' ? FileUploadStatus.Canceled : FileUploadStatus.Failed,
              error,
            })
          })
          .finally(() => {
            uploadedFiles++
            if (uploadedFiles === files.length) {
              emitter(END)
            }
          })

        uploadTasks.push(uploadTask)
      }

      await Promise.all(uploadTasks)
    }

    // Sequentially process each chunk
    ;(async function processChunksSequentially() {
      for (const chunk of chunks) {
        await processChunk(chunk)
      }
    })()

    return () => {}
  })
}

function* openUploadingThread({
  files,
  contentId,
  controlId,
  action,
  ignoreFileUploads,
  successPayload,
  failedPayload,
  allFilesLength,
  uploadContentType,
}) {
  const completedType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILES_COMPLETED')
  const successType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILES_SUCCESS')
  const failedType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILES_FAILED')

  const singleFileSuccessType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILE_SUCCESS')
  const singleFileCancelType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILE_CANCEL')
  const singleFileFailedType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILE_FAILED')

  let notCancelledFiles = files
  if (!ignoreFileUploads) {
    const fileUploads = yield select(getFileUploads)
    notCancelledFiles = files.filter((fileToUpload) => {
      const fileUpload = fileUploads.find((item) => item.file === fileToUpload.file)
      return !fileUpload || fileUpload.status !== FileUploadStatus.Canceled
    })
  }

  const filesEventsChannel = yield call(uploadFilesConcurrently, {
    files: notCancelledFiles,
    contentId,
    uploadContentType,
  })

  yield fork(function* () {
    yield take(fileUploadsActions.clearFileUploads)
    filesEventsChannel.close()
    if (allFilesLength !== successPayload.length + failedPayload.length) {
      const actions = []
      if (successPayload.length > 0) {
        actions.push(put({ type: successType, payload: successPayload }))
      }

      if (failedPayload.length > 0) {
        actions.push(put({ type: failedType, payload: failedPayload }))
      }
      yield all(actions)

      yield put({ type: completedType })
    }
  })

  try {
    while (true) {
      const fileEvent = yield take(filesEventsChannel)
      const { file, downloadUrl, status, error } = fileEvent
      if (downloadUrl) {
        successPayload.push({ file, url: downloadUrl, controlId })
        yield put({
          type: singleFileSuccessType,
          payload: { file, url: downloadUrl, controlId, value: 100 },
        })
        if (!ignoreFileUploads) {
          yield put(fileUploadsActions.updateFileUploads([{ file, url: downloadUrl, value: 100, status }]))
        }
      } else {
        failedPayload.push({ file, url: downloadUrl, error })
        yield put({
          type: status === FileUploadStatus.Canceled ? singleFileCancelType : singleFileFailedType,
          payload: { file, url: downloadUrl, error },
        })
        if (!ignoreFileUploads) {
          yield put(fileUploadsActions.updateFileUploads([{ file, status }]))
        }
      }
    }
  } finally {
    if (allFilesLength === successPayload.length + failedPayload.length) {
      const actions = []
      if (successPayload.length > 0) {
        actions.push(put({ type: successType, payload: successPayload }))
      }

      if (failedPayload.length > 0) {
        actions.push(put({ type: failedType, payload: failedPayload }))
      }
      yield all(actions)

      yield put({ type: completedType })
    }
  }
}

function* uploadFiles(action) {
  const successPayload = []
  const failedPayload = []

  let { files = [] } = action.payload

  const {
    controlId = null,
    ignoreFileUploads = false,
    retry,
    contentId = null,
    uploadContentType = UploadContentType.Page,
  } = action.payload

  if (!Array.isArray(files)) {
    files = [...files]
  }

  const AbortController = yield call(() => import('@azure/abort-controller').then((i) => i.AbortController))

  const filesWithAbortControllers = files.map((file) => {
    const abortController = new AbortController()
    return { file, abortController }
  })

  if (!ignoreFileUploads) {
    const fileUploads = yield select(getFileUploads)
    yield put(fileUploadsActions.clearSuccessFileUploads())
    yield put(
      fileUploadsActions.addFileUploads(
        filesWithAbortControllers.map(({ file, abortController }, index) => {
          const fileUpload = fileUploads.find((item) => item.file === file)
          return {
            file,
            controlId,
            uploadContentType,
            index,
            value: 0,
            abortController,
            status: !retry && fileUpload && fileUpload.status ? fileUpload.status : FileUploadStatus.Pending,
          }
        })
      )
    )
  }

  yield all([
    call(openUploadingThread, {
      files: filesWithAbortControllers,
      contentId,
      controlId,
      action,
      ignoreFileUploads,
      successPayload,
      failedPayload,
      allFilesLength: files.length,
      uploadContentType,
    }),
  ])
}

function cancelFileUploads({ payload }) {
  const filesToCancel = payload
  filesToCancel.forEach((fileToCancel) => {
    if (fileToCancel.abortController) {
      fileToCancel.abortController.abort()
    }
  })
}

export default function* watchUploadFiles() {
  yield all([
    takeEvery((action) => /^.*_UPLOAD_FILES$/.test(action.type), uploadFiles),
    takeEvery(fileUploadsActions.cancelFileUploads, cancelFileUploads),
  ])
}
