import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_REVIEW_SESSION_PAGES_STATE')

export const openReviewSessionPagesDialog = createAction('OPEN_REVIEW_SESSION_PAGES_DIALOG')
export const closeReviewSessionPagesDialog = createAction('CLOSE_REVIEW_SESSION_PAGES_DIALOG')

export const addPageForReviewing = createAction('ADD_PAGE_FOR_REVIEWING')
export const addAllPagesForReviewing = createAction('ADD_ALL_PAGES_FOR_REVIEWING')
export const deletePageForReviewing = createAction('DELETE_PAGE_FOR_REVIEWING')

export const addPagesByFormsForReviewing = createAction('ADD_PAGES_BY_FORMS_FOR_REVIEWING')

export const setPagesForReviewing = createAction('SET_PAGES_FOR_REVIEWING')
