import { handleActions } from 'redux-actions'

import { setSelectedModel } from '../actions'

const defaultState = {
  Id: 0,
  Url: '',
  Name: '',
}

export default handleActions(
  {
    [setSelectedModel](state, { payload }) {
      return payload
    },
  },
  defaultState
)
