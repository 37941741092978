import { isMobileOnly } from 'react-device-detect'
import { put, takeLatest, select, delay, all } from 'redux-saga/effects'

import { setSearchText, setPageNumber, setSessionsList } from '../actions'
import { getSearchQuery } from '../selectors/filtration'

function* updateGeneralFilter(action) {
  const { text } = action.payload
  yield delay(600)

  const searchText = yield select(getSearchQuery)
  if (searchText === text) {
    if (isMobileOnly) {
      yield put(setSessionsList([]))
    }

    yield put(setPageNumber(1))
  }
}

function* generalFilter() {
  yield all([yield takeLatest(setSearchText, updateGeneralFilter)])
}

export default generalFilter
