import PropTypes from 'prop-types'

import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { getEditedServerUtcDate, getUtcDate } from '@tabeeb/services/dateTimeService'

import { ReminderCustomRepeatEndOnType } from '@tabeeb/enums'
import classNames from 'classnames'
import { customRepeatEndModeOptions } from '../../services/repeatModeOptions'
import { ReminderError } from '../../services/reminderErrors'

import './styles.less'

const CustomRepeatEnd = ({ settings, setSettings, errors }) => {
  const _handleChangeType = (e) => {
    const value = parseInt(e.target.value, 10)
    setSettings({ ...settings, EndType: value })
  }

  const _handleChangeDate = (value) => {
    const utcDate = getUtcDate(value)
    setSettings({ ...settings, EndDate: utcDate })
  }

  const _handleChangeOccurNumber = (e) => {
    const value = parseInt(e.target.value, 10)
    setSettings({ ...settings, EndOccurrences: value })
  }

  const isEndDate = settings.EndType === ReminderCustomRepeatEndOnType.Date
  const isEndOccurrences = settings.EndType === ReminderCustomRepeatEndOnType.Occurrences

  const endDateError = errors.find((e) => e === ReminderError.InvalidEndDate)
  const occurrencesError = errors.find((e) => e === ReminderError.InvalidOccurrencesValue)

  const endDate = getEditedServerUtcDate(settings.EndDate)

  return (
    <div className='reminder-custom-repeat-end'>
      <span className='reminder-custom-repeat-end-label'>Ends</span>
      <RadioGroup value={settings.EndType} onChange={_handleChangeType}>
        {Object.keys(customRepeatEndModeOptions).map((key) => {
          const endMode = parseInt(key, 10)
          let error = null

          if (endMode === ReminderCustomRepeatEndOnType.Date) error = endDateError
          if (endMode === ReminderCustomRepeatEndOnType.Occurrences) error = occurrencesError

          return (
            <>
              <div className='reminder-custom-repeat-end-option'>
                <FormControlLabel
                  value={endMode}
                  control={<Radio color='primary' />}
                  label={customRepeatEndModeOptions[key]}
                />
                {endMode === ReminderCustomRepeatEndOnType.Date && (
                  <div
                    className={classNames('reminder-custom-repeat-end-date', {
                      'reminder-custom-repeat-end-date-disabled': !isEndDate,
                    })}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className='reminder-date-picker'
                        value={endDate}
                        onChange={_handleChangeDate}
                        format='MMMM d, yyyy'
                        disabled={!isEndDate}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                )}
                {endMode === ReminderCustomRepeatEndOnType.Occurrences && (
                  <div
                    className={classNames('reminder-custom-repeat-end-occur', {
                      'reminder-custom-repeat-end-occur-disabled': !isEndOccurrences,
                    })}
                  >
                    <input
                      type='number'
                      className='reminder-custom-repeat-interval-value'
                      value={settings.EndOccurrences}
                      min={1}
                      onChange={_handleChangeOccurNumber}
                      disabled={!isEndOccurrences}
                    />
                    <span className='reminder-custom-repeat-interval-label'>occurrences</span>
                  </div>
                )}
              </div>
              {error && <span className='reminder-error'>{error}</span>}
            </>
          )
        })}
      </RadioGroup>
    </div>
  )
}

CustomRepeatEnd.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
}

export default CustomRepeatEnd
