import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../actions'

const initialState = {
  fetchStatus: FetchStatus.Idle,
  fetchRequired: true,
  configuration: {
    AzureTenantId: '',
    AzureClientId: '',
    AzureClientSecret: '',
  },
}

export default handleActions(
  {
    [rawActions.getPowerBIReportProviderConfiguration.request]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [rawActions.getPowerBIReportProviderConfiguration.failed]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
    [rawActions.getPowerBIReportProviderConfiguration.success]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loaded, configuration: action.response.data }
    },
    [rawActions.setPowerBIReportProviderConfigurationFetchRequired]: (state, action) => {
      return { ...state, fetchRequired: action.payload }
    },
  },
  initialState
)
