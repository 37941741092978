import { handleActions, combineActions } from 'redux-actions'

import { clearUsersSearchValue, updateUsersSearchValue, resetShareSessionDialog, resetState } from '../actions'

const defaultState = ''

export default handleActions(
  {
    [updateUsersSearchValue](state, action) {
      return action.payload.searchText
    },
    [combineActions(clearUsersSearchValue, resetShareSessionDialog, resetState)](state, action) {
      return defaultState
    },
  },
  defaultState
)
