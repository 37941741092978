import { memo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContentSharingDialogState } from '@tabeeb/modules/contentSharings/selectors'
import { closeContentSharingDialog, resetState } from '@tabeeb/modules/contentSharings/actions'
import Title from './Title'
import Content from './Content'
import Actions from './Actions'
import { Container } from './styles'

const ContentSharingDialog = () => {
  const dispatch = useDispatch()
  const { stages, isOpen } = useSelector(getContentSharingDialogState)
  const [currentStageIndex, setCurrentStageIndex] = useState(0)

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [dispatch])

  const _handleCloseDialog = useCallback(() => {
    dispatch(closeContentSharingDialog())
  }, [dispatch])

  const props = { stages, currentStageIndex, setCurrentStageIndex, onClose: _handleCloseDialog }

  return (
    <Container open={isOpen}>
      <Title {...props} />
      <Content {...props} />
      <Actions {...props} />
    </Container>
  )
}

export default memo(ContentSharingDialog)
