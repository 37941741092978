import { useMemo } from 'react'
import { useApiRequest } from '@tabeeb/modules/shared/utils/hooks'
import { getUserTenants } from '../actions'

const useUserTenants = (userId) => {
  const requestPayload = useMemo(() => userId, [userId])

  const { status, response } = useApiRequest({
    request: getUserTenants.request,
    payload: requestPayload,
    enabled: !!requestPayload,
  })

  const tenants = useMemo(() => {
    if (!response) {
      return []
    }
    return response
  }, [response])

  return { fetchStatus: status, tenants }
}

export default useUserTenants
