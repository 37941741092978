import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [combineActions(rawActions.createBentleyAnalyticsReport.request)](state, action) {
      return true
    },
    [combineActions(rawActions.createBentleyAnalyticsReport.failed, rawActions.createBentleyAnalyticsReport.success)](
      state,
      action
    ) {
      return false
    },
    [rawActions.resetState](state, action) {
      return false
    },
  },
  false
)
