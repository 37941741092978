import { handleActions } from 'redux-actions'

import * as connectionActions from '../actions/connection'

const initialState = {
  connection: null,
  roomId: 0,
  isCallStarted: false,
  presenterId: 0,
  accessToken: '',
}

export default handleActions(
  {
    [connectionActions.startCall](state, action) {
      return { ...state, isCallStarted: true }
    },
    [connectionActions.endCall](state, action) {
      return { ...state, isCallStarted: false }
    },
    [connectionActions.setJitsiConnection](state, action) {
      return { ...state, connection: action.payload }
    },
    [connectionActions.setPresenter](state, action) {
      return { ...state, presenterId: action.payload }
    },
    [connectionActions.setRoomId](state, action) {
      return { ...state, roomId: action.payload }
    },
    [connectionActions.resetJitsiConnection](state, action) {
      return { ...state, connection: null }
    },
    [connectionActions.getJitsiTokenSuccess](state, action) {
      const { data } = action.response
      return { ...state, accessToken: data.access_token }
    },
  },
  initialState
)
