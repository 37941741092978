import { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import { DeleteOutlineOutlined, EditOutlined, MoreVertOutlined, VisibilityOutlined } from '@mui/icons-material'

import { useDialogState, usePopoverState } from '@tabeeb/shared/utils/hooks'

import TrainingMaterialDeleteConfirmationDialog from '../TrainingMaterialDeleteConfirmationDialog'

const TrainingMaterialMenu = ({ trainingMaterial, onDelete, onEdit, onView }) => {
  const [menuAnchorRef, isMenuOpen, onOpenMenu, onCloseMenu] = usePopoverState()
  const [isDeleteConfirmationDialogOpen, onOpenDeleteConfirmationDialog, onCloseDeleteConfirmationDialog] =
    useDialogState()

  const onDeleteSubmit = useCallback(() => {
    onCloseDeleteConfirmationDialog()
    onDelete(trainingMaterial)
  }, [onCloseDeleteConfirmationDialog, onDelete, trainingMaterial])

  const options = useMemo(() => {
    const menuOptions = []

    if (onEdit) {
      menuOptions.push({
        id: 'edit',
        title: 'Edit',
        icon: <EditOutlined />,
        onClick: () => onEdit(trainingMaterial),
      })
    }

    if (onView) {
      menuOptions.push({
        id: 'view',
        title: 'View',
        icon: <VisibilityOutlined />,
        onClick: () => onView(trainingMaterial),
      })
    }

    if (onDelete) {
      menuOptions.push({
        id: 'delete',
        title: 'Delete',
        icon: <DeleteOutlineOutlined color='error' />,
        onClick: onOpenDeleteConfirmationDialog,
      })
    }

    return menuOptions
  }, [onDelete, onEdit, onOpenDeleteConfirmationDialog, onView, trainingMaterial])

  return (
    <>
      <Tooltip title='Open menu'>
        <IconButton ref={menuAnchorRef} edge='end' size='small' onClick={onOpenMenu}>
          <MoreVertOutlined />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={onCloseMenu}
        onClick={onCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map(({ id, title, icon, onClick }) => (
          <MenuItem key={id} title={title} onClick={onClick}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
          </MenuItem>
        ))}
      </Menu>

      <TrainingMaterialDeleteConfirmationDialog
        open={isDeleteConfirmationDialogOpen}
        onClose={onCloseDeleteConfirmationDialog}
        onSubmit={onDeleteSubmit}
      />
    </>
  )
}

TrainingMaterialMenu.propTypes = {
  trainingMaterial: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
}

export default memo(TrainingMaterialMenu)
