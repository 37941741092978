import { AnnotationType } from '../../../Enums'

export function getAnnotationMessage(annotation) {
  switch (annotation.type) {
    case AnnotationType.Hotspot:
      return 'placed a flag.'
    case AnnotationType.LinkedPage:
      return 'took a photo.'
    case AnnotationType.Text:
      return annotation.parentId ? 'commented on a flag.' : 'added a text annotation.'
    case AnnotationType.Audio:
      return annotation.parentId ? 'left a voice message on a flag.' : 'recorded a voice over.'
    case AnnotationType.Line:
    case AnnotationType.Rectangle:
    case AnnotationType.Ellipse:
    case AnnotationType.Rectangle_Filled:
    case AnnotationType.Ellipse_Filled:
    case AnnotationType.ArrowEnd:
    case AnnotationType.ArrowBoth:
      return 'added a shape.'
    case AnnotationType.AI:
      return 'commented on an AI flag.'
    default:
      return 'drew something.'
  }
}

export function getActivityTypesList() {
  const result = []
  for (const key in AnnotationType) {
    result.push({ key, value: AnnotationType[key] })
  }

  return result
}

export const displayActivityTypes = {
  Flag: [6, 3, 4],
  Drawing: [0, 1, 2],
  Image: [7],
  Shape: [100, 101, 102, 103, 104, 105, 106],
  Text: [3],
  Audio: [4],
  AIFlag: [8],
}

export function timeFormatSeconds(seconds) {
  if (seconds === null || seconds <= 0) return null

  const date = new Date(1970, 0, 1)
  date.setSeconds(seconds)
  return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1')
}
