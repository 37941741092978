import { memo } from 'react'
import { Box, Button, Paper } from '@mui/material'
import { AddOutlined, Refresh } from '@mui/icons-material'
import PropTypes from 'prop-types'

import { PageHeader, PageLayout } from '@tabeeb/uikit'

const CertificatesPageHeader = ({ children, boxSx, loading, reRequestAll, onAddEditDialogOpen, isHidden }) => {
  return isHidden ? (
    children
  ) : (
    <Box sx={{ width: '100%', ...boxSx }}>
      <PageLayout component={Paper} variant='outlined' spacing='page' maxWidth='lg'>
        <PageHeader
          title='Certificates'
          actions={
            <>
              <Button
                variant='outlined'
                startIcon={<Refresh color='primary' />}
                disabled={loading}
                onClick={reRequestAll}
              >
                Refresh
              </Button>
              <Button variant='outlined' startIcon={<AddOutlined color='primary' />} onClick={onAddEditDialogOpen}>
                Create certificate
              </Button>
            </>
          }
        />
        {children}
      </PageLayout>
    </Box>
  )
}

CertificatesPageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  boxSx: PropTypes.shape({
    minWidth: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  reRequestAll: PropTypes.func.isRequired,
  onAddEditDialogOpen: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
}

export default memo(CertificatesPageHeader)
