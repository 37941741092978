export function isNeedToApprove(content, sessionApprovalSettings) {
  const creationMode = content == null
  let needToApproval = false
  if (sessionApprovalSettings != null) {
    if (creationMode) {
      if (sessionApprovalSettings.UserSessionCreationLimitUnlimited) {
        needToApproval = false
      } else if (sessionApprovalSettings.UserSessionCreationLimit === 0) {
        needToApproval = true
      } else {
        needToApproval =
          sessionApprovalSettings.UserSessionCreationLimit <= sessionApprovalSettings.UserSessionCreatedNumberTimes
      }
    } else if (sessionApprovalSettings.SingleSessionActivationsLimitUnlimited) {
      needToApproval = false
    } else if (sessionApprovalSettings.SingleSessionActivationsLimit === 0) {
      needToApproval = true
    } else if (sessionApprovalSettings.SingleSessionActivationsLimit != null) {
      needToApproval = sessionApprovalSettings.SingleSessionActivationsLimit <= content.ActivatedNumberTimes
    }
  }
  return needToApproval
}
