import * as actions from './actions'
import * as selectors from './selectors'
import classificationAnnotationsPagesTimelineSagas from './sagas'
import classificationAnnotationsPagesTimelineReducer from './reducers'
import ClassificationAnnotationsPagesTimeline from './components/ClassificationAnnotationsPagesTimeline'

export default ClassificationAnnotationsPagesTimeline

export {
  classificationAnnotationsPagesTimelineReducer,
  classificationAnnotationsPagesTimelineSagas,
  actions,
  selectors,
}
