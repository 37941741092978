import { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import FormTab from '@tabeeb/modules/forms/components/FormTab'
import styles from './styles'

const FormTabPanel = ({ panel, index, classes }) => {
  if (!panel.Tabs || panel.Tabs.length === 0) return null

  let tabWidth = 100 / panel.Tabs.length

  if (tabWidth < 20) tabWidth = 20
  const panelHeight = panel.Tabs.length > 5 ? 37 : 30

  return (
    <div className={classes.panel} style={{ height: panelHeight }}>
      {panel.Tabs.map((tab) => (
        <FormTab tab={tab} index={index} width={`${tabWidth}%`} key={tab.Id} />
      ))}
    </div>
  )
}

FormTabPanel.propTypes = {
  panel: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.shape({
    panel: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(FormTabPanel))
