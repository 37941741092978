export default (theme) => {
  return {
    status: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      maxWidth: '55%',
    },
    currentStatus: {
      fontSize: 14,
      textTransform: 'none',
      padding: '0 5px',
      maxWidth: '90%',
    },
  }
}
