import UsersFilter from '@tabeeb/modules/forms/components/UsersFilter'
import FormsFilterContainer from '../../containers/FormsFilterContainer'
import './styles.less'

const FilterPanel = () => {
  return (
    <div className='forms-filters'>
      <UsersFilter />
      <FormsFilterContainer />
    </div>
  )
}

export default FilterPanel
