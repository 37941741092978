export default (theme) => {
  return {
    page: {
      height: 'max-content',
      minHeight: '100%',
      width: 'min-content',
      minWidth: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      color: 'white',
      ...nestedAutofillOverride,
    },
    panel: {
      opacity: 0.9,
      minWidth: 360,
      width: '30%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    panelBackground: {
      width: '100%',
      height: '100%',
      background: 'rgb(66, 66, 66)',
      opacity: 0.7,
      position: 'absolute',
    },
    formContainer: {
      position: 'relative',
      padding: theme.spacing() * 2,
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      '& .MuiFormControlLabel-label': {
        color: 'white',
      },
      '& .MuiIconButton-label': {
        color: 'white',
      },
      '& .MuiFormLabel-root': {
        color: 'white',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
        },
        color: 'white',
        borderColor: 'white',
      },
      '& .MuiOutlinedInput-colorSecondary': {
        '&.Mui-focused': {
          '& > .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
        },
      },
    },
    banner: {
      zIndex: 1,
      transform: 'translateX(-50%)',
      margin: '0px 50%',
      marginTop: theme.spacing() * 4,
      maxWidth: '100%',
      width: 'max-content',
      objectFit: 'contain',
      maxHeight: 100,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing() * 2,
      paddingRight: theme.spacing() * 2,
    },
    bannerLink: {
      display: 'flex',
    },
    bullet: {
      wordBreak: 'break-word',
    },
    bulletIcon: {
      margin: 0,
      marginTop: 4,
      marginRight: 4,
      minWidth: 0,
      fontSize: 8,
    },
    mission: {
      margin: theme.spacing() * 2,
      padding: theme.spacing(),
      borderRadius: theme.shape.borderRadius,
      border: '1px solid white',
      maxHeight: 250,
      overflowY: 'auto',
      zIndex: 1,
    },
    linksContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    bottomLinksContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      zIndex: 1,
      padding: theme.spacing(2),
    },
  }
}

const autofillClasses = [
  'input:-webkit-autofill',
  'input:-webkit-autofill:hover',
  'input:-webkit-autofill:focus',
  'textarea:-webkit-autofill',
  'textarea:-webkit-autofill:hover',
  'textarea:-webkit-autofill:focus',
  'select:-webkit-autofill',
  'select:-webkit-autofill:hover',
  'select:-webkit-autofill:focus',
]

const autofillOverride = {
  '-webkit-text-fill-color': 'white',
  '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
  transition: 'background-color 5000s ease-in-out 0s',
}

const nestedAutofillOverride = autofillClasses.reduce((accumulator, className) => {
  accumulator[`& ${className}`] = autofillOverride
  return accumulator
}, {})
