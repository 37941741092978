import { useCallback, useEffect, useState } from 'react'

import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { addUniqueAiObjectConnection, deleteUniqueAiObjectConnection, getUniqueAiObject } from '../actions'

export default ({ id, onError }) => {
  const [uniqueAIObject, setUniqueAIObject] = useState(null)
  const [loading, setLoading] = useState(true)
  const [adding, setAdding] = useState(false)
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }

    setUniqueAIObject(null)
    setLoading(true)

    callApiAsync(getUniqueAiObject.request({ uniqueAIObjectId: id }))
      .then(({ response }) => {
        setUniqueAIObject(response.data)
        setLoading(false)
      })
      .catch((e) => {
        if (onError) {
          onError(e)
        }
      })
  }, [id, onError])

  const onAddConnection = useCallback(
    async (model) => {
      try {
        setAdding(true)
        const {
          response: { data: uniqueAIObjectConnection },
        } = await callApiAsync(addUniqueAiObjectConnection.request(model))

        if (!uniqueAIObject) {
          return
        }

        if (uniqueAIObject.Id === uniqueAIObjectConnection.Parent.Id) {
          setUniqueAIObject((prevState) => ({
            ...prevState,
            ChildConnections: [...prevState.ChildConnections, uniqueAIObjectConnection],
          }))
        } else if (uniqueAIObject.Id === uniqueAIObjectConnection.Child.Id) {
          setUniqueAIObject((prevState) => ({
            ...prevState,
            ParentConnections: [...prevState.ParentConnections, uniqueAIObjectConnection],
          }))
        }
        setAdding(false)
      } catch {
        // ignored
      } finally {
        setAdding(false)
      }
    },
    [uniqueAIObject]
  )

  const onDeleteConnection = useCallback(
    async (model) => {
      try {
        setDeleting(true)
        await callApiAsync(deleteUniqueAiObjectConnection.request(model))

        if (!uniqueAIObject) {
          return
        }

        setUniqueAIObject((prevState) => ({
          ...prevState,
          ChildConnections: prevState.ChildConnections.filter((cc) => cc.Id !== model.uniqueAIObjectConnectionId),
          ParentConnections: prevState.ParentConnections.filter((pc) => pc.Id !== model.uniqueAIObjectConnectionId),
        }))
        setDeleting(false)
      } catch {
        // ignored
      } finally {
        setDeleting(false)
      }
    },
    [uniqueAIObject]
  )

  return { loading, uniqueAIObject, onAddConnection, adding, onDeleteConnection, deleting, onError }
}
