import { handleActions } from 'redux-actions'

import { setActivateSessionDialog, resetActivateSessionDialog } from '../actions'

const defaultState = { isOpen: false, session: {} }

export default handleActions(
  {
    [setActivateSessionDialog](state, action) {
      return action.payload
    },
    [resetActivateSessionDialog](state, action) {
      return { ...state, isOpen: false }
    },
  },
  defaultState
)
