import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { tracksActions } from '@tabeeb/modules/presentation'
import GridView from '../components/GridView'
import * as usersSelectors from '../../../users/selectors'
import { getGridTemplateColumns, getIsOverflowed, MAX_GRID_ITEMS_AMOUNT } from '../services'

const GridViewContainer = ({ onlineUsers, tracksActions, remoteTracks }) => {
  useEffect(() => {
    tracksActions.toggleGridView(true)
    return () => {
      tracksActions.toggleGridView(false)
    }
  }, [remoteTracks])

  const gridTemplateColumns = getGridTemplateColumns(onlineUsers.length)
  const isOverflowed = getIsOverflowed(onlineUsers.length)

  const displayingUsers = isOverflowed ? onlineUsers.slice(0, MAX_GRID_ITEMS_AMOUNT) : onlineUsers
  const overflowedUser = isOverflowed ? displayingUsers.pop() : null
  const truncatedUsersAmount = onlineUsers.length - displayingUsers.length

  const props = {
    displayingUsers,
    gridTemplateColumns,
    truncatedUsersAmount,
    overflowedUser,
  }

  return <GridView {...props} />
}

GridViewContainer.propTypes = {
  remoteTracks: PropTypes.object.isRequired,
  onlineUsers: PropTypes.array.isRequired,
  tracksActions: PropTypes.shape({
    toggleGridView: PropTypes.func.isRequired,
  }),
}

const mapStateToProps = (state) => ({
  onlineUsers:
    usersSelectors.getUsersListForCurrentUser(state).filter((item) => !item.isDeleted && item.isOnline) || [],
  remoteTracks: state.presentation.tracks.remoteTracks,
})

const mapDispatchToProps = (dispatch) => ({
  tracksActions: bindActionCreators(tracksActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(GridViewContainer)
