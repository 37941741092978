import { rawContentActions } from '@tabeeb/modules/shared/content'
import { all, put, takeLatest } from 'redux-saga/effects'

import * as aiActions from '../actions'

function* resetAiState(action) {
  yield put(aiActions.resetState())
}

function* resetState() {
  yield all([takeLatest(rawContentActions.resetContentState, resetAiState)])
}

export default resetState
