/* eslint-disable jsx-a11y/media-has-caption */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ZOOM_MIN } from '@tabeeb/modules/../users/common/webZoom'
import { makeGetIsUserVideoMirrored } from '@tabeeb/modules/presentation/selectors'
import { getUserWebZoomValueById } from '@tabeeb/modules/../users/selectors'

import useStyles from './styles'

const UserVideo = ({ user, isVideoMuted }) => {
  const classes = useStyles()

  const videoMirroredStatusSelector = useMemo(makeGetIsUserVideoMirrored, [])
  const isVideoMirrored = useSelector((state) => videoMirroredStatusSelector(state, { userId: user.id }))

  const zoomValue = useSelector((state) => getUserWebZoomValueById(state, user.id))
  const isZoomed = zoomValue > ZOOM_MIN

  return (
    <div className={classNames(classes.container, { [classes.hidden]: isVideoMuted })}>
      <video
        className={classNames(
          'tabeebGridUserVideo',
          classes.video,
          { [classes.hidden]: isVideoMuted },
          { [classes.mirrored]: isVideoMirrored },
          { [classes.videoZoomed]: isZoomed }
        )}
        style={{
          height: `${zoomValue * 100}%`,
          transform: `rotateY(${isVideoMirrored ? '180deg' : '0deg'})`,
        }}
        data-id={user.id}
        autoPlay='autoPlay'
        onContextMenu={() => false}
        muted
        playsInline
      />
    </div>
  )
}

UserVideo.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  isVideoMuted: PropTypes.bool.isRequired,
}

export default UserVideo
