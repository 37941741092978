import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'
import SearchResultRowButton from './searchResultRowButton'
import { SearchResultItem, SearchResultItemInfo, SearchResultItemName } from './styles'

const UserRow = ({ user, button, handleButtonClick }) => {
  const _handleClick = useCallback(() => {
    handleButtonClick(user)
  }, [handleButtonClick, user])

  return (
    <SearchResultItem variant='outlined'>
      <SearchResultItemInfo>
        <UserAvatar user={user} style={{ width: 34, height: 34, marginRight: 6 }} />
        <SearchResultItemName>{user.Name}</SearchResultItemName>
      </SearchResultItemInfo>
      <SearchResultRowButton button={button} handleButtonClick={_handleClick} />
    </SearchResultItem>
  )
}

UserRow.propTypes = {
  user: PropTypes.shape({
    Name: PropTypes.string.isRequired,
  }).isRequired,
  button: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
}

export default memo(UserRow)
