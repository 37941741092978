import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeAddPowerBIReportDependantModuleModal,
  createPowerBIReportDependentModule,
} from '@tabeeb/modules/powerBIReports/actions'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material'
import { getAddPowerBIReportDependentModuleModalState } from '@tabeeb/modules/powerBIReports/selectors'
import { Add, Close } from '@mui/icons-material'
import { FetchStatus } from '@tabeeb/enums'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { GUIDRegexp } from '@tabeeb/modules/shared/constants'

const AddPowerBIReportDependentModuleModal = () => {
  const dispatch = useDispatch()

  const { open, fetchStatus, reportId } = useSelector(getAddPowerBIReportDependentModuleModalState)

  const loading = useMemo(() => {
    return fetchStatus === FetchStatus.Loading
  }, [fetchStatus])

  const submitButtonStartIcon = useMemo(() => {
    return loading ? <CircularProgress size={18} /> : <Add color='primary' />
  }, [loading])

  const onSubmit = useCallback(
    (values) => {
      const requestPayload = {
        reportId: values.reportId,
        name: values.name,
        externalId: values.externalId,
      }
      dispatch(createPowerBIReportDependentModule.request(requestPayload))
    },
    [dispatch]
  )

  const form = useFormik({
    initialValues: {
      reportId,
      name: '',
      externalId: '',
    },
    validationSchema: Yup.object({
      reportId: Yup.number().required(),
      name: Yup.string().required('Name is required.').max(100, 'Max length of the name is 100.').notEmptyString(),
      externalId: Yup.string().matches(GUIDRegexp, 'Must be a valid GUID.').required('ExternalID is required'),
    }),
    onSubmit,
    validateOnMount: true,
    enableReinitialize: true,
  })

  const handleClose = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') {
        return
      }
      form.resetForm()
      dispatch(closeAddPowerBIReportDependantModuleModal())
    },
    [dispatch, form]
  )

  useEffect(() => {
    if (fetchStatus === FetchStatus.Loaded) {
      handleClose()
    }
  }, [fetchStatus, handleClose])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>Add dependent report</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 12,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Grid container component='form' autoComplete='off' flexDirection='column' spacing={1}>
          <Grid item xs={12}>
            <TextField
              required
              variant='standard'
              name='name'
              value={form.values.name}
              helperText={form.errors.name}
              onChange={form.handleChange}
              label='Dependent report name'
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant='standard'
              name='externalId'
              value={form.values.externalId}
              helperText={form.errors.externalId}
              onChange={form.handleChange}
              label='Dependent report ID'
              fullWidth
              inputProps={{ maxLength: 36 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          color='primary'
          startIcon={submitButtonStartIcon}
          disabled={!form.isValid || !form.dirty || loading}
          onClick={form.handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddPowerBIReportDependentModuleModal
