import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'
import { openPagesDialog } from '../../spatialModel/actions'
import { open3dModelDialog } from '../../3dmodel/actions'
import { openBentleyModelTimeline } from '../../bentleyModel/actions'
import { openShareSessionPagesDialog } from '../../shareSessionPagesList/actions'
import * as customVideoActions from '../../customVideo/actions'
import { openReviewSessionPagesDialog } from '../../reviewSessionPagesList/actions'

const defaultState = false

export default handleActions(
  {
    [rawActions.enableExpandedGallery](state, action) {
      return true
    },
    [combineActions(
      rawActions.disableExpandedGallery,
      rawActions.onSelectGalleryItem,
      openShareSessionPagesDialog,
      customVideoActions.openTimeline,
      openPagesDialog,
      open3dModelDialog,
      openBentleyModelTimeline,
      openReviewSessionPagesDialog
    )](state, action) {
      return false
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
