import { combineActions, handleActions } from 'redux-actions'

import { getContentBentleyGigapixels } from '../actions'

const defaultState = false

export default handleActions(
  {
    [getContentBentleyGigapixels.request]() {
      return true
    },
    [combineActions(
      getContentBentleyGigapixels.success,
      getContentBentleyGigapixels.failed,
      getContentBentleyGigapixels.cancel
    )]() {
      return false
    },
  },
  defaultState
)
