import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { ListItemButton, ListItemText } from '@mui/material'
import { KeyboardArrowRightOutlined } from '@mui/icons-material'

import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import { getSncQuestionSelectedAnswers } from '../../selectors'

import AnswersMenu from '../AnswersMenu'

const QuestionsListItem = ({ disabled, question, onTouch }) => {
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const selectedQuestionAnswersCount = useSelector(
    (state) => getSncQuestionSelectedAnswers(state, { questionId: question.Id }).length
  )

  return (
    <>
      <ListItemButton ref={anchorRef} dense disableTouchRipple disabled={disabled} onClick={onOpen}>
        <ListItemText
          primary={question.Name}
          secondary={`Selected: ${selectedQuestionAnswersCount}/${question.TotalAnswersCount}`}
        />
        <KeyboardArrowRightOutlined />
      </ListItemButton>

      <AnswersMenu
        anchorEl={anchorRef.current}
        open={open}
        onClose={onClose}
        onTouch={onTouch}
        questionId={question.Id}
        questionName={question.Name}
      />
    </>
  )
}

QuestionsListItem.propTypes = {
  disabled: PropTypes.bool.isRequired,
  question: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    TotalAnswersCount: PropTypes.number.isRequired,
  }).isRequired,
  onTouch: PropTypes.func.isRequired,
}

export default memo(QuestionsListItem)
