import { handleActions } from 'redux-actions'

import { setUnit } from '../actions'
import { Units } from '../constants'

const defaultState = Units.Feet

export default handleActions(
  {
    [setUnit](state, { payload: unit }) {
      return unit
    },
  },
  defaultState
)
