import { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, MenuItem } from '@mui/material'
import { getSelectedTenantsForContentSharing } from '@tabeeb/modules/appConfigState/selectors'
import { selectTenantDestination } from '@tabeeb/modules/contentSharings/actions'
import { getContentSharingDialogState } from '@tabeeb/modules/contentSharings/selectors'
import { StyledSelect } from './styles'

const TenantDestination = ({ isDisabled }) => {
  const dispatch = useDispatch()
  const tenantsList = useSelector(getSelectedTenantsForContentSharing)
  const { tenant } = useSelector(getContentSharingDialogState)

  const _handleChange = (e) => {
    const tenantName = e.target.value
    const selectedTenant = tenantsList.find((t) => t.Name === tenantName)

    if (tenant?.Id !== selectedTenant?.Id) {
      dispatch(selectTenantDestination(selectedTenant))
    }
  }

  return (
    <FormControl fullWidth>
      <StyledSelect value={tenant?.Name || ''} onChange={_handleChange} disableUnderline disabled={isDisabled}>
        {tenantsList &&
          tenantsList.map((t) => (
            <MenuItem key={t.Id} value={t.Name}>
              {t.Name}
            </MenuItem>
          ))}
      </StyledSelect>
    </FormControl>
  )
}

TenantDestination.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
}

export default memo(TenantDestination)
