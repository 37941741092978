import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    slider: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.action.focus,
      width: 30,
      height: 128,
      padding: theme.spacing(),
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
      borderRadius: theme.shape.borderRadius,
    },
  }
})
