import PropTypes from 'prop-types'

import { Ellipse as CanvasEllipse, Group } from 'react-konva'

import { AnnotationType } from '@tabeeb/enums'
import { getHighlightedColor } from '../../services/colorsService'
import { getViewBoxFromAnnotationCoordinates } from '../../helpers/viewBoxCalculator'

import Transformer from '../Transformer'

const Circle = ({
  annotation,
  annotationRef,
  opacity,
  hovered,
  hoverProps,
  selected,
  selectionProps,
  contextMenuProps,
  dragAndDropProps,
  transformationProps,
  children,
  transformerRef,
}) => {
  const position = Circle.getXY(annotation)
  const anchor = Circle.getAnchor(annotation)
  const size = Circle.getSize(annotation)
  const filled = annotation.Type === AnnotationType.Ellipse_Filled

  const commonStyles = {
    lineCap: 'round',
    lineJoin: 'round',
    opacity,
  }

  const styles = {
    ...commonStyles,
    fill: annotation.Color,
    fillEnabled: filled,
    stroke: annotation.Color,
    strokeWidth: annotation.Width,
    hitStrokeWidth: annotation.Width * 2,
    strokeEnabled: !filled,
  }

  const hoveredStyles = {
    ...commonStyles,
    fillEnabled: false,
    stroke: getHighlightedColor(annotation.Color),
    strokeWidth: 1,
    strokeHitEnabled: false,
    strokeScaleEnabled: false,
  }

  return (
    <Group {...position} {...dragAndDropProps}>
      <CanvasEllipse
        id={annotation.Id}
        ref={annotationRef}
        {...size}
        {...styles}
        {...hoverProps}
        {...selectionProps}
        {...contextMenuProps}
        {...transformationProps}
      />
      {(selected || hovered) && <CanvasEllipse {...size} {...hoveredStyles} />}
      {selected && <Transformer transformerRef={transformerRef} />}
      <Group {...anchor}>{children}</Group>
    </Group>
  )
}

Circle.getXY = (annotation) => {
  const { height, width } = Circle.getSize(annotation)
  return {
    x: Math.min(annotation.Coordinates[0].Start.X, annotation.Coordinates[0].End.X) + width / 2,
    y: Math.min(annotation.Coordinates[0].Start.Y, annotation.Coordinates[0].End.Y) + height / 2,
  }
}

Circle.getAnchor = (annotation) => {
  const { width, height } = Circle.getSize(annotation)

  const a = width / 2
  const b = height / 2

  const angle = -Math.PI / 4
  const x = (a * b) / Math.sqrt(Math.pow(b, 2) + Math.pow(a, 2) * Math.pow(Math.tan(angle), 2))
  const y = Math.tan(angle) * x

  return { x, y }
}

Circle.getSize = (annotation) => {
  const coords = annotation.Coordinates[0]

  return {
    width: Math.abs(coords.Start.X - coords.End.X),
    height: Math.abs(coords.Start.Y - coords.End.Y),
  }
}

Circle.getViewBox = getViewBoxFromAnnotationCoordinates

Circle.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Color: PropTypes.string.isRequired,
    Coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        Start: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
        End: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
        }),
      })
    ),
    Type: PropTypes.number.isRequired,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  annotationRef: PropTypes.func,
  opacity: PropTypes.number,
  hovered: PropTypes.bool,
  hoverProps: PropTypes.shape({
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  }),
  selected: PropTypes.bool,
  selectionProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }),
  contextMenuProps: PropTypes.shape({
    onContextMenu: PropTypes.func.isRequired,
  }),
  dragAndDropProps: PropTypes.shape({
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
  }),
  transformationProps: PropTypes.shape({
    onTransformStart: PropTypes.func.isRequired,
    onTransform: PropTypes.func.isRequired,
    onTransformEnd: PropTypes.func.isRequired,
  }),
  transformerRef: PropTypes.object,
  children: PropTypes.node,
}

export default Circle
