import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'
import { signalrEvents, signalrConstants } from '../../signalr'

export default handleActions(
  {
    [rawActions.getTrackingsSuccess](state, action) {
      return action.response.data
    },
    [rawActions.addTrackingSuccess](state, action) {
      const tracking = action.response.data
      if (state.find((item) => item.Id === tracking.Id)) {
        return state
      }

      return [...state, tracking]
    },
    [signalrEvents[signalrConstants.tabeebHubName].onTrackingAdded](state, action) {
      const [tracking] = action.payload
      if (state.find((item) => item.Id === tracking.Id)) {
        return state
      }

      return [...state, tracking]
    },
    [rawActions.deleteTrackingSuccess](state, action) {
      const trackingId = action.payload.Id

      return state.filter((item) => item.Id !== trackingId)
    },
    [signalrEvents[signalrConstants.tabeebHubName].onTrackingDeleted](state, action) {
      const [{ Id: trackingId }] = action.payload

      return state.filter((item) => item.Id !== trackingId)
    },
    [rawActions.resetTracking]() {
      return []
    },
  },
  []
)
