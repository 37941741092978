import PropTypes from 'prop-types'
import { memo } from 'react'
import { ContentSharingDialogStepId } from '@tabeeb/modules/contentSharings/constants/enums'
import TenantDestination from './tenantDestination'
import UserSelect from './userSelect'
import ContentDestination from './contentDestination'
import { StepContainer, StepTitle } from './styles'

const Step = ({ step, stepIndex, currentStage }) => {
  const previousStep = currentStage.steps[stepIndex - 1]
  let isDisabled = false

  if (previousStep) {
    isDisabled = !previousStep.isOptional && !previousStep.isCompleted
  }

  const props = { isDisabled }

  return (
    <StepContainer disabled={isDisabled}>
      <StepTitle>{step.label}</StepTitle>
      {step.id === ContentSharingDialogStepId.SelectTenantDestination && <TenantDestination {...props} />}
      {step.id === ContentSharingDialogStepId.SelectUser && <UserSelect {...props} />}
      {step.id === ContentSharingDialogStepId.SelectContentDestination && <ContentDestination {...props} />}
    </StepContainer>
  )
}

Step.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  currentStage: PropTypes.shape({
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        isOptional: PropTypes.bool.isRequired,
        isCompleted: PropTypes.bool.isRequired,
      }).isRequired
    ).isRequired,
  }),
  stepIndex: PropTypes.number.isRequired,
}

export default memo(Step)
