import { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import AlarmIcon from '@material-ui/icons/Alarm'

import FormControlButton from '@tabeeb/modules/forms/components/FormControlButton'
import { ReminderType } from '@tabeeb/enums'

import { getContentId } from '@tabeeb/modules/shared/content/selectors'

import ReminderManager from '../ReminderManager'

import { getAvailableReminderReceivers, setExistingReminder, setNewReminder } from '../../actions'
import { getExistingReminders, getIsReminderIconVisible } from '../../selectors'

import { getDefaultReminderSettings } from '../../services/defaultReminderSettings'

const Reminder = ({ type, entity }) => {
  const dispatch = useDispatch()

  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false)
  const prevIsSettingsDialogOpenRef = useRef(isSettingsDialogOpen)

  const reminders = useSelector((state) => getExistingReminders(state, { type, entity }))
  const isVisible = useSelector(getIsReminderIconVisible)
  const contentId = useSelector(getContentId)

  const defaultReminder = useMemo(() => getDefaultReminderSettings(type, entity), [type, entity])

  useEffect(() => {
    if (isSettingsDialogOpen) {
      dispatch(getAvailableReminderReceivers.request({ contentId, formId: entity.FormId }))
    } else if (prevIsSettingsDialogOpenRef.current) {
      dispatch(getAvailableReminderReceivers.resetState())
    }
  }, [dispatch, contentId, entity.FormId, isSettingsDialogOpen])

  const _handleDialogOpen = useCallback(() => {
    dispatch(setNewReminder(defaultReminder))
    setIsSettingsDialogOpen(true)
    prevIsSettingsDialogOpenRef.current = false
  }, [defaultReminder, dispatch])

  const _handleDialogClose = useCallback(() => {
    dispatch(setNewReminder(null))
    dispatch(setExistingReminder(null))
    setIsSettingsDialogOpen(false)
    prevIsSettingsDialogOpenRef.current = true
  }, [dispatch])

  let ButtonComponent = null

  switch (type) {
    case ReminderType.AnswerFormQuestion:
      ButtonComponent = FormControlButton
      break
    default:
      break
  }

  return (
    isVisible && (
      <>
        <ButtonComponent
          onClick={_handleDialogOpen}
          Icon={AlarmIcon}
          title='Set reminder'
          iconColor={isSettingsDialogOpen ? '#0089cf' : reminders.length > 0 ? '#4CAF50' : undefined}
        />
        {isSettingsDialogOpen && (
          <ReminderManager
            onCloseDialog={_handleDialogClose}
            isOpen={isSettingsDialogOpen}
            reminders={reminders}
            defaultReminder={defaultReminder}
          />
        )}
      </>
    )
  )
}

Reminder.propTypes = {
  type: PropTypes.number.isRequired,
  entity: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    FormId: PropTypes.number.isRequired,
  }),
}

export default Reminder
