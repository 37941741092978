import { createAction } from 'redux-actions'

export const resetSubdomainForTenantForms = createAction('RESET_SUBDOMAIN_FOR_TENANT_FORMS')

export const openAvailableFormsForTenantDialog = createAction('OPEN_AVAILABLE_FORMS_FOR_TENANT_DIALOG')
export const closeAvailableFormsForTenantDialog = createAction('CLOSE_AVAILABLE_FORMS_FOR_TENANT_DIALOG')

export const getAvailableFormsForTenantRequest = createAction('GET_AVAILABLE_FORMS_FOR_TENANT_REQUEST')
export const getAvailableFormsForTenantSuccess = createAction('GET_AVAILABLE_FORMS_FOR_TENANT_SUCCESS')
export const getAvailableFormsForTenantFailed = createAction('GET_AVAILABLE_FORMS_FOR_TENANT_FAILED')

export const setFormsForTenant = createAction('SET_FORMS_FOR_TENANT')
export const resetFormsForTenant = createAction('RESET_FORMS_FOR_TENANT')

export const getTenantFormsRequest = createAction('GET_TENANT_FORMS_REQUEST')
export const getTenantFormsSuccess = createAction('GET_TENANT_FORMS_SUCCESS')
export const getTenantFormsFailed = createAction('GET_TENANT_FORMS_FAILED')

export const setTenantForms = createAction('SET_TENANT_FORMS')
export const resetTenantForms = createAction('RESET_TENANT_FORMS')

export const addTenantForm = createAction('ADD_TENANT_FORM')
export const removeTenantForm = createAction('REMOVE_TENANT_FORM')

export const prepareAttachingFormToTenant = createAction('PREPARE_ATTACHING_FORM_TO_TENANT')

export const attachFormToTenantRequest = createAction('ATTACH_FORM_TO_TENANT_REQUEST')
export const attachFormToTenantSuccess = createAction('ATTACH_FORM_TO_TENANT_SUCCESS')
export const attachFormToTenantFailed = createAction('ATTACH_FORM_TO_TENANT_FAILED')

export const detachFormFromTenantRequest = createAction('DETACH_FORM_FROM_TENANT_REQUEST')
export const detachFormFromTenantSuccess = createAction('DETACH_FORM_FROM_TENANT_SUCCESS')
export const detachFormFromTenantFailed = createAction('DETACH_FORM_FROM_TENANT_FAILED')

export const openTenantFormsDialog = createAction('OPEN_TENANT_FORMS_DIALOG')
export const closeTenantFormsDialog = createAction('CLOSE_TENANT_FORMS_DIALOG')

export const getTenantFormRequest = createAction('GET_TENANT_FORM_REQUEST')
export const getTenantFormSuccess = createAction('GET_TENANT_FORM_SUCCESS')
export const getTenantFormFailed = createAction('GET_TENANT_FORM_FAILED')

export const addOpenedTenantForm = createAction('ADD_OPENED_TENANT_FORM')
export const removeOpenedTenantForm = createAction('REMOVE_OPENED_TENANT_FORM')
export const removeOpenedTenantSubForms = createAction('REMOVE_OPENED_TENANT_SUB_FORMS')
export const resetOpenedTenantForms = createAction('RESET_OPENED_TENANT_FORMS')

export const setTenantFormAnswer = createAction('SET_TENANT_FORM_ANSWER')
export const resetTenantFormAnswers = createAction('RESET_TENANT_FORM_ANSWERS')

export const setButtonNameForForm = createAction('SET_BUTTON_NAME_FOR_FORM')

export const setTenantFormMultipleAnswer = createAction('SET_TENANT_FORM_MULTIPLE_ANSWER')

export const removeTenantFormAnswersForControl = createAction('REMOVE_TENANT_FORM_ANSWERS_FOR_CONTROL')
export const removeTenantFormAnswersForSubforms = createAction('REMOVE_TENANT_FORM_ANSWERS_FOR_SUB_FORMS')
export const submitTenantFormAnswers = createAction('SUBMIT_TENANT_FORM_ANSWERS')

export const checkTenantFormConditions = createAction('CHECK_TENANT_FORM_CONDITIONS')
export const checkTenantFormConditionsRequest = createAction('CHECK_TENANT_FORM_CONDITIONS_REQUEST')
export const checkTenantFormConditionsSuccess = createAction('CHECK_TENANT_FORM_CONDITIONS_SUCCESS')
export const checkTenantFormConditionsFailed = createAction('CHECK_TENANT_FORM_CONDITIONS_FAILED')

export const getAdditionalTenantFormRequest = createAction('GET_ADDITIONAL_TENANT_FORM_REQUEST')
export const getAdditionalTenantFormSuccess = createAction('GET_ADDITIONAL_TENANT_FORM_SUCCESS')
export const getAdditionalTenantFormFailed = createAction('GET_ADDITIONAL_TENANT_FORM_FAILED')

export const setTenantFormsSearchText = createAction('SET_TENANT_FORMS_SEATCH_TEXT')
export const setFoundFormsForTenant = createAction('SET_FOUND_FORMS_FOR_TENANT')

// // folders
export const getAvailableFormsForTenantByFolderIdRequest = createAction(
  'GET_AVAILABLE_FORMS_FOR_TENANT_BY_FOLDER_ID_REQUEST'
)
export const getAvailableFormsForTenantByFolderIdSuccess = createAction(
  'GET_AVAILABLE_FORMS_FOR_TENANT_BY_FOLDER_ID_SUCCESS'
)

export const getAllFoldersInTenantFormsRequest = createAction('GET_ALL_FOLDERS_IN_TENANT_FORMS_REQUEST')
export const getAllFoldersInTenantFormsSuccess = createAction('GET_ALL_FOLDERS_IN_TENANT_FORMS_SUCCESS')

export const openFolder = createAction('OPEN_FOLDER_IN_TENANT_FORMS')
export const setCurrentFolder = createAction('SET_CURRENT_FOLDER_IN_TENANT_FORMS')
export const clearCurrentFolder = createAction('CLEAR_CURRENT_FOLDER_IN_TENANT_FORMS')

export const clearFoldersList = createAction('CLEAR_FOLDERS_LIST_IN_TENANT_FORMS')
