const iterationsRequests = {
  getIterationsRequest: ({ projectId }) => ({
    url: `api/iterations/project/${projectId}`,
    method: 'get',
  }),
  deployIterationRequest: (data) => ({
    url: `api/iterations/deploy/start`,
    method: 'post',
    data,
  }),
  deployIterationByAiModelIdRequest: (data) => ({
    url: `api/iterations/deploy/start/byAIModel`,
    method: 'post',
    data,
  }),
  deployIterationByVqaModelIdRequest: (data) => ({
    url: `api/iterations/deploy/start/byVQAModel`,
    method: 'post',
    data,
  }),
  getIterationStatusRequest: ({ iterationId }) => ({
    url: `api/iterations/${iterationId}/status`,
    method: 'get',
  }),
  getIterationDeploymentStatusRequest: ({ iterationId }) => ({
    url: `api/iterations/${iterationId}/status/deployment`,
    method: 'get',
  }),
}

export default iterationsRequests
