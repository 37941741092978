import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(
      rawActions.attachVqaPolicyToFormControlOptionRequest,
      rawActions.detachVqaPolicyFromFormControlOptionRequest,
      rawActions.attachAiExpressionToFormControlOption.request,
      rawActions.detachAiExpressionFromFormControlOption.request
    )](state, { payload }) {
      return true
    },
    [combineActions(
      rawActions.attachVqaPolicyToFormControlOptionSuccess,
      rawActions.attachVqaPolicyToFormControlOptionFailed,
      rawActions.detachVqaPolicyFromFormControlOptionFailed,
      rawActions.detachVqaPolicyFromFormControlOptionSuccess,
      rawActions.attachAiExpressionToFormControlOption.failed,
      rawActions.attachAiExpressionToFormControlOption.success,
      rawActions.detachAiExpressionFromFormControlOption.failed,
      rawActions.detachAiExpressionFromFormControlOption.success
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
