export const INCHES_IN_FEET = 12

export const getDistanceInPx = (points) => {
  if (!points) {
    return 0
  }

  return points.reduce((distance, point, index) => {
    if (index === 0) {
      return distance
    }

    const previousPoint = points[index - 1]

    return distance + Math.sqrt((point.X - previousPoint.X) ** 2 + (point.Y - previousPoint.Y) ** 2)
  }, 0)
}

export const getDistanceInFt = (distanceInPx, dpi) => {
  return distanceInPx / dpi / INCHES_IN_FEET
}
