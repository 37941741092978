import { handleActions } from 'redux-actions'

import { setWorldRotation } from '../actions'

const defaultState = {
  x: 0,
  y: 0,
  z: 0,
}

export default handleActions(
  {
    [setWorldRotation](state, { payload: rotation }) {
      return {
        x: rotation.x,
        y: rotation.y,
        z: rotation.z,
      }
    },
  },
  defaultState
)
