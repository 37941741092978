import { handleActions } from 'redux-actions'
import {
  adminCreateUsersSuccess,
  adminCreateUsersRequest,
  adminCreateUsersFailed,
  adminCleanUsersUpload,
} from '../actions'

const defaultState = {
  errors: [],
  createdUsersCount: null,
  isLoading: false,
}

export default handleActions(
  {
    [adminCreateUsersSuccess](state, action) {
      const { ErroredUserList, CreatedUserList } = action.response.data

      const errors = ErroredUserList.map((i) => i.Message)
      const createdUsersCount = CreatedUserList.length

      return { errors, createdUsersCount, isLoading: false }
    },
    [adminCreateUsersRequest](state, action) {
      return { ...state, isLoading: true }
    },
    [adminCreateUsersFailed](state, action) {
      return defaultState
    },
    [adminCleanUsersUpload]() {
      return defaultState
    },
  },
  defaultState
)
