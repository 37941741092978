import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Grid, LinearProgress, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'
import {
  getPowerBIReportProviderConfiguration,
  openPowerBIProviderModal,
  setPowerBIReportProviderConfigurationFetchRequired,
} from '@tabeeb/modules/powerBIReportProviders/actions'
import { getPowerBIProviderConfigurationState } from '@tabeeb/modules/powerBIReportProviders/selectors'
import { FetchStatus } from '@tabeeb/enums'

const PowerBIProviderConfiguration = ({ provider }) => {
  const dispatch = useDispatch()

  const { fetchStatus, configuration, fetchRequired } = useSelector(getPowerBIProviderConfigurationState)

  useEffect(() => {
    if (fetchRequired) {
      dispatch(getPowerBIReportProviderConfiguration.request(provider.Id))
      dispatch(setPowerBIReportProviderConfigurationFetchRequired(false))
    }
  }, [dispatch, fetchRequired, provider.Id])

  const onModify = useCallback(() => {
    dispatch(openPowerBIProviderModal({ ...provider, ...configuration }))
  }, [dispatch, provider, configuration])

  const maskString = useCallback((string) => {
    if (!string) {
      return ''
    }
    return string.substring(string.length - 15, string.length).replace(/.(?=.{4,}$)/g, '*')
  }, [])

  return (
    <Grid container item xs={12}>
      <Typography variant='body1' fontWeight={500} mb={1}>
        Configuration
      </Typography>
      {fetchStatus === FetchStatus.Loading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      {fetchStatus === FetchStatus.Failed && (
        <Grid item xs={12}>
          <Typography sx={{ textAlign: 'center' }}>Failed to load configuration</Typography>
        </Grid>
      )}
      {fetchStatus === FetchStatus.Loaded && (
        <Grid container alignItems='center'>
          <Grid item xs={3.5}>
            <Typography>Azure Tenant ID: {maskString(configuration.AzureTenantId)}</Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography>Azure Client ID: {maskString(configuration.AzureClientId)}</Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography>Azure Client secret: {maskString(configuration.AzureClientSecret)}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Button variant='outlined' color='primary' startIcon={<Edit color='primary' />} onClick={onModify}>
              Modify
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

PowerBIProviderConfiguration.propTypes = {
  provider: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
  }),
}

export default PowerBIProviderConfiguration
