import { put, takeLatest, all } from 'redux-saga/effects'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import getErrorMessageFromResponse from '../../shared/utils/getErrorMessageFromResponse'

function* getFoldersFailed({ response }) {
  const message = getErrorMessageFromResponse(response.data)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* foldersList() {
  yield all([takeLatest(rawActions.getAllFormFoldersFailed, getFoldersFailed)])
}

export default foldersList
