import { put, takeLatest, all, select } from 'redux-saga/effects'

import * as actions from '../actions'
import { getTagsOfSelectedFormControl } from '../../formInfoPage/selectors'

function* onStartOpeningCreateVqaPolicyWithPredefinedTagsDialog() {
  const tags = yield select(getTagsOfSelectedFormControl)
  yield put(actions.openCreateVqaPolicyWithPredefinedTagsDialog(tags))
}

function* createVQAPolicy() {
  yield all([
    takeLatest(
      actions.startOpeningCreateVqaPolicyWithPredefinedTagsDialog,
      onStartOpeningCreateVqaPolicyWithPredefinedTagsDialog
    ),
  ])
}

export default createVQAPolicy
