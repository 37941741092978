import { handleActions } from 'redux-actions'

import * as aiActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [aiActions.getUnattachedAiModels.success](state, { response }) {
      return response.data
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
