import PropTypes from 'prop-types'

import UsersFilter from '../UsersFilter'
import ActivityTypeFilter from '../ActivityTypeFilter'
import PageTab from '../PageTab'
import SearchFilterContainer from '../../containers/SearchFilterContainer'

import './styles.less'

const FilterPanel = (propsObj) => {
  const { pageTabCurrentProps, pageTabAllProps, usersFilterProps, activityTypeFilterProps } = propsObj

  return (
    <header className='filterPanel'>
      <div className='pagesFilter'>
        <PageTab {...pageTabCurrentProps} />
        <PageTab {...pageTabAllProps} />
      </div>
      <div className='paramFilters'>
        <UsersFilter {...usersFilterProps} />
        <ActivityTypeFilter {...activityTypeFilterProps} />
      </div>
      <SearchFilterContainer />
    </header>
  )
}

FilterPanel.propTypes = {
  pageTabCurrentProps: PropTypes.object.isRequired,
  pageTabAllProps: PropTypes.object.isRequired,
  usersFilterProps: PropTypes.object.isRequired,
  activityTypeFilterProps: PropTypes.object.isRequired,
}

export default FilterPanel
