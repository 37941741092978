import { useState } from 'react'

import PlayingAudioUrlContext from './PlayingAudioUrlContext'

const PlayingAudioUrlProvider = (props) => {
  const state = useState('')

  return <PlayingAudioUrlContext.Provider value={state} {...props} />
}

export default PlayingAudioUrlProvider
