import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Popover, MenuItem, ListItemText } from '@material-ui/core'
import {
  getIsContentLinkingEnabled,
  getIsContentReviewingEnabled,
  getIsContentSharingEnabled,
} from '@tabeeb/modules/contentSharings/selectors'
import * as rawActions from '../../actions'

import { exportMenuItems } from '../../services/galleryMenus'

const ExportButtonMenu = ({
  setAnchorEl,
  anchorEl,
  isShareSessionByFormEnabled,
  isSessionReviewingEnabled,
  isSessionSharingEnabled,
  isContentLinkingEnabled,
  actions: { handleExportButtonMenuClick },
}) => {
  const _renderMenuItem = (menuItemType, key) => {
    let disabled = false
    let isVisible = true

    switch (menuItemType) {
      case exportMenuItems.ReviewSession:
        disabled = !isShareSessionByFormEnabled
        isVisible = isSessionReviewingEnabled
        break
      case exportMenuItems.ShareSession:
        isVisible = isSessionSharingEnabled
        break
      case exportMenuItems.MergeContents:
        isVisible = isContentLinkingEnabled
        break
    }

    return isVisible ? (
      <MenuItem onClick={() => _handleMenuClick(menuItemType)} disabled={disabled} key={key}>
        <ListItemText primary={menuItemType} />
      </MenuItem>
    ) : null
  }

  const _handleMenuClick = (menuItemType) => {
    setAnchorEl(null)
    handleExportButtonMenuClick(menuItemType)
  }

  return (
    <Popover
      className='gallery-export-menu-popover'
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <div className='gallery-export-menu'>
        {Object.values(exportMenuItems).map((menuItem, index) => _renderMenuItem(menuItem, index))}
      </div>
    </Popover>
  )
}

ExportButtonMenu.propTypes = {
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func.isRequired,
  isSessionSharingEnabled: PropTypes.bool.isRequired,
  isShareSessionByFormEnabled: PropTypes.bool.isRequired,
  isSessionReviewingEnabled: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    handleExportButtonMenuClick: PropTypes.func.isRequired,
  }).isRequired,
  isContentLinkingEnabled: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  return {
    isShareSessionByFormEnabled: state.forms.contentForms.length > 0,
    isSessionReviewingEnabled: getIsContentReviewingEnabled(state),
    isSessionSharingEnabled: getIsContentSharingEnabled(state),
    isContentLinkingEnabled: getIsContentLinkingEnabled(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportButtonMenu)
