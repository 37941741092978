import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(rawActions.getAvailableFormsRequest, rawActions.getAvailableFormsByFolderIdRequest)](
      state,
      action
    ) {
      return true
    },
    [rawActions.updateAvailableForms](state, action) {
      return defaultState
    },
    [rawActions.resetFormsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
