import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'
import { FileUploadStatus } from '../constants'

const defaultState = []

export default handleActions(
  {
    [rawActions.clearFileUploads](state, action) {
      return [...defaultState]
    },
    [rawActions.clearSuccessFileUploads](state) {
      const newState = state.filter((item) => {
        return item.status !== FileUploadStatus.Done
      })

      return newState
    },
    [rawActions.removeFileUploads](state, action) {
      const newState = state.filter((item) => !action.payload.some((file) => file === item.file))

      return newState
    },
    [rawActions.cancelFileUploads](state, action) {
      const newState = state.map((item) =>
        action.payload.some((fileUpload) => fileUpload.file === item.file)
          ? { ...item, status: FileUploadStatus.Canceled }
          : item
      )

      return newState
    },
    [rawActions.updateFileUploads](state, action) {
      const updatedFileUploads = action.payload
      const newState = state.map((item) => {
        const fileUpload = updatedFileUploads.find(
          (upload) =>
            upload.file === item.file || (item.url && upload.url === item.url.substring(0, item.url.lastIndexOf('?')))
        )
        return fileUpload ? { ...item, ...fileUpload } : item
      })

      return newState
    },
    [rawActions.addFileUploads](state, action) {
      let newState = [...state]

      const fileUploads = action.payload
      for (const fileUpload of fileUploads) {
        const isFileUploadAlreadyExist = state.some(
          (item) => item.file === fileUpload.file && item.status !== FileUploadStatus.Done
        )

        if (isFileUploadAlreadyExist) {
          newState = newState.map((item) =>
            item.file === fileUpload.file
              ? { ...fileUpload, status: fileUpload.status || FileUploadStatus.Pending }
              : item
          )
        } else {
          newState.push({ ...fileUpload, status: FileUploadStatus.Pending })
        }
      }

      return newState
    },
  },
  defaultState
)
