export default (theme) => {
  return {
    delimeter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(0.5),
    },
    error: {
      marginLeft: theme.spacing(0.5),
    },
    pickers: {
      display: 'flex',
    },
  }
}
