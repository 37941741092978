import convertLangLatToUTM from '../../shared/utils/gpsCoordinates/convertLangLatToUTM'

export const calculateAzimuth = (centerCoordinatesUTM, imgLongitude, imgLatitude) => {
  if (
    !Number.isFinite(centerCoordinatesUTM && centerCoordinatesUTM.x) ||
    !Number.isFinite(centerCoordinatesUTM && centerCoordinatesUTM.y) ||
    !Number.isFinite(imgLongitude) ||
    !Number.isFinite(imgLatitude)
  ) {
    return undefined
  }

  const utmImgCoordinates = convertLangLatToUTM(imgLatitude, imgLongitude)

  const cart_cams = { x: utmImgCoordinates.x - centerCoordinatesUTM.x, y: utmImgCoordinates.y - centerCoordinatesUTM.y }

  const phi = Math.atan2(cart_cams.x, cart_cams.y)

  let azimuthInDegrees = (180 / Math.PI) * phi
  azimuthInDegrees = (azimuthInDegrees + 360) % 360

  return azimuthInDegrees
}
