import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import { DisplayAnswersMode } from '@tabeeb/enums'
import {
  getFormUsersFilterSelectedValue,
  getIsFormUsersFilterVisible,
  selectUsersFilter,
} from '@tabeeb/modules/forms/selectors'
import { setDisplayAnswersMode, setSelectedUserId } from '@tabeeb/modules/forms/actions'
import './styles.less'

const UsersFilter = () => {
  const dispatch = useDispatch()
  const value = useSelector(getFormUsersFilterSelectedValue)
  const users = useSelector(selectUsersFilter)
  const isVisible = useSelector(getIsFormUsersFilterVisible)

  useEffect(() => {
    if (!isVisible) {
      dispatch(setDisplayAnswersMode(DisplayAnswersMode.AllLatest))
    }
  }, [dispatch, isVisible])

  const _handleChange = useCallback(
    (event) => {
      let displayAnswersMode
      let userId = 0

      switch (event.target.value) {
        case 'allLatest':
          displayAnswersMode = DisplayAnswersMode.AllLatest
          break
        case 'allMyLatest':
          displayAnswersMode = DisplayAnswersMode.AllMyLatest
          break
        default:
          displayAnswersMode = DisplayAnswersMode.ByUser
          userId = parseInt(event.target.value, 10)
          dispatch(setSelectedUserId(userId))
          break
      }

      dispatch(setDisplayAnswersMode(displayAnswersMode))
    },
    [dispatch]
  )

  const title = useMemo(() => {
    switch (value) {
      case 'allLatest':
        return 'All latest'
      case 'allMyLatest':
        return 'All my latest'
      default:
        return users.find((u) => u.id === value)?.displayName ?? ''
    }
  }, [users, value])

  if (!isVisible) {
    return null
  }

  return (
    <div className='forms-filters-item'>
      <FormControl className='forms-filters-item-control'>
        <InputLabel>User</InputLabel>
        <Select
          value={value}
          onChange={_handleChange}
          title={title}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            getContentAnchorEl: null,
            id: 'forms-filters-menu',
          }}
        >
          <MenuItem value='allLatest' key='all_latest'>
            <span className='forms-filters-menu-text' title='All latest'>
              All latest
            </span>
          </MenuItem>
          <MenuItem value='allMyLatest' key='my_latest'>
            <span className='forms-filters-menu-text' title='All my latest'>
              All my latest
            </span>
          </MenuItem>
          {users?.map((user) => (
            <MenuItem value={user.id} key={`user_${user.id}`}>
              <span className='forms-filters-menu-text' title={user.displayName}>
                {user.displayName}
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default UsersFilter
