import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Popover } from '@material-ui/core'

import { useDialogState } from '@tabeeb/shared/utils/hooks'
import { isFireFoxBrowser } from '@tabeeb/modules/shared/browser'
import { getMe } from '@tabeeb/modules/account/selectors'
import UserCertificatesDialog from '@tabeeb/modules/certificates/components/UserCertificatesDialog'
import SoundSettings from '../SoundSettings'
import VideoSettings from '../VideoSettings'
import UserOptionButton from '../UserOptionButton'
import HealthInfoDialog from '../../../modules/healthData/components/HealthInfoDialog'

import './styles.less'

const CurrentUserOptionsMenu = ({
  anchorEl,
  isOpen,
  onClose,
  isDeviceMenuVisible,
  isHealthInfoOptionsVisible,
  isCertificatesMenuVisible,
}) => {
  const [soundSettingOpen, onSoundSettingOpen, onSoundSettingClose] = useDialogState(false)
  const [videoSettingOpen, onVideoSettingOpen, onVideoSettingClose] = useDialogState(false)
  const [healthDataOpen, onHealthDataOpen, onHealthDataClose] = useDialogState(false)
  const [certificatesOpen, onCertificatesOpen, onCertificatesClose] = useDialogState(false)

  const currentUser = useSelector(getMe)

  const handleSoundSettingsOpen = useCallback(() => {
    onSoundSettingOpen()
    onClose()
  }, [onClose, onSoundSettingOpen])

  const handleVideoSettingsOpen = useCallback(() => {
    onVideoSettingOpen()
    onClose()
  }, [onClose, onVideoSettingOpen])

  const handleHealthDataOpen = useCallback(() => {
    onHealthDataOpen()
    onClose()
  }, [onClose, onHealthDataOpen])

  const handleCertificatesOpen = useCallback(() => {
    onCertificatesOpen()
    onClose()
  }, [onCertificatesOpen, onClose])

  return (
    <>
      <Popover
        className='user-options-controls-popover'
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <div className='user-options-controls'>
          {isDeviceMenuVisible && (
            <>
              <UserOptionButton
                onClick={handleSoundSettingsOpen}
                buttonText='Audio settings'
                disabled={isFireFoxBrowser}
              />
              <UserOptionButton onClick={handleVideoSettingsOpen} buttonText='Video settings' />
            </>
          )}
          {isHealthInfoOptionsVisible && (
            <UserOptionButton onClick={handleHealthDataOpen} buttonText='Show Health Data' />
          )}
          {isCertificatesMenuVisible && (
            <UserOptionButton onClick={handleCertificatesOpen} buttonText='Show Certificates' />
          )}
        </div>
      </Popover>
      <SoundSettings
        isOpen={soundSettingOpen}
        onOpen={handleSoundSettingsOpen}
        anchorEl={anchorEl}
        onClose={onSoundSettingClose}
      />
      <VideoSettings
        isOpen={videoSettingOpen}
        onOpen={handleVideoSettingsOpen}
        anchorEl={anchorEl}
        onClose={onVideoSettingClose}
      />
      <HealthInfoDialog
        isOpen={healthDataOpen}
        anchorEl={anchorEl}
        onClose={onHealthDataClose}
        userId={currentUser.IdentityGuid}
      />
      <UserCertificatesDialog user={currentUser} personal onClose={onCertificatesClose} open={certificatesOpen} />
    </>
  )
}

CurrentUserOptionsMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  isDeviceMenuVisible: PropTypes.bool.isRequired,
  isHealthInfoOptionsVisible: PropTypes.bool.isRequired,
  isCertificatesMenuVisible: PropTypes.bool.isRequired,
}

export default CurrentUserOptionsMenu
