import { memo } from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'

import UserAvatar from '@tabeeb/shared/userAvatar'

import useStyles, { listItemTextProps } from './styles'

const ParticipantsListItem = ({ user, onSelect }) => {
  const classes = useStyles()

  return (
    <ListItem
      button
      className={classes.container}
      onClick={onSelect}
      title={`Send private message to ${user.displayName}`}
    >
      <ListItemAvatar className={classes.avatarContainer}>
        <UserAvatar className={classes.avatar} user={user} />
      </ListItemAvatar>
      <ListItemText className={classes.name} primary={user.displayName} secondary={user.email} {...listItemTextProps} />
    </ListItem>
  )
}

ParticipantsListItem.propTypes = {
  user: PropTypes.shape({
    avatarUrl: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default memo(ParticipantsListItem)
