import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { contentStateSelectors } from '@tabeeb/shared/content'

const PresenterControls = ({ children }) => {
  const isPresenter = useSelector(contentStateSelectors.getIsCurrentUserPresenter)
  if (!isPresenter) {
    return null
  }

  return children
}

PresenterControls.propTypes = {
  children: PropTypes.any.isRequired,
}

export default memo(PresenterControls)
