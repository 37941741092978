import { combineReducers } from 'redux'

import isCallProcessing from './isCallProcessing'
import currentVideoBridge from './currentVideoBridge'
import isContentLoading from './isContentLoading'

export default combineReducers({
  isCallProcessing,
  currentVideoBridge,
  isContentLoading,
})
