import { resetContentState } from '@tabeeb/modules/shared/content/actions'
import { combineActions, handleActions } from 'redux-actions'

import { showSelfView, hideSelfView } from '../actions'

const defaultValue = true

export default handleActions(
  {
    [combineActions(resetContentState, showSelfView)]() {
      return true
    },
    [hideSelfView]() {
      return false
    },
  },
  defaultValue
)
