import { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Collapse, ListItem, Divider, withStyles, Fade, IconButton } from '@material-ui/core'

import { TopicType } from '@tabeeb/enums'
import Chat from '@tabeeb/modules/chat'

import styles from './styles'
import { AnswerInfo } from '../../Info'

const AnswersListItem = ({ classes, answer, answerInfoProps }) => {
  const [isDiscussionOpened, setIsDiscussionOpened] = useState(false)

  const handleOpenDisussion = useCallback(() => {
    setIsDiscussionOpened(!isDiscussionOpened)
  }, [isDiscussionOpened])

  return (
    <Fade in>
      <ListItem className={classes.container}>
        <div className={classes.content}>
          <AnswerInfo answer={answer} {...answerInfoProps} />
          <IconButton
            className={classes.action}
            onClick={handleOpenDisussion}
            title={isDiscussionOpened ? 'Collapse answer discussion' : 'Expand answer discussion'}
          >
            {isDiscussionOpened ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <Collapse in={isDiscussionOpened} unmountOnExit>
          <div className={classes.chat}>
            <Divider />
            <Chat topicType={TopicType.FormAnswer} topicId={answer.Id} />
          </div>
        </Collapse>
      </ListItem>
    </Fade>
  )
}

AnswersListItem.propTypes = {
  classes: PropTypes.shape({
    chat: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }).isRequired,
  answer: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  answerInfoProps: PropTypes.object.isRequired,
}

export default memo(withStyles(styles)(AnswersListItem))
