export default (theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowY: 'scroll',
    },
    progress: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
    list: {
      margin: 0,
      padding: theme.spacing(),

      '& li': {
        marginBottom: theme.spacing(),

        '&:last-child': {
          marginBottom: 'unset',
        },
      },
    },
  }
}
