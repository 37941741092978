export const BYTES_IN_MEGABYTES = 1048576

export const DEFAULT_SESSIONS_TITLE = 'Sessions'

export const GUIDRegexp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

export const IdentityRoles = {
  PowerBIDeveloper: 'PowerBIDeveloper',
  PowerBIAdmin: 'PowerBIAdmin',
}

export const AllPowerBIIdentityRoles = [IdentityRoles.PowerBIAdmin, IdentityRoles.PowerBIDeveloper]

export const DEFAULT_TRAINING_MATERIALS_TITLE = 'Training materials'
