import { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactAudioPlayer, { RHAP_UI } from 'react-h5-audio-player'

import usePlayingAudioUrl from './usePlayingAudioUrl'

const AudioPlayer = ({ url, ...restProps }) => {
  const [playingAudioUrl, setPlayingAudioURl] = usePlayingAudioUrl()
  const playerRef = useRef(null)

  useEffect(() => {
    const player = playerRef.current
    const isPlaying = player?.isPlaying()

    if (playingAudioUrl !== url && isPlaying) {
      player.audio.current.pause()
    }
  }, [playingAudioUrl, url])

  const onPlayStart = useCallback(() => {
    setPlayingAudioURl(url)
  }, [url])

  return (
    <ReactAudioPlayer
      ref={playerRef}
      showJumpControls={false}
      showSkipControls={false}
      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
      loop={false}
      preload='metadata'
      layout='horizontal-reverse'
      src={url}
      onPlay={onPlayStart}
      {...restProps}
    />
  )
}

AudioPlayer.propTypes = {
  url: PropTypes.string.isRequired,
}

export default AudioPlayer
