import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'
import * as rawActions from '../actions/index'
import OptionsDialog from '../components/OptionsDialog'
import { ExportFormsToCsvOptions } from '../../../Enums'

class ExportFormsToCsvOptionsDialogContainer extends Component {
  _setOption = (event) => {
    const {
      actions: { setExportFormsToCsvOptionState },
    } = this.props

    setExportFormsToCsvOptionState(parseInt(event.target.value))
  }

  _next = () => {
    const {
      actions: { exportFormToCsv, closeExportFormsToCsvDialog },
    } = this.props

    exportFormToCsv()
    closeExportFormsToCsvDialog()
  }

  render() {
    const {
      formTitle,
      isExportFormsToCsvDialogOpen,
      exportFormsToCsvOptionState,
      actions: { closeExportFormsToCsvDialog },
    } = this.props

    const exportFormsToCsvOptionsDisplayName = {
      CurrentForm: `Current ${formTitle}`,
      All: 'All',
    }

    const exportOptions = []
    for (const key in ExportFormsToCsvOptions) {
      exportOptions.push({
        Id: ExportFormsToCsvOptions[key],
        Value: exportFormsToCsvOptionsDisplayName[key],
      })
    }

    const props = {
      isOpen: isExportFormsToCsvDialogOpen,
      closeDialog: closeExportFormsToCsvDialog,
      next: this._next,
      setOption: this._setOption,
      selectedOption: exportFormsToCsvOptionState,
      exportOptions: exportOptions.reverse(),
    }
    return <OptionsDialog {...props} />
  }
}

ExportFormsToCsvOptionsDialogContainer.propTypes = {
  isExportFormsToCsvDialogOpen: PropTypes.bool.isRequired,
  exportFormsToCsvOptionState: PropTypes.number.isRequired,
  actions: PropTypes.shape({
    closeExportFormsToCsvDialog: PropTypes.func.isRequired,
    exportFormToCsv: PropTypes.func.isRequired,
    setExportFormsToCsvOptionState: PropTypes.func.isRequired,
  }),
  formTitle: PropTypes.string.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

function mapStateToProps(state) {
  return {
    isExportFormsToCsvDialogOpen: state.forms.isExportFormsToCsvDialogOpen,
    exportFormsToCsvOptionState: state.forms.exportFormsToCsvOptionState,
    formTitle: getFormTitle(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportFormsToCsvOptionsDialogContainer)
