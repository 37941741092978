import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = ''

export default handleActions(
  {
    [rawActions.updateFormsSearchText](state, action) {
      return action.payload
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
