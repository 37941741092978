const annotationRequests = {
  addAnnotationRequest: (data) => ({
    url: `api/annotation/addAnnotation`,
    method: 'post',
    data,
  }),
  addAudioAnnotationRequest: ({ data, contentId }) => ({
    url: `api/uploads/audio`,
    method: 'post',
    data: {
      ...data,
      ContentId: contentId,
    },
  }),
  deleteAnnotationsRequest: ({ annotationIds }) => ({
    url: `api/annotation`,
    method: 'delete',
    data: {
      annotationIds,
    },
  }),
  updateAnnotationRequest: ({ annotation }) => ({
    url: `api/annotation`,
    method: 'put',
    data: annotation,
  }),
  restoreAnnotationsRequest: ({ annotationIds }) => ({
    url: `api/annotation/restore`,
    method: 'post',
    data: {
      annotationIds,
    },
  }),
  updateAiAnnotationRequest: (data) => ({
    url: `api/annotation`,
    method: 'put',
    data,
  }),
  getAnnotationsByPageRequest: (pageId) => ({
    url: `api/annotation/getAnnotationsByPageId/${pageId}`,
    method: 'get',
  }),
  getAnnotationTextByCoordinatesRequest: ({ pageId, x, y }) => ({
    url: `api/annotation/getAnnotationsTextByCoordinates/${pageId}/${x}/${y}`,
    method: 'get',
  }),
  addClassificationAnnotationsRequest: (data) => ({
    url: `api/annotation/classification`,
    method: 'post',
    data,
  }),
}

export default annotationRequests
