import { all, takeLatest, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { notificationActions } from '@tabeeb/modules/notification'
import Routes from '@tabeeb/routes'
import * as rawActions from '../actions'

function* powerBIReportProviderCreated() {
  yield put(rawActions.loadPowerBIReportProviders())
  yield put(rawActions.resetCheckPowerBIReportProviderConnection())
}

function* powerBIReportProviderDeleted(_action) {
  yield put(push(Routes.powerBIReportProviders))
}

function* powerBIReportProviderConfigurationUpdated() {
  yield put(notificationActions.onAddInfoNotification({ message: 'Configuration updated' }))
  yield put(rawActions.setPowerBIReportProviderConfigurationFetchRequired(true))
  yield put(rawActions.closePowerBIProviderModal())
}

function* powerBIReportProviderConfigurationUpdateFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to update configuration'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportProviderCreateFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to create provider'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportProviderUpdateFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to update provider'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* powerBIReportProviderUsersUpdateFailed(action) {
  const message = action?.response?.data?.Error?.Message || 'Failed to udpate provider users'
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* reportProvidersSaga() {
  yield all([
    takeLatest(rawActions.createPowerBIReportProvider.success, powerBIReportProviderCreated),
    takeLatest(rawActions.deletePowerBIReportProvider.success, powerBIReportProviderDeleted),
    takeLatest(rawActions.updatePowerBIReportProviderConfiguration.success, powerBIReportProviderConfigurationUpdated),
    takeLatest(
      rawActions.updatePowerBIReportProviderConfiguration.failed,
      powerBIReportProviderConfigurationUpdateFailed
    ),
    takeLatest(rawActions.createPowerBIReportProvider.failed, powerBIReportProviderCreateFailed),
    takeLatest(rawActions.updatePowerBIReportProvider.failed, powerBIReportProviderUpdateFailed),
    takeLatest(rawActions.updatePowerBIReportProviderUsers.failed, powerBIReportProviderUsersUpdateFailed),
  ])
}

export default reportProvidersSaga
