import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TextFieldsOutlined } from '@material-ui/icons'

import { AnnotationType } from '@tabeeb/enums'
import { drawingActions, playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import ToolbarButton from '../ToolbarButton'

const BUTTON_NAME = 'text'

const TextButton = () => {
  const dispatch = useDispatch()

  const color = useSelector(playerToolbarSelectors.getSelectedColor)
  const enabled = useSelector((state) => state.playerToolbar.drawing.buttonState === BUTTON_NAME)

  const onClick = () => {
    if (enabled) {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: '' }))
      dispatch(drawingActions.onDisableDrawing())
    } else {
      dispatch(drawingActions.onSetDrawingType(AnnotationType.Text))
      dispatch(drawingActions.onChangePressedButton({ pressedButton: BUTTON_NAME }))
      dispatch(drawingActions.onEnableDrawing())
    }
  }

  return (
    <ToolbarButton
      Icon={TextFieldsOutlined}
      title='Add Text annotation'
      onClick={onClick}
      selected={enabled}
      htmlColor={enabled ? color : null}
    />
  )
}

export default memo(TextButton)
