export default (theme) => {
  return {
    text: {
      maxWidth: '90%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginRight: 5,
      fontSize: 12,
    },
  }
}
