import { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'

import { ListItemIcon, ListItemText, MenuItem as MuiMenuItem } from '@mui/material'

const MenuItem = forwardRef(({ icon: Icon, endIcon: EndIcon, title, onClick }, ref) => {
  return (
    <MuiMenuItem ref={ref} onClick={onClick} title={title}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
      {EndIcon && <EndIcon fontSize='small' sx={{ ml: 2 }} />}
    </MuiMenuItem>
  )
})

MenuItem.propTypes = {
  icon: PropTypes.elementType.isRequired,
  endIcon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(MenuItem)
