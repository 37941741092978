import { handleActions } from 'redux-actions'
import * as galleryActions from '../actions'

const defaultState = {
  selectedGalleryItemId: 0,
  selectedFolderId: null,
}

export default handleActions(
  {
    [galleryActions.selectGalleryItem](state, action) {
      return { ...state, selectedGalleryItemId: action.payload }
    },
    [galleryActions.selectSessionFolder](state, action) {
      const { folderId } = action.payload
      return { ...state, selectedFolderId: folderId }
    },
    [galleryActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
