import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
  },
  sortingButton: {
    '&.MuiButton-root': {
      padding: `${theme.spacing(1)}px 2px`,
      textTransform: 'none',
      minWidth: 0,
    },
  },
}))
