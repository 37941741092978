import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../actions'

const initialState = {
  providers: [],
  totalCount: 0,
  fetchStatus: FetchStatus.Idle,
  filters: {
    pageSize: 25,
    pageNumber: 1,
  },
}

export default handleActions(
  {
    [rawActions.getPowerBIReportProviders.request]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [rawActions.getPowerBIReportProviders.success]: (state, action) => {
      const { Providers, TotalCount } = action.response.data
      return { ...state, fetchStatus: FetchStatus.Loaded, providers: Providers, totalCount: TotalCount }
    },
    [rawActions.getPowerBIReportProviders.failed]: (state, _action) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
    [rawActions.setPowerBIReportProvidersFilters]: (state, action) => {
      return { ...state, filters: { ...state.filters, ...action.payload } }
    },
  },
  initialState
)
