import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    marginBlockEnd: theme.spacing(2),
  },
  contentHeader: {
    padding: theme.spacing(2),
  },
  contentForm: {
    paddingInline: theme.spacing(2),
  },
  formGroupHeader: {
    marginBlockEnd: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
  },
}))
