import { combineActions, handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = 1

export default handleActions(
  {
    [rawActions.setPageNumber](state, action) {
      return action.payload
    },
    [rawActions.updatePageNumber](state, action) {
      return action.payload
    },
    [combineActions(
      rawActions.selectTenantSessionsMode,
      rawActions.loadTenantContents,
      rawActions.setPageSize,
      rawActions.setStartDate,
      rawActions.setEndDate,
      rawActions.setSortingColumn,
      rawActions.getTenantContentsDashboardColumns.success,
      rawActions.resetState
    )]() {
      return defaultState
    },
  },
  defaultState
)
