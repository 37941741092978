import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { goToPage } from '@tabeeb/modules/spatialModel/actions'
import { openHotspotDialog } from '@tabeeb/modules/annotations/actions'
import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { closeAiReportPublishSettingsDialog, goToAnnotation, setAiObjectAnalysisNeedToRefresh } from '../actions'
import { getIsUniqueAIObjectAnalysisDialogOpen } from '../selectors'
import { createSheet } from '@tabeeb/modules/contentMetadata/actions'

function* onGoToAnnotation({ payload }) {
  const { PageId, AnnotationId } = payload

  const selectedGalleryItemId = yield select(getSelectedGalleryItemId)

  if (PageId !== selectedGalleryItemId) {
    yield put(goToPage(PageId))
  }

  yield put(openHotspotDialog({ annotationId: AnnotationId }))
}

function* onUniqueAIObjectUpdated(action) {
  const isDialogOpen = yield select(getIsUniqueAIObjectAnalysisDialogOpen)
  if (isDialogOpen) {
    yield put(setAiObjectAnalysisNeedToRefresh(true))
  }
}

function* onAnnotationMutate(action) {
  const isDialogOpen = yield select(getIsUniqueAIObjectAnalysisDialogOpen)
  if (isDialogOpen) {
    yield put(setAiObjectAnalysisNeedToRefresh(true))
  }
}

function* onPublishAiReportSuccess(action) {
  yield put(onAddSuccessNotification({ message: 'Report has been published successfully' }))
  yield put(closeAiReportPublishSettingsDialog())
}

function* onPublishAiReportFailed(action) {
  yield put(onAddErrorNotification({ message: 'Sorry, an error occurred while publishing the report' }))
}

function* aiReportSagas() {
  yield all([
    takeLatest(goToAnnotation, onGoToAnnotation),
    takeLatest(createSheet.success, onPublishAiReportSuccess),
    takeLatest(createSheet.failed, onPublishAiReportFailed),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onUniqueAIObjectUpdated, onUniqueAIObjectUpdated),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onAnnotationUpdate, onAnnotationMutate),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onAnnotationDeleted, onAnnotationMutate),
  ])
}

export default aiReportSagas
