import { put, takeLatest, select, all } from 'redux-saga/effects'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import { addEditedAnswer, onContentReviewCompleted, onReviewFeedbackSent } from '@tabeeb/modules/contentReviews/actions'
import * as rawActions from '../actions'
import { deleteGalleryItemSuccess, onDeleteGalleryItem } from '../../gallery/actions'
import { getIsCurrentUserReviewer } from '../../../users/selectors'
import { getIsContentFormOnReview } from '../selectors'
import { mapAnswersStatusesForReview } from '../services/mapAnswersStatusesForReview'

function* setAnswersStatusesCount(action) {
  const { data } = action.response
  if (!data) {
    return
  }
  yield put(rawActions.setAnswersStatusesCount(data.FormAnswerStatuses))
}

function* getAnswersStatusesCount() {
  const { currentForm } = yield select((state) => state.forms)
  if (currentForm.ContentFormId)
    yield put(rawActions.getAnswersStatusesCountRequest({ contentFormId: currentForm.ContentFormId }))
}

function* onAnswerStatusUpdatedByReviewer(action) {
  const [updatedAnswer] = action.payload
  const isReviewer = yield select(getIsCurrentUserReviewer)

  if (isReviewer) {
    yield put(addEditedAnswer(updatedAnswer))
    yield put(rawActions.updateAnswerStatus(updatedAnswer))
  }
}

function* setFormAnswerStatus(action) {
  let statusModel = action.payload
  const isReviewer = yield select(getIsCurrentUserReviewer)

  if (isReviewer) {
    yield put(rawActions.updateReviewerAnswerStatusRequest(statusModel))
  } else {
    const isFormOnReview = yield select(getIsContentFormOnReview)
    if (isFormOnReview) {
      statusModel = { ...statusModel, FormAnswerStatus: mapAnswersStatusesForReview(statusModel.FormAnswerStatus) }
    }

    yield put(rawActions.updateAnswerStatusRequest(statusModel))
  }
}

function* formAnswersStatus() {
  yield all([
    takeLatest(rawActions.getAnswersStatusesCountSuccess, setAnswersStatusesCount),
    takeLatest(
      [deleteGalleryItemSuccess, onDeleteGalleryItem, onReviewFeedbackSent, onContentReviewCompleted],
      getAnswersStatusesCount
    ),
    takeLatest(
      signalrEvents[signalrConstants.tabeebHubName].onAnswerStatusUpdatedByReviewer,
      onAnswerStatusUpdatedByReviewer
    ),
    takeLatest(rawActions.setFormAnswerStatus, setFormAnswerStatus),
  ])
}

export default formAnswersStatus
