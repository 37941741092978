import { HubConnectionState } from '@microsoft/signalr'

export const getSignalRState = (state) => state.signalr
export const getSignalRConnection = (state, hubName) => state.signalr[hubName]?.connection
export const getSignalRProgressState = (state, hubName) => state.signalr[hubName]?.isInProgress
export const getSignalRConnectionState = (state, hubName) => {
  const connection = getSignalRConnection(state, hubName)
  return Boolean(
    connection &&
      connection._connectionState !== HubConnectionState.Disconnected &&
      connection._connectionState !== HubConnectionState.Disconnecting
  )
}
