import { memo } from 'react'
import PropTypes from 'prop-types'

import FoldersItem from '../FoldersItem'
import './styles.less'

const FoldersList = ({ isOpen, list, openFolder }) => {
  return isOpen ? (
    <div className='shared-folders-list'>
      {list?.map((folder) => {
        return <FoldersItem folder={folder} open={openFolder} key={folder.id} />
      })}
    </div>
  ) : null
}

FoldersList.propTypes = {
  list: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openFolder: PropTypes.func.isRequired,
}

export default memo(FoldersList)
