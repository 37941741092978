import { memo } from 'react'
import PropTypes from 'prop-types'

import { Slider, Typography } from '@material-ui/core'
import { LensBlurOutlined } from '@mui/icons-material'

import { usePopoverState } from '@tabeeb/shared/utils/hooks'
import { Defaults } from '@tabeeb/modules/pointCloud/constants'

import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'

import PointCloudSettingsButtonContainer from './Container'

import useStyles from './styles'

const PointCloudSettingsButton = ({ defaultPointSize, onChange, onSubmit }) => {
  const classes = useStyles()
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  return (
    <>
      <ToolbarButton ref={anchorRef} onClick={onOpen} title='Point cloud settings' Icon={LensBlurOutlined} />
      <ToolbarPopover anchorEl={anchorRef.current} spacing={2} open={open} onClose={onClose}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography id='transparency-slider' color='textSecondary' variant='body2'>
              Point size
            </Typography>
          </div>
          <Slider
            min={0.000001}
            max={10}
            step={0.000001}
            valueLabelFormat={(value) => value.toFixed(Defaults.CALCULATIONS_PRECISION)}
            valueLabelDisplay='auto'
            marks={[
              {
                value: 0,
                label: '0',
              },
              {
                value: 10,
                label: '10',
              },
            ]}
            defaultValue={defaultPointSize}
            onChange={(_, newPointSize) => onChange(newPointSize)}
            onChangeCommitted={(_, newPointSize) => onSubmit(newPointSize)}
          />
        </div>
      </ToolbarPopover>
    </>
  )
}

PointCloudSettingsButton.propTypes = {
  defaultPointSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(PointCloudSettingsButton)

export { PointCloudSettingsButtonContainer }
