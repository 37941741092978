import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 48,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))
