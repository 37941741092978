import PropTypes from 'prop-types'

import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import { ActivityTypeFilterItems } from '@tabeeb/enums'

import './styles.less'

const ActivityTypeFilter = ({ activityType, handleSelectActivityTypeFilter }) => {
  return (
    <div className='activity-filters-item activity-filters-type'>
      <FormControl className='activity-filters-item-control'>
        <InputLabel>Type</InputLabel>
        <Select
          value={activityType}
          onChange={handleSelectActivityTypeFilter}
          MenuProps={{ id: 'activity-filters-menu' }}
        >
          {Object.keys(ActivityTypeFilterItems).map((activityType) => (
            <MenuItem value={activityType} key={activityType}>
              <span className='activity-filters-menu-text' title={ActivityTypeFilterItems[activityType]}>
                {ActivityTypeFilterItems[activityType]}
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

ActivityTypeFilter.propTypes = {
  activityType: PropTypes.string.isRequired,
  handleSelectActivityTypeFilter: PropTypes.func.isRequired,
}

export default ActivityTypeFilter
