import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.less'

const PdfSearchResults = ({
  searchResults,
  handleSetPdfSearchText,
  selectedSearchResult,
  togglePageResults,
  disabledPages,
}) => {
  const { matches } = searchResults
  const { contents } = searchResults
  const { searchText } = searchResults

  const replaceAt = (string, stringToFind, replaceString, atIndex) => {
    if (string.toLowerCase().indexOf(stringToFind.toLowerCase()) < 0) return string

    const regex = new RegExp(stringToFind, 'i')
    const split = string.split(regex)
    let returnStr = ''

    for (let i = 0; i < split.length; i++) {
      const str = split[i]
      returnStr += str

      if (atIndex === i) returnStr += replaceString
      else returnStr += stringToFind
    }

    return returnStr
  }

  const isLetter = (str) => {
    const code = str.charCodeAt(0)
    return (code >= 65 && code <= 90) || (code >= 97 && code <= 122)
  }

  const escapeHtml = (text) => {
    return text.replace(/[\"&<>]/g, function (a) {
      return { '"': '&quot;', '&': '&amp;', '<': '&lt;', '>': '&gt;' }[a]
    })
  }

  const setSearchResults = (matches, contents, searchText, pageIndex) => {
    const formattedMatches = []
    if (matches) {
      let textContent = contents[pageIndex]

      textContent = escapeHtml(textContent)
      const trailingTextCharCount = 50

      matches.map((characterPosition, index) => {
        const content = replaceAt(textContent, searchText, 'REPLACE_MARKER', index)
        let displayText = content.substring(
          Math.max(0, characterPosition - trailingTextCharCount),
          Math.min(content.length, characterPosition + trailingTextCharCount)
        )
        let inc = 0

        if (characterPosition - trailingTextCharCount > 0) {
          inc = trailingTextCharCount + 1
          while (
            characterPosition - inc > 0 &&
            isLetter(content.substring(characterPosition - inc, characterPosition))
          ) {
            var characterAtPos = content.substring(characterPosition - inc, characterPosition - inc + 1)

            displayText = characterAtPos + displayText

            if (characterAtPos.toUpperCase() === characterAtPos) break
            inc++
          }
        }

        if (characterPosition + trailingTextCharCount < content.length) {
          inc = trailingTextCharCount + 1
          while (
            characterPosition + inc < content.length &&
            isLetter(content.substring(characterPosition + inc - 1, characterPosition + inc))
          ) {
            characterAtPos = content.substring(characterPosition + inc - 1, characterPosition + inc)
            displayText += characterAtPos
            inc++
          }
        }

        const splittedText = displayText.split('REPLACE_MARKER')

        formattedMatches.push({
          text: splittedText,
          matchIndex: index,
        })
      })
    }
    return (
      <div
        className={classNames('pageResultsContent', {
          disabledPage: disabledPages.find((item) => item === pageIndex + 1),
        })}
        data-page-index={pageIndex}
      >
        {formattedMatches.map((result, index) => {
          return (
            <div
              className={classNames('result', {
                selectedResult:
                  selectedSearchResult.pageIndex === pageIndex && selectedSearchResult.matchIndex === result.matchIndex,
              })}
              data-page-index={pageIndex}
              data-match-index={result.matchIndex}
              onClick={handleSetPdfSearchText}
              key={result.matchIndex}
            >
              {result.text[0]}
              <span className='pdfSearchMatch' data-page-index={pageIndex} data-match-index={result.matchIndex}>
                {searchText}
              </span>
              {result.text[1]}

              <span className='triangleBorder' />
              <span className='pageNumber'>{pageIndex + 1}</span>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className='pdfSearchResults'>
      {matches &&
        matches.map((pageMatches, index) => {
          if (pageMatches.length > 0) {
            return (
              <div key={index} className='pageResultsContainer'>
                <div className='pageResultsTitle' onClick={() => togglePageResults(index + 1)}>
                  {disabledPages.find((item) => item === index + 1) && <span className='chevron-up' />}
                  {!disabledPages.find((item) => item === index + 1) && <span className='chevron-down' />}

                  <span className='resultTitleText'>{`Page ${index + 1}`}</span>
                </div>
                {setSearchResults(pageMatches, contents, searchText, index)}
              </div>
            )
          }
        })}
    </div>
  )
}

PdfSearchResults.propTypes = {
  searchResults: PropTypes.object,
  handleSetPdfSearchText: PropTypes.func.isRequired,
  selectedSearchResult: PropTypes.object,
  disabledPages: PropTypes.array,
  togglePageResults: PropTypes.func.isRequired,
}

export default PdfSearchResults
