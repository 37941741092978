import PropTypes from 'prop-types'
import { Popover, Typography, List, ListItem } from '@material-ui/core'
import { CameraAlt } from '@material-ui/icons'
import classNames from 'classnames'

import useVideoSettings from '@tabeeb/modules/presentation/hooks/useVideoSettings'

import './styles.less'

const VideoSettings = ({
  anchorEl,
  isOpen,
  onClose,
  showVideoPreview = true,
  originPosition = {
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    transformOrigin: { vertical: 'center', horizontal: 'right' },
  },
}) => {
  const { showDevices, selectedVideoInputDevice, handleVideoInputDeviceClick, videoInputDevices } = useVideoSettings({
    open: isOpen,
  })

  return (
    <Popover
      className='user-options-controls-popover'
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      {...originPosition}
    >
      <div className='section'>
        <div className='section-title'>
          <CameraAlt className='title-icon' color='disabled' />
          <Typography className='title-text'>Cameras</Typography>
        </div>
        <List className='section-content'>
          {showDevices ? (
            videoInputDevices.map((device) => (
              <ListItem
                key={device.deviceId}
                button
                title={device.label}
                onClick={() => handleVideoInputDeviceClick(device.deviceId)}
                selected={device.deviceId === selectedVideoInputDevice}
              >
                {showVideoPreview ? (
                  <div className='camera-preview'>
                    <video
                      className={classNames('tabeebUserVideo', 'video')}
                      data-id={device.deviceId}
                      autoPlay='autoPlay'
                      onContextMenu={() => false}
                    />
                    <Typography className='preview-label' noWrap>
                      {device.label}
                    </Typography>
                  </div>
                ) : (
                  <Typography noWrap>{device.label}</Typography>
                )}
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography noWrap>Devices are not detected</Typography>
            </ListItem>
          )}
        </List>
      </div>
    </Popover>
  )
}

VideoSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  showVideoPreview: PropTypes.bool,
  originPosition: PropTypes.object,
}

export default VideoSettings
