import { all } from 'redux-saga/effects'

import addModel from './addModel'
import addUniqueAIObjects from './addUniqueAIObjects'
import counterListValues from './counterListValues'
import deleteAiClass from './deleteAiClass'
import deleteModel from './deleteModel'
import getAiObjects from './getAiObjects'
import getUniqueAIObjects from './getUniqueAIObjects'
import resetState from './resetState'
import updateUniqueAIObject from './updateUniqueAIObject'
import updateAIObject from './updateAIObject'

function* artificialIntelligence() {
  yield all([
    addModel(),
    addUniqueAIObjects(),
    counterListValues(),
    deleteAiClass(),
    deleteModel(),
    getAiObjects(),
    getUniqueAIObjects(),
    resetState(),
    updateUniqueAIObject(),
    updateAIObject(),
  ])
}

export default artificialIntelligence
