import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Videocam, VideocamOff } from '@mui/icons-material'

import useMuteUserVideo from '@tabeeb/modules/presentation/hooks/useMuteUserVideo'
import UserActionButton from '../UserActionButton'

const MuteUserVideoButton = ({ user }) => {
  const { isMuted, isPresenter, currentUserId, handleClick } = useMuteUserVideo({ user })

  const icon = useMemo(() => {
    return isMuted ? VideocamOff : Videocam
  }, [isMuted])

  return (
    <UserActionButton
      icon={icon}
      title={isMuted ? 'Unmute video' : 'Mute video'}
      disabled={!isPresenter && currentUserId !== user.id}
      isMuted={isMuted}
      onClick={handleClick}
    />
  )
}

MuteUserVideoButton.propTypes = {
  user: PropTypes.shape({
    isOnline: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }),
}

export default memo(MuteUserVideoButton)
