import PropTypes from 'prop-types'

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from '../CheckBox/styles'

const RadioButtons = ({ disabled, control, answer, handleChange, classes }) => {
  const { Label, Options, Tooltip } = control
  const selectedOptionId = answer.selectedOptionsIds ? answer.selectedOptionsIds[0] : null

  const _getCss = (option) => {
    const isPositiveOption = option.Value.toLowerCase() === 'pass' && !disabled
    const isNegativeOption = option.Value.toLowerCase() === 'fail' && !disabled

    if (isPositiveOption) return classes.green
    if (isNegativeOption) return classes.red
    return classes.default
  }

  return (
    <RadioGroup name={Label} style={{ overflow: 'hidden' }} onChange={handleChange} value={selectedOptionId}>
      {Options &&
        Options.map((option) => {
          return (
            <FormControlLabel
              key={option.Id}
              value={option.Id}
              label={option.Value}
              disabled={disabled}
              title={Tooltip}
              control={<Radio color='default' classes={{ root: _getCss(option), checked: classes.checked }} />}
            />
          )
        })}
    </RadioGroup>
  )
}

RadioButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    green: PropTypes.string.isRequired,
    red: PropTypes.string.isRequired,
    default: PropTypes.string.isRequired,
    checked: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(RadioButtons)
