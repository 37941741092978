import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = null

export default handleActions(
  {
    [rawActions.setSelectedTagForAiObjectAttach](state, action) {
      return action.payload
    },
    [rawActions.attachAiObjectToTagSuccess](state, { response }) {
      if (state === defaultState) {
        return state
      }

      return { ...state, AIObjects: [...state.AIObjects, response.data] }
    },
    [rawActions.detachAiObjectFromTagSuccess](state, { payload }) {
      if (state === defaultState) {
        return state
      }
      const deletedAIObjectId = payload.aiObjectId
      return { ...state, AIObjects: state.AIObjects.filter((object) => object.Id !== deletedAIObjectId) }
    },
  },
  defaultState
)
