import { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { AnswerStatuses, ControlType } from '@tabeeb/enums'
import { useDispatch, useSelector, connect } from 'react-redux'
import { Paper, withStyles } from '@material-ui/core'
import { getIsDateControl, getIsTextControl } from '@tabeeb/modules/createFormPage/helpers/controlTypesHelper'
import {
  getDisplayAnswersMode,
  getIsFormControlOnReviewInOriginalContent,
  getIsFormControlDisabled,
  getMappedFormControlAnswer,
} from '@tabeeb/modules/forms/selectors'
import { answersPropsAreEqual } from '@tabeeb/modules/forms/services/compareAnswersService'
import { addFormAnswers } from '@tabeeb/modules/forms/actions'
import FormControlLabel from '@tabeeb/modules/forms/components/FormControlLabel'
import FormControlButtons from '@tabeeb/modules/forms/components/FormControlButtons'
import FormControlAnswers from '@tabeeb/modules/forms/components/FormControlAnswers'

import styles from './styles'

const FormQuestion = ({ control, answer, classes }) => {
  const [currentAnswer, setCurrentAnswer] = useState(answer)
  const [isEditingAnswer, setIsEditingAnswer] = useState(false)
  const displayAnswersMode = useSelector(getDisplayAnswersMode)

  useEffect(() => {
    setCurrentAnswer(answer)
  }, [answer])

  useEffect(() => {
    if (isEditingAnswer) {
      _cancelUpdateAnswer()
    }
  }, [displayAnswersMode])

  const dispatch = useDispatch()
  const isDisabled = useSelector((state) => getIsFormControlDisabled(state, { control, isEditingAnswer }))
  const isOnReview = useSelector((state) => getIsFormControlOnReviewInOriginalContent(state, control))
  const isTextControl = getIsTextControl(control.ControlType)
  const isDateControl = getIsDateControl(control.ControlType)

  const getContainerCss = () => {
    if (!answer.Id) return classes.container

    switch (answer.FormAnswerStatus) {
      case AnswerStatuses.Pending:
        return classes.pendingContainer
      case AnswerStatuses.Approve:
      case AnswerStatuses.ApprovedByAI:
      case AnswerStatuses.ApprovedByReviewer:
        return classes.approvedContainer
      case AnswerStatuses.Reject:
      case AnswerStatuses.RejectedByAI:
      case AnswerStatuses.RejectedByReviewer:
        return classes.rejectedContainer
      default:
        return classes.container
    }
  }

  const _handleChange = (eventOrDateValue) => {
    let value
    let isChecked
    let numberValue

    if (isDateControl) value = eventOrDateValue
    else {
      value = eventOrDateValue.target.value
      isChecked = eventOrDateValue.target.checked
      numberValue = parseInt(value, 10)
    }

    const newAnswer = { ...currentAnswer }

    if (isTextControl || isDateControl) newAnswer.value = value
    else if (control.ControlType === ControlType.CheckboxList) {
      const alreadySelectedOptions = currentAnswer.selectedOptionsIds ? currentAnswer.selectedOptionsIds : []

      if (isChecked) newAnswer.selectedOptionsIds = [...alreadySelectedOptions, numberValue]
      else newAnswer.selectedOptionsIds = alreadySelectedOptions.filter((id) => id !== numberValue)
    } else {
      newAnswer.selectedOptionsIds = [numberValue]
    }

    if (isEditingAnswer || isTextControl) setCurrentAnswer(newAnswer)
    else dispatch(addFormAnswers({ newAnswer, prevAnswer: answer, control }))
  }

  const _handleSubmit = () => {
    if (isEditingAnswer) setIsEditingAnswer(false)
    dispatch(addFormAnswers({ newAnswer: currentAnswer, prevAnswer: answer, control }))
  }

  const _cancelUpdateAnswer = () => {
    setIsEditingAnswer(false)
    setCurrentAnswer(answer)
  }

  const answerProps = {
    control,
    handleChange: _handleChange,
    handleSubmit: _handleSubmit,
    cancelUpdateAnswer: _cancelUpdateAnswer,
    answer: currentAnswer,
    disabled: isDisabled || isOnReview,
    isEditingAnswer,
  }

  const buttonsProps = {
    control,
    handleSubmit: _handleSubmit,
    cancelUpdateAnswer: _cancelUpdateAnswer,
    answer,
    isEditingAnswer,
    setIsEditingAnswer,
    isOnReview,
  }

  return (
    <Paper className={getContainerCss()} variant='outlined'>
      <FormControlButtons {...buttonsProps} />
      <div className={classes.question}>
        <FormControlLabel control={control} isOnReview={isOnReview} />
        <FormControlAnswers {...answerProps} />
      </div>
    </Paper>
  )
}

FormQuestion.propTypes = {
  control: PropTypes.shape({
    Type: PropTypes.number.isRequired,
    ControlType: PropTypes.number.isRequired,
  }).isRequired,
  answer: PropTypes.shape({
    Id: PropTypes.number,
    FormAnswerStatus: PropTypes.number,
  }),
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    pendingContainer: PropTypes.string.isRequired,
    approvedContainer: PropTypes.string.isRequired,
    rejectedContainer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
}

const mapStateToProps = (state, props) => {
  return {
    answer: getMappedFormControlAnswer(state, { control: props.control }),
  }
}

function propsAreEqual(prevProps, nextProps) {
  return answersPropsAreEqual(prevProps.answer, nextProps.answer)
}

export default connect(mapStateToProps)(memo(withStyles(styles)(FormQuestion), propsAreEqual))
