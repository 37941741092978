import { combineReducers } from 'redux'

import vqaPolicies from './vqaPolicies'
import vqaPolicyForEdit from './vqaPolicyForEdit'
import tags from './tags'
import aiObjects from './aiObjects'
import vqaPolicyToDelete from './vqaPolicyToDelete'
import isVQAPoliciesLoading from './isVQAPoliciesLoading'

export default combineReducers({
  vqaPolicies,
  vqaPolicyForEdit,
  aiObjects,
  tags,
  vqaPolicyToDelete,
  isVQAPoliciesLoading,
})
