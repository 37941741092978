import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'

import styles from './styles'

const ConnectionQualityDetails = ({ classes, bitrate }) => {
  return (
    <div className={classes.container}>
      <div className={classes.bitrateContainer}>
        <ArrowUpward className={classes.upstream} />
        <Typography variant='caption' color='secondary'>
          {bitrate?.download || 'N/A'}
        </Typography>
      </div>
      <div className={classes.bitrateContainer}>
        <ArrowDownward className={classes.downstream} />
        <Typography variant='caption' color='secondary'>
          {bitrate?.upload || 'N/A'}
        </Typography>
      </div>
    </div>
  )
}

ConnectionQualityDetails.defaultProps = {
  bitrate: {
    download: 'N/A',
    upload: 'N/A',
  },
}

ConnectionQualityDetails.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    bitrateContainer: PropTypes.string.isRequired,
    upstream: PropTypes.string.isRequired,
    downstream: PropTypes.string.isRequired,
  }).isRequired,
  bitrate: PropTypes.shape({
    download: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    upload: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
}

export default memo(withStyles(styles)(ConnectionQualityDetails))
