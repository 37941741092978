import { createSelector } from 'reselect'

import { getSelectedGalleryItem } from '@tabeeb/modules/gallery/selectors'

export const getIsVideoPlaying = (state) => state.player.isVideoPlaying
export const getIsAudioMuted = (state) => state.player.isAudioMuted
export const getVolume = (state) => state.player.videoVolume
export const getIsFullscreen = (state) => state.player.isFullScreen
export const getSeekToTimestamp = (state) => state.player.seekToTimestamp
export const getCurrentVideoTimestamp = (state) => state.player.currentVideoTimestamp
export const getDuration = (state) => state.player.durationVideoTimestamp

export const getFormAnswersTimelineMarkers = (state) => state.player.formAnswersTimeline.list
export const getAnnotationsTimelineMarkers = createSelector([getSelectedGalleryItem], (page) => {
  return (page?.annotations || [])
    .filter((annotation) => annotation.Timestamp >= 0)
    .map((annotation) => ({ Timestamp: annotation.Timestamp, Id: annotation.Id }))
})
