import { handleActions } from 'redux-actions'
import * as bentleyModelActions from '../actions'

export default handleActions(
  {
    [bentleyModelActions.openBentleySettingsDialog](state, action) {
      return true
    },
    [bentleyModelActions.closeBentleySettingsDialog](state, action) {
      return false
    },
    [bentleyModelActions.resetState](state, action) {
      return false
    },
  },
  false
)
