import PropTypes from 'prop-types'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import './styles.less'

import TimelineItem from '../TimelineItem'

const TimelineList = ({ timeLineList, handleDeleteItem, handleUpdateList }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(timeLineList)
    const [removed] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, removed)

    handleUpdateList(items)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable' direction='horizontal'>
        {(provided, snapshot) => (
          <div className='timeline-list' ref={provided.innerRef} {...provided.droppableProps}>
            {timeLineList.map((item, index) => (
              <TimelineItem key={index} item={item} index={index} handleDeleteItem={handleDeleteItem} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

TimelineList.propTypes = {
  timeLineList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  handleDeleteItem: PropTypes.func.isRequired,
  handleUpdateList: PropTypes.func,
}

export default TimelineList
