import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_CUSTOM_VIDEO_STATE')
export const setProcessing = createAction('SET_PROCESSING')

export const getConcatenatedVideoRequest = createAction('GET_CONCATENATED_VIDEO_REQUEST')
export const getConcatenatedVideoSuccess = createAction('GET_CONCATENATED_VIDEO_SUCCESS')

export const exportCustomVideo = createAction('EXPORT_CUSTOM_VIDEO')

export const getPagesForCustomVideo = createAction('GET_PAGES_FOR_CUSTOM_VIDEO')

export const getPagesByAnswerStatus = createAction('GET_PAGES_BY_ANSWER_STATUS')
export const getPagesByAnswerStatusRequest = createAction('GET_PAGES_BY_ANSWER_STATUS_REQUEST')
export const getPagesByAnswerStatusSuccess = createAction('GET_PAGES_BY_ANSWER_STATUS_SUCCESS')

export const getPagesPerQuestionsOrderRequest = createAction('GET_PAGES_PER_QUESTIONS_ORDER_REQUEST')
export const getPagesPerQuestionsOrderSuccess = createAction('GET_PAGES_PER_QUESTIONS_ORDER_SUCCESS')

export const openTimeline = createAction('OPEN_CUSTOM_VIDEO_TIMELINE')
export const closeTimeline = createAction('CLOSE_CUSTOM_VIDEO_TIMELINE')

export const addTimelineItem = createAction('ADD_CUSTOM_VIDEO_TIMELINE_ITEM')
export const addMultipleTimelineItems = createAction('ADD_MULTIPLE_CUSTOM_VIDEO_TIMELINE_ITEMS')
export const deleteTimelineItem = createAction('DELETE_CUSTOM_VIDEO_TIMELINE_ITEM')
export const updateTimelineList = createAction('UPDATE_CUSTOM_VIDEO_TIMELINE_LIST')

export const openRecipientsDialog = createAction('OPEN_CUSTOM_VIDEO_RECIPIENTS_DIALOG')
export const closeRecipientsDialog = createAction('CLOSE_CUSTOM_VIDEO_RECIPIENTS_DIALOG')
