import { handleActions } from 'redux-actions'

import * as flashActions from '../actions/flash'

import { InitialFlashValue } from '../common/flash'

const defaultValue = InitialFlashValue

export default handleActions(
  {
    [flashActions.onFlashUpdate](state, action) {
      return action.payload
    },
  },
  defaultValue
)
