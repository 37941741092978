import { put, takeLatest, all } from 'redux-saga/effects'
import { ErrorCode } from '@tabeeb/enums'
import * as aiActions from '../actions'

import * as notificationActions from '../../notification/actions'

function getErrorMessage(Error) {
  switch (Error.Code) {
    case ErrorCode.DeleteAttachedAIObjectTenantNotAllowed:
      return `Can not delete the CV class because it's used in a model`
    default:
      return Error.Message
  }
}

function* onDeleteAiClassFailed(action) {
  const message = getErrorMessage(action.response.data.Error)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* deleteAiClass() {
  yield all([takeLatest(aiActions.deleteAiObject.failed, onDeleteAiClassFailed)])
}

export default deleteAiClass
