import PropTypes from 'prop-types'

import CancelIcon from '@material-ui/icons/Cancel'
import FormControlButton from '../FormControlButton'

const CancelAnswerEditingButton = ({ cancelUpdateAnswer, isEditingAnswer }) => {
  if (!isEditingAnswer) return null

  const componentProps = {
    onClick: cancelUpdateAnswer,
    Icon: CancelIcon,
    title: 'Cancel',
    iconColor: '#f44336',
  }

  return <FormControlButton {...componentProps} />
}

CancelAnswerEditingButton.propTypes = {
  isEditingAnswer: PropTypes.bool.isRequired,
  cancelUpdateAnswer: PropTypes.func.isRequired,
}

export default CancelAnswerEditingButton
