import { combineActions, handleActions } from 'redux-actions'
import { deleteHealthDeviceSuccess } from '@tabeeb/modules/healthData/actions'
import { searchDevicesSuccess, editDeviceSuccess, deleteDeviceSuccess } from '../actions'

const defaultState = {
  list: [],
  totalCount: 0,
}

export default handleActions(
  {
    [searchDevicesSuccess](state, action) {
      const { Devices, TotalCount } = action.response.data

      return { list: Devices, totalCount: TotalCount }
    },
    [editDeviceSuccess](state, action) {
      const device = action.payload
      const result = state.list.map((i) => {
        if (i.Id === device.Id) {
          return device
        }
        return i
      })

      return { ...state, list: result }
    },
    [combineActions(deleteDeviceSuccess, deleteHealthDeviceSuccess)](state, action) {
      const id = action.payload
      const result = state.list.filter((i) => i.Id !== id)

      return { ...state, list: result }
    },
  },
  defaultState
)
