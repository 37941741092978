import { handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../actions'

const initialState = {
  fetchStatus: FetchStatus.Idle,
  errorObject: null,
}

export default handleActions(
  {
    [rawActions.resetCheckPowerBIReportProviderConnection]: (state, action) => {
      return initialState
    },
    [rawActions.checkPowerBIReportProviderConnection.request]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [rawActions.checkPowerBIReportProviderConnection.success]: (state, action) => {
      return { ...state, fetchStatus: FetchStatus.Loaded, errorObject: null }
    },
    [rawActions.checkPowerBIReportProviderConnection.failed]: (state, action) => {
      const errorMessage = action?.response?.data?.Error?.Details || null
      let errorObject = null
      if (errorMessage) {
        errorObject = JSON.parse(errorMessage)
      }
      return { ...state, fetchStatus: FetchStatus.Failed, errorObject }
    },
  },
  initialState
)
