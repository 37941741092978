import { memo } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

const FormButton = ({ onClick, buttonText, buttonColor, isDisabled, size = 'medium' }) => {
  return (
    <Button
      size={size}
      variant='contained'
      onClick={onClick}
      disabled={isDisabled}
      style={{ backgroundColor: !isDisabled && buttonColor }}
    >
      {buttonText}
    </Button>
  )
}

FormButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
}

export default memo(FormButton)
