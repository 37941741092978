import { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const HtmlContent = ({ html }) => {
  const iframeRef = useRef(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const RESIZE_INTERVAL = 100

    const iframe = iframeRef.current
    if (!iframe) {
      return () => {}
    }

    const intervalId = setInterval(() => {
      const DEFAULT_SPACING = 32

      if (iframe.contentWindow?.document?.body?.scrollHeight) {
        setHeight(iframe.contentWindow.document.body.scrollHeight + DEFAULT_SPACING)
      }
    }, RESIZE_INTERVAL)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const srcDoc = `<html>
      <head>
        <link
          type="text/css"
          rel="stylesheet"
          href="https://cdn.tiny.cloud/1/qwkonwj6vwdeshc22y0oiwbi8kakeyb6e6xnfg9aehskpwr9/tinymce/5.10.9-138/skins/ui/oxide/content.min.css"
          crossorigin="anonymous"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://cdn.tiny.cloud/1/qwkonwj6vwdeshc22y0oiwbi8kakeyb6e6xnfg9aehskpwr9/tinymce/5.10.9-138/skins/content/default/content.min.css"
          crossorigin="anonymous"
        />
      </head>
      
      <body id='tinymce' class='mce-content-body '>
        ${html}
      </body>
    </html>`

  return (
    <iframe
      ref={iframeRef}
      width='100%'
      height={height}
      frameBorder='0'
      title='html-content'
      scrolling='no'
      srcDoc={srcDoc}
    />
  )
}

HtmlContent.propTypes = {
  html: PropTypes.string.isRequired,
}

export default memo(HtmlContent)
