import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isMobileOnly } from 'react-device-detect'

import * as rawTrackActions from '@tabeeb/modules/presentation/actions/tracks'
import * as rawGridViewActions from '../actions'
import * as gridViewSelectors from '../selectors'
import GridViewButton from '../components/GridViewButton'

const GridViewButtonContainer = ({ isButtonVisible, gridViewActions, trackActions, isActive }) => {
  const handleClick = () => {
    if (isActive) {
      gridViewActions.disableGridView()
      if (isMobileOnly) trackActions.toggleMobilePriorityView(true)
    } else {
      gridViewActions.enableGridView()
    }
  }

  const props = {
    isActive,
    handleClick,
  }

  if (!isButtonVisible) {
    return null
  }

  return <GridViewButton {...props} />
}

GridViewButtonContainer.propTypes = {
  isButtonVisible: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  gridViewActions: PropTypes.shape({
    disableGridView: PropTypes.func.isRequired,
    enableGridView: PropTypes.func.isRequired,
  }),
  trackActions: PropTypes.shape({
    toggleMobilePriorityView: PropTypes.func.isRequired,
  }),
}

const mapStateToProps = (state) => ({
  isButtonVisible: gridViewSelectors.getIsGridViewButtonVisible(state),
  isActive: gridViewSelectors.getIsGridViewActive(state),
})

const mapDispatchToProps = (dispatch) => ({
  gridViewActions: bindActionCreators(rawGridViewActions, dispatch),
  trackActions: bindActionCreators(rawTrackActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(GridViewButtonContainer)
