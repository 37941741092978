import { createAction } from 'redux-actions'

export const getByTenantIdRequest = createAction('GET_STORAGES_FOR_TENANT_REQUEST')
export const getByTenantIdSuccess = createAction('GET_STORAGES_FOR_TENANT_SUCCESS')
export const getByTenantIdFailed = createAction('GET_STORAGES_FOR_TENANT_FAILED')

export const addByTenantIdRequest = createAction('ADD_STORAGE_TO_TENANT_REQUEST')
export const addByTenantIdSuccess = createAction('ADD_STORAGE_TO_TENANT_SUCCESS')
export const addByTenantIdFailed = createAction('ADD_STORAGE_TO_TENANT_FAILED')

export const removeByTenantStorageIdRequest = createAction('REMOVE_STORAGE_FROM_TENANT_REQUEST')
export const removeByTenantStorageIdSuccess = createAction('REMOVE_STORAGE_FROM_TENANT_SUCCESS')
export const removeByTenantStorageIdFailed = createAction('REMOVE_STORAGE_FROM_TENANT_FAILED')

export const setAsDefaultByTenantIdRequest = createAction('MAKE_STORAGE_DEFAULT_REQUEST')
export const setAsDefaultByTenantIdSuccess = createAction('MAKE_STORAGE_DEFAULT_SUCCESS')
export const setAsDefaultByTenantIdFailed = createAction('MAKE_STORAGE_DEFAULT_FAILED')

export const resetState = createAction('RESET_TENANT_STORAGES')
