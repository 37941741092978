import { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import { DeleteOutlineOutlined, GroupOutlined, MoreVertOutlined, VisibilityOutlined } from '@mui/icons-material'

import { useDialogState, usePopoverState } from '@tabeeb/shared/utils/hooks'

import ContentTrainingMaterialDetachConfirmationDialog from '../ContentTrainingMaterialDetachConfirmationDialog'

const ContentTrainingMaterialMenu = ({ contentTrainingMaterial, onDetach, onManageUsers, onView }) => {
  const [menuAnchorRef, isMenuOpen, onOpenMenu, onCloseMenu] = usePopoverState()
  const [isDetachConfirmationDialogOpen, onOpenDetachConfirmationDialog, onCloseDetachConfirmationDialog] =
    useDialogState()

  const onDetachSubmit = useCallback(() => {
    onCloseDetachConfirmationDialog()
    onDetach(contentTrainingMaterial)
  }, [onCloseDetachConfirmationDialog, onDetach, contentTrainingMaterial])

  const options = useMemo(() => {
    const menuOptions = []

    if (onView) {
      menuOptions.push({
        id: 'view',
        title: 'View',
        icon: <VisibilityOutlined />,
        onClick: () => onView(contentTrainingMaterial),
      })
    }

    if (onManageUsers) {
      menuOptions.push({
        id: 'manage-users',
        title: 'Manage users',
        icon: <GroupOutlined />,
        onClick: () => onManageUsers(contentTrainingMaterial),
      })
    }

    if (onDetach) {
      menuOptions.push({
        id: 'detach',
        title: 'Detach',
        icon: <DeleteOutlineOutlined color='error' />,
        onClick: onOpenDetachConfirmationDialog,
      })
    }

    return menuOptions
  }, [onDetach, onManageUsers, onOpenDetachConfirmationDialog, onView, contentTrainingMaterial])

  return (
    <>
      <Tooltip title='Open menu'>
        <IconButton ref={menuAnchorRef} edge='end' size='small' onClick={onOpenMenu}>
          <MoreVertOutlined />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={onCloseMenu}
        onClick={onCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map(({ id, title, icon, onClick }) => (
          <MenuItem key={id} title={title} onClick={onClick}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
          </MenuItem>
        ))}
      </Menu>

      <ContentTrainingMaterialDetachConfirmationDialog
        open={isDetachConfirmationDialogOpen}
        onClose={onCloseDetachConfirmationDialog}
        onSubmit={onDetachSubmit}
      />
    </>
  )
}

ContentTrainingMaterialMenu.propTypes = {
  contentTrainingMaterial: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  onDetach: PropTypes.func,
  onView: PropTypes.func,
  onManageUsers: PropTypes.func,
}

export default memo(ContentTrainingMaterialMenu)
