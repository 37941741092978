import PropTypes from 'prop-types'
import { Group, Image as KonvaImage } from 'react-konva'
import { useMemo } from 'react'
import FieldOfView from '../FieldOfView'

const DroneImageUrl = new URL('@tabeeb/assets/images/drone-icon.png', import.meta.url)

const Drone = ({ fov, azimuth, size, iconSizeRatio }) => {
  const isFovDefined = Number.isFinite(fov)
  const angle = -azimuth + 90

  const droneImage = useMemo(() => {
    const image = new Image()
    image.src = DroneImageUrl.href
    return image
  }, [])

  const dronePoint = {
    x: (Math.cos((Math.PI / 180) * -angle) * size) / 2 + size / 2,
    y: (Math.sin((Math.PI / 180) * -angle) * size) / 2 + size / 2,
  }

  const iconSize = size * iconSizeRatio

  return (
    <Group {...dronePoint} rotation={-angle}>
      <KonvaImage width={iconSize} height={iconSize} x={-iconSize / 2} y={-iconSize / 2} image={droneImage} />
      {isFovDefined && <FieldOfView size={size} fov={fov} directionAngle={angle} />}
    </Group>
  )
}

Drone.defaultProps = {
  iconSizeRatio: 0.125,
}

Drone.propTypes = {
  fov: PropTypes.number,
  azimuth: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  iconSizeRatio: PropTypes.number.isRequired,
}

export default Drone
