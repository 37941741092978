// @ts-check
import { createAction } from 'redux-actions'
import { nanoid } from 'nanoid/non-secure'

/** @typedef {import('notistack').VariantType} NotificationVariants */
/** @typedef {import('notistack').SnackbarMessage} SnackbarMessage */
/** @typedef {import('notistack').OptionsObject} SnackbarOptions */
/** @typedef {import('notistack').SnackbarKey} SnackbarKey */

/** @typedef {{
    message: SnackbarMessage,
    options?: SnackbarOptions
 }} Payload */

/** @typedef {{
    key: SnackbarKey,
    message: SnackbarMessage,
    options?: SnackbarOptions,
    dismissed?: boolean
 }} Notification */

/** @typedef {{
    key?: SnackbarKey,
    dismissAll: boolean
 }} DismissPayload */

/** @typedef {{
    key: SnackbarKey
 }} RemovePayload */

/**
 * @param {NotificationVariants} variant
 * @param {Payload} payload
 * @returns {Notification}
 */
const transformPayload = (variant, payload) => {
  const key = (payload.options && payload.options.key) || nanoid()
  return {
    key,
    message: payload.message,
    options: {
      key,
      variant,
      ...payload.options,
    },
  }
}

export const onAddSuccessNotification = createAction(
  'ON_ADD_SUCCESS_NOTIFICATION',
  /**
   * @param {Payload} payload
   * @returns {Notification}
   */
  (payload) => transformPayload('success', payload)
)

export const onAddErrorNotification = createAction(
  'ON_ADD_ERROR_NOTIFICATION',
  /**
   * @param {Payload} payload
   * @returns {Notification}
   */
  (payload) => transformPayload('error', payload)
)

export const onAddWarningNotification = createAction(
  'ON_ADD_WARNING_NOTIFICATION',
  /**
   * @param {Payload} payload
   * @returns {Notification}
   */
  (payload) => transformPayload('warning', payload)
)

export const onAddInfoNotification = createAction(
  'ON_ADD_INFO_NOTIFICATION',
  /**
   * @param {Payload} payload
   * @returns {Notification}
   */
  (payload) => transformPayload('info', payload)
)

export const onDismissNotification = createAction(
  'ON_DISMISS_NOTIFICATION',
  /**
   * @param {SnackbarKey?} key
   * @returns {DismissPayload}
   */
  (key) => ({ key, dismissAll: !key })
)

export const onRemoveNotification = createAction(
  'ON_REMOVE_NOTIFICATION',
  /**
   * @param {SnackbarKey} key
   * @returns {RemovePayload}
   */
  (key) => ({ key })
)
