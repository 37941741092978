import qs from 'qs'

const healthDataRequests = {
  getTheLatestHealthDataHistoryRequest: (measureModels) => ({
    url: `health-device-service/api/healthData/v2/latest`,
    method: 'get',
    params: {
      measureModels,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),

  getAllHealthDataHistoryRequest: ({ PatientId, StartTime, EndTime, ContentId, TenantId, MeasureModels }) => ({
    url: `health-device-service/api/healthData/v2`,
    method: 'get',
    params: {
      PatientId,
      StartTime,
      EndTime,
      ContentId,
      TenantId,
      MeasureModels,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),

  addHealthDataRequest: (data) => ({
    url: `health-device-service/api/healthData/v2`,
    method: 'post',
    data,
  }),

  addContentUserPatientsHealthInfoSettingRequest: (data) => ({
    url: `health-device-service/api/healthData/v2/userHealthChartsState`,
    method: 'post',
    data,
  }),

  getUserPatientsHealthInfoSettingRequest: ({ PatientId, PatientTenantId }) => ({
    url: `health-device-service/api/healthData/v2/userHealthChartsState`,
    method: 'get',
    params: {
      PatientId,
      PatientTenantId,
    },
  }),

  getLife365ListOfProgramsRequest: () => ({
    url: `health-device-service/api/life365/listOfPrograms`,
    method: 'get',
  }),

  getAssignedLife365DeviceStatusRequest: () => ({
    url: `health-device-service/api/life365/assignedLife365DeviceStatus`,
    method: 'get',
  }),
}

export default healthDataRequests
