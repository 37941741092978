import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(
      rawActions.getPagesStatisticsByAiObjectsForProjectTraining.request,
      rawActions.getPagesStatisticsByFormsForProjectTraining.request
    )](state, action) {
      return true
    },
    [combineActions(
      rawActions.getPagesStatisticsByAiObjectsForProjectTraining.success,
      rawActions.getPagesStatisticsByAiObjectsForProjectTraining.failed,
      rawActions.getPagesStatisticsByFormsForProjectTraining.success,
      rawActions.getPagesStatisticsByFormsForProjectTraining.failed
    )](state, action) {
      return false
    },
  },
  defaultState
)
