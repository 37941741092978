import { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useUnmount } from '@tabeeb/modules/shared/utils/hooks'

import * as discussionActions from '../../../actions'
import * as discussionSelectors from '../../../selectors'

import AnswersList from '../AnswersList'

const PageAnswersList = ({ pageId }) => {
  const dispatch = useDispatch()

  const { loading, loaded, failed } = useSelector(discussionSelectors.getPageAnswersListLoadingState)
  const filters = useSelector(discussionSelectors.getPageAnswersFilters)

  const answers = useSelector(discussionSelectors.getFilteredPageAnswers)

  useEffect(() => {
    if (!pageId) {
      return
    }

    dispatch(discussionActions.getFormAnswersByPage({ pageId }))
  }, [dispatch, pageId])

  useUnmount(() => {
    dispatch(discussionActions.resetPageAnswersState())
  })

  const handleFiltersChange = useCallback(
    (updatedFilter) => {
      dispatch(discussionActions.updatePageFormAnswersFilters(updatedFilter))
      dispatch(discussionActions.getFormAnswersByPage({ pageId }))
    },
    [dispatch, pageId]
  )

  const handleLoadMore = useCallback(() => {
    dispatch(discussionActions.getFormAnswersByPage({ pageId }))
  }, [dispatch, pageId])

  return (
    <AnswersList
      failed={failed}
      loaded={loaded}
      loading={loading}
      answers={answers}
      onLoadMore={handleLoadMore}
      onFiltersChange={handleFiltersChange}
      filtersProps={{ userFilter: true, formFilter: true, statusFilter: true }}
      answerInfoProps={{ pagePreview: false, formName: true, questionName: true }}
      filters={filters}
    />
  )
}

PageAnswersList.propTypes = {
  pageId: PropTypes.number.isRequired,
}

export default memo(PageAnswersList)
