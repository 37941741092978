import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as rawActions from '../../gallery/actions/index'
import FileUploadsList from '../components/FileUploadsList'

import { getFileUploads, getIncompleteUploadsCount } from '../selectors'

class FileUploadsListContainer extends Component {
  _handleCloseApp = (e) => {
    const { incompleteUploadsCount } = this.props
    if (incompleteUploadsCount > 0) {
      const dialogText = 'Are you sure you want to close?'
      e.returnValue = dialogText

      return dialogText
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this._handleCloseApp)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this._handleCloseApp)
  }

  render() {
    const { fileUploads } = this.props

    const props = {
      fileUploads,
    }

    return <FileUploadsList {...props} />
  }
}

FileUploadsListContainer.propTypes = {
  fileUploads: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  return {
    fileUploads: getFileUploads(state),
    incompleteUploadsCount: getIncompleteUploadsCount(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadsListContainer)
