import { useCallback, useState } from 'react'

export default () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [item, setItem] = useState(null)
  const [open, setOpen] = useState(false)

  const onOpen = useCallback(({ anchor, item: selectedItem }) => {
    setItem(selectedItem)
    setAnchorEl(anchor)
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  return [anchorEl, item, open, onOpen, onClose]
}
