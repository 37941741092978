import { combineReducers } from 'redux'

import { persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'

import enabled from './enabled'
import questions from './questions'

export default persistReducer(
  {
    key: 'sncFilters',
    storage,
  },
  combineReducers({
    enabled,
    questions,
  })
)
