import { put, select } from 'redux-saga/effects'
import selector from 'modules/shared/utils/selector'

import * as activityActions from '../actions'

function* updatePageAsset(pagesId, asset) {
  const galleryList = yield select(selector.getGalleryList)
  const page = galleryList.find((page) => page.id === galleryList)

  if (page == null) {
    return
  }

  const updatePage = {
    ...page,
    thumbnailUrl: asset.url,
    timestampReceived: Date.now(),
    latitude: asset.latitude,
    longitude: asset.longitude,
  }

  yield put(activityActions.updateGalleryItem(updatePage))
}

export default updatePageAsset
