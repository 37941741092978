import _ from 'lodash'
import { combineActions, handleActions } from 'redux-actions'

import { calendarActions } from '@tabeeb/modules/calendar'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'

import { eventsService } from '../services'

const defaultState = {}

function getEventsByCalendarId(state, calendarId) {
  return state[calendarId] || []
}

function getCalendarEvent(state, calendarId, eventId) {
  return getEventsByCalendarId(state, calendarId).find((event) => event.id === eventId)
}

function updateCalendarEventsList(state, calendarId, events) {
  return {
    ...state,
    [calendarId]: events,
  }
}

const mergeEventsLists = (oldEvents, newEvents) => {
  const groupedEvents = _.groupBy([...newEvents, ...oldEvents], (event) => event.id)

  return _.map(groupedEvents, (events) => _.orderBy(events, (event) => event.updatedOn || '', 'desc')[0])
}

const addCalendarEvent = (state, calendarId, newEvents) => {
  const events = getEventsByCalendarId(state, calendarId)

  const updatedEventsList = mergeEventsLists(events, newEvents)

  return updateCalendarEventsList(state, calendarId, updatedEventsList)
}

const deleteCalendarEvent = (state, calendarId, eventId) => {
  const events = getEventsByCalendarId(state, calendarId)

  const updatedEventsList = events.filter((event) => event.id !== eventId)

  return updateCalendarEventsList(state, calendarId, updatedEventsList)
}

const updateCalendarEvent = (state, calendarId, updatedEvent) => {
  const events = getEventsByCalendarId(state, calendarId)

  const updatedEventsList = events.map((event) => (event.id === updatedEvent.id ? updatedEvent : event))

  return updateCalendarEventsList(state, calendarId, updatedEventsList)
}

export default handleActions(
  {
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
    // add
    [combineActions(
      calendarActions.bookAppointmentSlotSuccess,
      calendarActions.createAppointmentSlotsSuccess,
      calendarActions.createCalendarEventSuccess
    )](state, action) {
      const event = eventsService.convertCalendarEvent(action.response.data)
      const calendarId = event.creatorId

      return addCalendarEvent(state, calendarId, [event])
    },
    [calendarActions.getCalendarEventsByUserSuccess](state, action) {
      const { userId: calendarId } = action.payload

      const events = action.response.data.map((event) => eventsService.convertCalendarEvent(event, calendarId))

      return addCalendarEvent(state, calendarId, events)
    },
    // update
    [combineActions(calendarActions.updateCalendarEventTime.request)](state, action) {
      const { eventId, userId: calendarId, startDate, endDate } = action.payload
      const event = getCalendarEvent(state, calendarId, eventId)

      return updateCalendarEvent(state, calendarId, { ...event, start: startDate, end: endDate })
    },
    [combineActions(calendarActions.updateCalendarEventTime.success)](state, action) {
      const event = eventsService.convertCalendarEvent(action.response.data)
      const calendarId = event.creatorId

      return addCalendarEvent(state, calendarId, [event])
    },
    // delete
    [combineActions(calendarActions.deleteCalendarEventSuccess, calendarActions.getCalendarEventFailed)](
      state,
      action
    ) {
      const { calendarId, eventId } = action.payload

      return deleteCalendarEvent(state, calendarId, eventId)
    },
    [signalrEvents[signalrConstants.tabeebHubName].onCalendarEventDeleted](state, action) {
      const [calendarId, eventId] = action.payload

      return deleteCalendarEvent(state, calendarId, eventId)
    },
  },
  defaultState
)
