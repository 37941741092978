export const TransformationModes = {
  TRANSLATE: 'translate',
  ROTATE: 'rotate',
  SCALE: 'scale',
}

export const TransformationModeDisplayNames = {
  [TransformationModes.TRANSLATE]: 'Translate',
  [TransformationModes.ROTATE]: 'Rotate',
  [TransformationModes.SCALE]: 'Scale',
}
