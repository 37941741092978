import { handleActions, combineActions } from 'redux-actions'

import { onEnableSelection, onChangePressedButton, onDisposeDrawing, onHideAnnotations } from '../../actions/drawing'
import { enableExpandedGallery } from '../../../gallery/actions'
import * as playerToolbarActions from '../../actions'

const defaultState = ''

export default handleActions(
  {
    [onChangePressedButton](state, action) {
      return action.payload.pressedButton
    },
    [combineActions(onDisposeDrawing, enableExpandedGallery, onHideAnnotations, onEnableSelection)](state, action) {
      return defaultState
    },
    [playerToolbarActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
