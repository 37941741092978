import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '50%',
      maxWidth: '100%',
      background: theme.palette.background.message.secondary,
      position: 'relative',
      marginTop: theme.spacing(0.25),
      paddingTop: theme.spacing(),
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius * 2,
      wordBreak: 'break-word',
      boxShadow: 'none',

      '& ~ &::before': {
        border: 'none',
      },

      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,

      '&:last-of-type': {
        borderBottomLeftRadius: theme.shape.borderRadius * 2,
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: -4,
        borderRight: `4px solid ${theme.palette.background.message.secondary}`,
        borderBottom: '4px solid transparent',
      },
    },
    self: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: theme.shape.borderRadius * 2,
      borderBottomLeftRadius: theme.shape.borderRadius * 2,
      background: theme.palette.background.message.primary,

      '&:last-of-type': {
        borderBottomRightRadius: theme.shape.borderRadius * 2,
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 'unset',
        right: -4,
        borderRight: 'none',
        borderLeft: `4px solid ${theme.palette.background.message.primary}`,
        borderBottom: '4px solid transparent',
      },
    },
  }
})
