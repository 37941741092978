import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'

import { DateRangeOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'

import { updateDateFilter } from '@tabeeb/modules/sessionsPage/actions'
import { getDateFilter } from '@tabeeb/modules/sessionsPage/selectors/filtration'

const CreationDateFilter = () => {
  const dispatch = useDispatch()

  const { startDate, endDate } = useSelector(getDateFilter)
  const [dates, setDates] = useState([moment(startDate), moment(endDate)])

  useEffect(() => {
    setDates([moment(startDate), moment(endDate)])
  }, [endDate, startDate])

  const onClose = useCallback(() => {
    const [start, end] = dates
    if (!start || !end) {
      return
    }

    if (moment(startDate).isSame(start, 'date') && moment(endDate).isSame(end, 'date')) {
      return
    }

    if (start.isAfter(end)) {
      return
    }

    dispatch(
      updateDateFilter({
        startDate: dates[0].startOf('day').format(),
        endDate: dates[1].endOf('day').format(),
      })
    )
  }, [dates, dispatch, endDate, startDate])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateRangePicker
        closeOnSelect={false}
        value={dates}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          textField: {
            fullWidth: true,
            size: 'medium',
            variant: 'standard',
            InputProps: {
              sx: {
                paddingInline: 2,
                paddingBlock: 1,
                bgcolor: '#fff',
                border: '1px solid #e5e5e5',
                borderRadius: 1,
              },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton size='small' edge='end'>
                    <DateRangeOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            },
          },
        }}
        onChange={setDates}
        onClose={onClose}
      />
    </LocalizationProvider>
  )
}

export default memo(CreationDateFilter)
