import { memo } from 'react'
import PropTypes from 'prop-types'

import { Chip, ListItemIcon, Menu, MenuItem } from '@mui/material'
import {
  ExpandMoreOutlined,
  ErrorOutlineOutlined,
  HelpOutlineOutlined,
  NotificationImportantOutlined,
} from '@mui/icons-material'

import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import { AnnouncementSeverity, AnnouncementSeverityDisplayName } from '../../enums'

const AnnouncementSeverityToColorMap = {
  [AnnouncementSeverity.Information]: 'primary',
  [AnnouncementSeverity.Notification]: 'warning',
  [AnnouncementSeverity.Alert]: 'error',
}

const AnnouncementSeverityToIconMap = {
  [AnnouncementSeverity.Information]: <HelpOutlineOutlined color='primary' />,
  [AnnouncementSeverity.Notification]: <NotificationImportantOutlined color='warning' />,
  [AnnouncementSeverity.Alert]: <ErrorOutlineOutlined color='error' />,
}

const AnnouncementSeverityChip = ({ severity, onChange }) => {
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  return (
    <>
      <Chip
        ref={anchorRef}
        size='small'
        variant='outlined'
        sx={{ padding: 0.25 }}
        icon={AnnouncementSeverityToIconMap[severity]}
        color={AnnouncementSeverityToColorMap[severity]}
        label={AnnouncementSeverityDisplayName[severity]}
        onClick={onChange && onOpen}
        onDelete={onChange && onOpen}
        deleteIcon={<ExpandMoreOutlined />}
      />
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {Object.values(AnnouncementSeverity).map((value) => (
          <MenuItem
            key={value}
            selected={severity === value}
            onClick={() => {
              onClose()
              onChange(value)
            }}
          >
            <ListItemIcon>{AnnouncementSeverityToIconMap[value]}</ListItemIcon>
            {AnnouncementSeverityDisplayName[value]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

AnnouncementSeverityChip.defaultProps = {
  severity: AnnouncementSeverity.Information,
}

AnnouncementSeverityChip.propTypes = {
  severity: PropTypes.oneOf(Object.values(AnnouncementSeverity)),
  onChange: PropTypes.func,
}

export default memo(AnnouncementSeverityChip)
