import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultValue = []

export default handleActions(
  {
    [rawActions.getTenantStorages.success](state, action) {
      return [...action.response.data]
    },
  },
  defaultValue
)
