import PropTypes from 'prop-types'
import { Divider, Grid, Typography } from '@material-ui/core'

import HealthInfoDevice from '@tabeeb/modules/healthData/components/HealthInfoDevice'

import useStyles from './styles'

const HealthInfoDevices = ({ healthDevices }) => {
  const classes = useStyles()
  return (
    <Grid item container direction='column' spacing={1}>
      <Grid item container justifyContent='center' className={classes.flexGrid}>
        <Typography>Added devices</Typography>
      </Grid>
      {healthDevices.map((healthDevice, index, array) => (
        <HealthInfoDevice key={healthDevice.Id} healthDevice={healthDevice} last={array.length === index + 1} />
      ))}
      <Divider className={classes.divider} />
    </Grid>
  )
}

HealthInfoDevices.propTypes = {
  healthDevices: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      DeviceId: PropTypes.string.isRequired,
      Type: PropTypes.number.isRequired,
      ProgramId: PropTypes.string,
    }).isRequired
  ).isRequired,
}

export default HealthInfoDevices
