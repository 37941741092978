import { handleActions } from 'redux-actions'
import * as activityActions from '../actions'

const defaultState = {
  selectedActivityId: null,
}

const activitiesState = handleActions(
  {
    [activityActions.selectActivity](state, { payload }) {
      return { ...state, selectedActivityId: payload }
    },
    [activityActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)

export default activitiesState
