export const SortingColumn = {
  Name: 'Description',
  Date: 'CreatedOn',
  Owner: 'FLName',
  LastActivity: 'LastActivity',
}

export const SortingColumnDisplayName = {
  [SortingColumn.Name]: 'Name',
  [SortingColumn.Date]: 'Date',
  [SortingColumn.Owner]: 'Owner',
  [SortingColumn.LastActivity]: 'Last activity',
}
