import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import Routes from '@tabeeb/routes'
import { TenantPermission } from '@tabeeb/enums'
import { RouteWrapper } from '@tabeeb/shared/routes/components'

import AnnouncementsPage from './AnnouncementsPage'
import AnnouncementCreateSystemPage from './AnnouncementCreateSystemPage'
import AnnouncementCreateTenantPage from './AnnouncementCreateTenantPage'
import AnnouncementEditPage from './AnnouncementEditPage'

import { AnnouncementScope } from '../../enums'
import { getAvailableAnnouncementScopes } from '../../selectors'

const AnnouncementPages = () => {
  const scopes = useSelector(getAvailableAnnouncementScopes)

  return (
    <Switch>
      <Route
        exact
        key={Routes.announcementCreateSystem}
        path={Routes.announcementCreateSystem}
        render={() => (
          <RouteWrapper
            routeEnabled={scopes.includes(AnnouncementScope.System)}
            permission={TenantPermission.ManageSystemAnnouncements}
          >
            <AnnouncementCreateSystemPage />
          </RouteWrapper>
        )}
      />

      <Route
        exact
        key={Routes.announcementCreateTenant}
        path={Routes.announcementCreateTenant}
        render={() => (
          <RouteWrapper
            routeEnabled={scopes.includes(AnnouncementScope.Tenant)}
            permission={TenantPermission.ManageTenantAnnouncements}
          >
            <AnnouncementCreateTenantPage />
          </RouteWrapper>
        )}
      />

      <Route
        exact
        key={Routes.announcementEdit}
        path={Routes.announcementEdit}
        render={({
          match: {
            params: { id },
          },
        }) => (
          <RouteWrapper routeEnabled={scopes.length > 0}>
            <AnnouncementEditPage id={parseInt(id, 10)} />
          </RouteWrapper>
        )}
      />

      <Route
        exact
        key={Routes.announcements}
        path={Routes.announcements}
        render={() => (
          <RouteWrapper routeEnabled={scopes.length > 0}>
            <AnnouncementsPage />
          </RouteWrapper>
        )}
      />

      <Redirect to={Routes.home} />
    </Switch>
  )
}

export default memo(AnnouncementPages)
