export default (theme) => {
  return {
    container: {
      margin: theme.spacing(),
      marginBottom: 0,
    },
    icon: {
      minWidth: 0,
    },
    success: {
      color: theme.palette.success.main,
    },
    list: {
      marginTop: theme.spacing(),
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItem: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: theme.spacing(),
    },
  }
}
