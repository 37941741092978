/* eslint-disable no-shadow */
import { put, takeLatest, select, all } from 'redux-saga/effects'
import * as rawActions from '@tabeeb/modules/tenantSessionsPage/actions'
import { TenantSessionsMode } from '@tabeeb/enums'
import { mapDeletedContents } from '@tabeeb/modules/tenantSessionsPage/services/contentMapper'
import { getFilterModel } from './common'

function* setTenantContentsList(contentsList) {
  yield put(rawActions.setTenantContentsList(contentsList))
  yield put(rawActions.setLoadingContent(false))
}

function* loadTenantContents() {
  const {
    currentMode,
    pageNumber,
    pageSize,
    dashboards: { selectedDashboardId },
  } = yield select((state) => state.tenantSessionsPage)

  yield put(rawActions.clearTenantContentsList())
  yield put(rawActions.setLoadingContent(true))

  const isDeletedMode = currentMode === TenantSessionsMode.Deleted
  const filterModel = yield getFilterModel(currentMode)

  let data = {
    skip: (pageNumber - 1) * pageSize,
    take: pageSize,
    searchQuery: filterModel,
  }

  if (isDeletedMode) {
    yield put(rawActions.getDeletedTenantContentsRequest(data))
  } else {
    data = { ...data, dashboardId: selectedDashboardId > 0 ? selectedDashboardId : null }
    yield put(rawActions.getTenantContentsRequest(data))
  }
}

function* getTenantContents(action) {
  const contents = action.response.data
  const { currentMode } = yield select((state) => state.tenantSessionsPage)

  if (currentMode !== TenantSessionsMode.Actual) {
    return
  }

  yield setTenantContentsList(contents)
}

function* getDeletedTenantContents(action) {
  const { data } = action.response

  const { currentMode } = yield select((state) => state.tenantSessionsPage)
  if (currentMode !== TenantSessionsMode.Deleted) {
    return
  }

  yield setTenantContentsList(mapDeletedContents(data))
}

function* fixPageNumber(action) {
  const { data } = action.response

  const totalContentsCount = data[0] ? data[0].TotalCount : 0
  const { pageNumber, pageSize } = yield select((state) => state.tenantSessionsPage)

  const pageNumberExceedsContentsCount = pageNumber * pageSize > totalContentsCount
  if (pageNumberExceedsContentsCount) {
    const lastPageNumber = Math.max(Math.ceil(totalContentsCount / pageSize), 1)

    yield put(rawActions.updatePageNumber(lastPageNumber))
  }
}

function* contentsList() {
  yield all([
    takeLatest(
      [
        rawActions.loadTenantContents,
        rawActions.setPageNumber,
        rawActions.setPageSize,
        rawActions.setStartDate,
        rawActions.setEndDate,
        rawActions.setSortingColumn,
        rawActions.getTenantContentsDashboardColumns.success,
      ],
      loadTenantContents
    ),
    takeLatest(rawActions.getTenantContentsSuccess, getTenantContents),
    takeLatest(rawActions.getDeletedTenantContentsSuccess, getDeletedTenantContents),
    takeLatest([rawActions.getTenantContentsSuccess, rawActions.getDeletedTenantContentsSuccess], fixPageNumber),
  ])
}

export default contentsList
