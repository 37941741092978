import { memo } from 'react'
import PropTypes from 'prop-types'

import { Slider, Typography } from '@material-ui/core'
import { VideocamOutlined } from '@mui/icons-material'

import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'

import FieldOfViewButtonContainer from './Container'

import useStyles from './styles'

const FieldOfViewButton = ({ defaultFov, onChange, onSubmit }) => {
  const classes = useStyles()
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  return (
    <>
      <ToolbarButton ref={anchorRef} onClick={onOpen} title='Camera settings' Icon={VideocamOutlined} />
      <ToolbarPopover anchorEl={anchorRef.current} spacing={2} open={open} onClose={onClose}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography id='transparency-slider' color='textSecondary' variant='body2'>
              Field of view
            </Typography>
          </div>
          <Slider
            min={20}
            max={100}
            step={1}
            defaultValue={defaultFov}
            valueLabelDisplay='auto'
            marks={[
              {
                value: 20,
                label: '20',
              },
              {
                value: 100,
                label: '100',
              },
            ]}
            onChange={(_, newFov) => {
              onChange(newFov)
            }}
            onChangeCommitted={(_, newFov) => {
              onSubmit(newFov)
            }}
            aria-labelledby='transparency-slider'
          />
        </div>
      </ToolbarPopover>
    </>
  )
}

FieldOfViewButton.propTypes = {
  defaultFov: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(FieldOfViewButton)

export { FieldOfViewButtonContainer }
