import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { ThemeProvider, createTheme } from '@mui/material'

import { modernV5 } from '@tabeeb/uikit'

const PageThemeProvider = ({ children }) => {
  const theme = useMemo(() => {
    return createTheme(
      {
        palette: {
          pagePrimaryAction: {
            main: 'rgb(20, 113, 235)',
          },
          background: {
            page: 'rgb(246, 246, 246)',
            session: 'rgb(236, 236, 236)',
            folder: 'rgb(236, 236, 236)',
          },
          text: {
            session: 'rgb(92, 92, 92)',
            folder: 'rgb(92, 92, 92)',
            main: 'rgb(67, 67, 67)',
          },
        },
      },
      modernV5
    )
  }, [])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

PageThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageThemeProvider
