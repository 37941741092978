import { put, takeLatest, all } from 'redux-saga/effects'

import { ApiResponseAction } from '@tabeeb/shared/utils/actions'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import { getFtpServerSettings } from '../actions'
import { FtpSettings } from '../types'

function* onGetFtpServerSettingsFailed({ response }: ApiResponseAction<string, FtpSettings>) {
  if (response.status === 404) {
    return // do not show an error if settings are not configured
  }

  yield put(onAddErrorNotification({ message: 'Failed to load the FTP settings' }))
}

function* getFtpServerSettingsSagas() {
  yield all([takeLatest(getFtpServerSettings.failed, onGetFtpServerSettingsFailed)])
}

export default getFtpServerSettingsSagas
