import { takeLatest, put, all } from 'redux-saga/effects'
import { stopSubmit, reset } from 'redux-form'

import * as galleryActions from '../actions'
import updateOrAddGallery from './updateOrAddGallery'
import { contentNotificationService } from '../../shared/content'
import { ADD_VIDEO_FROM_URL_DIALOG_FORM } from '../constants'

function* uploadGalleryVideoByUrlSuccess(action) {
  yield put(stopSubmit(ADD_VIDEO_FROM_URL_DIALOG_FORM))
  yield put(reset(ADD_VIDEO_FROM_URL_DIALOG_FORM))
  const pages = [action.response.data]
  const { isSelectAddedGalleryItem } = false
  yield updateOrAddGallery(isSelectAddedGalleryItem, pages)
  yield contentNotificationService.notifyOfNewPages(pages)
  yield put(galleryActions.onGalleryVideoByUrlUploaded())
}

function* uploadGalleryVideoByUrlFailed(action) {
  yield put(stopSubmit(ADD_VIDEO_FROM_URL_DIALOG_FORM))
  yield put(reset(ADD_VIDEO_FROM_URL_DIALOG_FORM))
}

function* addUrlVideos() {
  yield all([
    takeLatest(galleryActions.uploadGalleryVideoByUrlSuccess, uploadGalleryVideoByUrlSuccess),
    takeLatest(galleryActions.uploadGalleryVideoByUrlFailed, uploadGalleryVideoByUrlFailed),
  ])
}

export default addUrlVideos
