import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { getFormCounterProps } from '@tabeeb/modules/forms/selectors'
import styles from './styles'

const FormCounter = ({ counter, classes }) => {
  const { label, text, countPerPage, showPerPageCount, disabled, color } = useSelector((state) =>
    getFormCounterProps(state, { counter })
  )

  return (
    <Paper className={classNames(classes.counter, { [classes.disabledCounter]: disabled })} variant='outlined'>
      <span className={classes.label}>{label}</span>
      {disabled ? (
        <div>Counters are not available, please contact Administrator</div>
      ) : (
        <>
          {showPerPageCount && <div>Amount per page: {countPerPage}</div>}
          <div>
            <span>Total per session: </span>
            <span style={{ color }}>{text}</span>
          </div>
        </>
      )}
    </Paper>
  )
}

FormCounter.propTypes = {
  counter: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    counter: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabledCounter: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(FormCounter))
