import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { IconButton, Tooltip } from '@mui/material'
import { PersonAdd } from '@mui/icons-material'

import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { getHasAddContentUsersPermission } from '@tabeeb/modules/sessionsPage/selectors/permissions'

const AddUsersButton = ({ onClick }) => {
  const isVisible = useSelector(getHasAddContentUsersPermission)
  if (!isVisible) {
    return null
  }

  return (
    <Tooltip title={sessionTitleFormatter.format('Add users to selected sessions')}>
      <IconButton size='small' variant='contained' onClick={onClick}>
        <PersonAdd />
      </IconButton>
    </Tooltip>
  )
}

AddUsersButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default memo(AddUsersButton)
