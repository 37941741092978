import PropTypes from 'prop-types'
import GridViewIcon from '@material-ui/icons/GridOnOutlined'
import SingleViewIcon from '@material-ui/icons/CropSquareOutlined'
import { Button, withStyles } from '@material-ui/core'
import { isMobileOnly } from 'react-device-detect'
import classNames from 'classnames'

import { GridViewRounded } from '@mui/icons-material'
import { HeaderButton } from '@tabeeb/modules/mobileWhiteboard/components/CallControls/styles'

import styles from './styles'

const GridViewButton = ({ handleClick, isActive, classes }) => {
  return isMobileOnly ? (
    <HeaderButton className={classNames({ active: isActive })} onClick={handleClick}>
      <GridViewRounded />
    </HeaderButton>
  ) : (
    <Button className={classes.icon} onClick={handleClick} title={isActive ? 'Single view' : 'Grid view'}>
      {isActive ? <SingleViewIcon /> : <GridViewIcon />}
    </Button>
  )
}

GridViewButton.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default withStyles(styles)(GridViewButton)
