import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as galleryActions from '../../gallery/actions'
import * as compassActions from '../actions'
import * as compassSelectors from '../selectors'

function* onSelectGalleryItem() {
  const isOpen = yield select(compassSelectors.getIsOpen)
  if (isOpen) {
    yield put(compassActions.resetCompass())
  }
}

function* compassSaga() {
  yield all([takeLatest(galleryActions.onSelectGalleryItem, onSelectGalleryItem)])
}

export default compassSaga
