import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'
import { rawContentActions, contentStateSelectors } from '../../shared/content'

import { StructureModelStatus } from '../../../Enums'

function* onAddStructureModel(action) {
  const contentId = yield select(contentStateSelectors.getContentId)
  const imagesList = yield select((state) => state.structureModel.imagesList)

  if (imagesList.length < 1) {
    yield put(
      notificationActions.onAddErrorNotification({
        message: 'Images list is empty!',
      })
    )
    return
  }

  const pageIds = imagesList.map((image) => image.id)

  const model = {
    contentId,
    pageIds,
  }

  yield put(actions.addStructureModelRequest(model))
}

function* onAddStructureModelRequest() {
  yield put(
    rawContentActions.onUpdateContentState({
      structureModelStatus: StructureModelStatus.Creating,
    })
  )
}

function* onAddStructureModelSuccess() {
  yield put(actions.closeStructureModelDialog())
  yield put(notificationActions.onAddInfoNotification({ message: 'Data sent for creating Analytics Report' }))
}

function* onAddStructureModelFailed() {
  yield put(
    rawContentActions.onUpdateContentState({
      structureModelStatus: StructureModelStatus.NotCreated,
    })
  )
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to create Analytics Report' }))
}

function* addStructureModel() {
  yield all([
    takeLatest(actions.addStructureModel, onAddStructureModel),
    takeLatest(actions.addStructureModelRequest, onAddStructureModelRequest),
    takeLatest(actions.addStructureModelSuccess, onAddStructureModelSuccess),
    takeLatest(actions.addStructureModelFailed, onAddStructureModelFailed),
  ])
}

export default addStructureModel
