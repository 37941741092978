import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    title: {
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
  }
})
