const { createAction } = require('redux-actions')

export const hideSelfView = createAction('HIDE_SELF_VIEW')
export const showSelfView = createAction('SHOW_SELF_VIEW')

export const hideCallControls = createAction('HIDE_CALL_CONTROLS')
export const showCallControls = createAction('SHOW_CALL_CONTROLS')

export const hideCallPreview = createAction('HIDE_CALL_PREVIEW')
export const showCallPreview = createAction('SHOW_CALL_PREVIEW')

export const hideUserList = createAction('HIDE_USER_LIST')
export const showUserList = createAction('SHOW_USER_LIST')
