import { handleActions } from 'redux-actions'

import { disableTransformation, enableTransformation, setTransformationMode } from '../actions'
import { TransformationModes } from '../constants'

const defaultState = {
  enabled: false,
  mode: TransformationModes.TRANSLATE,
}

export default handleActions(
  {
    [enableTransformation](state) {
      return { ...state, enabled: true }
    },
    [disableTransformation](state) {
      return { ...defaultState }
    },
    [setTransformationMode](state, { payload: mode }) {
      return { ...state, mode }
    },
  },
  defaultState
)
