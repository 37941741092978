import { put, fork, all, select, takeEvery } from 'redux-saga/effects'

import moment from 'moment'

import { CalendarSubscriptionDisplay, FetchStatus } from '@tabeeb/enums'

import { calendarActions, calendarSelectors } from '@tabeeb/modules/calendar'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'

import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { EVENTS_BATCH_SIZE } from '../constants/calendarConfiguration'

function* fetchCalendarsEvents(calendarsIds, start, end, updatedOn = null) {
  if (moment(end).diff(start, 'days') > 50) {
    return
  }

  for (const calendarId of calendarsIds) {
    yield fork(fetchCalendarEvents, calendarId, start, end, updatedOn)
  }
}

function* fetchCalendarEvents(calendarId, start, end, updatedOn) {
  const { status, lastSynchronized } = yield select((state) =>
    calendarSelectors.getCalendarRangeSynchronizationState(state, { calendarId, start, end })
  )

  if (status === FetchStatus.Loading || (updatedOn && lastSynchronized && updatedOn < lastSynchronized)) {
    return
  }

  yield put(
    calendarActions.updateCalendarRangeSynchronizationState({
      calendarId,
      range: {
        start,
        end,
      },
      state: {
        status: FetchStatus.Loading,
      },
    })
  )

  const fetchStartDate = new Date()
  let hasMore = true

  let itemsFetchedTotal = 0
  while (hasMore) {
    try {
      const result = yield callApiAsync(
        calendarActions.getCalendarEventsByUserRequest({
          userId: calendarId,
          startDate: start,
          endDate: end,
          updatedOn: lastSynchronized,
          skip: itemsFetchedTotal,
          take: EVENTS_BATCH_SIZE,
        }),
        { callResultActions: true }
      )

      const eventsFetched = result.response.data.length
      if (eventsFetched < EVENTS_BATCH_SIZE) {
        hasMore = false
      }

      itemsFetchedTotal += eventsFetched
    } catch {
      yield put(
        calendarActions.updateCalendarRangeSynchronizationState({
          calendarId,
          range: {
            start,
            end,
          },
          state: {
            status: FetchStatus.Failed,
          },
        })
      )

      return
    }
  }

  yield put(
    calendarActions.updateCalendarRangeSynchronizationState({
      calendarId,
      range: {
        start,
        end,
      },
      state: {
        status: FetchStatus.Loaded,
        lastSynchronized: fetchStartDate,
      },
    })
  )
}

function* calendarSubscriptionDisplayUpdated(action) {
  const { userId: calendarId, mode } = action.payload
  const { start, end } = yield select(calendarSelectors.getSelectedDateRange)

  if (mode === CalendarSubscriptionDisplay.Hide) {
    return
  }

  yield fetchCalendarsEvents([calendarId], start, end)
}

function* onCalendarUpdated(action) {
  const [calendarId, , updatedOn] = action.payload

  const { start, end } = yield select(calendarSelectors.getSelectedDateRange)

  yield fetchCalendarsEvents([calendarId], start, end, updatedOn)
}

function* onSubscribed(action) {
  const { userId } = action.payload
  const { start, end } = yield select(calendarSelectors.getSelectedDateRange)

  yield fetchCalendarsEvents([userId], start, end)
}

function* synchronize(action) {
  const { start, end } = yield select(calendarSelectors.getSelectedDateRange)
  const calendarsIds = yield select(calendarSelectors.getEnabledUsersCalendarsIds)

  yield fetchCalendarsEvents(calendarsIds, start, end)
}

function* calendarSynchronizationSagas() {
  yield all([
    takeEvery(calendarActions.subscribeToUsersCalendarSuccess, onSubscribed),
    takeEvery(calendarActions.updateCalendarSubscriptionDisplayRequest, calendarSubscriptionDisplayUpdated),
    takeEvery([calendarActions.getCalendarsListSuccess, calendarActions.fetchCalendarsEvents], synchronize),
    takeEvery(
      [
        signalrEvents[signalrConstants.tabeebHubName].onCalendarEventCreated,
        signalrEvents[signalrConstants.tabeebHubName].onCalendarEventDeleted,
        signalrEvents[signalrConstants.tabeebHubName].onCalendarEventUpdated,
      ],
      onCalendarUpdated
    ),
  ])
}

export default calendarSynchronizationSagas
