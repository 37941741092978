import { IconButton } from '@material-ui/core'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Close } from '@material-ui/icons'
import PropTypes from 'prop-types'

import SwitchTenantContent from '@tabeeb/modules/account/components/SwitchTenantContent'

import useStyles from './styles'

const SwitchTenantDialog = ({ open, onClose, identityMe }) => {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <div className={classes.closeIconButton}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <DialogTitle>Available Tenants</DialogTitle>
      <DialogContent sx={{ pb: 5 }}>
        <SwitchTenantContent identityMe={identityMe} dialog />
      </DialogContent>
    </Dialog>
  )
}

SwitchTenantDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  identityMe: PropTypes.shape({
    userTenants: PropTypes.arrayOf(
      PropTypes.shape({
        tenantId: PropTypes.number,
        tenantName: PropTypes.string,
        tenantSubdomain: PropTypes.string,
      })
    ),
  }),
}

export default SwitchTenantDialog
