import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import UserAvatar from '@tabeeb/modules/shared/userAvatar'

import UserInfoSkeleton from './Skeleton'

import useStyles from './styles'

const UserInfo = ({ item, avatarWrapper: AvatarWrapper, avatarWrapperProps }) => {
  const classes = useStyles()

  return (
    <div className={classes.container} title={item.Name}>
      <Box mr={1}>
        <AvatarWrapper {...avatarWrapperProps}>
          <UserAvatar className={classes.avatar} user={item} />
        </AvatarWrapper>
      </Box>
      <div className={classes.content}>
        <Typography variant='body2' color='textPrimary' noWrap>
          {item.Name}
        </Typography>
        {!item.IsAccountDeleted && (
          <Typography variant='caption' color='textSecondary' noWrap>
            {item.Email}
          </Typography>
        )}
      </div>
    </div>
  )
}

UserInfo.defaultProps = {
  avatarWrapper: ({ children }) => children,
  avatarWrapperProps: {},
}

UserInfo.propTypes = {
  item: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Email: PropTypes.string.isRequired,
    IsAccountDeleted: PropTypes.bool,
    AvatarUrl: PropTypes.string,
  }).isRequired,
  avatarWrapper: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]),
  avatarWrapperProps: PropTypes.instanceOf(Object),
}

export default memo(UserInfo)

export { UserInfoSkeleton }
