import { Divider, Button } from '@material-ui/core'
import PropTypes from 'prop-types'

import './styles.less'

const AvailableTenantFormItem = ({ attachFormToTenant, form }) => {
  return (
    <div className='forms__availableToAttach_item'>
      <p className='forms__availableToAttach_item-name' title={form.Name}>
        {form.Name.length > 30 ? `${form.Name.slice(0, 30)}...` : form.Name}
      </p>
      <Divider />

      {form.IconUrl && (
        <div className='forms__availableToAttach_item-img'>
          <img src={form.IconUrl} alt='snapshot' />
        </div>
      )}
      {!form.IconUrl && <div className='forms__availableToAttach_item-img' />}

      <div className='forms__availableToAttach_item-info'>
        <span>Controls: {form.ControlsNumber}</span>
      </div>
      {!form.attached && (
        <Button onClick={() => attachFormToTenant(form)} variant='contained' className='attach-form-btn'>
          Attach
        </Button>
      )}
    </div>
  )
}

AvailableTenantFormItem.propTypes = {
  form: PropTypes.object.isRequired,
  attachFormToTenant: PropTypes.func.isRequired,
}

export default AvailableTenantFormItem
