import PropTypes from 'prop-types'
import { CircularProgress, IconButton, List, ListItem, ListItemText } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { UniqueAIObjectConnectionType, UniqueAIObjectConnectionTypeDisplayName } from '@tabeeb/enums'

const UniqueAIObjectConnectionsList = ({ deleting, connections, variant, onDelete }) => {
  return (
    <List>
      {connections.length > 0 ? (
        connections.map((connection, index) => {
          const uniqueAIObjectName =
            variant === 'children'
              ? connection.Child.Name
              : variant === 'parents'
              ? connection.Parent.Name
              : 'Unknown object'

          return (
            <ListItem
              key={connection.Id}
              secondaryAction={
                deleting ? (
                  <CircularProgress size='1.11em' />
                ) : (
                  <IconButton edge='end' onClick={() => onDelete(connection.Id)}>
                    <Delete />
                  </IconButton>
                )
              }
            >
              <ListItemText
                primary={uniqueAIObjectName}
                secondary={UniqueAIObjectConnectionTypeDisplayName[connection.Type]}
              />
            </ListItem>
          )
        })
      ) : (
        <ListItem>
          <ListItemText primary='None' />
        </ListItem>
      )}
    </List>
  )
}

UniqueAIObjectConnectionsList.propTypes = {
  connections: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Type: PropTypes.oneOf(Object.values(UniqueAIObjectConnectionType)).isRequired,
      UniqueAIObject: PropTypes.shape({
        Name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['children', 'parents']).isRequired,
}

export default UniqueAIObjectConnectionsList
