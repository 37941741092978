import PropTypes from 'prop-types'

import { Box, Typography, Button, withStyles } from '@material-ui/core'

import UserAvatar from '@tabeeb/shared/userAvatar'

import styles from './styles'

const AnnotationInfo = ({ classes, annotationInfo, handleEdit }) => {
  const _handleEdit = () => {
    handleEdit(annotationInfo)
  }

  return (
    <Box className={classes.container}>
      <UserAvatar
        className={classes.avatar}
        user={{
          displayName: annotationInfo.userName,
          initials: annotationInfo.userInitials,
          avatarUrl: annotationInfo.userAvatarUrl,
        }}
      />
      <div className={classes.content}>
        <Typography variant='body2' className={classes.name}>
          {annotationInfo.userName}
        </Typography>
        <Typography variant='body2' className={classes.text}>
          {annotationInfo.text}
        </Typography>
        {annotationInfo.hasProperties && (
          <Button aria-label='edit' title='Edit Properties' color='primary' variant='outlined' onClick={_handleEdit}>
            Edit Properties
          </Button>
        )}
      </div>
    </Box>
  )
}

AnnotationInfo.propTypes = {
  classes: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  annotationInfo: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hasProperties: PropTypes.bool.isRequired,
    userName: PropTypes.string.isRequired,
    userInitials: PropTypes.string.isRequired,
    userAvatarUrl: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(AnnotationInfo)
