import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ZoomIn } from '@material-ui/icons'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { isSupportZooming } from '@tabeeb/services/pageService'
import { playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import * as zoomingActions from '../../../actions/zooming'
import { MaxZoomValue } from '../../../common/zooming'
import PanelButton from '../PanelButton'

const ZoomInButton = () => {
  const dispatch = useDispatch()

  const isAvailable = useSelector((state) => playerToolbarSelectors.getScale(state) < MaxZoomValue)
  const display = useSelector((state) => isSupportZooming(gallerySelectors.getSelectedGalleryItemType(state)))

  const onZoomIn = useCallback(() => {
    if (isAvailable) {
      dispatch(zoomingActions.onZoomIn())
    }
  }, [dispatch, isAvailable])

  return <PanelButton display={display} disabled={!isAvailable} Icon={ZoomIn} onClick={onZoomIn} title='Zoom in' />
}

export default memo(ZoomInButton)
