import { handleActions } from 'redux-actions'

import * as aiActions from '../actions'

const defaultState = {}

export default handleActions(
  {
    [aiActions.getUniqueAiObjects.success](state, { payload, response }) {
      const { aiObjectId } = payload

      return { ...state, [aiObjectId]: response.data }
    },
    [aiActions.addUniqueAiObject.success](state, { payload, response }) {
      const { AIObjectId } = response.data
      const objects = state[AIObjectId] ? state[AIObjectId] : []

      return { ...state, [AIObjectId]: [...objects, response.data] }
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
