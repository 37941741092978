import { handleActions } from 'redux-actions'

import { ReviewStatus } from '@tabeeb/enums'

import { updateReviewStatusFilter, resetFilters } from '../../actions'

const defaultState = Object.values(ReviewStatus)

export default handleActions(
  {
    [updateReviewStatusFilter](state, action) {
      return action.payload
    },
    [resetFilters](state, action) {
      return defaultState
    },
  },
  defaultState
)
