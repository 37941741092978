import PropTypes from 'prop-types'

import { Chip, withStyles } from '@material-ui/core'

import styles from './styles'

const RecipientItem = ({ classes, item, handleDeleteRecipient }) => {
  return <Chip className={classes.recipient} label={item} onDelete={(event) => handleDeleteRecipient(event, item)} />
}

RecipientItem.propTypes = {
  classes: PropTypes.shape({
    recipient: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.string.isRequired,
  handleDeleteRecipient: PropTypes.func.isRequired,
}

export default withStyles(styles)(RecipientItem)
