import PropTypes from 'prop-types'

import { Checkbox, FormGroup, FormControlLabel, withStyles } from '@material-ui/core'
import styles from './styles'

const CheckBox = ({ disabled, control, answer, handleChange, classes }) => {
  const options = control.Options
  const { selectedOptionsIds } = answer

  const _getCss = (option) => {
    const isPositiveOption = option.Value.toLowerCase() === 'pass' && !disabled
    const isNegativeOption = option.Value.toLowerCase() === 'fail' && !disabled

    if (isPositiveOption) return classes.green
    if (isNegativeOption) return classes.red
    return classes.default
  }

  return (
    <FormGroup style={{ overflow: 'hidden' }}>
      {options &&
        options.map((option) => {
          const isChecked = selectedOptionsIds ? selectedOptionsIds.includes(option.Id) : false

          const props = {
            checked: isChecked,
            onChange: handleChange,
            value: option.Id,
          }

          return (
            <FormControlLabel
              control={
                <Checkbox {...props} color='default' classes={{ root: _getCss(option), checked: classes.checked }} />
              }
              label={option.Value}
              disabled={disabled}
              key={option.Id}
              title={control.Tooltip}
            />
          )
        })}
    </FormGroup>
  )
}

CheckBox.propTypes = {
  disabled: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    green: PropTypes.string.isRequired,
    red: PropTypes.string.isRequired,
    default: PropTypes.string.isRequired,
    checked: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(CheckBox)
