import { createAction } from 'redux-actions'

export const openCreateEventDialog = createAction('OPEN_CREATE_EVENT_DIALOG')
export const closeCreateEventDialog = createAction('CLOSE_CREATE_EVENT_DIALOG')

export const openEventInfoDialog = createAction('OPEN_EVENT_INFO_DIALOG')
export const closeEventInfoDialog = createAction('CLOSE_EVENT_INFO_DIALOG')

export const openEditEventDialog = createAction('OPEN_EDIT_EVENT_DIALOG')
export const closeEditEventDialog = createAction('CLOSE_EDIT_EVENT_DIALOG')

export const openChangeEventOwnerDialog = createAction('OPEN_CHANGE_EVENT_OWNER_DIALOG')
export const closeChangeEventOwnerDialog = createAction('CLOSE_CHANGE_EVENT_OWNER_DIALOG')

export const openSwitchSessionOwnerDialog = createAction('OPEN_SWITCH_SESSION_OWNER_DIALOG')
export const closeSwitchSessionOwnerDialog = createAction('CLOSE_SWITCH_SESSION_OWNER_DIALOG')

export const openBookAppointmentSlotDialog = createAction('OPEN_BOOK_APPOINTMENT_SLOT_DIALOG')
export const closeBookAppointmentSlotDialog = createAction('CLOSE_BOOK_APPOINTMENT_SLOT_DIALOG')

export const openUpdateSubscriptionColorDialog = createAction('OPEN_UPDATE_SUBSCRIPTION_COLOR_DIALOG')
export const closeUpdateSubscriptionColorDialog = createAction('CLOSE_UPDATE_SUBSCRIPTION_COLOR_DIALOG')
