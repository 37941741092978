const styles = (theme) => {
  return {
    userListItem: {
      display: 'flex',
      alignItems: 'center',
      margin: '8px 0px',
      gap: 10,
    },

    displayName: {
      flex: 3,
    },

    permissionsSelect: {
      flex: 1,
    },
  }
}

export default styles
