import { combineActions, handleActions } from 'redux-actions'

import _ from 'lodash'

import { selectGalleryItem } from '@tabeeb/modules/gallery/actions'

import * as annotationsActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [annotationsActions.setHighlightedAnnotationsIds](state, action) {
      const annotationId = action.payload

      return annotationId
    },
    [annotationsActions.addAnnotationsToHighlighted](state, action) {
      const annotationIds = action.payload

      return _.uniqBy([...state, ...annotationIds])
    },
    [annotationsActions.removeAnnotationsFromHighlighted](state, action) {
      const annotationIds = new Set(action.payload)

      return state.filter((id) => !annotationIds.has(id))
    },
    [annotationsActions.resetHighlightedAnnotationsIds](state, action) {
      return defaultState
    },
    [combineActions(
      selectGalleryItem,
      annotationsActions.resetAnnotationsState,
      annotationsActions.resetHighlightedAnnotationsIds
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
