import { handleActions, combineActions } from 'redux-actions'

import * as tenantConfigActions from '@tabeeb/modules/tenantConfigPage/actions'
import * as rawActions from '../actions'

const defaultState = false

export default handleActions(
  {
    [tenantConfigActions.updateConfig.request]() {
      return true
    },
    [combineActions(
      tenantConfigActions.updateConfig.success,
      tenantConfigActions.updateConfig.failed,
      rawActions.resetState
    )]() {
      return defaultState
    },
  },
  defaultState
)
