import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ZoomIn, ZoomOut } from '@material-ui/icons'
import { Grow, Typography } from '@material-ui/core'

import { DeviceType } from '@tabeeb/enums'
import { ZOOM_MAX, ZOOM_MIN } from '@tabeeb/modules/../users/common/webZoom'
import { usersActions, usersSelectors } from '@tabeeb/modules/../users'
import { presentationSelectors } from '@tabeeb/modules/presentation'

import ToolbarButton from '../ToolbarButton'

import useStyles from './styles'

const WebZoomButtons = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedUser = useSelector(presentationSelectors.getSelectedUser)
  const user = useSelector((state) => usersSelectors.getUserById(state, { Id: selectedUser?.id }))

  if (!selectedUser || !user) {
    return null
  }

  const isMobileUserOnScreen = [
    DeviceType.androidDevice,
    DeviceType.appleDevice,
    DeviceType.androidBrowser,
    DeviceType.iosBrowser,
  ].includes(selectedUser.deviceType)
  if (isMobileUserOnScreen) {
    return null
  }

  const zoomValue = user?.webZoomValue || ZOOM_MIN

  const onZoomIn = () => {
    if (zoomValue >= ZOOM_MAX) {
      return
    }

    dispatch(usersActions.onWebZoomIn(user.id))
  }

  const onZoomOut = () => {
    if (zoomValue <= ZOOM_MIN) {
      return
    }

    dispatch(usersActions.onWebZoomOut(user.id))
  }
  const isZoomInButtonActive = zoomValue < ZOOM_MAX
  const isZoomOutButtonActive = zoomValue > ZOOM_MIN

  return (
    <Grow in>
      <div className={classes.root}>
        <ToolbarButton
          variant='outlined'
          Icon={ZoomIn}
          display={Boolean(selectedUser)}
          disabled={!isZoomInButtonActive}
          title='Zoom in'
          onClick={onZoomIn}
        />
        <Typography className={classes.zoomValue} color='textPrimary' variant='body1'>
          {zoomValue * 100}%
        </Typography>
        <ToolbarButton
          variant='outlined'
          Icon={ZoomOut}
          display={Boolean(selectedUser)}
          disabled={!isZoomOutButtonActive}
          title='Zoom out'
          onClick={onZoomOut}
        />
      </div>
    </Grow>
  )
}

export default memo(WebZoomButtons)
