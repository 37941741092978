import { memo } from 'react'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import moment from 'moment'
import { Field, Form, Formik } from 'formik'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

import { FormikCheckbox } from '@tabeeb/shared/forms'

import { DurationField, PeriodField, VALIDATION_SCHEMAS } from '../../forms'

const schema = Yup.object().shape({
  Expiration: VALIDATION_SCHEMAS.Expiration,
  Duration: VALIDATION_SCHEMAS.Duration,
  Period: VALIDATION_SCHEMAS.Period,
})

const getEffectiveDuration = (invite) => {
  let duration = 28
  let unit = 'day'

  if (invite && invite.EndDate) {
    const startDate = moment(invite.StartDate || invite.CreatedOn)
    const endDate = moment(invite.EndDate)

    const units = ['month', 'day', 'hour']
    unit = units.find((u) => Number.isInteger(endDate.diff(startDate, u, true))) || 0
    duration = endDate.diff(startDate, unit)
  }

  return {
    duration,
    unit,
  }
}

const EditInviteTimeDialog = ({ open, invite, onClose, onSave }) => {
  const { duration, unit } = getEffectiveDuration(invite)

  const initial = {
    Expiration: Boolean(invite?.EndDate),
    Duration: duration,
    Period: unit,
  }

  const onSubmit = async ({ Expiration, Period, Duration }) => {
    onClose()

    await onSave({
      Id: invite.Id,
      StartDate: invite.StartDate || invite.CreatedOn,
      EndDate: Expiration
        ? moment(invite.StartDate || invite.CreatedOn)
            .add(Duration, Period)
            .toDate()
        : null,
    })
  }

  return (
    <Formik initialValues={initial} validateOnMount validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
      {(formik) => (
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogTitle>Edit invite time</DialogTitle>
          <DialogContent>
            <Form id='edit-invite-time-form' autoComplete='off'>
              <Grid container spacing={1}>
                <Grid xs={3} item>
                  <DurationField name='Duration' label='Duration' disabled={!formik.values.Expiration} />
                </Grid>
                <Grid xs={3} item>
                  <PeriodField name='Period' label='Period' disabled={!formik.values.Expiration} />
                </Grid>
                <Grid xs={6} item>
                  <Field
                    name='Expiration'
                    label='Set invite expiration'
                    component={FormikCheckbox}
                    color='primary'
                    size='small'
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!formik.isValid}
              variant='contained'
              color='primary'
              type='submit'
              form='edit-invite-time-form'
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

EditInviteTimeDialog.propTypes = {
  invite: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default memo(EditInviteTimeDialog)
