import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'

import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import Routes from '@tabeeb/routes'
import { DateInfo, PageHeader, PageLayout, Pagination, TableLoadingProgress, TableWrapper } from '@tabeeb/uikit'
import { usePagination } from '@tabeeb/shared/utils/hooks'
import { trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'

import { useTrainingMaterials } from '@tabeeb/modules/articles/hooks'
import { RefreshButton, TrainingMaterialCreateButton, TrainingMaterialMenu } from '@tabeeb/modules/articles/components'
import { getHasManageTrainingMaterialsPermission } from '@tabeeb/modules/articles/selectors'

const TrainingMaterialsPage = () => {
  const dispatch = useDispatch()
  const [itemsCount, setItemsCount] = useState(0)

  const {
    current: currentPage,
    pages,
    size,
    onPageNumberChange,
    onPageSizeChange,
  } = usePagination({ total: itemsCount })
  const { trainingMaterials, totalCount, loading, onReload, onDelete } = useTrainingMaterials({
    skip: (currentPage - 1) * size,
    take: size,
  })

  useEffect(() => {
    setItemsCount(totalCount)
  }, [totalCount])

  const onEdit = useCallback(
    (trainingMaterial) => {
      dispatch(push(generatePath(Routes.trainingMaterialEdit, { id: trainingMaterial.Id })))
    },
    [dispatch]
  )

  const onView = useCallback(
    (trainingMaterial) => {
      dispatch(push(generatePath(Routes.trainingMaterialView, { id: trainingMaterial.Id })))
    },
    [dispatch]
  )

  const hasManagePermission = useSelector(getHasManageTrainingMaterialsPermission)

  return (
    <PageLayout component={Paper} spacing='page' variant='outlined' maxWidth='lg'>
      <PageHeader
        title={trainingMaterialTitleFormatter.format('Training materials')}
        actions={
          <>
            <RefreshButton disabled={loading} onClick={onReload} />
            {hasManagePermission && <TrainingMaterialCreateButton />}
          </>
        }
      />

      <TableContainer>
        {loading && <TableLoadingProgress />}
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Name</TableCell>
                <TableCell align='left' width={150}>
                  Created on
                </TableCell>
                <TableCell align='left' width={150}>
                  Updated on
                </TableCell>
                <TableCell padding='none' width={50} />
              </TableRow>
            </TableHead>
            <TableBody>
              {trainingMaterials.length > 0 ? (
                trainingMaterials.map((trainingMaterial) => (
                  <TableRow key={trainingMaterial.Id} onDoubleClick={() => onView(trainingMaterial)}>
                    <TableCell align='left'>
                      <Typography variant='body2' fontWeight={500} maxWidth={450} title={trainingMaterial.Name} noWrap>
                        {trainingMaterial.Name}
                      </Typography>
                    </TableCell>
                    <TableCell align='left' width={150}>
                      <DateInfo date={trainingMaterial.CreatedOn} />
                    </TableCell>
                    <TableCell align='left' width={150}>
                      <DateInfo date={trainingMaterial.UpdatedOn} />
                    </TableCell>
                    <TableCell align='center' padding='none' width={50}>
                      <TrainingMaterialMenu
                        trainingMaterial={trainingMaterial}
                        onDelete={hasManagePermission ? onDelete : null}
                        onEdit={hasManagePermission ? onEdit : null}
                        onView={onView}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align='center'>
                    <Typography variant='subtitle1'>
                      {trainingMaterialTitleFormatter.format(
                        loading ? 'Loading training materials' : 'No training materials'
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        <Divider />
        <Pagination
          current={currentPage}
          pages={pages}
          pageSize={size}
          onPageNumberChange={onPageNumberChange}
          onPageSizeChange={onPageSizeChange}
        />
      </TableContainer>
    </PageLayout>
  )
}

export default memo(TrainingMaterialsPage)
