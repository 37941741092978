// @ts-check
import { ApplicationInsights, LoggingSeverity } from '@microsoft/applicationinsights-web'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

class TelemetryService {
  /**
   * @type {ReactPlugin}
   */
  #reactPlugin = new ReactPlugin()

  /**
   * @type {ClickAnalyticsPlugin}
   */
  #clickAnalyticsPlugin = new ClickAnalyticsPlugin()

  /**
   * @type {ApplicationInsights | null}
   */
  #appInsights = null

  initialize(connectionString, browserHistory) {
    if (!connectionString) {
      if (process.env.DEBUG_LOGGING_ENABLED === 'true') {
        /* eslint-disable no-console */
        console.warn('Telemetry connection string not provided')
      }
      return
    }

    if (!browserHistory) {
      if (process.env.DEBUG_LOGGING_ENABLED === 'true') {
        console.warn('Browser history for telemetry not provided')
      }
      return
    }

    this.#appInsights = new ApplicationInsights({
      config: {
        connectionString,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        loggingLevelConsole: process.env.NODE_ENV === 'development' && LoggingSeverity.WARNING,
        loggingLevelTelemetry: LoggingSeverity.WARNING,
        correlationHeaderExcludedDomains: JSON.parse(process.env.TELEMETRY_CORRELATION_EXCLUDED_DOMAINS),
        extensions: [this.#clickAnalyticsPlugin, this.#reactPlugin],
        disableTelemetry: this.disableTelemetry,
        extensionConfig: {
          [this.#clickAnalyticsPlugin.identifier]: {
            dataTags: {
              useDefaultContentNameOrId: true,
            },
          },
          [this.#reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    })

    this.#appInsights.loadAppInsights()
  }

  get disableTelemetry() {
    return (localStorage.getItem('ai_disabled') || process.env.DISABLE_TELEMETRY).toLowerCase() === 'true'
  }

  set disableTelemetry(value) {
    localStorage.setItem('ai_disabled', value.toString())
  }

  get appInsights() {
    return this.#appInsights
  }

  get reactPlugin() {
    return this.#reactPlugin
  }

  get clickAnalyticsPlugin() {
    return this.#clickAnalyticsPlugin
  }
}

const ai = new TelemetryService()

export default ai
