import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.setFoundFormsForTenant](state, action) {
      return action.payload
    },
  },
  defaultState
)
