import React from 'react'

import { Box, Tooltip, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import ToolbarButton from '../ToolbarButton'
import { FeaturesList } from './styles'

const PointCloudDocumentationButton = () => {
  return (
    <Tooltip
      placement='top-end'
      componentsProps={{
        tooltip: {
          sx: {
            background: 'rgba(79, 79, 79, 0.9)',
            minWidth: 440,
            p: 3,
          },
        },
      }}
      title={
        <Box color='white' fontSize={(theme) => theme.typography.body2}>
          <Typography variant='h5' fontWeight={600}>
            Talon Point Cloud
          </Typography>
          <br />
          <Typography variant='h6' fontSize={17} fontWeight={500}>
            New Viewer Enhancements
          </Typography>
          <br />
          <Typography variant='body2' fontWeight={600}>
            Simplified Zooming
          </Typography>
          <FeaturesList>
            <li>Double-click to quickly zoom in/out</li>
          </FeaturesList>

          <Typography variant='body2' fontWeight={600}>
            Enhanced Compass Tool
          </Typography>
          <FeaturesList>
            <li>Showing direction at all times</li>
            <li>Click on the compass and reset to North</li>
          </FeaturesList>

          <Typography variant='body2' fontWeight={600}>
            Measurement Enhancements
          </Typography>
          <FeaturesList>
            <li>New surface area measurement functionality</li>
            <li>Ability to label and color code measurements/lines</li>
            <li>Ability to measure angles</li>
            <li>Measurements from all users are stored on the asset</li>
          </FeaturesList>

          <Typography variant='body2' fontWeight={600}>
            New Snapshot tool
          </Typography>
          <FeaturesList>
            <li>Save clips of the model to the gallery</li>
          </FeaturesList>

          <Typography variant='body2' fontWeight={600}>
            Image Preview
          </Typography>
          <FeaturesList>
            <li>Click on the &quot;image preview&quot; to go to the relevant high-resolution images</li>
          </FeaturesList>

          <Typography variant='body2' fontWeight={600}>
            Artificial Intelligence
          </Typography>
          <FeaturesList>
            <li>See AI annotations for recognition or deficiencies directly on the model. *AI contract required</li>
          </FeaturesList>

          <Typography variant='body2' fontWeight={600}>
            Add Models
          </Typography>
          <FeaturesList>
            <li>
              Ability to overlay new equipment models(antennas, Radios, etc...) on the Point Cloud tower view to more
              fully visualize potential new tower configurations
            </li>
          </FeaturesList>

          <Typography variant='body2' fontStyle='italic'>
            <b>
              For more information, please visit our{' '}
              <a
                style={{ color: '#00bbd4', textDecoration: 'underline' }}
                href='https://talonsupport.freshdesk.com/support/solutions/articles/44002447019-point-cloud-viewer'
                target='_blank'
                rel='noreferrer'
              >
                Point Cloud Viewer guide here
              </a>
            </b>
          </Typography>
        </Box>
      }
    >
      <div>
        <ToolbarButton Icon={InfoOutlined} title='Info' htmlColor='#00bbd4' />
      </div>
    </Tooltip>
  )
}

export default React.memo(PointCloudDocumentationButton)
