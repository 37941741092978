import { RecordingStatus } from '@tabeeb/enums'
import { createSelector } from 'reselect'

export const getSelectedUserId = (state) => state.recording.selectedUserId
export const getElapsedTime = (state) => state.recording.elapsedTime || 0
export const getRecordingStatus = (state) => state.recording.recordingStatus
export const getIsSwitchRecordedUserConfirmationDialogOpen = (state) =>
  state.recording.isSwitchRecordedUserConfirmationDialogOpen

export const getIsRecordingActive = createSelector([getRecordingStatus], (recordingStatus) => {
  return [RecordingStatus.started, RecordingStatus.pausing].includes(recordingStatus)
})

export const getRecordingState = (state) => {
  const {
    recording: {
      recordId,
      selectedUserId,
      serverName,
      session,
      errorMessage,
      errorCode,
      recordingStatus,
      startDate,
      stopDate,
    },
  } = state

  return {
    recordId,
    selectedUserId,
    serverName,
    session,
    errorMessage,
    errorCode,
    recordingStatus,
    startDate,
    stopDate,
  }
}

export const makeGetIsUserRecordingActive = () =>
  createSelector(
    [getIsRecordingActive, getSelectedUserId, (_, { userId }) => userId],
    (active, recordingUserId, userId) => {
      return active && recordingUserId === userId
    }
  )
