export default (theme) => {
  return {
    container: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    message: {
      height: '100%',
      fontSize: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  }
}
