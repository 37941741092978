import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as galleryActions from '@tabeeb/modules/gallery/actions'
import { getGalleryList } from '@tabeeb/modules/gallery/selectors'
import { isImagePage } from '@tabeeb/services/pageService'
import * as notificationActions from '../../notification/actions'
import * as actions from '../actions'

function* onAddFolderToClassificationAnnotations({ payload: { folderId } }) {
  const galleryList = yield select(getGalleryList)

  const pages = galleryList.filter((page) => page.folderId === folderId && isImagePage(page.contentType))

  if (pages.length < 1) {
    yield put(
      notificationActions.onAddWarningNotification({
        message: 'Folder is empty!',
      })
    )

    return
  }

  yield put(actions.addPagesToClassificationAnnotationsPages(pages))
}

function* addFolderPages() {
  yield all([takeLatest(galleryActions.addFolderToClassificationAnnotations, onAddFolderToClassificationAnnotations)])
}

export default addFolderPages
