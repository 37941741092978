import { TrackingAnnotationType, TrackingEventType } from '../../../Enums'

export const GoogleMapsAnnotationTypes = {
  Marker: TrackingAnnotationType.Point,
  Rectangle: TrackingAnnotationType.Rectangle,
  Circle: TrackingAnnotationType.Circle,
}

export const TrackingEventTypes = {
  'Check in': TrackingEventType.CheckIn,
  'Check out': TrackingEventType.CheckOut,
  Manual: TrackingEventType.Manual,
}

export const TrackingFilterTypes = {
  TrackingTypes: 'trackingTypes',
  EventTypes: 'eventTypes',
  TimePeriod: 'timePeriod',
}
