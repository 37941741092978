import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ExploreOutlined } from '@material-ui/icons'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { isSupportCompass } from '@tabeeb/services/pageService'
import { compassActions, compassSelectors } from '@tabeeb/modules/compass'

import ToolbarButton from '../ToolbarButton'

const CompassButton = () => {
  const dispatch = useDispatch()

  const open = useSelector(compassSelectors.getIsOpen)
  const display = useSelector((state) => isSupportCompass(gallerySelectors.getSelectedGalleryItemType(state)))

  const handleClick = useCallback(() => {
    if (open) {
      dispatch(compassActions.closeCompass())
    } else {
      dispatch(compassActions.openCompass())
    }
  }, [dispatch, open])

  return (
    <ToolbarButton
      Icon={ExploreOutlined}
      display={display}
      selected={open}
      onClick={handleClick}
      title={open ? 'Hide compass' : 'Show compass'}
    />
  )
}

export default memo(CompassButton)
