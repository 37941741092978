import { combineActions, handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = true

const areTabsLoading = handleActions(
  {
    [actions.getContentMetadataTabs.request](state, action) {
      return true
    },
    [combineActions(
      actions.getContentMetadataTabs.success,
      actions.getContentMetadataTabs.failed,
      actions.closeContentMetadataDialog
    )]() {
      return false
    },
  },
  defaultState
)

export default areTabsLoading
