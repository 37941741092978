const contentReviewsRequests = {
  getContentReviewsRequest: ({ contentId }) => ({
    url: `api/contentsharings/getreviews/${contentId}`,
    method: 'get',
  }),
  sendReviewFeedbackRequest: (data) => ({
    url: `api/contentsharings/sendReviewFeedback`,
    method: 'post',
    data,
  }),
  completeReviewRequest: (data) => ({
    url: `api/contentsharings/completeReview`,
    method: 'post',
    data,
  }),
  revokeReviewRequest: (contentSharingId) => ({
    url: `api/contentsharings/revokesharing/${contentSharingId}`,
    method: 'get',
  }),
  getTenantsListForReviewRequest: (contentId) => ({
    url: `api/contentsharings/gettenantslistforreview/${contentId}`,
    method: 'get',
  }),
  renameContentForTenantRequest: (data) => ({
    url: `api/contentsharings/renameContent`,
    method: 'put',
    data,
  }),
  getContentDescriptionsRequest: (contentId) => ({
    url: `api/contents/getcontentdescriptions/${contentId}`,
    method: 'get',
  }),
  sendForInContentReviewRequest: (data) => ({
    url: `api/contentsharings/InContentReview`,
    method: 'post',
    data,
  }),
  feedbackInContentReviewRequest: (data) => ({
    url: `api/contentsharings/InContentReview/sendfeedback`,
    method: 'post',
    data,
  }),
  completeInContentReviewRequest: (data) => ({
    url: `api/contentsharings/InContentReview/complete`,
    method: 'post',
    data,
  }),
  getContentReviewStatusRequest: ({ contentId }) => ({
    url: `api/contentsharings/getreviewstatus/${contentId}`,
    method: 'get',
  }),
  beginInContentReviewRequest: (data) => ({
    url: `api/contentsharings/InContentReview/begin`,
    method: 'post',
    data,
  }),
  getTenantContentReviewsRequest: (contentId) => ({
    url: `api/contentsharings/getTenantContentReviews/${contentId}`,
    method: 'get',
  }),
  joinContentReviewRequest: (data) => ({
    url: `api/contentsharings/joinContentReview`,
    method: 'post',
    data,
  }),
}

export default contentReviewsRequests
