import PropTypes from 'prop-types'

import moment from 'moment'

import DateFnsUtils from '@date-io/date-fns'

import { withStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

import TimeDropdownPicker from '../TimeDropdownPicker'

import styles from './styles'

const getDateTime = (date, time) => {
  const startOfDay = moment(date).startOf('day')
  const dayTimeInMilliseconds = moment(time).diff(moment(time).startOf('day'), 'milliseconds')

  return startOfDay.clone().add(dayTimeInMilliseconds, 'milliseconds').toDate()
}

const DateTimePicker = ({ classes, disabled, error, label, size, value, variant, onChange }) => {
  const handleStartDateChange = (date) => {
    onChange(getDateTime(date, value))
  }

  const handleStartTimeChange = (time) => {
    onChange(getDateTime(value, time))
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <DatePicker
          fullWidth
          error={!!error}
          disabled={disabled}
          disableToolbar
          size={size}
          label={label}
          variant='inline'
          inputVariant={variant}
          value={value}
          format='d MMMM'
          onChange={handleStartDateChange}
        />
        <TimeDropdownPicker
          disabled={disabled}
          error={!!error}
          size={size}
          variant={variant}
          value={value}
          onChange={handleStartTimeChange}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}

DateTimePicker.defaultProps = {
  disabled: false,
}

DateTimePicker.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  value: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(DateTimePicker)
