import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [rawActions.openCompass](state, action) {
      return true
    },
    [rawActions.closeCompass](state, action) {
      return false
    },
    [rawActions.resetCompass](state, action) {
      return false
    },
  },
  false
)
