import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { withStyles, TextField, Button } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

import { getUsersListForCurrentUser } from '@tabeeb/modules/../users/selectors'
import { PageFolderAccessViewType, PageFolderPermission } from '@tabeeb/enums'

import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import EditFolderDialogUserPermission from '../EditFolderDialogUserPermission'

import styles from './styles'

const filterOptions = createFilterOptions({
  stringify: ({ name, email }) => `${name} ${email}`,
})

const UserSelect = ({ field, classes, accessType }) => {
  const dispatch = useDispatch()
  const [user, selectUser] = useState(null)

  const currentUserId = useSelector(getCurrentUserId)

  const { setFieldValue } = useFormikContext()
  const contentUsers = useSelector(getUsersListForCurrentUser)
  const options = contentUsers
    .filter((contentUser) => !contentUser.isDeleted && contentUser.id !== currentUserId)
    .filter((contentUser) => !field.value?.find((permissionModel) => permissionModel.userId === contentUser.id))

  const isUserNotSelected = user === null
  const isNotShared = accessType !== PageFolderAccessViewType.Shared

  const usersToDisplay = field.value.filter((model) => model.permissions.length !== 0)

  const handleAddClick = () => {
    const permissionModel = {
      userId: user.id,
      permissions: [PageFolderPermission.FullAccess],
      displayName: user.displayName,
    }

    const newFieldValue = field.value ? [...field.value, permissionModel] : [permissionModel]

    setFieldValue(field.name, newFieldValue)
    selectUser(null)
  }

  const handlePermissionCheckClick = (permissions, userId) => {
    if (permissions.length === 0) {
      dispatch(
        onAddErrorNotification({
          message: 'The user must have at least 1 permission',
        })
      )
      return
    }

    setFieldValue(
      field.name,
      field.value.map((model) => (model.userId === userId ? { ...model, permissions } : model))
    )
  }

  const handleRemoveClick = (userId) => {
    setFieldValue(
      field.name,
      field.value.map((model) => (model.userId === userId ? { ...model, permissions: [] } : model))
    )
  }

  return (
    <div>
      <div className={classes.userAutoComplete}>
        <Autocomplete
          options={options}
          renderInput={(params) => (
            <TextField {...params} size='small' variant='outlined' label='Search by email or name' />
          )}
          value={user}
          onChange={(event, newValue) => {
            selectUser(newValue)
          }}
          className={classes.userTextField}
          getOptionLabel={(option) => option.displayName}
          filterOptions={filterOptions}
          disabled={isNotShared}
        />
        <Button
          className={classNames(classes.button, classes.addButton)}
          size='small'
          disabled={isUserNotSelected || isNotShared}
          onClick={handleAddClick}
        >
          Add
        </Button>
      </div>
      {!isNotShared && (
        <div className={classes.userList}>
          {usersToDisplay?.map(({ userId, permissions, displayName }) => (
            <EditFolderDialogUserPermission
              key={userId}
              values={field.value}
              userId={userId}
              permissions={permissions}
              displayName={displayName}
              handlePermissionCheckClick={handlePermissionCheckClick}
              handleRemoveClick={handleRemoveClick}
            />
          ))}
        </div>
      )}
    </div>
  )
}

UserSelect.propTypes = {
  classes: PropTypes.shape({
    userList: PropTypes.string.isRequired,
    userTextField: PropTypes.string.isRequired,
    userAutoComplete: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    addButton: PropTypes.string.isRequired,
  }).isRequired,
  field: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        permissions: PropTypes.arrayOf(PropTypes.number),
        userId: PropTypes.number.isRequired,
      })
    ),
    name: PropTypes.string.isRequired,
  }),
  accessType: PropTypes.string.isRequired,
}

export default withStyles(styles)(UserSelect)
