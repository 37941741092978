import { takeLatest, put, all } from 'redux-saga/effects'

import { galleryActions } from '../../gallery'
import { contentNotificationService } from '../../shared/content'
import * as rotatePageActions from '../actions/rotatePage'
import * as notificationActions from '@tabeeb/modules/notification/actions'

function* onRotatePageSuccess(action) {
  const {
    response: {
      data: { Id: pageId },
    },
  } = action

  yield put(galleryActions.getGalleryItemRequest({ pageId, isSelectAddedGalleryItem: false, suppressMessage: false }))
  yield contentNotificationService.notifyOfUpdatePage({ pageId })
}

function* onRotatePageFailed(actionObj) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to rotate page' }))
}

function* rotatePageSaga() {
  yield all([
    takeLatest(rotatePageActions.onRotatePageSuccess, onRotatePageSuccess),
    takeLatest(rotatePageActions.onRotatePageFailed, onRotatePageFailed),
  ])
}

export default rotatePageSaga
