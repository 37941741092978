import { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  resetUsersSearchState,
  searchUserForSharing,
  setUserForSharing,
  updateSearchUserText,
} from '@tabeeb/modules/contentSharings/actions'
import { getContentSharingDialogState, getUserSearchState } from '@tabeeb/modules/contentSharings/selectors'
import { useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'
import { SearchResultRowBtn } from '@tabeeb/modules/contentSharings/constants/enums'
import SearchUsersResult from './searchUsersResult'
import UserRow from './userRow'
import { SelectContainer, StyledInput, SearchResultContainer } from './styles'

const UserSelect = ({ isDisabled }) => {
  const dispatch = useDispatch()
  const { tenant, user } = useSelector(getContentSharingDialogState)
  const { searchText } = useSelector(getUserSearchState)
  const debouncedSearchQuery = useDebouncedValue(searchText, 500)

  useEffect(() => {
    if (searchText && debouncedSearchQuery && tenant) {
      dispatch(searchUserForSharing.request({ search: debouncedSearchQuery, tenantId: tenant.Id }))
    }

    if (!searchText) {
      dispatch(resetUsersSearchState())
    }
  }, [debouncedSearchQuery, dispatch, tenant, searchText])

  const _handleRemoveButtonClick = useCallback(() => {
    dispatch(setUserForSharing(null))
  }, [dispatch])

  return (
    <SelectContainer>
      {!user && (
        <StyledInput
          value={searchText}
          onChange={(e) => dispatch(updateSearchUserText(e.target.value))}
          disableUnderline
          disabled={isDisabled}
        />
      )}
      <SearchResultContainer>
        {!user && <SearchUsersResult />}
        {user && (
          <UserRow user={user} button={SearchResultRowBtn.Remove} handleButtonClick={_handleRemoveButtonClick} />
        )}
      </SearchResultContainer>
    </SelectContainer>
  )
}

UserSelect.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
}

export default memo(UserSelect)
