import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ScreenShareOutlined, StopScreenShareOutlined } from '@material-ui/icons'

import { presentationSelectors, screenSharingActions } from '@tabeeb/modules/presentation'

import ToolbarButton from '../ToolbarButton'

const ScreenSharingButton = () => {
  const dispatch = useDispatch()

  const isScreenSharing = useSelector(presentationSelectors.getIsScreenSharing)
  const isScreenSharingInProgress = useSelector(presentationSelectors.getIsScreenSharingInProgress)

  const onClick = useCallback(() => {
    if (isScreenSharingInProgress) {
      return
    }

    if (isScreenSharing) {
      dispatch(screenSharingActions.stopScreenSharing())
    } else {
      dispatch(screenSharingActions.startScreenSharing())
    }
  }, [dispatch, isScreenSharing, isScreenSharingInProgress])

  return (
    <ToolbarButton
      onClick={onClick}
      selected={isScreenSharing}
      Icon={isScreenSharing ? StopScreenShareOutlined : ScreenShareOutlined}
      title={isScreenSharing ? 'Stop screen sharing' : 'Share screen'}
    />
  )
}

export default memo(ScreenSharingButton)
