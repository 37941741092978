import { contentStateSelectors } from '@tabeeb/modules/shared/content'
import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as usersActions from '../actions'
import { signalrActions } from '../../modules/signalr'

function* kickUser(action) {
  const userId = action.payload
  const contentId = yield select(contentStateSelectors.getContentId)

  const model = {
    contentId,
    userId,
  }

  yield put(usersActions.kickUserRequest(model))
}

function* kickUserSuccess(action) {
  const data = action.payload
  const presenterId = yield select((state) => state.contentState.presenterId)
  const isPresenter = presenterId === data.userId

  yield put(usersActions.removeUser(data.userId))

  if (isPresenter === true) {
    yield put(signalrActions.invokeHubAction({ method: 'SetPresenter', args: [data.contentId, null] }))
  }
  yield put(signalrActions.invokeHubAction({ method: 'RemoveUserFromContent', args: [data.contentId, data.userId] }))
}

function* usersSaga() {
  yield all([takeLatest(usersActions.kickUser, kickUser), takeLatest(usersActions.kickUserSuccess, kickUserSuccess)])
}

export default usersSaga
