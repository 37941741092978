import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.less'

const FormInput = (props) => {
  const {
    input,
    label,
    type,
    placeholder,
    disabled,
    className,
    meta: { active, error, touched },
    autoFocus,
  } = props

  const classes = classNames('form-input', className, {
    '-focus': active,
    '-error': !disabled && touched && error,
    '-disabled': disabled,
  })

  const inputProps = {
    ...input,
    id: input.name,
    className: 'input',
    type,
    placeholder,
    disabled,
    autoFocus,
  }

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={input.name} className='input-label'>
          {label}:
        </label>
      ) : null}
      <input {...inputProps} />
      {!disabled && touched && error && <span className='error'>{error}</span>}
    </div>
  )
}

FormInput.propTypes = {
  input: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

FormInput.defaultProps = {
  disabled: false,
  autoFocus: false,
}

export default FormInput
