import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FlagOutlined } from '@material-ui/icons'

import { drawingActions, playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'
import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import AnnotationPresetSelector, { Preset } from '../AnnotationPresetSelector'
import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'

const BUTTON_NAME = 'flag'

const presets = [
  Preset.Hotspot,
  Preset.Rectangle,
  Preset.Circle,
  Preset.Stroke,
  Preset.Arrow,
  Preset.Line,
  Preset.Polygon,
]

const FlagButton = () => {
  const dispatch = useDispatch()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const color = useSelector(playerToolbarSelectors.getSelectedColor)
  const preset = useSelector(playerToolbarSelectors.getDrawingPreset)
  const selected = useSelector((state) => playerToolbarSelectors.getIsButtonSelected(state, BUTTON_NAME))
  const isDrawingEnabled = useSelector(playerToolbarSelectors.getIsDrawingEnabled)

  const onClick = useCallback(() => {
    if (selected) {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: '' }))
      dispatch(drawingActions.onDisableDrawing())
    } else {
      onOpen()
    }
  }, [dispatch, onOpen, selected])

  const onSelectPreset = useCallback(
    (selectedPreset) => {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: BUTTON_NAME }))

      dispatch(drawingActions.onSetDrawingType(selectedPreset.type))
      dispatch(drawingActions.onSetDrawingPreset(selectedPreset.id))
      dispatch(drawingActions.onEnableDrawing())
    },
    [dispatch]
  )

  return (
    <>
      <ToolbarButton
        ref={anchorRef}
        Icon={FlagOutlined}
        htmlColor={selected && isDrawingEnabled ? color : null}
        title='Add Flag annotation'
        selected={selected || open}
        onClick={onClick}
      />
      <ToolbarPopover anchorEl={anchorRef.current} open={open} onClose={onClose}>
        <AnnotationPresetSelector
          presets={presets}
          color={color}
          selected={selected ? preset : null}
          enabled={isDrawingEnabled}
          onSelect={onSelectPreset}
        />
      </ToolbarPopover>
    </>
  )
}

export default memo(FlagButton)
