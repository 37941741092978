import { all } from 'redux-saga/effects'

import kickUserSaga from './kickUserSaga'
import mobileZoomSaga from './mobileZoomSaga'
import sessionSaga from './sessionSaga'

function* usersSaga() {
  yield all([kickUserSaga(), mobileZoomSaga(), sessionSaga()])
}

export default usersSaga
