export const IterationPagesDisplayMode = {
  All: 'all',
  IncludedOnly: 'includedOnly',
  ExcludedOnly: 'excludedOnly',
}

export const IterationPagesDisplayModeDescription = {
  [IterationPagesDisplayMode.All]: 'All',
  [IterationPagesDisplayMode.IncludedOnly]: 'Included',
  [IterationPagesDisplayMode.ExcludedOnly]: 'Excluded',
}

export const IterationPagesDisplayModeTooltip = {
  [IterationPagesDisplayMode.All]: 'Display all the pages both excluded from training and included in training.',
  [IterationPagesDisplayMode.IncludedOnly]: 'Display only the pages that are included in training.',
  [IterationPagesDisplayMode.ExcludedOnly]: 'Display only the pages that are excluded from training.',
}
