import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

const NavigationMenu = ({ anchorEl, open, onClose, items: links }) => {
  const { pathname } = useLocation()

  const isLinkSelected = (link) => {
    return pathname === link
  }

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{ sx: { minWidth: 200, maxWidth: 200, maxHeight: 250 } }}
    >
      {links.map(({ href: subitemHref, name: subitemName, icon: SubitemIcon }) => (
        <Link key={subitemHref} to={subitemHref}>
          <MenuItem dense selected={isLinkSelected(subitemHref)}>
            {SubitemIcon && (
              <ListItemIcon>
                <SubitemIcon />
              </ListItemIcon>
            )}
            <ListItemText primary={subitemName} />
          </MenuItem>
        </Link>
      ))}
    </Menu>
  )
}

NavigationMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(HTMLElement),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default NavigationMenu
