import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'

import { Layer, Stage } from 'react-konva'

import { Fade } from '@material-ui/core'

import { onDisableSnippingTool } from '@tabeeb/modules/playerToolbar/actions/drawing'
import { getIsSnippingToolEnabled } from '@tabeeb/modules/playerToolbar/selectors'
import { getPointerPosition } from '@tabeeb/modules/annotations/services/annotationsService'

import Toolbar from './Toolbar'
import SelectionArea from './SelectionArea'

import { takeContentSnapshot } from '../../actions'

import useStyles from './styles'

const SELECTION_DEFAULT_STATE = { active: false, complete: false, startX: 0, startY: 0, endX: 0, endY: 0 }

const SnippingTool = ({ width: stageWidth, height: stageHeight }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [{ active, complete, startX, startY, endX, endY }, setState] = useState(SELECTION_DEFAULT_STATE)

  const topLeftX = Math.min(startX, endX)
  const topLeftY = Math.min(startY, endY)
  const bottomRightY = Math.max(startY, endY)
  const bottomRightX = Math.max(startX, endX)

  const enabled = useSelector(getIsSnippingToolEnabled)

  useEffect(() => {
    setState(SELECTION_DEFAULT_STATE)
  }, [enabled])

  const onMouseDown = useCallback(({ target }) => {
    const position = getPointerPosition(target)

    setState({
      active: true,
      complete: false,
      startX: position.X,
      startY: position.Y,
      endX: position.X,
      endY: position.Y,
    })
  }, [])

  const onMouseMove = useCallback(({ target }) => {
    const position = getPointerPosition(target)

    setState((prevState) => ({
      ...prevState,
      endX: position.X,
      endY: position.Y,
    }))
  }, [])

  const onMouseUp = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        complete: true,
      }
    })
  }, [])

  const onSnapshotAreaMove = useMemo(
    () =>
      _.throttle(({ target }) => {
        setState((prevState) => {
          const offsetX = target.x() - prevState.startX
          const offsetY = target.y() - prevState.startY

          return {
            ...prevState,
            startX: prevState.startX + offsetX,
            startY: prevState.startY + offsetY,
            endX: prevState.endX + offsetX,
            endY: prevState.endY + offsetY,
          }
        })
      }, 10),
    []
  )

  const onCancelSnapshot = useCallback(() => {
    setState(SELECTION_DEFAULT_STATE)
  }, [])

  const onSubmitSnapshot = useCallback(() => {
    dispatch(
      takeContentSnapshot({
        x: topLeftX,
        y: topLeftY,
        width: bottomRightX - topLeftX,
        height: bottomRightY - topLeftY,
      })
    )

    dispatch(onDisableSnippingTool())
  }, [dispatch, topLeftX, topLeftY, bottomRightX, bottomRightY])

  return (
    <>
      <Fade in={enabled} unmountOnExit>
        <Stage
          className={classNames(classes.container, 'snipping-tool')}
          scaleX={1}
          scaleY={1}
          width={stageWidth}
          height={stageHeight}
        >
          <Layer
            onMouseDown={!active && !complete && onMouseDown}
            onMouseMove={active && !complete && onMouseMove}
            onMouseUp={active && !complete && onMouseUp}
            onMouseLeave={active && !complete && onCancelSnapshot}
          >
            <SelectionArea
              stageWidth={stageWidth}
              stageHeight={stageHeight}
              x={topLeftX}
              y={topLeftY}
              width={bottomRightX - topLeftX}
              height={bottomRightY - topLeftY}
              draggable={complete}
              onMove={onSnapshotAreaMove}
            />
          </Layer>
        </Stage>
      </Fade>

      {complete && (
        <Toolbar
          x={stageWidth - bottomRightX}
          y={bottomRightY}
          onCancelSnapshot={onCancelSnapshot}
          onSubmitSnapshot={onSubmitSnapshot}
        />
      )}
    </>
  )
}

SnippingTool.defaultProps = {
  height: 0,
  width: 0,
}

SnippingTool.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default React.memo(SnippingTool)
