import { handleActions } from 'redux-actions'
import { openAiReportPublishSettingsDialog, closeAiReportPublishSettingsDialog } from '../actions'

const defaultState = false

export default handleActions(
  {
    [openAiReportPublishSettingsDialog]() {
      return true
    },
    [closeAiReportPublishSettingsDialog]() {
      return false
    },
  },
  defaultState
)
