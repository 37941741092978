import { DeviceVendorType } from '@tabeeb/enums'

const HealthDeviceTypes = [
  DeviceVendorType.Life365,
  DeviceVendorType.Tenovi,
  DeviceVendorType.Google,
  DeviceVendorType.Apple,
]

export const isHealthDevice = (type) => HealthDeviceTypes.includes(type)
