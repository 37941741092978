export const Extensions = {
  Las: '.las',
  Pcd: '.pcd',
  Ply: '.ply',
  Stl: '.stl',
  Obj: '.obj',
  Glb: '.glb',
  PotreeLegacy: '.js',
  Potree: '.json',
  Splat: '.splat',
}

export const SupportedPointCloudExtensions = [
  Extensions.Las,
  Extensions.Pcd,
  Extensions.Ply,
  Extensions.Stl,
  Extensions.Obj,
  Extensions.Glb,
  Extensions.Splat,
]
