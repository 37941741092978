export const getWhiteBoardState = (state) => state.whiteboard

export const getMobileJoinCallDialogOpened = (state) => getWhiteBoardState(state).mobileJoinCallDialogOpened
export const getIsSwitchRecordingSourceDialogOpened = (state) =>
  getWhiteBoardState(state).isSwitchRecordingSourceDialogOpened
export const getIsMuteCameraDialogOpened = (state) => getWhiteBoardState(state).isMuteCameraDialogOpened
export const getIsMakePresenterDialogOpened = (state) => getWhiteBoardState(state).makePresenterDialogState.isOpen
export const getMakePresenterDialogData = (state) => getWhiteBoardState(state).makePresenterDialogState.data
export const getSwitchRecordingSourceDialogDeviceId = (state) =>
  getWhiteBoardState(state).switchRecordingSourceDialogDeviceId
export const getLeaveSessionDialogState = (state) => getWhiteBoardState(state).leaveSessionDialogState
export const getIsPickRecordingTypeDialogOpened = (state) => getWhiteBoardState(state).recordingTypeDialogState.opened
export const getRecordingTypeDialogResult = (state) => getWhiteBoardState(state).recordingTypeDialogState.result
