import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    input: {
      width: 40,
      border: 'none',
      backgroundColor: 'transparent',
    },
    pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 54,
      color: theme.palette.text.secondary,
      '& span': {
        width: 24,
        height: 24,
      },

      '&:hover': {
        cursor: 'text',
      },
      '&[disabled]': {
        cursor: 'not-allowed',
      },
    },
    content: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  }
})
