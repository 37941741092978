import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { callApiAsync } from '@tabeeb/shared/utils/requests'
import { getActiveUserQualificationIds } from '@tabeeb/modules/qualifications/actions'
import { getQualificationsForInvitesEnabled } from '@tabeeb/modules/appConfigState/selectors'

export default ({ users, requiredQualificationIds }) => {
  const isQualificationsForInvitesEnabled = useSelector(getQualificationsForInvitesEnabled)
  const [loadingQualifications, setLoadingQualifications] = useState(false)
  const [usersQualifications, setUsersQualifications] = useState([])

  useEffect(() => {
    if (
      !isQualificationsForInvitesEnabled ||
      requiredQualificationIds == null ||
      requiredQualificationIds.length === 0 ||
      users.length === 0
    ) {
      return
    }

    const fetchQualifications = async () => {
      setLoadingQualifications(true)
      const {
        response: { data },
      } = await callApiAsync(
        getActiveUserQualificationIds.request({ userGuids: users.map((user) => user.IdentityGuid) })
      )

      setLoadingQualifications(false)

      setUsersQualifications(data)
    }

    fetchQualifications()
  }, [isQualificationsForInvitesEnabled, requiredQualificationIds, users])

  return { loadingQualifications, usersQualifications }
}
