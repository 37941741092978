import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import { Typography, IconButton, Slider, Grid } from '@material-ui/core'

import PauseIcon from '@material-ui/icons/Pause'
import PlayIcon from '@material-ui/icons/PlayArrow'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeDownIcon from '@material-ui/icons/VolumeDown'
import VolumeMuteIcon from '@material-ui/icons/VolumeOff'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'

import { getTimeString } from '../../../recording/services/helpers'

import styles, { timeline } from './styles'

const Timeline = withStyles(timeline)(Slider)

const PlayerControls = ({
  classes,
  onSeek,
  onPlay,
  onPause,
  playing,
  elapsedTime,
  totalDuration,
  onMute,
  muted,
  onToggleFullScreen,
  volume,
  onVolumeChange,
}) => {
  return (
    <Grid className={classes.container} container>
      <Grid className={classes.timeline} item xs={12}>
        <Timeline min={0} max={totalDuration * 1000} value={elapsedTime * 1000} onChangeCommitted={onSeek} />
      </Grid>

      <Grid className={classes.footer} container>
        <Grid item>
          <Grid container alignItems='center'>
            <IconButton className={classes.icon} onClick={playing ? onPause : onPlay}>
              {playing ? <PauseIcon titleAccess='Pause' /> : <PlayIcon titleAccess='Play' />}
            </IconButton>
            <IconButton className={classes.icon} title='Volume control' onClick={onMute}>
              {muted || volume === 0.0 ? <VolumeMuteIcon /> : volume > 0.5 ? <VolumeUpIcon /> : <VolumeDownIcon />}
            </IconButton>
            <Slider
              min={0}
              max={100}
              value={muted ? 0 : volume * 100}
              onChange={onVolumeChange}
              className={classes.volumeSlider}
            />

            <Typography className={classes.time} variant='body1'>
              {getTimeString(elapsedTime * 1000)}/{getTimeString(totalDuration * 1000)}
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <IconButton className={classes.icon} onClick={onToggleFullScreen}>
            <FullscreenExitIcon titleAccess='Fullscreen' />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

PlayerControls.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    timeline: PropTypes.string.isRequired,
    footer: PropTypes.string.isRequired,
    volumeSlider: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  }).isRequired,
  onSeek: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onVolumeChange: PropTypes.func.isRequired,
  onToggleFullScreen: PropTypes.func.isRequired,
  onMute: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  elapsedTime: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,
  totalDuration: PropTypes.number.isRequired,
  muted: PropTypes.bool.isRequired,
}
export default withStyles(styles)(PlayerControls)
