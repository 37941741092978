import PropTypes from 'prop-types'
import { Popover, Divider, Typography, List, ListItem } from '@material-ui/core'
import { MicRounded, VolumeUp } from '@material-ui/icons'

import useMicrophoneSettings from '@tabeeb/modules/presentation/hooks/useMicrophoneSettings'
import useSpeakerSettings from '@tabeeb/modules/presentation/hooks/useSpeakerSettings'

import './styles.less'

const SoundSettings = ({
  anchorEl,
  isOpen,
  onClose,
  originPosition = {
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    transformOrigin: { vertical: 'center', horizontal: 'right' },
  },
}) => {
  const { showInputDevices, handleAudioInputDeviceClick, selectedAudioInputDevice, audioInputDevices } =
    useMicrophoneSettings({ open: isOpen })

  const { selectedAudioOutputDevice, handleAudioOutputDeviceClick, showOutputDevices, audioOutputDevices } =
    useSpeakerSettings({ open: isOpen })

  return (
    <Popover
      className='user-options-controls-popover'
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      {...originPosition}
    >
      <div className='section'>
        <div className='section-title'>
          <MicRounded className='title-icon' color='disabled' />
          <Typography className='title-text'>Microphones</Typography>
        </div>
        <List className='section-content'>
          {showInputDevices ? (
            audioInputDevices.map((device) => (
              <ListItem
                key={device.deviceId}
                button
                title={device.label}
                onClick={() => handleAudioInputDeviceClick(device.deviceId)}
                selected={device.deviceId === selectedAudioInputDevice}
              >
                <Typography noWrap>{device.label}</Typography>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography noWrap>Devices are not detected</Typography>
            </ListItem>
          )}
        </List>
      </div>
      <Divider />
      <div className='section'>
        <div className='section-title'>
          <VolumeUp className='title-icon' color='disabled' />
          <Typography className='title-text'>Speakers</Typography>
        </div>
        <List className='section-content'>
          {showOutputDevices ? (
            audioOutputDevices.map((device) => (
              <ListItem
                key={device.deviceId}
                button
                title={device.label}
                onClick={() => handleAudioOutputDeviceClick(device.deviceId)}
                selected={device.deviceId === selectedAudioOutputDevice}
              >
                <Typography noWrap>{device.label}</Typography>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography noWrap>Devices are not detected</Typography>
            </ListItem>
          )}
        </List>
      </div>
    </Popover>
  )
}

SoundSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
}

export default SoundSettings
