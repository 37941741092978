import { handleActions, combineActions } from 'redux-actions'

import * as aiActions from '../../actions'

const defaultState = {
  oldAIObject: null,
  newAIObject: null,
}

export default handleActions(
  {
    [aiActions.setConflictingAiObjects](state, action) {
      return { ...action.payload }
    },
    [combineActions(aiActions.restoreAiObject.success, aiActions.updateAiObject.success)](state, action) {
      return defaultState
    },
  },
  defaultState
)
