import { combineActions, handleActions } from 'redux-actions'

import { createSheet } from '@tabeeb/modules/contentMetadata/actions'

const defaultState = false

export default handleActions(
  {
    [createSheet.request]() {
      return true
    },
    [combineActions(createSheet.success, createSheet.failed, createSheet.cancel)]() {
      return false
    },
  },
  defaultState
)
