import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    chip: {
      marginLeft: theme.spacing(0.5),

      '& span': {
        maxWidth: 256,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        userSelect: 'none',
      },
    },
    private: {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,

      '&:focus': {
        background: theme.palette.error.main,
      },

      '& .MuiChip-icon': {
        color: 'inherit',
      },
    },
  }
})
