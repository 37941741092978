import { put, takeLatest, all } from 'redux-saga/effects'

import { ErrorCode } from '@tabeeb/enums'
import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onCreateSelectionListSuccess(action) {
  yield put(notificationActions.onAddInfoNotification({ message: 'A list of options created successfully.' }))
}

function* onCreateSelectionListFailed({ response }) {
  if (response?.data?.Error?.Code === ErrorCode.SelectionListAlreadyExists) {
    yield put(
      notificationActions.onAddErrorNotification({ message: 'A list of options with the same name already exists.' })
    )
    return
  }

  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to create a list of options.' }))
}

function* createSelectionList() {
  yield all([
    takeLatest(actions.createSelectionList.success, onCreateSelectionListSuccess),
    takeLatest(actions.createSelectionList.failed, onCreateSelectionListFailed),
  ])
}

export default createSelectionList
