const trackingRequests = {
  addTrackingRequest: (data) => {
    return {
      url: `api/tracking`,
      method: 'post',
      data,
    }
  },
  deleteTrackingRequest: ({ Id }) => {
    return {
      url: `api/tracking/${Id}`,
      method: 'delete',
    }
  },
  getTrackingsRequest: ({ params }) => {
    return {
      url: `api/tracking`,
      method: 'get',
      params,
    }
  },
}

export default trackingRequests
