import { handleActions } from 'redux-actions'

import * as rawActions from '../../actions'

const defaultState = {}

export default handleActions(
  {
    [rawActions.setSelectedSearchResult](state, action) {
      return action.payload
    },
    [rawActions.clearSelectedSearchResult](state, action) {
      return defaultState
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)
