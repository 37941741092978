import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    container: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingTop: 0,
      paddingBottom: 0,
    },
    avatarContainer: {
      minWidth: 0,
      paddingRight: theme.spacing(),
    },
    avatar: {
      height: 32,
      width: 32,
    },
  }
})

export const listItemTextProps = {
  primaryTypographyProps: { variant: 'body2', noWrap: true },
  secondaryTypographyProps: { variant: 'caption', noWrap: true },
}
