import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Redo } from '@tabeeb/shared/icons'

import { annotationsActions, annotationsSelectors } from '@tabeeb/modules/annotations'

import ToolbarButton from '../ToolbarButton'

const Y_BUTTON_KEY = 89

const RedoButton = () => {
  const dispatch = useDispatch()

  const available = useSelector(annotationsSelectors.getHasRedoActions)

  useEffect(() => {
    function redoLastAction({ ctrlKey, keyCode }) {
      if (!ctrlKey || keyCode !== Y_BUTTON_KEY) {
        return
      }

      dispatch(annotationsActions.redoLastAction())
    }

    if (!available) {
      return
    }

    window.addEventListener('keyup', redoLastAction)

    return () => {
      window.removeEventListener('keyup', redoLastAction)
    }
  }, [available, dispatch])

  const onClick = useCallback(() => {
    dispatch(annotationsActions.redoLastAction())
  }, [dispatch])

  return <ToolbarButton disabled={!available} Icon={Redo} title='Redo' onClick={onClick} />
}

export default React.memo(RedoButton)
