import PropTypes from 'prop-types'
import ReactCodeInput from 'react-code-input'
import { get } from 'lodash'
import { useEffect, useRef, useState } from 'react'

const FormikCodeInput = ({
  field: { name, onBlur, onChange, value },
  form: { errors, touched, setFieldValue, handleBlur, setFieldTouched },
  placeholder,
  ...props
}) => {
  // state for manual rerender
  const [render, setRender] = useState(false)
  const error = get(touched, name) && get(errors, name)
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current !== null && placeholder) {
      document.querySelectorAll('input').forEach((node) => node.setAttribute('placeholder', placeholder))
    }
  }, [placeholder])

  // react-code-input lib doesn't support clear all inputs, so it must be done manually
  useEffect(() => {
    if (value === '') {
      inputRef.current.textInput[0].focus()
      inputRef.current.state.input = inputRef.current.state.input.map((stateElement) => (stateElement = ''))
      inputRef.current.textInput = inputRef.current.textInput.map((textInputElement) => (textInputElement = ''))

      // need to manually call the rerender of the component
      setRender((prevState) => !prevState)
    }
  }, [value])

  return (
    <>
      <ReactCodeInput
        ref={inputRef}
        name={name}
        value={value}
        onChange={(newValue) => setFieldValue(name, newValue)}
        isValid={!error}
        touch={(fieldName) => setFieldTouched(fieldName)}
        // untouch must be a func, otherwise the touch will not work
        untouch={() => {}}
        {...props}
      />
      {error && <div>{error}</div>}
    </>
  )
}

FormikCodeInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
}

export default FormikCodeInput
