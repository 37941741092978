import { put, takeLatest, select, all } from 'redux-saga/effects'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions/index'

import { MAX_EXPORT_CSV_DAYS_RANGE } from '../constants'
import { getFilterModel, validateDateRange } from './common'

function* exportTenantSessionsCsv() {
  const {
    pageNumber,
    tenantContentsList,
    dashboards: { selectedDashboardId },
  } = yield select((state) => state.tenantSessionsPage)

  const filterModel = yield getFilterModel()
  const totalContentsCount = tenantContentsList.length > 0 ? tenantContentsList[0].TotalSessionsCount : 0

  const data = {
    pageNumber,
    pageSize: totalContentsCount * 2,
    searchQuery: filterModel,
    dashboardId: selectedDashboardId || null,
  }

  if (
    yield validateDateRange(
      filterModel.Filtering.CreatedOn.StartDate,
      filterModel.Filtering.CreatedOn.EndDate,
      MAX_EXPORT_CSV_DAYS_RANGE
    )
  ) {
    yield put(rawActions.exportTenantSessionsCsvRequest(data))
    return
  }

  yield put(
    notificationActions.onAddWarningNotification({
      message: `Selected period for export cannot exceed ${MAX_EXPORT_CSV_DAYS_RANGE} days`,
    })
  )
}

function* onTenantSessionsCsvExported() {
  yield put(
    notificationActions.onAddInfoNotification({
      message: 'An email will be sent to you shortly with the link for downloading CSV',
    })
  )
}

function* exportCsv() {
  yield all([
    takeLatest(rawActions.exportTenantSessionsCsv, exportTenantSessionsCsv),
    takeLatest(rawActions.exportTenantSessionsCsvSuccess, onTenantSessionsCsvExported),
  ])
}

export default exportCsv
