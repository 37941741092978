import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    avatarBlock: {
      width: 120,
      height: 120,
      fontSize: 38,
    },
    avatarGrid: {
      alignSelf: 'center',
      marginBottom: theme.spacing(1.25),
    },
    nameGrid: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }
})
