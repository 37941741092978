import PropTypes from 'prop-types'

import { TextField, withStyles, Link } from '@material-ui/core'
import { FormControlType } from '@tabeeb/enums'

import styles from './styles'

const TextBox = ({ disabled, control, answer, handleSubmit, handleChange, isEditingAnswer, classes }) => {
  const value = answer.value || ''

  const isPhoneNumber = control.Type === FormControlType.PhoneNumber
  const isEmail = control.Type === FormControlType.Email
  const isOtherUserContactInfoAnswer = disabled && (isPhoneNumber || isEmail) && value
  const hrefPrefix = isPhoneNumber ? 'tel' : 'mailto'
  let validatedValue = value

  if (isOtherUserContactInfoAnswer && isPhoneNumber) validatedValue = value.replace(/\D+/g, '')

  const _handleBlur = () => {
    if (isEditingAnswer) return

    handleSubmit()
  }

  return isOtherUserContactInfoAnswer && validatedValue.length > 0 ? (
    <Link className={classes.link} href={`${hrefPrefix}:${validatedValue}`} title={control.Tooltip}>
      {value}
    </Link>
  ) : (
    <TextField
      fullWidth
      title={control.Tooltip}
      disabled={disabled}
      id={`${control.Id}`}
      value={value}
      onChange={handleChange}
      onBlur={_handleBlur}
      variant='outlined'
      inputProps={{ style: { padding: '5px 7px' } }}
    />
  )
}

TextBox.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isEditingAnswer: PropTypes.bool,
  control: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(TextBox)
