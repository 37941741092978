import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.addOpenedTenantForm](state, action) {
      return [...state, action.payload]
    },
    [rawActions.removeOpenedTenantSubForms](state, action) {
      const formsToRemove = action.payload
      return state.filter((form) => !formsToRemove.includes(form))
    },
    [rawActions.resetOpenedTenantForms](state, action) {
      return defaultState
    },
  },
  defaultState
)
