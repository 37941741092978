import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import moment from 'moment'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  Radio,
  Typography,
} from '@mui/material'

import { getFormsTitle } from '@tabeeb/modules/appConfigState/selectors'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'

import { useSessionReviews } from '@tabeeb/modules/sessionsPage/hooks'
import { initiateJoinContentReview } from '@tabeeb/modules/contentReviews/actions'

const JoinSessionReviewDialog = ({ open, session, onClose }) => {
  const dispatch = useDispatch()

  const [selectedReview, setSelectedReview] = useState(null)
  const formsTitle = useSelector(getFormsTitle)

  const { loading, reviews } = useSessionReviews({ id: session.Id })

  useEffect(() => {
    if (reviews.length === 0) {
      return
    }

    setSelectedReview(reviews[0])
  }, [reviews])

  const onSubmit = useCallback(() => {
    dispatch(initiateJoinContentReview(selectedReview))

    onClose()
  }, [dispatch, onClose, selectedReview])

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle>{`Select review you'd like to join`}</DialogTitle>
      <DialogContent>
        {loading && <CircularProgressBar />}

        <List disablePadding>
          {reviews.map((review) => (
            <ListItemButton
              key={review.Id}
              selected={review === selectedReview}
              onClick={() => setSelectedReview(review)}
            >
              <ListItemIcon>
                <Radio edge='start' size='small' checked={review === selectedReview} />
              </ListItemIcon>
              <Box display='flex' flexDirection='column' minWidth={0}>
                <Typography variant='body1' title={review.ReviewerName} noWrap>
                  <b>Reviewer: </b> {review.ReviewerName}
                </Typography>

                <Typography variant='body1' title={review.ReviewerName} noWrap>
                  <b>{formsTitle}: </b> {review.Forms.join(', ') || 'All'}
                </Typography>

                <Typography variant='caption' noWrap>
                  {moment(review.CreatedOn).format('D MMMM YYYY h:mm A')}
                </Typography>
              </Box>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={onSubmit} color='primary' variant='contained' disabled={!selectedReview}>
          Join
        </Button>
      </DialogActions>
    </Dialog>
  )
}

JoinSessionReviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(JoinSessionReviewDialog)
