import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'
import { VQAPolicyRule } from '../../../Enums'

const defaultState = null
const newVqaPolicy = {
  Name: '',
  Description: '',
  Rule: VQAPolicyRule.AND,
  Tags: [],
  AIObjects: [],
}

export default handleActions(
  {
    [rawActions.openCreateVqaPolicyDialog](state, action) {
      return { ...newVqaPolicy }
    },
    [rawActions.openCreateVqaPolicyWithPredefinedTagsDialog](state, action) {
      return { ...newVqaPolicy, Tags: action.payload }
    },
    [rawActions.openEditVqaPolicyDialog](state, action) {
      return { ...action.payload }
    },
    [combineActions(
      rawActions.closeCreateVqaPolicyDialog,
      rawActions.addVqaPolicyRequest,
      rawActions.updateVqaPolicyRequest
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
