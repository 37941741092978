import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'
import { defaultPagesStatisticsModel } from '../services'

const defaultState = [defaultPagesStatisticsModel]

export default handleActions(
  {
    [rawActions.getPagesStatisticsByAiObjectsForProjectTraining.success](state, { response }) {
      return response.data
    },
    [rawActions.getPagesStatisticsByFormsForProjectTraining.success](state, { response }) {
      return response.data
    },
    [combineActions(
      rawActions.closePagesAnalysisDialog,
      rawActions.setSelectedProject,
      rawActions.resetPagesForProjectTraining
    )](state, { payload }) {
      return defaultState
    },
  },
  defaultState
)
