import { CHAT_HEIGHT } from '@tabeeb/modules/discussion/constants'

export default (theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flexGrow: 1,
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[4],
      padding: 0,
    },
    action: {
      display: 'flex',
      alignItems: 'flex-start',
      color: theme.palette.text.secondary,

      '& button': {
        color: 'inherit',
      },
    },
    content: {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
    },
    chat: {
      height: CHAT_HEIGHT,
    },
  }
}
