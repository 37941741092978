import { memo } from 'react'
import { useSelector } from 'react-redux'

import { isSupportZooming } from '@tabeeb/services/pageService'
import { gallerySelectors } from '@tabeeb/modules/gallery'

import ZoomInButton from './ZoomInButton'
import ZoomOutButton from './ZoomOutButton'
import ZoomValueSelect from './ZoomValueSelect'

import useStyles from './styles'

const ZoomButtons = () => {
  const classes = useStyles()

  const display = useSelector((state) => isSupportZooming(gallerySelectors.getSelectedGalleryItemType(state)))
  if (!display) {
    return null
  }

  return (
    <div className={classes.root}>
      <ZoomInButton />
      <ZoomValueSelect />
      <ZoomOutButton />
    </div>
  )
}

export default memo(ZoomButtons)
