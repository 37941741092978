import { put, takeEvery, select, take, all } from 'redux-saga/effects'

import * as galleryActions from '@tabeeb/modules/gallery/actions'

import { AnnotationType } from '@tabeeb/enums'
import {
  getAiCounterInfoRequest,
  getAiObjectsCountRequest,
  getUniqueAiObjects,
} from '@tabeeb/modules/artificialIntelligence/actions'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import * as annotationsActions from '../actions'
import * as annotationsSelectors from '../selectors'

function* deleteContentAnnotations(action) {
  const { annotationIds, ...rest } = action.payload

  const annotations = yield select((state) =>
    annotationIds
      .map((annotationId) => annotationsSelectors.getAnnotationById(state, { Id: annotationId }))
      .filter((annotation) => !!annotation)
  )

  yield put(galleryActions.deleteAnnotations(annotations))
  yield put(annotationsActions.deleteAnnotationsRequest({ annotationIds, annotations, ...rest }))

  const result = yield take([annotationsActions.deleteAnnotationsFailed, annotationsActions.deleteAnnotationsSuccess])
  if (annotations && result.type === annotationsActions.deleteAnnotationsFailed().type) {
    yield put(galleryActions.addAnnotations(annotations))
  }
}

function* deleteAnnotationsSuccess(action) {
  const contentId = yield select(getContentId)
  const { contentForms } = yield select((state) => state.forms)
  const { annotations } = action.payload
  const selectedObject = yield select((state) => state.artificialIntelligence.selectedAIObject)

  const hasCounters = contentForms?.some((cf) => cf.HasCounters || (cf.Counters && cf.Counters.length > 0))

  if (annotations.some((annotation) => annotation?.Type === AnnotationType.AI)) {
    if (hasCounters) {
      yield put(getAiCounterInfoRequest({ contentId }))
      yield put(getAiObjectsCountRequest({ contentId }))
    }
  }

  const uniqueAiObjectAnnotation = annotations.find(
    (annotation) => annotation?.UniqueAIObjectId && selectedObject?.Id === annotation?.AIObjectId
  )

  if (uniqueAiObjectAnnotation) {
    yield put(getUniqueAiObjects.request({ aiObjectId: uniqueAiObjectAnnotation.AIObjectId, contentId }))
  }
}

function* deleteAnnotationSaga() {
  yield all([
    takeEvery(annotationsActions.deleteContentAnnotations, deleteContentAnnotations),
    takeEvery(annotationsActions.deleteAnnotationsSuccess, deleteAnnotationsSuccess),
  ])
}

export default deleteAnnotationSaga
