import { createAction, createActions } from 'redux-actions'

const createSelectedFiltersActions = (name) =>
  createActions(`ADD_TO_SELECTED_${name}`, `DELETE_FROM_SELECTED_${name}`, `SET_SELECTED_${name}`)

export const { addToSelectedContents, deleteFromSelectedContents, setSelectedContents } =
  createSelectedFiltersActions('CONTENTS')
export const { addToSelectedAiObjects, deleteFromSelectedAiObjects, setSelectedAiObjects } =
  createSelectedFiltersActions('AI_OBJECTS')
export const { addToSelectedForms, deleteFromSelectedForms, setSelectedForms } = createSelectedFiltersActions('FORMS')
export const { addToSelectedAnnotationOwners, deleteFromSelectedAnnotationOwners, setSelectedAnnotationOwners } =
  createSelectedFiltersActions('ANNOTATION_OWNERS')
export const { addToSelectedAnswerers, deleteFromSelectedAnswerers, setSelectedAnswerers } =
  createSelectedFiltersActions('ANSWERERS')
export const { addToSelectedReviewers, deleteFromSelectedReviewers, setSelectedReviewers } =
  createSelectedFiltersActions('REVIEWERS')
export const { addToSelectedApprovers, deleteFromSelectedApprovers, setSelectedApprovers } =
  createSelectedFiltersActions('APPROVERS')
export const { addToSelectedFormControls, deleteFromSelectedFormControls, setSelectedFormControls } =
  createSelectedFiltersActions('FORM_CONTROLS')

export const addPageToSelectedPagesForExclusion = createAction('ADD_PAGE_TO_SELECTED_PAGES_FOR_EXCLUSION')
export const addPagesToSelectedPagesForExclusion = createAction('ADD_PAGES_TO_SELECTED_PAGES_FOR_EXCLUSION')
export const deletePageFromSelectedPagesForExclusion = createAction('DELETE_PAGE_FROM_SELECTED_PAGES_FOR_EXCLUSION')
export const deletePagesFromSelectedPagesForExclusion = createAction('DELETE_PAGES_FROM_SELECTED_PAGES_FOR_EXCLUSION')
export const resetSelectedPagesForExclusion = createAction('RESET_SELECTED_PAGES_FOR_EXCLUSION')

export const setAnnotationCreatedStartDate = createAction('SET_ANNOTATION_CREATED_START_DATE')
export const setAnnotationCreatedEndDate = createAction('SET_ANNOTATION_CREATED_END_DATE')

export const setSelectedFormAnswerValue = createAction('SET_SELECTED_FORM_ANSWER_VALUE')
export const setSelectedFormAnswerStatuses = createAction('SET_SELECTED_FORM_ANSWER_STATUSES')
