export function pageConverter(page) {
  const convertedPage = {
    id: page.id || page.PageId,
    thumbnailUrl: page.thumbnailUrl || page.ThumbnailUrl || '/Content/img/image_placeholder.png',
    contentFormId: page.ContentFormId || null,
    formCreatedOn: page.CreatedOn || null,
    order: page.Order || page.order,
    groupById: page.ContentFormId || page.id || page.PageId,
  }

  return convertedPage
}
