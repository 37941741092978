import { all, takeLatest, put, select } from 'redux-saga/effects'
import * as notificationActions from '@tabeeb/modules/notification/actions'
import * as rawActions from '../actions'
import { getPowerBIReportsState } from '../selectors'

function* loadPowerBIReports() {
  const { filters, isLoading } = yield select(getPowerBIReportsState)
  if (isLoading) {
    return
  }
  const requestPayload = {
    skip: (filters.pageNumber - 1) * filters.pageSize,
    take: filters.pageSize,
    search: filters.search,
  }
  yield put(rawActions.getPowerBIReports.request(requestPayload))
}

function* getDashboardsFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to retrieve dashboards' }))
}

function* dashboardsSaga() {
  yield all([
    takeLatest(rawActions.getPowerBIReports.failed, getDashboardsFailed),
    takeLatest([rawActions.loadPowerBIReports, rawActions.setPowerBIReportsFilters], loadPowerBIReports),
  ])
}

export default dashboardsSaga
