import { combineReducers } from 'redux'

import isTimelineOpen from './isTimelineOpen'
import isProcessing from './isProcessing'
import pagesList from './pagesList'

export default combineReducers({
  isTimelineOpen,
  isProcessing,
  pagesList,
})
