import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import PolicyViewer from '../components/PolicyViewer'

import { getPolicyByType } from '../selectors'

class PolicyViewerContainer extends Component {
  render() {
    const { policy } = this.props

    return <PolicyViewer policy={policy} />
  }
}

PolicyViewerContainer.propTypes = {
  policy: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Text: PropTypes.string.isRequired,
    TenantId: PropTypes.number,
  }),
}

const mapStateToProps = (state, { policyType }) => {
  return {
    policy: getPolicyByType(state, policyType),
  }
}

export default connect(mapStateToProps)(PolicyViewerContainer)
