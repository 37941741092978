import { combineActions, handleActions } from 'redux-actions'

import { resetContentState } from '@tabeeb/modules/shared/content/actions'
import * as rawActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [rawActions.selectContentTrashBinPage](state, action) {
      return [...state, action.payload]
    },
    [rawActions.deselectContentTrashBinPage](state, action) {
      return state.filter((page) => page.Id !== action.payload.Id)
    },
    [combineActions(rawActions.clearContentTrashBinSelectedPages, resetContentState)](state, action) {
      return defaultState
    },
  },
  defaultState
)
