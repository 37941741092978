import PropTypes from 'prop-types'
import { Grid, TextField, Typography } from '@mui/material'

const PowerBIReportConfiguration = ({ report }) => {
  return (
    <Grid container item xs={12} flexDirection='column'>
      <Grid item xs={12}>
        <Typography variant='body1' fontWeight={500} mb={1}>
          Configuration (read-only)
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={1} mt={1} component='form' autoComplete='off' flexDirection='column'>
        <Grid container spacing={1} item xs={12} alignItems='center'>
          <Grid item xs={2}>
            <Typography fontWeight={500}>Workspace ID</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField fullWidth size='small' value={report.WorkspaceId} />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} alignItems='center'>
          <Grid item xs={2}>
            <Typography fontWeight={500}>Dashboard ID</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField fullWidth size='small' value={report.DashboardId} />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} alignItems='center'>
          <Grid item xs={2}>
            <Typography fontWeight={500}>Provider</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField fullWidth size='small' value={report.Provider.Name} />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} alignItems='center'>
          <Grid item xs={2}>
            <Typography fontWeight={500}>Security Model</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField fullWidth size='small' value={report.SecurityModel.Name} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

PowerBIReportConfiguration.propTypes = {
  report: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Provider: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }),
    WorkspaceId: PropTypes.string.isRequired,
    DashboardId: PropTypes.string.isRequired,
    SecurityModel: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  }),
}

export default PowerBIReportConfiguration
