import { memo } from 'react'
import PropTypes from 'prop-types'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { ContentSharingModelType } from '@tabeeb/enums'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { clearFoundUsersList, updateUsersSearchValue, removeOwner } from '@tabeeb/modules/sessionsPage/actions'
import { getContentReviewTypes } from '@tabeeb/modules/contentSharings/selectors'

import useStyles from './styles'

const ContentSharingTypeSelector = ({ sharingType, setSharingType, selectedOwnerForSharing }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const currentUserId = useSelector(getCurrentUserId)
  const isMergeReview = sharingType === ContentSharingModelType.MergeForReview
  const isCurrentUserSelected = currentUserId === selectedOwnerForSharing?.Id
  const availableReviewTypes = useSelector(getContentReviewTypes)

  const _handleChange = (newSharingType) => {
    if (isMergeReview && newSharingType === ContentSharingModelType.ForReview && isCurrentUserSelected) {
      dispatch(clearFoundUsersList())
      dispatch(updateUsersSearchValue(''))
      dispatch(removeOwner())
    }

    setSharingType(newSharingType)
  }

  const isSharing =
    sharingType === ContentSharingModelType.SharedByOwner || sharingType === ContentSharingModelType.ContentsMerging

  const isReview = sharingType === ContentSharingModelType.ForReview || isMergeReview

  if (!isSharing && !isReview) {
    return null
  }

  if (isReview && availableReviewTypes.length < 2) {
    return null
  }

  return (
    <div className={classes.container}>
      <RadioGroup value={sharingType} onChange={(e) => _handleChange(parseInt(e.target.value, 10))}>
        {isSharing && (
          <>
            <FormControlLabel
              value={ContentSharingModelType.SharedByOwner}
              control={<Radio color='default' size='small' />}
              label='Create new session in destination tenant'
            />
            <FormControlLabel
              value={ContentSharingModelType.ContentsMerging}
              control={<Radio color='default' size='small' />}
              label='Share to existing session in destination tenant'
            />
          </>
        )}

        {isReview &&
          availableReviewTypes.map((reviewOption) => (
            <FormControlLabel
              value={reviewOption.type}
              control={<Radio color='default' size='small' />}
              label={reviewOption.description}
            />
          ))}
      </RadioGroup>
    </div>
  )
}

ContentSharingTypeSelector.propTypes = {
  sharingType: PropTypes.number.isRequired,
  setSharingType: PropTypes.func.isRequired,
  selectedOwnerForSharing: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }),
}

export default memo(ContentSharingTypeSelector)
