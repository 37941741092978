import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Box, withStyles } from '@material-ui/core'
import { Cancel, CheckCircle, Info } from '@material-ui/icons'
import { ApprovalRequestType, ContentState, NotificationType } from '@tabeeb/enums'
import { getIconByNotificationType } from '../../services/notificationsPageFilters'
import styles from './styles'

const NotificationIcon = ({ classes, notification }) => {
  const icon = getIconByNotificationType(notification.Type, { className: classes.icon })

  const showInfoIcon = Boolean(
    (notification.Type === ApprovalRequestType.SessionApproval && notification.NeedConfirmation) ||
      notification.NotificationType === NotificationType.AddUserToContent ||
      (notification.Type === ApprovalRequestType.CalendarEvent && notification.NeedConfirmation) ||
      notification.CanBeRevoked
  )

  const showSuccessIcon = Boolean(
    (notification.Approver &&
      notification.ContentStatus === ContentState.Created &&
      !notification.ContentSharingId &&
      !notification.CalendarEventId &&
      !notification.ContentsLinkId) ||
      (notification.ContentSharingId && notification.Accepted) ||
      (notification.Type === ApprovalRequestType.CalendarEvent && notification.Accepted)
  )
  const showDeclineIcon = Boolean(
    (notification.Approver && notification.ContentStatus === ContentState.Rejected) ||
      (notification.ContentSharingId && notification.Ignored) ||
      (notification.Type === ApprovalRequestType.CalendarEvent && notification.Ignored)
  )

  return (
    <Box className={classes.container}>
      {icon}
      {showInfoIcon && <Info color='primary' className={classes.subicon} fontSize='small' />}
      {showSuccessIcon && <CheckCircle className={classnames(classes.subicon, classes.success)} fontSize='small' />}
      {showDeclineIcon && <Cancel color='error' className={classes.subicon} fontSize='small' />}
    </Box>
  )
}

NotificationIcon.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    subicon: PropTypes.string.isRequired,
    success: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
  notification: PropTypes.shape({
    Approver: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      Email: PropTypes.string.isRequired,
    }),
    ContentStatus: PropTypes.number.isRequired,
    ContentSharingId: PropTypes.number,
    CalendarEventId: PropTypes.number,
    Type: PropTypes.number.isRequired,
    Ignored: PropTypes.bool.isRequired,
    Accepted: PropTypes.bool.isRequired,
    CanBeRevoked: PropTypes.bool.isRequired,
    NeedConfirmation: PropTypes.bool.isRequired,
    NotificationType: PropTypes.number.isRequired,
    ContentsLinkId: PropTypes.number,
  }).isRequired,
}

export default withStyles(styles)(NotificationIcon)
