import PropTypes from 'prop-types'
import {
  withStyles,
  Typography,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import { Cancel } from '@material-ui/icons'

import { PageFolderPermission, PageFolderPermissionLabel } from '@tabeeb/enums'

import styles from './styles'

const UserPermissions = ({
  classes,
  displayName,
  userId,
  handlePermissionCheckClick,
  handleRemoveClick,
  permissions,
}) => {
  const handleChange = (event) => {
    handlePermissionCheckClick(event.target.value, userId, displayName)
  }

  const menuProps = {
    variant: 'menu',
    getContentAnchorEl: null,
  }

  return (
    <div className={classes.userListItem}>
      <Typography className={classes.displayName}>{displayName}</Typography>
      <FormControl variant='outlined' size='small' className={classes.permissionsSelect}>
        <Select
          multiple
          value={permissions}
          onChange={handleChange}
          renderValue={(selected) => selected.map((permission) => PageFolderPermissionLabel[permission]).join(', ')}
          MenuProps={menuProps}
        >
          {Object.values(PageFolderPermission).map((permission) => (
            <MenuItem key={permission} value={permission}>
              <Checkbox color='primary' checked={permissions.includes(permission)} />
              <ListItemText primary={PageFolderPermissionLabel[permission]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant='outlined' size='small'>
        <IconButton onClick={() => handleRemoveClick(userId)}>
          <Cancel />
        </IconButton>
      </FormControl>
    </div>
  )
}

UserPermissions.propTypes = {
  displayName: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  handlePermissionCheckClick: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.number),
  classes: PropTypes.shape({
    userListItem: PropTypes.string.isRequired,
    permissionsSelect: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  handleRemoveClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(UserPermissions)
