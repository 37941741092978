import { memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Paper, Tooltip, withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getIsFormActionDisabled } from '@tabeeb/modules/forms/selectors'
import { triggerFormAction } from '@tabeeb/modules/forms/actions'
import styles from './styles'

const FormAction = ({ action, classes }) => {
  const dispatch = useDispatch()
  const contentId = useSelector(getContentId)
  const isDisabled = useSelector((state) => getIsFormActionDisabled(state, { actionId: action.Id }))

  return (
    <Paper className={classes.formAction} variant='outlined'>
      <Tooltip placement='top' arrow title={action.Tooltip || ''}>
        <Button
          onClick={() => dispatch(triggerFormAction.request({ formActionId: action.Id, contentId }))}
          variant='contained'
          color='primary'
          className={classes.button}
          disabled={isDisabled}
        >
          {action.Label}
        </Button>
      </Tooltip>
    </Paper>
  )
}

FormAction.propTypes = {
  action: PropTypes.shape({
    Id: PropTypes.string.isRequired,
    Tooltip: PropTypes.string,
    Label: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    formAction: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(FormAction))
