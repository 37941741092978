import { handleActions } from 'redux-actions'
import { setUniqueAiObjectForEdit, resetState, getUniqueAiObjectForEditSuccess } from '../actions'

const defaultState = null

export default handleActions(
  {
    [setUniqueAiObjectForEdit](state, { payload }) {
      return payload
    },
    [getUniqueAiObjectForEditSuccess](state, { response }) {
      return { ...response.data }
    },
    [resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
