import { combineActions, handleActions } from 'redux-actions'

import { FetchStatus } from '@tabeeb/enums'
import * as rawActions from '../../actions'

const emptyReport = {
  Id: 0,
  SecurityModel: {
    Id: 0,
    Name: '',
  },
}

const emptyReportTenant = {
  Id: 0,
  TenantId: 0,
  TenantName: '',
}

const initialState = {
  open: false,
  isEditMode: false,
  fetchStatus: FetchStatus.Idle,
  report: emptyReport,
  reportTenant: emptyReportTenant,
}

export default handleActions(
  {
    [rawActions.openPowerBIReportTenantModal]: (state, action) => {
      const { report, reportTenant } = action.payload
      return {
        ...state,
        open: true,
        report,
        reportTenant: reportTenant || emptyReportTenant,
        isEditMode: !!reportTenant,
      }
    },
    [rawActions.closePowerBIReportTenantModal]: () => {
      return initialState
    },
    [combineActions(rawActions.createPowerBIReportTenant.request, rawActions.updatePowerBIReportTenant.request)]: (
      state,
      _action
    ) => {
      return { ...state, fetchStatus: FetchStatus.Loading }
    },
    [combineActions(rawActions.createPowerBIReportTenant.success, rawActions.updatePowerBIReportTenant.success)]: (
      state,
      _action
    ) => {
      return { ...state, fetchStatus: FetchStatus.Loaded }
    },
    [combineActions(rawActions.createPowerBIReportTenant.failed, rawActions.updatePowerBIReportTenant.failed)]: (
      state,
      _action
    ) => {
      return { ...state, fetchStatus: FetchStatus.Failed }
    },
  },
  initialState
)
