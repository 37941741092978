import { put, takeLatest, all } from 'redux-saga/effects'

import * as formActions from '@tabeeb/modules/forms/actions'
import * as galleryActions from '@tabeeb/modules/gallery/actions'
import { contentNotificationService } from '@tabeeb/modules/shared/content'

function* sendFormAnswerSuccess({ payload }) {
  yield put(galleryActions.updatePageNameRequest({ PageId: payload.PageId, ContentId: payload.ContentId }))
}

function* renameEmit(action) {
  const { pageId } = action.payload

  yield contentNotificationService.notifyOfUpdatePage({ pageId })
}

function* renameGalleryItems() {
  yield all([
    takeLatest([formActions.sendFormAnswerSuccess], sendFormAnswerSuccess),
    takeLatest(galleryActions.renameGalleryItemSuccess, renameEmit),
  ])
}

export default renameGalleryItems
