import { AssetType, ContentType } from '@tabeeb/enums'
import fixWebmDuration from 'fix-webm-duration'

const recordedChunks = []
let mediaRecorder = null
let recordedBlob = null
let isLocalRecordingInitializedForCurrentUser = false
let isLocalRecordingInitializedInCall = false

const handleDataAvailable = (e) => {
  if (e.data.size > 0) {
    recordedChunks.push(e.data)
  }
}

const handleStop = () => {
  recordedBlob = new Blob(recordedChunks, {
    type: 'video/webm',
  })
  mediaRecorder = null
  isLocalRecordingInitializedForCurrentUser = false
  isLocalRecordingInitializedInCall = false
}

const initializeMediaRecorder = (videoTrack) => {
  try {
    const outputStream = new MediaStream()
    outputStream.addTrack(videoTrack)

    const audioElements = document.querySelectorAll('audio.tabeebUserAudio')

    const audioContext = new AudioContext()
    const audioDestination = audioContext.createMediaStreamDestination()
    Array.from(audioElements).forEach((audioElement) => {
      audioElement.captureStream = audioElement.captureStream || audioElement.mozCaptureStream
      const audioStream = audioElement.captureStream()
      const tracks = audioStream.getTracks()
      if (tracks && tracks[0]) {
        const source = audioContext.createMediaStreamSource(audioStream)
        source.connect(audioDestination)
      }
    })
    const audio = audioDestination.stream.getTracks()[0]
    outputStream.addTrack(audio)

    mediaRecorder = new MediaRecorder(outputStream, { mimeType: 'video/webm' })
    mediaRecorder.ondataavailable = (e) => handleDataAvailable(e)
    mediaRecorder.onstop = handleStop

    isLocalRecordingInitializedForCurrentUser = true

    return mediaRecorder
  } catch (e) {
    return null
  }
}

export const getMediaRecorder = (videoTrack) => {
  if (mediaRecorder) {
    return mediaRecorder
  }
  return initializeMediaRecorder(videoTrack)
}

export const isLocalRecordingActive = () =>
  isLocalRecordingInitializedForCurrentUser || isLocalRecordingInitializedInCall

export const isCurrentUserRecorder = () => isLocalRecordingInitializedForCurrentUser

export const isFileProcessing = () => recordedChunks.length !== 0

export const setLocalRecordingInitializedInCall = () => {
  isLocalRecordingInitializedInCall = true
}

export const resetLocalRecordingStatus = () => {
  isLocalRecordingInitializedForCurrentUser = false
  isLocalRecordingInitializedInCall = false
}

export const getRecordedFile = (recordingDuration) => {
  if (recordedBlob) {
    return fixWebmDuration(recordedBlob, recordingDuration, { logger: false }).then((fixedBlob) => {
      const file = new File([fixedBlob], 'Recorded Video.mp4', {
        type: fixedBlob.type,
      })
      file.contentType = ContentType.UnencodedVideo
      file.assetType = AssetType.UnencodedVideo

      recordedBlob = null
      recordedChunks.length = 0

      return file
    })
  }
  return null
}
