import { useDispatch, useSelector } from 'react-redux'
import { Button, styled } from '@mui/material'

import { getAIReportLabel, getIsAIReportEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { TenantPermission } from '@tabeeb/enums'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

import { closeUniqueAiObjectDialog, openAiReportDialog } from '../../actions'
import { getIsUniqueAIObjectAnalysisDialogOpen } from '../../selectors'

const StyledButton = styled(Button)(({ theme }) => ({
  margin: `12px 10px 0px`,
  maxWidth: `187px`,
  backgroundColor: '#0089cf',
  minWidth: 'unset',
  overflow: 'hidden',

  '&:hover': {
    backgroundColor: 'rgb(0, 95, 144)',
  },

  '& div': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'uppercase',
  },
}))

const AIReportButton = () => {
  const dispatch = useDispatch()

  const disabled = !useSelector(getContentId)
  const isAIReportEnabled = useSelector(getIsAIReportEnabled)
  const buttonLabel = useSelector(getAIReportLabel)
  const isOpen = useSelector(getIsUniqueAIObjectAnalysisDialogOpen)
  const hasPermission = useSelector((state) => hasTenantPermission(state, TenantPermission.AIReport))

  const handleClick = () => {
    if (isOpen) {
      dispatch(closeUniqueAiObjectDialog())
    } else {
      dispatch(openAiReportDialog())
    }
  }

  return isAIReportEnabled && hasPermission ? (
    <StyledButton onClick={handleClick} variant='contained' color='primary' disabled={disabled}>
      <div>{buttonLabel}</div>
    </StyledButton>
  ) : null
}

export default AIReportButton
