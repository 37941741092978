import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    icon: {
      minWidth: 0,
      padding: theme.spacing(0.75),

      '&.MuiButtonGroup-grouped': {
        marginLeft: 0,
        marginRight: 0,
      },

      '&.Mui-disabled': {
        pointerEvents: 'auto',
        cursor: 'not-allowed',
      },
    },
    active: {
      backgroundColor: theme.palette.action.selected,

      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      },
    },
    disabled: {
      cursor: 'not-allowed',
    },
  }
})
