import { useMemo } from 'react'

import useProgressiveLoadingResource from './useProgressiveLoadingResource'

import { getSncAnswers } from '../actions'

export default ({ enabled, questionId, searchText } = { searchText: '' }) => {
  const payload = useMemo(
    () => ({
      isDeleted: false,
      questionId,
      cacheId: 1,

      searchQuery: { Filtering: { AnswersFilter: searchText } },
    }),
    [questionId, searchText]
  )

  const { loading, loaded, items, hasMore, onLoadMore } = useProgressiveLoadingResource({
    enabled,
    request: getSncAnswers.request,
    payload,
  })

  return {
    loading,
    loaded,
    answers: items,
    hasMore,
    onLoadMore,
  }
}
