import PropTypes from 'prop-types'
import _ from 'lodash'

import { Box, CircularProgress } from '@material-ui/core'

import FoundUsersItem from '../FoundUsersItem'

import { DialogTypes } from '../../../../enums'

import './styles.less'

const FoundUsers = ({
  isSwitchingInProgress,
  foundUsersList,
  switchAction,
  selectOwner,
  selectUser,
  folderUsersList,
  limitedNumberOfDisplays,
  openedAllSessionsPageDialogType,
  openedDialogType,
}) => {
  const isSwitchReviewerDialog = openedDialogType === DialogTypes.SwitchReviewers
  const isAddUsersToFolder = openedDialogType === DialogTypes.ManageFolderUsers

  const buttonText = isSwitchReviewerDialog ? 'Make a Reviewer' : 'Make an Owner'

  if (isAddUsersToFolder && folderUsersList && folderUsersList.length > 0)
    foundUsersList = _.differenceBy(foundUsersList, folderUsersList, 'Id')

  if (limitedNumberOfDisplays && foundUsersList.length > limitedNumberOfDisplays)
    foundUsersList.length = limitedNumberOfDisplays

  return isSwitchingInProgress ? (
    <Box>
      <CircularProgress size={25} thickness={6} />
    </Box>
  ) : foundUsersList.length > 0 ? (
    <div className='found-users-list'>
      {foundUsersList.map((user, index) => (
        <FoundUsersItem
          switchAction={switchAction}
          user={user}
          key={index}
          selectOwner={selectOwner}
          selectUser={selectUser}
          buttonText={buttonText}
        />
      ))}
    </div>
  ) : null
}

FoundUsers.propTypes = {
  isSwitchingInProgress: PropTypes.bool,
  switchAction: PropTypes.func,
  foundUsersList: PropTypes.array.isRequired,
  selectOwner: PropTypes.func,
  selectUser: PropTypes.func,
  folderUsersList: PropTypes.array,
  limitedNumberOfDisplays: PropTypes.number,
  openedDialogType: PropTypes.string,
}

export default FoundUsers
