import React from 'react'
import PropTypes from 'prop-types'
import { Rect } from 'react-konva'

import { alpha, useTheme } from '@material-ui/core'

const SelectionArea = ({ x, y, width, height }) => {
  const theme = useTheme()

  return (
    <Rect
      x={x}
      y={y}
      width={width}
      height={height}
      dash={[10, 10]}
      fillEnabled
      listening={false}
      fill={alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)}
      strokeWidth={2}
      stroke={theme.palette.primary.main}
      strokeScaleEnabled={false}
    />
  )
}

SelectionArea.defaultProps = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

SelectionArea.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default React.memo(SelectionArea)
