import { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { Box, Chip } from '@mui/material'
import { AccessTimeOutlined } from '@mui/icons-material'

const ExpirationStatusChip = ({ invite }) => {
  if (!invite.EndDate) {
    return null
  }

  const now = moment()
  const endDate = moment(invite.EndDate)

  const expired = now > endDate
  const title = expired ? 'Expired' : `Expires ${endDate.toLocaleString()}`
  const label = expired ? 'Expired' : `Expires in ${moment.duration(moment(invite.EndDate).diff(moment())).humanize()}`

  return (
    <Box mr={0.5}>
      <Chip
        color={expired ? 'error' : 'primary'}
        title={title}
        label={label}
        size='small'
        variant='outlined'
        icon={<AccessTimeOutlined />}
      />
    </Box>
  )
}

ExpirationStatusChip.propTypes = {
  invite: PropTypes.shape({
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
  }).isRequired,
}

export default memo(ExpirationStatusChip)
