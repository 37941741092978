import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AvailableFormsItem from '../components/AvailableFormsItem/index'

import { prepareAttachingForm, prepareDetachingForm } from '../actions'
import { getIsDetachButtonDisabled } from '../selectors'

class AvailableFormsItemContainer extends Component {
  _attachFormToContent = (form) => {
    this.props.attachFormToContent(form)
  }

  _detachFormFromContent = (form) => {
    this.props.detachFormFromContent(form.FormId)
  }

  render() {
    const { isDetachButtonDisabled, form } = this.props
    const props = {
      form,
      attachFormToContent: this._attachFormToContent,
      detachFormFromContent: this._detachFormFromContent,
      isDetachButtonDisabled,
    }
    return <AvailableFormsItem {...props} />
  }
}

AvailableFormsItemContainer.propTypes = {
  form: PropTypes.object.isRequired,
  attachFormToContent: PropTypes.func.isRequired,
  detachFormFromContent: PropTypes.func.isRequired,
  isDetachButtonDisabled: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, { form }) => ({
  isDetachButtonDisabled: getIsDetachButtonDisabled(state, { form }),
})

const mapDispatchToProps = (dispatch) => ({
  attachFormToContent: (form) => dispatch(prepareAttachingForm(form)),
  detachFormFromContent: (formId) => dispatch(prepareDetachingForm({ formId })),
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailableFormsItemContainer)
