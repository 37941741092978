import {
  Numbers,
  ExpandCircleDownOutlined,
  EventOutlined,
  Link,
  ToggleOff,
  DataObjectOutlined,
  PlaceRounded,
  CheckBoxOutlined,
  FormatAlignLeft,
} from '@mui/icons-material'
import { AIObjectPropertyType, GraphElementType } from '@tabeeb/enums'

export const AIObjectPropertyTypeIcon = {
  [AIObjectPropertyType.Json]: <DataObjectOutlined fontSize='small' />,
  [AIObjectPropertyType.String]: <FormatAlignLeft fontSize='small' />,
  [AIObjectPropertyType.IntNumber]: <Numbers fontSize='small' />,
  [AIObjectPropertyType.DoubleNumber]: <Numbers fontSize='small' />,
  [AIObjectPropertyType.DateTime]: <EventOutlined fontSize='small' />,
  [AIObjectPropertyType.Dropdown]: <ExpandCircleDownOutlined fontSize='small' />,
  [AIObjectPropertyType.Checkbox]: <CheckBoxOutlined fontSize='small' />,
  [AIObjectPropertyType.Url]: <Link fontSize='small' />,
  [AIObjectPropertyType.Boolean]: <ToggleOff fontSize='small' />,
  [AIObjectPropertyType.Latitude]: <PlaceRounded fontSize='small' />,
  [AIObjectPropertyType.Longitude]: <PlaceRounded fontSize='small' />,
}

export const UniqueAIObjectRoleInConnection = {
  Parent: 0,
  Child: 1,
  Projection: 2,
  Intersection: 3,
}

export const UniqueAIObjectRoleInConnectionDisplayName = {
  [UniqueAIObjectRoleInConnection.Parent]: 'Parent',
  [UniqueAIObjectRoleInConnection.Child]: 'Child',
  [UniqueAIObjectRoleInConnection.Projection]: 'Projection',
  [UniqueAIObjectRoleInConnection.Intersection]: 'Intersection',
}

const ParentDescription = 'A parent of another object.'
const ChildDescription = 'A child of another object.'
const ProjectionDescription = 'Represents the same object as another object in another session.'

export const UniqueAIObjectRoleInConnectionDescriptions = {
  [UniqueAIObjectRoleInConnection.Parent]: {
    [GraphElementType.Edge]: ParentDescription,
    [GraphElementType.Node]: ParentDescription,
  },
  [UniqueAIObjectRoleInConnection.Child]: {
    [GraphElementType.Edge]: ChildDescription,
    [GraphElementType.Node]: ChildDescription,
  },
  [UniqueAIObjectRoleInConnection.Projection]: {
    [GraphElementType.Edge]: ProjectionDescription,
    [GraphElementType.Node]: ProjectionDescription,
  },
  [UniqueAIObjectRoleInConnection.Intersection]: {
    [GraphElementType.Edge]:
      'This connection-type object passes through an intermediate simple object, which is not its endpoint.',
    [GraphElementType.Node]:
      'This simple object serves as an intermediate point without being the endpoint of the connection-type object.',
  },
}

export const getRoleInConnectionDescription = (uniqueAIObjectRoleInConnection, graphElementType) => {
  return UniqueAIObjectRoleInConnectionDescriptions[uniqueAIObjectRoleInConnection]?.[graphElementType] || ''
}
