import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RecipientsDialog } from '@tabeeb/modules/shared/dialogs'

import { getPresenterEmail } from '@tabeeb/modules/../users/selectors'
import { setEmailRecipientsDialogState, openExportImagesDialog, setExportEmailRecipients } from '../actions/index'

const ExportImagesRecipientsContainer = () => {
  const dispatch = useDispatch()

  const presenterEmail = useSelector(getPresenterEmail)

  const handleCloseDialog = useCallback(() => {
    dispatch(setEmailRecipientsDialogState(null))
  }, [dispatch])

  const handleSubmitDialog = useCallback(
    (recipients) => {
      dispatch(setExportEmailRecipients(recipients))
      dispatch(openExportImagesDialog())
    },
    [dispatch]
  )

  const props = {
    initialRecipientsList: [presenterEmail],
    onClose: handleCloseDialog,
    onSubmit: handleSubmitDialog,
    title: 'Who should receive exported images?',
    warningText: 'The images must be sent to at least one email address',
  }

  return <RecipientsDialog {...props} />
}

export default ExportImagesRecipientsContainer
