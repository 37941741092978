import { handleActions } from 'redux-actions'

import { setFormIsLoading, unsetFormIsLoading, resetFormBuilderState } from '../actions'

const defaultState = false

export default handleActions(
  {
    [setFormIsLoading]() {
      return true
    },
    [unsetFormIsLoading]() {
      return false
    },
    [resetFormBuilderState](state, action) {
      return defaultState
    },
  },
  defaultState
)
