import { combineReducers } from 'redux'

import containerSize from './containerSize'
import contentSize from './contentSize'
import isLoading from './isLoading'
import spatialViewStartPageId from './spatialViewStartPageId'
import selectedCoordinatesInfo from './selectedCoordinatesInfo'
import webPage from './webPage'
import autodesk from './autodesk'

export default combineReducers({
  containerSize,
  contentSize,
  isLoading,
  spatialViewStartPageId,
  selectedCoordinatesInfo,
  webPage,
  autodesk,
})
