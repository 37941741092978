import { combineReducers } from 'redux'

import openedMenu from './openedMenu'
import menuAnchorEl from './menuAnchorEl'
import displayAddVideoDialog from './displayAddVideoDialog'
import displayAddWebPageDialog from './displayAddWebPageDialog'

export default combineReducers({
  openedMenu,
  menuAnchorEl,
  displayAddVideoDialog,
  displayAddWebPageDialog,
})
