import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'

import { Container } from './styles'

const SessionBadge = ({ icon: Icon, label, title }) => {
  return (
    <Container variant='outlined' title={title || label}>
      <Icon fontSize='small' />
      <Typography variant='body2' fontWeight={500} marginLeft={0.75} noWrap>
        {label}
      </Typography>
    </Container>
  )
}

SessionBadge.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default memo(SessionBadge)
