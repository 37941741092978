import { memo } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import FolderUserRoleSelect from '../FolderUserRoleSelect'

const FolderUserRoleSelectField = ({ disabled, field }) => {
  const { setFieldValue } = useFormikContext()

  const onRoleChange = ({ target: { value: role } }) => {
    setFieldValue(field.name, role)
  }

  return <FolderUserRoleSelect disabled={disabled} role={field.value} onChange={onRoleChange} />
}

FolderUserRoleSelectField.defaultProps = {
  disabled: false,
}

FolderUserRoleSelectField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

export default memo(FolderUserRoleSelectField)
