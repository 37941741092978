import { all } from 'redux-saga/effects'

import healthSagas from './healthSagas'
import healthDevicesSagas from './healthDevicesSagas'

function* healthDataSagas() {
  yield all([healthSagas(), healthDevicesSagas()])
}

export default healthDataSagas
