import { handleActions, combineActions } from 'redux-actions'
import * as rawActions from '../actions'

export default handleActions(
  {
    [combineActions(rawActions.addClassificationAnnotations.request)](state, action) {
      return true
    },
    [combineActions(rawActions.addClassificationAnnotations.success, rawActions.addClassificationAnnotations.failed)](
      state,
      action
    ) {
      return false
    },
    [rawActions.resetState](state, action) {
      return false
    },
  },
  false
)
