import { handleActions } from 'redux-actions'

import * as aiActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [aiActions.getAiObjectsForTagsSuccess](state, { response }) {
      return [...response.data]
    },
  },
  defaultState
)
