import { combineReducers } from 'redux'

import mobileJoinCallDialogOpened from './mobileJoinCallDialogOpened'
import leaveSessionDialogState from './leaveSessionDialogState'
import recordingTypeDialogState from './recordingTypeDialogState'
import isSwitchRecordingSourceDialogOpened from './isSwitchRecordingSourceDialogOpened'
import isMuteCameraDialogOpened from './isMuteCameraDialogOpened'
import switchRecordingSourceDialogDeviceId from './switchRecordingSourceDialogDeviceId'
import makePresenterDialogState from './makePresenterDialogState'

export default combineReducers({
  mobileJoinCallDialogOpened,
  leaveSessionDialogState,
  recordingTypeDialogState,
  isSwitchRecordingSourceDialogOpened,
  isMuteCameraDialogOpened,
  makePresenterDialogState,
  switchRecordingSourceDialogDeviceId,
})
