import { handleActions } from 'redux-actions'

import { SessionStatus, ReviewStatus } from '@tabeeb/enums'

import * as rawActions from '../actions'

const defaultState = {
  ReviewStatus: Object.values(ReviewStatus),
  SessionStatus: Object.values(SessionStatus),
}

export default handleActions(
  {
    [rawActions.updateFilter](state, action) {
      const { name, values } = action.payload

      return {
        ...state,
        [name]: values,
      }
    },
    [rawActions.resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
