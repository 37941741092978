export const AnnouncementSeverity = {
  Information: 0,
  Notification: 1,
  Alert: 2,
}

export const AnnouncementSeverityDisplayName = {
  [AnnouncementSeverity.Information]: 'Information',
  [AnnouncementSeverity.Notification]: 'Notification',
  [AnnouncementSeverity.Alert]: 'Alert',
}
