import { memo } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import './styles.less'

const TimelineButtons = ({ actions }) => {
  return (
    <div className='timeline-buttons-container'>
      {actions.map((item, index) => (
        <button
          key={index}
          onClick={item.onClick}
          title={item.tooltip}
          disabled={item.disabled}
          className={classNames('timeline-button', `timeline-button-${item.class}`)}
        >
          {item.title}
        </button>
      ))}
    </div>
  )
}

TimelineButtons.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
}

export default memo(TimelineButtons)
