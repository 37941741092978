import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Slider, Typography } from '@material-ui/core'

import { drawingActions, playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import useStyles from './styles'

const LineWidthSlider = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const color = useSelector(playerToolbarSelectors.getSelectedColor)
  const width = useSelector(playerToolbarSelectors.getWidth)

  const handleDragStop = useCallback(() => {
    dispatch(drawingActions.onSendStrokeAttributes())
  }, [dispatch])

  const handleWidthSliderChange = useCallback(
    (_, newValue) => {
      dispatch(drawingActions.onSetDrawingWidth(newValue))
    },
    [dispatch]
  )

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography id='line-width-slider' color='textSecondary' variant='body2'>
          Size
        </Typography>
        <div className={classes.previewContainer}>
          <div className={classes.dot} style={{ width, height: width, backgroundColor: color }} />
        </div>
      </div>
      <Slider
        min={1}
        max={24}
        step={1}
        value={width}
        aria-labelledby='line-width-slider'
        onChange={handleWidthSliderChange}
        onChangeCommitted={handleDragStop}
      />
    </div>
  )
}

export default memo(LineWidthSlider)
