import { Box } from '@mui/material'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

const BetweenDatesCustomFilterOperator = ({ item, applyValue }) => {
  const [filterValue, setFilterValue] = useState(
    item.value ?? [new Date(Date.UTC(1900, 1, 1)), new Date(Date.UTC(2050, 0, 0))]
  )

  const updateFilterValue = useCallback(
    (lowerBound, upperBound) => {
      setFilterValue([lowerBound, upperBound])

      applyValue({ ...item, value: [lowerBound, upperBound] })
    },
    [applyValue, item]
  )

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        height: 48,
      }}
    >
      <KeyboardDatePicker
        placeholder='From'
        label='From'
        autoOk
        format='MM/dd/yyyy'
        onChange={(newDate) => updateFilterValue(newDate, filterValue[1])}
        value={filterValue[0]}
      />
      <KeyboardDatePicker
        placeholder='To'
        label='To'
        autoOk
        format='MM/dd/yyyy'
        onChange={(newDate) => updateFilterValue(filterValue[0], newDate)}
        value={filterValue[1]}
      />
    </Box>
  )
}

BetweenDatesCustomFilterOperator.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string.isRequired,
    value: PropTypes.shape(PropTypes.instanceOf(Date)),
  }).isRequired,
  applyValue: PropTypes.func.isRequired,
}

export default BetweenDatesCustomFilterOperator
