import { handleActions } from 'redux-actions'
import { openSessionsPageDialog, closeSessionsPageDialog, resetState } from '../actions'

const defaultValue = {
  dialogType: undefined,
  payload: undefined,
}

export default handleActions(
  {
    [openSessionsPageDialog](_, { payload: { dialogType, dialogPayload } }) {
      return { dialogType, payload: dialogPayload }
    },
    [closeSessionsPageDialog]() {
      return defaultValue
    },
    [resetState]() {
      return defaultValue
    },
  },
  defaultValue
)
