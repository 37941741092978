import { memo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import './styles.less'

const EmptyPolicyPlaceholder = () => {
  return (
    <div className='empty-policy-placeholder-container'>
      <Typography variant='h5'>Document is empty</Typography>
    </div>
  )
}

const PolicyViewer = ({ policy: { Text } }) => {
  const containerRef = useRef(null)
  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollTop = 0
    }
  })

  return Text ? (
    <div ref={containerRef} className='policy-viewer-container' dangerouslySetInnerHTML={{ __html: Text }} />
  ) : (
    <EmptyPolicyPlaceholder />
  )
}

PolicyViewer.defaultProps = {
  policy: {
    Text: '',
  },
}

PolicyViewer.propTypes = {
  policy: PropTypes.shape({
    Text: PropTypes.string.isRequired,
  }),
}

export default memo(PolicyViewer)
