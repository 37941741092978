import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import {
  getIsUserNeedsUpdate,
  getIsAcceptanceInProgress,
  getPolicies,
  getIsOpen,
  getIsLoaded,
  getIsLoading,
  getIsFailed,
} from '../selectors'

import PoliciesUpdatePopup from '../components/PoliciesUpdatePopup'

import * as rawActions from '../actions'
import { accountActions } from '../../account'

class PoliciesViewerPopupContainer extends Component {
  _handleDeny = () => {
    const {
      actions: { closePoliciesViewer },
      accountActions: { onLogout },
    } = this.props

    closePoliciesViewer()
    onLogout()
  }

  _handleAccept = () => {
    const {
      policies,
      actions: { acceptPoliciesRequest },
    } = this.props

    const model = {
      policiesIds: Object.values(policies)
        .filter((item) => item.IsAccepted === false)
        .map((item) => item.Id),
    }

    acceptPoliciesRequest(model)
  }

  _handleClose = () => {
    const {
      actions: { closePoliciesViewer },
    } = this.props

    closePoliciesViewer()
  }

  _handleReload = () => {
    const {
      actions: { getLatestPoliciesWithAcceptanceStatusRequest },
    } = this.props

    getLatestPoliciesWithAcceptanceStatusRequest()
  }

  render() {
    const { needsUpdate, policies, isOpen, isFailed, isLoaded, isLoading, isAcceptanceInProgress } = this.props

    const props = {
      policies,
      needsUpdate,
      isOpen,
      isFailed,
      isLoaded,
      isLoading,
      isAcceptanceInProgress,
      handleDeny: this._handleDeny,
      handleClose: this._handleClose,
      handleAccept: this._handleAccept,
      handleReload: this._handleReload,
    }

    return isOpen ? <PoliciesUpdatePopup {...props} /> : null
  }
}

PoliciesViewerPopupContainer.propTypes = {
  needsUpdate: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAcceptanceInProgress: PropTypes.bool.isRequired,
  policies: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    closePoliciesViewer: PropTypes.func.isRequired,
    acceptPoliciesRequest: PropTypes.func.isRequired,
    getLatestPoliciesWithAcceptanceStatusRequest: PropTypes.func.isRequired,
  }).isRequired,
  accountActions: PropTypes.shape({
    onLogout: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    needsUpdate: getIsUserNeedsUpdate(state),
    policies: getPolicies(state),
    isOpen: getIsOpen(state),
    isFailed: getIsFailed(state),
    isLoaded: getIsLoaded(state),
    isLoading: getIsLoading(state),
    isAcceptanceInProgress: getIsAcceptanceInProgress(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesViewerPopupContainer)
