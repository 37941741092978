import { cancel, fork, take } from 'redux-saga/effects'

import { getChatId } from '../services/chat'

export default (patternOrChannel, saga, ...args) =>
  fork(function* () {
    const lastTaskByChatDictionary = {}
    while (true) {
      const action = yield take(patternOrChannel)
      const { id, topicType, topicId, receiverId: userId } = action.payload

      const chatId = getChatId({ topicType, topicId, userId })

      const lastTaskByChat = lastTaskByChatDictionary[chatId]
      if (lastTaskByChat && lastTaskByChat.id < id) {
        yield cancel(lastTaskByChat.task)
      }

      const task = yield fork(saga, ...args.concat(action))
      lastTaskByChatDictionary[chatId] = {
        id,
        task,
      }
    }
  })
