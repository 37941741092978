export default (theme) => {
  const defaultContainer = {
    marginBottom: 12,

    '&:last-child': {
      marginBottom: 2,
    },
  }

  const blue = theme.palette.background.formAnswerStatus.submitted
  const green = theme.palette.background.formAnswerStatus.approved
  const red = theme.palette.background.formAnswerStatus.rejected

  return {
    container: {
      ...defaultContainer,
    },
    pendingContainer: {
      ...defaultContainer,

      '&:hover': {
        boxShadow: `0 0 2px 0 ${blue}`,
      },
    },
    approvedContainer: {
      ...defaultContainer,

      '&:hover': {
        boxShadow: `0 0 2px 0 ${green}`,
      },
    },
    rejectedContainer: {
      ...defaultContainer,

      '&:hover': {
        boxShadow: `0 0 2px 0 ${red}`,
      },
    },
    question: {
      padding: '0 10px 10px',
    },
  }
}
