import { put, takeLatest, all } from 'redux-saga/effects'

import * as actions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onDownloadIterationMetricsFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load metrics' }))
}

function* onDownloadIterationMetricsSuccess(action) {
  yield put(notificationActions.onAddInfoNotification({ message: 'Metrics loaded successfully' }))
}

function* getMetrics() {
  yield all([
    takeLatest(actions.downloadIterationMetrics.failed, onDownloadIterationMetricsFailed),
    takeLatest(actions.downloadIterationMetrics.success, onDownloadIterationMetricsSuccess),
  ])
}

export default getMetrics
