import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box, Skeleton } from '@mui/material'

const ContentSkeleton = ({ width }) => {
  return (
    <Box flexGrow={1} overflow='hidden' width={width} maxWidth='100%'>
      <Box height='100%' width='100%' padding={2}>
        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='80%' sx={{ mb: 1 }} />

        <Box display='flex' mb={1} justifyContent='space-between'>
          <Skeleton variant='rounded' width='32%' height={200} />
          <Skeleton variant='rounded' width='32%' height={200} />
          <Skeleton variant='rounded' width='32%' height={200} />
        </Box>

        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='80%' sx={{ mb: 1 }} />

        <Box display='flex' mb={1} justifyContent='space-between'>
          <Skeleton variant='rounded' width='49%' height={200} />
          <Skeleton variant='rounded' width='49%' height={200} />
        </Box>

        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='80%' sx={{ mb: 1 }} />

        <Box display='flex' mb={1} justifyContent='space-between'>
          <Skeleton variant='rounded' width='32%' height={200} />
          <Skeleton variant='rounded' width='32%' height={200} />
          <Skeleton variant='rounded' width='32%' height={200} />
        </Box>

        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='100%' sx={{ mb: 1 }} />
        <Skeleton variant='text' width='80%' sx={{ mb: 1 }} />
      </Box>
    </Box>
  )
}

ContentSkeleton.defaultProps = {
  width: '100%',
}

ContentSkeleton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
}

export default memo(ContentSkeleton)
