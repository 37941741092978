import _ from 'lodash'
import moment from 'moment'
import { RRuleSet } from 'rrule'

export const populateEventsForDateRange = (events, range) => {
  const { start, end } = range

  const populatedEvents = _.flatten(
    events.map((event) => {
      if (event.recurrence) {
        let rruleSet = RRuleSet.fromString(event.recurrence)
        rruleSet.origOptions.dtstart = event.start
        rruleSet = rruleSet.clone()

        const eventStartDate = moment(event.start)
        const eventEndDate = moment(event.end)

        const duration = eventEndDate.diff(eventStartDate)

        const occurrences = rruleSet.between(start, end)

        const events = occurrences.map((occurrence) => {
          const occurrenceDate = moment(occurrence)

          return {
            ...event,
            start: occurrenceDate.toDate(),
            end: occurrenceDate.clone().add(duration).toDate(),
          }
        })

        return [...events]
      }

      return [event]
    })
  )

  return populatedEvents
}

export const convertCalendarEvent = (event, calendarId) => {
  return {
    id: event.Id,
    type: event.Type,
    updatedOn: event.UpdatedOn ? new Date(event.UpdatedOn) : null,
    transparency: event.Transparency,
    visibility: event.Visibility,
    deleted: event.IsDeleted,
    title: event.UserId ? event.Title ?? '(No title)' : 'Busy',
    originalTitle: event.Title,
    description: event.Description,
    start: new Date(event.StartDate),
    end: new Date(event.EndDate),
    creatorId: event.UserId,
    creator: event.User,
    attendees: event.Attendees || [],
    allDay: false,
    recurrent: Boolean(event.Recurrence),
    recurrence: event.Recurrence,
    interval: event.Interval,
    calendarId: calendarId || event.UserId,
    contentId: event.ContentId,
    content: event.Content,
  }
}
