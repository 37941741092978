import { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ArrowDownward,
  ArrowUpward,
  EventOutlined,
  PendingActionsOutlined,
  PersonOutlineOutlined,
  TitleOutlined,
} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { SortingOrder } from '@tabeeb/enums'
import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import { setSortingColumn, setPageNumber, setSortingOrder } from '../../actions'
import { SortingColumn, SortingColumnDisplayName } from '../../enums'
import { getEnabledSortingColumns, getSortingColumn, getSortingOrder } from '../../selectors/sorting'

const columnToIconMap = {
  [SortingColumn.Name]: TitleOutlined,
  [SortingColumn.Date]: EventOutlined,
  [SortingColumn.LastActivity]: PendingActionsOutlined,
  [SortingColumn.Owner]: PersonOutlineOutlined,
}

const SortingColumnSelector = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const compact = useMediaQuery(theme.breakpoints.down('md'))

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const currentSortingColumn = useSelector(getSortingColumn)
  const currentSortingOrder = useSelector(getSortingOrder)

  const sortingColumns = useSelector(getEnabledSortingColumns)

  const onSortingColumnChange = useCallback(
    (newSortingColumn) => {
      onClose()

      if (currentSortingColumn === newSortingColumn) {
        return
      }

      dispatch(setSortingColumn(newSortingColumn))
      dispatch(setPageNumber(1))
    },
    [currentSortingColumn, dispatch, onClose]
  )

  const onSortingOrderChange = () => {
    dispatch(
      setSortingOrder(currentSortingOrder === SortingOrder.Ascending ? SortingOrder.Descending : SortingOrder.Ascending)
    )
    dispatch(setPageNumber(1))
  }

  useEffect(() => {
    if (sortingColumns.length > 0 && !sortingColumns.includes(currentSortingColumn)) {
      onSortingColumnChange(sortingColumns[0])
    }
  }, [sortingColumns, currentSortingColumn, onSortingColumnChange])

  const ColumnIcon = columnToIconMap[currentSortingColumn]

  return (
    <Box display='flex' alignItems='center' mr={2} ml={2}>
      {!compact && (
        <Typography variant='body2' fontWeight={600} noWrap>
          Order by:
        </Typography>
      )}

      <Button ref={anchorRef} color='inherit' startIcon={<ColumnIcon color='pagePrimaryAction' />} onClick={onOpen}>
        {SortingColumnDisplayName[currentSortingColumn]}
      </Button>

      <Menu anchorEl={anchorRef.current} open={open} onClose={onClose}>
        {sortingColumns.map((ordering) => {
          const Icon = columnToIconMap[ordering]

          return (
            <MenuItem key={ordering} value={ordering} onClick={() => onSortingColumnChange(ordering)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={SortingColumnDisplayName[ordering]} />
            </MenuItem>
          )
        })}
      </Menu>

      <IconButton sx={{ marginLeft: -1 }} size='small' onClick={onSortingOrderChange}>
        {currentSortingOrder === SortingOrder.Ascending ? (
          <ArrowUpward color='pagePrimaryAction' fontSize='small' />
        ) : (
          <ArrowDownward color='pagePrimaryAction' fontSize='small' />
        )}
      </IconButton>
    </Box>
  )
}

export default memo(SortingColumnSelector)
