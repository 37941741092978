import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { Button, CircularProgress, Divider, Grid, LinearProgress, Paper, Typography } from '@mui/material'
import { PageHeader, PageLayout } from '@tabeeb/uikit'
import PropTypes from 'prop-types'
import { FetchStatus } from '@tabeeb/enums'
import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import { Delete, OpenInNew, Save } from '@mui/icons-material'
import Routes from '@tabeeb/routes'
import PowerBIReportConfiguration from './PowerBIReportConfiguration'
import { DeleteReportConfirmationDialog } from '../Dialogs'
import { deletePowerBIReport, getPowerBIReport } from '../../actions'
import usePowerBIReportPropertiesForm from '../../hooks/forms/usePowerBIReportPropertiesForm'
import PowerBIReportProperties from './PowerBIReportProperties'
import { getPowerBIReportEditorState } from '../../selectors'
import PowerBIReportTabs from './PowerBIReportTabs'

const PowerBIReportEditor = ({ id }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPowerBIReport.request(id))
  }, [dispatch, id])

  const { fetchStatus, saveStatus, report } = useSelector(getPowerBIReportEditorState)

  const [deleteReportDialogOpen, onDeleteReportDialogOpen, onDeleteReportDialogClose] = useDialogState()

  const propertiesForm = usePowerBIReportPropertiesForm(report)

  const onDelete = useCallback(() => {
    dispatch(deletePowerBIReport.request(report.Id))
    onDeleteReportDialogClose()
  }, [dispatch, onDeleteReportDialogClose, report.Id])

  const onSave = useCallback(() => {
    if (propertiesForm.isValid && propertiesForm.dirty) {
      propertiesForm.submitForm()
    }
  }, [propertiesForm])

  const onOpen = useCallback(() => {
    window.open(window.location.origin + generatePath(Routes.powerBIReport, { id }), '_blank')
  }, [id])

  const saveButtonStartIcon = useMemo(() => {
    return saveStatus === FetchStatus.Loading ? <CircularProgress size={18} /> : <Save color='primary' />
  }, [saveStatus])

  return (
    <PageLayout component={Paper} variant='outlined' spacing='page' maxWidth='lg'>
      <PageHeader
        title='Edit PowerBI report'
        actions={
          <>
            <Button
              variant='outlined'
              color='primary'
              onClick={onOpen}
              startIcon={<OpenInNew color='primary' />}
              disabled={fetchStatus !== FetchStatus.Loaded}
            >
              Open
            </Button>
            <Button
              variant='outlined'
              color='error'
              onClick={onDeleteReportDialogOpen}
              startIcon={<Delete color='error' />}
              disabled={fetchStatus !== FetchStatus.Loaded}
              sx={{ marginLeft: '5px' }}
            >
              Delete
            </Button>
            <Button
              variant='outlined'
              color='primary'
              startIcon={saveButtonStartIcon}
              onClick={onSave}
              disabled={!propertiesForm.dirty}
              sx={{ marginLeft: '5px' }}
            >
              Save
            </Button>
          </>
        }
      />
      <Paper variant='outlined' sx={{ p: 3, height: '100%', overflow: 'auto' }}>
        {fetchStatus === FetchStatus.Loading && <LinearProgress />}
        {fetchStatus === FetchStatus.Failed && (
          <Typography sx={{ textAlign: 'center' }}>Failed to load report info.</Typography>
        )}
        {fetchStatus === FetchStatus.Loaded && (
          <Grid container spacing={1} flexDirection='column'>
            <PowerBIReportProperties form={propertiesForm} />
            <Divider sx={{ my: 1 }} />
            <PowerBIReportConfiguration report={report} />
            <Divider sx={{ my: 1 }} />
            <Grid item xs={12}>
              <PowerBIReportTabs report={report} />
            </Grid>
          </Grid>
        )}
      </Paper>
      <DeleteReportConfirmationDialog
        open={deleteReportDialogOpen}
        onClose={onDeleteReportDialogClose}
        onSubmit={onDelete}
      />
    </PageLayout>
  )
}

PowerBIReportEditor.propTypes = {
  id: PropTypes.string.isRequired,
}

export default PowerBIReportEditor
