import { createSelector } from 'reselect'

import { TenantPermission } from '@tabeeb/enums'
import { getIsBulkImportEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

export const getShowBulkImport = createSelector(
  [getIsBulkImportEnabled, (state) => hasTenantPermission(state, TenantPermission.BulkImport)],
  (enabledOnTenant, hasPermission) => {
    return enabledOnTenant && hasPermission
  }
)
