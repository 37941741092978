import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'

import { Box, Typography } from '@material-ui/core'

import InlineEditContainer from '@tabeeb/shared/inlineEdit'
import { contentStateSelectors } from '@tabeeb/shared/content'

import { usersSelectors } from '@tabeeb/modules/../users'
import { accountSelectors } from '@tabeeb/modules/account'
import { presentationSelectors } from '@tabeeb/modules/presentation'
import { galleryActions, gallerySelectors } from '@tabeeb/modules/gallery'

import useStyles from './styles'

const PageNumberSelect = ({ galleryList, totalPagesNumber, currentPageNumber, isDisabled }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isInEditMode, setIsInEditMode] = useState(false)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur()
    }
    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace'].includes(event.key)) {
      return false
    }
  }

  const validateBlur = (value) => value > 0
  const validateChange = (value) => value <= totalPagesNumber

  const handleMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsInEditMode(true)
  }

  const handleBlurInput = (event) => {
    setIsInEditMode(false)

    const nextPage = galleryList[Number(event.target.value) - 1]
    if (nextPage) {
      dispatch(galleryActions.onSelectGalleryItem({ pageId: nextPage.id }))
    }
  }

  return (
    <Box className={classes.pagination} disabled={isDisabled} onMouseDown={!isDisabled ? handleMouseDown : undefined}>
      <Typography className={classes.content}>
        <InlineEditContainer
          isInEditMode={isInEditMode}
          disabled={isDisabled}
          value={currentPageNumber}
          maxLength='40'
          handleKeyDown={handleKeyDown}
          classNameInput={classes.input}
          validateBlur={validateBlur}
          validateChange={validateChange}
          handleBlurInput={handleBlurInput}
        />
        /{totalPagesNumber}
      </Typography>
    </Box>
  )
}

PageNumberSelect.propTypes = {
  galleryList: PropTypes.array.isRequired,
  totalPagesNumber: PropTypes.number.isRequired,
  currentPageNumber: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const galleryList = gallerySelectors.getPagesByFolder(state)
  const selectedGalleryItemId = gallerySelectors.getSelectedGalleryItemId(state)

  const selectedGalleryItemIndex = galleryList.findIndex((item) => item.id === selectedGalleryItemId)
  const nextPage = galleryList[selectedGalleryItemIndex + 1]
  const previousPage = galleryList[selectedGalleryItemIndex - 1]

  const currentUserId = accountSelectors.getCurrentUserId(state)
  const currentUser = usersSelectors.getUserById(state, { Id: currentUserId })

  const isPresenter = contentStateSelectors.getIsCurrentUserPresenter(state)
  const isCallStarted = presentationSelectors.getIsCallStarted(state)

  const isAvailable = isPresenter || !isCallStarted

  const isDisabled = galleryList.length === 1 || !isAvailable

  return {
    galleryList,
    nextPageId: nextPage && nextPage.id,
    currentPageNumber: selectedGalleryItemIndex + 1,
    totalPagesNumber: galleryList.length,
    previousPageId: previousPage && previousPage.id,
    isDisabled,
    isPresenter,
    isAvailable,
    isOnline: Boolean(currentUser && currentUser.isOnline),
  }
}

export default connect(mapStateToProps)(PageNumberSelect)
