import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as muteVideoActions from '../actions/muteVideo'
import { accountSelectors } from '../../account'

function* onRemoteVideoMuted({ payload }) {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const userId = payload[0].value
  if (currentUserId == userId) {
    yield put(muteVideoActions.muteVideo())
  }
}

function* onVideoMuted() {
  yield put(muteVideoActions.onMuteVideoProgressStateUpdate(false))
}

function* muteVideo() {
  yield put(muteVideoActions.onMuteVideoProgressStateUpdate(true))
}

function* muteAudioSaga() {
  yield all([
    takeLatest(muteVideoActions.onRemoteVideoMuted, onRemoteVideoMuted),
    takeLatest(muteVideoActions.onVideoMuted, onVideoMuted),
    takeLatest(muteVideoActions.muteVideo, muteVideo),
  ])
}

export default muteAudioSaga
