import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import * as healthActions from '@tabeeb/modules/healthData/actions'

import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'

import { Grid, Button, withStyles, Typography, Avatar as AvatarIcon, Divider, TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import PersonIcon from '@material-ui/icons/Person'

import {
  GenderDisplayName,
  Genders,
  HealthDataTypes,
  IOTDeviceUnitType,
  SystemOfMeasureDisplayName,
  SystemOfMeasures,
} from '@tabeeb/enums'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { getCurrentUserWithHealthDataUpdateProfile } from '@tabeeb/modules/healthData/selectors'
import { FormikRadioGroup, FormikSelect, FormikTextField } from '../../../shared/forms'

import RegionalHeight from '../../../healthData/components/RegionalHeight'
import RegionalWeight from '../../../healthData/components/RegionalWeight'
import { setHealthHistoriesWhenSaving } from '../../../healthData/helper'
import styles from './styles'
import { Tooltip } from '@mui/material'

const minMaxValues = {
  HeightCm: {
    min: 0,
    max: 303,
  },
  HeightFt: {
    min: 0,
    max: 9,
  },
  HeightIn: {
    min: 0,
    max: 11.99,
  },
  WeightKg: {
    min: 0,
    max: 500,
  },
  WeightLbs: {
    min: 0,
    max: 1102.31,
  },
}

const mainSettingsSchema = Yup.object().shape({
  FirstName: Yup.string().trim().required('Required').max(35, 'Maximum length is 35 characters').nullable(),
  LastName: Yup.string().trim().required('Required').max(35, 'Maximum length is 35 characters').nullable(),
  PhoneNumber: Yup.string()
    .matches(/^[0-9]{8,15}$/, 'Phone Number is invalid')
    .nullable(),
  DateOfBirth: Yup.date()
    .min(new Date(Date.UTC(1900, 0, 0)), 'Date of birth is invalid')
    .max(new Date(), 'Date of birth is invalid')
    .typeError('Date of birth is invalid')
    .nullable(),
  Gender: Yup.string().nullable(),
  SystemOfMeasure: Yup.string().required('Required'),
  HeightCm: Yup.number()
    .min(minMaxValues.HeightCm.min, `Minimum value is ${minMaxValues.HeightCm.min} cm.`)
    .max(minMaxValues.HeightCm.max, `Maximum value is ${minMaxValues.HeightCm.max} cm.`)
    .nullable(),
  HeightFt: Yup.number()
    .min(minMaxValues.HeightFt.min, `Minimum value is ${minMaxValues.HeightFt.min} ft.`)
    .max(minMaxValues.HeightFt.max, `Maximum value is ${minMaxValues.HeightFt.max} ft.`)
    .nullable(),
  HeightIn: Yup.number()
    .min(minMaxValues.HeightIn.min, `Minimum value is ${minMaxValues.HeightIn.min} in.`)
    .max(minMaxValues.HeightIn.max, `Maximum value is 11.99 in.`)
    .nullable(),
  WeightKg: Yup.number()
    .min(minMaxValues.WeightKg.min, `Minimum value is ${minMaxValues.WeightKg.min} kg.`)
    .max(minMaxValues.WeightKg.max, `Maximum value is ${minMaxValues.WeightKg.max} kg.`)
    .nullable(),
  WeightLbs: Yup.number()
    .min(minMaxValues.WeightLbs.min, `Minimum value is ${minMaxValues.WeightLbs.min} lbs.`)
    .max(minMaxValues.WeightLbs.max, `Maximum value is ${minMaxValues.WeightLbs.max} lbs.`)
    .nullable(),
})

const genderOptions = Object.keys(Genders).map((gender) => ({
  value: Genders[gender],
  name: GenderDisplayName[gender],
}))
const systemOfMeasureOptions = Object.keys(SystemOfMeasures).map((systemOfMeasure) => ({
  id: SystemOfMeasures[systemOfMeasure],
  value: SystemOfMeasureDisplayName[systemOfMeasure],
}))

const MainSettings = ({ classes }) => {
  const dispatch = useDispatch()
  const userSettingsFormData = useSelector(getCurrentUserWithHealthDataUpdateProfile)
  const showHealthInfo = useSelector(appConfigStateSelectors.getShowHealthData)

  useEffect(() => {
    if (showHealthInfo) {
      const data = [
        {
          MeasureId: HealthDataTypes.Height,
          MeasureUnitId:
            userSettingsFormData.SystemOfMeasure === SystemOfMeasures.Metric
              ? IOTDeviceUnitType.Centimeter
              : IOTDeviceUnitType.Inch,
        },
        {
          MeasureId: HealthDataTypes.Weight,
          MeasureUnitId:
            userSettingsFormData.SystemOfMeasure === SystemOfMeasures.Metric
              ? IOTDeviceUnitType.Kilogram
              : IOTDeviceUnitType.Pound,
        },
      ]
      dispatch(healthActions.getTheLatestHealthDataHistoryRequest(data))
    }
  }, [dispatch, showHealthInfo, userSettingsFormData.SystemOfMeasure])

  const onSaveUserSettings = useCallback(
    (data) => {
      if (
        Number(data.HeightCm) !== Number(userSettingsFormData.HeightCm) ||
        Number(data.HeightFt) !== Number(userSettingsFormData.HeightFt) ||
        Number(data.HeightIn) !== Number(userSettingsFormData.HeightIn) ||
        Number(data.WeightKg) !== Number(userSettingsFormData.WeightKg) ||
        Number(data.WeightLbs) !== Number(userSettingsFormData.WeightLbs)
      ) {
        data = setHealthHistoriesWhenSaving(data, userSettingsFormData)
      } else {
        delete data.HeightCm
        delete data.HeightFt
        delete data.HeightIn
        delete data.WeightKg
        delete data.WeightLbs
      }

      const userSetting = {
        ...data,
        FirstName: data.FirstName.trim(),
        LastName: data.LastName.trim(),
        DateOfBirth:
          data.DateOfBirth && data.DateOfBirth.length !== 0 ? new Date(data.DateOfBirth).toDateString() : null,
      }

      dispatch(healthActions.handleHealthDataInProfileToSubmit(userSetting))
    },
    [dispatch, userSettingsFormData]
  )

  return (
    <Formik
      initialValues={userSettingsFormData}
      enableReinitialize
      validationSchema={mainSettingsSchema}
      onSubmit={(data) => onSaveUserSettings(data)}
    >
      {({ dirty, isValid, values, setFieldValue, touched, errors }) => (
        <Form className={classes.form} autoComplete='off'>
          <Grid item>
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <div className={classes.header}>
                  <AvatarIcon className={classes.avatar}>
                    <PersonIcon className={classes.icon} />
                  </AvatarIcon>
                  <Typography variant='body1'>Profile details</Typography>
                </div>
                <Divider className={classes.divider} />
                <Grid container wrap='nowrap' direction='column' spacing={1}>
                  <Grid item>
                    <Grid container wrap='nowrap' direction='row' spacing={1}>
                      <Grid item xs={6}>
                        <Field
                          name='FirstName'
                          color='primary'
                          label='First Name'
                          required
                          component={FormikTextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field name='LastName' color='primary' label='Last Name' required component={FormikTextField} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Tooltip title='You can change this info in your Identity profile'>
                      <TextField
                        autoFocus
                        name='Email'
                        color='primary'
                        variant='outlined'
                        fullWidth
                        label='Email'
                        disabled
                        InputProps={{ readOnly: true }}
                        value={values.Email}
                        title='This data is from identity server account'
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title='You can change this info in your Identity profile'>
                      <TextField
                        autoFocus
                        name='PhoneNumber'
                        color='primary'
                        variant='outlined'
                        fullWidth
                        label='Phone Number'
                        disabled
                        InputProps={{ readOnly: true }}
                        value={values.PhoneNumber}
                        title='This data is from identity server account'
                      />
                    </Tooltip>
                  </Grid>
                  {showHealthInfo && (
                    <>
                      <Grid item>
                        <KeyboardDatePicker
                          style={{ width: '100%' }}
                          id='date-of-birth-picker'
                          autoOk
                          inputVariant='outlined'
                          format='MM/dd/yyyy'
                          name='DateOfBirth'
                          label='Date of birth'
                          value={(values.DateOfBirth && new Date(values.DateOfBirth)) || null}
                          onChange={(date) => setFieldValue('DateOfBirth', date)}
                          error={Boolean(errors.DateOfBirth)}
                          helperText={errors.DateOfBirth}
                          KeyboardButtonProps={{ 'aria-label': 'change date' }}
                          title='The required date format is MM/DD/YYYY'
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          id='select-gender-in-profile'
                          name='Gender'
                          label='Gender'
                          component={FormikSelect}
                          options={genderOptions}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name='SystemOfMeasure'
                          label='System of measure'
                          options={systemOfMeasureOptions}
                          component={FormikRadioGroup}
                          row
                        />
                      </Grid>
                      <Grid item>
                        <RegionalHeight color='primary' minMaxValues={minMaxValues} />
                      </Grid>
                      <Grid item>
                        <RegionalWeight color='primary' minMaxValues={minMaxValues} />
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <Button
                      disabled={!(isValid && dirty)}
                      size='large'
                      fullWidth
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

MainSettings.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MainSettings)
