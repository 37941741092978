import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(),
      width: 190,
      display: 'block',
      textAlign: 'center',
      marginBottom: 2,
    },
    aiObjectSelect: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: theme.spacing() * 2,
    },
    listbox: {
      fontSize: '14px',

      '& .MuiTypography-body1': {
        fontSize: '14px',
      },
    },
    input: {
      fontSize: '14px',
    },
    popper: {
      width: '175px !important',
    },
  }
})
