import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_3D_MODEL_STATE')

export const open3dModelDialog = createAction('OPEN_3D_MODEL_DIALOG')
export const close3dModelDialog = createAction('CLOSE_3D_MODEL_DIALOG')

export const addImageTo3dModel = createAction('ADD_IMAGE_TO_3D_MODEL')
export const addAllPages3dToModel = createAction('ADD_ALL_PAGES_TO_3D_MODEL')
export const deleteImageFrom3dModel = createAction('DELETE_IMAGE_FROM_3D_MODEL')
export const updateImages3dModel = createAction('UPDATE_IMAGES_3D_MODEL')

export const create3dModel = createAction('CREATE_3D_MODEL')
export const createThreedeeModelRequest = createAction('CREATE_THREEDEE_MODEL_REQUEST')
export const createThreedeeModelSuccess = createAction('CREATE_THREEDEE_MODEL_SUCCESS')
export const createThreedeeModelFailed = createAction('CREATE_THREEDEE_MODEL_FAILED')
