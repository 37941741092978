import { combineActions, handleActions } from 'redux-actions'

import { calendarActions } from '@tabeeb/modules/calendar'
import { sessionsActions } from '@tabeeb/modules/sessionsPage'
import moment from 'moment'

const defaultState = {
  inProgress: false,
  session: null,
}

export default handleActions(
  {
    [sessionsActions.createSession.request](state, action) {
      return {
        inProgress: true,
        session: null,
      }
    },
    [sessionsActions.createSession.success](state, action) {
      const {
        data: { Description },
        creator,
      } = action.payload
      const { ContentId } = action.response.data

      return {
        inProgress: false,
        session: {
          Id: ContentId,
          Description: Description || moment(new Date()).format('D MMMM YYYY'),
          CreatedOn: new Date().toString(),
          Owner: creator,
          ThumbnailUrl: null,
        },
      }
    },
    [calendarActions.attachSessionToEvent](state, action) {
      return {
        inProgress: false,
        session: action.payload,
      }
    },
    [combineActions(
      calendarActions.detachSessionFromEvent,
      calendarActions.createCalendarEventRequest,
      calendarActions.openBookAppointmentSlotDialog,
      calendarActions.openCreateEventDialog
    )](state, action) {
      return defaultState
    },
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
  },
  defaultState
)
