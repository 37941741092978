import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    form: {
      flexGrow: 1,
      width: 400,
    },
    button: {
      marginTop: theme.spacing(1.25),
    },
    select: {
      marginTop: theme.spacing(2),
    },
    selectMenuProps: {
      width: 350,
    },
  }
})
