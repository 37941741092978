import { memo } from 'react'
import PropTypes from 'prop-types'

const ButtonBubble = ({ number }) => {
  return Boolean(number) && <b>{number}</b>
}

ButtonBubble.propTypes = {
  number: PropTypes.number.isRequired,
}

export default memo(ButtonBubble)
