import { put, takeLatest, all } from 'redux-saga/effects'
import { goBack } from 'connected-react-router'

import * as aiActions from '../actions'

function* onRestoreAiObjectSuccess(action) {
  yield put(goBack())
}

function* updateAIObject() {
  yield all([
    takeLatest([aiActions.restoreAiObject.success, aiActions.updateAiObject.success], onRestoreAiObjectSuccess),
  ])
}

export default updateAIObject
