import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { ContentType } from '@tabeeb/enums'
import { getSelectedGalleryItemType } from '@tabeeb/modules/gallery/selectors'

const PointCloudControls = ({ children }) => {
  const display = useSelector((state) => getSelectedGalleryItemType(state) === ContentType.PointCloud)
  if (!display) {
    return null
  }

  return children
}

PointCloudControls.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  ]).isRequired,
}

export default memo(PointCloudControls)
