import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { withModernTheme } from '@tabeeb/uikit'
import { FormikTextField } from '@tabeeb/modules/shared/forms'
import {
  maxLengthWithName,
  noWhitespaces,
  requiredFieldWithName,
} from '@tabeeb/modules/shared/utils/validationErrorMessages'

const schema = Yup.object().shape({
  Name: Yup.string()
    .strict()
    .trim(noWhitespaces)
    .max(400, maxLengthWithName('Name', 400))
    .required(requiredFieldWithName('Name')),
})

const FolderBaseDialog = ({ open, title, initial, onClose, onSubmit }) => {
  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onClose}>
      <Formik initialValues={initial} validateOnMount validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
        {({ isSubmitting, isValid, dirty }) => (
          <Form autoComplete='off'>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <Field autoFocus name='Name' label='Folder name' size='small' component={FormikTextField} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Close</Button>
              <Button disabled={isSubmitting || !isValid || !dirty} color='primary' variant='contained' type='submit'>
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

FolderBaseDialog.defaultProps = {
  initial: {
    Name: '',
  },
}

FolderBaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  initial: PropTypes.shape({
    Name: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withModernTheme(FolderBaseDialog)
