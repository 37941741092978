import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import oidcUserManager from '@tabeeb/services/oidcUserManager'
import { getIsTenantAuthFlowCompleted } from '@tabeeb/modules/account/selectors'
import domainService from '@tabeeb/services/domainService'

export const OidcSecure = () => {
  const isAuthFlowCompleted = useSelector(getIsTenantAuthFlowCompleted)
  const location = useLocation()
  const targetUrlPath = useMemo(() => location.pathname + location.search, [location])

  useEffect(() => {
    if (isAuthFlowCompleted) {
      oidcUserManager.signinRedirect({
        state: targetUrlPath,
        extraQueryParams: { subdomain: domainService.subdomain },
      })
    }
  }, [isAuthFlowCompleted, targetUrlPath])

  return null
}
