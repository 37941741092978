import { combineActions, handleActions } from 'redux-actions'

import * as healthActions from '@tabeeb/modules/healthData/actions'

const defaultState = []

export default handleActions(
  {
    [healthActions.addHealthDataChart](state, { payload }) {
      return [...state, { ...payload, convertedData: [] }]
    },
    [healthActions.removeHealthDataChart](state, { payload }) {
      return state.filter((chartHealthData) => chartHealthData.dataType !== payload.dataType)
    },
    [healthActions.setChartInterval](state, { payload }) {
      return state.map((chartHealthData) => {
        if (chartHealthData.dataType !== payload.dataType) {
          return chartHealthData
        }
        return { ...chartHealthData, interval: payload.interval }
      })
    },
    [healthActions.setChartsBySetting](state, { payload }) {
      return payload
    },
    [healthActions.setGraphCoordinates](state, { payload: graphCoordinates }) {
      return state.map((chartHealthData) => ({
        ...chartHealthData,
        convertedData: graphCoordinates[chartHealthData.dataType],
      }))
    },
    [combineActions(healthActions.resetHealthChartsState, healthActions.resetState)]() {
      return defaultState
    },
  },
  defaultState
)
