import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    select: {
      '& fieldset': {
        top: 0,
      },

      '& legend': {
        display: 'none',
      },
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
      height: '100%',
      padding: 0,
      paddingRight: '0px !important',
      minWidth: 54,
    },
    menu: {
      maxHeight: 300,
    },
  }
})
