import { styled, Typography } from '@material-ui/core'
import { ColumnFlexContainer, FilledButton } from '@tabeeb/uikit'
import { Field } from 'formik'

export const FieldsContainer = styled(ColumnFlexContainer)(({ theme }) => ({
  gap: theme.spacing(2),
}))

export const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '32px',
  textAlign: 'center',
}))

export const SubmitButton = styled(FilledButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.mobile.dark,
  margin: theme.spacing(2, 0),

  '&.Mui-disabled': {
    color: theme.palette.text.mobile.disabled,
  },

  '&:hover': {
    backgroundColor: theme.palette.secondary.mobile.dark,
  },
}))

export const MobileField = styled(Field)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: `0 ${theme.spacing(2)}`,
}))

export const ForgotTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: theme.spacing(),
  color: theme.palette.text.mobile.secondary,
}))

export const SSOButtonsContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(0.75),
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(),
}))

export const AltsTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.text.mobile.secondary,
}))
