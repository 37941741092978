import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as rawActions from '../actions'
import SharedForReviewForm from '../components/SharedForReviewForm'
import { getReviewsList } from '../selectors'

class SharedForReviewFormContainer extends Component {
  _revokeReview = () => {
    const { formReview } = this.props
    const {
      actions: { revokeReviewRequest },
    } = this.props
    revokeReviewRequest(formReview.Id)
  }

  render() {
    const { formReview, form } = this.props
    if (!formReview) {
      return null
    }

    const props = {
      formReview,
      form,
      revokeReview: this._revokeReview,
    }
    return <SharedForReviewForm {...props} />
  }
}

SharedForReviewFormContainer.propTypes = {
  actions: PropTypes.shape({
    revokeReviewRequest: PropTypes.func.isRequired,
  }).isRequired,
  formReview: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    Name: PropTypes.string.isRequired,
  }).isRequired,
}

const mapStateToProps = (state, props) => {
  const { form } = props
  const reviewsList = getReviewsList(state)
  return {
    formReview: reviewsList.find((r) => r.FormsIds.some((formId) => formId === form.FormId)),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedForReviewFormContainer)
