export const View = {
  Top: 0,
  Bottom: 1,
  Left: 2,
  Right: 3,
  Front: 4,
  Back: 5,
}

export const ViewName = {
  [View.Top]: 'Top',
  [View.Bottom]: 'Bottom',
  [View.Left]: 'Left',
  [View.Right]: 'Right',
  [View.Front]: 'Front',
  [View.Back]: 'Back',
}
