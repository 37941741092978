import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ContentSharingModelType, ContentSharingType } from '@tabeeb/enums'

import {
  getSelectedFormsReview,
  getSelectedFormsReviewer,
  getSelectedFormsReviewerContent,
  getSelectedFormsReviewerTenant,
  getTenantsListForReview,
} from '@tabeeb/modules/contentReviews/selectors'
import * as rawActions from '../actions/index'

import ShareSessionDialog from '../components/Dialogs/LegacyShareSessionDialog'
import { getSelectedSessionsCount } from '../selectors'

class ShareSessionDialogContainer extends Component {
  _closeDialog = () => {
    const {
      onClose,
      actions: { resetShareSessionDialog },
    } = this.props
    onClose()
    resetShareSessionDialog()
  }

  _updateUsersSearchValue = (e) => {
    const {
      actions: { updateUsersSearchValue },
      selectedTenantForSharing,
    } = this.props
    const tenantId = selectedTenantForSharing.Id
    const searchText = e.target.value
    updateUsersSearchValue({ searchText, tenantId })
  }

  _selectTenantForSharing = (e) => {
    const {
      actions: { setTenantForSharing },
      tenantsList,
      usersSearchInputValue,
      actions: { updateUsersSearchValue },
    } = this.props

    const tenantName = e.target.value
    const tenant = tenantsList.find((ten) => ten.Name === tenantName)

    setTenantForSharing(tenant)

    if (usersSearchInputValue) {
      updateUsersSearchValue({ searchText: usersSearchInputValue, tenantId: tenant.Id })
    }
  }

  _selectOwnerForSharing = (user) => {
    const {
      actions: { setOwnerForSharing },
    } = this.props
    setOwnerForSharing(user)
  }

  _removeOwner = () => {
    const {
      actions: { removeOwner },
    } = this.props
    removeOwner()
  }

  _shareContent = (contentSharingType) => {
    const {
      actions: {
        createContentSharing,
        createContentSharings,
        setTenantForSharing,
        setOwnerForSharing,
        setSelectedTargetContentForMerging,
      },
      isMultipleSessions,
      selectedTenantForSharing,
      selectedOwnerForSharing,
      session,
      dataType,
      selectedTargetContentForMerging,
    } = this.props

    if (selectedTenantForSharing?.IsPredefined) {
      setTenantForSharing(selectedTenantForSharing)
    }

    if (selectedOwnerForSharing?.IsPredefined) {
      setOwnerForSharing(selectedOwnerForSharing)
    }

    if (selectedTargetContentForMerging?.IsPredefined) {
      setSelectedTargetContentForMerging(selectedTargetContentForMerging)
    }

    if (isMultipleSessions) createContentSharings()
    else createContentSharing({ type: contentSharingType, session, dataType })

    this._closeDialog()
  }

  render() {
    const {
      open,
      isMultipleSessions,
      usersSearchInputValue,
      tenantsList,
      selectedTenantForSharing,
      selectedOwnerForSharing,
      contentSharingType,
      selectedTargetContentForMerging,
      selectedFormsReview,
    } = this.props

    const props = {
      isOpen: open,
      isMultipleSessions,
      shareContent: this._shareContent,
      handleClose: this._closeDialog,
      updateUsersSearchValue: this._updateUsersSearchValue,
      usersSearchInputValue,
      tenantsList,
      selectedTenant: selectedTenantForSharing,
      selectTenantForSharing: this._selectTenantForSharing,
      selectOwner: this._selectOwnerForSharing,
      selectedOwnerForSharing,
      removeOwner: this._removeOwner,
      contentSharingType,
      selectedTargetContentForMerging,
      selectedFormsReview,
    }

    return open && <ShareSessionDialog {...props} />
  }
}

ShareSessionDialogContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }),
  actions: PropTypes.shape({
    createContentSharing: PropTypes.func.isRequired,
    createContentSharings: PropTypes.func.isRequired,
    setTenantForSharing: PropTypes.func.isRequired,
    resetShareSessionDialog: PropTypes.func.isRequired,
    removeOwner: PropTypes.func.isRequired,
    updateUsersSearchValue: PropTypes.func.isRequired,
    setOwnerForSharing: PropTypes.func.isRequired,
    setSelectedTargetContentForMerging: PropTypes.func.isRequired,
  }).isRequired,
  usersSearchInputValue: PropTypes.string,
  tenantsList: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }).isRequired
  ),
  selectedTenantForSharing: PropTypes.shape({
    Id: PropTypes.number,
    IsPredefined: PropTypes.bool,
  }),
  selectedOwnerForSharing: PropTypes.shape({
    Id: PropTypes.number,
    IsPredefined: PropTypes.bool,
  }),
  contentSharingType: PropTypes.number,
  isMultipleSessions: PropTypes.bool,
  selectedSessionsCount: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  selectedTargetContentForMerging: PropTypes.shape({
    Id: PropTypes.number,
    IsPredefined: PropTypes.bool,
  }),
  dataType: PropTypes.number,
  selectedFormsReview: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Type: PropTypes.number.isRequired,
  }),
}

ShareSessionDialogContainer.defaultProps = {
  tenantsList: [],
  contentSharingType: ContentSharingModelType.SharedByOwner,
}

const mapStateToProps = (state, props) => {
  const { sessionsPage, appConfigState } = state
  const { contentSharingType, isOpen, isMultipleSessions } = props
  const { selectedTenantForSharing, selectedOwnerForSharing, selectedTargetContentForMerging } = sessionsPage
  const { selectedTenantsForSharing } = appConfigState

  const isForReview =
    contentSharingType === ContentSharingModelType.ForReview ||
    contentSharingType === ContentSharingModelType.MergeForReview
  const selectedFormsReview = isForReview ? getSelectedFormsReview(state) : null

  return {
    isOpen,
    isMultipleSessions,
    selectedSessionsCount: getSelectedSessionsCount(state),
    usersSearchInputValue: sessionsPage.usersSearchInputValue,
    selectedTenantForSharing: selectedFormsReview ? getSelectedFormsReviewerTenant(state) : selectedTenantForSharing,
    tenantsList: isForReview ? getTenantsListForReview(state) : selectedTenantsForSharing,
    selectedOwnerForSharing: selectedFormsReview ? getSelectedFormsReviewer(state) : selectedOwnerForSharing,
    contentSharingType,
    selectedTargetContentForMerging:
      selectedFormsReview?.Type === ContentSharingType.MergeForReview
        ? getSelectedFormsReviewerContent(state)
        : selectedTargetContentForMerging,
    selectedFormsReview,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareSessionDialogContainer)
